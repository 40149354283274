import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["AgGridVue"], {
    onGridReady: $setup.onReady,
    onColumnRowGroupChanged: $setup.columnRowGroupChanged,
    groupDisplayType: "singleColumn",
    rowHeight: $setup.rowHeight,
    getRowId: $setup.getRowId,
    localeText: $setup.zhCN,
    rowData: $setup.data,
    columnDefs: $setup.columnDefs,
    domLayout: "autoHeight",
    suppressDragLeaveHidesColumns: $setup.smallMeta,
    autoGroupColumnDef: $setup.autoGroupColumnDef
  }, null, 8 /* PROPS */, ["rowHeight", "localeText", "rowData", "columnDefs", "suppressDragLeaveHidesColumns"]))
}