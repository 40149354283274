<template>
	<Page>
		<template #title>
			<h3 class="title">{{ tt('Company Assessment Scoring Page') }}</h3>
		</template>
		<template #tools>
			<ToolBar
				v-model:company="company"
				v-model:monthDate="monthDate"
				v-model:yearDate="yearDate"
				v-model:pageType="pageType"
				v-model:refreshValue="refreshValue"
				v-model:loading="loading"
				v-model:detailType="detailType"
				:detailGridApi="detailGridApi">
			</ToolBar>
		</template>
		<template #sider>
			<OrganizationTree :organizations="organizations" :organizationLoading="organizationLoading" v-model:organization="organization">
			</OrganizationTree>
		</template>
		<Skeleton :loading="loading" ref="skeleton" :layout="detailType" :hasDetail="recordValue">
			<DetailTable
				v-if="pageType === 'detail'"
				:company="company"
				:loading="loading"
				:detailTableData="detailTableData"
				:layout="detailType"
				@updateRecordValue="updateRecordValue"
				@onDetailGridReady="onDetailGridReady">
			</DetailTable>
			<template #detail>
				<FormDetail v-if="recordValue" :meta="docMeta" options="{}" v-model:value="recordValue" />
			</template>
		</Skeleton>
		<SummaryTable v-if="pageType === 'summary'" :year="yearDate" :loading="loading" :company="company" :summaryTableData="summaryTableData">
		</SummaryTable>
	</Page>
</template>

<script lang="ts" setup>
import {ref, watch, shallowRef, onMounted} from 'vue';
import moment from 'moment';
import {GridApi} from 'ag-grid-community';

import {Organization} from '../type';
import Page from '../../../../../../../guigu_pm/guigu_pm/public/js/components/page/index.vue';
import Skeleton from '../../../../../../../guigu_pm/guigu_pm/public/js/components/DefaultFormDetail/Detail/index.vue';
import FormDetail from '../../../../../../../guigu_pm/guigu_pm/public/js/components/DefaultFormDetail/FormDetail/index.vue';
import {DetailTableData, SummaryMessage, SummaryTableData} from '../type';

import OrganizationTree from './components/OrganizationTree.vue';
import ToolBar from './components/ToolBar.vue';
import DetailTable from './components/DetailTable.vue';
import SummaryTable from './components/SummaryTable.vue';

const tt = __;
const company = ref<string>('');
const organization = ref<string>('');
const monthDate = ref<string>();
const yearDate = ref<string>();
const pageType = ref<string>('detail');
const refreshValue = ref<number>(0);
const organizations = ref([]);
const detailType = ref<string>('modal');

const detailTableData = ref<DetailTableData[]>([]);
const summaryTableData = ref<SummaryTableData[]>([]);
const detailGridApi = ref<GridApi>();

const loading = ref<boolean>(false);
const organizationLoading = ref<boolean>(false);

function onDetailGridReady(params: GridApi) {
	detailGridApi.value = params;
}

const docMeta = ref();
const recordValue = shallowRef();

function updateRecordValue(value: any) {
	recordValue.value = value;
}

watch(
	() => company?.value,
	async () => {
		if (company?.value) {
			organizationLoading.value = true;
			const organizationsRes: Organization[] = await frappe.db.get_list('Guigu Sub Company Organization', {
				filters: {sub_company: company.value, stop_using: 0},
				fields: ['name', 'parent_guigu_sub_company_organization', 'organization_name'],
				limit: '',
			});
			const resultOrganization: Organization[] = [];
			const organizationNames: string[] = [];
			for (const item of (organizationsRes || [])) {
				organizationNames.push(item.name);
				resultOrganization.push({
					id: item.name,
					name: item.name,
					parent_guigu_sub_company_organization: item.parent_guigu_sub_company_organization,
					label: item.organization_name,
					parent: item.parent_guigu_sub_company_organization,
				});
			}
			organizations.value = resultOrganization;
			organizationLoading.value = false;
		}
	},
);

watch([company, pageType, monthDate, organization, refreshValue], async () => {
	if (pageType.value !== 'detail') {
		return;
	}
	if (!organization.value) {
		return;
	}
	if (!monthDate.value || !company.value) {
		return;
	}
	loading.value = true;
	const data = await frappe.call({
		method:
			'guigu_pc.guigu_sub_company_work.page.sub_company_work_assessment.sub_company_work_assessment.get_sub_company_work_assessment',
		args: {
			sub_company: company.value,
			organization: organization.value,
			month: moment(monthDate.value).format('YYYY-MM'),
		},
	});
	const newData = (data?.message || []).map(item => ({
		...item,
		id: `${item.work_name}${item.assessment_name}`,
		assigner_user: item?.assigner_user || '',
	}));
	detailTableData.value = newData;
	loading.value = false;
});

watch([company, pageType, yearDate, organization, refreshValue], async () => {
	if (pageType.value !== 'summary') {
		return;
	}
	if (!organization.value) {
		return;
	}
	if (!yearDate.value || !company.value) {
		return;
	}
	loading.value = true;
	const monthArr = [];
	for (let i = 1; i <= 12; i++) {
		if (i < 10) {
			monthArr.push(`${yearDate.value}-0${i}`);
		} else {
			monthArr.push(`${yearDate.value}-${i}`);
		}
	}
	const data = await frappe.call<{ message: SummaryMessage }>({
		method:
			'guigu_pc.guigu_sub_company_work.page.sub_company_work_assessment.sub_company_work_assessment.get_summary_user_work_assessment',
		args: {
			sub_company: company.value,
			organization: organization.value,
			month_arr: monthArr,
		},
	});
	const data_obj: { [key: string]: SummaryTableData } = {};
	for (const item of (data?.message || [])) {
		const new_assessment_date = (item.assessment_date || '').substring(0, 7);
		if (data_obj[item.user]) {
			data_obj[item.user][new_assessment_date] = NP.plus(data_obj[item.user][new_assessment_date] || 0, item.leader_assessment || 0);
			data_obj[item.user].summary = NP.plus(data_obj[item.user]?.summary || 0, item.leader_assessment || 0);
		} else {
			data_obj[item.user] = {
				[new_assessment_date]: item.leader_assessment,
				user_name: item.user_name,
				user_id: item.user,
				summary: item.leader_assessment || 0,
				id: item.id,
			};
		}
	}
	summaryTableData.value = Object.values(data_obj).sort((item: SummaryTableData) => item.user);
	loading.value = false;
});

async function init(docType: string) {
	frappe.model.with_doctype(docType, () => {
		const meta = frappe.get_meta(docType);
		const metaFields = meta.fields.map(item => {
			if (item.fieldname !== 'subject') {
				return {...item, in_list_view: 0};
			}
			return item;
		});
		meta.fields = metaFields;
		docMeta.value = meta;
	});
}

onMounted(() => init('Guigu Sub Company Work'));
</script>
<style lang="css" scoped>
  .container {
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
  }

  .title {
    margin-bottom: 0;
  }
</style>
