<template>
	<svg :class="classList" aria-hidden="true">
		<use :xlink:href="iconName" :fill="color" />
	</svg>
</template>
<script lang="ts" setup>
import { computed, withDefaults } from 'vue';

interface Props {
	className?: string,
	iconClass: string,
	color?: string,
	size?: string,
}
const props = withDefaults(defineProps<Props>(), {
	size: '20px',
	color:'#409eff',
});
const classList = computed(() => ['icon', props.className || '']);
const iconName = computed(() => `#${props.iconClass}`);
</script>
<style scoped>
.icon {
	/* v-bind是Vue3才支持的功能，可以将CSS的值与js的值绑定 */
	width: v-bind('props.size');
	height: v-bind('props.size');
	position: relative;
	fill: currentColor;
	/* width: 1em;
	height: 1em;
	vertical-align: -0.15em;
	fill: currentColor;
	overflow: hidden; */
}
</style>
