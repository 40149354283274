<template>
	<div v-loading="loading">
		<Page :detailStyle="detailStyle">
			<template #title>
				<h3 class="title">
					{{ tt('Performance Interview Record Form') }}
				</h3>
				<span
					v-if="status"
					class="indicator-pill whitespace-nowrap orange"
				>
					<span>{{ tt('Not Saved') }}</span>
				</span>
				<span
					class="indicator-pill whitespace-nowrap blue"
					v-if="lockStatus"
				>
					<span>{{ tt('Already Locked') }}</span>
				</span>
			</template>
			<template #sider>
				<Member :users="members" v-model="userValue" />
			</template>
			<template #tools>
				<Tools
					:assessInfo="assessInfo"
					:permission="interviewUserPermission"
					:lockStatus="lockStatus"
					:isChangedStatus="isChangedStatus"
					@exportInterview="exportInterview"
					@save="save"
					v-model:toolDevWidth="toolDevWidth"
				></Tools>
			</template>
			<template #detail>
				<div v-if="lockStatus">
					<el-alert
						:title="prompt"
						type="warning"
						show-icon
						:closable="false"
					/>
				</div>
				<Detail
					ref="detailRef"
					:assessInfo="assessInfo"
					@update="updateInterview"
					@create="createInterview"
					:assessUserPermission="assessUserPermission"
					:lockStatus="lockStatus"
					:smallMeta="smallMeta"
					:type="type"
					@updateStatus="updateStatus"
					:toolDevWidth="toolDevWidth"
					:periodType="periodType"
				></Detail>
			</template>
		</Page>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, watch, type CSSProperties } from 'vue';
import { ElMessage } from 'element-plus';

import * as Permission from '../Permission';
import Page from '../../../../../../../guigu_pm/guigu_pm/public/js/components/page/index.vue';
import { useMetaQuery } from '../../../../../../../guigu_pm/guigu_pm/public/js/components/page/useMetaQuery';
import Member from '../Member/index.vue';

import Tools from './components/Tools.vue';
import Detail from './components/Detail.vue';
import type { AssessInfo } from './type';
const tt = __;
const prompt = __(
	'The record has been locked. If you need to modify it, please contact the HR department to unlock it',
);
const props = defineProps<{
	type: 'project' | 'subCompany';
	department: string;
	user: string;
	date: string;
	periodType: 'week' | 'month';
	organization?: string;
}>();
const emit = defineEmits<{
	'update:week': [string?];
	'update:department': [string?];
	'update:user': [string?];
}>();
const toolDevWidth = ref<string>('0px');
const detailStyle = ref<CSSProperties>({
	overflow: 'hidden',
});

const members = ref<{ label: string; value: string }[]>([]);
const userValue = computed({
	get: () => props.user,
	set: u => emit('update:user', u),
});

const assessInfo = ref<AssessInfo>();
const loading = ref<boolean>(false);
const detailRef = ref<{ exportInterview: () => void; save: () => void }>();
const smallMeta = useMetaQuery();
const isChangedStatus = ref<boolean>(false);
const lockStatus = ref<boolean>(false);

const interviewPermission = Permission.use(() => {
	const period = props.periodType === 'week' ? 'Week' : 'Month';
	const department = props.type === 'project' ? 'Project' : 'Company';
	const interviewDoctype = `Guigu Hr ${department} ${period} Interview Record`;
	return interviewDoctype;
});
const isOwner = computed(() => props.user === frappe.user.name);

const interviewUserPermission = computed(() =>
	Permission.ifOwner(interviewPermission.value, isOwner.value),
);
const assessPermission = Permission.use(() => {
	const period = props.periodType === 'week' ? 'Week' : 'Month';
	const department = props.type === 'project' ? 'Project' : 'Company';
	return `Guigu Hr ${department} ${period} Interview Record`;
});
const assessUserPermission = computed(() =>
	Permission.ifOwner(assessPermission.value, props.user === frappe.user.name),
);
function updateStatus(newStatus: boolean) {
	isChangedStatus.value = newStatus;
}

async function getAssessListUser() {
	if (
		!props.date ||
		!props.type ||
		!props.department ||
		!props.organization
	) {
		return;
	}
	loading.value = true;
	let methodString = '';
	let args: any = {
		type: props.type,
		department: props.department,
		organization: props.organization,
	};
	if (props.periodType === 'week') {
		methodString =
			'guigu_hr.guigu_hr_assessment.page.guigu_hr_project_week_assessment_list.guigu_hr_project_week_assessment_list.get_assessments_user';
		args = { ...args, week: props.date };
	} else {
		methodString =
			'guigu_hr.guigu_hr_assessment.page.guigu_hr_project_assessment_list.guigu_hr_project_assessment_list.get_assessments_user';
		args = { ...args, month: props.date };
	}
	const res = await frappe.call<{ message: Assess[] }>({
		method: methodString,
		args: args,
	});
	loading.value = false;
	members.value =
		(res?.message || []).sort((pre, next) =>
			pre.full_name > next.full_name ? 1 : -1,
		) || [];
}

watch(
	[
		() => props.periodType,
		() => props.type,
		() => props.department,
		() => props.organization,
	],
	() => {
		getAssessListUser();
	},
	{ immediate: true },
);

const isModify = computed(() =>
	Boolean(assessInfo.value?.interview_record?.name),
);

const status = computed(
	() => !lockStatus.value && (!isModify.value || isChangedStatus.value),
);

async function createInterview(data: any) {
	if (!assessInfo.value) {
		return;
	}
	loading.value = true;
	try {
		await frappe.call({
			method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_assessment_interview.guigu_hr_assessment_interview.create_interview',
			args: {
				data,
				type: props.type,
				period_type: props.periodType,
				department: assessInfo.value.department_doc.name,
			},
		});
		isChangedStatus.value = false;
		frappe.show_alert({
			message: __('Saved Successfully'),
			indicator: 'green',
		});
		getAssess();
	} catch (e) {
		getAssess();
	}
}
async function updateInterview(data: any) {
	if (!assessInfo.value?.interview_record) {
		return;
	}
	loading.value = true;
	try {
		const aa = await frappe.call({
			method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_assessment_interview.guigu_hr_assessment_interview.update_interview',
			args: {
				data,
				type: props.type,
				period_type: props.periodType,
				name: assessInfo.value.interview_record.name,
			},
		});
		isChangedStatus.value = false;
		ElMessage({
			type: 'success',
			message: __('Saved Successfully'),
		});
		getAssess();
	} catch (e) {
		getAssess();
	}
}

watch(
	[
		() => props.periodType,
		() => props.date,
		() => props.user,
		() => props.department,
		() => props.type,
	],
	() => {
		getAssess();
		getLockStatus();
	},
	{ immediate: true },
);

async function getLockStatus() {
	const { date, type, department } = props;
	if (!date || !type || !department) {
		return;
	}
	const res = await frappe.call<{ message: boolean }>({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_project_assessment_list.guigu_hr_project_assessment_list.get_lock_status',
		args: {
			month: date,
			type: type,
			department: department,
		},
	});
	lockStatus.value = Boolean(res?.message);
}
async function getAssess() {
	const { date, periodType, user, department, type } = props;
	if (!date || !periodType || !user || !department || !type) {
		return;
	}
	loading.value = true;

	const res = await frappe.call<{ message: AssessInfo }>({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_assessment_interview.guigu_hr_assessment_interview.get_assessment',
		args: {
			period_type: periodType,
			type: type,
			period_date: date,
			department: department,
			user: user,
		},
	});
	assessInfo.value = res?.message;
	loading.value = false;
}

function exportInterview() {
	detailRef.value?.exportInterview();
}
function save() {
	detailRef.value?.save();
	// frappe.msgprint({
	// 	message: __('Saved Successfully'),
	// 	indicator: 'green',
	// });
}
</script>

<style lang="less" scoped>
.title {
	font-size: 1.25rem;
	font-weight: bold;
	margin-right: 15px;
	margin-bottom: 0;
}
.el-alert {
	margin-top: 5px;
	margin-bottom: 10px;
	height: 32px;
}
</style>
