import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18379aca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  class: "container",
  ref: "containerRef"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Page"], { mainStyle: {display:'flex', flexDirection:'column'} }, {
      title: _withCtx(() => [
        _createElementVNode("h3", _hoisted_2, _toDisplayString($setup.tt("Project Work Daily Log Attendance Summary")), 1 /* TEXT */)
      ]),
      tools: _withCtx(() => [
        _createVNode($setup["ToolBar"], {
          project: $setup.project,
          "onUpdate:project": _cache[0] || (_cache[0] = $event => (($setup.project) = $event)),
          monthDate: $setup.monthDate,
          "onUpdate:monthDate": _cache[1] || (_cache[1] = $event => (($setup.monthDate) = $event)),
          yearDate: $setup.yearDate,
          "onUpdate:yearDate": _cache[2] || (_cache[2] = $event => (($setup.yearDate) = $event)),
          pageType: $setup.pageType,
          "onUpdate:pageType": _cache[3] || (_cache[3] = $event => (($setup.pageType) = $event)),
          countCategory: $setup.countCategory,
          "onUpdate:countCategory": _cache[4] || (_cache[4] = $event => (($setup.countCategory) = $event)),
          refreshValue: $setup.refreshValue,
          "onUpdate:refreshValue": _cache[5] || (_cache[5] = $event => (($setup.refreshValue) = $event)),
          loading: $setup.loading,
          "onUpdate:loading": _cache[6] || (_cache[6] = $event => (($setup.loading) = $event)),
          size: $setup.size,
          detailGridApi: $setup.detailGridApi
        }, null, 8 /* PROPS */, ["project", "monthDate", "yearDate", "pageType", "countCategory", "refreshValue", "loading", "size", "detailGridApi"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode($setup["SummaryTable"], {
            pageType: $setup.pageType,
            countCategory: $setup.countCategory,
            monthDate: $setup.monthDate,
            yearDate: $setup.yearDate,
            project: $setup.project,
            summaryTableData: $setup.summaryTableData,
            attendanceTypes: $setup.attendanceTypes,
            loading: $setup.loading,
            isPhone: $setup.size,
            onOnDetailGridReady: $setup.onDetailGridReady
          }, null, 8 /* PROPS */, ["pageType", "countCategory", "monthDate", "yearDate", "project", "summaryTableData", "attendanceTypes", "loading", "isPhone"])
        ], 512 /* NEED_PATCH */),
        ($setup.project && $setup.countCategory == 'date' && $setup.pageType == 'month')
          ? (_openBlock(), _createBlock($setup["AttendanceType"], {
              key: 0,
              project: $setup.project,
              attendanceTypes: $setup.attendanceTypes
            }, null, 8 /* PROPS */, ["project", "attendanceTypes"]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ]))
}