import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-758bc963"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElButton = _resolveComponent("ElButton")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      ($setup.showGaterButton)
        ? (_openBlock(), _createBlock(_component_ElButton, {
            key: 0,
            type: "primary",
            onClick: $setup.showGaterTable
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.isShowGaterTable ? $setup.tt('View the progress of this structure') : $setup.tt('View summary progress')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      _withDirectives(_createVNode(_component_ElButton, {
        type: "primary",
        onClick: $setup.click
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.tt('Batch Add Structure Progress')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 512 /* NEED_PATCH */), [
        [_vShow, !$setup.isShowGaterTable]
      ])
    ]),
    _withDirectives(_createVNode($setup["StructureProgressTable"], {
      structureProgresses: $setup.structureProgresses || [],
      enableAudit: $setup.enableAudit,
      project: $setup.project,
      onDeleteStructureProgress: $setup.deleteStructureProgress,
      onClickComplete: $setup.clickComplete,
      onChange: $setup.structureProgressChange
    }, null, 8 /* PROPS */, ["structureProgresses", "enableAudit", "project"]), [
      [_vShow, !$setup.isShowGaterTable]
    ]),
    _withDirectives(_createVNode($setup["ProgressGather"], {
      structureName: $setup.structureName,
      project: $setup.project
    }, null, 8 /* PROPS */, ["structureName", "project"]), [
      [_vShow, $setup.isShowGaterTable]
    ]),
    _createVNode($setup["BulkProgressModal"], {
      visible: $setup.visible,
      selectedProgresses: $setup.selectedProgressesNames,
      progressItems: $setup.progressItems,
      project: $setup.project,
      onClose: $setup.closeInfo,
      onConfirm: $setup.confirm
    }, null, 8 /* PROPS */, ["visible", "selectedProgresses", "progressItems", "project"])
  ], 64 /* STABLE_FRAGMENT */))
}