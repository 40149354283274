import Handsontable from 'handsontable';

import { getRenderer } from './customStylesRenderer.mjs';

export default function setType(
	table: Handsontable,
	range: { start: { row: number; col: number; }; end: { row: number; col: number; }; }[],
	type: 'numeric' | 'text',
) {
	const prop: any = {
		type,
		className: '',
		numericFormat: type === 'numeric' ? { pattern: '0,0.00' } : {},
		renderer: getRenderer(type),
		editor: type,
		dataType: type,
	};
	if (type !== 'numeric') {
		prop.valid = undefined;
		prop.validator = undefined;
	}

	for (const item of range) {
		const startRow = item.start.row;
		const endRow = item.end.row;
		const startCol = item.start.col;
		const endCol = item.end.col;
		for (let row = startRow; row <= endRow; row++) {
			for (let col = startCol; col <= endCol; col++) {
				table.setCellMetaObject(row, col, prop);
			}
		}
	}
	table.render();
}
