<template>
	<div class="file-upload-area"
		@dragover.prevent="dragOver"
		@dragleave.prevent="dragLeave"
		@drop.prevent="dropFiles">
		<div v-if="!dragging">
			<div class="text-center">
				{{ tt('Drag and drop files here or upload from') }}
			</div>
			<div class="mt-2 text-center">
				<button class="btn btn-file-upload" @click="browseFiles">
					<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="15" cy="15" r="15" fill="url(#paint0_linear)"/>
						<path d="M13.5 22V19" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M16.5 22V19" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M10.5 22H19.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M7.5 16H22.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M21 8H9C8.17157 8 7.5 8.67157 7.5 9.5V17.5C7.5 18.3284 8.17157 19 9 19H21C21.8284 19 22.5 18.3284 22.5 17.5V9.5C22.5 8.67157 21.8284 8 21 8Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
						<defs>
							<linearGradient id="paint0_linear" x1="0" y1="0" x2="0" y2="30" gradientUnits="userSpaceOnUse">
								<stop stop-color="#2C9AF1"/>
								<stop offset="1" stop-color="#2490EF"/>
							</linearGradient>
						</defs>
					</svg>
					<div class="mt-1">{{ tt('My Device') }}</div>
				</button>
				<!-- <button v-if="!has" class="btn btn-file-upload" @click="showBrowser">
					<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="15" cy="15" r="15" fill="#48BB74"/>
					<path d="M13.0245 11.5H8C7.72386 11.5 7.5 11.7239 7.5 12V20C7.5 21.1046 8.39543 22 9.5 22H20.5C21.6046 22 22.5 21.1046 22.5 20V14.5C22.5 14.2239 22.2761 14 22 14H15.2169C15.0492 14 14.8926 13.9159 14.8 13.776L13.4414 11.724C13.3488 11.5841 13.1922 11.5 13.0245 11.5Z" stroke="white" stroke-miterlimit="10" stroke-linecap="square"/>
					<path d="M8.87939 9.5V8.5C8.87939 8.22386 9.10325 8 9.37939 8H20.6208C20.8969 8 21.1208 8.22386 21.1208 8.5V12" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
					<div class="mt-1">{{ tt('Library') }}</div>
					</button> -->
				<button v-if="allow_take_photo" class="btn btn-file-upload" @click="captureImage">
					<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="15" cy="15" r="15" fill="#CE315B"/>
						<path d="M11.5 10.5H9.5C8.67157 10.5 8 11.1716 8 12V20C8 20.8284 8.67157 21.5 9.5 21.5H20.5C21.3284 21.5 22 20.8284 22 20V12C22 11.1716 21.3284 10.5 20.5 10.5H18.5L17.3 8.9C17.1111 8.64819 16.8148 8.5 16.5 8.5H13.5C13.1852 8.5 12.8889 8.64819 12.7 8.9L11.5 10.5Z" stroke="white" stroke-linejoin="round"/>
						<circle cx="15" cy="16" r="2.5" stroke="white"/>
					</svg>
					<div class="mt-1">{{ tt('Camera') }}</div>
				</button>
			</div>
			<!-- <form @submit.prevent="submit">
				<textarea
				class="form-control"
				placeholder="添加附件链接(一行一个)"
				v-model="urlText"
				rows="5"
				@paste="paste"
				@keydown.ctrl.enter="submit"
				></textarea>
				<button type="submit" :disabled="!submittable" class="btn">添加附件链接</button>
				</form> -->
			<!-- <div class="text-muted text-medium text-center">
				{{ upload_notes }}
				</div> -->
		</div>
		<div v-else>
			{{ tt('Drop files here') }}
		</div>
	</div>
</template>
<script setup lang="ts">
import { ref, watch, computed } from 'vue';


const props = defineProps<{
	has?: boolean
	filetypes?: string[],
}>();

const emit = defineEmits<{
	addFiles: [(File | string)[]];
	showBrowser: [];
}>();
const tt = __;

function showBrowser() {
	emit('showBrowser');
}
function addFiles(files: (File | string)[]) {
	emit('addFiles', files);
}
const urlText = ref('');
const urlArray = computed(() => urlText.value
	.split('\n')
	.map(v => v.replace(/^\s+|\s+$/g, ''))
	.filter(Boolean));

const r = computed<[string[], string] | null>(() => {
	const urls: string[] = [];
	const others: string[] = [];
	for (const t of urlArray.value) {
		try {
			const u = new URL(t);
			if (['http:', 'https:'].includes(u.protocol.toLowerCase())) {
				urls.push(t);
			} else {
				others.push(t);
			}
		} catch {
			others.push(t);

		}
	}
	if (!urls.length) { return null; }
	return [urls, others.join('\n')];
});
const submittable = computed(() => Boolean(r.value));
const showUrlTip = computed(() => {
	const v = r.value;
	return Boolean( v ? v[1] : urlArray.value.length > 0);
});

function submit() {
	const v = r.value;
	if (!v) { return; }
	const [urls, t] = v;
	urlText.value = t;
	addFiles(urls);
}

const allow_take_photo = Boolean(window.navigator.mediaDevices);


function browseFiles() {
	const input = document.createElement('input');
	input.type = 'file';
	input.multiple = true;
	input.accept = props.filetypes?.join(', ') || '';
	input.addEventListener('change', () => addFiles(input.files));
	input.click();
}

function url_to_file(url, filename, mime_type) {
	return fetch(url)
		.then(res => res.arrayBuffer())
		.then(buffer => new File([buffer], filename, { type: mime_type }));
}
function captureImage() {
	const capture = new frappe.ui.Capture({ animate: false, error: true });
	capture.show();
	capture.submit(data_urls => {
		for (const [k, data_url] of data_urls.entries()) {
			let filename = `capture_${frappe.datetime.now_datetime().replaceAll(/[: -]/g, '_')}_${k + 1}.png`;
			url_to_file(data_url, filename, 'image/png').then(file =>
				addFiles([file]),
			);
		}
	});
}


function paste(e) {
	console.log(e);
	addFiles([...e.clipboardData.files]);
}
const dragging = ref(false);
function dragOver() {
	dragging.value = true;
}
function dragLeave() {
	dragging.value = false;
}
function dropFiles(e) {
	dragging.value = false;
	addFiles(e.dataTransfer.files);
}
</script>
<style>
.file-upload-area {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px dashed var(--dark-border-color);
	border-radius: var(--border-radius);
	cursor: pointer;
	background-color: var(--bg-color);
	padding-block: 10px;
}
.file-upload-area > div {
	inline-size: 100%;
}
.file-upload-area form {
	inline-size: 100%;
	display: flex;
	flex-direction: column;
	padding-block: 5px;
	padding-inline: 20px;
}
.file-upload-area textarea {
	resize: vertical;
	resize: block;
	min-block-size: 100px;
}
.btn-file-upload {
	background-color: transparent;
	border: none;
	box-shadow: none;
	font-size: var(--text-xs);
}
</style>
