import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a78c1e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "control editable" }
const _hoisted_2 = { class: "field-controls" }
const _hoisted_3 = { ref: "map" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "label", {}, undefined, true),
      _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_3, null, 512 /* NEED_PATCH */),
    ($props.df.description)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "mt-2 description",
          innerHTML: $props.df.description
        }, null, 8 /* PROPS */, _hoisted_4))
      : _createCommentVNode("v-if", true)
  ]))
}