<template>
	<div :class="['layout']">
		<div class="body" ref="body">
			<div class="maindata">
				<div :class="{ ['context']: true, ['skeleton-row']: row }">
					<!-- 主区域栏 -->
					<div class="main" v-loading="loading || false">
						<div class="data">
							<slot />
						</div>
					</div>
					<!-- 详情区 -->
					<Detail v-if="props.hasDetail"
						:model="modal" :row="row">
						<slot name="detail" />

					</Detail>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import {useSlots, shallowRef, onBeforeUpdate, toRefs, computed} from 'vue';
import {vLoading} from 'element-plus';


import {injectResizeStorage} from './resizeStorage';
import Detail from './Detail.vue';

defineOptions({name: 'Skeleton'});

const props = defineProps<{
	// classes: GlobalView.SkeletonClassNames;
	loading?: boolean;
	layout?: string;
	hasDetail?: any
}>();

const layout = computed(() => props.layout);
window.layoutlayout = layout;
const modal = computed(() => layout.value === 'modal');
const row = computed(() => layout.value === 'right');


const slots = useSlots();
const hasToolbar = shallowRef(Boolean(slots.toolbar?.()?.length));
const hasSider = shallowRef(Boolean(slots.sider?.()?.length));
const hasDetail = shallowRef(Boolean(slots.detail?.()?.length));
const hasFilterField = shallowRef(Boolean(slots.filterField?.()?.length));
onBeforeUpdate(() => {
	hasToolbar.value = Boolean(slots.toolbar?.()?.length);
	hasSider.value = Boolean(slots.sider?.()?.length);
	hasDetail.value = Boolean(slots.detail?.()?.length);
	hasFilterField.value = Boolean(slots.filterField?.()?.length);
});
const body = shallowRef();
function fullscreen() {
	const root = body.value;
	if (!root) {
		return;
	}
	if (document.fullscreenElement === root) {
		document.exitFullscreen();
	} else {
		root.requestFullscreen();
	}
}
defineExpose({
	fullscreen,
});
</script>
<style scoped lang="less">
.layout {
	overflow: hidden;
	display: flex;
	height: 100%;
	flex-direction: row;
	--mainView-sider: 30%;
}

.actions {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: nowrap;
	gap: 4px;
	float: inline-end;
}


.body {
	overflow: hidden;
	flex: 1;

	display: flex;
	flex-direction: column;
	border-radius: var(--border-radius-md);
	box-shadow: var(--card-shadow);
	background-color: var(--card-bg);

}

.toolbar {
	position: relative;
	background-color: var(--card-bg);
	border-bottom: 1px solid var(--border-color);
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	padding: var(--padding-sm);
	min-height: 40px;
	box-sizing: content-box;
	gap: 8px;

	&::after {
		content: '';
		clear: both;
	}
}

.toolbox {
	display: contents;
	flex: 1;
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	gap: 8px;

	>div {
		display: contents;
	}

	&-close {
		display: none;
	}

	&-btn {
		display: none;
		margin-inline-end: auto;
	}

	@media screen and (max-width: 738px) {

		display: block;
		position: fixed;
		inset: 0;
		overflow: auto;
		background: rgba(0, 0, 0, .3);
		padding: 40px;
		padding-block-start: 80px;
		z-index: 1020;

		&.hidden {
			display: none;
		}

		&-btn {
			display: inline-block;

		}

		&-close {
			position: absolute;
			inset-block-start: -20px;
			inset-inline-end: -20px;
			display: block;
		}

		>div {
			display: block;
			padding: 20px;
			border-radius: 20px;


			position: relative;
			background-color: var(--card-bg);
			display: flex;
			flex-direction: row;
			align-content: center;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;
			min-height: 40px;
			box-sizing: content-box;
			gap: 8px;
		}
	}
}


.maindata {
	flex: 1;
	overflow: hidden;
	display: flex;
	flex-direction: row;
}

.context {
	flex: 1;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	.main {
		overflow: hidden;
		flex: 1;
		display: flex;
		flex-direction: column;
		order: 1;
	}

	.pagination {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.data {
		overflow: auto;
		flex: 1;
		position: relative;
	}


	&.skeleton-row {
		flex-direction: row;

	}

}
</style>
