<template>
	<AgGridVue
		class="ag-theme-guigu week-plan_table"
		:columnDefs="columnDefs"
		:rowData="rowData"
		:defaultColDef="defaultColDef"
		rowSelection="multiple"
		animateRows="true"
		@grid-ready="onGridReady"
		:autoGroupColumnDef="autoGroupColumn"
		:getDataPath="getDataPath"
		:treeData="true"
		:localeText="zhCN"
		:suppressDragLeaveHidesColumns="smallMeta"
		:getRowId="getRowId">
	</AgGridVue>
</template>
<script lang="ts">
import {ref, computed, defineComponent, PropType, getCurrentInstance, watch} from 'vue';
import {AgGridVue} from 'ag-grid-vue3';
import {GetGroupRowAggParams} from 'ag-grid-community';

import FloatComponent from '../../../AgGrid/components/Float.vue';
import SparklineCellRenderer from '../../../AgGrid/components/SparklineCellRenderer.vue';
import {useMetaQuery} from '../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';
import zhCN from '../../../../../../../guigu/guigu/public/js/agGrid/zhCN';

import {BoqValue, Period, StructureItemFormat} from './type';
import {getColumns} from './tableColumns';
import {getProgressValue} from './helper';
const tt = __;
export default defineComponent({
	components: {
		FloatComponent,
		AgGridVue,
		SparklineCellRenderer,
	},
	props: {
		structureDescendants: Object as PropType<StructureItemFormat[]>,
		project: String,
		period: Object as PropType<Period>,
		progressValue: Object as PropType<BoqValue[]>,
	},
	setup(props, context) {
		const smallMeta = useMetaQuery();
		const {appContext} = getCurrentInstance() || {};
		const gridApi = ref(null);
		const onGridReady = (params:any) => {
			gridApi.value = params.api;
		};
		const autoGroupColumn = ref({
			headerName: tt('Guigu Boq Item'),
			flex: 1,
			minWidth: 230,
			enableRowGroup: false,
			editable: false,
			field: 'name',
			filter: false,
			sortable: false,
			pinned: 'left',
		});
		const columnDefs = computed(()=>getColumns(rowData.value));
		const rowData = computed(()=>getProgressValue(props.structureDescendants || [], props.progressValue || []));

		function getDataPath(data:any) {
			return data.ancestry;
		}
		const defaultColDef = {
			sortable: true,
			filter: true,
			flex: 1,
			resizable: true,
		};
		function getRowId(params:any) {
			return params.data.id;
		}

		return {
			rowData,
			onGridReady,
			autoGroupColumn,
			columnDefs,
			getDataPath,
			defaultColDef,
			getRowId,
			smallMeta,
			zhCN,
		};
	},
});
</script>
<style lang="less" scoped>
.week-plan_table {
	width: 100%;
	height: 100%;
}
</style>
