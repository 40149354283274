import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-94a65612"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-container" }
const _hoisted_2 = { class: "tree-container" }
const _hoisted_3 = { class: "tree-toolbar" }
const _hoisted_4 = { class: "aggrid-container ag-theme-guigu" }
const _hoisted_5 = {
  key: 0,
  class: "create-btn"
}
const _hoisted_6 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElLink = _resolveComponent("ElLink")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives((_openBlock(), _createBlock($setup["ElDialog"], {
      modelValue: $setup.dialogVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.dialogVisible) = $event)),
      onClose: $setup.onCancel,
      title: "关联数据",
      class: "tree-dialog",
      width: "min(600px, max(min(300px, 100%), 50%))"
    }, {
      footer: _withCtx(() => [
        ($setup.showCreate)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
              _createVNode(_component_ElLink, {
                class: "cus-link-btn",
                type: "primary",
                onClick: $setup.create
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(`+新建${$setup.tt($props.doctypeMeta.name)}`), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("span", _hoisted_6, [
          _createVNode($setup["ElButton"], { onClick: $setup.onCancel }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode($setup["ElButton"], {
            type: "primary",
            onClick: $setup.save
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('Confirm')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filterLine, (f) => {
            return (_openBlock(), _createBlock($setup["FilterInput"], {
              key: f.fieldname,
              class: "filter-input",
              size: "small",
              meta: $props.doctypeMeta,
              name: f.fieldname,
              label: f.label,
              type: f.fieldtype,
              options: f.options,
              condition: f.condition || '=',
              modelValue: $setup.params[f.fieldname],
              "onUpdate:modelValue": $event => (($setup.params[f.fieldname]) = $event)
            }, null, 8 /* PROPS */, ["meta", "name", "label", "type", "options", "condition", "modelValue", "onUpdate:modelValue"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", null, _toDisplayString($setup.tt('Please Select:')), 1 /* TEXT */),
            _createVNode($setup["ElButtonGroup"], { disabled: $setup.loading }, {
              default: _withCtx(() => [
                _createVNode($setup["ElButton"], {
                  disabled: $setup.loading,
                  onClick: $setup.fold,
                  round: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($setup.tt('Collapse Level')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["disabled"]),
                _createVNode($setup["ElButton"], {
                  disabled: $setup.loading,
                  onClick: $setup.expand,
                  round: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($setup.tt('Expand')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["disabled"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode($setup["AgGridVue"], {
              class: "aggrid",
              columnDefs: [],
              rowData: $setup.rowData,
              defaultColDef: $setup.defaultColDef,
              animateRows: "true",
              onGridReady: $setup.onGridReady,
              treeData: "",
              scroll: "",
              groupDefaultExpanded: "-1",
              getDataPath: $setup.getDataPath,
              domLayout: "autoHeight",
              autoGroupColumnDef: $setup.autoGroupColumn,
              localeText: $setup.zhCN,
              onRowSelected: $setup.rowSelected,
              onRowClicked: $setup.rowClicked,
              rowSelection: "single",
              suppressRowClickSelection: false,
              suppressDragLeaveHidesColumns: $setup.smallMeta,
              onRowDataUpdated: $setup.onRowDataUpdated
            }, null, 8 /* PROPS */, ["rowData", "autoGroupColumnDef", "localeText", "suppressDragLeaveHidesColumns"])
          ]),
          _createVNode($setup["ElPagination"], {
            class: "pagination",
            layout: "sizes, total, prev, pager, next",
            total: $setup.total,
            pageSize: $setup.pageSize,
            pageSizes: $setup.pageSizes,
            currentPage: $setup.page,
            small: $setup.small,
            onSizeChange: $setup.handleSizeChange,
            onCurrentChange: $setup.pageChange
          }, null, 8 /* PROPS */, ["total", "pageSize", "currentPage", "small"])
        ])), [
          [_directive_loading, $setup.loading]
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])), [
      [_directive_loading, $setup.dialogLoading]
    ])
  ]))
}