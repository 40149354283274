<template>
	<div class="inheritable-organization" v-loading="loading">
		<div class="btn-container">
			<ElButton v-if="permissions.createPermission" type="primary" @click="createInherit">
				{{ tt('Inheritance organization') }}
			</ElButton>
		</div>
		<ElTable :data="inheritList" border style="width: 100%" height="100%" :empty-text="tt('No Data')">
			<ElTableColumn prop="inherit_from_organization_doc.label" :label="tt('Inherited from')"></ElTableColumn>
			<ElTableColumn prop="visible" :label="tt('Seen')" width="60">
				<template #default="scope">
					{{ scope.row.visible ? tt('Yes') : tt('No') }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="viewable" :label="tt('Viewable')" width="60">
				<template #default="scope">
					{{ scope.row.viewable ? tt('Yes') : tt('No') }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="addible" :label="tt('Addible')" width="60">
				<template #default="scope">
					{{ scope.row.addible ? tt('Yes') : tt('No') }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="editable" :label="tt('Editable')" width="60">
				<template #default="scope">
					{{ scope.row.editable ? tt('Yes') : tt('No') }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="deletable" :label="tt('Deletable')" width="60">
				<template #default="scope">
					{{ scope.row.deletable ? tt('Yes') : tt('No') }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="manageable" :label="tt('Manageable')" width="60">
				<template #default="scope">
					{{ scope.row.manageable ? tt('Yes') : tt('No') }}
				</template>
			</ElTableColumn>

			<ElTableColumn v-if="
				permissions.writePermission || permissions.deletePermission
			" prop="address" :label="tt('Action')" width="130">
				<template #default="scope">
					<ElButton v-if="permissions.writePermission" type="primary" @click="editInherit(scope.row)">{{ tt('Edit') }}
					</ElButton>
					<ElButton v-if="permissions.deletePermission" type="danger" @click="deleteInherit(scope.row)">{{ tt('Delete')
						}}</ElButton>
				</template>
			</ElTableColumn>
		</ElTable>
	</div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';
import {
	ElButton,
	ElTree,
	ElTable,
	ElTableColumn,
	vLoading,
} from 'element-plus';

import type { InheritOrganization, Permissions } from '../type';

const tt = __;

interface Props {
	organization: string;
	permissions: Permissions;
}
const props = defineProps<Props>();
const inheritList = ref<InheritOrganization[]>([]);
const loading = ref<boolean>(false);

watch(
	() => props.organization,
	() => {
		getInherits();
	},
	{ immediate: true },
);

async function getInherits() {
	if (!props.organization) {
		return;
	}
	loading.value = true;
	const res = await frappe.call<{ message: InheritOrganization[]; }>({
		method: 'tianjy_organization.tianjy_organization.page.tianjy_organization_config.tianjy_organization_config.get_inherit',
		args: {
			organization_name: props.organization,
		},
	});
	inheritList.value = res?.message || [];
	loading.value = false;
}

function createInherit() {
	const newDoc = frappe.model.make_new_doc_and_get_name(
		'Tianjy Organization Inheritable',
	);
	frappe.model.set_value(
		'Tianjy Organization Inheritable',
		newDoc,
		'organization',
		props.organization,
	);
	frappe.set_route(['form', 'Tianjy Organization Inheritable', newDoc]);
}

function editInherit(inheritOrganization: InheritOrganization) {
	frappe.set_route([
		'form',
		'Tianjy Organization Inheritable',
		inheritOrganization.name,
	]);
}
function deleteInherit(inheritOrganization: InheritOrganization) {
	ElMessageBox.confirm(__('Are you sure you want to delete this person?'), __('please confirm'), {
		confirmButtonText: __('Sure'),
		cancelButtonText: __('Cancel'),
		type: 'warning',
	})
		.then(async () => {
			loading.value = true;
			await frappe.db.delete_doc(
				'Tianjy Organization Inheritable',
				inheritOrganization.name,
			);
			getInherits();
			ElMessage({
				type: 'success',
				message: __('Delete Successful'),
			});
		})
		.catch(() => {
			ElMessage({
				type: 'info',
				message: __('Cancel Delete'),
			});
		});
}

frappe.socketio.doctype_subscribe('Tianjy Organization Inheritable');

frappe.realtime.on('list_update', p => {
	if (p.doctype !== 'Tianjy Organization Inheritable') {
		return;
	}
	getInherits();
});

// const popstateListener = function (event:any) {
// 	getInherits();
// };
// onMounted(() => {
// 	window.addEventListener('popstate', popstateListener);
// });
// onUnmounted(() => {
// 	window.removeEventListener('popstate', popstateListener);
// });
</script>

<style lang="less" scoped>
.inheritable-organization {
	height: 100%;
	display: flex;
	flex-direction: column;

	.btn-container {
		text-align: right;
		margin-bottom: 8px;
	}
}
</style>
