
<template>
	<div class="footer">
		<span @click="clickNow">{{TODAY_LABEL[props.periodType]}}</span>
	</div>
</template>
<script lang="ts" setup>
import {ref, defineProps, defineEmits} from 'vue';

const TODAY_LABEL={
	'Year': __('Current year'),
	'Year Month':__('Current month'),
	'Year Week':__('Current week'),
};
interface Props{
	periodType:'Year'|'Year Month'|'Year Week'
}
const props = defineProps<Props>();
interface Emit{
	(e:'clickNow'):void
}
const emit = defineEmits<Emit>();

async function clickNow(){
	emit('clickNow');
}
</script>
<style lang="less" scoped>
.footer {
	border-top: 1px solid #f0f0f0;
	color: #409eff;
	cursor: pointer;
	text-align: center;
	padding: 8px 0;
}
</style>
