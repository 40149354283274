import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { ListConfiguration } from './types';

export default function useAggregation(
	view: ComputedRef<GlobalView.View | undefined>,
	configuration: ComputedRef<ListConfiguration>,
	titleDocField: ComputedRef<locals.DocField | null>,
	docMeta: ComputedRef<locals.DocType>,
	data: ComputedRef<any[]>,
) {
	return computed(() => {
		if (!configuration.value?.showSummaryRow) { return; }
		const meta = docMeta.value;
		const values = data.value;
		const titleField = titleDocField.value?.fieldname || 'name';
		const doctype = meta.name;
		const allFields = new Set((view.value?.fields || []).map(v => v.fieldOptions.fieldname));
		const fields = meta.fields.filter(df => {
			if (df.is_virtual) { return false; }
			const { fieldname } = df;
			if (fieldname === titleField || !allFields.has(fieldname)) { return false; }
			if (!frappe.perm.has_perm(doctype, df.permlevel, 'read')) { return false; }
			return ['Currency', 'Int', 'Float'].includes(df.fieldtype);
		}).map(v => v.fieldname);
		const agg: Record<string, any> = Object.fromEntries(fields.map(k => [
			k,
			values.map(v => Number(v[k]) || 0).reduce((a, b) => a + b, 0),
		]));
		agg.__IS_AGGREGATION__ = true;
		return agg;
	});
}
