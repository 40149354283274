<template>
	<div class="custom-btn-group">
		<button type="button" class="btn btn-default btn-sm ellipsis"
			data-toggle="dropdown" aria-expanded="false">
			<span class="hidden-xs">
				<svg class="icon icon-sm">
					<use class="" href="#icon-list" />
				</svg>
				<span class="custom-btn-group-label">{{currentView}}</span>
				<svg class="icon icon-xs">
					<use class="" href="#icon-select" />
				</svg>
			</span>
			<span class="visible-xs">
				<svg class="icon icon-sm">
					<use class="" href="#icon-list" />
				</svg>
			</span>
		</button>
		<ul class="dropdown-menu" role="menu">
			<li v-for="[name, action, icon] in views" :key="name">
				<a class="grey-link dropdown-item" href="#"
					@click.prevent="action">
					<span class="menu-item-icon">
						<svg class="icon icon-sm">
							<use class="" :href="'#icon-' + icon" />
						</svg>
					</span>
					<span class="menu-item-label">{{name}}</span>
				</a>
			</li>
		</ul>
	</div>

</template>

<script setup lang="ts">

import { inject } from 'vue';

import allViews from '../../extends/Views';
const icon_map = {
	Image: 'image-view',
	List: 'list',
	Report: 'small-file',
	Calendar: 'calendar',
	Gantt: 'gantt',
	Kanban: 'kanban',
	Dashboard: 'dashboard',
	Map: 'map',
};


function setup_kanban_boards(doctype: string) {
	function fetch_kanban_board() {
		frappe.db.get_value(
			'Kanban Board',
			{ reference_doctype: doctype },
			'name',
			board => {
				if (!$.isEmptyObject(board)) {
					frappe.set_route('list', doctype, 'kanban', board.name);
				} else {
					frappe.views.KanbanView.show_kanban_dialog(doctype);
				}
			}
		);
	}

	const last_opened_kanban =
		frappe.model.user_settings[doctype].Kanban?.last_kanban_board;
	if (!last_opened_kanban) {
		fetch_kanban_board(doctype);
	} else {
		frappe.db.exists('Kanban Board', last_opened_kanban).then(exists => {
			if (exists) {
				frappe.set_route('list', doctype, 'kanban', last_opened_kanban);
			} else {
				fetch_kanban_board(doctype);
			}
		});
	}
}

function setup_views(
	ctx: frappe.guigu.ViewContext,
) {
	const {
		currentView,
		doctype,
		settings,
		meta,
		switchView,
	} = ctx;
	const defaultViews = {
		List: {
			condition: true,
			action: () => switchView('list'),
		},
		Report: {
			condition: true,
			action: () => switchView('report'),
		},
		Dashboard: {
			condition: true,
			action: () => switchView('dashboard'),
		},
		Calendar: {
			condition: frappe.views.calendar[doctype],
			action: () => switchView('calendar', 'default'),
		},
		Gantt: {
			condition: frappe.views.calendar[doctype],
			action: () => switchView('gantt'),
		},
		Inbox: {
			condition: doctype === 'Communication' && frappe.boot.email_accounts.length,
			action: () => switchView('inbox'),
		},
		Image: {
			condition: meta.image_field,
			action: () => switchView('image'),
		},
		Tree: {
			condition:
				frappe.treeview_settings[doctype] ||
				frappe.get_meta(doctype).is_tree,
			action: () => switchView('tree'),
		},
		Kanban: {
			condition: doctype != 'File',
			action: () => setup_kanban_boards(doctype),
		},
		Map: {
			condition:
				settings.get_coords_method ||
				(meta.fields.find(i => i.fieldname === 'latitude') &&
					meta.fields.find(i => i.fieldname === 'longitude')) ||
				meta.fields.find(
					i => i.fieldname === 'location' && i.fieldtype == 'Geolocation'
				),
			action: () => switchView('map'),
		},
	};
	const baseViews = Object.entries(defaultViews).filter(([name, view]) => currentView !== name && view.condition).map(([name, view]) => {

		if (doctype == 'File' && name == 'List') {
			name = 'File';
		}
		return [__(name), view.action, icon_map[name] || 'list'];

	});


	for (const [name, t] of Object.entries(allViews)) {
		if (currentView === name) { continue; }
		const options = t?.getOption ? t.getOption(ctx) : {};
		if (!options) { continue; }
		baseViews.push([
			__(options.title || t?.title || frappe.utils.to_title_case(name)),
			options.action || (() => switchView(name)),
			icon_map[name] || 'list',
		]);
	}

	return baseViews;
}


const ctx = (inject<() => frappe.guigu.ViewContext>('__guigu__define__view__')!)();
const currentView = __(frappe.utils.to_title_case(ctx.currentView));
const views = setup_views(ctx);
</script>
