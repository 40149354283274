<template>
	<div class="fileBoxWrapper">
		<div class="fileBox">
			<img class="imgbox" :src="photo.image" />
			<ElTooltip placement="top" :content="photo.image_name">
				<div class="image-name">{{ photo.image_name }}</div>
			</ElTooltip>

			<ElDropdown class="dropdown-container">
				<ElButton class="iconBall right" type="primary" :icon="MoreFilled"
					circle />
				<template #dropdown>
					<ElDropdownMenu>
						<ElDropdownItem @click="download">{{ tt("Download") }}</ElDropdownItem>
						<ElDropdownItem class="delete">
							<div>
								<ElPopconfirm
									:title="tt('Are you sure you want to delete this picture?')"
									@confirm="deletePhoto"
									:confirmButtonText="tt('Confirm')"
									:cancelButtonText="tt('cancel')">
									<template #reference>
										<ElButton link type="danger">{{ tt('Delete') }}</ElButton>
									</template>
								</ElPopconfirm>
							</div>
						</ElDropdownItem>
					</ElDropdownMenu>
				</template>
			</ElDropdown>
		</div>
	</div>
</template>
<script lang="ts" setup>
import {defineProps, toRefs} from 'vue';
import {Delete, MoreFilled, Download} from '@element-plus/icons-vue';

import saveAs from '../../../utils';

import {Photo} from './type';
const tt = __;
interface Emits{
	(e:'showPhotoInfo', photo:Photo):void
	(e:'deletePhoto', photo:Photo):void
}
const emit = defineEmits<Emits>();

interface Props{
	photo:Photo,
}
const props = defineProps<Props>();

const {photo} = toRefs(props);

function download() {
	if (!photo.value.image) {
		return;
	}
	saveAs(photo.value.image, photo.value.image_name!);
}

function showPhotoInfo() {
	emit('showPhotoInfo', photo.value);
}
function deletePhoto() {
	emit('deletePhoto', photo.value);
}
</script>
<style lang="less" scoped>
.fileBoxWrapper {
	display: inline-block;
	width: 132px;
	height: 152px;
}

.imgbox {
	display: block;
	width: 84px;
	height: 84px;
	object-fit: cover;
	overflow: hidden;
	overflow: hidden;
	border-radius: 4px;
}

.image-name {
	width: 116px;
	margin-top: 8px;
	overflow: hidden;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	word-wrap: break-word;
	word-break: break-all;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.fileBox {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 132px;
	padding: 16px 8px 8px 8px;
	border: 1px solid white;
	border-radius: 6px;
	cursor: pointer;

	.name {
		width: 116px;
		margin-top: 8px;
		overflow: hidden;
		font-size: 14px;
		line-height: 18px;
		text-align: center;
		word-wrap: break-word;
		word-break: break-all;
	}

	.dropdown-container {
		position: absolute;
		top: 6px;
		right: 0px;
		display: block;
	}

	.iconBall {
		position: absolute;
		width: 32px;
		height: 32px;
		color: white;
		font-size: 16px;
		line-height: 32px;
		text-align: center;
		background-color: #e6f7ff;
		border-radius: 16px;
		padding: 0;
		transform: rotate(-90deg);

		&.left {
			top: 6px;
			left: 6px;
			display: block;
			opacity: 0;
			background-color: #91d5ff;
		}

		&.right {
			top: 6px;
			right: 6px;
			display: block;
			opacity: 0;
			background-color: #91d5ff;
		}
	}

	&:hover {
		background-color: #f0f6ff;
		border: 1px solid #f0f6ff;

		.iconBall {
			&.right {
				display: block;
				opacity: 0.8;

				&:hover {
					background-color: #1890ff;
					opacity: 1;
				}
			}
		}
	}
}
</style>
