<template>
	<div class="detail-container">
		<a :href="href" @click="click">
			<ElButton class="to_detail" size="small" type="default">详情</ElButton>
		</a>
		<div class="detail-item" v-for="field in fields">
			<span
				class="label"
				:style="[blockType.includes(field.fieldtype) ? blockCss : {}, field.labelStyle]">
				{{ tt(field.label || '') }}:
			</span>
			<span class="value">
				<Component :is="field.component" :field="field.field"
					:format="field.format"
					:data="detail" :value="detail[field.fieldname]">
				</Component>
			</span>
		</div>
	</div>
</template>
<script lang="ts" setup>
import {computed, Component} from 'vue';

import useDocHref from '../utils/useDocHref';
import {default as Check} from '../FieldRenderer/Check.vue';
import {default as Color} from '../FieldRenderer/Color.vue';
import {default as Currency} from '../FieldRenderer/Currency.vue';
import {default as Date} from '../FieldRenderer/Date.vue';
import {default as Duration} from '../FieldRenderer/Duration.vue';
import {default as File} from '../FieldRenderer/File.vue';
import {default as GuiguDate} from '../FieldRenderer/GuiguDate.vue';
import {default as Link} from '../FieldRenderer/Link.vue';
import {default as Icon} from '../FieldRenderer/Icon.vue';
import {default as Image} from '../FieldRenderer/Image.vue';
import {default as LastComment} from '../FieldRenderer/LastComment.vue';
import {default as Rating} from '../FieldRenderer/Rating.vue';
import {default as Signature} from '../FieldRenderer/Signature.vue';

import {BaseValue, Percent, Time, Table} from './DetailValue';


const blockType = ['Long Text', 'Markdown Editor', 'Small Text', 'Table', 'Text', 'Text Editor'];
const blockCss = {display: 'block'};
const tt = __;
interface Props {
	detail: Record<string, any>,
	meta: locals.DocType
	fields: GlobalView.DisplayField[];
	linkField?: string;
	preventClick(e: Event, data: any): void;
}
const props = defineProps<Props>();

function getValueByType({fieldtype}: { fieldtype: string }) {
	let valueComponent: Component = BaseValue;
	switch (fieldtype) {
		case 'Check': valueComponent = Check; break;
		case 'Color': valueComponent = Color; break;
		case 'Currency': valueComponent = Currency; break;
		case 'Duration': valueComponent = Duration; break;
		case 'Date': valueComponent = Date; break;
		case 'Icon': valueComponent = Icon; break;
		case 'Link': valueComponent = Link; break;
		case 'Tree Select': valueComponent = Link; break;
		case 'Tianjy Related Link': valueComponent = Link; break;
		case 'Percent': valueComponent = Percent; break;
		case 'Signature': valueComponent = Signature; break;
		case 'Time': valueComponent = Time; break;
		case 'Table': valueComponent = Table; break;
		case 'Rating': valueComponent = Rating; break;
		case 'Attach':
		case 'Attach Image': valueComponent = File; break;
		case 'Image': valueComponent = Image; break;
		case 'Guigu Date': valueComponent = GuiguDate; break;
		case '__LastComment__': valueComponent = LastComment; break;
		default: valueComponent = BaseValue;
	}

	return valueComponent;
}

interface Detail {
	label?: string;
	fieldname: string;
	fieldtype: string;
	field: GlobalView.FieldRenderOptions;
	labelStyle: any;
	format?: string;
	component: Component
}
const fields = computed(() => {
	const labelStyleMap = frappe.views.timeline[props.meta.name]?.label_style_map || {};

	return props.fields.map(({field, format}) => {
		const {fieldname} = field;
		if (fieldname === '__LastComment__') {
			if (!props.detail._comments) {
				return;
			}
			return {
				label: tt('Last Comment'),
				fieldname: '_comments',
				field,
				fieldtype: '__LastComment__',
				component: LastComment,
			};
		}
		let key = fieldname;
		if (field.fieldtype === 'Image') {
			const {options} = field;
			if (!options) {
				return;
			}
			const optionField = props.meta.fields.find(item => item.fieldname === options);
			if (!optionField) {
				return;
			}
			key = optionField.fieldname;
		}
		if (!key) {
			return;
		}
		const value = props.detail[key];
		if (!value) {
			return;
		}

		return {
			label: field.label,
			fieldname,
			fieldtype: field.fieldtype,
			labelStyle: labelStyleMap[fieldname],
			format: format,
			field,
			component: getValueByType(field),
		};
	}).filter(Boolean) as Detail[];
});

const meta = computed(() => props.meta);
const data = computed(() => props.detail);
const linkField = computed(() => props.linkField);
const href = useDocHref(meta, data, linkField);
function click(e: Event) {
	props.preventClick(e, data.value);
}
</script>
<style lang="less" scoped>
.detail-container {
	position: relative;
	border-radius: 8px;
	margin-right: 8px;
	padding: 8px;
	padding-top: 0;

	&:hover {
		background: #f0f2f5;

		.to_detail {
			opacity: 1;
		}
	}

	.to_detail {
		position: absolute;
		right: 10px;
		opacity: 0;
	}

	.detail-item {
		margin: 4px 0;

		.label {
			margin-right: 8px;
			font-weight: bolder;
		}

		.value {
			flex: 1;
			white-space: pre-wrap;
		}
	}
}
</style>
