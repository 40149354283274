<template>
	<div class="page">
		<Page :mainStyle="{display:'flex', flexDirection:'column'}">
			<template #title >
				<h3 class="title">{{ tt("Project Work Daily Log Attendance Summary") }}</h3>
			</template>
			<template #tools>
				<ToolBar
					v-model:project="project"
					v-model:monthDate="monthDate"
					v-model:yearDate="yearDate"
					v-model:pageType="pageType"
					v-model:countCategory="countCategory"
					v-model:refreshValue="refreshValue"
					v-model:loading="loading"
					:size="size"
					:detailGridApi="detailGridApi">
				</ToolBar>
			</template>
			<div class="container" ref="containerRef">
				<SummaryTable
					:pageType="pageType"
					:countCategory="countCategory"
					:monthDate="monthDate"
					:yearDate="yearDate"
					:project="project"
					:summaryTableData="summaryTableData"
					:attendanceTypes="attendanceTypes"
					:loading="loading"
					:isPhone="size"
					@onDetailGridReady="onDetailGridReady">
				</SummaryTable>
			</div>
			<AttendanceType
				v-if="project && countCategory == 'date' && pageType == 'month'"
				:project="project"
				:attendanceTypes="attendanceTypes" />

		</Page>
	</div>
</template>

<script lang="ts" setup>
import {onMounted, onUnmounted, ref, watch} from 'vue';
import {GridApi} from 'ag-grid-community';

import Page from '../../../../components/page/index.vue';
import {SummaryMessage, SummaryTableData} from '../type';

import ToolBar from './components/ToolBar.vue';
import SummaryTable from './components/SummaryTable.vue';
import AttendanceType from './components/AttendanceType.vue';
import {dailyRecordDataProcessing} from './components/helper';
import { ElLoading } from 'element-plus'

const tt = __;
const project = ref<string>('');
const monthDate = ref<string>();
const yearDate = ref<string>();
const pageType = ref<'month' | 'year'>('month');
const countCategory = ref<'date' | 'category'>('date');
const refreshValue = ref<number>(0);
const attendanceTypes = ref([]);

const loading = ref<boolean>(false);

const summaryTableData = ref<SummaryTableData[]>([]);
const detailGridApi = ref<GridApi>();

const size = ref<'' | 'small' | 'default' | 'large' | undefined>('small');
const containerRef = ref<HTMLDivElement|undefined>()

function updateComponentSize() {
	const widthElement = window.innerWidth;
	if (widthElement <= 640) {
		size.value = 'default';
	} else {
		size.value = 'small';
	}
}


function onDetailGridReady(params: any) {
	detailGridApi.value = params;
}

async function getAttendanceType() {
	const projectAttendanceType = await frappe.db.get_list(
		'Guigu Pm Daily Record Attendance Type',
		{
			filters: [],
			fields: ['*'],
			limit: 0,
		},
	);
	attendanceTypes.value = projectAttendanceType;
}

watch([project, pageType, countCategory, yearDate, monthDate, refreshValue], async () => {
	if (!yearDate.value || !project.value) {
		return;
	}
	loading.value = true;

	if (countCategory.value === 'category') {
		const data = await frappe.call<{ message: SummaryMessage }>({
			method: 'guigu_pm.guigu_pm_daily_record.page.project_daily_record_statistics.project_daily_record_statistics.get_attendance_dairy',
			args: {
				project: project.value,
				type: pageType.value,
				date: pageType.value === 'month' ? monthDate.value : yearDate.value,
			},
		});
		const result = dailyRecordDataProcessing(
			data?.message?.daily_record || [],
			'year',
			data?.message?.member_sort || [],
		);
		summaryTableData.value = result;
	} else {
		const data = await frappe.call<{ message: SummaryMessage }>({
			method: 'guigu_pm.guigu_pm_daily_record.page.project_daily_record_statistics.project_daily_record_statistics.get_organization_dairy',
			args: {
				project: project.value,
				type: pageType.value,
				date: pageType.value === 'month' ? monthDate.value : yearDate.value,
			},
		});
		const result = dailyRecordDataProcessing(
			data?.message?.daily_record || [],
			pageType.value,
			data?.message?.member_sort || [],
		);
		summaryTableData.value = result;
	}
	await getAttendanceType();
	loading.value = false;
});

let loadingInstance:any
watch(loading,()=>{
	if(loading.value){
		loadingInstance = ElLoading.service({
			target:containerRef.value,
		})
	}else{
		loadingInstance?.close()
	}
},{immediate:true})
onMounted(() => {
	window.addEventListener('resize', updateComponentSize);
});

onUnmounted(() => {
	window.removeEventListener('resize', updateComponentSize);
});

</script>
<style lang="css" scoped>
.container {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding:0;
}

:deep(.toggle-btn){
	display: none;
}

:deep(.tools){
	padding-top: 0 !important
}

.title {
	height: 75px;
	line-height: 75px;
	margin-bottom: 0;
}
</style>
