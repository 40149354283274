import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElSelect"], {
    modelValue: $setup.cond,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.cond) = $event)),
    teleported: false
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.conditions, (c) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: c.value
        }, [
          ($setup.validConditions.has(c.value))
            ? (_openBlock(), _createBlock($setup["ElOption"], {
                key: 0,
                label: c.label,
                value: c.value
              }, null, 8 /* PROPS */, ["label", "value"]))
            : _createCommentVNode("v-if", true)
        ], 64 /* STABLE_FRAGMENT */))
      }), 64 /* STABLE_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}