import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElButton = _resolveComponent("ElButton")
  const _component_ElTooltip = _resolveComponent("ElTooltip")

  return (_openBlock(), _createElementBlock("div", null, [
    ($setup.props.params.onLink)
      ? (_openBlock(), _createBlock(_component_ElTooltip, {
          key: 0,
          content: $setup.tt('Related BOQ'),
          placement: "top"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElButton, {
              type: "primary",
              icon: $setup.Link,
              onClick: $setup.clickLink
            }, null, 8 /* PROPS */, ["icon"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"]))
      : _createCommentVNode("v-if", true),
    ($setup.props.params.onRemove)
      ? (_openBlock(), _createBlock(_component_ElTooltip, {
          key: 1,
          content: $setup.tt('Delete'),
          placement: "top"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElButton, {
              type: "danger",
              icon: $setup.Delete,
              onClick: $setup.click
            }, null, 8 /* PROPS */, ["icon"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"]))
      : _createCommentVNode("v-if", true)
  ]))
}