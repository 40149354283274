<template>
	<div class="organization-container">
		<el-tree
			ref='treeRef'
			v-loading="props.organizationLoading"
			class="organization-tree"
			:data="organizationTree"
			:props="optionProps"
			node-key="id"
			:expand-on-click-node="false"
			:default-expanded-keys="rootNodeIds"
			:current-node-key="organization"
			@node-click="nodeClick"
			highlight-current />
	</div>
</template>
<script lang="ts" setup>
import { ref, defineProps, computed, watch } from 'vue';

import { list2Tree } from '../../../../utils';
import { Organization } from '../../type';

import { routeInit, setRoute } from './helper';
const treeRef = ref();
interface Props {
	organization: string
	organizations: Organization[]
	organizationLoading: boolean
}
const props = defineProps<Props>();

interface Emit {
	(e: 'update:organization', organization: string): void,
}
const emit = defineEmits<Emit>();

const paramsFilter = ref<{ [key: string]: string }>({});
const organizationTree = ref<any>({});
const selectedKey = ref<string>('');
const optionProps = {
	label: 'label',
	children: 'children',
};

watch(() => props.organizations, () => {
	routeInit(paramsFilter);

	const organizationNames = props.organizations.map(item => item.name);
	let defaultOrganization: string = '';
	if (organizationNames.includes(paramsFilter.value?.organization)) {
		defaultOrganization = paramsFilter.value?.organization;
	} else {
		defaultOrganization = (props.organizations || [])[0]?.name || '';
	}
	organizationTree.value = list2Tree(props.organizations);
	selectedKey.value = defaultOrganization;
	emit('update:organization', defaultOrganization);
	setRoute(paramsFilter, { organization: defaultOrganization });

}, { immediate: true });


const rootNodeIds = computed(() => {
	const ids: string[] = [];
	(organizationTree.value || []).forEach(item => {
		if (item.id !== 'all') {
			ids.push(item.id);
		}
	});
	return ids;
});

function nodeClick(organization: Organization, node: Node) {
	selectedKey.value = organization.id;
	emit('update:organization', organization.name);
	setRoute(paramsFilter, { organization: organization.name });
}
</script>
<style scoped>
.bolder {
	font-weight: bolder;
}

.organization-container {
	height: 100%;
	overflow-y: auto;
}
</style>
