import { FormatDiaryStructure, OriginDiaryStructure, StructureItemFormat, ProgressPlan } from '../type/index';

  export function formatStructure(structures:OriginDiaryStructure[]):FormatDiaryStructure[]{
	return structures.map(item=>({
			id:item.name,
			parent:item.parent_guigu_construction_structure,
			lft:item.lft,
			rgt:item.rgt,
			label:item.structure_name,
			isUnitStructure:item.is_unit_structure,
			code:item.code,
		}));
  }
