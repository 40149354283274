

import mountVueToPage from 'guiguLib/mountVueToPage';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn';

import DailyRecordStatistics from './daily_record_statistics/index.vue';
export default function loadProjectDailyRecordStatistics (wrapper:HTMLElement) {
	const app = mountVueToPage(wrapper, DailyRecordStatistics);
	app.use(ElementPlus, {locale: zhCn});
	frappe.router.on('change', () => {
		const [pageName] = frappe.router?.current_route || [];
		if (pageName !== 'project_daily_record_statistics') {
			delete frappe?.pages?.project_daily_record_statistics;
			app?.unmount();
		}
	});
}

frappe.provide('frappe.guigu_pm');
frappe.guigu_pm.guigu_pm_daily_record = frappe.guigu_pm.guigu_pm_daily_record || {};
frappe.guigu_pm.guigu_pm_daily_record.pages = frappe.guigu_pm.guigu_pm_daily_record.pages || {};
frappe.guigu_pm.guigu_pm_daily_record.pages.loadProjectDailyRecordStatistics = loadProjectDailyRecordStatistics;
