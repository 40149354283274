/* eslint-disable eqeqeq */
import { ICellRendererParams, CellClassParams, ValueGetterParams, ValueFormatterParams } from 'ag-grid-community';

import NP from '../../../../../../../../guigu_pm/guigu_pm/public/lib/number-precision/index.umd.js';

function checkPermission() {
  const allRoles = frappe?.user_roles || [];
  const permission = ['System Manager', 'Guigu Global Manager', 'Guigu Pc Sub Company Department Manager'];
  return permission.some((item) => allRoles.includes(item));
}

export const editCellStyle = { border: '1px solid #ccc' };

export function getWorkAssessmentSummaryColumns(year: string) {
  const monthColumns = [];
  for (let i = 1; i <= 12; i++) {
    monthColumns.push({
      headerName: __('{}Month', [i]),
      field: `${year}-${i < 10 ? `0${i}` : i}`,
      width: 100,
      minWidth: 100,
      editable: false,
      resizable: true,
    });
  }
  const columns = [
    {
      headerName: __('Employee User'),
      field: 'user_name',
      width: 150,
      minWidth: 120,
      editable: false,
      resizable: true,
      pinned: 'left',
    },
    {
      headerName: __('Average'),
      field: 'average',
      cellRenderer: 'FloatComponent',
      width: 150,
      minWidth: 120,
      editable: false,
      cellRendererParams: (params: ICellRendererParams) => {
        const { data } = params;
        return {
          value: data.summary ? NP.divide(Number(data.summary || 0), 12) : 0,
        };
      },
      pinned: 'left',
      resizable: true,
    },
    {
      headerName: __('Total'),
      field: 'summary',
      width: 150,
      minWidth: 120,
      editable: false,
      pinned: 'left',
      resizable: true,
    },
    ...monthColumns,
  ];
  return columns;
}

export function getWorkAssessmentDetailColumns(emit: any, layout: string) {
  const columns = [
    {
      headerName: __('Assessment Member'),
      field: 'user',
      width: 100,
      minWidth: 100,
      editable: false,
      filter: true,
      resizable: true,
      pinned: 'left',
      rowGroup: true,
      enableRowGroup: true,
      hide: true,
    },
    {
      headerName: __('Theme'),
      // flex: 1,
      minWidth: 230,
      // enableRowGroup: false,
      editable: false,
      field: 'subject',
      filter: false,
      sortable: false,
      resizable: true,
      pinned: 'left',
      cellRenderer: 'AgGridWorkAssessmentSubject',
      cellRendererParams: (params: any) => {
        const { value } = params;
        return {
          value,
          emit,
          layout,
        };
      },
    },
    {
      headerName: __('Type'),
      field: 'type_name',
      width: 120,
      minWidth: 120,
      editable: false,
      filter: true,
      resizable: true,
      enableRowGroup: true,
    },
    {
      headerName: __('Status'),
      field: 'status',
      width: 100,
      minWidth: 100,
      editable: false,
      filter: true,
      resizable: true,
      enableRowGroup: true,
      cellRenderer: 'AgGridWorkAssessmentStatus',
    },
    {
      headerName: __('Priority'),
      field: 'priority',
      width: 100,
      minWidth: 100,
      editable: false,
      filter: true,
      resizable: true,
      enableRowGroup: true,
      cellRenderer: 'AgGridWorkAssessmentPriority',
    },
    {
      headerName: __('Assigned User,'),
      field: 'assigner_user',
      width: 100,
      minWidth: 100,
      editable: false,
      filter: true,
      // pinned: 'left',
      enableRowGroup: true,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.node?.group && params.node.field == params.colDef.field) {
          if (params.colDef.enableValue) {
            return params.value;
          }
          return params.node.field ? params.node.allLeafChildren[0]?.data[params.node.field] || __('empty') : __('empty');
        }
      },
    },
    {
      headerName: __('Plan Start'),
      field: 'plan_start',
      width: 100,
      minWidth: 100,
      editable: false,
      filter: true,
      resizable: true,
    },
    {
      headerName: __('Plan Completed'),
      field: 'plan_end',
      width: 100,
      minWidth: 100,
      editable: false,
      filter: true,
      resizable: true,
    },

    {
      headerName: __('Check Month'),
      field: 'assessment_date',
      width: 100,
      minWidth: 100,
      filter: true,
      resizable: true,
      editable: false,
    },

    {
      headerName: __('Total Score'),
      field: 'total_score',
      width: 150,
      resizable: true,
      minWidth: 100,
      editable: () => checkPermission(),
      cellEditor: 'FloatEditor',
      cellStyle: (params: CellClassParams) => {
        const { data } = params;
        if (data?.id && checkPermission()) {
          return editCellStyle;
        }
        return;
      },
      enableValue: true,
      aggFunc: 'sum',
      valueGetter: (params: ValueGetterParams) => {
        try {
          return parseInt(params.data.total_score || '0');
        } catch {
          return null;
        }
      },
    },
    {
      headerName: __('Self Assessment'),
      field: 'self_assessment',
      width: 150,
      minWidth: 100,
      resizable: true,
      aggFunc: 'sum',
      editable: (params) => frappe?.user?.name === params?.data?.user_name,
      cellEditor: 'FloatEditor',
      cellStyle: (params: CellClassParams) => {
        const { data } = params;
        if (frappe?.user?.name === data?.user_name) {
          return editCellStyle;
        }
        return;
      },
      valueGetter: (params: ValueGetterParams) => {
        try {
          return parseInt(params.data.self_assessment || '0');
        } catch {
          return null;
        }
      },
    },
    {
      headerName: __('Leader Assessment'),
      field: 'leader_assessment',
      width: 150,
      minWidth: 100,
      resizable: true,
      editable: () => checkPermission(),
      cellEditor: 'FloatEditor',
      cellStyle: (params: CellClassParams) => {
        const { data } = params;
        if (data?.id && checkPermission()) {
          return editCellStyle;
        }
        return;
      },
      aggFunc: 'sum',
      valueGetter: (params: ValueGetterParams) => {
        try {
          return parseInt(params.data.leader_assessment || '0');
        } catch {
          return null;
        }
      },
    },
    {
      headerName: __('Last Comment'),
      field: 'content',
      width: 300,
      minWidth: 100,
      editable: false,
      resizable: true,
    },
    {
      headerName: __('Note'),
      field: 'assessment_comments',
      width: 100,
      minWidth: 100,
      editable: false,
      resizable: true,
    },
    {
      headerName: __('Assessment Criteria'),
      field: 'assessment_criteria',
      width: 100,
      minWidth: 100,
      resizable: true,
      editable: false,
    },
  ];
  return columns;
}
