<template>
	<ElButton
		type="text"
		size="small"
		@click="props.params.openCreateDialog(props.params.data)"
	>
		{{ tt('Create task') }}
	</ElButton>
</template>

<script setup lang="ts">
import {ElButton} from 'element-plus';
import type {ICellRendererParams} from 'ag-grid-community';

import type {TemplateCreateTask} from '../../../../../types';
const tt = __;

const props = defineProps<{
	params: ICellRendererParams & {
		openCreateDialog: (rowData: TemplateCreateTask) => void;
	}
}>();
</script>

<style lang="less" scoped></style>
