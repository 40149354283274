import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createBlock($setup["Timeline"], { onLoadMore: $setup.loadMore }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.photoLit, (item) => {
        return (_openBlock(), _createBlock($setup["photoItem"], {
          key: item.date,
          date: item.date,
          photos: item,
          onShowPhotoInfo: $setup.showPhotoInfo,
          onDeletePhoto: $setup.deletePhoto
        }, null, 8 /* PROPS */, ["date", "photos"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  })), [
    [_directive_loading, $setup.loading]
  ])
}