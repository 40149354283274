import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ( $props.meta && $props.name)
    ? (_openBlock(), _createBlock($setup["Main"], {
        key: 0,
        meta: $props.meta,
        name: $props.name,
        isHideClose: $props.isHideClose,
        isDialog: $props.isDialog,
        visible: $setup.formVisible,
        "onUpdate:visible": _cache[0] || (_cache[0] = $event => (($setup.formVisible) = $event)),
        onOnChange: $setup.onChange
      }, null, 8 /* PROPS */, ["meta", "name", "isHideClose", "isDialog", "visible"]))
    : _createCommentVNode("v-if", true)
}