import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, KeepAlive as _KeepAlive, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31db742a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.loaded)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(['global-layout', $setup.classes.skeletonClass])
        }, [
          _createElementVNode("div", _hoisted_1, [
            ($setup.sider.length)
              ? (_openBlock(), _createBlock($setup["SidebarToggleBtn"], {
                  key: 0,
                  modelValue: $setup.showSidebar,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.showSidebar) = $event))
                }, null, 8 /* PROPS */, ["modelValue"]))
              : _createCommentVNode("v-if", true),
            ($setup.isAdmin)
              ? (_openBlock(), _createBlock($setup["ElIcon"], {
                  key: 1,
                  title: $setup.tt('Add View'),
                  onClick: $setup.newView,
                  class: "add"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["Plus"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["title"]))
              : _createCommentVNode("v-if", true),
            ($setup.shown)
              ? (_openBlock(), _createBlock($setup["ElTabs"], {
                  key: 2,
                  class: "tabs",
                  modelValue: $setup.currentViewId,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.currentViewId) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.views, (view) => {
                      return (_openBlock(), _createElementBlock(_Fragment, null, [
                        ($setup.isAdmin || view.name === $setup.currentViewId || !view.hidden && (view.group === $setup.group || $setup.group === null))
                          ? (_openBlock(), _createBlock($setup["ElTabPane"], {
                              key: 0,
                              name: view.name
                            }, {
                              label: _withCtx(() => [
                                _createElementVNode("a", {
                                  href: view.search,
                                  onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
                                }, _toDisplayString(view.label || view.name), 9 /* TEXT, PROPS */, _hoisted_2),
                                ($setup.isAdmin && view.name === $setup.currentViewId)
                                  ? (_openBlock(), _createBlock($setup["ElDropdown"], {
                                      key: 0,
                                      onCommand: $event => ($setup.run($event, view))
                                    }, {
                                      dropdown: _withCtx(() => [
                                        _createVNode($setup["ElDropdownMenu"], null, {
                                          default: _withCtx(() => [
                                            _createVNode($setup["ElDropdownItem"], { command: "copy" }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(" 复制 ")
                                              ]),
                                              _: 1 /* STABLE */
                                            }),
                                            _createVNode($setup["ElDropdownItem"], { command: "edit" }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(" 编辑 ")
                                              ]),
                                              _: 1 /* STABLE */
                                            }),
                                            _createVNode($setup["ElDropdownItem"], { command: "move" }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(" 移动 ")
                                              ]),
                                              _: 1 /* STABLE */
                                            }),
                                            _createVNode($setup["ElDropdownItem"], { command: "setDefault" }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(" 设为默认 ")
                                              ]),
                                              _: 1 /* STABLE */
                                            }),
                                            _createVNode($setup["ElDropdownItem"], { command: "delete" }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(" 删除 ")
                                              ]),
                                              _: 1 /* STABLE */
                                            })
                                          ]),
                                          _: 1 /* STABLE */
                                        })
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode($setup["ElIcon"], null, {
                                          default: _withCtx(() => [
                                            _createVNode($setup["ArrowDown"])
                                          ]),
                                          _: 1 /* STABLE */
                                        })
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onCommand"]))
                                  : _createCommentVNode("v-if", true)
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["name"]))
                          : _createCommentVNode("v-if", true)
                      ], 64 /* STABLE_FRAGMENT */))
                    }), 256 /* UNKEYED_FRAGMENT */))
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"]))
              : _createCommentVNode("v-if", true),
            _createVNode($setup["RootMenu"], {
              meta: $props.meta,
              showDetailSwitcher: $setup.showDetailSwitcher,
              onAddView: $setup.newView,
              layout: $setup.layout,
              "onUpdate:layout": _cache[3] || (_cache[3] = $event => (($setup.layout) = $event)),
              canAdmin: $setup.canAdmin,
              isAdmin: $setup.isAdmin,
              "onUpdate:isAdmin": _cache[4] || (_cache[4] = $event => (($setup.isAdmin) = $event))
            }, null, 8 /* PROPS */, ["meta", "showDetailSwitcher", "layout", "canAdmin", "isAdmin"]),
            (!$setup.current?.view?.isHideAddBtn)
              ? (_openBlock(), _createBlock($setup["Add"], {
                  key: 3,
                  onCreate: $setup.create,
                  url: $setup.createUrl,
                  meta: $props.meta
                }, null, 8 /* PROPS */, ["url", "meta"]))
              : _createCommentVNode("v-if", true)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.views, (view) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: view.name
            }, [
              (view.configuration && view.view)
                ? (_openBlock(), _createBlock(_KeepAlive, { key: 0 }, [
                    ($setup.shown && view.name === $setup.currentViewId)
                      ? (_openBlock(), _createBlock($setup["Main"], {
                          key: 0,
                          meta: $setup.docMeta,
                          class: "body",
                          setting: $setup.globalView,
                          sider: $setup.sider,
                          toolbar: $setup.toolbar,
                          actions: $setup.actions,
                          mainComp: $setup.mainComp,
                          detailComp: $setup.detailComp,
                          rowActionComp: $setup.rowActionComp,
                          link: $setup.link,
                          layout: $setup.layout,
                          state: view,
                          view: view.view,
                          loading: view.loading,
                          showDetailSwitcher: $setup.showDetailSwitcher,
                          showDetail: $setup.showDetail,
                          configuration: view.configuration,
                          onUpdate: $event => (view.update($event))
                        }, null, 8 /* PROPS */, ["meta", "setting", "sider", "toolbar", "actions", "mainComp", "detailComp", "rowActionComp", "link", "layout", "state", "view", "loading", "showDetailSwitcher", "showDetail", "configuration", "onUpdate"]))
                      : _createCommentVNode("v-if", true)
                  ], 1024 /* DYNAMIC_SLOTS */))
                : ($setup.shown && view.name === $setup.currentViewId)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createCommentVNode("- TODO: 加载中 ")
                    ], 64 /* STABLE_FRAGMENT */))
                  : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
          }), 128 /* KEYED_FRAGMENT */))
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["Configuration"], {
      meta: $props.meta,
      view: $setup.editingView,
      configuration: $setup.editingConfiguration,
      visible: $setup.editing,
      "onUpdate:visible": _cache[5] || (_cache[5] = $event => (($setup.editing) = $event)),
      onUpdate: $setup.update
    }, null, 8 /* PROPS */, ["meta", "view", "configuration", "visible"])
  ], 64 /* STABLE_FRAGMENT */))
}