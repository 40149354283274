import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")

  return (_openBlock(), _createBlock(_component_el_tooltip, {
    effect: "light",
    content: $setup.value,
    placement: "top"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, _toDisplayString($setup.value), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["content"]))
}