import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-576fd71f"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (!$setup.allActions)
    ? (_openBlock(), _createBlock($setup["ElButton"], {
        key: 0,
        onClick: $setup.create,
        round: "",
        type: "primary"
      }, {
        default: _withCtx(() => [
          _createElementVNode("a", { href: $props.url }, _toDisplayString($setup.tt('Add')), 9 /* TEXT, PROPS */, _hoisted_1)
        ]),
        _: 1 /* STABLE */
      }))
    : (_openBlock(), _createBlock($setup["ElDropdown"], {
        key: 1,
        onCommand: $setup.command
      }, {
        dropdown: _withCtx(() => [
          _createVNode($setup["ElDropdownMenu"], null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.allActions, (a) => {
                return (_openBlock(), _createBlock($setup["ElDropdownItem"], {
                  command: a,
                  key: a.key
                }, {
                  default: _withCtx(() => [
                    (a.url && a.action)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          href: a.url
                        }, _toDisplayString(a.label), 9 /* TEXT, PROPS */, _hoisted_2))
                      : (a.url)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            href: a.url
                          }, _toDisplayString(a.label), 9 /* TEXT, PROPS */, _hoisted_3))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _createTextVNode(_toDisplayString(a.label), 1 /* TEXT */)
                          ], 64 /* STABLE_FRAGMENT */))
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["command"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          })
        ]),
        default: _withCtx(() => [
          _createVNode($setup["ElButton"], {
            round: "",
            type: "primary"
          }, {
            default: _withCtx(() => [
              _createElementVNode("a", null, _toDisplayString($setup.tt('Add')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }))
}