import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.pageSizes.length > 1)
    ? (_openBlock(), _createBlock($setup["ElSelect"], {
        key: 0,
        class: "limit",
        modelValue: $setup.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.value) = $event)),
        size: "small",
        disabled: $props.loading
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.pageSizes, (item) => {
            return (_openBlock(), _createBlock($setup["ElOption"], {
              key: item,
              label: `${item}条/页`,
              value: item
            }, null, 8 /* PROPS */, ["label", "value"]))
          }), 128 /* KEYED_FRAGMENT */)),
          ($props.maxLimit === Infinity)
            ? (_openBlock(), _createBlock($setup["ElOption"], {
                key: 0,
                label: "不分页",
                value: Infinity
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "disabled"]))
    : _createCommentVNode("v-if", true)
}