import type {CellParam, CellUpdate, Extension, CellComponent } from '../types';


const styles = {
	collapsible: 'nyloong-table-tree-collapsible',
	collapser: 'nyloong-table-tree-collapser',
	selectable: 'nyloong-table-selectable',
};
function createRender(render: (param: CellParam) => CellComponent) {
	return (p: CellParam): CellComponent => {
		const { rowApi } = p;
		const root = document.createElement('div');
		root.style.display = 'flex';
		root.classList.add('nyloong-table-tree');
		const collapser = root.appendChild(document.createElement('div'));
		collapser.className = styles.collapser;
		collapser.classList.add(styles.selectable);
		collapser.addEventListener('click', e => {
			rowApi.setCollapse();
		});
		rowApi.listen('collapseChange', c => {
			if (c) {
				root.classList.add('nyloong-table-tree-collapsed');
			} else {
				root.classList.remove('nyloong-table-tree-collapsed');
			}
		});
		if (rowApi.collapsed) {
			root.classList.add('nyloong-table-tree-collapsed');
		}

		function updateCollapser(row: CellUpdate) {
			root.style.setProperty(`--nyloong-table-tree-level`, String(row.level));
			if (row.children.length) {
				root.classList.add(styles.collapsible);
			} else {
				root.classList.remove(styles.collapsible);
			}
		}
		updateCollapser(p);
		const comp = render(p);
		root.appendChild(comp.root);
		return {
			...comp,
			root,
			destroy: comp.destroy.bind(comp),
			setHidden: comp.setHidden.bind(comp),
			update(row) { updateCollapser(row); return comp.update(row); },
		};
	};
}
const Tree: Extension = (_, update, api, next) => {
		const cc = next(update);
		return { ...cc, render: createRender(cc.render) };
	};

export default Tree;
