import Viewer from 'viewerjs';

import saveAs from '../../utils/saveAs.ts';
import imageExt from '../../utils/imageExt.ts';
import FormTimeline from '../../../../../../frappe/frappe/public/js/frappe/form/footer/form_timeline.js';
const old_setup_activity_toggle = FormTimeline.prototype.setup_activity_toggle;
FormTimeline.prototype.setup_activity_toggle = function () {
	let doc_info = this.doc_info || this.frm.get_docinfo();
	let has_communications = () => {
		let { communications } = doc_info;
		let { comments } = doc_info;
		return (communications || []).length || (comments || []).length;
	};
	if (has_communications()) {
		this.only_communication = true;
	}
	return old_setup_activity_toggle.apply(this, arguments);
};
const old_get_comment_timeline_content = FormTimeline.prototype.get_comment_timeline_content;
FormTimeline.prototype.get_comment_timeline_content = function (doc) {
	const comment_content = old_get_comment_timeline_content.apply(this, arguments);
	const comment_attachments_wrapper = $('<ul class="list-unstyled"></ul>');
	let exists = {};
	const image_ext = ['png', 'jpg', 'jpeg', 'gif'];
	let unique_attachments = doc.comment_attachments?.filter(attachment => Object.prototype.hasOwnProperty.call(exists, attachment.file_name)
		? false
		: (exists[attachment.file_name] = true));
	if (unique_attachments) {
		for (const attachment of unique_attachments) {
			this.add_attachment(attachment, comment_attachments_wrapper, doc);
		}
	}

	const images = unique_attachments?.filter(a => {
		const arr = a.file_url.split('.');
		const ext = arr[arr.length - 1].toLowerCase();
		return image_ext.includes(ext);
	});
	const image_wrapper = $('<div id="viewer" style="overflow:auto;white-space:nowrap;margin-bottom:8px;"></div>');
	if (images) {
		for (const i of images) {
			const file_url = get_file_url(i);
			image_wrapper.append($(`<img
			data-original="${file_url}"
			src="${file_url}"
			style="object-fit:cover;width:100px;height:100px;cursor:zoom-in;  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; margin:4px"
			></img>`));
		}
	}
	const image = new Viewer(image_wrapper[0]);
	comment_content.append(image_wrapper);
	comment_content.append(comment_attachments_wrapper);
	return comment_content;
};

function get_file_url(attachment) {
	let { file_url } = attachment;
	if (!file_url) {
		if (attachment.file_name.indexOf('files/') === 0) {
			file_url = `/${attachment.file_name}`;
		} else {
			file_url = `/files/${attachment.file_name}`;
		}
	}
	// hash is not escaped, https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURI
	return encodeURI(file_url).replace(/#/g, '%23');
}
FormTimeline.prototype.add_attachment = function add_attachment(attachment, comment_attachments_wrapper, doc) {
	let { file_name } = attachment;
	let file_url = get_file_url(attachment);
	let fileid = attachment.name;
	if (!file_name) {
		file_name = file_url;
	}

	let me = this;
	const arr = file_name.split('.');
	const ext = arr[arr.length - 1].toLowerCase();

	let file_label = `
		<a href="${file_url}" target="_blank" ${!imageExt.has(ext) && `download="${file_name}"`} title="${file_name}" class="ellipsis" style="max-width: calc(100% - 43px);">
			<span>${file_name}</span>
		</a>`;

	let remove_action = null;
	let rename_action = null;
	const hasDeletePermission = frappe.perm.has_perm(this.frm.doctype, 0, 'delete');
	const hasWritePermission = frappe.perm.has_perm(this.frm.doctype, 0, 'write');
	if (hasDeletePermission && hasWritePermission) {
		remove_action = function (target_id) {
			frappe.confirm(__('Are you sure you want to delete the attachment?'), function () {
				const all_attachments = me.frm.get_docinfo().attachments;
				let target_attachment = all_attachments
					.find(attachment => attachment.name === target_id);
				let to_be_removed = all_attachments
					.filter(
						attachment => attachment.file_name === target_attachment.file_name,
					);
				for (const attachment of to_be_removed) { me.remove_attachment(attachment.name, doc); }
			});
			return false;
		};
	}
	if (hasWritePermission) {
		rename_action = function (target_id) {
			const { name, file_name } = attachment;
			const ss = file_name.split('.');
			const s = ss.length > 1 && ss.pop() || '';
			const ext = s ? `.${s}` : '';
			const dialog = new frappe.ui.Dialog({
				title: __('Change File Name'),
				fields: [{
					label: __('New File Name'),
					fieldname: 'filename',
					fieldtype: 'Data',
					default: ext ? file_name.slice(0, -ext.length) : file_name,
				}],
				async primary_action(data) {
					const { filename } = data;
					const replaced = filename.replace(/[\\/]/g, '');
					if (/^[ \s]*$/.test(replaced)) {
						return;
					}
					const res = await frappe.call({
						method: 'tianjy_feature.feature.doctype.file.rename',
						args: { name, filename: replaced + ext },
						freeze: true,
					});
					if (res?.message === true) {
						dialog.hide();
						me.frm.sidebar.reload_docinfo();
						if (doc.comment_attachments) {
							for (const item of doc.comment_attachments) {
								if (item.name === name) {
									item.file_name = replaced + ext;
								}
							}
						}
						// me.refresh();
						frappe.show_alert({ message: __('Change File Name Successful'), indicator: 'green' });
					}
				},
				primary_action_label: __('Change File Name'),
			});
			dialog.show();
		};
	}
	const download_action = function () {
		saveAs(file_url, file_name);
	};
	const icon = `<a href="/app/file/${fileid}">
			${frappe.utils.icon(attachment.is_private ? 'lock' : 'unlock', 'sm ml-0')}
		</a>`;

	$(`<li class="attachment-row" style="overflow:hidden;">`)
		.append(frappe.get_data_pill(file_label, fileid, remove_action, icon, rename_action, download_action))
		.prependTo(comment_attachments_wrapper);
};

FormTimeline.prototype.remove_attachment = function remove_attachment(fileid, doc) {
	if (!fileid) {
		return;
	}

	let me = this;
	return frappe.call({
		method: 'frappe.desk.form.utils.remove_attach',
		type: 'DELETE',
		args: {
			fid: fileid,
			dt: me.frm.doctype,
			dn: me.frm.docname,
		},
		callback(r, rt) {
			if (r.exc) {
				if (!r._server_messages) { frappe.msgprint(__('There were errors')); }
				return;
			}
			frappe.show_alert({ message: __('Delete Successful'), indicator: 'green' });
			me.remove_fileid(fileid, doc);
			me.frm.sidebar.reload_docinfo();
		},
	});
};

FormTimeline.prototype.remove_fileid = function remove_fileid(fileid, doc) {
	let { attachments } = this.frm.get_docinfo();
	let new_attachments = [];
	$.each(attachments, function (i, attachment) {
		if (attachment.name != fileid) {
			new_attachments.push(attachment);
		}
	});
	this.frm.get_docinfo().attachments = new_attachments;
	doc.comment_attachments = doc.comment_attachments.filter(item => item.name !== fileid);
	this.refresh();
};

frappe.ui.form.Footer.prototype.make_timeline = function () {
	this.frm.timeline = new FormTimeline({
		parent: this.wrapper.find('.timeline'),
		frm: this.frm,
	});
};
