<template>
	<div class="instruction-wrapper">
		<component
			:is="InstructionComponent"
			:instructionName="instructionName"
			:editable="editable"
			:referenceType="referenceType"
			:referenceName="referenceName"
			:mode="mode"
			:emptyText="props.options?.emptyText"
			:addButtonText="props.options?.addButtonText"
		></component>
	</div>
</template>

<script setup lang='ts'>
import {computed} from 'vue';

import Empty from './Empty.vue';
import Instruction from './Instruction.vue';
interface Props {
	instructionName: string;
	editable?:boolean
	referenceType:string,
	referenceName:string,
	mode:'edit'|'show'
	options?:{
		title?:string
		addButtonText?:string
		emptyText?:string
	},
}
const props = defineProps<Props>();
const InstructionComponent = computed(()=>{
	if (props.instructionName) {
		return Instruction;
	}
	return Empty;
});
</script>

<style lang='less' scoped>
.instruction-wrapper{
	min-width: 1px;
	flex:1;
}
</style>
