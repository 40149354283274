<template lang="html">
	<ElButtonGroup :disabled="disabled" class="group">
		<ElButton
			@click="$emit('filter')"
			circle
			:title="filter ? `${tt('Filter')}(${filter})` : tt('Filter')"
			:type="filter ? 'success' : undefined"
			:icon="Filter" />
		<ElButton
			@click="$emit('sort')"
			circle
			:title="sort ? `${tt('Sort')}(${sort})` : tt('Sort')"
			:type="sort ? 'success' : undefined"
			:icon="Sort" />
		<ElButton @click="$emit('refresh')" :title="tt('Refresh')" circle :icon="RefreshRight" />
		<ElButton @click="$emit('fullscreen')" :title="tt('fullscreen')" circle :icon="FullScreen" />

		<ElDropdown v-if="list.length" @command="command">
			<ElButton circle :icon="ArrowDown" />
			<template #dropdown>
				<ElDropdownMenu>
					<ElDropdownItem v-for="c in list" :key="c.key" :command="c">
						{{ tt(c.label) }}
					</ElDropdownItem>
				</ElDropdownMenu>
			</template>
		</ElDropdown>
	</ElButtonGroup>
</template>
<script setup lang="ts">
import {ElDropdown, ElButton, ElButtonGroup, ElDropdownMenu, ElDropdownItem} from 'element-plus';
import {ArrowDown, RefreshRight, Sort, Filter, FullScreen} from '@element-plus/icons-vue';

import type {MenuItem} from './menu';

defineProps<{
	list: MenuItem[];
	filter: number;
	sort: number;
	disabled: boolean;
}>();
defineEmits<{
	(event: 'filter'): void;
	(event: 'sort'): void;
	(event: 'refresh'): void;
}>();

const tt = __;

function command(item: MenuItem) {
	item.action?.();
}
</script>

<style scoped lang="less">
  .group:global(.el-button-group > .el-dropdown) {
    > .el-button {
      border-radius: 0;
      border-left-color: var(--el-button-border-color);
    }

    + .el-dropdown > .el-button {
      border-left-color: var(--el-button-divide-border-color);
    }

    &:first-child > .el-button.is-circle {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    &:last-child > .el-button.is-circle {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
  }
</style>
