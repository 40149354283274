const assets = Object.create(null);
/**
 *
 * @param {string} src
 * @param {string} txt
 */
frappe.assets.set = function (src, txt) {
	assets[src] = txt;
};
/**
 *
 * @param {string} src
 */
frappe.assets.get = function (src) {
	return assets[src];
};
