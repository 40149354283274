<template>
	<div class="report-info_label">5. {{ tt("Guigu Construction Field Problem") }}</div>
	<AgGridVue
		class="ag-theme-guigu"
		:columnDefs="columnDefs"
		:rowData="problems"
		rowSelection="multiple"
		animateRows="true"
		groupDefaultExpanded="-1"
		domLayout="autoHeight"
		:suppressDragLeaveHidesColumns="smallMeta"
		:localeText="zhCN"
		:defaultColDef="defaultColDef">
	</AgGridVue>
</template>

<script lang='ts'>
import {AgGridVue} from 'ag-grid-vue3';
import {computed, defineComponent, PropType} from 'vue';

import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';
import {Problem} from '../type';
import {useMetaQuery} from '../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';

import getProblemColumns from './ProblemColumns';
const tt = __;
export default defineComponent({
	components: {
		AgGridVue,
	},
	props: {
		problems: Object as PropType<Problem[]>,
	},
	setup(props, context) {
		const smallMeta = useMetaQuery();
		const columnDefs = computed(()=>getProblemColumns());
		const defaultColDef = {
			resizable: true,
		};
		return {
			props,
			columnDefs,
			defaultColDef,
			smallMeta,
			zhCN,
		};
	},
});
</script>

<style lang='less' scoped>
:deep(.ag-group-value) {
	flex: 1;
}
</style>
