<template>
	<Timeline
		v-loading="loading"
		@loadMore="loadMore">
		<photoItem
			v-for="item in photoLit"
			:key="item.date"
			:date="item.date"
			:photos="item"
			@showPhotoInfo="showPhotoInfo"
			@deletePhoto="deletePhoto"></photoItem>
	</Timeline>
</template>
<script lang="ts" setup>
import {defineProps, ref, toRefs, onMounted, watchEffect, watch, computed} from 'vue';

import Timeline from '../../../components/timeline/Timeline.vue';

import {getTimelineData} from './helper';
import {Photo, TimelineSource} from './type';
import photoItem from './PhotoItem.vue';
interface Props{
	project?:string,
	structureOrProgress?:string,
	startDate?:string,
	endDate?:string
	sort:'desc' | 'asc'
	isIncludeChildren:boolean
}
const props = defineProps<Props>();
interface Emit{
	(e:'photoListChange', originPhotoList:Photo[]):void
}
const emit = defineEmits<Emit>();
const {project, structureOrProgress, startDate, endDate, sort, isIncludeChildren} = toRefs(props);
const originPhotoList = ref<Photo[]>([]);
const page = ref<number>(1);
const loading = ref<boolean>(false);
async function loadPhoto() {
	if (!project?.value || !structureOrProgress?.value || !startDate?.value || !endDate?.value) {
		originPhotoList.value = [];
		return;
	}
	loading.value = true;
	const target_type = structureOrProgress?.value?.startsWith('structure_progress_')
		? 'guigu_construction_structure_progress'
		: 'guigu_construction_structure';
	const nameArr = structureOrProgress?.value?.split('_') || [];
	const data = await frappe.call < { message: {photos:Photo[], page:string} } >({
		method: 'guigu_pm.guigu_pm_construction.page.photo_timeline.photo_timeline.get_photos',
		args: {
			project: project.value,
			target_type,
			target: nameArr[nameArr.length - 1],
			start_date: startDate.value,
			end_date: endDate.value,
			sort: sort.value,
			page: page.value,
			limit: 50,
			is_include_children: isIncludeChildren.value,
		},
	});
	loading.value = false;
	const newPhotoList = data?.message.page === '1' ? data?.message.photos || [] : originPhotoList.value.concat(data?.message.photos || []);
	originPhotoList.value = newPhotoList;
	emit('photoListChange', newPhotoList);
}
const photoLit = computed(()=>getTimelineData(originPhotoList.value, sort.value));
function loadMore() {
	page.value++;
	loadPhoto();
}
watch([project, startDate, endDate, sort, structureOrProgress, isIncludeChildren], ()=>{
	page.value = 1;
	loadPhoto();
});

function showPhotoInfo(photo:Photo) {
}
async function deletePhoto(photo:Photo) {
	try {
		const result = frappe.db.delete_doc('Guigu Construction Image', photo.name);
		const index = originPhotoList.value.findIndex(item=>item.name === photo.name);
		originPhotoList.value.splice(index, 1);
	} catch {

	}
}
</script>
