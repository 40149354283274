import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElButton = _resolveComponent("ElButton")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $setup.visible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.visible) = $event)),
    title: $setup.tt('Related Structure progress'),
    width: "50%",
    "before-close": $setup.handleClose,
    "custom-class": "progress-dialog"
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_ElButton, { onClick: $setup.handleClose }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_ElButton, {
          type: "primary",
          onClick: $setup.confirm
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Confirm')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode($setup["ProgressItemTable"], {
          selectedProgresses: $setup.selectProgressNames,
          progressItems: $setup.progressItems,
          onSelectChange: $setup.selectChange
        }, null, 8 /* PROPS */, ["selectedProgresses", "progressItems"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}