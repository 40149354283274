<template>
	<div :style="{ height: '100%'}">
		<div
			v-show="isShowSelect"
			@mouseover="handleMouseOver"
			@mouseout="handleMouseOut"
			:style="{ height: '100%', display: 'flex' }">
			<ElSelect
				ref="selectRef"
				v-show="isSelectShow || isSelectFocus"
				@change="change"
				@focus="focus"
				@blur="blur"
				v-model="selectValue"
			>
				<ElOption v-for="option in workList" :key="option.name" :label="option.subject" :value="option.name" />
			</ElSelect>
		</div>
		<el-tooltip ref="toolTipRef" placement="top-start">
			<template #content>
				<div style="max-width: 300px">
					<div v-for="(item, index) in cellData.context" style="max-width: 300px">
						<span v-if="type === 'month'">{{ index + 1 }}.</span>{{ item }}
					</div>
				</div>
			</template>
			<span
				v-if="type !== 'month'"
				style="max-width: 100%; display: inline-block; text-overflow: ellipsis; overflow: hidden; white-space: nowrap"
			>{{ params.value }}</span>
			<a
				v-show="!isShowSelect"
				style="max-width: 100%; text-overflow: ellipsis; overflow: hidden; white-space: nowrap"
				@dblclick="changeWork"
				@click="gotoWork">
				{{ params.value || tt('No Progress Yet') }}
			</a>
		</el-tooltip>
	</div>
</template>
<script lang="ts" setup>
import { computed, ref, watch, type Ref } from 'vue';
import moment from 'moment';

import type { BusinessTypeContent, LastYearUnfinishedWork, Work } from '../type';

const tt = __;
const props = defineProps<{ params: any }>();
const type = computed(() => props.params.data.type);
const toolTipRef = ref<any>();
const isSelectShow = ref<boolean>(false);
const isSelectFocus = ref<boolean>(false);
const workList = ref<Work[]>([]);
const showChangeWork = ref<boolean>(false);
const selectRef = ref<any>();
const businessTypeName = computed(() => props.params.colDef.field.split('.')[0]);
const cellData: Ref<BusinessTypeContent> = computed(() => {
	const { data } = props.params;
	return data[businessTypeName.value];
});
const selectValue = ref<string>('');

const isNoWork = computed(()=>!cellData.value.name && type.value === 'month');
const isShowSelect = computed(()=>isNoWork.value||showChangeWork.value);
const data = computed(() => props.params.data);
function focus() {
	isSelectFocus.value = true;
	getWorkList();
}
function blur(){
	isSelectFocus.value = false;
	showChangeWork.value = false;
}
async function getWorkList() {
	const project = data.value.project_name;
	const year = data.value.date;
	const startDate = moment(year).startOf('year').format('YYYY-MM-DD');
	const endDate = moment(year).endOf('year').format('YYYY-MM-DD');
	const filters: any[] = [
		['project', '=', project],
		['plan_start', 'between', [startDate, endDate]],
		['type', '=', businessTypeName.value],
	];
	const res = await frappe.call<{ message: Work[] }>({
		method: 'guigu_pc.guigu_pc.page.finishing_work_project_management.finishing_work_project_management.get_wok',
		args: {
			filters,
		},
	});
	workList.value = [ {name:'', subject:tt('empty')}, ...(res?.message || [])];
	selectValue.value = cellData.value?.name || '';
}
async function change(value: string) {
	isSelectFocus.value = false;
	if (cellData.value.unfinished_work_name) {
		if (value) {
			await updateLastYearWork(value);
		} else {
			await deleteLastYearWork();
		}
	} else {
		if (!value) {
			return;
		}
		await createLastYearWork(value);
	}
	props.params.refresh();
}
async function deleteLastYearWork() {
	await frappe.db.delete_doc('Guigu Last Year Unfinished Work', cellData.value.unfinished_work_name!);
}
async function createLastYearWork(workName: string) {
	const year = moment(data.value.date).startOf('year').format('YYYY-MM-DD');
	const res: LastYearUnfinishedWork = await frappe.db.insert({
		doctype: 'Guigu Last Year Unfinished Work',
		project: data.value.project_name,
		year,
		type: businessTypeName.value,
		content: '',
		annual_target: '',
		associated_work: workName,
	});
	return res;
}
async function updateLastYearWork(workName: string) {
	const { message: newData }: { message: LastYearUnfinishedWork } = await frappe.db.set_value(
		'Guigu Last Year Unfinished Work',
		cellData.value.unfinished_work_name,
		'associated_work',
		workName || '',
	);
	return newData;
}
let isDb = false;
function gotoWork(e:Event) {
	if (!cellData.value.name) {
		return;
	}
	isDb = false;
	setTimeout(()=>{
		if (isDb){
			return;
		}
		toolTipRef.value?.hide();
		frappe.set_route('Form', 'Guigu Project Work', cellData.value.name);
	}, 500);
}
function changeWork(e:Event){
	e.stopPropagation();
	isDb = true;
	showChangeWork.value = true;
	isSelectShow.value = true;
	selectRef.value?.focus();
}
function handleMouseOver() {
	// 鼠标悬停时改变背景颜色和文字颜色
	isSelectShow.value = true;
}
function handleMouseOut() {
	// 鼠标离开时恢复原始样式
	isSelectShow.value = false;
}
</script>
