import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withKeys as _withKeys, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom-tabs-label" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _withDirectives(_createElementVNode("span", {
      onDblclick: _cache[0] || (_cache[0] = $event => ($setup.editing = true))
    }, _toDisplayString($props.title), 545 /* TEXT, HYDRATE_EVENTS, NEED_PATCH */), [
      [_vShow, !$setup.editing]
    ]),
    _withDirectives(_createElementVNode("span", null, [
      _createVNode($setup["ElInput"], {
        modelValue: $setup.inputTitle,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.inputTitle) = $event)),
        onBlur: $setup.submit,
        onKeydown: [
          _withKeys($setup.submit, ["enter"]),
          _withKeys($setup.cancel, ["esc"]),
          _cache[2] || (_cache[2] = _withKeys(_withModifiers(() => {}, ["stop"]), ["backspace"]))
        ]
      }, null, 8 /* PROPS */, ["modelValue", "onKeydown"])
    ], 512 /* NEED_PATCH */), [
      [_vShow, $setup.editing]
    ])
  ]))
}