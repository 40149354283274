import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["FieldRenderer"], {
    value: $props.value,
    field: $props.meta.docField,
    enableLink: $props.meta.enableLink,
    data: $props.data,
    meta: $props.meta.meta,
    format: $props.meta.format,
    inline: "",
    showTitle: "",
    onFilter: $setup.setFilter
  }, null, 8 /* PROPS */, ["value", "field", "enableLink", "data", "meta", "format"]))
}