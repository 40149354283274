import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9e196cbf"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "icon icon-md" }
const _hoisted_3 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("span", {
    class: "toggle-btn",
    title: $setup.tt('Toggle Sidebar'),
    onClick: $setup.switchOpen
  }, [
    (_openBlock(), _createElementBlock("svg", _hoisted_2, [
      _createElementVNode("use", {
        href: $props.modelValue ? '#icon-sidebar-collapse' : '#icon-sidebar-expand'
      }, null, 8 /* PROPS */, _hoisted_3)
    ]))
  ], 8 /* PROPS */, _hoisted_1))
}