import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElTree = _resolveComponent("ElTree")

  return (_openBlock(), _createBlock(_component_ElTree, {
    class: "structure-tree",
    key: $props.project,
    props: $setup.optionProps,
    load: $setup.loadNode,
    lazy: "",
    "node-key": "name",
    onNodeClick: $setup.nodeClick,
    "highlight-current": ""
  }))
}