
<template>
	<div class="toolbar">
		<div class="tool_left">
			<div>
				<ProcessSearchTools
					:processes="processList"
					@getData="getData"
				></ProcessSearchTools>
			</div>
			<div class="input_container">
				{{ tt('Range') }}:
				<ElSelect class="input" v-model="rangeValue" size="small" :placeholder="tt('No Records Created')">
					<ElOption :label="tt('Global')" value="Global" />
					<ElOption :label="tt('Organization Unit Specific')" value="Organization Unit Specific" />
				</ElSelect>
			</div>
			<div class="input_container">
				{{ tt('Work List State') }}:
				<ElSelect v-model="disable" :placeholder="tt('Please Select')" size="small">
					<ElOption v-for="item,index in states" :key="index" :label="tt(item.label)" :value="item.value"/>
				</ElSelect>
			</div>
		</div>
		<div class="tool_right">
			<div class="tool_dropdown_menu">
				<LayoutSelect v-model:detailType="detailType"/>
			</div>
			<CollapsiblePanel @toggle="isExpand" />
			<ElButton type="primary" size="small" @click="addStandardWorkList" v-show="hasPermission">{{ tt('Create Standard Work List') }}</ElButton>
		</div>
	</div>
</template>

<script setup lang="ts">
import {ElButton, ElSelect, ElOption} from 'element-plus';
import {computed} from 'vue';

import ProcessSearchTools from '../../../../component/ProcessSearchTools/index.vue';
import LayoutSelect from '../../../../component/LayoutSelect/index.vue';
import CollapsiblePanel from '../../../../component/CollapsiblePanel/index.vue';

import type {ProcessData} from './type.ts';

const tt = __;
const states = [
	{label: 'Catch All', value: 'all'},
	{label: 'Enable', value: 'enable'},
	{label: 'Deactivate', value: 'disable'},
];
const props = defineProps<{
	hasPermission: boolean;
	process: string;
	processList: ProcessData[];
	layoutType: 'modal' | 'link' | 'bottom' | 'right';
	scope: 'Global' | 'Organization Unit Specific';
	disable: 'all' | 'enable' | 'disable';
	smallMeta: boolean;
}>();
const emit = defineEmits<{
	(e: 'update:process', process: string): void;
	(e: 'update:layoutType', layoutType: string): void;
	(e: 'update:disable', disable: 'all' | 'enable' | 'disable'): void;
	(e: 'update:scope', rangeValue: 'Global' | 'Organization Unit Specific'): void;
	(e: 'toggleExpand', state: boolean): void;
	(e: 'createWorkList'): void;
}>();
const detailType = computed({
	get: () => props.layoutType,
	set: (layoutType:string) => emit('update:layoutType', layoutType),
});
const rangeValue = computed({
	get: () => props.scope,
	set: (rangeValue:'Global' | 'Organization Unit Specific') => emit('update:scope', rangeValue),
});
const disable = computed({
	get: () => props.disable,
	set: (disable:'all' | 'enable' | 'disable') => emit('update:disable', disable),
});
// 获取流程分级的value
function getData(process:string) {
	emit('update:process', process);
}
// 展开层级
function isExpand(state:boolean) {
	emit('toggleExpand', state);
}
// 新建标准工作清单
function addStandardWorkList() {
	emit('createWorkList');
}
const flexDirection = computed(() => (props.smallMeta ? 'column' : 'row'));
</script>

<style lang="less" scoped>
  .toolbar {
    display: flex;
    flex-direction: v-bind(flexDirection);
	justify-content: space-between;

	.tool_left {
		display: flex;
		flex-direction: v-bind(flexDirection);
		flex-wrap: wrap;
		justify-content: flex-start;

		.input_container{
			margin:0 8px 8px 0;
		}
	}

	.tool_right {
		display: flex;
		flex-direction: v-bind(flexDirection);
		flex-wrap: wrap;
		.tool_dropdown_menu{
			margin:0 20px 8px 0;
		}
		.tool_toggle_State {
			margin:0 20px 8px 0;
		}
	}
}
</style>
