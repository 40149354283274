<template lang="html">
	<Skeleton :classes="setting" :loading="loading" ref="skeleton"
		:layout="state.layout">
		<FilterDialog :meta="meta" v-model="filters" v-model:or="orFilters"
			v-model:visible="filterVisible" />
		<Sorter :meta="meta" :value="sort" @update="setOrder"
			v-model:visible="sortVisible" />
		<!-- 活动按钮 -->
		<template #action>
			<component v-for="component in actions" :is="component"
				:meta="meta" :view="view" :configuration="configuration"
				:data="data" :total="total" :options="options"
				:loading="loading" @refresh="refresh"
				:page="page" :limit="limit" :value="value"
				v-model:selected="selected"
				v-model:group="group" @sort="setOrder" :sort="sort"
				v-model:filters="filters" :allFilters="allFilters"
				:quickFilters="quickFilters"
				:infiniteScroll="infiniteScroll" />

			<Buttons :buttons="buttons" />
			<Menu :meta="meta" :loading="loading" :selected="selected"
				:filter="hasFilter" :sorter="orderN" :view="view"
				@refresh="refresh" @fullscreen="fullscreen"
				@toggleSorter="sortVisible = !sortVisible"
				@toggleFilter="filterVisible = !filterVisible" />
		</template>
		<!-- 工具栏 -->
		<template #toolbar v-if="toolbar.length">
			<component v-for="component in toolbar" :is="component"
				:meta="meta" :view="view" :configuration="configuration"
				:data="data" :total="total" :options="options"
				:loading="loading" @refresh="refresh"
				:page="page" :limit="limit" :value="value"
				v-model:selected="selected"
				v-model:group="group" @sort="setOrder" :sort="sort"
				v-model:filters="filters" :allFilters="allFilters"
				:quickFilters="quickFilters"
				:infiniteScroll="infiniteScroll" />
		</template>
		<!-- 侧边栏 -->
		<template #sider v-if="sider.length">
			<component v-for="(component, index) in sider" :is="component"
				:meta="meta" :view="view" :configuration="configuration"
				:data="data" :total="total" :options="options"
				:loading="loading" @refresh="refresh"
				:page="page" :limit="limit" :value="value"
				v-model:selected="selected"
				v-model:group="group" @sort="setOrder" :sort="sort"
				v-model:filters="filters" :allFilters="allFilters"
				:quickFilters="quickFilters"
				:infiniteScroll="infiniteScroll" />
		</template>
		<!-- 筛选列表 -->
		<template #filterField v-if="filterField">
			<FilterField v-model="filterFieldValue" :field="filterField"
				:meta="meta" />
		</template>
		<!-- 主区域栏 -->
		<component :is="mainComp" :meta="meta" :configuration="configuration"
			:view="view" :data="data" :total="total" :options="options"
			:loading="loading" @refresh="refresh" @nextPage="nextPage"
			@sort="sortData"
			@create="create" @delete="del" @update:configuration="update"
			v-model:selected="selected" v-model="modelValue"
			v-model:filters="filters"
			:rowAction="rowAction" :infiniteScroll="infiniteScroll" :page="page"
			:limit="limit" :group="group" :sort="sort" :detail="detail">

			<template #limit v-if="!nonpageable && (infiniteScroll || isAll)">
				<Limit :loading="loading" v-model="limit"
					:maxLimit="maxLimit" />
			</template>
			<template #infiniteScroll
				v-if="!nonpageable && showInfiniteScrollBtn">
				<ElButton @click="nextPage" :disabled="loading">继续加载</ElButton>
			</template>

			<template #pagination
				v-if="!nonpageable && (infiniteScroll || isAll || showInfiniteScrollBtn)">
				<div class="pagination">
					<Limit v-if="infiniteScroll || isAll"
						:loading="loading" v-model="limit"
						:maxLimit="maxLimit" />
					<ElButton v-if="showInfiniteScrollBtn" @click="nextPage"
						:disabled="loading">
						继续加载
					</ElButton>
				</div>
			</template>
		</component>
		<!-- 翻页 -->
		<template #pagination v-if="!nonpageable && !isAll && !infiniteScroll">
			<Limit :loading="loading" v-model="limit" :maxLimit="maxLimit"
				class="limit" />
			<Pagination :total="total" :loading="loading" :limit="limit"
				v-model:page="page" />
		</template>
		<!-- 详情区 -->
		<template #detail v-if="detailComp && value && showDetail">
			<component :is="detailComp" :meta="meta" :isPosition="isPosition"
				:configuration="configuration" :view="view" :options="options"
				:selected="selected" v-model:value="value"
				:loading="loading" @refresh="refresh" />
		</template>
	</Skeleton>
</template>
<script setup lang="ts">
import type {Component} from 'vue';
import {computed, onActivated, onDeactivated, ref, shallowRef, toRef, watch} from 'vue';
import {ElButton} from 'element-plus';

import deleteDoc from '../utils/deleteDoc';

import FilterDialog from './FilterDialog/index.vue';
import Pagination from './Pagination/index.vue';
import Skeleton from './Skeleton/index.vue';
import Sorter from './Sorter/index.vue';
import FilterField from './FilterField/index.vue';
import Limit from './Limit/index.vue';
import useMain from './useMain';
import useLimit from './useLimit';
import type {View} from './types';
import Menu from './Menu/index.vue';
import useRowAction from './useRowAction';
import useSort from './useSort';
import Buttons from './Buttons/index.vue';

const props = defineProps<{
	meta: locals.DocType;
	setting: GlobalView;
	configuration: object;
	link: boolean;
	showDetail: boolean;
	view: GlobalView.View;
	state: View,
	sider: Component[];
	toolbar: Component[];
	actions: Component[];
	mainComp: any;
	detailComp: any;
	rowActionComp: any;
	loading: boolean;
	layout: string;
}>();

const emit = defineEmits<{
	(event: 'update', data: any): void;
}>();
const docMeta = toRef(props, 'meta');
const view = toRef(props, 'view');
const configuration = toRef(props, 'configuration');
const quickFilters = computed(() => view.value.quickFilters);
const buttons = computed(() => view.value.buttons || []);
const detailComp = toRef(props, 'detailComp');
const rowActionComp = toRef(props, 'rowActionComp');

const domVisible = shallowRef(false);
onActivated(() => {
	domVisible.value = true;
});
onDeactivated(() => {
	domVisible.value = false;
});
function update(data: any) {
	emit('update', data);
}

const skeleton = shallowRef<any>();
function fullscreen() {
	skeleton.value?.fullscreen();
}

const isPosition = computed(() => props.layout === 'modal');
/******** 过滤器相关 ********/
/** 查询参数 */
const filters = computed({
	get: () => props.state.filters,
	set: v => props.state.setFilters(v),
});
const orFilters = computed({
	get: () => props.state.orFilters,
	set: v => props.state.setOrFilters(v),
});
const hasFilter = computed(() => {
	if (filters.value.length) {
		return true;
	}
	if (orFilters.value.find(v => v.length)) {
		return true;
	}
	return false;
});
const allFilters = computed(() => props.state.allFilters);
const filterField = computed(() => props.view?.filterField);
const filterFieldValue = computed({
	get: () => props.state.filterFieldValue,
	set: v => props.state.setFilterFieldValue(v),
});
const queryFilters = computed(() => props.state.queryFilters);
const queryQrFilters = computed(() => props.state.queryQrFilters);
const filterVisible = shallowRef(false);
const sortVisible = shallowRef(false);

/******** 查询数据 ********/
/** 排序 */
const {sort, setOrder, orderN} = useSort(docMeta, toRef(props, 'setting'), configuration, view);
/** 分组 */
const group = shallowRef<GlobalView.Group[]>([]);
const nonpageable = computed(() => view.value.nonpageable);
const maxLimit = computed(() => view.value.maxLimit);
const defaultLimit = computed(() => view.value.defaultLimit);
const infiniteScroll = computed(() => nonpageable.value ? false : view.value.infiniteScroll);
/** 每页条数 */
const limit = useLimit(maxLimit, defaultLimit, nonpageable);
const {
	data, total, options, loading: queryLoading, refresh, nextPage, sortData, reRequest, page,
} = useMain(
	docMeta,
	toRef(props, 'setting'),
	configuration,
	view,
	queryFilters,
	queryQrFilters,
	sort,
	group,
	infiniteScroll,
	limit,
);
const deleteLoading = ref<boolean>(false);
const loading = computed(() => queryLoading.value || deleteLoading.value || props.loading);
watch([sort, group, queryFilters, queryQrFilters], reRequest);

/******** 详情区域 ********/
const showDetailSwitcher = computed(() => Boolean(props.showDetail && detailComp.value));
const detail = computed(() => !props.link && showDetailSwitcher.value);

/******** 数据状态 ********/
const selected = shallowRef<any[]>([]);
const value = shallowRef<any>();
const modelValue = computed({
	get: () => value.value,
	set: val => {
		if (!detail.value) {
			return;
		} value.value = val;
	},
});
watch(detail, s => {
	if (s) {
		return;
	} value.value = undefined;
});

/******** 创建按钮 ********/
function create(options?: Record<string, any>, extend?: boolean) {
	return props.state.create(options, extend);
}
function del(
	doc: any,
	mode?: 'all' | 'parent' | 'root',
	parent?: string,
) {
	deleteDoc(docMeta.value.name, doc, deleteLoading, mode, parent).then(() => {
		refresh();
		if (mode === 'all' || value.value?.name === doc.name) {
			value.value = undefined;
		}
	});
}
/******** 自动刷新 ********/
const updated = shallowRef(false);

watch(() => domVisible.value && updated.value, v => {
	if (!v) {
		return;
	}
	updated.value = false;
	refresh();
});

frappe.socketio.doctype_subscribe(docMeta.value.name);
frappe.realtime.on('list_update', p => {
	if (p.doctype !== docMeta.value.name) {
		return;
	}
	updated.value = true;
});

/******** 其他 ********/
const showInfiniteScrollBtn = computed(() =>
	limit.value !== Infinity && infiniteScroll.value && data.value.length < total.value);
const isAll = computed(() => limit.value === Infinity);
const rowAction = useRowAction(rowActionComp, docMeta, configuration, view, loading, refresh, create);
</script>
<style scoped >
.input {
	width: 160px;
}

.limit {
	margin-inline: 8px;
}

.pagination {
	position: absolute;
	right: 20px;
	bottom: 20px;

	display: flex;
	flex-direction: column;
}
</style>
