import { toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-467c407a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar" }
const _hoisted_2 = { class: "toolbar_left" }
const _hoisted_3 = {
  key: 0,
  class: "input-container"
}
const _hoisted_4 = { class: "input-container" }
const _hoisted_5 = { class: "input-container" }
const _hoisted_6 = { class: "toolbar_right" }
const _hoisted_7 = { class: "layout" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($setup.organizationList)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createTextVNode(_toDisplayString($setup.tt('Organization Unit')) + ": ", 1 /* TEXT */),
            _createVNode($setup["ElSelect"], {
              class: "input",
              disabled: "",
              modelValue: $setup.organization,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.organization) = $event)),
              size: "small",
              width: "150px"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["ElOption"], {
                  label: $setup.organizationList.label,
                  value: $setup.organizationList.name
                }, null, 8 /* PROPS */, ["label", "value"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue"])
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_4, [
        _createTextVNode(_toDisplayString($setup.tt('Dw Department')) + ": ", 1 /* TEXT */),
        _createVNode($setup["ElCascader"], {
          popperClass: "dw_task_department_cascader",
          size: "small",
          placeholder: $setup.tt('Please Select'),
          clearable: "",
          filterable: "",
          options: $props.departmentTree,
          props: $setup.cascaderProps,
          modelValue: $setup.department,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.department) = $event))
        }, null, 8 /* PROPS */, ["placeholder", "options", "modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createTextVNode(_toDisplayString($setup.tt('Work List Applicable State')) + ": ", 1 /* TEXT */),
        _createVNode($setup["ElSelect"], {
          class: "input",
          modelValue: $setup.applicable,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.applicable) = $event)),
          size: "small"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElOption"], {
              label: $setup.tt('Applicable'),
              value: "1"
            }, null, 8 /* PROPS */, ["label"]),
            _createVNode($setup["ElOption"], {
              label: $setup.tt('Inapplicability'),
              value: "0"
            }, null, 8 /* PROPS */, ["label"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode($setup["LayoutSelect"], {
          detailType: $setup.detailType,
          "onUpdate:detailType": _cache[3] || (_cache[3] = $event => (($setup.detailType) = $event))
        }, null, 8 /* PROPS */, ["detailType"])
      ]),
      _withDirectives(_createVNode($setup["ElButton"], {
        type: "primary",
        size: "small",
        onClick: $setup.addNewTask
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.tt('New Task')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 512 /* NEED_PATCH */), [
        [_vShow, $props.permission]
      ])
    ])
  ]))
}