<template>
	<div>
		<div>
			<img ref="image" :src="src" :alt="file.name" />
		</div>
		<div class="image-cropper-actions">
			<div>
				<div class="btn-group">
					<button
						v-for="button in aspect_ratio_buttons"
						type="button"
						class="btn btn-default btn-sm"
						:class="{
							active: isNaN(aspect_ratio)
								? isNaN(button.value)
								: button.value === aspect_ratio
						}"
						:key="button.label"
						@click="aspect_ratio = button.value"
					>
						{{ button.label }}
					</button>
				</div>
			</div>
			<div>
				<button
					class="btn btn-sm margin-right"
					@click="emit('toggle_image_cropper')"
				>
					{{ tt("Back") }}
				</button>
				<button class="btn btn-primary btn-sm" @click="crop_image">
					{{ tt("Crop") }}
				</button>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { ref, watch, computed, onMounted } from 'vue';

import Cropper from 'cropperjs';

import type { FileInfo } from './upload';

defineOptions({name: 'ImageCropper'});

const props = defineProps<{
	file: FileInfo
}>();
const emit = defineEmits<{
	toggle: [],
}>();

const aspect_ratio = ref( NaN);
const src = computed(() => {
	const file = props.file.cropper_file;
	if (!file) { return ''; }
	return URL.createObjectURL(file);
});
watch(src, (_, old) => {
	if (!old) { return; }
	URL.revokeObjectURL(old);
});
const cropper = ref<Cropper>();
const image = ref<HTMLImageElement>();


watch(aspect_ratio, value => {
	const c = cropper.value;
	if (!c) { return; }
	c.setAspectRatio(value);
});
const aspect_ratio_buttons = [
	{ label: __('1:1'), value: 1 },
	{ label: __('4:3'), value: 4 / 3 },
	{ label: __('16:9'), value: 16 / 9 },
	{ label: __('Free'), value: NaN },
];
const tt = __;

onMounted(() => {
	let crop_box = props.file.crop_box_data;
	const img = image.value;
	img.onload = () => {
		const c = new Cropper(img, {
			zoomable: false,
			scalable: false,
			viewMode: 1,
			data: crop_box,
			aspectRatio: aspect_ratio.value,
		});
		cropper.value = c;
		window.cropper = c;

	};
});
function crop_image() {
	const c = cropper.value;
	props.file.crop_box_data = c.getData();
	const canvas = c.getCroppedCanvas();
	const file_type = props.file.file_obj.type;
	canvas.toBlob(blob => {
		props.file.file_obj = new File([blob], props.file.name, { type: blob.type });
		emit('toggle');
	}, file_type);
}


</script>
<style scoped>
img {
	display: block;
	max-width: 100%;
	max-height: 600px;
}

.image-cropper-actions {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: var(--margin-md);
}
</style>
