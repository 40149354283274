import { resolveComponent as _resolveComponent, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElTreeSelect = _resolveComponent("ElTreeSelect")

  return _withDirectives((_openBlock(), _createBlock(_component_ElTreeSelect, {
    modelValue: $setup.structureValue,
    onChange: $setup.changeStructure,
    props: $setup.defaultProps,
    data: $setup.treeData,
    defaultExpandAll: "",
    filterNodeMethod: $setup.filterNodeMethod,
    filterable: "",
    checkStrictly: ""
  }, null, 8 /* PROPS */, ["modelValue", "data"])), [
    [_vShow, $setup.treeData.length>0]
  ])
}