<template>
	<ElDialog
		v-model="dialogVisible"
		:title="tt('Instruction Version List')"
		:width="smallMeta?'80%':'700'"
	>
		<ElTable
			ref="tableRef"
			class="table"
			:data="store.versions"
			style="width: 100%"
			stripe
			@select="selectionChange"
		>
			<ElTableColumn type="selection" width="55" />
			<ElTableColumn property="instruction_version" :label="tt('Version')" width="120" />
			<ElTableColumn property="compiler" :label="tt('instruction Compiler')" width="120" />
			<ElTableColumn property="checker" :label="tt('instruction Checker')" width="120" />
			<ElTableColumn property="change_log" :label="tt('Change Log')" />
			<ElTableColumn fixed="right" :label="tt('Actions')" minWidth="60">
				<template #default="data">
					<ElPopconfirm
						:title="tt('Are you sure you want to directly delete this data?')"
						@confirm="()=>deleteVersion(data.row)"
						:confirmButtonText="tt('Confirm')"
						:cancelButtonText="tt('Cancel')"
					>
						<template #reference>
							<ElButton v-if="data.row.name!==store.currentVersion.name" type="danger" size="small">{{ tt('Delete') }}</ElButton>
						</template>
					</ElPopconfirm>
				</template>
			</ElTableColumn>
		</ElTable>
		<template #footer>
			<div class="dialog-footer">
				<ElButton @click="dialogVisible = false">{{ tt('Cancel') }}</ElButton>
				<ElButton type="primary" @click="confirm">
					{{ tt('Confirm') }}
				</ElButton>
			</div>
		</template>
	</ElDialog>
</template>

<script lang="ts" setup>
import {computed, ref, watch} from 'vue';
import {ElDialog, ElButton, ElTable, ElTableColumn, type TableInstance, ElPopconfirm} from 'element-plus';

import {useMetaQuery} from '../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';

import type {Version} from './type';
import {useInstructionStore} from './store';
const smallMeta = useMetaQuery();

const tt = __;
interface Props {
	visible:boolean,
}
const props = defineProps<Props>();
interface Emit {
	(e: 'update:visible', visible: boolean): void,
}
const emit = defineEmits<Emit>();
const selectionData = ref<Version>();
const tableRef = ref<TableInstance>();
const store = useInstructionStore();

const dialogVisible = computed({
	get() {
		return props.visible;
	},
	set(visible:boolean) {
		emit('update:visible', visible);
	},
});
watch([()=>store.currentVersion, tableRef, dialogVisible, ()=>[...store.versions]], ()=>{
	if (!tableRef.value) {
		return;
	}
	tableRef.value?.clearSelection();
	const selectedVersion = store.versions.find(item=>item.name === store.currentVersion.name);
	tableRef.value?.toggleRowSelection(selectedVersion, true);
	selectionData.value = selectedVersion;
}, {immediate: true});

function selectionChange(selected:Version[], row:Version) {
	const isSelectRow = selected.some(item=>item.name === row.name);
	if (isSelectRow && selectionData.value) {
		tableRef.value?.toggleRowSelection(selectionData.value, false);
	}
	if (isSelectRow) {
		selectionData.value = row;
	} else {
		selectionData.value = undefined;
	}
}
function confirm() {
	if (selectionData.value) {
		store.currentVersion = selectionData.value;
		store.setVersion(selectionData.value);
		store.setEditorContent(selectionData.value.content);
	}
	dialogVisible.value = false;
}
function deleteVersion(version:Version) {
	store.deleteVersion(version.name);
}
</script>
<style scoped lang="less">
.table :deep(.el-table__header-wrapper  .el-checkbox){
	display:none
}
.table :deep(label){
	margin-bottom: 0;
}
</style>
