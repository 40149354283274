frappe.ui.form.ControlAttach.prototype.set_input = function(value, dataurl){
	this.last_value = this.value;
	this.value = value;
	if (this.value) {
		this.$input.toggle(false);
		// value can also be using this format: FILENAME,DATA_URL
		// Important: We have to be careful because normal filenames may also contain ","
		let file_url_parts = this.value.match(/^([^:]+),(.+):(.+)$/);
		let filename;
		if (file_url_parts) {
			filename = file_url_parts[1];
			dataurl = `${file_url_parts[2]}:${file_url_parts[3]}`;
		} else {
			const filenameArr = this.value.split('/');
			filename = filenameArr.pop();
			const isPrivate = filenameArr.some(item=>item==='private');
			filename = `[${isPrivate?__('Private'):__('Public')}]${filename}`;
		}
		this.$value
			.toggle(true)
			.find('.attached-file-link')
			.html(filename || this.value)
			.attr('href', dataurl || this.value);
	} else {
		this.$input.toggle(true);
		this.$value.toggle(false);
	}
};

frappe.ui.form.ControlAttach.prototype.make_input = function(){
	let me = this;
	this.$input = $('<button class="btn btn-default btn-sm btn-attach">')
		.html(__('Attach'))
		.prependTo(me.input_area)
		.on({
			click: function () {
				me.on_attach_click();
			},
			attach_doc_image: function () {
				me.on_attach_doc_image();
			},
		});
	this.$value = $(
		`<div class="attached-file flex justify-between align-center">
			<div class="ellipsis">
				<i class="fa fa-paperclip"></i>
				<a class="attached-file-link" target="_blank"></a>
			</div>
			<div style='display:flex;align-items:center'>
				<a style='min-width:47px;margin-right:8px' class="btn btn-xs btn-default" data-action="reload_attachment">${__('Reload File')}</a>
				<a style='min-width:47px' class="btn btn-xs btn-default" data-action="clear_attachment">${__('Clear')}</a>
			</div>
		</div>`,
	)
		.prependTo(me.input_area)
		.toggle(false);
	this.input = this.$input.get(0);
	this.set_input_attributes();
	this.has_input = true;

	frappe.utils.bind_actions_with_object(this.$value, this);
	this.toggle_reload_button();
};
