export function routeInit(paramsFilter: any) {
	const [_, ...filters] = frappe.router?.current_route || [];
	const filterObj: { [key: string]: string } = {};
	const keyArr = ['company', 'organization', 'pageType', 'monthDate', 'yearDate'];
	for (let i = 0; i < filters.length; i++) {
		if ((i & 1) === 0 && keyArr.includes(filters[i])) {
			filterObj[filters[i]] = filters[i + 1];
		}
	}
	paramsFilter.value = filterObj;
}


export function setRoute(paramsFilter: any, filter: { [key: string]: string }) {
	const [pageName] = frappe.router?.current_route || [];
	const newRoute = [pageName];
	const newParamsFilter = { ...paramsFilter.value, ...filter };
	Object.keys(newParamsFilter || {}).forEach(item => {
		newRoute[newRoute.length] = item;
		newRoute[newRoute.length++] = newParamsFilter[item];
	});
	paramsFilter.value = newParamsFilter;
	frappe.set_route(newRoute);
}
