<template>
	<ElSelect
		size="small"
		v-model="selected"
		@change="stopEditing"
		:placeholder="tt('Please Select')"
	>
		<ElOption
			v-for="option in selectOptions"
			:key="option.value"
			:value="option.value"
			:label="option.label"
		/>
	</ElSelect>
</template>

<script lang="ts">
import {ref, defineComponent} from 'vue';
import {ElSelect, ElOption} from 'element-plus';

import type {SelectOption} from '../../../../../types';
const tt = __;

export default defineComponent({
	props: ['params'],
	components: {
		ElSelect, ElOption,
	},
	// eslint-disable-next-line vue/component-api-style
	setup(props) {
		const selected = ref(props.params.value);
		const selectOptions = ref<SelectOption[]>(props.params.options);
		function getValue() {
			return selected.value;
		}
		function stopEditing() {
			props.params.stopEditing();
		}
		return {
			props,
			selected,
			selectOptions,
			getValue,
			stopEditing,
			tt,
		};
	},

});

</script>

<style lang="less" scoped>
:deep(.el-select) {
	line-height: 32px;
}
</style>
