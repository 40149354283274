<!-- 组织单元工作清单的组织单元任务模板 -->
<template>
	<h5>{{ tt('Organization unit task templates') }}</h5>
	<div v-loading="deleteLoading">
		<AgGridVue
			class="ag-theme-guigu"
			domLayout="autoHeight"
			:rowData="tableData"
			:rowSelection="'multiple'"
			:defaultColDef="defaultColDef"
			@grid-ready="onGridReady"
			:suppressDragLeaveHidesColumns="smallMeta"
			:localeText="zhCN"
			useValueFormatterForExport
			:getRowId="getRowId"
			@row-selected="handleRowSelected"
		>
		</AgGridVue>
		<div class="operation-button">
			<ElButton
				v-if="!props.frm.is_new() && manager"
				type="primary"
				@click="createTaskTemplate"
				size="small"
			>
				{{ tt('new') }}
			</ElButton>
			<ElPopover
				placement="bottom"
				:visible="visible"
				showArrow
				trigger="click"
			>
				<p>{{ tt("Are you sure you want to disable it") }}?</p>
				<div style="display: flex;">
					<ElButton size="small" @click="visible = false">
						{{ tt("Cancel") }}
					</ElButton>
					<ElButton size="small" type="primary" @click="disableTemplate">
						{{ tt("Sure") }}
					</ElButton>
				</div>
				<template #reference>
					<ElButton
						v-if="!props.frm.is_new() && manager && selectData.length > 0"
						type="danger"
						size="small"
						@click="visible = true"
					>
						{{ tt('Disable') }}
					</ElButton>
				</template>
			</ElPopover>
		</div>
	</div>
	<ElDialog
		v-if="dialogVisible"
		v-model="dialogVisible"
		:title="tt('Batch create task')"
		width="80%"
	>
		<EditTable
			:tableData="taskTemplate"
			:taskTypeList="taskTypeList"
			:user="organizationUser"
		></EditTable>
		<template #footer>
			<div class="dialog-footer">
				<ElButton @click="cancelDialogVisible=true" size="small">{{ tt('Cancel') }}</ElButton>
				<ElButton type="primary" size="small" @click="confirm">
					{{ tt('Confirm') }}
				</ElButton>
			</div>
		</template>
	</ElDialog>
	<ElDialog
		v-model="cancelDialogVisible"
		:title="tt('Prompt')"
	>
		<div>{{ tt("Are you sure to cancel the creation task? The maintained information will be cleared") }}</div>
		<template #footer>
			<div class="dialog-footer">
				<ElButton @click="cancelDialogVisible = false" size="small">{{ tt('Cancel') }}</ElButton>
				<ElButton type="primary" size="small" @click="cancelCreate">
					{{ tt('Confirm') }}
				</ElButton>
			</div>
		</template>
	</ElDialog>
</template>

<script lang="ts" setup>
import {onMounted, ref, defineProps, watch, onUnmounted} from 'vue';
import {ElButton, ElDialog, ElMessage, vLoading, ElPopover} from 'element-plus';
import {AgGridVue} from 'ag-grid-vue3';
import type {ColDef, ColGroupDef} from 'ag-grid-community';
import moment from 'moment';

import type {TaskTemplate, TemplateCreateTask, TaskType, SelectOption} from '../../../../types';
import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';
import {useMetaQuery} from '../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';
import Subject from '../../dw_standard_worklist/task_template/DwTaskTemplateList/component/Subject.vue';
import {registerEvent, triggerEvent} from '../eventBus';

import CreateTaskButton from './component/CreateTaskButton.vue';
import EditTable from './component/EditTable.vue';
const tt = __;

const props = defineProps<{
	frm: any
}>();
const tableData = ref<TaskTemplate[]>([]);
const loading = ref<boolean>(false);
const smallMeta = useMetaQuery();
const selectData = ref<TaskTemplate[]>([]);
const standardWorklistName = ref<string>('');
const manager = ref<boolean>(false);
const gridApi = ref<any>(null);
const taskTypeObj = ref<Record<string, string>>();
const deleteLoading = ref<boolean>(false);
const visible = ref<boolean>(false);

// 任务模板创建任务
const dialogVisible = ref<boolean>(false);
const taskTemplate = ref<TemplateCreateTask[]>([]);
const taskTypeList = ref<SelectOption[]>([]);
const cancelDialogVisible = ref<boolean>(false);
const organizationUser = ref<SelectOption[]>([]);
registerEvent('organizationWorkListChange', function() {
	getTableData();
});
// 获取一级任务模板列表;
async function getTableData() {
	loading.value = true;
	const organizationTaskTempate: TaskTemplate[] = await frappe.db.get_list(
		'Dw Task Template',
		{
			filters: {
				reference_type: 'Dw Organization Worklist',
				reference_name: props.frm.docname,
				parent_dw_task_template: '',
				disable: 0,
			},
			fields: [
				'name', 'subject', 'priority', 'task_type', 'is_necessary', 'percent_complete',
				'plan_start', 'plan_end', 'assignee', 'parent_dw_task_template', 'lft', 'rgt', 'root_id',
			],
			order_by: 'subject asc',
			limit: 0,
		},
	);
	for (const template of organizationTaskTempate) {
		template.task_type = taskTypeObj.value?.[template.task_type] || '';
	}
	tableData.value = organizationTaskTempate;
	loading.value = false;
}

function getTranslatedPriority(data: string) {
	return __(data);
}

function addPathAttribute(data: TemplateCreateTask[]): TemplateCreateTask[] {
	// 创建一个映射来存储每个节点及其父节点
	const nodeMap: Record<string, TemplateCreateTask> = {};

	data.map(item => {
		nodeMap[item.name] = item;
	});

	function buildPath(currentNode: TemplateCreateTask, path: string[] = []): void {
		path.push(currentNode.name);
		currentNode.path = [...path];

		// 查找当前节点的子节点
		for (const childNode of Object.values(nodeMap)) {
			if (childNode.parent_dw_task_template === currentNode.name) {
				buildPath(childNode, path);
			}
		}

		// 移除当前节点，以便递归完成后路径正确
		path.pop();
	}

	// 从根节点开始构建路径
	const rootNodes = data.filter(node => !node.parent_dw_task_template);
	for (const rootNode of rootNodes) {
		buildPath(rootNode, []);
	}

	return data;
}

async function getTaskTemplateList(rowData: TemplateCreateTask) {
	const childTaskTempate: TemplateCreateTask[] = await frappe.db.get_list(
		'Dw Task Template',
		{
			filters: [
				['reference_type', '=', 'Dw Organization Worklist'],
				['reference_name', '=', props.frm.docname],
				['root_id', '=', rowData.root_id],
				['lft', '>=', rowData.lft],
				['rgt', '<=', rowData.rgt],
				['disable', '=', 0],
			],
			fields: [
				'name', 'subject', 'priority', 'task_type', 'is_necessary', 'percent_complete',
				'plan_start', 'plan_end', 'assignee', 'parent_dw_task_template', 'lft', 'rgt',
			],
			limit: 0,
		},
	);
	const newTableData = addPathAttribute(childTaskTempate);
	taskTemplate.value = newTableData;
}

async function getTaskType() {
	const taskType:TaskType[] = await frappe.db.get_list(
		'Dw Task Type',
		{
			fields: ['name', 'type_name'],
			limit: 0,
		},
	);
	const options = [];
	const typeObject: Record<string, string> = {};
	for (const type of taskType) {
		options.push({
			value: type.name,
			label: type.type_name,
		});
		typeObject[type.name] = type.type_name;
	}
	taskTypeList.value = options;
	taskTypeObj.value = typeObject;
}

async function getOrganizationUser() {
	const userList = await frappe.db.get_list('Tianjy Organization Member', {
		filters: {organization: localStorage.getItem('TianjyOrganization/currentOrganization')},
		fields: ['user', 'user_name'],
		limit: 0,
	});
	organizationUser.value = userList?.map(item => ({
		label: item.user_name,
		value: item.user,
	})) || [];
}


function openCreateDialog(rowData: TemplateCreateTask) {
	getTaskTemplateList(rowData);
	// getTaskType();
	getOrganizationUser();
	dialogVisible.value = true;
}

const columns: (ColDef | ColGroupDef)[] = [
	{
		headerName: __('Subject'),
		field: 'subject',
		enableRowGroup: false,
		editable: false,
		filter: false,
		pinned: smallMeta ? undefined : ('left' as const),
		flex: 1,
		checkboxSelection: true,
		cellRenderer: Subject,
	},
	// {
	// 	headerName: __('Dw Task Type'),
	// 	field: 'task_type',
	// 	enableRowGroup: false,
	// 	editable: false,
	// 	filter: false,
	// 	pinned: smallMeta ? undefined : ('left' as const),
	// 	flex: 1,
	// 	checkboxSelection: false,
	// },
	{
		headerName: __('Priority'),
		field: 'priority',
		enableRowGroup: false,
		editable: false,
		filter: false,
		width: 150,
		valueGetter: params => getTranslatedPriority(params.data.priority),
		checkboxSelection: false,
	},
	{
		headerName: __('Necessary'),
		field: 'is_necessary',
		enableRowGroup: false,
		editable: false,
		filter: false,
		width: 100,
		valueGetter: params => {
			if (params.data.is_necessary) {
				return __('Yes');
			}
			return '';
		},
		checkboxSelection: false,
	},
	{
		headerName: __('Actions'),
		field: '',
		enableRowGroup: false,
		editable: false,
		filter: false,
		pinned: smallMeta ? undefined : ('left' as const),
		width: 120,
		minWidth: 120,
		checkboxSelection: false,
		cellRenderer: CreateTaskButton,
		cellRendererParams: {
			openCreateDialog,
		},
	},
];

watch([() => smallMeta, gridApi], () => {
	gridApi.value?.setColumnDefs(columns);
});

const defaultColDef = {
	filter: true,
	resizable: true,
	suppressMovable: true,
	cellDataType: false,
};
const onGridReady = (params: any) => {
	gridApi.value = params.api;
};
function getRowId(params: any) {
	return params.data.name;
}


async function is_applier(specific_organization: string) {
	if (!specific_organization) {
		return false;
	}
	const appliers = await frappe.db.get_list('Dw Organization Worklist Member', {
		filters: [['organization_unit', '=', specific_organization]],
		fields: ['user'],
		limit: 0,
	});
	return appliers.some(user => user.user === frappe.session.user);
}

async function getFormEditable() {
	if (frappe.session.user === 'Administrator' || frappe.user.has_role('System Manager')) {
		return true;
	}
	if (props.frm.doctype === 'Dw Organization Worklist' && props.frm.doc.organization_unit) {
		return is_applier(props.frm.doc.organization_unit);
	}
	return false;
}


function handleRowSelected() {
	const selectedRows = gridApi.value.getSelectedRows();
	selectData.value = selectedRows;
}

function createTaskTemplate() {
	const doctype = 'Dw Task Template';
	const docName = frappe.model.make_new_doc_and_get_name(doctype, true);
	const docDetail = locals[doctype][docName] as locals.Doctype;
	docDetail.reference_type = 'Dw Organization Worklist';
	docDetail.reference_name = props.frm.docname;
	docDetail.organization = props.frm.doc.organization_unit;
	frappe.set_route('Form', doctype, docDetail.name);
}

async function confirm() {
	for (const template of taskTemplate.value) {
		if (template.plan_start) {
			template.plan_start = moment(template.plan_start).format('YYYY-MM-DD');
		} else {
			template.plan_start = '';
		}
		if (template.plan_end) {
			template.plan_end = moment(template.plan_end).format('YYYY-MM-DD');
		} else {
			template.plan_end = '';
		}
	}
	const currentOrganization = localStorage.getItem('TianjyOrganization/currentOrganization');
	const res = await frappe.call<{ message: [] }>({
		method: 'dw_worklist_management.dw_task_management.create_task_by_template.batch_create_task_by_template',
		args: {
			task_templates: taskTemplate.value,
			organization: currentOrganization,
			department: props.frm.doc.dw_department,
		},
	});
	if (res?.message) {
		ElMessage({
			message: __('Created Successfully'),
			type: 'success',
		});
		taskTemplate.value = [];
		dialogVisible.value = false;
		triggerEvent('organizationWorkListTaskChange');
	}
}

function cancelCreate() {
	cancelDialogVisible.value = false;
	dialogVisible.value = false;
}

async function setPermission() {
	manager.value = await getFormEditable();
}

async function disableTemplate() {
	deleteLoading.value = true;
	const allTemplate = [];
	visible.value = false;
	if (selectData.value.length) {
		for (const row of selectData.value) {
			const templateList = await frappe.db.get_list(
				'Dw Task Template',
				{
					filters: [
						['reference_type', '=', 'Dw Organization Worklist'],
						['reference_name', '=', props.frm.docname],
						['root_id', '=', row.root_id],
						['lft', '>=', row.lft],
						['rgt', '<=', row.rgt],
					],
					fields: ['name'],
					limit: 0,
				},
			);
			if (templateList.length > 0) {
				allTemplate.push(...templateList);
			}
		}
		if (allTemplate.length > 0) {
			for (const template of allTemplate) {
				await frappe.db.set_value(
					'Dw Task Template',
					template.name,
					{
						disable: 1,
					},
				);
			}
			ElMessage({
				type: 'success',
				message: __('Execution Succeeded'),
			});
			getTableData();
		}
		triggerEvent('getEnableTemplate');
		deleteLoading.value = false;
	}
}

const popstateListener = function () {
	getTableData();
};

onMounted(() => {
	getTaskType();
	standardWorklistName.value = props.frm.doc.dw_standard_worklist;
	getTableData();
	setPermission();
	gridApi.value?.setColumnDefs(columns);
	window.addEventListener('popstate', popstateListener);
});

onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});

</script>


<style lang="less" scoped>
.operation-button{
	margin-top: 10px;
	display: flex;
	flex-direction: row;
}
</style>
