import type { Key, IdKey, RowApi, RowEventMap, Emit, Listen } from '../types';
import createKey, { createIdKey } from '../createKey';
import type { Row, RowDataProxy } from '../types/Row';

import type Source from '.';
type RowInfo = [string | number, any, string | number | undefined];
function unique(
	rows: any[],
	idKey: (v: any) => string | number,
	parentKey: (v: any) => string | number | undefined,
): RowInfo[] {
	const list: RowInfo[] = [];
	const map = new Map<string | number, RowInfo>();
	for (const value of rows || []) {
		const id = idKey(value);
		let row = map.get(id);
		if (row) {
			row[1] = value;
			continue;
		}
		row = [id, value, parentKey(value)];
		map.set(id, row);
		list.push(row);
	}
	return list;
}

function toTree(rows: RowInfo[]): RowInfo[] {
	const ids = new Set(rows.map(v => v[0]));
	const root: RowInfo[] = [];
	const map = new Map<string | number, RowInfo[]>();
	for (const row of rows) {
		const parentId = row[2];
		if (parentId === undefined || !ids.has(parentId)) {
			root.push(row);
			continue;
		}
		const list = map.get(parentId);
		if (list) {
			list.push(row);
			continue;
		}
		map.set(parentId, [row]);
	}

	function* get(list: RowInfo[]): Iterable<RowInfo> {
		for (const row of list) {
			yield row;
			const id = row[0];
			const list = map.get(id);
			if (!list) { continue; }
			map.delete(id);
			yield* get(list);
		}
	}
	const list: RowInfo[] = [...get(root)];
	while (map.size) {
		const [id] = map.keys();
		const l = map.get(id);
		map.delete(id);
		if (!l) { continue; }
		for (const v of get(l)) {
			list.push(v);
		}
	}
	return list;
}
function createRowEl(setSelected: () => void) {
	const el = document.createElement('div');
	el.className = 'nyloong-table-row';
	el.addEventListener('click', e => {
		for (const v of e.composedPath()) {
			if (v === el) { break; }
			if (!(v instanceof Element)) { continue; }
			if (v.classList.contains('nyloong-table-selectable')) { return; }
		}
		setSelected();
	});
	el.appendChild(document.createElement('div')).className = 'nyloong-table-fixed-line';
	return el;
}

function createRowApi(
	source: Source,
	id: string | number,
	el: HTMLDivElement,
	listen: Listen<RowEventMap>,
	emit: Emit<RowEventMap>,
): RowApi {
	return {
		emit, listen,
		setCollapse: v => source.setCollapse(id, v),
		get collapsed() { return source.isCollapsed(id); },
		set collapsed(v) { source.setCollapse(id, v); },
		setChecked: v => { source.setChecked(id, v); },
		get checked() { return source.isChecked(id); },
		set checked(v) { source.setChecked(id, v); },
		addClass(...c) { el.classList.add(...c); },
		removeClass(...c) { el.classList.remove(...c); },
		hasClass(c) { return el.classList.contains(c); },
	};
}


export default function setValue<T extends object>(
	source: Source,
	rows: object[],
	setHover: (id?: string | number) => void,
	oldMap: Map<string | number, Row>,
	idKey?: IdKey<T>,
	parentKey?: Key<string | number, T>,
): [Row[], Map<string | number, Row>] {
	const infoList = toTree(unique(rows, createIdKey(idKey), createKey(parentKey)));
	const list: Row[] = [];
	const map = new Map<string | number, Row>();

	const updatedRow = new Set<Row>();
	const ancestorIds: (string | number)[] = [];
	const ancestors: Row[] = [];
	let index = 0;
	for (const [id, data, parentId] of infoList) {
		let row = map.get(id);
		if (row) {
			row.value.data = data;
			continue;
		}
		row = oldMap.get(id);
		const level = parentId === undefined ? 0 : ancestorIds.lastIndexOf(parentId) + 1;
		ancestorIds.length = level;
		ancestors.length = level;
		if (row) {
			oldMap.delete(id);
			updatedRow.add(row);
			row.index = index;
			row.parentId = level ? parentId : undefined;
			row.value = { id, data, parentId, level, children: [] };
			row.descendants = [];
			row.children = [];
		} else {

			const emit = source.emitRow.bind(null, id);
			const listen = source.listenRow.bind(null, id);
			row = {
				index, parentId,
				emit,
				listen,
				value: { id, data, parentId: level ? parentId : undefined, level, children: [] },
				id,
				descendants: [],
				children: [],
				createProxy() {
					const el = createRowEl(() => source.toggleSelected(this.id));
					el.addEventListener('pointerenter', () => { setHover(id); });
					el.addEventListener('pointerleave', () => { setHover(); });
					const elProxy: RowDataProxy = {
						el, index: this.index, row: this, cells: new Map(), shown: [],
						api: createRowApi(source, id, el, listen, emit),
					};
					return elProxy;
				},
			};
		}
		index++;
		const last = ancestors[ancestors.length - 1];
		if (last) {
			last.value.children.push(row.value);
			last.children.push(row);
		}
		for (const a of ancestors) {
			a.descendants.push(row);
		}
		ancestors.push(row);
		ancestorIds.push(id);
		map.set(id, row);
		list.push(row);
	}
	for (const row of updatedRow) {
		const { value, elMap } = row;
		if (!elMap) { continue; }
		for (const cell of [...elMap.values()].flatMap(e => [...e.cells.values()])) {
			if (!cell) { continue; }
			cell.update(value);
		}
	}
	for (const row of oldMap.values()) {
		const { elMap } = row;
		if (!elMap) { continue; }
		for (const cell of [...elMap.values()].flatMap(e => [...e.cells.values()])) {
			if (!cell) { continue; }
			cell.destroy();
		}
	}
	return [list, map];
}
