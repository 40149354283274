import type {BoqItem} from './type';
export interface CodeRule {
	digit: number
	character: string
}
/** 单据中的规则 */
export interface ResCodeRule{
	digit: number
	space_character: string
}
/** 单据中的编码域 */
export interface ResCodeScope {
	name: string
	sepco_code_scope: string

}
/**
 * @description 将编码规则中每一层级编码的数量及其对应的父编码数量枚举出来
 * @param rules 编码规则
 * @returns “{[key:层级上的编码长度]: 对应父级的编码长度}”
 */

function codeRuleParent(rules: CodeRule[]) {
	/** 将每一层级的digit 和 character 按照索引进行存储 */
	const digitIndex:number[] = rules.map(item=> item.digit);
	const characterIndex:number[] = rules.map(item=> (item.character || '').length);

	/** 每一层级编码长度及所对应的父级编码的长度，使用 {[key:编码长度]: 父级编码长度} 存储*/
	const ruleLevelParent: {[key:number]: number} = {};
	/** 每一层级编码的字符长度 使用[key: 层级]: 对应编码长度 存储 */
	const ruleLevelCodeCount:{[key:number]: number} = {};
	for (const [index] of rules.entries()) {
		if (index === 0) {
			ruleLevelCodeCount[index] = digitIndex[index];
			ruleLevelParent[digitIndex[index]] = 0;
			continue;
		}
		/** 当前层级的code长度 */
		let currentCodeCount = 0;
		for (let i = 0; i <= index; i++) {
			currentCodeCount += digitIndex[i];
			if ((i + 1) <= index) {
				currentCodeCount += characterIndex[i];
			}
		}
		ruleLevelCodeCount[index] = currentCodeCount;
		ruleLevelParent[currentCodeCount] = ruleLevelCodeCount[index - 1];
	}
	return ruleLevelParent;
}

/**
 * @description 获取编码规则单据
 * @returns 返回编码规则列表和编码域所选字段数组
 */
export async function getCodeRuleSetting(project: string) {
	const codeRuleSetting = await frappe.db.get_list(
		'Guigu Boq Item Code Rule Setting',
		{
			filters: [['project', '=', project]],
			fields: ['*'],
		},
	);
	if (codeRuleSetting.length === 0) {
		return [];
		// frappe.throw(__('Please Create Current Project Boq Item Code Rule'));
	}
	const resultDoc = await frappe.db.get_doc('Guigu Boq Item Code Rule Setting', codeRuleSetting[0].name, {});
	/** 编码规则列表 */
	const codeRuleList: CodeRule[] = resultDoc.rule.map((item:ResCodeRule) => ({
		digit: item.digit, character: item.space_character,
	}));

	return codeRuleList;
}
/**
 * @description 获取清单列表
 * @params project 项目值
 * @returns  当前项目下的清单列表
 */
export async function getBoqItemList(project: string): Promise<BoqItem[]> {
	const boqItemList = await frappe.db.get_list<BoqItem>(
		'Guigu Boq Item',
		{
			filters: [['project', '=', project]],
			fields: ['*'],
			limit: 0,
		},
	);
	return boqItemList;
}
/**
 * @description 获取清单状态对象
 * @returns  一个对象key为name，value为清单状态record
 */
export async function getBoqItemStatusListObj() {
	const boqItemTypeList = await frappe.db.get_list(
		'Guigu Boq Status',
		{
			fields: ['*'],
			limit: 0,
		},
	);
	const typeObj: {[key: string]: any} = {};
	for (const type of boqItemTypeList) {
		typeObj[type.name] = type;
	}
	return typeObj;
}
/**
 * @description 获取清单单位对象
 * @returns  一个对象key为name，value为清单单位record
 */
export async function getBoqItemUnitListObj() {
	const boqItemUnitList = await frappe.db.get_list(
		'Guigu Boq Unit',
		{
			fields: ['*'],
			limit: 0,
		},
	);
	const unitObj: {[key: string]: any} = {};
	for (const unit of boqItemUnitList) {
		unitObj[unit.name] = unit.boq_unit_name;
	}
	return unitObj;
}
/**
 * @description 获取清单类型对象
 * @param project 项目
 * @returns  一个对象key为name，value为清单状态record
 */
export async function getBoqItemTypeListObj(project: string) {
	const boqItemTypeList = await frappe.db.get_list(
		'Guigu Boq Type',
		{
			filters: [['project', '=', project]],
			fields: ['*'],
			limit: 0,
		},
	);
	const typeObj: {[key: string]: any} = {};
	for (const type of boqItemTypeList) {
		typeObj[type.name] = type.boq_type_name;
	}
	return typeObj;
}

/**
 * @description 获取清单ancestry列表
 * @param boqItemList 清单列表
 * @param codeRuleList 规则列表
 * @returns 带有ancestry字段的清单列表
 */
export async function getWorkListAncestry<T extends BoqItem>(
	boqItemList: T[],
	codeRuleList: CodeRule[],
) {
	/** 如果没有配置编码规则，简单处理后返回 */
	if (!codeRuleList.length) {
		return boqItemList;
	}
	/** 每一层级编码字符长度 对应的 父级编码对应的字符长度 */
	const curCodeToParentCodeObj = codeRuleParent(codeRuleList);
	const newBoqItemList = [...boqItemList].map(item => {
		/** 先把编码和编码域设定的字段组装起来 因为这是唯一标识 */

		const newCode = item.boq_item_code;

		/** 根据parentCodeLength计算ancestry数组 */
		const ancestry: string[] = [];
		const boqItemCodeLength = item.boq_item_code.length;
		/** key是当前code字符长度，value是对应的parentCode长度 */
		for (const codeLength of Object.keys(curCodeToParentCodeObj)) {
			if (codeLength === String(boqItemCodeLength)) {
				break;
			}
			ancestry.push(item.boq_item_code.substring(0, Number(codeLength)));
		}
		return {
			...item,
			newCode,
			ancestry: [...ancestry, newCode],
		};
	});
	return newBoqItemList;
}
