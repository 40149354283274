import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElTooltip"], {
    placement: "top",
    content: $props.description?.value,
    visible: $setup.props.isSilentMode && $setup.isTooltipVisible
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "node-container",
        onMouseenter: _cache[0] || (_cache[0] = $event => ($setup.isTooltipVisible = true)),
        onMouseleave: _cache[1] || (_cache[1] = $event => ($setup.isTooltipVisible = false))
      }, [
        (!$setup.props.isSilentMode)
          ? (_openBlock(), _createBlock($setup["ElTooltip"], {
              key: 0,
              placement: "top",
              content: $props.description?.value
            }, {
              default: _withCtx(() => [
                _createVNode($setup["ElIcon"], {
                  onClick: $setup.click,
                  size: 20,
                  style: { cursor: 'pointer' }
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["ChatLineRound"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["content"]))
          : _createCommentVNode("v-if", true)
      ], 32 /* HYDRATE_EVENTS */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["content", "visible"]))
}