

import { ComputedRef, Ref, WritableComputedRef } from 'vue';

import * as services from '../services';
import type { Field, Filter, Kanban, Column, Group } from '../types';

function toValue(v: any) {
	return ['string', 'number'].includes(typeof v) ? v : '';
}
function getNewColumns(
	list: Column[],
	data: Partial<Column> & { value: any, index?: number } | boolean | number | string
): Column[] | void {
	const columns: Column[] = list.map(({ title, value, color, bgColor }) => ({
		title, value: toValue(value), color, bgColor,
	}));
	if (typeof data !== 'object') {
		const val = toValue(data);
		const col = columns.findIndex(v => v.value === val);
		if (col < 0) { return; }
		columns.splice(col, 1);
		return columns;
	}
	const { title, color, bgColor, value, index } = data;
	const val = toValue(value);
	let updated = false;
	let oldIndex = columns.findIndex(v => v.value === val);
	if (oldIndex < 0) {
		oldIndex = columns.push({ value: val, title: val }) - 1;
		updated = true;
	}
	const col = columns[oldIndex];
	if (typeof title === 'string' && col.title !== title) {
		col.title = title;
		updated = true;
	}
	if ((typeof color === 'string' || color === null) && col.color !== color) {
		col.color = color;
		updated = true;
	}
	if ((typeof bgColor === 'string' || bgColor === null) && col.bgColor !== bgColor) {
		col.bgColor = bgColor;
		updated = true;
	}
	if (index && index > 0) {
		const k = index - 1;
		if (k !== oldIndex) {
			columns.splice(k, 0, ...columns.splice(oldIndex, 1));
			updated = true;
		}
	}
	if (!updated) { return; }
	return columns;
}


function getNewGroups(
	groups: Group[],
	field: string,
	data: Partial<Column> & { value: any, index?: number } | boolean | number | string
): Group[] | void {
	const index = groups.findIndex(g => g.field === field);
	if (index < 0) { return; }
	const group = groups[index];
	if (!group) { return; }
	const values = getNewColumns(group.values, data);
	if (!values) { return; }
	return [
		...groups.slice(0, index),
		{ ...group, values },
		...groups.slice(index + 1),
	];
}


export default function useServices(
	doctype: ComputedRef<string>,
	kanbanList: Ref<Kanban[]>,
	kanban: ComputedRef<Kanban | undefined>,
	loadingCount: Ref<number>,
	loading: ComputedRef<boolean>,
	data: Ref<any[]>,
	stages: ComputedRef<Group[]>,
	init: (name?: string) => Promise<void>,
	refresh: () => Promise<void>
) {

	async function update(name: string, value: any) {
		if (loading.value) { return; }
		loadingCount.value++;
		await services.updateDoc(doctype.value, name, value)
			.then(() => { refresh(); }, () => { data.value = [...data.value]; })
			.finally(() => { loadingCount.value--; });
	}

	async function createKanban(data: {
		private?: boolean;
		title?: string;
		groups?: any;
		filters?: any;
		fields?: any;
	}) {
		if (loading.value) { return; }
		loadingCount.value++;
		try {
			const kb = await services.create(doctype.value, data);
			await init(kb);
		} finally {
			loadingCount.value--;
		}
	}
	function kanbanRun<T extends any[]>(fn: (kb: Kanban, ...p: T) => any) {
		return async (kanban: Kanban | string | undefined, ...p: T) => {
			if (loading.value) { return; }
			const kb = typeof kanban === 'string'
				? kanbanList.value.find(k => k.name === kanban)
				: kanban;
			if (!kb) { return; }
			loadingCount.value++;
			try {
				return await fn(kb, ...p);
			} finally {
				loadingCount.value--;
			}
		};
	}
	const saveKanban = kanbanRun(async (kb, { title, fields, filters, groups }: {
		title?: string;
		fields?: Field[];
		filters?: Filter[];
		groups?: Group[];
	}) => {
		const data: {
			title?: string;
			fields?: Field[];
			filters?: Filter[];
			groups?: Group[];
		} = {};
		const updateFn = new Set<() => void>();
		if (title && typeof title === 'string') {
			data.title = title;
			updateFn.add(() => { kb.title = title; });
		}
		if (Array.isArray(fields)) {
			data.fields = fields;
			updateFn.add(() => { kb.fields = fields; });
		}
		if (Array.isArray(filters)) {
			data.filters = filters;
			updateFn.add(() => { kb.filters = filters; });
		}
		if (Array.isArray(groups)) {
			data.groups = groups;
			updateFn.add(() => { kb.groups = groups; });
		}
		if (!updateFn.size) { return; }
		await services.save(kb.name, data);
		for (const fn of updateFn) { fn(); }
	});
	const deleteKanban = kanbanRun(async kb => {
		const { name } = kb;
		await services.del(name);
		kanbanList.value = kanbanList.value.filter(k => k.name !== name);
	});

	function saveGroupValue(
		field: string,
		data: Partial<Column> & { value: any, index?: number } | boolean | number | string
	) {
		const kb = kanban.value;
		if (!kb) { return; }
		const groups = getNewGroups(stages.value, field, data);
		if (!groups) { return; }
		saveKanban(kanban.value, { groups });
	}
	return {
		update,
		saveKanban,
		createKanban,
		deleteKanban,
		saveGroupValue,
	};

}
