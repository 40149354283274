import type { RowValue } from '../core';

import getDate from './getDate';
import getLineDates from './getLineDates';
import type { LineInfo } from './getLineDates';
import type { LineMeta } from './types';

function toLineData(
	data: any,
	dateDate: Record<string, Date | undefined>,
	endDateData: Record<string, Date | undefined>,
	lines: LineInfo[],
	dateMap: WeakMap<any, [([Date, Date | null, LineMeta?] | null)[], Date, Date]>,
): [([Date, Date | null, LineMeta?] | null)[], Date, Date] {
	const val = dateMap.get(data);
	if (val) { return val; }
	const dates = getLineDates(data, dateDate, endDateData, lines);
	let startDate = new Date();
	let endDate = new Date();
	startDate.setHours(0, 0, 0, 0);
	endDate.setHours(23, 59, 59, 999);
	for (const date of dates) {
		if (!date) { continue; }
		const [s, e] = date;
		if (startDate > s) { startDate = s; }
		if (e && endDate < e) { endDate = e; }
	}
	dateMap.set(data, [dates, startDate, endDate]);
	return [dates, startDate, endDate];
}

export default function getLineData(
	allData: readonly RowValue[],
	allDateData: Map<string | number, Record<string, Date | undefined>>,
	allEndDateData: Map<string | number, Record<string, Date | undefined>>,
	lines: LineInfo[],
	summarize: (v: any) => boolean | undefined,
	todayStart: Date,
	todayEnd: Date,
): [
		Date, Date,
		Map<number | string, ([Date, Date | null, LineMeta?] | null)[]>,
	] {
	let lineStartDate = todayStart;
	let lineEndDate = todayEnd;
	const allLineData = new Map<number | string, ([Date, Date | null, LineMeta?] | null)[]>;
	const baseDates = new WeakMap<any, [([Date, Date | null, LineMeta?] | null)[], Date, Date]>();

	function getShownDate(
		v: RowValue,
	) {
		const { id, data } = v;
		const val = allLineData.get(id);
		if (val) { return val; }

		const [dates, start, end] = toLineData(
			data,
			allDateData.get(id) || {},
			allEndDateData.get(id) || {},
			lines,
			baseDates,
		);
		allLineData.set(id, dates);
		if (lineStartDate > start) { lineStartDate = start; }
		if (lineEndDate < end) { lineEndDate = end; }
		const children = summarize(data)
			&& v.children.map(v => getShownDate(v)) || [];
		if (!children.length) { return dates; }
		const shown: typeof dates = lines.map(({ meta }, i) => {
			const s = getDate(children.map(v => v[i]?.[0]));
			if (!s) { return null; }
			return [s, getDate(children.map(v => v[i]?.[1]), true), meta];
		});
		allLineData.set(id, shown);
		return shown;
	}
	for (const v of allData) {
		getShownDate(v);
	}
	return [lineStartDate, lineEndDate, allLineData];
}
