<template>
	<ElSelect
		v-model="selectedProgress"
		filterable
		remote
		reserve-keyword
		:placeholder="tt('Please enter keywords')"
		:style="{width:'100%'}"
		@change="change"
		:remote-method="remoteMethod"
		:loading="loading">
		<ElOption
			v-for="item in options"
			:key="item.value"
			:label="item.label"
			:value="item.value" />
	</ElSelect>
</template>
<script lang="ts">
	import { nextTick,  onMounted, ref, defineProps, defineExpose, defineComponent, getCurrentInstance } from 'vue';
	const tt = __
	interface ListItem {
		value: string
		label: string
	}

	export default defineComponent({
		props:['params'],
		setup(props, context) {
			const {appContext} = getCurrentInstance()||{};
			const selectedProgress = ref();
			const selectedProgressObj = ref();
			const loading = ref<boolean>(false);
			const options = ref<ListItem[]>([]);
			function getValue(){
				return selectedProgressObj.value||props.params.value;
			}
			function change(v:string){
				selectedProgressObj.value = options.value.find(item=>item.value === v);
				props.params.api.stopEditing();
			}
			function remoteMethod (query: string) {
				if (query) {
					loading.value = true;
					setTimeout(async () => {
						loading.value = false;
						const data = await frappe.call<{ message: {name:string, structure_ancestry_label:string, label:string, type:'progress'|'temp'}[] }>({
							method: 'guigu_pm.guigu_pm_construction.doctype.guigu_construction_diary.guigu_construction_diary.search_progress',
							args: {
								query_string:query,
								date:appContext?.config.globalProperties.$frm.doc.date,
								structure: appContext?.config.globalProperties.$frm.doc.guigu_construction_structure,
								selected_structure_progresses:appContext?.config.globalProperties.$frm.doc.progress_actual?.map(item=>item.guigu_construction_structure_progress)||[],
								selected_temp_progress_names:appContext?.config.globalProperties.$frm.doc.temp_actual.map(item=>item.temp_progress_name)||[],
							},
						});
						options.value = data?.message.map(item=>{
							if (item.type==='progress'){
								return {value:item.name, label:item.structure_ancestry_label, type:item.type};
							}
								return {value:item.name, label:item.label, type:item.type};
						})||[];
					}, 200);
				} else {
					options.value = [];
				}
			}
			return {
				selectedProgress,
				getValue,
				loading,
				remoteMethod,
				options,
				change,
			};
		},
	});


</script>
