import type { Filter } from '../types';
export function setFiltersFromRouteOptions(
	filterList: [any, any, any, any][],
	allFilters: Filter[],
) {
	for (const [d, f, c, v] of filterList) {
		allFilters.push({ doctype: d, field: f, condition: c, value: v });
	}
}

export default function parseFiltersFromRouteOptions(
	currentDoctype: string
): [string, Filter[]] | undefined {
	const options = frappe.route_options;
	frappe.route_options = null;
	if (!options) { return; }
	const filters: Filter[] = [];

	let name = '';
	for (let [field, value] of Object.entries(options)) {
		let doctype: null | string = null;
		if (field === '') {
			if (value && typeof value === 'string') {
				name = value;
			}
			name = value as string;
		}
		if (field.includes('.')) {
			[doctype, field] = field.split('.');
		}
		doctype ||= frappe.meta.get_doctype_for_field(currentDoctype, field);
		if (!doctype) { continue; }

		if (Array.isArray(value) && value[0].startsWith('[') && value[0].endsWith(']')) {
			for (const v of value) {
				const value = JSON.parse(v);
				if (Array.isArray(value)) {
					filters.push({ doctype, field, condition: value[0], value: value[1] });
				} else {
					filters.push({ doctype, field, condition: '=', value });
				}
			}
			continue;
		}
		if (typeof value === 'string' && value.startsWith('[') && value.endsWith(']')) {
			value = JSON.parse(value);
		}
		if (Array.isArray(value)) {
			filters.push({ doctype, field, condition: value[0], value: value[1] });
		} else {
			filters.push({ doctype, field, condition: '=', value });
		}
	}
	if (!name) { return; }
	return [name, filters];
}
