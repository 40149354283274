<template>
	<Page>
		<template #title>
			<h3 class="title">{{ tt("Photo List") }}</h3>
		</template>
		<template #tools>
			<ToolBar
				@projectChange="projectChange"
				@dateRangeChange="dateRangeChange"
				@sortChange="sortChange"
				@isIncludeChildrenChange="isIncludeChildrenChange"
				:structureOrProgress="structureOrProgress"
				:photoList="photoList"></ToolBar>
		</template>
		<template #sider>
			<StructureTree
				v-if="project"
				:project="project"
				@structureChange="structureChange"></StructureTree>
		</template>
		<PhotoTimeline
			:project="project"
			:structureOrProgress="structureOrProgress.name"
			:startDate="startDate"
			:endDate="endDate"
			:sort="sort"
			:isIncludeChildren="isIncludeChildren"
			@photoListChange="photoListChange"></PhotoTimeline>
	</Page>
</template>
<script lang="ts" setup>
import {ref} from 'vue';

import Page from '../../../components/page/index.vue';

import StructureTree from './StructureTree.vue';
import ToolBar from './ToolBar.vue';
import PhotoTimeline from './PhotoTimeline.vue';
import {Photo, StructureORProgress} from './type';
const tt = __;
const project = ref<string>();
const sort = ref<'desc' | 'asc'>('desc');
const structureOrProgress = ref<StructureORProgress>({});
const startDate = ref<string>();
const endDate = ref<string>();
const photoList = ref<Photo[]>([]);
const isIncludeChildren = ref<boolean>(false);
function projectChange(newProject: string) {
	project.value = newProject;
}
function dateRangeChange(dateRange: [string, string]) {
	[startDate.value, endDate.value] = dateRange;
}
function sortChange(newSort: 'desc' | 'asc') {
	sort.value = newSort;
}
function structureChange(newStructureORProgress: StructureORProgress) {
	structureOrProgress.value = newStructureORProgress;
}
function isIncludeChildrenChange(data: boolean) {
	isIncludeChildren.value = data;
}
function photoListChange(newPhotoList: Photo[]) {
	photoList.value = newPhotoList;
}
</script>
<style scoped lang="less">
.title{
	margin-bottom: 0;
}
</style>
