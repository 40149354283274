<script setup lang="ts">
import FieldRenderer from '../FieldRenderer/index.vue';

import type Column from './lib/types/Column';
import type RowValue from './lib/types/RowValue';

interface Meta {
	docField: locals.DocField;
	meta: locals.DocType;
	enableLink?: boolean;
	format?: string;
	setFilter?(field: string, operator: string, value: any): void;
}

const props = defineProps<{
	value: any;
	data: Record<string, any>;
	column: Column
	level: number;
	children: RowValue[];
	meta: Meta
}>();

function setFilter(field: string, operator: string, value: any) {
	const { meta } = props;
	const { setFilter } = meta;
	if (!setFilter) { return; }
	setFilter(field, operator, value);

}

</script>
<template>
	<FieldRenderer
		:value="value"
		:field="meta.docField"
		:enableLink="meta.enableLink"
		:data="data"
		:meta="meta.meta"
		:format="meta.format"
		inline
		showTitle
		@filter="setFilter" />
</template>
