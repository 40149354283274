
<template>
	<div v-if="text" class="selectedText">{{ text }}</div>
</template>

<script setup lang='ts'>
import {computed} from 'vue';

defineOptions({inheritAttrs: false});

const props = defineProps<{
	selected: any[];
	meta: locals.DocType;
}>();
const text = computed(() => {
	const n = props.selected?.length;
	if (!n || n < 2) {
		return '';
	}
	return `已选择${n}条`;
});
</script>

<style lang="less" scoped>
.selectedText {
	margin-inline-end: 8px;
}
</style>
