<template>
	<div>
		<a :style="style" :href="href" :title="props.params.value" @click="click">{{ params.value }}</a>
	</div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
const props = defineProps<{params:any}>();

const linkable = computed(()=>props.params.data.doc_name&& props.params.data.link_doctype);
const href = computed(()=>{
	const docName = props.params.data.doc_name;
	if (!docName) { return '#'; }
	const doctype = props.params.data.link_doctype;
	if (!doctype) { return '#'; }
	return `/app/${frappe.router.slug(doctype)}/${docName}`;
});
const style={
	color:linkable.value?'#409eff':'',
	cursor:linkable.value?'pointer':'default',
};

function click(e: Event) {
	if (linkable.value) { return; }
	e.preventDefault();
}
</script>
