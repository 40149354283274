<template>
	<div :id="uniKey" ref="infoRef">
      <a name="uniKey" :href="`#${uniKey}`" ></a>
        <div
          v-loading="Boolean(!diary)"
          :class="{'diary-info':true, loading:Boolean(!diary)}"
        >
            <div class='report-info-container' v-if="diary">
              <div class="report-info_title">
                {{`${teamStructure.teamName}(${teamStructure.structureName})`}}
              </div>
              <Progress
                :progress="diary?.progress_actual || []"
                :enableAudit="enableAudit"
              ></Progress>
              <Description :description="diary?.description" />
              <Person
                :personList="diary.labourer_type_actual || []"
                :enableAudit="enableAudit"
              />
              <Machine
                :machineList="diary.machine_type_actual || []"
                :enableAudit="enableAudit"
              />
              <Problem :problems="diary?.field_problems || []" />
              <Images :images="diary?.structure_image || []" />
            </div>
        </div>
    </div>
</template>

<script setup lang='ts'>
import { ref, defineProps, defineEmits, toRefs, computed, defineExpose } from 'vue';

import { Diary, TeamStructure } from '../type';

import Description from './Description.vue';
import Images from './Images.vue';
import Machine from './Machine.vue';
import Person from './Person.vue';
import Problem from './Problem.vue';
import Progress from './Progress.vue';
const infoRef = ref();
defineExpose({infoRef});
interface Props{
	uniKey: string;
	teamStructure: TeamStructure;
	dayDiaries:Record<string, Diary>
  enableAudit:boolean
}
const props = defineProps<Props>();
const {uniKey, teamStructure, dayDiaries, enableAudit} = toRefs(props);
interface Emit{

}
const emit = defineEmits<Emit>();
const diary = computed(()=>{
	const data = dayDiaries.value[uniKey.value];
	return data;
});

</script>

<style lang='less' scoped>
.diary-info {
  overflow: hidden;
  min-height: 300px;
  height: auto;
}

.loading {
  height: 400px;
}

.report-info-container {
  border: 1px solid #ebebeb;
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 8px;
}

.report-info_title {
  text-align: left;
  font-weight: bolder;
  font-size: 16px;
}

:deep(.report-info_label) {
  text-align: left;
  font-weight: bolder;
  margin-top: 8px;
}
</style>
