
frappe.guigu.GlobalView.settings['*'] = {
	sort: [
		{
			label(
				modelValue: frappe.guigu.GlobalView.Setting.DragInfo,
				meta: locals.DocType,
			) {
				const sort_field = meta.guigu_sort_field;
				if (!sort_field) { return ''; }
				const field = meta.fields.find(r =>r.fieldname === sort_field && r.fieldtype === 'Int');
				if (!field) { return ''; }
				return `按照 ${__(field.label || field.fieldname)} 升序排序（放在当前项之前）`;
			},
			action(
				modelValue: frappe.guigu.GlobalView.Setting.DragInfo,
				meta: locals.DocType,
			) {
				frappe.call('guigu.doc.sort.sort_int', {
					doctype: meta.name,
					target: modelValue.target.name,
					docs: modelValue.list.map(v => v.name),
					before: true,
				});
			},
		},
		{
			label(
				modelValue: frappe.guigu.GlobalView.Setting.DragInfo,
				meta: locals.DocType,
			) {
				const sort_field = meta.guigu_sort_field;
				if (!sort_field) { return ''; }
				const field = meta.fields.find(r =>r.fieldname === sort_field && r.fieldtype === 'Int');
				if (!field) { return ''; }
				return `按照 ${__(field.label || field.fieldname)} 升序排序（放在当前项之后）`;
			},
			action(
				modelValue: frappe.guigu.GlobalView.Setting.DragInfo,
				meta: locals.DocType,
			) {
				frappe.call('guigu.doc.sort.sort_int', {
					doctype: meta.name,
					target: modelValue.target.name,
					docs: modelValue.list.map(v => v.name),
				});
			},
		},
	],

};
