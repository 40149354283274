import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dcd6c6f9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Page"], null, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.tt('Organizational configuration')), 1 /* TEXT */)
    ]),
    sider: _withCtx(() => [
      _createVNode($setup["OrganizationTree"], {
        modelValue: $setup.organization,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.organization) = $event)),
        permissions: $setup.organizationPermissions
      }, null, 8 /* PROPS */, ["modelValue", "permissions"])
    ]),
    default: _withCtx(() => [
      _createVNode($setup["ElTabs"], {
        modelValue: $setup.activeName,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.activeName) = $event)),
        class: "organization-tabs"
      }, {
        default: _withCtx(() => [
          _createVNode($setup["ElTabPane"], {
            class: "tab-container",
            label: $setup.tt('Basic Information'),
            name: "info"
          }, {
            default: _withCtx(() => [
              _createVNode($setup["FormDetail"], {
                name: $setup.organization?.name,
                doctype: "Tianjy Organization"
              }, null, 8 /* PROPS */, ["name"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          ($setup.organization?.type_doc.no_workspace===0)
            ? (_openBlock(), _createBlock($setup["ElTabPane"], {
                key: 0,
                class: "tab-container",
                label: $setup.tt('Workspace'),
                name: "workspace"
              }, {
                default: _withCtx(() => [
                  ($setup.organization)
                    ? (_openBlock(), _createBlock($setup["Workspace"], {
                        key: 0,
                        organization: $setup.organization.name
                      }, null, 8 /* PROPS */, ["organization"]))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]))
            : _createCommentVNode("v-if", true),
          _createVNode($setup["ElTabPane"], {
            class: "tab-container",
            label: $setup.tt('Team Member'),
            name: "users"
          }, {
            default: _withCtx(() => [
              ($setup.organization)
                ? (_openBlock(), _createBlock($setup["Member"], {
                    key: 0,
                    organization: $setup.organization.name,
                    permissions: $setup.memberPermissions
                  }, null, 8 /* PROPS */, ["organization", "permissions"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTabPane"], {
            class: "tab-container",
            label: $setup.tt('Inherit'),
            name: "inherit"
          }, {
            default: _withCtx(() => [
              ($setup.organization)
                ? (_openBlock(), _createBlock($setup["Inherit"], {
                    key: 0,
                    organization: $setup.organization.name,
                    permissions: $setup.inheritPermissions
                  }, null, 8 /* PROPS */, ["organization", "permissions"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTabPane"], {
            class: "tab-container",
            label: $setup.tt('Inheritors'),
            name: "inherit_members"
          }, {
            default: _withCtx(() => [
              ($setup.organization)
                ? (_openBlock(), _createBlock($setup["InheritMember"], {
                    key: 0,
                    organization: $setup.organization.name,
                    permissions: $setup.inheritPermissions
                  }, null, 8 /* PROPS */, ["organization", "permissions"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ]),
    _: 1 /* STABLE */
  }))
}