/**
 * DocType 的默认视图选择中，增加自定义的视图
 */

import { viewsList } from '../Views';

frappe.model.set_default_views_for_doctype = function (doctype: string, frm) {
	frappe.model.with_doctype(doctype, () => {
		let meta = frappe.get_meta(doctype)!;
		let default_views: (string | {
			value: string;
			label: string;
		})[] = ['List', 'Report', 'Dashboard', 'Kanban'];

		if (meta.is_calendar_and_gantt && frappe.views.calendar[doctype]) {
			let views = ['Calendar', 'Gantt'];
			default_views.push(...views);
		}

		if (meta.is_tree) {
			default_views.push('Tree');
		}

		if (frm.doc.image_field) {
			default_views.push('Image');
		}

		if (doctype === 'Communication' && frappe.boot.email_accounts.length) {
			default_views.push('Inbox');
		}

		if (
			(frm.doc.fields.find(i => i.fieldname === 'latitude') &&
				frm.doc.fields.find(i => i.fieldname === 'longitude')) ||
			frm.doc.fields.find(
				i => i.fieldname === 'location' && i.fieldtype == 'Geolocation',
			)
		) {
			default_views.push('Map');
		}
		default_views.push(...viewsList);

		frm.set_df_property('default_view', 'options', default_views);
	});
};
