import parseArray from './parseArray';

export function getFilterField({
	filter_field,
	filter_field_all_show,
	filter_field_all_label,
	filter_field_null_show,
	filter_field_children_show,
	filter_field_null_label,
	filter_field_filters,
	filter_field_or_filters,
	filter_field_sorts,
	filter_field_level_unfolded,
}: {
	filter_field?: string | null;
	filter_field_all_show?: 0 | 1;
	filter_field_all_label?: string | null;
	filter_field_null_show?: 0 | 1;
	filter_field_children_show?: 0 | 1;
	filter_field_null_label?: string | null;
	filter_field_filters?: string | null;
	filter_field_or_filters?: string | null;
	filter_field_sorts?: string | null;
	filter_field_level_unfolded?: number
}, meta: locals.DocType) {
	if (!filter_field) { return; }
	const field = meta.fields.find(v => v.fieldname === filter_field);
	if (!field) { return; }
	const filterField: GlobalView.FilterField = {
		fieldname: field.fieldname,
		fieldtype: field.fieldtype,
		options: field.options,
		levelUnfolded: filter_field_level_unfolded || 0,
		filters: parseArray(filter_field_filters),
		orFilters: parseArray(filter_field_or_filters),
		order: parseArray(filter_field_sorts),
		showChildren: filter_field_children_show||0,
	};
	if (filter_field_all_show) {
		filterField.allLabel = __(filter_field_all_label || 'All') || __('All');
	}
	if (filter_field_null_show) {
		filterField.nullLabel = __(filter_field_null_label || 'Null') || __('Null');
	}
	return filterField;

}
