<template>
	<span :title="title">
		{{ value }}
	</span>
</template>
<script lang="ts" setup>
import { computed } from 'vue';


const props = defineProps<{
	value: any;
	data: Record<string, any>;
	field: GlobalView.FieldRenderOptions;
	meta?: locals.DocType;
	maxLine?: number;
	enableLink?: boolean;
	noData?: boolean;
	inline?: boolean;
	format?: string;
	showTitle?: boolean;
}>();
const emit = defineEmits<{
	(event: 'filter', field: string, operator: string, value: any): void;
}>();
const field = computed(() => props.field);
const value = computed(() => {
	const duration = moment.duration(props.value, 'seconds');
	const days = duration.days();
	const hours = duration.hours();
	const minutes = duration.minutes();
	const seconds = duration.seconds();
	return `${days}天 ${hours}小时 ${minutes}分钟 ${seconds}秒`;
});
const title = computed(() => {
	if (!props.showTitle) { return; }
	return `${__(field.value.label)}: ${value.value}`;
});
function setFilter() {
	emit('filter', props.field.fieldname, '=', props.value);

}
const tt = __;

</script>
