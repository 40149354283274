<template>
	<AgGridVue
		class="ag-theme-guigu table"
		style="width: 100%;margin: 0;overflow: auto;"
		rowSelection="multiple"
		:rowData="dataList"
		:columnDefs="columnDefs"
		domLayout="autoHeight"
		:defaultColDef="defaultColDef"
		animateRows="true"
		@grid-ready="onGridReady"
		:getRowId="getRowId"
		:suppressDragLeaveHidesColumns="smallMeta"
		:localeText="zhCN"
	></AgGridVue>
</template>
<script setup lang="ts">
import {ref} from 'vue';
import {AgGridVue} from 'ag-grid-vue3';
import type {CellClassParams, GridApi, ICellEditorParams, ICellRendererParams} from 'ag-grid-community';
import moment from 'moment';

import zhCN from '../../../../../../../guigu/guigu/public/js/agGrid/zhCN';

import type {OrganizationProcessData} from './type';
import ActionComponent from './Action.vue';

const props = defineProps<{
	dataList: OrganizationProcessData[];
	smallMeta: boolean;
	relatePermission:boolean
}>();
const emit = defineEmits<{
	(e: 'showDetail', data:OrganizationProcessData): void;
	(e: 'unLink', data:OrganizationProcessData): void;
}>();
const gridApi = ref<GridApi>();
// 点击组织单元工作清单时查看详情
function viewDetail(data: OrganizationProcessData) {
	emit('showDetail', data);
}
const defaultColDef = {
	sortable: true,
	filter: true,
	resizable: true,
	suppressMovable: true,
};
function unLink(data:OrganizationProcessData) {
	emit('unLink', data);
}

const columnDefs = [
	{
		headerName: __('Work List Title'),
		field: 'label',
		editable: false,
		filter: false,
		flex: 1,
		cellRenderer: (params:ICellRendererParams<OrganizationProcessData>)=>{
			const {data} = params;
			if (!data) {
				return;
			}
			const span = document.createElement('a');
			span.innerText = data.label;
			span.addEventListener('click', ()=> viewDetail(data));
			return span;
		},
	},
	{
		headerName: __('Related Date'),
		field: 'relate_date',
		editable: false,
		filter: false,
		flex: 1,
		valueGetter: (params: CellClassParams) => moment(params.data.relate_date).format('YYYY-MM-DD'),
	},
	{
		headerName: __('Actions'),
		field: 'actions',
		editable: false,
		filter: false,
		width: 90,
		cellRenderer: ActionComponent,
		cellRendererParams: (params: ICellEditorParams) => {
			const fn: {
				unLink?: (data: any) => void
			} = {};
			if (props.relatePermission) {
				fn.unLink = unLink;
			}

			return {...params, ...fn};
		},
	},
];

function onGridReady(params: CellClassParams) {
	gridApi.value = params.api;
}
function getRowId(params: CellClassParams) {
	return params.data.name;
}

defineExpose({
	gridApi,
});
</script>
<style scoped>
:deep(.ag-group-contracted){
	height: 100% !important;
}
:deep(.ag-group-expanded){
	height: 100% !important;
}
:deep(.ag-group-child-count){
	height: 100% !important;
}
</style>
