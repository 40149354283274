<template>
	<ElDialog v-model="visible" title="过滤" modal
		width="max(min(400px, 95%), 50%)">
		<Item v-for="filter in filters" :key="filter.key"
			:fields="fields"
			:meta="meta"
			:withSession="withSession"
			v-model:doctype="filter.doctype"
			v-model:field="filter.field"
			v-model:condition="filter.condition"
			v-model:value="filter.value"
			@remove="remove(filter)" />

		<template #footer>
			<span class="footer">
				<ElButton @click="add" title="添加" :icon="Plus" />
				<div class="placeholder" />
				<ElButtonGroup>
					<ElButton @click="clean" title="清空" :icon="Delete" />
					<ElButton @click="visible = false" title="取消"
						:icon="Close" />
					<ElButton type="primary" @click="submit" title="确认"
						:icon="Finished" />
				</ElButtonGroup>
			</span>
		</template>
	</ElDialog>
</template>
<script lang="ts" setup>
import {computed, shallowReactive} from 'vue';
import {ElDialog, ElButton, ElButtonGroup} from 'element-plus';
import {Plus, Finished, Delete, Close} from '@element-plus/icons-vue';

import build_options from './build_options';
import Item from './Item.vue';
const props = defineProps<{
	meta: locals.DocType;
	modelValue?: any[];
	visible: boolean;
	withSession?: boolean;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', filters: Filter[]): void;
	(event: 'update:visible', visible: boolean): void;
}>();

const visible = computed({
	get() {
		return props.visible;
	},
	set(v) {
		emit('update:visible', v);
	},
});
let nextId = 1;
interface Filter {
	key: number;
	doctype: string;
	field: string;
	condition?: string;
	value?: any;
}
const doctype = computed(() => props.meta.name);
const filters = computed<Filter[]>(() => {
	if (!visible.value) {
		return shallowReactive([]);
	}
	const list = (props.modelValue || []).map(f => shallowReactive({
		...f,
		key: nextId++,
	}));
	if (!list.length) {
		list.push(shallowReactive({
			key: nextId++,
			doctype: doctype.value,
			field: 'name',
		}));
	}
	return shallowReactive(list);
});
function submit() {
	emit('update:modelValue', filters.value.map(f => ({...f})));
	visible.value = false;
}
function clean() {
	filters.value.length = 0;
}
const fields = computed(() => build_options(doctype.value));
function add() {
	filters.value.push(shallowReactive({
		key: nextId++,
		doctype: doctype.value,
		field: 'name',
	}));
}
function remove(filter: Filter) {
	const index = filters.value.indexOf(filter);
	if (index >= 0) {
		filters.value.splice(index, 1);
	}
}
</script>
<style scoped>
.footer {
	width: 100%;
	display: flex;
	flex-direction: row;
}

.placeholder {
	flex: 1;
}
</style>
