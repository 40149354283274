import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "el-upload__text" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_upload = _resolveComponent("el-upload")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_upload, {
      class: "upload-demo",
      drag: "",
      accept: "image/bmp,image/png,image/jpeg,image/gif",
      "show-file-list": false,
      "on-progress": $setup.onProgress,
      "file-list": $setup.fileList
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_icon, { class: "el-icon--upload" }, {
          default: _withCtx(() => [
            _createVNode($setup["UploadFilled"])
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("div", _hoisted_1, _toDisplayString($setup.tt('Click or drag the image here to upload it')), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["file-list"]),
    _createVNode($setup["UploadDialog"], {
      visible: $setup.visible,
      fileList: $setup.fileList,
      structureDescendants: $setup.props.structureDescendants,
      date: $setup.props.date,
      showDateInput: $setup.props.showDateInput,
      onClose: $setup.close,
      onConfirm: $setup.confirm
    }, null, 8 /* PROPS */, ["visible", "fileList", "structureDescendants", "date", "showDateInput"])
  ], 64 /* STABLE_FRAGMENT */))
}