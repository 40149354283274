/**
 * 原实现中，同一个 doctype 只会实现一个 ScriptManager
 * 但在 FormDetail 中，每一次现实都会新建一个 ScriptManager
 * 为了避免重复执行 __js 中的代码，加上去重处理
 *
 * 至于最后的 extend_cscript，是因为 DocType 和 CustomizeForm 会为当前表单的 cscript 增加属性
 */
const oldSetup = frappe.ui.form.ScriptManager.prototype.setup;
const added = new Set();
frappe.ui.form.ScriptManager.prototype.setup = function setup() {

	const doctype = this.frm.meta.name;
	if (!added.has(doctype)) {
		added.add(doctype);
		oldSetup.call(this);
		return;
	}
	if (doctype !== 'DocType' && doctype !== 'CustomizeForm') {
		return;
	}
	extend_cscript(cur_frm.cscript, new frappe.model.DocTypeController({ frm: cur_frm }));

};
