<template>
	<ElButtonGroup v-if="allButtons.length">
		<template v-for="b in allButtons">
			<ElDropdown v-if="b.children">
				<ElButton :title="b.label" round>
					{{ b.label }}
					<ElIcon class="el-icon--right">
						<ArrowDown />
					</ElIcon>
				</ElButton>
				<template #dropdown>
					<ElDropdownMenu>
						<ElDropdownItem v-for="s in b.children" :title="s.label">
							<a :href="s.url">
								{{ s.label }}
							</a>
						</ElDropdownItem>
					</ElDropdownMenu>
				</template>
			</ElDropdown>
			<ElButton v-else :title="b.label" round>
				<a :href="b.url">
					{{ b.label }}
				</a>
			</ElButton>
		</template>
	</ElButtonGroup>
</template>
<script setup lang="ts">
import { computed } from 'vue';

import {
	ElButton, ElButtonGroup,
	ElDropdown, ElDropdownMenu, ElDropdownItem,
	ElIcon,
} from 'element-plus';
import { ArrowDown } from '@element-plus/icons-vue';

// TODO: 分级生成
const props = defineProps<{
	buttons: GlobalView.Button[];
}>();
interface Button {
	label: string
	url: string
	children?: Button[] | null;
}
function filter(list: Button[]) {
	const buttons: Button[] = [];
	for (const b of list) {
		const children = filter(b.children || []);
		buttons.push({...b, children: children.length ? children : null});
	}
	return buttons;
}
const allButtons = computed(() => {
	const buttons: Button[] = [];
	const patents: Button[][] = [];
	for (const {level, url, label} of props.buttons) {
		const l = level ? Math.min(patents.length, 1) : 0;
		patents.length = l;
		const list = l ? patents[l - 1] : buttons;
		const children = [];
		patents.push(children);
		list.push({label, url, children});
	}
	return filter(buttons);
});
</script>
