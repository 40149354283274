import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9242bd25"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "subjectContent" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: "editBtn",
      onclick: $setup.click
    }, _toDisplayString($setup.props.params.value), 1 /* TEXT */)
  ]))
}