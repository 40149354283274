<template>
	<el-dialog
		v-model="visible"
		:title="tt('Editing progress')"
		:width="dialogWidth"
		:before-close="handleClose"
		custom-class="progress-dialog">
		<ElForm
			ref="formRef"
			label-width='auto'
			:model="form"
			:rules="rules">
			<ElFormItem :label="tt('Construction content')" props="name">
				<ElInput disabled v-model='form.name' />
			</ElFormItem>
			<ElFormItem :label="tt('Unit')" props="unit">
				<ElInput disabled v-model='form.unit' />
			</ElFormItem>
			<ElFormItem :label="tt('Weekly planned quantity')" props="week_plan_quantity">
				<ElInput disabled v-model='form.week_plan_quantity' />
			</ElFormItem>
			<ElFormItem :label="tt('Unfinished quantity this week')" props="complete_quantity">
				<ElInput disabled v-model='form.complete_quantity' />
			</ElFormItem>
			<ElFormItem :label="tt('Today planned quantity')" props="today_plan_quantity">
				<ElInput disabled v-model='form.today_plan_quantity' />
			</ElFormItem>
			<ElFormItem v-if="enableAudit" :label="tt('Today actual quantity')"
				props="report_quantity">
				<ElInputNumber v-model="form.report_quantity"
					@change="changeReport" />
			</ElFormItem>
			<ElFormItem v-if="!enableAudit" :label="tt('Today actual quantity')"
				props="actual_quantity">
				<ElInputNumber v-model='form.actual_quantity' />
			</ElFormItem>
			<ElFormItem v-if="enableAudit" :label="tt('Today review volume')"
				props="actual_quantity">
				<ElInputNumber v-model='form.actual_quantity' />
			</ElFormItem>
			<ElFormItem :label="tt('Tomorrow Plan Quantity')" props="tomorrow_plan_quantity">
				<ElInputNumber v-model='form.tomorrow_plan_quantity' />
			</ElFormItem>
			<ElFormItem :label="tt('Remark')" props="remark">
				<ElInput v-model='form.remark' />
			</ElFormItem>
		</ElForm>
		<template #footer>
			<span class="dialog-footer">
				<ElButton @click="handleClose">{{ tt('Cancel') }}</ElButton>
				<ElButton type="primary" @click="confirm">
					{{ tt('Confirm') }}
				</ElButton>
			</span>
		</template>
	</el-dialog>
</template>
<script lang="ts" setup>
  import { defineProps, defineEmits, toRefs, computed, ref, onMounted, onUnmounted, reactive, watchEffect, getCurrentInstance } from 'vue';
  import { FormInstance } from 'element-plus/lib/components/index.js';

  import { ProgressActual, ProgressActualFormData, StructureItemFormat } from '../type/diary';
  const tt = __
  interface Props{
	visible:boolean,
	progressActual?:ProgressActual
	enableAudit:boolean
  }
  interface Emits{
	(e:'close'):void
	(e:'confirm', progressActual:ProgressActual, form: ProgressActualFormData):void
  }
  const props = defineProps<Props>();
  const {visible, progressActual, enableAudit} = toRefs(props);
  const formRef = ref<FormInstance>();

  const rules = reactive({
	report_quantity:[
		{type:'number', message:__('Must be a number')},
	],
	actual_quantity:[
		{type:'number', message:__('Must be a number')},
	],
	tomorrow_plan_quantity:[
		{type:'number', message:__('Must be a number')},
	],
  });
  const form = reactive<ProgressActualFormData>({
	  unit:'',
	  name:'',
	  remark:'',
  });
  watchEffect(()=>{
	form.name= progressActual?.value?.structure_ancestry_label||'';
	form.unit = progressActual?.value?.unit||'';
	form.week_plan_quantity = progressActual?.value?.week_plan_quantity;
	form.complete_quantity = progressActual?.value?.complete_quantity;
	form.today_plan_quantity= progressActual?.value?.today_plan_quantity;
	form.report_quantity= progressActual?.value?.report_quantity;
	form.actual_quantity= progressActual?.value?.actual_quantity;
	form.tomorrow_plan_quantity= progressActual?.value?.tomorrow_plan_quantity;
	form.remark= progressActual?.value?.remark||'';
  });
  const emit = defineEmits<Emits>();

	const dialogWidth = ref<string>('50%');
	  let mQuery:MediaQueryList;
	  function mediaChange(){
		  if (mQuery.matches){
			  dialogWidth.value='100%';
		  } else {
			  dialogWidth.value='50%';
		  }
	  }
	  onMounted(()=>{
		  mQuery = window.matchMedia('(max-width: 640px)');
		  if (mQuery.matches){
			  dialogWidth.value='100%';
		  } else {
			  dialogWidth.value='50%';
		  }
		  mQuery.addEventListener('change', mediaChange);
	  });
	  onUnmounted(()=>{
		  mQuery.removeEventListener('change', mediaChange);
	  });
  function changeReport(value:string){

  }
  function confirm(){
	if (!formRef.value){
		return;
	}
	formRef.value.validate(async (valid:boolean, fields:any[])=>{
		if (valid){
			if (!progressActual?.value){
				return;
			}
			emit('confirm', progressActual.value, form);
		}
	});
  }
  function handleClose(){
	  emit('close');
  }
  </script>
<style scoped lang="less">
:deep(.el-checkbox) {
	margin-bottom: 0px !important;
}

:deep(.el-checkbox__original) {
	width: 0px !important;
	height: 0px !important;
	border: 0px !important;
}

.progress-dialog {
	:deep(.el-dialog__body) {
		height: 350px;
		overflow-y: scroll;
	}
}
</style>
