interface Item{
	name:string,
	[parent:string]:any
}
interface TreeItemData extends Item {
	children?: this[];
}
export function list2Tree<T extends Item>(listData: T[], parentField:string) {
	const listDataCopy:T[] = structuredClone(listData);
	const treeData: (T & TreeItemData)[] = [];
	const map:Record<string, any> = {};
	for (const item of listDataCopy) {
		map[item.name] = item;
	}
	for (const item of listDataCopy) {
		const parent = map[item[parentField] || 0];
		if (parent) {
			(parent.children || (parent.children = [])).push(item);
		} else {
			treeData.push(item);
		}
	}
	return treeData;
}
