<template>
  <Page>
    <template v-slot:title>
      <h3 class="title">{{ tt('Project Task Template Assessment') }}</h3>
    </template>
    <template v-slot:tools>

      <ToolBar
        v-model:project="project"
        v-model:organization="organization"
        v-model:refreshValue="refreshValue"
        v-model:loading="loading"
        :detailGridApi="detailGridApi">
      </ToolBar>
    </template>
    <template v-slot:sider>
      <OrganizationTree
        :organizations="organizations"
        :organizationLoading="organizationLoading"
        v-model:organization="organization">
      </OrganizationTree>
    </template>

    <DetailTable
      :project="project"
      :detailTableData="detailTableData"
      :loading="loading"
      @onDetailGridReady="onDetailGridReady">
    </DetailTable>
  </Page>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { GridApi } from 'ag-grid-community';

import { DetailTableData } from '../type';
import Page from '../../../components/page/index.vue';
import { Project, Organization } from '../type';

import ToolBar from './components/ToolBar.vue';
import DetailTable from './components/DetailTable.vue';
import OrganizationTree from './components/OrganizationTree.vue';

const tt = __
const project = ref<string>('');
const organization = ref<string>('');
const refreshValue = ref<number>(0);

const detailTableData = ref<DetailTableData[]>([]);
const detailGridApi = ref<GridApi>();

const organizations = ref<Organization[]>([]);

const loading = ref<boolean>(false);
const organizationLoading = ref<boolean>(false);


function onDetailGridReady(params: any) {
  detailGridApi.value = params;
}

watch(() => project?.value, async () => {
  if (project?.value) {
    organizationLoading.value = true;
    const organizationsRes: Organization[] = await frappe.db.get_list(
      'Guigu Project Organization',
      {
        filters: { project: project.value, stop_using: 0 },
        fields: ['name', 'parent_guigu_project_organization', 'organization_name'],
        limit: '',
      }
    );
    const resultOrganization: Organization[] = [];
    const organizationNames: string[] = [];
    (organizationsRes || []).forEach(item => {
      organizationNames.push(item.name);
      resultOrganization.push({
        id: item.name,
        name: item.name,
        parent_guigu_project_organization: item.parent_guigu_project_organization,
        label: item.organization_name,
        parent: item.parent_guigu_project_organization,
      });
    });
    organizations.value = resultOrganization;
    organizationLoading.value = false;
  }
});


watch([project, organization, refreshValue], async () => {
  if (!organization.value) { return; }
  if (!project.value) { return; }
  loading.value = true;
  const data = await frappe.call({
    method: 'guigu_pm.guigu_pm_project_work.page.project_work_template_assessment.project_work_template_assessment.get_project_work_template_assessment',
    args: {
      project: project.value,
      organization: organization.value,
    },
  });
  const newData = (data?.message || []).map(item => ({
    ...item,
    id: `${item.work_name}${item.assessment_name}`,
    assigner_user: item?.assigner_user || '',
  }));
  detailTableData.value = newData;
  loading.value = false;
});


</script>
<style lang="css" scoped>
.container {
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 0;
}
</style>
