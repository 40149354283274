<template>
	<Page>
		<template #title>
			<h3 class="title">{{ tt('Project leader work log viewing page') }}</h3>
		</template>
		<template #tools>
			<ToolBar
				v-model:subCompany="subCompany"
				v-model:designations="selectedDesignations"
				v-model:date="date"
				v-model:showComment="showComment"
				v-model:searchString="searchString"
				@search="search"
				@clearSearch="clearSearch">
			</ToolBar>
		</template>
		<template #sider>
			<ProjectList
				:projectList="projectList"
				:projectLoading="loading"
				@projectChange="projectChange">
			</ProjectList>
		</template>
		<DiaryTimeline
			v-if="doctypeLoaded"
			v-model:project="selectedProject"
			:designations="selectedDesignations"
			:searchString="searchString"
			:date="date"
			:showComment="showComment"
			ref="diaryTimelineRef"></DiaryTimeline>

	</Page>
</template>
<script lang="ts" setup>
import {ref, watch, onMounted, computed} from 'vue';
import type {Ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';

import Page from '../../../components/page/index.vue';

import ToolBar from './components/Toolbar.vue';
import ProjectList from './components/ProjectList.vue';
import DiaryTimeline from './components/DiaryTimeline.vue';
import {Project} from './components/type';
const tt = __;
const route = useRoute();
const router = useRouter();

function setRoute(key: string, value: any) {
	const newParamsFilter = {...route.query, [key]: value};
	if ((value ?? '') === '') {
		delete newParamsFilter[key];
	}
	router.push({query: newParamsFilter});
}

function bindRoute(key: string, def?: any, hideDef?: boolean) {
	return computed<any>({
		get: () => route.query[key] || def,
		set: value => setRoute(key, hideDef && value === def ? '' : value),
	});
}
const showComment = ref<boolean>(false);
const subCompany = bindRoute('subCompany');
const selectedProject = ref<string>('all');
const date = bindRoute('date', moment().format('YYYY-MM-DD'));
const selectedDesignations: Ref<string[]> = computed<any>({
	get: () => {
		const v = route.query?.designations;
		if (!v) {
			return [];
		}
		return (Array.isArray(v) ? v : [v]).flatMap(v => v?.split(',')).filter(Boolean);
	},
	set: value => setRoute('designations', value || []),
});
const loading = ref<boolean>(false);

const projectList = ref<Project[]>([]);
const doctypeLoaded = ref<boolean>(false);
const searchString = ref<string>('');
const diaryTimelineRef = ref<{
	searchDiary:(searchModeProps: boolean) => void;
}>();

function search() {
	diaryTimelineRef.value?.searchDiary(Boolean(searchString.value));
}

function clearSearch() {
	searchString.value = '';
	diaryTimelineRef.value?.searchDiary(false);
}

async function getProjectList() {
	loading.value = true;
	projectList.value = [];
	const data = await frappe.db.get_list<Project>('Project', {
		fields: ['name', 'project_abbr_name'],
		limit: 0,
	});
	projectList.value = [
		{name: 'all', project_abbr_name: tt('All Diary')},
		...data,
	];
	selectedProject.value = 'all';
	loading.value = false;
}

function projectChange(newProject: string) {
	selectedProject.value = newProject;
}

watch(subCompany, () => {
	getProjectList();
}, {immediate: true});

onMounted(async () => {
	doctypeLoaded.value = false;
	await frappe.model.with_doctype('Guigu Daily Record B');
	doctypeLoaded.value = true;
});
</script>
<style lang="less" scoped>
.title {
	height: 75px;
	line-height: 75px;
	margin-bottom: 0;
}

</style>
