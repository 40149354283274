frappe.provide('frappe.guigu');
frappe.provide("frappe.views");
import type Vue from "vue";
import switchView from '../lib/switchView';
import createVue from './createVue';

export const views: Record<string, ((sel: frappe.guigu.ViewContext) => {
	action?(): void;
} | null) | undefined> = {};


function defineView(
	name: string,
	VueComponent: any,
	view?: (sel: frappe.guigu.ViewContext) => { action?(): void; } | null
) {
	const viewName = frappe.utils.to_title_case(name);

	views[name] = view
	class View extends frappe.views.BaseList {
		constructor(opts: any) {
			super(opts);
			this.show();
		}
		setup_view() {
			const el = document.createElement('div');
			this.parent.innerHTML = '';
			this.parent.appendChild(el)
			const vue = createVue(VueComponent, {
				props: {doctype: this.doctype},
				provide: {
					'__guigu__define__view__': (): frappe.guigu.ViewContext => ({
						currentView: name.toLowerCase(),
						doctype: this.doctype,
						settings: this.settings,
						meta: this.meta,
						switchView: switchView.bind(null, this.doctype),
					})
				}
			})
			vue.$mount(el);
			// TODO: 监听 parent 被移除
		}
		set_fields() {}
		build_fields() {}
		_add_field() {}
		setup_view_menu() {}
		set_default_secondary_action() {}

		show_or_hide_sidebar() {}
		set_menu_items() {}
		setup_side_bar() {}
		get view_name() { return viewName; }
		refresh() {
		}
		setup_result_area() {
			// $(this.parent).find('.sidebar-toggle-btn').remove()
			// $(this.parent).find('.layout-side-section').remove()
		}
		setup_no_result_area() {}
		setup_freeze_area() {}
		setup_paging_area() {}

		has_permissions() {
			const can_read = frappe.perm.has_perm(this.doctype, 0, "read");
			return can_read;
		}

		check_permissions() {
			if (!this.has_permissions()) {
				frappe.set_route("");
				frappe.throw(__("Not permitted to view {0}", [this.doctype]));
			}
			return true;
		}

		// render() {}

	};
	frappe.views[`${viewName}View`] = View;
}
frappe.guigu.defineView = defineView;
for (const p of frappe.guigu.views || []) {
	defineView(...p);
}
export {};
