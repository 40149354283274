import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8b179aaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "doctype" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["ElTable"], {
      data: $setup.doctypeList,
      border: "",
      style: {"width":"100%"},
      height: $setup.tableHeight
    }, {
      empty: _withCtx(() => [
        _createVNode($setup["Empty"], { ref: "empty" }, null, 512 /* NEED_PATCH */)
      ]),
      default: _withCtx(() => [
        _createVNode($setup["ElTableColumn"], {
          fixed: "",
          prop: "name",
          label: "名称",
          width: "250"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(`${$setup.tt(scope.row.name)}(${scope.row.name})`), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElTableColumn"], {
          prop: "module",
          label: "模块",
          width: "180"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(`${$setup.tt(scope.row.module)}(${scope.row.module})`), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElTableColumn"], {
          prop: "is_submittable",
          label: "可提交",
          width: "80"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.is_submittable?'是':'否'), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElTableColumn"], {
          prop: "istable",
          label: "是子表",
          width: "80"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.istable?'是':'否'), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElTableColumn"], {
          prop: "issingle",
          label: "是单笔记录",
          width: "100"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.issingle?'是':'否'), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElTableColumn"], {
          prop: "is_tree",
          label: "支持树结构",
          width: "100"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.is_tree?'是':'否'), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElTableColumn"], {
          prop: "track_changes",
          label: "记录历史",
          width: "80"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.track_changes?'是':'否'), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElTableColumn"], {
          prop: "description",
          label: "描述"
        }),
        _createVNode($setup["ElTableColumn"], {
          prop: "address",
          label: "操作",
          width: "60"
        }, {
          default: _withCtx((scope) => [
            _createVNode($setup["ElButton"], {
              type: "primary",
              onClick: $event => ($setup.editDoctype(scope.row))
            }, {
              default: _withCtx(() => [
                _createTextVNode("详情")
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["data", "height"])
  ])), [
    [$setup["vLoading"], $setup.loading]
  ])
}