import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b54ad019"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-container" }
const _hoisted_2 = { class: "left-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElButton = _resolveComponent("ElButton")
  const _component_ElSpace = _resolveComponent("ElSpace")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString($setup.tt('Unit')) + ":", 1 /* TEXT */),
        _createTextVNode(_toDisplayString($props.assessInfo?.department_doc.label), 1 /* TEXT */)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString($setup.tt('Department')) + ":", 1 /* TEXT */),
        _createTextVNode(_toDisplayString($props.assessInfo?.organization?.organization_name), 1 /* TEXT */)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString($setup.tt('Position')) + ":", 1 /* TEXT */),
        _createTextVNode(_toDisplayString($props.assessInfo?.job), 1 /* TEXT */)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString($setup.tt('Name')) + ":", 1 /* TEXT */),
        _createTextVNode(_toDisplayString($props.assessInfo?.user_doc.full_name), 1 /* TEXT */)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString($setup.tt('Performance Year')) + ":", 1 /* TEXT */),
        _createTextVNode(_toDisplayString($setup.month), 1 /* TEXT */)
      ])
    ]),
    _createVNode(_component_ElSpace, null, {
      default: _withCtx(() => [
        _createVNode(_component_ElButton, { onClick: $setup.exportDetail }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Export')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}