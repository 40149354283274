import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8bd11e8b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createBlock($setup["Page"], null, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.tt("Day Plan")), 1 /* TEXT */)
    ]),
    tools: _withCtx(() => [
      _createVNode($setup["ToolBar"], {
        project: $setup.project,
        "onUpdate:project": _cache[0] || (_cache[0] = $event => (($setup.project) = $event)),
        dateRange: $setup.dateRange,
        "onUpdate:dateRange": _cache[1] || (_cache[1] = $event => (($setup.dateRange) = $event)),
        structure: $setup.structure,
        "onUpdate:structure": _cache[2] || (_cache[2] = $event => (($setup.structure) = $event))
      }, null, 8 /* PROPS */, ["project", "dateRange", "structure"])
    ]),
    default: _withCtx(() => [
      _withDirectives(_createVNode($setup["PlanTable"], {
        structureDescendants: $setup.structureDescendants,
        progressPlans: $setup.progressPlans,
        project: $setup.project,
        dateRange: $setup.dateRange,
        progress: $setup.progress,
        structure: $setup.structure,
        onPlanChange: $setup.planChange,
        onRequestProgressPlan: $setup.requestProgressPlan
      }, null, 8 /* PROPS */, ["structureDescendants", "progressPlans", "project", "dateRange", "progress", "structure"]), [
        [_directive_loading, $setup.structureLoading || $setup.progressPlanLoading]
      ])
    ]),
    _: 1 /* STABLE */
  }))
}