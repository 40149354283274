<template>
	<div ref="root" class="root" />
</template>
<script setup lang="ts">
import {computed, shallowRef, watch, onBeforeUnmount} from 'vue';

import MultiOrFilters from '../../utils/MultiOrFilters';

const props = defineProps<{
	doctype: string;
	modelValue?: string | null;
}>();

const emit = defineEmits<{
	(event: 'update:modelValue', value: string | null): void;
}>();
const root = shallowRef();
function stringifyFilters(filters: any[]) {
	if (!Array.isArray(filters)) {
		return null;
	}
	try {
		return JSON.stringify(filters);
	} catch {
		return null;
	}
}
function parseFilters(filters?: string | null): [string, string, string, any][][] {
	if (!filters) {
		return [];
	}
	try {
		return JSON.parse(filters).map((l: any) => (l || []));
	} catch {
		return [];
	}
}
const modelValue = computed({
	get: () => parseFilters(props.modelValue),
	set: v => emit('update:modelValue', stringifyFilters(v)),
});
let editor: MultiOrFilters | undefined;
watch([() => props.doctype, root], ([dt, el]) => {
	if (editor) {
		// TODO:
	}
	if (!el) {
		return;
	}
	el.innerHTML = '';
	if (!dt) {
		return;
	}
	const current = new MultiOrFilters(el, dt, () => {
		if (!editor) {
			return;
		}
		modelValue.value = editor.value;
	});
	editor = current;
	frappe.model.with_doctype(dt, () => {
		if (!editor) {
			return;
		}
		editor.value = modelValue.value;
	});
}, {immediate: true});

onBeforeUnmount(() => {
	if (!editor) {
		return;
	}
	modelValue.value = editor.value;
});
</script>
<style scoped lang="less">
.root {
	width: 100%;

	:global(.filter-area .filter-box .awesomplete ul[role=listbox] li) {
		max-width: unset;
	}
}
</style>
