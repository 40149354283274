frappe.ui.form.Toolbar.prototype.set_title = function(){
	let title;
	if (this.frm.is_new()) {
		title = __("New {0}", [__(this.frm.meta.name)]);
	} else if (this.frm.meta.title_field) {
		let title_field = (this.frm.doc[this.frm.meta.title_field] || "").toString().trim();
		const link_field = this.frm.meta.fields.find(item=>item.fieldname === this.frm.meta.title_field)
		if(link_field){
			title_field = frappe.format(title_field, link_field)
		}
		title = strip_html(title_field || this.frm.docname);
		if (
			this.frm.doc.__islocal ||
			title === this.frm.docname ||
			this.frm.meta.autoname === "hash"
		) {
			this.page.set_title_sub("");
		} else {
			this.page.set_title_sub(this.frm.docname);
			this.page.$sub_title_area.css("cursor", "copy");
			this.page.$sub_title_area.on("click", (event) => {
				event.stopImmediatePropagation();
				frappe.utils.copy_to_clipboard(this.frm.docname);
			});
		}
	} else {
		title = this.frm.docname;
	}

	var me = this;
	title = __(title);
	this.page.set_title(title);
	if (this.frm.meta.title_field) {
		frappe.utils.set_title(title + " - " + this.frm.docname);
	}
	this.page.$title_area.toggleClass(
		"editable-title",
		!!(this.is_title_editable() || this.can_rename())
	);

	this.set_indicator();
}
