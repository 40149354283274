import { computed, inject, provide, reactive } from 'vue';

import { useStorage } from '@vueuse/core';

const resizeStorageKey = Symbol();
export function initResizeStorage(doctype: string) {

	const resize = useStorage(`mainView:resize:${doctype}`, {
		sider: 'calc(16.66666666666666% + 3px)',
		detailColumn: '50%',
		detailRow: '50%',
		filterField: '200px',
		showFilterField: true,
		showSidebar: true,
	});

	const resizeRider = computed({
		get: () => resize.value.sider,
		set(v) { resize.value = { ...resize.value, sider: v }; },
	});
	const resizeFilterField = computed({
		get: () => resize.value.filterField,
		set(v) { resize.value = { ...resize.value, filterField: v || '200px' }; },
	});
	const resizeDetailColumn = computed({
		get: () => resize.value.detailColumn,
		set(v) { resize.value = { ...resize.value, detailColumn: v }; },
	});
	const resizeDetailRow = computed({
		get: () => resize.value.detailRow,
		set(v) { resize.value = { ...resize.value, detailRow: v }; },
	});
	const showSidebar = computed({
		get: () => resize.value.showSidebar,
		set(v) { resize.value = { ...resize.value, showSidebar: Boolean(v) }; },
	});
	const showFilterField = computed({
		get: () => resize.value.showFilterField,
		set(v) { resize.value = { ...resize.value, showFilterField: Boolean(v) }; },
	});

	const a = reactive({

		resizeFilterField,
		resizeRider,
		resizeDetailColumn,
		resizeDetailRow,
		showSidebar,

		showFilterField,
	});

	provide(resizeStorageKey, a);
	return a;

}
export function injectResizeStorage() {
	return inject(resizeStorageKey, {

		resizeFilterField: '200px',

		resizeRider: 'calc(16.66666666666666% + 3px)',
		resizeDetailColumn: '50%',
		resizeDetailRow: '50%',
		showSidebar: true,

		showFilterField: true,
	});
}
