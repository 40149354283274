import type { Api, ColumnCell, ColumnOptions, RowValue } from '../types';
import type Source from '../Source';
import Body from '../Body';
import Header from '../Header';
import type { Row } from '../types/Row';

import updateColumn from './updateColumn';
import createColumns from './createColumns';


export default class Group {
	#api: Api;
	#startFixed = 0;
	get startFixed() { return this.#startFixed; }
	set startFixed(v) {
		if (this.#destroyed) { return; }
		this.#startFixed = v;
		this.requestRender();
	}
	#remove: () => void;
	#getData: () => readonly RowValue[];
	#requestRender: () => void;
	constructor(
		source: Source,
		getData: () => readonly RowValue[],
		remove: () => void,
	) {
		this.#remove = remove;
		this.#getData = getData;
		this.#requestRender = () => source.requestRender(this);
		this.#api = {
			toggleSelected: id => source.toggleSelected(id),
			setCollapse: (k, c) => source.setCollapse(k, c),
			isCollapsed: k => source.isCollapsed(k),
			setChecked: (k, v) => source.setChecked(k, v),
			isChecked: k => source.isChecked(k),
			isAllChecked: () => source.isAllChecked(),
			hasChecked: () => source.hasChecked(),
			checkedAll: () => source.checkedAll(),
			cleanChecked: () => source.cleanChecked(),

			emit: source.emit,
			listen: source.listen,
			emitRow: source.emitRow,
			listenRow: source.listenRow,
			setStyleVar: (k, v) => this.setStyleVar(k, v),
			removeStyleVar: k => this.removeStyleVar(k),
		};
	}
	createHeader(root?: HTMLElement) {
		const header = new Header(root, this, () => {
			this.#headers.delete(header);
		});

		header.requestRender = () => this.requestRender(header);
		this.#headers.add(header);
		if (this.#destroyed) {
			header.destroy();
		} else {
			header._updateColumns(this.#columns);
			for (const [k, v] of this.#styles) {
				header.root.style.setProperty(k, v);
			}
		}
		this.requestRender(header);
		return header;
	}
	createBody(root?: HTMLElement, rowHeight?: number) {
		const area = new Body(root, this, () => {
			this.#bodies.delete(area);
		});
		if (rowHeight && rowHeight > 0) {
			area.rowHeight = rowHeight;
		}

		area.requestRender = () => this.requestRender(area);
		this.#bodies.add(area);
		if (this.#destroyed) {
			area.destroy();
		} else {
			area._updateColumns(this.#columns);
			for (const [k, v] of this.#styles) {
				area.root.style.setProperty(k, v);
			}
		}
		this.requestRender(area);
		return area;
	}
	#columns: ColumnCell[] = [];
	get columns() { return this.#columns; }
	_updateData(data: RowValue[]) {
		this.#hide();
		for (const column of this.#columns) {
			column.updateData(data);
		}
	}
	setColumns(v?: ColumnOptions[]) {
		if (this.#destroyed) { return; }
		const columns = createColumns(
			() => this.requestRender(),
			this.#api,
			this.#columns,
			this.#getData(),
			v || [],
		);
		this.#columns = columns;
		for (const header of this.#headers) {
			header._updateColumns(columns);
		}
		for (const area of this.#bodies) {
			area._updateColumns(columns);
		}
		this.requestRender();
	}

	#hide() {
		for (const header of this.#headers) {
			header._hide();
		}
		for (const area of this.#bodies) {
			area._hide();
		}
	}
	paused = false;
	#renderSet = new Set();
	#renderAll = false;
	requestRender(p?: any) {
		if (this.#destroyed) { return; }
		this.#requestRender();
		if (p) {
			this.#renderSet.add(p);
		} else {
			this.#renderAll = true;
		}
	}
	#destroyed = false;
	destroy() {
		if (this.#destroyed) { return; }
		this.#destroyed = true;
		this.#remove();
		this.#hide();
		for (const c of [...this.#headers]) {
			c.destroy();
		}
		for (const c of [...this.#bodies]) {
			c.destroy();
		}
		for (const c of this.#columns) {
			c.destroy();
		}
		this.#columns = [];

	}
	#bodies = new Set<Body>();
	#headers = new Set<Header>();
	_render(
		rowData: Row[],
		rowMap: Map<string | number, Row>,
		visibleRowIndexes: number[],
		hoverId: string | number | undefined,
		selectedId: string | number | undefined,
		checkedSet: Set<string | number>,
		force = this.#renderAll,
	) {
		if (this.#destroyed) { return; }
		this.setStyleVar('row-visible', `${visibleRowIndexes.length}`);

		const { startFixed } = this;
		const columns = this.#columns;
		const separate = updateColumn(columns, startFixed);
		const width = separate[separate.length - 1];
		this.setStyleVar('width', `${width}px`);
		const s = startFixed < separate.length && separate[startFixed] || 0;
		this.setStyleVar('fixed-start-width', `${s > 0 ? s : -100}px`);
		if (this.paused) { return; }
		this.#renderAll = false;
		const set = new Set(this.#renderSet);
		this.#renderSet.clear();


		for (const header of this.#headers) {
			header._render(
				columns,
				force || set.has(header),
			);
		}
		for (const body of this.#bodies) {
			body._render(
				columns,
				rowData,
				rowMap,
				visibleRowIndexes,
				hoverId,
				selectedId,
				checkedSet,
				separate,
				startFixed,
				force || set.has(body),
			);
		}
	}
	#styles = new Map<string, string>();
	setStyleVar(k: string, v: string) {
		const key = `--nyloong-table-${k}`;
		this.#styles.set(key, v);
		for (const area of this.#headers) {
			area.root.style.setProperty(key, v);
		}
		for (const area of this.#bodies) {
			area.root.style.setProperty(key, v);
		}
	}
	removeStyleVar(k: string) {
		const key = `--nyloong-table-${k}`;
		this.#styles.delete(key);
		for (const area of this.#headers) {
			area.root.style.removeProperty(key);
		}
		for (const area of this.#bodies) {
			area.root.style.removeProperty(key);
		}
	}
}
