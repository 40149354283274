export function getWorkAssessmentSummaryColumns(
	pageType: string,
	date: string,
	company: string
) {
	const commanColumn = [{
		headerName: '组织',
		field: 'organization_name',
		width: 150,
		minWidth: 100,
		resizable: true,
		pinned: 'left',
	}, {
		headerName: '姓名',
		field: 'record_owner_name',
		width: 120,
		minWidth: 100,
		resizable: true,
		pinned: 'left',
	}];
	if (pageType === 'month') {
		const dayCount = moment(date).daysInMonth();
		const dayColumn: any[] = [];
		for (let i = 1; i < dayCount + 1; i++) {
			dayColumn.push({
				headerName: `${i}`,
				field: `${date}-${i < 10 ? `0${i}` : i}`,
				width: 70,
				minWidth: 70,
				resizable: true,
				cellRenderer: 'AgGridAttendanceIcon',
				cellRendererParams: (params: any) => {
					const { value } = params;
					return {
						value,
						date: `${date}-${i < 10 ? `0${i}` : i}`,
						company,
					};
				},

			});
		}
		return [
			...commanColumn,
			{
				headerName: `日志数${dayCount}`,
				field: 'diary_count',
				width: 110,
				minWidth: 110,
				resizable: true,
				pinned: 'left',
				cellRenderer: 'AgGridAttendanceCount',
				cellRendererParams: (params: any) => {
					const { value } = params;
					return {
						currentCount: value,
						totalCount: dayCount,
					};
				},
			},
			...dayColumn,
		];
	}
	const monthColumn: any[] = [];
	const startOfYear = moment(`${date}-01-01`, 'YYYY-MM-DD');
	const endOfYear = moment(`${date}-12-31`, 'YYYY-MM-DD');
	const daysInYear = endOfYear.diff(startOfYear, 'days') + 1;
	for (let i = 1; i < 13; i++) {
		monthColumn.push({
			headerName: `${i}月`,
			field: `${date}-${i < 10 ? `0${i}` : i}`,
			width: 70,
			minWidth: 70,
			resizable: true,
		});
	}
	return [
		...commanColumn,
		{
			headerName: `日志数(${daysInYear})`,
			field: 'diary_count',
			width: 100,
			minWidth: 100,
			resizable: true,
			pinned: 'left',
			cellRenderer: 'AgGridAttendanceCount',
			cellRendererParams: (params: any) => {
				const { value } = params;
				return {
					currentCount: value,
					totalCount: 365,
				};
			},
		},
		...monthColumn,
	];


}
