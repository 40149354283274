import {createApp} from 'vue';

import type {Data} from './components/types';
import App from './index.vue';
function renderDwTaskList(wrapper: HTMLElement, data: Data) {
	const app = createApp(App, {data});
	app.mount(wrapper);
}

frappe.provide('frappe.dw_worklist_management.doctype.dw_organization_worklist');
frappe.dw_worklist_management.doctype.dw_organization_worklist
	= frappe.dw_worklist_management.doctype.dw_organization_worklist || {};
frappe.dw_worklist_management.doctype.dw_organization_worklist.renderDwTaskList = renderDwTaskList;
