import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b5929e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("span", { title: $setup.text }, [
    _createElementVNode("span", _hoisted_2, _toDisplayString($setup.text), 1 /* TEXT */),
    _createVNode($setup["ElIcon"], {
      class: "button",
      onClick: $setup.add
    }, {
      default: _withCtx(() => [
        _createVNode($setup["Plus"])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode($setup["ElIcon"], {
      class: "button",
      onClick: $setup.edit
    }, {
      default: _withCtx(() => [
        _createVNode($setup["Setting"])
      ]),
      _: 1 /* STABLE */
    })
  ], 8 /* PROPS */, _hoisted_1))
}