import type {Ref} from 'vue';

const confirmText = {
	all: '确定要删除{0}及其所有后代吗？',
	parent: '确定要删除{0}，并修改当前项目的子项的父节点吗？',
	root: '确定要删除{0}，并将当前项目的子项的作为根节点吗？',
};

function getTitle(doctype: string, doc: any) {
	const {name} = doc;
	const {title_field} = frappe.get_meta(doctype) || {};
	if (!title_field) {
		return name;
	}
	const v = frappe.model.get_value(doctype, name, title_field) || doc[title_field];
	if (v) {
		return `${v} (${name})`;
	}
	return name;
}
export default async function deleteDoc(
	doctype: string,
	doc: any,
	loading:Ref<boolean>,
	mode?: 'all' | 'parent' | 'root',
	parent?: string,
) {
	const {name} = doc;
	await new Promise(r => {
		frappe.confirm(__(!mode || !['all', 'parent', 'root'].includes(mode)
			? 'Permanently delete {0}?'
			: confirmText[mode === 'parent' && !parent ? 'root' : mode], [getTitle(doctype, doc)]), r);
	});
	loading.value = true;
	if (!mode || !['all', 'parent', 'root'].includes(mode)) {
		const e = await new Promise<any>(rr => frappe.call({
			method: 'frappe.client.delete',
			args: {doctype, name},
			callback(r, rt) {
				rr(r.exc);
			},
		}));
		loading.value = false;
		if (e) {
			return;
		}
	} else {
		const e = await new Promise<any>(rr => frappe.call({
			method: 'guigu.doc.delete',
			args: {doctype, name, mode, parent},
			callback(r, rt) {
				rr(r.exc);
			},
		}));
		loading.value = false;
		if (e) {
			return;
		}
	}
	frappe.utils.play_sound('delete');
	frappe.model.clear_doc(doctype, name);
}
