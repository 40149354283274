import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e45478df"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "project-container" }
const _hoisted_2 = { class: "custom-tree-node" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElTree = _resolveComponent("ElTree")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createBlock(_component_ElTree, {
      class: "organization-tree",
      data: $props.projectList,
      props: $setup.optionProps,
      nodeKey: "id",
      expandOnClickNode: false,
      onNodeClick: $setup.nodeClick,
      currentNodeKey: $setup.selectedKey,
      defaultCheckedKeys: [1],
      highlightCurrent: ""
    }, {
      default: _withCtx(({ node, data }) => [
        _createElementVNode("span", _hoisted_2, [
          _createElementVNode("span", {
            class: _normalizeClass({ 'bolder': data.name === 'all'})
          }, _toDisplayString(node.label), 3 /* TEXT, CLASS */)
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["data", "currentNodeKey"])), [
      [_directive_loading, $props.projectLoading]
    ])
  ]))
}