<template>
	<div :class="{ 'img-item': true, isSelected }">
		<div class="icon-container">
			<div class="cursor-area" @click="click">
				<el-icon class="check" :size="30"
					@click="selectFile($event)">
					<Check />
				</el-icon>
				<el-dropdown
					v-if="permission.write || permission.export || permission.delete"
					@command="clickMenu" @click.stop="" class="dropdown">
					<span>
						<el-icon @click.stop="" class="menu" :size="30">
							<Menu />
						</el-icon>
					</span>
					<template #dropdown>
						<el-dropdown-menu class="guigu-file-view_dropdown">
							<el-dropdown-item v-if="permission.write"
								command="edit">编辑</el-dropdown-item>
							<el-dropdown-item
								v-if="fileIcon !== 'folder' && permission.export"
								command="download">下载</el-dropdown-item>
							<el-dropdown-item class="delete"
								v-if="permission.delete"
								command="delete">删除</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
				<img
					v-if="fileIcon === 'img'"
					class="img"
					:src="encodeURI(data[fileField] || '').replace(/#/g, '%23')" />
				<SvgIcon @click="clickIcon" v-else size="84px"
					:iconClass="`icon-${fileIcon}`">
				</SvgIcon>
				<span
					class="private"
					v-if="data[fileField] && data[fileField].includes('private')">私有</span>
			</div>
			<div class="file-title">
				<el-tooltip
					:content="title"
					placement="top">{{ title }}</el-tooltip>
			</div>
		</div>
	</div>
</template>

<script setup lang='ts'>
import { ref, computed, watch } from 'vue';
import { Menu, Check } from '@element-plus/icons-vue';

import SvgIcon from './SvgIcon.vue';
import { useFileViewStore } from './stores/fileView';
interface Props {
	meta: locals.DocType;
	data: any
	isFolderField: string
	fileNameField: string
	folderNameField: string
	selected: any[];
	permission: { write: boolean, delete: boolean, export: boolean }
}
const props = defineProps<Props>();
interface Emit {
	(event: 'clickFolder', folderName: string): void;
	(event: 'selectFile', isSelected: boolean, value: string): void;
	(event: 'refresh'): void;
	(event: 'delete', data: any): void;
}
const emit = defineEmits<Emit>();
const isSelected = ref<boolean>(false);
const fileViewStore = useFileViewStore();

watch(() => props.selected, () => {
	const selectedStatus = props.selected.some(item => item.name === props.data.name);
	isSelected.value = selectedStatus;
});
const fileField = computed(() => {
	const field = props.meta.fields.find(item => item.fieldtype === 'Attach' || item.fieldtype === 'Attach Image');
	return field?.fieldname || '';
});
const fileIcon = computed(() => {
	if (props.data[props.isFolderField] === 1) {
		return 'folder';
	}
	const field = props.meta.fields.find(item => item.fieldtype === 'Attach' || item.fieldtype === 'Attach Image');
	const value: string = props.data[field?.fieldname || ''] || '';
	const arr = value.split('.');
	const ext = arr[arr.length - 1].toLowerCase();
	const IMAGE_EXT = ['png', 'jpg', 'jpeg', 'gif'];
	const EXCEL_EXT = ['xls', 'xlsx'];
	const DOC_EXT = ['doc', 'docx', 'wps'];
	const PDF_EXT = ['pdf'];
	const ZIP_EXT = ['zip', 'rar', 'tar', '7-zip'];
	const AUDIO_EXT = ['mp3', 'wma', 'wav', 'ape', 'flac', 'ogg'];
	const VIDEO_EXT = ['avi', 'wmv', 'mpeg', 'mp4', 'm4v', 'mov', 'asf', 'flv', 'f4v', 'rmvb', 'rm', '3gp', 'vob'];
	const PPT_EXT = ['ppt', 'pptx'];
	const CSV_EXT = ['csv'];

	if (IMAGE_EXT.includes(ext)) {
		return 'img';
	}
	if (EXCEL_EXT.includes(ext)) {
		return 'excel';
	}
	if (DOC_EXT.includes(ext)) {
		return 'doc';
	}
	if (PDF_EXT.includes(ext)) {
		return 'pdf';
	}
	if (ZIP_EXT.includes(ext)) {
		return 'zip';
	}
	if (AUDIO_EXT.includes(ext)) {
		return 'audio';
	}
	if (VIDEO_EXT.includes(ext)) {
		return 'video';
	}
	if (PPT_EXT.includes(ext)) {
		return 'ppt';
	}
	if (CSV_EXT.includes(ext)) {
		return 'csv';
	}
	return 'unknownfile';
});
const title = computed(() => props.data[props.isFolderField] === 1
	? props.data[props.folderNameField]
	: props.data[props.fileNameField]
);

function click() {
	if (props.data[props.isFolderField] !== 1) { return; }
	emit('clickFolder', props.data.name);
}
function selectFile(event: Event) {
	event.stopPropagation();
	isSelected.value = !isSelected.value;
	emit('selectFile', isSelected.value, props.data);
}

function edit() {
	// const {
	// 	detail_type,
	// } = frappe.views.file[props.meta.name]||{};
	const detail_type = 'blank';
	if (detail_type === 'blank') {
		frappe.set_route(['form', props.meta.name, props.data.name]);
		return;
	}
	const dialog = new frappe.ui.Dialog({
		title: __(props.meta.name),
		fields: props.meta.fields,
		async primary_action() {
			const values = this.get_values();
			delete values.name;
			const data = await frappe.call<{ message: any }>({
				method: 'guigu.fileview.fileview.update_data',
				args: { doctype: props.meta.name, name: props.data.name, values },
			});
			emit('refresh');
			if (values[props.isFolderField] === 1) {
				const timeStamp = (new Date).getTime();
				fileViewStore.setUpdatedFolder(`${timeStamp}_${data?.message?.name || ''}`);
			}
			this.hide();
		},
		// primary_action_label: __("Update Order"),
	});
	dialog.set_values(props.data);
	dialog.show();
}
function deleteFile() {
	emit('delete', props.data);
}
function download() {
	const url: string = encodeURI(props.data[fileField.value] || '').replace(/#/g, '%23');
	const a = document.createElement('a');
	a.href = url;
	a.download = title.value;
	a.target = '_blank';
	a.click();
}
function clickMenu(command: string) {
	if (command === 'edit') {
		edit();
	}
	if (command === 'delete') {
		deleteFile();
	}
	if (command === 'download') {
		download();
	}
}
function clickIcon() {
	if (fileIcon.value === 'pdf' || fileIcon.value === 'audio' || fileIcon.value === 'video') {
		const url: string = encodeURI(props.data[fileField.value] || '').replace(/#/g, '%23');
		const a = document.createElement('a');
		a.href = url;
		a.target = '_blank';
		a.click();
	}
}
</script>

<style lang='less' scoped>
.img-item {
	display: inline-flex;
	width: 120px;
	height: 152px;
	vertical-align: middle;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	margin: 4px;

	&:hover {
		background-color: #f0f6ff;

		.cursor-area .check {
			opacity: 1;
		}

		.cursor-area .menu {
			opacity: 1;
		}
	}

	&.isSelected {
		background-color: #f0f6ff;
		border: 1px solid #1890ff;

		.cursor-area .check {
			opacity: 1;
			background-color: #1890ff;
		}
	}

	.icon-container {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.cursor-area {
		position: relative;
		cursor: pointer;
		margin: auto;

		.check {
			font-size: 30px;
			padding: 5px;
			position: absolute;
			background-color: #91d5ff;
			border-radius: 15px;
			color: #fff;
			left: -10px;
			top: -10px;
			opacity: 0;
			z-index: 1;

			&:hover {
				background-color: #1890ff;
			}
		}

		.menu {
			font-size: 30px;
			padding: 5px;
			position: absolute;
			background-color: #91d5ff;
			border-radius: 15px;
			color: #fff;
			right: -15px;
			top: -10px;
			opacity: 0;
			z-index: 1;

			&:hover {
				background-color: #1890ff;
			}
		}

		.img {
			width: 84px;
			height: 84px;
			object-fit: cover;
		}
	}

	.file-title {
		width: 100%;
		padding: 0 5px;
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		margin-top: 8px;
	}
}

.dropdown {
	position: absolute;
	right: 0px;


}

.guigu-file-view_dropdown {
	:deep(.delete) {
		color: #f00 !important;
	}
}

.private {
	position: absolute;
	bottom: 0px;
	right: 0;
	color: #fff;
	background: orange;
	border-radius: 8px;
	width: 31px;
	text-align: center;
	font-size: 12px;
}
</style>
