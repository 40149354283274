<template>
	<ElButtonGroup :disabled="loading" v-if="expandableList">
		<ElButton :disabled="loading" @click="set()" round>{{ tt('Fold Level') }}</ElButton>
		<ElDropdown @command="set" :teleported="false">
			<ElButton :disabled="loading" round>{{ tt('Expand') }}</ElButton>
			<template #dropdown>
				<ElDropdownMenu>
					<ElDropdownItem
						v-for="item in expandableList"
						:key="item.key"
						:command="item">
						{{ item.title }}
					</ElDropdownItem>
				</ElDropdownMenu>
			</template>
		</ElDropdown>
	</ElButtonGroup>
	<ElButtonGroup :disabled="loading" v-else-if="expandableValue">
		<ElButton :disabled="loading" @click="set()" round>{{ tt('Fold Level') }}</ElButton>
		<ElButton :disabled="loading" @click="set(expandableValue)" round>{{ tt('Expand') }}</ElButton>
	</ElButtonGroup>
</template>
<script lang="ts" setup>
import {computed, toRefs} from 'vue';
import {
	ElButton, ElButtonGroup, ElDropdownMenu,
	ElDropdownItem, ElDropdown,
} from 'element-plus';

import useExpander from './useExpander';
import type {ExpanderItem} from './useExpander';

defineOptions({inheritAttrs: false});
const props = defineProps<{
	loading?: boolean;
	view?: GlobalView.View;
}>();


const {expandable, fold} = useExpander(computed(() => props.view?.name || ''));
const tt = __;
function set(ex?: ExpanderItem) {
	if (ex) {
		ex?.set();
	} else {
		fold();
	}
}

const expandableList = computed(() => {
	const list = expandable.value;
	return list.length ? list : null;
});
const expandableValue = computed(() => expandable.value[0]);
</script>
