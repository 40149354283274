<template>
	<ElDialog
		v-model="visible"
		:title="tt('Prompt')"
	>
		<div v-if="cData?.is_core_work_list" class="info">
			<div>
				{{ tt('Are you sure you want to cancel the applicable job list') }}?
				{{ tt('Note the reason for cancelling the core work list') }}
			</div>
			<div style="margin-top: 20px">
				<ElInput
					v-model="cData.adjustment_instruction"
					:rows="3"
					type="textarea"
				></ElInput>
			</div>
		</div>
		<div v-else>
			{{ tt('Are you sure you want to cancel the applicable job list') }}?
		</div>
		<template #footer>
			<div class="dialog-footer">
				<ElButton @click="visible = false" size="small">{{ tt('Cancel') }}</ElButton>
				<ElButton type="primary" size="small" @click="inapplicability">
					{{ tt('Confirm') }}
				</ElButton>
			</div>
		</template>
	</ElDialog>
</template>

<script setup lang="ts">
import {defineProps, defineEmits, computed, ref, watch} from 'vue';
import {ElDialog, ElInput, ElButton} from 'element-plus';

import type {Worklist} from '../type';
const tt = __;

const props = defineProps<{
	currentData: Worklist | undefined,
	dialogVisible: boolean
}>();

const emit = defineEmits<{
	(e: 'update:dialogVisible', value: boolean): void;
	(e: 'inapplicability', value: Worklist | undefined): void;
}>();

const cData = ref<Worklist>();

watch(() => props.currentData, val => {
	cData.value = val;
});

const visible = computed({
	get() {
		return props.dialogVisible;
	},
	set(value:boolean) {
		emit('update:dialogVisible', value);
	},
});

function inapplicability() {
	emit('inapplicability', cData.value);
}

</script>

<style lang="less" scoped></style>
