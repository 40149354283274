export function buildTree(data:{label:string, level:number}[]) {
	const nodeMap = new Map;

	for (let item of data) {
		const newNode = { label: item.label, level:item.level, children: [] };
		nodeMap.set(item.label, newNode);
	}

	const rootNodes = [];

	for (let item of data) {
		let currentNode = nodeMap.get(item.label);
		let parentId = findParentId(data, item.label, item.level);
		if (parentId){
			let parentNode = nodeMap.get(parentId);
			if (parentNode) {
				parentNode.children.push(currentNode);
			}
		} else {
			rootNodes.push(currentNode);
		}
	}

	return rootNodes;
}
function findParentId(data:{label:string, level:number}[], label:string, level:number) {
	for (let i = data.findIndex(item => item.label === label) - 1; i >= 0; i--) {
		if (data[i].level < level ) {
			return data[i].label;
		}
	}
	return null;
}
