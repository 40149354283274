
<template>
	<ElTooltip placement="top" :content="description?.value" :visible="props.isSilentMode && isTooltipVisible">
		<div
			class="node-container"
			:ref="setContainer"
			@mouseenter="isTooltipVisible = true"
			@mouseleave="isTooltipVisible = false"
		>
			<div class="diamond-top"></div>
			<div class="diamond-bottom"></div>
			<ElTooltip placement="top" :content="description?.value" v-if="!props.isSilentMode">
				<ElIcon class="des-icon" @click="click" :size="20" :style="{ cursor: 'pointer', }"><ChatLineRound /></ElIcon>
			</ElTooltip>
		</div>
	</ElTooltip>
</template>

<script setup lang="ts">
import { computed, ref, type Ref } from 'vue';
import { ChatLineRound } from '@element-plus/icons-vue';
import {ElIcon, ElTooltip} from 'element-plus';

interface Props{
	text?:Ref<string>
	description?: Ref<string>
	isSilentMode:Ref<boolean>
}
const props = defineProps<Props>();
const height = ref<number>(100);
const width = ref<number>(100);
const isTooltipVisible = ref<boolean>(false);
interface Emits{
	(e:'edit'):void
	(e:'resize', size:{width:number, height:number}):void
}

const emit = defineEmits<Emits>();
function click(){
	emit('edit');
}
let resizeObserver = new ResizeObserver(entries => {
	height.value = entries[0].target.offsetHeight;
	width.value = entries[0].target.offsetWidth;
});
function setContainer(element: HTMLDivElement) {
	resizeObserver.observe(element);
}

const borderWidth = computed(()=>width.value/2);
const borderHeight = computed(()=>height.value/2);
const borderOffset = 2;
const offset = computed(()=>borderOffset*Math.sqrt(borderWidth.value**2+borderHeight.value**2)/borderHeight.value);
const top = computed(()=>Math.ceil(offset.value/(borderWidth.value/borderHeight.value )));
const topValue = computed(()=>`${top.value}px` );

const offsetValue = computed(()=>`${offset.value}px`);
const widthBorderValue = computed(()=>`${borderWidth.value}px`);
const heightBorderValue = computed(()=>`${borderHeight.value}px`);
const offsetWidthBorderValue = computed(()=>`${borderWidth.value-offset.value}px`);
const offsetHeightBorderValue = computed(()=>`${borderHeight.value-top.value}px`);

</script>

<style lang="less" scoped>
.node-container{
	width:100%;
	height: 100%;
	.des-icon{
		position: absolute;;
		top:0;
		left:50%;
		transform: translate(-50%, 10px);
	}
}
.diamond-top{
	width: 0;
	height: 0;
	border-left: v-bind(widthBorderValue) solid transparent;
	border-right: v-bind(widthBorderValue) solid transparent;
	border-bottom: v-bind(heightBorderValue) solid  #187dff;
	position: relative;
}
.diamond-top:after {
	content: '';
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
	width: 0;
	height: 0;
	top:v-bind(topValue);
	border-left: v-bind(offsetWidthBorderValue) solid transparent;
	border-right: v-bind(offsetWidthBorderValue) solid transparent;
	border-bottom: v-bind(offsetHeightBorderValue) solid  #fff;
}
.diamond-bottom{
	width: 0;
	height: 0;
	border-left: v-bind(widthBorderValue) solid transparent;
	border-right: v-bind(widthBorderValue) solid transparent;
	border-top: v-bind(heightBorderValue) solid  #187dff;
	position: relative;
}
.diamond-bottom::after{
	content: '';
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
	width: 0;
	height: 0;
	top: v-bind("-borderHeight+'px'");
	border-left:  v-bind(offsetWidthBorderValue)  solid transparent;
	border-right:  v-bind(offsetWidthBorderValue)  solid transparent;
	border-top: v-bind(offsetHeightBorderValue) solid  #fff;
}
</style>
