import type {Component} from 'vue';

import type {CheckboxSelectionCallbackParams, ColDef, ColGroupDef, ICellEditorParams, ICellRendererParams, RowDragCallbackParams, RowNode, ValueFormatterParams, ValueGetterParams} from 'ag-grid-community';

import type {ConnectionSetting, CustomActionButton, LinkMetaPermissions, LinkOptions} from './type';
import FileComponent from './AggridComponents/File.vue';
import LinkComponent from './AggridComponents/Link.vue';
import BaseValueComponent from './AggridComponents/BaseValue.vue';
import ActionComponent from './AggridComponents/Action.vue';
import CheckComponent from './AggridComponents/Check.vue';
import LastCommentComponent from './AggridComponents/LastComment.vue';
import GuiguDateComponent from './AggridComponents/GuiguDate.vue';
import CheckBoxComponent from './AggridComponents/CheckBox.vue';
import Title from './AggridComponents/Title.vue';
import TableMultiSelect from './AggridComponents/TableMultiSelect.vue';
import TextEditor from './AggridComponents/TextEditor.vue';

const tt = __;

const renderer: Record<string, Component> = {
	Link: LinkComponent,
	'Tianjy Related Link': LinkComponent,
	'Tree Select': LinkComponent,
	Attach: FileComponent,
	'Attach Image': FileComponent,
	Check: CheckComponent,
	'Guigu Date': GuiguDateComponent,
	__LastComment__: LastCommentComponent,
	Title: Title,
	'Table MultiSelect': TableMultiSelect,
	'Text Editor': TextEditor,
};


export function getColumns(
	linkOptions: LinkOptions[],
	linkMetaPermissions: LinkMetaPermissions,
	middleMetaPermissions: LinkMetaPermissions,
	customActionButton: CustomActionButton[],
	isNew: boolean,
	smallMeta: boolean,
	emit: any,
	dragEnable: boolean,
	isTree: boolean,
	connectionFieldSetting?: ConnectionSetting,
	renderers?: Record<string, Function | Component>,
	readonly?: boolean,
) {
	if (!connectionFieldSetting) {
		return [];
	}
	const rawColumns = connectionFieldSetting?.columns || [];
	const columns: any[] = rawColumns?.map((item, index) => ({
		headerName: tt(item.label),
		field: item.fieldname,
		minWidth: item.minWidth,
		maxWidth: (rawColumns.length === index + 1) ? undefined : item.maxWidth,
		width: item.width,
		flex: (rawColumns.length === index + 1) ? 1 : 0,
		resizable: true,
		filter: !['Table MultiSelect'].includes(item.fieldtype),
		enableRowGroup: !['Table MultiSelect'].includes(item.fieldtype),
		cellRenderer: (renderers && renderers[item.fieldname]) || renderer[item.fieldtype] || BaseValueComponent,
		cellRendererParams: (params: ICellRendererParams) => ({
			linkOptions, fieldname: item.fieldname, field: item, rowData: params.data, connectionFieldSetting, emit, isTree,
		}),
		filterParams: {
			suppressAndOrCondition: true,
			valueFormatter: (params: ValueFormatterParams) => {
				const {value} = params;
				const options = linkOptions?.find(each => each.value === value && each.fieldname === item.fieldname);
				if (options) {
					return tt(options.label);
				}
				return tt(value.toString());
			},
		},
		aggFunc: connectionFieldSetting.total_columns?.some(f => f.field === item.fieldname) ? ({values}: { values: number[] }) => {
			if (['Currency', 'Float', 'Int', 'Percent'].includes(item.fieldtype)) {
				const sum = values.reduce((total: number, each: number) => (total || 0) + (parseFloat(each.toString() || '0')), 0) || 0;
				return sum.toFixed(2);
			}
			return '';
		} : undefined,
	})) || [];
	const canEdit = linkMetaPermissions.writePermission && !connectionFieldSetting?.hide_edit_button;
	const canUnConnect = linkMetaPermissions.writePermission && middleMetaPermissions.deletePermission && !connectionFieldSetting?.hide_unlink_button;
	const canRemove = linkMetaPermissions.deletePermission && middleMetaPermissions.deletePermission && !connectionFieldSetting?.hide_delete_button;
	const hasBtn = customActionButton.length > 0 || (canEdit || canUnConnect || canRemove);
	if (connectionFieldSetting.is_show_line_number === 1 && !isTree) {
		columns.unshift({
			headerName: tt('Order Number'),
			minWidth: 25,
			width: 48,
			menuTabs: [],
			resizable: true,
			enableRowGroup: false,
			pinned: 'left',
			cellRenderer({node}: any) {
				if (!node) {
					return;
				}
				const no = node.childIndex + 1;
				return isNaN(no) ? '' : no;
			},
		});
	}
	if (!isNew && hasBtn) {
		columns.push({
			headerName: tt('Actions'),
			field: 'action',
			cellRenderer: ActionComponent,
			flex: 1,
			pinned: smallMeta ? undefined : 'right',
			resizable: false,
			menuTabs: [],
			cellRendererParams: (params: ICellEditorParams) => {
				const fn: {
					onUnConnect?: (data: any) => void,
					onRemove?: (data: any) => void
					onEdit?: (data: any) => void
				} = {};
				if (canEdit) {
					fn.onEdit = (data: any) => {
						if (connectionFieldSetting.is_detail_use_dialog) {
							emit('showDetail', data.name);
						} else {
							frappe.set_route('Form', connectionFieldSetting!.connection_table, data.name);
						}
					};
				}
				if (canUnConnect) {
					fn.onUnConnect = (data: any) => emit('unConnect', data);
				}
				if (canRemove) {
					fn.onRemove = (data: any) => emit('remove', data);
				}
				return {...fn, customActionButton, readonly, deleteTip: connectionFieldSetting.deleteTip};
			},
		});
	}
	if (!isTree && !isNew && !readonly && !connectionFieldSetting?.not_select) {
		columns.unshift(getCheckBoxColumn(isTree, dragEnable, readonly));
	}
	return columns;
}

export function getCheckBoxColumn(isTree: boolean, dragEnable: boolean, readonly?:boolean): (ColDef<any> | ColGroupDef<any>) {
	const width = dragEnable ? 80 : 50;
	return {
		headerName: __('Select Row'),
		field: 'checkbox',
		rowDrag: (isTree || !dragEnable) ? false : (event: RowDragCallbackParams) => event.node.level !== -1,
		checkboxSelection: (params: CheckboxSelectionCallbackParams) => params.node.level !== -1,
		headerCheckboxSelection: true,
		headerCheckboxSelectionCurrentPageOnly: true,
		width: width,
		minWidth: 25,
		editable: false,
		filter: false,
		menuTabs: [],
		pinned: 'left',
		cellRenderer: CheckBoxComponent,
	};
}
export function getAutoGroupColumn(
	dragEnable: boolean,
	smallMeta: boolean,
	emit:any,
	linkMetaPermissions: LinkMetaPermissions,
	middleMetaPermissions: LinkMetaPermissions,
	linkMeta?: locals.DocType,
	connectionFieldSetting?: ConnectionSetting,
	potentialParent?: RowNode<any> | null,
) {
	const titleField = linkMeta?.fields?.find(item => item.fieldname === linkMeta?.title_field);
	const setting = connectionFieldSetting?.columnTitleSetting;
	const canCreate = linkMetaPermissions.createPermission && middleMetaPermissions.createPermission;
	return {
		enableRowGroup: false,
		field: titleField?.fieldname,
		editable: false,
		minWidth: parseInt(setting?.min_width?.toString() || '0') === 0 ? undefined : setting?.min_width,
		maxWidth: parseInt(setting?.max_width?.toString() || '0') === 0 ? undefined : setting?.max_width,
		width: parseInt(setting?.width.toString() || '0') === 0 ? undefined : setting?.width,
		filter: false,
		sortable: false,
		rowDrag: dragEnable ? (event: RowDragCallbackParams) => event.node.level !== -1 : false,
		checkboxSelection: connectionFieldSetting?.not_select ? false : (params: CheckboxSelectionCallbackParams) => params.node.level !== -1,
		pinned: smallMeta ? undefined : 'left',
		cellRendererParams: (params: ICellRendererParams) => ({
			innerRenderer: Title,
			innerRendererParams: {
				connectionFieldSetting, emit, isTree: Boolean(linkMeta?.is_tree), canCreate,
			},
		}),
		cellClassRules: {
			'hover-over'(params: any) {
				return params.node === potentialParent;
			},
		},
	};
}
