<template>
	<div class="header-container" ref="toolDev">
		<div class="left-container">
			<div>
				<span>{{ tt('Unit') }}:</span
				>{{ assessInfo?.department_doc.label }}
			</div>
			<div>
				<span>{{ tt('Department') }}:</span
				>{{ assessInfo?.organization?.organization_name }}
			</div>
			<div>
				<span>{{ tt('Position') }}:</span
				>{{ assessInfo?.job_doc?.job_title || '' }}
			</div>
			<div>
				<span>{{ tt('Name') }}:</span
				>{{ assessInfo?.user_doc.full_name }}
			</div>
			<div>
				<span>{{ tt('Performance Year') }}:</span
				>{{ month }}
			</div>
		</div>
		<!-- <div class="btn-container"> -->
		<ElSpace>
			<el-button @click="emit('exportInterview')">{{ tt('Export') }}</el-button>
			<el-button v-if="show" type="primary" @click="emit('save')">{{ tt('Save') }}</el-button>
		</ElSpace>
		<!-- </div> -->
	</div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, onUnmounted, watch } from 'vue';

import type * as Permission from '../../Permission';
import type { AssessInfo, Permissions } from '../type';

const tt = __;

interface Props {
	assessInfo?: AssessInfo;
	permission: Permission.UserPermission;
	lockStatus: boolean;
	toolDevWidth: string;
	smallMeta: boolean;
}
const props = defineProps<Props>();
interface Emit {
	(e: 'exportInterview'): void;
	(e: 'save'): void;
	(e: 'update:toolDevWidth', width: string): void
}
const emit = defineEmits<Emit>();
const show = computed(() => {
	if (props.lockStatus) {
		return false;
	}
	if (props.assessInfo?.interview_record?.name) {
		return props.permission.writable.size;
	}
	return props.permission.creatable;
});

const margin = computed(() => (props.smallMeta ? '8px' : '0'));
const flexDirection = computed(() => (props.smallMeta ? 'column' : 'row'));
const month = computed(() => {
	if (!props.assessInfo) {
		return '';
	}
	return moment(props.assessInfo.date).format('YYYY-MM');
});
const toolDev = ref(null);
function watchWidth(smallMeta) {
	const observer = new ResizeObserver(entries => {
		for (const entry of entries) {
			// 当div的高度发生变化时，这个回调函数会被调用
			emit('update:toolDevWidth', `${entry.contentRect.height}px`);
		}
	});
	if (smallMeta) {
		observer.unobserve(toolDev.value);
		return;
	}
	if (toolDev.value) {
		observer.observe(toolDev.value);
	}
}
watch(() => props.smallMeta, () => {
	watchWidth(props.smallMeta);
}, { immediate: true });
onMounted(() => {
	watchWidth(props.smallMeta);
});
// // 在组件卸载时停止监听，以避免内存泄漏
// onUnmounted(() => {
// 	console.log(888888888);
// 	observer.unobserve(toolDev.value);
// });
</script>

<style lang="less" scoped>
	.header-container {
		display: flex;
		justify-content: space-between;
		flex-direction: v-bind(flexDirection);
		.left-container > div {
			display: inline-block;
			margin-right: 16px;
			margin-bottom: v-bind(margin);
		}
		span {
			margin-right: 16px;
			font-weight: bolder;
		}
	}

	.btn-container {
		display: flex;
		justify-content: end;
	}
	.confirm-container {
		display: flex;
		justify-content: space-around;
	}
	.interview-form :deep(label) {
		margin-bottom: 0;
	}
</style>
