<template>
	<ElButtonGroup :disabled="loading">
		<ElButton @click="toggleFilter" circle :title="tt('Filter')" :type="filter ? 'success' : undefined">
			{{
				tt('Matches') }}
		</ElButton>
		<ElButton @click="toggleSorter" circle :title="tt('Sort')" :type="sorter ? 'success' : undefined">
			{{
				tt('Sort') }}
		</ElButton>
		<ElButton @click="refresh" :title="tt('Refresh')" circle>
			{{ tt('Refresh') }}
		</ElButton>
		<ElButton @click="fullscreen" :title="tt('Fullscreen')" circle>{{ tt('Fullscreen') }}</ElButton>
		<ElDropdown v-if="selected.length" @command="command">
			<ElButton circle :icon="ArrowDown" type="primary" />
			<template #dropdown>
				<ElDropdownMenu>
					<ElDropdownItem v-if="editable" command="edit">
						{{ tt('Edit') }}
					</ElDropdownItem>
					<ElDropdownItem command="export">
						{{ tt('Export') }}
					</ElDropdownItem>
					<ElDropdownItem v-if="downloadable" command="download">
						{{ tt('Download Attach') }}
					</ElDropdownItem>
					<ElDropdownItem command="assignment">
						{{ tt('Assign To') }}
					</ElDropdownItem>
					<ElDropdownItem command="assignmentRule">
						{{ tt('Apply Assignment Rule') }}
					</ElDropdownItem>
					<ElDropdownItem command="addTags">
						{{ tt('Add Tags') }}
					</ElDropdownItem>
					<ElDropdownItem v-if="printable" command="print">
						{{ tt('Print') }}
					</ElDropdownItem>
					<ElDropdownItem v-if="submittable" command="submit">
						{{ tt('Submit') }}
					</ElDropdownItem>
					<ElDropdownItem v-if="cancelable" command="cancel">
						{{ tt('Cancel') }}
					</ElDropdownItem>
					<ElDropdownItem v-if="deletable" command="delete">
						{{ tt('Delete') }}
					</ElDropdownItem>
				</ElDropdownMenu>
			</template>
		</ElDropdown>
	</ElButtonGroup>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import { ElDropdown, ElButton, ElButtonGroup, ElDropdownMenu, ElDropdownItem } from 'element-plus';
import { ArrowDown } from '@element-plus/icons-vue';


import addTags from './addTags';
import print from './print';
import showAssignToDialog from './showAssignToDialog';
import assignmentRule from './assignmentRule';
import deleteSelected from './deleteSelected';
import submitOrCancel from './submitOrCancel';
import download from './download';
import edit from './edit';
import exportSelected from './export';
import is_field_editable from './is_field_editable';

defineOptions({ inheritAttrs: false });

const props = defineProps<{
	meta: locals.DocType;
	selected: locals.Doctype[];
	loading: boolean;
	filter: boolean;
	sorter: number;
	view: GlobalView.View;
}>();
const emit = defineEmits<{
	(event: 'fullscreen'): void;
	(event: 'refresh'): void;
	(event: 'unselect'): void;
	(event: 'toggleSorter'): void;
	(event: 'toggleFilter'): void;
}>();

function refresh() {
	emit('refresh');
}
function toggleSorter() {
	emit('toggleSorter');
}
function toggleFilter() {
	emit('toggleFilter');
}
function fullscreen() {
	emit('fullscreen');
}


let disable_list_update = false;
function done() {
	disable_list_update = false;
	// emit('unselect');
	emit('refresh');
}
const doctype = computed(() => props.meta.name);


const tt = __;


function getMessage(command: string, length: number) {
	switch (command) {
		case 'submit': return 'Submit {0} documents?';
		case 'cancel': return 'Cancel {0} documents?';
		case 'delete': return length > 1
			? 'Delete {0} items permanently?'
			: 'Delete {0} item permanently?';
	}
}
function run(
	doctype: string,
	command: string,
	selected: locals.Doctype<string>[],
) {
	switch (command) {
		case 'print': return print(doctype, selected);
	}
	const names = selected.map(v => v.name);
	switch (command) {
		case 'export': return exportSelected(doctype, names);
	}

	disable_list_update = true;
	switch (command) {
		case 'edit': return edit(doctype, names, done);
		case 'assignment': return showAssignToDialog(doctype, names, done);
		case 'assignmentRule': return assignmentRule(doctype, names, done);
		case 'addTags': return addTags(doctype, names, done);
		case 'submit': return submitOrCancel(doctype, names, true, done);
		case 'cancel': return submitOrCancel(doctype, names, false, done);
		case 'delete': return deleteSelected(doctype, names, done);
		case 'download': return download(props.meta, names, props.view.downloadFolderFields);
	}

}
function command(command: string) {
	const { selected } = props;
	const length = selected?.length;
	if (!length) { return; }
	const message = getMessage(command, length);
	const dt = doctype.value;
	if (!message) {
		run(dt, command, selected);
		return;
	}
	frappe.confirm(__(message, [length]), () => {
		run(dt, command, selected);
	});
}

const printable = computed(() => frappe.model.can_print(doctype.value));
const submittable = computed(() => {
	const dt = doctype.value;
	if (
		frappe.model.is_submittable(dt) &&
		frappe.perm.has_perm(dt, 0, 'submit') &&
		!frappe.model.has_workflow(dt)
	) {
		return true;
	}
	return false;
});
const cancelable = computed(() => {
	const dt = doctype.value;
	if (frappe.model.can_cancel(dt) && !frappe.model.has_workflow(dt)) {
		return true;
	}
	return false;
});
const deletable = computed(() => frappe.model.can_delete(doctype.value));

const editable = computed(() => frappe.meta
	.get_docfields(doctype.value)
	.some(field_doc => is_field_editable(field_doc)));
const downloadable = computed(() => (props.view?.downloadFolderFields || []).length > 0);
</script>
