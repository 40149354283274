import registerGlobalView from 'guiguLib/registerGlobalView';
import registerGlobalViewSiderCustomize from 'guiguLib/registerGlobalViewSiderCustomize';
import registerGlobalViewToolbarCustomize from 'guiguLib/registerGlobalViewToolbarCustomize';
import registerGlobalViewAction from 'guiguLib/registerGlobalViewAction';

import FileView from './index.vue';
import FolderContainer from './FolderContainer.vue';
import Toolbar from './FileTools.vue';
import FieldConfig from './FieldConfig.vue';

export { default as View } from './index.vue';
export { default as Sider } from './FolderContainer.vue';
export { default as Toolbar } from './FileTools.vue';
export { default as RowAction } from './TableRowAction.vue';
export type { Config } from './type';

registerGlobalView('file', FileView);
registerGlobalViewSiderCustomize('FolderContainer', FolderContainer);
registerGlobalViewToolbarCustomize('fileTools', Toolbar);
registerGlobalViewAction('guiguFileAction', Toolbar);


export const widgets: GlobalView.ConfigurationWidget[] = [{
	component: FieldConfig,
	label: '字段',
}];
