import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElTag = _resolveComponent("ElTag")

  return (_openBlock(), _createElementBlock("div", null, [
    ($setup.status)
      ? (_openBlock(), _createBlock(_component_ElTag, {
          key: 0,
          type: $setup.statusType
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.DIARY_STATUS[$setup.status]), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["type"]))
      : _createCommentVNode("v-if", true)
  ]))
}