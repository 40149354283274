
<template>
	<div class="tool-container">
		<ElSpace>
			<ElButton type="primary" size="small"
				@click="toggleShowMode">{{
					fileViewStore.showMode === 'iconMode' ? "大图标" : "列表" }}
			</ElButton>
			<ZipDownload
				v-if="isShowDownloadBtn"
				:selected="selected"
				:is-folder-field="isFolderField" :parent-field="parentField"
				:meta="meta" :folder-name-field="folderNameField"
				:file-name-field="fileNameField"></ZipDownload>
			<ElButton v-if="isShowCreateBtn" type="primary" size="small"
				@click="createFolder">新建文件夹
			</ElButton>
			<ElButton v-if="isShowCreateBtn" type="primary" size="small"
				@click="createFile">上传文件
			</ElButton>
		</ELSpace>
	</div>
</template>

<script setup lang='ts'>
import { ref, computed, watch, onMounted } from 'vue';

import { Filter } from '../GlobalView/types';

import { useFileViewStore } from './stores/fileView';
import { Config } from './type';
import ZipDownload from './ZipDownload.vue';
interface Props {
	selected: any[];
	meta: locals.DocType;
	filters: Filter[]
	configuration: Config;
}

const props = defineProps<Props>();
interface Emit {
	(event: 'refresh'): void;
	(event: 'update:filters', data: Filter[]): void

}
const emit = defineEmits<Emit>();
const fileViewStore = useFileViewStore();

function toggleShowMode() {
	fileViewStore.changeMode();
}

const isShowCreateBtn = computed(() => {
	const hasCreatePermission = frappe.perm.has_perm(props.meta.name, 0, 'create');
	return hasCreatePermission;
});
const isShowDownloadBtn = computed(() => {
	const hasExportPermission = frappe.perm.has_perm(props.meta.name, 0, 'export');
	return hasExportPermission;
});
const {
	isFolderField, fileNameField, folderNameField, parentField, projectField,
} = computed(() => {
	const { isFolderField, fileNameField, folderNameField, parentField, projectField } = props.configuration || {};
	return { isFolderField, fileNameField, folderNameField, parentField, projectField };
}).value;

async function createDoctypeRow(values: any) {
	const data = await frappe.call<{ message: any }>({
		method: 'guigu.fileview.fileview.create_data',
		args: { doctype: props.meta.name, values },
	});
	emit('refresh');
	if (values[isFolderField] === 1) {
		fileViewStore.setCreatedFolder(data?.message?.name || '');
	}
}
async function createFile() {
	const folderFilters = props.filters.filter(item => item.field === parentField);
	let parent = '';
	if (folderFilters.length > 0) {
		parent = folderFilters[0].value;
		parent = parent === 'not set' ? '' : parent;
	}

	// const {
	// 	detail_type,
	// } = frappe.views.file[props.meta.name]||{};
	const detail_type = 'blank';
	const parentFolder = parent ? await frappe.db.get_doc(props.meta.name, parent) : '';
	if (detail_type === 'blank') {
		const newFolderName = frappe.model.make_new_doc_and_get_name(props.meta.name);
		frappe.model.set_value(props.meta.name, newFolderName, isFolderField, 0);
		frappe.model.set_value(props.meta.name, newFolderName, parentField, parent);

		if (projectField) {
			frappe.model.set_value(props.meta.name, newFolderName, projectField, parentFolder[projectField]);
		}
		frappe.set_route(['form', props.meta.name, newFolderName]);
		return;
	}
	const dialog = new frappe.ui.Dialog({
		title: __(props.meta.name),
		fields: props.meta.fields,
		async primary_action() {
			const data = this.get_values();
			await createDoctypeRow(data);
			this.hide();
		},
	});
	dialog.set_values({
		[parentField]: parent,
		[isFolderField]: 0,
		[projectField]: parentFolder[projectField],
	});
	dialog.show();
}
async function createFolder() {
	const folderFilters = props.filters.filter(item => item.field === parentField);
	let parent = '';
	if (folderFilters.length > 0) {
		parent = folderFilters[0].value;
		parent = parent === 'not set' ? '' : parent;
	}

	// const {
	// 	detail_type,
	// } = frappe.views.file[props.meta.name]||{};
	const detail_type = 'blank';
	const parentFolder = parent ? await frappe.db.get_doc(props.meta.name, parent) : '';
	if (detail_type === 'blank') {
		const newFolderName = frappe.model.make_new_doc_and_get_name(props.meta.name);
		frappe.model.set_value(props.meta.name, newFolderName, isFolderField, 1);
		frappe.model.set_value(props.meta.name, newFolderName, parentField, parent);
		if (projectField) {
			frappe.model.set_value(props.meta.name, newFolderName, projectField, parentFolder[projectField]);
		}
		frappe.set_route(['form', props.meta.name, newFolderName]);
		return;
	}
	const dialog = new frappe.ui.Dialog({
		title: __(props.meta.name),
		fields: props.meta.fields,
		async primary_action() {
			const data = this.get_values();
			await createDoctypeRow(data);
			this.hide();
		},
		// primary_action_label: __("Update Order"),
	});
	dialog.set_values({
		[parentField]: parent,
		[isFolderField]: 1,
		[projectField]: parentFolder[projectField],
	});
	dialog.show();
}
</script>

<script lang="ts">
export default { inheritAttrs: false };
</script>
<style lang='less' scoped>
</style>
