<script setup lang="ts">
import {computed, watch} from 'vue';
import {ElButton, ElDialog} from 'element-plus';

const props = defineProps<{
	meta: locals.DocType;
	modelValue?: GlobalView.DragInfo;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: any): void;
}>();
const allMenu = computed(() => {
	const {meta} = props;
	const setting = frappe.guigu.GlobalView.settings['*'];
	const sort = setting?.sort;
	if (!sort) {
		return [];
	}
	return Array.isArray(sort) ? sort : [sort];
});
const dtMenu = computed(() => {
	const {meta} = props;
	const setting = frappe.guigu.GlobalView.settings[meta.name];
	const sort = setting?.sort;
	if (!sort) {
		return [];
	}
	return Array.isArray(sort) ? sort : [sort];
});
const menu = computed(() => {
	const {modelValue, meta} = props;
	if (!modelValue) {
		return [];
	}
	const menu = [...allMenu.value, ...dtMenu.value];
	return menu.filter(m => !m.hidden?.(modelValue, meta)).map(m => {
		const {label} = m;
		if (typeof label !== 'function') {
			return m;
		}
		return {...m, label: label(modelValue, meta)};
	}).filter(m => m.label);
});
const tt = __;
const show = computed({
	get: () => Boolean(menu.value.length > 1),
	set: v => {
		if (v) {
			return;
		} emit('update:modelValue', undefined);
	},
});
function click(menu?: frappe.guigu.GlobalView.Setting.SortMenu) {
	if (!menu) {
		return;
	}
	const {modelValue, meta} = props;
	if (!modelValue) {
		return;
	}
	emit('update:modelValue', undefined);
	menu.action(modelValue, meta);
}
watch(() => {
	const actions = menu.value;
	if (actions.length !== 1) {
		return;
	}
	return actions[0];
}, click);
</script>
<template>
	<ElDialog v-model="show" title="移动方式" width="min(300px, 100%)">
		<div class="menu">
			<ElButton v-for="it in menu" @click="click(it)">
				{{ it.label }}
			</ElButton>
		</div>
		<template #footer>
			<ElButton @click="show = false">{{ tt('Cancel') }}</ElButton>
		</template>
	</ElDialog>
</template>

<style scoped lang="less">
.menu {
	display: flex;
	flex-direction: column;

	button {
		margin-left: 0 !important;
		width: 100%;
	}
}
</style>
