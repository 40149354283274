import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElOption = _resolveComponent("ElOption")
  const _component_ElSelect = _resolveComponent("ElSelect")

  return (_openBlock(), _createBlock(_component_ElSelect, {
    modelValue: _ctx.selectedProgress,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.selectedProgress) = $event)),
    filterable: "",
    remote: "",
    "reserve-keyword": "",
    placeholder: _ctx.tt('Please enter keywords'),
    style: {width:'100%'},
    onChange: _ctx.change,
    "remote-method": _ctx.remoteMethod,
    loading: _ctx.loading
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
        return (_openBlock(), _createBlock(_component_ElOption, {
          key: item.value,
          label: item.label,
          value: item.value
        }, null, 8 /* PROPS */, ["label", "value"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "placeholder", "onChange", "remote-method", "loading"]))
}