import {define} from 'tianjy_workspace';

import vue2renderer from '../vue2renderer';
import createWidgetDialog from '../createWidgetDialog';

import View from './View.vue';
import Config from './Config.vue';

define(
	'GuiguCard',
	vue2renderer(View),
	createWidgetDialog(Config, 'Guigu Card'),
	{
		title: 'Guigu Card',
		col: 4,
		minWidth: 4,
	},
);
