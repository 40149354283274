<template>
	<ElTooltip
		:visible="visible">
		<template #content>
			<div class="progress-container">
				<ElProgress
					:format="format"
					:percentage="speed"

					:status="speed === 100 ? undefined : 'success'">
				</ElProgress>
			</div>
		</template>
		<ElButton size="small" v-if="selected.length > 0" type="primary"
			@click="get_zip_files">批量导出
		</ElButton>
	</ElTooltip>
</template>
<script lang="ts" setup>
import { ref, toRefs } from 'vue';

import { Download } from '@element-plus/icons-vue';

import JSZip from '../../lib/jszip.min.js';
import FileSaver from '../../lib/FileSaver.min.js';

import { formatFiles, list2Tree } from './helper';
interface FileTree {
	name: any;
	title: any;
	url?: string;
	parent: any;
	isFolder: boolean,
	children?: this[]
}
interface Props {
	selected: any[];
	meta: locals.DocType;
	isFolderField: string,
	parentField: string
	fileNameField: string
	folderNameField: string
}
const props = defineProps<Props>();
const visible = ref<boolean>(false);
const speed = ref<number>(0);
const allFiles = ref<any[]>([]);
function format(percentage: string) {
	return `${percentage}%`;
}

function getBase64(
	url: string,
	onloadCallback: (Base64?: string) => any,
	onprogressCallback?: (progress: number, index?: number) => any,
	index?: number,
) {
	const xhr = new XMLHttpRequest();
	xhr.open('GET', `${url}?v=${Math.random()}`, true);
	xhr.responseType = 'blob';
	xhr.onprogress = function (event) {
		const aa = url;
		const total = event.total === 0 ? event.loaded : event.loaded;
		return onprogressCallback?.(event.loaded / total, index) || null;
	};
	xhr.onload = () => {
		if (xhr.status === 200) {
			const reader = new FileReader();
			reader.onloadend = function () {
				return onloadCallback?.(String(reader.result)) || null;
			};
			reader.readAsDataURL(xhr.response);
		} else {
			onloadCallback?.();
		}
	};
	xhr.onerror = () => {
		onloadCallback?.();
	};
	xhr.send();
}

function zipFile(files: FileTree[], fileNames: string[], progressSpeed: number[], fileFolder: any, zip: any) {
	const nameCatalogue: Record<string, any> = {};
	for (const [index, file] of files.entries()) {
		if (file.isFolder) {
			if (!file.children || file.children.length === 0) { continue; }
			const newFileFolder = fileFolder.folder(file.title);
			zipFile(file.children, fileNames, progressSpeed, newFileFolder, zip);
			continue;
		}
		progressSpeed.push(0);
		const onloadCallback = (url?: string) => {
			progressSpeed[index] = parseInt(`${100 / allFiles.value.length}`);

			const ext = file.title.split('.').pop()?.toLowerCase();
			let newFileName = file?.title.substring(0, file?.title.lastIndexOf('.'));
			const fileName = newFileName;
			nameCatalogue[fileName] = nameCatalogue?.[fileName] + 1 || 0;
			if (fileNames.includes(fileName)) {
				newFileName = `(${fileName}${nameCatalogue[fileName]})`;
			}
			fileNames.push(fileName);
			if (url) {
				fileFolder.file(
					`${newFileName}.${ext}`,
					url.substring(url.indexOf(',') + 1),
					{
						base64: true,
					},
				);
			}
			if (fileNames.length === allFiles.value.length) {
				speed.value = 100;
				zip.generateAsync({ type: 'blob' }).then((content: any) => {
					const time = moment().format('YYYY-MM-DD');
					FileSaver.saveAs(content, `${time}-export.zip`);
				});
				setTimeout(() => {
					visible.value = false;
					speed.value = 0;
				}, 1000);
			}
		};
		const onprogressCallback = (progress: number, index?: number) => {
			if (!index) {
				return;
			}
			progressSpeed[index] = parseInt(`${(progress * 100) / allFiles.value.length}`);
			speed.value = progressSpeed.reduce((pre, next) => pre + next, 0);
		};
		getBase64(file?.url!, onloadCallback, onprogressCallback, index);
	}
}
function downloadZip(fileTree: FileTree[]) {
	visible.value = true;
	const zip = new JSZip();
	const time = moment().format('YYYY-MM-DD');
	const fileFolder = zip.folder(`${time}-export`);
	const fileNames: string[] = [];
	const progressSpeed: number[] = [];
	zipFile(fileTree, fileNames, progressSpeed, fileFolder, zip);
}

async function get_zip_files() {
	const data = await frappe.call<{ message: any[] }>({
		method: 'guigu.fileview.fileview.get_file_tree',
		args: {
			doctype: props.meta.name,
			selected_files: props.selected.map(item => item.name),
			is_folder_field: props.isFolderField,
			parent_field: props.parentField,
		},
	});
	const field = props.meta.fields.find(item => item.fieldtype === 'Attach' || item.fieldtype === 'Attach Image');
	if (!field) { return; }
	const formatList = formatFiles(data?.message || [], props.meta, props.isFolderField, props.parentField, props.folderNameField, props.fileNameField);
	allFiles.value = formatList.filter(item => !item.isFolder);
	const treeData = list2Tree(formatList, 'parent');
	downloadZip(treeData);
}
</script>
<style lang="less" scoped>
.progress-container {
	width: 100px
}
</style>
