<template>
	<Page>
		<template #title>
			<h3 class="title">{{ tt('Work Progress Of The Final Project') }}</h3>
		</template>
		<template #tools>
			<Tools
				:smallMeta="smallMeta"
				v-model:company="company"
				v-model:project="project"
				v-model:year="year"
				v-model:startMonth="startMonth"
				v-model:endMonth="endMonth">
			</Tools>
		</template>
		<div v-loading="loading || cellChangeLoading" class="table-container">
			<Table
				:workList="finishingWorks"
				:businessTypes="businessTypes"
				:loading="loading"
				@beforeUpdate="cellChangeLoading = true"
				@afterUpdate="afterUpdate"
				@refresh="getFinishingWork"></Table>
		</div>
	</Page>
</template>

<script setup lang="ts">
import {onMounted, onUnmounted, ref, watch} from 'vue';
import moment from 'moment';

import Page from '../../../../../../../guigu_pm/guigu_pm/public/js/components/page/index.vue';
import {useMetaQuery} from '../../../../../../../guigu_pm/guigu_pm/public/js/components/page/useMetaQuery';

import Table from './components/Table.vue';
import Tools from './components/Tools.vue';
import type {BusinessType, FinishingWork, LastYearUnfinishedWork} from './type';

const company = ref<string>('');
const project = ref<string>('');
const year = ref<string>(moment().format('YYYY'));
const startMonth = ref<string>(moment().format('MM'));
const endMonth = ref<string>(moment().format('MM'));
const loading = ref<boolean>(false);
const cellChangeLoading = ref<boolean>(false);
const finishingWorks = ref<FinishingWork[]>([]);
const smallMeta = useMetaQuery();
const businessTypes = ref<BusinessType[]>([]);
const tt = __;

watch(
	() => window.location.search,
	() => {
		const params = new URLSearchParams(window.location.search);
		if (params.get('company')) {
			company.value = params.get('company') || '';
		}
		if (params.get('project')) {
			project.value = params.get('project') || '';
		}
		if (params.get('year')) {
			year.value = params.get('year') || '';
		}
		if (params.get('startMonth')) {
			startMonth.value = params.get('startMonth') || '';
		}
		if (params.get('endMonth')) {
			endMonth.value = params.get('endMonth') || '';
		}
	},
	{immediate: true},
);
watch(
	[company, startMonth, endMonth, year, project],
	async () => {
		const queryObj = new URLSearchParams({
			company: company.value,
			project: project.value,
			startMonth: startMonth.value,
			endMonth: endMonth.value,
			year: year.value,
		});
		history.replaceState(null, '', `?${queryObj.toString()}`);
		getFinishingWork();
	},
	{immediate: true},
);
async function getFinishingWork() {
	if (!company.value || !startMonth.value || !endMonth.value) {
		return;
	}
	if (startMonth.value > endMonth.value) {
		finishingWorks.value = [];
		frappe.show_alert(__('The start month cannot be greater than the end month'));
		return;
	}
	loading.value = true;
	const res = await frappe.call<{ message: FinishingWork[] }>({
		method: 'guigu_pc.guigu_pc.page.finishing_work_project_management.finishing_work_project_management.get_finishing_Work',
		args: {
			company: company.value,
			project: project.value,
			start_date: moment(`${year.value}-${startMonth.value}`).startOf('month').format('YYYY-MM-DD'),
			end_date: moment(`${year.value}-${endMonth.value}`).endOf('month').format('YYYY-MM-DD'),
		},
	});
	loading.value = false;
	finishingWorks.value = res?.message || [];
}
onMounted(() => {
	getBusiness();
});
async function getBusiness() {
	const res = await frappe.call<{ message: BusinessType[] }>({
		method: 'guigu_pc.guigu_pc.page.finishing_work_project_management.finishing_work_project_management.get_finishing_business',
	});
	businessTypes.value = res?.message || [];
}

function afterUpdate(newData: LastYearUnfinishedWork) {
	const projectFinishingWork = finishingWorks.value.find(item => item.type === 'future' && item.project_name === newData.project);
	if (!projectFinishingWork) {
		return;
	}
	projectFinishingWork[newData.type] = {
		...projectFinishingWork[newData.type],
		context: [newData.content],
		context_string: newData.content,
		name: newData.name,
	};
	const projectFinishingWorkMonth = finishingWorks.value.find(item => item.type === 'year' && item.project_name === newData.project);
	if (projectFinishingWorkMonth) {
		projectFinishingWorkMonth[newData.type].name = newData.name;
	}
	cellChangeLoading.value = false;
	finishingWorks.value = [...finishingWorks.value];
}
const popstateListener = function () {
	getFinishingWork();
};
onMounted(() => {
	window.addEventListener('popstate', popstateListener);
});
onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});
frappe.realtime.on('doc_update', p => {
	if (p.doctype !== 'Guigu Project Work' && p.doctype !== 'Guigu Last Year Unfinished Work') {
		return;
	}
	getFinishingWork();
});
frappe.realtime.on('list_update', p => {
	if (p.doctype !== 'Guigu Project Work' && p.doctype !== 'Guigu Last Year Unfinished Work') {
		return;
	}
	getFinishingWork();
});
</script>

<style lang="less" scoped>
  .title {
    height: 75px;
    line-height: 75px;
    margin-bottom: 0;
  }
  .table-container {
    height: 100%;
  }
</style>
