<template>
	<ElDialog
		v-model="dialogVisible"
		:title="tt('Dw Work Instruction Work List')"
		:width="smallMeta?'80%':'700'"
		v-loading="loading"
	>
		<div>
			<div class="tools">
				<ProcessSearchTools
					:processes="processList"
					@getData="getData"
				></ProcessSearchTools>
			</div>
			<ElTable
				ref="tableRef"
				class="work_list_table"
				:data="tableList"
				style="width: 100%"
				stripe
				rowKey="name"
				border
				@select="selectionChange"
			>
				<ElTableColumn type="selection" :selectable="selectable" width="55" />
				<ElTableColumn prop="title" :label="tt('Subject')" />
			</ElTable>
		</div>
		<template #footer>
			<div class="dialog-footer">
				<ElButton @click="dialogVisible = false">{{ tt('Cancel') }}</ElButton>
				<ElButton type="primary" @click="confirm">
					{{ tt('Confirm') }}
				</ElButton>
			</div>
		</template>
	</ElDialog>
</template>

<script lang="ts" setup>
import {computed, ref, watch} from 'vue';
import {ElDialog, ElButton, ElTable, ElTableColumn, type TableInstance, vLoading} from 'element-plus';

import {useMetaQuery} from '../../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';
import ProcessSearchTools from '../../../../../component/ProcessSearchTools/index.vue';
import {list2Tree} from '../../../../../utils/index';

import type {Process, Worklist, WorkListData} from './type';
import {mergeData} from './helper';

const smallMeta = useMetaQuery();

const tt = __;
interface Props {
	visible:boolean,
	organization:string
	separator:string
}
const props = defineProps<Props>();
interface Emit {
	(e: 'update:visible', visible: boolean): void,
	(e:'relateWorkList', data:Worklist):void,
}
const emit = defineEmits<Emit>();
const selectionData = ref<Worklist>();
const tableRef = ref<TableInstance>();
const tableList = ref<Worklist[]>([]);
const processList = ref<Process[]>([]);
const process = ref<string>('');
const loading = ref<boolean>(true);
const dialogVisible = computed({
	get() {
		return props.visible;
	},
	set(visible:boolean) {
		emit('update:visible', visible);
	},
});

function selectionChange(selected:Worklist[], row:Worklist) {
	const isSelectRow = selected.some(item=>item.name === row.name);
	if (isSelectRow && selectionData.value) {
		tableRef.value?.toggleRowSelection(selectionData.value, false);
	}
	if (isSelectRow) {
		selectionData.value = row;
	} else {
		selectionData.value = undefined;
	}
}
function confirm() {
	if (selectionData.value) {
		emit('relateWorkList', selectionData.value);
	}
	dialogVisible.value = false;
}
watch([process, ()=>props.organization, ()=>props.separator], ()=>{
	getWorkList();
}, {immediate: true});
async function getWorkList() {
	loading.value = true;
	// 获取清单项
	const res = await frappe.call<{ message: WorkListData }>({
		method: 'dw_worklist_management.api.instruction.block.get_process_children',
		args: {
			process: process.value,
			organization: props.organization,
		},
	});
	const list = mergeData(res?.message || {process_list: [], worklist_list: []}, props.separator);
	tableList.value = list2Tree(list, 'parent_dw_process', 'name');
	loading.value = false;
}
function selectable(row:Worklist) {
	return !row.is_process;
}
function getData(newProcess:string) {
	process.value = newProcess;
}

async function getProcessList() {
	const res = await frappe.db.get_list<Process>('Dw Process', {
		limit: 0,
		fields: ['*'],
	});
	processList.value = res;
}
getProcessList();
</script>
<style scoped lang="less">
.work_list_table :deep(.el-table__header-wrapper  .el-checkbox){
	display:none
}
.work_list_table :deep(label){
	margin-bottom: 0;
}
.tools{
	margin-bottom: 8px
}
</style>
