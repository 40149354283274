
<template>
	<ElTooltip placement="top" :content="description?.value" :visible="props.isSilentMode && isTooltipVisible">
		<div class="node-container" @mouseenter="isTooltipVisible = true" @mouseleave="isTooltipVisible = false">
			<div class="node-container-inner">
				<ElTooltip placement="top" :content="description?.value" v-if="!props.isSilentMode">
					<ElIcon @click="click" :size="20" :style="{ cursor: 'pointer' }"><ChatLineRound/></ElIcon>
				</ElTooltip>
			</div>
		</div>
	</ElTooltip>
</template>

<script setup lang="ts">
import { ref, type Ref } from 'vue';
import { ChatLineRound } from '@element-plus/icons-vue';
import {ElIcon, ElTooltip} from 'element-plus';

interface Props{
	text?:Ref<string>
	description?: Ref<string>
	isSilentMode:Ref<boolean>
}
const props = defineProps<Props>();
const isTooltipVisible = ref<boolean>(false);

interface Emits{
	(e:'edit'):void
	(e:'resize', size:{width:number, height:number}):void
}
const emit = defineEmits<Emits>();
function click(){
	emit('edit');
}

</script>

<style lang="less" scoped>
.node-container{
	position: relative;
	display: flex;
	border: 2px solid #187dff;
	border-radius: 10px;
    text-align: center;
    justify-content: center;
	align-items: center;
    width: 100%;
	height:100%;
	background-color: #fff;
}
.node-container-inner {
	display: flex;
    border-left: 2px solid #187dff;
	border-right: 2px solid #187dff;
    text-align: center;
    justify-content: flex-end;
	align-items: center;
    width: 80%;
	height:100%;
}
</style>
