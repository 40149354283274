import moment from "moment";

export function dateRange(startDate: string, endDate:string) {
	const startDateMoment = moment(startDate);
	const endDateMoment = moment(endDate);

	// 创建一个数组来存放中间的日期
	const dateArray = [];

	// 循环遍历日期范围并将每个日期添加到数组中
	let currentDate = startDateMoment.clone();
	while (currentDate.isSameOrBefore(endDateMoment)) {
		// 将日期格式化为所需的格式
		dateArray.push(currentDate.format('YYYY-MM-DD'));
		// 增加一天
		currentDate.add(1, 'days');
	}
	return dateArray;
}
export function getSummaryColumns(
	startDate:string,
	endDate: string,
	isPhone:string,
) {
	const rangeDate = dateRange(startDate, endDate);
	const commanColumn = [{
		headerName: __('Project Name'),
		field: 'project_abbr_name',
		colId: 'project_abbr_name',
		width: 150,
		minWidth: 100,
		resizable: true,
		pinned: isPhone === 'default' ? '' : 'left',
	}, {
		headerName: __('Designation'),
		field: 'job_title',
		colId: 'job_title',
		width: 120,
		minWidth: 120,
		resizable: true,
		pinned: isPhone === 'default' ? '' : 'left',
	}, {
		headerName: __('People Name'),
		field: 'record_owner_name',
		colId: 'record_owner_name',
		width: 100,
		minWidth: 100,
		resizable: true,
		pinned: 'left',
	}, {
		headerName: `${__('Totals')}(${(rangeDate ||[]).length})`,
		field: 'total',
		colId: 'total',
		width: 90,
		minWidth: 90,
		resizable: true,
		pinned: isPhone === 'default' ? '' : 'left',
		cellStyle: (params: any) => {
			const {value} = params;
			const allCount = (rangeDate || []).length;
			if (value < allCount && value !== allCount){
				return {color: 'red'};
			}
			return { color: 'black'};
		},
	}, {
		headerName: `${__('Daily Record Normal')}`,
		field: 'normal',
		colId: 'normal',
		width: 90,
		minWidth: 90,
		resizable: true,
	}, {
		headerName: `${__('Daily Record Delayed')}`,
		field: 'delayed',
		colId: 'delayed',
		width: 90,
		minWidth: 90,
		resizable: true,
	}];
	const dateColumn = rangeDate.map(item => ({
		headerName: item,
		field: item,
		colId: item,
		width: 115,
		minWidth: 60,
		resizable: true,
		cellRenderer: 'AgGridAttandanceIcon',
		cellRendererParams: (params: any) => {
			const { value } = params;
			return {
				value: value,
				date: item,
				project: params.data.project_name,
			};
		},

	}));
	return [...commanColumn, ...dateColumn];
}
