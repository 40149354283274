<template>
	<div>
		<a :style="{ color: '#409eff' }" @click="click">{{ value }}</a>
	</div>
</template>

<script setup lang='ts'>
import { computed } from 'vue';

const props = defineProps<{ params: any }>();
const value = computed(() => {
	const fieldname = `${props.params.field.fieldname || ''}.title`;
	if (fieldname) {
		return props.params.data[fieldname] || props.params.value;
	}
	return props.params.value;
});


function click() {
	const { field, value } = props.params;
	if (!value) { return; }
	if (!field?.options) { return; }
	frappe.set_route('Form', field.options, value);
}
</script>
