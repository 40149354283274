import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createBlock(_component_el_tabs, {
    modelValue: $setup.activeName,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.activeName) = $event)),
    class: "sidler-tab"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_tab_pane, {
        label: "目录",
        name: "first",
        class: "directory"
      }, {
        default: _withCtx(() => [
          _createVNode($setup["FolderTree"], {
            meta: $props.meta,
            "parent-field": $setup.parentField,
            folderList: $setup.formatFolderList,
            onSetFavorite: $setup.setFavorite,
            onSetCurrentFolder: $setup.setCurrentFolder,
            onSetHome: $setup.setHome,
            filters: $props.filters
          }, null, 8 /* PROPS */, ["meta", "parent-field", "folderList", "filters"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_tab_pane, {
        label: "收藏",
        name: "second"
      }, {
        default: _withCtx(() => [
          _createVNode($setup["FolderTree"], {
            meta: $props.meta,
            "parent-field": $setup.parentField,
            folderList: $setup.favoriteFolders,
            onSetFavorite: $setup.setFavorite,
            onSetCurrentFolder: $setup.setCurrentFolder,
            onSetHome: $setup.setHome,
            filters: $props.filters
          }, null, 8 /* PROPS */, ["meta", "parent-field", "folderList", "filters"])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"])), [
    [_directive_loading, $setup.loading]
  ])
}