
const folder = 'Home/Attachments';
export interface FileInfo {
	file_obj?: File;
	file_name?: string;
	cropper_file?: File;
	crop_box_data: null;
	optimize: any;
	name: any;
	doc: any;
	progress: number;
	total: number;
	failed: boolean;
	request_succeeded: boolean;
	error_message: null | string;
	file_url?: string;
	isLink?: boolean;
	uploading: boolean;
}

export default function upload(file: FileInfo, {
	doctype,
	docname,
	fieldname,
	method,
	success,
}:{
	doctype?: string;
	docname?: string;
	fieldname?: string;
	method?: string;
	success?:(file_doc: any, r: any) => void
} = {}) {
	return new Promise<void>((resolve, reject) => {
		let xhr = new XMLHttpRequest();
		xhr.upload.addEventListener('loadstart', () => {
			file.uploading = true;
		});
		xhr.upload.addEventListener('progress', e => {
			if (e.lengthComputable) {
				file.progress = e.loaded;
				file.total = e.total;
			}
		});
		xhr.upload.addEventListener('load', () => {
			file.uploading = false;
			resolve();
		});
		xhr.addEventListener('error', e => {
			file.failed = true;
			reject(e);
		});
		xhr.onreadystatechange = () => {
			if (xhr.readyState == XMLHttpRequest.DONE) {
				if (xhr.status === 200) {
					file.request_succeeded = true;
					let r = null;
					let file_doc = null;
					try {
						r = JSON.parse(xhr.responseText);
						if (r.message.doctype === 'File') {
							file_doc = r.message;
						}
					} catch (e) {
						r = xhr.responseText;
					}

					file.doc = file_doc;

					success?.(file_doc, r);


				} else if (xhr.status === 403) {
					file.failed = true;
					let response = JSON.parse(xhr.responseText);
					file.error_message = `Not permitted. ${response._error_message || ''}`;

				} else if (xhr.status === 413) {
					file.failed = true;
					file.error_message = 'Size exceeds the maximum allowed file size.';

				} else {
					file.failed = true;
					file.error_message = xhr.status === 0 ? 'XMLHttpRequest Error' : `${xhr.status} : ${xhr.statusText}`;

					let error = null;
					try {
						error = JSON.parse(xhr.responseText);
					} catch (e) {
						// pass
					}
					frappe.request.cleanup({}, error);
				}
			}
		};
		xhr.open('POST', '/api/method/upload_file', true);
		xhr.setRequestHeader('Accept', 'application/json');
		xhr.setRequestHeader('X-Frappe-CSRF-Token', frappe.csrf_token);

		let form_data = new FormData();
		if (file.file_obj) {
			form_data.append('file', file.file_obj, file.name);
		}
		form_data.append('is_private', '0');
		form_data.append('folder', folder);

		if (file.file_url) {
			form_data.append('file_url', file.file_url);
		}

		if (file.file_name) {
			form_data.append('file_name', file.file_name);
		}

		if (doctype && docname) {
			form_data.append('doctype', doctype);
			form_data.append('docname', docname);
			if (fieldname) {
				form_data.append('fieldname', fieldname);
			}
		}


		if (method) {
			form_data.append('method', method);
		}

		if (file.optimize) {
			form_data.append('optimize', '1');
		}

		xhr.send(form_data);
	});
}
