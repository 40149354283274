<template>
  <Page>
    <template v-slot:title>
      <h3 class="title">{{ tt('Company Task Template Assessment Scoring Page') }}</h3>
    </template>
    <template v-slot:tools>
      <ToolBar
        v-model:company="company"
        v-model:organization="organization"
        v-model:refreshValue="refreshValue"
        v-model:loading="loading"
        :detailGridApi="detailGridApi">
      </ToolBar>
    </template>
    <template v-slot:sider>
      <OrganizationTree :organizations="organizations" :organizationLoading="organizationLoading" v-model:organization="organization">
      </OrganizationTree>
    </template>

    <DetailTable :company="company" :detailTableData="detailTableData" :loading="loading" @onDetailGridReady="onDetailGridReady">
    </DetailTable>
  </Page>
</template>

<script lang="ts" setup>
  import { ref, watch } from 'vue';
  import { GridApi } from 'ag-grid-community';

  import { DetailTableData } from '../type';
  import Page from '../../../../../../../guigu_pm/guigu_pm/public/js/components/page/index.vue';
  import { Organization } from '../type';

  import ToolBar from './components/ToolBar.vue';
  import DetailTable from './components/DetailTable.vue';
  import OrganizationTree from './components/OrganizationTree.vue';

  const tt = __;

  const company = ref<string>('');
  const organization = ref<string>('');
  const refreshValue = ref<number>(0);

  const detailTableData = ref<DetailTableData[]>([]);
  const detailGridApi = ref<GridApi>();

  const organizations = ref<Organization[]>([]);

  const loading = ref<boolean>(false);
  const organizationLoading = ref<boolean>(false);

  function onDetailGridReady(params: any) {
    detailGridApi.value = params;
  }

  watch(
    () => company?.value,
    async () => {
      if (company?.value) {
        organizationLoading.value = true;
        const organizationsRes: Organization[] = await frappe.db.get_list('Guigu Sub Company Organization', {
          filters: { sub_company: company.value, stop_using: 0 },
          fields: ['name', 'parent_guigu_sub_company_organization', 'organization_name'],
          limit: '',
        });
        const resultOrganization: Organization[] = [];
        const organizationNames: string[] = [];
        (organizationsRes || []).forEach((item) => {
          organizationNames.push(item.name);
          resultOrganization.push({
            id: item.name,
            name: item.name,
            parent_guigu_sub_company_organization: item.parent_guigu_sub_company_organization,
            label: item.organization_name,
            parent: item.parent_guigu_sub_company_organization,
          });
        });
        organizations.value = resultOrganization;
        organizationLoading.value = false;
      }
    }
  );

  watch([company, organization, refreshValue], async () => {
    if (!organization.value) {
      return;
    }
    if (!company.value) {
      return;
    }
    loading.value = true;
    const data = await frappe.call({
      method:
        'guigu_pc.guigu_sub_company_work.page.sub_company_work_template_assessment.sub_company_work_template_assessment.get_sub_company_work_template_assessment',
      args: {
        sub_company: company.value,
        organization: organization.value,
      },
    });
    const newData = (data?.message || []).map((item) => ({
      ...item,
      id: `${item.work_name}${item.assessment_name}`,
      assigner_user: item?.assigner_user || '',
    }));
    detailTableData.value = newData;
    loading.value = false;
  });
</script>
<style lang="css" scoped>
  .container {
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
  }

  .title {
    margin-bottom: 0;
  }
</style>
