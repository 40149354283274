<template>
	<ElFormItem>
		<ElTable :data="buttons" style="width: 100%">
			<ElTableColumn type="index" :index="1" />
			<ElTableColumn :label="tt('Field')" #="{ row, $index }">
				<ElInput :modelValue="row.label"
					@update:modelValue="set($index, 'label', $event || '')" />
			</ElTableColumn>

			<ElTableColumn :label="tt('Sub Button')" #="{ row, $index }">
				<ElCheckbox :modelValue="row.level ? true : false"
					@update:modelValue="set($index, 'level', $event ? 1 : 0)" />
			</ElTableColumn>
			<ElTableColumn :label="tt('Enable Link')" #="{ row, $index }">
				<ElInput :modelValue="row.url"
					@update:modelValue="set($index, 'url', $event || '')" />
			</ElTableColumn>

			<ElTableColumn :label="tt('Operation')" #="{ $index }">
				<ElButtonGroup>
					<ElButton @click="moveTo($index)" text :icon="Sort"
						:title="tt('Move')" />
					<ElButton type="danger" @click="remove($index)" text
						:icon="Delete" :title="tt('Delete')" />
				</ElButtonGroup>
			</ElTableColumn>
		</ElTable>
		<ElButton @click="add">{{ tt('Add') }}</ElButton>
	</ElFormItem>
</template>
<script setup lang="ts">
import {computed, shallowRef, h} from 'vue';
import {
	ElTable, ElTableColumn,
	ElSelect, ElInput, ElCheckbox,
	ElFormItem, ElButtonGroup, ElButton, ElOption, ElMessageBox,
} from 'element-plus';
import {Delete, Sort} from '@element-plus/icons-vue';


const props = defineProps<{
	meta: locals.DocType;
	modelValue: any;
	define?: GlobalView;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: GlobalView.View): void;
}>();
const view = computed({
	get: () => props.modelValue,
	set: v => emit('update:modelValue', v),
});
const tt = __;

const buttons = computed<GlobalView.Button[]>({
	get: () => view.value.buttons || [],
	set: v => {
		view.value = {...view.value, buttons: v.map(v=>({...v, idx: undefined}))};
	},
});
function set(index: number, key: keyof GlobalView.Button, value: any) {
	const list = [...buttons.value];
	const item: any = list[index];
	if (!item) {
		return;
	}
	item[key] = value;
	buttons.value = list;
}

function add() {
	buttons.value = [...buttons.value, {label: '', url: ''}];
}
function remove(index: number) {
	const list = [...buttons.value];
	if (!list.splice(index, 1).length) {
		return;
	}
	buttons.value = list;
}
function move(from: number, to: number) {
	if (from === to) {
		return;
	}
	const list = [...buttons.value];
	const item: any = list[from];
	if (!item) {
		return;
	}
	list.splice(from, 1);
	list.splice(to, 0, item);
	buttons.value = list;
}
function moveTo(index: number) {
	const target = shallowRef<number>(index);
	const label = buttons.value[index]?.label || '';
	ElMessageBox({
		title: `将 ${label} #${index + 1} 移动到...`,
		message: (): any => h(ElSelect, {
			filterable: true, defaultFirstOption: true,
			modelValue: target.value,
			'onUpdate:modelValue': (val: number) => {
				target.value = val;
			},
		}, buttons.value.map(({label}, i) => h(ElOption, {
			value: i,
			label: i === index
				? '<<<位置不变>>>'
				: `${label || ''} #${i + 1} ${i < index ? '之前' : '之后'}`,
		}))),
	}).then(() => {
		move(index, target.value);
	});
}
</script>
