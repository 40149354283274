import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b17e1350"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tool_container" }
const _hoisted_2 = { class: "pm_toolbar" }
const _hoisted_3 = { class: "input-container" }
const _hoisted_4 = { class: "input-container" }
const _hoisted_5 = { class: "input-container" }
const _hoisted_6 = { class: "completed_qty_btn" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString($setup.tt('Company')) + ": ", 1 /* TEXT */),
        _createVNode($setup["ElSelect"], {
          class: "input",
          filterable: "",
          modelValue: $setup.companyValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.companyValue) = $event)),
          size: "small",
          onChange: _cache[1] || (_cache[1] = value => $setup.confirmChange('company', value))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.companies, (item) => {
              return (_openBlock(), _createBlock($setup["ElOption"], {
                key: item.name,
                label: item.company_name,
                value: item.name
              }, null, 8 /* PROPS */, ["label", "value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createTextVNode(_toDisplayString($setup.tt('Project')) + ": ", 1 /* TEXT */),
        _createVNode($setup["ElSelect"], {
          class: "input",
          filterable: "",
          modelValue: $setup.projectValue,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.projectValue) = $event)),
          size: "small",
          popperClass: "projectLink",
          onChange: _cache[3] || (_cache[3] = value => $setup.confirmChange('project', value))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.projects, (item) => {
              return (_openBlock(), _createBlock($setup["ElOption"], {
                key: item.name,
                label: item.project_abbr_name || item.project_name,
                value: item.name
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, [
                    _createElementVNode("strong", null, _toDisplayString(item.project_abbr_name || item.project_name), 1 /* TEXT */),
                    _createElementVNode("span", null, _toDisplayString(item.description), 1 /* TEXT */)
                  ])
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createTextVNode(_toDisplayString($setup.tt('Date')) + ": ", 1 /* TEXT */),
        _createVNode($setup["ElDatePicker"], {
          modelValue: $setup.monthValue,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.monthValue) = $event)),
          teleported: false,
          clearable: false,
          editable: false,
          type: "month",
          valueFormat: "YYYY-MM-DD",
          disabledDate: $setup.disabledDate,
          onChange: _cache[5] || (_cache[5] = value => $setup.confirmChange('month', value)),
          size: "small"
        }, null, 8 /* PROPS */, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode($setup["ElButtonGroup"], { class: "boq_button_group" }, {
        default: _withCtx(() => [
          _createVNode($setup["ElButton"], {
            size: "small",
            onClick: _cache[6] || (_cache[6] = ()=>$setup.emit('updateIsExpand',true))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('Expand')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode($setup["ElButton"], {
            size: "small",
            onClick: _cache[7] || (_cache[7] = ()=>$setup.emit('updateIsExpand',false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('Collapse')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode($setup["ElButton"], {
        type: "primary",
        size: "small",
        disabled: !$props.isModified,
        onClick: $setup.clickSave
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.tt('Save')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled"])
    ])
  ]))
}