import { createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-da789f12"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "photo-info-container" }
const _hoisted_2 = { class: "left" }
const _hoisted_3 = { class: "photo-image-container" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "right" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElInput = _resolveComponent("ElInput")
  const _component_ElFormItem = _resolveComponent("ElFormItem")
  const _component_ElDatePicker = _resolveComponent("ElDatePicker")
  const _component_ElTreeSelect = _resolveComponent("ElTreeSelect")
  const _component_ElForm = _resolveComponent("ElForm")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", { src: $setup.url }, null, 8 /* PROPS */, _hoisted_4)
      ]),
      _createCommentVNode(" 暂时注释这个删除,因为暂时只能单选 "),
      _createCommentVNode(" <ElButton\n\t\t\t\tclass='photo-delete'\n\t\t\t\ttype=\"danger\"\n\t\t\t\t@click='deleteFile'\n\t\t\t>\n\t\t\t删除\n\t\t\t</ElButton> ")
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_ElForm, {
        ref: "formRef",
        "label-width": "auto",
        rules: $setup.rules,
        model: $setup.form
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ElFormItem, {
            label: $setup.tt('File Name'),
            props: "name"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElInput, {
                modelValue: $setup.form.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.form.name) = $event))
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          ($props.showDateInput)
            ? (_openBlock(), _createBlock(_component_ElFormItem, {
                key: 0,
                label: $setup.tt('Date'),
                props: "date",
                rules: "{ required: true, trigger: 'blur' }"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElDatePicker, {
                    modelValue: $setup.form.date,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.form.date) = $event)),
                    type: "date",
                    onChange: $setup.changeDate
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_ElFormItem, {
            label: $setup.tt('Structure'),
            props: "target"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElTreeSelect, {
                "default-expand-all": "",
                modelValue: $setup.form.target,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.form.target) = $event)),
                data: $setup.treeStructure,
                "render-after-expand": true,
                "filter-node-method": $setup.filterNodeMethod,
                props: $setup.defaultProps,
                onChange: $setup.changeTarget,
                filterable: "",
                "check-strictly": ""
              }, null, 8 /* PROPS */, ["modelValue", "data"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_ElFormItem, {
            label: $setup.tt('Author'),
            props: "photographer"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElInput, {
                modelValue: $setup.form.photographer,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.form.photographer) = $event)),
                onChange: $setup.changeAuthor
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_ElFormItem, {
            label: $setup.tt('Description'),
            props: "description"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElInput, {
                rows: 2,
                type: "textarea",
                modelValue: $setup.form.description,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.form.description) = $event))
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["rules", "model"])
    ])
  ]))
}