import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElFormItem"], null, {
    default: _withCtx(() => [
      _createVNode($setup["ElTable"], {
        data: $setup.fields,
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode($setup["ElTableColumn"], {
            type: "index",
            index: 1
          }),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Field')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElSelect"], {
                modelValue: row.date,
                filterable: "",
                defaultFirstOption: "",
                "onUpdate:modelValue": $event => ($setup.set($index, 'date', $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fieldOptions, (field) => {
                    return (_openBlock(), _createBlock($setup["ElOption"], {
                      key: field.value,
                      value: field.value,
                      label: field.label
                    }, null, 8 /* PROPS */, ["value", "label"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Display control field')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElSelect"], {
                modelValue: row.filter_field,
                "onUpdate:modelValue": $event => ($setup.set($index, 'filter_field', $event)),
                clearable: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filterFields, (field) => {
                    return (_openBlock(), _createBlock($setup["ElOption"], {
                      key: field.value,
                      value: field.value,
                      label: field.label
                    }, null, 8 /* PROPS */, ["value", "label"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Displays control field values')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElSelect"], {
                modelValue: $setup.getList(row.filter_value),
                filterable: "",
                defaultFirstOption: "",
                "onUpdate:modelValue": $event => ($setup.set($index, 'filter_value', $setup.setList($event))),
                multiple: "",
                allowCreate: "",
                reserveKeyword: false
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["ElOption"], {
                    label: $setup.tt('Manual input'),
                    value: "",
                    disabled: ""
                  }, null, 8 /* PROPS */, ["label"])
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Color')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElColorPicker"], {
                modelValue: row.color || '',
                "onUpdate:modelValue": $event => ($setup.set($index, 'color', $event || null))
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Size')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElInput"], {
                type: "number",
                modelValue: row.size,
                "onUpdate:modelValue": $event => ($setup.setWidth($index, 'size', $event || 0))
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Position')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElInput"], {
                type: "number",
                modelValue: row.position,
                "onUpdate:modelValue": $event => ($setup.setWidth($index, 'position', $event || 0))
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Operation')
          }, {
            default: _withCtx(({ $index }) => [
              _createVNode($setup["ElButton"], {
                type: "danger",
                onClick: $event => ($setup.remove($index)),
                text: "",
                icon: $setup.Delete,
                title: $setup.tt('Delete')
              }, null, 8 /* PROPS */, ["onClick", "icon", "title"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["data"]),
      _createVNode($setup["ElButton"], { onClick: $setup.add }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.tt('Add')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}