<template>
	<div class="toolbar">
		<div class="left-container">
			<ProjectCom
				class="project-select"
				default
				noLabel
				v-model="projectValue"
			></ProjectCom>
			<Structure
				class="input"
				v-show="project"
				:project="project"
				:modelValue="structure"
				@update:modelValue="changeStructure"></Structure>
			<PeriodPicker
				v-if="project"
				class="input"
				calendarType="Construction Calendar"
				:project="project"
				:periodType="`Year ${type}`"
				defaultValue
				:modelValue="period"
				@update:model-value="updatePeriod"></PeriodPicker>
		</div>
		<ElButton :disabled="isCreateDisabled" @click="$emit('createTempProgress')"
			type="primary">
			{{ tt("New Temporary Job") }}
		</ElButton>
	</div>
</template>
<script lang="ts" setup>
import {ref, onMounted, watchEffect, toRefs, computed} from 'vue';

import {Period, Project, Team} from '../type';
import PeriodPicker from '../../../../components/PeriodPicker/index.vue';
import {useMetaQuery} from '../../../../components/page/useMetaQuery';
import ProjectCom from '../../../../../../../../guigu/guigu/public/js/components/PageComponents/Tools/Project.vue';

import Structure from './Structure.vue';
const tt = __;
interface Props {
	project?: string,
	period?: Period,
	structure?: string
	isCreateDisabled:boolean
	type:'Month'|'Week'
}
const props = defineProps<Props>();
interface Emit {
	(e: 'update:project', project: string): void,
	(e: 'update:period', period?: Period): void,
	(e: 'update:structure', structure?: string): void
	(e: 'createTempProgress', structure?: string): void
}
const emit = defineEmits<Emit>();
const smallMeta = useMetaQuery();

const projectValue = computed({
	get: () => props.project || '',
	set: (v: string) => {
		emit('update:project', v);
	},
});

async function changeStructure(newStructure?: string) {
	emit('update:structure', newStructure);
}
function updatePeriod(period?: Period) {
	emit('update:period', period);
}

const flexDirection = computed(()=>smallMeta.value ? 'column' : 'row');
const margin = computed(()=>smallMeta.value ? '8px' : '0');

</script>
<style lang="less" scoped>
.project-select{
	width:280px;
}
.toolbar {
	display: flex;
	flex-direction: v-bind(flexDirection);
	justify-content: space-between;
	& :deep(.input){
		margin-right: 8px;
		margin-bottom: v-bind(margin);
	}
}
</style>
