/* eslint-disable eqeqeq */
import type {
	CellClassParams,
	ColDef,
	ColGroupDef,
	EditableCallbackParams,
	ICellEditorParams,
	ICellRendererParams,
} from 'ag-grid-community';

import FloatComponent from '../../../../AgGrid/components/Float.vue';
import FloatEditor from '../../../../AgGrid/components/FloatEditor.vue';
import Action from '../../../../AgGrid/components/Action.vue';
import type {StructureItemFormat} from '../type';

export const editCellStyle = {border: '1px solid #ccc'};

export function getPlanColumns(
	type:'Month'|'Week',
	onRemove:(data:StructureItemFormat)=>void,
): (ColDef | ColGroupDef)[] {
	return [
		{
			headerName: __('Construction content'),
			flex: 1,
			minWidth: 350,
			enableRowGroup: false,
			editable: (e:EditableCallbackParams)=>e.data.type === 'temp',
			field: 'label',
			filter: false,
			sortable: false,
			pinned: 'left',
			cellStyle: (params: CellClassParams) => {
				const {data} = params;
				if (data.type === 'temp') {
					return editCellStyle;
				}
				return;
			},
		},
		{
			headerName: __('Unit'),
			field: 'unit',
			width: 80,
			minWidth: 80,
			enableRowGroup: false,
			editable: (e:EditableCallbackParams)=>e.data.type === 'temp',
			filter: false,
			pinned: 'left',
			cellStyle: (params: CellClassParams) => {
				const {data} = params;
				if (data.type === 'temp') {
					return editCellStyle;
				}
				return;
			},
		},
		{
			headerName: __('Design Quantity'),
			field: 'designQuantity',
			width: 110,
			minWidth: 110,
			enableRowGroup: false,
			editable: (e:EditableCallbackParams)=>e.data.type === 'temp',
			cellRenderer: FloatComponent,
			cellDataType: false,
			filter: false,
			cellRendererParams: (params: ICellRendererParams) => {
				const {data} = params;
				return {
					value:
					data?.designQuantity == 0
						? null
						: data?.designQuantity,
				};
			},
			cellStyle: (params: CellClassParams) => {
				const {data} = params;
				if (data.type === 'temp') {
					return editCellStyle;
				}
				return;
			},
		},
		{
			headerName: __('No Complete'),
			field: 'until_unfinished',
			width: 110,
			minWidth: 110,
			enableRowGroup: false,
			editable: false,
			cellRenderer: FloatComponent,
			filter: false,
			cellRendererParams: (params: ICellRendererParams) => {
				const {data} = params;
				return {
					value:
				data?.until_unfinished == 0
					? null
					: data?.until_unfinished,
				};
			},
		},
		{
			headerName: type === 'Week' ? __('Last Week Plan') : __('Last Month Plan'),
			field: 'last_period_plan',
			width: 130,
			minWidth: 130,
			filter: false,
			enableRowGroup: false,
			editable: false,
			cellRenderer: FloatComponent,
			cellRendererParams: (params: ICellRendererParams) => {
				const {data} = params;
				const value
				= data?.last_period_plan == 0
					? null
					: data?.last_period_plan;
				return {
					value: data?.last_period_plan == 0 ? null : value,
				};
			},
		},
		{
			headerName: type === 'Week' ? __('Last Week Complete') : __('Last Month Comnplete'),
			field: 'last_period_finished',
			width: 120,
			minWidth: 120,
			filter: false,
			enableRowGroup: false,
			editable: false,
			cellRenderer: FloatComponent,
			cellRendererParams: (params: ICellRendererParams) => {
				const {data} = params;
				return {
					value:
				data?.last_period_finished == 0
					? null
					: data?.last_period_finished,
				};
			},
		},
		{
			headerName: type === 'Week' ? __('This Week Plan') : __('This Month Plan'),
			field: 'quantity',
			width: 120,
			minWidth: 120,
			filter: false,
			enableRowGroup: false,
			editable: true,
			cellDataType: false,
			cellRenderer: FloatComponent,
			cellEditor: FloatEditor,
			cellStyle: (params: CellClassParams) => editCellStyle,
			cellRendererParams: (params: ICellRendererParams) => {
				const {data, value} = params;
				return {
					value: value == 0 ? null : value,
				};
			},
		},
		{
			headerName: __('Remark'),
			field: 'plan_remark',
			flex: 2,
			minWidth: 300,
			filter: false,
			enableRowGroup: false,
			editable: true,
			cellStyle: (params: CellClassParams) => editCellStyle,
		},
		{
			headerName: __('Completion status and summary'),
			field: 'summary',
			flex: 2,
			minWidth: 300,
			filter: false,
			enableRowGroup: false,
			editable: true,
			cellStyle: (params: CellClassParams) => editCellStyle,
		},
		{
			headerName: __('Action'),
			field: 'action',
			filter: false,
			enableRowGroup: false,
			editable: false,
			pinned: 'right',
			resizable: false,
			menuTabs: [],
			width: 50,
			cellRenderer: Action,
			cellRendererParams: (params: ICellRendererParams) => {
				const {data} = params;
				if (data.type === 'temp') {
					return {
						onRemove,
					};
				}
			},
		},
	];
}
