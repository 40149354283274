// @ts-check
const {prototype} = frappe.ui.form.ControlComment;
prototype.submit = function () {
	this.on_submit && this.on_submit(this.get_value(), this.attachments?.attachments || []);
};

const old_make_wrapper = prototype.make_wrapper;

prototype.make_wrapper = function make_wrapper() {
	old_make_wrapper.apply(this, arguments);
	this.make_attachments();
};

prototype.update_state = function () {
	const value = this.get_value();
	const attachments = this.attachments?.attachments || [];
	if (strip_html(value).trim() != '' || value.includes('img') || attachments.length > 0) {
		this.button.removeClass('hidden').addClass('btn-primary');
	} else {
		this.button.addClass('hidden').removeClass('btn-primary');
	}
};
prototype.make_attachments = function () {
	if (!this.frm){ return; }
	if (this.no_wrapper) { return; }
	this.comment_wrapper.children('button').before(`
	<ul class="list-unstyled sidebar-menu form-attachments">
		<li class="sidebar-label attachments-label">
		</li>
		<li class="add-attachment-btn">
			<button class="data-pill btn">
				<span class="pill-label ellipsis">
					${__('Attach File')}
				</span>
				<svg class="icon icon-sm">
					<use href="#icon-add"></use>
				</svg>
			</button>
		</li>
	</ul>`);
	this.attachments = new frappe.ui.form.CommentAttachments({
		parent: this.comment_wrapper.find('.form-attachments'),
		frm: this.frm,
	});
};
prototype.clear_attachments = function () {
	this.attachments.clear();
};
prototype.refresh_attachments = function (attachments) {
	this.attachments?.refresh(attachments);
};
prototype.set_model_value = function(value){
	if (this.doc) {
		this.doc[this.df.fieldname] = value;
	}
	this.set_input(value);
	return Promise.resolve();
} 
