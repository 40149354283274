<template>
	<div class="root ag-theme-guigu">
		<AgGridVue
			class="ag"
			:style="style"
			@selectionChanged="selectionChanged"
			rowDragMultiRow
			rowSelection="multiple"
			@gridReady="onReady"
			@RowDragEnd="onRowDragEnd"
			:rowHeight="rowHeight"
			:getRowId="getRowId"
			:getDataPath="getDataPath"
			:localeText="zhCN"
			:rowData="data"
			treeData
			:defaultExcelExportParams="excelExportParams"
			:columnDefs="columnDefs"
			:suppressDragLeaveHidesColumns="smallMeta"
			:autoGroupColumnDef="autoGroupColumnDef" />
		<slot name="pagination" />
		<TargetSelector
			v-model="DShow" :x="treeDragInfo?.x" :y="treeDragInfo?.y"
			@submit="onClickItem" />
		<DeleteSelector v-model="deleteTarget" @delete="delTree" :meta="meta" />
	</div>
</template>
<script lang="ts" setup>
import {computed, shallowRef, watch} from 'vue';
import {AgGridVue} from 'ag-grid-vue3';
import type {
	SelectionChangedEvent, GridApi, GetRowIdParams, RowDragEndEvent, GridReadyEvent,
	ColumnApi,
} from 'ag-grid-community';
import {ElNotification} from 'element-plus';
import {useWindowSize} from '@vueuse/core';

import {getTreeExpander, useExpander} from '../Expander';
import {useMetaQuery} from '../hooks/useMetaQuery';
import zhCN from '../agGrid/zhCN';

import defaultExcelExportParams from './defaultExcelExportParams';
import DeleteSelector from './DeleteSelector.vue';
import TargetSelector from './TargetSelector.vue';
import getColumns from './getColumns';
import {ListConfiguration} from './types';
import useAggregation from './useAggregation';
import useStyle from './useStyle';
import Title from './Title/index.vue';

defineOptions({name: 'DefaultMain'});


const props = defineProps<{
	meta: locals.DocType;
	options: Record<string, any>;

	data: locals.Doctype[];
	total: number;
	loading?: boolean;

	modelValue: any;
	selected?: any[];

	infiniteScroll: boolean;
	page: number;
	limit: number;
	group: GlobalView.Group[];
	sort: GlobalView.MainLoaderOptions['order'];
	filters?: any;

	detail?: boolean;
	rowAction?: any;


	view?: GlobalView.View;
	configuration?: ListConfiguration;
}>();
const emit = defineEmits<{
	(event: 'refresh'): void;
	(
		event: 'delete',
		doc: any,
		mode?: 'root' | 'parent' | 'all',
		parent?: string,
	): void;
	(event: 'create', data: any, extend?: boolean): void;
	(event: 'nextPage'): void;
	(event: 'update:modelValue', value: any): void;
	(event: 'update:selected', value: any[]): void;
	(event: 'update:data', data: locals.Doctype[]): void;
	(event: 'filter', field: string, operator: string, value: any): void;
	(event: 'update:configuration', cfg: any): void;

	(event: 'sort', target: string, docs: string[], before: boolean, field?: string): void
	(event: 'sort', target: string, docs: string[], before: boolean, children?: boolean): void
}>();
const smallMeta = useMetaQuery();
const configuration = computed(() => props.configuration || {});
const gridApi = shallowRef<GridApi>();

const gridColumnApi = shallowRef<ColumnApi>();
const {width, height} = useWindowSize();
const isMobile = computed(() => width.value < 640);
watch([isMobile, gridColumnApi], ([isMobile, columnApi]) => {
	if (!isMobile || !columnApi) {
		return;
	}
	const cols = columnApi.getAllGridColumns() || [];
	columnApi.setColumnsPinned(cols.map(v => v.getColId()), null);
}, {immediate: true});

function nextPage() {
	emit('nextPage');
}
function refresh() {
	emit('refresh');
}
function setValue(value?: any) {
	emit('update:modelValue', value);
}
const selected = computed({
	get: () => props.selected,
	set: v => emit('update:selected', v || []),
});

const data = computed<locals.Doctype<string>[]>(() => props.data.map(v => ({
	...v,
})));
const map = computed(() => new Map(data.value.map(v => [v.name, v])));

function setFilter(field: string, operator: string, value: any) {
	emit('filter', field, operator, value);
}

function updateExpanded(expanded: any[]) {
	const api = gridApi.value;
	if (!api) {
		return;
	}
	api.collapseAll();
	const ids = new Set(expanded);
	for (const id of ids) {
		const node = api.getRowNode(id);
		if (!node) {
			continue;
		}
		api.setRowNodeExpanded(node, true);
	}
}

function getRowId({data}: GetRowIdParams<locals.Doctype>) {
	return data.name;
}
watch(() => props.data, d => {
	selected.value = [];
	const api = gridApi.value;
	if (api) {
		api.deselectAll();
	}
});
function updateSelected(selected?: any[], api?: GridApi<any>) {
	if (!selected || !Array.isArray(selected) || !api) {
		return;
	}
	const selectedInApi = api.getSelectedNodes();
	const selectedIds = new Set(selected.map(v => v.name));
	for (const node of selectedInApi) {
		const {id} = node;
		if (selectedIds.has(id)) {
			selectedIds.delete(id);
		} else {
			node.setSelected(false);
		}
	}
	for (const id of selectedIds) {
		const node = api.getRowNode(id);
		if (node) {
			node.setSelected(true);
		}
	}
}
watch(gridApi, api => {
	updateSelected(selected.value, api);
});
watch(selected, list => {
	updateSelected(list, gridApi.value);
});
function getDataPath(data: locals.Doctype<string>): string[] {
	const parentFiled = props.meta.nsm_parent_field;
	const done = new Set();
	const stack: any[] = [];
	let path: string[] = [];
	const all = map.value;
	for (let item: any = data; item;) {
		const {__path__} = item;
		if (Array.isArray(__path__)) {
			for (const p of [...__path__].reverse()) {
				path.push(p as any);
			}
			break;
		}
		if (done.has(item)) {
			break;
		}
		done.add(item);
		stack.push(item);
		path.push(item.name);
		const parentId = item[parentFiled] as any;
		if (!parentId) {
			break;
		}
		item = all.get(parentId);
	}
	path = path.reverse();
	const paths = [...path];
	for (const s of stack) {
		s.__path__ = [...paths];
		paths.pop();
	}
	return path;
}
function selectionChanged(event: SelectionChangedEvent) {
	selected.value = event.api.getSelectedRows();
}
const titleDocField = computed(() => {
	const fieldName = props.meta.title_field;
	const {fields} = props.meta;
	return fieldName && fields.find(v => v.fieldname === fieldName) || null;
});


const sortField = computed(() => {
	const {sort, meta, data} = props;
	if (!data?.length) {
		return;
	}
	if (sort.length !== 1) {
		return;
	}
	const [field] = sort;
	const {doctype} = field;
	if (doctype !== meta.name) {
		return;
	}
	return field;
});
const treeDragInfo = shallowRef<{
	target: string;
	list: string[];
	desc: boolean;
	x: number;
	y: number;
}>();
const DShow = computed({
	get: () => Boolean(treeDragInfo.value),
	set: v => {
		if (!v) {
			treeDragInfo.value = undefined;
		}
	},
});
function dragTree(list: any[], target: any, tree: GlobalView.Sort, event: MouseEvent) {
	if (list.find(it => it.name === target.name)) {
		return;
	}
	for (const it of list) {
		if (it.lft < target.lft && target.rgt < it.rgt) {
			ElNotification({title: '移动失败', message: '无法将节点移动到自身后代中'});
			return;
		}
	}
	treeDragInfo.value = {
		list: list.map(v => v.name),
		target: target.name,
		desc: tree.desc || false,
		x: event.pageX,
		y: event.pageY,
	};
}
function onClickItem(before: boolean, children: boolean) {
	const info = treeDragInfo.value;
	treeDragInfo.value = undefined;
	if (!info) {
		return;
	}
	const {target, list, desc} = info;
	emit('sort', target, [...list].reverse(), desc ? !before : before, children);
}
async function onReady({api, columnApi}: GridReadyEvent) {
	gridApi.value = api;
	gridColumnApi.value = columnApi;
}
async function onRowDragEnd({overNode, event, nodes}: RowDragEndEvent) {
	if (!overNode) {
		return;
	}
	const list = nodes.map(n => n.data);
	if (!list.length) {
		return;
	}
	const target = overNode.data;
	const tree = sortField.value;
	if (!tree) {
		return;
	}
	if (['lft', 'rgt'].includes(tree.field)) {
		return dragTree(list, target, tree, event);
	}
}

function preventClick(e: Event, data: any) {
	if (props.detail) {
		e.preventDefault();
	}
	setValue(data?.name === props.modelValue?.name ? undefined : data);
}

const deleteTarget = shallowRef<Record<string, any>>();
function delTree(doc: any, mode?: 'root' | 'parent' | 'all', parent?: string) {
	emit('delete', doc, mode, parent);
}
function del(data: any) {
	deleteTarget.value = data;
}
const treeParent = computed(() => {
	const {meta} = props;
	if (!meta) {
		return;
	}
	if (!meta?.is_tree) {
		return;
	}
	const parent = meta.nsm_parent_field;
	if (!parent) {
		return;
	}
	if (!meta.fields.find(f => f.fieldname === parent)) {
		return;
	}
	return parent;
});
const state = {
	get meta() {
		return props.meta;
	},
	get rowAction() {
		return props.rowAction;
	},
	get titleField() {
		return titleDocField.value;
	},
	get linkField() {
		return props.view?.linkField;
	},
	get treeParent() {
		return treeParent.value;
	},
};


const autoGroupColumn = computed(() => {
	const {width, maxWidth, minWidth} = props.view?.titleField || {};
	const titleField = titleDocField.value;
	return {
		colId: `id`,
		resizable: true,
		checkboxSelection: true,
		headerCheckboxSelection: true,
		enableRowGroup: false,
		pinned: 'left',
		rowDrag: true,

		headerName: titleField ? __(titleField.label) : 'ID',
		width: width || 200, maxWidth, minWidth,
		field: titleField?.fieldname || 'name',
		cellRendererParams: {
			innerRenderer: Title,
			preventClick,
			del,
			getState: () => state,
		},
	};
});

const columns = computed(() => getColumns(
	props.meta,
	setFilter,
	() => props.data,
	props.view?.fields || [],
	configuration.value.fixedFieldColumns || 0,
	true,
));

const {setExpandable, expandable} = useExpander(computed(() => props.view?.name || ''));

const aggregation = useAggregation(
	computed(() => props.view),
	configuration,
	titleDocField,
	computed(() => props.meta),
	computed(() => props.data),
);
watch([aggregation, gridApi], ([aggregation, api]) => {
	if (!api) {
		return;
	}
	api.setPinnedBottomRowData(aggregation ? [aggregation] : []);
}, {immediate: true});

const expanders = computed(() => {
	if (props.loading) {
		return [];
	}
	return getTreeExpander(props.data, props.meta.nsm_parent_field);
});
watch(expanders, list => {
	setExpandable(list, updateExpanded);
	const expandedDefault = props.configuration?.expandedDefault || 0;
	if (expandedDefault <= 0) {
		return;
	}
	const ex = expandable.value;
	if (!ex.length) {
		return;
	}
	const level = Math.min(ex.length, expandedDefault) - 1;
	ex[level]?.set();
}, {immediate: true});

const {rowHeight: {value: rowHeight}, style: {value: style}} = useStyle(configuration);
const columnDefs = columns.value;
const autoGroupColumnDef = autoGroupColumn.value;


const hasSelected = computed(() => Boolean(selected.value.length));
const excelExportParams = computed(() => ({
	...defaultExcelExportParams,
	onlySelected: hasSelected.value,
}));
</script>
<style scoped lang="less">
.root {
	width: 100%;
	height: 100%;
	padding: 8px;
	position: relative;
}

.ag {
	width: 100%;
	height: 100%;


	:global(.ag-cell-wrapper) {
		height: 100%
	}

	:global(.ag-group-value) {
		flex: 1;
		height: 100%
	}

	:global(.ag-full-width-container) {

		:global(.ag-group-value) {
			flex: unset;
		}
	}
}
</style>
