import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';

import JSZip from '../../../../../../../guigu/guigu/public/lib/jszip.min.js';
import FileSaver from '../../../../../../../guigu/guigu/public/lib/FileSaver.min.js';

export function getBinaryContent(path: string) {
	return new Promise<string>((resolve, reject) =>
		PizZipUtils.getBinaryContent(path, (e: null | Error, c: string) => (e ? reject(e) : resolve(c))));
}

const docxMimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export async function exportDocx(template: any, data: any) {
	if (template instanceof Blob) {
		template = await template.arrayBuffer();
	}
	const zip = new PizZip(template);
	const doc = new Docxtemplater(zip, {
		paragraphLoop: true,
		linebreaks: true,
	});

	doc.setData(data);
	doc.render();
	const out = doc.getZip().generate({
		type: 'blob',
		mimeType: docxMimeType,
	});
	return out;
}

export function saveAs(data: Blob | MediaSource | string, name: string) {
	const url = typeof data === 'string' ? data : URL.createObjectURL(data);
	const a = document.createElement('a');
	a.href = url;
	a.download = name;
	a.target = '_blank';
	a.click();
	if (typeof data !== 'string') {
		setTimeout(() => {
			URL.revokeObjectURL(url);
		}, 30000);
	}
}

export function zip(files: { user: string; userFolder: string; date: string; detail: any; interview: any }[], type: 'week' | 'month') {
	const zip = new JSZip();
	const time = moment().format('YYYY-MM-DD');
	const fileFolder = zip.folder(`${time}-export`);
	for (const file of files) {
		const userFolder = fileFolder.folder(file.userFolder);
		const detailLabel
      = type === 'week'
      	? __('{}{}Weekly Assessment Details.docx', [file.user, moment(file.date).format('YYYY-ww')])
      	: __('{}{}Monthly Assessment Details.docx', [file.user, moment(file.date).format('YYYY-MM')]);
		const interviewLabel
      = type === 'week'
      	? __('{} {} Weekly Interview Record. docx', [file.user, moment(file.date).format('YYYY-ww')])
      	: __('{} {} Monthly Meeting Record. docx', [file.user, moment(file.date).format('YYYY-MM')]);
		if (file.detail) {
			userFolder.file(detailLabel, file.detail, {
				base64: true,
			});
		}
		if (file.interview) {
			userFolder.file(interviewLabel, file.interview, {
				base64: true,
			});
		}
	}
	zip.generateAsync({type: 'blob'}).then((content: any) => {
		const time = moment().format('YYYY-MM-DD');
		FileSaver.saveAs(content, `${time}-export.zip`);
	});
}
