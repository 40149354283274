<template>
	<div class="doctype" v-loading="loading">
		<ElTable :data="doctypeList" border style="width: 100%" :height="tableHeight">
			<template #empty>
				<Empty ref="empty"></Empty>
			</template>
			<ElTableColumn fixed prop="name" label="名称" width="250" >
				<template #default="scope">
					{{ `${tt(scope.row.name)}(${scope.row.name})` }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="module" label="模块" width="180" >
				<template #default="scope">
					{{ `${tt(scope.row.module)}(${scope.row.module})` }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="is_submittable" label="可提交" width="80" >
				<template #default="scope">
					{{ scope.row.is_submittable?'是':'否' }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="istable" label="是子表" width="80" >
				<template #default="scope">
					{{ scope.row.istable?'是':'否' }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="issingle" label="是单笔记录" width="100" >
				<template #default="scope">
					{{ scope.row.issingle?'是':'否' }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="is_tree" label="支持树结构" width="100" >
				<template #default="scope">
					{{ scope.row.is_tree?'是':'否' }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="track_changes" label="记录历史" width="80" >
				<template #default="scope">
					{{ scope.row.track_changes?'是':'否' }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="description" label="描述">
			</ElTableColumn>
			<ElTableColumn prop="address" label="操作" width="60" >
				<template #default="scope">
					<ElButton type="primary" @click="editDoctype(scope.row)">详情</ElButton>
				</template>
			</ElTableColumn>
		</ElTable>
	</div>
</template>

<script setup lang='ts'>
import {ref, watch, onMounted, onUnmounted} from 'vue';
import {ElTable, ElTableColumn, ElButton, vLoading} from 'element-plus';

import type {AppInfo, Doctype, ModuleInfo} from './type';
import Empty from './Empty.vue';

const tt = __;
interface Props{
	current?:AppInfo|ModuleInfo
}
const props = defineProps<Props>();
const doctypeList = ref<Doctype[]>([]);
const loading = ref<boolean>(true);
const empty = ref<HTMLElement>();
const tableHeight = ref<string>('100%');
watch(()=>props.current, async()=>{
	getDoctypeList();
}, {
	deep: true,
	immediate: true,
});
function editDoctype(data:Doctype) {
	frappe.set_route(['form', 'DocType', data.name]);
}
async function getDoctypeList() {
	tableHeight.value = '100%';
	loading.value = true;
	if (!props.current) {
		return;
	}
	const res = await frappe.call<{message:Doctype}>({
		method: 'guigu.guigu.page.guigu_app_list.guigu_app_list.get_doctype_list',
		args: {
			type: props.current.type,
			name: props.current.name,
		},
	});
	doctypeList.value = res.message || [];
	loading.value = false;
	if (doctypeList.value.length === 0) {
		tableHeight.value = '182px';
	}
}
// watch(empty, () => {
// 	debugger;
// 	if (empty.value && doctypeList.value.length === 0) {
// 		tableHeight.value = '182px';
// 	}
// });
const popstateListener = function () {
	getDoctypeList();
};
onMounted(() => {
	window.addEventListener('popstate', popstateListener);
});
onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});
</script>

<style lang='less' scoped>
.doctype{
	height: 100%;
	display: flex;
    flex-direction: column;
	.btn-container{
		text-align: right;
		margin-bottom: 8px;
	}
	.role{
		cursor: pointer;
		margin-right: 4px;
		&:hover{
			text-decoration: underline;
		}
	}
}
</style>
