import type { AssessInfo } from '../type';

export function getData(type: 'week' | 'month', assessInfo?: AssessInfo) {
	const interview = assessInfo?.interview_record;
	const data: any = {};
	data.user = assessInfo?.user_doc.full_name || '';
	data.organization = assessInfo?.organization?.organization_name || '';
	if (type === 'week') {
		data.date = assessInfo?.date ? moment(assessInfo.date).format(__('gggg-ww Week')) : '';
	} else {
		data.date = assessInfo?.date ? moment(assessInfo.date).format('YYYY-MM') : '';
	}
	data.job = assessInfo?.job_doc?.job_title || '';
	if (interview) {
		data.organization = interview.organization;
		data.job = interview.job;
		data.leader = interview.leader_doc.name;
		data.assess_date = interview.assess_date;
		data.summary = interview.summary;
		data.advantage = interview.advantage;
		data.shortcoming = interview.shortcoming;
		data.requirement = interview.requirement;
		data.learning = interview.learning;
		data.suggestion = interview.suggestion;
	}
	return data;
}
