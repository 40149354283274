
import { createApp, h, ref } from 'vue';

import SelectExcelTemplateDialog from './guigu_project_monthly_report/SelectExcelTemplateDialog.vue';
let app;
let index = ref(0);
const dialog = new frappe.ui.Dialog({
	title: __('Import Sheet Template'),
	primary_action: values => {
		dialog.hide();
		index.value++;
	},
	primary_action_label: __('Import Sheet Template'),
});

export function getNewTitle(titles, newTitle) {
	if (titles.includes(newTitle)) {
		const res = newTitle.match(/\d+$/);
		let title = newTitle;
		if (res) {
			title = `${title.substring(0, res.index || title.length)}${(parseInt(res[0]) + 1)}`;
		} else {
			title = `${title}${1}`;
		}
		return getNewTitle(titles, title);
	}
	return newTitle;
}

function onAddSheet(frm, newSheets) {
	const selectedSheets = newSheets.map(item => {
		try {
			const selectedSheets = JSON.parse(item.template_content || '[]');
			return selectedSheets;
		} catch (e) { return; }
	}).filter(Boolean).flat();
	const oldSheets = JSON.parse(frm.doc.report_content || '[]');
	const names = oldSheets.map(s => s.name);
	for (const sheet of selectedSheets) {
		const name = getNewTitle(names, sheet.name);
		names.push(name);
		oldSheets.push({ ...sheet, name });
	}
	frm.set_value('report_content', JSON.stringify(oldSheets));
}
function render_copy_vue(wrapper, frm, excelMeta) {
	if (app) {
		return;
	}
	if (!wrapper) { return; }
	const newApp = createApp({
		props: [], render: () => h(SelectExcelTemplateDialog, {
			onAddSheet: newSheets => onAddSheet(frm, newSheets),
			excelMeta,
			index,
			query: { filters: [['project', '=', frm.doc.project], ['report_category', '=', frm.doc.report_category]] },
		}),
	});
	newApp.mount(wrapper);
	app = newApp;
}

async function getMeta(doctype) {
	let local_meta = frappe.get_meta(doctype);
	if (local_meta) {
		return local_meta;
	}
	await frappe.model.with_doctype(doctype);
	local_meta = frappe.get_meta(doctype);
	return local_meta || undefined;
}
frappe.ui.form.on('Guigu Project Monthly Report', {
	async import_sheet_template(frm) {
		const excelMeta = await getMeta('Guigu Project Monthly Report Template');
		dialog.show();
		render_copy_vue(dialog.$body[0], frm, excelMeta);
	},
});
