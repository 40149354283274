import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b600451"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tree-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.users?.length)
      ? (_openBlock(), _createBlock($setup["ElTree"], {
          key: 0,
          ref: "userTree",
          data: $props.users,
          props: $setup.defaultProps,
          onNodeClick: $setup.handleNodeClick,
          highlightCurrent: "",
          nodeKey: "name",
          currentNodeKey: $setup.currentNodeKey
        }, null, 8 /* PROPS */, ["data", "currentNodeKey"]))
      : _createCommentVNode("v-if", true)
  ]))
}