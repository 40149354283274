<template>
	<ElButton
		v-if="props.params.data.applicable"
		type="text"
		size="small"
		@click="props.params.openNoticeDialog(props.params.data.name)"
	>
		{{ tt('Inapplicability') }}
	</ElButton>
</template>

<script setup lang="ts">
import {ElButton} from 'element-plus';
import type {ICellRendererParams} from 'ag-grid-community';
const tt = __;

const props = defineProps<{
	params: ICellRendererParams & {
		openNoticeDialog: (name: string) => void;
	}
}>();

</script>

<style lang="less" scoped></style>
