import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElTabs"], {
    modelValue: $setup.cusTab,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.cusTab) = $event)),
    type: "border-card"
  }, {
    default: _withCtx(() => [
      _createVNode($setup["ElTabPane"], {
        name: "workInstruction",
        label: $setup.tt('Standard Work Instruction')
      }, {
        default: _withCtx(() => [
          ($setup.cusTab==='workInstruction')
            ? (_openBlock(), _createBlock($setup["Instruction"], {
                key: 0,
                instructionName: $setup.instructionName,
                referenceType: $setup.referenceType,
                referenceName: $setup.referenceName
              }, null, 8 /* PROPS */, ["instructionName", "referenceType", "referenceName"]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["label"]),
      _createVNode($setup["ElTabPane"], {
        name: "cusWorkInstruction",
        label: $setup.tt('Customize Work Instruction')
      }, {
        default: _withCtx(() => [
          ($setup.cusTab==='cusWorkInstruction')
            ? (_openBlock(), _createBlock($setup["Instruction"], {
                key: 0,
                instructionName: $setup.instructionName,
                referenceType: $setup.referenceType,
                referenceName: $setup.referenceName
              }, null, 8 /* PROPS */, ["instructionName", "referenceType", "referenceName"]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["label"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}