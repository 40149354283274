import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex"} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElPopover"], {
    placement: "bottom",
    visible: $setup.visible,
    showArrow: "",
    trigger: "click"
  }, {
    reference: _withCtx(() => [
      _createVNode($setup["ElButton"], {
        type: "danger",
        size: "small",
        onClick: _cache[2] || (_cache[2] = $event => ($setup.visible = true))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.tt('Remove')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString($setup.tt("Are you sure you want to delete it?")), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["ElButton"], {
          size: "small",
          onClick: _cache[0] || (_cache[0] = $event => ($setup.visible = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt("Cancel")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElButton"], {
          size: "small",
          type: "primary",
          onClick: _cache[1] || (_cache[1] = $event => ($setup.props.params.removeTableData($setup.props.params.data.name)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt("Sure")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible"]))
}