import type { Row, RowDataProxy } from '../types/Row';

function getElMap(row: Row) {
	let { elMap } = row;
	if (elMap) { return elMap }
		elMap = new Map();
		row.elMap = elMap
	return elMap;
}
function getProxy(
	row: Row,
	key: any,
) {
	const elMap = getElMap(row);
	const proxy = elMap.get(key);
	if (proxy) {
		proxy.index = row.index;
		return proxy;
	}
	const elProxy: RowDataProxy = row.createProxy();
	elMap.set(key, elProxy);
	return elProxy;
}
export default function getRow(
	list: Row[],
	map: Map<string | number, Row>,
	visible: number[],
	rStart: number,
	rEnd: number,
	key: any,
) {
	const needShowRow = new Set<RowDataProxy>();
	for (let i = rStart; i <= rEnd; i++) {
		let index = visible[i];
		if (typeof index !== 'number') { continue; }
		const row = list[index];
		if (!row) { continue; }
		const elProxy = getProxy(row, key);
		if (needShowRow.has(elProxy)) { continue; }
		needShowRow.add(elProxy);
		// 需要显示浮动的行首和行尾部
		const { parentId } = row;
		if (parentId === undefined) { continue; }
		let parent = map.get(parentId);
		while (parent) {
			const elMap = getElMap(parent);
			let proxy = elMap.get(key);
			if (!proxy) {
				proxy = parent.createProxy();
				elMap.set(key, proxy);
			} else if (needShowRow.has(proxy)) {
				break;
			}
			needShowRow.add(proxy);
			const { parentId } = parent;
			if (parentId === undefined) { break; }
			parent = map.get(parentId);
		}
	}
	return [...needShowRow].sort(({ index: a }, { index: b }) => a - b);

}
