import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["AgGridVue"], {
    class: "ag-theme-guigu assess-table",
    rowData: $setup.assessList,
    defaultColDef: $setup.defaultColDef,
    columnDefs: $setup.columnDefs,
    animateRows: "true",
    onGridReady: $setup.onGridReady,
    onCellValueChanged: $setup.cellValueChanged,
    onSelectionChanged: $setup.onSelectionChanged,
    suppressDragLeaveHidesColumns: $setup.smallMeta,
    domLayout: "autoHeight",
    suppressRowTransform: "",
    localeText: $setup.zhCN,
    getRowId: $setup.getRowId,
    rowSelection: "multiple",
    isRowSelectable: $setup.isRowSelectable
  }, null, 8 /* PROPS */, ["rowData", "columnDefs", "suppressDragLeaveHidesColumns", "localeText"]))
}