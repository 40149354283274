import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c200b18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-container" }
const _hoisted_2 = { class: "delete-icon" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_ElIcon = _resolveComponent("ElIcon")
  const _component_ElTooltip = _resolveComponent("ElTooltip")
  const _component_ElPopconfirm = _resolveComponent("ElPopconfirm")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.props.params.clickDiary)
      ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          type: "primary",
          link: "",
          onClick: $setup.clickDiary
        }, {
          default: _withCtx(() => [
            _createElementVNode("a", null, _toDisplayString($setup.props.params?.data?.diary ? $setup.tt("Log viewing"):$setup.tt("Log filling")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.props.params.onRemove)
      ? (_openBlock(), _createBlock(_component_ElPopconfirm, {
          key: 1,
          title: $setup.tt('Are you sure you want to delete it?'),
          onConfirm: $setup.remove,
          "confirm-button-text": $setup.tt('Confirm'),
          "cancel-button-text": $setup.tt('Cancelled')
        }, {
          reference: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ElTooltip, {
                placement: "top",
                content: $setup.tt('Delete')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElIcon, null, {
                    default: _withCtx(() => [
                      _createVNode($setup["Delete"], { color: "#ff4d4f" })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["content"])
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["title", "confirm-button-text", "cancel-button-text"]))
      : _createCommentVNode("v-if", true)
  ]))
}