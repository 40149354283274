<template>
	<div class="show" ref="root">
		<div
			:class="colGroups.length ? rowGroups.length ? 'root' : 'col' : 'row'"
			ref="el">
			<Headers :groups="colGroups" :width="colWidths[1]" @setting="edit"
				@add="add"
				:widths="colWidths[0]" :start="rowGroups.length" :data="data" />
			<Headers :groups="rowGroups" :width="rowWidths[1]"
				:cc="!!colGroups.length" @setting="edit" @add="add" :data="data"
				:widths="rowWidths[0]" :start="colGroups.length" />

			<div class="inner" v-for="p in params">
				<button @click="create(p)" class="add">+</button>
				<ul ref="list" :data-value="jsonStringify(p)" class="list">
					<li class="item" v-for="it in getList(p)"
						:data-id="it.name" :key="it.name">
						<slot :value="it">{{ it.name }}</slot>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup name="KanbanBoard">
import {computed, onMounted, onUnmounted, ref, watch} from 'vue';

import dragula from 'dragula';

import type {Column, Group} from '../types';

import Headers from './Headers.vue';
import {getWidths, toList} from './utils';


const props = defineProps<{
	data: any[];
	groups: Group[];
}>();
const emit = defineEmits<{
	(event: 'update', blockId: string, newState: any): void;
	(event: 'setting', field: string, value: Column): void
	(event: 'add', field: string, index: number): void
	(event: 'create', data: Record<string, any>): void
}>();
function create(p: Record<string, any>) {
	emit('create', p);
}
const colGroups = computed(() => props.groups.filter(v => !v.column));
const rowGroups = computed(() => props.groups.filter(v => v.column));
const rowWidths = computed(() => getWidths(rowGroups.value));
const colWidths = computed(() => getWidths(colGroups.value));
const rowParams = computed(() => toList(rowGroups.value));
const colParams = computed(() => toList(colGroups.value));

const params = computed(() => {
	const col = rowParams.value;
	const row = colParams.value;
	return col.flatMap(c => row.map(r => ({...c, ...r})));
});

function toValue(v: any) {
	return ['string', 'number'].includes(typeof v) ? v : '';
}
function getList(p: Record<string, any>) {
	const pp = Object.entries(p);
	return props.data.filter(d => !pp.find(([k, v]) => toValue(d[k]) !== v));
}


function edit(field: string, value: Column) {
	emit('setting', field, value);
}
function add(field: string, index: number) {
	emit('add', field, index);
}
function jsonStringify(v: any) {
	return JSON.stringify(v);
}
const root = ref<HTMLElement>();
const el = ref<HTMLElement>();
const list = ref<HTMLElement[]>([]);
let d: any;
onMounted(() => {
	if (d) {
		d.destroy();
	}
	d = dragula(list.value, {mirrorContainer: el.value!})
		.on('drop', (block: HTMLElement, list: HTMLElement) => {
			const value = JSON.parse((list as HTMLElement).dataset.value || '{}');
			const id = (block as HTMLElement).dataset.id || '';
			emit('update', id, value);
		});
});
const height = ref('100%');
const width = ref('100%');
function update() {
	const r = root.value;
	if (!r) {
		return;
	}
	const s = r.getBoundingClientRect();
	height.value = `${s.height}px`;
	width.value = `${s.width}px`;
}

const m = new ResizeObserver(update);
watch(root, (r, old) => {
	if (old) {
		m.unobserve(old);
	}
	if (r) {
		m.observe(r);
	}
}, {immediate: true});

onUnmounted(() => {
	if (d) {
		d.destroy();
	}
	m.disconnect();
	d = undefined;
});
</script>

<style lang="less" scoped>
@ease-out: all .3s cubic-bezier(0.23, 1, 0.32, 1);

.show {
	width: 100%;
	height: 100%;
	overflow: auto;
}

.root,
.row,
.col {
	height: max-content;
	width: max-content;
	overflow: visible;
	display: grid;
	--row-group-width: 240px;
	--col-group-width: 42px;

	* {
		box-sizing: border-box;
	}

}

.row {
	min-width: 100%;
	writing-mode: vertical-lr;
	grid-template-columns: repeat(v-bind('rowParams.length'), auto);
	grid-template-rows: repeat(v-bind('rowGroups.length'), var(--row-group-width)) auto;
	// TODO
}

.col {
	min-height: 100%;
	grid-template-columns: repeat(v-bind('colParams.length'), 240px);
	grid-template-rows: repeat(v-bind('colGroups.length'), var(--col-group-width)) auto;

}

.root {
	grid-template-columns:
		repeat(v-bind('rowGroups.length'), var(--row-group-width)) repeat(v-bind('colParams.length'), 240px);
	grid-template-rows:
		repeat(v-bind('colGroups.length'), var(--col-group-width)) repeat(v-bind('rowParams.length'), auto);

	&::before {
		content: '泳道';
		grid-row: span v-bind('colGroups.length');
		grid-column: span v-bind('rowGroups.length');
		z-index: 2;
		position: sticky;
		inset-block-start: 0;
		inset-inline-start: 0;
		border: 1px solid #EEEEEE;
		overflow: hidden;
		font-size: 14px;
		padding: 10px;
		line-height: calc(var(--col-group-width) * v-bind('colGroups.length') - 22px);
		background-color: var(--card-bg);
	}
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;

	h2>a {
		float: right;
	}
}

.add {
	flex-shrink: 0;
}

.inner {
	display: flex;
	min-block-size: 50px;
	border: 1px solid #EEEEEE;
	flex-direction: column;
	overflow: hidden;
	max-block-size: calc(v-bind(height) - v-bind('colGroups.length') * var(--col-group-width));

	.row & {
		max-block-size: calc(v-bind(width) - v-bind('colGroups.length') * var(--col-group-width));

	}

	> :first-child {
		border-style: dashed;
		margin: 4px;
		background-color: transparent;
		overflow: hidden;
		border-color: currentColor;
		border-radius: var(--border-radius-md);
		color: #AAAAAA;
	}
}

.list {
	flex: 1;
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	overflow: auto;

}

.item {
	padding: 10px;
	margin: 4px;
	transition: @ease-out;
	border-radius: var(--border-radius-md);
	box-shadow: var(--card-shadow);
	background-color: var(--kanban-card-bg);
	cursor: grab;
	padding: var(--padding-sm);

	&:global(.is-moving) {
		transform: scale(1.5);
		background: rgba(black, 0.8);
	}

	>* {
		writing-mode: horizontal-tb;
	}

	.row & {
		width: 240px;
	}
}
</style>

<style lang="less">
/* Dragula CSS  */

.gu-mirror {
	position: fixed !important;
	margin: 0 !important;
	z-index: 9999 !important;
	opacity: 0.8;
	list-style-type: none;
}

.gu-hide {
	display: none !important;
}

.gu-unselectable {
	-webkit-user-select: none !important;
	-moz-user-select: none !important;
	-ms-user-select: none !important;
	user-select: none !important;
}

.gu-transit {
	opacity: 0.2;
}
</style>
