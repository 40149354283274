<template>
	<ElPopover placement="bottom" v-model="visible" @show="show" @hide="hide"
		:showArrow="false" trigger="click" width="min(100%, 300px)">
		<template #reference>
			<div class="btn item-btn btn-default btn-sm list-sidebar-button"
				aria-haspopup="true" aria-expanded="false">
				<span class="ellipsis">{{ title }}</span>
				<span>
					<svg class="icon icon-xs">
						<use href="#icon-select" />
					</svg>
				</span>
			</div>
		</template>
		<ElInput v-model="input" :placeholder="tt('Search')" />
		<template v-for="{ label, name, count } of list" :key="name">
			<div v-if="filter(label)" @click="value = name" class="item">
				<span class="ellipsis">
					<ElIcon v-if="name === value"><Select /></ElIcon>
					{{ label }}
				</span>
				{{ count }}
			</div>
		</template>
	</ElPopover>
</template>

<script lang="ts" setup>
import {computed, ref} from 'vue';
import {ElPopover, ElIcon, ElInput} from 'element-plus';
import {Select} from '@element-plus/icons-vue';

import {Filter} from '../types';

import loadCount from './loadCount';
import loadTags from './loadTags';

const props = defineProps<{
	doctype: string;
	title: string;
	field: string;
	type: string
	options?: any;
	filters: Filter[];
	allFilters: any;
	isLike?: boolean
	isStats?: boolean;
}>();
const input = ref('');
const emit = defineEmits<{
	(event: 'update:filters', filters: Filter[]): void;
}>();
const tt = __;
const visible = ref(false);
const selected = computed(() => {
	const {doctype, field, isLike} = props;
	const condition = isLike ? 'like' : '=';
	const filter = props.filters.find(v =>
		v.doctype === doctype
		&& v.field === field
		&& (v.condition === condition || v.condition === 'is' && v.value === 'not set'));
	if (filter?.condition === 'is') {
		return null;
	}
	const value = filter?.value;
	if (isLike && typeof value === 'string') {
		return value.replace(/^%|%$/g, '');
	}
	return value;
});
const value = computed({
	get() {
		return selected.value;
	}, set(value) {
		visible.value = false;
		const {doctype, field, isLike} = props;
		const filters = props.filters.filter(v => v.doctype !== doctype || v.field !== field);

		if (value === selected.value) {

		} else if (value === null) {
			filters.push({doctype, field, condition: 'is', value: 'not set'});
		} else if (value !== undefined) {
			filters.push({
				doctype, field,
				condition: isLike ? 'like' : '=',
				value: isLike ? `%${value}%` : value,
			});
		}
		emit('update:filters', filters);
	},
});
function filter(label: string) {
	return label.includes(input.value);
}

const list = ref<{
	label: string;
	name?: string | number | null | undefined;
	count: number;
}[]>([]);
const loadingCount = ref(0);
async function load() {
	try {
		loadingCount.value++;
		const fn = props.isStats ? loadTags : loadCount;
		const data = await fn(
			props.doctype,
			props.field,
			props.allFilters,
			props.type,
			props.options,
		);
		if (loadingCount.value !== 1) {
			return;
		}
		if (!visible.value) {
			return;
		}
		list.value = data;
	} finally {
		loadingCount.value--;
	}
}
function show() {
	visible.value = true;
	load();
}
function hide() {
	visible.value = false;
}
</script>
<style scoped lang="less">
.item-btn {
	display: flex;
	margin-top: var(--margin-md);
	justify-content: space-between;
	border: none;
	border-radius: var(--border-radius);
	box-shadow: var(--btn-shadow);
	font-size: var(--text-md);
	padding: 4px 8px;
}

.item {
	display: flex;
	justify-content: space-between;
	padding: var(--padding-sm) var(--padding-sm);
	clear: both;
	font-weight: 400;
	color: var(--text-color);
	white-space: nowrap;
	background-color: transparent;
	border: 0;
	font-size: var(--text-md);
}
</style>
