<template>
	<div v-loading="loading">
		<Table
			v-if="dataList.length"
			:smallMeta="smallMeta"
			:dataList="dataList"
			:loading="loading"
		></Table>
		<ElEmpty v-else :description="tt('No Records Created')" :imageSize="100"/>
		<ElButton
			class="new_btn"
			v-show="data.editable"
			type="primary"
			size="small"
			@click="newAddTask">
			{{ tt('New Task') }}
		</ElButton>
	</div>
</template>

<script setup lang="ts">
import {onMounted, onUnmounted, ref} from 'vue';
import {ElButton, ElEmpty} from 'element-plus';

import {useMetaQuery} from '../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';
import {registerEvent} from '../eventBus';

import Table from './components/Table.vue';
import type {TaskData} from './components/types';
import {setData} from './components/helper';

const props = defineProps<{
	data: {
		name: string,
		editable: boolean,
	}
}>();

const tt = __;
const loading = ref<boolean>(false);
const dataList = ref<TaskData[]>([]);
const smallMeta = useMetaQuery();

registerEvent('organizationWorkListTaskChange', function() {
	getList();
});
/**
 * 新建任务
 */
function newAddTask() {
	frappe.new_doc('Dw Task', {organization_worklist: props.data.name});
}
/**
 * 获取任务列表数据
 */
async function getList() {
	loading.value = true;
	const res = await frappe.call<{ message: TaskData[] }>({
		method: 'dw_worklist_management.dw_worklist_management.doctype.dw_organization_worklist.dw_organization_worklist.get_task_list',
		args: {
			name: props.data.name,
		},
	});
	dataList.value = setData(res?.message || []);
	loading.value = false;
}


const popstateListener = function () {
	getList();
};
onMounted(() => {
	getList();
	window.addEventListener('popstate', popstateListener);
});
onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});
frappe.realtime.on('doc_update', doc => {
	if (doc.doctype !== 'Dw Organization Worklist') {
		return;
	}
	getList();
});
</script>
<style scoped >
.new_btn{
	margin-top: 15px;
}
</style>
