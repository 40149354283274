<template>
	<ElConfigProvider size="small">
		<div class="instruction-container" v-loading="store.loading">
			<div v-if="mode==='edit'">
				<VersionForm></VersionForm>
			</div>
			<div v-if="mode==='show'">
				<div v-if="editable" class="button">
					<ElButton link type="primary" @click="navigate">{{ tt('Edit') }}</ElButton>
				</div>
				<h2 class="title">{{ store.currentVersion.title }}</h2>
			</div>
			<div class="editor-container">
				<Editor
					:editable="editable&&mode==='edit'"
					:value="store.content"
					@change="editorChange"
					height="100%"
				></Editor>
			</div>
		</div>
	</ElConfigProvider>
</template>

<script setup lang='ts'>
import {ElConfigProvider, vLoading, ElButton} from 'element-plus';
import {onMounted, onUnmounted, watch} from 'vue';

import VersionForm from './VersionForm.vue';
import {useInstructionStore} from './store';

const Editor = frappe.tianjy_editor.editor;
const tt = __;
interface Props {
	instructionName: string;
	editable?:boolean
	mode:'edit'|'show'
	referenceType:string,
	referenceName:string,
}

const props = defineProps<Props>();
const store = useInstructionStore();

watch(()=>props.editable, ()=>{
	store.setEditable(props.editable);
}, {immediate: true});

function editorChange(value:string) {
	store.setVersion({content: value});
}

function resetInstruction() {
	store.loading = true;
	if (!props.instructionName) {
		store.loading = false;
		store.setDefault({
			instruction: {
				reference_type: props.referenceType || '', reference_name: props.referenceName || '',
			},
		});
		return;
	}
	store.initInstruction(props.instructionName);
}
watch([
	()=>props.instructionName,
	()=>props.referenceType,
	()=>props.referenceName,
], ()=>{
	resetInstruction();
}, {immediate: true});

function navigate() {
	const searchUrl = new URLSearchParams();
	searchUrl.set('instruction_name', props.instructionName);
	searchUrl.set('reference_type', props.referenceType);
	frappe.router.push_state(`/app/dw_instruction?${encodeURIComponent(searchUrl.toString())}`);
}

function popstateListener() {
	resetInstruction();
}
onMounted(() => {
	window.addEventListener('popstate', popstateListener);
});

onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});
</script>

<style lang='less' scoped>
.button{
	text-align: end
}
.title{
	text-align:left;
	margin-bottom: 16px;
}
.instruction-container{
	height: 100%;
	display: flex;
	flex-direction: column;
	.editor-container{
		flex:1;
		overflow: hidden;
	}
}
</style>
