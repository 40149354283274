<template>
	<AgGridVue
		@gridReady="onReady"
		:rowHeight="rowHeight"
		:getRowId="getRowId"
		:getDataPath="getDataPath"
		:localeText="zhCN"
		:rowData="data"
		treeData
		domLayout="autoHeight"
		:columnDefs="columnDefs"
		:suppressDragLeaveHidesColumns="smallMeta"
		:autoGroupColumnDef="autoGroupColumnDef" />
</template>
<script lang="ts" setup>
import { computed, shallowRef, watch } from 'vue';

import { AgGridVue } from 'ag-grid-vue3';
import type {
	GridApi, GetRowIdParams, GridReadyEvent, ColumnApi,
} from 'ag-grid-community';
import { useWindowSize } from '@vueuse/core';

import { useMetaQuery } from '../../hooks/useMetaQuery';
import zhCN from '../../agGrid/zhCN';

import type { ViewInfo } from './types';
import { getTreeExpander, useExpander } from './Expander';
import getColumns from './getColumns';
import useAggregation from './useAggregation';
import Title from './Title/index.vue';


const props = defineProps<{
	meta: locals.DocType;

	data: locals.Doctype[];
	loading?: boolean;
	rowHeight: number;


	detail?: boolean;

	configuration: ViewInfo;
}>();

const smallMeta = useMetaQuery();
const configuration = computed(() => props.configuration);
const gridApi = shallowRef<GridApi>();

const gridColumnApi = shallowRef<ColumnApi>();
const { width, height } = useWindowSize();
const isMobile = computed(() => width.value < 640);
watch([isMobile, gridColumnApi], ([isMobile, columnApi]) => {
	if (!isMobile || !columnApi) { return; }
	const cols = columnApi.getAllGridColumns() || [];
	columnApi.setColumnsPinned(cols.map(v => v.getColId()), null);
}, { immediate: true });

const data = computed<locals.Doctype<string>[]>(() => props.data.map(v => ({
	...v,
})));
const map = computed(() => new Map(data.value.map(v => [v.name, v])));


function updateExpanded(expanded: any[]) {
	const api = gridApi.value;
	if (!api) { return; }
	api.collapseAll();
	const ids = new Set(expanded);
	for (const id of ids) {
		const node = api.getRowNode(id);
		if (!node) { continue; }
		api.setRowNodeExpanded(node, true);
	}
}

function getRowId({ data }: GetRowIdParams<locals.Doctype>) {
	return data.name;
}

function getDataPath(data: locals.Doctype<string>): string[] {
	const parentFiled = props.meta.nsm_parent_field;
	const done = new Set();
	const stack: any[] = [];
	let path: string[] = [];
	const all = map.value;
	for (let item: any = data; item;) {
		const { __path__ } = item;
		if (Array.isArray(__path__)) {
			for (const p of [...__path__].reverse()) {
				path.push(p as any);
			}
			break;
		}
		if (done.has(item)) { break; }
		done.add(item);
		stack.push(item);
		path.push(item.name);
		const parentId = item[parentFiled] as any;
		if (!parentId) { break; }
		item = all.get(parentId);
	}
	path = path.reverse();
	const paths = [...path];
	for (const s of stack) {
		s.__path__ = [...paths];
		paths.pop();
	}
	return path;

}
const titleDocField = computed(() => {
	const fieldName = props.meta.title_field;
	const { fields } = props.meta;
	return fieldName && fields.find(v => v.fieldname === fieldName) || null;
});

async function onReady({ api, columnApi }: GridReadyEvent) {
	gridApi.value = api;
	gridColumnApi.value = columnApi;
}


function preventClick(e: Event, data: any) {
	if (props.detail) {
		e.preventDefault();
	}
}
const state = {
	get meta() { return props.meta; },
	get titleField() { return titleDocField.value; },
	get linkField() { return configuration.value.linkField; },
};


const autoGroupColumn = computed(() => {
	const { width, maxWidth, minWidth } = configuration.value.titleField || {};
	const titleField = titleDocField.value;
	return {
		colId: `id`,
		resizable: true,
		enableRowGroup: false,
		pinned: 'left',

		headerName: titleField ? __(titleField.label) : 'ID',
		width: width || 200, maxWidth, minWidth,
		field: titleField?.fieldname || 'name',
		cellRendererParams: {
			innerRenderer: Title,
			preventClick,
			getState: () => state,
		},
	};
});

const columns = computed(() => getColumns(
	props.meta,
	() => props.data,
	configuration.value.fields,
	configuration.value.fixedFieldColumns || 0,
	true,
));

const { setExpandable } = useExpander();

const aggregation = useAggregation(
	configuration,
	titleDocField,
	computed(() => props.meta),
	computed(() => props.data),
);
watch([aggregation, gridApi], ([aggregation, api]) => {
	if (!api) { return; }
	api.setPinnedBottomRowData(aggregation ? [aggregation] : []);
}, { immediate: true });

const expanders = computed(() => {
	if (props.loading) { return []; }
	return getTreeExpander(props.data, props.meta.nsm_parent_field);
});
watch(expanders, list => { setExpandable(list, updateExpanded); }, { immediate: true });

const { rowHeight } = props;
const columnDefs = columns.value;
const autoGroupColumnDef = autoGroupColumn.value;


</script>
