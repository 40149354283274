
import { computed, ComputedRef, ref } from 'vue';

export default function useSort(meta: ComputedRef<locals.DocType>) {
	/** 排序 */
	const order = ref<{ doctype: string; field: string; desc: boolean; }[]>([]);
	const defaultSort = computed(() => {
		const { sort_field, sort_order, name } = meta.value;
		return {
			doctype: name,
			field: sort_field || 'modified',
			desc: sort_order?.toUpperCase() === 'DESC',
		};
	});
	const sort = computed({
		get: () => {
			const sort = order.value;
			return sort.length ? sort : [defaultSort.value];
		},
		set: v => { order.value = v; },
	});
	return { order, sort };

}
