<template>
	<ElForm
		labelWidth="auto"
		:model="formValue"
		:rules="rules"
		inline
		class="instruction-form"
	>
		<ElFormItem :label="tt('Subject')" prop="title">
			<ElInput v-model="formValue.title" />
		</ElFormItem>
		<ElFormItem
			:label="tt('Version')"
			prop="version"
		>
			<ElCol :span="18">
				<ElInput :disabled="Boolean(store.currentVersion.name)" v-model="formValue.version" />
			</ElCol>
			<ElCol :span="1"></ElCol>
			<ElCol :span="5" v-if="store.editable&&store.currentVersion.name">
				<ElTooltip :content="tt('Update Version')">
					<ElButton @click="visible=true" :icon="Edit"></ElButton>
				</ElTooltip>
			</ElCol>
		</ElFormItem>
		<ElFormItem :label="tt('instruction Compiler')" prop="title">
			<ElInput v-model="formValue.compiler" />
		</ElFormItem>
		<ElFormItem :label="tt('instruction Checker')" prop="title">
			<ElInput v-model="formValue.checker" />
		</ElFormItem>
	</ElForm>
	<VersionNoDialog
		v-model:visible="visible"
		v-model:versionNo="formValue.version"
	></VersionNoDialog>

</template>

<script setup lang='ts'>
import {ElCol, ElForm, ElFormItem, ElInput, type FormRules, ElButton, ElTooltip} from 'element-plus';
import {reactive, ref, watch} from 'vue';
import {Edit} from '@element-plus/icons-vue';

import VersionNoDialog from './VersionNoDialog.vue';
import {useInstructionStore} from './store';
const tt = __;
interface FormValue {
	version:string,
	title:string
}
const visible = ref<boolean>(false);

const store = useInstructionStore();

const formValue = reactive({
	version: store.currentVersion?.instruction_version || '',
	title: store.currentVersion?.title || '',
	compiler: store.currentVersion?.compiler,
	checker: store.currentVersion?.checker,
});
watch(formValue, ()=>{
	if (
		formValue.title !== store.currentVersion.title || formValue.version !== store.currentVersion.instruction_version
		|| formValue.compiler !== store.currentVersion.compiler || formValue.checker !== store.currentVersion.checker
	) {
		store.setVersion({
			title: formValue.title,
			instruction_version: formValue.version,
			compiler: formValue.compiler,
			checker: formValue.checker,
		});
	}
});
watch([()=>store.currentVersion?.instruction_version, ()=>store.currentVersion?.title], ()=>{
	formValue.version = store.currentVersion?.instruction_version || '';
	formValue.title = store.currentVersion?.title || '';
	formValue.checker = store.currentVersion?.checker;
	formValue.compiler = store.currentVersion?.compiler;
}, {immediate: true});

const rules = reactive<FormRules<FormValue>>({
	version: [
		{required: true, message: __('Please input version'), trigger: 'blur'},
	],
	title: [
		{required: true, message: __('Please input title'), trigger: 'blur'},
	],
});
</script>

<style lang='less' scoped>
.instruction-form{
	display: flex;
	flex-wrap: wrap;
	:deep(label){
		margin-bottom: 0;
	}
	:deep(.el-form-item){
		flex:1;
		min-width: 150px;
	}
}
</style>
