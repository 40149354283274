import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "icon icon-sm" }
const _hoisted_3 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "filter-list btn btn-xs",
    title: $props.title,
    onClick: $setup.click
  }, [
    (_openBlock(), _createElementBlock("svg", _hoisted_2, [
      _createElementVNode("use", {
        href: '#icon-' + $props.icon
      }, null, 8 /* PROPS */, _hoisted_3)
    ]))
  ], 8 /* PROPS */, _hoisted_1))
}