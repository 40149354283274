import type { Key, IdKey } from "./types";

export function createIdKey<T extends object = object>(
	idKey?: IdKey<T>
): (v: any) => string | number {
	if (typeof idKey === 'function') {
		return idKey;
	}
	if (typeof idKey === 'string' && idKey) {
		return v => v[idKey];
	}
	return v => v.id;
}

export default function createKey<R, T extends object = object>(
	key?: Key<R, T>
): (v: any) => R | undefined {
	if (typeof key === 'function') {
		return key;
	}
	if (typeof key === 'string' && key) {
		return v => v[key];
	}
	return v => undefined;
}
function toDate(date: string | Date | undefined) {
	if (!date) { return undefined; }
	if (!(date instanceof Date)) {
		const d = new Date(Date.parse(date));
		date = new Date(
			d.getUTCFullYear(),
			d.getUTCMonth(),
			d.getUTCDate(),
			d.getUTCHours(),
			d.getUTCMinutes(),
			d.getUTCSeconds(),
			d.getUTCMilliseconds()
		);
	}
	return Number(date) ? date : undefined;
}

export function createDateKey<T extends object = object>(
	key?: Key<Date | string | undefined, T>
): (v: any) => Date | undefined {
	if (typeof key === 'function') {
		return v => toDate(key(v));
	}
	if (typeof key === 'string' && key) {
		return v => toDate(v[key]);
	}
	return v => undefined;
}
