import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElSelect"], {
    modelValue: $setup.modelValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.modelValue) = $event)),
    multiple: "",
    filterable: "",
    defaultFirstOption: "",
    clearable: "",
    style: {"width":"100%"}
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.options, (field) => {
        return (_openBlock(), _createBlock($setup["ElOption"], {
          key: field.value,
          value: field.value,
          label: field.label
        }, null, 8 /* PROPS */, ["value", "label"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}