<template>
	<el-dialog append-to-body v-model="visible" :title="title">
		<div>如何处理文件夹下的文件？</div>
		<el-form :model="formModel" label-width="120px">
			<el-form-item label="选择" prop="action" props="action">
				<el-radio-group v-model='formModel.action'>
					<el-radio label="root">作为根元素</el-radio>
					<el-radio label="newParent">选择父文件夹</el-radio>
					<el-radio label="delete">直接删除</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item required v-show="formModel.action === 'newParent'"
				label="父级">
				<el-tree-select
					v-show="treeDataSource.length > 0"
					default-expand-all
					:props="defaultProps"
					v-model="formModel.parent"
					placeholder="请选择父级"
					check-strictly
					filterable
					:filter-node-method="filterNodeMethod"
					:data="treeDataSource">
				</el-tree-select>
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="visible = false">取消</el-button>
				<el-button type="primary" @click="confirm">
					确定
				</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup lang='ts'>
import { ref, watch, reactive, watchEffect, computed } from 'vue';

import { formatData, list2Tree } from './helper';
import { FileDocData } from './type';

interface Props {
	visible: boolean
	isFolderField: string
	folderNameField: string
	parentField: string
	meta: locals.DocType;
	folder?: Record<string, any>
}
const props = defineProps<Props>();
interface Emit {
	(event: 'deleteFolder', data: { action: string, parent?: string }): void;
}
const emit = defineEmits<Emit>();

const visible = ref(props.visible);
const folderList = ref<any[]>([]);

const title = computed(() => `删除文件夹${props.folder?.[props.folderNameField]}`);
const formModel = reactive({
	action: 'newParent',
	parent: '',
});
const defaultProps = {
	children: 'children',
	label: 'label',
	value: 'id',
};
watch(() => props.visible, () => { visible.value = props.visible; }, { immediate: true });
watch([() => props.meta.name, () => props.isFolderField], async () => {
	if (!props.isFolderField) {
		return;
	}
	const data = await frappe.call<{ message: FileDocData[] }>({
		method: 'guigu.fileview.fileview.get_folders',
		args: { doctype: props.meta.name, is_folder_field: props.isFolderField },
	});
	folderList.value = data?.message || [];
}, { immediate: true });

const treeDataSource = computed(() => {
	const withoutSelf = folderList.value.filter(item => item.name !== props.folder?.name);
	const formatList = formatData(withoutSelf, [], {
		folderNameField: props.folderNameField,
	});
	const treeData = list2Tree(formatList, props.parentField);
	return treeData;
});
function confirm() {
	const { action, parent } = formModel;
	if (action === 'newParent' && !parent) {
		return;
	}

	emit('deleteFolder', { action, parent });
}
function filterNodeMethod(value: string, data: any) {
	return data.label.includes(value);
}
</script>


<style lang='less' scoped>
</style>
