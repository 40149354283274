import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0da5038e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("a", {
    href: $setup.href,
    title: $setup.title
  }, _toDisplayString($setup.title), 9 /* TEXT, PROPS */, _hoisted_1))
}