<template>
	<Page>
		<template #title>
			<h3 class="title">{{ tt('Dw Work List Task View') }}</h3>
		</template>
		<template #tools>
			<Tools
				:smallMeta="smallMeta"
				:currentOrganization="currentOrganization"
				:selectedWorkList="selectedWorkList"
				:permission="permission"
				:departmentTree="departmentTree"
				v-model:layoutType="layoutType"
				v-model:department="department"
				v-model:applicable="applicable"
				@showProcessModalOrNavigate="showProcessModalOrNavigate"
			></Tools>
		</template>
		<template #sider>
			<TreeSider
				:treeList="treeList"
				:loading="treeLoading"
				:permission="permission"
				:allWorklist="allWorklist"
				@updateData="updateData"
				@setSideSelected="setSideSelected"
			></TreeSider>
		</template>
		<template #detail>
			<div class="table_container">
				<FilterCondition
					:smallMeta="smallMeta"
					:currentOrganization="currentOrganization"
					v-model:taskState="taskState"
					v-model:taskPriority="taskPriority"
					v-model:taskAssignee="taskAssignee"
				></FilterCondition>
				<Skeleton
					v-loading="taskLoading"
					:layout="layoutType"
					doctype="Dw Task"
					:detailName="detailName"
					@onChange="onChange"
					v-model:form-visible="formVisible"
				>
					<Table
						:smallMeta="smallMeta"
						:taskList="taskList"
						:loading="taskLoading"
						:currentOrganization="currentOrganization"
						@showProcessModalOrNavigate="showProcessModalOrNavigate"
					></Table>
				</Skeleton>
			</div>
			<div class="pagination">
				<ElConfigProvider :locale="locale">
					<ElPagination
						v-model:current-page="currentPage"
						v-model:page-size="pageSize"
						background
						small
						layout="total,sizes,prev, pager, next"
						:total="total"
						:pageSizes="[25,50,100,200]"
						@current-change="getTaskList" />
				</ElConfigProvider>
			</div>
		</template>
	</Page>
</template>

<script setup lang="ts">
import {computed, onMounted, onUnmounted, ref, watch} from 'vue';
import {ElPagination, ElConfigProvider, vLoading} from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn';

import Page from '../../../../../../../guigu/guigu/public/js/components/page/index.vue';
import {useMetaQuery} from '../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';
import Skeleton from '../../../component/Skeleton/index.vue';
import {list2Tree} from '../../../utils/index';

import Table from './components/Table.vue';
import Tools from './components/Tools.vue';
import FilterCondition from './components/FilterCondition.vue';
import TreeSider from './components/TreeSider.vue';
import type {TaskData, TreeData, WorkListData, Department} from './components/type';
import {mergeData, hasPermission} from './components/helper';

const smallMeta = useMetaQuery();
const layoutType = ref<'modal'|'link'|'bottom'|'right'>('right');
const taskState = ref<string>('');
const taskPriority = ref<string>('');
const taskAssignee = ref<string>('');
const formVisible = ref<boolean>(false);
const detailName = ref<string>('');
const treeLoading = ref<boolean>(false);
const taskLoading = ref<boolean>(false);
const permission = ref<boolean>(false);
const applicable = ref<'1' | '0'>('1');
const department = ref<string>('');
const currentPage = ref<number>(1);
const pageSize = ref<number>(25);
const total = ref<number>();
const currentOrganization = ref<string>(frappe.tianjy.organization.getCurrent());
const selectedWorkList = ref<string[]>([]);
const taskList = ref<TaskData[]>([]);
const treeList = ref<TreeData[]>([]);
const allWorklist = ref<WorkListData>({process_list: [], worklist_list: []});
const departmentTree = ref<Department[]>([]);
const tt = __;

const locale = computed(() => frappe.boot.user.language === 'zh' ? zhCn : undefined);
// 任务弹窗显示
function showProcessModalOrNavigate(name: string) {
	if (layoutType.value === 'link') {
		frappe.set_route('Form', 'Dw Task', name);
	}
	detailName.value = name;
	formVisible.value = true;
}
// 更新数据
function updateData(type:'create'|'update'|'delete', data?:TaskData) {
	switch (type) {
		case 'create':
		case 'update': return getTaskList();
		case 'delete':
			taskList.value = taskList.value.filter(item => item.name !== data?.name);
			break;
	}
}
// 任务编辑后返回的数据
function onChange(returnData: {type:'create'|'update'|'delete', data:any}) {
	const {type, data} = returnData;
	updateData(type, data);
}
// 获取选中工作清单的name
function setSideSelected(names: string[]) {
	if (!names.length) {
		taskList.value = [];
		return;
	}
	selectedWorkList.value = names;
	getTaskList();
}
// 获取任务列表
async function getTaskList() {
	taskLoading.value = true;
	const filters:[string, string, string | string[]][] = [];
	const conditions = [
		{key: 'priority', value: taskPriority.value, operator: '='},
		{key: 'assignee', value: taskAssignee.value, operator: '='},
		{key: 'status', value: taskState.value, operator: '='},
		{key: 'organization_worklist', value: selectedWorkList.value, operator: 'in'},
	];
	for (const {key, value, operator} of conditions) {
		if (value) {
			if (key === 'organization_worklist' && !value.length) {
				continue;
			}
			filters.push([key, operator, value || [value]]);
		}
	}
	const res = await frappe.call < { message: { task_list:TaskData[], task_count:number} } >({
		method: 'dw_worklist_management.dw_task_management.page.dw_work_list_task_view.dw_work_list_task_view.get_task',
		args: {
			filters,
			page: currentPage.value,
			limit: pageSize.value,
			organization: currentOrganization.value,
			applicable: applicable.value,
			department: department.value,
		},
	});
	// 提取评论内容
	taskList.value = res?.message.task_list.map(item => {
		if (item.comment) {
			const tempDiv = document.createElement('div');
			tempDiv.innerHTML = item.comment.content;
			item.last_comment = tempDiv.textContent || '';
			tempDiv.remove();
		}
		return item;
	}) || [];
	total.value = res?.message.task_count;
	taskLoading.value = false;
}
// 获取组织单元工作清单列表
async function getOrganizationWorkList() {
	treeLoading.value = true;
	const treeRes = await frappe.call<{ message: WorkListData}>({
		method: 'dw_worklist_management.dw_worklist_management.doctype.dw_organization_worklist.dw_organization_worklist.get_organization_worklist',
		args: {
			organization: currentOrganization.value,
			applicable: applicable.value,
			department: department.value,
		},
	});
	allWorklist.value = treeRes?.message as WorkListData;
	const {process_list, worklist_list} = treeRes!.message;
	if (worklist_list.length) {
		treeList.value = mergeData(process_list, worklist_list);
	} else {
		treeList.value = [];
	}
	treeLoading.value = false;
}
// 获取组织下的部门
async function getDepartment() {
	const departmentRes:Department[] = await frappe.db.get_list('Dw Department', {
		filters: {
			organization: currentOrganization.value,
		},
		fields: ['name', 'department_name', 'organization', 'parent_dw_department'],
		limit: 0,
	});
	departmentTree.value = list2Tree(departmentRes, 'parent_dw_department', 'name');
	// 设置默认部门
	const departments = departmentRes.map(item => item.name);
	const res = await frappe.db.get_list('Dw Department Member', {
		filters: [
			['dw_department_user', '=', frappe.session.user],
			['department', 'in', departments],
		],
		fields: ['department'],
		limit: 0,
	});
	if (!department.value) {
		department.value = res[0]?.department || '';
	}
}
function getSearchParams() {
	const params = new URLSearchParams(window.location.search);
	if (params.get('state')) {
		taskState.value = params.get('state') || '';
	}
	if (params.get('priority')) {
		taskPriority.value = params.get('priority') || '';
	}
	if (params.get('assignee')) {
		taskAssignee.value = params.get('assignee') || '';
	}
	if (params.get('organization')) {
		currentOrganization.value = params.get('organization') || frappe.tianjy.organization.getCurrent();
	}
	if (params.get('layout')) {
		layoutType.value = params.get('layout') as ('modal'|'link'|'bottom'|'right') || 'right';
	}
	if (params.get('applicable')) {
		applicable.value = params.get('applicable') as ('1'|'0') || '1';
	}
	if (params.get('department')) {
		department.value = params.get('department') || '';
	}
}
getSearchParams();
$(frappe.pages.dw_work_list_task_view).on('show', function() {
	getSearchParams();
});
// 更新URL路径
function updateURL() {
	const queryObj = new URLSearchParams({
		state: taskState.value,
		priority: taskPriority.value,
		assignee: taskAssignee.value,
		organization: currentOrganization.value,
		layout: layoutType.value,
		applicable: applicable.value,
		department: department.value,
	});
	history.replaceState(null, '', `?${queryObj.toString()}`);
}
watch(
	[taskState, taskPriority, taskAssignee, pageSize, currentOrganization],
	() => {
		updateURL();
		getTaskList();
	},
	{immediate: true},
);
watch([applicable, department], () => {
	updateURL();
	selectedWorkList.value = [];
	getOrganizationWorkList();
	getTaskList();
});
watch(layoutType, () => {
	updateURL();
});
function popstateListener() {
	getDepartment();
	getOrganizationWorkList();
	getTaskList();
}
onMounted(async () => {
	permission.value = await hasPermission();
	await getDepartment();
	getOrganizationWorkList();
	getTaskList();
	window.addEventListener('popstate', popstateListener);
});
onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});
</script>

<style lang="less" scoped>
  .title {
    height: 75px;
    line-height: 75px;
  }
  .detail{
	height: 100%;
	width: 100%;
	overflow: hidden;

	.table_container {
		height: 93%;
		width: 100%;
		overflow: hidden;
	}
	.pagination{
		height: 7%;
		width: 100%;
		display: flex;
		justify-content: center
	}
  }

</style>
