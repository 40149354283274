import { createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-adfa3e5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "root" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        ref: "a1",
        onScroll: $setup.scroll1,
        class: "main",
        style: _normalizeStyle({ inlineSize: $setup.inlineSize })
      }, [
        _createVNode($setup["Header"], {
          group: $setup.group,
          class: "header"
        }, null, 8 /* PROPS */, ["group"]),
        _createVNode($setup["Body"], {
          group: $setup.group,
          rowHeight: $props.rowHeight
        }, null, 8 /* PROPS */, ["group", "rowHeight"])
      ], 36 /* STYLE, HYDRATE_EVENTS */),
      ($setup.currentUnit)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            onPointerdown: $setup.begin,
            onPointermove: $setup.move,
            onPointercancel: $setup.end,
            onPointerup: $setup.end,
            class: "resizer"
          }, null, 32 /* HYDRATE_EVENTS */))
        : _createCommentVNode("v-if", true),
      ($setup.currentUnit)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            ref: "a2",
            onScroll: $setup.scroll2,
            class: "gantt"
          }, [
            _createVNode($setup["Header"], {
              group: $setup.ganttGroup,
              class: "header"
            }, null, 8 /* PROPS */, ["group"]),
            _createVNode($setup["Body"], {
              group: $setup.ganttGroup,
              rowHeight: $props.rowHeight
            }, null, 8 /* PROPS */, ["group", "rowHeight"])
          ], 544 /* HYDRATE_EVENTS, NEED_PATCH */))
        : _createCommentVNode("v-if", true)
    ]),
    _createVNode($setup["CellRenderer"]),
    _createVNode($setup["GanttCellRenderer"])
  ], 64 /* STABLE_FRAGMENT */))
}