import {createApp} from 'vue';

import BoqItemShow from './index.vue';

export default function loadBoqItemShowPage(wrapper:HTMLElement) {
	const page = frappe.ui.make_app_page({
		parent: wrapper,
		title: __('Boq Item Show'),
		single_column: true,
	});

	const app = createApp(BoqItemShow);
	app.mount(page.parent);
}
