import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-544f2304"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-container" }
const _hoisted_2 = {
  key: 0,
  class: "img-container"
}
const _hoisted_3 = { class: "img-item" }
const _hoisted_4 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.imageData.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.imageData, (img) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("img", {
                class: "img",
                src: encodeURI(img || '').replace(/#/g, '%23')
              }, null, 8 /* PROPS */, _hoisted_4)
            ]))
          }), 256 /* UNKEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["AgGridVue"], {
      class: "ag-theme-guigu",
      columnDefs: $setup.columnDefs,
      rowData: $setup.rowData,
      suppressDragLeaveHidesColumns: $setup.smallMeta,
      localeText: $setup.zhCN,
      domLayout: "autoHeight"
    }, null, 8 /* PROPS */, ["columnDefs", "rowData", "suppressDragLeaveHidesColumns", "localeText"])
  ]))
}