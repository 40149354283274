<template>
	<div>
		<ElTag
			v-if="status"
			:type="statusType"
		>
		{{DIARY_STATUS[status]}}
		</ElTag>
	</div>
</template>
<script lang="ts" setup>
import { defineProps, toRefs, computed} from 'vue';
import {
	ICellRendererParams,
  } from 'ag-grid-community';
  const tt = __
  const DIARY_STATUS = {
	'Normal Construction': tt("normal construction"),
	'Half Processing': tt("Half Processing"),
	'Prevent': tt("Prevent construction"),
	'Stop Processing': tt("Stop Processing"),
  };
const props = defineProps<{params:ICellRendererParams}>();
const status = computed(()=>props?.params.data?.diary?.status as keyof typeof DIARY_STATUS);
const statusType = computed(()=>{
	switch (props?.params.data?.diary?.status) {
          case 'Normal Construction':
            return 'success';
          case 'Half Processing':
            return '';
          case 'Prevent':
            return 'warning';
          case 'Stop Processing':
            return 'danger';
          default:
            return '';
        }
});

</script>
