<template>
	<div class="line">
		<ElSelect v-model="selectedField" filterable :suffixIcon="null"
			:teleported="false" class="field">
			<ElOption
				v-for="df in fields"
				:key="`${df.doctype}.${df.field}`"
				:label="df.doctype === meta.name
					? tt(df.label || df.field)
					: `${tt(df.label || df.field)} (${tt(df.doctype)})`
				"
				:value="`${df.doctype}.${df.field}`">
				<template v-if="df.doctype === meta.name">
					{{ tt((df.label || df.field)) }}
				</template>
				<template v-else>
					{{ tt((df.label || df.field)) }}
					<small>{{ tt((df.doctype)) }}</small>
				</template>
			</ElOption>
		</ElSelect>
		<Condition v-model="cond" :type="type" :options="options"
			class="condition" :withSession="withSession" />
		<Input :condition="cond" :type="type || ''" :options="options"
			:name="field" listable required
			v-model="val" :meta="docMeta" class="input" />
		<ElButton @click="remove" :icon="Delete" title="移除" text />
	</div>
</template>
<script lang="ts" setup>
import {computed} from 'vue';
import {ElSelect, ElOption, ElButton} from 'element-plus';
import {Delete} from '@element-plus/icons-vue';

import {Field} from './build_options';
import Condition from './Condition.vue';
import Input from './Input/index.vue';

const props = defineProps<{
	fields: Field[];
	doctype: string;
	field: string;
	withSession?: boolean;
	condition?: string;
	value?: any;
	meta: locals.DocType;
}>();
const docMeta = computed(() => {
	const {meta, doctype} = props;
	return meta.name === doctype ? meta : frappe.get_meta(doctype) || meta;
});
const emit = defineEmits<{
	(event: 'remove'): void;
	(event: 'update:doctype', doctype?: string): void;
	(event: 'update:field', field?: string): void;
	(event: 'update:condition', condition: string): void;
	(event: 'update:value', value: any): void;
}>();

const tt = __;

const cond = computed({
	get: () => props.condition || '=',
	set: cond => emit('update:condition', cond),
});

const val = computed({
	get: () => props.value,
	set: val => emit('update:value', val),
});
const selectedField = computed({
	get() {
		const {doctype, field} = props;
		if (!field) {
			return;
		}
		if (!doctype) {
			return field;
		}
		return `${doctype}.${field}`;
	},
	set(v) {
		if (!v) {
			emit('update:doctype', undefined);
			emit('update:field', undefined);
			return;
		}
		const index = v.indexOf('.');
		if (index < 0) {
			emit('update:doctype', '');
			emit('update:field', v);
		} else {
			emit('update:doctype', v.substring(0, index));
			emit('update:field', v.substring(index + 1));
		}
	},
});
const docField = computed(() => {
	const {doctype, field, fields} = props;
	return fields.find(v => v.doctype === doctype && v.field === field);
});
const type = computed(() => docField.value?.type);
const options = computed(() => docField.value?.options);

function remove() {
	emit('remove');
}


</script>
<style scoped>
.line {
	display: flex;
	gap: 8px;
	margin-block-end: 8px;
}

.field {
	flex: 1
}

.condition {
	width: 100px;
}

.input {
	flex: 2
}
</style>
