<template>
	<div class="tool-container">
		<div class="tool">
			<CompanyCom
				:loading="loading"
				default
				v-model:company="companyValue"
				:paramsFilter="paramsFilter"
				@setRoute="setRoute"
			/>
		</div>
		<div class="tool">
			<ProjectCom
				:loading="loading"
				default
				noEmpty
				:company="companyValue"
				v-model="projectValue"
				:paramsFilter="paramsFilter"
				:filters="projectFilter"
				@setRoute="setRoute"
			></ProjectCom>
		</div>
		<div class="right-container">
			<div class="right-container_content">
				<ElSelect
					size="small"
					class="modalSelectPadding"
					:modelValue="detailType"
					@change="detailTypeChange"
				>
					<ElOption
						value="modal"
						key="modal"
						:label="tt('Details Dialog')"
					/>
					<ElOption
						value="link"
						key="link"
						:label="tt('Jump to page')"
					/>
					<ElOption
						value="bottom"
						key="bottom"
						:label="tt('vertical layout')"
					/>
					<ElOption
						value="right"
						key="right"
						:label="tt('Left and right layout')"
					/>
				</ElSelect>
				<ElButtonGroup class="elButtonGroup">
					<ElButton size="small" @click="() => expandAll(true)">{{
						tt('Expand')
					}}</ElButton>
					<ElButton size="small" @click="() => expandAll(false)">{{
						tt('Collapse')
					}}</ElButton>
					<ElButton size="small" @click="() => refresh()">{{
						tt('Refresh')
					}}</ElButton>
					<ElDropdown @command="handleImport">
						<ElButton size="small">{{ tt('Import') }}</ElButton>
						<template #dropdown>
							<ElDropdownMenu>
								<ElDropdownItem @click="importBoq">{{
									tt('Import Boq Item')
								}}</ElDropdownItem>
								<ElDropdownItem @click="importConsumption">{{
									tt('Import Consumption')
								}}</ElDropdownItem>
							</ElDropdownMenu>
						</template>
					</ElDropdown>
				</ElButtonGroup>
				<ElButton
					:loading="loading"
					type="primary"
					size="small"
					@click="createBoq"
					>{{ tt('Create') }}</ElButton
				>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { ref, onMounted, computed, onUnmounted } from 'vue';
import { createApp } from 'vue';

import type { GridApi } from 'ag-grid-community';

import {
	ElButton,
	ElSelect,
	ElOption,
	ElButtonGroup,
	ElDropdown,
	ElDropdownMenu,
	ElDropdownItem,
} from 'element-plus';

import {
	exportTemplateFile,
	exportConsumptionTemplateFile,
} from '../exportFile';

import ProjectCom from './Project.vue';
import CompanyCom from './Company.vue';
import Excel from './HandsonTable.vue';

const tt = __;
interface Props {
	project: string;
	loading: boolean;
	detailType?: string;
	gridApi: GridApi | undefined;
	refreshValue?: number;
}
const props = defineProps<Props>();
interface Emit {
	(e: 'update:project', project: string): void;
	(e: 'update:detailType', detailType: string): void;
	(e: 'update:refreshValue', refreshValue: number): void;
	(e: 'updateRecordValue', value: locals.DocType): void;
}
const emit = defineEmits<Emit>();
const paramsFilter = ref<{ [key: string]: string }>({});
const size = ref<string>('small');
const companyValue = ref<string>('');
const projectValue = computed({
	get: () => props.project || '',
	set: (value: string) => {
		emit('update:project', value);
	},
});
const projectFilter = computed(() => [['company', '=', companyValue.value]]);

/**
 * @description 展开收起函数
 * @params expand 展开为true 收起为false
 */
function expandAll(expand: boolean) {
	if (expand) {
		props.gridApi?.expandAll();
	} else {
		props.gridApi?.collapseAll();
	}
}

/**
 * @description 更新布局之间的宽度
 * @returns 无返回值
 */
function updateComponentSize() {
	const widthElement = window.innerWidth;
	if (widthElement <= 640) {
		size.value = 'default';
	} else {
		size.value = 'small';
	}
}

/**
 * @description 初始化url和toolbar中的参数
 */
function routeInit() {
	const keyArr = ['company', 'project'];
	const queryObj = new URLSearchParams(window.location.search);
	// 创建一个对象来存储参数
	const paramsObj: { [key: string]: string } = {};
	// 遍历每个参数，并将它们存储在 params 对象中
	for (const item of keyArr) {
		if (queryObj.get(item)) {
			paramsObj[item] = queryObj.get(item) || '';
		}
	}
	paramsFilter.value = paramsObj;
}

/**
 * @description 更新url中的值
 * @param filter 对象数组
 */
function setRoute(filter: { [key: string]: string }) {
	const newParamsFilter = { ...paramsFilter.value, ...filter };
	const queryObj = new URLSearchParams(newParamsFilter);
	paramsFilter.value = newParamsFilter;
	history.replaceState(null, '', `?${queryObj.toString()}`);
}

function refresh() {
	const newValue: number = (props?.refreshValue || 0) + 1;
	emit('update:refreshValue', newValue);
}

function visibleSubmitButton(
	importDialog: frappe.ui.Dialog<frappe.ui.form.Form>,
	visible: boolean,
) {
	if (visible) {
		importDialog.enable_primary_action();
	} else {
		importDialog.disable_primary_action();
	}
	importDialog.get_primary_btn().attr('disabled', !visible);
}

async function visible_handsontable(
	importDialog: frappe.ui.Dialog<frappe.ui.form.Form>,
	api_name: string,
) {
	$(importDialog.fields_dict.handsontable.wrapper).find('div.html').remove();
	const html_wrapper = $(importDialog.fields_dict.handsontable.wrapper).find(
		'div.html',
	);
	const uploadValue = importDialog.get_value('data_upload');
	const result_arr = await frappe.call<{
		message: { has_err: boolean; header: []; data: [] };
	}>({
		method: `guigu_pm.guigu_pm_boq.page.guigu_boq_item_show.guigu_boq_item_show.${api_name}`,
		args: {
			file_path: uploadValue,
			project: projectValue.value,
			company: companyValue.value,
		},
	});
	if (result_arr?.message?.has_err) {
		visibleSubmitButton(importDialog, false);
		const headerList = result_arr?.message?.header;
		const dataList = result_arr?.message?.data;
		if (
			html_wrapper.length === 0 &&
			(headerList.length || dataList.length)
		) {
			$(importDialog.fields_dict.handsontable.wrapper).html(`
						<div class="html">
						</div>
					`);
			const app = createApp(Excel, {
				headerList,
				dataList,
				hotName: '',
			});
			app.mount(
				$(importDialog.fields_dict.handsontable.wrapper).find(
					'.html',
				)[0],
			);
			throw new Error('');
		}
	} else {
		visibleSubmitButton(importDialog, true);
	}
}

function importBoq() {
	const importDialog = new frappe.ui.Dialog({
		size: 'large',
		title: tt('Import Boq Item'),
		fields: [
			{
				label: tt('File Upload'),
				fieldname: 'data_upload',
				fieldtype: 'Attach',
				onchange: async function () {
					frappe.dom.freeze(tt('Validating'));
					try {
						await visible_handsontable(importDialog, 'read_file');
						frappe.dom.unfreeze();
					} catch (err) {
						frappe.dom.unfreeze();
					}
				},
			},
			{
				label: tt('Table'),
				fieldname: 'handsontable',
				fieldtype: 'HTML',
			},
		],
		primary_action_label: tt('Submit'),
		secondary_action_label: tt('Download Template'),
		secondary_action: () => exportTemplateFile(),
		async primary_action(e) {
			frappe.dom.freeze(tt('Importing Time Is Long Please Wait'));
			try {
				await visible_handsontable(importDialog, 'submit_file');
				frappe.show_alert({
					message: tt('Import Successful'),
					indicator: 'green',
				});
				frappe.dom.unfreeze();
				importDialog.hide();
				refresh();
			} catch (err) {
				frappe.dom.unfreeze();
				frappe.show_alert({
					message: tt('Import Failed'),
					indicator: 'red',
				});
			}
		},
	});
	visibleSubmitButton(importDialog, false);
	importDialog.show();
}
function importConsumption() {
	const importDialog = new frappe.ui.Dialog({
		size: 'large',
		title: tt('Import Consumption'),
		fields: [
			{
				label: tt('File Upload'),
				fieldname: 'data_upload',
				fieldtype: 'Attach',
				onchange: async function () {
					frappe.dom.freeze(tt('Validating'));
					try {
						await visible_handsontable(
							importDialog,
							'read_consumption_file',
						);
						frappe.dom.unfreeze();
					} catch (err) {
						frappe.dom.unfreeze();
					}
				},
			},
			{
				label: tt('Table'),
				fieldname: 'handsontable',
				fieldtype: 'HTML',
			},
		],
		primary_action_label: tt('Submit'),
		secondary_action_label: tt('Download Template'),
		secondary_action: () => exportConsumptionTemplateFile(),
		async primary_action(e) {
			frappe.dom.freeze(tt('Importing Time Is Long Please Wait'));
			try {
				await visible_handsontable(
					importDialog,
					'submit_consumption_file',
				);
				frappe.show_alert({
					message: tt('Import Successful'),
					indicator: 'green',
				});
				frappe.dom.unfreeze();
				importDialog.hide();
				refresh();
			} catch (err) {
				frappe.dom.unfreeze();
				frappe.show_alert({
					message: tt('Import Failed'),
					indicator: 'red',
				});
			}
		},
	});
	visibleSubmitButton(importDialog, false);
	importDialog.show();
}
/**
 * @description 详情布局变化时触发的函数
 * @param type
 */
function detailTypeChange(type: string) {
	emit('update:detailType', type);
}

function createBoq() {
	frappe.new_doc('Guigu Boq Item', {
		project: projectValue.value,
		company: companyValue.value,
	});
}

onMounted(() => {
	updateComponentSize();
	routeInit();
});

window.addEventListener('resize', updateComponentSize);

onUnmounted(() => window.removeEventListener('resize', updateComponentSize));
</script>
<style scoped lang="less">
.current {
	background-color: #eee;
}

@media (min-width: 640px) {
	.tool {
		display: flex;
		margin-right: 8px;
	}
	.tool-container {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		.required {
			margin-right: 4px;
			color: #f00;
			line-height: 24px;
		}
		.right-container {
			display: flex;
			flex: 1;
			flex-direction: row-reverse;
			.right-container_content {
				display: flex;
			}
		}
	}
	.modalSelectPadding {
		padding-right: 12px;
	}
	.elButtonGroup {
		padding-right: 12px;
	}
}

@media (max-width: 640px) {
	.tool {
		width: 100%;
		:deep(.input-container) {
			width: 100%;
		}
	}
	.right-container {
		display: flex;
		flex-direction: column !important;
		.right-container_content {
			display: flex;
			flex-direction: column !important;
		}
	}
	.modalSelectPadding {
		padding: 0 0 12px 0;
	}
	.elButtonGroup {
		padding: 0 0 12px 0;
	}
}
</style>
