import { normalizeStyle as _normalizeStyle, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d4ad78a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "root ag-theme-guigu" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["AgGridVue"], {
      class: "ag",
      style: _normalizeStyle($setup.style),
      onSelectionChanged: $setup.selectionChanged,
      rowDragMultiRow: "",
      rowSelection: "multiple",
      onGridReady: $setup.onReady,
      onRowDragEnd: $setup.onRowDragEnd,
      rowHeight: $setup.rowHeight,
      getRowId: $setup.getRowId,
      getDataPath: $setup.getDataPath,
      localeText: $setup.zhCN,
      rowData: $setup.data,
      treeData: "",
      defaultExcelExportParams: $setup.excelExportParams,
      columnDefs: $setup.columnDefs,
      suppressDragLeaveHidesColumns: $setup.smallMeta,
      autoGroupColumnDef: $setup.autoGroupColumnDef
    }, null, 8 /* PROPS */, ["style", "rowHeight", "localeText", "rowData", "defaultExcelExportParams", "columnDefs", "suppressDragLeaveHidesColumns", "autoGroupColumnDef"]),
    _renderSlot(_ctx.$slots, "pagination", {}, undefined, true),
    _createVNode($setup["TargetSelector"], {
      modelValue: $setup.DShow,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.DShow) = $event)),
      x: $setup.treeDragInfo?.x,
      y: $setup.treeDragInfo?.y,
      onSubmit: $setup.onClickItem
    }, null, 8 /* PROPS */, ["modelValue", "x", "y"]),
    _createVNode($setup["DeleteSelector"], {
      modelValue: $setup.deleteTarget,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.deleteTarget) = $event)),
      onDelete: $setup.delTree,
      meta: $props.meta
    }, null, 8 /* PROPS */, ["modelValue", "meta"])
  ]))
}