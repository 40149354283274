import { createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ef6017e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElDialog"], {
    modelValue: $setup.thisVisible,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.thisVisible) = $event)),
    title: $setup.tt('Batch selection of new personnel')
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["ElButton"], {
          onClick: _cache[4] || (_cache[4] = $event => ($setup.cancel()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _withDirectives(_createVNode($setup["ElButton"], {
          type: "primary",
          onClick: $setup.pre
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Previous step')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 512 /* NEED_PATCH */), [
          [_vShow, $setup.active>1]
        ]),
        _createVNode($setup["ElButton"], {
          disabled: $setup.selectedUsers.length===0,
          type: "primary",
          onClick: $setup.next
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.active===2?$setup.tt('Confirm'):$setup.tt('Next Steps')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["ElSteps"], {
          active: $setup.active,
          "finish-status": "success",
          "align-center": ""
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElStep"], {
              title: $setup.tt('Select personnel')
            }, null, 8 /* PROPS */, ["title"]),
            _createVNode($setup["ElStep"], {
              title: $setup.tt('Edit permissions')
            }, null, 8 /* PROPS */, ["title"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["active"]),
        _withDirectives(_createVNode($setup["ElTable"], {
          data: $setup.users,
          onSelectionChange: $setup.handleSelectionChange,
          style: {"width":"100%"},
          "max-height": "700px"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElTableColumn"], {
              type: "selection",
              width: "55"
            }),
            _createVNode($setup["ElTableColumn"], {
              property: "name",
              label: "ID",
              width: "150"
            }),
            _createVNode($setup["ElTableColumn"], {
              property: "full_name",
              label: $setup.tt('Person Name')
            }, null, 8 /* PROPS */, ["label"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["data"]), [
          [_vShow, $setup.active===1]
        ]),
        _withDirectives(_createVNode($setup["MemberForm"], {
          isCreate: "",
          designations: $setup.designations,
          "onUpdate:designations": _cache[0] || (_cache[0] = $event => (($setup.designations) = $event)),
          permissions: $setup.permissionCheckList,
          "onUpdate:permissions": _cache[1] || (_cache[1] = $event => (($setup.permissionCheckList) = $event)),
          isDefault: $setup.isDefault,
          "onUpdate:isDefault": _cache[2] || (_cache[2] = $event => (($setup.isDefault) = $event)),
          roles: $setup.roleCheckList,
          "onUpdate:roles": _cache[3] || (_cache[3] = $event => (($setup.roleCheckList) = $event))
        }, null, 8 /* PROPS */, ["designations", "permissions", "isDefault", "roles"]), [
          [_vShow, $setup.active===2]
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}