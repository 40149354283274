export enum State {
	/** 请求错误 */
	error = -1000,
	/** 未知错误 */
	unknown = -100,
	/** 微信用户与当前用户不同 */
	different = -3,
	/** 关联到已登录帐号失败 */
	unassociated = -2,
	/** key 无效 */
	invalid = -1,
	/** 二维码尚未被扫描 */
	wait = 0,
	/** 自动登陆 */
	ok = 1,
	/** 关联到已登录帐号成功 */
	associated = 2,
	/** 微信用户与当前用户相同 */
	same = 3,
	/** 要求用户登陆 */
	login = 4,
}


export async function loginByWechatCode(name: string, code: string, custom: boolean) {
	try {
		const params: Record<string, any> = { name, code };
		if (custom) { params.custom = 1; }
		const data = await frappe.call<{
			message: State,
		}>('guigu_wechat.bind.bind_wechat', params);
		if (!data) { return State.error; }
		return data.message;
	} catch {
		return State.error;
	}
}

export async function subscribe(name: string) {
	await frappe.call<{
		message: boolean,
	}>('guigu_wechat.bind.subscribe', { name });
}
export async function unsubscribe(name: string) {
	await frappe.call<{
		message: boolean,
	}>('guigu_wechat.bind.unsubscribe', { name });
}
export async function unbind(name: string) {
	const data = await frappe.call<{
		message: boolean,
	}>('guigu_wechat.bind.unbind', { name });
	return Boolean(data?.message);
}
export interface Official {
	appid: string;
	name: string;
	agentid?: string;
	title: string;
	login_enabled: 0 | 1
	qrcode_image?: string;
	login_url?: string;
}
export async function status() {
	const data = await frappe.call<{
		message: {
			message_enabled: Record<string, 0 | 1 | undefined>;
			officials: Official[];
		},
	}>('guigu_wechat.bind.status');
	return data?.message;
}
