import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "file-uploader" }
const _hoisted_2 = { class: "file-preview-area" }
const _hoisted_3 = {
  key: 0,
  class: "file-preview-container"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      (!$setup.show_image_cropper)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.files, (file, i) => {
              return (_openBlock(), _createBlock($setup["FilePreview"], {
                file: file,
                onRemove: $event => ($setup.remove_file(file)),
                onRename: $event => (file.name = $event),
                onToggle_optimize: $event => (file.optimize = !file.optimize),
                onToggle_image_cropper: $event => ($setup.toggle_image_cropper(i))
              }, null, 8 /* PROPS */, ["file", "onRemove", "onRename", "onToggle_optimize", "onToggle_image_cropper"]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]))
        : _createCommentVNode("v-if", true)
    ], 512 /* NEED_PATCH */), [
      [_vShow, $setup.files.length]
    ]),
    _withDirectives(_createVNode($setup["UploadArea"], {
      onRename: _cache[0] || (_cache[0] = $event => (_ctx.file.name = $event)),
      has: Boolean($setup.files.length),
      filetypes: $props.filetypes,
      onAddFiles: $setup.addFiles,
      onShowBrowser: _cache[1] || (_cache[1] = $event => ($setup.show_file_browser = true))
    }, null, 8 /* PROPS */, ["has", "filetypes"]), [
      [_vShow, !$setup.show_image_cropper && !$setup.show_file_browser]
    ]),
    ($setup.show_image_cropper)
      ? (_openBlock(), _createBlock($setup["ImageCropper"], {
          key: 0,
          file: $setup.files[$setup.crop_image_with_index],
          onToggle: _cache[2] || (_cache[2] = $event => ($setup.toggle_image_cropper(-1)))
        }, null, 8 /* PROPS */, ["file"]))
      : _createCommentVNode("v-if", true),
    ($setup.show_file_browser)
      ? (_openBlock(), _createBlock($setup["FileBrowser"], {
          key: 1,
          onSelect: _cache[3] || (_cache[3] = $event => ($setup.selected_node = $event)),
          onHide: _cache[4] || (_cache[4] = $event => ($setup.show_file_browser = false))
        }))
      : _createCommentVNode("v-if", true)
  ]))
}