// /* eslint-disable no-unused-expressions */
import { DailyRecordData, SummaryTableData } from '../../type';

export function routeInit(paramsFilter: any,) {
	const [_, ...filters] = frappe.router?.current_route || [];
	const filterObj: { [key: string]: string } = {};
	const keyArr = ['project', 'pageType', 'monthDate', 'yearDate', 'countCategory'];
	for (let i = 0; i < filters.length; i++) {
		if ((i & 1) === 0 && keyArr.includes(filters[i])) {
			filterObj[filters[i]] = filters[i + 1];
		}
	}
	paramsFilter.value = filterObj;
}

export function setRoute(paramsFilter: any, filter: { [key: string]: string }) {
	const [pageName] = frappe.router?.current_route || [];
	const newRoute = [pageName];
	const newParamsFilter = { ...paramsFilter.value, ...filter };
	Object.keys(newParamsFilter || {}).forEach(item => {
		newRoute[newRoute.length] = item;
		newRoute[newRoute.length++] = newParamsFilter[item];
	});
	paramsFilter.value = newParamsFilter;
	frappe.set_route(newRoute);
}
/** 将日志记录数据转为obj {[user]: {[date]: [data]}}*/
export function daily_record_to_obj(
	dailyRecordRata: DailyRecordData[],
	type: string
): { [key: string]: { [key: string]: DailyRecordData | number | string } } {
	const obj: { [key: string]: { [key: string]: DailyRecordData | number | string } } = {};
	dailyRecordRata.forEach(item => {
		if (obj[item.record_owner]) {

			if (type === 'month') {
				if (item?.record_date) {
					obj[item.record_owner][item.record_date] = item;
					obj[item.record_owner].diary_count = Number(obj[item.record_owner].diary_count) + 1;
				}

			} else if (item.record_date || item?.guigu_pm_daily_record_attendance_type) {
				obj[item.record_owner][item?.record_date || item?.guigu_pm_daily_record_attendance_type] = item?.count || 0;
				obj[item.record_owner].diary_count = Number(obj[item.record_owner].diary_count) + item?.count || 0;
			}

		} else if (type === 'month') {
			/** 这里根据record_date 因为要是显示组织中的没写日志的人
			 * 没有日志数据的人员返回的数据中没有record_date 这个字段 */
			if (item?.record_date) {
				obj[item.record_owner] = {
					[item.record_date]: item,
					diary_count: 1,
					organization_name: item.organization_name,
					record_owner: item.record_owner,
					record_owner_name: item.record_owner_name,

				};
			} else {
				obj[item.record_owner] = {
					diary_count: 0,
					organization_name: item.organization_name,
					record_owner: item.record_owner,
					record_owner_name: item.record_owner_name,

				};
			}

		} else if (item?.record_date || item.guigu_pm_daily_record_attendance_type) {
			obj[item.record_owner] = {
				[item?.record_date || item.guigu_pm_daily_record_attendance_type]: item?.count || 0,
				diary_count: item?.count || 0,
				organization_name: item.organization_name,
				record_owner: item.record_owner,
				record_owner_name: item.record_owner_name,
			};
		} else {
			obj[item.record_owner] = {
				diary_count: item?.count || 0,
				organization_name: item.organization_name,
				record_owner: item.record_owner,
				record_owner_name: item.record_owner_name,

			};
		}
	});
	return obj;
}

export function daily_record_data_processing(
	dailyRecordData: DailyRecordData[],
	type: 'month' | 'year',
): SummaryTableData[] {
	const dailyRecordObj = daily_record_to_obj(dailyRecordData, type);
	if (type === 'month') {

		return [...Object.values(dailyRecordObj)];
	}

	return [...Object.values(dailyRecordObj)];

}
