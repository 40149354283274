import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AgGridVue = _resolveComponent("AgGridVue")
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createBlock(_component_AgGridVue, {
    class: "ag-theme-guigu boq_item-table",
    columnDefs: _ctx.columnDefs,
    rowData: _ctx.boqItemList,
    treeData: "true",
    localeText: _ctx.zhCN,
    getDataPath: _ctx.getDataPath,
    getRowId: _ctx.getRowId,
    autoGroupColumnDef: _ctx.autoGroupColumnDef,
    onGridReady: _ctx.onGridReady
  }, null, 8 /* PROPS */, ["columnDefs", "rowData", "localeText", "getDataPath", "getRowId", "autoGroupColumnDef", "onGridReady"])), [
    [_directive_loading, _ctx.loading]
  ])
}