import { createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["ElForm"], {
      class: "form",
      ref: "formRef",
      model: $setup.form,
      onSubmit: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"])),
      rules: $setup.rules
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElFormItem"], {
          label: $setup.tt('Title'),
          prop: "title"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElInput"], {
              modelValue: $setup.form.title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.form.title) = $event)),
              onChange: $setup.changeTitle
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElFormItem"], {
          label: $setup.tt('Calculation method'),
          prop: "method"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElSelect"], {
              placeholder: $setup.tt('Please Select'),
              modelValue: $setup.form.method,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.form.method) = $event)),
              onChange: $setup.changeMethod
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.METHODS, (f) => {
                  return _createVNode($setup["ElOption"], {
                    value: f,
                    label: $setup.tt(f)
                  }, null, 8 /* PROPS */, ["value", "label"])
                }), 64 /* STABLE_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["placeholder", "modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElFormItem"], {
          label: $setup.tt('X-axis'),
          prop: "xAxis"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElSelect"], {
              placeholder: $setup.tt('Please Select'),
              modelValue: $setup.form.xAxis,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.form.xAxis) = $event)),
              onChange: $setup.changeX,
              filterable: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fields, (f) => {
                  return (_openBlock(), _createBlock($setup["ElOption"], {
                    value: f.fieldname,
                    label: $setup.tt(f.label)
                  }, null, 8 /* PROPS */, ["value", "label"]))
                }), 256 /* UNKEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["placeholder", "modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        ($setup.isShowY)
          ? (_openBlock(), _createBlock($setup["ElFormItem"], {
              key: 0,
              label: $setup.tt('Y-axis'),
              prop: "yAxis"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["ElSelect"], {
                  placeholder: $setup.tt('Please Select'),
                  modelValue: $setup.form.yAxis,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.form.yAxis) = $event)),
                  multiple: "",
                  onChange: $setup.changeY,
                  filterable: ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.numberFields, (f) => {
                      return (_openBlock(), _createBlock($setup["ElOption"], {
                        value: f.fieldname,
                        label: $setup.tt(f.label)
                      }, null, 8 /* PROPS */, ["value", "label"]))
                    }), 256 /* UNKEYED_FRAGMENT */))
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["placeholder", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]))
          : _createCommentVNode("v-if", true),
        _createVNode($setup["ElFormItem"], {
          label: $setup.tt('X-axis name'),
          prop: "xLabel"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElInput"], {
              modelValue: $setup.form.xLabel,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.form.xLabel) = $event)),
              onChange: $setup.changeXLabel
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElFormItem"], {
          label: $setup.tt('Y-axis name'),
          prop: "yLabel"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElInput"], {
              modelValue: $setup.form.yLabel,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.form.yLabel) = $event)),
              onChange: $setup.changeYLabel
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElFormItem"], {
          label: $setup.tt('Height'),
          prop: "height"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElInputNumber"], {
              modelValue: $setup.form.height,
              onChange: $setup.changeHeight
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["model", "rules"])
  ]))
}