import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24570d53"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.data.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "handsontable-container",
          ref: $setup.set
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode($setup["ElEmpty"], {
          description: $setup.tt('Current Project No Material Records'),
          imageSize: 200
        }, null, 8 /* PROPS */, ["description"])
      ]))
}