import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.quickFilters, (f) => {
    return (_openBlock(), _createBlock($setup["QuickFilter"], {
      key: f.fieldname,
      meta: $props.meta,
      quickFilter: f,
      filters: $setup.filters,
      "onUpdate:filters": _cache[0] || (_cache[0] = $event => (($setup.filters) = $event))
    }, null, 8 /* PROPS */, ["meta", "quickFilter", "filters"]))
  }), 128 /* KEYED_FRAGMENT */))
}