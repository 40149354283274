<template>
	<span v-if="indicator" :class="className"
		@click="setFilter" :title="title">
		{{ tt(indicator[0]) }}
		<span />
	</span>
</template>
<script lang="ts" setup>
import { computed } from 'vue';


const props = defineProps<{
	value: any;
	data: Record<string, any>;
	field: GlobalView.FieldRenderOptions;
	meta?: locals.DocType;
	maxLine?: number;
	enableLink?: boolean;
	noData?: boolean;
	inline?: boolean;
	format?: string;
	showTitle?: boolean;
}>();
const emit = defineEmits<{
	(event: 'filter', field: string, operator: string, value: any): void;
}>();
const data = computed(() => props.data);
const doctype = computed(() => props.meta?.name);
const tt = __;
const indicator = computed(() => {
	if (props.noData) { return; }
	const dt = doctype.value;
	if (!dt) { return; }
	const doc = data.value;
	const indicator = frappe.get_indicator(doc as any, dt);
	return indicator;
});
const description = computed(() => {
	const description: string[] = [
		__('Document is in draft state'),
		__('Document has been submitted'),
		__('Document has been cancelled'),
	];
	return description[data.value.docstatus || 0];
});
const title = computed(() => {
	if (!props.showTitle) { return; }
	return description.value;
});
function setFilter() {
	const filter = indicator.value?.[2];
	if (!filter) { return; }
	emit('filter', ...filter.split(',') as [string, string, any]);

}
const className = computed(() => [
	`indicator-pill`,
	indicator.value?.[1],
]);

</script>
