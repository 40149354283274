import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9acf6f16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "flow-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h4", _hoisted_1, _toDisplayString($setup.tt('Instruction Flow')), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_2, [
      ($setup.flowData?.name)
        ? (_openBlock(), _createBlock($setup["Flow"], {
            key: 0,
            ref: "flowRef",
            data: $setup.flowContent,
            options: $setup.options
          }, null, 8 /* PROPS */, ["data", "options"]))
        : _createCommentVNode("v-if", true)
    ])
  ])), [
    [$setup["vLoading"], $setup.loading]
  ])
}