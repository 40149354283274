<template>
	<span :style="color"> {{ params?.value?.boq_status_name }}</span>
</template>
<script lang="ts" setup>
import {computed, defineProps} from 'vue';
import type {
	ICellRendererParams,
} from 'ag-grid-community';

const props = defineProps<{ params: ICellRendererParams}>();
const {value} = props.params;
const color = computed(() => `color: ${value?.boq_status_color}`);

</script>

<style scoped lang="less">

</style>
