import {Photo, TimelineSource} from './type/index';

export function getTimelineData(
  photos: Photo[],
  sort: 'desc' | 'asc',
): TimelineSource[] {
  const timelineData: TimelineSource[] = [];
  const photosByDate: Record<string, Photo[]> = {};
  photos.forEach(photo => {
    const date = moment(photo.date).format('YYYY-MM-DD');
    if (!photosByDate[date]) {
      photosByDate[date] = [];
    }
    photosByDate[date].push(photo);
  });
  Object.keys(photosByDate).forEach(date => {
    timelineData.push({
      date,
      list: photosByDate[date],
    });
  });
  if (sort === 'desc') {
    timelineData.sort((pre, next) => (pre.date > next.date ? -1 : 1));
  } else {
    timelineData.sort((pre, next) => (pre.date > next.date ? 1 : -1));
  }
  return timelineData;
}
