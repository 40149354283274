<template>
	<ElButton type="text" @click="toInfo" size="small">{{ props.params.value }}</ElButton>
</template>

<script setup lang="ts">
// import {ref} from 'vue';
import {ElButton} from 'element-plus';
import type {ICellRendererParams} from 'ag-grid-community';

const props = defineProps<{
	params: ICellRendererParams & {
		removeTableData: (name: string) => void;
	}
}>();

function toInfo() {
	frappe.set_route(['Form', 'Dw Task', props.params.data.name]);
}
</script>

<style lang="less" scoped></style>
