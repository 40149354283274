import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f4ee3d03"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["Page"], null, {
      title: _withCtx(() => [
        _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.tt('Log Page')), 1 /* TEXT */)
      ]),
      tools: _withCtx(() => [
        ($setup.doctypeLoaded)
          ? (_openBlock(), _createBlock($setup["Tools"], {
              key: 0,
              organization: $setup.filterOrganization,
              "onUpdate:organization": _cache[0] || (_cache[0] = $event => (($setup.filterOrganization) = $event)),
              searchString: $setup.searchString,
              "onUpdate:searchString": _cache[1] || (_cache[1] = $event => (($setup.searchString) = $event)),
              date: $setup.selectedDate,
              "onUpdate:date": _cache[2] || (_cache[2] = $event => (($setup.selectedDate) = $event)),
              showComment: $setup.showComment,
              "onUpdate:showComment": _cache[3] || (_cache[3] = $event => (($setup.showComment) = $event)),
              onSearch: $setup.search,
              onClearSearch: $setup.clearSearch
            }, null, 8 /* PROPS */, ["organization", "searchString", "date", "showComment"]))
          : _createCommentVNode("v-if", true)
      ]),
      sider: _withCtx(() => [
        _createVNode($setup["OrganizationTree"], {
          subOrganizations: $setup.subOrganizations,
          organizationLoading: $setup.loading,
          onOrganizationChange: $setup.organizationChange
        }, null, 8 /* PROPS */, ["subOrganizations", "organizationLoading"])
      ]),
      default: _withCtx(() => [
        ($setup.doctypeLoaded)
          ? (_openBlock(), _createBlock($setup["DiaryTimeline"], {
              key: 0,
              organization: $setup.organization,
              subOrganizations: $setup.subOrganizations,
              filterOrganization: $setup.filterOrganization,
              searchString: $setup.searchString,
              date: $setup.selectedDate,
              showComment: $setup.showComment,
              onShowDailyRecord: _cache[4] || (_cache[4] = v => ($setup.detailName = v)),
              ref: "diaryTimelineRef"
            }, null, 8 /* PROPS */, ["organization", "subOrganizations", "filterOrganization", "searchString", "date", "showComment"]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    ($setup.detailName)
      ? (_openBlock(), _createBlock($setup["Detail"], {
          key: 0,
          onHide: _cache[5] || (_cache[5] = $event => ($setup.detailName = '')),
          doctype: "Dw Daily Record",
          name: $setup.detailName
        }, null, 8 /* PROPS */, ["name"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}