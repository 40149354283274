import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a8fe3ba5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tool-container" }
const _hoisted_2 = { class: "tool" }
const _hoisted_3 = { class: "tool" }
const _hoisted_4 = { class: "right-container" }
const _hoisted_5 = { class: "right-container_content" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["CompanyCom"], {
        loading: $props.loading,
        default: "",
        company: $setup.companyValue,
        "onUpdate:company": _cache[0] || (_cache[0] = $event => (($setup.companyValue) = $event)),
        paramsFilter: $setup.paramsFilter,
        onSetRoute: $setup.setRoute
      }, null, 8 /* PROPS */, ["loading", "company", "paramsFilter"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode($setup["ProjectCom"], {
        loading: $props.loading,
        default: "",
        noEmpty: "",
        company: $setup.companyValue,
        modelValue: $setup.projectValue,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.projectValue) = $event)),
        paramsFilter: $setup.paramsFilter,
        filters: $setup.projectFilter,
        onSetRoute: $setup.setRoute
      }, null, 8 /* PROPS */, ["loading", "company", "modelValue", "paramsFilter", "filters"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode($setup["ElSelect"], {
          size: "small",
          class: "modalSelectPadding",
          modelValue: $props.detailType,
          onChange: $setup.detailTypeChange
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElOption"], {
              value: "modal",
              key: "modal",
              label: $setup.tt('Details Dialog')
            }, null, 8 /* PROPS */, ["label"]),
            _createVNode($setup["ElOption"], {
              value: "link",
              key: "link",
              label: $setup.tt('Jump to page')
            }, null, 8 /* PROPS */, ["label"]),
            _createVNode($setup["ElOption"], {
              value: "bottom",
              key: "bottom",
              label: $setup.tt('vertical layout')
            }, null, 8 /* PROPS */, ["label"]),
            _createVNode($setup["ElOption"], {
              value: "right",
              key: "right",
              label: $setup.tt('Left and right layout')
            }, null, 8 /* PROPS */, ["label"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"]),
        _createVNode($setup["ElButtonGroup"], { class: "elButtonGroup" }, {
          default: _withCtx(() => [
            _createVNode($setup["ElButton"], {
              size: "small",
              onClick: _cache[2] || (_cache[2] = () => $setup.expandAll(true))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.tt('Expand')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode($setup["ElButton"], {
              size: "small",
              onClick: _cache[3] || (_cache[3] = () => $setup.expandAll(false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.tt('Collapse')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode($setup["ElButton"], {
              size: "small",
              onClick: _cache[4] || (_cache[4] = () => $setup.refresh())
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.tt('Refresh')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode($setup["ElDropdown"], { onCommand: _ctx.handleImport }, {
              dropdown: _withCtx(() => [
                _createVNode($setup["ElDropdownMenu"], null, {
                  default: _withCtx(() => [
                    _createVNode($setup["ElDropdownItem"], { onClick: $setup.importBoq }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.tt('Import Boq Item')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode($setup["ElDropdownItem"], { onClick: $setup.importConsumption }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.tt('Import Consumption')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              default: _withCtx(() => [
                _createVNode($setup["ElButton"], { size: "small" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($setup.tt('Import')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onCommand"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElButton"], {
          loading: $props.loading,
          type: "primary",
          size: "small",
          onClick: $setup.createBoq
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Create')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["loading"])
      ])
    ])
  ]))
}