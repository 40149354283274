
export const extendFields = new Map<string, [string, string, Partial<locals.DocField>, string][]>([
	['Date', [
		['year', '年', {options: 'Year'}, 'dateExtField'],
		['month', '月', {options: 'Month'}, 'dateExtField'],
		['week', '周', {options: 'Week'}, 'dateExtField'],
	]],
]);
const noGroupFieldType = [
	'Table MultiSelect',
];
const defaultFieldFilter = (field: locals.DocField) =>{
	if (field.fieldtype === 'Table MultiSelect' || field.fieldtype === 'Tianjy Tree Table MultiSelect') {
		return true;
	}
	return !frappe.model.no_value_type.includes(field.fieldtype);
};

function filterStdField({fieldname}: {fieldname: string}) {
	if (fieldname === 'name') {
		return false;
	}
	if (fieldname === 'idx') {
		return false;
	}
	if (fieldname === 'docstatus') {
		return false;
	}
	if (fieldname[0] === '_') {
		return false;
	}
	return true;
}
const linkType = new Set(['Link', 'Tree Select', 'Tianjy Related Link', 'Attach', 'Attach Image']);

function getDefaultFieldDisplayed(
	meta: locals.DocType,
	fields: locals.DocField[],
): GlobalView.DisplayField[] {
	const list: GlobalView.DisplayField[] = [];
	for (const df of fields) {
		if (!df.in_list_view) {
			continue;
		}
		if (df.is_virtual) {
			continue;
		}
		const {fieldtype} = df;
		if (frappe.model.is_value_type(fieldtype)) {
			list.push({groupable: true, fieldOptions: df, field: df});
		} else if (fieldtype === 'Currency' && df.options && !df.options.includes(':')) {
			list.push({groupable: true, fieldOptions: df, field: df});
		}
	}

	if (frappe.has_indicator(meta.name)) {
		const df = {
			label: 'Status',
			fieldname: '__Status__',
			fieldtype: '__Status__',
			parent: meta.name,
		};
		list.unshift({groupable: true, fieldOptions: df, field: df});
	}
	if (meta.title_field && meta.title_field !== 'name') {
		const df = {
			label: 'ID',
			fieldname: 'name',
			fieldtype: '__ID__',
			parent: meta.name,
		};
		list.push({groupable: false, fieldOptions: df, field: df});
	}
	return list;
}

function filterDisplayField(
	doctype: string,
	fields: locals.DocField[],
	list: GlobalView.Field[],
	setting: GlobalView.DisplayFieldConfiguration,
) {
	const allFields = new Map<string, GlobalView.FieldRenderOptions>();
	allFields.set('name', {
		label: 'ID',
		fieldname: 'name',
		fieldtype: '__ID__',
		parent: doctype,
	});
	for (const df of [...fields, ...frappe.model.std_fields.filter(filterStdField)]) {
		const {fieldname, fieldtype, label} = df;
		allFields.set(fieldname, {parent: doctype, ...df});
		for (const [v, l, o] of extendFields.get(fieldtype) || []) {
			allFields.set(`${fieldname}:${v}`, {
				parent: doctype,
				...df,
				label: `${__(label)}：${__(l)}`,
				...o,
			});
		}
	}


	if (frappe.has_indicator(doctype)) {
		allFields.set('__Status__', {
			label: 'Status',
			fieldname: '__Status__',
			fieldtype: '__Status__',
			parent: doctype,
		});
	}

	if (setting.commentField) {
		allFields.set('__LastComment__', {
			label: 'Last Comment',
			fieldname: '_comments',
			fieldtype: '__LastComment__',
			parent: doctype,
		});
	}

	if (setting.tagField) {
		allFields.set('Tag', {
			label: 'Tag',
			fieldname: 'Tag',
			fieldtype: '__Tag__',
			parent: doctype,
		});
	}

	const displayField: GlobalView.DisplayField[] = [];
	for (const v of list) {
		const fieldOptions = allFields.get(v.field);
		if (!fieldOptions) {
			continue;
		}
		const groupable = !noGroupFieldType.includes(fieldOptions.fieldtype) && !['name', '__LastComment__'].includes(v.field);
		const field = {...fieldOptions, label: v.label || fieldOptions.label};
		fieldOptions.label = v.label || fieldOptions.label;
		displayField.push({
			...v,
			field,
			group: groupable && v.group,
			groupable,
			fieldOptions,
		});
	}

	return displayField;
}
export function getFieldDisplayed(
	list: GlobalView.Field[],
	meta: locals.DocType,
	displayField: GlobalView.DisplayFieldConfiguration,
): GlobalView.DisplayField[] {
	const fieldFilterFn = displayField.fieldFilter;
	const fieldFilter = typeof fieldFilterFn === 'function' ? fieldFilterFn : defaultFieldFilter;

	const {name: doctype} = meta;
	const allFields = (meta.fields || [])
		.filter(fieldFilter)
		.filter(d => !d.is_virtual)
		.filter(d => frappe.perm.has_perm(doctype, d.permlevel, 'read'));

	const fields = filterDisplayField(doctype, allFields, list, displayField);
	if (fields.length) {
		return fields;
	}
	if (!displayField.useDefault) {
		return [];
	}
	return getDefaultFieldDisplayed(meta, allFields);
}


export function getFieldOptions(meta: locals.DocType, options: {
	fieldFilter?: (field: locals.DocField) => any;
	titleField?: boolean;
	commentField?: boolean;
	tagField?: boolean;
	[k: string]: any
}) {
	const fieldFilterFn = options.fieldFilter;
	const fieldFilter = typeof fieldFilterFn === 'function' ? fieldFilterFn : defaultFieldFilter;

	const allFields = (meta.fields || [])
		.filter(fieldFilter)
		.filter(d => !d.is_virtual);

	const list: {
		value: string;
		label: string;
		isLink: boolean;
		groupable: boolean;
		format: null | string | boolean;
	}[] = [{
		value: 'name',
		label: `${__('ID')}`,
		isLink: false,
		groupable: false,
		format: null,
	}];
	for (const field of [...allFields, ...frappe.model.std_fields.filter(filterStdField)]) {
		const {fieldname, label, fieldtype} = field;
		list.push({
			value: fieldname,
			label: `${__(label)}`,
			isLink: linkType.has(fieldtype),
			groupable: !noGroupFieldType.includes(fieldtype),
			format: ['Date', 'Datetime'].includes(fieldtype),
		});
		for (const [v, l, , k] of extendFields.get(fieldtype) || []) {
			if (k && !options[k]) {
				continue;
			}
			list.push({
				value: `${fieldname}:${v}`,
				label: `${__(label)}：${__(l)}`,
				isLink: false,
				groupable: true,
				format: null,
			});
		}
	}


	if (frappe.has_indicator(meta.name)) {
		list.unshift({
			value: '__Status__',
			label: __('Status'),
			isLink: false,
			groupable: true,
			format: null,
		});
	}

	if (options.titleField) {
		list.unshift({
			value: '__Title__',
			label: `${__('Title')}`,
			isLink: false,
			groupable: false,
			format: null,
		});
	}


	if (options.commentField) {
		list.push({
			value: '__LastComment__',
			label: `${__('Last Comment')}`,
			isLink: false,
			groupable: false,
			format: null,
		});
	}

	if (options.tagField) {
		list.push({
			value: 'Tag',
			label: `${__('Tag')}`,
			isLink: false,
			groupable: false,
			format: null,
		});
	}

	return list;
}
