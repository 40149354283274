import type { SetupContext } from 'vue';
import type { Ref } from 'vue';
import { computed, defineComponent, h } from 'vue';

export default function useRowAction(
	rowActionComp: Ref<any>,
	meta: Ref<locals.DocType>,
	configuration: Ref<object>,
	view: Ref<GlobalView.View>,
	loading: Ref<boolean>,
	onRefresh: () => void,
	onCreate: (options?: Record<string, any>, extend?: boolean) => void,
) {

	return computed(() => {
		const comp = rowActionComp.value;
		if (!comp) { return; }
		function setup(p: any, ctx: SetupContext) {
			return () => h(comp, {
				value: p.value,
				treeParent: p.treeParent,
				meta: meta.value,
				configuration: configuration.value,
				view: view.value,
				loading: loading.value,
				onDelete: (name: string, ...p: any) => ctx.emit('delete', name, ...p),
				onRefresh,
				onCreate,
			});
		}
		return defineComponent({ props: ['value', 'treeParent'], setup, inheritAttrs: false });
	});
}
