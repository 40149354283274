import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aa1b53f9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Page"], null, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.tt('Dw Worklist Clipping Enable')), 1 /* TEXT */)
    ]),
    tools: _withCtx(() => [
      _createVNode($setup["Tools"], {
        smallMeta: $setup.smallMeta,
        levels: $setup.levels,
        processes: $setup.processes,
        showApplyButton: $setup.showApplyButton,
        hasPermission: $setup.hasPermission,
        organizationValue: $setup.organizationValue,
        "onUpdate:organizationValue": _cache[0] || (_cache[0] = $event => (($setup.organizationValue) = $event)),
        levelValue: $setup.levelValue,
        "onUpdate:levelValue": _cache[1] || (_cache[1] = $event => (($setup.levelValue) = $event)),
        layoutType: $setup.layoutType,
        "onUpdate:layoutType": _cache[2] || (_cache[2] = $event => (($setup.layoutType) = $event)),
        showOnlyApplicable: $setup.showOnlyApplicable,
        "onUpdate:showOnlyApplicable": _cache[3] || (_cache[3] = $event => (($setup.showOnlyApplicable) = $event)),
        onToggleGroupsState: $setup.toggleGroupsState,
        onOpenDetail: $setup.openDetail,
        onApplyWorklist: $setup.applyWorklist
      }, null, 8 /* PROPS */, ["smallMeta", "levels", "processes", "showApplyButton", "hasPermission", "organizationValue", "levelValue", "layoutType", "showOnlyApplicable"])
    ]),
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createBlock($setup["Skeleton"], {
        layout: $setup.layoutType,
        doctype: $setup.currentDoctype,
        detailName: $setup.detailName,
        onOnChange: $setup.onChange,
        "form-visible": $setup.formVisible,
        "onUpdate:formVisible": _cache[5] || (_cache[5] = $event => (($setup.formVisible) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode($setup["Table"], {
            ref: "aggrid",
            class: "table-container",
            smallMeta: $setup.smallMeta,
            dataList: $setup.dataList,
            loading: $setup.loading,
            hasPermission: $setup.hasPermission,
            processes: $setup.processes,
            layoutType: $setup.layoutType,
            "onUpdate:layoutType": _cache[4] || (_cache[4] = $event => (($setup.layoutType) = $event)),
            onOpenDetail: $setup.openDetail,
            onChangeApplyStatus: $setup.changeApplyStatus,
            onCancelUse: $setup.cancelUse,
            onDeleteWorklist: $setup.deleteWorklist
          }, null, 8 /* PROPS */, ["smallMeta", "dataList", "loading", "hasPermission", "processes", "layoutType"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["layout", "doctype", "detailName", "form-visible"])), [
        [$setup["vLoading"], $setup.loading]
      ])
    ]),
    _: 1 /* STABLE */
  }))
}