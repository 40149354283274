import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65dafe9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-container" }
const _hoisted_2 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$props.noLabel)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($setup.tt('Company')) + ":", 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["ElSelect"], {
      class: "input",
      modelValue: $setup.companyValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.companyValue) = $event)),
      popperClass: $setup.popperClassProject,
      size: "small",
      placeholder: $setup.tt('Please Select Company')
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.companyList, (item) => {
          return (_openBlock(), _createBlock($setup["ElOption"], {
            key: item.name,
            label: item.company_name,
            value: item.name
          }, null, 8 /* PROPS */, ["label", "value"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "popperClass", "placeholder"])
  ]))
}