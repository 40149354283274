import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["TimelineItem"], { date: $setup.date }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.photos.list, (photo) => {
        return (_openBlock(), _createBlock($setup["PhotoThumbnail"], {
          photo: photo,
          onShowPhotoInfo: $setup.showPhotoInfo,
          onDeletePhoto: $setup.deletePhoto
        }, null, 8 /* PROPS */, ["photo"]))
      }), 256 /* UNKEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["date"]))
}