import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (!$props.loading)
    ? (_openBlock(), _createBlock($setup["AgGridVue"], {
        key: 0,
        class: "ag-theme-guigu",
        domLayout: "autoHeight",
        rowData: $setup.dataList,
        rowSelection: 'multiple',
        defaultColDef: $setup.defaultColDef,
        onGridReady: $setup.onGridReady,
        suppressDragLeaveHidesColumns: $props.smallMeta,
        localeText: $setup.zhCN,
        useValueFormatterForExport: "",
        getRowId: $setup.getRowId,
        onRowSelected: $setup.handleRowSelected
      }, null, 8 /* PROPS */, ["rowData", "suppressDragLeaveHidesColumns", "localeText"]))
    : _createCommentVNode("v-if", true)
}