import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AgGridVue = _resolveComponent("AgGridVue")
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createBlock(_component_AgGridVue, {
    class: "ag-theme-guigu work-assessment-container_table",
    rowData: _ctx.summaryTableData,
    columnDefs: _ctx.columnDefs,
    onGridReady: _ctx.onGridReady,
    animateRows: "true",
    localeText: _ctx.zhCN,
    suppressDragLeaveHidesColumns: _ctx.smallMeta,
    getRowId: _ctx.getRowId
  }, null, 8 /* PROPS */, ["rowData", "columnDefs", "onGridReady", "localeText", "suppressDragLeaveHidesColumns", "getRowId"])), [
    [_directive_loading, _ctx.loading]
  ])
}