import {
	onMounted, computed, onUnmounted, shallowRef, Ref, watch, unref,
} from 'vue';

import type { ViewInfo } from '../types';

import getFields from './getFields';
import loadList from './loadList';
import pretreatDate from './pretreatDate';

function initData(list: any, meta: locals.DocType) {
	const initDatas = frappe.listview_settings[meta.name]?.initData;
	if (typeof initDatas !== 'function') { return; }
	return initDatas(list);
}


export default function useMain(
	meta: Ref<locals.DocType> | locals.DocType,
	view: Ref<ViewInfo> | ViewInfo,
	filters: Ref<[string, string, string, any][]> | [string, string, string, any][],
	order: Ref<GlobalView.Order[]> | GlobalView.Order[],
	infiniteScroll: Ref<boolean> | boolean,
	limit: Ref<number> | number,
	page: Ref<number>,
) {

	/** 主区域数据 */
	const data = shallowRef<any[]>([]);
	/** 总条数 */
	const total = shallowRef(0);
	/** 主区域数据加载中 */
	const loadingCount = shallowRef(1);
	/** 是否正在加载 */
	const loading = computed(() => Boolean(loadingCount.value));

	const limitInfinite = computed(() => {
		const l = unref(limit);
		return l > 0 ? l : Infinity;
	});

	const fields = computed(() => getFields(
		unref(meta),
		unref(view),
		unref(order),
	));
	const pageOptions = computed(() => {
		const opts: GlobalView.MainLoaderOptions = {
			order: unref(order),
			group: [],
			fields: fields.value,
		};
		const limit = limitInfinite.value;
		if (limit === Infinity) {
			opts.all = true;
		} else {
			const p = page.value;
			opts.limit = limit;
			opts.page = p;
			opts.offset = (p - 1) * limit;
		}
		return opts;
	});
	const unmounted = (() => {
		const unmounted = shallowRef(false);
		onUnmounted(() => { unmounted.value = true; });
		return unmounted;
	})();
	async function load(append?: boolean) {
		const docMeta = unref(meta);
		const viewData = unref(view);
		const { values, total: newTotal } = await loadList(docMeta, [
			...unref(filters),
		], pageOptions.value);
		pretreatDate(values, docMeta, viewData);
		await initData(values, docMeta);
		total.value = newTotal;

		data.value = append ? [...data.value.map(v => ({ ...v })), ...values] : values;
	}
	let timeout: any = undefined;
	let lastTime = 0;
	function cancel() {
		clearTimeout(timeout);
		lastTime = 0;
	}
	onUnmounted(() => { cancel(); });
	/** 加载主区域数据 */
	async function reload(append?: boolean) {
		cancel();
		if (unmounted.value) { return; }
		loadingCount.value++;
		try {
			await load(append);
		} finally {
			loadingCount.value--;
		}
	}
	function delayRequest() {
		if (unmounted.value) { return; }
		const now = Number(new Date());
		if (!lastTime) { lastTime = now + 5 * 1000; }
		clearTimeout(timeout);
		timeout = setTimeout(reload, Math.min(500, lastTime - now), false);
	}
	watch(limitInfinite, limit => {
		if (unref(infiniteScroll) || limit === Infinity) {
			page.value = 1;
		}
		delayRequest();

	});
	watch(page, delayRequest);
	function refresh() {
		// 无限滚动刷新需要回到顶部
		if (unref(infiniteScroll)) {
			page.value = 1;
		}
		reload(false);
	}
	function nextPage() {
		if (limitInfinite.value === Infinity) { return; }
		const infScroll = unref(infiniteScroll);
		// 无限滚动时，禁止在加载时调用 nextPage
		if (infScroll && loading.value) { return; }
		page.value++;
		reload(infScroll);
	}

	function reRequest() {
		if (unmounted.value) { return; }
		if (unref(infiniteScroll)) {
			page.value = 1;
		}
		delayRequest();
	}

	onMounted(() => { reload().finally(() => loadingCount.value--); });
	return { data, total, loading, refresh, nextPage, reRequest };
}
