import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f7dd24f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "detail-container" }
const _hoisted_2 = {
  key: 0,
  class: "confirm-container"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_date_picker = _resolveComponent("el-date-picker")
  const _component_el_row = _resolveComponent("el-row")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form = _resolveComponent("el-form")
  const _component_el_checkbox = _resolveComponent("el-checkbox")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      class: "interview-form",
      labelPosition: $props.smallMeta ? 'top' : 'right',
      ref: "formRef",
      model: $setup.form,
      rules: $setup.rules,
      labelWidth: "75px",
      onChange: $setup.formChange
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_row, { gutter: 10 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 8 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: $setup.tt('Conversation Leader') + ':',
                  prop: "leader"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      onChange: $setup.changeLeader,
                      filterable: "",
                      disabled: $setup.isDisabled('leader'),
                      modelValue: $setup.form.leader,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.form.leader) = $event))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.leaders, (user) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            label: user.full_name,
                            value: user.name
                          }, null, 8 /* PROPS */, ["label", "value"]))
                        }), 256 /* UNKEYED_FRAGMENT */))
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["disabled", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_col, { span: 8 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: $setup.tt('Date Of Conversation') + ':',
                  prop: "assess_date"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_date_picker, {
                      onChange: $setup.changeDate,
                      disabled: $setup.isDisabled('assess_date'),
                      modelValue: $setup.form.assess_date,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.form.assess_date) = $event)),
                      valueFormat: "YYYY-MM-DD"
                    }, null, 8 /* PROPS */, ["disabled", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ]),
              _: 1 /* STABLE */
            }),
            _createCommentVNode(" <el-col :span=\"8\">\n\t\t\t\t\t<el-form-item label=\"绩效年度:\" prop=\"date\">\n\t\t\t\t\t{{ form.date }}\n\t\t\t\t\t</el-form-item>\n\t\t\t\t\t</el-col> ")
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_el_form, {
          class: "interview-form-bottom",
          labelPosition: "top"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: 
						$setup.tt('Summarize the work during the assessment cycle') +
						':'
					,
              prop: "summary"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  disabled: $setup.isDisabled('summary'),
                  type: "textarea",
                  rows: 4,
                  modelValue: $setup.form.summary,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.form.summary) = $event))
                }, null, 8 /* PROPS */, ["disabled", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: 
						$setup.tt(
							'Positive performance and advantages within the assessment cycle',
						) + ':'
					,
              prop: "advantage"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  disabled: $setup.isDisabled('advantage'),
                  type: "textarea",
                  rows: 4,
                  modelValue: $setup.form.advantage,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.form.advantage) = $event))
                }, null, 8 /* PROPS */, ["disabled", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: 
						$setup.tt('Identify the shortcomings and weaknesses') + ':'
					,
              prop: "shortcoming"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  disabled: $setup.isDisabled('shortcoming'),
                  type: "textarea",
                  rows: 4,
                  modelValue: $setup.form.shortcoming,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.form.shortcoming) = $event))
                }, null, 8 /* PROPS */, ["disabled", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: 
						$setup.tt(
							'Specific requirements and directions for the new assessment cycle',
						) + ':'
					,
              prop: "requirement"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  disabled: $setup.isDisabled('requirement'),
                  type: "textarea",
                  rows: 4,
                  modelValue: $setup.form.requirement,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.form.requirement) = $event))
                }, null, 8 /* PROPS */, ["disabled", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: 
						$setup.tt(
							'In the future, training and learning should be strengthened',
						) + ':'
					,
              prop: "learning"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  disabled: $setup.isDisabled('learning'),
                  type: "textarea",
                  rows: 4,
                  modelValue: $setup.form.learning,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($setup.form.learning) = $event))
                }, null, 8 /* PROPS */, ["disabled", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: $setup.tt('Other Opinions And Suggestions') + ':',
              prop: "suggestion"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  disabled: $setup.isDisabled('suggestion'),
                  type: "textarea",
                  rows: 4,
                  modelValue: $setup.form.suggestion,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($setup.form.suggestion) = $event))
                }, null, 8 /* PROPS */, ["disabled", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["labelPosition", "model", "rules"]),
    ($setup.hadInterview)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives(_createElementVNode("div", null, _toDisplayString($setup.tt('Interviewee (Signature)')) + ":" + _toDisplayString($setup.props.assessInfo?.user_doc.full_name) + " " + _toDisplayString($setup.tt('Confirmed')), 513 /* TEXT, NEED_PATCH */), [
            [_vShow, $setup.userConfirm]
          ]),
          _withDirectives(_createVNode(_component_el_checkbox, {
            disabled: $setup.isDisabled('self_confirm'),
            modelValue: $setup.isUserConfirmed,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => (($setup.isUserConfirmed) = $event)),
            onChange: $setup.userToConfirm,
            label: $setup.tt('Interviewee (Signature)')
          }, null, 8 /* PROPS */, ["disabled", "modelValue", "label"]), [
            [_vShow, !$setup.userConfirm]
          ]),
          _withDirectives(_createElementVNode("div", null, _toDisplayString($setup.tt('Conversation Leader (Signature)')) + ":" + _toDisplayString($setup.leaderDoc?.full_name) + " " + _toDisplayString($setup.tt('Confirmed')), 513 /* TEXT, NEED_PATCH */), [
            [_vShow, $setup.leaderConfirm]
          ]),
          _withDirectives(_createVNode(_component_el_checkbox, {
            disabled: $setup.isDisabled('leader_confirm'),
            modelValue: $setup.isLeaderConfirmed,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => (($setup.isLeaderConfirmed) = $event)),
            onChange: $setup.leaderToConfirm,
            label: $setup.tt('Conversation Leader (Signature)')
          }, null, 8 /* PROPS */, ["disabled", "modelValue", "label"]), [
            [_vShow, !$setup.leaderConfirm]
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}