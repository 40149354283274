import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eb429150"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "multi"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.multiSel)
    ? (_openBlock(), _createBlock($setup["MultiSelect"], {
        key: 0,
        teleported: false,
        style: {width:'140px'},
        filter: "",
        modelValue: $setup.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.value) = $event)),
        name: $props.name,
        placeholder: $props.label && $setup.tt($props.label),
        options: $props.options
      }, null, 8 /* PROPS */, ["modelValue", "name", "placeholder", "options"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.value, (it, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "line"
          }, [
            _createVNode($setup["Types"], {
              class: "main",
              filter: "",
              modelValue: it,
              "onUpdate:modelValue": $event => ($setup.set(index, $event)),
              type: $props.type,
              label: $props.label,
              name: $props.name,
              required: $props.required,
              options: $props.options
            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "type", "label", "name", "required", "options"]),
            (!index)
              ? (_openBlock(), _createBlock($setup["ElButton"], {
                  key: 0,
                  onClick: $setup.add,
                  icon: $setup.Plus,
                  text: "",
                  title: "增加"
                }, null, 8 /* PROPS */, ["icon"]))
              : (_openBlock(), _createBlock($setup["ElButton"], {
                  key: 1,
                  onClick: $event => ($setup.remove(index)),
                  icon: "Minus",
                  text: "",
                  title: "移除"
                }, null, 8 /* PROPS */, ["onClick"]))
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ]))
}