import registerGlobalView from 'guiguLib/registerGlobalView';
import registerGlobalViewAction from 'guiguLib/registerGlobalViewAction';

import Gantt from './index.vue';
import Action from './Action.vue';
import DotsConfig from './DotsConfig.vue';
import LinesConfig from './LinesConfig.vue';
import OtherConfig from './OtherConfig.vue';
import EstimatedLinesConfig from './EstimatedLinesConfig.vue';

export type * from './types';
registerGlobalView('gantt', Gantt);
registerGlobalViewAction('gantt:unit', Action);

export { Action, Gantt as View };

export const widgets: GlobalView.ConfigurationWidget[] = [{
	component: LinesConfig,
	label: '进度线',
}, {
	component: EstimatedLinesConfig,
	label: '预估线',
}, {
	component: DotsConfig,
	label: '里程碑',
}, {
	component: OtherConfig,
	label: '其他',
}];
