
<template>
	<ElPopover
		placement="bottom"
		:width="270"
		trigger="click">
		<template #reference>
			<ElInput :value="inputValue">
				<template #prepend>
					<svg class="icon  icon-md" style="">
						<use class="" :href="`#icon-${inputValue}`"></use>
					</svg>
				</template>
			</ElInput>
		</template>
		<div class="icon-popover-container">
			<div>
				<ElInput v-model="searchString" :suffix-icon="Search"
					placeholder="请搜索"
					@change="changeSearch"></ElInput>
			</div>
			<div class="icon-content">
				<div v-for="item in icons" :id="item" class="icon-wrapper"
					@click="selectIcon(item)">
					<svg class="icon  icon-md" style="">
						<use class="" :href="`#icon-${item}`"></use>
					</svg>
				</div>
			</div>
		</div>
	</ElPopover>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, shallowRef, watch, triggerRef } from 'vue';

import {
	ElInput, ElButton,
	ElPopover,
} from 'element-plus';
import { Search } from '@element-plus/icons-vue';
const props = defineProps<{
	modelValue?: string;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: any): void;
}>();
const allIcons:string[] = [];
const icons = ref<string[]>([]);
const inputValue = ref<string>(props.modelValue||'');
const searchString = ref<string>('');
watch(()=>props.modelValue, ()=>{ inputValue.value = props.modelValue||''; });
onMounted(()=>{
	$('#frappe-symbols > symbol[id]').each(function () {
		if (this.id.includes('icon-')){
			allIcons.push(this.id.replace('icon-', ''));
		}
	});
	icons.value = allIcons;
});

function changeSearch(v:string){
	icons.value = allIcons.filter(i=>i.includes(v));
}

function selectIcon(item:string){
	inputValue.value = item;
	emit('update:modelValue', item);
}
</script>

<style scoped lang="less">
.icon-content {
	margin-top: 10px;
	display: flex;
	flex-wrap: wrap;
	overflow-y: scroll;
	max-height: 210px;

	.icon-wrapper {
		width: 30px;
		height: 30px;
		cursor: pointer;
	}
}
</style>
