export default function usePointerCapture<T>(
	downFn: (event: PointerEvent) => T,
	moveFn: (event: PointerEvent, value: T) => void,
): [
		(event: PointerEvent) => void,
		(event: PointerEvent) => void,
		(event: PointerEvent) => void,
	] {
	let state: any;
	let captured = false;
	function begin(e: PointerEvent) {
		if (captured) { return; }
		state = downFn(e);
		(e.currentTarget as Element).setPointerCapture(e.pointerId);
		captured = true;
	}
	function move(e: PointerEvent) {
		const el = e.currentTarget as Element;
		if (!el.hasPointerCapture(e.pointerId)) { return; }
		moveFn(e, state);
	}
	function end(e: PointerEvent) {
		const { pointerId } = e;
		try {
			const el = e.currentTarget as Element;
			el.releasePointerCapture(pointerId);
		} catch (e) {
			console.error(e);
		}
		captured = false;
	}
	return [begin, move, end];
}
