import { watch, shallowRef, toRef, computed } from 'vue';
import type { Ref } from 'vue';

export interface EditPermission {
	creatable: boolean;
	deletable: boolean;
	exportable: boolean;
	importable: boolean;
	sharable: boolean;
	printable: boolean;
	readable: Set<string>;
	writable: Set<string>;
	modifiable: Set<string>;
}
export interface UserPermission extends EditPermission {
}
export interface DocPermission extends UserPermission {
	ifOwner: EditPermission;
}
export function build(meta: locals.DocType, targetMeta = meta): DocPermission {
	const permissions = meta.permissions.filter(pe => frappe.user.has_role(pe.role));
	const level0 = permissions.filter(p => !p.permlevel);
	const level0NotOwner = level0.filter(p => !p.if_owner);
	const level0Owner = level0.filter(p => p.if_owner);
	const creatable = Boolean(level0NotOwner.find(p=> p.create));
	const creatableIfOwner = creatable || Boolean(level0Owner.find(p=> p.create));

	const deletable = Boolean(level0NotOwner.find(p=> p.delete));
	const deletableIfOwner = deletable || Boolean(level0Owner.find(p=> p.delete));

	const exportable = Boolean(level0NotOwner.find(p=> p.export));
	const exportableIfOwner = exportable || Boolean(level0Owner.find(p=> p.export));

	const importable = Boolean(level0NotOwner.find(p=> p.import));
	const importableIfOwner = importable || Boolean(level0Owner.find(p=> p.import));

	const sharable = Boolean(level0NotOwner.find(p=> p.share));
	const sharableIfOwner = sharable || Boolean(level0Owner.find(p=> p.share));

	const printable = Boolean(level0NotOwner.find(p=> p.print));
	const printableIfOwner = printable || Boolean(level0Owner.find(p=> p.print));

	const writableLevel = new Set();
	const writableLevelIfOwner = new Set();
	const readableLevel = new Set();
	const readableLevelIfOwner = new Set();
	for (const p of permissions) {
		const {permlevel} = p;
		if (p.write) {
			if (p.if_owner) {
				writableLevelIfOwner.add(permlevel);
			} else {
				writableLevel.add(permlevel);
			}
		}
		if (p.read) {
			if (p.if_owner) {
				readableLevelIfOwner.add(permlevel);
			} else {
				readableLevel.add(permlevel);
			}
		}
	}
	const isWritable = writableLevel.has(0);
	const isWritableIfOwner = writableLevelIfOwner.has(0);
	const isReadable = writableLevel.has(0);
	const isReadableIfOwner = writableLevelIfOwner.has(0);
	const writable = new Set<string>();
	const writableIfOwner = new Set<string>();
	const readable = new Set<string>();
	const readableIfOwner = new Set<string>();
	const setOnlyOnce = new Set<string>();
	for (const {fieldname, permlevel, set_only_once} of targetMeta.fields) {
		if (set_only_once) { setOnlyOnce.add(fieldname); }
		if (writableLevel.has(permlevel)) {
			if (isWritable) {
				writable.add(fieldname);
				writableIfOwner.add(fieldname);
			} else if (isWritableIfOwner) {
				writableIfOwner.add(fieldname);
			}
		} else if (writableLevelIfOwner.has(permlevel) && (isWritable || isWritableIfOwner)) {
			writableIfOwner.add(fieldname);
		}
		if (readableLevel.has(permlevel)) {
			if (isReadable) {
				readable.add(fieldname);
				readableIfOwner.add(fieldname);
			} else if (isReadableIfOwner) {
				readableIfOwner.add(fieldname);
			}
		} else if (readableLevelIfOwner.has(permlevel) && (isReadable || isReadableIfOwner)) {
			readableIfOwner.add(fieldname);
		}
	}
	const modifiable = new Set<string>(writable);
	const modifiableIfOwner = new Set<string>(writableIfOwner);
	for (const field of setOnlyOnce) {
		modifiable.delete(field);
		modifiableIfOwner.delete(field);
	}

	return {
		creatable,
		deletable, exportable, importable, sharable, printable,
		readable, writable, modifiable,
		ifOwner: {
			creatable: creatableIfOwner,
			deletable: deletableIfOwner,
			exportable: exportableIfOwner,
			importable: importableIfOwner,
			sharable: sharableIfOwner,
			printable: printableIfOwner,
			readable:readableIfOwner,
			writable: writableIfOwner,
			modifiable: modifiableIfOwner,
		},
	};
}

const allPermissions = new Map<string, Promise<DocPermission | null>>();
export function createNull(): DocPermission {
	return {
		creatable: false,
		deletable: false,
		exportable: false,
		importable: false,
		sharable: false,
		printable: false,
		readable: new Set(),
		writable: new Set(),
		modifiable: new Set(),
		ifOwner: {
			creatable: false,
			deletable: false,
			exportable: false,
			importable: false,
			sharable: false,
			printable: false,
			readable: new Set(),
			writable: new Set(),
			modifiable: new Set(),
		},
	};
}
async function _loadPermissions(doctype: string, targetDoctype = doctype) {
	const meta = frappe.get_meta(doctype);
	const targetMeta = frappe.get_meta(targetDoctype);
	if (meta && targetMeta) { return build(meta, targetMeta); }
	if (!meta) {
		await frappe.model.with_doctype(doctype);
	}
	if (!targetMeta && targetDoctype !== doctype) {
		await frappe.model.with_doctype(targetDoctype);

	}
	const dt = frappe.get_meta(doctype);
	const tdt = frappe.get_meta(targetDoctype);
	if (dt && tdt) { return build(dt, tdt); }
	return null;
}
export function load(doctype: string, targetDoctype?: string) {
	const key = targetDoctype ? [doctype, targetDoctype].join('\n') : doctype;
	const p = allPermissions.get(key);
	if (p) { return p; }
	const perm = _loadPermissions(doctype, targetDoctype);
	allPermissions.set(key, perm);
	perm.then(r => {
		if (r) { return; }
		if (allPermissions.get(key) !== perm) { return; }
		allPermissions.delete(key);
	}, () => {
		if (allPermissions.get(key) !== perm) { return; }
		allPermissions.delete(key);
	});
	return perm;
}

export function use(
	doctype: string | Ref<string> | (() => string),
	targetDoctype?: string | Ref<string> | (() => string),
) {
	const dt = typeof doctype === 'function' ? computed(doctype) : toRef(doctype);
	const tdt = typeof targetDoctype === 'function'
		? computed(targetDoctype)
		: toRef(targetDoctype);
	const permission = shallowRef<DocPermission | null>(null);

	watch([dt, () => tdt.value || dt.value], async ([doctype, targetDoctype]) => {
		permission.value = null;
		const dp = await load(doctype, targetDoctype);
		if (dt.value !== doctype) { return; }
		if ((tdt.value || dt.value) !== targetDoctype) { return; }
		permission.value = dp;
	}, {immediate: true});

	return permission;
}

export function ifOwner(
	permission?: DocPermission | null,
	owner?: boolean,
): UserPermission {
	if (!permission) {
		return {
			creatable: false,
			deletable: false,
			exportable: false,
			importable: false,
			sharable: false,
			printable: false,
			readable: new Set(),
			writable: new Set(),
			modifiable: new Set(),
		};
	}
	return owner ? {...permission, ...permission.ifOwner} : permission;
}

export function useIfOwner(
	doctype: string | Ref<string> | (() => string),
	owner: boolean | Ref<boolean> | (() => boolean),
) {
	const own = typeof owner === 'function' ? computed(owner) : toRef(owner);
	const permission = use(doctype);
	return computed(() => ifOwner(permission.value, own.value));
}
