import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66ff320e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "action_container",
  style: {display:'flex', justifyContent:'space-around', alignItems:'center'}
}
const _hoisted_2 = { style: { 'margin': '0' } }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElIcon = _resolveComponent("ElIcon")
  const _component_ElTooltip = _resolveComponent("ElTooltip")
  const _component_ElPopconfirm = _resolveComponent("ElPopconfirm")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.props.params.onEdit)
      ? (_openBlock(), _createBlock(_component_ElTooltip, {
          key: 0,
          content: $setup.tt('Edit'),
          placement: "top"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElIcon, {
              color: "#337ecc",
              size: "12",
              onClick: $setup.editClick
            }, {
              default: _withCtx(() => [
                _createVNode($setup["Edit"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"]))
      : _createCommentVNode("v-if", true),
    ($setup.props.params.onRemove)
      ? (_openBlock(), _createBlock(_component_ElPopconfirm, {
          key: 1,
          title: $setup.tt('Are you sure you want to delete it?'),
          onConfirm: $setup.click,
          "confirm-button-text": $setup.tt('Confirm'),
          "cancel-button-text": $setup.tt('Cancel')
        }, {
          reference: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ElTooltip, {
                content: $setup.tt('Delete'),
                placement: "top"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElIcon, {
                    color: "#c45656",
                    size: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["Delete"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["content"])
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["title", "confirm-button-text", "cancel-button-text"]))
      : _createCommentVNode("v-if", true)
  ]))
}