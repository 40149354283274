<template>
	<div class="main" :hidden="hidden">
		<slot />
	</div>
	<div class="resize" :hidden="hidden"
		@pointerdown="detailBegin"
		@pointermove="detailMove"
		@pointerup="detailEnd"
		@pointercancel="detailEnd"
		@touchmove.prevent />
</template>
<script setup lang="ts">

import {computed} from 'vue';

import usePointerCapture from './usePointerCapture';

const props = defineProps<{ modelValue: string; hidden?: boolean }>();
const emit = defineEmits<{
	(event: 'update:modelValue', size: string): void
}>();

const [detailBegin, detailMove, detailEnd] = usePointerCapture(
	e => e.offsetX,
	(e, x) => {
		const parent = (e.currentTarget as Element).parentElement;
		if (!parent) {
			return;
		}
		const rect = parent.getBoundingClientRect();
		emit('update:modelValue', `${e.pageX - rect.x - x}px`);
	},
);
const size = computed(() => `min(max(100px, ${props.modelValue}), 50%)`);
</script>
<style scoped lang="less">
.main {
	overflow: auto;
	flex: 0 v-bind(size);

}

.resize {
	flex: 0 1px;
	z-index: 2;
	box-sizing: content-box;
	background: #d9d9d9;
	background-clip: padding-box;
	border-color: transparent;
	border-style: solid;
	margin: 0 -5px;
	border-width: 0 5px;
	cursor: col-resize;

	&:hover {
		border-color: #0000001a;
		transition: border-color 2s ease;
	}

}
</style>
