import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ce22fb7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-container" }
const _hoisted_2 = { class: "add-button" }
const _hoisted_3 = { class: "confirm_div" }
const _hoisted_4 = { class: "confirm_div" }
const _hoisted_5 = { class: "description" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")
  const _component_el_checkbox = _resolveComponent("el-checkbox")
  const _component_el_form = _resolveComponent("el-form")
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["FixScore"], {
      tempAssessList: $setup.tempAssessList,
      permission: $props.detailPermission,
      lockStatus: $props.lockStatus,
      onUpdate: $setup.update,
      onRemove: _cache[0] || (_cache[0] = (data, type) => $setup.emit('remove', data))
    }, null, 8 /* PROPS */, ["tempAssessList", "permission", "lockStatus"]),
    _createElementVNode("div", _hoisted_2, [
      (!$props.lockStatus && $props.detailPermission?.creatable)
        ? (_openBlock(), _createBlock($setup["ElButton"], {
            key: 0,
            onClick: $setup.createTempTask,
            link: "",
            icon: $setup.Plus,
            title: $setup.tt('Add Weekly Key Task'),
            type: "primary"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('Add')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["icon", "title"]))
        : _createCommentVNode("v-if", true)
    ]),
    _createVNode(_component_el_form, {
      labelPosition: "top",
      class: "detail-form",
      model: $setup.form,
      labelWidth: "100px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_row, { gutter: 10 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: 
							$setup.tt('Last Week Problem Rectification Arrangement')
						,
                  prop: "rectification_record"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: $setup.form.rectification_record,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.form.rectification_record) = $event)),
                      rows: 4,
                      type: "textarea",
                      onChange: _cache[2] || (_cache[2] = v => $setup.change('rectification_record', v)),
                      disabled: $setup.isDisabled('rectification_record')
                    }, null, 8 /* PROPS */, ["modelValue", "disabled"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: 
							$setup.tt('Reason Explanation For Incomplete Completion')
						,
                  prop: "uncomplete_reason"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      type: "textarea",
                      rows: 4,
                      modelValue: $setup.form.uncomplete_reason,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.form.uncomplete_reason) = $event)),
                      onChange: _cache[4] || (_cache[4] = v => $setup.change('uncomplete_reason', v)),
                      disabled: $setup.isDisabled('uncomplete_reason')
                    }, null, 8 /* PROPS */, ["modelValue", "disabled"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_el_row, { gutter: 10 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: 
							$setup.tt(
								'Assessment of work for this week, existing gaps, and improvement suggestions for next week',
							)
						,
                  prop: "suggestion"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: $setup.form.suggestion,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.form.suggestion) = $event)),
                      rows: 4,
                      type: "textarea",
                      onChange: _cache[6] || (_cache[6] = v => $setup.change('suggestion', v)),
                      disabled: $setup.isDisabled('suggestion')
                    }, null, 8 /* PROPS */, ["modelValue", "disabled"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"]),
                _createVNode(_component_el_form_item, { label: "" }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", null, _toDisplayString($setup.tt('Assessor (Signature)')) + ":" + _toDisplayString($props.assessInfo?.leader_doc?.full_name) + " " + _toDisplayString($setup.tt('Confirmed ({})', [
										$setup.form.leaderConfirmDate,
									])), 1 /* TEXT */)
                    ], 512 /* NEED_PATCH */), [
                      [_vShow, $setup.form.leaderConfirmDate]
                    ]),
                    _withDirectives(_createVNode(_component_el_checkbox, {
                      label: $setup.tt('Assessment Person Sign'),
                      modelValue: $setup.isLeaderConfirmed,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($setup.isLeaderConfirmed) = $event)),
                      onChange: $setup.leaderToConfirm,
                      disabled: $setup.leader_confirm_disabled
                    }, null, 8 /* PROPS */, ["label", "modelValue", "disabled"]), [
                      [_vShow, !$setup.form.leaderConfirmDate]
                    ])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: 
							$setup.tt(
								'Provide work support and requirements to the work planner for this week',
							)
						,
                  prop: "support"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: $setup.form.support,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => (($setup.form.support) = $event)),
                      rows: 4,
                      type: "textarea",
                      onChange: _cache[9] || (_cache[9] = v => $setup.change('support', v)),
                      disabled: $setup.isDisabled('support')
                    }, null, 8 /* PROPS */, ["modelValue", "disabled"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"]),
                _createVNode(_component_el_form_item, { label: "" }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", null, _toDisplayString($setup.tt('Assessed Person (Signature)')) + ":" + _toDisplayString($setup.props.assessInfo?.user_doc.full_name) + " " + _toDisplayString($setup.tt('Confirmed ({})', [$setup.form.userConfirmDate])), 1 /* TEXT */)
                    ], 512 /* NEED_PATCH */), [
                      [_vShow, $setup.form.userConfirmDate]
                    ]),
                    _withDirectives(_createVNode(_component_el_checkbox, {
                      label: $setup.tt('Assessed Person'),
                      modelValue: $setup.isUserConfirmed,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => (($setup.isUserConfirmed) = $event)),
                      onChange: $setup.userToConfirm,
                      disabled: $setup.user_confirm_disabled
                    }, null, 8 /* PROPS */, ["label", "modelValue", "disabled"]), [
                      [_vShow, !$setup.form.userConfirmDate]
                    ])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("div", _hoisted_5, _toDisplayString($setup.tt('Explanation')) + ":" + _toDisplayString($setup.tt(
						'① This table serves as a work schedule and a summary of work completion status;',
					)) + _toDisplayString($setup.tt(
						'② Confirm the weekly plan with the department head before the end of each Monday and have both parties sign it;',
					)) + " " + _toDisplayString($setup.tt(
						'③ Summarize the completion status of last week plan every Monday. If the planned work is not completed, please specify the reason.',
					)), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["model"])
  ])), [
    [_directive_loading, $setup.loading]
  ])
}