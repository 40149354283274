<template>
	<AgGridVue
		v-loading="loading"
		class="ag-theme-guigu"
		style="height: 500px"
		:columnDefs="columnDefs"
		:rowData="progressGatherData"
		:defaultColDef="defaultColDef"
		animateRows="true"
		@grid-ready="onGridReady"
		:autoGroupColumnDef="autoGroupColumn"
		:getDataPath="getDataPath"
		:treeData="true"
		:localeText="zhCN"
		:suppressDragLeaveHidesColumns="smallMeta"
		groupDefaultExpanded="-1">
	</AgGridVue>
</template>
<script lang="ts">
import { ref, computed, defineComponent, PropType, getCurrentInstance, watch} from 'vue';
import { AgGridVue } from 'ag-grid-vue3';

import Float from '../../../../../AgGrid/components/Float.vue';
import SparklineCellRenderer from '../../../../../AgGrid/components/SparklineCellRenderer.vue';
import { GaterData } from '../../type';
import { getTreeAncestry } from '../../../../../utils';
import { useMetaQuery } from '../../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';
import zhCN from '../../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';

import { getColumns } from './helper';

export default defineComponent({
	components:{
		Float,
		AgGridVue,
		SparklineCellRenderer,
	},
	props:{
		'project':String,
		'structureName':String,
	},
	setup(props, context) {
		const smallMeta = useMetaQuery();
		const {appContext} = getCurrentInstance()||{};
		const progressGatherData = ref<GaterData[]>([]);
		const gridApi = ref(null);
		const enableAudit = ref<boolean>(false);
		const loading = ref<boolean>(false);
		const onGridReady = (params:any) => {
			gridApi.value = params.api;
		};
		let autoGroupColumn = ref({
			headerName: __('Construction Progress'),
			field:'progress_name',
			flex: 1,
			minWidth: 230,
			enableRowGroup: false,
			editable: false,
			filter: false,
			sortable: false,
			pinned: 'left',
		});
		watch(()=>props.project, async ()=>{
			const data = await frappe.db.get_list('Guigu Construction Project Setting', {
				fields:['*'],
				filters:{project:props.project},
				limit:0,
			});
			enableAudit.value = data[0]?.use_audit_volume === 'Yes';
		}, {immediate:true});
		watch([()=>props.project, ()=>props.structureName], async ()=>{
			if (!props.project||!props.structureName||appContext?.config.globalProperties.$frm.is_new()){ return; }
			loading.value=true;
			const data = await frappe.call<{message:GaterData[]}>({
				method: 'guigu_pm.guigu_pm_construction.doctype.guigu_construction_structure.guigu_construction_structure.get_progress_gather',
				args:{project:props.project, structure:props.structureName},
			});
			const ancestryData = getTreeAncestry(data?.message||[]);
			progressGatherData.value = ancestryData;
			loading.value=false;
		}, {immediate:true});
		const columnDefs = computed(()=>getColumns(enableAudit.value));
		function getDataPath(data:any){
			return data.ancestry;
		}
		const defaultColDef = {
			sortable: true,
			filter: true,
			flex: 1,
			resizable: true,
		};


		return {
			loading,
			onGridReady,
			autoGroupColumn,
			columnDefs,
			getDataPath,
			defaultColDef,
			progressGatherData,
			smallMeta,
			zhCN,
		};
	},
});
</script>
