import { renderSlot as _renderSlot, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3cdf77ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "timeline-container",
  ref: "el"
}
const _hoisted_2 = { class: "manageContainer" }
const _hoisted_3 = { class: "manageContent noBorder" }
const _hoisted_4 = {
  key: "timeline",
  id: "guigu-timeline-view"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_viewer = _resolveDirective("viewer")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          id: "guigu_timeline_container",
          class: _normalizeClass({ timeLine: true, 'left-margin': $props.mode === 'left' })
        }, [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])), [
            [_directive_viewer]
          ])
        ], 2 /* CLASS */)
      ])
    ])
  ], 512 /* NEED_PATCH */))
}