<template>
	<div class="table" v-if="props.hotDataList.length">
		<div ref="hot"></div>
	</div>
	<div v-else>
		<ElEmpty :description="tt('No Records Created')" :imageSize="200" />
	</div>
</template>
<script setup lang="ts">
import {ref, onMounted, onUnmounted, watch} from 'vue';
import Handsontable from 'handsontable';
import {ElEmpty} from 'element-plus';

import 'handsontable/dist/handsontable.full.css';

import type {HotData} from '../type';
import {getMenus} from '../../../../excel/helper';

import {getColumn, getMergeCells, getHeader} from './helper';

const props = defineProps<{
	hotDataList: HotData[];
	isTotal: boolean;
	isShowConsume: boolean;
	isShowDetail:boolean;
	hotName:string
}>();
const tt = __;
const hot = ref<HTMLElement>();
const hatable = ref<Handsontable>();

/**
 * 创建表格
 * @param container
 */
function createHot(container: HTMLElement) {
	const {height} = document.querySelector('#main_material_verification_and_disposal_statistics')!.getBoundingClientRect();
	hatable.value = new Handsontable(container, {
		data: [],
		columns: [],
		rowHeaders: true,
		colHeaders: true,
		height: height * 0.95,
		width: '100%',
		autoWrapRow: true,
		autoWrapCol: true,
		mergeCells: [],
		licenseKey: 'non-commercial-and-evaluation',
		language: frappe.boot.lang === 'zh' ? 'zh-CN' : undefined,
	});
}
// 监视hot节点，存在之后创建handsontable实例
watch(hot, () => {
	if (hot.value) {
		createHot(hot.value);
	}
}, {immediate: true});
// 监视数据变化，更新表格
watch(
	[
		hatable,
		() => props.hotDataList,
		() => props.isTotal,
		() => props.isShowConsume,
		() => props.isShowDetail,
	],
	() => {
		if (!hatable.value) {
			return;
		}
		const column = getColumn(props.hotName, props.isShowConsume, props.isTotal, props.isShowDetail);
		const tableData = getHeader(column, true);
		const {headerData, columns, cells, dataSchema, widths} = tableData;
		const mergeCells = getMergeCells(props.hotDataList, props.isShowConsume, props.isTotal, props.isShowDetail);
		const newData = [...(headerData || []), ...(props.hotDataList || [])];
		const contextMenu = getMenus(
			hatable.value as Handsontable, false, false, false,
			__('{} Main Material Verification And Disposal Statistics', [props.hotName]),
		);
		hatable.value?.updateSettings({
			columns: columns,
			data: newData,
			colWidths: widths,
			mergeCells,
			contextMenu,
			cells,
			dataSchema,
			fixedRowsTop: 3,
		});
	},
);
// 窗口变化时
function handleResize() {
	if (hot.value) {
		const {height} = document.querySelector('#main_material_verification_and_disposal_statistics')!.getBoundingClientRect();
		hot.value.style.height = `${height * 0.95}px`;
	}
}
onMounted(() => {
	window.addEventListener('resize', handleResize);
});
onUnmounted(() => {
	window.removeEventListener('resize', handleResize);
});
</script>

<style lang="less" scoped>
.table {
	margin: 0;
	width: 100%;
	height: 100%;
}
:deep(.ht_clone_left) {
	z-index: 1;
}
:deep(.ht_clone_top) {
	z-index: 1;
}
:deep(.ht_clone_top_inline_start_corner.ht_clone_top_left_corner) {
	z-index: 1;
}
:deep(.ht_master) {
	padding-bottom: 15px;
}
:deep(.ht_clone_inline_start.ht_clone_left.handsontable) {
	display: inline;
}
</style>
