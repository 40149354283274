import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4cf468c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pagination" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_WorkspaceWidget = _resolveComponent("WorkspaceWidget")

  return _withDirectives((_openBlock(), _createBlock(_component_WorkspaceWidget, null, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString($props.label), 1 /* TEXT */)
    ]),
    button: _withCtx(() => [
      _createVNode($setup["Expander"], { loading: $setup.loading }, null, 8 /* PROPS */, ["loading"]),
      _createVNode($setup["ElButton"], {
        onClick: $setup.refresh,
        title: "刷新",
        disabled: $setup.loading,
        circle: "",
        icon: $setup.RefreshRight
      }, null, 8 /* PROPS */, ["onClick", "disabled", "icon"])
    ]),
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent($setup.comp), {
        class: "ag-theme-guigu ag",
        style: _normalizeStyle($setup.style),
        rowHeight: $setup.rowHeight,
        meta: $props.meta,
        data: $setup.data,
        loading: $setup.loading,
        detail: $setup.detail,
        configuration: $setup.view
      }, null, 8 /* PROPS */, ["style", "rowHeight", "meta", "data", "loading", "detail", "configuration"])),
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["Limit"], {
          loading: $setup.loading,
          modelValue: $setup.limit,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.limit) = $event)),
          maxLimit: $setup.maxLimit,
          class: "limit"
        }, null, 8 /* PROPS */, ["loading", "modelValue", "maxLimit"]),
        (!$setup.isAll && !$setup.infiniteScroll)
          ? (_openBlock(), _createBlock($setup["Pagination"], {
              key: 0,
              total: $setup.total,
              loading: $setup.loading,
              limit: $setup.limit,
              page: $setup.page,
              "onUpdate:page": _cache[1] || (_cache[1] = $event => (($setup.page) = $event))
            }, null, 8 /* PROPS */, ["total", "loading", "limit", "page"]))
          : _createCommentVNode("v-if", true),
        ($setup.showInfiniteScrollBtn)
          ? (_openBlock(), _createBlock($setup["ElButton"], {
              key: 1,
              onClick: $setup.nextPage,
              size: "small",
              disabled: $setup.loading
            }, {
              default: _withCtx(() => [
                _createTextVNode(" 继续加载 ")
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick", "disabled"]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _: 1 /* STABLE */
  })), [
    [$setup["vLoading"], $setup.loading || false]
  ])
}