<template>
	<ElPopover :popperStyle="{ 'width': '450px','zIndex':2002 }" :visible="visible" trigger="click" placement="right">
		<template #reference>
			<Settings class="setting" @click="clickSetting" :size="16"></Settings>
		</template>
		<div>
			<div class="header">
				<ElSpace>
					<ElButton type="danger" @click="onDelete">{{ tt('Delete') }}</ElButton>
					<X class="close" :size="16" @click="close"></X>
				</ElSpace>
			</div>
			<div class="option-container">
				<slot :visible="visible" />
			</div>
		</div>
	</ElPopover>
</template>

<script setup lang="ts">
import { ref, defineExpose, watch, inject } from 'vue';

import { ElPopover, ElSpace, ElButton } from 'element-plus';
import { Settings, X } from 'lucide-vue-next';

import type { ChartProvide } from '../../type';

const tt = __;

const props = defineProps({
	editable: Boolean,
});
interface Emit {
	(event: 'remove'): void;
}
const emit = defineEmits<Emit>();
const visible = ref<boolean>(false);
const chart = inject<ChartProvide>('chart');
function clickSetting() {
	visible.value = !visible.value;
}
function close() {
	visible.value = false;
}

async function onDelete() {
	const isSuccess = await chart?.delete?.();
	if (isSuccess) {
		emit('remove');
	}
}
</script>
<style scoped lang="less">
.header {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 8px;
}

.setting {
	cursor: pointer;
}

.close {
	cursor: pointer;
}

.option-container {
	padding: 0 15px;
	max-height: 600px;
	overflow-y: scroll;
}
</style>
