<template>
	<div class="report" v-loading="loading">
		<ElTable :data="reportList" border style="width: 100%" :height="tableHeight">
			<template #empty>
				<Empty ref="empty"></Empty>
			</template>
			<ElTableColumn fixed prop="report_name" label="名称" minWidth="250" >
				<template #default="scope">
					{{ `${tt(scope.row.report_name)}(${scope.row.report_name})` }}
				</template>
			</ElTableColumn>
			<ElTableColumn fixed prop="ref_doctype" label="源单据" minWidth="180">
				<template #default="scope">
					{{ `${tt(scope.row.ref_doctype)}(${scope.row.ref_doctype})` }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="module" label="模块" width="180" >
				<template #default="scope">
					{{ `${tt(scope.row.module)}(${scope.row.module})` }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="is_standard" label="系统自带" width="80" >
				<template #default="scope">
					{{ scope.row.is_standard==='Yes'?'是':'否' }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="report_type" label="报表类型" width="80" >
				<template #default="scope">
					{{ tt(scope.row.report_type) }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="add_total_row" label="添加总计行" width="80" >
				<template #default="scope">
					{{ scope.row.add_total_row===1?'是':'否' }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="disabled" label="禁用" width="50" >
				<template #default="scope">
					{{ scope.row.disabled===1?'是':'否' }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="prepared_report" label="后台运行报表" width="100" >
				<template #default="scope">
					{{ scope.row.prepared_report===1?'是':'否' }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="address" label="操作" width="150" >
				<template #default="scope">
					<ElButton type="primary" @click="gotoReport(scope.row)">显示报表</ElButton>
					<ElButton type="primary" @click="editReport(scope.row)">详情</ElButton>
				</template>
			</ElTableColumn>
		</ElTable>
	</div>
</template>

<script setup lang='ts'>
import {ref, watch, onMounted, onUnmounted} from 'vue';
import {ElTable, ElTableColumn, ElButton, vLoading} from 'element-plus';

import type {AppInfo, Report, ModuleInfo} from './type';
import Empty from './Empty.vue';

const tt = __;
interface Props{
	current?:AppInfo|ModuleInfo
}
const props = defineProps<Props>();
const reportList = ref<Report[]>([]);
const loading = ref<boolean>(true);
const empty = ref<HTMLElement>();
const tableHeight = ref<string>('100%');
watch(()=>props.current, async()=>{
	getReportList();
}, {
	deep: true,
	immediate: true,
});
function editReport(data:Report) {
	frappe.set_route(['form', 'Report', data.name]);
}
function gotoReport(data:Report) {
	switch (data.report_type) {
		case 'Report Builder':
			frappe.set_route('List', data.ref_doctype, 'Report', data.name);
			break;
		case 'Query Report':
			frappe.set_route('query-report', data.name);
			break;
		case 'Script Report':
			frappe.set_route('query-report', data.name);
			break;
		case 'Custom Report':
			frappe.set_route('query-report', data.name);
			break;
	}
}
async function getReportList() {
	tableHeight.value = '100%';
	loading.value = true;
	if (!props.current) {
		return;
	}
	const res = await frappe.call<{message:Report}>({
		method: 'guigu.guigu.page.guigu_app_list.guigu_app_list.get_report_list',
		args: {
			type: props.current.type,
			name: props.current.name,
		},
	});
	reportList.value = res.message || [];
	loading.value = false;
}
watch(empty, () => {
	if (empty.value && reportList.value.length === 0) {
		tableHeight.value = '182px';
	}
});
const popstateListener = function () {
	getReportList();
};
onMounted(() => {
	window.addEventListener('popstate', popstateListener);
});
onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});
</script>

<style lang='less' scoped>
.report{
	height: 100%;
	display: flex;
    flex-direction: column;
	.btn-container{
		text-align: right;
		margin-bottom: 8px;
	}
	.role{
		cursor: pointer;
		margin-right: 4px;
		&:hover{
			text-decoration: underline;
		}
	}
}
</style>
