import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8cfd5be7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElDialog"], {
    modelValue: $setup.show,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.show) = $event)),
    title: "移动方式到...",
    width: "min(300px, 100%)"
  }, {
    footer: _withCtx(() => [
      _createVNode($setup["ElButton"], {
        onClick: _cache[4] || (_cache[4] = $event => ($setup.show = false))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["ElButton"], {
          onClick: _cache[0] || (_cache[0] = $event => ($setup.click(true, false)))
        }, {
          default: _withCtx(() => [
            _createTextVNode("当前项前")
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElButton"], {
          onClick: _cache[1] || (_cache[1] = $event => ($setup.click(false, false)))
        }, {
          default: _withCtx(() => [
            _createTextVNode("当前项后")
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElButton"], {
          onClick: _cache[2] || (_cache[2] = $event => ($setup.click(true, true)))
        }, {
          default: _withCtx(() => [
            _createTextVNode("当前项的子项中的最前")
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElButton"], {
          onClick: _cache[3] || (_cache[3] = $event => ($setup.click(false, true)))
        }, {
          default: _withCtx(() => [
            _createTextVNode("当前想的子项中的最后")
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}