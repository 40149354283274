<template>
	<div ref="excelContainerRef">
		<div class="header">
			<div v-if="writePermission && excelMetaPermissions.read && df.options"
				class="btn-container">
				<ElButton type="primary" @click="importSheets" :disabled="readonly">加载模版表格</ElButton>
			</div>
		</div>
		<div v-loading="loading">
			<ElTabs class="tab-container" type="border-card" tabPosition="bottom"
				v-model="editableTabsValue"
				:editable="!readonly && writePermission && excelMetaPermissions.write"
				@tabAdd="tabAdd" @tabRemove="tabRemove" @tabChange="tabChange"
			>
				<ElTabPane v-for="item in sheets" :key="item.key"
					:label="item.name" :name="item.key">
					<template #label>
						<TabLabel :title="item.name" @change="changeTitle" />
					</template>
					<Table
						:value="item.value"
						:readOnly="readonly || !writePermission"
						:formula="hyperFormula" :name="item.name"
						:getSheets="getSheets"
						@add="hotTableRefs[item.name] = $event"
						@remove="delete hotTableRefs[item.name]"
						@change="emitChange"
						@init="initState[item.key] = true"
					/>
				</ElTabPane>
			</ElTabs>
		</div>
		<SelectExcelTemplateDialog
			v-if="excelMeta"
			:visible="visible"
			:excelMeta="excelMeta"
			:query="query"
			@addSheets="addSheets"
			@cancel="cancel" />
	</div>
</template>

<script setup lang='ts'>
import { ref, watch, shallowRef, shallowReactive, onMounted, triggerRef, computed } from 'vue';


import type { TabPaneName } from 'element-plus';
import { ElMessage, ElMessageBox, ElTabs, ElTabPane, ElButton, vLoading } from 'element-plus';
import Sortable from 'sortablejs';

import HyperFormula from 'hyperformula';

import type { DF } from '../connection_table/type';

import TabLabel from './components/TabLabel.vue';
import Table from './Table.vue';
import type { ExcelTem, Sheet } from './type';
import SelectExcelTemplateDialog from './components/SelectExcelTemplateDialog.vue';
import { getNewTitle } from './helper';
import { useGetMeta } from './useGetMeta';

const hyperFormula = HyperFormula.buildEmpty({
	licenseKey: 'internal-use-in-handsontable',
	localeLang: 'zh-cn',
});

interface Props {
	value: string;
	doctype?: string;
	df: DF
	readonly?: boolean;
	getQueries: () => { filters: any };
}
const query = shallowRef<{ filters: any }>();
const props = defineProps<Props>();
interface Emit {
	(e: 'change', options: string): void
}
const emit = defineEmits<Emit>();
const hotTableRefs = shallowReactive<{[k: string]: any}>(Object.create(null));
const sheets = shallowRef<Sheet[]>([]);
const editableTabsValue = ref<string>('');
const initState = ref<{ [key: string]: boolean }>({});
const visible = ref<boolean>(false);
const excelContainerRef = ref<HTMLDivElement>();
const currentValue = ref<string>('[]');
const { excelMeta, options, field } = useGetMeta(props.df.options);
const excelMetaPermissions = computed(() => {
	if (props.df.read_only === '1'){
		return { write: false, read: false };
	}
	if (!props.df.options) {
		return { write: true, read: true };
	}
	if (!excelMeta.value) {
		return { write: false, read: false };
	}
	const dt = excelMeta.value.name;
	const write = frappe.perm.has_perm(dt, 0, 'write');
	const read = frappe.perm.has_perm(dt, 0, 'read');
	return { write, read };
});
const writePermission = computed(() => {
	const {doctype} = props;
	if (!doctype || props.df.read_only === '1'){ return false; }
	return frappe.perm.has_perm(doctype, 0, 'write');
});
const loading = computed(()=>Object.values(initState.value).some(init => init === false));
function importSheets() {
	query.value = props.getQueries();
	visible.value = true;
}
function addSheets(newSheet: ExcelTem[]) {
	visible.value = false;
	const selectedSheets: Sheet[] = newSheet.map(item => {
		try {
			const selectedSheets: Sheet = JSON.parse(item[field.value] || '[]');
			return selectedSheets;
		} catch (e) { return; }
	}).filter(Boolean).flat() as Sheet[];
	const names = sheets.value.map(s => s.name);
	for (const sheet of selectedSheets) {
		const name = getNewTitle(names, sheet.name);
		names.push(name);
		const value = sheet.value || sheet.tableOptions;
		initState.value[name] = false;
		sheets.value.push({ name, key: name, value });
	}
	editableTabsValue.value = editableTabsValue.value || sheets.value[0]?.key || '';
	triggerRef(sheets);
}
function cancel() {
	visible.value = false;
}

watch(() => props.value, value => {
	if (!value) { return; }
	if (currentValue.value === value){ return; }
	currentValue.value = value;
	const options = JSON.parse(value) as Sheet[];
	sheets.value = options.map(item => {
		const name = item.name || item.title;
		const value = item.value || item.tableOptions;
		return { key: name, name, value };
	});
	const oldState = initState.value;
	initState.value = Object.fromEntries(
		sheets.value.map(({key}) => [key, Boolean(key in oldState && oldState[key])]),
	);
	const currentTab = editableTabsValue.value;
	if (!currentTab || !(currentTab in initState.value)) {
		editableTabsValue.value = sheets.value[0]?.key || '';
	}
	triggerRef(sheets);
}, { immediate: true, deep: true });

function emitChange(){
	if (loading.value){ return; }
	const value = JSON.stringify(sheets.value.map(sheet => ({
		name: sheet.name,
		value: hotTableRefs[sheet.name]?.value || {},
	})));
	currentValue.value = value;
	if (value === props.value) { return; }
	emit('change', value);
}
function tabAdd() {
	const n = sheets.value.reduce((r, {name}) => {
		const res = /^sheet([0-9]+)$/i.exec(name);
		return res ? Math.max(parseInt(res[1]) || 0, r) : r;
	}, 0);
	const name = `sheet${n + 1}`;
	initState.value[name] = false;
	sheets.value.push({ name, key: name });
	emitChange();
	editableTabsValue.value = name;
}
function tabRemove(name: TabPaneName) {
	ElMessageBox.confirm('您确认删除此sheet吗?', '请确认', {
		confirmButtonText: '确定',
		cancelButtonText: '取消',
		type: 'warning',
	}).then(() => {
		const tabs = sheets.value;
		let activeName = editableTabsValue.value;
		if (activeName === name) {
			const index = tabs.findIndex(tab => tab.name === name);
			if (index >= 0) {
				const nextTab = tabs[index + 1] || tabs[index - 1];
				activeName = nextTab?.name || '';
			}
		}
		editableTabsValue.value = activeName;
		sheets.value = tabs.filter(tab => tab.name !== name);
		emitChange();
	}).catch(() => { ElMessage({ type: 'info', message: '删除取消' }); });

}
function tabChange(){
	const bound = excelContainerRef.value?.getBoundingClientRect();
	if (!bound){ return; }
	const {top} = bound;
	if (top>0){ return; }
	const bodyBound = document.body.getBoundingClientRect();
	const {top:bodTop} = bodyBound;
	const diff = top- bodTop-140;
	window.scrollTo({top: diff});
}
function changeTitle(oldTitle: string, newTitle: string) {
	if (oldTitle === newTitle){ return; }
	if (sheets.value.some(item => item.name === newTitle)) {
		ElMessageBox.alert('工作表名称不可重复', '通知', {
			confirmButtonText: '确定',
			type: 'warning',
		});
		return;
	}
	const target = sheets.value.find(item => item.name === oldTitle);
	if (!target) { return; }
	target.name = newTitle;
	hotTableRefs[newTitle] = hotTableRefs[oldTitle];
	delete hotTableRefs[oldTitle];
	triggerRef(sheets);
	emitChange();
}
function getSheets() { return hotTableRefs; }


onMounted(() => {
	if (props.df.read_only==='1') { return; }
	const el = document.querySelector('.tab-container .el-tabs__nav');
	Sortable.create(el, { onEnd({ newIndex, oldIndex }: { newIndex: number, oldIndex: number }) {
		if (props.df.read_only === '1') { return; }
		const [currRow] = sheets.value.splice(oldIndex, 1);
		sheets.value.splice(newIndex, 0, currRow);
		emitChange();
	} });
});
</script>

<style lang='less' scoped>
.header {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.btn-container {
	height: 40px;
	display: flex;
	justify-content: end;
	align-items: center;
}

:deep(.el-tabs__header) {
	min-height: 40px;
	position: sticky;
	bottom: 0;
	z-index: 3;
}

:deep(.el-tabs__content) {
	padding: 0;
}

</style>
