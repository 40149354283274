import {createApp} from 'vue';

import OrganizationMembers from './tianjy_organization_members/index.vue';

export default function loadOrganizationMemberPage(wrapper:HTMLElement) {
	const page = frappe.ui.make_app_page({
		parent: wrapper,
		title: __('Organizational configuration'),
		single_column: true,
	});

	const app = createApp(OrganizationMembers);
	app.mount(page.parent);
}
