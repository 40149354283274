import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElButton"], {
    type: "text",
    onClick: $setup.toInfo,
    size: "small"
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString($setup.props.params.value), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }))
}