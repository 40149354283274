<template>
	<div style="height:100%;display: flex;align-items: center;justify-content: space-between;">
		<div>
			<Icon :icon="icon"/>
			<span style="cursor: pointer;text-decoration: underline;" @click="clickLabel">
				{{ fullName }}</span>
			<span style="color:red" v-show="disable" >{{ `（${tt('Deactivate')})` }}</span>
		</div>
		<ElDropdown @command="command" v-if="params.permission" trigger="click">
			<ElIcon style="cursor: pointer;"><MoreFilled /></ElIcon>
			<template #dropdown>
				<ElDropdownMenu>
					<ElDropdownItem command="add" v-if="!disable">{{ tt('Add Child') }}</ElDropdownItem>
					<ElDropdownItem v-if="isFinal && !disable" command="disable" style="color: red;">
						{{ tt('Deactivate') }}
					</ElDropdownItem>
					<ElDropdownItem v-if="isFinal && disable" command="enable" >
						{{ tt('Enable') }}
					</ElDropdownItem>
				</ElDropdownMenu>
			</template>
		</ElDropdown>
	</div>
</template>
<script setup lang="ts">
import type {ICellRendererParams} from 'ag-grid-community';
import {MoreFilled} from '@element-plus/icons-vue';
import {ElDropdown, ElDropdownMenu, ElDropdownItem, ElIcon} from 'element-plus';
import {computed} from 'vue';

import Icon from '../../../../component/Icon/index.vue';

import {processLevel} from './helper';
import type {LevelStyle} from './type';

const props = defineProps<{
	params: ICellRendererParams & {
		addNextProcess: (parentProcessName: string) => void,
		disableProcess: (processName: string) => void,
		enableProcess: (processName: string) => void,
		viewProcessDetail: (processName: string) => void,
		permission: boolean,
		style: LevelStyle[]
	}
}>();
const tt = __;
const icon = computed(() => props.params.style.find(item =>item.level === processLevel[props.params.data?.level])?.icon || '');
const isFinal = computed(() =>props.params.node.allChildrenCount === null);
const disable = computed(() => props.params.data.disable);
const fullName = computed(() => {
	const {full_process_code, process_name} = props.params.data;
	return `${full_process_code || ''} ${process_name || ''}`;
});
// 添加下级流程 / 删除流程
function command(action: 'add' | 'disable'| 'enable') {
	switch (action) {
		case 'add':return props.params.addNextProcess(props.params.data);
		case 'disable':return	props.params.disableProcess(props.params.data);
		case 'enable':return	props.params.enableProcess(props.params.data);
	}
}
// 点击流程label查看详情
function clickLabel() {
	props.params.viewProcessDetail(props.params.data.name);
}
</script>
