<template>
	<ElTreeSelect
		v-show="treeData.length>0"
		:modelValue="modelValue"
		@change="changeStructure"
		:props="defaultProps"
		:data="treeData"
		defaultExpandAll
		:filterNodeMethod="filterNodeMethod"
		filterable
		checkStrictly />
</template>
<script lang="ts" setup>
import {watchEffect, toRefs, ref, computed, watch} from 'vue';

import {FormatDiaryStructure, OriginDiaryStructure} from '../type';
import {list2Tree} from '../../../../utils';

import {formatStructure} from './helper';
interface Props{
	project?:string,
	modelValue?:string,
}
const props = defineProps<Props>();

interface Emit{
	(e:'update:modelValue', structureId?: string):void
}
const emit = defineEmits<Emit>();
const formatStructures = ref<FormatDiaryStructure[]>([]);
const defaultProps = {
	disabled(data:FormatDiaryStructure) {
		return !data.isUnitStructure;
	},
	label: 'label',
	children: 'children',
	value: 'id',
};
watch(()=>props.project, ()=>{
	if (!props.project) {
		return;
	}
	loadDiaryStructure(props.project);
}, {immediate: true});
watchEffect(()=>{
	if (props.modelValue) {
		const hasData = formatStructures.value.some(item=>item.id === props.modelValue);
		if (!hasData) {
			emit('update:modelValue');
		}
	}
	if (props.modelValue || !formatStructures.value || formatStructures.value.length === 0) {
		return;
	}
	const data = formatStructures.value.find(item=>item.isUnitStructure);
	emit('update:modelValue', data?.id!);
});
async function loadDiaryStructure(project:string) {
	const data = await frappe.call < { message: OriginDiaryStructure[] } >({
		method: 'guigu_pm.guigu_pm_construction.page.guigu_structure_week_plan.guigu_structure_week_plan.get_diary_structure',
		args: {
			project,
		},
	});
	formatStructures.value = formatStructure(data?.message.sort((pre, next)=>pre.lft - next.lft) || []);
}
const treeData = computed(()=>list2Tree(formatStructures.value));
function changeStructure(val:string) {
	emit('update:modelValue', val);
}
function filterNodeMethod(value:string, data:(FormatDiaryStructure)) {
	return data.label.includes(value);
}
</script>
<style lang="less" scoped>
</style>
