import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.list.length)
    ? (_openBlock(), _createBlock($setup["ElDropdown"], {
        key: 0,
        onCommand: $setup.setKanban
      }, {
        dropdown: _withCtx(() => [
          _createVNode($setup["ElDropdownMenu"], null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, (c) => {
                return (_openBlock(), _createBlock($setup["ElDropdownItem"], {
                  key: c.name,
                  command: c.name,
                  class: _normalizeClass({ ['current']: c.name === $setup.kanbanId })
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(c.title), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["command", "class"]))
              }), 128 /* KEYED_FRAGMENT */)),
              _createVNode($setup["ElDropdownItem"], {
                command: "",
                divided: "",
                icon: $setup.Plus
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.tt('Create')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["icon"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        default: _withCtx(() => [
          _createVNode($setup["ElButton"], {
            circle: "",
            icon: $setup.Grid
          }, null, 8 /* PROPS */, ["icon"])
        ]),
        _: 1 /* STABLE */
      }))
    : _createCommentVNode("v-if", true)
}