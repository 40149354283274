import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AgGridVue = _resolveComponent("AgGridVue")

  return (_openBlock(), _createBlock(_component_AgGridVue, {
    class: "ag-theme-guigu work-assessment-sumary-container_table",
    rowData: _ctx.summaryTableData,
    columnDefs: _ctx.columnDefs,
    localeText: _ctx.zhCN,
    onGridReady: _ctx.onGridReady,
    animateRows: "true",
    getRowId: _ctx.getRowId
  }, null, 8 /* PROPS */, ["rowData", "columnDefs", "localeText", "onGridReady", "getRowId"]))
}