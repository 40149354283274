<template>
	<span v-if="tags.length" v-for="tag in tags" class="tag-pill" :title="tag">
		{{ tag }}
	</span>
	<span v-else class="tags-empty">-</span>
</template>
<script lang="ts" setup>
import { computed } from 'vue';


const props = defineProps<{
	value: any;
	data: Record<string, any>;
	field: GlobalView.FieldRenderOptions;
	meta?: locals.DocType;
	maxLine?: number;
	enableLink?: boolean;
	noData?: boolean;
	inline?: boolean;
	format?: string;
	showTitle?: boolean;
}>();
const emit = defineEmits<{
	(event: 'filter', field: string, operator: string, value: any): void;
}>();
const tags = computed(() => {
	const userTags = (props.data._user_tags || '').split(',') as string[];

	return userTags.filter(Boolean);
	// .map(tag => {
	// 	const [bgColor, color] = frappe.get_palette(tag);
	// 	return {
	// 		tag,
	// 		backgroundColor: `var(${bgColor})`,
	// 		color: `var(${color})`,
	// 	};
	// });
});
</script>
