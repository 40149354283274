import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-867e1c04"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tool-container" }
const _hoisted_2 = { class: "tool" }
const _hoisted_3 = { class: "project-info" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h5", null, _toDisplayString($setup.tt('5、 Technical Management')), 1 /* TEXT */),
      _createElementVNode("p", _hoisted_3, _toDisplayString($setup.tt(
						'This month, Southwest Company submitted {} construction organization designs and micro engineering plans, with {} actual depth plans. The July plans are currently being prepared or reviewed.'
					)), 1 /* TEXT */),
      _createElementVNode("p", null, _toDisplayString($setup.tt(
						'（1） The approval and implementation status of this month construction plan, as well as the plans for minor, major, and extremely dangerous situations, are shown in the table below:'
					)), 1 /* TEXT */),
      _createElementVNode("p", null, _toDisplayString($setup.tt('（2） The approval plan for next month is shown in the table below:')), 1 /* TEXT */)
    ])
  ]))
}