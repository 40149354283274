<template>
	<AgGridVue
		v-if="!loading"
		class="ag-theme-guigu work-table"
		:rowData="workList"
		:defaultColDef="defaultColDef"
		animateRows="true"
		@grid-ready="onGridReady"
		:suppressDragLeaveHidesColumns="smallMeta"
		:localeText="zhCN"
		suppressRowTransform
		@cellValueChanged="cellValueChanged"
		useValueFormatterForExport
		:getRowId="getRowId">
	</AgGridVue>
</template>
<script setup lang="ts">
import {ref, computed, watch, onMounted} from 'vue';
import {AgGridVue} from 'ag-grid-vue3';
import type {CellValueChangedEvent} from 'ag-grid-community';
import moment from 'moment';

import type {BusinessType, BusinessTypeContent, FinishingWork, LastYearUnfinishedWork} from '../type';
import {useMetaQuery} from '../../../../../../../../guigu_pm/guigu_pm/public/js/components/page/useMetaQuery';
import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';

import {getColumns} from './helper';

interface Props{
	workList:FinishingWork[]
	businessTypes:BusinessType[]
	loading:boolean
}
const props = defineProps<Props>();

const smallMeta = useMetaQuery();
const gridApi = ref<any>(null);
const editable = ref<boolean>(false);
const emit = defineEmits<{
	(event: 'beforeUpdate'): void;
	(event: 'afterUpdate', newData:LastYearUnfinishedWork): void;
	(event: 'refresh'):void
}>();
const onGridReady = (params: any) => {
	gridApi.value = params.api;
};

onMounted(async() => {
	await new Promise(r => frappe.model.with_doctype('Guigu Last Year Unfinished Work', r));
	editable.value = frappe.perm.has_perm('Guigu Last Year Unfinished Work', 2, 'write');
});

watch([
	smallMeta, gridApi, ()=>props.businessTypes, editable,
], ([newSmallMeta, api])=>{
	api?.setColumnDefs(getColumns(
		newSmallMeta, props.businessTypes,
		()=>emit('refresh'),
		editable.value,
	));
});
const defaultColDef = {
	sortable: true,
	filter: true,
	resizable: true,
	suppressMovable: true,
	cellDataType: false,
};

function getRowId(params: any) {
	return params.data.name;
}
async function cellValueChanged(e:CellValueChangedEvent<FinishingWork>) {
	const {colDef, data, value} = e;
	const businessTypeName = colDef?.field?.split('.')[0] || '';
	const cellData:BusinessTypeContent = data[businessTypeName];
	emit('beforeUpdate');
	let newData:LastYearUnfinishedWork|undefined;
	if (cellData.name) {
		// update
		newData = await updateLastYearWork(cellData, data, value);
	} else {
		// create
		newData = await createLastYearWork(businessTypeName, data, value);
	}
	emit('afterUpdate', newData);
	frappe.show_alert({
		message: __('Saved Successfully'),
		indicator: 'green',
	});
}
async function createLastYearWork(businessTypeName:string, data:FinishingWork, value:string) {
	const year = moment({year: data.date}).startOf('year').format('YYYY-MM-DD');
	const newData = {
		doctype: 'Guigu Last Year Unfinished Work',
		project: data.project_name,
		year,
		type: businessTypeName,
		content: '',
		annual_target: '',
	};
	if (data.type === 'future') {
		newData.content = value;
	} else {
		newData.annual_target = value;
	}
	const res:LastYearUnfinishedWork = await frappe.db.insert(newData);
	return res;
}
async function updateLastYearWork(cellData:BusinessTypeContent, data:FinishingWork, value:string) {
	let field = 'content';
	if (data.type === 'year') {
		field = 'annual_target';
	}
	const {message: newData}:{message:LastYearUnfinishedWork} = await frappe.db.set_value('Guigu Last Year Unfinished Work', cellData.name, field, value || '');
	return newData;
}
</script>
  <style lang="less" scoped>
  .work-table {
	width: 100%;
	height: 100%;
	:deep(.rowSpanCell) {
		display: flex;
		align-items: center;
		background: #fff;
		border-bottom: 1px solid #d9dddf;
	}

	:deep(.ag-header-group-cell-label .ag-header-group-text) {
		width: 100%;
		text-align: center;
	}
  }
  </style>
