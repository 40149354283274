import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bfe7ad02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Page"], null, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.tt('Project assessment scoring page')), 1 /* TEXT */)
    ]),
    tools: _withCtx(() => [
      _createVNode($setup["ToolBar"], {
        project: $setup.project,
        "onUpdate:project": _cache[0] || (_cache[0] = $event => (($setup.project) = $event)),
        monthDate: $setup.monthDate,
        "onUpdate:monthDate": _cache[1] || (_cache[1] = $event => (($setup.monthDate) = $event)),
        yearDate: $setup.yearDate,
        "onUpdate:yearDate": _cache[2] || (_cache[2] = $event => (($setup.yearDate) = $event)),
        pageType: $setup.pageType,
        "onUpdate:pageType": _cache[3] || (_cache[3] = $event => (($setup.pageType) = $event)),
        refreshValue: $setup.refreshValue,
        "onUpdate:refreshValue": _cache[4] || (_cache[4] = $event => (($setup.refreshValue) = $event)),
        loading: $setup.loading,
        "onUpdate:loading": _cache[5] || (_cache[5] = $event => (($setup.loading) = $event)),
        detailType: $setup.detailType,
        "onUpdate:detailType": _cache[6] || (_cache[6] = $event => (($setup.detailType) = $event)),
        detailGridApi: $setup.detailGridApi
      }, null, 8 /* PROPS */, ["project", "monthDate", "yearDate", "pageType", "refreshValue", "loading", "detailType", "detailGridApi"])
    ]),
    sider: _withCtx(() => [
      _createVNode($setup["OrganizationTree"], {
        organizations: $setup.organizations,
        organizationLoading: $setup.organizationLoading,
        organization: $setup.organization,
        "onUpdate:organization": _cache[7] || (_cache[7] = $event => (($setup.organization) = $event))
      }, null, 8 /* PROPS */, ["organizations", "organizationLoading", "organization"])
    ]),
    default: _withCtx(() => [
      _createVNode($setup["Skeleton"], {
        loading: $setup.loading,
        ref: "skeleton",
        layout: $setup.detailType,
        hasDetail: $setup.recordValue
      }, {
        detail: _withCtx(() => [
          ($setup.recordValue)
            ? (_openBlock(), _createBlock($setup["FormDetail"], {
                key: 0,
                meta: $setup.docMeta,
                options: "{}",
                value: $setup.recordValue,
                "onUpdate:value": _cache[8] || (_cache[8] = $event => (($setup.recordValue) = $event))
              }, null, 8 /* PROPS */, ["meta", "value"]))
            : _createCommentVNode("v-if", true)
        ]),
        default: _withCtx(() => [
          ($setup.pageType === 'detail')
            ? (_openBlock(), _createBlock($setup["DetailTable"], {
                key: 0,
                loading: $setup.loading,
                project: $setup.project,
                detailTableData: $setup.detailTableData,
                layout: $setup.detailType,
                onUpdateRecordValue: $setup.updateRecordValue,
                onOnDetailGridReady: $setup.onDetailGridReady
              }, null, 8 /* PROPS */, ["loading", "project", "detailTableData", "layout"]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["loading", "layout", "hasDetail"]),
      ($setup.pageType === 'summary')
        ? (_openBlock(), _createBlock($setup["SummaryTable"], {
            key: 0,
            year: $setup.yearDate,
            loading: $setup.loading,
            project: $setup.project,
            summaryTableData: $setup.summaryTableData
          }, null, 8 /* PROPS */, ["year", "loading", "project", "summaryTableData"]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }))
}