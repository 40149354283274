<template>
	<ElConfigProvider size="small" :locale="zhCn">
		<Page>
			<template #title>
				<h3 class="title">App列表</h3>
			</template>
			<template #sider>
				<AppTree @setCurrent="setCurrent"></AppTree>
			</template>
			<ElTabs v-model="activeName" class="tabs">
				<ElTabPane class="tab-container" label="详情" name="Detail">
					<InfoDetail
						v-if="current"
						:name="current?.name"
						:doctype="current?.type==='App'?'Installed Application':'Module Def'"
						:data="current"
					></InfoDetail>
				</ElTabPane>
				<ElTabPane class="tab-container" label="单据" name="Doctype">
					<DoctypeTable
						:current="current"
					></DoctypeTable>
				</ElTabPane>
				<ElTabPane class="tab-container" label="页面" name="Page">
					<PageTable :current="current"></PageTable>
				</ElTabPane>
				<ElTabPane class="tab-container" label="报表" name="Report">
					<ReportTable :current="current"></ReportTable>
				</ElTabPane>
			</ElTabs>
		</Page>
	</ElConfigProvider>
</template>

<script setup lang='ts'>
import {ref} from 'vue';
import {ElConfigProvider, ElTabs, ElTabPane} from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn.js';

import Page from '../../../../components/page/index.vue';

import AppTree from './AppTree.vue';
import {AppInfo, ModuleInfo} from './type';
import InfoDetail from './InfoDetail.vue';
import DoctypeTable from './DoctypeTable.vue';
import PageTable from './PageTable.vue';
import ReportTable from './ReportTable.vue';
const activeName = ref<string>('Detail');
const current = ref<AppInfo|ModuleInfo>();
function setCurrent(data:AppInfo|ModuleInfo) {
	current.value = data;
}
</script>

<style lang='less' scoped>
.tabs{
	height: 100%;
    display: flex;
    flex-direction: column;
}
.tab-container{
	height: 100%;
}
</style>
