<template>
	<Page>
		<template #title>
			<h3 class="title">{{ title }}{{ tt('Plans') }}</h3>
		</template>
		<template #tools>
			<ToolBar
				v-model:project="project"
				v-model:period="period"
				v-model:structure="structure"
				:isCreateDisabled="isCreateDisabled"
				:type="mainType"
				@createTempProgress="createTempProgress">
			</ToolBar>
		</template>
		<template #default>
			<PlanTable
				v-loading="structureLoading || progressPlanLoading"
				:structureDescendants="structureDescendants"
				:project="project"
				:period="period"
				:structure="structure"
				:mainType="mainType"
				:subType="subType"
				@onRemove="onRemove"
				@planChange="planChange">
			</PlanTable>
		</template>
	</Page>
</template>
<script lang="ts" setup>
import {ref, watch, watchEffect, reactive, computed} from 'vue';
import {RowGroupOpenedEvent} from 'ag-grid-community';
import {ElMessage} from 'element-plus';
import cloneDeep from 'lodash/cloneDeep';

import Page from '../../../../components/page/index.vue';

import ToolBar from './ToolBar.vue';
import {NewProgress, NewStructureProgress, Period, ProgressPlan, StructureItem, StructureItemFormat, TempPlan} from './type';
import PlanTable from './PlanTable.vue';
const tt = __;
interface Props {
	mainType: 'Week' | 'Month',
	subType: 'Week' | 'Month',
}
const props = defineProps<Props>();
const project = ref<string>('');
const period = ref<Period>();
const structure = ref<string>('');
const structureDescendants = ref<StructureItemFormat[]>([]);
const structureLoading = ref<boolean>(false);
const progressPlanLoading = ref<boolean>(false);
const title = computed(()=>{
	let title = '';
	switch (props.mainType) {
		case 'Week':
			title = tt('Week'); break;
		case 'Month':
			title = tt('Month'); break;
	}
	return title;
});
let id = 0;
watch(project, () => {
	structureDescendants.value = [];
});
watch([structure, period], async () => {
	if (!structure.value || !period.value) {
		return;
	}
	structureLoading.value = true;
	const data = await frappe.call<{ message: { descendants: StructureItem[], template_plan: TempPlan[] } }>({
		method: 'guigu_pm.guigu_pm_construction.page.guigu_structure_week_plan.guigu_structure_week_plan.get_structure_descendants',
		args: {
			structure: structure.value,
			period: period.value.name,
			main_type: props.mainType,
			sub_type: props.subType,
		},
	});
	const structureObj = await frappe.db.get_doc('Guigu Construction Structure', structure.value);
	const descendants: StructureItemFormat[] = [];
	const temPlans: StructureItemFormat[] = (data?.message.template_plan || []).map(item => {
		let until_unfinished: string | number = item.design_quantity;
		if (item.design_quantity && item?.until_finished) {
			until_unfinished = (item.design_quantity - item.until_finished).toFixed(2);
		}
		return {
			label: item.label,
			designQuantity: item.design_quantity,
			id: `tem_plan_${item.name}`,
			unit: item.unit,
			plan_name: item.name,
			quantity: item.quantity,
			plan_remark: item.plan_remark,
			summary: item.summary,
			until_unfinished,
			type: 'temp',
		};
	});
	for (const item of data?.message.descendants) {
		if (item.progress_item) {
			for (const each of item.progress_item) {
				let until_unfinished: string | number = each.design_quantity;
				if (each.design_quantity && each?.until_finished) {
					until_unfinished = (each.design_quantity - each.until_finished).toFixed(2);
				}
				const labelArr = item.structure_ancestry_label.split('/');
				// eslint-disable-next-line max-nested-callbacks
				const index = labelArr.findIndex(eachLabel => eachLabel === structureObj.structure_name);
				let label = '';
				if (index === -1) {
					label = item.structure_ancestry_label;
				} else {
					label = labelArr.slice(index + 1).join('/');
				}

				descendants.push({
					position: each.position,
					structureProgressId: each.structure_progress_name,
					progressItemId: each.progress_item_name,
					label: `${label}${label ? '/' : ''}${each.progress_item_label}`,
					parent: `structure_${item.name}`,
					designQuantity: each.design_quantity,
					id: `structure_progress_${each.structure_progress_name}`,
					unit: each.unit,
					plan_name: each.plan_name,
					quantity: each.quantity,
					plan_remark: each.plan_remark,
					summary: each.summary,
					until_finished: each.until_finished,
					last_period_plan: each.last_period_plan,
					last_period_finished: each.last_period_finished,
					until_unfinished,
					type: 'progress',
				});
			}
		}
	}
	structureDescendants.value = cloneDeep(descendants.concat(temPlans));
	structureLoading.value = false;
});

function planChange(newData:
	{
		name: string,
		quantity?: number,
		summary?: string,
		plan_remark?: string,
		guigu_construction_structure_progress: string
		label: string
		design_quantity: number
		unit: string
	}, oldID: string) {
	const structure = structureDescendants.value.find(item => item.id === oldID);
	const index = structureDescendants.value.findIndex(item => item.id === oldID);
	if (!structure) {
		return;
	}
	if (structure.type === 'temp') {
		const newStructure = {
			id: `tem_plan_${newData.name}`,
			label: newData.label,
			type: 'temp' as const,
			plan_name: newData.name,
			quantity: newData.quantity,
			plan_remark: newData.plan_remark,
			summary: newData.summary,
			designQuantity: newData.design_quantity,
			unit: newData.unit,
		};
		structureDescendants.value.splice(index, 1, newStructure);
	} else {
		structure.plan_name = newData.name;
		structure.quantity = newData.quantity;
		structure.plan_remark = newData.plan_remark;
		structure.summary = newData.summary;
	}
}
const isCreateDisabled = computed(() => structureDescendants.value.some(i => i.id.startsWith('new')));
function createTempProgress() {
	if (isCreateDisabled.value) {
		return;
	}
	structureDescendants.value.push({
		id: `new_${++id}`,
		label: __('Please enter the construction content'),
		type: 'temp',
	});
}
function onRemove(data: StructureItemFormat) {
	structureDescendants.value = structureDescendants.value.filter(item => item.id !== data.id);
	ElMessage({
		message: __('Delete Successful'),
		type: 'success',
	});
}
</script>
<style lang="less" scoped>
.container {
	height: calc(100vh - 60px);
	display: flex;
	flex-direction: column;
}
.title{
	margin-bottom: 0px;
}
</style>
