<template>
	<ElFormItem>
		<ElTable :data="fields" style="width: 100%">
			<ElTableColumn type="index" :index="1" />
			<ElTableColumn type="expand" #="{ row, $index }">
				<ElRow :gutter="8">
					<ElCol :span="4"></ElCol>
					<ElCol :span="5">{{ tt('In Progress') }}</ElCol>
					<ElCol :span="5">{{ tt('In progress and overdue') }}</ElCol>
					<ElCol :span="5">{{ tt('Completed') }}</ElCol>
					<ElCol :span="5">{{ tt('Completed but overdue') }}</ElCol>
				</ElRow>
				<ElRow :gutter="8">
					<ElCol :span="4">{{ tt('Color') }}</ElCol>
					<ElCol :span="5">
						<ElColorPicker
							:modelValue="row.processing_color || ''"
							@update:modelValue="set(
								$index,
								'processing_color',
								$event || null,
							)" />
					</ElCol>
					<ElCol :span="5">
						<ElColorPicker
							:modelValue="row.processing_overdue_color || ''"
							@update:modelValue="set(
								$index,
								'processing_overdue_color',
								$event || null,
							)" />
					</ElCol>
					<ElCol :span="5">
						<ElColorPicker :modelValue="row.complete_color || ''"
							@update:modelValue="set(
								$index,
								'complete_color',
								$event || null,
							)" />
					</ElCol>
					<ElCol :span="5">
						<ElColorPicker
							:modelValue="row.complete_overdue_color || ''"
							@update:modelValue="set(
								$index,
								'complete_overdue_color',
								$event || null,
							)" />
					</ElCol>
				</ElRow>
				<ElRow :gutter="8">
					<ElCol :span="4">{{ tt('Border Color') }}</ElCol>
					<ElCol :span="5">
						<ElColorPicker
							:modelValue="row.processing_border_color || ''"
							@update:modelValue="set(
								$index,
								'processing_border_color',
								$event || null,
							)" />
					</ElCol>
					<ElCol :span="5">
						<ElColorPicker
							:modelValue="row.processing_overdue_border_color || ''"
							@update:modelValue="set(
								$index,
								'processing_overdue_border_color',
								$event || null,
							)" />
					</ElCol>
					<ElCol :span="5">
						<ElColorPicker
							:modelValue="row.complete_border_color || ''"
							@update:modelValue="set(
								$index,
								'complete_border_color',
								$event || null,
							)" />
					</ElCol>
					<ElCol :span="5">
						<ElColorPicker
							:modelValue="row.complete_overdue_border_color || ''"
							@update:modelValue="set(
								$index,
								'complete_overdue_border_color',
								$event || null,
							)" />
					</ElCol>
				</ElRow>


				<ElRow :gutter="8">
					<ElCol :span="8">{{ tt('Display control field') }}</ElCol>
					<ElCol :span="16">{{ tt('Displays control field values') }}</ElCol>
				</ElRow>
				<ElRow :gutter="8">
					<ElCol :span="8">
						<ElSelect :modelValue="row.filter_field" filterable defaultFirstOption
							@update:modelValue="set($index, 'filter_field', $event)" clearable>
							<ElOption v-for="field in filterFields" :key="field.value"
								:value="field.value" :label="field.label" />
						</ElSelect>
					</ElCol>
					<ElCol :span="16">
						<ElSelect
							:modelValue="getList(row.filter_value)" filterable defaultFirstOption
							@update:modelValue="set($index, 'filter_value', setList($event))"
							multiple allowCreate :reserveKeyword="false" style="width: 100%">
							<ElOption label="手动输入" value="" disabled />
						</ElSelect>
					</ElCol>
				</ElRow>
			</ElTableColumn>


			<ElTableColumn :label="tt('Label')"
				#="{ row, $index }">
				<ElInput :modelValue="row.label || ''"
					@update:modelValue="set($index, 'label', $event)" />
			</ElTableColumn>

			<ElTableColumn :label="tt('Start Date')" #="{ row, $index }">
				<ElSelect :modelValue="row.start" filterable defaultFirstOption
					@update:modelValue="set($index, 'start', $event)">
					<ElOption v-for="field in fieldOptions" :key="field.value"
						:value="field.value" :label="field.label" />
				</ElSelect>
			</ElTableColumn>
			<ElTableColumn :label="tt('End Date')" #="{ row, $index }">
				<ElSelect :modelValue="row.end" filterable defaultFirstOption
					@update:modelValue="set($index, 'end', $event)">
					<ElOption v-for="field in fieldOptions" :key="field.value"
						:value="field.value" :label="field.label" />
				</ElSelect>
			</ElTableColumn>

			<ElTableColumn :label="tt('Position')"
				#="{ row, $index }">
				<ElInput type="number" :modelValue="row.position || ''"
					@update:modelValue="setWidth($index, 'position', $event || 0)" />
			</ElTableColumn>
			<ElTableColumn :label="tt('Width')"
				#="{ row, $index }">
				<ElInput type="number" :modelValue="row.width || ''"
					@update:modelValue="setWidth($index, 'width', $event || 0)" />
			</ElTableColumn>

			<ElTableColumn :label="tt('Color')"
				#="{ row, $index }">
				<ElColorPicker :modelValue="row.color || ''"
					@update:modelValue="set($index, 'color', $event || null)" />
			</ElTableColumn>

			<ElTableColumn :label="tt('Border Color')"
				#="{ row, $index }">
				<ElColorPicker :modelValue="row.border_color || ''"
					@update:modelValue="set($index, 'border_color', $event || null)" />
			</ElTableColumn>


			<ElTableColumn :label="tt('Key')"
				#="{ row, $index }">
				<ElInput :modelValue="row.key || ''"
					@update:modelValue="set($index, 'key', $event)" />
			</ElTableColumn>

			<ElTableColumn :label="tt('Gantt Reference')"
				#="{ row, $index }">
				<ElInput :modelValue="row.reference || ''"
					@update:modelValue="set($index, 'reference', $event)" />
			</ElTableColumn>


			<ElTableColumn :label="tt('Operation')" #="{ $index }">
				<ElButtonGroup>
					<ElButton @click="moveTo($index)" text :icon="Sort"
						:title="tt('Move')" />
					<ElButton type="danger" @click="remove($index)" text
						:icon="Delete" :title="tt('Delete')" />
				</ElButtonGroup>
			</ElTableColumn>
		</ElTable>
		<ElButton @click="add">{{ tt('Add') }}</ElButton>

	</ElFormItem>
</template>
<script setup lang="ts">
import {computed, ref, h} from 'vue';
import {
	ElTable, ElTableColumn, ElForm,
	ElSelect, ElRow, ElCol, ElInput, ElColorPicker,
	ElFormItem, ElButtonGroup, ElButton, ElMessageBox, ElOption,
} from 'element-plus';
import {Delete, Sort} from '@element-plus/icons-vue';

const props = defineProps<{
	meta: locals.DocType;
	modelValue: any;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: GlobalView.View): void;
}>();
const view = computed({
	get: () => props.modelValue,
	set: v => emit('update:modelValue', v),
});
const tt = __;

const fields = computed<any[]>({
	get: () => view.value.lines || [],
	set: v => {
		view.value = {...view.value, lines: v};
	},
});
function set(index: number, key: string, value: any) {
	const list = [...fields.value];
	const item: any = list[index];
	if (!item) {
		return;
	}
	item[key] = value;
	fields.value = list;
}
function setWidth(index: number, key: any, value: any) {
	const list = [...fields.value];
	const item: any = list[index];
	if (!item) {
		return;
	}
	item[key] = Math.max(0, parseInt(value) || 0);
	fields.value = list;
}

function add() {
	fields.value = [...fields.value, {}];
}
function remove(index: number) {
	const list = [...fields.value];
	if (!list.splice(index, 1).length) {
		return;
	}
	fields.value = list;
}
function move(from: number, to: number) {
	if (from === to) {
		return;
	}
	const list = [...fields.value];
	const item: any = list[from];
	if (!item) {
		return;
	}
	list.splice(from, 1);
	list.splice(to, 0, item);
	fields.value = list;
}

const fieldOptions = computed(() => {
	const fields = props.meta.fields || [];
	return fields
		.filter(d => ['Date', 'Datetime'].includes(d.fieldtype))
		.map(d => ({value: d.fieldname, label: `${__(d.label)} (${d.fieldname})`}));
});
function moveTo(index: number) {
	const target = ref<number>(index);
	const {start, end} = fields.value[index] || {};
	const startLabel = fieldOptions.value.find(f => f.value === start)?.label || start || '';
	const endLabel = fieldOptions.value.find(f => f.value === end)?.label || end || '';
	ElMessageBox({
		title: `将 ${startLabel}-${endLabel} #${index + 1} 移动到...`,
		message: (): any => {
			const labels = new Map(fieldOptions.value.map(v => [v.value, v.label]));
			return h(ElSelect, {
				filterable: true,
				defaultFirstOption: true,
				modelValue: target.value,
				'onUpdate:modelValue': (val: number) => {
					target.value = val;
				},
			}, fields.value.map(({start, end}, i) => h(ElOption, {
				value: i,
				label: i === index
					? '<<<位置不变>>>'
					: [
						`${labels.get(start) || start}`,
						`-`,
						`${labels.get(end) || end}`,
						` #${i + 1} `,
						i < index ? '之前' : '之后',
					].join(''),
			})));
		},
	}).then(() => {
		move(index, target.value);
	});
}

function getList(v?: string | string[]) {
	if (typeof v === 'string') {
		return v.split('\n').filter(Boolean);
	}
	if (Array.isArray(v)) {
		return v.filter(Boolean);
	}
	return [];
}
function setList(v?: string | string[]) {
	if (typeof v === 'string') {
		return v.split('\n').filter(Boolean).join('\n');
	}
	if (Array.isArray(v)) {
		return v.filter(Boolean).join('\n');
	}
	return '';
}
const filterFields = computed(() => {
	const fields = props.meta.fields || [];
	return fields
		.filter(d => ['Select'].includes(d.fieldtype))
		.map(d => ({value: d.fieldname, label: `${__(d.label)} (${d.fieldname})`}));
});
</script>
