<template>
	<ElTree
		class="directory-tree"
		style="max-width: 250px"
		:data="directory"
		:props="defaultProps"
		defaultExpandAll
		highlightCurrent
		:expandOnClickNode="false"
		@node-click="handleNodeClick"
	/>
</template>

<script setup lang='ts'>
import { ref, defineProps, defineEmits, onMounted, watch, computed, provide } from 'vue';

import { ElButton, ElTooltip, vLoading, ElTree } from 'element-plus';

import { buildTree } from '../getDirectoryTree';
import type { ReportConfig } from '../../type';

interface Props {
	content:ReportConfig
}
const props = defineProps<Props>();
interface Emit {

}
const emit = defineEmits<Emit>();

const defaultProps = {
	children: 'children',
	label: 'label',
};

const directory = computed(()=>{
	const headings = props.content?.content?.filter(item=>item.type==='heading')?.map(item=>({label:item.content?.[0]?.text, level:item.attrs.level}))||[];
	return buildTree(headings);
});
function handleNodeClick(data:{level:number, label:string}){
	const node = document.evaluate(`//h${data.level}[text()='${data.label}']`, document, null, XPathResult.ANY_TYPE, null ).iterateNext();
	node?.scrollIntoView(true);
}

</script>

<style lang='less' scoped>
.directory-tree :deep(.el-tree-node__label){
	overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
