<template>
  <AgGridVue
    class="ag-theme-guigu work-assessment-container_table"
    :rowData="detailTableData"
    :columnDefs="columnDefs"
    :localeText="zhCN"
    :autoGroupColumnDef="autoGroupColumnDef"
    v-loading="loading"
    @grid-ready="onGridReady"
    :getRowId="getRowId"
    rowGroupPanelShow="always"
    @cellValueChanged="cellValueChanged"
    :suppressDragLeaveHidesColumns="smallMeta"
    :leftFixed="5">
  </AgGridVue>
</template>

<script lang="ts">
  import { ref, computed, defineComponent, PropType, getCurrentInstance, watch } from 'vue';
  import { AgGridVue } from 'ag-grid-vue3';
  import { GridReadyEvent, CellValueChangedEvent, GridApi } from 'ag-grid-community';
  import { ElMessage } from 'element-plus';

  import FloatComponent from '../../../../../../../../guigu_pm/guigu_pm/public/js/AgGrid/components/Float.vue';
  import FloatEditor from '../../../../../../../../guigu_pm/guigu_pm/public/js/AgGrid/components/CommanFloatEditor.vue';
  import { DetailTableData } from '../../type';
  import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';
  import { useMetaQuery } from '../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';

  import AgGridWorkAssessmentSubject from './AgGridWorkAssessmentSubject.vue';
  import AgGridWorkAssessmentStatus from './AgGridWorkAssessmentStatus.vue';
  import AgGridWorkAssessmentPriority from './AgGridWorkAssessmentPriority.vue';
  import { getWorkAssessmentDetailColumns } from './tableColumns';

  export default defineComponent({
    components: {
      FloatComponent,
      AgGridVue,
      FloatEditor,
      AgGridWorkAssessmentSubject,
      AgGridWorkAssessmentStatus,
      AgGridWorkAssessmentPriority,
    },
    props: {
      company: String,
      detailTableData: Object as PropType<DetailTableData[]>,
      loading: Boolean,
      layout: String,
    },
    emits: {
      onDetailGridReady(params: GridApi) {},
      updateRecordValue(value: any) {},
    },
    setup(props, context) {
      const { appContext } = getCurrentInstance() || {};
      const loading = ref<boolean>(false);
      const gridApi = ref(null);
      const smallMeta = useMetaQuery();

      const onGridReady = (params: GridReadyEvent) => {
        gridApi.value = params.api;
        context.emit('onDetailGridReady', params.api);
      };
      const visible = ref(false);

      const recordValue = ref();
      async function updateRecordValue(value: any) {
        recordValue.value = value;
      }
      watch(
        () => recordValue.value,
        () => {
          context.emit('updateRecordValue', recordValue.value);
        }
      );

      const columnDefs = computed(() => getWorkAssessmentDetailColumns(updateRecordValue, props.layout));
      watch(
        () => props.loading,
        () => {
          loading.value = props.loading;
        }
      );

      const autoGroupColumnDef = {
        headerName: __('Grouped'),
        width: 150,
        minWidth: 150,
        editable: false,
        filter: true,
        resizable: true,
        pinned: 'left',
        sortable: true,
      };
      async function cellValueChanged(event: CellValueChangedEvent) {
        const { data, value, colDef } = event;
        try {
          const result = await frappe.call({
            method: 'guigu_pc.guigu_sub_company_work.page.sub_company_work_assessment.sub_company_work_assessment.update_work_assessment',
            args: {
              name: data.assessment_name,
              field: colDef.field,
              value,
            },
          });
          ElMessage({
            message: __('Updated successfully'),
            type: 'success',
          });
        } catch {
          ElMessage({
            message: __('Update failed'),
            type: 'success',
          });
        }
      }
      function getRowId(params: any) {
        return params.data.id;
      }

      return {
        loading,
        cellValueChanged,
        onGridReady,
        columnDefs,
        autoGroupColumnDef,
        getRowId,
        visible,
        zhCN,
        confirm,
        smallMeta,
      };
    },
  });
</script>

<style lang="less" scoped>
  .work-assessment-container {
    flex: 1;
  }

  .work-assessment-container_table {
    height: 100%;
  }
</style>
