<template>
	<Page>
		<template v-slot:title>
			<h3 class="title">分公司日志考勤页面</h3>
		</template>
		<template v-slot:tools>
			<ToolBar
				v-model:company="company"
				v-model:monthDate="monthDate"
				v-model:yearDate="yearDate"
				v-model:pageType="pageType"
				v-model:refreshValue="refreshValue"
				:detailGridApi="detailGridApi">
			</ToolBar>
		</template>
		<SummaryTable
			:pageType="pageType"
			:monthDate="monthDate"
			:yearDate="yearDate"
			:company="company"
			:summaryTableData="summaryTableData"
			@onDetailGridReady="onDetailGridReady">
		</SummaryTable>
	</Page>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { Column, GridApi } from 'ag-grid-community';

import Page from '../../../../../../../guigu_pm/guigu_pm/public/js/components/page/index.vue';
import { SummaryMessage, SummaryTableData } from '../type';

import ToolBar from './components/ToolBar.vue';
import SummaryTable from './components/SummaryTable.vue';
import { daily_record_data_processing } from './components/helper';


const company = ref<string>('');
const monthDate = ref<string>();
const yearDate = ref<string>();
const pageType = ref<'month' | 'year'>('month');
const refreshValue = ref<number>(0);

const summaryTableData = ref<SummaryTableData[]>([]);
const detailGridApi = ref<GridApi>();


function onDetailGridReady(params: any) {
	detailGridApi.value = params;
}


watch([company, pageType, yearDate, monthDate, refreshValue], async () => {

	if (!yearDate.value || !company.value) { return; }
	summaryTableData.value = [];
	const data = await frappe.call<{ message: SummaryMessage }>({
		method: 'guigu_pc.guigu_pc_daily_record.page.company_daily_record_assessment.company_daily_record_assessment.get_organization_dairy',
		args: {
			'sub_company': company.value,
			'type': pageType.value,
			'date': pageType.value === 'month' ? monthDate.value : yearDate.value,
		},
	});
	const result = daily_record_data_processing(
		data?.message?.daily_record || [],
		pageType.value,
	);
	summaryTableData.value = result;

});


</script>
<style lang="css" scoped>
.container {
	height: calc(100vh - 60px);
	display: flex;
	flex-direction: column;
}

.title {
	margin-bottom: 0;
}
</style>
