import type { ColumnCell } from '../types';
import binarySearch from '../utils/binarySearch';
import type { Cell, RowDataProxy } from '../types/Row';

import replaceShow from './replaceShow';
import createCell from './createCell';
import showCell from './showCell';
import hideCell from './hideCell';

export default function showRow(
	r: RowDataProxy,
	hoverId: string | number | undefined,
	selectedId: string | number | undefined,
	checkedSet: Set<string | number>,
	startFixed: number,
	visible: number[],
	rowHeight: number,
	columns: ColumnCell[],
	cStart: number,
	cEnd: number,
) {
	// 计算出在显示的项目当中的编号
	const showIndex = binarySearch(r.index, visible, 0, visible.length - 1);
	const { el, row: { id }, cells, api } = r;
	if (hoverId === id) {
		el.classList.add('nyloong-table-row-hover');
	} else {
		el.classList.remove('nyloong-table-row-hover');
	}
	if (selectedId === id) {
		el.classList.add('nyloong-table-row-selected');
	} else {
		el.classList.remove('nyloong-table-row-selected');
	}
	if (checkedSet.has(id)) {
		el.classList.add('nyloong-table-row-checked');
	} else {
		el.classList.remove('nyloong-table-row-checked');
	}
	el.style.insetBlockStart = `${rowHeight * showIndex}px`;
	el.style.blockSize = `${rowHeight}px`;

	const needCells = new Set<Cell>();
	const startFixedMax = Math.min(startFixed, cStart);
	function add(i: number) {
		const column = columns[i];
		if (column.hidden) { return; }
		const index = column.start;
		let cell = cells.get(column);

		if (cell) {
			cell.index = index;
		} else {
			let destroyed = false;
			function destroy() {
				if (destroyed) { return; }
				destroyed = true;
				if (cells.get(column) !== cell) { return; }
				cells.delete(column);
			}
			cell = createCell(api, r.row.value, column, index, destroy);
			if (!destroyed) {
				cells.set(column, cell);
			}
		}
		needCells.add(cell);
		if (i < startFixed) {
			cell.el.classList.add('nyloong-table-cell-fixed');
		} else {
			cell.el.classList.remove('nyloong-table-cell-fixed');
		}
	}
	for (let i = 0; i < startFixedMax; i++) {
		add(i);
	}
	for (let i = cStart; i <= cEnd; i++) {
		add(i);
	}
	r.shown = replaceShow(
		el,
		[...needCells].sort(({ index: a }, { index: b }) => a - b),
		r.shown,
		showCell,
		hideCell,
	);
}
