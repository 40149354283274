import type { Ref, ComputedRef, Component, VNode } from 'vue';
import { computed, shallowReactive, unref, Teleport, h, KeepAlive, ref } from 'vue';

import type { ColumnOptions, CellComponent, CellParam, CellUpdate } from '../core';

import type { VueColumn } from './types';


function create(
	list: VNode[],
	component: any,
	{ column, value, data, level, children, meta }: CellParam,
	key: string,
	className?: string,
): CellComponent {
	const root = document.createElement('div');
	if (className) {
		root.className += ` ${className}`;
	}
	const attrs = shallowReactive({ meta, value, column, data, level, children });
	let hiddenValue = false;
	const hidden = ref(false);
	const render = () => h(Teleport, { to: root },
		h(KeepAlive, hidden.value ? undefined : h(component, { ...attrs }))
	);
	const node = h({ render }, { key });
	list.push(node);
	return {
		root,
		destroy() {
			const index = list.indexOf(node);
			if (index < 0) { return; }
			list.splice(index, 1);
		},
		setHidden(v) {
			if (hiddenValue === v) { return; }
			hiddenValue = v;
			hidden.value = v;
		},
		update(row: CellUpdate) {
			attrs.value = row.value;
			attrs.data = row.data;
			attrs.level = row.level;
			attrs.children = row.children;
		},
	};
}

export default function useColumns(
	columns?: VueColumn[] | undefined | Ref<VueColumn[] | undefined>,
): [ComputedRef<ColumnOptions[]>, Component] {
	let id = 0;
	const list = shallowReactive<VNode[]>([]);
	const renderTo = document.createElement('div');
	function toColumnOptions({
		render, component, class: className, ...v
	}: VueColumn): ColumnOptions {
		return {
			...v,
			render: component
				? p => create(list, component, p, `c${id++}`, className)
				: render,
		};
	}
	const data = computed<ColumnOptions[]>(
		() => unref(columns)?.map(toColumnOptions) || []
	);
	function render() {
		return h(Teleport, { to: renderTo }, [...list]);
	}
	return [data, { render, inheritAttrs: false }];
}
