import Detail from './FormDetail.vue';
frappe.provide('frappe.guigu.GlobalView.details');
const { details } = frappe.guigu.GlobalView;

export default function getDetailComp(name?: string | boolean | any) {
	if (!name) { return Detail; }
	if (name === true) { return Detail; }
	if (typeof name === 'function') { return name; }
	if (typeof name === 'object') { return name; }
	return name in details && details[name] || Detail;
}
