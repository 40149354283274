import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d1c85004"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "organization" }
const _hoisted_2 = {
  key: 0,
  class: "btn-container"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["ElConfigProvider"], { locale: $setup.zhCn }, {
      default: _withCtx(() => [
        ($props.permissions.createPermission)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode($setup["ElButton"], {
                type: "primary",
                onClick: $setup.joinOrganization
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.tt('Join Organization')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              ($props.permissions.createPermission)
                ? (_openBlock(), _createBlock($setup["ElButton"], {
                    key: 0,
                    type: "primary",
                    onClick: $setup.batchCreateOrganizations
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Batch Join Organization')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true),
              ($props.permissions.createPermission && $setup.selectedOrganizations.length)
                ? (_openBlock(), _createBlock($setup["ElButton"], {
                    key: 1,
                    type: "primary",
                    onClick: $setup.bulkEdit
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Batch editing')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true),
              ($props.permissions.deletePermission && $setup.selectedOrganizations.length)
                ? (_openBlock(), _createBlock($setup["ElButton"], {
                    key: 2,
                    type: "danger",
                    onClick: $setup.bulkDelete
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Bulk Delete')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true)
            ]))
          : _createCommentVNode("v-if", true),
        _createVNode($setup["ElTable"], {
          onSelectionChange: $setup.handleSelectionChange,
          data: $setup.organizationList,
          border: "",
          style: {"width":"100%"},
          height: "100%",
          "empty-text": $setup.tt('No Data')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElTableColumn"], {
              type: "selection",
              width: "55"
            }),
            _createVNode($setup["ElTableColumn"], {
              prop: "organization_doc.label",
              label: $setup.tt('Organization')
            }, null, 8 /* PROPS */, ["label"]),
            _createVNode($setup["ElTableColumn"], {
              prop: "default",
              label: $setup.tt('Default Organization')
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("div", null, _toDisplayString(scope.row.default === 1 ? $setup.tt('Yes') : ''), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            ($props.permissions.writePermission || $props.permissions.deletePermission)
              ? (_openBlock(), _createBlock($setup["ElTableColumn"], {
                  key: 0,
                  align: "center",
                  prop: "address",
                  label: $setup.tt('Action'),
                  width: "340"
                }, {
                  default: _withCtx((scope) => [
                    ($props.permissions.writePermission && scope.row.type_doc?.no_default !== 1)
                      ? (_openBlock(), _createBlock($setup["ElButton"], {
                          key: 0,
                          type: "primary",
                          onClick: $event => ($setup.toggleDefault(scope.row))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(scope.row.default === 1 ? $setup.tt('Cancel Default') : $setup.tt('Make Default')), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                      : _createCommentVNode("v-if", true),
                    ($props.permissions.writePermission)
                      ? (_openBlock(), _createBlock($setup["ElButton"], {
                          key: 1,
                          type: "primary",
                          onClick: $event => ($setup.editOrganization(scope.row))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.tt('Edit')), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                      : _createCommentVNode("v-if", true),
                    _createVNode($setup["ElButton"], {
                      type: "primary",
                      onClick: $event => ($setup.viewPermissions(scope.row))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.tt('Permissions')), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                    _createVNode($setup["ElButton"], {
                      type: "primary",
                      onClick: $event => ($setup.viewRoles(scope.row))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.tt('Role')), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                    ($props.permissions.deletePermission && $props.type === 'organization')
                      ? (_openBlock(), _createBlock($setup["ElButton"], {
                          key: 2,
                          type: "danger",
                          onClick: $event => ($setup.outOrganization(scope.row))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.tt('ESC')), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["data", "empty-text"]),
        _createVNode($setup["RolesDialog"], {
          visible: $setup.visible,
          user: $props.user,
          organization: $setup.viewOrganization,
          onCancel: _cache[0] || (_cache[0] = $event => ($setup.visible = false))
        }, null, 8 /* PROPS */, ["visible", "user", "organization"]),
        _createVNode($setup["PermissionsDialog"], {
          visible: $setup.permissionVisible,
          user: $props.user,
          organization: $setup.viewOrganization,
          onCancel: _cache[1] || (_cache[1] = $event => ($setup.permissionVisible = false))
        }, null, 8 /* PROPS */, ["visible", "user", "organization"]),
        _createVNode($setup["BulkOrganizations"], {
          visible: $setup.bulkOrganizationVisible,
          "onUpdate:visible": _cache[2] || (_cache[2] = $event => (($setup.bulkOrganizationVisible) = $event)),
          user: $props.user,
          memberList: $setup.organizationList,
          onLoading: _cache[3] || (_cache[3] = $event => ($setup.emit('loading')))
        }, null, 8 /* PROPS */, ["visible", "user", "memberList"]),
        _createVNode($setup["BulkEdit"], {
          visible: $setup.bulkEditVisible,
          "onUpdate:visible": _cache[4] || (_cache[4] = $event => (($setup.bulkEditVisible) = $event)),
          selected: $setup.selectedOrganizations,
          user: $props.user,
          onLoading: _cache[5] || (_cache[5] = $event => ($setup.emit('loading')))
        }, null, 8 /* PROPS */, ["visible", "selected", "user"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["locale"])
  ])), [
    [$setup["vLoading"], $setup.loading]
  ])
}