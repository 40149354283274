
<template>
	<Page>
		<template #title>
			<h3 class="title">{{ tt('Log page') }}</h3>
		</template>
		<template #tools>
			<Tools
				v-if="doctypeLoaded"
				:organizations="organizations"
				v-model:project="selectedProject"
				v-model:searchString="searchString"
				v-model:date="selectedDate"
				v-model:showComment="showComment"
				@search="search"
				@clearSearch="clearSearch"></Tools>
		</template>
		<template #sider>
			<OrganizationTree
				:organizations="organizations"
				:organizationLoading="loading"
				@organizationChange="organizationChange">
			</OrganizationTree>
		</template>
		<DiaryTimeline
			v-if="doctypeLoaded"
			:project="selectedProject"
			:organization="organization"
			:organizations="organizations"
			:searchString="searchString"
			:date="selectedDate"
			:showComment="showComment"
			ref="diaryTimelineRef"></DiaryTimeline>
	</Page>
</template>
<script lang="ts" setup>
import { ref, onMounted, watch } from 'vue';
import moment from 'moment';

import Page from '../../../components/page/index.vue';

import OrganizationTree from './OrganizationTree.vue';
import DiaryTimeline from './DiaryTimeline.vue';
import Tools from './Tools.vue';
import type { Organization } from './type';
const tt = __
const showComment = ref<boolean>(false);
const selectedProject = ref<string>('');
const selectedDate = ref<string>(moment().format('YYYY-MM-DD'));
const loading = ref<boolean>(false);
const organizations = ref<Organization[]>([]);
const organization = ref<Organization>();
const doctypeLoaded = ref<boolean>(false);
const searchString = ref<string>('');
const diaryTimelineRef = ref<{
	searchDiary: (searchModeProps: boolean) => void;
}>();

function search() {
	diaryTimelineRef.value?.searchDiary(Boolean(searchString.value));
}
function clearSearch() {
	searchString.value = '';
	diaryTimelineRef.value?.searchDiary(false);
}
async function getOrganizations() {
	loading.value = true;
	organizations.value = [];
	const data = await frappe.call<{ message: Organization[] }>({
		method: 'guigu_pm.guigu_pm_daily_record.page.project_work_daily_record.project_work_daily_record.get_organizations',
		args: { project: selectedProject.value },
	});
	const org = data?.message || [];
	const isInOrganization = org.some(item => item.user?.name === frappe.session.user);
	if (isInOrganization) {
		org.unshift({
			'id': 'my',
			'title': tt('My'),
			'type': 'member',
			'user': { name: frappe.session.user, full_name: frappe.session.user_fullname },
		});
	}
	org.unshift({
		'id': 'all',
		'title': tt('All Daily Record'),
		'type': 'organization',
	});
	organizations.value = org;
	loading.value = false;
}
watch(selectedProject, () => {
	getOrganizations();
});

function organizationChange(newOrganizationORUser: Organization) {
	organization.value = newOrganizationORUser;
}
onMounted(async () => {
	doctypeLoaded.value = false;
	await frappe.model.with_doctype('Guigu Daily Record B');
	doctypeLoaded.value = true;
});
</script>
<style scoped>
.title {
	margin-bottom: 0;
}
</style>
