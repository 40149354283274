import { Filter } from './types';

export default function getFilters(
	filters: Filter[],
) {
	const list = filters.map(({ doctype, field, condition, value }) => {
		if (Array.isArray(value)) {
			// eslint-disable-next-line no-param-reassign
			value = value.filter(v => v !== undefined && v !== null);
			if (!value.length) { return; }
		}
		if (value === undefined) { return; }
		if (condition) { return [doctype, field, condition, value]; }
		return [doctype, field, Array.isArray(value) ? 'in' : '=', value];
	}).filter(Boolean) as [string, string, string, any][];
	return list;
}
