<template>
	<ElTabs v-model="cusTab" type="border-card">
		<ElTabPane name="workInstruction" :label="tt('Standard Work Instruction')">
			<Instruction
				v-if="cusTab==='workInstruction'"
				:instructionName="instructionName"
				:referenceType="referenceType"
				:referenceName="referenceName"
			></Instruction>
		</ElTabPane>
		<ElTabPane name="cusWorkInstruction" :label="tt('Customize Work Instruction')">
			<Instruction
				v-if="cusTab==='cusWorkInstruction'"
				:instructionName="instructionName"
				:referenceType="referenceType"
				:referenceName="referenceName"
			></Instruction>
		</ElTabPane>
	</ElTabs>

</template>

<script setup lang='ts'>
import {ElTabs, ElTabPane} from 'element-plus';
import {computed, ref, watch} from 'vue';


import Instruction from './Instruction.vue';

const tt = __;
interface Props {
	organizationWorklist: string;
}
const props = defineProps<Props>();
const workInstruction = ref<string>('');
const cusWorkInstruction = ref<string>('');
const worklistName = ref<string>('');
const cusTab = ref<string>('workInstruction');

const instructionName = computed(()=>cusTab.value === 'workInstruction' ? workInstruction.value : cusWorkInstruction.value);
const referenceName = computed(()=>cusTab.value === 'workInstruction' ? worklistName.value : props.organizationWorklist);
const referenceType = computed(()=>cusTab.value === 'workInstruction' ? 'Dw Standard Worklist' : 'Dw Organization Worklist');
watch(()=>props.organizationWorklist, async ()=>{
	if (!props.organizationWorklist) {
		workInstruction.value = '';
		cusWorkInstruction.value = '';
		worklistName.value = '';
		return;
	}
	const organizationWorkListDoc = await frappe.db.get_doc('Dw Organization Worklist', props.organizationWorklist);
	worklistName.value = organizationWorkListDoc?.dw_standard_worklist || '';
	if (organizationWorkListDoc.dw_standard_worklist) {
		const standard_worklist = await frappe.db.get_doc('Dw Standard Worklist', organizationWorkListDoc.dw_standard_worklist);
		workInstruction.value = standard_worklist.work_instruction;
	} else {
		workInstruction.value = '';
	}
	cusWorkInstruction.value = organizationWorkListDoc.work_instruction;
}, {immediate: true});
</script>

<style lang='less' scoped>
</style>
