<template>
	<ElTooltip placement="top" :content="labelString">
		<div :style="{overflow:'auto', height:'100%', 'line-height':'100%'}">
			<el-space :style="{ height:'100%', 'line-height':'100%'}">
				<el-tag v-for="d in value" >
					<a :href="d.multi_table_url">{{ tt(d.multi_table_label) }}</a>
				</el-tag>
			</el-space>
		</div>
	</ElTooltip>
</template>
<script lang="ts" setup>
import { computed } from 'vue';


const props = defineProps<{
	value: any;
	data: Record<string, any>;
	field: GlobalView.FieldRenderOptions;
	meta?: locals.DocType;
	maxLine?: number;
	enableLink?: boolean;
	noData?: boolean;
	inline?: boolean;
	format?: string;
	showTitle?: boolean;
}>();
const emit = defineEmits<{
	(event: 'filter', field: string, operator: string, value: any): void;
}>();
const value = computed(()=>(props.value||[]).map(item=>({
	...item,
	multi_table_url:`/app/${frappe.router.slug(item.multi_table_link)}/${item.multi_table_value}`,
})));
const labelString = computed(()=>(props.value||[]).map(item=>item.multi_table_label).join(','));
const tt = __;

</script>
