<template>
	<ElTable :data="fields" style="width: 100%">
		<ElTableColumn type="index" :index="1" />
		<ElTableColumn :label="tt('Field')" #="{ row, $index }">
			<ElSelect :modelValue="row.field" filterable defaultFirstOption
				@update:modelValue="set($index, 'field', $event)">
				<ElOption v-for="field in fieldsSelectable" :key="field.value"
					:value="field.value" :label="field.label" />
			</ElSelect>
		</ElTableColumn>

		<ElTableColumn :label="tt('Width')" #="{ row, $index }">
			<ElInput type="number" :modelValue="row.width"
				@update:modelValue="setWidth($index, 'width', $event || 0)" />
		</ElTableColumn>
		<ElTableColumn :label="tt('Min Width')" #="{ row, $index }">
			<ElInput type="number" :modelValue="row.minWidth"
				@update:modelValue="setWidth($index, 'minWidth', $event || 0)" />
		</ElTableColumn>
		<ElTableColumn :label="tt('Max Width')" #="{ row, $index }">
			<ElInput type="number" :modelValue="row.maxWidth"
				@update:modelValue="setWidth($index, 'maxWidth', $event || 0)" />
		</ElTableColumn>

		<ElTableColumn :label="tt('Enable Link')" #="{ row, $index }">
			<ElCheckbox :modelValue="row.enableLink" v-if="isLink(row.field)"
				@update:modelValue="set($index, 'enableLink', $event || false)" />
		</ElTableColumn>
		<ElTableColumn :label="tt('Format')" #="{ row, $index }">
			<ElInput :modelValue="row.format" v-if="hasFormat(row.field)"
				@update:modelValue="set($index, 'format', $event || '')" />
		</ElTableColumn>

		<ElTableColumn :label="tt('Operation')" #="{ $index }">
			<ElButtonGroup>
				<ElButton @click="moveTo($index)" text :icon="Sort"
					:title="tt('Move')" />
				<ElButton type="danger" @click="remove($index)" text
					:icon="Delete" :title="tt('Delete')" />
			</ElButtonGroup>
		</ElTableColumn>
	</ElTable>
	<ElButton @click="add">{{ tt('Add') }}</ElButton>
</template>
<script setup lang="ts">
import {computed, shallowRef, h} from 'vue';
import {
	ElTable, ElTableColumn,
	ElSelect, ElCheckbox, ElInput,
	ElButtonGroup, ElButton, ElOption, ElMessageBox,
} from 'element-plus';
import {Delete, Sort} from '@element-plus/icons-vue';

import {getFieldOptions} from '../../../utils/FieldDisplayed';
import {displayField} from '../constants';

const props = defineProps<{
	meta: locals.DocType;
	modelValue: GlobalView.Field[];
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: GlobalView.Field[]): void;
}>();
const fields = computed({
	get: () => props.modelValue,
	set: v => emit('update:modelValue', v),
});
const tt = __;


function set(index: number, key: keyof GlobalView.Field, value: any) {
	const list = [...fields.value];
	const item: any = list[index];
	if (!item) {
		return;
	}
	item[key] = value;
	fields.value = list;
}
function setWidth(index: number, key: keyof GlobalView.Field, value: any) {
	const list = [...fields.value];
	const item: any = list[index];
	if (!item) {
		return;
	}
	item[key] = Math.max(0, parseInt(value) || 0);
	fields.value = list;
}
function add() {
	fields.value = [...fields.value, {field: ''}];
}
function remove(index: number) {
	const list = [...fields.value];
	if (!list.splice(index, 1).length) {
		return;
	}
	fields.value = list;
}
function move(from: number, to: number) {
	if (from === to) {
		return;
	}
	const list = [...fields.value];
	const item: any = list[from];
	if (!item) {
		return;
	}
	list.splice(from, 1);
	list.splice(to, 0, item);
	fields.value = list;
}
const fieldsSelectable = computed(() => getFieldOptions(props.meta, displayField));
function isLink(field?: string) {
	return fieldsSelectable.value.find(v => v.value === field)?.isLink || false;
}
function hasFormat(field?: string) {
	return fieldsSelectable.value.find(v => v.value === field)?.format || false;
}
function moveTo(index: number) {
	const target = shallowRef<number>(index);
	const field = fields.value[index]?.field;
	const label = fieldsSelectable.value.find(f => f.value === field)?.label || field || '';
	ElMessageBox({
		title: `将 ${label} #${index + 1} 移动到...`,
		message: (): any => {
			const labels = new Map(fieldsSelectable.value.map(v => [v.value, v.label]));
			return h(ElSelect, {
				filterable: true, defaultFirstOption: true,
				modelValue: target.value,
				'onUpdate:modelValue': (val: number) => {
					target.value = val;
				},
			}, fields.value.map(({field}, i) => h(ElOption, {
				value: i,
				label: i === index
					? '<<<位置不变>>>'
					: `${labels.get(field) || field} #${i + 1} ${i < index ? '之前' : '之后'}`,
			})));
		},
	}).then(() => {
		move(index, target.value);
	});
}
</script>
