import type {
	ColDef,
	ColGroupDef,
	ValueFormatterParams,
  } from 'ag-grid-community';

  const PROBLEM_STATE={
	'In Progress':__("In Progress"),
	'Resolved':__("Resolved"),
	'Canceled':__("Cancel"),
  };
  export default function getProblemColumns(
  ) {
	const initColumns: (ColDef | ColGroupDef)[] = [
	  {
		headerName: __('Status'),
		field: 'problem_state',
		lockPinned: true,
		pinned: 'left',
		width: 150,
		sortable: false,
		editable: false,
		filter: false,
		enableRowGroup: false,
		valueFormatter:(params:ValueFormatterParams)=>PROBLEM_STATE[params.value as keyof typeof PROBLEM_STATE],
	  },
	  {
		headerName: __("Subject"),
		field: 'subject',
		minWidth:300,
		flex: 1,
		sortable: false,
		editable: false,
		filter: false,
		enableRowGroup: false,
	  },
	];
	return initColumns;
  }
