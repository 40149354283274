import type {
	ColDef,
	ICellRendererParams,
	KeyCreatorParams,
	ValueGetterParams,
	ValueFormatterParams,
} from 'ag-grid-community';
import moment from 'moment';

import type { DiaryStructure, DiaryStructureFormat } from './type/index';
const tt=__
export function dateComparator(
	filterLocalDateAtMidnight: Date,
	cellValue: any,
) {
	if (!cellValue) {
		return -1;
	}
	const value = Array.isArray(cellValue)
		? moment(cellValue[0]).format('YYYY-MM-DD')
		: moment(cellValue).format('YYYY-MM-DD');

	if (moment(filterLocalDateAtMidnight).format('YYYY-MM-DD') > value) {
		return -1;
	} else if (moment(filterLocalDateAtMidnight).format('YYYY-MM-DD') < value) {
		return 1;
	}
	return 0;
}
const FILTER_OPTION = {
	date: ['equals', 'lessThan', 'greaterThan', 'inRange'],
};
const STRUCTURE_STATUS = {
	'null': tt("null"),
	'Not Started': tt("Not Started"),
	'Normal Processing': tt("Normal Processing"),
	'Stop Processing': tt("Stop Processing"),
	'Completed': tt("Progress Completed"),
};


const DIARY_STATUS = {
	'Normal Construction': tt("normal construction"),
	'Half Processing': tt("Half Processing"),
	'Prevent': tt("Prevent construction"),
	'Stop Processing': tt("Stop Processing"),
};
export function getColumns(
	clickDiary:(data:DiaryStructureFormat)=>void,
	onRemove:(data:DiaryStructureFormat)=>void,
){
	const columns = [
		{
			headerName: tt('Subcontracting Team'),
			field: 'team.organization_name',
			flex: 1,
			minWidth: 100,
			enableRowGroup: false,
			editable: false,
		},
		{
			headerName: tt('Status'),
			field: 'status',
			width: 100,
			minWidth: 100,
			editable: false,
			valueFormatter:(params:ValueFormatterParams)=>STRUCTURE_STATUS[params.value as keyof typeof STRUCTURE_STATUS],
			keyCreator: (params: KeyCreatorParams) => {
				const { data } = params;
				return data?.status || 'null';
			},
			filter: 'agSetColumnFilter',
			filterParams: {
				suppressAndOrCondition: true,
				valueFormatter: (params: ValueGetterParams) => {
					const { getValue }= params;
					const value = getValue('status');
					return STRUCTURE_STATUS[value as keyof typeof STRUCTURE_STATUS];
				},
				valueGetter: (params: ValueGetterParams) => {
					const { data } = params;
					if (data?.status === undefined) {
						return '';
					}
					const { status } = data;
					return status;
				},
			},
			enableRowGroup: false,
		},
		{
			headerName: tt('Weather'),
			field: 'diary.weather',
			flex: 1,
			minWidth: 100,
			enableRowGroup: false,
			editable: false,
			valueFormatter:(params:ValueFormatterParams)=>__(params.value),
		},
		{
			headerName: tt("Report status"),
			field: 'diary',
			width: 100,
			minWidth: 100,
			maxWidth: 100,
			enableRowGroup: false,
			editable: false,
			filter: 'agSetColumnFilter',
			cellRenderer:'AgGridDiary',
		},
		{
			headerName: tt("Start Date"),
			field: 'plan_start_date',
			width: 100,
			minWidth: 100,
			enableRowGroup: false,
			editable: false,
			filter: 'agDateColumnFilter',
			filterParams: {
				filterOptions: FILTER_OPTION.date,
				comparator: dateComparator,
				inRangeInclusive: true,
			},
		},
		{
			headerName: tt("End Date"),
			field: 'plan_end_date',
			width: 100,
			minWidth: 100,
			filter: 'agDateColumnFilter',
			enableRowGroup: false,
			editable: false,
			filterParams: {
				filterOptions: FILTER_OPTION.date,
				comparator: dateComparator,
				inRangeInclusive: true,
			},
		},
		{
			headerName: tt("Actual Start"),
			field: 'actual_start_date',
			width: 100,
			minWidth: 100,
			enableRowGroup: false,
			editable: false,
			filter: 'agDateColumnFilter',
			filterParams: {
				filterOptions: FILTER_OPTION.date,
				comparator: dateComparator,
				inRangeInclusive: true,
			},
		},
		{
			headerName: tt("Actual End"),
			field: 'actual_end_date',
			width: 100,
			minWidth: 100,
			filter: 'agDateColumnFilter',
			enableRowGroup: false,
			editable: false,
			filterParams: {
				filterOptions: FILTER_OPTION.date,
				comparator: dateComparator,
				inRangeInclusive: true,
			},
		},
		{
			headerName: tt('Construction status of the day'),
			field: 'diary',
			width: 140,
			minWidth: 140,
			enableRowGroup: false,
			editable: false,
			filter: 'agSetColumnFilter',
			filterParams: {
				suppressAndOrCondition: true,
				valueFormatter: (params: any) => {
					const { value } = params;
					return DIARY_STATUS[value as keyof typeof DIARY_STATUS];
				},
			},
			cellRenderer:'AgGridDiaryStatus',
		},
		{
			headerName: tt("Actions"),
			width: 100,
			pinned: 'right',
			enableRowGroup: false,
			filter: false,
			sortable: false,
			editable: false,
			menuTabs: [],
			cellRenderer: 'Action',
			cellRendererParams: (params: ICellRendererParams) => {
				const { data } = params;
				if (!data.is_unit_target){ return; }
				if (data?.diary) {
					return { onRemove, clickDiary };
				}
				return {clickDiary};
			},
		},
	];
	return columns;
}
