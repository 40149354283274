import { createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-be66b6f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar" }
const _hoisted_2 = { class: "left-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElButton = _resolveComponent("ElButton")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["ProjectCom"], {
        class: "project-select",
        default: "",
        noLabel: "",
        modelValue: $setup.projectValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.projectValue) = $event))
      }, null, 8 /* PROPS */, ["modelValue"]),
      _withDirectives(_createVNode($setup["Structure"], {
        class: "input",
        project: $props.project,
        modelValue: $props.structure,
        "onUpdate:modelValue": $setup.changeStructure
      }, null, 8 /* PROPS */, ["project", "modelValue"]), [
        [_vShow, $props.project]
      ]),
      ($props.project)
        ? (_openBlock(), _createBlock($setup["PeriodPicker"], {
            key: 0,
            class: "input",
            calendarType: "Construction Calendar",
            project: $props.project,
            periodType: `Year ${$props.type}`,
            defaultValue: "",
            modelValue: $props.period,
            "onUpdate:modelValue": $setup.updatePeriod
          }, null, 8 /* PROPS */, ["project", "periodType", "modelValue"]))
        : _createCommentVNode("v-if", true)
    ]),
    _createVNode(_component_ElButton, {
      disabled: $props.isCreateDisabled,
      onClick: _cache[1] || (_cache[1] = $event => (_ctx.$emit('createTempProgress'))),
      type: "primary"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($setup.tt("New Temporary Job")), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["disabled"])
  ]))
}