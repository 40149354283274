import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.dataType)
    ? (_openBlock(), _createBlock($setup["ElDatePicker"], {
        key: 0,
        type: $setup.dataType,
        modelValue: $setup.val,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.val) = $event)),
        unlinkPanels: "",
        rangeSeparator: "~",
        teleported: false
      }, null, 8 /* PROPS */, ["type", "modelValue"]))
    : (_openBlock(), _createBlock($setup["Types"], {
        key: 1,
        filter: "",
        modelValue: $setup.val,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.val) = $event)),
        required: $props.required,
        type: $props.type,
        name: $props.name,
        label: $props.label,
        options: $props.options
      }, null, 8 /* PROPS */, ["modelValue", "required", "type", "name", "label", "options"]))
}