<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { ElOption, ElSelect } from 'element-plus';

import { load } from './services';

const props = defineProps<{
	modelValue?: any;
	doctype: string;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', data: any): void;
}>();

const value = computed({
	get: () => props.modelValue,
	set: v => emit('update:modelValue', v),
});
const list = ref<{ value: string, label: string }[]>([]);
watch(() => props.doctype, async dt => {
	list.value = [];
	const data = await load(dt, true);
	if (dt !== props.doctype) { return; }
	list.value = data.map(v => ({ value: v.name, label: v.title }));
}, { immediate: true });

</script>

<template>
	<ElSelect v-model="value" filterable>
		<ElOption v-for="v in list"
			:key="v.value"
			:value="v.value"
			:label="v.label" />
	</ElSelect>
</template>
