import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d8fc68e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "report" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["ElTable"], {
      data: $setup.reportList,
      border: "",
      style: {"width":"100%"},
      height: $setup.tableHeight
    }, {
      empty: _withCtx(() => [
        _createVNode($setup["Empty"], { ref: "empty" }, null, 512 /* NEED_PATCH */)
      ]),
      default: _withCtx(() => [
        _createVNode($setup["ElTableColumn"], {
          fixed: "",
          prop: "report_name",
          label: "名称",
          minWidth: "250"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(`${$setup.tt(scope.row.report_name)}(${scope.row.report_name})`), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElTableColumn"], {
          fixed: "",
          prop: "ref_doctype",
          label: "源单据",
          minWidth: "180"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(`${$setup.tt(scope.row.ref_doctype)}(${scope.row.ref_doctype})`), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElTableColumn"], {
          prop: "module",
          label: "模块",
          width: "180"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(`${$setup.tt(scope.row.module)}(${scope.row.module})`), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElTableColumn"], {
          prop: "is_standard",
          label: "系统自带",
          width: "80"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.is_standard==='Yes'?'是':'否'), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElTableColumn"], {
          prop: "report_type",
          label: "报表类型",
          width: "80"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString($setup.tt(scope.row.report_type)), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElTableColumn"], {
          prop: "add_total_row",
          label: "添加总计行",
          width: "80"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.add_total_row===1?'是':'否'), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElTableColumn"], {
          prop: "disabled",
          label: "禁用",
          width: "50"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.disabled===1?'是':'否'), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElTableColumn"], {
          prop: "prepared_report",
          label: "后台运行报表",
          width: "100"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.prepared_report===1?'是':'否'), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElTableColumn"], {
          prop: "address",
          label: "操作",
          width: "150"
        }, {
          default: _withCtx((scope) => [
            _createVNode($setup["ElButton"], {
              type: "primary",
              onClick: $event => ($setup.gotoReport(scope.row))
            }, {
              default: _withCtx(() => [
                _createTextVNode("显示报表")
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
            _createVNode($setup["ElButton"], {
              type: "primary",
              onClick: $event => ($setup.editReport(scope.row))
            }, {
              default: _withCtx(() => [
                _createTextVNode("详情")
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["data", "height"])
  ])), [
    [$setup["vLoading"], $setup.loading]
  ])
}