<template>
	<MultiSelect v-if="multiSel" :teleported="false"
		:style="{width:'140px'}"
		filter
		v-model="value"
		:name="name"
		:placeholder="label && tt(label)"
		:options="options" />
	<div v-else class="multi">
		<div v-for="(it, index) in value" :key="index" class="line">
			<Types
				class="main"
				filter
				:modelValue="it"
				@update:modelValue="set(index, $event)"
				:type="type"
				:label="label"
				:name="name"
				:required="required"
				:options="options" />
			<ElButton v-if="!index" @click="add" :icon="Plus" text title="增加" />
			<ElButton v-else @click="remove(index)" icon="Minus" text
				title="移除" />
		</div>
	</div>
</template>
<script lang="ts" setup>
import {computed} from 'vue';
import {ElButton} from 'element-plus';
import {Plus} from '@element-plus/icons-vue';

import Types from '../../GuiguFieldInput/index.vue';
import MultiSelect from '../../GuiguFieldInput/MultiSelect.vue';

const props = defineProps<{
	meta: locals.DocType;
	type: string;
	name: string;
	label?: string;
	required?: boolean;
	options?: any;
	modelValue?: any
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: any): void;
}>();
const tt = __;

const multiSel = computed(() => ['select', 'multiselect'].includes(props.type.toLowerCase()));
const value = computed({
	get: () => {
		const value = props.modelValue;
		if (!Array.isArray(value)) {
			return [value];
		}
		if (value.length || multiSel.value) {
			return value;
		}
		if (value.length) {
			return value;
		}
		return [undefined];
	},
	set: v => emit('update:modelValue', v),
});
function set(index: number, val: any) {
	const list = value.value;
	if (index >= list.length) {
		return;
	}
	const newList = [...list];
	newList[index] = val;
	emit('update:modelValue', newList);
}

function add() {
	emit('update:modelValue', [...value.value, undefined]);
}

function remove(index: number) {
	const list = value.value;
	if (index >= list.length) {
		return;
	}
	const newList = [...list];
	newList.splice(index, 1);
	emit('update:modelValue', newList);
}
</script>
<style lang="less" scoped>
.multi {
	display: flex;
	flex-direction: column;

	>* {
		flex: 1;
	}
}

.line {
	display: flex;
	flex-direction: row;
}

.main {
	flex: 1;
}
</style>
