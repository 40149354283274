<template>
	<div class="toolbar">
		<div class="toolbar_left">
			<div class="input-container" v-if="organizationList">
				{{ tt('Organization Unit') }}:
				<ElSelect class="input" disabled v-model="organization" size="small" width="150px">
					<ElOption :label="organizationList.label" :value="organizationList.name" />
				</ElSelect>
			</div>
			<div class="input-container">
				{{ tt('Dw Department') }}:
				<ElCascader
					popperClass="dw_task_department_cascader"
					size="small"
					:placeholder="tt('Please Select')"
					clearable
					filterable
					:options="departmentTree"
					:props="cascaderProps"
					v-model="department"
				/>
			</div>
			<div class="input-container">
				{{ tt('Work List Applicable State') }}:
				<ElSelect class="input" v-model="applicable" size="small">
					<ElOption :label="tt('Applicable')" value="1" />
					<ElOption :label="tt('Inapplicability')" value="0" />
				</ElSelect>
			</div>
		</div>
		<div class="toolbar_right">
			<div class="layout">
				<LayoutSelect v-model:detailType="detailType"/>
			</div>
			<ElButton type="primary" size="small" @click="addNewTask" v-show="permission">{{ tt('New Task') }}</ElButton>
		</div>
	</div>
</template>
<script setup lang="ts">
import {computed, ref, watch} from 'vue';
import {ElButton, ElSelect, ElOption, ElCascader} from 'element-plus';

import LayoutSelect from '../../../../component/LayoutSelect/index.vue';

import type {Organization, Department} from './type';
const tt = __;
const organizationList = ref<Organization>();
const props = defineProps<{
	smallMeta: boolean;
	permission: boolean;
	layoutType: 'modal' | 'link' | 'bottom' | 'right';
	currentOrganization: string;
	selectedWorkList: string[];
	department: string;
	departmentTree: Department[];
	applicable: string;
}>();
const emit = defineEmits<{
	(e: 'update:currentOrganization', value: string): void;
	(e: 'update:layoutType', value: string): void;
	(e: 'showProcessModalOrNavigate', name: string): void;
	(e: 'update:department', department: string): void;
	(e: 'update:applicable', value: string): void;
}>();
// 计算查询条件
const organization = computed({
	get: () => props.currentOrganization || '',
	set: (value: string) =>emit('update:currentOrganization', value),
});

const detailType = computed({
	get: () => props.layoutType,
	set: (value:string) => emit('update:layoutType', value),
});
const department = computed({
	get: () => props.department,
	set: (department:string) => emit('update:department', department || ''),
});
const applicable = computed({
	get: () => props.applicable || '',
	set: (value: string) =>emit('update:applicable', value),
});
const cascaderProps = {
	label: 'department_name',
	value: 'name',
	children: 'children',
	emitPath: false,
	checkStrictly: true,
};
// 新建任务
function addNewTask() {
	const docName = frappe.model.make_new_doc_and_get_name('Dw Task', true);
	const docDetail = locals['Dw Task'][docName] as locals.Doctype;
	[docDetail.organization_worklist] = props.selectedWorkList;
	emit('showProcessModalOrNavigate', docDetail.name);
}
// 组织变化获取组织信息
watch(organization, async ()=>{
	await frappe.db.get_value(
		'Tianjy Organization',
		{name: organization.value},
		['name', 'label'],
		(res: Organization) => {
			organizationList.value = res;
		},
	);
}, {immediate: true});
const flexDirection = computed(() => (props.smallMeta ? 'column' : 'row'));
</script>

<style lang="less" scoped>
.toolbar {
  display: flex;
    flex-direction: v-bind(flexDirection);
	justify-content: space-between;
	flex-wrap: wrap;
	.toolbar_left {
		display: flex;
		flex-direction: v-bind(flexDirection);
		flex-wrap: wrap;

		.input-container{
			white-space: nowrap;
			margin-right: 8px;
			margin-bottom: 8px;

			.input {
				min-width: 120px;
			}
		}
	}
	.toolbar_right {
		display: flex;
		flex-direction: v-bind(flexDirection);
		justify-content: flex-end;
		flex-wrap: wrap;

		.layout {
			margin-right: 20px;
			margin-bottom: 8px;
		}
	}
}
</style>
<style>
.dw_task_department_cascader .el-cascader-panel .el-radio{
	margin-bottom: 0;
}
.dw_task_department_cascader .el-radio__original{
	position: absolute!important;
}
</style>
