<template>
	<Select v-if="selOptions"
		:style="{width:'140px'}"
		:placeholder="label && tt(label)"
		v-model="val"
		:options="selOptions" />
	<Like v-else-if="cond === 'like' || cond === 'not like'"
		:label="label"
		v-model="val" />
	<Between v-else-if="btw"
		:meta="meta"
		v-model="val"
		:type="type"
		:name="name"
		:label="label"
		:required="required"
		:options="options" />
	<Multi v-else-if="multi"
		:meta="meta"
		v-model="val"
		:type="type"
		:name="name"
		:label="label"
		:required="required"
		:options="options" />
	<Types v-else-if="!isSession"
		:listable="listable"
		filter
		v-model="val"
		:type="type"
		:name="name"
		:label="label"
		:required="required"
		:options="options" />
</template>
<script lang="ts" setup>
import { computed, watch } from 'vue';

import Types from '../../GuiguFieldInput/index.vue';

import Select from './Select.vue';
import Like from './Like.vue';
import Multi from './Multi.vue';
import Between from './Between.vue';
import * as selectOptionMap from './options';
const props = defineProps<{
	meta: locals.DocType;
	name: string;
	label?: string;
	required?: boolean;
	type: string;
	condition?: string;
	options?: any;
	modelValue?: any
	listable?: boolean
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', modelValue: any): void;
}>();
const tt = __;
const cond = computed(() => props.condition?.toLowerCase());
const isSession = computed(() => cond.value === 'session');
const btw = computed(() => cond.value === 'between');
const selOptions = computed(() => {
	const condition = cond.value;
	switch (condition) {
		case 'is':
		case 'timespan':
			return selectOptionMap[condition];
	}
});
const multi = computed(() => ['in', 'not in'].includes(cond.value || ''));

const val = computed({
	get: () => props.modelValue,
	set: val => emit('update:modelValue', val),
});

watch([isSession, val, () => props.options], ([isSession, v, options]) => {
	if (!isSession) { return; }
	const value = typeof options === 'string' && options || undefined;
	if (!value) { return; }
	if (value === v) { return; }
	val.value = value;
});

</script>
