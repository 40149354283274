<template>
  <div class="toolbar">
    <el-select :size="size" class="elSelectPadding" filterable :modelValue="company" @change="changeCompany">
      <el-option v-for="item in subCompanies" :key="item.name" :label="item.company_name" :value="item.name" />
    </el-select>

    <el-select :size="size" class="elSelectPadding" :modelValue="pageType" @change="pageTypeChange">
      <el-option value="detail" key="detail" :label="tt('Detail')" />
      <el-option value="summary" key="summary" :label="tt('Sum')" />
    </el-select>

    <el-date-picker
      class="dateSelectPadding"
      :size="size"
      v-if="pageType === 'detail'"
      v-model="monthValue"
      @change="monthChange"
      type="month"
      :placeholder="tt('Select Month')">
    </el-date-picker>

    <el-date-picker
      class="dateSelectPadding"
      :size="size"
      v-if="pageType === 'summary'"
      v-model="yearValue"
      @change="yearChange"
      type="year"
      :placeholder="tt('Select Year')">
    </el-date-picker>
    <div class="refresh">
      <el-select :size="size" class="modalSelectPadding" :modelValue="detailType" @change="detailTypeChange">
        <el-option value="modal" key="modal" :label="tt('Details Dialog')" />
        <el-option value="link" key="link" :label="tt('Jump to page')" />
        <el-option value="bottom" key="bottom" :label="tt('vertical layout')" />

        <el-option value="right" key="right" :label="tt('Details Dialog')" />
      </el-select>
      <el-button-group class="ml-4">
        <ElButton :size="size" @click="() => expandAll(true)">{{ tt('Expand') }}</ElButton>
        <ElButton :size="size" @click="() => expandAll(false)">{{ tt('Collapse') }}</ElButton>
        <ElButton :size="size" @click="refresh" :loading="loading" :title="tt('Refresh')" :icon="RefreshRight">
          {{ tt('Refresh') }}
        </ElButton>
      </el-button-group>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { ref, onMounted, toRefs, onUnmounted } from 'vue';
  import moment from 'moment';
  import { RefreshRight } from '@element-plus/icons-vue';
  import { ElButton } from 'element-plus';
  import { GridApi } from 'ag-grid-community';

  import { SubCompany, Organization } from '../../type';

  import { routeInit, setRoute } from './helper';

  const tt = __;

  interface Props {
    company?: string;
    organization?: string;
    pageType?: string;
    monthDate?: string;
    yearDate?: string;
    refreshValue?: number;
    detailGridApi?: GridApi;
    loading?: boolean;
    detailType?: string;
  }

  const props = defineProps<Props>();
  const { company, pageType, detailType, monthDate, yearDate, refreshValue, detailGridApi, loading } = toRefs(props);

  interface Emit {
    (e: 'update:company', company: string): void;
    (e: 'update:monthDate', date: string): void;
    (e: 'update:yearDate', date: string): void;
    (e: 'update:pageType', type: string): void;
    (e: 'update:refreshValue', refreshValue: number): void;
    (e: 'update:detailType', detailType: string): void;
  }

  const emit = defineEmits<Emit>();

  const subCompanies = ref<SubCompany[]>();

  const monthValue = ref<string>(monthDate?.value || moment());
  const yearValue = ref<string>(yearDate?.value || '');

  const paramsFilter = ref<{ [key: string]: string }>({});

  const size = ref<'' | 'small' | 'default' | 'large'>('small');

  function updateComponentSize() {
    const widthElement = window.innerWidth;
    if (widthElement <= 640) {
      size.value = 'default';
    } else {
      size.value = 'small';
    }
  }
  window.addEventListener('resize', updateComponentSize);

  onUnmounted(() => window.removeEventListener('resize', updateComponentSize));

  onMounted(async () => {
    updateComponentSize();
    routeInit(paramsFilter);
    const subCompaniesRes: SubCompany[] = await frappe.db.get_list('Company', {
      fields: ['name', 'company_name'],
      limit: '',
    });
    subCompanies.value = subCompaniesRes;

    const companyNames = subCompaniesRes.map((item) => item.name);
    const sessionDefaultCompany: string = (frappe.defaults.get_user_defaults('company') || [])[0];
    let defaultCompany: string = '';
    if (companyNames.includes(paramsFilter.value?.company)) {
      defaultCompany = paramsFilter.value?.company;
    } else {
      defaultCompany = sessionDefaultCompany || subCompaniesRes[0]?.name || '';
    }
    let defaultPageType: string = paramsFilter.value?.pageType || props.pageType || 'detail';
    let monthDate: string = paramsFilter.value?.monthDate || props.monthDate || moment().format('YYYY-MM');
    let yearDate: string = paramsFilter.value?.yearDate || props.yearDate || moment().format('YYYY');
    let routeObj: { [key: string]: any } = {};
    monthValue.value = monthDate;
    yearValue.value = yearDate;
    if (defaultPageType === 'detail') {
      routeObj = { company: defaultCompany, pageType: defaultPageType, monthDate };
      emit('update:pageType', 'detail');
    } else {
      routeObj = { company: defaultCompany, pageType: defaultPageType, yearDate };
      emit('update:pageType', 'summary');
    }
    emit('update:monthDate', monthDate);
    emit('update:yearDate', yearDate);
    emit('update:company', defaultCompany);
    setRoute(paramsFilter, routeObj);
  });
  function expandAll(expand: boolean) {
    if (expand) {
      detailGridApi?.value?.expandAll();
    } else {
      detailGridApi?.value?.collapseAll();
    }
  }

  function changeCompany(newCompany: string) {
    setRoute(paramsFilter, { company: newCompany });
    emit('update:company', newCompany);
  }

  function monthChange(date: moment) {
    const dateValue = date || moment();
    setRoute(paramsFilter, { monthDate: moment(dateValue).format('YYYY-MM') });
    monthValue.value = dateValue;
    emit('update:monthDate', moment(dateValue).format('YYYY-MM'));
  }

  function yearChange(date: moment) {
    const dateValue = date || moment();
    setRoute(paramsFilter, { yearDate: moment(dateValue).format('YYYY') });
    yearValue.value = dateValue;
    emit('update:yearDate', moment(dateValue).format('YYYY'));
  }

  function pageTypeChange(type: string) {
    setRoute(paramsFilter, { pageType: type });
    emit('update:pageType', type);
  }

  function detailTypeChange(type: string) {
    emit('update:detailType', type);
  }
  function refresh() {
    const newValue: number = (refreshValue?.value || 0) + 1;
    emit('update:refreshValue', newValue);
  }
</script>
<style lang="less" scoped>
  .toolbar {
    padding: 8px 0;
  }

  @media (min-width: 640px) {
    .refresh {
      float: right;
    }

    .elSelectPadding {
      margin-right: 12px;

      :deep(.select-trigger .el-input .el-input__wrapper) {
        height: 24px;
      }
    }

    .modalSelectPadding {
      :deep(.select-trigger .el-input .el-input__wrapper) {
        height: 24px;
      }
    }
  }

  @media (max-width: 640px) {
    .refresh {
      float: none;
      padding-top: 12px;
    }

    .dateSelectPadding {
      :deep(.el-date-editor.el-input) {
        width: 100% !important;
      }
    }

    .elSelectPadding {
      padding-bottom: 12px;
      width: 100% !important;
    }

    .modalSelectPadding {
      padding-bottom: 12px;
      width: 100% !important;
    }
  }

  .ml-4 {
    margin: 0 !important;
    padding: 0 !important;
  }
</style>
