<template>
	<div>
		<dev class="tree-select" @click="showDialog">
			{{valueTitle||'请选择'}}</dev>
		<el-dialog
			v-model="dialogVisible"
			title="树选择"
			:before-close="beforeClose"
			width="50%">
			<div class="search-container">
				<el-input @keydown.enter="search($event)" v-model="filterText"
					placeholder="关键字过滤" />
				<ElButton class="search-button" type="primary"
					@click="search($event)">
					搜索</ElButton>
			</div>
			<div
				v-loading="loading"
				class="tree-container">
				<el-tree
					ref="searchTree"
					v-show="!showCompleteTree"
					class="tree"
					:props="treeSelectProps"
					:data="searchTreeData"
					default-expand-all
					node-key="value"
					check-on-click-node
					:current-node-key="selectedKey"
					check-strictly
					show-checkbox
					highlight-current
					:expand-on-click-node="false"
					@check-change="checkChange"
					@node-click="currentChange" />
				<el-tree v-show="showCompleteTree" class="tree"
					v-if="rootLoaded"
					ref="completeTree"
					:props="treeSelectProps"
					:load="load"
					lazy
					node-key="value"
					check-on-click-node
					:current-node-key="selectedKey"
					check-strictly
					show-checkbox
					highlight-current
					:expand-on-click-node="false"
					@check-change="checkChange"
					@node-click="currentChange" />
			</div>

			<template #footer>
				<span class="dialog-footer">
					<el-button @click="closeDialog">取消</el-button>
					<el-button type="primary" @click="confirm">
						确定
					</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script setup lang='ts'>
import { ref, defineProps, defineEmits, watch, computed } from 'vue';

import Node from 'element-plus/lib/components/tree/src/model/node.js';
import { TreeNode } from 'element-plus/lib/components/tree-v2/src/types.js';

import { formatData, list2Tree } from './helper';
import { DF, FormatData, TreeItem } from './type';

interface Props{
	value:string,
	df:DF,
	queries:any
	treeControl:any
}

const props = defineProps<Props>();
interface Emit{
	(e:'change', data:string):void
	(e:'focus'):void
}
const emit = defineEmits<Emit>();
const dialogVisible = ref<boolean>(false);
const treeSelectValue = ref<string>(props.value);
const searchTreeData = ref<FormatData[]>([]);
const rootTreeData = ref<FormatData[]>([]);
const filters = ref<any>();
const valueTitle = ref<string>('');
const rootLoaded = ref<boolean>(false);
const filterText = ref<string>('');
const selectedKey = ref<string>(props.value);
const completeTree = ref<any>();
const searchTree = ref<any>();
const loading = ref<boolean>(false);

watch(()=>props.value, async ()=>{
	treeSelectValue.value = props.value;
	selectedKey.value = props.value;
	if (!props.df.options||!props.value){
		valueTitle.value='';
		return;
	}
	const res = await frappe.call<{message:string}>({
			method: 'guigu_pm.form.controls.tree_select.get_value_info',
			args:{
				doctype:props.df.options,
				name:props.value,
			},
		});
	valueTitle.value = res?.message||'';
});
watch([()=>props.value, searchTree, completeTree], ()=>{
	if (searchTree.value){
		searchTree.value.setCheckedKeys([props.value]);
	}
	if (completeTree.value){
		completeTree.value.setCheckedKeys([props.value]);
	}
});
watch(()=>props.queries, async ()=>{
	filters.value = props.queries?.filters;
	rootTreeData.value = [];
	rootLoaded.value = false;
	const res = await loadRoot();
	rootTreeData.value = res;
	rootLoaded.value = true;
}, {immediate:true});

function confirm(){
	dialogVisible.value = false;
	emit('change', selectedKey.value);
}
const showCompleteTree = computed(()=> filterText.value === '');
watch(filterText, ()=>{
	if (!filterText.value){
		searchTreeData.value=[];
	}
});
async function search(event:Event){
	event.stopPropagation();
	event.preventDefault();
	loading.value = true;
	if (!filterText.value){
		searchTreeData.value = [];
		loading.value = false;
		return;
	}
	const filterData = await frappe.call<{message:{data:TreeItem[], title_field:string, parent_field:string}}>({
			method: 'guigu_pm.form.controls.tree_select.get_filter_data',
			args:{
				doctype:props.df.options,
				filter_value:filterText.value,
				filters:filters.value,
			},
	});
	const treeItems = formatData(filterData?.message.data||[], filterData?.message.title_field);
	if (!filterData?.message?.parent_field){ loading.value = false; return; }
	const treeData = list2Tree(treeItems, filterData.message?.parent_field);
	searchTreeData.value = treeData;
	loading.value = false;
}

function showDialog(){
	dialogVisible.value = true;
	emit('focus');
}
async function loadRoot(){
	const rootTreeData = await frappe.call<{message:{data:TreeItem[], title_field:string}}>({
			method: 'guigu_pm.form.controls.tree_select.load_tree_options',
			args:{
				doctype:props.df.options,
				filters:filters.value,
			},
		});

	const treeItems = formatData(rootTreeData?.message.data||[], rootTreeData?.message.title_field);
	return treeItems;
}


const treeSelectProps = {
  label: 'label',
  children: 'children',
  isLeaf: 'isLeaf',
};

async function load(node:Node, resolve:(childData: FormatData[]) => void){
	if (node.level === 0){ return resolve(rootTreeData.value); }
	if (node?.isLeaf) { return resolve([]); }
	if (!props.df.options){ return resolve([]); }
	const childData = await frappe.call<{message:{data:TreeItem[], title_field:string}}>({
			method: 'guigu_pm.form.controls.tree_select.load_tree_options',
			args:{
				doctype:props.df.options,
				parent_name:node?.data?.value,
				filters:filters.value,
			},
		});
	const treeItems = formatData(childData?.message.data||[], childData?.message.title_field);
	resolve(treeItems);
}
function checkChange(data:FormatData, isChecked:boolean){
	if (isChecked){
		selectedKey.value = data.value;
		searchTree.value.setCheckedKeys([data.value]);
		completeTree.value.setCheckedKeys([data.value]);
	} else {

		const checkedKeys:string[] = completeTree.value.getCheckedKeys();
		const index =  checkedKeys.findIndex(item=>item===data.value);
		if (index>-1){
			checkedKeys.splice(index, 1);
		}
		selectedKey.value = checkedKeys.length>0?checkedKeys[0]:'';
		searchTree.value.setCheckedKeys(checkedKeys);
		completeTree.value.setCheckedKeys(checkedKeys);
	}
}
function currentChange(data:FormatData, node:Node, treeNode:TreeNode, event:any){
	if (node.checked){
		selectedKey.value = data.value;
		searchTree.value.setCheckedKeys([data.value]);
		completeTree.value.setCheckedKeys([data.value]);
	} else {
		selectedKey.value = '';
		searchTree.value.setCheckedKeys([]);
		completeTree.value.setCheckedKeys([]);
	}

}

function beforeClose(done:()=>void){
	if (searchTree.value){
		searchTree.value.setCheckedKeys([props.value]);
	}
	if (completeTree.value){
		completeTree.value.setCheckedKeys([props.value]);
	}
	done();
}
function closeDialog(){
	if (searchTree.value){
		searchTree.value.setCheckedKeys([props.value]);
	}
	if (completeTree.value){
		completeTree.value.setCheckedKeys([props.value]);
	}
	dialogVisible.value = false;
}
</script>

<style lang='less' scoped>
.tree-select {
	width: 100%;
	display: inline-block;
	cursor: pointer;
}

.search-container {
	display: flex;

	.search-button {
		margin-left: 8px;
	}
}

.tree {
	:deep(label) {
		margin-bottom: 0px;
	}
}

.tree-container {
	max-height: 500px;
	overflow-y: scroll;
}
</style>
