<template>
	<ElFormItem :label="tt('Link Field')">
		<ElSelect v-model="linkField" filterable defaultFirstOption clearable>
			<ElOption v-for="field in linkFields" :key="field.value"
				:value="field.value" :label="field.label" />
		</ElSelect>
	</ElFormItem>
	<ElFormItem :label="tt('Max Limit')">
		<ElInputNumber v-model="maxLimit" />
	</ElFormItem>
	<ElFormItem :label="tt('Default Limit')">
		<ElInputNumber v-model="defaultLimit" />
	</ElFormItem>
	<ElFormItem>
		<ElCheckbox v-model="infiniteScroll" :label="tt('Infinite Scroll')" />
	</ElFormItem>
	<ElFormItem :label="tt('Number Of Fields Fixed')">
		<ElInputNumber v-model="fixedFieldColumns" />
	</ElFormItem>
	<ElFormItem>
		<ElCheckbox v-model="showSummaryRow" :label="tt('Show Summary Rows')" />
	</ElFormItem>
	<ElFormItem>
		<ElCheckbox v-model="tree" :label="tt('Display In A Tree Structure')" />
	</ElFormItem>
	<ElFormItem v-if="!tree">
		<ElCheckbox v-model="showLineNumber" :label="tt('Display Line Number')" />
	</ElFormItem>
	<ElFormItem :label="tt('Additional Request Fields')">
		<ElSelect v-model="fieldsRequested" multiple filterable defaultFirstOption
			clearable style="width: 100%">
			<ElOption v-for="field in fields" :key="field.value"
				:value="field.value" :label="field.label" />
		</ElSelect>
	</ElFormItem>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import {ElSelect, ElOption, ElInputNumber, ElFormItem, ElCheckbox} from 'element-plus';

const props = defineProps<{
	meta: locals.DocType;
	modelValue: any;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: GlobalView.View): void;
}>();
const view = computed({
	get: () => props.modelValue,
	set: v => emit('update:modelValue', v),
});

const tt = __;

const linkFields = computed(() => (props.meta.fields || [])
	.filter(d => ['Link', 'Dynamic Link'].includes(d.fieldtype))
	.map(function toSelect(d) {
		return {
			value: d.fieldname,
			label: `${__(d.label)} (${d.fieldname})`,
		};
	}));
const linkField = computed({
	get: () => view.value.linkField,
	set: v => {
		view.value = {...view.value, linkField: v || null};
	},
});
const maxLimit = computed({
	get: () => view.value.maxLimit || 0,
	set: v => {
		view.value = {...view.value, maxLimit: v || 0};
	},
});
const defaultLimit = computed({
	get: () => view.value.defaultLimit || 0,
	set: v => {
		view.value = {...view.value, defaultLimit: v || 0};
	},
});
const infiniteScroll = computed({
	get: () => view.value.infiniteScroll,
	set: infiniteScroll => {
		view.value = {...view.value, infiniteScroll};
	},
});

const fixedFieldColumns = computed({
	get: () => view.value.fixedFieldColumns || 0,
	set: v => {
		view.value = {...view.value, fixedFieldColumns: v || 0};
	},
});
const tree = computed({
	get: () => Boolean(view.value.tree),
	set: tree => {
		view.value = {...view.value, tree};
	},
});
const showLineNumber = computed({
	get: () => Boolean(view.value.showLineNumber),
	set: showLineNumber => {
		view.value = {...view.value, showLineNumber};
	},
});
const showSummaryRow = computed({
	get: () => Boolean(view.value.showSummaryRow),
	set: showSummaryRow => {
		view.value = {...view.value, showSummaryRow};
	},
});
const fieldsRequested = computed({
	get: () => view.value.fieldsRequested || [],
	set: v => {
		view.value = {...view.value, fieldsRequested: v};
	},
});
const fields = computed(() => (props.meta.fields || [])
	.filter(d => !frappe.model.no_value_type.includes(d.fieldtype))
	.map(function toSelect(d) {
		return {
			value: d.fieldname,
			label: `${__(d.label)} (${d.fieldname})`,
		};
	}));
</script>
