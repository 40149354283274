import type { VueColumn } from './nyloongTable/vue';
import Comp from './Comp.vue';

export default function getColumns(
	meta: locals.DocType,
	setFilter: (field: string, operator: string, value: any) => void,
	fields: GlobalView.DisplayField[],
): VueColumn[] {
	return fields.map(({
		fieldOptions: docField, width = 200, maxWidth, minWidth, enableLink,
	}) => ({
		title: __(docField.label || 'Field'),
		field: docField.fieldname,
		resizable: true,
		width: width || undefined,
		maxWidth: maxWidth || undefined,
		minWidth: minWidth || undefined,
		component: Comp,
		meta: { docField, meta, setFilter, enableLink },
	}));
}
