<template>
	<el-dialog
	  v-model="visible"
	  :title="tt('Related BOQ')"
	  width="50%"
	  :before-close="handleClose"
	  custom-class="progress-dialog"
	>
		<div>
			<ElForm ref="formRef" :model="form" :rules="rules" label-width="120px">
				<ElFormItem :label="tt('Boq')" prop="boqItem">
					<ElTreeSelect
						v-model="form.boqItem"
						:data="boqTree"
						:props="defaultProps"
						default-expand-all
						check-strictly
						:filter-node-method="filterNodeMethod"
						filterable
					></ElTreeSelect>
				</ElFormItem>
				<ElFormItem v-show="!isTotalPriceProject" :label="tt('Coefficient')" prop="coefficient">
					<ElInputNumber v-model="form.coefficient"></ElInputNumber>
				</ElFormItem>
				<ElFormItem v-show="isTotalPriceProject" :label="tt('Comprehensive unit price')" prop="price">
					<ElInputNumber v-model.number="form.price"></ElInputNumber>
				</ElFormItem>
			</ElForm>
		</div>
	  <template #footer>
		  <span class="dialog-footer">
			<ElButton @click="handleClose">{{ tt('Cancel') }}</ElButton>
			<ElButton type="primary" @click="confirm">
			  {{ tt('Confirm') }}
			</ElButton>
		  </span>
	  </template>
	</el-dialog>
</template>

<script setup lang='ts'>
import { ref, defineProps, defineEmits, reactive, toRefs, watch, computed } from 'vue';
import { FormInstance } from 'element-plus/lib/components/index.js';

import { list2Tree } from '../../../../../utils/index';
import { BoqFormat } from '../../type/boq';
import { OriginStructureProgress, StructureProgressBoq } from '../../type/StructureProgress';
const tt = __
interface Props{
	visible:boolean,
	project:string,
	structureProgress?:OriginStructureProgress
}
const props = defineProps<Props>();
const {
	visible,
	project,
	structureProgress,
} = toRefs(props);
interface Emit{
	(e:'close'):void
	(e:'confirm', formData: StructureProgressBoq):void
}
const emit = defineEmits<Emit>();
const formRef = ref<FormInstance>();
const rules = reactive({
  boqItem: [
    { required: true, message:tt('Items Required') },
  ],
});

const form = reactive({
	boqItem:'',
	coefficient:undefined,
	price:undefined,
});
const defaultProps = {
  children: 'children',
  label: 'name',
  value:'id',
  disabled:(data: BoqFormat)=>structureProgress?.value?.boq.some(item=>item.boq_item===data.id)||!data.isBoq,
};
const boqItems=ref<BoqFormat[]>([]);
const isTotalPriceProject = computed(()=>{
	const boq = boqItems.value.find(item=>item.id === form.boqItem);
	return boq?.isTotalPriceProject;
});

watch(project, async ()=>{
	const boqResult = await frappe.db.get_list('Guigu Boq Item', {
		filters:{
			project:project.value,
		},
		fields:[
			'name', 'boq_item_full_name', 'parent_guigu_boq_item', 'name', 'is_boq', 'is_total_price_project', 'guigu_boq_unit', 'top_unit_price',
		],
		limit:'',
	});
	const boqFormatData = boqResult.map(item=>({
		id:item.name,
		parent:item.parent_guigu_boq_item,
		name:item.boq_item_full_name,
		isBoq:item.is_boq,
		isTotalPriceProject: item.is_total_price_project,
		topUnitPrice:item.top_unit_price,
		unit:item.guigu_boq_unit||'',
	}));
	boqItems.value = boqFormatData;
}, {immediate:true});
const boqTree = computed(()=>list2Tree(boqItems.value));

async function confirm(){
	if (!formRef.value){
		return;
	}
	formRef.value.validate(async (valid:boolean, fields:any[])=>{
		if (valid){
			const boqItem = boqItems.value.find(item=>item.id === form.boqItem);
			let unit;
			if (boqItem?.unit){
				unit = await frappe.db.get_doc('Guigu Boq Unit', boqItem.unit);
			}

			const data = {
				structure_progress:structureProgress?.value?.structure_progress_name!,
				boq_item:form.boqItem,
				price: boqItem?.isTotalPriceProject?form.price:boqItem?.topUnitPrice,
				coefficient: form.coefficient||1,
				boq_item_title:boqItem?.name,
				unit:unit?.boq_unit_name,
				is_total_price_project: boqItem?.isTotalPriceProject,
			};
			formRef.value?.resetFields();
			emit('confirm', data);
		}
	});
}
function handleClose(){
	emit('close');
}
function filterNodeMethod(value:string, data:BoqFormat){
	return data.name.includes(value);
}
</script>

<style lang='less' scoped>

</style>
