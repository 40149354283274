import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-652fee50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode($setup["Main"], {
        columns: $setup.columns,
        rows: $setup.rows,
        parentKey: $setup.parentKey,
        dateFields: $setup.dateFields,
        endDateFields: $setup.endDateFields,
        lines: $setup.lines,
        dots: $setup.dots,
        checked: $setup.checkedIds,
        "onUpdate:checked": _cache[0] || (_cache[0] = $event => (($setup.checkedIds) = $event)),
        expanded: $setup.expandedIds,
        "onUpdate:expanded": _cache[1] || (_cache[1] = $event => (($setup.expandedIds) = $event)),
        startFixed: $setup.startFixed,
        rowHeight: $setup.rowHeight,
        tooltip: $setup.tooltip,
        view: $props.view
      }, null, 8 /* PROPS */, ["columns", "rows", "parentKey", "dateFields", "endDateFields", "lines", "dots", "checked", "expanded", "startFixed", "rowHeight", "tooltip", "view"])
    ]),
    _renderSlot(_ctx.$slots, "pagination", {}, undefined, true)
  ], 64 /* STABLE_FRAGMENT */))
}