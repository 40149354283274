<template>
	<template v-for="{ values, field }, g in groups">
		<template v-for="_ in width / widths[g] / (values.length || 1)">
			<template v-for="value, i in values">
				<Title :class="['header', { ['column']: cc }]"
					:style="getStyle(value, widths[g], g, (_ - 1) * (values.length || 1) + i)"
					:title="value.title || value.value"
					:n="getList(g, ((_ - 1) * (values.length || 1) + i) * widths[g])"
					@setting="edit(field, value)" />
			</template>
		</template>
	</template>
</template>

<script lang="ts" setup name="KanbanBoardRowHeader">
import {computed} from 'vue';

import type {Column, Group} from '../../types';

import Title from './Title.vue';
const props = defineProps<{
	groups: Group[];
	widths: number[];
	width: number;
	start: number
	cc?: boolean;
	data: any[];
}>();

const emit = defineEmits<{
	(event: 'setting', field: string, value: Column): void
}>();
function edit(field: string, value: Column) {
	emit('setting', field, value);
}
function toValue(v: any) {
	return ['string', 'number'].includes(typeof v) ? v : '';
}
function getList(g: number, index: number) {
	const {widths, groups} = props;
	const pp: [string, any][] = [];
	for (let i = 0; i <= g; i++) {
		const {values, field} = groups[i];
		const s = Math.floor(index / widths[i]) % values.length;
		pp.push([field, values[s].value]);
	}
	return props.data.filter(d => !pp.find(([k, v]) => toValue(d[k]) !== v)).length;
}
const styleProp = computed(() => props.cc ? 'grid-column' : 'grid-row');
const styleProp2 = computed(() => props.cc ? 'grid-row' : 'grid-column');
function getStyle(value: Column, width: number, g: number, index: number) {
	const begin = index * width + props.start + 1;
	const end = begin + width;
	const style = `
	${styleProp2.value}: ${begin} / ${end};
	${styleProp.value}: ${g + 1} / ${g + 2};
	--index: ${g};
	background-color: ${value.bgColor};
	color: ${value.color};
	`;
	return style;
}

</script>


<style lang="less" scoped>
.header {
	border: 1px solid #EEEEEE;
	position: sticky;
	justify-content: space-between;
	display: flex;
	overflow: hidden;
	font-size: 14px;
	padding: 10px;
	line-height: calc(var(--col-group-width) - 22px);
	inset-block-start: calc(var(--index) * var(--col-group-width));
	background-color: var(--bg-color);
}

.column {
	// writing-mode: vertical-lr;
	min-block-size: 100px;
	inset-block-start: auto;
	inset-inline-start: calc(var(--index) * var(--row-group-width));
}
</style>
