<template>
	<span class="ck-content" v-html="tt(value?.toString() || '')"></span>
</template>

<script setup lang='ts'>
const tt = __;

interface Props {
	data: Record<string, any>;
	field: GlobalView.FieldRenderOptions;
	format?: string;
	value: any

}
const props = defineProps<Props>();
</script>

<style lang='less' scoped>
</style>
