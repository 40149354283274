import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.props.params.data.applicable)
    ? (_openBlock(), _createBlock($setup["ElButton"], {
        key: 0,
        type: "text",
        size: "small",
        onClick: _cache[0] || (_cache[0] = $event => ($setup.props.params.openNoticeDialog($setup.props.params.data.name)))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.tt('Inapplicability')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }))
    : _createCommentVNode("v-if", true)
}