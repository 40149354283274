import * as api from '@vue/composition-api'
import VueCompositionApi from '@vue/composition-api'
import Vue from 'vue';

Vue.use(VueCompositionApi);

frappe.provide('frappe.guigu');
frappe.guigu.vueApi = api
for (const p of frappe.guigu.vueExports || []) {
	Object.assign(p, api);
}
