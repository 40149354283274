export default function showTip() {
	const { protocol, host, pathname, hostname, search, hash } = location;
	const home = `${protocol}//${host}/pmplus/`;
	const key = 'No more hints(to PM+ tip)';

	if (hostname === frappe?.boot?.sitename) {
		return;
	}
	if (pathname !== '/app' && !pathname.startsWith('/app/')) {
		return;
	}

	if (localStorage.getItem(key)) {
		return;
	}

	const body = document.createElement('div');
	body.style.padding = '0 10px 10px';
	body.style.textAlign = 'right';
	body.style.marginTop = '-10px';
	const noMoreHints = body.appendChild(document.createElement('button'));
	noMoreHints.className = 'btn btn-secondary btn-default btn-sm';
	noMoreHints.appendChild(document.createTextNode('不再提示'));
	noMoreHints.style.marginInline = '8px';
	noMoreHints.addEventListener('click', () => {
		localStorage.setItem(key, '1');
	});
	const btn = body.appendChild(document.createElement('a'));
	btn.href = `${pathname.replace('app', 'pmplus')}${search}${hash}`;
	btn.target = '_blank';
	btn.className = 'btn btn-secondary btn-default btn-sm';
	btn.appendChild(document.createTextNode('前往 PM+'));

	const subtitleLink = `<a href="${home}" target="_blank">${home}</a>`;

	const alert = frappe.show_alert({
		message: 'PM+ 网址已切换',
		subtitle: `网址已切换到 ${subtitleLink}<br />如果有浏览器收藏(书签)或桌面快捷方式等，请及时修改。`,
		body,
	}, 60);
	const remove = (e: StorageEvent) => {
		if (e.storageArea !== localStorage) { return; }
		if (e.key !== key) { return; }
		if (!e.newValue) { return; }
		removeEventListener('storage', remove);

		alert.addClass('out');
		setTimeout(() => alert.remove(), 800);
	};
	addEventListener('storage', remove);
}
