<script lang="ts" setup>
import {computed, reactive, ref, watch} from 'vue';
import {
	ElInput, ElButton, ElOption, ElSelect,
	ElForm, ElFormItem, ElAlert,
} from 'element-plus';
import {Plus, Finished, Close} from '@element-plus/icons-vue';

import allViews from '../../extends/Views';
import {Filter} from '../../GlobalView/types';
import build_options from '../../components/GuiguFilterDialog/build_options';
import Item from '../../components/GuiguFilterDialog/Item.vue';
import loadLinkDocTypes from '../../utils/loadLinkDocTypes';

import DoctypeSelect from './DoctypeSelect.vue';

interface Option {
	value: string;
	label: string;
	filter?: boolean;
	component?: any;
}

const views = Object.entries(allViews)
	.map(([k, v]) => {
		const link = v?.link;
		if (!link) {
			return;
		}
		const label = v?.title || __(frappe.utils.to_title_case(k));
		if (typeof link !== 'object') {
			return {value: k, label};
		}
		const {component, filter} = link;
		return {value: k, label, component, filter};
	}).filter(Boolean as any as (v: any) => v is Option);
const viewMap = new Map(views.map(v => [v.value, v]));

const props = defineProps<{
	value?: any;
}>();
const emit = defineEmits<{
	(event: 'update', data: any, submittable?: boolean): void;
	(event: 'cancel' | 'submit'): void;
}>();


const doctype = ref<string>(props.value?.doctype || '');
const label = ref<string>(props.value?.label || '');
const view = ref<string>(props.value?.view || '');
const option = ref<any>(props.value?.option || undefined);
const doctypeLabel = ref('');
watch(doctypeLabel, (v, o) => {
	if (!v) {
		return;
	}
	const l = label.value;
	if (!l || l === o) {
		label.value = v;
	}
});
const currentView = computed(() => viewMap.get(view.value));
let nextId = 1;
const filters = ref<Filter[]>((props.value?.filters || []).map((f: Filter[]) => ({
	...f,
	key: nextId++,
})));
watch(doctype, () => {
	filters.value = [];
});
watch([doctype, label, view, option, filters], ([doctype, label, view, option, filters]) => {
	emit('update', {doctype, label, view, option, filters}, Boolean(doctype));
}, {immediate: true, deep: true});

function cancel() {
	emit('cancel');
}
function submit() {
	emit('submit');
}
const optionComponent = computed(() => currentView.value?.component);
watch(optionComponent, () => {
	option.value = undefined;
});
const filterable = computed(() => currentView.value?.filter);
watch(filterable, filterable => {
	if (filterable) {
		return;
	}
	filters.value = [];
});
const meta = ref<locals.DocType>();

watch([doctype, filterable], async ([doctype, filterable]) => {
	meta.value = undefined;
	if (!doctype || !filterable) {
		return;
	}
	let m = frappe.get_meta(doctype);
	if (!m) {
		await new Promise<void>(resolve => {
			frappe.model.with_doctype(doctype, () => {
				resolve();
			});
		});
		m = frappe.get_meta(doctype);
		if (!m) {
			return;
		}
	}
	await loadLinkDocTypes(m);
	meta.value = m;
}, {immediate: true});

const fields = computed(() => {
	const m = meta.value;
	if (!m) {
		return [];
	}
	return build_options(m.name);
});
function add() {
	filters.value.push({
		key: nextId++,
		doctype: doctype.value,
		field: 'name',
	});
}
function remove(filter: Filter) {
	const index = filters.value.indexOf(filter);
	if (index >= 0) {
		filters.value.splice(index, 1);
	}
}
const tt = __;
const title = computed(() => `${__('DocType Link')} 已被废弃，请用 ${__('View Link')} 代替`);
</script>

<template>
	<ElForm labelPosition="top">
		<ElAlert :title="title" type="warning" />
		<ElFormItem :label="tt('DocType')">
			<DoctypeSelect v-model="doctype" @label="doctypeLabel = $event" />
		</ElFormItem>

		<ElFormItem :label="tt('Label')">
			<ElInput v-model="label" />
		</ElFormItem>
		<ElFormItem :label="tt('Select View')">
			<ElSelect v-model="view" filterable>
				<ElOption v-for="v in views"
					:key="v.value"
					:value="v.value"
					:label="v.label" />
			</ElSelect>
		</ElFormItem>
		<ElFormItem :label="tt('Options')" v-if="optionComponent">
			<component :is="optionComponent" :doctype="doctype" v-model="option" />
		</ElFormItem>

		<ElFormItem v-if="meta" :label="tt('Filter')">
			<template #label="{ label }">
				{{ label }}
				<ElButton @click="add" title="添加" :icon="Plus" />
			</template>
			<Item v-for="filter in filters" :key="filter.key"
				:fields="fields"
				:meta="meta"
				withSession
				v-model:doctype="filter.doctype"
				v-model:field="filter.field"
				v-model:condition="filter.condition"
				v-model:value="filter.value"
				@remove="remove(filter)" />
		</ElFormItem>
	</ElForm>
</template>
