import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f983c0b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Page"], null, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.tt('Project leader work log viewing page')), 1 /* TEXT */)
    ]),
    tools: _withCtx(() => [
      _createVNode($setup["ToolBar"], {
        subCompany: $setup.subCompany,
        "onUpdate:subCompany": _cache[0] || (_cache[0] = $event => (($setup.subCompany) = $event)),
        designations: $setup.selectedDesignations,
        "onUpdate:designations": _cache[1] || (_cache[1] = $event => (($setup.selectedDesignations) = $event)),
        date: $setup.date,
        "onUpdate:date": _cache[2] || (_cache[2] = $event => (($setup.date) = $event)),
        showComment: $setup.showComment,
        "onUpdate:showComment": _cache[3] || (_cache[3] = $event => (($setup.showComment) = $event)),
        searchString: $setup.searchString,
        "onUpdate:searchString": _cache[4] || (_cache[4] = $event => (($setup.searchString) = $event)),
        onSearch: $setup.search,
        onClearSearch: $setup.clearSearch
      }, null, 8 /* PROPS */, ["subCompany", "designations", "date", "showComment", "searchString"])
    ]),
    sider: _withCtx(() => [
      _createVNode($setup["ProjectList"], {
        projectList: $setup.projectList,
        projectLoading: $setup.loading,
        onProjectChange: $setup.projectChange
      }, null, 8 /* PROPS */, ["projectList", "projectLoading"])
    ]),
    default: _withCtx(() => [
      ($setup.doctypeLoaded)
        ? (_openBlock(), _createBlock($setup["DiaryTimeline"], {
            key: 0,
            project: $setup.selectedProject,
            "onUpdate:project": _cache[5] || (_cache[5] = $event => (($setup.selectedProject) = $event)),
            designations: $setup.selectedDesignations,
            searchString: $setup.searchString,
            date: $setup.date,
            showComment: $setup.showComment,
            ref: "diaryTimelineRef"
          }, null, 8 /* PROPS */, ["project", "designations", "searchString", "date", "showComment"]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }))
}