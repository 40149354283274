import type { DateGetter, Line, LineMeta } from './types';


export interface LineInfo extends Line {
	start: DateGetter;
	end: DateGetter;
}

export default function getLineDates(
	data: any,
	dateDate: Record<string, Date | undefined>,
	endDateDate: Record<string, Date | undefined>,
	lines: LineInfo[],
) {
	const dates: ([Date, Date | null, LineMeta?] | null)[] = lines.map(({ start, end, meta }) => {
		let s = start(data, dateDate, endDateDate);
		if (!s) { return null; }
		return [s, end(data, dateDate, endDateDate) || null, meta];
	});
	return dates;

}
