import type { ColumnCell, CustomizeComponent } from '../types';
import { defaultRowHeight } from '../defaultConfig';
import { verticalWritingMode } from '../verticalWritingMode';
import type Group from '../Group';
import type { Row, RowDataProxy } from '../types/Row';

import render from './render';
import hideAll from './hideAll';
import findRow from './findRow';
import findCol from './findCol';


function getBoundingClientRect(root: HTMLElement, getClientRects: () => Iterable<DOMRect>): [
	[number, number, number],
	[number, number, number],
] {
	let view: [number, number, number, number] = [0, 0, window.innerWidth, window.innerHeight];
	for (const { left, top, right, bottom } of getClientRects()) {
		view = [
			Math.max(view[0], left),
			Math.max(view[1], top),
			Math.min(view[2], right),
			Math.min(view[3], bottom),
		];
	}
	const { left, top, width, height } = root.getBoundingClientRect();
	const right = view[2] - left - width;
	const bottom = view[3] - top - height;
	return [
		[-left + view[0], width, -right],
		[-top + view[1], height, -bottom],
	];

}
interface ColumnState {
	fixed: boolean;
	hidden: boolean;
	start?: number;
	width?: number;
}
function createCustomize(column: ColumnCell): [CustomizeComponent, ColumnState] | null {
	const customizeComponent = column.customize({ column: column.options }) || null;
	if (!customizeComponent) { return null; }
	if (customizeComponent) {
		customizeComponent.root.classList.add('nyloong-table-customize');
	}
	return [customizeComponent, { fixed: false, hidden: false }];
}
export default class Body {
	readonly root: HTMLElement;
	readonly #main: HTMLElement = document.createElement('div');
	#getClientRects = (): Iterable<DOMRect> => [];
	get getClientRects() { return this.#getClientRects; }
	set getClientRects(v) {
		if (this.#destroyed) { return; }
		this.#getClientRects = v;
		this.requestRender();
	}
	#rowHeight: number = 0;
	get rowHeight() { return this.#rowHeight; }
	set rowHeight(v) {
		if (this.#destroyed) { return; }
		this.#rowHeight = v;
		this.root.style.setProperty('--nyloong-table-row-height', `${v}px`);
		this.requestRender();
	}
	#group: Group;
	#remove: () => void;
	constructor(
		root: HTMLElement | undefined,
		group: Group,
		remove: () => void,
	) {
		this.#remove = remove;
		this.#group = group;
		const body = root || document.createElement('div');
		this.root = body;
		const main = body.appendChild(this.#main);
		body.classList.add('nyloong-table', 'nyloong-table-body');
		main.classList.add('nyloong-table-main');
		this.rowHeight = defaultRowHeight;
	}
	#shownRows: RowDataProxy[] = [];

	#customizeMap = new Map<ColumnCell, [CustomizeComponent, ColumnState] | null>();
	#customizeList: ([CustomizeComponent, ColumnState] | null)[] = [];
	_updateColumns(columns: ColumnCell[]) {
		const oldMap = this.#customizeMap;
		const newMap = new Map<ColumnCell, [CustomizeComponent, ColumnState] | null>();
		const list: ([CustomizeComponent, ColumnState] | null)[] = [];
		this.#customizeList = list;
		this.#customizeMap = newMap;
		const body = this.root;
		for (const column of columns) {
			const c = oldMap.get(column);
			oldMap.delete(column);
			const cs = c === undefined ? createCustomize(column) : c;
			newMap.set(column, cs);
			list.push(cs);
			if (!cs) { continue; }
			const [component] = cs;
			body.appendChild(component.root);
			component.update?.(column.options);
		}
		for (const c of oldMap.values()) {
			if (!c) { continue; }
			const [component] = c;
			component.root.remove();
			component.destroy();
		}

	}
	_hide() {
		this.#shownRows = hideAll(this.#shownRows);
	}
	#destroyed = false;
	destroy() {
		if (this.#destroyed) { return; }
		this.#destroyed = true;
		this.#remove();
		this._hide();
		for (const c of this.#customizeList) {
			if (!c) { continue; }
			const [component] = c;
			component.root.remove();
			component.destroy();
		}
		// TODO:
	}
	#lastSize = '';
	paused = false;
	requestRender() {
		if (this.#destroyed) { return; }
		this.#lastSize = '';
		this.#group.requestRender(this);
	}
	_render(
		columns: ColumnCell[],
		rowData: Row[],
		rowMap: Map<string | number, Row>,
		visibleRowIndexes: number[],
		hoverId: string | number | undefined,
		selectedId: string | number | undefined,
		checkedSet: Set<string | number>,
		separate: number[],
		startFixed: number,
		force = false,
	) {
		if (this.#destroyed) { return; }
		if (this.paused) { return; }
		const { root } = this;
		const style = getComputedStyle(root);
		const writingMode = style.writingMode?.toLowerCase();
		const vertical = verticalWritingMode.has(writingMode);
		const size = getBoundingClientRect(root, this.getClientRects);
		if (vertical) { size.reverse(); }
		if (['vertical-rl', 'sideways-rl'].includes(writingMode)) {
			size[1].reverse();
		}
		const rtl =
			(style.direction.toLowerCase() === 'rtl')
			!== (writingMode === 'sideways-lr');
		if (rtl) { size[0].reverse(); }

		const lastSize = size.toString();
		if (!force && this.#lastSize === lastSize) { return; }
		this.#lastSize = lastSize;

		const [c, r] = size;
		const rowHeight = this.#rowHeight;
		const row = findRow(rowHeight, r[0], r[1] - r[2], rowData.length);
		if (!row) { return this._hide(); }
		renderCustomize(columns, this.#customizeList);


		const col = findCol(separate, c[0], c[1] - c[2]);

		if (!col) { return this._hide(); }

		this.#shownRows = render(
			row,
			col,
			rowData,
			rowMap,
			visibleRowIndexes,
			startFixed,
			this.#shownRows,
			hoverId,
			selectedId,
			checkedSet,
			rowHeight,
			columns,
			this.#main,
		);
	}
}

function renderCustomize(
	columns: ColumnCell[],
	list: ([CustomizeComponent, ColumnState] | null)[],
) {
	let i = 0;
	for (const { width, hidden, start, fixed } of columns) {
		const item = list[i];
		i++;
		if (!item) { continue; }
		const [{ root }, state] = item;

		if (fixed !== state.fixed) {
			state.fixed = fixed;
			if (fixed) {
				root.classList.add('nyloong-table-customize-fixed');
			} else {
				root.classList.remove('nyloong-table-customize-fixed');
			}
		}
		if (hidden !== state.hidden) {
			state.hidden = hidden;
			root.hidden = hidden;
		}
		if (hidden) { continue; }
		if (state.start !== start) {
			state.start = start;
			root.style.setProperty('--nyloong-table-column-start', `${start}px`);
		}
		if (state.width !== width) {
			state.width = width;
			root.style.inlineSize = `${width}px`;
		}
	}
}
