<template>
	<div class="page">
		<Page :mainStyle="{display:'flex', flexDirection:'column'}">
			<template #title>
				<h3 class="title">{{ tt('Statistics of project leadership work logs') }}</h3>
			</template>
			<template #tools>
				<ToolBar
					v-model:subCompany="selectedSubCompany"
					v-model:designations="selectedDesignations"
					v-model:startDate="selectedStartDate"
					v-model:endDate="selectedEndDate"
					v-model:refreshValue="refreshValue"
					v-model:loading="loading"
					:size="size"
					:detailGridApi="detailGridApi"
				>
				</ToolBar>
			</template>
			<div class="container">
				<SummaryTable :startDate="selectedStartDate"
					:endDate="selectedEndDate"
					:loading="loading"
					:summaryTableData="summaryTableData"
					:isPhone="size"
					@onDetailGridReady="onDetailGridReady"
				>
				</SummaryTable>
			</div>
			<AttendanceType
				:attendanceTypes="attendanceTypes" />
		</Page>
	</div>
</template>
<script lang="ts" setup>
import {ref, watch, onMounted, computed} from 'vue';
import moment from 'moment';
import {GridApi} from 'ag-grid-community';

import Page from '../../../../components/page/index.vue';

import ToolBar from './Toolbar.vue';
import SummaryTable from './SummaryTable.vue';
import type {ResultSummaryTableData, SummaryTableData, Project, Designation} from './type';
import {dailyRecordDataProcessing} from './helper';
import AttendanceType from './AttendanceType.vue';

const tt = __;
const selectedSubCompany = ref<string>('');
const selectedStartDate = ref<string>(moment().subtract(30, 'days').format('YYYY-MM-DD'));
const selectedEndDate = ref<string>(moment().format('YYYY-MM-DD'));
const selectedDesignations = ref<string[]>([]);
const refreshValue = ref<number>(0);
const loading = ref<boolean>(false);
const summaryTableData = ref<ResultSummaryTableData[]>([]);
const detailGridApi = ref<GridApi>();

const size = ref<'small'|'default'>('small');
const attendanceTypes = ref<any[]>([]);

function updateComponentSize() {
	const widthElement = window.innerWidth;
	if (widthElement <= 640) {
		size.value = 'default';
	} else {
		size.value = 'small';
	}
}

function onDetailGridReady(params: any) {
	detailGridApi.value = params;
}

const selectedDesignationsStr = computed(() => (selectedDesignations.value || []).join(','));

watch([
	selectedSubCompany,
	selectedStartDate,
	selectedEndDate,
	selectedDesignationsStr,
	refreshValue,
], async () => {
	if (!selectedSubCompany.value || !selectedStartDate.value || !selectedEndDate.value || !selectedDesignationsStr.value) {
		summaryTableData.value = [];
		return;
	}
	loading.value = true;
	const data = await frappe.call<{
		message:{
			diary_data: SummaryTableData[],
			user_order_arr: string[],
			user_obj: {[key: string]: string},
			designation_order_arr: string[],
			project_list: Project[],
			designation_list: Designation[],
			delayed_days: Number
		}
	}>({
		method: 'guigu_pm.guigu_pm_daily_record.page.project_leader_daily_record_statistics.project_leader_daily_record_statistics.get_diary_statistics',
		args: {
			sub_company: selectedSubCompany.value,
			start_date: selectedStartDate.value,
			end_date: selectedEndDate.value,
			designations: selectedDesignationsStr.value.split(','),
		},
	});
	const result = dailyRecordDataProcessing(
		data?.message?.diary_data || [],
		data?.message?.user_order_arr || [],
		data?.message?.project_list || [],
		data?.message?.designation_list || [],
		data?.message?.user_obj || {},
		data?.message?.delayed_days || 0,
	);
	summaryTableData.value = result;
	loading.value = false;
});

async function getAttendanceType() {
	const projectAttendanceType = await frappe.db.get_list(
		'Guigu Pm Daily Record Attendance Type',
		{
			filters: [],
			fields: ['*'],
			limit: 0,
		},
	);
	attendanceTypes.value = projectAttendanceType;
}

onMounted(() => {
	getAttendanceType();
	window.addEventListener('resize', updateComponentSize);
});
onMounted(() => {
	window.addEventListener('resize', updateComponentSize);
});
</script>
<style lang="less" scoped>
.container {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding:0;
}

:deep(.toggle-btn){
	display: none;
}
:deep(.tools){
	padding-top: 0 !important
}

.title {
	height: 75px;
	line-height: 75px;
	margin-bottom: 0;
}
</style>
