import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    ($setup.dataList.length)
      ? (_openBlock(), _createBlock($setup["Table"], {
          key: 0,
          smallMeta: $setup.smallMeta,
          dataList: $setup.dataList,
          loading: $setup.loading
        }, null, 8 /* PROPS */, ["smallMeta", "dataList", "loading"]))
      : (_openBlock(), _createBlock($setup["ElEmpty"], {
          key: 1,
          description: $setup.tt('No Records Created'),
          imageSize: 100
        }, null, 8 /* PROPS */, ["description"])),
    _withDirectives(_createVNode($setup["ElButton"], {
      class: "new_btn",
      type: "primary",
      size: "small",
      onClick: $setup.newAddTask
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($setup.tt('New Task')), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */), [
      [_vShow, $props.data.editable]
    ])
  ])), [
    [_directive_loading, $setup.loading]
  ])
}