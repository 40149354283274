import type {Ref} from 'vue';
import {shallowRef, computed, watch} from 'vue';

import getFiltersSearch from '../../utils/getFiltersSearch';
import {viewSettings} from '../../extends/init/defineMainView';
import type {Filter, View} from '../types';
import getFilters from '../getFilters';
import loadTree, {findNode, getChildren} from '../FilterField/loadTree';

import loadView from './loadView';
import createDoc from './createDoc';


function getOrganizationFilter(
	meta: locals.DocType,
	filters: Filter[],
	orFilters: Filter[][],
): Filter | null {
	const currentOrganization = frappe.tianjyOrganization?.getCurrent();
	if (!currentOrganization) {
		return null;
	}
	const field: string = meta.tianjy_organization_field as any;
	if (!field) {
		return null;
	}
	if (!meta.fields.find(v => v.fieldtype === 'Link' && v.fieldname === field)) {
		return null;
	}
	const doctype = meta.name;
	const isOrganizationFilter = (v: Filter) => v.doctype === doctype && v.field === field;
	if (filters.find(isOrganizationFilter)) {
		return null;
	}
	if (orFilters.find(l => l.find(isOrganizationFilter))) {
		return null;
	}
	const method = meta.tianjy_organization_filter_method;
	return {
		doctype,
		field,
		condition: method === 'Descendants' ? 'organization descendants' : 'organization',
		value: '~',
	};
}
export interface ViewCon {
	type: string;
	name: string;
	label?: string;
	position?: number;
	organizations?: string;
	default?: 0 | 1
	group?: string;
}

function getLayoutKey(name: string) {
	return `guigu:globalView:layout:${name}`;
}
export default function createItem(
	{type, name, label, position, organizations, default: def, group}: ViewCon,
	docMeta: Ref<locals.DocType>,
	defOrganization: Ref<string | undefined>,
): View | void {
	const setting = viewSettings.get(type);
	if (!setting) {
		return;
	}
	const {updateConfigurations, getConfigurations} = setting;
	/** 查询参数 */
	const filters = shallowRef<Filter[]>([]);
	const orFilters = shallowRef<Filter[][]>([]);
	const filterFieldValue = shallowRef<any>();


	const layout = shallowRef<string>(localStorage.getItem(getLayoutKey(name)) || '');


	let promise: Promise<[viewData: any, data: any]> | undefined;
	const view = shallowRef<GlobalView.View>();
	const configuration = shallowRef<any>();
	const organizationRef = shallowRef((organizations || '').split('\n').filter(Boolean));
	const hidden = computed(() => {
		const organizations = organizationRef.value;
		if (!organizations.length) {
			return false;
		}
		const def = defOrganization.value;
		if (!def) {
			return false;
		}
		return !organizations.includes(def);
	});

	const allFilters = computed(() => getFilters(filters.value));
	const filterFieldChildrenValue = shallowRef<any>([]);
	const filterList = shallowRef<any>([]);
	watch([()=>filterFieldValue.value, view], async ()=>{
		const field = view.value?.filterField;
		if (!field?.fieldname) {
			return;
		}
		const {value} = filterFieldValue;
		if (!value) {
			return;
		}
		let children = [value];

		if (field?.showChildren && (field.fieldtype === 'Link' || field.fieldtype === 'Tree Select' || field.fieldtype === 'Tianjy Related Link')) {
			let node = findNode(filterList.value, value);
			if (!node) {
				const list = await loadTree(field.options, field.filters, field.orFilters, field.order);
				filterList.value = list;
				node = findNode(list, value);
			}
			const childrenNode = node ? getChildren([node]) : [];
			children = childrenNode.map(item=>item.name);
		}
		filterFieldChildrenValue.value = children;
	}, {immediate: true, deep: true});
	const filterFieldFilter = computed<[string, string, string, any] | undefined>(() => {
		const field = view.value?.filterField?.fieldname;
		if (!field) {
			return;
		}
		const {value} = filterFieldValue;
		if (value === undefined) {
			return;
		}
		if (!value) {
			return [docMeta.value.name, field, 'is', 'not set'];
		}
		if (!filterFieldChildrenValue.value?.length) {
			return;
		}
		return [docMeta.value.name, field, 'in', filterFieldChildrenValue.value];
	});
	const queryFilters = computed(() => {
		const f = filterFieldFilter.value;
		if (filterFieldValue.value && !f) {
			return;
		}
		if (!f) {
			return allFilters.value;
		}
		return [...allFilters.value, f];
	});
	const queryQrFilters = computed(() => orFilters.value.map(v => v.map(({
		doctype, field, condition, value,
	}) => {
		if (Array.isArray(value)) {
			// eslint-disable-next-line no-param-reassign, max-nested-callbacks
			value = value.filter(v => v !== undefined && v !== null);
			if (!value.length) {
				return;
			}
		}
		if (value === undefined) {
			return;
		}
		if (condition) {
			return [doctype, field, condition, value];
		}
		return [doctype, field, Array.isArray(value) ? 'in' : '=', value];
	}).filter(Boolean) as [string, string, string, any][]).filter(Boolean));
	const loadingCount = shallowRef(0);
	const loading = computed(() => Boolean(loadingCount.value));


	const create = createDoc.bind(null, docMeta, queryFilters);

	const load = () => {
		loadingCount.value++;
		const p = loadView(setting, docMeta.value, name);
		promise = p.then(([vd, d]) => [vd, d]);
		const p2 = promise;
		p.then(([vd, , v, c]) => {
			if (p2 === promise) {
				view.value = v;
				organizationRef.value = (vd?.organization || '').split('\n').filter(Boolean);
				configuration.value = c;
				layout.value = localStorage.getItem(getLayoutKey(name)) || vd?.default_layout || '';
			}
			let filtersValue = filters.value;
			let orFiltersValue = orFilters.value;
			if (!filtersValue.length && !orFiltersValue.length) {
				filtersValue = v.filtersDefault.map(([
					doctype, field, condition, value,
				]) => ({
					doctype, field, condition, value,
				}));
				orFiltersValue = v.orFiltersDefault.map(v => v.map(([
					doctype, field, condition, value,
				]) => ({
					doctype, field, condition, value,
				})));
			}
			const organizationFilter = getOrganizationFilter(docMeta.value, filtersValue, orFiltersValue);
			if (organizationFilter) {
				filtersValue = [...filtersValue, organizationFilter];
			}
			filters.value = filtersValue;
			orFilters.value = orFiltersValue;
		}, () => {
			if (p2 === promise) {
				promise = undefined;
			}
		}).finally(() => {
			loadingCount.value--;
		});
		return promise;
	};
	const search = computed(() => {
		const dt = docMeta.value.name;
		const search = getFiltersSearch(dt, allFilters.value, orFilters.value);
		if (filterFieldValue.value) {
			search.unshift(`-=${encodeURIComponent(filterFieldValue.value)}`);
		}
		search.unshift(`=${encodeURIComponent(name)}`);
		return `?${search.filter(Boolean).join('&')}`;
	});
	const labelRef = shallowRef(label || name);
	const isDefault = Boolean(def);

	return {
		type, name, viewSetting: setting,
		group: group || '', position: position || 0,

		get label() {
			return labelRef.value;
		},
		set label(v) {
			labelRef.value = v;
		},

		get search() {
			return search.value;
		},
		get filters() {
			return filters.value;
		},
		get orFilters() {
			return orFilters.value;
		},
		get filterFieldValue() {
			return filterFieldValue.value;
		},
		setFilters(v) {
			filters.value = v;
		},
		setOrFilters(v) {
			orFilters.value = v;
		},
		setFilterFieldValue(v) {
			filterFieldValue.value = v;
		},
		get filterFieldFilter() {
			return filterFieldFilter.value;
		},
		get allFilters() {
			return allFilters.value;
		},
		get queryFilters() {
			return queryFilters.value;
		},
		get queryQrFilters() {
			return queryQrFilters.value;
		},
		get hidden() {
			return hidden.value;
		},
		get view() {
			return view.value;
		},
		get loading() {
			return loading.value;
		},
		get default() {
			return isDefault;
		},
		get configuration() {
			return configuration.value;
		},
		get layout() {
			return layout.value || 'link';
		},
		set layout(l) {
			layout.value = l;
			if (l) {
				localStorage.setItem(getLayoutKey(name), l);
			}
		},
		load() {
			return promise || load();
		},
		create,
		reload() {
			if (!promise) {
				return;
			}
			view.value = undefined;
			configuration.value = undefined;
			load();
		},
		async update(data?: any) {
			if (!updateConfigurations) {
				return;
			}
			const v = view.value;
			const c = configuration.value;
			if (!v || !c) {
				return;
			}
			const p = promise;
			if (!p) {
				return;
			}
			try {
				loadingCount.value++;
				const s = await updateConfigurations(data, docMeta.value, c, v);
				if (!s) {
					return;
				}
				if (p !== promise) {
					return;
				}
				const p2 = promise.then(([a]) => Promise.all([
					a,
					frappe.call('guigu.view.update_configuration_only', {
						name: v.name, configuration: s,
					}).then((v: any) => v.message).then(a => {
						if (!a || typeof a !== 'object') {
							throw new Error();
						}
						return a;
					}),
				]));
				promise = p2;
				const [, cfg] = await p2;
				if (promise !== p2) {
					return;
				}
				const config = await getConfigurations?.(docMeta.value, cfg, v) || cfg;
				if (promise !== p2) {
					return;
				}
				configuration.value = config;
			} finally {
				loadingCount.value--;
			}
		},
	};
}
