import {createApp} from 'vue';

import App from './index.vue';


function renderDwUpdateCard(html: HTMLElement, data:any[]) {
	const app = createApp(App, {data});
	app.mount(html);
}

frappe.provide('frappe.dw_worklist_management.doctype.dw_organization_worklist');
frappe.dw_worklist_management.doctype.dw_organization_worklist.renderDwUpdateCard = renderDwUpdateCard;
