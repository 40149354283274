import WorkBasis from './blocks/WorkBasis';
import TemplateFile from './blocks/TemplateFile';
import Attachment from './blocks/Attachment';
import Flow from './blocks/Flow';
import WorkList from './blocks/WorkList';
export const blocks = [
	WorkBasis,
	TemplateFile,
	Attachment,
	Flow,
	WorkList,
];
export const doctypeBlocks:{[key:string]:typeof blocks} = {
	'Dw Organization Worklist': blocks,
	'Dw Process': [Flow, Attachment],
	'Dw Work Instruction Template': [Flow, Attachment],
	'Dw Standard Worklist': blocks,
};
export function createTianjyEditorPlugin(doctype:string) {
	return frappe.tianjy_editor?.createTianjyEditorPlugin?.(doctypeBlocks[doctype]) || [];
}
