import { createApp, h, provide, unref, Plugin } from 'vue';

import elementPlus from 'element-plus';
import VueViewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
import { ElConfigProvider } from 'element-plus';
// @ts-ignore
import zhCN from 'element-plus/dist/locale/zh-cn';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

import * as components from '../../components/install';
export default function createVue(
	Component: any,
	props: Record<string, any> = {},
	provided: Record<string, any> = {},
) {
	const app = createApp({
		setup() {
			for (const [k, v] of Object.entries(provided)) {
				provide(k, v);
			}
			return () => h(ElConfigProvider, {
				locale: zhCN,
			}, () => h(Component, Object.fromEntries(Object.entries(props)
				.map(([k, v]) => [k, unref(v)])
			)));
		},
	});
	for (const [name, component] of Object.entries(components)) {
		app.component(name, component);
	}
	for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
		// @ts-ignore
		app.component(key, component);
	}
	app.use(VueViewer);
	app.use(elementPlus as Plugin, {});
	return app;
}
