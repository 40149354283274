<template>
	<AgGridVue
		class="ag-theme-guigu assess-table"
		:rowData="extraAssessList"
		:defaultColDef="defaultColDef"
		animateRows="true"
		@grid-ready="onGridReady"
		@cellValueChanged="cellValueChanged"
		:suppressDragLeaveHidesColumns="smallMeta"
		suppressRowTransform
		domLayout="autoHeight"
		:localeText="zhCN"
		:getRowId="getRowId">
	</AgGridVue>
</template>
<script setup lang="ts">
import {ref, watch} from 'vue';
import {AgGridVue} from 'ag-grid-vue3';
import type {GridApi} from 'ag-grid-community';

import type * as Permission from '../../Permission';
import type {ExtraScore, Permissions} from '../type';
import {useMetaQuery} from '../../../../../../../../guigu_pm/guigu_pm/public/js/components/page/useMetaQuery';
import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';

import {getColumns} from './extraScoreColumns';

interface Props{
	extraAssessList:(ExtraScore&{index:number, type_label:string})[];
	permission: Permission.DocPermission | null
	lockStatus:boolean
}
const props = defineProps<Props>();
interface Emit{
	(e: 'update', field:string, value:string|number, data:ExtraScore): void,
	(e: 'remove', data:ExtraScore, type:'detail'|'extra'): void,
}
const emit = defineEmits<Emit>();

const smallMeta = useMetaQuery();
const gridApi = ref<GridApi>();

const onGridReady = (params: any) => {
	gridApi.value = params.api;
};
function onRemove(data:ExtraScore) {
	emit('remove', data, 'extra');
}

watch([
	smallMeta, gridApi,
	()=>props.lockStatus,
	() => props.permission,
	()=>props.extraAssessList,
], ([
	smallMeta, api,
	lockStatus, permission,
	extraAssessList,
])=>{
	if (!api) {
		return;
	}
	api.setColumnDefs(getColumns(extraAssessList, onRemove, permission, lockStatus, smallMeta));
});
const defaultColDef = {
	sortable: false,
	filter: true,
	resizable: true,
	menuTabs: [],
	suppressMovable: true,
};

async function cellValueChanged(event: any) {
	const {data, value, colDef: {field}} = event;
	emit('update', field as string, value, data);
}
function getRowId(params: any) {
	return params.data.name;
}
</script>
  <style lang="less" scoped>
  .assess-table {
	width: 100%;
	margin-top: 8px;
	:deep(.rowSpanCell) {
		display: flex;
		align-items: center;
		background: #fff;
		border-bottom: 1px solid #d9dddf;
	}

	:deep(.ag-header-group-cell-label .ag-header-group-text) {
		width: 100%;
		text-align: center;
	}
  }
  </style>
