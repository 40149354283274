<template>
	<div>
		<ElDialog
			v-model="dialogVisible"
			v-loading="dialogLoading"
			@close="onCancel"
			title="关联数据"
			class="tree-dialog"
			width="min(600px, max(min(300px, 100%), 50%))">
			<div class="search-container">
				<FilterInput v-for="f in filterLine" :key="f.fieldname"
					class="filter-input"
					size="small"
					:meta="doctypeMeta"
					:name="f.fieldname" :label="f.label"
					:type="f.fieldtype" :options="f.options"
					:condition="f.condition || '='"
					v-model="params[f.fieldname]" />
			</div>
			<div
				v-loading="loading"
				class="tree-container">
				<div class="tree-toolbar">
					<label>{{ tt('Please Select:') }}</label>
					<ElButtonGroup :disabled="loading">
						<ElButton :disabled="loading" @click="fold" round>{{ tt('Collapse Level') }}</ElButton>
						<ElButton :disabled="loading" @click="expand" round>{{ tt('Expand') }}</ElButton>
					</ElButtonGroup>
				</div>
				<div class="aggrid-container ag-theme-guigu">
					<AgGridVue
						class="aggrid"
						:columnDefs="[]"
						:rowData="rowData"
						:defaultColDef="defaultColDef"
						animateRows="true"
						@grid-ready="onGridReady"
						treeData
						scroll
						groupDefaultExpanded="-1"
						:getDataPath="getDataPath"
						domLayout="autoHeight"
						:autoGroupColumnDef="autoGroupColumn"
						:localeText="zhCN"
						@rowSelected="rowSelected"
						@rowClicked="rowClicked"
						rowSelection="single"
						:suppressRowClickSelection="false"
						:suppressDragLeaveHidesColumns="smallMeta"
						@row-data-updated="onRowDataUpdated" />
				</div>
				<ElPagination class="pagination"
					layout="sizes, total, prev, pager, next" :total="total"
					:pageSize="pageSize"
					:pageSizes="pageSizes"
					:currentPage="page"
					:small="small"
					@size-change="handleSizeChange"
					@current-change="pageChange" />
			</div>
			<template #footer>
				<span class="create-btn" v-if="showCreate">
					<ElLink class="cus-link-btn" type="primary" @click="create">{{
						`+新建${tt(doctypeMeta.name)}` }}</ElLink>
				</span>
				<span class="dialog-footer">
					<ElButton @click="onCancel">{{ tt('Cancel') }}</ElButton>
					<ElButton type="primary" @click="save">{{ tt('Confirm') }}
					</ElButton>
				</span>
			</template>
		</ElDialog>
	</div>
</template>

<script setup lang='ts'>
import {ref, computed, watch} from 'vue';

import {AgGridVue} from 'ag-grid-vue3';
import type {RowSelectedEvent, RowDataUpdatedEvent, RowClickedEvent, GridApi} from 'ag-grid-community';
import {ElDialog, ElButton, ElButtonGroup, ElPagination} from 'element-plus';
import {debounce} from 'lodash';

import makeFilterLineFields from '../../GlobalView/makeFilterLineFields';
import zhCN from '../../agGrid/zhCN';
import FilterInput from '../../components/GuiguFilterDialog/Input/index.vue';
import {useMetaQuery} from '../../hooks/useMetaQuery';

import getFilters from './getFilters';
import {getFormatData, getTreeAncestry} from './helper';
import type {DF, TreeItem} from './type';
import {createDoc, getAllFilters} from './createDoc';
const tt = window.__;

interface Props {
	visible: boolean
	doctypeMeta: locals.DocType
	treeGroupField: string
	originSelectedList: string
	queries: { filters: any }
	df: DF,
	treeControl:any
}
const props = defineProps<Props>();
interface Emit {
	(e: 'update:visible', visible: boolean): void
	(e: 'change', selected: string, label?:string): void
}

const emit = defineEmits<Emit>();
const loading = ref<boolean>(false);
const dialogLoading = ref<boolean>(false);
const dialogVisible = ref<boolean>(false);
const small = ref<boolean>(true);

const params = ref<Record<string, any>>({});

const gridApi = ref<GridApi>();
const listData = ref<any[]>([]);
const selected = ref<string>(props.originSelectedList);
const total = ref<number>(0);
const page = ref<number>(1);
const pageSize = ref<number>(100);
const pageSizes = [10, 20, 50, 100];
const smallMeta = useMetaQuery();

const showCreate = computed(()=>!props.df.only_select && frappe.model.can_create(props.doctypeMeta.name));
watch(() => props.originSelectedList, () => {
	selected.value = props.originSelectedList;
}, {immediate: true});
const onGridReady = (params: any) => {
	gridApi.value = params.api;
};
const filterLine = computed(() => makeFilterLineFields(props.doctypeMeta, true));

const debounceFilter = debounce(search, 800);

watch(params.value, () => {
	debounceFilter();
});
watch(() => props.visible, () => {
	dialogVisible.value = props.visible;
});

const defaultColDef = {
	sortable: false,
	filter: false,
	editable: false,
	enableRowGroup: false,
	resizable: true,
	minWidth: 100,
};
function getDataPath(data: any) {
	return data.ancestry;
}
const titleField = computed(()=>props.doctypeMeta?.fields?.find(item => item.fieldname === props.doctypeMeta?.title_field));
const autoGroupColumn = computed(() => ({
	headerName: tt(titleField.value?.label || '分组'),
	flex: 1,
	enableRowGroup: false,
	editable: false,
	field: props.doctypeMeta?.title_field || 'name',
	filter: false,
	sortable: false,
	checkboxSelection: true,
	headerCheckboxSelection: true,
}));
watch(() => props.visible, () => {
	if (!props.visible) {
		return;
	}
	search();
});

async function search() {
	loading.value = true;
	const defaultFilters = props.queries?.filters || [];

	const searchFilters = getFilters(
		props.doctypeMeta?.name || '',
		[],
		params.value,
		filterLine.value,
	);
	const filters: any[] = [];
	if (Array.isArray(defaultFilters)) {
		filters.push(...defaultFilters);
		filters.push(...searchFilters);
	} else {
		const arrayFilters = Object.entries(defaultFilters).map(item => ({
			[item[0]]: item[1],
		}));
		filters.push(...arrayFilters);
		filters.push(...searchFilters);
	}
	if (typeof props.queries.query === 'string') {
		const filterData = await frappe.call<{ message: { data: TreeItem[], total: number } }>({
			method: props.queries.query,
			args: {
				doctype: props.doctypeMeta.name,
				limit: pageSize.value,
				page: page.value,
				filters: searchFilters,
				args: props.queries,
			},
		});
		listData.value = filterData?.message.data || [];
		total.value = filterData?.message.total || 0;
		loading.value = false;
		return;
	}
	const filterData = await frappe.call<{ message: { data: TreeItem[], total: number } }>({
		method: 'guigu.guigu_controls.tree_select.get_filter_data',
		args: {
			doctype: props.doctypeMeta.name,
			filters,
			limit: pageSize.value,
			page: page.value,
		},
	});
	listData.value = filterData?.message.data || [];
	total.value = filterData?.message.total || 0;
	loading.value = false;
}
const rowData = computed(() => {
	const formatData = getFormatData(listData.value, props.doctypeMeta.nsm_parent_field);
	const ancestryList = getTreeAncestry(formatData, props.treeGroupField);
	return ancestryList;
});

function pageChange(newPage: number) {
	page.value = newPage;
	search();
}
function handleSizeChange(newPageSize: number) {
	pageSize.value = newPageSize;
	pageChange(1);
}
function rowSelected(event: RowSelectedEvent) {
	const isSelected = event.node.isSelected();
	if (isSelected) {
		selected.value = event.data.name;
	} else if (selected.value === event.data.name) {
		selected.value = '';
	}
}
function rowClicked(event:RowClickedEvent) {
	if (event.data.name === selected.value) {
		event.node.setSelected(false);
	}
}
function onRowDataUpdated(event: RowDataUpdatedEvent) {
	event.api.forEachNode(node => node.setSelected(selected.value === node.data.name));
}
async function save() {
	const selectedRow = rowData.value.find(item=>item.name === selected.value);
	emit('change', selected.value, selectedRow?.[titleField.value?.fieldname || 'name']);
}
function onCancel() {
	selected.value = props.originSelectedList;
	emit('update:visible', false);
}
function create() {
	const allFilters = getAllFilters(props.doctypeMeta?.name || '', props.queries, filterLine.value, params.value);
	createDoc(props.doctypeMeta, props.treeControl, props.df, allFilters);
}

function fold() {
	gridApi.value?.collapseAll();
}
function expand() {
	gridApi.value?.expandAll();
}
</script>

<style lang='less' scoped>
.search-container {
	border-bottom: 1px solid var(--border-color);
	display: flex;
	flex-direction: row;
	align-content: center;
	overflow: visible;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	padding: var(--padding-sm);
	min-height: 40px;
	box-sizing: content-box;
	gap: 8px;
}

.filter-input {
	width: 160px;
}

.tree-container {
	height: calc(100vh - 460px);
	.aggrid-container{
		height: calc(100vh - 520px);
		overflow: scroll;

		.aggrid {
			height: 100%;
			width:100%
		};
	}
	.pagination{
		margin-top: 8px;
	}
}

.create-btn {
	float: left;
}
.cus-link-btn:after{
	border-bottom: 0!important;
}
:deep(.tree-dialog) .el-dialog__body{
	padding: 0 var(--el-dialog-padding-primary)
}
.tree-toolbar {
	display: flex;
    margin-top: 4px;
    justify-content: space-between;
}
</style>
