<template>
	<ElDialog
		v-model="dialogVisible"
		v-loading="loading"
		width="min(600px, max(min(300px, 100%), 50%))"
		@close="cancel"
		title="选择模版">
		<div>
			<div class="search-container">
				<FilterInput v-for="f in filterLine" :key="f.fieldname"
					class="filter-input"
					size="small"
					:meta="excelMeta"

					:name="f.fieldname" :label="f.label"
					:type="f.fieldtype" :options="f.options"
					:condition="f.condition || '='"

					v-model="params[f.fieldname]" />
			</div>
			<div
				v-loading="loading"
				class="tree-container">
				<label>请选择：</label>
				<AgGridVue
					class="ag-theme-guigu"
					:columnDefs="columnDefs"
					:rowData="listData"
					:defaultColDef="defaultColDef"
					rowSelection="multiple"
					animateRows="true"
					@grid-ready="onGridReady"
					domLayout="autoHeight"
					:localeText="zhCN"
					:suppressDragLeaveHidesColumns="smallMeta"
					@rowSelected="rowSelected" />
			</div>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<ElButton @click="cancel">{{ tt('Cancel') }}</ElButton>
				<ElButton type="primary" @click="confirm">
					{{ tt('Confirm') }}
				</ElButton>
			</span>
		</template>
	</ElDialog>
</template>

<script setup lang='ts'>
import {ref, computed, watch, Component} from 'vue';
import {ElDialog, ElButton, vLoading} from 'element-plus';
import {ICellRendererParams, RowSelectedEvent} from 'ag-grid-community';
import {AgGridVue} from 'ag-grid-vue3';

import {debounce} from 'lodash';

import {ExcelTem, Sheet} from '../type';
import makeFilterLineFields from '../../../GlobalView/makeFilterLineFields';
import zhCN from '../../../agGrid/zhCN';
import FilterInput from '../../../components/GuiguFilterDialog/Input/index.vue';
import {useMetaQuery} from '../../../hooks/useMetaQuery';
import FileComponent from '../../connection_table/AggridComponents/File.vue';
import LinkComponent from '../../connection_table/AggridComponents/Link.vue';
import BaseValueComponent from '../../connection_table/AggridComponents/BaseValue.vue';
import GuiguDateComponent from '../../connection_table/AggridComponents/GuiguDate.vue';
import LastCommentComponent from '../../connection_table/AggridComponents/LastComment.vue';
import CheckComponent from '../../connection_table/AggridComponents/Check.vue';

import getFilters from './getFilters';

const tt = window.__;
const renderer: Record<string, Component> = {
	Link: LinkComponent,
	'Tree Select': LinkComponent,
	'Tianjy Related Link': LinkComponent,
	Attach: FileComponent,
	'Attach Image': FileComponent,
	__LastComment__: LastCommentComponent,
	'Guigu Date': GuiguDateComponent,
	Check: CheckComponent,
};
interface Props {
	visible: boolean
	excelMeta: locals.DocType
	query?: { filters: any[] | Record<string, any> }
}
const props = defineProps<Props>();
interface Emit {
	(event: 'addSheets', data: ExcelTem[]): void;
	(event: 'cancel'): void;
}
const emit = defineEmits<Emit>();
const dialogVisible = ref<boolean>(false);
const loading = ref<boolean>(false);
const params = ref<Record<string, any>>({});
const listData = ref<ExcelTem[]>([]);
const gridApi = ref(null);
const selected = ref<ExcelTem[]>([]);
const smallMeta = useMetaQuery();
const defaultColDef = {
	sortable: false,
	filter: false,
	editable: false,
	enableRowGroup: false,
	resizable: true,
	minWidth: 100,
};
const onGridReady = (params: any) => {
	gridApi.value = params.api;
};
function cancel() {
	selected.value = [];
	emit('cancel');
}
function confirm() {
	emit('addSheets', selected.value);
	selected.value = [];
}
const filterLine = computed(() => makeFilterLineFields(props.excelMeta));

watch(() => props.visible, () => {
	dialogVisible.value = props.visible;
});

watch(() => props.visible, () => {
	if (!props.visible) {
		return;
	}
	search();
});
async function search() {
	loading.value = true;
	const defaultFilters = props.query?.filters || [];

	const searchFilters = getFilters(
		props.excelMeta?.name || '',
		[],
		params.value,
		filterLine.value,
	);
	const filters: any[] = [];
	if (Array.isArray(defaultFilters)) {
		filters.push(...defaultFilters);
		filters.push(...searchFilters);
	} else {
		const arrayFilters = Object.entries(defaultFilters).map(item => ({
			[item[0]]: item[1],
		}));
		filters.push(...arrayFilters);
		filters.push(...searchFilters);
	}
	const filterData = await frappe.db.get_list<{ name: string, [key: string]: any }>(props.excelMeta?.name, {
		filters,
		fields: ['*'],
		limit: 0,
	});
	listData.value = filterData || [];
	loading.value = false;
}
const debounceFilter = debounce(search, 800);
watch(params.value, () => {
	debounceFilter();
});
const columnDefs = computed(() => {
	const showFields = props.excelMeta.fields.filter(item=>item.in_list_view);
	const titleField = props.excelMeta.fields.find(item=>item.fieldname === props.excelMeta.title_field);
	if (showFields.length === 0) {
		showFields.push(titleField || {
			fieldname: 'name',
			label: 'ID',
			fieldtype: 'data',
		});
	}
	return showFields?.map((item, index) => ({
		headerName: tt(item.label),
		field: item.fieldname,
		minWidth: item.minWidth,
		width: item.width,
		flex: (showFields.length === index + 1) ? 1 : 0,
		resizable: true,
		checkboxSelection: index === 0,
		headerCheckboxSelection: index === 0,
		cellRenderer: renderer[item.fieldtype] ? renderer[item.fieldtype] : BaseValueComponent,
		cellRendererParams: (params: ICellRendererParams) => ({field: item}),
	})) || [];
});

function rowSelected(event: RowSelectedEvent) {
	const isSelected = event.node.isSelected();
	const hasSelected = selected.value.some(item => item.name === event.data.name);
	if (isSelected && !hasSelected) {
		const selectedCopy = JSON.parse(JSON.stringify(selected.value));
		selectedCopy.push(event.data);
		selected.value = selectedCopy;
	}
	if (!isSelected && hasSelected) {
		selected.value = selected.value.filter(item => item.name !== event.data.name);
	}
}
</script>

<style lang='less' scoped>
.search-container {
	border-bottom: 1px solid var(--border-color);
	display: flex;
	flex-direction: row;
	align-content: center;
	overflow: auto;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	padding: var(--padding-sm);
	min-height: 40px;
	box-sizing: content-box;
	gap: 8px;
}

.filter-input {
	width: 160px;
}

.tree-container {
	max-height: 500px;
	overflow-y: scroll;
}
</style>
