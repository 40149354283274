<template>
	<div v-if="options.content" class="text-container">{{ text }}</div>
	<div v-else class="placeholder">
		{{ tt('Please configure the template string') }}
	</div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, reactive, watch } from 'vue';

import * as echarts from 'echarts';

const tt = __;

interface Props {
	data: Record<string, any>[];
	options: Record<string, any>;
}
const props = defineProps<Props>();
const text = computed(() => {
	let content: string = props.options?.content || '';
	if (props.data.length === 0) {
		return content;
	}
	const regex = /{{([^}]+)}}/g;
	const results = [];
	let match;
	// eslint-disable-next-line no-cond-assign
	while (match = regex.exec(content)) {
		results.push({ label: match[0], fieldname: match[1] });
	}
	for (const item of results) {
		const f = props.options.fields?.find(field => field.fieldname === item.fieldname);
		const [data] = props.data;
		if (f) {
			content = content.replaceAll(item.label, __(data[`${f.fieldname}.title`]||data[f.fieldname]));
		}
	}
	return content;
});

</script>
<style scoped>
.text-container {
	word-break: break-all;
	white-space: break-spaces;
}

.placeholder {
	height: 200px;
	width: 100%;
	text-align: center;
}
</style>
