<template>
	<template v-if="inline">
		<img v-if="options && value" :src="value" :title="title"
			style="max-height: 30px; max-width: 100%;">
		<ElIcon v-else>
			<Picture />
		</ElIcon>
	</template>
	<img v-else-if="options && value" :src="value" :title="title"
		style="max-width: 100%;">
</template>
<script lang="ts" setup>
import { computed } from 'vue';

import { ElIcon } from 'element-plus';
import { Picture } from '@element-plus/icons-vue';


const props = defineProps<{
	value: any;
	data: Record<string, any>;
	field: GlobalView.FieldRenderOptions;
	meta?: locals.DocType;
	maxLine?: number;
	enableLink?: boolean;
	noData?: boolean;
	inline?: boolean;
	format?: string;
	showTitle?: boolean;
}>();
const emit = defineEmits<{
	(event: 'filter', field: string, operator: string, value: any): void;
}>();
const field = computed(() => props.field);
const options = computed(() => field.value.options);
const value = computed(() => props.data[options.value || '']);
const title = computed(() => {
	if (!props.showTitle) { return; }
	return `${__(field.value.label)}`;
});

</script>
