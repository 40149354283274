<template>
	<div class="instruction-container">
		<div class="instruction-wrapper">
			<component
				:is="InstructionComponent"
				:instructionName="instructionName"
				:editable="false"
				mode="show"
				:referenceType="referenceType"
				:referenceName="referenceName"
			></component>
		</div>
	</div>

</template>

<script setup lang='ts'>
import {computed} from 'vue';

import Empty from '../../../dw_worklist_management/common/instruction/instruction/Empty.vue';
import Instruction from '../../../dw_worklist_management/common/instruction/instruction/Instruction.vue';
interface Props {
	instructionName: string;
	referenceType:string,
	referenceName:string
}
const props = defineProps<Props>();
const InstructionComponent = computed(()=>{
	if (props.instructionName) {
		return Instruction;
	}
	return Empty;
});
</script>

<style lang='less' scoped>
.instruction-container{
	display: flex;
}
.instruction-wrapper{
	min-width: 1px;
	flex:1;
	max-height: 70vh
}
</style>
