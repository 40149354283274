<template>
	<div class="container">
		<div class="day-report-container">
			<div class="header">
				<Header></Header>
			</div>
			<div class="tools">
				<Toolbar
					@date-change="dateChange"
					@project-change="projectChange"
				></Toolbar>
			</div>
			<div class="main">
				<div class="day-report_board">
					<ReportList
						:date="date"
						:project="project"
						:nav="nav"
					/>
				</div>
				<div class="day-report_nav">
					<NavTree
						:date="date"
						:nav="nav"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import {ref, watch} from 'vue';

import Toolbar from './Toolbar.vue';
import NavTree from './NavTree.vue';
import ReportList from './ReportList.vue';
import { TeamNav } from './type';
import Header from './Header.vue';
const selectedKeys = location.hash.replace('#', '')
	? [location.hash.replace('#', '')]
	: [];
const date = ref<string>();
const project = ref<string>();
const nav = ref<TeamNav[]>([]);

function projectChange(newProject:string){
	project.value = newProject;
	const searchParams = new URLSearchParams(location.search);
	const oldProject = searchParams.get('project');
	if (oldProject===newProject){ return; }
	searchParams.set('project', newProject);
	history.pushState({}, '', `${location.pathname}?${searchParams.toString()}`);
}
function dateChange(newDate: string){
	date.value = newDate;
	const searchParams = new URLSearchParams(location.search);
	const oldDate = searchParams.get('date');
	if (oldDate===newDate){ return; }
	searchParams.set('date', newDate);
	history.pushState({}, '', `${location.pathname}?${searchParams.toString()}`);
}

async function loadNav(){
	const data = await frappe.call < { message: TeamNav[] } >({
		method: 'guigu_pm.guigu_pm_construction.page.diary_report.diary_report.get_nav',
		args: {
			project: project?.value,
			date: date?.value,
		},
	});
	nav.value = data?.message||[];
	const [firstTeam] = nav.value;
	const firstStructure = firstTeam?.structures[0];
	const firstKey = `${firstTeam?.name}_${firstStructure?.name}`;
	if (!selectedKeys[0]&&(!data?.message||!firstStructure)){ return; }
	location.href = `#${selectedKeys[0] || firstKey}`;
}
watch([date, project], ()=>{
	if (!date?.value||!project?.value){ return; }
	loadNav();
});
</script>
<style lang="less" scoped>
@media (max-width:768px) {
	.day-report_nav {
		display: none;
	}
}


.day-report-container {
	display: flex;
	height: calc(100vh - 60px);
	flex-direction: column;
	background: #fff;

	.tools {
		padding: 8px;
	}

	.main {
		display: flex;
		flex: 1;
		overflow: hidden;

		.day-report_board {
			flex: 1;
			border-right: 1px solid #d9d9d9;
			height: 100%;
		}

		.day-report_nav {
			width: 300px;
		}
	}
}
</style>
