<template>
	<div ref="root" class="root" />
</template>
<script setup lang="ts">
import {computed, shallowRef, watch, onBeforeUnmount} from 'vue';
const props = defineProps<{
	doctype: string;
	modelValue?: string | null;
}>();

const emit = defineEmits<{
	(event: 'update:modelValue', value: string | null): void;
}>();
const root = shallowRef();
function stringifyFilters(filters: any[]) {
	if (!Array.isArray(filters)) {
		return null;
	}
	try {
		return JSON.stringify(filters.map(([t, f, o, v]) => [t, f, o, v]));
	} catch {
		return null;
	}
}
function parseFilters(filters?: string | null): any[] {
	if (!filters) {
		return [];
	}
	try {
		return JSON.parse(filters).map(([t, f, o, v]: any) => [t, f, o, v, false]);
	} catch {
		return [];
	}
}
const modelValue = computed({
	get: () => parseFilters(props.modelValue),
	set: v => emit('update:modelValue', stringifyFilters(v)),
});
let editor: any;
watch([() => props.doctype, root], ([dt, el]) => {
	if (editor) {
		// TODO:
	}
	if (!el) {
		return;
	}
	el.innerHTML = '';
	if (!dt) {
		return;
	}
	const current = new frappe.ui.FilterGroup({
		parent: $(el),
		doctype: dt,
		on_change: () => {
			modelValue.value = current.get_filters();
		},
		base_list: [] as any,
	});
	editor = current;
	frappe.model.with_doctype(dt, () => {
		editor.clear_filters();
		editor.add_filters_to_filter_group(modelValue.value);
	});
}, {immediate: true});

onBeforeUnmount(() => {
	if (!editor) {
		return;
	}
	modelValue.value = editor.get_filters();
});
</script>
<style scoped lang="less">
.root {
	width: 100%;
	border:1px solid #ccc;
	border-radius: 4px;
	padding: 8px;

	:global(.filter-area .filter-box .awesomplete ul[role=listbox] li) {
		max-width: unset;
	}
}
</style>
