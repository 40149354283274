<template>
	<span class="text-right" :title="title"
		@click="setFilter">{{ value }}</span>
</template>
<script lang="ts" setup>
import { computed } from 'vue';


const props = defineProps<{
	value: any;
	data: Record<string, any>;
	field: GlobalView.FieldRenderOptions;
	meta?: locals.DocType;
	maxLine?: number;
	enableLink?: boolean;
	noData?: boolean;
	inline?: boolean;
	format?: string;
	showTitle?: boolean;
}>();
const emit = defineEmits<{
	(event: 'filter', field: string, operator: string, value: any): void;
}>();
const field = computed(() => props.field);
const value = computed(() => {
	const number_format = props.field.options?.trim()||get_number_format();
	const precision = props.field.precision || cint(frappe.boot.sysdefaults.float_precision, null);
	return format_number(props.value, number_format, precision);
});
const title = computed(() => {
	if (!props.showTitle) { return; }
	return `${__(field.value.label)}: ${value.value}`;
});
function setFilter() {
	emit('filter', props.field.fieldname, '=', props.value);
}
const tt = __;

</script>
