<template>
	<div  class="spark-line">
      <div
		class="spark-line_outer"
      ></div>
      <span class="spark-line_inner" :style="{ position: 'absolute', top: 0, left: 0 }">
        {{value ? `${value}%` : ''}}
      </span>
    </div>
 </template>
<script lang="ts" setup>
 import {defineProps} from 'vue';
 const props = defineProps<{params:any}>();
 const {value} = props.params;
 const width=`${value||0}%`;
 const background = Number(value) > 100 ? '#1890ff' : '#82d249';
 </script>

<style scoped>
.spark-line {
	text-align: right;
	position: relative;

}

.spark-line_outer {
	width: v-bind(width);
	height: 26px;
	background: v-bind(background);
}

.spark-line_inner {
	position: absolute;
	top: 0;
	left: 0;
}
</style>
