import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6498f52b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "widget-control" }
const _hoisted_2 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['indicator-pill', 'ellipsis', 'gray', $setup.props.size==='small'?'small':undefined]),
      title: $setup.title
    }, _toDisplayString($setup.value), 11 /* TEXT, CLASS, PROPS */, _hoisted_2)
  ]))
}