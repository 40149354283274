import { RangeType } from 'handsontable/common';
import HyperFormula from 'hyperformula';
import Sortable from 'sortablejs';
import * as XLSX from 'xlsx-js-style';

import createTab from './createTab';
import create, { XLSXEditor } from './createSheet.mts';
import createXLSXSheet from './createXLSXSheet.mts';

const noop = () => { };
export default function createSpreadSheet(root: HTMLElement, {
	value: defaultValue,
	readonly: ro,
	set,
	height,
	modifible: mf,
}: {
	value?: any[],
	readonly?: boolean,
	set?: (sheets: any[]) => void
	height?: string
	modifible?: boolean
}) {

	let readonly = Boolean(ro);
	let modifible = Boolean(mf);
	let onChange: (changed: [number, number, any, any][]) => void = noop;
	let onPaste: (data: any[][], coords: RangeType[]) => void = noop;
	let currentSheets: any[] = defaultValue || [];
	let sheetNames: string[] = [];
	let current = '';
	let sheetContexts: { [key: string]: XLSXEditor } = {};
	const formula = HyperFormula.buildEmpty({
		licenseKey: 'internal-use-in-handsontable',
		localeLang: 'zh-cn',
	});
	let inputMode = false;
	root.classList.add('tianjy-spread-sheet-root');
	root.style.height = height ? height : `auto`;
	const sheetRoot = root.appendChild(document.createElement('div'));
	sheetRoot.className = 'tianjy-spread-sheet-body';
	const tabHeader = root.appendChild(document.createElement('div'));
	tabHeader.className = 'tianjy-spread-sheet-header';
	const tabParent = tabHeader.appendChild(document.createElement('div'));
	tabParent.className = 'tianjy-spread-sheet-tabs';
	Sortable.create(tabParent, {
		onEnd({ newIndex, oldIndex }: { newIndex: number, oldIndex: number; }) {
			if (readonly || !modifible) { return; }
			sheetNames.splice(newIndex, 0, ...sheetNames.splice(oldIndex, 1));
			renderTabs();
			change();
		},
	});
	const addBtn = tabHeader.appendChild(document.createElement('button'));
	addBtn.className = 'tianjy-spread-sheet-add btn btn-xs';
	addBtn.title = __('Add new tab');
	addBtn.appendChild(document.createTextNode(__('Add')));
	addBtn.hidden = readonly || !modifible;

	let tabMap: Record<string, ReturnType<typeof createTab>> = {};
	let activeTab;
	function addTab(name: string) {
		const tab = createTab(name, readonly || !modifible, updateCurrent, onChangeTitle, onRemove);
		tabMap[name] = tab;
		tabParent.appendChild(tab.el);
	}
	function renderTabs() {
		tabParent.innerHTML = '';
		activeTab = null;
		tabMap = {};
		for (const name of sheetNames) { addTab(name); }
		const c = current;
		const tab = c in tabMap && tabMap[c];
		if (tab) {
			tab.active = true;
			activeTab = tab;
		}
	}


	function setCurrent(v: string) {
		if (activeTab) {
			activeTab.active = false;
		}
		const old = current;
		current = v;
		const oldEl = sheetContexts[old]?.el;
		const el = sheetContexts[v]?.el;
		if (oldEl) {
			oldEl.hidden = true;
		}
		if (el) {
			el.hidden = false;
		}
		const tab = v in tabMap && tabMap[v];
		if (tab) {
			tab.active = true;
			activeTab = tab;
		} else {
			activeTab = null;
		}
	}

	function updateCurrent(v) {
		setCurrent(v);
		root.scrollIntoView({
			behavior: 'smooth',
			inline: 'center',
			block: 'nearest',
		});
	}

	function exportXLSX(name?: string) {
		const wb = XLSX.utils.book_new();
		for (const [name, editor] of Object.entries(sheetContexts)) {
			const ws = createXLSXSheet(editor.readValue());
			XLSX.utils.book_append_sheet(wb, ws, name);
		}
		const fileName = [name].find(v => v && typeof v === 'string');
		XLSX.writeFile(wb, `${fileName || 'Data'}.xlsx`);
	}
	function createTable(name: string) {
		const el = sheetRoot.appendChild(document.createElement('div'));
		el.hidden = current !== name;

		return create(el, {
			formula, name,
			readOnly: readonly,
			// value,
			exportXLSX,
			onChange: changed => onChange(changed),
			onPaste: (data, coords) => onPaste(data, coords),
			isInputMode: () => inputMode,
		}, change);

	}
	function update(value) {
		if (!value.length) {
			const name = 'sheet1';
			sheetNames = [name];
			tabParent.innerHTML = '';
			activeTab = null;
			tabMap = {};
			addTab(name);
			for (const v of Object.values(sheetContexts)) {
				v.destroy();
			}
			sheetContexts = { [name]: createTable(name) };
			setCurrent(name);
			return;
		}
		sheetNames = value.map(({ name }) => name);
		renderTabs();
		const oldState = sheetContexts;
		sheetContexts = Object.fromEntries(
			sheetNames.map(name => [name, oldState[name] || createTable(name)]),
		);
		for (const it of value) {
			const sc = sheetContexts[it.name];
			if (!sc) { continue; }
			sc.setValue(it, readonly);
		}
		const newKeys = new Set(Object.keys(sheetContexts));
		for (const [k, sc] of Object.entries(oldState)) {
			if (newKeys.has(k)) { continue; }
			sc.destroy();
		}
		const currentTab = current;
		if (!currentTab || !(currentTab in sheetContexts)) {
			setCurrent(sheetNames[0] || '');
		}

	}
	update(currentSheets);

	function change() {
		if (destroyed) { return; }
		if (readonly) { return; }
		const sheets = sheetNames.map(name => ({
			...sheetContexts[name]?.getValue(),
			name,
		}));
		currentSheets = sheets;
		set?.(sheets);
	}
	function onRemove(name: string) {
		if (sheetNames.length === 1) {
			frappe.throw(__('Keep at least one worksheet'));
			return;
		}
		frappe.confirm(__('Are you sure you want to delete this sheet'), () => {
			if (sheetNames.length === 1) {
				return;
			}
			const index = sheetNames.findIndex(n => n === name);
			if (index < 0) { return; }
			if (current === name) {
				const nextTab = sheetNames[index + 1] || sheetNames[index - 1];
				setCurrent(nextTab || '');
			}
			sheetNames.splice(index, 1);
			const tab = name in tabMap && tabMap[name];
			if (tab) { tab.el.remove(); }
			delete tabMap[name];
			const sc = name in sheetContexts && sheetContexts[name];
			if (sc) { sc.destroy(); }
			delete sheetContexts[name];
			change();
		});
	}
	function onChangeTitle(oldTitle: string, newTitle: string) {
		if (oldTitle === newTitle || !newTitle) { return; }
		if (sheetNames.some(item => item === newTitle)) {
			frappe.throw(__('The worksheet name cannot be duplicated'));
			return;
		}
		const target = sheetNames.findIndex(item => item === oldTitle);
		if (target < 0) { return; }
		sheetNames[target] = newTitle;

		tabMap[newTitle] = tabMap[oldTitle];
		delete tabMap[oldTitle];
		const tab = tabMap[newTitle];
		if (tab) { tab.name = newTitle; }

		sheetContexts[newTitle] = sheetContexts[oldTitle];
		delete sheetContexts[oldTitle];
		const s = sheetContexts[newTitle];
		if (s) { s.name = newTitle; }

		if (current === oldTitle) {
			setCurrent(newTitle);
		}
		change();
	}

	addBtn.addEventListener('click', function () {
		const n = sheetNames.reduce((r, name) => {
			const res = /^sheet([0-9]+)$/i.exec(name);
			return res ? Math.max(parseInt(res[1]) || 0, r) : r;
		}, 0);
		const name = `sheet${n + 1}`;
		sheetNames.push(name);
		addTab(name);
		sheetContexts[name] = createTable(name);
		setCurrent(name);
		change();
	});
	let namedExpressions: Record<string, string> = {};
	for (const [name, expression] of Object.entries(namedExpressions)) {
		try {
			formula.addNamedExpression(name, expression);
		} catch (e) {
			console.error(e, name, expression);
		}
	}
	function updateFormulas() {
		for (const t of Object.values(sheetContexts)) {
			t.updateFormulas();
		}

	}
	function setTabReadonly() {
		const tabHidden = readonly || !modifible;
		for (const v of Object.values(tabMap)) {
			if (!v) { continue; }
			v.readonly = tabHidden;
		}
		addBtn.hidden = tabHidden;
	}
	function setReadonly() {
		for (const v of Object.values(sheetContexts)) {
			if (!v) { continue; }
			v.readOnly = readonly;
		}
	}


	let destroyed = false;
	return {
		get modifible() {
			return modifible;
		},
		set modifible(m) {
			if (destroyed) { return; }
			const mf = Boolean(m);
			if (mf === modifible) { return; }
			modifible = mf;
			setTabReadonly();
		},
		get readonly() {
			return readonly;
		},
		set readonly(r) {
			if (destroyed) { return; }
			const ro = Boolean(r);
			if (ro === readonly) { return; }
			readonly = ro;
			setReadonly();
			setTabReadonly();
		},
		setValue(value, ro) {
			if (destroyed) { return; }
			if (currentSheets === value) {
				if (typeof ro !== 'boolean' || readonly === ro) {
					return;
				}
				readonly = ro;
				setReadonly();
				setTabReadonly();
				return;
			}
			currentSheets = value;
			if (typeof ro === 'boolean') {
				readonly = ro;
			}
			update(value);
		},
		addSheets(selectedSheets: any[]) {
			if (destroyed) { return; }
			if (readonly) { return; }
			if (!modifible) { return; }
			if (!selectedSheets.length) { return; }
			const nameSet = new Set(sheetNames);
			const newNameSet = new Set(selectedSheets.map(v => v.name));
			function getNewName(name: string): string {
				if (name && !nameSet.has(name)) { return name; }
				let n = 1;
				let baseName = name || 'sheet';
				const res = name.match(/\d+$/);
				if (res) {
					baseName = name.substring(0, res.index || baseName.length);
					n = parseInt(res[0]) + 1;
				}
				for (; ; n++) {
					const t = `${baseName}${n}`;
					if (nameSet.has(t) || newNameSet.has(t)) { continue; }
					return t;
				}
			}
			for (const sheet of selectedSheets) {
				const name = getNewName(sheet.name);
				nameSet.add(name);
				sheetNames.push(name);
				const t = createTable(name);
				sheetContexts[name] = t;
				addTab(name);
				t.setValue(sheet, readonly);
			}
			change();
		},
		get namedExpressions() { return namedExpressions; },
		set namedExpressions(names) {
			if (destroyed) { return; }
			for (const name of Object.keys(namedExpressions)) {
				try {
					formula.removeNamedExpression(name);
				} catch (e) {
					console.error(e, name);
				}
			}
			namedExpressions = names;
			for (const [name, expression] of Object.entries(namedExpressions)) {
				try {
					formula.addNamedExpression(name, expression);
				} catch (e) {
					console.error(e, name, expression);
				}
			}
			updateFormulas();
		},
		exportXLSX,
		get inputMode() { return inputMode; },
		set inputMode(v) {

			if (destroyed) { return; }
			inputMode = Boolean(v);
		},
		get onChange() { return onChange; },
		set onChange(o) {

			if (destroyed) { return; }
			onChange = o;
		},
		get onPaste() { return onPaste; },
		set onPaste(o) {

			if (destroyed) { return; }
			onPaste = o;
		},
		getData(name?: string) {
			if (name) {
				return sheetContexts[name]?.getData() || [];
			}
			return Object.fromEntries(Object.entries(sheetContexts).map(([k, s]) => [
				k, s.getData(),
			]));
		},
		destroy() {
			if (destroyed) { return; }
			destroyed = false;
			for (const s of Object.values(sheetContexts)) {
				s.destroy();
			}
		},
	};
}
