<template>
	<ElDrawer v-model="visible" appendToBody destroyOnClose direction="rtl"
		size="80%" :beforeClose="handleClose" class="global-view-drawer"
		customClass="drawer">
		<template #header>
			<div class="header">
				<ElSelect v-model="type" :disabled="isUpdate" filterable
					defaultFirstOption>
					<ElOption
						v-for="type in types"
						:key="type.value"
						:value="type.value"
						:label="type.label" />
				</ElSelect>
				<ElInput v-model="label" />
			</div>
		</template>
		<template #default>
			<div class="menu">
				<ElMenu :defaultActive="active" :key="active"
					@select="active = $event">
					<template v-for="g in menu" :key="g.key">
						<ElMenuItemGroup v-if="g.key" :title="g.label">
							<ElMenuItem v-for="m in g.children" :key="m.key"
								:index="m.key">
								{{ m.label }}
							</ElMenuItem>
						</ElMenuItemGroup>
						<template v-else>
							<ElMenuItem v-for="m in g.children" :key="m.key"
								:index="m.key">
								{{ m.label }}
							</ElMenuItem>
						</template>
					</template>
				</ElMenu>
			</div>
			<ElForm class="menu-form" labelPosition="top" @submit.prevent>
				<template v-if="current" :key="current.key">
					<component v-if="current.type" :is="current.component"
						:meta="meta" :name="view.name" :type="type"
						v-model="currentConfiguration" />
					<component v-else :is="current.component" :key="current.key"
						:meta="meta" :define="define" v-model="view" />
				</template>
			</ElForm>
		</template>
		<template #footer>
			<div style="flex: auto">
				<ElButton @click="cancelClick">取消</ElButton>
				<ElButton type="primary" @click="confirmClick">
					<template v-if="isUpdate">更新</template>
					<template v-else>新建</template>
				</ElButton>
			</div>
		</template>
	</ElDrawer>
</template>

<script setup lang="ts">
import {computed, ref, toRaw, watch, shallowReactive, shallowRef} from 'vue';
import {
	ElDrawer,
	ElButton,
	ElForm,
	ElMenu, ElSelect, ElOption, ElInput, ElMenuItem, ElMenuItemGroup, ElMessageBox,
} from 'element-plus';

import {viewSettings} from '../../extends/init/defineMainView';

import getMenus, {getTypes} from './getMenus';
const props = defineProps<{
	meta: locals.DocType;
	visible?: boolean;
	view?: any;
	configuration?: any;
}>();
const emit = defineEmits<{
	(event: 'update:visible', visible: boolean): void;
	(event: 'update', view: any, configuration: any): void;
}>();
const visible = computed({
	get: () => props.visible || false,
	set: v => emit('update:visible', v),
});
function cancelClick() {
	visible.value = false;
}

const originView = shallowRef({
	name: '',
	type: 'Guigu List View Configuration',
	label: '',
});
const originConfiguration = shallowRef({});
const view = ref(originView.value);
const configurations = shallowRef<Record<string, any>>(shallowReactive({}));
const type = computed({
	get: () => view.value.type,
	set: type => {
		view.value = {...view.value, type};
	},
});
const label = computed({
	get: () => view.value.label,
	set: label => {
		view.value = {...view.value, label};
	},
});
const define = computed(() => viewSettings.get(type.value));

watch([
	() => props.visible,
	() => props.view,
	() => props.configuration,
], ([visible, v, c]) => {
	if (!visible) {
		return;
	}
	const type = v?.type || 'Guigu List View Configuration';
	const name = v?.name;
	const ov = name && type && v || {
		...v, name: '', type,
	};
	originView.value = ov;
	view.value = ov;
	const oc = c || {};
	originConfiguration.value = oc;
	configurations.value = shallowReactive({[type]: oc});
});
const active = ref('');

const isUpdate = computed(() => Boolean(view.value?.name));
const types = computed(() => getTypes());
const menuTree = computed(() => getMenus());

const currentConfiguration = computed({
	get: () => {
		const c = configurations.value;
		const t = type.value;
		if (!(t in c)) {
			toRaw(c)[t] = {};
		}
		return c[t];
	},
	set: v => {
		const c = configurations.value;
		const t = type.value;
		c[t] = v;
	},
});
function isEqual(a: any, b: any) {
	if (Object.is(a, b)) {
		return true;
	}
	if (!a !== !b) {
		return false;
	}
	if (typeof a !== typeof b) {
		return false;
	}
	if (typeof a !== 'object') {
		return false;
	}
	const keys = Object.keys(a);
	if (keys.length !== Object.keys(b).length) {
		return false;
	}
	for (const key of keys) {
		if (!isEqual(a[key], b[key])) {
			return false;
		}
	}
	return true;
}
const saved = computed(() => {
	const ov = originView.value;
	if (!ov.name) {
		return false;
	}
	if (!isEqual(ov, toRaw(view.value))) {
		return false;
	}
	if (!isEqual(originConfiguration.value, currentConfiguration.value)) {
		return false;
	}
	return true;
});
function handleClose(done: () => void) {
	if (saved.value) {
		done(); return;
	}
	ElMessageBox.confirm('确认要取消保存吗?', {
		cancelButtonText: '取消',
		confirmButtonText: '确认',
	}).then(() => done());
}
const menu = computed(() => {
	const t = type.value;
	const s = define.value;
	const {meta} = props;
	const {value} = view;
	const configuration = currentConfiguration.value;
	const menu = menuTree.value.map(g => ({
		...g, children: g.children.filter(v => {
			const {type} = v;
			if (type && type !== t) {
				return false;
			}
			if (v.hidden?.(meta, configuration, value, s)) {
				return false;
			}
			return true;
		}),
	})).filter(g => g.children.length);
	return menu;
});
const menuVisible = computed(() => new Map(menu.value.flatMap(v => v.children)
	.map(v => [v.key, v])));
const current = computed(() => menuVisible.value.get(active.value));
watch([active, menuVisible], ([c, all]) => {
	if (all.has(c)) {
		return;
	}
	if (!all.size) {
		return;
	}
	const [first] = all.keys();
	if (!first) {
		return;
	}
	active.value = first;
}, {immediate: true});

function confirmClick() {
	emit('update', view.value, currentConfiguration.value);
}

</script>
<style scoped lang="less">
.header {
	display: flex;

	>* {
		flex: 1;
	}

	>:first-child {
		width: 100px;
		flex: unset;
	}
}

:global(.global-view-drawer .el-drawer__body) {
	display: flex;
	overflow: hidden;
	padding: 0;
}
.menu-form{
	flex: 1;
	overflow: auto;
	padding: 20px;
}
.menu {
	width: 200px;
	overflow: auto;
	border-right: solid 1px var(--el-menu-border-color);
	background-color: var(--el-menu-bg-color);

	:deep(.el-menu) {
		border-right: none;
		background-color: none;
	}
}
</style>
