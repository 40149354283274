import type { AssessInfo } from '../type';

export function getExportData(assessInfo: AssessInfo) {
  const {
    date,
    uncomplete_reason = '',
    rectification_record = '',
    suggestion = '',
    support = '',
    leader_confirm_date,
    user_confirm_date,
    leader_doc,
    user_doc,
  } = assessInfo;
  const linkAssessList =
    assessInfo.detail
      .filter((item) => item.doc_name && item.link_doctype)
      .map((item) => ({
        subject: item.subject || '',
        standard: item.standard || '',
        remark: item.remark || '',
      })) || [];
  const tempAssess =
    assessInfo.temp.map((item) => ({
      subject: item.subject || '',
      standard: item.standard || '',
      remark: item.remark || '',
    })) || [];
  return {
    year: moment(date).format('YYYY'),
    month: moment(date).format('MM'),
    week: getMonthWeek(date),
    leader: leader_doc?.full_name || '',
    user: user_doc?.full_name || '',
    linkAssessList,
    tempAssess,
    uncomplete_reason: uncomplete_reason || '',
    rectification_record: rectification_record || '',
    suggestion: suggestion || '',
    support: support || '',
    leader_confirm_date: leader_confirm_date ? moment(leader_confirm_date).format(__('YYYY Year MM Month DD Day')) : '',
    user_confirm_date: user_confirm_date ? moment(user_confirm_date).format(__('YYYY Year MM Month DD Day')) : '',
  };
}

export function getMonthWeek(date: string) {
  //获取当前时间转字符串并用字符串方法split转数组，获取当前时间年、月、日
  const [year, month, day] = moment(date).format('YYYY-MM-DD').split('-');
  //获取本月有多少天
  const monthDay = moment(date).daysInMonth();
  //创建一个新数组，用来接收本月所有周未的日，如果本月最后一天不是周日那也算是周未
  const monthWeekend = [];
  let indexWeek = 1;
  //循环遍历当前月的每一天
  for (let i = 1; i <= monthDay; i++) {
    //获取每一天是周几
    const week = moment(`${year}-${month}-${i} 00:00:00`).day();
    //如果当前i==今天，就看之前的周末数组里有几个值就是第几周
    if (parseInt(day) === i) {
      indexWeek = monthWeekend.length + 1;
      break;
    } else if (week === 0) {
      //如果当前i不是今天并且当前i是周末，那就push进周末数组
      monthWeekend.push(i);
    }
  }
  //返回当前日期是本月的第几周
  return indexWeek;
}
