import Handsontable from 'handsontable';

export function* saveData(hatable:Handsontable, field:string, frm:any, onChange:(data:any)=>void) {
	const sourceData:any[] = hatable.getSourceData();
	yield;
	const {columns = []} = hatable.getSettings();
	if (typeof columns === 'function') {
		return;
	}
	const bodyObjectData = sourceData.filter(item=>item.__type__ !== 'summary' && item.__type__ !== 'headerData');
	const bodyDataRows = sourceData.map((item, index)=>{
		if (item.__type__ !== 'summary' && item.__type__ !== 'headerData') {
			return index;
		}
	}).filter(each=>each !== undefined);
	yield;
	const tableData = hatable.getData();
	const data = tableData.filter((item:any, index:number)=>(bodyDataRows.includes(index)));
	yield;
	if (!field) {
		onChange({columns, bodyObjectData: sourceData, data: tableData});
		return;
	}

	const oldFormDataList = structuredClone(frm.doc[field]);
	const formDataList = frm.doc[field];
	// 更新行
	for (const [index, d] of bodyObjectData.entries()) {
		const formData = d.name ? formDataList.find((item:any)=>item.name === d.name) : {};
		const calcuData = data[index];
		if (!formData) {
			continue;
		}
		if (calcuData.filter(Boolean).length === 0) {
			continue;
		}
		formData.idx = index + 1;
		// eslint-disable-next-line unicorn/no-array-for-each
		columns.forEach((col:any, colIndex:number)=>{
			const defaultData = col.type === 'numeric' ? 0 : '';
			let value = calcuData[colIndex] || defaultData;
			const columnField = frm.fields_dict[field].grid.docfields.find(f=>f.fieldname === col.data);
			if (!columnField) {
				return;
			}
			if (columnField.fieldtype === 'Float') {
				if (typeof value === 'number') {
					value = Number(parseFloat(value?.toFixed(col.precision || 2) || '0'));
				}
			}
			if (formData[col.data] !== value) {
				if (col.data === 'unit' && !formData[col.data] && value === '') {
					return;
				}
				if (col.data === 'inventory_loss_quantity' || col.data === 'inventory_profit_quantity') {
					if (!(value === '' && formData[col.data] === 0)) {
						formData[col.data] = value;
					}
				} else {
					formData[col.data] = value;
				}
			}
		});

		if (!d.name) {
			const newData = frm.add_child(field, formData);
			newData.idx = index + 1;
			d.name = newData.name;
		}
	}
	yield;
	hatable.loadData(sourceData);
	if (JSON.stringify(frm.doc[field]) !== JSON.stringify(oldFormDataList)) {
		frm.refresh_field(field);
		frm.dirty();
	}
	const summaryObjectDataIndex = sourceData.findIndex(item=>item.__type__ === 'summary');
	const summaryDatum = tableData.find((item:any, index:number)=>(summaryObjectDataIndex === index));
	const summary:Record<string, any> = {};
	// eslint-disable-next-line unicorn/no-array-for-each
	columns.forEach((col:any, colIndex:number)=>{
		if (col.summary) {
			summary[col.data] = summaryDatum?.[colIndex];
		}
	});
	onChange(summary);
	yield;
}

export function timeSliceSave() {
	return async function(hatable:Handsontable, field:string, frm:any, onChange:(data:any)=>void) {
		const func_ = saveData(hatable, field, frm, onChange);
		let data;
		do {
			data = func_.next();
			await new Promise(res=>setTimeout(res));
		} while (!data.done);
	};
}
