
<template>
	<div class="tool-container">
		<div class="tool">
			<span class="required">*</span>
			<el-tree-select
				v-show="organizations.length>0"
				:modelValue="organization"
				:data="organizations"
				defaultExpandAll
				:filterNodeMethod="filterNodeMethod"
				@change="organizationChange"
				filterable
				checkStrictly
			/>
		</div>
		<div class="tool">
			<span class="required">*</span>
			<el-space class="dateSpace">
				<el-tooltip
					class="box-item"
					effect="dark"
					:content="tt('the day before')"
					placement="top-start">
					<el-button :size="size"
						@click="dateHandleChange('before')">
						{{ `<<` }}
					</el-button>
				</el-tooltip>

				<ElDatePicker
					class="dailyDataSelect"
					v-model="datePicker"
					@change="dateChange"
					:clearable="false"
					type="date"
					:size="size"
					valueFormat="YYYY-MM-DD" />
				<el-tooltip
					class="box-item"
					effect="dark"
					:content="tt('The day after')"
					placement="top-start">
					<el-button :size="size"
						@click="dateHandleChange('after')">
						{{ `>>` }}
					</el-button>
				</el-tooltip>
			</el-space>
		</div>
		<div class="statistics_comment">
			<div class="statistics">
				<el-tooltip :content="tt('Log Attendance Summary')">
					<el-button :icon="PieChart" circle
						@click="navigateToRecordStatistics"></el-button>/>
				</el-tooltip>
			</div>
			<div class="tool comment">
				<el-checkbox
					:size="size"
					v-model="showComment"
					:label="tt('Show comments')"
					class="commentCheckbox" />
			</div>
		</div>
		<div class="search">
			<el-input
				:size="size"
				clearable
				:modelValue="searchString"
				@input="searchChange"
				@clear="clearSearch"
				@keyup.enter="search"
				:placeholder="tt('Search For')">
				<template #append>
					<el-button :size="size" @click="search">{{ tt('Search') }}</el-button>
				</template>
			</el-input>
		</div>
	</div>
</template>
<script lang="ts" setup>
import {ref, onMounted, onUnmounted, computed, watch} from 'vue';
import {PieChart, Back, Right} from '@element-plus/icons-vue';

import {list2Tree} from '../../../../../../../../guigu_pm/guigu_pm/public/js/utils/index';

import {Organization} from './type';

const tt = __;
interface Props {
	organization: string
	date: string
	searchString: string
	showComment: boolean
}
const props = defineProps<Props>();
interface Emit {
	(e: 'update:organization', organization: string): void
	(e: 'update:date', date: string): void
	(e: 'update:showComment', showComment: boolean): void
	(e: 'update:searchString', searchString: string): void
	(e: 'search'): void
	(e: 'clearSearch'): void
}
const emit = defineEmits<Emit>();
const organizations = ref<Organization[]>([]);
const datePicker = ref<string>(props.date);
const showComment = computed<boolean>({
	get: () => props.showComment,
	set: v => {
		emit('update:showComment', v);
	},
});
const sessionDefaultOrganization = ref<string>((frappe.defaults.get_user_defaults('organization') || [])[0]);
const paramsFilter = ref<{ [key: string]: string }>({});


const size = ref<string>('small');
function filterNodeMethod(value: string, data: (FormatDiaryStructure)) {
	return data.label.includes(value);
}
function updateComponentSize() {
	const widthElement = window.innerWidth;
	if (widthElement <= 640) {
		size.value = 'default';
	} else {
		size.value = 'small';
	}
}
window.addEventListener('resize', updateComponentSize);

onUnmounted(() => window.removeEventListener('resize', updateComponentSize));

function routeInit() {
	const keyArr = ['organization', 'date'];
	const queryObj = new URLSearchParams(window.location.search);
	// 创建一个对象来存储参数
	const paramsObj:{[key: string]: string} = {};
	// 遍历每个参数，并将它们存储在 params 对象中
	for (const item of keyArr) {
		if (queryObj.get(item)) {
			paramsObj[item] = queryObj.get(item) || '';
		}
	}
	paramsFilter.value = paramsObj;
}


function setRoute(filter: { [key: string]: string }) {
	const newParamsFilter = {...paramsFilter.value, ...filter};
	const queryObj = new URLSearchParams(newParamsFilter);
	paramsFilter.value = newParamsFilter;
	history.replaceState(null, '', `?${queryObj.toString()}`);
}

function dateHandleChange(way: string) {
	if (way === 'before') {
		const new_date = moment(datePicker.value).subtract(1, 'days').format('YYYY-MM-DD');
		setRoute({date: new_date});
		emit('update:date', new_date);
		datePicker.value = new_date;
	} else {
		const new_date = moment(datePicker.value).add(1, 'days').format('YYYY-MM-DD');
		setRoute({date: new_date});
		emit('update:date', new_date);
		datePicker.value = new_date;
	}
}

function navigateToRecordStatistics() {
	const dateString = paramsFilter.value?.date;
	const dateObject = new Date(dateString);
	const formattedDate = `${dateObject.getFullYear()}-${(dateObject.getMonth() + 1)
		.toString()
		.padStart(2, '0')}`;
	const year = dateObject.getFullYear();
	const filter = {
		organization: paramsFilter.value?.organization,
		monthDate: formattedDate,
		pageType: 'month',
		yearDate: year.toString(),
	};
	const queryObj = new URLSearchParams(filter);
	const url = `?${queryObj.toString()}`;
	frappe.router.push_state(`/app/epc_daily_record_statistics${url}`);
}

async function onRouteChange() {
	updateComponentSize();
	routeInit();
	const OrganizationRes:{message:Organization[]} = await frappe.call<{message:Organization[]}>({
		method: 'guigu_work_list.guigu_work_list.page.guigu_epc_organization_daily_record.guigu_epc_organization_daily_record.get_organizations',
	});
	const formateData = (OrganizationRes?.message || []).map(item=>({
		...item,
		id: item.name,
		value: item.name,
		parent: item.parent_organization,
	}));
	organizations.value = list2Tree(formateData);
	const organizationNames = (OrganizationRes?.message || []).map(item => item.name);
	let defaultOrganization: string = '';
	if (organizationNames.includes(paramsFilter.value?.organization)) {
		defaultOrganization = paramsFilter.value?.organization;
	} else {
		defaultOrganization = sessionDefaultOrganization.value || OrganizationRes.message[0]?.name || '';
	}
	setRoute({organization: defaultOrganization, date: paramsFilter.value?.date || datePicker.value});
	datePicker.value = paramsFilter.value?.date;
	emit('update:organization', defaultOrganization);
	emit('update:date', paramsFilter.value?.date || datePicker.value);
}

onMounted(() => {
	onRouteChange();
});

function organizationChange(value: string) {
	setRoute({organization: value});
	emit('update:organization', value);
}
function dateChange(value: string) {
	datePicker.value = value || moment().format('YYYY-MM-DD');
	setRoute({date: value || moment().format('YYYY-MM-DD')});
	emit('update:date', value || moment().format('YYYY-MM-DD'));
}
function searchChange(value: string) {
	emit('update:searchString', value);
}
function search() {
	emit('search');
}
function clearSearch() {
	emit('clearSearch');
}

</script>
<style scoped lang="less">
.tool-container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	.tool {
		display: flex;
		margin-bottom: 8px;
	}
	.required {
		margin-right: 4px;
		color: #f00;
		line-height: 24px;
	}
}
.comment {
	:deep(.el-checkbox__original) {
			display:none !important
	}
}
@media (min-width: 640px) {

	.tool {
		display: flex;
		margin-right: 8px;
	}
	.statistics{
		margin-left: -8px;
		margin-top: 2px;
	}
	.statistics_comment{
		display: flex;
	}
	.comment {
		margin-top: 3px;
		:deep(.el-checkbox) {
			margin-bottom:0px;
			margin-left: 8px
		}
		:deep(.el-checkbox__label) {
			margin-top: -1px;
		}
	}
	.search {
		margin-left: auto;
		margin-bottom: 8px;
	}

	.dailyDataSelect {
		:deep(.select-trigger .el-input .el-input__wrapper) {
			min-width: 300px;
		};
		:deep(.el-input__inner) {
			height:22px !important
		}
	}

}

@media (max-width: 640px) {
	.tool {
		width: 100%;
	}
	.search {
		width: 100%;
		margin-left: 11px
	}
	.statistics_comment{
		display: flex;
		.statistics{
			margin-top: 4px;
			margin-left: 10px;
		}
		.commentCheckbox {
			margin-left: 11px;

			:deep(.el-checkbox__original) {
				display: none;
			}
		}
	}
	:deep(.el-date-editor.el-input) {
			width: 100% !important
	};
	.el-picker__popper {
		width: 100px !important
	}
	:deep(.el-picker-panel__sidebar+.el-picker-panel__body) {
		margin-left: 50px !important;
	}
	.dailyDataSelect {
		width: 100%;
	}
	.dateSpace {
		:deep(.el-space__item:last-child) {
			margin-right: 0px !important;
		}
	}
}


</style>
