import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bdc1b9a5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Page"], null, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.tt("Photo List")), 1 /* TEXT */)
    ]),
    tools: _withCtx(() => [
      _createVNode($setup["ToolBar"], {
        onProjectChange: $setup.projectChange,
        onDateRangeChange: $setup.dateRangeChange,
        onSortChange: $setup.sortChange,
        onIsIncludeChildrenChange: $setup.isIncludeChildrenChange,
        structureOrProgress: $setup.structureOrProgress,
        photoList: $setup.photoList
      }, null, 8 /* PROPS */, ["structureOrProgress", "photoList"])
    ]),
    sider: _withCtx(() => [
      ($setup.project)
        ? (_openBlock(), _createBlock($setup["StructureTree"], {
            key: 0,
            project: $setup.project,
            onStructureChange: $setup.structureChange
          }, null, 8 /* PROPS */, ["project"]))
        : _createCommentVNode("v-if", true)
    ]),
    default: _withCtx(() => [
      _createVNode($setup["PhotoTimeline"], {
        project: $setup.project,
        structureOrProgress: $setup.structureOrProgress.name,
        startDate: $setup.startDate,
        endDate: $setup.endDate,
        sort: $setup.sort,
        isIncludeChildren: $setup.isIncludeChildren,
        onPhotoListChange: $setup.photoListChange
      }, null, 8 /* PROPS */, ["project", "structureOrProgress", "startDate", "endDate", "sort", "isIncludeChildren"])
    ]),
    _: 1 /* STABLE */
  }))
}