<template>
	<ElButtonGroup class="tool_toggle_State">
		<ElDropdown @command="clickLevel" v-if="levels">
			<ElButton size="small">
				{{ tt('Expand') }}
				<ElIcon class="el-icon--right"><ArrowDown /></ElIcon>
			</ElButton>
			<template #dropdown>
				<ElDropdownMenu>
					<ElDropdownItem v-for="index in levels" :command="index" :key="index">
						{{ tt('Level {}',[index]) }}
					</ElDropdownItem>
				</ElDropdownMenu>
			</template>
		</ElDropdown>
		<ElButton v-else size="small" @click="emit('toggle',true)">{{ tt('Expand') }}</ElButton>
		<ElButton size="small" @click="emit('toggle',false)">{{ tt('Collapse') }}</ElButton>
	</ElButtonGroup>
</template>

<script setup lang='ts'>
import {ElButton, ElButtonGroup, ElDropdown, ElDropdownMenu, ElDropdownItem, ElIcon} from 'element-plus';
import {ArrowDown} from '@element-plus/icons-vue';

interface Props {
	levels?: number;
}
defineProps<Props>();
const emit = defineEmits<{
	(e: 'toggle', value: boolean, level?:number): void;
}>();

const tt = __;
// 展开到指定层级
function clickLevel(level:number) {
	emit('toggle', true, level);
}
</script>

<style scoped>
.tool_toggle_State {
	margin-right: 20px;
	margin-bottom: 8px;
}
</style>
