import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28a23755"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "report-info_label" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AgGridVue = _resolveComponent("AgGridVue")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, "1. " + _toDisplayString(_ctx.tt("Construction Progress")), 1 /* TEXT */),
    _createVNode(_component_AgGridVue, {
      class: "ag-theme-guigu",
      columnDefs: _ctx.columnDefs,
      rowData: _ctx.progressAncestryList,
      rowSelection: "multiple",
      animateRows: "true",
      autoGroupColumnDef: _ctx.autoGroupColumnDef,
      getDataPath: _ctx.getDataPath,
      treeData: true,
      groupDefaultExpanded: "-1",
      domLayout: "autoHeight",
      suppressDragLeaveHidesColumns: _ctx.smallMeta,
      localeText: _ctx.zhCN,
      defaultColDef: _ctx.defaultColDef
    }, null, 8 /* PROPS */, ["columnDefs", "rowData", "autoGroupColumnDef", "getDataPath", "suppressDragLeaveHidesColumns", "localeText", "defaultColDef"])
  ], 64 /* STABLE_FRAGMENT */))
}