<template lang="html">
	<ValueEditor :meta="meta" :loading="loading"
		v-model:visible="valueEditorVisible"
		:field="editField" :value="editValue" @update="saveGroupValue" />
	<KanbanC :groups="stages" :data="data" @update="update"
		:key="key" @setting="edit" @add="add" @create="create" #="{ value }">
		<Item :data="value" :meta="meta" :loading="loading"
			:fields="fieldDef" @clickTitle="clickTitle($event, value)" />

		<slot name="pagination" />
	</KanbanC>
</template>
<script setup lang="ts">
import {computed, ref, watch} from 'vue';
import {watchEffect} from 'vue';

import type {Column, KanbanConfiguration, Field} from './types';
import KanbanC from './Kanban/index.vue';
import getStages from './getStages';
import getNewGroups from './getNewGroups';
import Item from './Item.vue';
import ValueEditor from './ValueEditor.vue';


const props = defineProps<{
	meta: locals.DocType;
	options: Record<string, any>;

	data: locals.Doctype[];
	total: number;
	loading?: boolean;

	modelValue: any;
	selected?: any[];

	infiniteScroll: boolean;
	page: number;
	limit: number;
	group: GlobalView.Group[];
	sort: GlobalView.MainLoaderOptions['order'];
	filters?: any;

	detail?: boolean;
	rowAction?: any;


	view: GlobalView.View;
	configuration: KanbanConfiguration;
}>();
const emit = defineEmits<{
	(event: 'refresh'): void;
	(event: 'delete', name: string): void;
	(event: 'create', data: any, extend?: boolean): void;
	(event: 'nextPage'): void;
	(event: 'update:modelValue', value: any): void;
	(event: 'update:selected', value: any[]): void;
	(event: 'update:data', data: locals.Doctype[]): void;
	(event: 'filter', field: string, operator: string, value: any): void;
	(event: 'update:configuration', cfg: any): void;

	(event: 'sort', target: string, docs: string[], before: boolean, field?: string): void
	(event: 'sort', target: string, docs: string[], before: boolean, children?: boolean): void
}>();


const docMeta = computed(() => props.meta);

const loadingCount = ref(0);
const loading = computed(() => props.loading || Boolean(loadingCount.value));

const data = ref<any[]>([]);
watchEffect(() => {
	data.value = props.data;
});


const editField = ref('');
const editValue = ref<Column>();
const valueEditorVisible = ref(false);
function edit(field: string, value?: Column) {
	editField.value = field;
	editValue.value = value;
	valueEditorVisible.value = true;
}
function add(field: string, index: number) {
	editField.value = field;
	editValue.value = {index: index + 2} as any;
	valueEditorVisible.value = true;
	// emit('add', field, index);
}

/******** 创建按钮 ********/
function create(v: any) {
	emit('create', v, true);
}
function clickTitle(event: Event, data: any) {
	if (!props.detail) {
		return;
	}
	event.preventDefault();
	emit('update:modelValue', data?.name === props.modelValue?.name ? undefined : data);
}
/******** 数据处理 ********/
const stages = computed(() =>
	props.configuration.groups.map(g => getStages(g, docMeta.value, data.value)) || []);
const key = ref(0);
watch(stages, () => key.value++);

function saveGroupValue(
	field: string,
	data: Partial<Column> & { value: any, index?: number } | boolean | number | string,
) {
	const groups = getNewGroups(stages.value, field, data);
	if (!groups) {
		return;
	}
	if (loading.value) {
		return;
	}
	emit('update:configuration', {groups});
}


async function update(name: string, value: any) {
	// TODO: 移动到 GlobalView 中实现
	if (loading.value) {
		return;
	}
	loadingCount.value++;
	await frappe.call('guigu_kanban.kanban.update_doc', {
		doctype: props.meta.name,
		name,
		value,
	})
		.then(() => {
			emit('refresh');
		}, () => {
			data.value = [...data.value];
		})
		.then(() => {
			loadingCount.value--;
		});
}


/******** 视图部分 ********/
const fieldDef = computed<Field[]>(() => props.view.fields.map(({
	showLabel, fieldOptions, enableLink, format,
}) => ({
	title: __(fieldOptions.label || 'Field'),
	name: fieldOptions.fieldname,
	field: fieldOptions,
	showLabel,
	enableLink,
	format,
})));

function addBlock(key: string) {
	// blocks.value.push({
	//   name: blocks.value.length,
	//   status: stage,
	//   title: '标题',
	// });
}
</script>
<style scoped lang="less">
.main {
	overflow: auto;
}
</style>
