<template>
	<div style="height: 100%; display: flex;align-items: center;justify-content: space-between;">
		<div>
			<Icon :icon="icon"/>
			<span :style="{
				cursor: isProcess ? '' : 'pointer',
				textDecoration: isProcess ? '' : 'underline'
			}" @click="clickLabel">
				{{ fullName }}</span>
			<span style="color: red;" v-show="disable">{{ `（${tt('Deactivate')})` }}</span>
		</div>
		<div style="display: flex;align-items: center;">
			<span class="core_circle" v-if="isCore"></span>
			<ElDropdown @command="command" trigger="click" v-if="params.hasPermission">
				<ElIcon style="cursor: pointer;"><MoreFilled /></ElIcon>
				<template #dropdown>
					<ElDropdownMenu>
						<ElDropdownItem command="add" v-if="isProcess">
							{{ tt('New Work List') }}
						</ElDropdownItem>
						<ElDropdownItem
							command="disable"
							v-if="!isProcess && !disable"
							style="color:red">
							{{ tt('Deactivate') }}
						</ElDropdownItem>
						<ElDropdownItem command="enable" v-if="!isProcess && disable" >
							{{ tt('Enable') }}
						</ElDropdownItem>
					</ElDropdownMenu>
				</template>
			</ElDropdown>
		</div>
	</div>
</template>
<script setup lang="ts">
import type {CellClassParams} from 'ag-grid-community';
import {MoreFilled} from '@element-plus/icons-vue';
import {ElDropdown, ElDropdownMenu, ElDropdownItem, ElIcon} from 'element-plus';
import {computed} from 'vue';

import Icon from '../../../../component/Icon/index.vue';

import {processLevel} from './helper';
import type {LevelStyle, TableData, ProcessData} from './type';

const props = defineProps<{
	params: CellClassParams& {
		disableStandardWorklist: (data: TableData) => void;
		addStandardWorklist: (data: TableData) => void;
		enableStandardWorklist: (data: TableData) => void;
		editDetail: (processName: string) => void;
		hasPermission: boolean;
		levelStyle: LevelStyle[];
		processList: ProcessData[];
		separator: string;
	}
}>();
const tt = __;
const isProcess = computed(() => props.params.data?.is_process);
const isCore = computed(() => props.params.data?.is_core);
const icon = computed(() => props.params.levelStyle.find(item => item.level
	=== processLevel[props.params.data?.level])?.icon || '');
const disable = computed(() => props.params.data?.disable);
const fullName = computed(() => {
	const {data, processList, separator} = props.params;
	if (!data) {
		return '';
	}
	const process = data.is_process ? null : processList.find(item => item.name === data.parent);
	const prefix = process ? `${process.full_process_code}${separator}` : '';
	return `${prefix}${data.code} ${data.title}`;
});
// 删除 / 新建工作清单
function command(action: 'add'| 'disable' | 'enable') {
	if (!props.params.data) {
		return;
	}
	switch (action) {
		case 'add':return props.params.addStandardWorklist(props.params.data);
		case 'disable':return	props.params.disableStandardWorklist(props.params.data);
		case 'enable':return	props.params.enableStandardWorklist(props.params.data);
	}
}
// 点击标准工作清单label查看详情
function clickLabel() {
	if (isProcess.value || !props.params.data) {
		return;
	}
	props.params.editDetail(props.params.data.name);
}
</script>
<style>
	.core_circle {
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: red;
		margin-right: 20px;
	}
</style>
