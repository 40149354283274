export default function setFullscreen(e?: any) {
	if (!e) { return; }
	if (e.requestFullScreen) {
		e.requestFullScreen();
	} else if (e.webkitRequestFullScreen) {
		e.webkitRequestFullScreen();
	} else if (e.mozRequestFullScreen) {
		e.mozRequestFullScreen();
	}
}
