import Viewer from 'viewerjs';

import fileUpload from './file_uploader';

const attachTypes = ['Attach', 'Attach Image'];
const ulStyle = `
list-style: none;
white-space: nowrap;
flex-direction: row;
align-items: center;
padding: 0;
margin: 0;
margin-block-start: 8px;
overflow: auto;
`;
const liStyle = `
display: inline-flex;
width: 100px;
height: 100px;
align-content: center;
justify-content: center;
flex-wrap: wrap;
flex-direction: row;
align-items: center;
box-shadow: 4px 4px 15px #666;
`;
const imgStyle = `
inline-size: 100%;
block-size: 100%;
cursor: pointer;
object-fit: cover;
`;

class FileUploader {
	root = document.createElement('ul');
	button = document.createElement('button');
	show = false;
	constructor(df, frm) {
		if (!df || !frm) {
			return;
		}
		const dt = frappe.get_meta(df.options);
		if (!dt) {
			return;
		}
		const [field, ...fields] = dt.fields.filter(d => attachTypes.includes(d.fieldtype));
		if (!field || fields.length) {
			return;
		}
		const isImage = field.fieldtype === 'Attach Image';
		const fileField = field.fieldname;
		const {root} = this;
		root.setAttribute('style', ulStyle);
		const refresh = () => {
			root.innerHTML = '';
			const data = frm.doc[df.fieldname];
			const attachments = frm.attachments.get_attachments();
			if (!Array.isArray(data)) {
				return;
			}
			for (const d of data) {
				if (!d) {
					continue;
				}
				const url = d[fileField];
				if (!url) {
					continue;
				}
				const thumbnail = attachments.find(a => a.file_url === url && a.thumbnail_url);
				if (!thumbnail) {
					continue;
				}
				const {thumbnail_url} = thumbnail;
				if (!thumbnail_url) {
					continue;
				}
				const li = document.createElement('li');
				const img = document.createElement('img');
				img.src = thumbnail_url;
				img.setAttribute('style', imgStyle);
				img.setAttribute('data-original', url);
				li.setAttribute('style', liStyle);
				li.addEventListener('click', () => {
					const view = new Viewer(root, {url: 'data-original', hidden() {
						view.destroy();
					}});
				});
				li.appendChild(img);
				root.appendChild(li);
			}
		};
		this.refresh = refresh;
		const {button} = this;
		button.className = 'btn btn-xs btn-secondary';
		button.appendChild(document.createTextNode(`批量添加${isImage ? '图片' : '附件'}`));
		button.addEventListener('click', () => {
			fileUpload(frm.is_new() ? {
				filetypes: isImage ? ['image/*'] : [],
			} : {
				filetypes: isImage ? ['image/*'] : [],
				doctype: frm.doc.doctype,
				docname: frm.doc.name,
				fieldname: df.fieldname,
			}, file_doc => {
				if (!file_doc) {
					return;
				}
				const {fieldname} = df;
				const data = frm.doc[fieldname];
				frm.attachments.add_attachment(file_doc);
				frm.get_docinfo().attachments ||= [];
				frm.attachments.get_attachments().push(file_doc);
				const value = {[fileField]: file_doc.file_url};
				frm.set_value(fieldname, Array.isArray(data) ? [...data, value] : [value]);
			}, ()=>{
				if (frm.doc.docstatus === 1) {
					frm.save('Update');
				} else {
					frm.save();
				}
			});
		});
		this.show = true;
	}
	refresh() {

	}
}
const {prototype} = frappe.ui.form.ControlTable;
const old_refresh_input = prototype.refresh_input;
const old_make = prototype.make;

prototype.make = function make() {
	old_make.call(this, arguments);
	this.guiguFileUploader = new FileUploader(this.df, this.frm);
};

prototype.refresh_input = function refresh_input() {
	old_refresh_input.call(this, arguments);
	const {guiguFileUploader} = this;
	if (!guiguFileUploader?.show) {
		return;
	}
	this.$wrapper.append(guiguFileUploader.root);
	this.$wrapper.find('.grid-buttons').append(guiguFileUploader.button);
	guiguFileUploader?.refresh();
};
