<template>
	<div class="detail-container">
		<el-form
			class="interview-form"
			:labelPosition="smallMeta ? 'top' : 'right'"
			ref="formRef"
			:model="form"
			:rules="rules"
			labelWidth="75px"
			@change="formChange"
		>
			<el-row :gutter="10">
				<el-col :span="8">
					<el-form-item
						:label="tt('Conversation Leader') + ':'"
						prop="leader"
					>
						<el-select
							@change="changeLeader"
							filterable
							:disabled="isDisabled('leader')"
							v-model="form.leader"
						>
							<el-option
								v-for="user in leaders"
								:label="user.full_name"
								:value="user.name"
							/>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item
						:label="tt('Date Of Conversation') + ':'"
						prop="assess_date"
					>
						<el-date-picker
							@change="changeDate"
							:disabled="isDisabled('assess_date')"
							v-model="form.assess_date"
							valueFormat="YYYY-MM-DD"
						></el-date-picker>
					</el-form-item>
				</el-col>
				<!-- <el-col :span="8">
					<el-form-item label="绩效年度:" prop="date">
					{{ form.date }}
					</el-form-item>
					</el-col> -->
			</el-row>
			<el-form class="interview-form-bottom" labelPosition="top">
				<el-form-item
					:label="
						tt('Summarize the work during the assessment cycle') +
						':'
					"
					prop="summary"
				>
					<el-input
						:disabled="isDisabled('summary')"
						type="textarea"
						:rows="4"
						v-model="form.summary"
					/>
				</el-form-item>
				<el-form-item
					:label="
						tt(
							'Positive performance and advantages within the assessment cycle',
						) + ':'
					"
					prop="advantage"
				>
					<el-input
						:disabled="isDisabled('advantage')"
						type="textarea"
						:rows="4"
						v-model="form.advantage"
					/>
				</el-form-item>
				<el-form-item
					:label="
						tt('Identify the shortcomings and weaknesses') + ':'
					"
					prop="shortcoming"
				>
					<el-input
						:disabled="isDisabled('shortcoming')"
						type="textarea"
						:rows="4"
						v-model="form.shortcoming"
					/>
				</el-form-item>
				<el-form-item
					:label="
						tt(
							'Specific requirements and directions for the new assessment cycle',
						) + ':'
					"
					prop="requirement"
				>
					<el-input
						:disabled="isDisabled('requirement')"
						type="textarea"
						:rows="4"
						v-model="form.requirement"
					/>
				</el-form-item>
				<el-form-item
					:label="
						tt(
							'In the future, training and learning should be strengthened',
						) + ':'
					"
					prop="learning"
				>
					<el-input
						:disabled="isDisabled('learning')"
						type="textarea"
						:rows="4"
						v-model="form.learning"
					/>
				</el-form-item>
				<el-form-item
					:label="tt('Other Opinions And Suggestions') + ':'"
					prop="suggestion"
				>
					<el-input
						:disabled="isDisabled('suggestion')"
						type="textarea"
						:rows="4"
						v-model="form.suggestion"
					/>
				</el-form-item>
			</el-form>
		</el-form>
		<div class="confirm-container" v-if="hadInterview">
			<div v-show="userConfirm">
				{{ tt('Interviewee (Signature)') }}:{{
					props.assessInfo?.user_doc.full_name
				}}
				{{ tt('Confirmed') }}
			</div>
			<el-checkbox
				:disabled="isDisabled('self_confirm')"
				v-show="!userConfirm"
				v-model="isUserConfirmed"
				@change="userToConfirm"
				:label="tt('Interviewee (Signature)')"
			/>
			<div v-show="leaderConfirm">
				{{ tt('Conversation Leader (Signature)') }}:{{
					leaderDoc?.full_name
				}}
				{{ tt('Confirmed') }}
			</div>
			<el-checkbox
				:disabled="isDisabled('leader_confirm')"
				v-show="!leaderConfirm"
				v-model="isLeaderConfirmed"
				@change="leaderToConfirm"
				:label="tt('Conversation Leader (Signature)')"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { ElMessage } from 'element-plus';
import type { FormInstance } from 'element-plus';

import type * as Permission from '../../Permission';
import { getBinaryContent, exportDocx, saveAs } from '../../export/export';
import type { AssessInfo, Permissions } from '../type';

import { getData } from './helper';

const tt = __;
interface Props {
	assessInfo?: AssessInfo;
	lockStatus: boolean;
	assessUserPermission: Permission.UserPermission;
	periodType: 'week' | 'month';
	smallMeta: boolean;
	type: string;
	toolDevWidth: string;
}
const props = defineProps<Props>();
interface Emit {
	(e: 'create', data: any): void;
	(e: 'update', data: any): void;
	(e: 'updateStatus', isChanged: boolean): void;
}
const emit = defineEmits<Emit>();
const userConfirm = ref<boolean>(false);
const leaderConfirm = ref<boolean>(false);
const leaders = ref<{ name: string; full_name: string }[]>([]);
const formRef = ref<FormInstance>();
const bottom_height = ref<string>('');
defineExpose({
	exportInterview,
	save,
});

const form = reactive({
	user: '',
	organization: '',
	job: '',
	leader: '',
	date: '',
	assess_date: '',
	summary: '',
	advantage: '',
	shortcoming: '',
	requirement: '',
	learning: '',
	suggestion: '',
	userConfirm: 0,
	leaderConfirm: 0,
});

const isUserConfirmed = ref<boolean>(!!form.userConfirm);
const isLeaderConfirmed = ref<boolean>(!!form.leaderConfirm);

watch(
	() => form.userConfirm,
	newVal => {
		isUserConfirmed.value = !!newVal;
	},
);

watch(
	() => form.leaderConfirm,
	newVal => {
		isLeaderConfirmed.value = !!newVal;
	},
);

const hadInterview = computed(() =>
	Boolean(props.assessInfo?.interview_record?.name),
);
watch(
	() => [
		props.assessInfo?.interview_record?.name,
		props.toolDevWidth,
		props.smallMeta,
	],
	() => {
		if (Boolean(props.assessInfo?.interview_record?.name)) {
			if (props.smallMeta) {
				bottom_height.value = `calc(100vh - 266px)`;
			} else {
				bottom_height.value = `calc(100vh - 220px - ${props.toolDevWidth})`;
			}
		} else {
			bottom_height.value = `calc(100vh - 190px)`;
		}
	},
	{ immediate: true },
);

const isDisabled = (field: string) => {
	if (props.lockStatus) {
		return true;
	}
	if (hadInterview.value) {
		if (field === 'leader_confirm') {
			if (form.leader !== frappe.user.name) {
				return true;
			}
		}
		return !props.assessUserPermission.writable.has(field);
	}
	return !props.assessUserPermission.creatable;
};

const rules = reactive({
	user: [
		{
			required: true,
			message: __('Please fill in the interviewee'),
			trigger: 'blur',
		},
	],
	leader: [
		{
			required: true,
			message: __('Please fill in the conversation leader'),
			trigger: 'blur',
		},
	],
	assess_date: [
		{
			required: true,
			message: __('Please fill in the date of the conversation'),
			trigger: 'blur',
		},
	],
});
watch(
	() => props.assessInfo,
	() => {
		const interview = props.assessInfo?.interview_record;
		form.user = props.assessInfo?.user_doc.full_name || '';
		form.organization =
			props.assessInfo?.organization?.organization_name || '';
		if (props.periodType === 'week') {
			form.date = props.assessInfo?.date
				? moment(props.assessInfo.date).format(__('gggg-ww Week'))
				: '';
		} else {
			form.date = props.assessInfo?.date
				? moment(props.assessInfo.date).format('YYYY-MM')
				: '';
		}
		form.job = props.assessInfo?.job_doc?.job_title || '';
		if (interview) {
			form.organization = interview.organization;
			form.job = interview.job;
			form.leader = interview.leader_doc.name;
			form.assess_date = interview.assess_date;
			form.summary = interview.summary;
			form.advantage = interview.advantage;
			form.shortcoming = interview.shortcoming;
			form.requirement = interview.requirement;
			form.learning = interview.learning;
			form.suggestion = interview.suggestion;
			userConfirm.value = Boolean(interview.self_confirm);
			leaderConfirm.value = Boolean(interview.leader_confirm);
		}
	},
	{ immediate: true },
);
watch(
	() => props.assessInfo,
	() => {
		getLeaders();
	},
	{ immediate: true },
);
const leaderDoc = computed(() =>
	leaders.value.find(item => item.name === form.leader),
);
async function getLeaders() {
	if (!props.assessInfo) {
		return;
	}
	const filters: [string, string, any][] = [];
	if (props.type === 'project') {
		filters.push(['project', '=', props.assessInfo.department_doc?.name]);
	} else {
		filters.push([
			'sub_company',
			'=',
			props.assessInfo.department_doc?.name,
		]);
	}
	const userMemberType =
		props.type === 'project'
			? 'Guigu Project Member'
			: 'Guigu Sub Company Member';
	const members = await frappe.db.get_list(userMemberType, {
		filters,
		fields: ['user'],
		limit: 0,
	});
	if (members.length === 0) {
		leaders.value = [];
		return;
	}
	const res = await frappe.db.get_list<{ name: string; full_name: string }>(
		'User',
		{
			filters: [['name', 'in', members.map(item => item.user)]],
			fields: ['name', 'full_name'],
			limit: 0,
		},
	);
	leaders.value = res || [];
}
async function onConfirm(type: 'user' | 'leader') {
	if (!formRef.value) {
		return;
	}
	await formRef.value.validate(async (valid, fields) => {
		if (valid) {
			if (!props.assessInfo) {
				return;
			}
			if (props.assessInfo?.interview_record) {
				updateInterview();
			} else {
				createInterview();
			}
		} else {
			ElMessage({
				type: 'warning',
				message: __(
					'Please fill in the date of the conversation and the conversation leader',
				),
			});
			if (type === 'user') {
				userConfirm.value = false;
			} else {
				leaderConfirm.value = false;
			}
		}
	});
}
async function userToConfirm() {
	onConfirm('user');
	handleConfirmCallback('self_confirm');
}
async function leaderToConfirm(value: string | number | boolean) {
	onConfirm('leader');
	handleConfirmCallback('leader_confirm');
}
async function save() {
	if (!formRef.value) {
		return;
	}
	await formRef.value.validate(async (valid, fields) => {
		if (valid) {
			if (!props.assessInfo) {
				return;
			}
			if (props.assessInfo?.interview_record) {
				updateInterview();
			} else {
				createInterview();
			}
		}
	});
}

function getFormData() {
	return {
		user: props.assessInfo?.user_doc.name,
		date: props.assessInfo?.date,
		leader: form.leader,
		assess_date: form.assess_date,
		summary: form.summary,
		advantage: form.advantage,
		shortcoming: form.shortcoming,
		requirement: form.requirement,
		learning: form.learning,
		suggestion: form.suggestion,
		job: form.job,
		organization: form.organization,
		self_confirm: isUserConfirmed.value ? 1 : 0,
		leader_confirm: isLeaderConfirmed.value ? 1 : 0,
	};
}
async function createInterview() {
	if (!props.assessInfo) {
		return;
	}
	const data = getFormData();
	emit('create', data);
}
async function updateInterview() {
	if (!props.assessInfo?.interview_record) {
		return;
	}
	const data = getFormData();
	emit('update', data);
}

async function exportInterview() {
	if (!props.assessInfo) {
		return;
	}
	const template = await getBinaryContent(
		'/assets/guigu_hr/files/interview_template.docx',
	);
	const data = {
		...form,
		leader: leaderDoc.value?.full_name || '',
	};
	const out = await exportDocx(template, data);
	const fileName =
		props.periodType === 'month'
			? __('{} {} Monthly Meeting Record. docx', [
					form.user,
					moment(props.assessInfo.date).format('YYYY-MM'),
			  ])
			: __('{} {} Weekly Interview Record. docx', [
					form.user,
					moment(props.assessInfo.date).format('YYYY-ww'),
			  ]);
	saveAs(out, fileName);
}

function changeLeader(v: string) {
	const data = getData(props.periodType, props.assessInfo);
	emit('updateStatus', !frappe.utils.deep_equal(form, data));
}
function changeDate(v: string) {
	const data = getData(props.periodType, props.assessInfo);
	emit('updateStatus', !frappe.utils.deep_equal(form, data));
}
function formChange() {
	const data = getData(props.periodType, props.assessInfo);
	emit('updateStatus', !frappe.utils.deep_equal(form, data));
}

function handleConfirmCallback(field) {
	const alertDiv = document.createElement('div');
	alertDiv.innerHTML = `
		<div style="display: flex; align-items: center; justify-content: space-between;">
			<span>${__('Signed confirmation completed')}</span>
			<button 
          id='cancel-signature-btn-3'
          style="background: none; border: none; color: #aaa; font-weight: bold; cursor: pointer; margin-left: 10px;" 
          onmouseover="this.style.color='#1C86EE'" 
          onmouseout="this.style.color='#aaa'">${__('Revoke Signature')}
			</button>
		</div>
	`;

	frappe.show_alert(
		{
			message: alertDiv.innerHTML,
			indicator: 'green',
		},
		60,
	);

	document
		.querySelector('#cancel-signature-btn-3')
		.addEventListener('click', async () => {
			await frappe.call({
				method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_assessment_interview.guigu_hr_assessment_interview.cancel_signature',
				args: {
					name: props.assessInfo?.interview_record.name,
					period_type: props.periodType,
					type: props.type,
					field,
				},
			});

			if (field === 'self_confirm') {
				form.userConfirm = '';
				isUserConfirmed.value = false;
			} else if (field === 'leader_confirm') {
				form.leaderConfirm = '';
				isLeaderConfirmed.value = false;
			}

			const data = getFormData();
			emit('update', data);

			frappe.show_alert(
				{
					message: __('Revoked signature confirmation'),
					indicator: 'blue',
				},
				10,
			);
		});
}
</script>

<style lang="less" scoped>
.confirm-container {
	display: flex;
	justify-content: space-around;
	padding: 16px 0px;
	label {
		margin-bottom: 0px;
	}
}
.interview-form :deep(label) {
	margin-bottom: 0;
}
.interview-form :deep(.el-form-item__label) {
	font-weight: bolder;
	white-space: nowrap;
}
.interview-form-bottom {
	overflow: scroll;
	height: v-bind(bottom_height);
	padding: 8px 0px;
	border-top: 1px solid #d9d9d9;
	border-bottom: 1px solid #d9d9d9;
}
</style>
