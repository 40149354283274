import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElSelect"], {
    modelValue: $setup.val,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.val) = $event)),
    filterable: "",
    remote: "",
    onChange: $setup.change,
    remoteMethod: $setup.search,
    placeholder: $props.label && $setup.tt($props.label),
    onFocus: $setup.focus,
    defaultFirstOption: "",
    loading: $setup.loading || $setup.waiting
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.opts, ({ value, label, description }) => {
        return (_openBlock(), _createBlock($setup["ElOption"], {
          key: value,
          label: label,
          value: value
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(label) + " ", 1 /* TEXT */),
            description
              ? (_openBlock(), _createElementBlock("small", _hoisted_1, _toDisplayString(description), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "value"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "placeholder", "loading"]))
}