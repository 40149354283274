import fieldTypes from '../components/GuiguFilterDialog/fieldTypes';


const dataType = new Set([
	'Text',
	'Small Text',
	'Text Editor',
	'HTML Editor',
	'Data',
	'Code',
	'Read Only',
]);
export default function makeFilterLineFields(meta: locals.DocType, noNameField:boolean = false) {
	const doctype_fields = meta.fields;
	const {title_field} = meta;
	const fields: {
		fieldtype: string;
		label?: any;
		options?: string;
		fieldname: string;
		condition: string;
	}[] = doctype_fields.filter(df =>
		df.fieldname === title_field
			|| (df.in_standard_filter && frappe.model.is_value_type(df.fieldtype))).map(df => {
		const type = df.fieldtype;
		const fieldtype = dataType.has(type) ? 'Data' : type;
		const {options} = df;
		const condition = fieldtype === 'Data' ? 'like' : '=';

		return {
			fieldtype,
			label: __(df.label),
			options,
			fieldname: df.fieldname,
			condition,
			ignore_link_validation: fieldtype === 'Dynamic Link',
		};
	});
	if (!noNameField) {
		fields.unshift({fieldtype: 'Data', label: 'ID', condition: 'like', fieldname: 'name'});
	}
	return fields.filter(field => fieldTypes.has(field.fieldtype?.toLowerCase()));
}
