import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn';

import App from '../components/levelReport/index.vue';
function loadGuiguHrProjectLevelReport(wrapper) {
	const app = createApp(App, { type: 'project' });

	app.use(ElementPlus, { size: 'small', locale: zhCn });
	app.mount(wrapper);
}
frappe.provide('frappe.guigu_hr_assessment');
frappe.guigu_hr_assessment.pages = frappe.guigu_hr_assessment.pages || {};
frappe.guigu_hr_assessment.pages.loadGuiguHrProjectLevelReport = loadGuiguHrProjectLevelReport;
