import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElButton"], {
    type: "text",
    size: "small",
    onClick: _cache[0] || (_cache[0] = $event => ($setup.props.params.openCreateDialog($setup.props.params.data)))
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString($setup.tt('Create task')), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }))
}