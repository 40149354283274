import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7960409f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-container" }
const _hoisted_2 = {
  key: 0,
  class: "add-button"
}
const _hoisted_3 = {
  key: 1,
  class: "add-button"
}
const _hoisted_4 = { class: "description" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["FixScore"], {
      linkAssessList: $setup.linkAssessList,
      tempAssessList: $setup.tempAssessList,
      permission: $props.detailPermission,
      assessmentPermission: $props.assessmentPermission,
      lockStatus: $props.lockStatus,
      onUpdate: $setup.update,
      onRemove: _cache[0] || (_cache[0] = (data, type) => $setup.emit('remove', data, type)),
      onSelectionChanged: $setup.onSelectionChanged
    }, null, 8 /* PROPS */, ["linkAssessList", "tempAssessList", "permission", "assessmentPermission", "lockStatus"]),
    ($setup.createShow && $setup.taskCreatable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (($props.fixSelectedRows?.value || []).length > 0)
            ? (_openBlock(), _createBlock($setup["ElButton"], {
                key: 0,
                onClick: _cache[1] || (_cache[1] = () => $setup.command('assessmentStandardTemplate')),
                type: "primary",
                link: "",
                icon: $setup.Plus,
                title: $setup.tt('Assessment Standard Template')
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.tt('Assessment Standard Template')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["icon", "title"]))
            : _createCommentVNode("v-if", true),
          _createVNode($setup["ElButton"], {
            onClick: _cache[2] || (_cache[2] = () => $setup.command('createTempTask')),
            type: "primary",
            link: "",
            icon: $setup.Plus,
            title: $setup.tt('New Temporary Job')
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('New Temporary Job')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["icon", "title"])
        ]))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["ExtraScoreComp"], {
      extraAssessList: $setup.extraAssessList,
      permission: $props.extraPermission,
      lockStatus: $props.lockStatus,
      onUpdate: $setup.update,
      onRemove: _cache[3] || (_cache[3] = (data, type) => $setup.emit('remove', data, type))
    }, null, 8 /* PROPS */, ["extraAssessList", "permission", "lockStatus"]),
    ($setup.createShow && $props.extraPermission?.creatable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode($setup["ElButton"], {
            onClick: _cache[4] || (_cache[4] = () => $setup.command('createExtraScore')),
            type: "primary",
            link: "",
            icon: $setup.Plus,
            title: $setup.tt('Create New Addition and Subtraction Items')
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('Create New Addition and Subtraction Items')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["icon", "title"])
        ]))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["LeaderConfirm"], {
      type: $props.type,
      assessInfo: $props.assessInfo,
      assessUserPermission: $props.assessUserPermission,
      lockStatus: $props.lockStatus,
      onUpdate: _cache[5] || (_cache[5] = $event => ($setup.emit('update')))
    }, null, 8 /* PROPS */, ["type", "assessInfo", "assessUserPermission", "lockStatus"]),
    _createElementVNode("div", {
      style: _normalizeStyle($setup.style)
    }, [
      _createTextVNode(_toDisplayString($setup.tt('Instructions For Filling Out The Form')) + ": ", 1 /* TEXT */),
      _createElementVNode("ul", _hoisted_4, [
        _createElementVNode("li", null, _toDisplayString($setup.tt(
							'In principle, the monthly work plan (key performance indicators) should not exceed 6 items;',
						)), 1 /* TEXT */),
        _createElementVNode("li", null, _toDisplayString($setup.tt(
							'Achieving standards (goals) refers to the final results or effects achieved by modifying the work plan (key performance indicators);',
						)), 1 /* TEXT */),
        _createElementVNode("li", null, _toDisplayString($setup.tt(
							'The maximum score for monthly plans is 100, and the total weight of each monthly work plan is 100%. The corresponding weight corresponds to the corresponding score; There should be a monthly key task with a weight of no less than 30% in the general monthly plan;',
						)), 1 /* TEXT */),
        _createElementVNode("li", null, _toDisplayString($setup.tt(
							'Temporary addition or adjustment of specific weights for work tasks, achieved by adjusting the weights of other monthly plans;',
						)), 1 /* TEXT */),
        _createElementVNode("li", null, _toDisplayString($setup.tt(
							'The evaluation (scoring) criteria should include specific quantifiable assessment and scoring criteria for the results of the work plan;',
						)), 1 /* TEXT */),
        _createElementVNode("li", null, _toDisplayString($setup.tt(
							'Other bonus points generally refer to the completion of a certain work plan/task, achieving significant results or significantly exceeding expectations; Design challenge goals achieved; By optimizing management methods and adopting new work methods to efficiently complete related work, as well as other situations that have significantly improved work efficiency and effectiveness, points can be appropriately added according to the actual situation. Generally, the overall monthly bonus does not exceed 10 points;',
						)), 1 /* TEXT */),
        _createElementVNode("li", null, _toDisplayString($setup.tt(
							'Other deduction items generally refer to those that have a certain impact due to errors in daily work; Not following labor discipline and being negligent in work; Not obeying the work arrangements of direct superiors; Errors that were not deducted in the current period during other assessment periods; The problems in the previous assessment (performance improvement) have not been significantly corrected or have only been partially corrected; As well as other behaviors and events that affect work efficiency and effectiveness, points can be deducted based on actual situations, and there is no lower limit for monthly deduction.',
						)), 1 /* TEXT */)
      ])
    ], 4 /* STYLE */)
  ])), [
    [_directive_loading, $setup.loading]
  ])
}