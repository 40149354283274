
<template>
	<div class="container">
		<Main
			:columns="columns"
			:rows="rows"
			:parentKey="parentKey"
			:dateFields="dateFields"
			:endDateFields="endDateFields"
			:lines="lines"
			:dots="dots"
			v-model:checked="checkedIds"
			v-model:expanded="expandedIds"
			:startFixed="startFixed"

			:rowHeight="rowHeight"
			:tooltip="tooltip"
			:view="view" />
	</div>
	<slot name="pagination" />
</template>

<script lang="ts" setup>
import {computed, shallowRef, watch} from 'vue';

import {getTreeExpander, useExpander} from '../Expander';

import type {VueColumn} from './nyloongTable/vue';
import Main from './Main.vue';
import Title from './Title.vue';
import getColumns from './getColumns';
import type {GanttConfiguration} from './types';
import getLine from './getLine';
import getDot from './getDot';
import ganttTooltip from './ganttTooltip';
import getEstimatedLine from './getEstimatedLine';
import Tree from './nyloongTable/core/Tree';
import Check from './nyloongTable/core/Check';
const minRowHeight = 24;


const props = defineProps<{
	meta: locals.DocType;
	options: Record<string, any>;

	data: locals.Doctype[];
	total: number;
	loading?: boolean;

	modelValue: any;
	selected: any[];

	infiniteScroll: boolean;
	page: number;
	limit: number;
	group: GlobalView.Group[];
	sort: GlobalView.MainLoaderOptions['order'];
	filters?: any;

	detail?: boolean;
	rowAction?: any;


	view?: GlobalView.View;
	configuration: GanttConfiguration;
}>();


const emit = defineEmits<{
	(event: 'refresh'): void;
	(event: 'delete', doc: any): void;
	(event: 'create', data: any, extend?: boolean): void;
	(event: 'nextPage'): void;
	(event: 'update:modelValue', value: any): void;
	(event: 'update:selected', value: any[]): void;
	(event: 'update:data', data: locals.Doctype[]): void;
	(event: 'filter', field: string, operator: string, value: any): void;


	(event: 'sort', target: string, docs: string[], before: boolean, field?: string): void
	(event: 'sort', target: string, docs: string[], before: boolean, children?: boolean): void
}>();

const map = computed(() => {
	const name = 'name';
	return new Map(props.data.map(v => [v[name], v]));
});

function nextPage() {
	emit('nextPage');
}
function refresh() {
	emit('refresh');
}

function setValue(value?: any) {
	emit('update:modelValue', value);
}
const checkedIds = computed({
	get: () => props.selected.map(v => v.name),
	set: value => {
		const m = map.value;
		emit('update:selected', value.map(v => m.get(v)));
	},
});
const parentKey = computed(() => props.meta.nsm_parent_field);
const rows = computed({
	get: () => props.data,
	set: v => emit('update:data', v),
});

function setFilter(field: string, operator: string, value: any) {
	emit('filter', field, operator, value);
}
watch(rows, () => {
	checkedIds.value = [];
});


const expandedIds = shallowRef<string[]>();


const titleDocField = computed(() => {
	const fieldName = props.meta.title_field;
	const {fields} = props.meta;
	return fieldName && fields.find(v => v.fieldname === fieldName) || null;
});
function getHref(name: string) {
	const doctype = frappe.router.slug(titleDocField.value?.parent || props.meta.name);
	return `/app/${doctype}/${encodeURIComponent(name)}`;
}

const baseLines = computed(() => props.configuration?.lines?.map(getLine) || []);
const estimatedLines = computed(() => props.configuration
	?.estimatedLines?.map(getEstimatedLine) || []);

const dateFields = computed(() => Object.fromEntries([
	...props.configuration?.lines?.map(({start}) => [start]) || [],
	...props.configuration?.estimatedLines?.map(({start}) => [start]) || [],
	...props.configuration?.dots?.map(({date}) => [date]) || [],
].flat().filter(Boolean as any as (v: any) => v is string).map(v => [v, v])));
const endDateFields = computed(() => Object.fromEntries([
	...props.configuration?.lines?.map(({end}) => [end]) || [],
	...props.configuration?.estimatedLines?.map(({plan, end}) => [plan, end]) || [],
].flat().filter(Boolean as any as (v: any) => v is string).map(v => [v, v])));
const lines = computed(() => [...baseLines.value, ...estimatedLines.value]);
const dots = computed(() => props.configuration?.dots?.map(getDot) || []);
const rowHeight = computed(() => props.configuration?.lineHeight || minRowHeight);
const tooltip = computed(() => ganttTooltip.bind(null, props.configuration?.lines || []));

const autoGroupColumnDefHeaderName = computed(() => {
	const titleField = titleDocField.value;
	return titleField ? __(titleField.label) : 'ID';
});
const autoGroupColumnDefField = computed(() => {
	const titleField = titleDocField.value;
	return titleField && titleField.fieldname || 'name';
});
const main = computed(() => {
	const main = props.view?.titleField;
	if (!main) {
		return {width: 200};
	}
	const {width, maxWidth, minWidth} = main;
	return {width: width || 200, maxWidth, minWidth};
});
const mainColumn = computed<VueColumn>(() => ({
	tree: true,
	...main.value,
	title: autoGroupColumnDefHeaderName.value,
	field: autoGroupColumnDefField.value || 'name',
	component: Title,
	extensions: [Check, Tree],
	meta: {
		preventClick(e: Event, data: any) {
			if (props.detail) {
				e.preventDefault();
				setValue(data);
			}
		},
		getHref,
		del(data: any) {
			emit('delete', data);
		},
		get meta() {
			return props.meta;
		},
		get loading() {
			return props.loading;
		},
		get rowAction() {
			return props.rowAction;
		},
		get titleField() {
			return titleDocField.value;
		},
		get linkField() {
			return props.view?.linkField;
		},
		emit,
	},
	minWidth: 100,
	resizable: true,
}));

const columnDefs = computed(() => getColumns(
	props.meta,
	setFilter,
	props.view?.fields || [],
));
const columns = computed<VueColumn[]>(() => [
	mainColumn.value, ...columnDefs.value]);


const startFixed = computed(() => Math.min(
	Math.max(0, props.configuration.fixedFieldColumns || 0),
	columnDefs.value.length,
) + 1);


const {setExpandable, expandable} = useExpander(computed(() => props.view?.name || ''));

const expanders = computed(() => {
	if (props.loading) {
		return [];
	}
	return getTreeExpander(props.data, props.meta.nsm_parent_field);
});
const updateExpanded = (ids: string[]) => {
	expandedIds.value = ids;
};
watch(expanders, expanders => {
	setExpandable(expanders, updateExpanded);
	const expandedDefault = props.configuration?.expandedDefault || 0;
	if (expandedDefault <= 0) {
		return;
	}
	const ex = expandable.value;
	if (!ex.length) {
		return;
	}
	const level = Math.min(ex.length, expandedDefault) - 1;
	ex[level]?.set();
}, {immediate: true});

</script>

<style scoped lang="less">
.container {
	width: 100%;
	height: 100%;
	padding: 8px;
	display: flex;
	font-size: 12px;

}
</style>
