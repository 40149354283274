<template>
	<div class="detail-width" ref="el">
		<div class="detail-container">
			<ElButton class="to_detail" size="small" type="default"
				@click="gotoDetail(dailyRecord)">
				{{ tt('Detail') }}
			</ElButton>
			<div class="detail-item">
				<span class="label">
					<Avatar :title="dailyRecord.user.full_name"
						:url="dailyRecord.user.user_image"
						:name="dailyRecord.user?.name"></Avatar>
					<span class="fullname">{{ dailyRecord.user.full_name }}</span>
					<span class="record_attendance_type" v-if="dailyRecord.subject&&dailyRecord.attendance_type"
						:style="{ background: dailyRecord.attendance_type.color }">
						{{ dailyRecord.attendance_type.type_name }}</span>
				</span>
			</div>
			<div class="detail-item" v-if="dailyRecord.subject">
				<span
					class="label">
					{{ tt('Subject') }}:
				</span>
				<span class="value">
					{{ dailyRecord.subject }}
				</span>
			</div>
			<div class="add-weather-item">
				<div v-if="dailyRecord.address">
					<span
						class="label">
						{{ tt('Address') }}:
					</span>
					<span class="value">
						{{ dailyRecord.address }}
					</span>
				</div>
				<div class="space" v-if="dailyRecord.address"></div>
				<div v-if="dailyRecord.weather">
					<span
						class="label">
						{{ tt('Weather') }}:
					</span>
					<span class="value">
						{{ dailyRecord.weather }}
					</span>
				</div>
			</div>
			<div class="detail-item" v-if="dailyRecord.description">
				<span
					class="label">
					{{ tt('Description') }}:
				</span>
				<span class="value ck-content">
					<span v-html="description"></span>
				</span>
			</div>
			<div class="detail-item"
				v-if="dailyRecord.comment && dailyRecord.comment.length > 0 && showComment">
				<span
					class="label">
				</span>
				<span class="value">
					<div class="table-container">
						<AgGridVue
							v-if="dailyRecord.comment && dailyRecord.comment.length > 0"
							class="ag-theme-guigu"
							:columnDefs="commentColumnDefs"
							:rowData="dailyRecord?.comment"
							:suppressDragLeaveHidesColumns="smallMeta"
							:localeText="zhCN"
							domLayout="autoHeight" />
					</div>
				</span>
			</div>
			<div class="detail-item"
				v-if="dailyRecord.files && dailyRecord.files.length > 0">
				<span
					class="label">
				</span>
				<span class="value">
					<div class="table-container">
						<div class="img-container" v-if="imageData.length > 0">
							<div class="img-item" v-for="img in imageData">
								<img
									class="img"
									:src="img" />
							</div>
						</div>
						<AgGridVue
							v-if="dailyRecord.files && dailyRecord.files.length > 0"
							class="ag-theme-guigu"
							:columnDefs="columnDefs"
							:rowData="dailyRecord?.files"
							:suppressDragLeaveHidesColumns="smallMeta"
							:localeText="zhCN"
							domLayout="autoHeight" />
					</div>
				</span>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { AgGridVue } from 'ag-grid-vue3';
import type { ICellRendererParams } from 'ag-grid-community';

import zhCN from '../../../../../../../guigu/guigu/public/js/agGrid/zhCN';

import Avatar from './Avatar.vue';
import type { DiaryRecordB } from './type';
import { useMetaQuery } from './useMetaQuery';
import { useObserve } from './useIntersection';
const smallMeta = useMetaQuery();
const detailButtonOpacity = computed(() => smallMeta.value ? 1 : 0);
const tt = __;
const el = ref<HTMLElement>();

interface Emits {
	(e: 'deleteDailyRecord', dailyRecord: DiaryRecordB): void
}
const emit = defineEmits<Emits>();
interface Props {
	dailyRecord: DiaryRecordB
	showComment: boolean
	searchString?: string
}
const props = defineProps<Props>();
useObserve(
	'Guigu Daily Record B',
	computed(() => props.dailyRecord.name),
	computed(() => props.dailyRecord.seen),
	computed(() => props.dailyRecord.modified),
	el,
);
function deletePhoto(dailyRecord: DiaryRecordB) {
	emit('deleteDailyRecord', dailyRecord);
}
async function gotoDetail(dailyRecord: DiaryRecordB) {
	frappe.set_route('Form', 'Guigu Daily Record B', props.dailyRecord.name);
}

const imageExt = [
	'bmp', 'jpg', 'jpeg', 'png', 'gif', 'tiff', 'svg', 'webp',
	'xbm', 'pjp', 'svgz', 'ico', 'jfif', 'pjpeg', 'avif',
];
const imageData = computed(() => {
	const imageArr = props.dailyRecord.files.map(item => {
		const arr = item.files?.split('.');
		const ext = arr[arr.length - 1];
		if (!imageExt.includes(ext.toLocaleLowerCase())) { return; }
		return item.files;
	}).filter(Boolean) as string[];
	return imageArr;
});
const description = computed(() => {
	const des = props.dailyRecord.description?.toString() || '';
	return props.searchString ? des.replace(
		new RegExp(props.searchString, 'g'),
		`<span style="color:#FA8C16">${props.searchString}</span>`,
	) : des;
});
const commentColumnDefs = [
	{
		headerName: tt('Comment'),
		field: 'comment',
		enableRowGroup: false,
		editable: false,
		filter: false,
		flex: 1,
		cellRenderer: (params: ICellRendererParams) => {
			const comment_owner = params.data.comment_by;
			// eslint-disable-next-line prefer-destructuring
			const comment_datetime = params.data.modified.slice(0, 16);
			const comment_content = params.data.content?.toString() || '';
			const tempElement = document.createElement('div');
			// 将 HTML 字符串插入临时元素
			tempElement.innerHTML = comment_content;
			// 获取纯文本内容
			const plainTextContent = tempElement.textContent || tempElement.innerText;
			return `<a>${comment_owner}(${comment_datetime}): ${plainTextContent}</a>`;
		},
	},
];
const columnDefs = [
	{
		headerName: tt('Daily Files'),
		field: 'files',
		enableRowGroup: false,
		editable: false,
		filter: false,
		flex: 1,
		cellRenderer: (params: ICellRendererParams) => {
			const fileUrl = params.value;
			const filenameArr = fileUrl.split('/');
			const filename = filenameArr[filenameArr.length - 1];
			return `<a target='blank' href='${params.value}' download='${filename}'>${params.value}</a>`;
		},
	},
];
</script>
<style lang="less" scoped>
.detail-width{
	position: relative;
	flex:1;
	overflow:auto;
}
.detail-container {
	position: relative;
	border-radius: 8px;
	margin-right: 8px;
	padding: 8px;
	padding-top: 0px;
	padding-left: 0px;

	&:hover {
		background: #f0f2f5;

		.to_detail {
			opacity: 1;
		}
	}

	.to_detail {
		position: absolute;
		right: 10px;
		opacity: v-bind(detailButtonOpacity);
	}

	.detail-item {
		margin: 4px 0;

		.label {
			margin-right: 8px;
			font-weight: bolder;

			.fullname {
				vertical-align: bottom;
				line-height: 25px;
			}
			.record_attendance_type{
				padding-left: 6px;
				padding-right: 6px;
				margin-left: 8px;
				color: #fff;
				font-size: 10px;
				padding-bottom: 2px;
				padding-top: 2px;
				border-radius:8px;
				background: #373b415e;
			}
		}

		.value {
			flex: 1;
			white-space: pre-wrap;
			word-break: break-all;
		}
	}
	.add-weather-item {
		margin: 4px 0;
		display:flex;
		.label {
			margin-right: 8px;
			font-weight: bolder;
		}
		.space {
			margin-left: 20px; /* 适当调整空白的大小 */
		}
}
}


.table-container {
	white-space: normal;
}

.img-container {
	margin-bottom: 8px;
	overflow-x: scroll;
	white-space: nowrap;
}

.img-item {
	display: inline-flex;
	width: 100px;
	height: 100px;
	vertical-align: middle;
	align-items: center;
	justify-content: center;
	box-shadow: 4px 4px 15px #666;
}

.img {
	width: 100px;
	height: 100px;
	object-fit: cover;
}
</style>
