
<template>
	<Page>
		<template #title>
			<h3 class="title">{{ tt('Dw Standard Work List') }}</h3>
		</template>
		<template #tools>
			<Tools
				:smallMeta="smallMeta"
				:processList="processList"
				:hasPermission="hasPermission"
				v-model:disable="disable"
				v-model:process="process"
				v-model:layoutType="layoutType"
				v-model:scope="scope"
				@toggleExpand="toggleExpand"
				@createWorkList="createWorkList"
			>
			</Tools>
		</template>
		<Skeleton
			v-loading="loading"
			:layout="layoutType"
			doctype="Dw Standard Worklist"
			:detailName="detailName"
			@onChange="updateData"
			v-model:form-visible="formVisible"
		>
			<Table
				ref="aggrid"
				class="table-container"
				:smallMeta="smallMeta"
				:dataList="dataList"
				:loading="loading"
				:hasPermission="hasPermission"
				:organizationMap="organizationMap"
				:processList="processList"
				:levelStyle="levelStyle"
				v-model:layoutType="layoutType"
				@updateData="updateData"
				@createWorkList="createWorkList"
				@showDetail="showDetail"
			></Table>
		</Skeleton>
	</Page>
</template>

<script setup lang="ts">
import {onMounted, onUnmounted, ref, watch} from 'vue';
import {vLoading} from 'element-plus';

import Page from '../../../../../../../guigu/guigu/public/js/components/page/index.vue';
import {useMetaQuery} from '../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';
import Skeleton from '../../../component/Skeleton/index.vue';
import {agGridTree} from '../../../utils/index';

import type {ProcessData, TableData, Result, Organization, LevelStyle} from './components/type';
import Table from './components/Table.vue';
import Tools from './components/Tools.vue';
import {getPermission, transformData, setData} from './components/helper';

const aggrid = ref<InstanceType<typeof Table> | null>(null);
const smallMeta = useMetaQuery();
const loading = ref<boolean>(false);
const process = ref<string>('');
const layoutType = ref<'modal'|'link'|'bottom'|'right'>('right');
const scope = ref<'Global' | 'Organization Unit Specific'>('Global');
const disable = ref<'all' | 'enable' | 'disable'>('enable');
const detailName = ref<string>('');
const formVisible = ref<boolean>(false);
const hasPermission = ref<boolean>(true);
const organization = ref<string>(frappe.tianjy.organization.getCurrent());
const processList = ref<ProcessData[]>([]);
const dataList = ref<TableData[]>([]);
const levelStyle = ref<LevelStyle[]>([]);
const organizationMap = ref<Map<string, Organization>>(new Map());

const tt = __;
function createWorkList(data?:TableData) {
	const docName = frappe.model.make_new_doc_and_get_name('Dw Standard Worklist', true);
	const docDetail = locals['Dw Standard Worklist'][docName] as locals.Doctype;
	docDetail.work_process = data?.name;
	showDetail(docDetail.name);
}
// 流程弹窗显示或者跳转页面
function showDetail(name: string) {
	if (layoutType.value === 'link') {
		frappe.set_route(['Form', 'Dw Standard Worklist', name]);
		return;
	}
	detailName.value = name;
	formVisible.value = true;
}
// 更新dataList数据
function updateData(params: { type: 'delete' | 'create' | 'update' | 'enable' | 'disable', data: any; }) {
	const {type, data} = params;
	switch (type) {
		case 'enable':
		case 'disable': {
			const preData = dataList.value.find(item => item.name === data.name);
			if (disable.value === 'all') {
				const newData = {...preData};
				if (newData) {
					newData.disable = type === 'enable' ? 0 : 1;
				}
				aggrid.value?.gridApi?.getRowNode(data.name)?.setData(newData);
			} else {
				aggrid.value?.gridApi?.applyTransaction({remove: [preData]});
			}
			break;
		}
		case 'delete': {
			const preData = dataList.value.find(item => item.name === data.name);
			aggrid.value?.gridApi?.applyTransaction({remove: [preData]});
			break;
		}
		case 'create': {
			const parent = dataList.value.find(item => item.name === data.work_process);
			const path = parent ? [...(parent.path || []), data.name].filter(Boolean) : [data.name];
			const newData = setData(data, path);
			aggrid.value?.gridApi?.applyTransaction({add: [newData]});
			break;
		}
		case 'update': {
			const parent = dataList.value.find(item => item.name === data.work_process);
			const path = parent ? [...(parent.path || []), data.name] : [data.name];
			const newData = setData(data, path);
			aggrid.value?.gridApi?.applyTransaction({update: [newData]});
			aggrid.value?.gridApi?.getRowNode(data.name)?.setData(newData);
			break;
		}
	}
}

// 展开或收起
function toggleExpand(state: boolean) {
	if (state) {
		aggrid.value?.gridApi?.expandAll();
	} else {
		aggrid.value?.gridApi?.collapseAll();
	}
}
// 获取标准工作清单
async function getStandardWorkList() {
	loading.value = true;
	const args: { [key: string]: string | number} = {
		process: process.value,
		scope: scope.value,
	};
	if (scope.value !== 'Global') {
		args.organization = organization.value;
	}
	if (disable.value !== 'all') {
		args.disable = disable.value === 'disable' ? 1 : 0;
	}
	const res = await frappe.call<{ message: Result }>({
		method: 'dw_worklist_management.dw_worklist_management.doctype.dw_standard_worklist.dw_standard_worklist.get_standard_worklist',
		args: args,
	});
	if (res?.message) {
		const {process_list, worklist_list} = res.message;
		const data = transformData(process_list, worklist_list);
		dataList.value = agGridTree<TableData>(data, 'parent');
		if (!processList.value.length) {
			processList.value = process_list.map(item => {
				item.process_name = item.title;
				return item;
			}) || [];
		}
	}
	loading.value = false;
}
// 获取组织列表
async function getOrganizationList() {
	const res:Organization[] = await frappe.db.get_list('Tianjy Organization', {
		fields: ['name', 'label'],
		limit: 0,
	});
	organizationMap.value = new Map<string, Organization>(res.map(item => [item.name, item]));
}
// 获取层级样式
async function getLevelStyleList() {
	levelStyle.value = await frappe.db.get_list('Dw Process Level Style', {
		fields: ['level', 'row_background_color', 'row_height', 'font_color', 'is_bold', 'icon'],
		limit: 0,
	});
}

function getSearchParams() {
	const params = new URLSearchParams(window.location.search);
	if (params.get('scope')) {
		scope.value = params.get('scope') as ('Global'|'Organization Unit Specific') || 'Global';
	}
	if (params.get('disable')) {
		disable.value = params.get('disable') as ('all' | 'enable' | 'disable') || 'enable';
	}
	if (params.get('organization')) {
		organization.value = params.get('organization') || frappe.tianjy.organization.getCurrent();
	}
	if (params.get('layout')) {
		layoutType.value = params.get('layout') as ('modal' | 'link' | 'bottom' | 'right') || 'right';
	}
}
getSearchParams();
$(frappe.pages.dw_standard_work_list).on('show', function() {
	getSearchParams();
});
// 更新URL路径
function updateURL() {
	const params = new URLSearchParams(window.location.search);
	params.set('layout', layoutType.value);
	params.set('disable', disable.value);
	params.set('scope', scope.value);
	params.set('organization', organization.value);
	history.replaceState(null, '', `?${params.toString()}`);
}

watch(
	[process, scope, disable],
	() => {
		updateURL();
		getStandardWorkList();
	},
	{immediate: true},
);
watch(layoutType, () => {
	updateURL();
});

const popstateListener = async function () {
	await getLevelStyleList();
	hasPermission.value = await getPermission();
	getOrganizationList();
	getStandardWorkList();
};
onMounted(async () => {
	await getLevelStyleList();
	hasPermission.value = await getPermission();
	getOrganizationList();
	getStandardWorkList();
	window.addEventListener('popstate', popstateListener);
});
onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});
</script>

<style lang="less" scoped>
  .title {
    height: 75px;
    line-height: 75px;
    margin-bottom: 0;
  }
  .table-container {
    height: 100%;
	overflow: hidden;
  }
</style>
