import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31dd1a61"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label" }
const _hoisted_2 = ["placeholder", "onKeydown"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "tip"
}
const _hoisted_5 = {
  key: 1,
  class: "tip"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString($setup.tt('Save Filter')), 1 /* TEXT */),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.name) = $event)),
      placeholder: $setup.tt('Filter Name'),
      class: "input",
      onKeydown: _withKeys($setup.save, ["enter"])
    }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_2), [
      [_vModelText, $setup.name]
    ]),
    ($setup.name)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (!$setup.creatable)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($setup.tt('Duplicate Filter Name')), 1 /* TEXT */))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString($setup.tt('Press Enter to save')), 1 /* TEXT */)),
          ($setup.can_add_global)
            ? (_openBlock(), _createBlock(_component_el_checkbox, {
                key: 2,
                modelValue: $setup.isGlobal,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.isGlobal) = $event)),
                class: "checkbox",
                label: `${$setup.tt('Is Global')}${$setup.tt('(Or Press Shift+Enter To Save)')}`
              }, null, 8 /* PROPS */, ["modelValue", "label"]))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.list, ({ name, filters, filter_name, for_user }) => {
      return (_openBlock(), _createBlock($setup["ElTag"], {
        key: name,
        class: "tag",
        closable: $setup.removable(for_user),
        onClick: $event => ($setup.set(filters)),
        onClose: $event => ($setup.remove(name))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(filter_name), 1 /* TEXT */)
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["closable", "onClick", "onClose"]))
    }), 128 /* KEYED_FRAGMENT */))
  ], 64 /* STABLE_FRAGMENT */))
}