<template>
  <Page>
    <template v-slot:title>
      <h3 class="title">{{ tt('Comprehensive Monthly Report') }}</h3>
    </template>
    <template v-slot:tools>
      <Tools v-model:date="selectedDate"></Tools>
    </template>
    <ProjectOverview :selected-date="selectedDate"></ProjectOverview>
    <BusinessIndicatorsOverview :selected-date="selectedDate"></BusinessIndicatorsOverview>
    <ProfitAndLossSituation :selected-date="selectedDate"></ProfitAndLossSituation>
    <ProgressManagement :selected-date="selectedDate"></ProgressManagement>
    <TechnicalManagement :selected-date="selectedDate"></TechnicalManagement>
  </Page>
</template>
<script lang="ts" setup>
  import { ref, onMounted, watch } from 'vue';
  import moment from 'moment';

  import Page from '../../../../../../../guigu_pm/guigu_pm/public/js/components/page/index.vue';

  import Tools from './Tools.vue';
  import ProjectOverview from './ProjectOverview.vue';
  import BusinessIndicatorsOverview from './BusinessIndicatorsOverview.vue';
  import ProfitAndLossSituation from './ProfitAndLossSituation.vue';
  import ProgressManagement from './ProgressManagement.vue';
  import TechnicalManagement from './TechnicalManagement.vue';
  const selectedDate = ref<string>(moment().format('YYYY-MM'));

  const tt = __;
  // const loading = ref<boolean>(false);
  // const doctypeLoaded = ref<boolean>(false);
</script>
<style scoped>
  .title {
    margin-bottom: 0;
  }
</style>
