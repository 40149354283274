import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AgGridVue = _resolveComponent("AgGridVue")

  return (_openBlock(), _createBlock(_component_AgGridVue, {
    class: "ag-theme-guigu diary_list-table",
    columnDefs: _ctx.columnDefs,
    rowData: _ctx.diaryStructure,
    defaultColDef: _ctx.defaultColDef,
    rowSelection: "multiple",
    animateRows: "true",
    onGridReady: _ctx.onGridReady,
    treeData: true,
    groupDefaultExpanded: "-1",
    getDataPath: _ctx.getDataPath,
    autoGroupColumnDef: _ctx.autoGroupColumn,
    suppressDragLeaveHidesColumns: _ctx.smallMeta,
    localeText: _ctx.zhCN
  }, null, 8 /* PROPS */, ["columnDefs", "rowData", "defaultColDef", "onGridReady", "getDataPath", "autoGroupColumnDef", "suppressDragLeaveHidesColumns", "localeText"]))
}