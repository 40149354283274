import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a239fb3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-container" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "indicator-pill no-indicator-dot whitespace-nowrap orange" }
const _hoisted_4 = { class: "dialog-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Page"], null, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString($setup.title), 1 /* TEXT */),
        _withDirectives(_createElementVNode("span", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString($setup.tt('Not Saved')), 1 /* TEXT */)
        ], 512 /* NEED_PATCH */), [
          [_vShow, $setup.store.isChange]
        ])
      ])
    ]),
    action: _withCtx(() => [
      _createVNode($setup["ElSpace"], null, {
        default: _withCtx(() => [
          _createVNode($setup["ElButton"], { onClick: $setup.navigateBack }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('Navigate Back')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode($setup["ElDropdown"], { onCommand: $setup.handleCommand }, {
            dropdown: _withCtx(() => [
              _createVNode($setup["ElDropdownMenu"], null, {
                default: _withCtx(() => [
                  _createVNode($setup["ElDropdownItem"], { command: "loadTemplate" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Load Instruction Template')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode($setup["ElDropdownItem"], { command: "openVersion" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Open Other Version')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode($setup["ElDropdownItem"], { command: "saveVersion" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Save New Version')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            default: _withCtx(() => [
              _createVNode($setup["ElButton"], { icon: $setup.MoreFilled }, null, 8 /* PROPS */, ["icon"])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode($setup["ElButton"], {
            type: "primary",
            onClick: $setup.save
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('Save')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _createVNode($setup["Instruction"], {
        referenceType: $setup.referenceType,
        referenceName: $setup.referenceName,
        instructionName: $setup.instructionName,
        editable: $setup.editable,
        mode: 'edit'
      }, null, 8 /* PROPS */, ["referenceType", "referenceName", "instructionName", "editable"]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode($setup["SaveDialog"], {
          visible: $setup.visible,
          "onUpdate:visible": _cache[0] || (_cache[0] = $event => (($setup.visible) = $event))
        }, null, 8 /* PROPS */, ["visible"]),
        _createVNode($setup["VersionDialog"], {
          visible: $setup.versionVisible,
          "onUpdate:visible": _cache[1] || (_cache[1] = $event => (($setup.versionVisible) = $event))
        }, null, 8 /* PROPS */, ["visible"]),
        _createVNode($setup["TemplateDialog"], {
          visible: $setup.templateVisible,
          "onUpdate:visible": _cache[2] || (_cache[2] = $event => (($setup.templateVisible) = $event))
        }, null, 8 /* PROPS */, ["visible"])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}