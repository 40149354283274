<template>
	<Page>
		<template #title>
			<h3 class="title">{{ tt('Log Page') }}</h3>
		</template>
		<template #tools>
			<Tools
				v-if="doctypeLoaded"
				v-model:organization="filterOrganization"
				v-model:searchString="searchString"
				v-model:date="selectedDate"
				v-model:showComment="showComment"
				@search="search"
				@clearSearch="clearSearch"
			></Tools>
		</template>
		<template #sider>
			<OrganizationTree
				:subOrganizations="subOrganizations"
				:organizationLoading="loading"
				@organizationChange="organizationChange"
			>
			</OrganizationTree>
		</template>
		<DiaryTimeline
			v-if="doctypeLoaded"
			:organization="organization"
			:subOrganizations="subOrganizations"
			:filterOrganization="filterOrganization"
			:searchString="searchString"
			:date="selectedDate"
			:showComment="showComment"
			@show-daily-record="v => (detailName = v)"
			ref="diaryTimelineRef"
		></DiaryTimeline>
	</Page>
	<Detail
		v-if="detailName"
		@hide="detailName = ''"
		doctype="Dw Daily Record"
		:name="detailName"
	></Detail>
</template>
<script lang="ts" setup>
import {ref, onMounted, watch} from 'vue';
import moment from 'moment';

import Page from '../../../../../../../guigu_pm/guigu_pm/public/js/components/page/index.vue';
import * as store from '../../../../../../../tianjy_organization/tianjy_organization/public/js/store';

import Detail from './Detail.vue';
import OrganizationTree from './OrganizationTree.vue';
import DiaryTimeline from './DiaryTimeline.vue';
import Tools from './Tools.vue';
import type {SubOrganization} from './type';


const tt = __;
const showComment = ref<boolean>(false);
const filterOrganization = ref<string>(store.getCurrent());
const selectedDate = ref<string>(moment().format('YYYY-MM-DD'));
const loading = ref<boolean>(false);
const subOrganizations = ref<SubOrganization[]>([]);
const organization = ref<SubOrganization>();
const doctypeLoaded = ref<boolean>(false);
const searchString = ref<string>('');
const diaryTimelineRef = ref<{
	searchDiary:(searchModeProps: boolean) => void;
}>();
const detailName = ref<string>('');

function search() {
	diaryTimelineRef.value?.searchDiary(Boolean(searchString.value));
}
function clearSearch() {
	searchString.value = '';
	diaryTimelineRef.value?.searchDiary(false);
}
async function getSubOrganizations() {
	loading.value = true;
	subOrganizations.value = [];
	const data = await frappe.call<{ message: SubOrganization[] }>({
		method: 'dw_worklist_management.dw_daily_record.page.dw_organization_daily_record.dw_organization_daily_record.get_department_members',
		args: {organization_name: filterOrganization.value},
	});
	const org = data?.message || [];
	const isInOrganization = org.some(item => item.user?.name === frappe.session.user);
	if (isInOrganization) {
		org.unshift({
			id: 'my',
			title: __('My'),
			type: 'member',
			user: {
				name: frappe.session.user,
				full_name: frappe.session.user_fullname,
			},
		});
	}
	org.unshift({
		id: 'all',
		title: __('All'),
		type: 'organization',
	});
	subOrganizations.value = org;
	loading.value = false;
}
watch(filterOrganization, () => {
	getSubOrganizations();
}, {immediate: true});

function organizationChange(newOrganizationORUser: SubOrganization) {
	if (newOrganizationORUser.type === 'department') {
		// 当点击部门时，获取该部门的所有成员
		const departmentMembers = subOrganizations.value.filter(item =>
			item.type === 'member' && item.parent === newOrganizationORUser.id);
		// 设置组织为部门下的所有成员
		organization.value = {
			id: newOrganizationORUser.id,
			title: newOrganizationORUser.title,
			type: 'department',
			members: departmentMembers.map(member => member.user?.name).filter(Boolean) || [],
		};
	} else {
		organization.value = newOrganizationORUser;
	}
}
onMounted(async () => {
	doctypeLoaded.value = false;
	await frappe.model.with_doctype('Dw Daily Record');
	doctypeLoaded.value = true;
});
</script>
<style scoped>
.title {
	margin-bottom: 0;
}
</style>
