import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.inline)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        title: $setup.inlineTitle
      }, _toDisplayString($setup.inlineTitle), 9 /* TEXT, PROPS */, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        ($setup.userName)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              style: _normalizeStyle($setup.titleStyle),
              title: $setup.userTitle
            }, _toDisplayString($setup.userName) + "(" + _toDisplayString($setup.time) + ")： ", 13 /* TEXT, STYLE, PROPS */, _hoisted_3))
          : _createCommentVNode("v-if", true),
        _createElementVNode("span", {
          style: _normalizeStyle($setup.valueStyle),
          title: $setup.valueTitle
        }, _toDisplayString($setup.value), 13 /* TEXT, STYLE, PROPS */, _hoisted_4)
      ]))
}