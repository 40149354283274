<template>
	<span :style="{display:'flex'}" :title="title">
		<a v-if="href" :style="urlStyle" target="_blank"
			:href="href">
			{{ label }}
		</a>
		<a v-if="href" :href="href" :download="download">
			<ElIcon>
				<Download />
			</ElIcon>
		</a>
	</span>
</template>
<script lang="ts" setup>
import {computed} from 'vue';
import {Download} from '@element-plus/icons-vue';
import {ElIcon} from 'element-plus';
interface Params {
	data: any
	value:string,
}
const props = defineProps<{ params: Params }>();
const title = computed(() => props.params.value);
const href = computed(() => encodeURI(props.params.value || '')?.replace(/#/g, '%23'));

const label = computed(() => {
	const name = props.params.value;
	if (!name) {
		return name;
	}
	const filenameArr = name.split('/');
	let filename = filenameArr.pop();
	const isPrivate = filenameArr.some(item=>item === 'private');
	filename = `[${isPrivate ? __('Private') : __('Public')}]${filename}`;
	return filename;
});
const download = computed(() => (props.params.value || '').split('/').pop() || '');
const urlStyle = {
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
};
</script>

<style scoped>
.url{
	display: flex
}
</style>
