import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","align-items":"center","justify-content":"space-between"} }
const _hoisted_2 = { style: {"display":"flex","align-items":"center"} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", { style: $setup.titleContainerStyle }, [
      _createElementVNode("span", null, [
        _createVNode($setup["Icon"], { icon: $setup.icon }, null, 8 /* PROPS */, ["icon"])
      ]),
      _createElementVNode("span", {
        style: $setup.titleStyle,
        onClick: $setup.clickLabel
      }, _toDisplayString($setup.fullName), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_2, [
      ($setup.isCore)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            style: $setup.circleStyle
          }))
        : _createCommentVNode("v-if", true),
      ($setup.isShow)
        ? (_openBlock(), _createBlock($setup["ElDropdown"], {
            key: 1,
            trigger: "click",
            onCommand: $setup.command
          }, {
            dropdown: _withCtx(() => [
              _createVNode($setup["ElDropdownMenu"], null, {
                default: _withCtx(() => [
                  _createVNode($setup["ElDropdownItem"], {
                    command: "del",
                    style: {"color":"red"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Delete')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            default: _withCtx(() => [
              _createVNode($setup["ElIcon"], { style: {"cursor":"pointer"} }, {
                default: _withCtx(() => [
                  _createVNode($setup["MoreFilled"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}