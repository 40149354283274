import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4bd6a64a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["ProjectCom"], {
      class: "project-select",
      default: "",
      noLabel: "",
      modelValue: $setup.projectValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.projectValue) = $event))
    }, null, 8 /* PROPS */, ["modelValue"]),
    ($props.project)
      ? (_openBlock(), _createBlock($setup["PeriodPicker"], {
          key: 0,
          class: "input",
          calendarType: "Construction Calendar",
          project: $props.project,
          periodType: `Year ${$props.type}`,
          defaultValue: "",
          modelValue: $props.period,
          "onUpdate:modelValue": $setup.updatePeriod
        }, null, 8 /* PROPS */, ["project", "periodType", "modelValue"]))
      : _createCommentVNode("v-if", true)
  ]))
}