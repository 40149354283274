<template>
	<el-form
		labelPosition="top"
		class="detail-form"
		:model="form"
		labelWidth="100px"
	>
		<el-row :gutter="10">
			<el-col :span="12">
				<el-form-item label="">
					<div v-show="form.leaderConfirmDate" class="confirm_div">
						<div>
							{{ tt('Assessor (Signature)') }}:{{
								assessInfo?.leader_doc?.full_name
							}}
							{{ tt('Confirmed ({})', [form.leaderConfirmDate]) }}
						</div>
					</div>
					<el-checkbox
						v-show="!form.leaderConfirmDate"
						:label="tt('Assessment Person Sign')"
						v-model="isLeaderConfirmed"
						@change="leaderToConfirm"
						:disabled="leader_confirm_disabled"
					/>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item label="">
					<div v-show="form.userConfirmDate" class="confirm_div">
						<div>
							{{ tt('Assessed Person (Signature)') }}:
							{{ props.assessInfo?.user_doc.full_name }}
							{{ tt('Confirmed ({})', [form.userConfirmDate]) }}
						</div>
					</div>
					<el-checkbox
						v-show="!form.userConfirmDate"
						:label="tt('Assessed Person')"
						v-model="isUserConfirmed"
						@change="userToConfirm"
						:disabled="user_confirm_disabled"
					/>
				</el-form-item>
			</el-col>
		</el-row>
	</el-form>
</template>

<script setup lang="ts">
import { computed, watch, ref, reactive, shallowRef } from 'vue';

import type * as Permission from '../../Permission';
import type { AssessDetail, AssessInfo, ExtraScore } from '../type';

const tt = __;

interface Props {
	type: 'project' | 'subCompany';
	assessInfo?: AssessInfo;
	assessUserPermission: Permission.UserPermission;
	lockStatus: boolean;
}
const props = defineProps<Props>();
interface Emit {
	(e: 'update'): void;
	(e: 'remove', data: ExtraScore | AssessDetail): void;
}

const user_confirm_disabled = computed((field: string) => {
	if (props.lockStatus) {
		return true;
	}
	const is_current_user =
		props.assessInfo?.user_doc?.name === frappe.user.name;
	if (props.assessInfo?.assess_name) {
		return !(
			is_current_user &&
			props.assessUserPermission?.writable.has('user_confirm_date')
		);
	}
	return !(is_current_user && props.assessUserPermission?.creatable);
});

const leader_confirm_disabled = computed((field: string) => {
	if (props.lockStatus) {
		return true;
	}
	const is_current_user =
		props.assessInfo?.select_leader_doc?.name === frappe.user.name;
	if (props.assessInfo?.assess_name) {
		return !(
			is_current_user &&
			props.assessUserPermission?.writable.has('leader_confirm_date')
		);
	}
	return !(is_current_user && props.assessUserPermission?.creatable);
});

const emit = defineEmits<Emit>();
const tempAssessList = ref<
	(AssessDetail & { index: number; type_label: string })[]
>([]);
const linkAssessList = ref<
	(AssessDetail & { index: number; type_label: string })[]
>([]);
const form = reactive({
	userConfirmDate: '',
	leaderConfirmDate: '',
});

const isUserConfirmed = ref<boolean>(Boolean(form.userConfirmDate));
const isLeaderConfirmed = ref<boolean>(Boolean(form.leaderConfirmDate));

watch(
	() => form.userConfirmDate,
	newVal => {
		isUserConfirmed.value = Boolean(newVal);
	},
);

watch(
	() => form.leaderConfirmDate,
	newVal => {
		isLeaderConfirmed.value = Boolean(newVal);
	},
);

watch(
	() => props.assessInfo,
	() => {
		const {
			detail = [],
			temp = [],
			leader_confirm_date,
			user_confirm_date,
		} = props.assessInfo || {};
		linkAssessList.value =
			detail
				.filter(item => item.doc_name && item.link_doctype)
				.sort((pre, next) => (pre.subject > next.subject ? 1 : -1))
				.map((item, index) => ({
					...item,
					index: index + 1,
					type_label: __('Plan'),
				})) || [];
		const tempAssess =
			temp
				.sort((pre, next) => (pre.subject > next.subject ? 1 : -1))
				.map((item, index) => ({
					...item,
					index: index + 1,
					type_label: __('adjust'),
				})) || [];
		tempAssessList.value = tempAssess;
		form.userConfirmDate = user_confirm_date;
		form.leaderConfirmDate = leader_confirm_date;
	},
	{ immediate: true },
);

async function userToConfirm() {
	if (!props.assessInfo) {
		return;
	}
	let url =
		'guigu_hr.guigu_hr_assessment.page.guigu_hr_assessment_detail.guigu_hr_assessment_detail.update_assess';
	const args: any = {
		name: props.assessInfo?.assess_name,
		field: 'user_confirm_date',
		value: moment().format('YYYY-MM-DD'),
		type: props.type,
	};
	if (!props.assessInfo.assess_name) {
		url =
			'guigu_hr.guigu_hr_assessment.page.guigu_hr_assessment_detail.guigu_hr_assessment_detail.create_assess';
		args.date = props.assessInfo.date;
		args.user = props.assessInfo.user_doc.name;
		args.type = props.type;
		args.department = props.assessInfo.department_doc.name;
	}
	await frappe.call({
		method: url,
		args,
		callback: response => handleConfirmCallback('user_confirm_date'),
	});
	emit('update');
}
async function leaderToConfirm(value: string | number | boolean) {
	if (!props.assessInfo) {
		return;
	}
	await frappe.call({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_assessment_detail.guigu_hr_assessment_detail.leader_confirm',
		args: {
			name: props.assessInfo?.assess_name,
			date: props.assessInfo.date,
			user: props.assessInfo.user_doc.name,
			type: props.type,
			assessment_department: props.assessInfo.department_doc.name,
		},
		callback: response => handleConfirmCallback('leader_confirm_date'),
	});
	emit('update');
}
function handleConfirmCallback(field) {
	const alertDiv = document.createElement('div');
	alertDiv.innerHTML = `
		<div style="display: flex; align-items: center; justify-content: space-between;">
			<span>${__('Signed confirmation completed')}</span>
			<button
          id='cancel-signature-btn'
          style="background: none; border: none; color: #aaa; font-weight: bold; cursor: pointer; margin-left: 10px;"
          onmouseover="this.style.color='#1C86EE'"
          onmouseout="this.style.color='#aaa'">${__('Revoke Signature')}
			</button>
		</div>
	`;

	frappe.show_alert(
		{
			message: alertDiv.innerHTML,
			indicator: 'green',
		},
		60,
	);

	document
		.querySelector('#cancel-signature-btn')
		.addEventListener('click', async () => {
			await frappe.call({
				method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_assessment_detail.guigu_hr_assessment_detail.cancel_signature',
				args: {
					name: props.assessInfo?.assess_name,
					type: props.type,
					field,
				},
			});
			// 通过明确设置签字状态
			if (field === 'user_confirm_date') {
				form.userConfirmDate = '';
				isUserConfirmed.value = false;
			} else if (field === 'leader_confirm_date') {
				form.leaderConfirmDate = '';
				isLeaderConfirmed.value = false;
			}
			emit('update');
			frappe.show_alert(
				{
					message: __('Revoked signature confirmation'),
					indicator: 'blue',
				},
				10,
			);
		});
}
</script>

<style lang="less" scoped>
.table-container {
	overflow: hidden;
}
.extra-btn_container {
	display: flex;
	justify-content: end;
	padding: 8px 0;
}
.detail-form {
	margin-top: 8px;
}
.confirm_div {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.description {
	font-size: 12px;
}
</style>
