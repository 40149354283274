<template>
	<el-dropdown
		v-if="permission.write || permission.export || permission.delete"
		@command="clickMenu" @click.stop="" class="dropdown">
		<span>
			<el-icon>
				<MoreFilled />
			</el-icon>
		</span>
		<template #dropdown>
			<el-dropdown-menu class="guigu-file-view_dropdown">
				<el-dropdown-item v-if="permission.write"
					command="edit">编辑</el-dropdown-item>
				<el-dropdown-item
					v-if="fileIcon !== 'folder' && permission.export"
					command="download">下载</el-dropdown-item>
				<el-dropdown-item class="delete"
					v-if="permission.delete"
					command="delete">删除</el-dropdown-item>
			</el-dropdown-menu>
		</template>
	</el-dropdown>
	<DeleteFolderDialog v-loading="deleteLoading" :isFolderField="isFolderField"
		:folderNameField="folderNameField" :parentField="parentField"
		:meta="meta"
		:visible="visible" :folder="deleteFolder"
		@deleteFolder="deleteFolderFn"></DeleteFolderDialog>
</template>

<script setup lang='ts'>
import { ref, computed } from 'vue';

import { MoreFilled } from '@element-plus/icons-vue';
import { useStorage } from '@vueuse/core';
import { ElMessage, ElMessageBox } from 'element-plus';

import DeleteFolderDialog from './DeleteFolderDialog.vue';
import { useFileViewStore } from './stores/fileView';

const props = defineProps<{ value: any, meta: locals.DocType, configuration: any }>();
const deleteFileStorage = useStorage('deleteFile', '');
const refreshStorage = useStorage('refresh', '');
const fileViewStore = useFileViewStore();

const visible = ref<boolean>(false);
const deleteFolder = ref<any>();
const deleteLoading = ref<boolean>(false);

const permission = computed(() => {
	const writePermission = frappe.perm.has_perm(props.meta.name, 0, 'write');
	const deletePermission = frappe.perm.has_perm(props.meta.name, 0, 'delete');
	const exportPermission = frappe.perm.has_perm(props.meta.name, 0, 'export');

	return { write: writePermission, delete: deletePermission, export: exportPermission };
});

const {
	isFolderField, fileNameField, folderNameField, parentField,
} = computed(() => {
	const { isFolderField, fileNameField, folderNameField, parentField } = props.configuration || {};
	return { isFolderField, fileNameField, folderNameField, parentField };
}).value;

const fileIcon = computed(() => {
	if (props.value[isFolderField] === 1) {
		return 'folder';
	}
	return 'file';
});

const title = computed(() => props.value[isFolderField] === 1
	? props.value[folderNameField]
	: props.value[fileNameField]
);
const fileField = computed(() => {
	const field = props.meta.fields.find(item => item.fieldtype === 'Attach' || item.fieldtype === 'Attach Image');
	return field?.fieldname || '';
});
function clickMenu(command: string) {
	if (command === 'edit') {
		edit();
	}
	if (command === 'delete') {
		deleteFile();
	}
	if (command === 'download') {
		download();
	}
}
function download() {
	const url: string = encodeURI(props.value[fileField.value] || '').replace(/#/g, '%23');
	const a = document.createElement('a');
	a.href = url;
	a.download = title.value;
	a.target = '_blank';
	a.click();
}

function deleteFile() {
	if (props.value[isFolderField] === 1) {
		visible.value = true;
		deleteFolder.value = props.value;
	} else {
		ElMessageBox.confirm(
			'您确认删除此文件吗?',
			'请确认',
			{
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}
		).then(async () => {
			deleteLoading.value = true;
			const timeStamp = (new Date).getTime();
			deleteFileStorage.value = `${timeStamp}_${props.value.name}_true`;
			frappe.call({
				method: 'frappe.client.delete',
				args: {
					doctype: props.meta.name,
					name: props.value.name,
				},
				error() {
					deleteLoading.value = false;
					deleteFileStorage.value = `${timeStamp}_${props.value.name}_false_error`;
				},
				callback() {
					deleteFileStorage.value = `${timeStamp}_${props.value.name}_false_success`;
					deleteLoading.value = false;
				},
			});
		}).catch(() => {
			deleteLoading.value = false;
			const timeStamp = (new Date).getTime();
			deleteFileStorage.value = `${timeStamp}_${props.value.name}_false_error`;
			ElMessage({
				type: 'info',
				message: '取消删除',
			});
		});
	}
}
async function deleteFolderFn(deleteOption: { action: string, parent?: string }) {
	visible.value = false;
	deleteLoading.value = true;
	const timeStamp = (new Date).getTime();
	deleteFileStorage.value = `${timeStamp}_${deleteFolder.value.name}_true`;
	const { action, parent } = deleteOption;
	const data = await frappe.call<{ message: any }>({
		method: 'guigu.fileview.fileview.delete_folder',
		args: { doctype: props.meta.name, parent_field: parentField, name: deleteFolder.value?.name, action, parent },
		error() {
			deleteFileStorage.value = `${timeStamp}_${deleteFolder.value.name}_false_error`;
			deleteLoading.value = false;
		},
	});
	fileViewStore.setUpdatedFolder(`${timeStamp}_${deleteFolder.value.name || ''}`);
	deleteFileStorage.value = `${timeStamp}_${deleteFolder.value.name}_false_success`;
	deleteLoading.value = false;
}
function edit() {
	// const {
	// 	detail_type,
	// } = frappe.views.file[props.meta.name]||{};
	const detail_type = 'blank';
	if (detail_type === 'blank') {
		frappe.set_route(['form', props.meta.name, props.value.name]);
		return;
	}
	const dialog = new frappe.ui.Dialog({
		title: __(props.meta.name),
		fields: props.meta.fields,
		async primary_action() {
			const values = this.get_values();
			delete values.name;
			const data = await frappe.call<{ message: any }>({
				method: 'guigu.fileview.fileview.update_data',
				args: { doctype: props.meta.name, name: props.value.name, values },
			});
			const timeStamp = (new Date).getTime();
			refreshStorage.value = timeStamp.toString();
			if (values[isFolderField] === 1) {
				const timeStamp = (new Date).getTime();
				fileViewStore.setUpdatedFolder(`${timeStamp}_${data?.message?.name || ''}`);
			}
			this.hide();
		},
		// primary_action_label: __("Update Order"),
	});
	dialog.set_values(props.value);
	dialog.show();
}
</script>
<script lang="ts">
export default { inheritAttrs: false };
</script>

<style lang='less' scoped>
.guigu-file-view_dropdown {
	:deep(.delete) {
		color: #f00 !important;
	}
}
</style>
