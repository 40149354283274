import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f286d1fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "show",
  ref: "root"
}
const _hoisted_2 = { class: "inner" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["data-value"]
const _hoisted_5 = ["data-id"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass($setup.colGroups.length ? $setup.rowGroups.length ? 'root' : 'col' : 'row'),
      ref: "el"
    }, [
      _createVNode($setup["Headers"], {
        groups: $setup.colGroups,
        width: $setup.colWidths[1],
        onSetting: $setup.edit,
        onAdd: $setup.add,
        widths: $setup.colWidths[0],
        start: $setup.rowGroups.length,
        data: $props.data
      }, null, 8 /* PROPS */, ["groups", "width", "widths", "start", "data"]),
      _createVNode($setup["Headers"], {
        groups: $setup.rowGroups,
        width: $setup.rowWidths[1],
        cc: !!$setup.colGroups.length,
        onSetting: $setup.edit,
        onAdd: $setup.add,
        data: $props.data,
        widths: $setup.rowWidths[0],
        start: $setup.colGroups.length
      }, null, 8 /* PROPS */, ["groups", "width", "cc", "data", "widths", "start"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.params, (p) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("button", {
            onClick: $event => ($setup.create(p)),
            class: "add"
          }, "+", 8 /* PROPS */, _hoisted_3),
          _createElementVNode("ul", {
            ref_for: true,
            ref: "list",
            "data-value": $setup.jsonStringify(p),
            class: "list"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getList(p), (it) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "item",
                "data-id": it.name,
                key: it.name
              }, [
                _renderSlot(_ctx.$slots, "default", { value: it }, () => [
                  _createTextVNode(_toDisplayString(it.name), 1 /* TEXT */)
                ], true)
              ], 8 /* PROPS */, _hoisted_5))
            }), 128 /* KEYED_FRAGMENT */))
          ], 8 /* PROPS */, _hoisted_4)
        ]))
      }), 256 /* UNKEYED_FRAGMENT */))
    ], 2 /* CLASS */)
  ], 512 /* NEED_PATCH */))
}