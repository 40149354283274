import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0333c0ab"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = {
  ref: "doctypeTablesRef",
  class: "table-container"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["Page"], null, {
      title: _withCtx(() => [
        _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.title), 1 /* TEXT */)
      ]),
      tools: _withCtx(() => [
        _createVNode($setup["Tools"], {
          smallMeta: $setup.smallMeta,
          month: $setup.month,
          "onUpdate:month": _cache[0] || (_cache[0] = $event => (($setup.month) = $event)),
          detail: $setup.setting.detail,
          "onUpdate:detail": _cache[1] || (_cache[1] = $event => (($setup.setting.detail) = $event)),
          onRefresh: $setup.getPageSetting,
          onFullScreen: $setup.toggleFullscreen
        }, null, 8 /* PROPS */, ["smallMeta", "month", "detail", "onFullScreen"])
      ]),
      sider: _withCtx(() => [
        _withDirectives(_createVNode($setup["Users"], {
          users: $setup.setting.users,
          user: $setup.user,
          onSelectUser: $setup.selectUser
        }, null, 8 /* PROPS */, ["users", "user"]), [
          [_directive_loading, $setup.userLoading]
        ])
      ]),
      default: _withCtx(() => [
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!$setup.loading)
            ? (_openBlock(), _createBlock($setup["DoctypeRender"], {
                key: 0,
                doctypes: $setup.setting?.doctypes||[],
                useDetail: $setup.setting?.detail,
                doctypeList: $setup.doctypeList,
                smallMeta: $setup.smallMeta,
                user: $setup.user,
                date: $setup.month,
                onShowDetail: $setup.showDetail
              }, null, 8 /* PROPS */, ["doctypes", "useDetail", "doctypeList", "smallMeta", "user", "date"]))
            : _createCommentVNode("v-if", true)
        ])), [
          [_directive_loading, $setup.loading]
        ])
      ]),
      _: 1 /* STABLE */
    }),
    ($setup.detailName)
      ? (_openBlock(), _createBlock($setup["Detail"], {
          key: 0,
          onHide: _cache[2] || (_cache[2] = $event => ($setup.detailName='')),
          doctype: $setup.detailDoctype,
          name: $setup.detailName
        }, null, 8 /* PROPS */, ["doctype", "name"]))
      : _createCommentVNode("v-if", true)
  ]))
}