import NewWidget from './NewWidget';

// const setup_new_widget = frappe.widget.WidgetGroup.prototype.setup_new_widget
frappe.widget.WidgetGroup.prototype.setup_new_widget = function setup_new_widget() {
	const max = this.options
		? this.options.max_widget_count || Number.POSITIVE_INFINITY
		: Number.POSITIVE_INFINITY;

	if (this.widgets_list.length < max) {
		this.new_widget = new NewWidget({
			container: this.body,
			type: this.type,
			custom_dialog: this.custom_dialog,
			default_values: this.default_values,
			on_create: (config) => {
				// Remove new widget
				this.new_widget.delete();
				delete this.new_widget;

				config.in_customize_mode = 1;

				// Add new widget and customize it
				let wid = this.add_widget(config);
				wid.customize(this.options);

				// Put back the new widget if required
				if (this.widgets_list.length < max) {
					this.setup_new_widget();
				}
			},
		});
	}
}
