import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8fe68891"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass($props.enableLink ? null : 'disabled'),
    href: $setup.href,
    title: $setup.title,
    onClick: $setup.click
  }, _toDisplayString($setup.value), 11 /* TEXT, CLASS, PROPS */, _hoisted_1))
}