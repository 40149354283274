<template>
	<div>{{ translate(params.value) }}</div>
</template>

<script setup lang='ts'>
import { computed, watch } from 'vue';
const translate = window.__;

const props = defineProps<{ params: any }>();
</script>

<style lang='less' scoped>
.link {
	color: #409eff;
}
</style>
