import { views } from '../init/defineView';
import switchView from '../lib/switchView';

const oldSetupViews = frappe.views.ListViewSelect.prototype.setup_views;
frappe.views.ListViewSelect.prototype.setup_views = function setup_views(this: frappe.views.ListViewSelect) {
	oldSetupViews.call(this);
	const currentView = this.current_view
	const s: frappe.guigu.ViewContext = {
			currentView: currentView,
			doctype: this.doctype,
			settings: this.list_view.settings,
			meta: this.list_view.meta,
			switchView: switchView.bind(null, this.doctype),
	}
	for (const [name, t] of Object.entries(views)) {
		if (currentView === name) { continue; }
		const options = t ? t(s) : {};
		if (!options) { continue; }
		this.add_view_to_menu(frappe.utils.to_title_case(name), options.action || (() => {
			this.set_route(name)
		}));
	}
}
