import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"flex":"auto"} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElDrawer"], {
    class: "bbb",
    modelValue: $setup.visibleValue,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.visibleValue) = $event)),
    direction: "rtl",
    title: "请输入描述",
    modalClass: "aaa",
    customClass: "bbb"
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["ElButton"], { onClick: $setup.cancelClick }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElButton"], {
          type: "primary",
          onClick: $setup.confirmClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Confirm')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode($setup["ElInput"], {
          modelValue: $setup.textValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.textValue) = $event)),
          type: "textarea"
        }, null, 8 /* PROPS */, ["modelValue"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}