import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a577b2b7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "selectedText"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.text)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($setup.text), 1 /* TEXT */))
    : _createCommentVNode("v-if", true)
}