<script lang="ts" setup>
import { ref, shallowRef, watch, h } from 'vue';

import {
	ElInput, ElButton,
	ElForm, ElFormItem,
	ElSelect, ElOption, ElTable, ElTableColumn, ElMessageBox,
} from 'element-plus';
import { Sort } from '@element-plus/icons-vue';

import ViewLinkConfig from '../ViewLink/Config.vue';
import DoctypeLinkConfig from '../DocTypeLink/Config.vue';

import UrlConfig from './UrlConfig.vue';
import ListConfig from './ListConfig.vue';
import IconPicker from './Icon.vue';
import type { TableData } from './type';
import {types} from './type';

const tt = __;
const props = defineProps<{
	value?: {tableData:TableData[], label:string};
}>();
const emit = defineEmits<{
	(event: 'update', data: any, submittable?: boolean): void;
	(event: 'cancel' | 'submit'): void;
}>();

const label = ref<string>(props.value?.label || '');
const description = ref<string>(props.value?.description||'')
const tableData = ref<TableData[]>((props.value?.tableData||[]).map(item=>{
	let label='';
	let link_to = '';
	switch (item.type){
		case 'View Link': label= item.viewLinkConfig?.label||''; link_to = item.viewLinkConfig?.doctype||''; break;
		case 'DocType Link': label= item.doctypeLinkConfig?.label||''; link_to = item.doctypeLinkConfig?.doctype||''; break;
		case 'List': label = item.listLinkConfig?.label||''; link_to = item.listLinkConfig?.doctype||''; break;
		case 'URL': label = item.urlConfig?.label||''; link_to = ''; break;

	}
	return {
		...item,
		label,
		link_to,
	};
}));
const expandRowKeys = ref<string[]>([]);
const selectedData = ref<any[]>([]);

watch([label, description, tableData], ([label, description, tableData]) => {
	const submittable = tableData.every(item=>{
		switch (item.type){
			case 'View Link': return Boolean(item.viewLinkConfig?.doctype);
			case 'DocType Link': return Boolean(item.doctypeLinkConfig?.doctype);
			case 'List': return Boolean(item.listLinkConfig?.doctype);
			case 'URL': return Boolean(item.urlConfig?.url);
		}
	});
	if (!submittable){ return; }
	emit('update', { label, description, tableData, }, submittable);
}, { immediate: true, deep: true });

function addRow(){
	tableData.value.push({name:frappe.utils.get_random(20), label:'', type:'View Link'});
}

function expandChange(row:any, expandedRows:any){
	expandRowKeys.value = expandedRows.length===0?[]:[row.name];
}

function deleteRow(row:any){
	const selectedNames = selectedData.value.map(s=>s.name);
	tableData.value = tableData.value.filter(t=>!selectedNames.includes(t.name));
}

function selectionChange(selection:any[]){
	selectedData.value = selection;
}

function updateViewLinkConfig(row:any, data: any, submittable?: boolean){
	row.viewLinkConfig = data;
	row.viewLinkConfig.submittable=submittable;
	row.label=data.label;
	row.lin_to = data.doctype;

}
function updateDoctypeLinkConfig(row:any, data: any, submittable?: boolean){
	row.doctypeLinkConfig = data;
	row.doctypeLinkConfig.submittable=submittable;
	row.label=data.label;
	row.lin_to = data.doctype;
}
function updateListLinkConfig(row:any, data: any, submittable?: boolean){
	row.listLinkConfig = data;
	row.listLinkConfig.submittable=submittable;
	row.label=data.label;
	row.lin_to = data.doctype;
}
function updateUrlConfig(row:any, data: any, submittable?: boolean){
	row.urlConfig = data;
	row.urlConfig.submittable=submittable;
	row.label=data.label;
	row.url = data.url;
}
function move(from: number, to: number) {
	if (from === to) { return; }
	const list = [...tableData.value];
	const item: any = list[from];
	if (!item) { return; }
	list.splice(from, 1);
	list.splice(to, 0, item);
	tableData.value = list;
}

function moveTo(index: number) {
	const target = shallowRef<number>(index);
	const data = tableData.value[index];
	ElMessageBox({
		title: `将 ${data.label} #${index + 1} 移动到...`,
		message: (): any => {
			const labels = new Map(tableData.value.map(v => [v.label, v.label]));
			return h(ElSelect, {
				filterable: true, defaultFirstOption: true,
				modelValue: target.value,
				'onUpdate:modelValue': (val: number) => {
					target.value = val;
				},
			}, tableData.value.map(({ label }, i) => h(ElOption, {
				value: i,
				label: i === index
					? '<<<位置不变>>>'
					: `${labels.get(label) || label} #${i + 1} ${i < index ? '之前' : '之后'}`,
			})));
		},
	}).then(() => {
		move(index, target.value);
	});
}

</script>

<template>
	<div class="card-config" >
		<ElFormItem :label="tt('Label')">
			<ElInput v-model="label"></ElInput>
		</ElFormItem>
		<ElFormItem :label="tt('description')">
			<ElInput type="textarea" :autosize="{ minRows: 4 }" v-model="description"></ElInput>
		</ElFormItem>
		<ElTable class="config-table" :data="tableData" border rowKey="name"
			:expandRowKeys="expandRowKeys"
			@expand-change="expandChange"
			@selection-change="selectionChange"
			style="width: 100%">
			<ElTableColumn type="selection" align="center" width="40" />
			<ElTableColumn type="index" align="center" />
			<ElTableColumn type="expand">
				<template #default="props">
					<div class="form-content">
						<ElForm labelPosition="top">
							<ElFormItem :label="tt('Type')" :rules="[
								{
									required: true,
									message: '请选择类型',
									trigger: 'blur',
								}
							]">
								<ElSelect v-model="props.row.type">
									<ElOption v-for="v in types" :key="v"
										:value="v" :label="tt(v)" />
								</ElSelect>
							</ElFormItem>
							<ElFormItem :label="tt('Icon')">
								<IconPicker v-model="props.row.icon" />
							</ElFormItem>
							<ViewLinkConfig v-if="props.row.type==='View Link'"
								:value="props.row.viewLinkConfig"
								@update="(data, submittable)=>updateViewLinkConfig(props.row,data, submittable)">
							</ViewLinkConfig>
							<DoctypeLinkConfig
								v-else-if="props.row.type==='DocType Link'"
								:value="props.row.doctypeLinkConfig"
								@update="(data, submittable)=>updateDoctypeLinkConfig(props.row,data, submittable)">
							</DoctypeLinkConfig>
							<UrlConfig v-else-if="props.row.type==='URL'"
								:value="props.row.urlConfig"
								@update="(data, submittable)=>updateUrlConfig(props.row,data, submittable)"
							></UrlConfig>
							<ListConfig v-else
								:value="props.row.listLinkConfig"
								@update="(data, submittable)=>updateListLinkConfig(props.row,data, submittable)">
							</ListConfig>
						</ElForm>
					</div>
				</template>
			</ElTableColumn>
			<ElTableColumn :label="tt('Label')" prop="label" />
			<ElTableColumn :label="tt('Link To')" prop="link_to" />
			<ElTableColumn :label="tt('操作')" align="center" width="55" #="{ $index }">
				<ElButtonGroup>
					<ElButton class="btn" @click="moveTo($index)" text :icon="Sort"
						:title="tt('移动')" />
				</ElButtonGroup>
			</ElTableColumn>
		</ElTable>
		<ElButton class="add-row-btn" @click="addRow">添加行</ElButton>
		<ElButton type="danger" v-if="selectedData.length>0" class="add-row-btn"
			@click="deleteRow">
			删除行
		</ElButton>
	</div>
</template>
<style scoped lang="less">
.card-config{
	max-height: calc(100vh - 260px );
	overflow: auto;
	.config-table, :deep(.el-scrollbar__wrap), :deep(.el-scrollbar), :deep(.el-table__body-wrapper){
		overflow: visible;
	}
	:deep(.el-scrollbar__bar){
		display: none;
	}
}
.card-config :deep(.el-table .el-table__cell){
	z-index: auto;
}
.card-config :deep(.el-checkbox__original){
	width: 0!important;
	height: 0;
	border: 0;
	margin: 0!important;
	padding: 0;
}
.btn{
	padding: 0;
}
.form-content {
	margin: 1rem;
}

.add-row-btn {
	margin-top: 8px;

}
</style>
