export const cardWidth=180;
export const lineHeight=25;
export const headerHeight=25;
export const defaultLayout={
	type: 'compactBox', // 布局类型
	// direction: 'TB', // 自左至右布局，可选的有 H / V / LR / RL / TB / BT
	nodeSep: 50, // 节点之间间距
	rankSep: 100, // 每个层级之间的间距
	excludeInvisibles: true, // 布局计算是否排除掉隐藏的节点，v4.8.8 起支持
	getWidth:()=>cardWidth,
	getHGap:()=>40,
	getVGap:()=>40,
	getHeight:(d:any)=>Object.entries(d.data||{}).length*lineHeight,
};
