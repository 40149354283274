/* eslint-disable no-prototype-builtins */
import cloneDeep from 'lodash/cloneDeep';
import type Handsontable from 'handsontable';
import type {DetailedSettings} from 'handsontable/plugins/mergeCells';

import {exportFile} from './exportFile';
import {Summary, type Column, type FormatColumns, type HotColumn} from './type';
import {getRenderer, getCustomRenderer} from './customStylesRenderer';

const Align = {
	lft: 'htLeft',
	left: 'htLeft',
	rgt: 'htRight',
	right: 'htRight',
	center: 'htCenter',
	top: 'htTop',
	middle: 'htMiddle',
	bottom: 'htBottom',
};
export function findNodeInTree<T extends Column>(tree:T[], target:string):T|null {
	for (const node of tree) {
		if (node.field === target) {
			return node;
		}
		if (node.children?.length) {
			const foundNode = findNodeInTree(node.children, target);
			if (foundNode) {
				return foundNode;
			}
		}
	}
	return null;
}
export function findLeafNodes<T extends Column>(tree:T[], target:string) {
	const targetNode = findNodeInTree(tree, target);
	if (!targetNode) {
		return [];
	}
	const leafNodes:T[] = [];
	function traverse(node:T) {
		if (!node.children || node.children.length === 0) {
			leafNodes.push(node);
		} else {
			for (const child of node.children) {
				traverse(child);
			}
		}
	}
	traverse(targetNode);
	return leafNodes;
}
export function tree2list(treeData:FormatColumns[], list:FormatColumns[]) {
	for (const item of treeData) {
		const itemCopy = cloneDeep(item);
		const {children} = itemCopy;
		list.push(itemCopy);
		if (children?.length) {
			tree2list(children, list);
		}
	}
}
function getMaxFool(tree:Column[]) {
	let max = 0;
	function loop(data:Column[], floor:number) {
		for (const item of data) {
			if (floor > max) {
				max = floor;
			}
			if (item.children?.length) {
				loop(item.children, floor + 1);
			}
		}
	}
	loop(tree, 1);
	return max;
}
export function getColumns(cols:Column[]) {
	return cols.map(item=>{
		const formatCols:any = {
			data: item.field,
			summary: item.summary,
			rowSummary: item.rowSummary,
			source: item.source,
			type: item.type,
			precision: item.precision,
			renderer: item.renderer,
		};
		if (item.editable === false) {
			formatCols.editor = item.editable;
		}
		if (formatCols.renderer) {
			formatCols.renderer = getCustomRenderer(formatCols.renderer);
		} else {
			formatCols.renderer = getRenderer(item.type || 'text');
		}

		if (item.type === 'numeric') {
			const pattern = `0,0.${new Array(item.precision || 2).fill('0').join('')}`;
			formatCols.numericFormat = {pattern};
		}
		return formatCols;
	});
}
export function getCells(originCols:FormatColumns[], readOnly:boolean) {
	const list:FormatColumns[] = [];
	tree2list(originCols, list);
	return function(row:number, col:number) {
		const cellProperties:Record<string, any> = {};
		let cellDefine = list.find(item=>item.row === row && item.col === col);
		cellDefine = cellDefine || list.find(item=> item.col === col && !item.children?.length);
		const maxHeaderRow = Math.max(...list.map(item=>item.row || 0));
		if (!cellDefine) {
			return cellProperties;
		}
		// @ts-ignore
		const rowData = this.instance.getSettings().data[row];
		const text = rowData?.[cellDefine.field];
		// 设置readonly
		const editableProp = cellDefine.editable;
		if (readOnly) {
			cellProperties.readOnly = true;
		} else if (row <= maxHeaderRow) {
			cellProperties.readOnly = true;
		} else if (rowData?.__type__ === 'summary') {
			cellProperties.readOnly = true;
			cellProperties.bgColor = '#f5f7fa';
		} else if (typeof editableProp === 'function') {
			const editable = editableProp(row, rowData, text);
			if (editable === false) {
				cellProperties.editor = editable;
				cellProperties.bgColor = '#f5f7fa';
			}
		} else if (typeof editableProp === 'boolean') {
			if (!editableProp) {
				cellProperties.bgColor = '#f5f7fa';
			}
		}
		if (cellDefine.rowSummary) {
			cellProperties.editor = false;
			// cellProperties.renderer = getRenderer('n');、
			cellProperties.validator = (v:any, cb:(f:boolean)=>void)=>cb(true);
		}
		// 设置对齐
		const alginProp = cellDefine.align;
		if (rowData?.__type__ === 'headerData') {
			cellProperties.className = `${cellProperties.className || ''} htCenter htMiddle`;
		} else if (alginProp) {
			const aligns = typeof alginProp === 'function'
				? alginProp(row, rowData, text)
				: alginProp;
			const className = aligns.map(item=>Align[item]).join(' ');
			cellProperties.className = `${cellProperties.className || ''} ${className}`;
		}
		// 设置样式
		let {style, bold, bgColor, color, fontSize} = cellDefine;
		if (typeof style === 'function') {
			const styles = style(row, rowData, text) || {};
			({bold, bgColor, color, fontSize} = styles);
		}
		if (rowData?.__type__ === 'headerData') {
			cellProperties.bold = 'bold';
			cellProperties.fontSize = '14px';
			// 增加表格表头样式
			cellProperties.bgColor = '#999999';
			cellProperties.color = '#ffffff';
		} else {
			if (bold) {
				cellProperties.bold = 'bold';
			}
			if (color) {
				cellProperties.color = color;
			}
			if (bgColor) {
				cellProperties.bgColor = bgColor;
			}
			if (fontSize) {
				cellProperties.fontSize = fontSize;
			}
		}
		return cellProperties;
	};
}
export function getWidths(originCols:Column[]) {
	return originCols.map(item=>item.width);
}
export function formatColumns(columns: FormatColumns[]) {
	const list:FormatColumns[] = [...columns];
	for (const item of list) {
		item.row = 0;
	}
	let lastRow = 0;
	let col = 0;
	while (list.length) {
		const top = list.shift();
		if (!top) {
			continue;
		}
		if (top.row !== lastRow) {
			col = top.startCol || 0;
		}
		lastRow = top.row || 0;
		top.col = col;
		top.maxLevel = top.maxLevel ? top.maxLevel : getMaxFool([top]);
		const leafs = findLeafNodes([top], top.field);
		if (top!.children) {
			for (const child of top!.children) {
				child.parentId = top.field;
				child.startCol = col;
				child.maxLevel = getMaxFool([top]) - 1;
				child.row = (top.row || 0) + 1;
				list.push(child);
			}
		}
		col += (leafs.length || 0);
	}
}
export function getSummary(originCols:HotColumn[]) {
	return originCols.map((item, index)=>{
		const summaryProp = item.summary;
		const type = typeof summaryProp === 'function' ? 'custom' : summaryProp;
		if (summaryProp) {
			return {
				type: type,
				sourceColumn: index,
				reversedRowCoords: true,
				destinationRow: 0,
				destinationColumn: index,
				roundFloat: 2,
				forceNumeric: true,
				customFunction: function(endpoint:any) {
					// @ts-ignore
					const hotInstance = this.hot;
					function checkRange(rowRange:[number, number]) {
						let i = rowRange[1] || rowRange[0];
						const data = [];
						do {
							const cellData = hotInstance.getDataAtCell(i, endpoint.sourceColumn);
							data.push(cellData);
							i--;
						} while (i >= rowRange[0]);

						return data;
					}
					let evenCount:any[] = [];
					for (const r in endpoint.ranges) {
						if (endpoint.ranges.hasOwnProperty(r)) {
							evenCount = [...evenCount, ...checkRange(endpoint.ranges[r])];
						}
					}
					if (typeof summaryProp === 'function') {
						return summaryProp(evenCount);
					}
				},
			};
		}
	}).filter(Boolean);
}
export function getHeader(root:Column[], readOnly:boolean, fixedColumnsStart:number = 0) {
	if (root.length === 0) {
		return {columns: []};
	}
	const list = cloneDeep(root) as Required<FormatColumns>[];
	const originCols = list.flatMap(item=>findLeafNodes([item], item.field));
	const columns = getColumns(originCols);
	const widths = getWidths(originCols);
	formatColumns(list);
	const cells = getCells(list, readOnly);
	const headerData = [];
	const merge:Handsontable.GridSettings['mergeCells'] = [];
	let lastRow = 0;
	let rowHeader:Record<string, any> = {__type__: 'headerData'};
	while (list.length) {
		const top = list.shift();
		if (!top) {
			continue;
		}
		if (top.row !== lastRow) {
			headerData.push(rowHeader);
			rowHeader = {__type__: 'headerData'};
			// rowHeader = JSON.parse(JSON.stringify(rowHeader))
		}
		lastRow = top.row || 0;
		const leafs = findLeafNodes([top], top.field);

		for (const [index, leaf] of leafs.entries()) {
			rowHeader[leaf.field] = top.headerName;
		}

		const mergeObj:DetailedSettings = {};
		if ((top.col >= fixedColumnsStart)
			|| (top.col < fixedColumnsStart
			&& leafs.length <= fixedColumnsStart - top.col)) {
			mergeObj.colspan = leafs.length;
			mergeObj.row = top.row;
			mergeObj.col = top.col;
			mergeObj.rowspan = leafs.length > 1 ? 1 : top.maxLevel;
		}
		if (top.col < fixedColumnsStart
			&& leafs.length > fixedColumnsStart - top.col) {
			mergeObj.colspan = leafs.length - fixedColumnsStart;
			mergeObj.row = top.row;
			mergeObj.col = fixedColumnsStart;
			mergeObj.rowspan = leafs.length > 1 ? 1 : top.maxLevel;

			const mergeLeftObj:DetailedSettings = {};
			mergeLeftObj.colspan = fixedColumnsStart - top.col;
			mergeLeftObj.row = top.row;
			mergeLeftObj.col = top.col;
			mergeLeftObj.rowspan = leafs.length > 1 ? 1 : top.maxLevel;
			merge.push(mergeLeftObj);
		}
		merge.push(mergeObj);
		if (top!.children) {
			for (const child of top!.children) {
				list.push(child as Required<FormatColumns>);
			}
		}
	}
	headerData.push(rowHeader);
	const dataSchema = Object.fromEntries((columns || []).map(item=>([item.data])));
	const hasSummaryRow = columns?.some(item=>item.summary);
	return {
		headerData,
		mergeCells: merge.filter(item=>!(item.rowspan === 1 && item.colspan === 1)),
		columns,
		cells,
		widths,
		dataSchema,
		hasSummaryRow,
	};
}

export function getBodyData(columns:any[], data:any[], startRow:number, dataSchema:Record<string, any>) {
	const dataCopy:any[] = JSON.parse(JSON.stringify(data));
	// if (dataCopy.length < 15) {
	// 	const oldLength = dataCopy.length;
	// 	dataCopy.length = 15;
	// 	dataCopy = Array.from(dataCopy, (d, i)=>i >= oldLength ? structuredClone(dataSchema) : d);
	// }
	for (const [col, item] of columns.entries()) {
		if (item.rowSummary) {
			const formulaField:string[] = item.rowSummary.match(/\{\{(.+?)\}\}/g);
			for (const [rowIndex, d] of dataCopy.entries()) {
				let {rowSummary} = item;
				for (const f of formulaField) {
					const formulaCol = columns.find(col=>col.data === f.replaceAll('{{', '').replaceAll('}}', ''));
					const formulaColIndex = columns.findIndex(col=>col.data === formulaCol.data);
					const fColName = String.fromCharCode(65 + formulaColIndex);
					rowSummary = rowSummary.replace(f, `${fColName}${startRow + rowIndex + 1}`);
				}
				d[item.data] = rowSummary;
			}
		}
	}
	return dataCopy.map(item => {
		const newItem: { [key:string]: any} = {};
		Object.keys(item).map(key => {
			newItem[key] = item[key] === 0 ? '' : item[key];
		});
		return newItem;
	});
}
export function getSummaryRow(columns:any[], row:number, dataSchema:Record<string, any>) {
	const dataSchemaCopy = JSON.parse(JSON.stringify(dataSchema));
	dataSchemaCopy.__type__ = 'summary';
	for (const [col, item] of columns.entries()) {
		if (item.summary) {
			const colName = String.fromCharCode(65 + col);
			dataSchemaCopy[item.data] = `=SUM(${colName}1:${colName}${row})`;
		}
	}
	return dataSchemaCopy;
}

export function getMenus(
	hatable:Handsontable,
	canCreateRow:boolean,
	canRemoveRow:boolean,
	readOnly:boolean,
	exportTitle?:string,
) {
	const disabledType = ['summary', 'headerData'];
	const items:Record<string, any> = {
		export: {
			name: __('Export'),
			callback() {
				exportFile(this, exportTitle || 'export');
			},
		},
	};
	if (canCreateRow && !readOnly) {
		items.row_below = {
			disabled() {
				let [row, col] = this.getSelectedLast();
				col = col < 0 ? 0 : col;
				const type = this.getSourceData()[row]?.__type__;
				const {rowspan} = this.getCellMeta(row, col);
				const nextRowType = this.getSourceData()[row + rowspan]?.__type__;
				return nextRowType === 'headerData' || type === 'summary';
			},
		};
		items.row_below_multiple = {
			name: __('Row Below Multiple'),
			submenu: {
				items: [
					{
						key: 'row_below_multiple:5',
						name: __('Insert Below 5'),
						callback(key:string, selection:any[]) {
							const e = this.getSelectedRangeLast().getBottomRightCorner();
							this.alter('insert_row_below', e.row, 5, 'ContextMenu.rowBelow5');
						},
					},
					{
						key: 'row_below_multiple:10',
						name: __('Insert Below 10'),
						callback(key:string, selection:any[]) {
							const e = this.getSelectedRangeLast().getBottomRightCorner();
							this.alter('insert_row_below', e.row, 10, 'ContextMenu.rowBelow5');
						},
					},
					{
						key: 'row_below_multiple:custom',
						name: __('Insert Below custom rows'),
						renderer(hot:Handsontable, wrapper, row, col, prop, itemValue, a, b) {
							const elem = document.createElement('input');
							elem.setAttribute('type', 'number');
							elem.style.height = '16px';
							elem.style.border = '1px solid #aaa';
							elem.style.borderRadius = '4px';
							elem.style.marginRight = '4px';
							const btn = document.createElement('button');
							btn.innerText = __('Confirm');
							btn.className = 'btn btn-primary btn-sm primary-action';
							btn.style.height = '20px';
							btn.style.padding = '0 8px';
							wrapper.appendChild(elem);
							wrapper.appendChild(btn);
							elem.addEventListener('mouseup', e => {
								e.stopPropagation();
							});
							btn.addEventListener('mouseup', e => {
								const rows = elem.value || '0';
								const range = hatable.getSelectedRangeLast()?.getBottomRightCorner();
								hatable.alter('insert_row_below', range?.row, parseInt(rows), 'ContextMenu.rowBelow5');
								hot.getPlugin('contextMenu').close();
							});
							return wrapper;
						},
						disableSelection: true,
					},
				],
			},
		};
	}
	if (canRemoveRow && !readOnly) {
		items.remove_row = {
			disabled() {
				const [row] = this.getSelectedLast();
				const type = this.getSourceData()[row].__type__;
				return disabledType.includes(type);
			},
		};
	}
	return {
		items,
	};
}
