<template>
	<div>
		<ElSelect :placeholder="`${tt('Add')}${label}`" @change="add" filterable>
			<template v-for="item in options" :key="item.field">
				<ElOption v-if="!added.has(`${item.field}`)" :key="item.field" :value="item.field" :label="tt(item.label)"> </ElOption>
			</template>
		</ElSelect>
		<ElButton @click="groups = []" :title="tt('Clear')" :icon="Delete" />
	</div>
	<div
		class="line"
		v-for="group in groups"
		@dragover.prevent
		@dragend="currentGroup = undefined"
		@dragstart="currentGroup = group"
		:key="`${group.field}`"
		@drop.prevent="drop(group)"
		:draggable="draggable(group)">
		<ElButton
			@pointerdown="draggableGroup = group"
			@pointerup="draggableGroup = undefined"
			@pointercancel="draggableGroup = undefined"
			@touchmove.prevent
			:icon="Rank"
			text />
		<div class="label">
			{{ tt(getLabel(group)) }}
		</div>

		<ElCheckbox v-model="group.checked" :label="checkLabel" size="large" />
		<ElButton @click="remove(group)" :icon="Delete" :title="tt('Delete')" text />
	</div>
</template>
<script lang="ts" setup>
import {computed, ref} from 'vue';
import {ElButton, ElCheckbox, ElSelect, ElOption, ElDropdown, ElDropdownMenu, ElDropdownItem} from 'element-plus';
import {Plus, Delete, Rank} from '@element-plus/icons-vue';

const props = defineProps<{
	label: string;
	checkLabel: string;
	modelValue: { field: string; checked?: boolean }[];
	options: {
		field: string;
		label: string;
	}[];
	repeatable?: boolean;
}>();

const emit = defineEmits<{
	(event: 'update:modelValue', group: { field: string; checked?: boolean }[]): void;
	(event: 'update:visible', visible: boolean): void;
}>();
const groups = computed({
	get() {
		return props.modelValue;
	},
	set(v) {
		emit('update:modelValue', v);
	},
});

const added = computed(() => new Set(groups.value.map(f => `${f.field}`)));
const options = computed(() => {
	if (props.repeatable) {
		return props.options;
	}
	const addedList = added.value;
	return props.options.filter(({field}) => !addedList.has(field));
});
const labels = computed(() => Object.fromEntries(props.options.map(({label, field}) => [field, label])));

function add(field: string) {
	if (!props.repeatable && added.value.has(field)) {
		return;
	}
	groups.value = [...groups.value, {field, checked: false}];
}
function remove(filter: { field: string; checked?: boolean }) {
	const {value} = groups;
	const index = value.indexOf(filter);
	if (index < 0) {
		return;
	}
	groups.value = [...value.slice(0, index), ...value.slice(index + 1)];
}
const currentGroup = ref<{ field: string; checked?: boolean }>();
const draggableGroup = ref<{ field: string; checked?: boolean }>();
function draggable(it: { field: string; checked?: boolean }) {
	return draggableGroup.value === it;
}
function drop(it: { field: string; checked?: boolean }) {
	const old = currentGroup.value;
	if (!old) {
		return;
	}
	if (it === old) {
		return;
	}
	let list = groups.value;
	const oldIndex = list.indexOf(old);
	if (oldIndex < 0) {
		return;
	}
	const newIndex = list.indexOf(it);
	if (newIndex < 0) {
		return;
	}
	if (newIndex === oldIndex) {
		return;
	}
	list = [...list.slice(0, oldIndex), ...list.slice(oldIndex + 1)];
	groups.value = [...list.slice(0, newIndex), old, ...list.slice(newIndex)];
}
function getLabel({field}: { field: string; checked?: boolean }) {
	const label = labels.value;
	return (field in label && label[field]) || field;
}

const tt = __;
</script>

<style scoped lang="less">
  .line {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    margin: 4px;
    padding: 4px;
    inline-size: 100%;

    &:hover {
      background: #0001;
      border-radius: 4px;
    }
  }

  .label {
    flex: 1;
  }

  .footer {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .placeholder {
    flex: 1;
  }
</style>
