<template>
	<div v-loading="loading">
		<h4 class="title">{{ tt('Instruction Flow') }}</h4>
		<div class="flow-container">
			<Flow
				v-if="flowData?.name"
				ref="flowRef"
				:data="flowContent"
				:options="options"
			></Flow>
		</div>
	</div>
</template>

<script setup lang="ts">
import {computed, ref, watch, defineProps} from 'vue';
import {vLoading} from 'element-plus';

import {useInstructionStore} from '../../instruction/store';
import Flow from '../../../../../../../../../guigu_flow/guigu_flow/public/js/flow_chart/Flow.vue';
const store = useInstructionStore();

const tt = __;
interface Emit {
	(e: 'remove'): void,
	(e: 'setData', data:string): void,
}
const emit = defineEmits<Emit>();
interface Props {
	editable: boolean;
	data:string
	chartName:string
}
const props = defineProps<Props>();
const DOCTYPE = 'Dw Work Instruction Flow';
const flowRef = ref();
const flowData = ref<{name:string, content:string, dw_work_instruction_version?:string }>();
const loading = ref<boolean>(true);
const flowContent = computed(()=>JSON.parse(flowData.value?.content || '{}'));
store.afterSave(({instruction, versions})=>{
	if (store.isSaveNewVersion) {
		const currentVer = versions?.find(version=>version.name === instruction?.current_version);
		const content = JSON.parse(currentVer?.content || '{content:[]}');
		const flowChart = content.content.find(item=>item.type === 'chart' && item.attrs.chart_type === 'instruction_flow');
		frappe.db.set_value(DOCTYPE, flowChart.attrs.data, 'content', flowData.value?.content);
		return;
	}
	if (!flowData.value?.name) {
		return;
	}
	frappe.db.set_value(DOCTYPE, flowData.value?.name, 'content', flowData.value?.content);
	const versionId = instruction?.current_version;
	if (!versionId || flowData.value?.dw_work_instruction_version) {
		return;
	}
	frappe.db.set_value(DOCTYPE, flowData.value?.name, 'dw_work_instruction_version', versionId);
});
const options = computed(()=>({
	isSilentMode: !props.editable,
	stopScrollGraph: true,
	stopZoomGraph: true,

	onChange(data: any) {
		if (!flowData.value) {
			return;
		}
		if (flowData.value.content !== JSON.stringify(data)) {
			store.isChange = true;
		}
		flowData.value.content = JSON.stringify(data);
	},
}));
watch([()=>flowRef.value?.flow, ()=>props.editable], ()=>{
	if (!flowRef.value?.flow || props.editable) {
		return;
	}
	flowRef.value.flow.fitView();
}, {immediate: true});

function remove() {
	if (!flowData.value?.name) {
		return;
	}
	frappe.db.delete_doc(DOCTYPE, flowData.value?.name);
}
defineExpose({
	remove: remove,
});

const parsedId = computed<string>(()=>props.data || '');
watch(parsedId, ()=>{
	getFlowData(parsedId.value);
}, {immediate: true});

async function createFlow() {
	const data = await frappe.db.insert({
		doctype: DOCTYPE,
		dw_work_instruction_version: store.currentVersion.name,
		content: '{}',
	});
	return data;
}
async function getFlowData(flowId:string) {
	loading.value = true;

	try {
		let doc:any;
		if (flowId) {
			doc = await frappe.db.get_doc(DOCTYPE, flowId);
		} else {
			doc = await createFlow();
		}
		flowData.value = doc;
	} catch {
		const doc = await createFlow();
		flowData.value = doc;
	}
	if (flowId !== flowData.value!.name) {
		emit('setData', flowData.value!.name);
	}
	loading.value = false;
}

frappe.realtime.on('doc_update', params => {
	if (params.doctype !== DOCTYPE) {
		return;
	}
	getFlowData(parsedId.value);
});

</script>
<style scoped lang="less">
.title{
	text-align: center;
	margin-bottom: 1.5rem;
}
.flow-container{
	height: 600px;
}
</style>
