import {createApp} from 'vue';

import TaskTemplateList from './DwTaskTemplateList/StandardWorklist/index.vue';
frappe.provide('frappe.dw_worklist_management.doctype.dw_standard_worklist');

const render_task_template_list = function(frm: any, html: HTMLElement) {
	if (frm.task_template) {
		frm.task_template._instance.props.frm = frm;
	} else {
		const app = createApp(TaskTemplateList, {frm});
		app.mount(html);
	}
};

frappe.dw_worklist_management.doctype.dw_standard_worklist.
	render_task_template_list
	= frappe.dw_worklist_management.doctype.dw_standard_worklist.
			render_task_template_list || {};
frappe.dw_worklist_management.doctype.dw_standard_worklist.
	render_task_template_list = render_task_template_list;
