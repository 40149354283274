<template>
	<Timeline
		:mode="smallMeta ? 'right' : 'left'"
		v-loading="loading"
		@loadMore="loadMore">
		<TimelineItem
			v-for="timelineData, index in timelineResults"
			:date="smallMeta ? timelineData.date : ''"
			:notShowLine="index === (timelineResults?.length - 1)"
			:leftTitle="smallMeta ? '' : timelineData.date">
			<Detail
				v-for="detail in timelineData.list"
				:detail="detail"
				:meta="meta"
				:fields="fields"
				:linkField="linkField"
				:preventClick="preventClick">
			</Detail>
		</TimelineItem>
	</Timeline>
</template>

<script lang="ts" setup>
import {computed} from 'vue';

import {useMetaQuery} from '../hooks/useMetaQuery';

import Timeline from './Timeline.vue';
import TimelineItem from './TimelineItem.vue';
import Detail from './Detail.vue';
import {getTimelineData} from './helper';
import {Config} from './type';

defineOptions({inheritAttrs: false});

const props = defineProps<{
	meta: locals.DocType;
	options: Record<string, any>;

	data: locals.Doctype[];
	total: number;
	loading?: boolean;

	modelValue: any;
	selected: any[];

	infiniteScroll: boolean;
	page: number;
	limit: number;
	group: GlobalView.Group[];
	sort: GlobalView.MainLoaderOptions['order'];

	detail?: boolean;
	rowAction?: any;

	view?: GlobalView.View;
	configuration?: Config;
}>();

const emit = defineEmits<{
	(event: 'refresh'): void;
	(event: 'delete', doc: any): void;
	(event: 'create', data: any, extend?: boolean): void;
	(event: 'nextPage'): void;
	(event: 'update:modelValue', value: any): void;
	(event: 'update:selected', value: any[]): void;
	(event: 'update:data', data: locals.Doctype[]): void;
	(event: 'filter', field: string, operator: string, value: any): void;
	(event: 'sort', target: string, docs: string[], before: boolean, field?: string): void
	(event: 'sort', target: string, docs: string[], before: boolean, children?: boolean): void
}>();
const smallMeta = useMetaQuery();
const linkField = computed(() => props.view?.linkField);
function preventClick(e: Event, value: any) {
	if (!props.detail) {
		return;
	}
	e.preventDefault();
	emit('update:modelValue', value);
}

const fields = computed(() => props.view?.fields || []);
const timelineResults = computed(() => {
	const timeField = props.configuration?.time_field;
	if (!timeField) {
		return [];
	}
	const order = props.configuration?.order;
	return getTimelineData(props.data, order === 'Desc', timeField);
});
function loadMore() {
	if (props.data.length === props.total) {
		return;
	}
	emit('nextPage');
}
</script>

<style scoped lang="less">
.container {
	overflow: hidden;
	position: relative;
	height: 100%;
	width: 100%;
}
</style>
