<template>
	<ElInput v-model="value" :rows="2" type="textarea"
		:placeholder="label && tt(label)" />
</template>
<script lang="ts" setup>
import { computed, onUnmounted, watch } from 'vue';
import { ElInput } from 'element-plus';


const props = defineProps<{
	label?: string;
	name: string;
	required?: boolean;
	filter?: boolean;
	modelValue?: any;
	fitWidth?: boolean;
	popperClass?: string;
	options?: any;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: any): void;
}>();

const tt = __;


watch(() => props.modelValue, value => {
	if (typeof value !== 'string' && value !== undefined) {
		emit('update:modelValue', undefined);
	} else if (!props.required && value === '') {
		emit('update:modelValue', undefined);
	}
}, { immediate: true });
const value = computed({
	get: () => {
		const value = props.modelValue;
		if (!props.required && value === '') {
			return;
		}
		return typeof value === 'string' ? value : undefined;
	},
	set: v => emit('update:modelValue', !props.required && v === '' ? undefined : v),
});
</script>
