import type {Cell} from '../types/Row';
export default function showCell(c: Cell) {
	const {column} = c;
	const {el} = c;
	el.style.setProperty('--nyloong-table-column-start', `${column.start}px`);
	el.style.inlineSize = `${column.width}px`;
	if (column.spillable) {
		el.classList.remove('nyloong-table-cell-ellipsis');
	} else {
		el.classList.add('nyloong-table-cell-ellipsis');

	}
	c.setHidden(false);
}
