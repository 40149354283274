import PizZip from 'pizzip';

import DocxtemplaterImageModule from 'docxtemplater-image-module';
import Docxtemplater from 'docxtemplater';
import DocxtemplaterHtmlModule from 'docxtemplater-html-module';
import JSZip from 'jszip/dist/jszip.min.js';
import JSZipUtils from 'jszip-utils/dist/jszip-utils.min.js';

frappe.provide('guigu.exportDocx');
const exports = guigu.exportDocx;
export function getBinaryContent(path: string) {
	return new Promise<string>((resolve, reject) =>
		JSZipUtils.getBinaryContent(path, (e: null | Error, c: string) =>
			e ? reject(e) : resolve(c),
		),
	);
}
exports.getBinaryContent = getBinaryContent;
const docxMimeType =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export async function exportHtmlDocx(template: any, data:any) {
	if (template instanceof Blob) {
		template = await template.arrayBuffer();
	}
	const doc = new Docxtemplater()
	.loadZip(new JSZip(template))
    .attachModule(
      new DocxtemplaterHtmlModule(
		{
        ignoreUnknownTags: true,
        ignoreCssErrors: true,
        styleSheet:`
		h1, h2, h3, h4, h5, h6{ color: #000; font-weight: bold; }
		h1 { font-size: 20px; }
		h2 { font-size: 18px; }
		h3 { font-size: 16px; }
		h4 { font-size: 14px; }
		p { margin: 4px 0; fontsize: 11px; }
		th { background-color: #e8e8e8; font-weight: bold;}
		th,td { padding: 2px; }
		tbody { vertical-align: middle; }
		tr, *:first-child { border-left: none; }
		tr, *:last-child { border-right: none; }`,
        img: {
          Module: DocxtemplaterImageModule,
          getValue: ({ attribs: { src } }: { attribs: { src: string } }) =>
            getBinaryContent(src),
        },
        elementCustomizer: (element: any) => {
          if (!(element.name === 'td' || element.name === 'th')) {
            return;
          }
          if (
            !element.attribs?.style ||
            element.attribs?.style?.indexOf('vertical-align') < 0
          ) {
            return { htmlStyle: 'vertical-align: middle;' };
          }
          return;
        },
      },
	  ),
    )
    .compile();
	await doc.resolveData(data);
	doc.render();
	const out = doc.getZip().generate({
		type: 'blob',
		mimeType: docxMimeType,
	});
	return out;
}
exports.exportHtmlDocx = exportHtmlDocx;

export async function exportDocx(template: any, data: any) {
	if (template instanceof Blob) {
		template = await template.arrayBuffer();
	}
	const zip = new PizZip(template);
	const doc = new Docxtemplater(zip, {
		paragraphLoop: true, linebreaks: true,
	});
	doc.setData(data);
	doc.render();
	const out = doc.getZip().generate({
		type: 'blob',
		mimeType: docxMimeType,
	});
	return out;
}
exports.exportDocx = exportDocx;


export function saveAs(
	data: Blob | MediaSource | string,
	name: string,
) {
	const url = typeof data === 'string' ? data : URL.createObjectURL(data);
	const a = document.createElement('a');
	a.href = url;
	a.download = name;
	a.target = '_blank';
	a.click();
	if (typeof data !== 'string') {
		setTimeout(() => {
			URL.revokeObjectURL(url);
		}, 30000);
	}
}
exports.saveAs = saveAs;
