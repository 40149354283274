frappe.views.DashboardView.prototype.show_add_chart_dialog = function show_add_chart_dialog() {
	let fields = this.get_field_options();
	const dialog = new frappe.ui.Dialog({
		title: __('Add a {0} Chart', [__(this.doctype)]),
		fields: [
			{
				fieldname: 'new_or_existing',
				fieldtype: 'Select',
				label: 'Choose an existing chart or create a new chart',
				options: ['New Chart', 'Existing Chart'],
				reqd: 1,
			},
			{
				label: 'Chart',
				fieldname: 'chart',
				fieldtype: 'Link',
				get_query: () => ({
					query: 'frappe.desk.doctype.dashboard_chart.dashboard_chart.get_charts_for_user',
					filters: {
						document_type: this.doctype,
					},
				}),
				options: 'Dashboard Chart',
				depends_on: 'eval: doc.new_or_existing == "Existing Chart"',
			},
			{
				fieldname: 'sb_2',
				fieldtype: 'Section Break',
				depends_on: 'eval: doc.new_or_existing == "New Chart"',
			},
			{
				label: 'Chart Label',
				fieldname: 'label',
				fieldtype: 'Data',
				mandatory_depends_on: 'eval: doc.new_or_existing == "New Chart"',
			},
			{
				fieldname: 'cb_1',
				fieldtype: 'Column Break',
			},
			{
				label: 'Chart Type',
				fieldname: 'chart_type',
				fieldtype: 'Select',
				options: ['Time Series', 'Group By'],
				mandatory_depends_on: 'eval: doc.new_or_existing == "New Chart"',
			},
			{
				fieldname: 'sb_2',
				fieldtype: 'Section Break',
				label: 'Chart Config',
				depends_on:
					'eval: doc.chart_type == "Time Series" && doc.new_or_existing == "New Chart"',
			},
			{
				label: 'Function',
				fieldname: 'chart_function',
				fieldtype: 'Select',
				options: ['Count', 'Sum', 'Average'],
				default: 'Count',
			},
			{
				label: 'Timespan',
				fieldtype: 'Select',
				fieldname: 'timespan',
				depends_on: 'eval: doc.chart_type == "Time Series"',
				options: ['Last Year', 'Last Quarter', 'Last Month', 'Last Week'],
				default: 'Last Year',
			},
			{
				fieldname: 'cb_2',
				fieldtype: 'Column Break',
			},
			{
				label: 'Value Based On',
				fieldtype: 'Select',
				fieldname: 'based_on',
				options: fields.value_fields,
				depends_on: 'eval: doc.chart_function=="Sum" && doc.type!="BCWP"',
			},
			{
				label: 'Time Series Based On',
				fieldtype: 'Select',
				fieldname: 'based_on',
				options: fields.date_fields,
				mandatory_depends_on: 'eval: doc.chart_type == "Time Series"',
			},
			{
				label: 'Time Interval',
				fieldname: 'time_interval',
				fieldtype: 'Select',
				depends_on: 'eval: doc.chart_type == "Time Series"',
				options: ['Yearly', 'Quarterly', 'Monthly', 'Weekly', 'Daily'],
				default: 'Monthly',
			},
			{
				fieldname: 'sb_2',
				fieldtype: 'Section Break',
				label: 'Chart Config',
				depends_on:
					'eval: doc.chart_type == "Group By" && doc.new_or_existing == "New Chart"',
			},
			{
				label: 'Group By Type',
				fieldname: 'group_by_type',
				fieldtype: 'Select',
				options: ['Count', 'Sum', 'Average'],
				default: 'Count',
			},
			{
				label: 'Aggregate Function Based On',
				fieldtype: 'Select',
				fieldname: 'aggregate_function_based_on',
				options: fields.aggregate_function_fields,
				depends_on: 'eval: ["Sum", "Average"].includes(doc.group_by_type)',
			},
			{
				fieldname: 'cb_2',
				fieldtype: 'Column Break',
			},
			{
				label: 'Group By Based On',
				fieldtype: 'Select',
				fieldname: 'group_by_based_on',
				options: fields.group_by_fields,
				default: 'Last Year',
			},
			{
				label: 'Number of Groups',
				fieldtype: 'Int',
				fieldname: 'number_of_groups',
				default: 0,
			},
			{
				fieldname: 'sb_2',
				fieldtype: 'Section Break',
				label: 'Value Based On Multiple',
				depends_on:
					'eval: doc.chart_type=="Group By" || doc.type =="BCWP"',
			},
			{
				fieldname: 'value_based_on_multiple',
				fieldtype: 'Table',
				fields: [
					{
						fieldtype: 'Select',
						fieldname: 'field',
						name: 'field',
						label: __('Field'),
						options: fields.value_fields,
						in_list_view: 1,
					},
					{
						fieldtype: 'Select',
						fieldname: 'denominator',
						name: 'denominator',
						label: __('Denominator'),
						options: fields.value_fields,
						in_list_view: 1,
					},
					{
						fieldtype: 'Data',
						fieldname: 'percent_title',
						name: 'percent_title',
						label: __('Percent Title'),
						in_list_view: 1,
					},
					{
						fieldtype: 'Color',
						fieldname: 'color',
						name: 'color',
						label: __('Color'),
						in_list_view: 1,
					},
				],
				depends_on: 'eval: doc.chart_type=="Group By" || doc.type =="BCWP"',
			},
			{
				fieldname: 'sb_3',
				fieldtype: 'Section Break',
				depends_on: 'eval: doc.new_or_existing == "New Chart"',
			},
			{
				label: 'Chart Type',
				fieldname: 'type',
				fieldtype: 'Select',
				options: ['BCWP', 'Line', 'Bar', 'Percentage', 'Pie'],
				depends_on: 'eval: doc.new_or_existing == "New Chart"',
			},
			{
				fieldname: 'cb_1',
				fieldtype: 'Column Break',
			},
			{
				label: 'Chart Color',
				fieldname: 'color',
				fieldtype: 'Color',
				depends_on: 'eval: doc.new_or_existing == "New Chart"',
			},
		],
		primary_action_label: __('Add'),
		primary_action: values => {
			let chart = values;
			if (chart.new_or_existing == 'New Chart') {
				chart.chart_name = chart.label;
				chart.chart_type =
					chart.chart_type == 'Time Series'
						? chart.chart_function
						: chart.chart_type;
				chart.document_type = this.doctype;
				chart.filters_json = '[]';
				frappe
					.xcall(
						'frappe.desk.doctype.dashboard_chart.dashboard_chart.create_dashboard_chart',
						{ args: chart }
					)
					.then(doc => {
						this.chart_group.new_widget.on_create({
							chart_name: doc.chart_name,
							name: doc.chart_name,
							label: chart.label,
						});
					});
			} else {
				this.chart_group.new_widget.on_create({
					chart_name: chart.chart,
					label: chart.chart,
					name: chart.chart,
				});
			}
			dialog.hide();
		},
	});
	dialog.show();
};
