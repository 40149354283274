import GridRowForm from '../../../../../../frappe/frappe/public/js/frappe/form/grid_row_form';

export default class GuiguGirdRowForm extends GridRowForm {
	constructor(opts) {
		super(opts);
		if (this.row.frm.isPosition) {
			$(this.wrapper).wrap('<dialog class="guigu_sepco_dialog"><div class="guigu_dialog_modal"><div class="guigu_dialog_content"></div></div></dialog>');
		}
	}
}
