import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["AgGridVue"], {
    class: "ag-theme-guigu table",
    style: {"width":"100%","margin":"0","overflow":"auto"},
    rowSelection: "multiple",
    rowData: $props.dataList,
    columnDefs: $setup.columnDefs,
    domLayout: "autoHeight",
    defaultColDef: $setup.defaultColDef,
    animateRows: "true",
    onGridReady: $setup.onGridReady,
    getRowId: $setup.getRowId,
    suppressDragLeaveHidesColumns: $props.smallMeta,
    localeText: $setup.zhCN
  }, null, 8 /* PROPS */, ["rowData", "suppressDragLeaveHidesColumns", "localeText"]))
}