import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f3c5a2b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "agcontainer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Page"], null, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.tt('Boq Item List')), 1 /* TEXT */)
    ]),
    tools: _withCtx(() => [
      _createVNode($setup["Tools"], {
        project: $setup.project,
        "onUpdate:project": _cache[0] || (_cache[0] = $event => (($setup.project) = $event)),
        detailType: $setup.detailType,
        "onUpdate:detailType": _cache[1] || (_cache[1] = $event => (($setup.detailType) = $event)),
        loading: $setup.loading,
        refreshValue: $setup.refreshValue,
        "onUpdate:refreshValue": _cache[2] || (_cache[2] = $event => (($setup.refreshValue) = $event)),
        gridApi: $setup.gridApi,
        onUpdateRecordValue: $setup.updateRecordValue
      }, null, 8 /* PROPS */, ["project", "detailType", "loading", "refreshValue", "gridApi"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["Skeleton"], {
          loading: $setup.loading,
          ref: "skeleton",
          layout: $setup.detailType,
          hasDetail: $setup.recordValue
        }, {
          detail: _withCtx(() => [
            ($setup.recordValue)
              ? (_openBlock(), _createBlock($setup["FormDetail"], {
                  key: 0,
                  meta: $setup.docMeta,
                  options: {},
                  value: $setup.recordValue,
                  "onUpdate:value": _cache[3] || (_cache[3] = $event => (($setup.recordValue) = $event))
                }, null, 8 /* PROPS */, ["meta", "value"]))
              : _createCommentVNode("v-if", true)
          ]),
          default: _withCtx(() => [
            _createVNode($setup["AgGridTable"], {
              boqItemList: $setup.boqItemListAncestry,
              layout: $setup.detailType,
              onUpdateRecordValue: $setup.updateRecordValue,
              onOnGridReady: $setup.onGridReady
            }, null, 8 /* PROPS */, ["boqItemList", "layout"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["loading", "layout", "hasDetail"])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}