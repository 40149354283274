<template>
	<div class="label">{{ tt('Save Filter') }}</div>
	<input v-model="name" :placeholder="tt('Filter Name')" class="input"
		@keydown.enter="save" />
	<div v-if="name">
		<div v-if="!creatable" class="tip">
			{{ tt('Duplicate Filter Name') }}
		</div>
		<div v-else class="tip">{{ tt('Press Enter to save') }}</div>
		<el-checkbox v-if="can_add_global" v-model="isGlobal"
			class="checkbox"
			:label="`${tt('Is Global')}${tt('(Or Press Shift+Enter To Save)')}`" />
	</div>
	<ElTag v-for="{ name, filters, filter_name, for_user } in list" :key="name"
		class="tag" :closable="removable(for_user)"
		@click="set(filters)" @close="remove(name)">
		{{ filter_name }}
	</ElTag>
</template>
<script setup lang="ts">
import {computed, ref, watch} from 'vue';
import {ElTag} from 'element-plus';

defineOptions({name: 'FilterList', inheritAttrs: false});


const props = defineProps<{
	meta: locals.DocType;
	filters: {
		key?: number | undefined;
		doctype: string;
		field: string;
		condition?: string | undefined;
		value?: any;
	}[];
}>();
const tt = __;
const emit = defineEmits<{
	(event: 'update:filters', filters: typeof props.filters): void
}>();
const name = ref('');
const isGlobal = ref(false);
const can_add_global = frappe.user.has_role(['System Manager', 'Administrator']) || false;
type Filter = [string, string, any] | [string, string, string, any, false?]
const list = ref<{
	name: string,
	filter_name: string,
	for_user: string,
	filters: Filter[]
}[]>([]);
const loadingCount = ref(0);
const filterLoading = computed(() => Boolean(loadingCount.value));
function removable(user: string) {
	return can_add_global || user === frappe.session.user;
}
async function load() {
	try {
		loadingCount.value++;
		const data = await frappe.db.get_list<{
			name: string,
			filter_name: string,
			for_user: string,
			filters: string
		}>('List Filter', {
			fields: ['name', 'filter_name', 'for_user', 'filters'],
			filters: {reference_doctype: props.meta.name},
			or_filters: [
				['for_user', '=', frappe.session.user],
				['for_user', '=', ''],
			],
		});
		if (loadingCount.value !== 1) {
			return;
		}
		list.value = data.map(v => ({...v, filters: JSON.parse(v.filters)}));
	} finally {
		loadingCount.value--;
	}
}
const creatable = computed(() => {
	const filter_name = name.value;
	if (!filter_name) {
		return false;
	}
	return !list.value.find(v => v.filter_name === filter_name);
});
async function save(e?: Event | KeyboardEvent) {
	if (!(e instanceof KeyboardEvent) && e instanceof Event) {
		return;
	}
	const global = can_add_global && (e && e.shiftKey || isGlobal.value);
	const doctype = props.meta.name;

	if (!creatable.value) {
		return;
	}

	const filter_name = name.value;
	if (!filter_name) {
		return;
	}
	if (list.value.find(v => v.filter_name === filter_name)) {
		return;
	}
	name.value = '';

	await frappe.db.insert({
		doctype: 'List Filter',
		reference_doctype: doctype,
		filter_name,
		for_user: global ? '' : frappe.session.user,
		filters: JSON.stringify([
			...props.filters.map(v => {
				if (v.value === undefined) {
					return;
				}
				return [v.doctype, v.field, v.condition || '=', v.value, false];
			}),
		].filter(Boolean)),
	});
	load();
}
async function remove(name: string) {
	if (!name) {
		return;
	}
	await frappe.db.delete_doc('List Filter', name);
	load();
}

const doctype = computed(() => props.meta.name);
watch(doctype, load, {immediate: true});

function set(list: Filter[]) {
	const doctype = props.meta.name;
	const filters: typeof props.filters = [];

	function addFilter(doctype: string, field: string, condition = '=', value: any) {
		filters.push({doctype, field, condition, value});
	}
	for (const it of list) {
		if (!Array.isArray(it)) {
			continue;
		}
		if (it.length === 3) {
			const [field, condition, value] = it;
			addFilter(doctype, field, condition, value);
		} else if (it.length >= 4) {
			const [doctype, field, condition, value, t] = it;
			if (t === undefined || t === false) {
				addFilter(doctype, field, condition, value);
			}
		}
	}
	emit('update:filters', filters);
}

</script>
<style scoped lang="less">
.tag {
	display: flex;
	justify-content: space-between;
	margin-block: 8px;
}

.input {
	display: block;
	inline-size: 100%;
	block-size: calc(1.5em + 0.75rem + 2px);
	padding-block: 0.375rem;
	padding-inline: 0.75rem;
	font-weight: 400;
	line-height: 1.5;
	color: var(--text-color);
	border-radius: var(--border-radius);
	box-shadow: none;
	transition: none;

	height: 26px;
	background: var(--control-bg-on-gray);
	border: none;
	font-size: var(--text-md);
	position: relative;

	&:focus {

		border-color: var(--gray-500);
		outline: 0;
		box-shadow: 0 0 0 2px #68717840;
	}
}

.label {
	margin-bottom: var(--margin-sm);
	font-weight: 500;
	display: flex;
	align-items: center;
	color: var(--text-muted);
	letter-spacing: .04rem;
	font-size: var(--text-sm);
}

.tip {
	color: var(--text-muted) !important;

	font-weight: 400;
	font-size: var(--text-sm);
	margin-top: 4px;
	margin-bottom: 8px;
	line-height: 1.6;
}

.checkbox {
	* {
		font-size: var(--text-sm) !important;
	}

	input {
		height: 0;
		width: 0 !important;
		margin: 0 !important;
	}
}
</style>
