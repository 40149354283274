import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f839a63"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fileBoxWrapper" }
const _hoisted_2 = { class: "fileBox" }
const _hoisted_3 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElButton = _resolveComponent("ElButton")
  const _component_ElDropdownItem = _resolveComponent("ElDropdownItem")
  const _component_ElDropdownMenu = _resolveComponent("ElDropdownMenu")
  const _component_ElDropdown = _resolveComponent("ElDropdown")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "imgbox",
        src: $setup.photo.image
      }, null, 8 /* PROPS */, _hoisted_3),
      _createVNode(_component_ElDropdown, { class: "dropdown-container" }, {
        dropdown: _withCtx(() => [
          _createVNode(_component_ElDropdownMenu, null, {
            default: _withCtx(() => [
              _createVNode(_component_ElDropdownItem, { onClick: $setup.download }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.tt("Download")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_ElButton, {
            class: "iconBall right",
            type: "primary",
            icon: $setup.MoreFilled,
            circle: ""
          }, null, 8 /* PROPS */, ["icon"])
        ]),
        _: 1 /* STABLE */
      })
    ])
  ]))
}