import defineView from 'guiguLib/defineView';

import './init';
import KanbanView from './KanbanView/index.vue';
import LinkOptions from './LinkOptions.vue';
import './view';

defineView('gkanban', KanbanView, {
  title: __('Comprehensive Kanban'),
  link: {
    component: LinkOptions,
    getLink(doctype, filters, options) {
      return options ? `?=${options}` : '';
    },
  },
});
