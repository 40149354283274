<template>
	<div>
		<a :href="href" :title="title" class="title">{{ title }}</a>
		<div class="field" v-for="field in fields">
			{{ field.showLabel && field.title ? `${field.title}：` : undefined }}
			<GuiguFieldRenderer
				:meta="meta"
				:data="data"
				:value="data[field.name]"
				:field="field.field" />
		</div>
	</div>
</template>
<script lang="ts" setup>
import {computed} from 'vue';

import html2text from '../utils/html2text';

import type {Column} from './getFieldDef';


const props = defineProps<{
	meta: locals.DocType;
	data: locals.Doctype;
	loading?: boolean;
	fields: Column[]
}>();
const href = computed(() => `/app/${frappe.router.slug(props.meta.name)}/${props.data.name}`);
const title = computed(() => {
	const {meta, data} = props;
	const {name} = data;
	const fieldName = meta.title_field;
	if (!fieldName) {
		return name;
	}
	const value = fieldName in data && data[fieldName];
	if (!value) {
		return name;
	}
	const titleField = meta.fields.find(v => v.fieldname === fieldName);
	if (!titleField) {
		return name;
	}
	const type = titleField.fieldtype;
	if (['HTML', 'HTML Editor', 'Text Editor'].includes(type)) {
		return html2text(value as any);
	}
	return `${value}`;
});
</script>

<style scoped lang="less">
.title {
	font-weight: bold;
}
.field{
	position:relative;
}
</style>
