import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Main"], {
    type: $setup.type,
    week: $setup.week,
    "onUpdate:week": _cache[0] || (_cache[0] = $event => (($setup.week) = $event)),
    department: $setup.department,
    "onUpdate:department": _cache[1] || (_cache[1] = $event => (($setup.department) = $event)),
    user: $setup.user,
    "onUpdate:user": _cache[2] || (_cache[2] = $event => (($setup.user) = $event)),
    organization: $setup.organization,
    "onUpdate:organization": _cache[3] || (_cache[3] = $event => (($setup.organization) = $event))
  }, null, 8 /* PROPS */, ["type", "week", "department", "user", "organization"]))
}