import get_dialog_constructor from './get_dialog_constructor';

const Onboarding = frappe.workspace_block.blocks.onboarding


const old = Onboarding.prototype.new;
Onboarding.prototype.new = function (block, widget_type = block) {
	let me = this;
	const dialog_class = get_dialog_constructor(widget_type);
	let block_name = block + "_name";
	this.dialog = new dialog_class({
		label: this.label,
		type: widget_type,
		primary_action: (widget) => {
			widget.in_customize_mode = 1;
			this.block_widget = frappe.widget.make_widget({
				...widget,
				widget_type: widget_type,
				container: this.wrapper,
				options: {
					...this.options,
					on_delete: () => this.api.blocks.delete(),
					on_edit: () => this.on_edit(this.block_widget),
				},
				new: true,
			});
			this.block_widget.customize(this.options);
			this.wrapper.setAttribute(
				block_name,
				this.block_widget.label || this.block_widget.onboarding_name
			);
			$(this.wrapper).find(".widget").addClass(`${widget_type} edit-mode`);
			this.new_block_widget = this.block_widget.get_config();
			this.add_settings_button();
		},
	});

	if (!this.readOnly && this.data && !this.data[block_name]) {
		this.dialog.make();

		this.dialog.dialog.get_close_btn().click(() => {
			me.wrapper.closest(".ce-block").remove();
		});
	}
}
