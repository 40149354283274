import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-94183501"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "guigu_date_editor_container",
  ref: "dateRef"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.showQuarter)
      ? (_openBlock(), _createBlock($setup["Quarter"], {
          key: 0,
          inputClass: $props.inputClass,
          value: $setup.date,
          "onUpdate:value": $setup.changeQuarter
        }, null, 8 /* PROPS */, ["inputClass", "value"]))
      : _createCommentVNode("v-if", true),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_date_picker, {
        class: "guigu_date_editor",
        modelValue: $setup.date,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.date) = $event)),
        type: $setup.type,
        format: $setup.format,
        placeholder: "请选择",
        disabledDate: $setup.disabledDate,
        onChange: $setup.change
      }, null, 8 /* PROPS */, ["modelValue", "type", "format"])
    ], 512 /* NEED_PATCH */), [
      [_vShow, !$setup.showQuarter]
    ])
  ], 64 /* STABLE_FRAGMENT */))
}