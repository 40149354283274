import type { Dot } from './types';
import date2n from './date2n';

function getIcon(icon?: false | SVGElement | HTMLElement | string) {
	if (!icon) { return; }
	if (typeof icon === 'string') {
		const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
		path.setAttribute('fill', 'currentColor');
		path.setAttribute('d', icon);
		const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
		svg.setAttribute('width', '1em');
		svg.setAttribute('height', '1em');
		svg.setAttribute('viewBox', '0 0 20 20');
		svg.appendChild(path);
		return svg;
	}
	if (icon instanceof SVGElement) {
		if (icon instanceof SVGSVGElement) {
			const svg = icon.cloneNode(true) as SVGSVGElement;
			svg.setAttribute('width', '1em');
			svg.setAttribute('height', '1em');
			return svg;
		}
		const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
		svg.appendChild(icon.cloneNode(true));
		svg.setAttribute('width', '1em');
		svg.setAttribute('height', '1em');
		return svg;
	}
	if (icon instanceof HTMLElement) {
		return icon.cloneNode(true) as HTMLElement;
	}
}

export default function renderDot(
	data: any,
	allDates: (Date | null)[],
	{ color, icon }: Dot,
	index: number,
	todayN: number,
	prefix: string
) {

	const date = allDates[index];
	if (!date) { return; }

	const el = getIcon(typeof icon === 'function' ? icon(data, index, allDates) : icon);
	if (!el) { return; }
	// 计算开始位置及总长度

	const startN = date2n(date);
	const begin = startN - todayN;

	el.classList.add('nyloong-table-gantt-dot');
	el.style.setProperty('--nyloong-table-gantt-dot-begin', `${begin}`);
	el.style.setProperty('--nyloong-table-gantt-dot-position', `var(${prefix}-dot-position-${index})`);
	el.style.setProperty('--nyloong-table-gantt-dot-size', `var(${prefix}-dot-size-${index})`);

	const c = typeof color === 'function' ? color(data, index, allDates) : color;
	if (c) { el.style.color = c; }
	return el;
}
