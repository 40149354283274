const old_set_fieldtype = frappe.ui.filter_utils.set_fieldtype;

frappe.ui.filter_utils.set_fieldtype = function set_fieldtype(df, fieldtype, condition) {
	old_set_fieldtype.call(this, df, fieldtype, condition);
	if (condition == 'Between' && (df.fieldtype == 'Date' || df.fieldtype == 'Datetime' ||df.fieldtype == 'Guigu Date')) {
		df.fieldtype = 'DateRange';
	}
	if (
		condition == 'Timespan' &&
		['Guigu Date'].includes(df.fieldtype)
	) {
		df.fieldtype = 'Select';
		df.options = this.get_timespan_options([
			'Last',
			'Yesterday',
			'Today',
			'Tomorrow',
			'This',
			'Next',
		]);
	}
};

frappe.ui.filter_utils.get_default_condition = function(df){
	if (df.fieldtype == 'Data') {
		return 'like';
	} else if (df.fieldtype == 'Date' || df.fieldtype == 'Datetime'|| df.fieldtype == 'Guigu Date') {
		return 'Between';
	}
	return '=';

};
