<template>
	<ElInputNumber v-model="val" :precision="3" />
</template>
<script lang="ts" setup>
import { computed, onUnmounted, watch } from 'vue';
import { ElInputNumber } from 'element-plus';


const props = defineProps<{
	label?: string;
	name: string;
	required?: boolean;
	filter?: boolean;
	modelValue?: any;
	fitWidth?: boolean;
	popperClass?: string;
	options?: any;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: any): void;
}>();

function getBaseNumber(value: any): number | undefined {
	switch (typeof value) {
		case 'bigint': return Number(value);
		case 'number': return value;
		case 'string': return Number.parseFloat(value);
	}
}

function getValue(value: any): number | undefined {
	const n = getBaseNumber(value);
	if (n === undefined || isNaN(n)) { return undefined; }
	if (n > Number.MAX_VALUE) {
		return Number.MAX_VALUE;
	}
	if (n < Number.MIN_VALUE) {
		return Number.MIN_VALUE;
	}
	return n;
}
const val = computed({
	get: () => getValue(props.modelValue),
	set: v => emit('update:modelValue', v),
});
watch(() => props.modelValue, value => {
	const newValue = getValue(value);
	if (value !== newValue) {
		emit('update:modelValue', newValue);
	}
}, { immediate: true });
</script>
