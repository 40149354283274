<!-- 组织单元工作清单的组织单元任务模板 -->
<template>
	<h5>{{ tt('Organization unit task templates') }}</h5>
	<AgGridVue
		class="ag-theme-guigu"
		domLayout="autoHeight"
		:rowData="tableData"
		:rowSelection="'multiple'"
		:defaultColDef="defaultColDef"
		@grid-ready="onGridReady"
		:suppressDragLeaveHidesColumns="smallMeta"
		:localeText="zhCN"
		useValueFormatterForExport
		:getRowId="getRowId"
		@row-selected="handleRowSelected"
	>
	</AgGridVue>
	<ElDialog
		v-if="dialogVisible"
		v-model="dialogVisible"
		:title="tt('Batch create task')"
		width="80%"
	>
		<EditTable
			:tableData="taskTemplate"
			:taskTypeList="taskTypeList"
			:user="organizationUser"
		></EditTable>
		<template #footer>
			<div class="dialog-footer">
				<ElButton @click="cancelDialogVisible=true" size="small">{{ tt('Cancel') }}</ElButton>
				<ElButton type="primary" size="small" @click="confirm">
					{{ tt('Confirm') }}
				</ElButton>
			</div>
		</template>
	</ElDialog>
	<ElDialog
		v-model="cancelDialogVisible"
		:title="tt('Prompt')"
	>
		<div>{{ tt("Are you sure to cancel the creation task? The maintained information will be cleared") }}</div>
		<template #footer>
			<div class="dialog-footer">
				<ElButton @click="cancelDialogVisible = false" size="small">{{ tt('Cancel') }}</ElButton>
				<ElButton type="primary" size="small" @click="cancelCreate">
					{{ tt('Confirm') }}
				</ElButton>
			</div>
		</template>
	</ElDialog>
</template>

<script lang="ts" setup>
import {onMounted, ref, defineProps, watch, onUnmounted} from 'vue';
import {ElButton, ElDialog, ElMessage} from 'element-plus';
import {AgGridVue} from 'ag-grid-vue3';
import type {ColDef, ColGroupDef, GridApi} from 'ag-grid-community';
import moment from 'moment';

import type {TaskTemplate, TemplateCreateTask, TaskType, SelectOption} from '../../../../types';
import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';
import {useMetaQuery} from '../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';
import Subject from '../../../../dw_worklist_management/doctype/dw_standard_worklist/task_template/DwTaskTemplateList/component/Subject.vue';
import EditTable from '../../../../dw_worklist_management/doctype/dw_organization_worklist/organization_worklist_task_template/component/EditTable.vue';
import CreateTaskButton from '../../../../dw_worklist_management/doctype/dw_organization_worklist/organization_worklist_task_template/component/CreateTaskButton.vue';

import {addPathAttribute} from './helper';
const tt = __;
const props = defineProps<{
	name: string;
	department: string;
}>();
const emit = defineEmits<{
	(e:'updateTaskList'):void;
}>();
const tableData = ref<TaskTemplate[]>([]);
const loading = ref<boolean>(false);
const smallMeta = useMetaQuery();
const selectData = ref<TaskTemplate[]>([]);
const gridApi = ref<GridApi>();
const taskTypeObj = ref<Record<string, string>>();

// 任务模板创建任务
const dialogVisible = ref<boolean>(false);
const taskTemplate = ref<TemplateCreateTask[]>([]);
const taskTypeList = ref<SelectOption[]>([]);
const cancelDialogVisible = ref<boolean>(false);
const organizationUser = ref<SelectOption[]>([]);
// 获取一级任务模板列表;
async function getTableData() {
	loading.value = true;
	const organizationTaskTemplate: TaskTemplate[] = await frappe.db.get_list(
		'Dw Task Template',
		{
			filters: {
				reference_type: 'Dw Organization Worklist',
				reference_name: props.name,
				parent_dw_task_template: '',
				disable: 0,
			},
			fields: [
				'name', 'subject', 'priority', 'task_type', 'is_necessary', 'percent_complete',
				'plan_start', 'plan_end', 'assignee', 'parent_dw_task_template', 'lft', 'rgt', 'root_id',
			],
			limit: 0,
		},
	);
	for (const template of organizationTaskTemplate) {
		template.task_type = taskTypeObj.value?.[template.task_type] || '';
	}
	tableData.value = organizationTaskTemplate;
	loading.value = false;
}

async function getTaskTemplateList(rowData: TemplateCreateTask) {
	const childTaskTemplate: TemplateCreateTask[] = await frappe.db.get_list(
		'Dw Task Template',
		{
			filters: [
				['reference_type', '=', 'Dw Organization Worklist'],
				['reference_name', '=', props.name],
				['root_id', '=', rowData.root_id],
				['lft', '>=', rowData.lft],
				['rgt', '<=', rowData.rgt],
				['disable', '=', 0],
			],
			fields: [
				'name', 'subject', 'priority', 'task_type', 'is_necessary', 'percent_complete',
				'plan_start', 'plan_end', 'assignee', 'parent_dw_task_template', 'lft', 'rgt',
			],
			limit: 0,
		},
	);
	const newTableData = addPathAttribute(childTaskTemplate);
	taskTemplate.value = newTableData;
}

async function getTaskType() {
	const taskType:TaskType[] = await frappe.db.get_list(
		'Dw Task Type',
		{
			fields: ['name', 'type_name'],
			limit: 0,
		},
	);
	const options = [];
	const typeObject: Record<string, string> = {};
	for (const type of taskType) {
		options.push({
			value: type.name,
			label: type.type_name,
		});
		typeObject[type.name] = type.type_name;
	}
	taskTypeList.value = options;
	taskTypeObj.value = typeObject;
}

async function getOrganizationUser() {
	const userList = await frappe.db.get_list('Tianjy Organization Member', {
		filters: {organization: localStorage.getItem('TianjyOrganization/currentOrganization')},
		fields: ['user', 'user_name'],
		limit: 0,
	});
	organizationUser.value = userList?.map(item => ({
		label: item.user_name,
		value: item.user,
	})) || [];
}

function openCreateDialog(rowData: TemplateCreateTask) {
	getTaskTemplateList(rowData);
	getOrganizationUser();
	dialogVisible.value = true;
}

const columns: (ColDef | ColGroupDef)[] = [
	{
		headerName: __('Subject'),
		field: 'subject',
		flex: 1,
		checkboxSelection: true,
		cellRenderer: Subject,
	},
	{
		headerName: __('Dw Task Type'),
		field: 'task_type',
		flex: 1,
	},
	{
		headerName: __('Priority'),
		field: 'priority',
		width: 150,
		valueGetter: params => __(params.data.priority),
	},
	{
		headerName: __('Necessary'),
		field: 'is_necessary',
		width: 100,
		valueGetter: params => {
			if (params.data.is_necessary) {
				return __('Yes');
			}
			return '';
		},
	},
	{
		headerName: __('Actions'),
		field: '',
		width: 120,
		minWidth: 120,
		cellRenderer: CreateTaskButton,
		cellRendererParams: {
			openCreateDialog,
		},
	},
];

const defaultColDef = {
	filter: false,
	resizable: true,
	editable: false,
	suppressMovable: true,
	cellDataType: false,
	enableRowGroup: false,
};
const onGridReady = (params: any) => {
	gridApi.value = params.api;
};
function getRowId(params: any) {
	return params.data.name;
}
watch([() => smallMeta, gridApi], () => {
	gridApi.value?.setColumnDefs(columns);
});
function handleRowSelected() {
	const selectedRows = gridApi.value?.getSelectedRows();
	selectData.value = selectedRows || [];
}

async function confirm() {
	for (const template of taskTemplate.value) {
		template.plan_start = moment(template.plan_start).format('YYYY-MM-DD') || '';
		template.plan_end = moment(template.plan_end).format('YYYY-MM-DD') || '';
	}
	const res = await frappe.call<{ message: [] }>({
		method: 'dw_worklist_management.dw_task_management.create_task_by_template.batch_create_task_by_template',
		args: {
			task_templates: taskTemplate.value,
			organization: frappe.tianjy.organization.getCurrent(),
			department: props.department,
		},
	});
	if (res?.message) {
		ElMessage({message: __('Created Successfully'), type: 'success'});
		taskTemplate.value = [];
		emit('updateTaskList');
		dialogVisible.value = false;
	}
}

function cancelCreate() {
	cancelDialogVisible.value = false;
	dialogVisible.value = false;
}
const popstateListener = function () {
	getTableData();
};

onMounted(() => {
	getTaskType();
	getTableData();
	window.addEventListener('popstate', popstateListener);
});
onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});
</script>
<style lang="less" scoped>
.operation-button{
	margin-top: 10px;
	display: flex;
	flex-direction: row;
}
</style>
