import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["AgGridVue"], {
    onGridReady: $setup.onReady,
    rowHeight: $setup.rowHeight,
    getRowId: $setup.getRowId,
    getDataPath: $setup.getDataPath,
    localeText: $setup.zhCN,
    rowData: $setup.data,
    treeData: "",
    domLayout: "autoHeight",
    columnDefs: $setup.columnDefs,
    suppressDragLeaveHidesColumns: $setup.smallMeta,
    autoGroupColumnDef: $setup.autoGroupColumnDef
  }, null, 8 /* PROPS */, ["rowHeight", "localeText", "rowData", "columnDefs", "suppressDragLeaveHidesColumns", "autoGroupColumnDef"]))
}