import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a765b1d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "instruction-wrapper" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent($setup.InstructionComponent), {
      instructionName: $props.instructionName,
      editable: $props.editable,
      referenceType: $props.referenceType,
      referenceName: $props.referenceName,
      mode: $props.mode,
      emptyText: $setup.props.options?.emptyText,
      addButtonText: $setup.props.options?.addButtonText
    }, null, 8 /* PROPS */, ["instructionName", "editable", "referenceType", "referenceName", "mode", "emptyText", "addButtonText"]))
  ]))
}