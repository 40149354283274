import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$props.loading)
      ? (_openBlock(), _createBlock($setup["AgGridVue"], {
          key: 0,
          class: "ag-theme-guigu table",
          style: {"width":"100%","margin":"0","height":"95%","overflow":"auto"},
          treeData: "",
          rowSelection: "multiple",
          rowData: $props.dataList,
          autoGroupColumnDef: $setup.autoGroupColumnDef,
          columnDefs: $setup.columnDefs,
          defaultColDef: $setup.defaultColDef,
          onGridReady: $setup.onGridReady,
          getDataPath: $setup.getDataPath,
          getRowId: $setup.getRowId,
          getRowHeight: $setup.getRowHeight,
          suppressDragLeaveHidesColumns: $props.smallMeta,
          localeText: $setup.zhCN,
          onSelectionChanged: $setup.onSelectionChanged
        }, null, 8 /* PROPS */, ["rowData", "autoGroupColumnDef", "columnDefs", "defaultColDef", "suppressDragLeaveHidesColumns", "localeText"]))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["CancelDialog"], {
      dialogVisible: $setup.dialogVisible,
      "onUpdate:dialogVisible": _cache[0] || (_cache[0] = $event => (($setup.dialogVisible) = $event)),
      currentData: $setup.currentData,
      onInapplicability: $setup.inapplicability
    }, null, 8 /* PROPS */, ["dialogVisible", "currentData"])
  ], 64 /* STABLE_FRAGMENT */))
}