<!-- 工作清单任务模板 -->
<template>
	<div v-loading="deleteLoading">
		<TaskTemplateTable
			:tableData="tableData"
			:loading="loading"
			:smallMeta="smallMeta"
			@getSelectRows="getSelectRows">
		</TaskTemplateTable>
		<div class="operation-button">
			<ElButton
				v-if="!props.frm.is_new()"
				type="primary"
				@click="createTaskTemplate"
				size="small"
			>
				{{ tt('new') }}
			</ElButton>
			<ElPopover
				placement="bottom"
				:visible="visible"
				showArrow
				trigger="click"
			>
				<p>{{ tt("Are you sure you want to disable it") }}?</p>
				<div style="display: flex;">
					<ElButton size="small" @click="visible = false">
						{{ tt("Cancel") }}
					</ElButton>
					<ElButton size="small" type="primary" @click="disableTemplate">
						{{ tt("Sure") }}
					</ElButton>
				</div>
				<template #reference>
					<ElButton
						v-if="!props.frm.is_new() && canBeDelete && selectData.length > 0"
						type="danger"
						size="small"
						@click="visible = true"
					>
						{{ tt('Disable') }}
					</ElButton>
				</template>
			</ElPopover>
		</div>
	</div>
</template>

<script lang="ts" setup>
import {onMounted, ref, defineProps, onUnmounted} from 'vue';
import {ElButton, ElMessage, vLoading, ElPopover} from 'element-plus';

import TaskTemplateTable from '../component/TaskTemplateTable.vue';
import type {TaskTemplate} from '../../../../../../types';
import {useMetaQuery} from '../../../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';
const tt = __;

const props = defineProps<{
	frm: any
}>();
const tableData = ref<TaskTemplate[]>([]);
const loading = ref<boolean>(false);
const canBeDelete = ref<boolean>(false);
const smallMeta = useMetaQuery();
const selectData = ref<TaskTemplate[]>([]);
const deleteLoading = ref<boolean>(false);
const visible = ref<boolean>(false);

function createTaskTemplate() {
	const doctype = 'Dw Task Template';
	const docName = frappe.model.make_new_doc_and_get_name(doctype, true);
	const docDetail = locals[doctype][docName] as locals.Doctype;
	docDetail.reference_type = 'Dw Standard Worklist';
	docDetail.reference_name = props.frm.docname;
	frappe.set_route('Form', doctype, docDetail.name);
}

// 获取一级任务模板列表;
async function getTableData() {
	loading.value = true;
	const res = await frappe.call<{ message: TaskTemplate[]}>({
		method: 'dw_worklist_management.dw_task_management.doctype.dw_task_template.dw_task_template.get_root_task_template',
		args: {
			name: props.frm.docname,
		},
	});
	if (res?.message && res.message.length > 0) {
		tableData.value = res.message;
	} else {
		tableData.value = [];
	}
	loading.value = false;
}

async function isManager() {
	const managers = await frappe.db.get_list('Dw Process Manager', {
		fields: ['user'],
		limit: 0,
	});
	return managers.some(item => item.user === frappe.session.user);
}

async function getFormEditable() {
	if (frappe.session.user === 'Administrator' || frappe.user.has_role('System Manager')) {
		return true;
	}

	if (props.frm.doc.scope === 'Global') {
		return isManager();
	}
	return false;
}

function getSelectRows(rows: TaskTemplate[]) {
	selectData.value = rows;
}

async function disableTemplate() {
	deleteLoading.value = true;
	const allTemplate = [];
	visible.value = false;
	if (selectData.value.length) {
		for (const row of selectData.value) {
			const templateList = await frappe.db.get_list(
				'Dw Task Template',
				{
					filters: [
						['reference_type', '=', 'Dw Standard Worklist'],
						['reference_name', '=', props.frm.docname],
						['root_id', '=', row.root_id],
						['lft', '>=', row.lft],
						['rgt', '<=', row.rgt],
					],
					fields: ['name'],
					limit: 0,
				},
			);
			if (templateList.length > 0) {
				allTemplate.push(...templateList);
			}
		}
		if (allTemplate.length > 0) {
			for (const template of allTemplate) {
				await frappe.db.set_value(
					'Dw Task Template',
					template.name,
					{
						disable: 1,
					},
				);
			}
			ElMessage({
				type: 'success',
				message: __('Execution Succeeded'),
			});
			getTableData();
			selectData.value = [];
		}
		deleteLoading.value = false;
	}
}

async function setPermission() {
	canBeDelete.value = await getFormEditable();
}

const popstateListener = function () {
	getTableData();
};

onMounted(() => {
	getTableData();
	setPermission();
	window.addEventListener('popstate', popstateListener);
});

onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});

</script>


<style lang="less" scoped>
.operation-button{
	margin-top: 10px;
	display: flex;
	flex-direction: row;
}
</style>
