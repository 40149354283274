<template>
	<ElDialog v-model="visible" :title="translate('Filter By')" modal width="max(min(400px, 95%), 50%)">
		<div class="filterContainer">
			<div class="at">{{ translate('When') }}</div>
			<div ref="root" :class="['root', 'filter']" />
		</div>
		<div ref="or" class="root" />
		<template #footer>
			<ElButtonGroup>
				<ElButton @click="visible = false" :title="translate('Cancel')" >{{ translate('Cancel') }}</ElButton>
				<ElButton type="primary" @click="submit" :title="translate('Confirm')">{{ translate('Confirm') }}</ElButton>
			</ElButtonGroup>
		</template>
	</ElDialog>
</template>
<script lang="ts" setup>
import {computed, watch, shallowRef} from 'vue';
import {ElDialog, ElButton, ElButtonGroup} from 'element-plus';

import {Filter} from '../types';
import MultiOrFilters from '../../utils/MultiOrFilters';


const translate = __;
const root = shallowRef();
const or = shallowRef();
const props = defineProps<{
	meta: locals.DocType;
	modelValue?: Filter[];
	or?: Filter[][];
	visible: boolean;
	withSession?: boolean;
}>();
const emit = defineEmits<{
	(event: 'update:or', filters: Filter[][]): void;
	(event: 'update:modelValue', filters: Filter[]): void;
	(event: 'update:visible', visible: boolean): void;
}>();

let orEditor: MultiOrFilters | undefined;
let editor: any;
function initOrValue() {
	if (!orEditor) {
		return;
	}
	try {
		const list = (props.or || []).map(v => v.map(({
			doctype, field, condition, value,
		}) => [
			doctype, field, condition, value,
		] as [string, string, string, any]));
		orEditor.value = list;
	} catch {
		return [];
	}
}
function initValue() {
	if (!editor) {
		return;
	}
	editor.clear_filters();
	try {
		const list = (props.modelValue || []).map(({
			doctype, field, condition, value,
		}) => [
			doctype, field, condition, value, false,
		]);
		editor.add_filters_to_filter_group(list);
	} catch {
		return [];
	}
}
const visible = computed({
	get() {
		return props.visible;
	},
	set(v) {
		emit('update:visible', v);
	},
});
watch([() => props.meta.name, or, visible], ([dt, el, visible]) => {
	if (!visible) {
		return;
	}
	if (orEditor) {
		// TODO:
	}
	if (!el) {
		return;
	}
	el.innerHTML = '';
	if (!dt) {
		return;
	}
	const current = new MultiOrFilters(el, dt);
	orEditor = current;
	frappe.model.with_doctype(dt, initOrValue);
}, {immediate: true});
watch([() => props.meta.name, root, visible], ([dt, el, visible]) => {
	if (!visible) {
		return;
	}
	if (editor) {
		// TODO:
	}
	if (!el) {
		return;
	}
	el.innerHTML = '';
	if (!dt) {
		return;
	}
	const current = new frappe.ui.FilterGroup({
		parent: $(el),
		doctype: dt,
		on_change: () => { },
		base_list: [] as any,
	});
	editor = current;
	frappe.model.with_doctype(dt, initValue);
}, {immediate: true});

function toFilters(filters: [string, string, string, any, any?][]): Filter[] {
	if (!Array.isArray(filters)) {
		return [];
	}
	try {
		return filters.map(([
			doctype, field, condition, value,
		]) => ({
			doctype, field, condition, value,
		}));
	} catch {
		return [];
	}
}
function submit() {
	if (editor) {
		const filters = editor.get_filters();
		emit('update:modelValue', toFilters(filters));
	}
	if (orEditor) {
		const orFilters = orEditor.value;
		emit('update:or', orFilters.map(v => toFilters(v)));
	}
	visible.value = false;
}
</script>
<style scoped lang="less">
.filterContainer{
	display: flex;
	align-items: center;
}
.filter{
	border:1px solid #ccc;
	border-radius: 4px;
	padding: 8px;
}
.at{
	padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-inline-end: 8px;
}
.root {
	width: 100%;

	:global(.filter-area .filter-box .awesomplete ul[role=listbox] li) {
		max-width: unset;
	}
}
</style>
../../utils/MultiOrFilters
