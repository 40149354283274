import * as XLSX from 'xlsx-js-style';
import Handsontable from 'handsontable';

import createXLSXSheet from './createXLSXSheet.mjs';

function* getXY(width: number, height: number): Iterable<[number, number]> {
	for (let x = 0; x < width; x++) {
		for (let y = 0; y < height; y++) {
			yield [x, y];
		}
	}
}

function isType(val?: string): val is 'text' | 'numeric' {
	if (!val) {
		return false;
	}
	return ['text', 'numeric'].includes(val);
}

function getStyles(hot: Handsontable, width: number, height: number) {
	const styles: TemplateStyle[][] = [];
	for (const [x, y] of getXY(width, height)) {
		try {
			const {
				row, col,
				bold, color, bgColor, italic, underline, className, fontSize,
				type, readOnly,
			} = hot.getCellMeta(y, x);
			while (styles.length <= row) {
				styles.push([]);
			}
			const style: TemplateStyle = {};
			if (bold) {
				style.bold = 1;
			}
			if (italic) {
				style.italic = 1;
			}
			if (underline) {
				style.underline = 1;
			}
			if (color) {
				style.color = color;
			}
			if (bgColor) {
				style.bgColor = bgColor;
			}
			if (fontSize) {
				style.fontSize = fontSize;
			}
			const cname = (
				Array.isArray(className) ? className.join(' ') : className || ''
			).split(' ');
			if (cname.includes('htLeft')) {
				style.left = 1;
			} else if (cname.includes('htCenter')) {
				style.center = 1;
			} else if (cname.includes('htRight')) {
				style.right = 1;
			} else if (cname.includes('htJustify')) {
				style.justify = 1;
			}
			if (cname.includes('htTop')) {
				style.top = 1;
			} else if (cname.includes('htMiddle')) {
				style.middle = 1;
			} else if (cname.includes('htBottom')) {
				style.bottom = 1;
			}
			if (isType(type)) {
				style.type = type;
			}
			if (readOnly) {
				style.readOnly = 1;
			}
			styles[row][col] = style;
		} catch (e) {
			console.error(e);
		}
	}
	return styles;
}

export default function readValue(
	handsontable: Handsontable,
	hasValue?: boolean,
): Template {
	const settings = handsontable.getSettings();
	const {columns} = settings;
	const data = handsontable.getSourceData().map(d=>columns?.map(col=>d[col.data]));
	const maxCol = data.reduce((m, v) => Math.max(m, v.length), 0);
	const maxRow = data.length;
	return {
		data,
		value: hasValue && handsontable.getData().map((v: any) => [...v as any]) || undefined,
		freezeRow: settings.fixedRowsTop,
		styles: getStyles(handsontable, maxCol, maxRow),
		freezeCol: settings.fixedColumnsStart,
		widths: Array(maxCol).fill(0).map((_, i) => handsontable.getColWidth(i)),
		heights: Array(maxRow).fill(0).map((_, i) => handsontable.getRowHeight(i)),
	};
}

export function exportFile(hatable: any, name: string) {
	const value = readValue(hatable, true);
	const wb = XLSX.utils.book_new();
	const ws = createXLSXSheet(value);
	XLSX.utils.book_append_sheet(wb, ws, name);

	const fileName = name;
	XLSX.writeFile(wb, `${fileName || 'Data'}.xlsx`);
}
