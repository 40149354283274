import type {
	RowEmit,
	RowListen,
	EmitOption,
	Listener,
	EventContext
} from "../types";

function getRow(
	events: Map<string | number, Map<string | symbol, Set<(v: any, ctx: EventContext) => void>>>,
	row: string | number,
) {
	let rowEvents = events.get(row);
	if (rowEvents) { return rowEvents }
	rowEvents = new Map();
	events.set(row, rowEvents)
	return rowEvents;

}
function get(
	rowEvents: Map<string | symbol, Set<(v: any, ctx: EventContext) => void>>,
	key: any,
) {
	let set = rowEvents.get(key);
	if (set) { return set; }
	set = new Set();
	rowEvents.set(key, set);
	return set;
}
export function createListenRow<T extends object>(
	events: Map<string | number, Map<string | symbol, Set<(v: any, ctx: EventContext) => void>>>,
): RowListen<T> {
	return (row: string | number, key: any, fn: Listener<any>) => {
		const rowEvents = getRow(events, row);
		const set = get(rowEvents, key);
		const f: (v: any, ctx: EventContext) => void = (...v) => fn(...v);
		set.add(f);
		return () => {
			set.delete(f);
			if (set.size) { return; }
			rowEvents.delete(key)
			if (rowEvents.size) { return; }
			events.delete(row)
		};
	};
}

export function createEmitRow<T extends object>(
	events: Map<string | number, Map<string | symbol, Set<(v: any, ctx: EventContext) => void>>>,
): RowEmit<T> {
	return (row: string | number, key: any, value: any, opt?: EmitOption) => {
		const set = events.get(row)?.get(key);
		if (!set) { return true; }

		let prevented = true;
		let stop = false;
		const cancelable = Boolean(opt?.cancelable);
		const ctx: EventContext = {
			stop() { stop = true; },
			prevent() { prevented = true; },
			get cancelable() { return cancelable; },
			get prevented() { return prevented; },
		};
		for (const f of [...set]) {
			f(value, ctx);
			if (stop) { break; }
		}
		return !prevented;
	};
}
