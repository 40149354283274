<template>
	<div class="link-field ui-front" :style="{position: 'relative'}">
		<input :class="[inputClass, placeHolderColorClass]" :value="valueTitle || '请选择'"
			@click="showDialog" />
		<span v-if="linkRef" class="link-btn">
			<a :href="linkRef" class="btn-open no-decoration"
				:title="tt('Open Link')">
				<svg class="icon  icon-xs">
					<use class="" href="#icon-arrow-right"></use>
				</svg>
			</a>
		</span>
		<TreeSelectDialog
			v-if="doctypeMeta"
			v-model:visible="dialogVisible"
			:doctypeMeta="doctypeMeta"
			:treeGroupField="treeGroupField"
			:originSelectedList="value"
			:queries="queries"
			:df="df"
			:treeControl="treeControl"
			@change="confirm"></TreeSelectDialog>
	</div>
</template>

<script setup lang='ts'>
import {ref, watch, computed, defineExpose} from 'vue';

import {DF, FormatData, TreeItem} from './type';
import TreeSelectDialog from './TreeSelectDialog.vue';
const tt = window.__;
const {icon} = frappe.utils;
interface Props {
	value: string,
	df: DF,
	queries: any
	treeControl: any
	inputClass: string
	frm:any
}

const props = defineProps<Props>();
interface Emit {
	(e: 'change', data: string): void
	(e: 'focus'): void
}
const emit = defineEmits<Emit>();
defineExpose({
	refresh,
});
const dialogVisible = ref<boolean>(false);
const valueTitle = ref<string>('');
const treeGroupField = ref<string>('');
const doctypeMeta = ref<locals.DocType>();
const placeHolderColorClass = computed(() => {
	if (!valueTitle.value) {
		return 'tree-input-color';
	}
	return '';
});

const linkRef = computed(()=>{
	const doctype = props.df.options;
	const name = props.value;
	if (!doctype || !name) {
		return '';
	}
	return frappe.utils.get_form_link(doctype, name);
});
function refresh() {
	dialogVisible.value = false;
	getValueTitle();
}
watch(() => props.value, async () => {
	getValueTitle();
});
async function getValueTitle() {
	if (!props.df.options || !props.value) {
		valueTitle.value = '';
		return;
	}
	if (props.treeControl.get_link_title && typeof props.treeControl.get_link_title === 'function') {
		const link_title = await props.treeControl.get_link_title(props.value);
		valueTitle.value = link_title || '';
		return;
	}
	try {
		const res = await frappe.call<{ message: string }>({
			method: 'guigu.guigu_controls.tree_select.get_value_info',
			args: {
				doctype: props.df.options,
				name: props.value,
			},
		});
		valueTitle.value = res?.message || '';
	} catch {
		valueTitle.value = props.value;
	}
}
watch(() => props.df.options, () => {
	getTreeGroupField();
	getLinkMeta(props.df.options);
}, {immediate: true});

async function getLinkMeta(doctype: string) {
	if (!doctype) {
		return;
	}
	let local_meta = frappe.get_meta(doctype);
	if (local_meta) {
		doctypeMeta.value = local_meta;
		return;
	}
	await frappe.model.with_doctype(doctype);
	local_meta = frappe.get_meta(doctype);
	doctypeMeta.value = local_meta || undefined;
}
async function getTreeGroupField() {
	if (!props.df.options) {
		return;
	}
	frappe.call({
		method: 'guigu.guigu_controls.tree_select.get_tree_select_tree_group_field',
		args: {
			doctype: props.df.options,
		},
		callback(data: { message: string }) {
			treeGroupField.value = data?.message || '';
		},
	});
}
function confirm(selected: string, label?:string) {
	dialogVisible.value = false;
	emit('change', selected, label);
}

function showDialog() {
	if (!doctypeMeta.value) {
		return;
	}
	dialogVisible.value = true;
	emit('focus');
}
</script>

<style lang='less' scoped>
.tree-select {
	width: 100%;
	display: inline-block;
	cursor: pointer;
}

.search-container {
	display: flex;

	.search-button {
		margin-left: 8px;
	}
}

.tree {
	:deep(label) {
		margin-bottom: 0px;
	}
}

.tree-container {
	max-height: 500px;
	overflow-y: scroll;
}

.link-btn {
	display: inline;
}

.tree-input-color {
	color:#525252 !important;
}
</style>
