<template>
	<ElFormItem :label="tt('Number Of Fields Fixed')">
		<ElInputNumber v-model="fixedFieldColumns" />
	</ElFormItem>
	<ElFormItem :label="tt('Line Height')">
		<ElInputNumber v-model="lineHeight" />
	</ElFormItem>
	<ElFormItem :label="tt('Default Expand Level')">
		<ElInputNumber v-model="expandedDefault" mix="0" />
	</ElFormItem>
	<ElFormItem>
		<ElCheckbox v-model="showSummaryRow" :label="tt('Show Summary Rows')" />
	</ElFormItem>
	<ElFormItem>
		<ElCheckbox v-model="tree" :label="tt('Display In A Tree Structure')" />
	</ElFormItem>
	<ElFormItem v-if="!tree">
		<ElCheckbox v-model="showLineNumber" :label="tt('Display Line Number')" />
	</ElFormItem>
	<ElFormItem :label="tt('Text Color')">
		<ElColorPicker v-model="textColor" />
	</ElFormItem>
	<ElFormItem :label="tt('Background Color')">
		<ElColorPicker v-model="bgColor" />
	</ElFormItem>
	<ElFormItem :label="tt('Even Line Background Color')">
		<ElColorPicker v-model="oddBgColor" />
	</ElFormItem>
	<ElFormItem :label="tt('Horizontal Table Line Color')">
		<ElColorPicker v-model="rowLineColor" />
	</ElFormItem>
	<ElFormItem :label="tt('Vertical Table Line Color')">
		<ElColorPicker v-model="colLineColor" />
	</ElFormItem>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import {ElInputNumber, ElFormItem, ElCheckbox, ElColorPicker} from 'element-plus';
const props = defineProps<{
	meta: locals.DocType;
	name?: string;
	type: string;
	modelValue: any;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: any): void;
}>();
const cfg = computed({
	get: () => props.modelValue,
	set: v => emit('update:modelValue', v),
});
const tt = __;

const fixedFieldColumns = computed({
	get: () => cfg.value.fixed_field_columns || 0,
	set: v => {
		cfg.value = {...cfg.value, fixed_field_columns: v || 0};
	},
});
const lineHeight = computed({
	get: () => cfg.value.line_height || 0,
	set: v => {
		cfg.value = {...cfg.value, line_height: v || 0};
	},
});
const expandedDefault = computed({
	get: () => cfg.value.expanded_default || 0,
	set: v => {
		cfg.value = {...cfg.value, expanded_default: v || 0};
	},
});
const tree = computed({
	get: () => Boolean(cfg.value.tree),
	set: v => {
		cfg.value = {...cfg.value, tree: v ? 1 : 0};
	},
});
const showLineNumber = computed({
	get: () => Boolean(cfg.value.show_line_number),
	set: v => {
		cfg.value = {...cfg.value, show_line_number: v ? 1 : 0};
	},
});
const showSummaryRow = computed({
	get: () => Boolean(cfg.value.show_summary_row),
	set: v => {
		cfg.value = {...cfg.value, show_summary_row: v ? 1 : 0};
	},
});
const textColor = computed({
	get: () => cfg.value.text_color || '',
	set: v => {
		cfg.value = {...cfg.value, text_color: v};
	},
});

const bgColor = computed({
	get: () => cfg.value.bg_color || '',
	set: v => {
		cfg.value = {...cfg.value, bg_color: v};
	},
});

const oddBgColor = computed({
	get: () => cfg.value.odd_bg_color || '',
	set: v => {
		cfg.value = {...cfg.value, odd_bg_color: v};
	},
});
const rowLineColor = computed({
	get: () => cfg.value.row_line_color || '',
	set: v => {
		cfg.value = {...cfg.value, row_line_color: v};
	},
});
const colLineColor = computed({
	get: () => cfg.value.col_line_color || '',
	set: v => {
		cfg.value = {...cfg.value, col_line_color: v};
	},
});
</script>
