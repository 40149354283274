frappe.ui.form.ControlLink.prototype.validate_link_and_fetch = function(value){
	const options = this.get_options();
	if (!options) {
		return;
	}

	const columns_to_fetch = Object.values(this.fetch_map);

	// if default and no fetch, no need to validate
	if (!columns_to_fetch.length && this.df.__default_value === value) {
		return value;
	}

	const update_dependant_fields = response => {
		let field_value = '';
		for (const [target_field, source_field] of Object.entries(this.fetch_map)) {
			if (value) {
				field_value = response[source_field];
			}
			frappe.model.set_value(
				this.df.parent,
				this.docname,
				target_field,
				field_value,
				this.df.fieldtype,
			);
		}
	};

	// to avoid unnecessary request
	if (value) {
		return frappe
			.xcall('frappe.client.validate_link', {
				doctype: options,
				docname: value,
				fields: columns_to_fetch,
			})
			.then(async response => {
				if (!this.docname || !columns_to_fetch.length) {
					return response.name;
				}
				const {message:link_titles} = await frappe.call({
					method:'guigu.controllers.get_link_titles.get_titles',
					args:{
						doctype: options,
						fields: columns_to_fetch,
						values:response,
					},
				});
				if (link_titles) { for (const item of link_titles) {
					frappe._link_titles[`${item.doctype}::${item.name}`] = item.title;
				} }

				update_dependant_fields(response);
				return response.name;
			});
	}
	update_dependant_fields({});
	return value;
};
