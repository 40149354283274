<template>
  <div class="action_container"
    :style="{display:'flex', justifyContent:'space-around', alignItems:'center'}">
    <ElTooltip
      :content="tt('Edit')"
      placement="top"
      v-if="props.params.onEdit">
      <ElIcon color="#337ecc" size="12" @click="editClick">
        <Edit />
      </ElIcon>
    </ElTooltip>
    <ElPopconfirm
      v-if="props.params.onRemove"
      :title="tt('Are you sure you want to delete it?')"
      @confirm="click"
      :confirm-button-text="tt('Confirm')"
      :cancel-button-text="tt('Cancel')">
      <template #reference>
        <div :style="{ 'margin': '0' }">
          <ElTooltip :content="tt('Delete')" placement="top">
            <ElIcon color="#c45656" size="12">
              <Delete />
            </ElIcon>
          </ElTooltip>
        </div>
      </template>
    </ElPopconfirm>
  </div>
</template>
<script lang="ts" setup>
import { Delete, Edit } from '@element-plus/icons-vue';
import { defineProps} from 'vue';
const tt = __
const props = defineProps<{params:any}>();

function click(){
	props.params.onRemove(props.params.data);
}
function editClick(){
  props.params.onEdit(props.params.data);
}
</script>
<style scoped lang="less">
.action_container {
  display: flex;
  height: 100%;
  justify-content: space-around;
  align-items: center;
}
</style>
