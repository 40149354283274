import type {
  ColDef,
  ColGroupDef,
  ICellRendererParams,
} from 'ag-grid-community';

export default function getMachineColumns(
  enableAudit: boolean
) {
  const auditColumn = enableAudit
  ? [
      {
        headerName: __("Audit volume"),
        field: 'quantity',
        width: 140,
        sortable: false,
        editable: false,
        enableRowGroup: false,
        filter: false,
        aggFunc: 'sum',
        cellRenderer: 'Integer',
        cellRendererParams: (params: ICellRendererParams) => {
          const { value } = params;
          return {
            value: value == 0 ? null : value,
          };
        },
      },
    ]
  : [];
  const initColumns: (ColDef | ColGroupDef)[] = [
    {
      headerName: __("Machine Type"),
      field: 'machine_type_name',
      lockPinned: true,
      pinned: 'left',
      width: 150,
      sortable: false,
      editable: false,
      filter: false,
      enableRowGroup: false,
    },
    {
      headerName: __('Planned Quantity'),
      field: 'today_plan_quantity',
      width: 140,
      sortable: false,
      editable: false,
      filter: false,
      enableRowGroup: false,
      aggFunc: 'sum',
      cellRenderer: 'Integer',
      cellRendererParams: (params: ICellRendererParams) => {
        const { value } = params;
        return {
          value: value == 0 ? null : value,
        };
      },
    },
    {
      headerName: __('Actual Quantity'),
      field: enableAudit?'report_quantity':'quantity',
      width: 140,
      sortable: false,
      editable: false,
      enableRowGroup: false,
      filter: false,
      aggFunc: 'sum',
      cellRenderer: 'Integer',
      cellRendererParams: (params: ICellRendererParams) => {
        const { value } = params;
        return {
          value: value == 0 ? null : value,
        };
      },
    },
    ...auditColumn,
  ];
  initColumns.push(
    {
      headerName: __('Tomorrow Plan Quantity'),
      field: 'tomorrow_plan_quantity',
      sortable: false,
      editable: false,
      width: 140,
      filter: false,
      enableRowGroup: false,
      aggFunc: 'sum',
      cellRenderer: 'Integer',
      cellRendererParams: (params: ICellRendererParams) => {
        const { value } = params;
        return {
          value: value == 0 ? null : value,
        };
      },
    },
    {
      headerName: __('Remark'),
      field: 'remark',
      sortable: false,
      editable: false,
      minWidth: 300,
      flex: 1,
      filter: false,
      enableRowGroup: false,
      cellRenderer: 'RemarkCellRender',
      cellRendererParams: (params: ICellRendererParams) => {
        const { value } = params;
        return {
          value,
        };
      },
    },
  );
  return initColumns;
}
