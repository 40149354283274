import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8f67adf0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["height", "width"]
const _hoisted_2 = ["stroke-dasharray", "stroke-width", "r", "cx", "cy"]
const _hoisted_3 = ["stroke-dasharray", "stroke-width", "r", "cx", "cy"]
const _hoisted_4 = ["x", "y"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("svg", {
    height: $props.radius * 2,
    width: $props.radius * 2
  }, [
    _createElementVNode("circle", {
      "stroke-dasharray": $setup.circumference + ' ' + $setup.circumference,
      style: _normalizeStyle({
				stroke: $props.secondary,
				strokeDashoffset: 0
			}),
      "stroke-width": $props.stroke,
      fill: "transparent",
      r: $setup.normalizedRadius,
      cx: $props.radius,
      cy: $props.radius
    }, null, 12 /* STYLE, PROPS */, _hoisted_2),
    _createElementVNode("circle", {
      "stroke-dasharray": $setup.circumference + ' ' + $setup.circumference,
      style: _normalizeStyle({ stroke: $props.primary, strokeDashoffset: $setup.strokeDashoffset }),
      "stroke-width": $props.stroke,
      fill: "transparent",
      r: $setup.normalizedRadius,
      cx: $props.radius,
      cy: $props.radius
    }, null, 12 /* STYLE, PROPS */, _hoisted_3),
    _createElementVNode("text", {
      "dominant-baseline": "middle",
      "text-anchor": "middle",
      x: $props.radius,
      y: $props.radius,
      style: _normalizeStyle({
				color: 'var(--text-color)',
				fontSize: 'var(--text-xs)',
				fontWeight: 'var(--text-bold)'
			})
    }, _toDisplayString($props.progress) + "% ", 13 /* TEXT, STYLE, PROPS */, _hoisted_4)
  ], 8 /* PROPS */, _hoisted_1))
}