import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51efc4a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: { 'margin': '0', display:'flex' } }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (!$setup.hideAction)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "action_container",
        style: $setup.containerStyle
      }, [
        ($setup.props.params.onEdit)
          ? (_openBlock(), _createBlock($setup["ElTooltip"], {
              key: 0,
              content: $setup.tt('Edit')
            }, {
              default: _withCtx(() => [
                _createVNode($setup["ElButton"], {
                  style: { 'margin': '0', display:'flex' },
                  icon: $setup.Edit,
                  onClick: $setup.edit
                }, null, 8 /* PROPS */, ["icon"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["content"]))
          : _createCommentVNode("v-if", true),
        ($setup.props.params.unRelate)
          ? (_openBlock(), _createBlock($setup["ElTooltip"], {
              key: 1,
              content: $setup.tt('UnRelate')
            }, {
              default: _withCtx(() => [
                _createVNode($setup["ElButton"], {
                  style: { 'margin': '0', display:'flex' },
                  icon: $setup.Link,
                  onClick: $setup.unRelate
                }, null, 8 /* PROPS */, ["icon"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["content"]))
          : _createCommentVNode("v-if", true),
        ($setup.props.params.onRemove)
          ? (_openBlock(), _createBlock($setup["ElPopconfirm"], {
              key: 2,
              title: $setup.tt('Are you sure you want to directly delete this data?'),
              onConfirm: $setup.deleteRow,
              confirmButtonText: $setup.tt('Confirm'),
              cancelButtonText: $setup.tt('Cancel')
            }, {
              reference: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode($setup["ElTooltip"], {
                    content: $setup.tt('Delete')
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ElButton"], null, {
                        default: _withCtx(() => [
                          _createVNode($setup["ElIcon"], null, {
                            default: _withCtx(() => [
                              _createVNode($setup["Delete"], { color: "#E24C4C" })
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["content"])
                ])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["title", "confirmButtonText", "cancelButtonText"]))
          : _createCommentVNode("v-if", true)
      ]))
    : _createCommentVNode("v-if", true)
}