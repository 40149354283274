const linkTypes = ['Link', 'Tree Select', 'Tianjy Related Link'];


export default async function loadLinkDocTypes(meta: locals.DocType) {
	const linkDocTypes = meta.fields
		.map(df => (linkTypes.includes(df.fieldtype) ? df.options as string : ''))
		.filter(Boolean);

	await Promise.all([...new Set(linkDocTypes)].map(doctype => new Promise<void>(resolve => {
		frappe.model.with_doctype(doctype, () => {
			resolve();
		}, true);
	})));
}
