<template>
	<AgGridVue
		v-if="!loading"
		class="ag-theme-guigu table"
		style="width: 100%;margin: 0;height: 95%; overflow: auto;"
		treeData
		rowSelection="multiple"
		:rowData="dataList"
		:autoGroupColumnDef="autoGroupColumnDef"
		:columnDefs="columnDefs"
		:defaultColDef="defaultColDef"
		@grid-ready="onGridReady"
		:getDataPath="getDataPath"
		:getRowId="getRowId"
		:getRowHeight="getRowHeight"
		:suppressDragLeaveHidesColumns="smallMeta"
		:localeText="zhCN"
		@selection-changed="onSelectionChanged"
	></AgGridVue>
	<CancelDialog
		v-model:dialogVisible="dialogVisible"
		:currentData="currentData"
		@inapplicability="inapplicability"
	></CancelDialog>
</template>
<script setup lang="ts">
import {onBeforeMount, onMounted, ref, watch} from 'vue';
import {AgGridVue} from 'ag-grid-vue3';
import type {CellClassParams, ValueFormatterParams, ColDef, GridApi, ColGroupDef} from 'ag-grid-community';

import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';
import type {LevelStyle} from '../../dw_standard_process_library_list/components/type';
import type {Worklist} from '../type';
import type {process} from '../../../../types';

import WorkList from './WorkList.vue';
import InapplicabilityButton from './InapplicabilityButton.vue';
import CancelDialog from './CancelDialog.vue';
import {processLevel} from './helper';
const props = defineProps<{
	dataList: Worklist[];
	loading: boolean;
	smallMeta: boolean;
	processes: process[];
	hasPermission: boolean;
}>();
const emit = defineEmits<{
	(e: 'openDetail', name: string, isProcess: boolean): void;
	(e: 'changeApplyStatus', visible: boolean): void;
	(e: 'cancelUse', data: Worklist | undefined): boolean;
	(e: 'deleteWorklist', data: Worklist): void;
}>();


const gridApi = ref<GridApi>();
const separator = ref<string>('.');
const dialogVisible = ref<boolean>(false);
const levelList = ref<LevelStyle[]>([]);
const currentData = ref<Worklist | undefined>();
const selectedRows = ref<Worklist[]>([]);
const defaultColDef = ref({
	filter: true,
	resizable: true,
	cellDataType: false,
});
function getDefaultDef(styleArray:LevelStyle[]) {
	return {
		filter: true,
		resizable: true,
		cellDataType: false,
		cellStyle: (params: CellClassParams) => {
			const levelStyle = styleArray.find(item => item.level === processLevel[params.data?.level]);
			const {row_background_color = '', font_color = '', is_bold = false} = levelStyle || {};
			if (!params.data.is_work_list) {
				return {
					display: 'grid',
					alignItems: 'center',
					backgroundColor: row_background_color,
					color: font_color,
					fontWeight: is_bold ? 'bold' : 'normal',
				};
			}
		},
	};
}

/**
 * 点击流程或者工作清单label时根据布局的方式查看详情
 * @param name 流程/工作清单的name
 */
function viewDetail(name: string, isProcess: boolean) {
	emit('openDetail', name, isProcess);
}
function deleteWorklist(data: Worklist) {
	emit('deleteWorklist', data);
}
const autoGroupColumnDef = ref<ColGroupDef|ColDef>();
const columnDefs = ref<ColDef[]>([
	{
		headerName: __('Organizational Unit Self Build Work List'),
		field: 'scope',
		editable: false,
		filter: false,
		flex: 1,
		minWidth: 140,
		valueFormatter: (params:ValueFormatterParams) => {
			let showString = '';
			if (params.data.scope && params.data.scope !== 'Global') {
				showString = __('Yes');
			}
			return showString;
		},
	},
	{
		headerName: __('Applicable'),
		field: 'applicable',
		editable: false,
		filter: false,
		flex: 1,
		minWidth: 70,
		valueFormatter: (params:ValueFormatterParams) => {
			let showString = '';
			if (params.data.applicable) {
				showString = __('Yes');
			}
			return showString;
		},
	},
	{
		headerName: __('Last Updated On'),
		field: 'modified',
		editable: false,
		filter: false,
		flex: 1,
		minWidth: 120,
	},
	{
		headerName: __('Adjustment Instruction'),
		field: 'adjustment_instruction',
		editable: false,
		filter: false,
		flex: 1,
		minWidth: 200,
	},
]);

watch([levelList, () => props.processes, () => props.hasPermission, separator], () => {
	autoGroupColumnDef.value = {
		headerName: __('Work List Title'),
		field: 'title',
		editable: false,
		filter: false,
		flex: 3,
		minWidth: 250,
		checkboxSelection: true,
		cellRendererParams: {
			suppressCount: true,
			innerRenderer: WorkList,
			innerRendererParams: {
				levelList: levelList.value,
				hasPermission: props.hasPermission,
				separator: separator.value,
				processes: props.processes,
				viewDetail,
				deleteWorklist,
			},
		},
		sort: 'asc',
		comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
			const {full_process_code: codeA, is_process: isProcessA, full_code: workCodeA} = nodeA.data;
			const {full_process_code: codeB, is_process: isProcessB, full_code: workCodeB} = nodeB.data;
			if (isProcessA && isProcessB) {
				if (codeA === codeB) {
					return 0;
				}
				return (codeA > codeB) ? 1 : -1;
			}
			if (!isProcessA && !isProcessB) {
				if (workCodeA === workCodeB) {
					return 0;
				}
				return (workCodeA > workCodeB) ? 1 : -1;
			}
			if (isProcessB) {
				return 1;
			}
			return -1;
		},
	};
	defaultColDef.value = getDefaultDef(levelList.value);
});

watch(() => props.hasPermission, () => {
	columnDefs.value.push({
		headerName: __('Actions'),
		field: '',
		editable: false,
		filter: false,
		flex: 1,
		minWidth: 80,
		cellRenderer: InapplicabilityButton,
		cellRendererParams: {
			openNoticeDialog,
		},
	});
});
async function getLevelStyleList() {
	levelList.value = await frappe.db.get_list('Dw Process Level Style', {
		fields: ['level', 'row_background_color', 'row_height', 'font_color', 'is_bold', 'icon'],
		limit: 0,
	});
}
function onGridReady(params: CellClassParams) {
	gridApi.value = params.api;
}
function getRowId(params: CellClassParams) {
	return params.data.name;
}
function getDataPath(data: any) {
	return data.path;
}
function getRowHeight(params: CellClassParams) {
	if (!params.data.is_process) {
		return 30;
	}
	const style = levelList.value.find(item => item.level === processLevel[params.data?.level]);
	return style?.row_height || 30;
}

// 表格选中改变
function onSelectionChanged(event: any) {
	const currentSelectedRows = gridApi.value?.getSelectedRows();
	if (!['checkboxSelected', 'rowClicked'].includes(event.source)) {
		return;
	}
	// 记录当前选中的行
	const previouslySelectedRows = selectedRows.value;
	const previouslySelectedRowsPlain = previouslySelectedRows.map((row: Worklist) => ({...row}));
	const newlySelectedRows = currentSelectedRows!.filter((row: Worklist) => !previouslySelectedRowsPlain.includes(row));
	const deselectedRows = currentSelectedRows?.length === 0
		? [...previouslySelectedRowsPlain]
		: previouslySelectedRowsPlain?.filter((row: Worklist) =>
			!currentSelectedRows?.some((item: Worklist) => item.name === row.name));
	for (const item of newlySelectedRows) {
		toggleChildrenSelection(item, true);
	}
	for (const item of deselectedRows) {
		toggleChildrenSelection(item, false);
	}
	const SelectedRows = gridApi.value?.getSelectedRows();
	selectedRows.value = SelectedRows as Worklist[];
}

function isDescendantOf(parentRow: Worklist, descendantRow: Worklist) {
	// 检查 descendantRow 是否是 parentRow 的后代节点
	return parentRow.path.every((part, index) => part === descendantRow.path[index]);
}

function toggleChildrenSelection(parentRow: Worklist, select: boolean = true) {
	const roots = gridApi.value?.getModel().getRootNode().childrenAfterFilter;
	let allRows:any = [];
	if (roots.length > 0) {
		for (let index = 0; index < roots.length; index++) {
			if (roots[index].data.name === parentRow.path[0]) {
				allRows = roots[index].allLeafChildren;
			}
		}
	}
	for (const rowNode of allRows) {
		const row = rowNode.data;
		if (isDescendantOf(parentRow, row)) {
			rowNode.setSelected(select, false);
		}
	}
}

watch(() => selectedRows.value, newSelectedRows => {
	emit('changeApplyStatus', newSelectedRows.some(item=>!item.is_process));
});

// 打开取消适用的确认弹窗
function openNoticeDialog(name: string) {
	// 查找当前要取消适用的清单
	currentData.value = props.dataList.find(item => item.name === name);
	dialogVisible.value = true;
}

// 取消适用
async function inapplicability(data: Worklist | undefined) {
	dialogVisible.value = await emit('cancelUse', data);
}


// 清空选中行
function clearSelectRows() {
	selectedRows.value = [];
}
// 获取编码连接符
async function getSeparator() {
	separator.value = await frappe.db.get_single_value('Dw Process Separator Rule', 'separator') || '.';
}
onMounted(() => {
	getSeparator();
});
onBeforeMount(() => {
	getLevelStyleList();
});
defineExpose({
	gridApi,
	clearSelectRows,
});
</script>

<style lang="less" scoped>
.info {
	display: flex;
	flex-direction: column
}
:deep(.ag-cell-wrapper){
	min-width: 0;
}
</style>
