<template>
	<div class="root">
		<template v-if="params.data">
			<span v-if="aggregation">合计</span>
			<a :href="href" v-else @click="click" :title="value">{{ value }}</a>
		</template>
	</div>
</template>
<script lang="ts" setup>
import {computed} from 'vue';
import type {ICellRendererParams} from 'ag-grid-community';

import html2text from '../../../utils/html2text';
import useDocHref from '../../../utils/useDocHref';

const props = defineProps<{
	params: ICellRendererParams & {
		preventClick(e: Event): void
		getState(): {
			meta: locals.DocType,
			titleField: locals.DocField | null;
			linkField?: string
		}
	}
}>();
const state = props.params.getState();
const meta = computed(() => state.meta);

function click(e: Event) {
	props.params.preventClick(e);
}

const data = computed(() => props.params.data);

const aggregation = computed(() => data.value.__IS_AGGREGATION__);
const value = computed(() => {
	const type = state.titleField?.fieldtype || '';
	if (['HTML', 'HTML Editor', 'Text Editor'].includes(type)) {
		return html2text(props.params.value);
	}
	return props.params.value;
});
const linkField = computed(() => state.linkField);
const href = useDocHref(meta, data, linkField);
</script>
<style lang="less" scoped>
.root {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;

	>:first-child {
		flex: 1;

		overflow: hidden;
		text-overflow: ellipsis;
	}
}
</style>
