frappe.ui.form.Footer.prototype.make_comment_box = function () {
	this.frm.comment_box = frappe.ui.form.make_control({
		parent: this.wrapper.find('.comment-box'),
		render_input: true,
		only_input: true,
		enable_mentions: true,
		frm: this.frm,
		df: {
			fieldtype: 'Comment',
			fieldname: 'comment',
		},
		on_submit: (comment, attachments) => {
			if (strip_html(comment).trim() != '' || comment.includes('img') || attachments.length > 0) {
				this.frm.comment_box.disable();
				const attachmentsNames = attachments?.map(a => a.name);
				frappe
					.xcall('frappe.desk.form.utils.add_comment', {
						reference_doctype: this.frm.doctype,
						reference_name: this.frm.docname,
						content: comment,
						comment_email: frappe.session.user,
						comment_by: frappe.session.user_fullname,
						attachments: attachmentsNames || [],
					})
					.then(comment => {
						this.frm.comment_box.clear_attachments();
						comment.comment_attachments = attachments;
						let comment_item =
							this.frm.timeline.get_comment_timeline_item(comment);
						this.frm.comment_box.set_value('');
						this.frm.comment_box.clear();
						frappe.utils.play_sound('click');
						this.frm.timeline.add_timeline_item(comment_item);
						this.frm.sidebar.refresh_comments_count &&
							this.frm.sidebar.refresh_comments_count();
					})
					.finally(() => {
						this.frm.comment_box.enable();
					});
			}
		},
	});
};
