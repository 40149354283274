<template>
	<div class="tools container" v-if="editor">
		<el-tooltip :content="tt('Bold')">
			<ElButton :class="{ 'is-active': editor.isActive('bold') }" @click="editor.chain().focus().toggleBold().run()">
				<Bold :size="16"></Bold>
			</ElButton>
		</el-tooltip>
		<el-tooltip :content="tt('Italic')">
			<ElButton :class="{ 'is-active': editor.isActive('italic') }"
				@click="editor.chain().focus().toggleItalic().run()">
				<Italic :size="16"></Italic>
			</ElButton>
		</el-tooltip>
		<el-tooltip :content="tt('Delete Line')">
			<ElButton :class="{ 'is-active': editor.isActive('strike') }"
				@click="editor.chain().focus().toggleStrike().run()">
				<Strikethrough :size="16"></Strikethrough>
			</ElButton>
		</el-tooltip>
		<el-tooltip :content="tt('align-left')">
			<ElButton @click="editor.chain().focus().setTextAlign('left').run()">
				<AlignLeft :size="16"></AlignLeft>
			</ElButton>
		</el-tooltip>
		<el-tooltip :content="tt('Center alignment')">
			<ElButton @click="editor.chain().focus().setTextAlign('center').run()">
				<AlignCenter :size="16"></AlignCenter>
			</ElButton>
		</el-tooltip>
		<el-tooltip :content="tt('Automatic alignment')">
			<ElButton @click="editor.chain().focus().setTextAlign('justify').run()">
				<AlignJustify :size="16"></AlignJustify>
			</ElButton>
		</el-tooltip>
		<el-tooltip :content="tt('align-right')">
			<ElButton @click="editor.chain().focus().setTextAlign('right').run()">
				<AlignRight :size="16"></AlignRight>
			</ElButton>
		</el-tooltip>
		<el-tooltip :content="tt('Insert Table')">
			<ElButton @click="editor.chain().focus()
				.insertTable({ rows: 3, cols: 3, withHeaderRow: true })
				.run();">
				<Table :size="16"></Table>
			</ElButton>
		</el-tooltip>
		<el-tooltip :content="tt('Title One')">
			<ElButton @click="editor.chain().focus()
				.setNode('heading', { level: 2 })
				.run();">
				<Heading1 :size="16"></Heading1>
			</ElButton>
		</el-tooltip>
		<el-tooltip :content="tt('Title Two')">
			<ElButton @click="editor.chain().focus()
				.setNode('heading', { level: 3 })
				.run();">
				<Heading2 :size="16"></Heading2>
			</ElButton>
		</el-tooltip>
		<el-tooltip :content="tt('Title Three')">
			<ElButton @click="editor.chain().focus()
				.setNode('heading', { level: 4 })
				.run();">
				<Heading3 :size="16"></Heading3>
			</ElButton>
		</el-tooltip>
		<el-tooltip :content="tt('Chart')">
			<ElButton @click="insertChart">
				<LineChart :size="16"></LineChart>
			</ElButton>
		</el-tooltip>
		<el-tooltip :content="tt('Insert Image')">
			<ElButton @click="insetImage">
				<Image :size="16"></Image>
			</ElButton>
		</el-tooltip>
		<el-tooltip :content="tt('Ordered List')">
			<ElButton @click="editor.chain().focus().toggleOrderedList().run();">
				<ListOrdered :size="16"></ListOrdered>
			</ElButton>
		</el-tooltip>
		<el-tooltip :content="tt('Unordered list')">
			<ElButton @click="editor.chain().focus().toggleBulletList().run();">
				<List :size="16"></List>
			</ElButton>
		</el-tooltip>
	</div>
</template>

<script setup lang='ts'>
import { ref, defineProps, defineEmits } from 'vue';

import {
	Bold, Italic, Strikethrough, AlignLeft, AlignCenter, AlignRight, AlignJustify, Table,
	Heading1, Heading2, Heading3, LineChart, Image, ListOrdered, List,
} from 'lucide-vue-next';
import { ElButton, ElTooltip } from 'element-plus';

const tt = __;

interface Props {
	editor: any;
}
const props = defineProps<Props>();
interface Emit {

}
const emit = defineEmits<Emit>();

function insetImage() {
	const input = document.createElement('input');
	input.setAttribute('type', 'file');
	input.setAttribute('accept', 'image/*');
	input.addEventListener('change', e => {
		if (!e.target?.files || e.target.files?.length === 0) { return; }
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			const url = reader.result;
			props.editor.chain().focus().setImage({ src: url })
				.run();
		};
	});
	input.click();
}
function insertChart() {
	props.editor.chain().focus()
		.insertContent(`<chart data-type="draggable-item"></chart>`)
		.run();
}
</script>

<style lang='less' scoped>
.tools {
	display: flex;
	flex-flow: wrap;
	align-items: center;

	button {
		margin: 5px;
	}
}
</style>
