<template>
	<div>
		<a v-if="value" :href="href" style="margin-inline-end: 8px;">
			{{ params.value }}
		</a>
		<a v-if="value" :href="href" :download="name" style="font-size: inherit;">
			<ElIcon>
				<Download />
			</ElIcon>
		</a>
	</div>
</template>

<script setup lang='ts'>
import { computed } from 'vue';

import { ElIcon } from 'element-plus';
import { Download } from '@element-plus/icons-vue';

const props = defineProps<{ params: any }>();

const value = computed(() => props.params.value || '');
const href = computed(() => encodeURI(value.value).replace(/#/g, '%23'));

const name = computed(() => value.value.split('/')?.pop() || '');
</script>
