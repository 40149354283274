<template>
	<div class="table" v-if="dataList.length">
		<div ref="set"></div>
	</div>
	<div v-else>
		<ElEmpty :description="tt('No Records Created')" :imageSize="200" />
	</div>
</template>
<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch } from 'vue';
import { ElEmpty } from 'element-plus';
import Handsontable from 'handsontable';
import 'handsontable/dist/handsontable.full.css';
import type { CellChange } from 'handsontable/common';

import type { SaveData, BoqData } from '../type';

import { column, getHeader, getMenus } from './helper';

const props = defineProps<{
	dataList: BoqData[];
	isExpand: boolean;
	hotName: string;
}>();
const emit = defineEmits<{
	(
		e: 'updateAndSubmitData',
		params: {
			source: string;
			savedData: SaveData[];
		},
	): void;
}>();
const tt = __;
const set = ref<HTMLElement>();
const hatable = ref<Handsontable>();
const needSaveData: SaveData[] = [];
const hotUI = ref<any>();

/**
 * 存储编辑后的数据
 * @param changes
 * @param source
 */
function saveChangeData(changes: CellChange[], source: string) {
	if (source === 'loadData' || source === 'updateData') {
		return;
	}
	const isFix = [];
	if (changes) {
		for (const [row, prop, oldValue, newValue] of changes) {
			// 判断新值与旧值是否相同，相同则不保存
			if (oldValue === newValue) {
				isFix.push(false);
				continue;
			} else {
				isFix.push(true);
			}
			const rowData: any = hatable.value?.getSourceDataAtRow(row);
			// 完工数量列清空单元格后，值设为0
			if (
				(newValue === '' ||
					newValue === null ||
					newValue === undefined) &&
				prop === 'quantity' &&
				rowData.is_boq
			) {
				hatable.value?.setDataAtRowProp(row, prop, 0);
			}
			// 判断是否已经存在
			const isExist = needSaveData.find(
				item => item.name === rowData.name,
			);
			const saveObj: SaveData = {
				name: rowData?.name,
				boq_item_full_name: rowData?.boq_item_full_name,
				is_boq: rowData?.is_boq,
				quantity: rowData?.quantity,
				top_completed_quantity: rowData?.top_completed_quantity,
				closing_quantity: rowData?.closing_quantity,
			};
			if (isExist) {
				if (prop === 'remark') {
					isExist.remark = newValue;
				} else {
					isExist[prop] = newValue;
				}
			} else {
				if (prop === 'remark') {
					saveObj[prop] = rowData[prop] || null;
					saveObj.remark = newValue;
				} else {
					saveObj[prop] = newValue;
					saveObj.remark = rowData?.remark || null;
				}
				needSaveData.push(saveObj);
			}
		}
	}
	// 判断是否有修改，有修改则触发事件更新数据
	if (isFix.find(item => item === true)) {
		emit('updateAndSubmitData', {
			source: 'table',
			savedData: needSaveData,
		});
	}
}
/**
 * 复制粘贴数据是跳过只读行
 * @param data
 * @param coords
 */
function beforePaste(
	data: string[] | number[][],
	coords: {
		startRow: number;
		startCol: number;
		endRow: number;
		endCol: number;
	}[],
) {
	const [{ startRow, startCol }] = coords;
	const editCol = [6, 10, 11, 12];
	const sourceData = hatable.value?.getSourceData() || [];
	for (const [index, row] of data.entries()) {
		debugger;
		const currentRow = startRow + index;
		if (sourceData[currentRow].is_boq) {
			row.forEach((e, index) => {
				if (editCol.includes(index + startCol)) {
					const colName =
						hatable.value?.getSettings().columns[index + startCol]
							.data;
					const val = colName === 'remark' ? e : Number(e);
					hatable.value?.setDataAtRowProp(currentRow, colName, val);
				}
			});
		}
	}
	return false;
}

watch([set, () => props.dataList], () => {
	if (!set.value) {
		return;
	}
	// 实例是否存在，存在则销毁
	if (
		!hatable.value?.isDestroyed &&
		hatable.value?.isDestroyed !== undefined
	) {
		hatable.value?.destroy();
	}
	const { height } = document
		.querySelector('.table-container')!
		.getBoundingClientRect();
	const tableData = getHeader(column);
	const { headerData, columns, cells, dataSchema, widths } = tableData;
	const newData = [...(headerData || []), ...(props.dataList || [])];
	hatable.value = new Handsontable(set.value, {
		data: newData,
		columns,
		rowHeaders: true,
		rowHeaderWidth: 85,
		colHeaders: true,
		height: height * 0.95,
		width: '100%',
		cells,
		colWidths: widths,
		dataSchema,
		contextMenu: getMenus(props.hotName),
		autoWrapRow: true,
		autoWrapCol: true,
		nestedRows: true,
		fixedRowsTop: 1,
		fixedColumnsStart: 3,
		allowInsertRow: false,
		beforePaste,
		licenseKey: 'non-commercial-and-evaluation',
		language: frappe.boot.lang === 'zh' ? 'zh-CN' : undefined,
		afterChange: (changes, source) => {
			saveChangeData(changes as CellChange[], source);
		},
	});
	hotUI.value = hatable.value?.getPlugin('nestedRows').collapsingUI;
});

// 展开
function expand() {
	hotUI.value.expandAll();
	if (hotUI.value.collapsedRows.length > 0) {
		expand();
	}
}

// 折叠
function collapse() {
	hotUI.value.collapseAll();
}
function handleResize() {
	if (set.value) {
		const { height } = document
			.querySelector('#guigu_project_monthly_boq_completed_quantity')!
			.getBoundingClientRect();
		set.value.style.height = `${height * 0.95}px`;
	}
}
onMounted(() => {
	window.addEventListener('resize', handleResize);
});
onUnmounted(() => {
	window.removeEventListener('resize', handleResize);
});
defineExpose({
	expand,
	collapse,
});
</script>

<style lang="less" scoped>
.table {
	margin: 0;
	width: 100%;
	height: 100%;
}
:deep(.ht_clone_left) {
	z-index: 1;
}
:deep(.ht_clone_top) {
	z-index: 1;
}
:deep(.ht_clone_top_inline_start_corner.ht_clone_top_left_corner) {
	z-index: 1;
}
:deep(.ht_master) {
	padding-bottom: 15px;
}
:deep(.ht_clone_inline_start.ht_clone_left.handsontable) {
	display: inline;
}
</style>
