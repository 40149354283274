import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (!$setup.officials)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, "..."))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.officials, ({ appid, name, title, agentid, login_enabled, qrcode_image, login_url }) => {
          return (_openBlock(), _createBlock($setup["Item"], {
            key: name,
            appid: appid,
            name: name,
            agentid: agentid,
            title: title,
            loginEnabled: login_enabled,
            bind: name in $setup.users,
            enabled: $setup.users[name],
            disabled: $setup.loading,
            wechatQrcode: qrcode_image,
            loginUrl: login_url,
            onSubscribe: $setup.subscribe,
            onUnsubscribe: $setup.unsubscribe,
            onUnbind: $setup.unbind
          }, null, 8 /* PROPS */, ["appid", "name", "agentid", "title", "loginEnabled", "bind", "enabled", "disabled", "wechatQrcode", "loginUrl"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]))
}