<template>
	<AgGridVue
		v-loading="loading"
		class="ag-theme-guigu week-plan_table"
		:columnDefs="columnDefs"
		:rowData="structureDescendants"
		:getRowStyle="getRowStyle"
		:defaultColDef="defaultColDef"
		rowSelection="multiple"
		animateRows="true"
		@grid-ready="onGridReady"
		@cellValueChanged="cellValueChanged"
		@rowDataUpdated="rowDataUpdated"
		@cellEditingStopped="cellEditingStopped"
		:suppressDragLeaveHidesColumns="smallMeta"
		:localeText="zhCN"
		:getRowId="getRowId">
	</AgGridVue>
</template>
<script lang="ts" setup>
import {ref, computed, defineComponent, PropType, getCurrentInstance, watch} from 'vue';
import {AgGridVue} from 'ag-grid-vue3';
import {CellEditingStoppedEvent, GridApi, RowClassParams, RowDataUpdatedEvent} from 'ag-grid-community';

import type {Period, ProgressPlan, StructureItemFormat} from '../type';
import {useMetaQuery} from '../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';
import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';

import {getPlanColumns} from './tableColumns';

interface Props{
	'structureDescendants': StructureItemFormat[],
	'project': string,
	'period'?: Period,
	'structure':string
	mainType:'Week'|'Month',
	subType:'Week'|'Month',
}
const tt = __;
const props = defineProps<Props>();
const emit = defineEmits<{
	(event: 'planChange', value: any, oldID:string): void;
	(event: 'onRemove', data:StructureItemFormat): void;
}>();
const smallMeta = useMetaQuery();
const loading = ref<boolean>(false);
const gridApi = ref<GridApi>();
const onGridReady = (params: any) => {
	gridApi.value = params.api;
};
function getRowStyle(params:RowClassParams) {
	if (params.data.type === 'temp') {
		return {color: '#d36e13'};
	}
}
watch(()=>[...props.structureDescendants], ()=>{
	if (!gridApi.value) {
		return;
	}
	gridApi.value.setRowData(props.structureDescendants);
}, {deep: true});
function rowDataUpdated(event: RowDataUpdatedEvent) {
	const nodes = event.api.getRenderedNodes();
	const editNode = nodes.find(item=>item.data.id.startsWith('new') && item.data.type === 'temp');
	if (!gridApi.value) {
		return;
	}
	gridApi.value.startEditingCell({rowIndex: editNode?.rowIndex, colKey: 'label'});
}
function onRemove(data:StructureItemFormat) {
	if (data.type === 'temp' && data.plan_name) {
		deleteTemProgress(data);
	}
	emit('onRemove', data);
}
const columnDefs = computed(() => getPlanColumns(props.mainType, onRemove));
const defaultColDef = {
	sortable: true,
	filter: true,
	flex: 1,
	resizable: true,
};
async function cellEditingStopped(event:CellEditingStoppedEvent) {
	const {data, value, oldValue, newValue, colDef} = event;
	if (data.type === 'temp' && data.id.startsWith('new') && colDef.field === 'label' && oldValue === newValue && newValue === tt('Please enter the construction content')) {
		const newData = await createTempProgress(value);
		if (!newData) {
			return false;
		}
		emit('planChange', newData, data.id);
	}
}
async function cellValueChanged(event: any) {
	const {data, value, colDef} = event;
	let colum: string = colDef.field;
	colum = colum === 'designQuantity' ? 'design_quantity' : colum;
	loading.value = true;
	let newData;
	if (data.type === 'temp') {
		// 更新或新建临时形象进度
		if (data.id.startsWith('new')) {
			newData = await createTempProgress(value);
		} else {
			newData = await updateTempProgress(data.plan_name, colum, value);
		}
	} else if (data?.plan_name) {
		newData = await updatePlan(data.plan_name, colum, value);
	} else {
		newData = await createPlan(colum, data.structureProgressId, value);
	}

	loading.value = false;
	if (!newData) {
		return false;
	}
	emit('planChange', newData, data.id);
}
async function deleteTemProgress(data:StructureItemFormat) {
	if (!data.plan_name) {
		return;
	}
	await frappe.db.delete_doc('Guigu Construction Temp Progress Plan', data.plan_name);
}
async function createTempProgress(value:string) {
	if (!props.period) {
		return;
	}
	const newData = await frappe.db.insert({
		doctype: 'Guigu Construction Temp Progress Plan',
		project: props.project,
		period: props.period.name,
		label: value,
		construction_structure: props.structure,
	});
	return newData;
}
async function updateTempProgress(name:string, column:string, value:any) {
	const res = await frappe.db.set_value(
		'Guigu Construction Temp Progress Plan',
		name,
		column,
		value,
	);
	return res?.message;
}

async function updatePlan(planName:string, column:string, value:any) {
	const res = await frappe.db.set_value(
		'Guigu Construction Progress Plan',
		planName,
		column,
		value,
	);
	return res?.message;
}
async function createPlan(column:string, structureProgressId:string, value:any) {
	if (!props.period) {
		return;
	}
	const newData = await frappe.db.insert({
		doctype: 'Guigu Construction Progress Plan',
		project: props.project,
		guigu_construction_calendar_period: props.period.name,
		guigu_construction_structure_progress: structureProgressId,
		main_type: props.mainType,
		sub_type: props.subType,
		[column]: value,
	});
	return newData;
}
function getRowId(params: any) {
	return params.data.id;
}

</script>
<style lang="less" scoped>
.week-plan_table {
  width: 100%;
  height: 100%;
}
</style>
