<template>
	<el-tooltip effect="light" :content="value" placement="top">
		<div>{{ value }}</div>
	</el-tooltip>
</template>

<script setup lang='ts'>
import { computed } from 'vue';

const props = defineProps<{ params: any; }>();
const value = computed(() => {
	const tempDiv = document.createElement('div');
	tempDiv.innerHTML = props.params.value;
	const text = tempDiv.textContent || '';
	tempDiv.remove();
	return text;
});
</script>

<style lang='less' scoped>
</style>
