import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["AgGridVue"], {
    class: "ag-theme-guigu assess-table",
    rowData: $props.extraAssessList,
    defaultColDef: $setup.defaultColDef,
    animateRows: "true",
    onGridReady: $setup.onGridReady,
    onCellValueChanged: $setup.cellValueChanged,
    suppressDragLeaveHidesColumns: $setup.smallMeta,
    suppressRowTransform: "",
    domLayout: "autoHeight",
    localeText: $setup.zhCN,
    getRowId: $setup.getRowId
  }, null, 8 /* PROPS */, ["rowData", "suppressDragLeaveHidesColumns", "localeText"]))
}