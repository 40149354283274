import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_WorkspaceWidget = _resolveComponent("WorkspaceWidget")
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createBlock(_component_WorkspaceWidget, null, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString($props.label), 1 /* TEXT */)
    ]),
    button: _withCtx(() => [
      _createVNode($setup["Expander"], { loading: $setup.loading }, null, 8 /* PROPS */, ["loading"]),
      _createVNode($setup["ElButton"], {
        onClick: $setup.refresh,
        title: $setup.tt('Refresh'),
        disabled: $setup.loading,
        circle: "",
        icon: $setup.RefreshRight
      }, null, 8 /* PROPS */, ["onClick", "title", "disabled", "icon"])
    ]),
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent($setup.comp), {
        class: "ag-theme-guigu ag",
        style: _normalizeStyle($setup.style),
        rowHeight: $setup.rowHeight,
        meta: $props.meta,
        data: $setup.data,
        loading: $setup.loading,
        detail: $setup.detail,
        configuration: $setup.view
      }, null, 8 /* PROPS */, ["style", "rowHeight", "meta", "data", "loading", "detail", "configuration"]))
    ]),
    _: 1 /* STABLE */
  })), [
    [_directive_loading, $setup.loading || false]
  ])
}