<template>
	<div>
		<el-date-picker
			v-model="value"
			class="date"
			type="daterange"
			align="right"
			@change="dateChange"
			unlinkPanels
			:startPlaceholder="tt('Start Date')"
			:endPlaceholder="tt('End Date')"
			:shortcuts="shortcuts">
		</el-date-picker>
	</div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import moment from 'moment';
const tt = __
interface Emit {
	(e: 'update:modelValue', dayList?: string[]): void
}
const emit = defineEmits<Emit>();

interface Props {

}

const value = ref();

const shortcuts = [{
	text: __("Today"),
	onClick(picker) {
		picker.$emit('pick', new Date());
	},
}, {
	text: __("Yesterday"),
	onClick(picker) {
		const date = new Date();
		date.setTime(date.getTime() - 3600 * 1000 * 24);
		picker.$emit('pick', date);
	},
}, {
	text: __("1 week ago"),
	onClick(picker) {
		const date = new Date();
		date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
		picker.$emit('pick', date);
	},
}];
function getDataRange(from: Date, to: Date) {
	const momentFrom = moment(from);
	const momentTo = moment(to);
	const day = momentTo.diff(momentFrom, 'days');
	const daysList: string[] = [];
	for (let i = 0; i <= day; i++) {
		if (i === 0) {
			daysList.push(momentFrom.format('YYYY-MM-DD'));
		} else {
			daysList.push(momentFrom.add(1, 'days').format('YYYY-MM-DD'));
		}
	}

	return daysList.sort((a, b) => a - b);
}
function dateChange(date) {
	const dateList = getDataRange(date[0], date[1]);
	emit('update:modelValue', dateList);
}
</script>
<style lang="less" scoped>
:deep(.date){
	width:265px!important;
}
</style>
