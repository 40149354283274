import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (!$props.loading)
    ? (_openBlock(), _createBlock($setup["AgGridVue"], {
        key: 0,
        class: "ag-theme-guigu table",
        style: {"height":"95%","width":"100%","margin":"0","overflow":"auto"},
        treeData: "",
        animateRows: "",
        suppressRowClickSelection: "",
        rowSelection: "multiple",
        rowData: $props.dataList,
        columnDefs: $setup.columnDefs,
        defaultColDef: $setup.defaultColDef,
        suppressDragLeaveHidesColumns: $props.smallMeta,
        autoGroupColumnDef: $setup.autoGroupColumnDef,
        localeText: $setup.zhCN,
        getDataPath: $setup.getDataPath,
        getRowId: $setup.getRowId,
        getRowHeight: $setup.getRowHeight,
        onGridReady: $setup.onGridReady,
        onSelectionChanged: $setup.onSelectionChanged
      }, null, 8 /* PROPS */, ["rowData", "columnDefs", "defaultColDef", "suppressDragLeaveHidesColumns", "autoGroupColumnDef", "localeText"]))
    : _createCommentVNode("v-if", true)
}