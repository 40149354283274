import mountVueToPage from 'guiguLib/mountVueToPage';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn';

import LeaderWorkPage from './index.vue';

export default function loadGuiguLeaderWork (wrapper) {
	const app = mountVueToPage(wrapper, [
		{path: '/:pathMatch(.*)*', component: LeaderWorkPage},
	], {
		base: '/app/guigu_leader_work',
	});
	app.use(ElementPlus, {size: 'small', locale: zhCn});
}

frappe.provide('frappe.guigu_pc');
frappe.guigu_pc.pages = frappe.guigu_pc.pages || {};
frappe.guigu_pc.pages.loadGuiguLeaderWork = loadGuiguLeaderWork;
