<template>
	<ElFormItem>
		<Filters :doctype="doctype" v-model="filtersMust" />
	</ElFormItem>
</template>
<script setup lang="ts">
import { computed } from 'vue';

import { ElFormItem } from 'element-plus';

import Filters from './Filters.vue';

const props = defineProps<{
	meta: locals.DocType;
	modelValue: any;
	define?: GlobalView;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: GlobalView.View): void;
}>();
const view = computed({
	get: () => props.modelValue,
	set: v => emit('update:modelValue', v),
});


const filtersMust = computed({
	get: () => view.value.filters_must,
	set: v => { view.value = { ...view.value, filters_must: v || null }; },
});

const doctype = computed(() => props.meta.name);

</script>
