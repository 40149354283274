<template>
	<AgGridVue
		class="ag-theme-guigu"
		domLayout="autoHeight"
		:rowData="td"
		treeData
		:defaultColDef="defaultColDef"
		@grid-ready="onGridReady"
		:getDataPath="getDataPath"
		:suppressDragLeaveHidesColumns="smallMeta"
		:localeText="zhCN"
		useValueFormatterForExport
		:autoGroupColumnDef="autoGroupColumnDef"
	></AgGridVue>
</template>

<script setup lang="ts">
import {ref, defineProps, watch, nextTick, onMounted} from 'vue';
import {AgGridVue} from 'ag-grid-vue3';
import type {GridApi, ValueFormatterParams} from 'ag-grid-community';

import zhCN from '../../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';
import {useMetaQuery} from '../../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';
import type {TemplateCreateTask, SelectOption} from '../../../../../types';

import Select from './Select.vue';
import DatePicker from './DatePicker.vue';
import RemoveButton from './RemoveButton.vue';

const props = defineProps<{
	tableData: TemplateCreateTask[],
	taskTypeList: SelectOption[]
	user: SelectOption[]
}>();
const smallMeta = useMetaQuery();
const gridApi = ref<GridApi>();
const td = ref<TemplateCreateTask[]>([]);
const taskTypeOptions = ref<SelectOption[]>(props.taskTypeList);
const organizationUser = ref<SelectOption[]>([]);
const priorityOptions = ref<SelectOption[]>([
	{
		label: __('Urgent'),
		value: 'Urgent',
	},
	{
		label: __('High'),
		value: 'High',
	},
	{
		label: __('Medium'),
		value: 'Medium',
	},
	{
		label: __('Low'),
		value: 'Low',
	},
]);

watch(() => props.tableData, val => {
	td.value = val;
	gridApi.value?.setRowData(val);
});

onMounted(() => {
	td.value = props.tableData;
});

const defaultColDef = {
	filter: false,
	resizable: true,
	suppressMovable: true,
	cellDataType: false,
};
const onGridReady = (params: any) => {
	gridApi.value = params.api;
};

const autoGroupColumnDef = {
	headerName: __('Subject'),
	field: 'subject',
	enableRowGroup: false,
	editable: true,
	filter: false,
	flex: 1,
	cellEditor: 'agTextCellEditor',
};

const columns = [
	// {
	// 	headerName: __('Dw Task Type'),
	// 	field: 'task_type',
	// 	enableRowGroup: false,
	// 	editable: true,
	// 	filter: false,
	// 	width: 120,
	// 	cellEditor: Select,
	// 	cellEditorParams: {
	// 		field: 'task_type',
	// 		options: taskTypeOptions.value,
	// 	},
	// 	valueFormatter: (params:ValueFormatterParams) => {
	// 		if (!params.value) {
	// 			return '';
	// 		}
	// 		return taskTypeOptions.value.find(item => item.value === params.value)?.label || '';
	// 	},
	// },
	{
		headerName: __('Priority'),
		field: 'priority',
		enableRowGroup: false,
		editable: true,
		filter: false,
		width: 120,
		cellEditor: Select,
		cellEditorParams: {
			field: 'priority',
			options: priorityOptions.value,
		},
		valueFormatter: (params:ValueFormatterParams) =>{
			if (!params.value) {
				return '';
			}
			const priority = priorityOptions.value.find(item => item.value === params.value);
			return priority?.label ? priority?.label : priority?.value;
		},
	},
	{
		headerName: __('Assignee'),
		field: 'assignee',
		enableRowGroup: false,
		editable: true,
		filter: false,
		width: 120,
		cellEditor: Select,
		cellEditorParams: {
			field: 'assignee',
			options: props.user,
		},
		valueFormatter: (params:ValueFormatterParams) => {
			if (!params.value) {
				return '';
			}
			const assignee = props.user.find(item => item.value === params.value);
			return assignee?.label ? assignee?.label : assignee?.value;
		},
	},
	{
		headerName: __('Plan Start'),
		field: 'plan_start',
		enableRowGroup: false,
		editable: true,
		filter: false,
		width: 100,
		cellEditor: DatePicker,
		valueFormatter: (params: ValueFormatterParams) => {
			if (params.value === 'Invalid Date') {
				return '';
			}
		},
	},
	{
		headerName: __('Plan End'),
		field: 'plan_end',
		enableRowGroup: false,
		editable: true,
		filter: false,
		width: 100,
		cellEditor: DatePicker,
		valueFormatter: (params: ValueFormatterParams) => {
			if (params.value === 'Invalid Date') {
				return '';
			}
		},
	},
	{
		headerName: __('Actions'),
		field: '',
		enableRowGroup: false,
		editable: false,
		filter: false,
		width: 90,
		minWidth: 90,
		cellRenderer: RemoveButton,
		cellRendererParams: {
			removeTableData,
		},
	},
];

watch(() => props.taskTypeList, val => {
	taskTypeOptions.value = val;
	columns.map(item => {
		if (item.field === 'task_type' && item.cellEditorParams) {
			item.cellEditorParams.options = val;
		}
	});
	nextTick(() => {
		setTimeout(() => {
			gridApi.value?.setColumnDefs(columns);
		}, 0);
	});
});

watch(() => props.user, val => {
	organizationUser.value = val;
	columns.map(item => {
		if (item.field === 'assignee' && item.cellEditorParams) {
			item.cellEditorParams.options = val;
		}
	});
	nextTick(() => {
		setTimeout(() => {
			gridApi.value?.setColumnDefs(columns);
		}, 0);
	});
});

function getDataPath(data: any) {
	return data.path;
}

function removeTableData(name: string) {
	const tableData = td.value;
	removeNodeAndDescendants(tableData, name);
	const deletedTable = tableData.filter(item => !item.deleted);
	td.value = Array.from(deletedTable);
}

function removeNodeAndDescendants(data: TemplateCreateTask[], name: string) {
	for (const node of data) {
		if (node.name === name) {
			markNodeAndChildrenForDeletion(node);
		} else {
			const children = data.filter(subNode => subNode.parent_dw_task_template === node.name);
			if (children.length > 0) {
				removeNodeAndDescendants(children, name);
			}
		}
	}
}

function markNodeAndChildrenForDeletion(node:TemplateCreateTask) {
	node.deleted = true;
	const children = td.value.filter(subNode => subNode.parent_dw_task_template === node.name);
	for (const child of children) {
		markNodeAndChildrenForDeletion(child);
	}
}

</script>

<style lang="less" scoped></style>
