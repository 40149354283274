<template>
	<el-upload
      class="upload-demo"
      drag
      accept="image/bmp,image/png,image/jpeg,image/gif"
	  :show-file-list="false"
	  :on-progress="onProgress"
	  :file-list="fileList"
    >
      <el-icon class="el-icon--upload"><upload-filled /></el-icon>
      <div class="el-upload__text">
        {{ tt('Click or drag the image here to upload it') }}
      </div>
    </el-upload>
	<UploadDialog
		:visible="visible"
		:fileList="fileList"
		:structureDescendants="props.structureDescendants"
		:date="props.date"
		:showDateInput="props.showDateInput"
		@close="close"
		@confirm="confirm"
	></UploadDialog>
</template>
<script lang="ts" setup>
import { ref, Ref, defineProps, defineEmits } from 'vue';
import {  UploadFilled } from '@element-plus/icons-vue';
import { UploadFile, UploadFiles } from 'element-plus/lib/components/index.js';
import { UploadProgressEvent } from 'element-plus/lib/components/index.js';
import { FormInstance } from 'element-plus/lib/components/index.js';

import {ImageFile, ImageFormValue} from '../../type/image';
import { StructureItemFormat } from '../../type/diary';

import UploadDialog from './uploadDialog/index.vue';
const tt = __
interface Props{
	structureDescendants:StructureItemFormat[],
	date:string,
	showDateInput?:boolean
}
const props = defineProps<Props>();
interface Emits{
	(e:'save', photoList:(ImageFormValue&{file?:ImageFile})[]):void
}
const emit = defineEmits<Emits>();
const fileList = ref<ImageFile[]>([]);
const visible = ref<boolean>(false);
function onProgress(event:UploadProgressEvent, uploadFile:UploadFile, uploadFiles:UploadFiles){
	const newFiles = uploadFiles.filter(item=>!fileList.value.some(each=>each.uid===item.uid));
	if (newFiles.length>0){
		fileList.value.push(...newFiles);
	}
	visible.value=true;
}

function close(){
	fileList.value=[];
	visible.value=false;
}
async function confirm(forms?:{formRef:FormInstance, key:string, formValue:ImageFormValue}[]){
	const validatePromise: Promise<any>[] = [];
	const newFileList: ImageFile[] = [];
	forms?.forEach(form=>{
		const file = fileList.value.find(item => item.uid === form.key);
		if (!file) {
			return;
		}
		newFileList.push(file);
		validatePromise.push(form.formRef.validate());
	});
	try {
      const result = await Promise.allSettled(validatePromise);
      if (result.some(item => item.status === 'rejected'||item.value===false)) {
        return;
      }
		const photoList = forms?.map(item=>{
			const file = fileList.value.find(each=>each.uid===item.key);
			if (!file){ return; }
			return {
				...item.formValue,
				file,
			};
		}).filter(Boolean) as (ImageFormValue&{file?:ImageFile})[];
		visible.value=false;
		fileList.value=[];
		await emit('save', photoList);
    } catch {

	}
}
</script>
<style lang="less" scoped>

</style>
