import requestDocList from '../../utils/requestDocList';
import requestDocCount from '../../utils/requestDocCount';

export default async function loadList(
	meta: locals.DocType,
	filters: [string, string, string, any][],
	options: GlobalView.MainLoaderOptions,
): Promise<GlobalView.MainData> {
	const [values, total] = await Promise.all([
		requestDocList(meta, filters, options),
		requestDocCount(meta.name, filters, options.orFilters),
	]);
	return { values, total };
	// render
	// this.before_render();
	// this.render();
	// this.after_render();
	// this.freeze(false);
	// this.reset_defaults();
	// if (this.settings.refresh) {
	// 	this.settings.refresh(this);
	// }
}
