<template>
	<div v-loading="loading">
		<h4 class="title">{{ tt('Work Basic') }}</h4>
		<AgGridVue
			class="ag-theme-guigu"
			:columnDefs="columnDefs"
			:rowData="list"
			:defaultColDef="defaultColDef"
			rowSelection="multiple"
			animateRows="true"
			@grid-ready="onGridReady"
			groupDefaultExpanded="-1"
			:getRowNodeId="getRowId"
			domLayout="autoHeight"
			:localeText="zhCN"
			excludeChildrenWhenTreeDataFiltering
			suppressPaginationPanel
			:groupAllowUnbalanced="false"
			groupSelectsFiltered
			:suppressDragLeaveHidesColumns="smallMeta" />
	</div>
	<div class="btn-group" v-if="editable">
		<ElButton type="primary" @click="add">添加</ElButton>
	</div>
	<Dialog
		:doctype="DOCTYPE"
		:detailName="detailValue?.name"
		v-model:visible="formVisible"
		@onChange="onChange"
	></Dialog>
</template>

<script setup lang="ts">
import {computed, ref, watch, defineProps, shallowRef} from 'vue';
import type {ColumnApi, GridApi, ICellEditorParams, ColDef} from 'ag-grid-community';
import {ElButton, vLoading} from 'element-plus';
import {AgGridVue} from 'ag-grid-vue3';

import {useMetaQuery} from '../../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';
import zhCN from '../../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';
import ActionComponent from '../../components/Action.vue';
import type {ChangeValue} from '../../../../../component/FormDetail/type';
import Dialog from '../../../../../component/FormDetailDialog/index.vue';
import {useInstructionStore} from '../../instruction/store';
import Link from '../../components/Link.vue';

import type {Result} from './type';
const store = useInstructionStore();

const smallMeta = useMetaQuery();
const tt = __;
interface Emit {
	(e: 'remove'): void,
	(e: 'setData', data:string): void,
}
const emit = defineEmits<Emit>();
interface Props {
	editable: boolean;
	data:string
	chartName:string
}
const props = defineProps<Props>();
const gridApi = ref<GridApi | null>(null);
const gridColumnApi = shallowRef<ColumnApi>();
const detailValue = ref<locals.Doctype>();
const formVisible = ref<boolean>(false);
const DOCTYPE = 'Dw Work Instruction Work Basis';
const list = ref<locals.Doctype[]>([]);
const loading = ref<boolean>(true);
store.afterSave(({instruction})=>{
	const versionId = instruction?.current_version;
	if (!versionId || store.isSaveNewVersion) {
		return;
	}
	const notRelatedNames = list.value
		.filter(item=>item.dw_work_instruction_version !== versionId)
		.map(item=>item.name);
	if (notRelatedNames.length === 0) {
		return;
	}
	frappe.call({
		method: 'dw_worklist_management.api.instruction.block.relate_version',
		args: {names: notRelatedNames, version_name: versionId, doctype: DOCTYPE},
	});
});
function remove() {
	frappe.call({
		method: 'dw_worklist_management.api.instruction.block.bulk_delete',
		args: {names: list.value.map(item=>item.name), doctype: DOCTYPE},
	});
}
defineExpose({
	remove: remove,
});
const defaultColDef = computed(()=>({
	sortable: false,
	filter: false,
	minWidth: 100,
	resizable: true,
	editable: false,
	enableRowGroup: false,
}));

const onGridReady = (params: any) => {
	gridApi.value = params.api;
	gridColumnApi.value = params.columnApi;
};
const columnDefs = computed(()=>{
	const cols:ColDef[] = [
		{
			headerName: __('Name'),
			field: 'standard_document_file_name',
			flex: 1,
			cellRenderer: Link,
			cellRendererParams: (params: ICellEditorParams) => {
				const label = params.value;
				const value = params.data.standard_document;
				const options = 'Dw Standard Document';
				return {...params, label, value, options};
			},
		},
		{
			headerName: __('Description'),
			field: 'description',
			flex: 1,
		},

	];
	if (props.editable) {
		cols.push({
			headerName: __('Actions'),
			field: 'action',
			cellRenderer: ActionComponent,
			pinned: smallMeta ? undefined : 'right',
			resizable: false,
			width: 100,
			menuTabs: [],
			cellRendererParams: (params: ICellEditorParams) => {
				const fn: {
					onRemove?: (data: any) => void
					onEdit?: (data: any) => void
				} = {};
				if (props.editable) {
					fn.onEdit = (data: any) => {
						detailValue.value = data;
						formVisible.value = true;
					};
					fn.onRemove = async (data: any) => {
						loading.value = true;
						await frappe.db.delete_doc(DOCTYPE, data.name);
						loading.value = false;
						onChange({type: 'delete', data});
					};
				}
				return {...params, ...fn};
			},
		});
	}
	return cols;
});

function onChange(data:ChangeValue) {
	switch (data.type) {
		case 'create':list.value = [...list.value, data.data]; break;
		case 'update':{
			const index = list.value.findIndex(item=>item.name === data.data.name);
			list.value.splice(index, 1, data.data);
			break;
		}
		case 'delete':{
			const index = list.value.findIndex(item=>item.name === data.data.name);
			list.value.splice(index, 1);
			break;
		}
	}
	emit('setData', JSON.stringify(list.value.map(item=>item.name)));
}
const parsedData = computed<string[]>(()=>JSON.parse(props.data || '[]'));
watch(parsedData, ()=>{
	getWorkBasic(parsedData.value);
}, {immediate: true});

async function getWorkBasic(data:string[]) {
	loading.value = true;
	const doctype = `tab${DOCTYPE}`;
	const res = await frappe.call<{message:Result}>({
		method: 'frappe.desk.reportview.get',
		args: {
			doctype: DOCTYPE,
			fields: [
				`\`${doctype}\`.\`name\``,
				`\`${doctype}\`.\`description\``,
				`\`${doctype}\`.\`standard_document\``,
				`standard_document.file_name as standard_document_file_name`,
				'dw_work_instruction_version',
			],
			filters: [
				['name', 'in', data],
			],
		},
	});
	const result:Result = (Array.isArray(res?.message) || !res?.message) ? {keys: [], values: []} : res?.message;
	const formatList = result.values?.map(data=>{
		const info: locals.Doctype = {} as locals.Doctype;
		for (const [index, key] of (result.keys || []).entries()) {
			info[key] = data[index];
		}
		return info;
	});
	list.value = formatList || [];
	loading.value = false;
}

function add() {
	const docName = frappe.model.make_new_doc_and_get_name(DOCTYPE, true);
	const docDetail = locals[DOCTYPE][docName] as locals.Doctype;
	docDetail.dw_work_instruction_version = store.currentVersion.name;
	detailValue.value = docDetail;
	formVisible.value = true;
}
function getRowId(data: {name:string}) {
	return data.name;
}

frappe.realtime.on('doc_update', params => {
	if (params.doctype !== 'Dw Standard Document') {
		return;
	}
	getWorkBasic(parsedData.value);
});

</script>
<style scoped lang="less">
.title{
	text-align: center;
	margin-bottom: 1.5rem;
}
.btn-group{
	margin-top: 8px;
}
</style>
