import mountVueToPage from 'guiguLib/mountVueToPage';
import ElementPlus from 'element-plus';

import Main from './main.vue';

export default function loadProjectLeaderDailyRecord (wrapper:HTMLElement) {
	const app = mountVueToPage(wrapper, [
		{path: '/:pathMatch(.*)*', component: Main},
	], {
		base: '/app/project_leader_daily_record',
	});
	app.use(ElementPlus);
}

frappe.provide('frappe.guigu_pm');
frappe.guigu_pm.guigu_pm_daily_record = frappe.guigu_pm.guigu_pm_daily_record || {};
frappe.guigu_pm.guigu_pm_daily_record.pages = frappe.guigu_pm.guigu_pm_daily_record.pages || {};
frappe.guigu_pm.guigu_pm_daily_record.pages.loadProjectLeaderDailyRecord = loadProjectLeaderDailyRecord;
