import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-899bbe08"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "root",
  class: "root"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, null, 512 /* NEED_PATCH */))
}