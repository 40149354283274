<template>
	<ElSelect v-model="val" filterable remote :key="key" :teleported="false"
		:remoteMethod="search" :placeholder="label && tt(label)"
		@focus="focus"
		:allowCreate="filter"
		defaultFirstOption
		:popperClass="popperClassProject"
		:fitInputWidth="fitWidth"
		:loading="loading || waiting"
	>
		<ElOption
			v-for="option in allOptions"
			:key="option.value"
			:label="option.label"
			:value="option.value">
			<p v-if="isProject">
				<strong>{{ option.label }}</strong>
				<span>{{ option.description }}</span>
				<span>{{ option.constructionStatus ? tt(option.constructionStatus) : '' }}</span>
			</p>
			<div v-if="!isProject">
				<strong>{{ option.label }}</strong>
				<small>{{ option.description }}</small>
			</div>
		</ElOption>
	</ElSelect>
</template>
<script lang="ts" setup>
import { computed, shallowRef, watch } from 'vue';
import { ElSelect, ElOption } from 'element-plus';

import useDebounce from '../../hooks/useDebounce';

const props = defineProps<{
	label?: string;
	name: string;
	required?: boolean;
	filter?: boolean;
	modelValue?: any;
	fitWidth?: boolean;
	popperClass?: string;
	options?: any;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value?: any): void;
}>();
const tt = __;
const key = shallowRef('0');

interface Option {
	value: string;
	label: string;
	description: string;
	constructionStatus: string;
}
const searchedOptions = shallowRef<Option[]>([]);
const currentOption = shallowRef<Option>();

const hasNullValue = shallowRef(true);
const isProject = computed(() => props.name === 'project');
const popperClassProject= computed(() => props.name === 'project' ? `${props.popperClass} projectLink`: props.popperClass || '');

const val = computed({
	get: () => {
		const value = props.modelValue;
		if (typeof value !== 'string') {
			return;
		}
		return value;
	},
	set: v => emit('update:modelValue', v || undefined),
});
const opts = computed(() => {
	const c = currentOption.value;
	const list = searchedOptions.value;
	if (!c || val.value !== c.value) { return list; }
	const { value } = val;
	if (value !== c.value) { return list; }
	if (list.find(v => v.value === value)) { return list; }
	return [...list, c];
});
const allOptions = computed(() => {
	const baseList = opts.value;
	if (props.filter && !hasNullValue.value) {
		return baseList;
	}
	return [
		{ value: '', label: '', description: '', constructionStatus: '' },
		...baseList,
	];
});

interface Result {
	value: string;
	label: string | null;
	description: string;
	constructionStatus: string;
}
const [getLinkTitle] = useDebounce(
	async (value: string) => {
		const args = { docname: value || '', doctype: props.options };

		return frappe.call<{ message: string }>({
			type: 'POST',
			method: 'frappe.desk.search.get_link_title',
			args,
		}).then(v => ({ value, label: v?.message || value, description: '', constructionStatus:''}));
	}, 300, {
		render: v => {
			key.value = '1';
			currentOption.value = v;
		},
	});
const [remoteMethod, loading, waiting] = useDebounce(
	async (query?: string) => {
		const args = {
			txt: query || '',
			doctype: props.options,
			// reference_doctype: props.name,
		};
		return frappe.call<{ results: Result[] }>({
			type: 'POST',
			method: 'frappe.desk.search.search_link',
			args,
		}).then(v => v?.results || v?.message || []);
	}, 300, {
		render: v => {
			searchedOptions.value = [
				...v.map(({ value, label, description }) => ({
					value, label: label || value, description,
				})),
			];
		},
	});
let value = '';
function search(query?: string) {
	value = query || '';
	hasNullValue.value = !value;
	remoteMethod(query);
}
function focus() {
	if (value) { return; }
	if (searchedOptions.value.length) { return; }
	if (loading.value) { return; }
	if (waiting.value) { return; }
	remoteMethod('');
}

watch(() => props.modelValue, value => {
	if (typeof value !== 'string' && value !== undefined) {
		emit('update:modelValue');
	} else if (value && key.value !== '1') {
		getLinkTitle(value);
	}
}, { immediate: true });


</script>
<style scoped lang="less">
.projectLink {
	.el-select-dropdown__item {
		height: 59px !important;
	}

	.el-select-dropdown__item > p{
		display: flex;
		flex-direction: column;

	}
	.el-select-dropdown__item > p > span {
		font-size: 12px;
		line-height: 1em;
	}
}
</style>
