import type {
	EmitOption,
	Emit,
	Listen,
	Listener,
	EventContext
} from "../types";

function get(
	events: Record<string | symbol, Set<(v: any, ctx: EventContext) => void>>,
	key: any,
) {
	let set = key in events && events[key];
	if (set instanceof Set) { return set; }
	set = new Set();
	events[key] = set;
	return set;
}
export function createListen<T extends object>(
	events: Record<string | symbol, Set<(v: any, ctx: EventContext) => void>>,
): Listen<T> {
	return (key: any, fn: Listener<any>) => {
		const set = get(events, key);
		const f: (v: any, ctx: EventContext) => void = (...v) => fn(...v);
		set.add(f);
		return () => { set.delete(f); };
	};
}

export function createEmit<T extends object>(
	events: Record<string | symbol, Set<(v: any, ctx: EventContext) => boolean>>,
): Emit<T> {
	return (key: any, value: any, opt?: EmitOption) => {
		const set = key in events && events[key];
		if (!(set instanceof Set)) { return true; }

		let prevented = true;
		let stop = false;
		const cancelable = Boolean(opt?.cancelable);
		const ctx: EventContext = {
			stop() { stop = true; },
			prevent() { prevented = true; },
			get cancelable() { return cancelable; },
			get prevented() { return prevented; },
		};
		for (const f of [...set]) {
			f(value, ctx);
			if (stop) { break; }
		}
		return !prevented;
	};
}
