import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.show)
    ? (_openBlock(), _createBlock($setup["ElButton"], {
        key: 0,
        icon: $setup.Switch,
        disabled: $props.loading,
        size: "small",
        title: $setup.tt('Reorder'),
        onClick: $setup.click
      }, null, 8 /* PROPS */, ["icon", "disabled", "title"]))
    : _createCommentVNode("v-if", true)
}