<template>
	<AgGridVue
		class="ag-theme-guigu assess-table"
		:rowData="assessList"
		:defaultColDef="defaultColDef"
		animateRows="true"
		@grid-ready="onGridReady"
		:suppressDragLeaveHidesColumns="smallMeta"
		:localeText="zhCN"
		rowGroupPanelShow="always"
		:getRowId="getRowId"
	>
	</AgGridVue>
	<el-alert :title="prompt" type="warning" show-icon :closable="false" />
</template>
<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { AgGridVue } from 'ag-grid-vue3';
import { ElMessageBox, ElMessage } from 'element-plus';

import * as Permission from '../../Permission';
import type { Assess, Level, Permissions } from '../type';
import { useMetaQuery } from '../../../../../../../../guigu_pm/guigu_pm/public/js/components/page/useMetaQuery';
import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';

import { getColumns } from './helper';

interface Props {
	assessList: Assess[];
	week: string;
	department: string;
	type: string;
}
const props = defineProps<Props>();
const prompt = __(
	'Tip: If there are no records of relevant members in the list, it indicates that the member has not created any assessment records in the corresponding month',
);
const smallMeta = useMetaQuery();
const gridApi = ref<any>(null);

const onGridReady = (params: any) => {
	gridApi.value = params.api;
};

function gotoDetail(data: Assess) {
	if (!props.week || !props.department || !props.type) {
		return;
	}
	frappe.router.push_state(
		`guigu_hr_week_assessment_detail?user=${data.name}&week=${props.week}&department=${props.department}&type=${props.type}&organization=${data?.organization_id}`,
	);
}
function gotoInterview(data: Assess) {
	if (!props.week || !props.department || !props.type) {
		return;
	}
	frappe.router.push_state(
		`guigu_hr_assessment_interview?user=${data.name}&date=${props.week}&department=${props.department}&type=${props.type}&period_type=week&organization=${data?.organization_id}`,
	);
}
watch([smallMeta, gridApi], ([smallMeta, api]) => {
	api?.setColumnDefs(getColumns(gotoDetail, gotoInterview, smallMeta));
});
const defaultColDef = {
	sortable: true,
	filter: true,
	flex: 1,
	resizable: true,
	minWidth: 150,
	suppressMovable: true,
};

function getRowId(params: any) {
	return params.data.name;
}
</script>
<style lang="less" scoped>
.assess-table {
	width: 100%;
	height: 93%;
}
.el-alert {
	height: 32px;
}
</style>
