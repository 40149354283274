<template>
	<Page>
		<template #title>
			<div class="header-container">
				<h3 class="title">{{ title }}</h3>
				<span v-show="store.isChange" class="indicator-pill no-indicator-dot whitespace-nowrap orange"><span>{{ tt('Not Saved') }}</span></span>
			</div>
		</template>
		<template #action>
			<ElSpace>
				<ElButton @click="navigateBack">{{ tt('Navigate Back') }}</ElButton>
				<ElDropdown @command="handleCommand">
					<ElButton :icon="MoreFilled"></ElButton>
					<template #dropdown>
						<ElDropdownMenu>
							<ElDropdownItem command="loadTemplate">{{ tt('Load Instruction Template') }}</ElDropdownItem>
							<ElDropdownItem command="openVersion">{{ tt('Open Other Version') }}</ElDropdownItem>
							<ElDropdownItem command="saveVersion">{{ tt('Save New Version') }}</ElDropdownItem>
						</ElDropdownMenu>
					</template>
				</ElDropdown>
				<ElButton type="primary" @click="save">{{ tt('Save') }}</ElButton>
			</ElSpace>
		</template>
		<Instruction
			:referenceType="referenceType"
			:referenceName="referenceName"
			:instructionName="instructionName"
			:editable="editable"
			:mode="'edit'"
		></Instruction>
		<div class="dialog-container">
			<SaveDialog
				v-model:visible="visible"
			></SaveDialog>
			<VersionDialog
				v-model:visible="versionVisible"
			></VersionDialog>
			<TemplateDialog
				v-model:visible="templateVisible"
			></TemplateDialog>
		</div>
	</Page>
</template>

<script setup lang='ts'>
import {computed, getCurrentInstance, onMounted, ref} from 'vue';
import {ElButton, ElDropdown, ElIcon, ElDropdownMenu, ElDropdownItem, ElSpace} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue';

import Instruction from '../instruction/Instruction.vue';
import Page from '../../../../../../../../guigu/guigu/public/js/components/page/HeaderSlotPage.vue';
import {useInstructionStore} from '../instruction/store';
import {doctypeBlocks} from '../blocks';
import TemplateDialog from '../instruction/TemplateDialog.vue';
import VersionDialog from '../instruction/VersionDialog.vue';
import SaveDialog from '../instruction/SaveDialog.vue';
const editable = ref<boolean>(true);
const referenceType = ref<string>('');
const referenceName = ref<string>('');
const instructionName = ref<string>('');
const tt = __;
const store = useInstructionStore();
const visible = ref<boolean>(false);
const versionVisible = ref<boolean>(false);
const templateVisible = ref<boolean>(false);
const title = computed(()=>referenceType.value === 'Dw Process' ? __('Process Description') : __('Work Instruction'));
function handleCommand(command: 'loadTemplate'|'openVersion'|'saveVersion') {
	switch (command) {
		case 'loadTemplate':{
			templateVisible.value = true;
			break;
		}
		case 'openVersion':{
			versionVisible.value = true;
			break;
		}
		case 'saveVersion':{
			visible.value = true; store.isSaveNewVersion = true;
			break;
		}
	}
}
function navigateBack() {
	history.back();
}
function save() {
	visible.value = true;
	store.isSaveNewVersion = false;
}
store.afterSave(({instruction})=>{
	const search = new URLSearchParams();
	if (instruction?.name) {
		search.set('instruction_name', instruction?.name);
	} else {
		search.set('reference_name', referenceName.value);
	}
	search.set('reference_type', referenceType.value);
	const searchString = search.toString();

	history.replaceState({}, '', `?${encodeURIComponent(searchString)}`);
});
function getSearchParams() {
	const search = new URLSearchParams(decodeURIComponent(window.location.search));
	const reference_type = search.get('reference_type');
	const reference_name = search.get('reference_name');
	const instruction_name = search.get('instruction_name');

	instructionName.value = instruction_name || '';
	referenceType.value = reference_type || '';
	referenceName.value = reference_name || '';
	const instance = getCurrentInstance();
	const blocks = doctypeBlocks[reference_type || ''] || [];
	if (!instance) {
		return;
	}
	instance.appContext.config.globalProperties.$CustomBlocks = blocks;
}
onMounted(async () => {
	getSearchParams();
});
$(frappe.pages.dw_instruction).on('show', function() {
	getSearchParams();
});
</script>

<style lang='less' scoped>
.header-container{
	display: flex;
}
  .title {
    margin-bottom: 0;
	margin-right: 10px
  }

.dialog-container :deep(.el-dialog__body){
	padding-top:0;
}
</style>
