import { createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.hasPerm)
    ? (_openBlock(), _createBlock($setup["ElDropdown"], {
        key: 0,
        onCommand: $setup.command
      }, {
        dropdown: _withCtx(() => [
          _createVNode($setup["ElDropdownMenu"], null, {
            default: _withCtx(() => [
              ($setup.addable)
                ? (_openBlock(), _createBlock($setup["ElDropdownItem"], {
                    key: 0,
                    command: "add"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Add Child')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true),
              ($setup.copyable)
                ? (_openBlock(), _createBlock($setup["ElDropdownItem"], {
                    key: 1,
                    command: "copy"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Copy')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true),
              ($setup.deletable)
                ? (_openBlock(), _createBlock($setup["ElDropdownItem"], {
                    key: 2,
                    command: "del"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Delete')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        default: _withCtx(() => [
          _createVNode($setup["ElIcon"], { class: "icon" }, {
            default: _withCtx(() => [
              _createVNode($setup["MoreFilled"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }))
    : _createCommentVNode("v-if", true)
}