<template>
	<div class="action_container" :style="containerStyle" v-if="!hideAction">
		<ElTooltip :content="tt('Edit')" v-if="props.params.onEdit">
			<ElButton :style="{ 'margin': '0', display:'flex' }" :icon="Edit"
				@click="edit" />
		</ElTooltip>
		<ElTooltip :content="tt('UnRelate')" v-if="props.params.unRelate">
			<ElButton :style="{ 'margin': '0', display:'flex' }" :icon="Link"
				@click="unRelate" />
		</ElTooltip>
		<ElPopconfirm
			v-if="props.params.onRemove"
			:title="tt('Are you sure you want to directly delete this data?')"
			@confirm="deleteRow"
			:confirmButtonText="tt('Confirm')"
			:cancelButtonText="tt('Cancel')">
			<template #reference>
				<div :style="{ 'margin': '0', display:'flex' }">
					<ElTooltip :content="tt('Delete')">
						<ElButton>
							<ElIcon>
								<Delete color="#E24C4C" />
							</ElIcon>
						</ElButton>
					</ElTooltip>
				</div>
			</template>
		</ElPopconfirm>
	</div>
</template>

<script setup lang='ts'>
import {computed} from 'vue';
import {ElButton, ElPopconfirm, ElTooltip, ElIcon} from 'element-plus';
import {Delete, Edit, Link} from '@element-plus/icons-vue';
const tt = __;
interface Params {
	data: any
	onRemove?: (data: any) => void,
	onEdit?: (data: any) => void
	unRelate?: (data: any) => void
}
const props = defineProps<{ params: Params }>();
const hideAction = computed(() => props.params.node.group || props.params.node.data.__IS_AGGREGATION__);

function deleteRow() {
	props.params.onRemove?.(props.params.data);
}
function edit() {
	props.params.onEdit?.(props.params.data);
}
function unRelate() {
	props.params.unRelate?.(props.params.data);
}
const containerStyle = {
	display: 'flex',
	height: '100%',
	'justify-content': 'space-around',
	'align-items': 'center',
};
</script>

<style lang='less' scoped>
.action_container {
	display: flex;
	height: 100%;
	justify-content: space-around;
	align-items: center;
}
</style>
