<template>
	<div class="toolbar">
		<div class="input-container">
			{{ tt('Company') }}:
			<ElSelect class="input" filterable v-model="companyValue" size="small" @change="companyChange">
				<ElOption v-for="item in companies" :key="item.name" :label="item.company_name" :value="item.name" />
			</ElSelect>
		</div>
		<div class="input-container">
			{{ tt('Project') }}:
			<ElSelect class="input" filterable v-model="projectValue" size="small" popperClass="projectLink">
				<ElOption
					v-for="item in projects"
					:key="item.name"
					:label="item.project_abbr_name||item.project_name"
					:value="item.name">
					<p>
						<strong>{{ item.project_abbr_name|| item.project_name }}</strong>
						<span>{{ item.description }}</span>
					</p>
				</ElOption>
			</ElSelect>
		</div>
		<div class="input-container">
			{{ tt('Material Month') }}:
			<ElDatePicker
				v-model="monthValue"
				:teleported="false"
				:clearable="false"
				:editable="false"
				type="month"
				valueFormat="YYYY-MM-DD"
				:disabledDate="disabledDate"
				size="small" />
		</div>
		<ElCheckbox class="material_tool_checkbox" v-model="isTotal" :label="tt('Accumulation')" size="small" ></ElCheckbox>
		<ElCheckbox class="material_tool_checkbox" v-model="isShowConsume" :label="tt('Show Consumption Only')" size="small" ></ElCheckbox>
		<ElCheckbox class="material_tool_checkbox" v-model="isShowDetail" :label="tt('Show Material Detail')" size="small" ></ElCheckbox>
	</div>
</template>

<script setup lang="ts">
import {onMounted, ref, watch, computed} from 'vue';
import {ElDatePicker, ElCheckbox, ElSelect, ElOption} from 'element-plus';
import moment, {type Moment} from 'moment';

import type {Company, Projects} from '../type';

const emit = defineEmits<{(event: 'updateIndexParams', params: {
	company: string;
	project: string;
	month: string;
	isTotal: boolean;
	isShowConsume: boolean;
	isShowDetail: boolean;
	hotName: string;
}):void;
}>();
const tt = __;
const companies = ref<Company[]>([]);
const projects = ref<Projects[]>([]);
const isTotal = ref<boolean>(true);
const isShowConsume = ref<boolean>(true);
const isShowDetail = ref<boolean>(false);
const companyValue = ref<string>('');
const projectValue = ref<string>('');
const monthValue = ref<string>(moment().startOf('month').subtract(1, 'month')
	.format('YYYY-MM-DD'));
/**
 * 当前月份之后禁选
 * @param time
 * @returns boolean
 */
function disabledDate(time: Moment) {
	return time > moment().startOf('month');
}
// 计算项目+年月
const hotName = computed(() => {
	const projectName = projects.value.find(item => item.name === projectValue.value)?.project_abbr_name || '';
	const project_year_month = `${projectName}${moment(monthValue.value).format('YYYY年MM月')}`;
	return project_year_month;
});
/**
 * 更新父组件中的查询参数
 */
function updateParams() {
	emit('updateIndexParams', {
		company: companyValue.value,
		project: projectValue.value,
		month: monthValue.value,
		isTotal: isTotal.value,
		isShowConsume: isShowConsume.value,
		isShowDetail: isShowDetail.value,
		hotName: hotName.value,
	});
}
/**
 * 获取项目列表
 * 过滤条件：已选择公司下的项目
 */
async function getProjects(company: string, searchProject?: string) {
	const projectRes: Projects[] = await frappe.db.get_list('Project', {
		filters: [['company', '=', company]],
		fields: ['name', 'project_abbr_name', 'project_name', 'project_construction_status'],
		limit: 0,
	});
	if (projectRes.length === 0) {
		projects.value = [];
		return;
	}
	projects.value = projectRes.map(item=>{
		item.description = `${item.name},${__(item.project_name)}${item.project_construction_status ? ',' : ''}${__(item.project_construction_status)}`;
		return item;
	});
	if (projectValue.value === '') {
		projectValue.value = searchProject || projects.value[0].name;
		updateParams();
	}
}

/**
 * 获取公司列表
 */
async function getCompanies() {
	const companiesRes: Company[] = await frappe.db.get_list('Company', {
		fields: ['name', 'company_name'],
		limit: 0,
	});
	companies.value = companiesRes;
	if (companyValue.value === '') {
		companyValue.value = companies.value[0]?.company_name || '';
	}
	getProjects(companyValue.value);
}
// 获取路径参数
function getSearchParams() {
	const params = new URLSearchParams(window.location.search);
	const searchCompany = params.get('company');
	const searchProject = params.get('project') || '';
	if (params.get('month')) {
		monthValue.value = params.get('month') || '';
	}
	if (params.get('isTotal')) {
		isTotal.value = params.get('isTotal') === '1';
	}
	if (params.get('isShowConsume')) {
		isShowConsume.value = params.get('isShowConsume') === '1';
	}
	if (params.get('isShowDetail')) {
		isShowDetail.value = params.get('isShowDetail') === '1';
	}
	if (searchCompany) {
		companyValue.value = searchCompany;
		getProjects(searchCompany, searchProject);
	}
}

onMounted(() => {
	getCompanies();
	getSearchParams();
});
// 监听参数变化
watch(
	[companyValue, projectValue, monthValue, isTotal, isShowConsume, isShowDetail],
	([newCompany, newProject, newMonth, newIsTotal, newIsShowConsume, newIsShowDetail]) => {
		updateParams();
		const queryObj = new URLSearchParams({
			company: newCompany || '',
			project: newProject || '',
			month: newMonth || '',
			isTotal: newIsTotal ? '1' : '0',
			isShowConsume: newIsShowConsume ? '1' : '0',
			isShowDetail: newIsShowDetail ? '1' : '0',
		});
		history.replaceState(null, '', `?${queryObj.toString()}`);
	},
);
// 公司变化
function companyChange() {
	projectValue.value = '';
	getProjects(companyValue.value);
}
</script>

<style lang="less" scoped>
  .toolbar {
    display: flex;
    flex-direction: row;
		flex-wrap: wrap;
		align-items: center;

		.input-container {
			white-space: nowrap;
			margin-right: 10px;

			.input {
				min-width: 150px;
			}
		}
		.material_tool_checkbox {
        margin-bottom: 0;
    }
  }
  .projectLink {
	.el-select-dropdown__item {
		height: 59px !important;
	}

	.el-select-dropdown__item > p{
		display: flex;
		flex-direction: column;

	}
	.el-select-dropdown__item > p > span {
		font-size: 12px;
		line-height: 1em;
	}
}
@media (min-width: 640px) {
	.input-container {
		display: flex;
		margin-right: 8px;
	}
	.right-container{
		float: right
	}
}

@media (max-width: 640px) {
	.input-container {
		width: 100%;
	}
	.right-container{
		float: none;
		padding-top: 12px;
	}
}
</style>
