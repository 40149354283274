import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createBlock($setup["ElTree"], {
    class: "container",
    data: $props.users,
    nodeKey: "value",
    expandOnClickNode: false,
    onNodeClick: $setup.click,
    props: { class: $setup.customNodeClass }
  }, null, 8 /* PROPS */, ["data", "props"])), [
    [_directive_loading, $setup.loading]
  ])
}