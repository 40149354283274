import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f2de3bf1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "tableContainer",
  class: "table-container"
}
const _hoisted_2 = { class: "pagination" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.data && !$setup.isHidden)
      ? (_openBlock(), _createBlock($setup["AgGridVue"], {
          key: 0,
          class: "ag-theme-guigu",
          columnDefs: $setup.columnDefs,
          rowData: $setup.rowData,
          defaultColDef: $setup.defaultColDef,
          rowSelection: "multiple",
          animateRows: "true",
          onGridReady: $setup.onGridReady,
          treeData: $setup.isTree,
          groupDefaultExpanded: "-1",
          getDataPath: $setup.getDataPath,
          getRowNodeId: $setup.getRowId,
          domLayout: "autoHeight",
          autoGroupColumnDef: $setup.autoGroupColumn,
          localeText: $setup.zhCN,
          rowClassRules: $setup.rowClassRules,
          rowDrag: $setup.dragEnable,
          onRowDragEnd: $setup.onRowDragEnd,
          onRowDragMove: $setup.onRowDragMove,
          onRowDragLeave: $setup.onRowDragLeave,
          excludeChildrenWhenTreeDataFiltering: "",
          rowGroupPanelShow: $setup.rowGroupPanelShow,
          pagination: !$props.connectionFieldSetting?.hide_pagination,
          paginationPageSize: 10,
          suppressPaginationPanel: "",
          onColumnRowGroupChanged: $setup.ColumnRowGroupChanged,
          onSelectionChanged: $setup.selectionChanged,
          onFirstDataRendered: $setup.onFirstDataRendered,
          groupAllowUnbalanced: false,
          onPaginationChanged: $setup.paginationChanged,
          onFilterChanged: $setup.filterChanged,
          groupSelectsFiltered: "",
          overlayNoRowsTemplate: $setup.overlayNoRowsTemplate,
          suppressDragLeaveHidesColumns: $setup.smallMeta
        }, null, 8 /* PROPS */, ["columnDefs", "rowData", "defaultColDef", "treeData", "autoGroupColumnDef", "localeText", "rowDrag", "rowGroupPanelShow", "pagination", "suppressDragLeaveHidesColumns"]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      (!$props.connectionFieldSetting?.hide_pagination)
        ? (_openBlock(), _createBlock($setup["ElPagination"], {
            key: 0,
            layout: "prev, pager, next",
            total: $setup.total,
            pageSize: 10,
            onCurrentChange: $setup.pageChange
          }, null, 8 /* PROPS */, ["total"]))
        : _createCommentVNode("v-if", true)
    ])
  ], 512 /* NEED_PATCH */))
}