import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a00d365c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Page"], null, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.tt('Dw Standard Process Library List')), 1 /* TEXT */)
    ]),
    tools: _withCtx(() => [
      _createVNode($setup["Tools"], {
        smallMeta: $setup.smallMeta,
        levels: $setup.levels,
        processes: $setup.processes,
        permission: $setup.permission,
        layoutType: $setup.layoutType,
        "onUpdate:layoutType": _cache[0] || (_cache[0] = $event => (($setup.layoutType) = $event)),
        disable: $setup.disable,
        "onUpdate:disable": _cache[1] || (_cache[1] = $event => (($setup.disable) = $event)),
        levelValue: $setup.levelValue,
        "onUpdate:levelValue": _cache[2] || (_cache[2] = $event => (($setup.levelValue) = $event)),
        onExpandOrCollapse: $setup.expandOrCollapse,
        onShowProcessModalOrNavigate: $setup.showProcessModalOrNavigate
      }, null, 8 /* PROPS */, ["smallMeta", "levels", "processes", "permission", "layoutType", "disable", "levelValue"])
    ]),
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createBlock($setup["Skeleton"], {
        layout: $setup.layoutType,
        doctype: "Dw Process",
        detailName: $setup.detailName,
        onOnChange: $setup.onChange,
        "form-visible": $setup.formVisible,
        "onUpdate:formVisible": _cache[3] || (_cache[3] = $event => (($setup.formVisible) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode($setup["Table"], {
            ref: "aggrid",
            class: "table-container",
            smallMeta: $setup.smallMeta,
            dataList: $setup.dataList,
            loading: $setup.loading,
            permission: $setup.permission,
            levelList: $setup.levelList,
            onUpdateData: $setup.updateData,
            onShowProcessModalOrNavigate: $setup.showProcessModalOrNavigate
          }, null, 8 /* PROPS */, ["smallMeta", "dataList", "loading", "permission", "levelList"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["layout", "detailName", "form-visible"])), [
        [$setup["vLoading"], $setup.loading]
      ])
    ]),
    _: 1 /* STABLE */
  }))
}