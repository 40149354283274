<template>
	<div v-html="imageHTML" class="user-image"></div>
</template>

<script setup lang='ts'>
import { computed, defineProps, defineEmits } from 'vue';

interface Props{
	name:string,
	title?:string,
	url?:string
}
const props = defineProps<Props>();
interface Emit{

}
const emit = defineEmits<Emit>();
const imageHTML = computed(()=>frappe.avatar(props.name, 'guigu_diary_avatar', props.title, props.url));

</script>

<style lang='less' scoped>
.user-image {
	display: inline-block;
	width: 24px;
	height: 24px;
	margin-right: 8px;
	white-space: nowrap;

	:deep(.guigu_diary_avatar) {
		width: 24px;
		height: 24px;
	}

}
</style>
