import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38e8ed3f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElInput = _resolveComponent("ElInput")
  const _component_ElFormItem = _resolveComponent("ElFormItem")
  const _component_ElInputNumber = _resolveComponent("ElInputNumber")
  const _component_ElForm = _resolveComponent("ElForm")
  const _component_ElButton = _resolveComponent("ElButton")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $setup.visible,
    "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => (($setup.visible) = $event)),
    title: $setup.tt('Editing progress'),
    width: $setup.dialogWidth,
    "before-close": $setup.handleClose,
    "custom-class": "progress-dialog"
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_ElButton, { onClick: $setup.handleClose }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_ElButton, {
          type: "primary",
          onClick: $setup.confirm
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Confirm')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_ElForm, {
        ref: "formRef",
        "label-width": "auto",
        model: $setup.form,
        rules: $setup.rules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ElFormItem, {
            label: $setup.tt('Construction content'),
            props: "name"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElInput, {
                disabled: "",
                modelValue: $setup.form.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.form.name) = $event))
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_ElFormItem, {
            label: $setup.tt('Unit'),
            props: "unit"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElInput, {
                disabled: "",
                modelValue: $setup.form.unit,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.form.unit) = $event))
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_ElFormItem, {
            label: $setup.tt('Weekly planned quantity'),
            props: "week_plan_quantity"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElInput, {
                disabled: "",
                modelValue: $setup.form.week_plan_quantity,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.form.week_plan_quantity) = $event))
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_ElFormItem, {
            label: $setup.tt('Unfinished quantity this week'),
            props: "complete_quantity"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElInput, {
                disabled: "",
                modelValue: $setup.form.complete_quantity,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.form.complete_quantity) = $event))
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_ElFormItem, {
            label: $setup.tt('Today planned quantity'),
            props: "today_plan_quantity"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElInput, {
                disabled: "",
                modelValue: $setup.form.today_plan_quantity,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.form.today_plan_quantity) = $event))
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          ($setup.enableAudit)
            ? (_openBlock(), _createBlock(_component_ElFormItem, {
                key: 0,
                label: $setup.tt('Today actual quantity'),
                props: "report_quantity"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElInputNumber, {
                    modelValue: $setup.form.report_quantity,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.form.report_quantity) = $event)),
                    onChange: $setup.changeReport
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]))
            : _createCommentVNode("v-if", true),
          (!$setup.enableAudit)
            ? (_openBlock(), _createBlock(_component_ElFormItem, {
                key: 1,
                label: $setup.tt('Today actual quantity'),
                props: "actual_quantity"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElInputNumber, {
                    modelValue: $setup.form.actual_quantity,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($setup.form.actual_quantity) = $event))
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]))
            : _createCommentVNode("v-if", true),
          ($setup.enableAudit)
            ? (_openBlock(), _createBlock(_component_ElFormItem, {
                key: 2,
                label: $setup.tt('Today review volume'),
                props: "actual_quantity"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElInputNumber, {
                    modelValue: $setup.form.actual_quantity,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($setup.form.actual_quantity) = $event))
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_ElFormItem, {
            label: $setup.tt('Tomorrow Plan Quantity'),
            props: "tomorrow_plan_quantity"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElInputNumber, {
                modelValue: $setup.form.tomorrow_plan_quantity,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => (($setup.form.tomorrow_plan_quantity) = $event))
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_ElFormItem, {
            label: $setup.tt('Remark'),
            props: "remark"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElInput, {
                modelValue: $setup.form.remark,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => (($setup.form.remark) = $event))
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["model", "rules"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title", "width"]))
}