import { App, Plugin, createApp } from 'vue';

import ElementPlus from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn';

import TreeSelect from './index.vue';

frappe.ui.form.ControlTreeSelect = class ControlTreeSelect extends frappe.ui.form.ControlInput {
	app:App<Element>|undefined = undefined;
	$input:HTMLElement|undefined = undefined;
	df:any;
	input_area:HTMLElement|undefined;
	last_value:string = '';
	value:string = '';
	has_input:boolean=false;
	make_input() {
		if (this.$input||this.app) { return; }

		const propsDefault:Record<string, any>={
			df:this.df,
			treeControl:this,
		};
		if (this.df.input_css) {
			propsDefault.css = this.df.input_css;
		}
		if (this.df.input_class) {
			propsDefault.input_class = this.df.input_class;
		}

		const me = this;
		const app = createApp(TreeSelect, {
			class:'input-with-feedback form-control',
			...propsDefault,
		});
		app.use(ElementPlus, { size: 'small', locale: zhCn });
		app.mount(this.input_area);
		if (app._props){
			app._props.onChange = (value:string) => {
				this.parse_validate_and_set_in_model(value);
			};
			app._props.onFocus = () => {
				me.get_custom_queries();
			};
		}
		this.app = app;
		this.has_input = true;
	}

	set_input(value:string) {
		this.last_value = this.value;
		this.value = value;
		this.set_formatted_input(value);
		this.set_disp_area(value);
		this.set_mandatory && this.set_mandatory(value);
	}
	set_formatted_input(value) {
		this.app._instance.props.value=value;
	}
	format_for_input(val?:string) {
		return val == null ? '' : val;
	}
	validate(v?:string) {
		if (!v) {
			return '';
		}
			return v;
	}
	get_input_value() {
		return this.app?._instance?.props?.value||null;
	}
	get_custom_queries(){
		const args = {};
		this.set_custom_query(args);
		this.app._instance.props.queries=args;
	}
	set_custom_query(args:Record<string, any>) {
		const is_valid_value = (value:any, key:string) => {
			if (value) { return true; }
			// check if empty value is valid
			if (this.frm) {
				let field = frappe.meta.get_docfield(this.frm.doctype, key);
				// empty value link fields is invalid
				return !field || !['Link', 'Dynamic Link', 'Tree Select'].includes(field.fieldtype);
			}
				return value !== undefined;

		};

		const set_nulls = (obj:Record<string, any>) => {
			$.each(obj, (key, value) => {
				if (!is_valid_value(value, key)) {
					delete obj[key];
				}
			});
			return obj;
		};
		if (this.get_query || this.df.get_query) {
			var get_query = this.get_query || this.df.get_query;
			if ($.isPlainObject(get_query)) {
				var filters = null;
				if (get_query.filters) {
					// passed as {'filters': {'key':'value'}}
					filters = get_query.filters;
				} else if (get_query.query) {
					// passed as {'query': 'path.to.method'}
					args.query = get_query;
				} else {
					// dict is filters
					filters = get_query;
				}

				if (filters) {
					filters = set_nulls(filters);

					// extend args for custom functions
					$.extend(args, filters);

					// add "filters" for standard query (search.py)
					args.filters = filters;
				}
			} else if (typeof get_query === 'string') {
				args.query = get_query;
			} else {
				// get_query by function
				var q = get_query(
					(this.frm && this.frm.doc) || this.doc,
					this.doctype,
					this.docname
				);

				if (typeof q === 'string') {
					// returns a string
					args.query = q;
				} else if ($.isPlainObject(q)) {
					// returns a plain object with filters
					if (q.filters) {
						set_nulls(q.filters);
					}

					// turn off value translation
					if (q.translate_values !== undefined) {
						this.translate_values = q.translate_values;
					}

					// extend args for custom functions
					$.extend(args, q);

					// add "filters" for standard query (search.py)
					args.filters = q.filters;
				}
			}
		}
		if (this.df.filters) {
			set_nulls(this.df.filters);
			if (!args.filters) { args.filters = {}; }
			$.extend(args.filters, this.df.filters);
		}
	}
};
