<template>
	<div
		:class="colGroups.length ? rowGroups.length ? 'root' : 'col' : 'row'"
		ref="el">
		<Headers :groups="colGroups" :width="colWidths[1]" @setting="edit"
			:widths="colWidths[0]" :start="rowGroups.length" :data="data" />
		<Headers :groups="rowGroups" :width="rowWidths[1]" :cc="!!colGroups.length"
			@setting="edit" :data="data"
			:widths="rowWidths[0]" :start="colGroups.length" />

		<div class="inner" v-for="p in params">
			<button @click="create(p)" class="add">+</button>
			<ul ref="list" :data-value="jsonStringify(p)">
				<li class="item" v-for="it in getList(p)" :data-id="it.name"
					:key="it.name">
					<slot :value="it">{{ it.name }}</slot>
				</li>
			</ul>
		</div>
	</div>
</template>

<script lang="ts" setup name="KanbanBoard">
import {computed, onMounted, onUnmounted, ref} from 'vue';

import dragula from 'dragula';

import type {Column, Group} from '../../types';

import Headers from './Headers.vue';
import {getWidths, toList} from './utils';


const props = defineProps<{
	data: any[];
	groups: Group[];
}>();
const emit = defineEmits<{
	(event: 'update', blockId: string, newState: any): void;
	(event: 'setting', field: string, value: Column): void
	(event: 'create', data: Record<string, any>): void
}>();
function create(p: Record<string, any>) {
	emit('create', p);
}
const colGroups = computed(() => props.groups.filter(v => !v.column));
const rowGroups = computed(() => props.groups.filter(v => v.column));
const rowWidths = computed(() => getWidths(rowGroups.value));
const colWidths = computed(() => getWidths(colGroups.value));
const rowParams = computed(() => toList(rowGroups.value));
const colParams = computed(() => toList(colGroups.value));

const params = computed(() => {
	const col = rowParams.value;
	const row = colParams.value;
	return col.flatMap(c => row.map(r => ({...c, ...r})));
});

function toValue(v: any) {
	return ['string', 'number'].includes(typeof v) ? v : '';
}
function getList(p: Record<string, any>) {
	const pp = Object.entries(p);
	return props.data.filter(d => !pp.find(([k, v]) => toValue(d[k]) !== v));
}


function edit(field: string, value: Column) {
	emit('setting', field, value);
}
function jsonStringify(v: any) {
	return JSON.stringify(v);
}
const el = ref<HTMLElement>();
const list = ref<HTMLElement[]>([]);
let d: any;
onMounted(() => {
	if (d) {
		d.destroy();
	}
	d = dragula(list.value, {mirrorContainer: el.value!})
		.on('drop', (block: HTMLElement, list: HTMLElement) => {
			const value = JSON.parse((list as HTMLElement).dataset.value || '{}');
			const id = (block as HTMLElement).dataset.id || '';
			emit('update', id, value);
		});
});
onUnmounted(() => {
	if (d) {
		d.destroy();
	}
	d = undefined;
});
</script>

<style lang="less" scoped>
@ease-out: all .3s cubic-bezier(0.23, 1, 0.32, 1);

.root,
.row,
.col {
	height: max-content;
	width: max-content;
	overflow: visible;
	display: grid;
	--row-group-width: 240px;
	--col-group-width: 42px;

	* {
		box-sizing: border-box;
	}

}

.row {
	min-width: 100%;
	writing-mode: vertical-lr;
	grid-template-columns: repeat(v-bind('rowParams.length'), auto);
	grid-template-rows: repeat(v-bind('rowGroups.length'), var(--row-group-width)) auto;
}

.col {
	min-height: 100%;
	grid-template-columns: repeat(v-bind('colParams.length'), 240px);
	grid-template-rows: repeat(v-bind('colGroups.length'), var(--col-group-width)) auto;

}

.root {
	grid-template-columns:
		repeat(v-bind('rowGroups.length'), var(--row-group-width)) repeat(v-bind('colParams.length'), 240px);
	grid-template-rows:
		repeat(v-bind('colGroups.length'), var(--col-group-width)) repeat(v-bind('rowParams.length'), auto);

	&::before {
		content: '';
		grid-row: span v-bind('colGroups.length');
		grid-column: span v-bind('rowGroups.length');
	}
}


.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;

	h2>a {
		float: right;
	}
}

.add {
	flex-shrink: 0;
}

.inner {
	display: flex;
	min-block-size: 50px;
	border: 1px solid #EEEEEE;
	flex-direction: column;
	overflow: auto;
	max-block-size: calc(100vh - 75px - var(--navbar-height) - v-bind('colGroups.length') * var(--col-group-width));

	.row & {
		max-block-size: calc(100vw - v-bind('colGroups.length') * var(--col-group-width));

	}

	>ul {
		flex: 1;
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	> :first-child {
		border-style: dashed;
		margin: 4px;
		background-color: transparent;
		overflow: hidden;
		border-color: currentColor;
		border-radius: var(--border-radius-md);
		color: #AAAAAA;
	}
}

.item {
	padding: 10px;
	margin: 4px;
	transition: @ease-out;
	min-block-size: 80px;
	border-radius: var(--border-radius-md);
	box-shadow: var(--card-shadow);
	background-color: var(--kanban-card-bg);
	cursor: grab;
	padding: var(--padding-sm);

	.row & {
		width: 240px;
	}

	&:global(.is-moving) {
		transform: scale(1.5);
		background: rgba(black, 0.8);
	}

	>* {
		writing-mode: horizontal-tb;
	}

}
</style>

<style lang="less">
/* Dragula CSS  */

.gu-mirror {
	position: fixed !important;
	margin: 0 !important;
	z-index: 9999 !important;
	opacity: 0.8;
	list-style-type: none;
}

.gu-hide {
	display: none !important;
}

.gu-unselectable {
	-webkit-user-select: none !important;
	-moz-user-select: none !important;
	-ms-user-select: none !important;
	user-select: none !important;
}

.gu-transit {
	opacity: 0.2;
}
</style>
