import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElSelect"], {
    modelValue: $setup.val,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.val) = $event)),
    teleported: false
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (option) => {
        return (_openBlock(), _createBlock($setup["ElOption"], {
          key: option.value,
          label: option.label,
          value: option.value
        }, null, 8 /* PROPS */, ["label", "value"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}