frappe.provide('frappe.dw_worklist_management.permission');
frappe.provide('frappe.dw_worklist_management.local');
frappe.dw_worklist_management.permission = frappe.dw_worklist_management.permission || {};
frappe.dw_worklist_management.local = frappe.dw_worklist_management.local || {};
export async function isManager() {
	const currentUser = frappe.session.user;
	if (frappe.dw_worklist_management.local[currentUser]?.isManager !== undefined) {
		return Promise.resolve(frappe.dw_worklist_management.local[currentUser].isManager);
	}
	const managers = await frappe.db.get_list('Dw Process Manager', {
		filters: {
			user: currentUser,
		},
		limit: 1,
	});
	const isManager = managers.length > 0;
	if (frappe.dw_worklist_management.local[currentUser]) {
		updateLocalPermission(currentUser, {isManager});
	} else {
		addLocalPermission(currentUser, {isManager});
	}
	return managers.length > 0;
}
export async function isApplier(organization:string) {
	const currentUser = frappe.session.user;
	if (!organization) {
		return Promise.resolve(false);
	}
	const key = `is_applier_${organization}`;
	if (frappe.dw_worklist_management.local[currentUser]?.[key] !== undefined) {
		return Promise.resolve(frappe.dw_worklist_management.local[currentUser]?.[key]);
	}
	const appliers = await frappe.db.get_list('Dw Organization Worklist Member', {
		filters: [
			['organization_unit', '=', organization],
			['user', '=', currentUser],
		],
		fields: ['user'],
		limit: 1,
	});
	const isApplier = appliers.length > 0;
	if (frappe.dw_worklist_management.local[currentUser]) {
		updateLocalPermission(currentUser, {[key]: isApplier});
	} else {
		addLocalPermission(currentUser, {[key]: isApplier});
	}
	return appliers.length > 0;
}
export async function hasApplier() {
	const currentUser = frappe.session.user;
	if (frappe.dw_worklist_management.local[currentUser]?.hasApplier !== undefined) {
		return Promise.resolve(frappe.dw_worklist_management.local[currentUser].hasApplier);
	}
	const user_appliers = await frappe.db.get_list('Dw Organization Worklist Member', {
		filters: [
			['user', '=', currentUser],
		],
	});
	const hasApplier = user_appliers.length > 0;
	if (frappe.dw_worklist_management.local[currentUser]) {
		updateLocalPermission(currentUser, {hasApplier});
	} else {
		addLocalPermission(currentUser, {hasApplier});
	}
	return hasApplier;
}

frappe.dw_worklist_management.permission.isManager = isManager;
frappe.dw_worklist_management.permission.isApplier = isApplier;
frappe.dw_worklist_management.permission.hasApplier = hasApplier;
function addLocalPermission(user:string, permission:Record<string, boolean>) {
	if (!frappe.dw_worklist_management.local[user]) {
		frappe.dw_worklist_management.local[user] = {};
	}
	updateLocalPermission(user, permission);
}
function updateLocalPermission(user:string, permission:Record<string, boolean>) {
	Object.keys(permission).map(key=>{
		frappe.dw_worklist_management.local[user][key] = permission[key];
	});
}
