import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fd69c16"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onDragstart", "onDrop", "draggable"]
const _hoisted_2 = { class: "label" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElFormItem"], null, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode($setup["ElSelect"], {
          placeholder: $setup.tt('Add grouping configuration'),
          onChange: $setup.add,
          filterable: "",
          defaultFirstOption: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.optionsAddible, (item) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: item.field
              }, [
                (!$setup.added.has(`${item.field}`))
                  ? (_openBlock(), _createBlock($setup["ElOption"], {
                      key: item.field,
                      value: item.field,
                      label: $setup.tt(item.label)
                    }, null, 8 /* PROPS */, ["value", "label"]))
                  : _createCommentVNode("v-if", true)
              ], 64 /* STABLE_FRAGMENT */))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["placeholder"]),
        _createVNode($setup["ElButton"], {
          onClick: _cache[0] || (_cache[0] = $event => ($setup.groups = [])),
          title: $setup.tt('Clear'),
          icon: $setup.Delete
        }, null, 8 /* PROPS */, ["title", "icon"])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.groups, (group) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "line",
          key: `${group.field}`,
          onDragover: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"])),
          onDragend: _cache[5] || (_cache[5] = $event => ($setup.currentGroup = undefined)),
          onDragstart: $event => ($setup.currentGroup = group),
          onDrop: _withModifiers($event => ($setup.drop(group)), ["prevent"]),
          draggable: $setup.draggable(group)
        }, [
          _createVNode($setup["ElButton"], {
            onPointerdown: $event => ($setup.draggableGroup = group),
            onPointerup: _cache[1] || (_cache[1] = $event => ($setup.draggableGroup = undefined)),
            onPointercancel: _cache[2] || (_cache[2] = $event => ($setup.draggableGroup = undefined)),
            onTouchmove: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"])),
            icon: $setup.Rank,
            text: ""
          }, null, 8 /* PROPS */, ["onPointerdown", "icon"]),
          _createElementVNode("div", _hoisted_2, _toDisplayString($setup.tt($setup.getLabel(group))), 1 /* TEXT */),
          _createVNode($setup["ElCheckbox"], {
            modelValue: Boolean(group.column),
            "onUpdate:modelValue": $event => ($setup.setColumn(group, $event)),
            label: $setup.tt('Is Swimming Lane'),
            size: "large"
          }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "label"]),
          _createVNode($setup["ElButton"], {
            onClick: $event => ($setup.remove(group)),
            icon: $setup.Delete,
            title: $setup.tt('Delete'),
            text: ""
          }, null, 8 /* PROPS */, ["onClick", "icon", "title"])
        ], 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_1))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }))
}