<template>
	<div>
		<div>{{ label }}</div>
	</div>
</template>

<script setup lang='ts'>
import { computed, watch } from 'vue';

const props = defineProps<{ params: any }>();

const label = computed(() => {
	const options = props.params.linkOptions?.find(item => item.value === props.params.value && props.params.fieldname === item.fieldname);
	if (options) {
		return options.label;
	}
	return props.params.value;
});
</script>

<style lang='less' scoped>
.link {
	color: #409eff;
}
</style>
