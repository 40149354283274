<template>
	<Select v-if="selOptions"
		:placeholder="label && tt(label)"
		:style="{width:'140px'}"
		v-model="val"
		:size="size"
		:popperClass="popperClass"
		:fitInputWidth="fitWidth"
		:options="selOptions" />
	<component
		v-else
		:is="comp"
		:style="style"
		v-model="val"
		:options="options"
		:name="name"
		:label="label"
		:size="size"
		:filter="filter"
		:popperClass="popperClass"
		:fitWidth="fitWidth"
		:required="required" />
</template>
<script lang="ts" setup>
import { computed } from 'vue';

import Time from './Time.vue';
import Data from './Data.vue';
import Datetime from './Datetime.vue';
import Date from './Date.vue';
import Select from './Select.vue';
import MultiSelect from './MultiSelect.vue';
import Int from './Int.vue';
import Float from './Float.vue';
import Password from './Password.vue';
import Rating from './Rating.vue';
import LongText from './LongText.vue';
import Currency from './Currency.vue';
import Check from './Check.vue';
import Link from './Link.vue';
import Percent from './Percent.vue';
import GuiguDate from './GuiguDate.vue';
import * as selectOptionMap from './selectOptionMap';
const props = defineProps<{
	label?: string;
	name: string;
	type: string;
	required?: boolean;
	filter?: boolean;
	options?: any;
	modelValue?: any;
	fitWidth?: boolean;
	popperClass?: string;
	listable?: boolean;
	size?: 'small' | 'default' | 'large'
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: any): void;
}>();
const tt = __;

const val = computed({
	get: () => props.modelValue,
	set: val => emit('update:modelValue', val),
});
const selOptions = computed(() => {
	if (!props.listable) { return; }
	if (props.name === 'docstatus') {
		return selectOptionMap.docstatus;
	}
	if (props.type.toLowerCase() === 'check') {
		return selectOptionMap.check;
	}
	return null;
});

const style = computed(()=>{
	if (props.type?.toLowerCase() === 'check'){
		return {};
	}
	return {width:'140px'};
});
const comp = computed(() => {
	switch (props.type?.toLowerCase()) {
		case 'date': return Date;
		case 'datetime': return Datetime;
		case 'time': return Time;
		case 'select': return Select;
		case 'multiselect': return MultiSelect;
		case 'int': return Int;
		case 'check': return Check;
		case 'percent': return Percent;
		case 'float': return Float;
		case 'password': return Password;
		case 'rating': return Rating;
		case 'currency': return Currency;

		case 'text editor':
		case 'html editor':
		case 'markdown editor':
		case 'code': return LongText;

		case 'json':
		case 'long text': return LongText;

		case 'link': return Link;
		// case 'phone':
		// case 'attach':
		// case 'autocomplete':
		// case 'attach image':
		// case 'barcode':
		// case 'duration':
		// case 'geolocation':
		// case 'icon':
		// case 'signature':
		default: return Data;


		case 'small text':
		case 'text':
		case 'read only':
		case 'color':
		case 'data': return Data;
		case 'guigu date': return GuiguDate;
		case 'tree select': return Link;

	}
});

</script>
