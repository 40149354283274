
import type { Kanban } from '../types';


export interface MenuItem {
	label: string,
	key: string;
	action(): void;
}

export default function* (
	meta: locals.DocType,
	saveFilter: () => void,
	deleteKanban: () => void,
	showSetting: () => void,
	kanban?: Kanban,
): Iterable<MenuItem> {
	if (kanban) {
		yield {
			key: 'save filters',
			label: __('Save filters'),
			action: saveFilter,
			// action: () => {
			// 	// this.save_kanban_board_filters();
			// },
		};
	}
	if (kanban) {
		yield {
			key: 'delete',
			label: __('Delete Kanban Board'),
			action: deleteKanban,
			// action: () => {
			// 	frappe.confirm('Are you sure you want to proceed?', () => {
			// 		frappe.db.delete_doc('Kanban Board', this.board_name).then(() => {
			// 			frappe.show_alert(`Kanban Board ${this.board_name} deleted.`);
			// 			frappe.set_route('List', this.doctype, 'List');
			// 		});
			// 	});
			// },
		};
	}
	if (frappe.model.can_import(meta.name, null, meta)) {
		yield {
			key: 'import',
			label: 'Import',
			action() {
				frappe.set_route('list', 'data-import', {
					reference_doctype: meta.name,
				});
			},
		};
	}
	if (frappe.model.can_set_user_permissions(meta.name)) {
		yield {
			key: 'userPermissions',
			label: 'User Permissions',
			action() {
				frappe.set_route('list', 'user-permission', {
					allow: meta.name,
				});
			},
		};
	}
	if (frappe.user_roles.includes('System Manager')) {
		yield {
			key: 'rolePermissionsManager',
			label: 'Role Permissions Manager',
			action() {
				frappe.set_route('permission-manager', {
					doctype: meta.name,
				});
			},
		};
	}
	if (
		frappe.model.can_create('Custom Field') &&
		frappe.model.can_create('Property Setter')
	) {
		yield {
			key: 'customize',
			label: 'Customize',
			action() {
				if (!meta) { return; }
				const doctype = meta.name;
				if (meta.custom) {
					frappe.set_route('form', 'doctype', doctype);
				} else if (!meta.custom) {
					frappe.set_route('form', 'customize-form', { doc_type: doctype });
				}
			},
		};
	}

	if (!meta.custom && frappe.user.has_role('System Manager') && frappe.boot.developer_mode) {
		yield {
			label: 'Edit DocType',
			key: 'editDocType',
			action() {
				frappe.set_route('form', 'doctype', meta.name);
			},
		};
	}
	if (kanban?.private || frappe.user.has_role('System Manager')) {
		yield { label: 'Settings', key: 'Settings', action: showSetting };
	}
}
