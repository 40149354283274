import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElTreeSelect = _resolveComponent("ElTreeSelect")
  const _component_ElFormItem = _resolveComponent("ElFormItem")
  const _component_ElInputNumber = _resolveComponent("ElInputNumber")
  const _component_ElForm = _resolveComponent("ElForm")
  const _component_ElButton = _resolveComponent("ElButton")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $setup.visible,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.visible) = $event)),
    title: $setup.tt('Related BOQ'),
    width: "50%",
    "before-close": $setup.handleClose,
    "custom-class": "progress-dialog"
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_ElButton, { onClick: $setup.handleClose }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_ElButton, {
          type: "primary",
          onClick: $setup.confirm
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Confirm')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_ElForm, {
          ref: "formRef",
          model: $setup.form,
          rules: $setup.rules,
          "label-width": "120px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElFormItem, {
              label: $setup.tt('Boq'),
              prop: "boqItem"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElTreeSelect, {
                  modelValue: $setup.form.boqItem,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.form.boqItem) = $event)),
                  data: $setup.boqTree,
                  props: $setup.defaultProps,
                  "default-expand-all": "",
                  "check-strictly": "",
                  "filter-node-method": $setup.filterNodeMethod,
                  filterable: ""
                }, null, 8 /* PROPS */, ["modelValue", "data"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _withDirectives(_createVNode(_component_ElFormItem, {
              label: $setup.tt('Coefficient'),
              prop: "coefficient"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElInputNumber, {
                  modelValue: $setup.form.coefficient,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.form.coefficient) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]), [
              [_vShow, !$setup.isTotalPriceProject]
            ]),
            _withDirectives(_createVNode(_component_ElFormItem, {
              label: $setup.tt('Comprehensive unit price'),
              prop: "price"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElInputNumber, {
                  modelValue: $setup.form.price,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.form.price) = $event)),
                  modelModifiers: { number: true }
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]), [
              [_vShow, $setup.isTotalPriceProject]
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model", "rules"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}