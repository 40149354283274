import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElDialog"], {
    modelValue: $setup.visible,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.visible) = $event)),
    title: $setup.tt('Please Select Department'),
    width: "40%"
  }, {
    footer: _withCtx(() => [
      _createVNode($setup["ElButton"], {
        onClick: _cache[0] || (_cache[0] = $event => ($setup.closeDialog('Cancel'))),
        size: "small"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode($setup["ElButton"], {
        type: "primary",
        size: "small",
        onClick: _cache[1] || (_cache[1] = $event => ($setup.closeDialog('Confirm')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.tt('Confirm')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      ($props.departmentTree.length)
        ? (_openBlock(), _createBlock($setup["ElTree"], {
            key: 0,
            ref: "tree",
            class: "dw_department_tree",
            data: $props.departmentTree,
            highlightCurrent: "",
            defaultExpandAll: "",
            checkOnClickNode: "",
            checkStrictly: "",
            showCheckbox: "",
            props: $setup.defaultProps,
            nodeKey: "name",
            onCheck: $setup.changeDepartment
          }, null, 8 /* PROPS */, ["data"]))
        : (_openBlock(), _createBlock($setup["ElEmpty"], {
            key: 1,
            description: $setup.tt('No Data')
          }, null, 8 /* PROPS */, ["description"]))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}