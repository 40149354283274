import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.showQuarter)
    ? (_openBlock(), _createBlock($setup["Quarter"], {
        key: 0,
        placeholder: $props.label && $setup.tt($props.label),
        value: $props.modelValue,
        originInput: "",
        "onUpdate:value": $setup.changeQuarter
      }, null, 8 /* PROPS */, ["placeholder", "value"]))
    : (_openBlock(), _createBlock($setup["ElDatePicker"], {
        key: 1,
        modelValue: $setup.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.value) = $event)),
        type: $setup.type,
        format: $setup.format,
        placeholder: "请选择",
        teleported: false
      }, null, 8 /* PROPS */, ["modelValue", "type", "format"]))
}