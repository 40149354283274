<template>
	<ElDialog v-model="show" title="移动方式到..." width="min(300px, 100%)">
		<div class="menu">
			<ElButton @click="click(true, false)">当前项前</ElButton>
			<ElButton @click="click(false, false)">当前项后</ElButton>
			<ElButton @click="click(true, true)">当前项的子项中的最前</ElButton>
			<ElButton @click="click(false, true)">当前想的子项中的最后</ElButton>
		</div>
		<template #footer>
			<ElButton @click="show = false">{{ tt('Cancel') }}</ElButton>
		</template>
	</ElDialog>
</template>
<script lang="ts" setup>
import {computed} from 'vue';
import {ElButton, ElDialog} from 'element-plus';

const tt = __;
const props = defineProps<{
	modelValue: boolean;
	x?: number;
	y?: number;
}>();
const emit = defineEmits<{
	(event: 'submit', before: boolean, children: boolean): void;
	(event: 'update:modelValue', visible: boolean): void;
}>();
const show = computed({
	get: () => props.modelValue,
	set: v => {
		emit('update:modelValue', v);
	},
});
function click(before: boolean, children: boolean) {
	emit('submit', before, children);
}
</script>
<style scoped lang="less">
.menu {
	display: flex;
	flex-direction: column;

	button {
		margin-left: 0 !important;
		width: 100%;
	}
}
</style>
