<template>
	<div class="toolbar">
		<ElSpace>
			<ElSelect filterable :model-value="company" @change="changeCompany">
				<ElOption
					v-for="item in subCompanies"
					:key="item.name"
					:label="item.company_name"
					:value="item.name" />
			</ElSelect>

			<ElSelect :model-value="pageType" @change="pageTypeChange">
				<ElOption
					value="month"
					key="month"
					label="月度" />
				<ElOption
					value="year"
					key="year"
					label="年度" />
			</ElSelect>

			<el-date-picker
				v-if="pageType === 'month'"
				v-model="monthValue"
				@change="monthChange"
				type="month"
				:clearable="false"
				placeholder="选择月">
			</el-date-picker>

			<el-date-picker
				v-if="pageType === 'year'"
				v-model="yearValue"
				@change="yearChange"
				type="year"
				:clearable="false"
				placeholder="选择年">
			</el-date-picker>

		</ElSpace>
		<div class="refresh">
			<el-button-group class="ml-4">
				<el-button @click="exportTable">导出</el-button>
				<el-button @click="refresh" :icon="RefreshRight">刷新</el-button>
			</el-button-group>
		</div>
	</div>
</template>
<script lang="ts" setup>

import { ref, onMounted, defineEmits, watchEffect, defineProps, toRefs, watch, computed } from 'vue';
import moment from 'moment';
import { RefreshRight } from '@element-plus/icons-vue';
import { GridApi } from 'ag-grid-community';

import { SubCompany } from '../../type';

import { routeInit, setRoute } from './helper';

interface Props {
	company?: string
	pageType?: string
	monthDate?: string
	yearDate?: string
	refreshValue?: number
	detailGridApi?: GridApi
}

const props = defineProps<Props>();
const { company, pageType, monthDate, yearDate, refreshValue, detailGridApi } = toRefs(props);

interface Emit {
	(e: 'update:company', company: string): void,
	(e: 'update:monthDate', monthDate: string): void,
	(e: 'update:yearDate', yearDate: string): void,
	(e: 'update:pageType', type: string): void,
	(e: 'update:refreshValue', refreshValue: number): void,
}

const emit = defineEmits<Emit>();

const subCompanies = ref<SubCompany[]>();
const monthValue = ref<string>(monthDate?.value || moment());
const yearValue = ref<string>(yearDate?.value || '');

const paramsFilter = ref<{ [key: string]: string }>({});

onMounted(async () => {
	routeInit(paramsFilter);
	const subCompaniesRes: SubCompany[] = await frappe.db.get_list('Company',
		{
			fields: ['name', 'company_name'],
			limit: '',
		}
	);
	subCompanies.value = subCompaniesRes;
	const companyNames = subCompaniesRes.map(item => item.name);
	const sessionDefaultCompany: string = (frappe.defaults.get_user_defaults('company') || [])[0];
	let defaultCompany: string = '';
	if (companyNames.includes(paramsFilter.value?.company)) {
		defaultCompany = paramsFilter.value?.company;
	} else {
		defaultCompany = sessionDefaultCompany || subCompaniesRes[0]?.name || '';
	}
	let defaultPageType: string = paramsFilter.value?.pageType || props.pageType || 'month';
	let monthDate: string = paramsFilter.value?.monthDate || props.monthDate || moment().format('YYYY-MM');
	let yearDate: string = paramsFilter.value?.yearDate || props.yearDate || moment().format('YYYY');
	let routeObj: { [key: string]: any } = {};
	monthValue.value = monthDate;
	yearValue.value = yearDate;
	if (defaultPageType === 'month') {
		routeObj = { company: defaultCompany, pageType: defaultPageType, monthDate, yearDate };
		emit('update:pageType', 'month');
	} else {
		routeObj = { company: defaultCompany, pageType: defaultPageType, monthDate, yearDate };
		emit('update:pageType', 'year');
	}
	emit('update:monthDate', moment(monthDate).format('YYYY-MM'));
	emit('update:yearDate', yearDate);
	emit('update:company', defaultCompany);
	setRoute(paramsFilter, routeObj);
});

function exportTable() {
	detailGridApi?.value?.exportDataAsExcel();
}


function changeCompany(newCompany: string) {
	setRoute(paramsFilter, { company: newCompany });
	emit('update:company', newCompany);
}

function monthChange(date: moment) {
	setRoute(paramsFilter, { monthDate: moment(date).format('YYYY-MM') });
	emit('update:monthDate', moment(date).format('YYYY-MM'));
}

function yearChange(date: moment) {
	setRoute(paramsFilter, { yearDate: moment(date).format('YYYY') });
	emit('update:yearDate', moment(date).format('YYYY'));
}

function pageTypeChange(type: string) {
	setRoute(paramsFilter, { pageType: type });
	emit('update:pageType', type);
}

function refresh() {
	const newValue: number = (refreshValue?.value || 0) + 1;
	emit('update:refreshValue', newValue);
}
</script>
<style lang="less" scoped>
.toolbar {
	padding: 8px 0;
}

.refresh {
	float: right
}
</style>
