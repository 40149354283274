import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.loaded)
    ? (_openBlock(), _createBlock(_KeepAlive, { key: 0 }, [
        ($setup.domVisible && $setup.docMeta)
          ? (_openBlock(), _createBlock($setup["Main"], {
              key: 0,
              meta: $setup.docMeta,
              cfg: $setup.view,
              label: $setup.label,
              class: "widget"
            }, null, 8 /* PROPS */, ["meta", "cfg", "label"]))
          : _createCommentVNode("v-if", true)
      ], 1024 /* DYNAMIC_SLOTS */))
    : _createCommentVNode("v-if", true)
}