import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c72304aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createBlock($setup["Page"], null, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.tt("Construction Management Log")), 1 /* TEXT */)
    ]),
    tools: _withCtx(() => [
      _createVNode($setup["Toolbar"], { onFilterChange: $setup.filterChange })
    ]),
    default: _withCtx(() => [
      _withDirectives(_createVNode($setup["DiaryTable"], {
        diaryStructure: $setup.structure,
        project: $setup.selectedProject,
        date: $setup.selectedDate,
        onDeleteDiary: $setup.deleteDiary,
        onAfterSave: $setup.afterSave
      }, null, 8 /* PROPS */, ["diaryStructure", "project", "date"]), [
        [_directive_loading, $setup.loading]
      ])
    ]),
    _: 1 /* STABLE */
  }))
}