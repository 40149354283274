import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElButton = _resolveComponent("ElButton")

  return (_openBlock(), _createElementBlock("div", { style: $setup.style }, [
    ($setup.showScore)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($setup.score), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($setup.props.params.onRemove && !$setup.showScore)
      ? (_openBlock(), _createBlock(_component_ElButton, {
          key: 1,
          type: "danger",
          onClick: $setup.deleteData
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Delete')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ]))
}