<template>
	<div ref="dateRef">
		<el-popover
			:visible="visible"
			placement="bottom"
			:width="400"
			trigger="click"
		>
			<template #reference>
				<ElInput @focus="isFocus=originInput?false:true" @blur="blur" :class="{'is-focus':isFocus}" :prefixIcon="Calendar" size="small" :placeholder="placeholder" class="input" @click="visible=true" v-model="inputValue" v-click-outside="onClickOutside">
					<template #suffix>
						<el-icon @click="clear"><CircleClose /></el-icon>
					</template>
				</ElInput>
			</template>
			<div ref="popperWrapper">
				<div class="year_toggle">
					<ElButton :icon="DArrowLeft" @click="year--"></ElButton>
					<span>{{ year }}</span>
					<ElButton :icon="DArrowRight" @click="year++"></ElButton>
				</div>
				<div class="quarter-content">
					<ElButton
						v-for="q,index in QUARTER"
						:class="{current:index+1===quarterIndex}"
						:data-value="q[0]"
						@click="click"
					>
						{{ q[1] }}
					</ElButton>
				</div>
			</div>
		</el-popover>
	</div>

</template>

<script setup lang='ts'>
import { computed, ref, watch } from 'vue';

import { ClickOutside as vClickOutside } from 'element-plus';
import { Calendar, Search, DArrowLeft, DArrowRight, CircleClose } from '@element-plus/icons-vue';
const QUARTER=[
	['01-01', '第一季度'],
	['04-01', '第二季度'],
	['07-01', '第三季度'],
	['10-01', '第四季度'],
];
interface Props{
	value:string,
	originInput?:boolean
	inputClass?:string
	placeholder?:string
}
const props = defineProps<Props>();
interface Emit{
	(e: 'update:value', data: string): void
}
const emit = defineEmits<Emit>();
const visible = ref(false);
const year = ref<number>(parseInt(moment().format('YYYY')));
const quarterIndex = ref<number>(0);
const inputValue = ref<string>('');
const popperWrapper = ref<any>();
const dateRef = ref<any>();
const isFocus = ref<Boolean>(false);

watch([dateRef, ()=>props.originInput], () => {
	if (!dateRef.value) { return; }
	if (props.originInput){ return; }
	const input = dateRef.value.querySelector('input');
	$(input).removeClass('el-input__inner');
	$(input).addClass(props.inputClass||'');
	$(input).attr('readonly', false);
	const prefix = dateRef.value.querySelector('.el-input__prefix');
	$(prefix).hide();
});

watch(()=>props.value, ()=>{
	if (!props.value){ return; }
	year.value = parseInt(moment(props.value).format('YYYY'));
	quarterIndex.value = moment(props.value).quarter();
	inputValue.value = `${moment(props.value).format('YYYY')}-${moment(props.value).quarter()}季度`;
}, {immediate:true});

function click(e:Event){
	const dayValue = e.currentTarget?.dataset.value;
	if (!dayValue){
		return;
	}
	const quarter = QUARTER.findIndex(item=>item[0]===dayValue);
	inputValue.value = `${year.value.toString().padStart(4, '0')}-${quarter+1}季度`;
	emit('update:value', `${year.value.toString().padStart(4, '0')}-${dayValue}`);
	visible.value = false;
}
function clear(){
	inputValue.value = '';
	emit('update:value', undefined);
	visible.value = false;
}

function blur(e:Event){
	isFocus.value=false;
	const newValue = e.target?.value;
	if (!newValue){
		emit('update:value', undefined);
		return;
	}
	const res = inputValue.value.match(/^(\d{4})-(\d{1})季度$/);
	if (!res){
		setInvalidateValue();
		return;
	}
	let [_, newYear, newQuarter]:[string, string|number, string|number] = Array.from(res);
	const numberYear = parseInt(newYear);
	const numberQuarter = parseInt(newQuarter);
	if (isNaN(numberYear)||isNaN(numberQuarter)){
		setInvalidateValue();
		return;
	}
	if (numberYear<=0||numberQuarter<=0||numberQuarter>4){
		setInvalidateValue();
		return;
	}
	emit('update:value', `${newYear}-${QUARTER[numberQuarter-1][0]}`);
}
function setInvalidateValue(){
	inputValue.value='';
	emit('update:value', undefined);
}

function onClickOutside(e:any){
	if (popperWrapper.value.contains(e.target)){
		return;
	}
	visible.value = false;
}

const width = computed(()=>props.originInput?'220px':'100%');
const background = computed(()=>props.originInput?'auto':'#f4f5f6');
const shadow = computed(()=>props.originInput?'0 0 0 1px var(--el-input-border-color,var(--el-border-color)) inset':'none');

</script>

<style lang='less' scoped>
:deep(.el-input__wrapper){
	width: v-bind(width);
    box-shadow: v-bind(shadow);
    background:v-bind(background);
}
.is-focus{
	color: var(--text-color);
    background-color: var(--control-bg);
    border-color: var(--gray-500);
    outline: 0;
    box-shadow: 0 0 0 2px rgba(104, 113, 120, 0.25);
	border-radius: 4px;
}
:deep(.form-control):focus{
	box-shadow: none;
}
.year_toggle{
	display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.quarter-content{
	display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    text-align: center;
	margin-top:8px;
	button{
		margin: 8px 0;
	}
	button:nth-child(1n){
		margin-right:8px ;
	}
	button:nth-child(2n){
		margin-left:8px ;
	}
	.current{
		color:#409eff
	}
}
</style>
