import type { ColumnCell } from '../types';
import type { Row, RowDataProxy } from '../types/Row';

import replaceShow from './replaceShow';
import getRow from './getRow';
import hideRow from './hideRow';
import showRow from './showRow';
export default function render(
	[rStart, rEnd]: [number, number],
	[cStart, cEnd]: [number, number],
	rowData: Row[],
	rowMap: Map<string | number, Row>,
	visible: number[],
	startFixed: number,
	shownRows: RowDataProxy[],
	hoverId: string | number | undefined,
	selectedId: string | number | undefined,
	checkedSet: Set<string | number>,
	rowHeight: number,
	columns: ColumnCell[],
	body: HTMLElement,
) {
	return replaceShow(
		body,
		getRow(rowData, rowMap, visible, rStart, rEnd, body),
		shownRows,
		r => showRow(
			r,
			hoverId,
			selectedId,
			checkedSet,
			startFixed,
			visible,
			rowHeight,
			columns,
			cStart,
			cEnd,
		),
		hideRow,
	);
}
