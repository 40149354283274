
export const not_show_type = new Set(['Barcode',
	'Button', 'Code', 'Geolocation', 'Heading',
	'HTML', 'Image', 'Password', 'Rating', 'Signature', 'Table',
	'Text Editor', 'Markdown Editor',
]);
export function subDocFieldFilter(fields: locals.DocField[], parent: string) {
	return fields.filter(v => !not_show_type.has(v.fieldtype))
		.filter(v => !v.is_virtual)
		.filter(v => !frappe.model.no_value_type.includes(v.fieldtype))
		.filter(df => frappe.perm.has_perm(parent, df.permlevel, 'read'));
}


export default async function pretreat(
	list: locals.Doctype[],
	meta: locals.DocType,
	cfg: any,
	view: GlobalView.View,
) {
	const names = list.map(v => v.name);
	if (!names.length) { return; }
	const { fields } = view;

	const docMap = new Map(list.map(v => [v.name, v]));

	const tableFields = fields.map(({ fieldOptions: docField }) => docField);

	const tables = new Map<string, Set<string>>();
	for (const { fieldtype, fieldname, options: dt } of tableFields) {
		if (fieldtype !== 'Table') { continue; }
		if (!dt) { continue; }
		if (!fieldname) { continue; }
		const set = tables.get(dt);
		if (!set) {
			tables.set(dt, new Set([fieldname]));
		} else {
			set.add(fieldname);
		}
	}
	if (!tables.size) { return; }

	await Promise.all([...tables].map(([dt, fields]) => new Promise<void>(resolve => {
		frappe.model.with_doctype(dt, () => { resolve(); }, true);
	})));

	const dtList: Record<string, {
		fields: string[]
		parentFields: string[],
	}> = {};
	const parentType = meta.name;
	for (const [dt, parentField] of tables) {
		const meta = frappe.get_meta(dt);
		if (!meta) { continue; }
		const fields = new Set([
			...subDocFieldFilter(meta.fields, parentType).map(v => v.fieldname),
		]);
		dtList[dt] = { parentFields: [...parentField], fields: [...fields] };
	}

	const children = await frappe.call('guigu.timeline.timeline.get_table_values', {
		parent: parentType, names, tables: dtList,
	}).then((v: any) => v.message || []);
	for (const sub of children) {
		const doc = docMap.get(sub.parent);
		if (!doc) { continue; }
		const field = sub.parentfield;
		if (!field) { continue; }
		const list = doc[field];
		if (Array.isArray(list)) {
			list.push(sub);
		} else {
			doc[field] = [sub];
		}
	}
}
