import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["ElForm"], { labelPosition: "top" }, {
      default: _withCtx(() => [
        _createVNode($setup["ElFormItem"], {
          label: $setup.tt('URL'),
          rules: [
					{
						required: true,
						message: '请选择链接',
						trigger: 'blur',
					}
				]
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElInput"], {
              modelValue: $setup.url,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.url) = $event)),
              onLabel: _cache[1] || (_cache[1] = $event => ($setup.doctypeLabel = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElFormItem"], {
          label: $setup.tt('Label'),
          rules: [
									{
										required: true,
										message: '请输入标题',
										trigger: 'blur',
									}
								]
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElInput"], {
              modelValue: $setup.label,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.label) = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}