<template>
	<div :style="{ display:'flex', alignItems:'center'}">
		<a
			:style="{ color: canClick?'#409eff':'',minWidth:0, overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis', flex:1}"
			@click="click"
		>
			{{ label }}
		</a>
		<ElDropdown @command="command" v-if="hasPerm">
			<ElIcon :style="{cursor: 'pointer'}"><MoreFilled /></ElIcon>
			<template #dropdown>
				<ElDropdownMenu>
					<ElDropdownItem v-if="addable" command="add">添加子项</ElDropdownItem>
				</ElDropdownMenu>
			</template>
		</ElDropdown>
	</div>
</template>

<script setup lang='ts'>
import { computed, watch } from 'vue';
import { ElDropdown, ElDropdownMenu, ElDropdownItem, ElIcon } from 'element-plus';
import { MoreFilled } from '@element-plus/icons-vue';

const props = defineProps<{ params: any }>();
const label = computed(() => {
	const options = props.params.linkOptions?.find(item => item.value === props.params.value && props.params.fieldname === item.fieldname);
	if (options) {
		return options.label;
	}
	return props.params.value;
});

const canClick = computed(()=>Boolean(props.params.connectionFieldSetting&&props.params.emit));
function click() {
	const {connectionFieldSetting, emit, data} = props.params;
	if (!connectionFieldSetting||!emit){ return; }
	if (connectionFieldSetting.is_detail_use_dialog) {
		emit('showDetail', data.name);
	} else {
		frappe.set_route('Form', connectionFieldSetting!.connection_table, data.name);
	}
}
const addable = computed(() => props.params.canCreate&&!props.params.connectionFieldSetting.hide_add_child_button);
const hasPerm = computed(() => addable.value);
function addChild() {
	const {emit, data} = props.params;
	emit('createChild', data);
}

function command(v: string) {
	switch (v) {
		case 'add': return addChild();
	}
}
</script>

<style lang='less' scoped>
.link {
	color: #409eff;
}
</style>
