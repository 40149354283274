import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4da457eb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "operation-button" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h5", null, _toDisplayString($setup.tt('Standard task template')), 1 /* TEXT */),
    _createElementVNode("div", null, [
      _createVNode($setup["TaskTemplateTable"], {
        tableData: $setup.tableData,
        loading: $setup.loading,
        smallMeta: $setup.smallMeta,
        onGetSelectRows: $setup.getSelectRows
      }, null, 8 /* PROPS */, ["tableData", "loading", "smallMeta"]),
      _createElementVNode("div", _hoisted_1, [
        (!$setup.props.frm.is_new() && $setup.manager)
          ? (_openBlock(), _createBlock($setup["ElButton"], {
              key: 0,
              type: "primary",
              size: "small",
              onClick: $setup.enableTemplate
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.tt('Enable')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}