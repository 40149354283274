import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-915f9c5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "root" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["href", "title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.params.data)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          ($setup.aggregation)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "合计"))
            : (_openBlock(), _createElementBlock("a", {
                key: 1,
                href: $setup.href,
                onClick: $setup.click,
                title: $setup.value
              }, _toDisplayString($setup.value), 9 /* TEXT, PROPS */, _hoisted_3))
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true)
  ]))
}