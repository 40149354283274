
<template>
	<div class="tool-container">
		<div class="tool">
			<span class="required">*</span>
			<ElDatePicker
				v-model="datePicker"
				@change="dateChange"
				:clearable="false"
				type="month"
				value-format="YYYY-MM" />
		</div>
	</div>
</template>
<script lang="ts" setup>
import { ref, onMounted, watchEffect, defineProps, computed, watch } from 'vue';

interface Props {
	date: string
}
const props = defineProps<Props>();

const datePicker = ref<string>(props.date);

interface Emit {
	(e: 'update:date', date: string): void
}
const emit = defineEmits<Emit>();
function dateChange(value: string) {
	emit('update:date', value);
}
</script>

<style scoped lang="less">
.tool-container {
	display: flex;
	flex-wrap: wrap;

	.tool {
		margin-right: 8px;
		margin-bottom: 8px;
	}
	.required {
		margin-right: 4px;
		color: #f00;
		line-height: 24px;
	}
}
</style>
