import defineMainView from '../extends/init/defineMainView';
import DefaultMain, { widgets, RebuildSort } from '../DefaultMain';
import type { ListConfiguration } from '../DefaultMain';
import ListAction from '../GlobalView/ListAction';
import Expander from '../Expander';

import Selected from './Selected.vue';


defineMainView<ListConfiguration>({
	type: 'Guigu List View Configuration',
	label: 'List',

	sider: ['filters', 'filterList'],
	toolbar: [Selected, 'filterLine', ListAction, RebuildSort],
	actions: [Expander],
	detail: true,
	view: DefaultMain,
	rowAction: true,
	configuration: {
		filterField: true,
		widgets,
		displayField: {
			useDefault: true,

			titleField: true,
			commentField: true,
			tagField: true,
			dateExtField: true,

			group: '默认分组，树状结构下无效',
			width: true,
			enableLink: true,
		},
	},
	pretreat: [],
	getConfigurations(meta, {
		fixed_field_columns,
		line_height,
		tree,
		show_line_number,
		show_summary_row,
		text_color,
		bg_color,
		odd_bg_color,
		row_line_color,
		col_line_color,
		expanded_default,
	}: any) {
		const data: ListConfiguration = {
			tree: Boolean(tree),
			showLineNumber: Boolean(show_line_number),
			showSummaryRow: Boolean(show_summary_row),
			textColor: text_color || undefined,
			bgColor: bg_color || undefined,
			oddBgColor: odd_bg_color || undefined,
			rowLineColor: row_line_color || undefined,
			colLineColor: col_line_color || undefined,
			expandedDefault: expanded_default,
		};
		if (typeof fixed_field_columns === 'number') {
			data.fixedFieldColumns = fixed_field_columns;
		}
		if (line_height) { data.lineHeight = line_height; }

		return data;
	},
	*fields(meta: locals.DocType, cfg) {
		yield 'status';
		yield 'name';
		yield 'enabled';
		yield 'disabled';
		yield 'color';
		if (cfg.tree) {
			yield 'lft';
			yield 'rgt';
			yield 'is_group';
			yield meta.nsm_parent_field;
		}
	},
});
