import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a65b2c9d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ViewPage-sidebar-main" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['ViewPage-sidebar', _ctx.open ? 'ViewPage-sidebar-open' : null])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("div", {
      class: "ViewPage-sidebar-close",
      onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.close && _ctx.close(...args)))
    })
  ], 2 /* CLASS */))
}