<template>
	<div @click="onClick" class="divCell"
		:style="{ cursor: hasOwner, background: props.params.value?.color }">
		{{ props.params.value?.type_name || '' }}
	</div>
</template>
<script lang="ts" setup>
import { defineProps } from 'vue';

const props = defineProps<{ params: { value: any, onClick: any, data: any, project: string, date: string } }>();
const hasOwner = (props.params.data?.record_owner === frappe.user.name || props.params.value?.record_name) ? 'pointer' : 'default';

function onClick() {
	if (props.params.value?.record_name) {
		frappe.set_route('Form', 'Guigu Daily Record B', props.params.value?.record_name);
	} else if (props.params.data?.record_owner === frappe.user.name || props.params.value?.record_name) {
		frappe.new_doc('Guigu Daily Record B', {
			project: props.params.project,
			record_date: props.params.date,
			record_owner: props.params.data?.record_owner,
		});
	}
}
</script>

<style scoped lang="less">
.divCell {
	width: 100%;
	height: 100%;
	content: center;
}
</style>
