<template>
	<ElFormItem :label="tt('Time Field')">
		<ElSelect v-model="time_field" filterable defaultFirstOption clearable>
			<ElOption v-for="field in dateOptions" :key="field.value"
				:value="field.value" :label="field.label" />
		</ElSelect>
	</ElFormItem>
	<ElFormItem :label="tt('Sort Order Method')">
		<ElSelect v-model="order">
			<ElOption value="Desc" :label="tt('DESC')" />
			<ElOption value="ASC" :label="tt('ASC')" />
		</ElSelect>
	</ElFormItem>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import {ElSelect, ElFormItem} from 'element-plus';

const props = defineProps<{
	meta: locals.DocType;
	modelValue: any;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: GlobalView.View): void;
}>();
const cfg = computed({
	get: () => props.modelValue,
	set: v => emit('update:modelValue', v),
});
const tt = __;

const time_field = computed<string>({
	get: () => cfg.value.time_field || '',
	set: v => {
		cfg.value = {...cfg.value, time_field: v || null};
	},
});

const order = computed<string>({
	get: () => cfg.value.order || 'Desc',
	set: v => {
		cfg.value = {...cfg.value, order: v || null};
	},
});


const fields = computed(() => props.meta.fields || []);


const dateFields = computed(() => fields.value
	.filter(d => ['Date', 'Datetime'].includes(d.fieldtype))
	.map(d => ({
		value: d.fieldname,
		label: `${__(d.label)} (${d.fieldname})`,
	})));

const dateOptions = computed(() => [
	...dateFields.value,
	{value: 'creation', label: '创建时间 (creation)'},
	{value: 'modified', label: '最后修改时间 (modified)'},
]);
</script>
