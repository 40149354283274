<script setup lang="ts">
import { computed } from 'vue';

import { ICellRendererParams } from 'ag-grid-community';

import FieldRenderer from './FieldRenderer/index.vue';

interface Params extends ICellRendererParams {
	docField: locals.DocField;
	meta: locals.DocType;
	enableLink?: boolean;
	format?: string;
	setFilter?(field: string, operator: string, value: any): void;
	getRows(): any[];
	alignment?: string;
	show_tag?: '1'|'0';
}

const props = defineProps<{
	params: Params
}>();

function setFilter(field: string, operator: string, value: any) {
	const { params } = props;
	const { setFilter } = params;
	if (!setFilter) { return; }
	setFilter(field, operator, value);

}
const docField = computed(() => props.params.docField);
const meta = computed(() => props.params.meta);
const enableLink = computed(() => props.params.enableLink);
const format = computed(() => props.params.format);
const noData = computed(() => {
	const { params } = props;
	const { data } = params;
	if (!data) { return true; }
	return Boolean(data.__IS_AGGREGATION__);
});
const data = computed(() => {
	const { params } = props;
	const { data } = params;
	if (data) { return data; }
	const { value } = params;
	const { fieldname, fieldtype } = docField.value;
	const item = params.getRows().find(v => {
		if (['Float', 'Int'].includes(fieldtype)) {
			return parseFloat(v[fieldname]) === parseFloat(value);
		}
		return v[fieldname] === value;
	});
	return item;
});
const value = computed(() => props.params.value);
const text = computed(() => {
	const v = value.value;
	if (v || v === 0) {
		return String(v);
	}
	return '';
});
const propsAlignment = computed(() => props.params.alignment || 'start');

</script>
<template>
	<div :class="['ellipsis', 'tianjy_report_vertical-horizontal-center-style']">
		<FieldRenderer
			v-if="data"
			:value="value"
			:field="docField"
			:data="data"
			:meta="meta"
			:enableLink="enableLink"
			:noData="noData"
			:format="format"
			:showTag="Boolean(params.show_tag)"
			ellipsis
			inline
			showTitle
			@filter="setFilter" />
		<template v-else-if="text">{{ text }}</template>
	</div>
</template>
<style lang="less">
.tianjy_report_vertical-horizontal-center-style{
	display: flex !important;
    height: 100%;
    align-items: center;
	justify-content: v-bind(propsAlignment) !important
}

</style>
