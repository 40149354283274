import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.allButtons.length)
    ? (_openBlock(), _createBlock($setup["ElButtonGroup"], { key: 0 }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.allButtons, (b) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (b.children)
                ? (_openBlock(), _createBlock($setup["ElDropdown"], { key: 0 }, {
                    dropdown: _withCtx(() => [
                      _createVNode($setup["ElDropdownMenu"], null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(b.children, (s) => {
                            return (_openBlock(), _createBlock($setup["ElDropdownItem"], {
                              title: s.label
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("a", {
                                  href: s.url
                                }, _toDisplayString(s.label), 9 /* TEXT, PROPS */, _hoisted_1)
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["title"]))
                          }), 256 /* UNKEYED_FRAGMENT */))
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    default: _withCtx(() => [
                      _createVNode($setup["ElButton"], {
                        title: b.label,
                        round: ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(b.label) + " ", 1 /* TEXT */),
                          _createVNode($setup["ElIcon"], { class: "el-icon--right" }, {
                            default: _withCtx(() => [
                              _createVNode($setup["ArrowDown"])
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["title"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */))
                : (_openBlock(), _createBlock($setup["ElButton"], {
                    key: 1,
                    title: b.label,
                    round: ""
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("a", {
                        href: b.url
                      }, _toDisplayString(b.label), 9 /* TEXT, PROPS */, _hoisted_2)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["title"]))
            ], 64 /* STABLE_FRAGMENT */))
          }), 256 /* UNKEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }))
    : _createCommentVNode("v-if", true)
}