
<template>
	<div class="tool-container">
		<div class="tool">
			<span class="required">*</span>
			<ElSelect v-model="subCompanySelected"
				:size="size"
				class="dataSelect company-select"
				filterable>
				<ElOption
					v-for="item in subCompanies"
					:key="item.name"
					:label="item.company_name"
					:value="item.name" />
			</ElSelect>
		</div>
		<div class="tool">
			<span class="required">*</span>
			<ElSelect v-model="designationsSelected"
				:size="size"
				multiple
				class="dataSelectMultiple"
				collapseTags
				:collapseTagsTooltip="size==='small'"
				filterable>
				<ElOption
					v-for="item in designationList"
					:key="item.name"
					:label="item.job_title"
					:value="item.name" />
			</ElSelect>
		</div>
		<div class="tool">
			<span class="required">*</span>
			<ElSpace class="dateSpace">
				<ElTooltip
					class="box-item"
					effect="dark"
					:content="tt('the day before')"
					placement="top-start">
					<ElButton :size="size" @click="dateHandleChange(-1)">
						{{ `<<` }}
					</ElButton>
				</ElTooltip>
				<ElDatePicker
					class="dataSelect"
					v-model="dateSelected"
					:clearable="false"
					type="date"
					:size="size"
					valueFormat="YYYY-MM-DD" />
				<ElTooltip
					class="box-item"
					effect="dark"
					:content="tt('The day after')"
					placement="top-start">
					<ElButton :size="size"
						@click="dateHandleChange(1)">
						{{ `>>` }}
					</ElButton>
				</ElTooltip>
			</ElSpace>
		</div>
		<div class="tool comment">
			<ElTooltip :content="tt('Summary of Project Leader Log Pages')">
				<ElButton :icon="PieChart" circle size="small"
					@click="navigateToRecordStatistics"></ElButton>
			</ElTooltip>
			<ElCheckbox
				:size="size"
				v-model="showComment"
				:label="tt('Show comments')"
				class="commentCheckbox" />
		</div>
		<div class="search">
			<ElInput
				:size="size"
				clearable="true"
				v-model="searchValue"
				@clear="clearSearch"
				@keyup.enter="search"
				:placeholder="tt('Search For')">
				<template #append>
					<ElButton :size="size" @click="search">{{ tt('Search') }}</ElButton>
				</template>
			</ElInput>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { computed, ref, onUnmounted, defineProps, defineEmits, onMounted} from 'vue';
import { PieChart } from '@element-plus/icons-vue';

import {SubCompany, Designation} from './type';
const tt = __
interface Props {
	subCompany: string
	designations: string[]
	date: string
	searchString: string
	showComment: boolean
}
const props = defineProps<Props>();
interface Emit {
	(e: 'update:subCompany', subCompany: string): void
	(e: 'update:designations', designations: string[]): void
	(e: 'update:date', date: string): void
	(e: 'update:showComment', showComment: boolean): void
	(e: 'update:searchString', searchString: string): void
	(e: 'search'): void
	(e: 'clearSearch'): void
}
const emit = defineEmits<Emit>();
const dateSelected = computed({
	get: () => props.date,
	set: v => { emit('update:date', v || moment().format('YYYY-MM-DD')); },
});
const subCompanySelected = computed({
	get: () => props.subCompany,
	set: v => { emit('update:subCompany', v); },
});
const designationsSelected = computed({
	get: () => props.designations,
	set: v => { emit('update:designations', v); },
});
const showComment = computed<boolean>({
	get: () => props.showComment,
	set: v => { emit('update:showComment', v); },
});
const searchValue = computed({
	get: () => props.searchString,
	set: v => { emit('update:searchString', v); },
});

const subCompanies = ref<SubCompany[]>([]);
const designationList = ref<Designation[]>([]);
const sessionDefaultSubCompany = ref<string>((frappe.defaults.get_user_defaults('company') || [])[0]);

const size = ref<string>('small');

function updateComponentSize() {
	const widthElement = window.innerWidth;
	if (widthElement <= 640) {
		size.value = 'default';
	} else {
		size.value = 'small';
	}
}
window.addEventListener('resize', updateComponentSize);

function navigateToRecordStatistics() {
	const queryObj = new URLSearchParams();
	const endDate = dateSelected.value;
	if (endDate) {
		queryObj.append('endDate', endDate);
		const startDate = moment(endDate).subtract(30, 'days').format('YYYY-MM-DD');
		queryObj.append('startDate', startDate);
	}
	const {subCompany} = props;
	if (subCompany) {
		queryObj.append('subCompany', subCompany);
	}
	for (const designations of props.designations) {
		queryObj.append('designations', designations);
	}
	const url = `?${queryObj.toString()}`;
	frappe.router.push_state(`/app/project_leader_daily_record_statistics${url}`);
}

onMounted(async () => {
	updateComponentSize();
	const designationRes: Designation[] = await frappe.db.get_list('Guigu Project Designation',
		{
			fields: ['name', 'job_title'],
			limit: 0,
			order_by: 'position asc',
		},
	);
	designationList.value = designationRes;
	const subCompaniesRes: SubCompany[] = await frappe.db.get_list('Company',
		{
			fields: ['name', 'company_name'],
			limit: 0,
		},
	);
	subCompanies.value = subCompaniesRes;
	const subCompanyNames = subCompaniesRes.map(item => item.name);
	let defaultSubCompany: string = '';
	if (subCompanyNames.includes(props.subCompany)) {
		defaultSubCompany = props.subCompany;
	} else {
		defaultSubCompany = sessionDefaultSubCompany.value || subCompaniesRes[0]?.name || '';
	}
	const designation = props.designations ? props.designations.split('_'): [];


	subCompanySelected.value = defaultSubCompany;
	designationsSelected.value = designation || [];
});

onUnmounted(() => {
	window.removeEventListener('resize', updateComponentSize);
});

function dateHandleChange(days: number) {
	dateSelected.value =moment(dateSelected.value).add(days, 'days').format('YYYY-MM-DD');
}

function search() {
	emit('search');
}
function clearSearch() {
	emit('clearSearch');
}
</script>

<style scoped lang="less">
.tool-container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	.tool {
		display: flex;
		margin-bottom: 8px;
		:deep(.el-select-tags-wrapper) {
			display: flex !important;
		}
	}
	.required {
		margin-right: 4px;
		color: #f00;
		line-height: 24px;
	}
	.dateSpace {
		:deep(.el-space__item:last-child) {
			margin-right: 0px !important;
		}
	}
}
.comment {
	:deep(.el-checkbox__original) {
			display:none !important
	}
}
@media (min-width: 640px) {

	.tool {
		display: flex;
		margin-right: 8px;
		.company-select{
			width: 300px
		}
	}
	.statistics{
		margin-left: -8px;
		margin-right: -8px;
		margin-top: 2px;
	}
	.statistics_comment{
		display: flex;
	}
	.comment {
		:deep(.el-checkbox) {
			margin-bottom:0px;
			margin-left: 8px
		}
		:deep(.el-checkbox__label) {
			margin-top: -1px;
		}
	}
	.search {
		margin-left: auto;
		margin-bottom: 8px;
	}

	.dataSelect{
		:deep(.el-input__inner) {
			height:22px !important
		}
	}
}

@media (max-width: 640px) {
	.tool {
		width: 100%;
	}
	.search {
		width: 100%;
		margin-left: 11px
	}
	.comment{
		margin-left: 11px;
		margin-bottom: 0px;
		align-items: baseline;
		:deep(.el-checkbox) {
			margin-bottom:0px;
			margin-left: 8px
		}
	}
	:deep(.el-date-editor.el-input) {
			width: 100% !important
		};

	.dataSelect, .dataSelectMultiple{
		width: 100%;
	}

}
</style>
