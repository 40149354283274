
export interface TreeToListItem{
	[key:string]:any
}
export interface TreeItemData extends TreeToListItem {
	children?: this[];
}

/**
 * @description list列表转tree的公共函数
 * @param listData 列表
 * @param parentField 父级节点字段
 * @param keyField 用来做唯一值的key
 * @returns
 */
export function list2Tree<T extends TreeToListItem>(listData: T[], parentField:string, keyField = 'name') {
	const listDataCopy:T[] = JSON.parse(JSON.stringify(listData));
	const treeData: (T & TreeItemData)[] = [];
	const map:Record<string, any> = {};
	for (const item of listDataCopy) {
		map[item[keyField]] = item;
	}
	for (const item of listDataCopy) {
		const parent = map[item[parentField] || 0];
		if (parent) {
			(parent.children || (parent.children = [])).push(item);
		} else {
			treeData.push(item);
		}
	}
	return treeData;
}
