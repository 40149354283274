<template>
	<div>
		<el-tree
			:data="treeData"
			:props="defaultProps"
			@node-click="handleNodeClick"
			default-expand-all
		/>
	</div>
</template>

<script setup lang='ts'>
import { ref, defineProps, defineEmits, watch, toRefs, computed, toRaw } from 'vue';

import { navToTree } from './helper';
import { TeamNav, TreeData } from './type';


interface Props{
	date?:string
	nav:TeamNav[]
}
const props = defineProps<Props>();
const {date, nav} = toRefs(props);
interface Emit{

}
const emit = defineEmits<Emit>();

const defaultProps = {
  children: 'children',
  label: 'label',
};
const treeData = computed(()=>date?.value?navToTree(toRaw(nav.value), date?.value):[]);
function handleNodeClick(data:TreeData){
	location.href = `#${data.key}`;
}
</script>

<style lang='less' scoped>

</style>
