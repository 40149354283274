import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0dd6e004"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "rootRef",
  class: "reportList"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.reportList, (item) => {
      return (_openBlock(), _createBlock($setup["ReportInfo"], {
        ref_for: true,
        ref: $setup.getRef,
        key: item.key,
        uniKey: item.key,
        teamStructure: item.teamStructure,
        dayDiaries: $setup.dayDiaries,
        enableAudit: $setup.enableAudit
      }, null, 8 /* PROPS */, ["uniKey", "teamStructure", "dayDiaries", "enableAudit"]))
    }), 128 /* KEYED_FRAGMENT */)),
    ($setup.reportList.length===0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.tt("No Records Created")), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ], 512 /* NEED_PATCH */))
}