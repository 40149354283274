<template>
	<ElRate @click="setFilter" :modelValue="showValue" allowHalf :max="max"
		disabled />
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { ElRate } from 'element-plus';


const props = defineProps<{
	value: any;
	data: Record<string, any>;
	field: GlobalView.FieldRenderOptions;
	meta?: locals.DocType;
	maxLine?: number;
	enableLink?: boolean;
	noData?: boolean;
	inline?: boolean;
	format?: string;
	showTitle?: boolean;
}>();
const emit = defineEmits<{
	(event: 'filter', field: string, operator: string, value: any): void;
}>();
const field = computed(() => props.field);

const max = computed(() => parseInt(field.value.options || '') || 5);
const value = computed(() => props.value);
const title = computed(() => {
	if (!props.showTitle) { return; }
	return `${__(field.value.label)}: ${value.value}`;
});

const showValue = computed(() => value.value * max.value);
function setFilter() {
	emit('filter', props.field.fieldname, '=', props.value);

}
const tt = __;

</script>

<style  scoped lang="scss">
:deep(svg) {
	vertical-align:unset
}
</style>
