<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
	value?: any;
	editing: boolean;
}>();
const emit = defineEmits<{
	(event: 'edit'): void;
}>();

const v = computed(() => JSON.stringify(props.value));

const tt = __;
</script>

<template>
	<WorkspaceWidget>
		<template #title>
			{{ tt('This is the title') }}
		</template>
		<template #subtitle>
			{{ tt('This is a subheading') }}
		</template>
		<div>{{ v }}</div>
	</WorkspaceWidget>
</template>
