import type { ViewInfo } from '../types';

export function getFieldName(dt: string, field: string) {
	return frappe.model.get_full_column_name(field, dt);
}

export default function getFields(
	meta: locals.DocType,
	view: ViewInfo,
	order: { doctype: string; field: string; desc: boolean; }[],
) {
	const doctype = meta.name;
	let fields: [string, string][] = [
		['status', doctype],
		['name', doctype],
		['enabled', doctype],
		['disabled', doctype],
		['color', doctype],
	];
	for (const f of frappe.model.std_fields_list) {
		fields.push([f, doctype]);
	}
	if (view.tree) {
		fields.push(['lft', doctype], ['rgt', doctype], ['is_group', doctype]);
		if (meta.nsm_parent_field) {
			fields.push([meta.nsm_parent_field, doctype]);
		}
	}
	const { linkField, fieldsRequested } = view;
	if (linkField) { fields.push([linkField, doctype]); }
	fields.push([meta.title_field || '', doctype]);
	for (const { fieldOptions: { fieldname, fieldtype, options } } of view.fields) {
		fields.push([fieldname, doctype]);
		if (fieldtype === 'Image' && options) {
			fields.push([options, doctype]);
		}
	}

	for (const field of fieldsRequested) {
		if (field) {
			fields.push([field, doctype]);
		}
	}

	if (meta.track_seen) {
		fields.push(['_seen', doctype]);
	}
	for (const f of order) {
		fields.push([f.field, f.doctype]);
	}
	fields = fields.filter(([field, dt]) => {
		if (!field) { return false; }
		if (dt === doctype && field === '_comments') { return true; }
		if (frappe.model.std_fields_list.includes(field)) { return true; }
		if (field === '_seen') { return true; }
		const df = frappe.get_meta(dt)?.fields.find(df => df.fieldname === field);
		if (!df) { return false; }
		if (df.is_virtual) { return false; }
		if (!frappe.perm.has_perm(doctype, df.permlevel, 'read')) { return false; }
		const type = df.fieldtype;
		if (!type) { return false; }
		return !frappe.model.no_value_type.includes(type);
	});

	return fields;
}
