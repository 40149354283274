import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a3dd8891"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-tree-node" }
const _hoisted_2 = { class: "el-dropdown-link" }
const _hoisted_3 = { class: "delete" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString($props.node.label), 1 /* TEXT */),
    ($props.node.data.permissions.createPermission || $props.node.data.permissions.deletePermission)
      ? (_openBlock(), _createBlock($setup["ElDropdown"], {
          key: 0,
          onCommand: $setup.command
        }, {
          dropdown: _withCtx(() => [
            _createVNode($setup["ElDropdownMenu"], null, {
              default: _withCtx(() => [
                ($setup.childTypes.length > 0 && $props.node.data.permissions.createPermission)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.childTypes, (type) => {
                      return (_openBlock(), _createBlock($setup["ElDropdownItem"], {
                        command: "add",
                        onClick: $event => ($setup.createByType(type))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.tt('New')) + " " + _toDisplayString($setup.tt(type.name)), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                    }), 256 /* UNKEYED_FRAGMENT */))
                  : _createCommentVNode("v-if", true),
                ($props.node.data.permissions.deletePermission && !$props.node.data.children?.length)
                  ? (_openBlock(), _createBlock($setup["ElDropdownItem"], {
                      key: 1,
                      command: "delete"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_3, _toDisplayString($setup.tt('Delete')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, [
              _createVNode($setup["ElIcon"], {
                class: "el-icon--right",
                onClick: _cache[0] || (_cache[0] = e => e.stopPropagation())
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["MoreFilled"])
                ]),
                _: 1 /* STABLE */
              })
            ])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ]))
}