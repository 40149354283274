import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10a07b83"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tree-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.tree.length)
      ? (_openBlock(), _createBlock($setup["ElTree"], {
          key: 0,
          class: "app-tree",
          data: $setup.tree,
          props: $setup.defaultProps,
          onNodeClick: $setup.nodeClick,
          "highlight-current": "",
          "expand-on-click-node": false,
          "node-key": "name",
          "current-node-key": $setup.currentNodeKey
        }, null, 8 /* PROPS */, ["data", "current-node-key"]))
      : _createCommentVNode("v-if", true)
  ])), [
    [$setup["vLoading"], $setup.loading]
  ])
}