import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-91ffd4da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_input_number = _resolveComponent("el-input-number")
  const _component_el_form = _resolveComponent("el-form")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $setup.dialogVisible,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.dialogVisible) = $event)),
    title: $setup.tt('Create New Addition and Subtraction Items'),
    width: $setup.width,
    destroyOnClose: "",
    onClose: $setup.cancel
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_el_button, { onClick: $setup.cancel }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: $setup.confirm
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Sure')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_form, {
          class: "extra-form",
          model: $setup.form,
          rules: $setup.rules,
          labelWidth: "100px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: $setup.tt('Type'),
              prop: "type"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: $setup.form.type,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.form.type) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: $setup.tt('Assessment Bonus Item'),
                      value: "Add"
                    }, null, 8 /* PROPS */, ["label"]),
                    _createVNode(_component_el_option, {
                      label: $setup.tt('Subtraction Item'),
                      value: "Minus"
                    }, null, 8 /* PROPS */, ["label"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: $setup.tt('Specific Items For Adding Or Subtracting Points'),
              prop: "subject"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: $setup.form.subject,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.form.subject) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: $setup.tt('Reasons For Adding Or Subtracting Points'),
              prop: "reason"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: $setup.form.reason,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.form.reason) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: $setup.tt('Rating'),
              prop: "score"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input_number, {
                  modelValue: $setup.form.score,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.form.score) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model", "rules"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title", "width"]))
}