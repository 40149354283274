import { ElTreeSelect } from 'element-plus';
import { createApp, h, ref } from 'vue';

async function sendDocRemind(
	doctype: string,
	name: string,
	users: string[],
): Promise<null | {}> {
	return frappe
		.call<any>('guigu_wechat.message.send_doc_remind', {
			doctype,
			name,
			users,
		})
		.then(v => v.message || null);
}

interface UserNode {
	value: string;
	label: string;
	disabled?: boolean;
	fields: string[];
}
interface Tree {
	children: UserNode[];
	value: string;
	label: string;
	disabled?: boolean;
}

async function getTree(doc: locals.Doctype) {
	return frappe
		.call<any>('guigu_wechat.message.doc_recipient.get_doc_users', {
			doctype: doc.doctype,
			name: doc.name,
		})
		.then(v => v.message || []);
}
export default async function showSender(doc: locals.Doctype) {
	const value = ref<string[]>([]);
	const tree = ref<(Tree | UserNode)[]>();

	const vue = createApp({
		setup() {
			return () => {
				const data = tree.value;
				if (!data) {
					return h('p', __('Loading...'));
				}
				return h(ElTreeSelect, {
					placeholder: __('Choose To Accept Users'),
					modelValue: value.value,
					'onUpdate:modelValue': (v: string[]) => {
						value.value = v;
					},
					data,
					multiple: true,
					showCheckbox: true,
					defaultExpandAll: true,
				});
			};
		},
	});
	let waiting = false;
	const dialog = new frappe.ui.Dialog({
		title: __('Send WeChat Message'),
		fields: [{ fieldname: 'main', fieldtype: 'HTML', label: '' }],
		primary_action_label: __('Send'),
		async primary_action() {
			if (waiting) {
				return;
			}
			const users = value.value;
			if (!users.length) {
				// TODO:
			}
			waiting = true;
			// 用户点击确认后提交
			await sendDocRemind(doc.doctype, doc.name, users);

			waiting = false;
			vue.unmount();
			dialog.hide();
		},
		on_hide() {
			vue.unmount();
		},
	});
	const p = (dialog.fields_dict.main as any).wrapper as HTMLElement;
	vue.mount(p.appendChild(document.createElement('div')));
	dialog.show();

	tree.value = await getTree(doc);
}
