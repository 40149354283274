import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["AgGridVue"], {
      class: "ag-theme-guigu",
      columnDefs: $setup.columnDefs,
      rowData: $props.structureProgresses,
      rowDragMultiRow: "",
      rowSelection: "multiple",
      onGridReady: _cache[0] || (_cache[0] = $event => ($setup.gridApi = $event.api)),
      getRowId: $setup.getRowId,
      domLayout: "autoHeight",
      onCellValueChanged: $setup.cellValueChanged,
      detailRowAutoHeight: true,
      groupDefaultExpanded: 1,
      suppressDragLeaveHidesColumns: $setup.smallMeta,
      localeText: $setup.zhCN,
      defaultColDef: $setup.defaultColDef
    }, null, 8 /* PROPS */, ["columnDefs", "rowData", "suppressDragLeaveHidesColumns", "localeText"]),
    _createVNode($setup["LinkBoqDialog"], {
      project: $props.project,
      visible: $setup.visible,
      structureProgress: $setup.structureProgressLink,
      onClose: $setup.close,
      onConfirm: $setup.confirm
    }, null, 8 /* PROPS */, ["project", "visible", "structureProgress"])
  ]))
}