import { TeamNav, TreeData } from './type/index';

export function navToTree(nav: TeamNav[], date: string): TreeData[] {
	if (!nav) {
		return [];
	}
	const cloneNav = structuredClone(nav);
	const data: any[] = [];
	cloneNav.forEach(team => {
		const cloneTeam = {
			key: team.name,
			label: team.team_name,
			disabled: true,
			children: team.structures.map(structure => ({
				key: `${team.name}_${structure.name}_${date}`,
				label: structure.structure_name,
			})),
		};
		data.push(cloneTeam);
	});
	return data;
}

export function isInViewWindow(root: HTMLDivElement, info: Element) {
	const { top: infoTop, bottom: infoBottom } = info.getBoundingClientRect();
	const { top: rootTop, bottom: rootBottom } = root.getBoundingClientRect();
	return infoTop < rootBottom && infoBottom > rootTop;
}
