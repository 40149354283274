import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b793e31"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["ElForm"], null, {
      default: _withCtx(() => [
        _createVNode($setup["ElFormItem"], { label: "应用名称" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.appInfo.app_name), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElFormItem"], { label: "应用版本" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.appInfo.app_version), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElFormItem"], { label: "git分支" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.appInfo.git_branch), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}