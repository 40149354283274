<template>
	<div v-loading="loading">
		<h4 class="title">{{ tt('Relate Work List') }}</h4>
		<AgGridVue
			class="ag-theme-guigu"
			:columnDefs="columnDefs"
			:rowData="list"
			:defaultColDef="defaultColDef"
			rowSelection="multiple"
			animateRows="true"
			@grid-ready="onGridReady"
			groupDefaultExpanded="-1"
			:getRowNodeId="getRowId"
			domLayout="autoHeight"
			:localeText="zhCN"
			excludeChildrenWhenTreeDataFiltering
			suppressPaginationPanel
			:groupAllowUnbalanced="false"
			groupSelectsFiltered
			:suppressDragLeaveHidesColumns="smallMeta" />
	</div>
	<div class="btn-group" v-if="editable">
		<ElButton type="primary" @click="add">{{ tt('Relate') }}</ElButton>
	</div>
	<RelateDialog
		v-model:visible="formVisible"
		:organization="organization"
		:separator="separator"
		@relateWorkList="relateWorkList"
	></RelateDialog>
</template>

<script setup lang="ts">
import {computed, ref, watch, defineProps, shallowRef, onMounted} from 'vue';
import type {ColumnApi, GridApi, ICellEditorParams, ColDef, GridReadyEvent} from 'ag-grid-community';
import {ElButton, vLoading} from 'element-plus';
import {AgGridVue} from 'ag-grid-vue3';

import {useMetaQuery} from '../../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';
import zhCN from '../../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';
import ActionComponent from '../../components/Action.vue';
import {useInstructionStore} from '../../instruction/store';
import Link from '../../components/Link.vue';

import RelateDialog from './RelateDialog.vue';
import type {Worklist} from './type';
interface Result{
	'title':string,
	'name':string,
	'work_name':string,
	'type':'organization_work_list'|'standard_work_list',
	'dw_work_instruction_version'?:string
}
const store = useInstructionStore();

const smallMeta = useMetaQuery();
const tt = __;
interface Emit {
	(e: 'remove'): void,
	(e: 'setData', data:string): void,
}
const emit = defineEmits<Emit>();
interface Props {
	editable: boolean;
	data:string
	chartName:string
}
const props = defineProps<Props>();
const gridApi = ref<GridApi | null>(null);
const gridColumnApi = shallowRef<ColumnApi>();
const formVisible = ref<boolean>(false);
const DOCTYPE = 'Dw Work Instruction Work List';
const list = ref<Result[]>([]);
const loading = ref<boolean>(true);
const organization = ref<string>('');
const separator = ref<string>('.');
store.afterSave(({instruction})=>{
	const versionId = instruction?.current_version;
	if (!versionId || store.isSaveNewVersion) {
		return;
	}
	const notRelatedNames = list.value
		.filter(item=>item.dw_work_instruction_version !== versionId)
		.map(item=>item.name);
	if (notRelatedNames.length === 0) {
		return;
	}
	frappe.call({
		method: 'dw_worklist_management.api.instruction.block.relate_version',
		args: {names: notRelatedNames, version_name: versionId, doctype: DOCTYPE},
	});
});
function remove() {
	frappe.call({
		method: 'dw_worklist_management.api.instruction.block.bulk_delete',
		args: {names: list.value.map(item=>item.name), doctype: DOCTYPE},
	});
}
defineExpose({
	remove: remove,
});
const defaultColDef = computed(()=>({
	sortable: false,
	filter: false,
	minWidth: 100,
	resizable: true,
	editable: false,
	enableRowGroup: false,
}));

const onGridReady = (params: GridReadyEvent) => {
	gridApi.value = params.api;
	gridColumnApi.value = params.columnApi;
};
const columnDefs = computed(()=>{
	const cols:ColDef[] = [
		{
			headerName: __('Title'),
			field: 'title',
			flex: 1,
			cellRenderer: Link,
			cellRendererParams: (params: ICellEditorParams) => {
				const label = params.value;
				const value = params.data.work_name;
				const options = params.data.type === 'organization_work_list' ? 'Dw Organization Worklist' : 'Dw Standard Worklist';
				return {...params, label, value, options};
			},
		},
	];
	if (props.editable) {
		cols.push({
			headerName: __('Actions'),
			field: 'action',
			cellRenderer: ActionComponent,
			pinned: smallMeta ? undefined : 'right',
			resizable: false,
			width: 100,
			menuTabs: [],
			cellRendererParams: (params: ICellEditorParams) => {
				const fn: {
					unRelate?: (data: Result) => void
				} = {};
				if (props.editable) {
					fn.unRelate = async (data: Result) => {
						loading.value = true;
						await frappe.db.delete_doc(DOCTYPE, data.name);
						const index = list.value.findIndex(item=>item.name === data.name);
						list.value.splice(index, 1);
						emit('setData', JSON.stringify(list.value.map(item=>item.name)));
						loading.value = false;
					};
				}
				return {...params, ...fn};
			},
		});
	}
	return cols;
});

const parsedData = computed<string[]>(()=>JSON.parse(props.data || '[]'));
watch([()=>store.instruction.reference_type, ()=>store.instruction.reference_name], async ()=>{
	if (store.instruction.reference_type === 'Dw Organization Worklist') {
		if (!store.instruction.reference_name) {
			organization.value = '';
		}
		const organizationWorkList = await frappe.db.get_doc('Dw Organization Worklist', store.instruction.reference_name);
		organization.value = organizationWorkList.organization_unit;
		return;
	}
	organization.value = '';
}, {immediate: true});
watch(parsedData, ()=>{
	getRelateWorkList(parsedData.value);
}, {immediate: true});

async function getRelateWorkList(data:string[]) {
	if (!data || data.length === 0) {
		loading.value = false;
		return;
	}
	loading.value = true;
	const res = await frappe.call<{message:Result[]}>({
		method: 'dw_worklist_management.api.instruction.block.get_relate_work_list',
		args: {
			names: data,
		},
	});
	list.value = res?.message || [];
	loading.value = false;
}

function add() {
	formVisible.value = true;
}
async function relateWorkList(data:Worklist) {
	loading.value = true;
	const newData = await frappe.db.insert({
		doctype: 'Dw Work Instruction Work List',
		reference_type: data.org_worklist_name ? 'Dw Organization Worklist' : 'Dw Standard Worklist',
		reference_name: data.org_worklist_name || data.name,
		dw_work_instruction_version: store.currentVersion.name,
	});
	list.value.push({
		title: data.title,
		name: newData.name,
		work_name: data.org_worklist_name || data.name,
		type: data.org_worklist_name ? 'organization_work_list' : 'standard_work_list',
		dw_work_instruction_version: store.currentVersion.name,
	});
	emit('setData', JSON.stringify(list.value.map(item=>item.name)));
	loading.value = false;
}
function getRowId(data: {name:string}) {
	return data.name;
}

frappe.realtime.on('doc_update', params => {
	if (params.doctype !== 'Dw Standard Worklist' && params.doctype !== 'Dw Organization Worklist') {
		return;
	}
	getRelateWorkList(parsedData.value);
});
async function getSeparator() {
	separator.value = await frappe.db.get_single_value('Dw Process Separator Rule', 'separator') || '.';
}
onMounted(() => {
	getSeparator();
});
</script>
<style scoped lang="less">
.title{
	text-align: center;
	margin-bottom: 1.5rem;
}
.btn-group{
	margin-top: 8px;
}
</style>
