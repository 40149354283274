import { Filter } from '../../GlobalView/types';
export const types = ['View Link', 'DocType Link', 'List', 'URL'] as const;

export interface Config{
	doctype:string,
	label:string,
	filters:Filter[]
}
export interface TableData{
	name:string,
	label:string
	type:(typeof types)[number],
	icon?:string,
	viewLinkConfig?:Config&{
		view:string
	},
	doctypeLinkConfig?:Config &{
		view:string
		option:any
	}
	listLinkConfig?:Config,
	urlConfig?:{
		url:string,
		label:string
	}
}
