import {mergeAttributes, Node, type Editor} from '@tiptap/core';
import {VueNodeViewRenderer, type NodeConfig} from '@tiptap/vue-3';
import {Plugin, PluginKey} from '@tiptap/pm/state';

import Chart from '../components/Chart.vue';

export function createNodeExtension(options:NodeConfig) {
	const {name, component, tag, ...rest} = options;
	return Node.create({
		name,
		group: 'block',
		atom: true,
		draggable: true,
		exitable: true,
		isolating: true,
		defining: true,
		addAttributes() {
			return rest.attributes || {};
		},
		parseHTML() {
			return [{tag: `${tag}[data-type="draggable-item"]`}];
		},
		renderHTML({HTMLAttributes}) {
			return [tag, mergeAttributes(HTMLAttributes, {'data-type': 'draggable-item'})];
		},
		addNodeView() {
			return VueNodeViewRenderer(component);
		},
		addProseMirrorPlugins() {
			const self = this;
			return [
				new Plugin({
					key: new PluginKey('custom'),
					props: {
						handleTextInput: function() {
							return self.editor.state.selection?.node?.type?.name === 'chart';
						},
					},
				}),
			];
		},
		addKeyboardShortcuts() {
			return {
				Backspace: function({editor}) {
					return isChartInSelection(editor);
				},
				'Mod-Backspace': function({editor}) {
					return isChartInSelection(editor);
				},
			};
		},
	});
}

export function isChartInSelection(editor:Editor) {
	const {from, to} = editor.state.selection;
	const customNodes = editor.$nodes('chart') || [];
	const isInSelection = customNodes?.some(item=>item.from >= from && item.to <= to);
	const isEnd2End = customNodes.some(item=>to === item.to);
	const isSelectedChart = editor.state.selection?.node?.type?.name === 'chart';
	return isEnd2End || isInSelection || isSelectedChart;
}
export default createNodeExtension({
	name: 'chart',
	tag: 'chart',
	component: Chart,
	attributes: {
		chart_name: undefined,
		chart_type: undefined,
		data: undefined,
	},
});
