
<template>
	<Page>
		<template #title>
			<h3 class="title">{{ tt('Dw Organizational Unit Work List') }}</h3>
		</template>
		<template #tools>
			<Tools
				:smallMeta="smallMeta"
				:processes="processes"
				:organization="organization"
				:departmentTree="departmentTree"
				:selectedWorkList="selectedWorkList"
				v-model:levelValue="levelValue"
				v-model:layoutType="layoutType"
				v-model:department="department"
				v-model:visible="visible"
				@toggleGroupsState="toggleGroupsState"
			>
			</Tools>
		</template>
		<Skeleton
			v-loading="loading"
			:layout="layoutType"
			:doctype="doctypeName"
			:detailName="detailName"
			@onChange="onChange"
			v-model:form-visible="formVisible"
		>
			<Table
				ref="aggrid"
				class="table-container"
				:smallMeta="smallMeta"
				:dataList="dataList"
				:loading="loading"
				:processes="processes"
				:levelStyle="levelStyle"
				v-model:layoutType="layoutType"
				@showProcessModalOrNavigate="showProcessModalOrNavigate"
				@updateSelectedWorkList="updateSelectedWorkList"
			></Table>
		</Skeleton>
	</Page>
	<DepartmentDialog
		v-if="visible"
		:departmentTree="departmentTree"
		v-model:visible="visible"
		v-model:selectedDepartment="selectedDepartment"
		@distributeDepartment="distributeDepartment"
	/>
</template>

<script setup lang="ts">
import {onMounted, onUnmounted, ref, watch} from 'vue';
import {vLoading} from 'element-plus';

import Page from '../../../../../../../guigu/guigu/public/js/components/page/index.vue';
import {useMetaQuery} from '../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';
import Skeleton from '../../../component/Skeleton/index.vue';
import {agGridTree, list2Tree} from '../../../utils/index';

import DepartmentDialog from './components/DepartmentDialog.vue';
import type {Process, WorkListData, AgGridData, LevelStyle, Department} from './components/type';
import Table from './components/Table.vue';
import Tools from './components/Tools.vue';
import {mergeData, isChildDepartment} from './components/helper';

const aggrid = ref<InstanceType<typeof Table> | null>(null);
const layoutType = ref<'modal'|'link'|'bottom'|'right'>('right');
const smallMeta = useMetaQuery();
const loading = ref<boolean>(false);
const levelValue = ref<string>('');
const organization = ref<string>(frappe.tianjy.organization.getCurrent());
const formVisible = ref<boolean>(false);
const visible = ref(false);
const selectedDepartment = ref('');
const detailName = ref<string>('');
const doctypeName = ref<string>('');
const department = ref<string>('');
const dataList = ref<AgGridData[]>([]);
const processes = ref<Process[]>([]);
const levelStyle = ref<LevelStyle[]>([]);
const departmentList = ref<Department[]>([]);
const departmentTree = ref<Department[]>([]);
const selectedWorkList = ref<string[]>([]);
const tt = __;
// 流程弹窗显示
function showProcessModalOrNavigate(name: string, doctype:string) {
	if (layoutType.value === 'link') {
		frappe.set_route(['Form', doctype, name]);
		return;
	}
	doctypeName.value = doctype;
	detailName.value = name;
	formVisible.value = true;
}
// 已勾选的组织单元工作清单
function updateSelectedWorkList(workLists: string[]) {
	selectedWorkList.value = workLists;
}
// 更新部门
function updateDepartment(list: AgGridData, department: string) {
	const newData = {...list};
	newData.department = department;
	newData.department_name = departmentList.value.find(item => item.name === department)?.department_name || '';
	aggrid.value?.gridApi?.applyTransaction({update: [newData]});
}
// 表单更新数据
function updateData(type: 'update' | 'delete', data: any) {
	const preData = dataList.value.find(item => item.name === data.name);
	switch (type) {
		case 'update': {
			// 更新条件：1. 适用 2. 组织单元不变 3. 部门筛选条件为空 4. 部门筛选条件不为空，但分配部门是部门筛选条件的子级
			const isUpdate = data.applicable
				&& data.organization_unit === organization.value
				&& (department.value === '' || (department.value !== '' && isChildDepartment(departmentTree.value, department.value, data.dw_department)));

			if (isUpdate && preData) {
				updateDepartment(preData, data.dw_department);
				return;
			}
			aggrid.value?.gridApi?.applyTransaction({remove: [preData]});
			break;
		}
		case 'delete': {
			aggrid.value?.gridApi?.applyTransaction({remove: [preData]});
			break;
		}
	}
}
// 批量编辑后更新部门
function updateDepartmentData() {
	if (!selectedWorkList.value.length || !dataList.value.length) {
		return;
	}
	const workMap = new Map(dataList.value.map(item => [item.name, item]));

	// 更新条件：1.部门筛选条件为空 且与分配的部门不同 2.部门筛选条件不为空 分配的部门是部门筛选条件的子级
	const isUpdate = (department.value === '' && department.value !== selectedDepartment.value)
		|| (department.value !== '' && isChildDepartment(departmentTree.value, department.value, selectedDepartment.value));

	for (const name of selectedWorkList.value) {
		const workItem = workMap.get(name);
		if (workItem) {
			if (isUpdate) {
				updateDepartment(workItem, selectedDepartment.value);
			} else {
				aggrid.value?.gridApi?.applyTransaction({remove: [workItem]});
			}
		}
	}
	aggrid.value?.clearSelectRows();
}
// 组织单元工作清单批量分配部门
async function distributeDepartment() {
	try {
		await frappe.call({
			method: 'frappe.desk.doctype.bulk_update.bulk_update.submit_cancel_or_update_docs',
			args: {
				doctype: 'Dw Organization Worklist',
				docnames: selectedWorkList.value,
				freeze: true,
				action: 'update',
				data: {
					dw_department: selectedDepartment.value,
				},
			},
		});
		frappe.show_alert(__('Distribute Successfully'));
	} catch (error) {
		frappe.show_alert(__('Distribute Failed'));
	}
	updateDepartmentData();
	selectedDepartment.value = '';
}
// 组织单元工作清单编辑后返回的数据
function onChange(returnData: any) {
	const {type, data} = returnData;
	updateData(type, data);
}
// 展开或收起
function toggleGroupsState(state: boolean) {
	if (aggrid.value?.gridApi) {
		if (state) {
			aggrid.value.gridApi.expandAll();
		} else {
			aggrid.value.gridApi.collapseAll();
		}
	}
}
// 获取组织单元工作清单列表
async function getOrganizationWorkList() {
	loading.value = true;
	const res = await frappe.call<{ message: WorkListData}>({
		method: 'dw_worklist_management.dw_worklist_management.doctype.dw_organization_worklist.dw_organization_worklist.get_organization_worklist',
		args: {
			organization: organization.value,
			process: levelValue.value,
			department: department.value,
		},
	});
	if (res?.message) {
		if (res.message.worklist_list.length === 0) {
			dataList.value = [];
			loading.value = false;
			return;
		}
		const list = mergeData(res.message);
		dataList.value = agGridTree(list, 'parent_dw_process', 'name') || [];
		if (!processes.value.length) {
			processes.value = res?.message.process_list.map(item => {
				item.process_name = item.title;
				return item;
			}) || [];
		}
	}
	loading.value = false;
}
// 获取层级样式
async function getLevelStyleList() {
	levelStyle.value = await frappe.db.get_list('Dw Process Level Style', {
		fields: ['level', 'row_background_color', 'row_height', 'font_color', 'is_bold', 'icon'],
		limit: 0,
	});
}
// 获取组织下的部门
async function getDepartment() {
	const departmentRes:Department[] = await frappe.db.get_list('Dw Department', {
		filters: {
			organization: organization.value,
		},
		fields: ['name', 'department_name', 'organization', 'parent_dw_department'],
		limit: 0,
	});
	departmentList.value = departmentRes;
	departmentTree.value = list2Tree(departmentRes, 'parent_dw_department', 'name');
	// 设置默认部门
	const departments = departmentRes.map(item => item.name);
	const res = await frappe.db.get_list('Dw Department Member', {
		filters: [
			['dw_department_user', '=', frappe.session.user],
			['department', 'in', departments],
		],
		fields: ['department'],
		limit: 0,
	});
	if (!department.value) {
		department.value = res[0]?.department || '';
	}
}
function getSearchParams() {
	const params = new URLSearchParams(window.location.search);
	if (params.get('organization')) {
		organization.value = params.get('organization') || frappe.tianjy.organization.getCurrent();
	}
	if (params.get('layout')) {
		layoutType.value = params.get('layout') as('modal'|'link'|'bottom'|'right') || 'right';
	}
	if (params.get('department')) {
		department.value = params.get('department') || '';
	}
}
getSearchParams();
$(frappe.pages.dw_organizational_unit_work_list).on('show', function() {
	getSearchParams();
});
// 更新URL路径
function updateUrl() {
	const params = new URLSearchParams(window.location.search);
	params.set('organization', organization.value);
	params.set('layout', layoutType.value);
	params.set('department', department.value);
	history.replaceState(null, '', `?${params.toString()}`);
}
watch(
	[levelValue, organization, department],
	async () => {
		updateUrl();
		getOrganizationWorkList();
	},
	{immediate: true},
);
watch([layoutType], () => {
	updateUrl();
});
function popstateListener() {
	getOrganizationWorkList();
}

onMounted(async () => {
	await getLevelStyleList();
	await getDepartment();
	getOrganizationWorkList();
	window.addEventListener('popstate', popstateListener);
});
onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});
</script>
<style lang="less" scoped>
  .title {
    height: 75px;
    line-height: 75px;
    margin-bottom: 0;
  }
  .table-container {
    height: 100%;
	overflow: auto;
  }
</style>
