<template>
	<component :is="comp"
		:meta="meta"
		:options="options"

		v-model:data="data"
		:total="total"
		:loading="loading"


		v-model:modelValue="modelValue"
		v-model:selected="selected"


		:infiniteScroll="infiniteScroll"
		:page="page"
		:limit="limit"
		:group="group"
		:sort="sort"
		:filters="filters"


		:detail="detail"
		:rowAction="rowAction"


		:view="view"
		v-model:configuration="configuration"


		@refresh="emit('refresh')"
		@delete="(...p) => emit('delete', ...p)"
		@create="(data, extend) => emit('create', data, extend)"
		@nextPage="emit('nextPage')"
		@filter="(field, operator, value) => emit('filter', field, operator, value)"
		@sort="(target, docs, before, children) => emit('sort', target, docs, before, children)" />
</template>
<script lang="ts" setup>
import { computed } from 'vue';

import { ListConfiguration } from './types';
import List from './List.vue';
import Tree from './Tree.vue';


const props = defineProps<{
	meta: locals.DocType;
	options: Record<string, any>;

	data: locals.Doctype[];
	total: number;
	loading?: boolean;

	modelValue: any;
	selected?: any[];

	infiniteScroll: boolean;
	page: number;
	limit: number;
	group: GlobalView.Group[];
	sort: GlobalView.MainLoaderOptions['order'];
	filters?: any;

	detail?: boolean;
	rowAction?: any;


	view?: GlobalView.View;
	configuration?: ListConfiguration;
}>();
const emit = defineEmits<{
	(event: 'refresh'): void;
	(event: 'delete', ...p: any[]): void;
	(event: 'create', data: any, extend?: boolean): void;
	(event: 'nextPage'): void;
	(event: 'update:modelValue', value: any): void;
	(event: 'update:selected', value: any[]): void;
	(event: 'update:data', data: locals.Doctype[]): void;
	(event: 'filter', field: string, operator: string, value: any): void;
	(event: 'update:configuration', cfg: any): void;

	(event: 'sort', target: string, docs: string[], before: boolean, field?: string): void
	(event: 'sort', target: string, docs: string[], before: boolean, children?: boolean): void
}>();
const modelValue = computed({
	get: () => props.modelValue,
	set: v => emit('update:modelValue', v),
});
const selected = computed({
	get: () => props.selected,
	set: v => emit('update:selected', v || []),
});
const data = computed({
	get: () => props.data,
	set: v => emit('update:data', v),
});
const configuration = computed({
	get: () => props.configuration,
	set: v => emit('update:configuration', v),
});
const comp = computed(() => configuration.value?.tree ? Tree : List);
</script>
