<template>
	<div class="toolbar">
		<div class="left-container">
			<ProjectCom
				v-if="type === 'project'"
				class="input"
				noLabel
				v-model="projectValue"
			></ProjectCom>
			<ElSelect
				v-else
				class="input"
				filterable
				:modelValue="departmentName"
				@change="changeType"
			>
				<ElOption
					v-for="item in departments"
					:key="item.name"
					:label="item.label"
					:value="item.name"
				/>
			</ElSelect>
			<ElDatePicker
				class="date-input"
				v-model="date"
				valueFormat="YYYY-MM-DD"
				type="week"
				:format="tt('gggg-ww Week')"
				:clearable="false"
			/>
		</div>
		<ElSpace v-if="exportable">
			<ElButton @click="exportList">{{ tt('Export') }}</ElButton>
		</ElSpace>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { Plus } from '@element-plus/icons-vue';

import { getBinaryContent, exportDocx, saveAs, zip } from '../../export/export';
import type { Company, Project, Permissions, ExportItem } from '../type';
import ProjectCom from '../../../../../../../../guigu/guigu/public/js/components/PageComponents/Tools/Project.vue';

import { getExportData } from './getExportData';

const tt = __;
interface Props {
	type: 'project' | 'subCompany';
	departmentName?: string;
	week?: string;
	departments: { name: string; label: string }[];
	smallMeta: boolean;
	exportable: boolean;
}
const props = defineProps<Props>();
interface Emit {
	(e: 'update:departmentName', project: string): void;
	(e: 'update:week', date?: string): void;
}
const emit = defineEmits<Emit>();
const date = computed({
	get: () => props.week || '',
	set: (v: string) => {
		emit('update:week', moment(v).add(1, 'days').format('YYYY-MM-DD'));
	},
});
function changeType(newTypeName: string) {
	emit('update:departmentName', newTypeName);
}
const projectValue = computed({
	get: () => props.departmentName || '',
	set: (v: string) => {
		emit('update:departmentName', v);
	},
});
async function exportList() {
	const templatePromise = getBinaryContent(
		'/assets/guigu_hr/files/week_template.docx',
	);

	const exportListPromise = frappe.call<{ message: ExportItem[] }>({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_project_week_assessment_list.guigu_hr_project_week_assessment_list.get_export_assessments',
		args: {
			week: props.week,
			type: props.type,
			department: props.departmentName,
		},
	});

	const interviewTemplatePromise = await getBinaryContent(
		'/assets/guigu_hr/files/interview_template.docx',
	);

	const [interviewTemplate, template, exportDataList] = await Promise.all([
		interviewTemplatePromise,
		templatePromise,
		exportListPromise,
	]);
	if (!exportDataList?.message.length) {
		return;
	}
	const assessDataList = getExportData(exportDataList?.message);
	const files: {
		user: string;
		userFolder: string;
		date: string;
		detail: any;
		interview: any;
	}[] = [];
	for (const assessData of assessDataList) {
		const detailOut = assessData.detail
			? await exportDocx(template, assessData.detail)
			: undefined;
		const interviewOut = assessData.interview
			? await exportDocx(interviewTemplate, assessData.interview)
			: undefined;
		let userFolder = assessData.user_doc.full_name;
		if (files.some(item => item.user === assessData.user_doc.full_name)) {
			const users = files.filter(
				item => item.user === assessData.user_doc.full_name,
			);
			const userIDs = users.map(item => {
				const res = item.userFolder.match(/\(([0-9]+)\)$/);
				if (res) {
					let n = 1;
					if (!isNaN(parseInt(res[1] || '1'))) {
						n = parseInt(res[1] || '1');
					}
					return n;
				}
				return 0;
			});
			userFolder += `(${Math.max(...userIDs) + 1})`;
		}
		files.push({
			user: assessData.user_doc.full_name,
			userFolder: userFolder,
			date: assessData.date,
			detail: detailOut,
			interview: interviewOut,
		});
	}
	zip(files, 'week');
}

const flexDirection = computed(() => (props.smallMeta ? 'column' : 'row'));
const margin = computed(() => (props.smallMeta ? '8px' : '0'));
</script>

<style lang="less" scoped>
.toolbar {
	display: flex;
	flex-direction: v-bind(flexDirection);
	justify-content: space-between;
}
.input {
	margin-right: 8px;
	margin-bottom: v-bind(margin);
}
:deep(.date-input) {
	margin-bottom: v-bind(margin);
}
</style>
