<template>
	<div class="action_container" v-if="!hideAction">
		<ElButton link type="primary" @click="unlink" >{{ tt('Cancel Process') }}</ElButton>
	</div>
</template>

<script setup lang='ts'>
import {computed} from 'vue';
import {ElButton} from 'element-plus';
const tt = __;
interface Params {
	data: any
	onRemove?: (data: any) => void,
	onEdit?: (data: any) => void
	unLink?:(data: any) => void
}
const hideAction = computed(()=>!Boolean(props.params.unLink));
const props = defineProps<{ params: Params }>();

function unlink() {
	props.params.unLink?.(props.params.data);
}

</script>

<style lang='less' scoped>

</style>
