<template lang="html">
	<WorkspaceWidget v-if="editing" class="widget">
		<template #title>
			{{ label }}
		</template>
		<div>
			编辑模式下不显示
		</div>
	</WorkspaceWidget>
	<WorkspaceWidget v-else-if="error" class="widget">
		<template #title>
			{{ label }}
		</template>
		<div>{{ error }}</div>
	</WorkspaceWidget>
	<KeepAlive v-else-if="loaded && docMeta">
		<Main v-if="domVisible" :meta="docMeta" :cfg="view"
			:label="label" class="widget" />
	</KeepAlive>
	<WorkspaceWidget v-else class="widget">
		<template #title>
			{{ label }}
		</template>
		<div>加载中...</div>
	</WorkspaceWidget>
</template>
<script setup lang="ts">
import {computed, onMounted, shallowRef} from 'vue';


import loadLinkDocTypes from '../../utils/loadLinkDocTypes';

import Main from './Main.vue';

const props = defineProps<{
	value?: any;
	editing: boolean;
}>();

const domVisible = shallowRef(true);
const docMeta = shallowRef();


const view = computed(() => props.value || {});
const error = shallowRef('');
const label = computed(() => {
	const {label, doctype} = view.value;
	if (label) {
		return label;
	}
	if (doctype) {
		return __('doctype');
	}
	return '<无标题>';
});


const loaded = shallowRef(false);
async function init() {
	if (props.editing) {
		return;
	}
	try {
		let meta = docMeta.value;
		const {value} = view;
		if (!meta) {
			const {doctype} = value || {};
			if (!doctype) {
				error.value = 'error';
				return;
			}
			await new Promise<void>(resolve => {
				frappe.model.with_doctype(doctype, () => {
					resolve();
				}, true);
			});
			meta = await frappe.get_meta(doctype);
			docMeta.value = meta;
		}
		await loadLinkDocTypes(meta);
		if (frappe.perm.has_perm(meta.name)) {
			loaded.value = true;
		} else {
			error.value = '无权限';
		}
	} catch (e) {
		console.error(e);
		error.value = 'error';
	}
}
onMounted(init);

</script>
<style scoped lang="less">
.widget {
	border-radius: var(--border-radius-md);
	box-shadow: var(--card-shadow);
	background-color: var(--card-bg);

}
</style>
