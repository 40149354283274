import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createBlock($setup["Timeline"], {
    mode: $setup.smallMeta ? 'right' : 'left',
    onLoadMore: $setup.loadMore
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.timelineResults, (timelineData, index) => {
        return (_openBlock(), _createBlock($setup["TimelineItem"], {
          date: $setup.smallMeta ? timelineData.date : '',
          notShowLine: index === ($setup.timelineResults?.length - 1),
          leftTitle: $setup.smallMeta ? '' : timelineData.date
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(timelineData.list, (detail) => {
              return (_openBlock(), _createBlock($setup["Detail"], {
                detail: detail,
                meta: $props.meta,
                fields: $setup.fields,
                linkField: $setup.linkField,
                preventClick: $setup.preventClick
              }, null, 8 /* PROPS */, ["detail", "meta", "fields", "linkField"]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["date", "notShowLine", "leftTitle"]))
      }), 256 /* UNKEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["mode"])), [
    [_directive_loading, $props.loading]
  ])
}