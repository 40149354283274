/* eslint-disable prefer-destructuring */

import { createApp } from 'vue';

import Flow from './Flow.vue';
frappe.provide('frappe.guigu_flow');

export interface RenderProps{
	frm: any,
	renderField: string,
	props:Record<string, any>
}

frappe.guigu_flow.render_flow = function (
	{
		frm,
		renderField,
		props,
	}: RenderProps,
) {
	const html_wrapper = $(frm.fields_dict[renderField].wrapper).find('div.html');
	const appName = `${props.field}_app`;
	if (html_wrapper.length === 0) {
		$(frm.fields_dict[renderField].wrapper).html(`
			<div class="html" style='height:calc(100vh - 190px)'>
			</div>
		`);
		const app = createApp(Flow, {
			...props,
			frm,
		});
		app.mount($(frm.fields_dict[renderField].wrapper).find('.html')[0]);
		frm[appName] = app;
	}
};

frappe.guigu_flow.render_flow_chart = function({
	doctype,
	renderField,
	dataField,
	isSilentMode,
	onChange,
}: {
	doctype: string
	renderField: string,
	dataField: string,
	isSilentMode: boolean,
	onChange?:(frm:any, data:any)=>void
}){
	frappe.ui.form.on(doctype, {
		refresh(frm: any) {
			const sourceData = frm.doc[dataField];
			const data = sourceData ? JSON.parse(sourceData) : {};
			frappe.guigu_flow.render_flow({
				frm,
				renderField,
				props: {
					data: data,
					options: {
						isSilentMode: isSilentMode,
						onChange(data: any) {
							onChange?.(frm, data);
						},
					},
				},
			});
		},
	});
};
