import Expander from '../Expander';
import { View, Action, widgets } from '../Gantt';
import type { GanttConfiguration } from '../Gantt';
import ListAction from '../GlobalView/ListAction';
import defineMainView from '../extends/init/defineMainView';

import Selected from './Selected.vue';

defineMainView<GanttConfiguration>({
	type: 'Guigu Gantt View Configuration',
	label: 'Gantt',

	sider: ['filters', 'filterList'],
	toolbar: [Selected, 'filterLine', ListAction],
	actions: [Action, Expander],
	detail: true,
	rowAction: true,
	view: View,
	pretreat: [],
	configuration: {
		widgets,
		displayField: {
			useDefault: true,

			titleField: true,
			commentField: true,
			tagField: true,
			dateExtField: true,

			width: true,
			enableLink: true,
		},
	},
	getConfigurations(meta, {
		fixed_field_columns,
		line_height,
		lines,
		dots,
		unit_default,
		estimated_lines,
		expanded_default,
	}: any) {
		const data: GanttConfiguration = {
			estimatedLines: estimated_lines,
			expandedDefault: expanded_default,
		};
		if (unit_default && typeof unit_default === 'string') {
			data.unitDefault = unit_default === 'none' ? '' : unit_default;
		}

		if (typeof fixed_field_columns === 'number') {
			data.fixedFieldColumns = fixed_field_columns;
		}
		if (line_height) { data.lineHeight = line_height; }

		if (lines?.length) {
			data.lines = lines.map((l: any) => ({
				label: l.label,
				start: l.start,
				end: l.end,
				position: l.position,
				width: l.width,
				color: l.color,
				borderColor: l.border_color,
				key: l.key,
				reference: l.reference,
				processingColor: l.processing_color,
				processingBorderColor: l.processing_border_color,
				processingOverdueColor: l.processing_overdue_color,
				processingOverdueBorderColor: l.processing_overdue_border_color,
				completeColor: l.complete_color,
				completeBorderColor: l.complete_border_color,
				completeOverdueColor: l.complete_overdue_color,
				completeOverdueBorderColor: l.complete_overdue_border_color,

				filterField: l.filter_field,
				filterValue: l.filter_value,
			}));
		}
		if (dots?.length) {
			data.dots = dots.map((d: any) => ({
				date: d.date,
				icon: d.icon,
				size: d.size,
				color: d.color,
				position: d.position,
				filterField: d.filter_field,
				filterValue: d.filter_value,
			}));
		}

		return data;
	},
	*fields(meta, cfg) {
		yield 'status';
		yield 'name';
		yield 'enabled';
		yield 'disabled';
		yield 'color';

		yield 'lft';
		yield 'rgt';
		yield 'is_group';
		yield meta.nsm_parent_field;

		for (const { start, end, filterField } of cfg.lines || []) {
			yield start;
			yield end;
			yield filterField;
		}
		for (const { start, end, plan, percent, method } of cfg.estimatedLines || []) {
			yield start;
			yield end;
			yield plan;
			yield percent;
			yield method;
		}
		for (const { date, filterField } of cfg.dots || []) {
			yield date;
			yield filterField;
		}
	},
});
