import mountVueToPage from 'guiguLib/mountVueToPage';
import ElementPlus from 'element-plus';

import Main from './components/main.vue';

export default function loadProjectLeaderDailyRecordStatistics(wrapper:HTMLElement) {
	const app = mountVueToPage(wrapper, Main);
	app.use(ElementPlus);
	frappe.router.on('change', () => {
		const [pageName] = frappe.router?.current_route || [];
		if (pageName !== 'project_leader_daily_record_statistics') {
			delete frappe?.pages?.project_leader_daily_record_statistics;
			app?.unmount();
		}
	});
}
frappe.provide('frappe.guigu_pm');
frappe.guigu_pm.guigu_pm_daily_record = frappe.guigu_pm.guigu_pm_daily_record || {};
frappe.guigu_pm.guigu_pm_daily_record.pages = frappe.guigu_pm.guigu_pm_daily_record.pages || {};
frappe.guigu_pm.guigu_pm_daily_record.pages.loadProjectLeaderDailyRecordStatistics = loadProjectLeaderDailyRecordStatistics;
