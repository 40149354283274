/* eslint-disable prefer-destructuring */
import type { Extension, ColumnOptions, Key, RowValue, ExtensionOption } from '../core';
import { createKey } from '../core';

import type { GanttHeader, Dot, Line, LineMeta } from './types';
import date2n from './date2n';
import renderHeaders from './renderHeaders';
import getBgGroup from './getBgGroup';
import getBg from './getBg';
import getLineDates from './getLineDates';
import type { LineInfo } from './getLineDates';
import getLineData from './getLineData';
import renderLine from './renderLine';
import getDotData, { getDotDate, type DotInfo } from './getDotData';
import renderDot from './renderDot';
import getDateData, { getDate } from './getDateData';
import createDateKey, { createDateGetter } from './createDateKey';

export type * from './types';


interface Options {
	dateFields?: Record<string, string | ((v: object) => Date | string | undefined)>;
	endDateFields?: Record<string, string | ((v: object) => Date | string | undefined)>;
	lines?: Line[];
	dots?: Dot[];
	summarize?: Key<boolean>;
	margin?: number;
	dayWidth?: number;
	headers?: GanttHeader[],
	tooltip?(v: any, dates: ([Date, Date | null, (LineMeta | undefined)?] | null)[]): string;
	bg?: GanttHeader;
	showDate?(start: Date, end: Date): [Date, Date]
	onChange?(today: number, dayWidth: number, p: {
		today: Date;
		start: Date;
		end: Date;
		days: number;
	}): void
}
const Gantt: Extension<Options> = (options, update, api, next, colOpt) => {
	const summarize = createKey(options.summarize);
	const todayStart = new Date();
	const todayEnd = new Date();
	let { tooltip, bg, showDate } = options;
	todayStart.setHours(0, 0, 0, 0);
	todayEnd.setHours(23, 59, 59, 999);
	const todayN = date2n(todayStart);

	let lineStartDate = todayStart;
	let lineEndDate = todayEnd;
	let dotStartDate = todayStart;
	let dotEndDate = todayEnd;
	let startDate = todayStart;
	let endDate = todayEnd;

	// TODO: 默认值
	let headerGets = options?.headers || [];
	let dateFields = Object.entries(options.dateFields || {}).map(
		([k, d]) => [k, createDateKey(d)] as [string, (v: any) => Date | undefined],
	) || [];
	let endDateFields = Object.entries(options.endDateFields || {}).map(
		([k, d]) => [k, createDateKey(d, true)] as [string, (v: any) => Date | undefined],
	) || [];
	let lines: LineInfo[] = options.lines?.map(({ start, end, ...line }) => ({
		start: createDateGetter(start),
		end: createDateGetter(end, true),
		...line,
	})) || [];
	let dots: DotInfo[] = options.dots?.map(({ date, ...l }) => ({
		date: createDateGetter(date), ...l,
	})) || [];
	let onChange = options.onChange;

	const headers = new Set<HTMLElement>();
	const cells = new Set<() => void>();
	let allLineData = new Map<number | string, ([Date, Date | null, LineMeta?] | null)[]>();
	let allDateData = new Map<number | string, Record<string, Date | undefined>>();
	let allEndDateData = new Map<number | string, Record<string, Date | undefined>>();
	let allDotData = new Map<number | string, (Date | null)[]>();

	// const varPrefix = `gantt-${`${Math.random()}`.substring(2)}`
	const varPrefix = `gantt`;
	const prefix = `--nyloong-table-${varPrefix}`;
	let dayWidth = options.dayWidth || 10;
	let bgGroup: number[] = [];
	let allData: readonly RowValue[] = [];

	function updateSize() {
		const s = Math.floor(date2n(startDate));
		const e = Math.floor(date2n(endDate));
		const n = Math.max(todayN - s, 0);
		api.setStyleVar(`${varPrefix}-start`, `${n}`);
		update({ width: Math.max((e - s + 1) * dayWidth, 0) });
	}

	function updateBg() {
		api.setStyleVar(`${varPrefix}-background`, getBg(bgGroup, dayWidth));
	}
	function updateDayWidth() {
		api.setStyleVar(`${varPrefix}-day-width`, `${dayWidth}px`);
		updateBg();
		updateSize();
	}
	function updateBgGroup() {
		bgGroup = bg ? getBgGroup(bg, startDate, endDate) : [];
		updateBg();
	}
	function renderAllHeaders() {
		if (!headerGets.length) { return; }
		renderHeaders(headerGets, [...headers], startDate, endDate);
	}
	function updateHeaders() {
		if (!headerGets.length) {
			update({ width: 0 });
			api.setStyleVar(`${varPrefix}-start`, `0`);
		}
		api.setStyleVar(`${varPrefix}-header-lines`, `${headerGets.length}`);
		renderAllHeaders();
	}
	function updateDateRange() {
		startDate = lineStartDate < dotStartDate ? lineStartDate : dotStartDate;
		endDate = lineEndDate < dotEndDate ? dotEndDate : lineEndDate;
		if (showDate) { [startDate, endDate] = showDate(startDate, endDate); }
		updateSize();
		updateBgGroup();
		renderAllHeaders();
		for (const f of [...cells]) { f(); }
	}
	function updateDateData() {
		allDateData = getDateData(allData, dateFields);
		allEndDateData = getDateData(allData, endDateFields);
	}
	function updateLineData() {
		[lineStartDate, lineEndDate, allLineData]
			= getLineData(allData, allDateData, allEndDateData, lines, summarize, todayStart, todayEnd);
		lines.map(({ width: height, position }, index) => {
			api.setStyleVar(`${varPrefix}-line-position-${index}`, `${position}px`);
			api.setStyleVar(`${varPrefix}-line-height-${index}`, `${height}px`);
		});
	}
	function updateDotData() {
		[dotStartDate, dotEndDate, allDotData]
			= getDotData(allData, allDateData, allEndDateData, dots, todayStart, todayEnd);
		dots.map(({ size, position }, index) => {
			api.setStyleVar(`${varPrefix}-dot-position-${index}`, `${position}px`);
			if (!size) { return; }
			const v = typeof size === 'number' ? `${size}px` : size;
			api.setStyleVar(`${varPrefix}-dot-size-${index}`, v);
		});
	}
	function updateAll() {
		updateDateData();
		updateLineData();
		updateDotData();
		updateDateRange();
		if (typeof onChange !== 'function') {
			return;
		}
		const s = Math.floor(date2n(startDate));
		const e = Math.floor(date2n(endDate));
		onChange(todayN - s, dayWidth, {
			today: new Date(todayStart),
			start: new Date(startDate),
			end: new Date(endDate),
			days: e - s + 1,
		});
	}

	updateAll();
	updateDayWidth();
	updateHeaders();
	return {
		hidden: colOpt.hidden,
		width: 200,
		area: 'gantt',
		updateOptions(options: Options, colOpt: ColumnOptions) {
			update({ hidden: colOpt.hidden });
			showDate = options.showDate;
			bg = options.bg;
			headerGets = options.headers || [];
			dateFields = Object.entries(options.dateFields || {})
				.map(([k, d]) => [k, createDateKey(d)]) || [];
			endDateFields = Object.entries(options.endDateFields || {})
				.map(([k, d]) => [k, createDateKey(d, true)]) || [];
			dots = options.dots?.map(({ date, ...l }) => ({
				date: createDateGetter(date), ...l,
			})) || [];
			lines = options.lines?.map(({ start, end, ...line }) => ({
				start: createDateGetter(start),
				end: createDateGetter(end, true),
				...line,
			})) || [];
			onChange = options.onChange;
			dayWidth = options.dayWidth || 10;
			updateAll();
			updateDayWidth();
			updateHeaders();
		},
		updateData(list) {
			allData = list;
			updateAll();
		},
		render: p => {
			let { id, data } = p;
			const root = document.createElement('div');
			// root.style.setProperty('--nyloong-table-gantt-start', `var(${prefix}-start)`);
			// root.style.setProperty('--nyloong-table-gantt-day-width', `var(${prefix}-day-width)`);
			root.classList.add('nyloong-table-gantt-cell');
			function update() {
				root.innerHTML = '';
				const lineDates = allLineData.get(id)
					|| getLineDates(data, getDate(data, dateFields), getDate(data, endDateFields), lines);
				for (const [index, line] of lines.entries()) {
					const el = renderLine(p, lineDates, line, index, todayN, prefix);
					if (el) { root.appendChild(el); }
				}
				const dotDates = allDotData.get(id)
					|| getDotDate(data, getDate(data, dateFields), getDate(data, endDateFields), dots);
				for (const [index, dot] of dots.entries()) {
					const el = renderDot(p, dotDates, dot, index, todayN, prefix);
					if (el) { root.appendChild(el); }
				}
				root.title = typeof tooltip === 'function' && tooltip(data, lineDates) || '';
			}
			update();

			cells.add(update);
			return {
				root,
				destroy() { cells.delete(update); },
				setHidden() { },
				update(row: RowValue) {
					if (data === row.data) { return; }
					data = row.data;
					update();
				},
			};
		},
		customize() {
			const root = document.createElement('div');
			root.classList.add('nyloong-table-gantt-customize');

			// root.style.setProperty('--nyloong-table-gantt-day-width', `var(${prefix}-day-width)`)
			// root.style.setProperty('--nyloong-table-gantt-start', `var(${prefix}-start)`)
			const today = root.appendChild(document.createElement('div'));
			today.classList.add('nyloong-table-gantt-today');
			return { root, destroy() { } };
		},
		header: () => {
			const root = document.createElement('div');
			headers.add(root);
			root.classList.add('nyloong-table-gantt-header');
			// root.style.setProperty('--nyloong-table-gantt-header-lines', `var(${prefix}-header-lines)`)
			// root.style.setProperty('--nyloong-table-gantt-day-width', `var(${prefix}-day-width)`);


			if (headerGets.length) {
				renderHeaders(headerGets, [root], startDate, endDate);
			}
			return {
				root,
				destroy() { headers.delete(root); },
			};
		},
	};
};

export default (options: Options): ExtensionOption<Options> => ({ ...options, extension: Gantt });
