import { Field } from '../types';

export interface Column {
	title: any;
	field: locals.DocField;
	name: string;
	showLabel?: boolean;
}

export default function getColumns(
	meta: locals.DocType,
	fields?: Field[],
) {
	if (!fields?.length) { return []; }
	const doctype = meta.name;
	const titleField = meta.title_field;
	const hasStatus = frappe.has_indicator(doctype);
	const columns: Record<string, Column> = Object.fromEntries(meta.fields.filter(df => {
		const { fieldname } = df;
		if (fieldname === 'status') {
			return !hasStatus && df.fieldname !== titleField;
		}
		const { fieldtype, options } = df;
		if (fieldtype === 'Code') {
			return false;
		}
		if (!(
			(frappe.model.is_value_type(fieldtype) &&
				frappe.perm.has_perm(doctype, df.permlevel, 'read')) ||
			(fieldtype === 'Currency' && options && !options.includes(':'))
		)) { return false; }
		return df.fieldname !== titleField;
	}).map(field => ({
		title: __(field.label || 'Field'),
		field,
		name: field.fieldname,
	})).map(v => [v.name, v]));
	columns.status_field = {
		title: __('Status'), name: 'Status', field: {
			label: 'Status',
			fieldname: 'status_field',
			fieldtype: '__Status__',
			parent: doctype,
		} as locals.DocField,
	};
	columns.Tag = {
		title: __('Tag'), name: 'Tag', field: {
			label: 'Tag',
			fieldname: 'Tag',
			fieldtype: '__Tag__',
			parent: doctype,
		} as locals.DocField,
	};

	columns._comments = {
		title: __('Last Comment'), name: '_comments', field: {
			label: 'Last Comment',
			fieldname: '_comments',
			fieldtype: '__LastComment__',
			parent: doctype,
		} as locals.DocField,
	};

	return fields.map(({ field, showLabel }) => {
		const column = field in columns ? columns[field] : undefined;
		if (column) { return { ...column, showLabel } as Column; }
	}).filter(Boolean as unknown as (v: any) => v is Column);
}
