import { createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElCheckbox = _resolveComponent("ElCheckbox")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Grouped')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElInput"], {
          modelValue: $setup.group,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.group) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Link Field')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElSelect"], {
          modelValue: $setup.linkField,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.linkField) = $event)),
          filterable: "",
          defaultFirstOption: "",
          clearable: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.linkFields, (field) => {
              return (_openBlock(), _createBlock($setup["ElOption"], {
                key: field.value,
                value: field.value,
                label: field.label
              }, null, 8 /* PROPS */, ["value", "label"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Default Layout')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElSelect"], {
          modelValue: $setup.defaultLayout,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.defaultLayout) = $event)),
          filterable: "",
          defaultFirstOption: "",
          clearable: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.layouts, (layout) => {
              return (_openBlock(), _createBlock($setup["ElOption"], {
                key: layout.value,
                value: layout.value,
                label: layout.label
              }, null, 8 /* PROPS */, ["value", "label"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    (!$setup.nonpageable)
      ? (_openBlock(), _createBlock($setup["ElFormItem"], {
          key: 0,
          label: $setup.tt('Max Limit')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElInputNumber"], {
              modelValue: $setup.maxLimit,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.maxLimit) = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]))
      : _createCommentVNode("v-if", true),
    (!$setup.nonpageable)
      ? (_openBlock(), _createBlock($setup["ElFormItem"], {
          key: 1,
          label: $setup.tt('Default Limit')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElInputNumber"], {
              modelValue: $setup.defaultLimit,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.defaultLimit) = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]))
      : _createCommentVNode("v-if", true),
    (!$setup.nonpageable && !$setup.scrollOnly)
      ? (_openBlock(), _createBlock($setup["ElFormItem"], {
          key: 2,
          label: $setup.tt('Infinite Scroll')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElSelect"], {
              modelValue: $setup.infiniteScroll,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.infiniteScroll) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode($setup["ElOption"], {
                  value: "Default",
                  label: $setup.tt('Default')
                }, null, 8 /* PROPS */, ["label"]),
                _createVNode($setup["ElOption"], {
                  value: "Yes",
                  label: $setup.tt('Yes')
                }, null, 8 /* PROPS */, ["label"]),
                _createVNode($setup["ElOption"], {
                  value: "No",
                  label: $setup.tt('No')
                }, null, 8 /* PROPS */, ["label"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Query Filters')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElSelect"], {
          modelValue: $setup.queryFilters,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($setup.queryFilters) = $event)),
          multiple: "",
          filterable: "",
          defaultFirstOption: "",
          clearable: "",
          style: {"width":"100%"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.queryFilterFields, (field) => {
              return (_openBlock(), _createBlock($setup["ElOption"], {
                key: field.fieldname,
                value: field.fieldname,
                label: field.label
              }, null, 8 /* PROPS */, ["value", "label"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Fields Copied When Creating Children')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElSelect"], {
          modelValue: $setup.fieldsCopiedWhenCreatingChildren,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($setup.fieldsCopiedWhenCreatingChildren) = $event)),
          multiple: "",
          filterable: "",
          defaultFirstOption: "",
          clearable: "",
          style: {"width":"100%"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fields, (field) => {
              return (_openBlock(), _createBlock($setup["ElOption"], {
                key: field.value,
                value: field.value,
                label: field.label
              }, null, 8 /* PROPS */, ["value", "label"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Fields Copied While Copying')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElSelect"], {
          modelValue: $setup.fieldsCopiedWhileCopying,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => (($setup.fieldsCopiedWhileCopying) = $event)),
          multiple: "",
          filterable: "",
          defaultFirstOption: "",
          clearable: "",
          style: {"width":"100%"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fields, (field) => {
              return (_openBlock(), _createBlock($setup["ElOption"], {
                key: field.value,
                value: field.value,
                label: field.label
              }, null, 8 /* PROPS */, ["value", "label"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Fields Requested')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElSelect"], {
          modelValue: $setup.fieldsRequested,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => (($setup.fieldsRequested) = $event)),
          multiple: "",
          filterable: "",
          defaultFirstOption: "",
          clearable: "",
          style: {"width":"100%"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fields, (field) => {
              return (_openBlock(), _createBlock($setup["ElOption"], {
                key: field.value,
                value: field.value,
                label: field.label
              }, null, 8 /* PROPS */, ["value", "label"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], null, {
      default: _withCtx(() => [
        _createVNode(_component_ElCheckbox, {
          modelValue: $setup.isHideAddBtn,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => (($setup.isHideAddBtn) = $event)),
          label: $setup.tt('Hide Add Button')
        }, null, 8 /* PROPS */, ["modelValue", "label"])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode($setup["ElFormItem"], null, {
      default: _withCtx(() => [
        _createVNode(_component_ElCheckbox, {
          modelValue: $setup.isHideSider,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => (($setup.isHideSider) = $event)),
          label: $setup.tt('Hide Side')
        }, null, 8 /* PROPS */, ["modelValue", "label"])
      ]),
      _: 1 /* STABLE */
    })
  ], 64 /* STABLE_FRAGMENT */))
}