<template>
  <ElDialog v-model="visible" :title="value ? tt('Modified') : tt('Create')" modal width="min(95%, 400px)">
    <ElForm>
      <ElFormItem>
        <ElInput v-model="title" />
      </ElFormItem>
      <ElFormItem v-if="!value">
        <ElCheckbox v-model="publish" :label="tt('Public')" />
      </ElFormItem>
      <ElFormItem>
        <GroupEditor v-model="groups" :options="groupOptions" :label="tt('Group Configuration')" :checkLabel="tt('Is Swimming Lane')" />
      </ElFormItem>
    </ElForm>
			<ElFormItem>
				<GroupEditor
					v-model="fields"
					:options="showOptions"
					repeatable
					:label="tt('Can Display Column Configuration')"
					:checkLabel="tt('Show Tags')" />
			</ElFormItem>

    <template #footer>
      <ElButtonGroup>
        <ElButton @click="close" :title="tt('Cancel')" :icon="Close" />
        <ElButton type="primary" @click="submit" :title="tt('Confirm')" :disabled="!groups.length" :icon="Finished" />
      </ElButtonGroup>
    </template>
  </ElDialog>
</template>
<script lang="ts" setup>
  import { computed, ref, watchEffect } from 'vue';
  import { ElDialog, ElButton, ElButtonGroup, ElInput, ElCheckbox, ElForm, ElFormItem } from 'element-plus';
  import { Finished, Close } from '@element-plus/icons-vue';

  import type { Group, Kanban } from '../../types';
  import groupFieldTypes from '../groupFieldTypes';

  import GroupEditor from './Group.vue';

  const tt = __;

  const props = defineProps<{
    meta: locals.DocType;
    visible: boolean;
    value?: Kanban;
  }>();

  const emit = defineEmits<{
    (event: 'update', data: any, name?: string): void;
    (event: 'update:visible', visible: boolean): void;
  }>();

  const visible = computed({
    get() {
      return props.visible;
    },
    set(v) {
      emit('update:visible', v);
    },
  });

  const groupOptions = computed(() => {
    const { meta } = props;
    const doctype = meta.name;
    const list: { label: any; field: string }[] = [];
    function add_field_option({ fieldname: field, label, fieldtype: type, permlevel }: any) {
      if (!groupFieldTypes.has(type.toLowerCase())) {
        return;
      }
      if (!frappe.perm.has_perm(doctype, permlevel, 'read')) {
        return;
      }
      if (field === 'docstatus' && !frappe.model.is_submittable(doctype)) {
        return;
      }
      if (!frappe.model.is_value_type(type)) {
        return;
      }
      list.push({ field, label: __(label || field) });
    }
    for (const df of frappe.model.std_fields) {
      add_field_option(df);
    }
    if (meta.istable) {
      add_field_option({ fieldname: 'parent', fieldtype: 'Data', label: 'Parent' });
    }
    for (const df of meta.fields) {
      add_field_option(df);
    }
    const seen = new Set(['_user_tags', '_liked_by', '_comments']);
    return list.filter(({ field }) => {
      if (seen.has(field)) {
        return false;
      }
      seen.add(field);
      return true;
    });
  });

  const showOptions = computed(() => {
    const { meta } = props;

    const doctype = meta.name;
    const titleField = meta.title_field;
    const hasStatus = frappe.has_indicator(doctype);
    const list = meta.fields
      .filter((df) => {
        const { fieldname } = df;
        if (fieldname === 'status') {
          return !hasStatus && df.fieldname !== titleField;
        }
        const { fieldtype, options } = df;
        if (fieldtype === 'Code') {
          return false;
        }
        if (
          !(
            (frappe.model.is_value_type(fieldtype) && frappe.perm.has_perm(doctype, df.permlevel, 'read')) ||
            (fieldtype === 'Currency' && options && !options.includes(':'))
          )
        ) {
          return false;
        }
        return df.fieldname !== titleField;
      })
      .map((field) => ({
        label: __(field.label || 'Field'),
        field: field.fieldname,
      }));
    list.push(
      { label: __('Status'), field: 'Status' },
      { label: __('Tag'), field: 'Tag' },
      { label: __('Last Comment'), field: '_comments' }
    );
    const seen = new Set();
    return list.filter(({ field }) => {
      if (seen.has(field)) {
        return false;
      }
      seen.add(field);
      return true;
    });
  });
  const groups = ref<{ field: string; checked?: boolean }[]>([]);
  const fields = ref<{ field: string; checked?: boolean }[]>([]);
  const title = ref('');
  const priv = ref(false);
  const publish = computed({
    get: () => !priv.value,
    set: (v) => {
      priv.value = !v;
    },
  });

  watchEffect(() => {
    if (!visible.value) {
      return;
    }
    const kb = props.value;
    groups.value = (kb?.groups || []).map(({ field, column: checked }) => ({ field, checked }));
    fields.value = (kb?.fields || []).map(({ field, showLabel: checked }) => ({ field, checked }));
    title.value = kb?.title || '';
    priv.value = kb?.private || false;
  });
  function getSubmitFields() {
    return fields.value.map((a) => ({
      field: a.field,
      showLabel: a.checked || false,
    }));
  }
  function getSubmitGroup() {
    const list: Group[] = [];
    const added = new Set<string>();
    const kb = props.value;
    const valueMap = new Map((kb?.groups || []).map((v) => [v.field, v.values]));
    for (const { field, checked } of groups.value) {
      if (added.has(field)) {
        continue;
      }
      added.add(field);
      list.push({ field, column: checked || false, values: valueMap.get(field) || [] });
    }
    return list;
  }

  function close() {
    visible.value = false;
  }
  function submit() {
    if (!groups.value.length) {
      return;
    }
    emit(
      'update',
      {
        title: title.value,
        groups: getSubmitGroup(),
        fields: getSubmitFields(),
        private: priv.value,
      },
      props.value?.name
    );
    visible.value = false;
  }
</script>
