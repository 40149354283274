frappe.form.formatters.GuiguDate = function (value:string, docfield:any, options?:string, doc?:any) {
	const type = docfield.options?.toLowerCase()||'month';
	if (!value||value===''){
		return '';
	}
	let lang = 'en';
	frappe.boot.user && (lang = frappe.boot.user.language);
	switch (type){
		case 'week':
			return moment(value).format(`gggg-ww[${lang==='zh'?'周':'week'}]`);
		case 'month':
			return moment(value).format('YYYY-MM');
		case 'year':
			return moment(value).format('YYYY');
		case 'quarter':
			return `${moment(value).format('YYYY')}-${moment(value).quarter()}季度`;
	}
};
