import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElTree"], {
    class: "directory-tree",
    style: {"max-width":"250px"},
    data: $setup.directory,
    props: $setup.defaultProps,
    defaultExpandAll: "",
    highlightCurrent: "",
    expandOnClickNode: false,
    onNodeClick: $setup.handleNodeClick
  }, null, 8 /* PROPS */, ["data"]))
}