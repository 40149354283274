<template>
  <el-dialog v-model="dialogVisible" :title="tt('Create New Addition and Subtraction Items')" :width="width" destroyOnClose @close="cancel">
    <div>
      <el-form class="extra-form" :model="form" :rules="rules" labelWidth="100px">
        <el-form-item :label="tt('Type')" prop="type">
          <el-select v-model="form.type">
            <el-option :label="tt('Assessment Bonus Item')" value="Add" />
            <el-option :label="tt('Subtraction Item')" value="Minus" />
          </el-select>
        </el-form-item>
        <el-form-item :label="tt('Specific Items For Adding Or Subtracting Points')" prop="subject">
          <el-input type="textarea" v-model="form.subject" />
        </el-form-item>
        <el-form-item :label="tt('Reasons For Adding Or Subtracting Points')" prop="reason">
          <el-input type="textarea" v-model="form.reason" />
        </el-form-item>
        <el-form-item :label="tt('Rating')" prop="score">
          <el-input-number v-model="form.score" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel">{{ tt('Cancel') }}</el-button>
        <el-button type="primary" @click="confirm"> {{ tt('Sure') }} </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
  import { ElMessage, ElMessageBox } from 'element-plus';
  import { computed, reactive, ref, watch } from 'vue';

  const tt = __;

  interface Props {
    visible: boolean;
    smallMeta: boolean;
  }
  const props = defineProps<Props>();
  interface Emit {
    (e: 'cancel'): void;
    (e: 'confirm', data: { subject: string; type: string; reason: string; score: number }): void;
  }
  const emit = defineEmits<Emit>();
  const dialogVisible = ref<boolean>(false);
  const width = computed(() => (props.smallMeta ? '90%' : '40%'));

  const form = reactive({
    subject: '',
    type: '',
    reason: '',
    score: 0,
  });
  const rules = reactive({
    subject: [{ required: true, message: __('Please fill in specific details'), trigger: 'blur' }],
    type: [{ required: true, message: __('Please select the type of plus or minus points'), trigger: 'blur' }],
  });
  watch(
    () => props.visible,
    () => {
      dialogVisible.value = props.visible;
    },
    { immediate: true }
  );

  function reset() {
    form.subject = '';
    form.type = '';
    form.reason = '';
    form.score = 0;
  }
  function confirm() {
    if (!form.subject || !form.type) {
      ElMessage({
        showClose: true,
        message: __('Please fill in the required fields'),
        type: 'error',
      });
      return;
    }
    emit('confirm', form);
    reset();
  }
  function cancel() {
    emit('cancel');
    reset();
  }
</script>

<style lang="less" scoped>
  .extra-form :deep(label) {
    margin-bottom: 0;
  }
</style>
