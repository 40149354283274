frappe.ui.form.ControlDatetime.prototype.set_formatted_input = function(value){
	if (this.timepicker_only) { return; }
	if (!this.datepicker) { return; }
	if (!value) {
		this.datepicker.clear();
		return;
	} else if (value.toLowerCase() === 'today') {
		value = this.get_now_date();
	} else if (value.toLowerCase() === 'now') {
		value = frappe.datetime.now_datetime();
	}
	value = this.format_for_input(value);
	this.$input && this.$input.val(value);
	// 修改frappe 代码，添加是否需要重新设置datepicker值的判断
	// this.datepicker.selectDate(frappe.datetime.user_to_obj(value));
	const last_value = this.format_for_input(this.last_value);
	let should_refresh = last_value && last_value !== value;

	if (!should_refresh) {
		if (this.datepicker.selectedDates.length > 0) {
			// if date is selected but different from value, refresh
			const selected_date = this.format_for_input(moment(this.datepicker.selectedDates[0]).format(
				this.date_format,
			));

			should_refresh = selected_date !== value;
		} else {
			// if datepicker has no selected date, refresh
			should_refresh = true;
		}
	}

	if (should_refresh) {
		this.datepicker.selectDate(frappe.datetime.user_to_obj(value));
	}
};
