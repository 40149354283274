<template>
	<el-dialog
		v-model="visible"
		:title="tt('Related Structure progress')"
		width="50%"
		:before-close="handleClose"
		custom-class="progress-dialog">
		<div>
			<ProgressItemTable
				:selectedProgresses="selectProgressNames"
				:progressItems="progressItems"
				@selectChange="selectChange"></ProgressItemTable>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<ElButton @click="handleClose">{{ tt('Cancel') }}</ElButton>
				<ElButton type="primary" @click="confirm">
					{{ tt('Confirm') }}
				</ElButton>
			</span>
		</template>
	</el-dialog>
</template>

<script setup lang='ts'>
import { ref, defineProps, defineEmits, toRefs, watch, getCurrentInstance, toRaw } from 'vue';

import { ProgressItem, StructureProgress } from '../type';

import ProgressItemTable from './ProgressItemTable.vue';
const tt = __
const { appContext } = getCurrentInstance() || {};
interface Props {
	visible: boolean,
	selectedProgresses: string[],
	progressItems: ProgressItem[]
	project: string
}
const props = defineProps<Props>();
const {
	visible,
	selectedProgresses,
	progressItems,
	project,
} = toRefs(props);
const selectProgressNames = ref<string[]>(selectedProgresses.value);
watch(() => props.selectedProgresses, () => {
	selectProgressNames.value = structuredClone(toRaw(selectedProgresses.value));
}, { deep: true, immediate: true });
interface Emit {
	(e: 'close'): void
	(e: 'confirm', progress: any[]): void
}
const emit = defineEmits<Emit>();

function handleClose() {
	emit('close');
}
async function confirm() {
	const frm = appContext?.config.globalProperties.$frm;
	const saveIds: string[] = [];
	selectProgressNames.value.forEach(item => {
		const progressItem = progressItems.value.find(each => each.name === item);
		if (progressItem?.is_group === 0) {
			saveIds.push(item);
		}
	});
	const newKeys = saveIds.filter(item => !selectedProgresses.value.includes(item));
	const deleteKeys = selectedProgresses.value.filter(item => !saveIds.includes(item));
	let { progress_item }: { progress_item?: StructureProgress[] | '' } = frm.get_doc();
	if (typeof progress_item === 'string') { progress_item = []; }
	const filterProgressItem = progress_item?.filter(item => !deleteKeys.includes(item.progress_item));
	const newProgressItem = newKeys.map(item => {
		const progress = progressItems.value.find(each => each.name === item);
		return {
			progress_item: item,
			project: project.value,
			progress_item_title: progress?.progress_name,
			structure_progress_name: `new-progress-${item}`,
		};
	});
	const structureProgress = filterProgressItem?.concat(newProgressItem);
	frm.set_value('progress_item', structureProgress);
	emit('confirm', structureProgress || []);
}
function selectChange(newSelectProgressNames: string[]) {
	selectProgressNames.value = newSelectProgressNames;
}
</script>

<style lang='less' scoped>
</style>
