
<template>
	<div class="toolbar">
		<div class="tool_left">
			<ProcessSearchTools
				:processes="processes"
				@getData="getData"
			></ProcessSearchTools>
			<div class="process_state">
				{{ tt('Process State') }}:
				<ElSelect v-model="disable" :placeholder="tt('Please Select')" size="small">
					<ElOption v-for="item,index in states" :key="index" :label="tt(item.label)" :value="item.value"/>
				</ElSelect>
			</div>
		</div>
		<div class="tool_right">
			<div class="tool_dropdown_menu">
				<LayoutSelect v-model:detailType="detailType"/>
			</div>
			<CollapsiblePanel :levels="levels" @toggle="isExpand"/>
			<ElButton type="primary" size="small" @click="addProcess" v-show="props.permission">{{ tt('Add Process') }}</ElButton>
		</div>
	</div>
</template>

<script setup lang="ts">
import {ElButton, ElSelect, ElOption} from 'element-plus';
import {computed} from 'vue';

import ProcessSearchTools from '../../../../component/ProcessSearchTools/index.vue';
import LayoutSelect from '../../../../component/LayoutSelect/index.vue';
import CollapsiblePanel from '../../../../component/CollapsiblePanel/index.vue';

import type{Process} from './type.ts';
const tt = __;
const states = [
	{label: 'Catch All', value: 'all'},
	{label: 'Enable', value: 'enable'},
	{label: 'Deactivate', value: 'disable'},
];
const props = defineProps<{
	permission: boolean;
	processes: Process[];
	levelValue:string
	layoutType: 'modal' | 'link' | 'bottom' | 'right';
	disable: 'all' | 'enable' | 'disable';
	smallMeta: boolean;
	levels: number;
}>();
const emit = defineEmits<{
	(e: 'update:levelValue', value: string): void;
	(e: 'update:disable', disable: 'all' | 'enable' | 'disable'): void;
	(e: 'update:layoutType', layoutType: string): void;
	(e: 'expandOrCollapse', expand: boolean, level?:number): void;
	(e: 'showProcessModalOrNavigate', name: string): void;
}>();
const detailType = computed({
	get: () => props.layoutType,
	set: (layoutType:string) => emit('update:layoutType', layoutType),
});
const disable = computed({
	get: () => props.disable,
	set: (disable:'all' | 'enable' | 'disable') => emit('update:disable', disable),
});
// 获取流程分级的value
function getData(value: string) {
	emit('update:levelValue', value);
}
// 展开层级
function isExpand(value:boolean, level?:number) {
	emit('expandOrCollapse', value, level);
}
// 新建流程
function addProcess() {
	const docName = frappe.model.make_new_doc_and_get_name('Dw Process', true);
	const docDetail = locals['Dw Process'][docName] as locals.Doctype;
	emit('showProcessModalOrNavigate', docDetail.name);
}

const flexDirection = computed(() => (props.smallMeta ? 'column' : 'row'));
</script>
<style lang="less" scoped>
  .toolbar {
    display: flex;
    flex-direction: v-bind(flexDirection);
	justify-content: space-between;

	.tool_left {
		display: flex;
		flex-direction: v-bind(flexDirection);
		align-items: center;
		flex-wrap: wrap;

		.process_state{
			white-space: nowrap;
			margin-bottom: 8px;
		}
	}
	.tool_right {
		display: flex;
		flex-direction: v-bind(flexDirection);
		flex-wrap: wrap;

		.tool_dropdown_menu{
			margin-right: 20px;
			margin-bottom: 8px;
		}
	}
}
</style>
