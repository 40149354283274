import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["FilterInput"], {
    class: "input",
    size: "small",
    meta: $setup.meta,
    name: $setup.quickFilter.fieldname,
    label: $setup.quickFilter.label,
    type: $setup.quickFilter.fieldtype,
    options: $setup.quickFilter.options,
    condition: $setup.quickFilter.condition || '=',
    fitWidth: $setup.fitWidth,
    modelValue: $setup.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.value) = $event))
  }, null, 8 /* PROPS */, ["meta", "name", "label", "type", "options", "condition", "fitWidth", "modelValue"]))
}