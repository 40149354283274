
<template>
	<div class="toolbar">
		<div class="tool_left">
			<div class="organizational_unit" v-if="organizationList">
				<span>{{ tt('Organizational Unit') }}:</span>
				<ElSelect class="input" size="small" v-model="organization" disabled>
					<ElOption :label="organizationList.label" :value="organizationList.name" />
				</ElSelect>
			</div>
			<div class="dw_department">
				<span>{{ tt('Dw Department') }}:</span>
				<ElCascader
					popperClass="dw_department_cascader"
					size="small"
					:placeholder="tt('Please Select')"
					clearable
					filterable
					:options="departmentTree"
					:props="cascaderProps"
					v-model="department"
				/>
			</div>
			<ProcessSearchTools :processes="processes" @getData="getData"></ProcessSearchTools>
		</div>
		<div class="tool_right">
			<div class="tool_dropdown_menu">
				<LayoutSelect v-model:detailType="detailType"/>
			</div>
			<CollapsiblePanel @toggle="isExpand" />
			<ElButton
				v-if="permission"
				class="distribute_department"
				type="primary"
				size="small"
				:disabled="!selectedWorkList.length"
				@click="visible=true">
				{{ tt('Distribute Department') }}
			</ElButton>
		</div>
	</div>
</template>

<script setup lang="ts">
import {ElSelect, ElOption, ElCascader, ElButton} from 'element-plus';
import {computed, onMounted, ref, watch} from 'vue';

import ProcessSearchTools from '../../../../component/ProcessSearchTools/index.vue';
import LayoutSelect from '../../../../component/LayoutSelect/index.vue';
import CollapsiblePanel from '../../../../component/CollapsiblePanel/index.vue';

import {hasPermission} from './helper';
import type {Process, Organization, Department} from './type';
const tt = __;
const organizationList = ref<Organization>();
const permission = ref<boolean>(false);
const props = defineProps<{
	levelValue: string;
	organization: string;
	layoutType: 'modal' | 'link' | 'bottom' | 'right';
	department: string;
	smallMeta: boolean;
	visible: boolean;
	processes: Process[];
	departmentTree: Department[];
	selectedWorkList: string[];
}>();
const emit = defineEmits<{
	(e: 'update:levelValue', levelValue: string): void;
	(e: 'update:layoutType', layoutType: string): void;
	(e: 'update:department', department: string): void;
	(e: 'update:visible', value: boolean): void;
	(e: 'toggleGroupsState', state: boolean, level?:number): void;
}>();
const detailType = computed({
	get: () => props.layoutType,
	set: (layoutType:string) => emit('update:layoutType', layoutType),
});
const department = computed({
	get: () => props.department,
	set: (department:string) => emit('update:department', department || ''),
});
const organization = computed({
	get: () => props.organization,
	set: ()=>{},
});
const visible = computed({
	get: () => props.visible,
	set: (value:boolean) => emit('update:visible', value),
});
const cascaderProps = {
	label: 'department_name',
	value: 'name',
	children: 'children',
	emitPath: false,
	checkStrictly: true,
};

// 获取流程分级的value
function getData(levelValue:string) {
	emit('update:levelValue', levelValue);
}
// 展开层级
function isExpand(value:boolean) {
	emit('toggleGroupsState', value);
}


watch(()=>props.organization, async ()=>{
	await frappe.db.get_value(
		'Tianjy Organization',
		{name: props.organization}, ['name', 'label'],
		(res: Organization) => {
			organizationList.value = res;
		},
	);
}, {immediate: true});
const flexDirection = computed(() => (props.smallMeta ? 'column' : 'row'));
onMounted(async ()=>{
	permission.value = await hasPermission(props.organization);
});
</script>

<style lang="less" scoped>
  .toolbar {
    display: flex;
    flex-direction: v-bind(flexDirection);
	justify-content: space-between;
	flex-wrap: wrap;

	.tool_left {
		display: flex;
		flex-direction: v-bind(flexDirection);
		flex-wrap: wrap;

		.organizational_unit {
			white-space: nowrap;
			margin-right: 10px;
			margin-bottom: 8px;

		}
		.dw_department {
			white-space: nowrap;
			margin-right: 10px;
			margin-bottom: 8px;

		}
	}

	.tool_right {
		display: flex;
		flex-direction: v-bind(flexDirection);
		justify-content: flex-end;
		flex-wrap: wrap;
		.tool_dropdown_menu{
			margin-right: 20px;
			margin-bottom: 8px;
		}
	}
}
</style>
<style>
.dw_department_cascader .el-cascader-panel .el-radio{
	margin-bottom: 0;
}
.dw_department_cascader .el-radio__original{
	position: absolute!important;
}
</style>
