<template>
	<div v-loading="loading">
		<Page>
			<template #title>
				<h3 class="title">
					{{ type === 'project' ? tt('Project') : tt('Company')
					}}{{ tt('Monthly Assessment List') }}
				</h3>
			</template>
			<template #tools>
				<Tools
					:type="type"
					:departments="departments"
					:lockStatus="lockStatus"
					v-model:departmentName="departmentName"
					v-model:month="month"
					@changeLock="getLockStatus"
					@create="create"
					:creatable="creatable"
					:exportable="exportable"
					:lockable="lockable"
					:unlockable="unlockable"
				></Tools>
			</template>
			<Table
				:permission="assessPermission"
				:assessList="assessList"
				:type="type"
				:month="month"
				:department="departmentName"
				:lockStatus="lockStatus"
				@updateLevel="updateLevel"
			></Table>
		</Page>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted, onUnmounted, toRef } from 'vue';

import * as Permission from '../Permission';
import Page from '../../../../../../../guigu_pm/guigu_pm/public/js/components/page/index.vue';

import Tools from './components/Tools.vue';
import Table from './components/Table.vue';
import type { Assess, Company, Level, Project } from './type';
interface Props {
	type: 'project' | 'subCompany';
}
const tt = __;
const props = defineProps<Props>();
const departmentName = ref<string>('');
const month = ref<string>(moment().startOf('month').format('YYYY-MM-DD'));
const loading = ref<boolean>(false);
const assessList = ref<Assess[]>([]);
const departments = ref<{ name: string; label: string }[]>([]);
const lockStatus = ref<boolean>(false);

const assessPermission = Permission.use(() => {
	const department = props.type === 'project' ? 'Project' : 'Company';
	return `Guigu Hr ${department} Month Assess`;
});
const workPermission = Permission.use(() => {
	const department = props.type === 'project' ? 'Project' : 'Sub Company';
	return `Guigu ${department} Work`;
});
const lockPermission = Permission.use(() => {
	const department = props.type === 'project' ? 'Project' : 'Company';
	return `Guigu Hr ${department} Work Assessment Lock`;
});
const creatable = computed(() => Boolean(workPermission.value?.creatable));
const exportable = computed(() => Boolean(assessPermission.value?.exportable));
const lockable = computed(() => Boolean(lockPermission.value?.creatable));
const unlockable = computed(() => Boolean(lockPermission.value?.deletable));

watch(
	() => window.location.search,
	() => {
		const params = new URLSearchParams(window.location.search);
		if (params.get('month')) {
			month.value = params.get('month') || '';
		}
		if (params.get('department')) {
			departmentName.value = params.get('department') || '';
		}
	},
	{ immediate: true },
);

function create() {
	if (props.type === 'project') {
		frappe.new_doc('Guigu Project Work', { project: departmentName.value });
	} else {
		frappe.new_doc('Guigu Sub Company Work', {
			company: departmentName.value,
		});
	}

	// frappe.new_doc(doctype, options);
}

watch(
	() => props.type,
	() => {
		getSelectOptions();
	},
	{ immediate: true },
);
async function getSelectOptions() {
	const isProject = props.type === 'project';
	const url = isProject ? 'Project' : 'Company';
	const fields = isProject
		? ['name', 'project_abbr_name']
		: ['name', 'company_name'];
	const filters = isProject ? { status: 'Open' } : {};
	const res: (Project | Company)[] = await frappe.db.get_list(url, {
		fields: fields,
		filters: filters,
		limit: 0,
	});
	departments.value = res.map(item => {
		const label = isProject
			? (item as Project).project_abbr_name
			: (item as Company).company_name;
		return {
			name: item.name,
			label,
		};
	});
	if (!departmentName.value) {
		departmentName.value = res[0]?.name || '';
	}
}

watch(
	[departmentName, month],
	async () => {
		const queryObj = new URLSearchParams({
			department: departmentName.value,
			month: month.value,
		});
		history.replaceState(null, '', `?${queryObj.toString()}`);
		getAssessList();
		getLockStatus();
	},
	{ immediate: true },
);
async function getLockStatus() {
	if (!month.value || !props.type || !departmentName.value) {
		return;
	}
	const res = await frappe.call<{ message: boolean }>({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_project_assessment_list.guigu_hr_project_assessment_list.get_lock_status',
		args: {
			month: month.value,
			type: props.type,
			department: departmentName.value,
		},
	});
	lockStatus.value = Boolean(res?.message);
}
async function getAssessList() {
	if (!month.value || !props.type || !departmentName.value) {
		return;
	}
	loading.value = true;
	const res = await frappe.call<{ message: Assess[] }>({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_project_assessment_list.guigu_hr_project_assessment_list.get_assessments',
		args: {
			month: month.value,
			type: props.type,
			department: departmentName.value,
		},
	});
	loading.value = false;
	assessList.value =
		res?.message.sort((pre, next) =>
			pre.full_name > next.full_name ? 1 : -1,
		) || [];
}

async function updateLevel(data: Assess, level: Level) {
	await frappe.call<{ message: Assess[] }>({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_project_assessment_list.guigu_hr_project_assessment_list.update_level',
		args: {
			month: month.value,
			type: props.type,
			department: departmentName.value,
			user: data.name,
			level: level,
			hr_assess_name: data.hr_assess_doc?.name,
		},
	});
	getAssessList();
}
const popstateListener = function (event: any) {
	if (
		window.location.pathname.indexOf('guigu_hr_project_assessment_list') >
		-1
	) {
		getAssessList();
	}
	if (
		window.location.pathname.indexOf(
			'guigu_hr_sub_company_assessment_list',
		) > -1
	) {
		getAssessList();
	}
};
onMounted(() => {
	window.addEventListener('popstate', popstateListener);
});

onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});
</script>

<style lang="less" scoped>
.title {
	margin-bottom: 0;
}
</style>
