/* eslint-disable eqeqeq */
import {
	CellClassParams,
	ColDef,
	ColGroupDef,
	EditableCallbackParams,
	ICellEditorParams,
	ICellRendererParams,
	ValueGetterParams,
} from 'ag-grid-community';

import FloatComponent from '../../../../AgGrid/components/Float.vue';
import FloatEditor from '../../../../AgGrid/components/FloatEditor.vue';
import Action from '../../../../AgGrid/components/Action.vue';

import SelectProgress from './SelectProgress.vue';
import { editCellStyle } from './utils';

export function getActualColumns(
	deleteRecord: any,
	editRecord:(data:any)=>void,
	enableAudit: boolean,
	isSmallScreen:boolean,
): (ColDef | ColGroupDef)[] {
	const auditColumn = enableAudit
		? [
			{
				headerName: __("Today's review volume"),
				field: 'actual_quantity',
				width: 110,
				minWidth: 110,
				filter: false,
				enableRowGroup: false,
				resizable:true,
				editable: (e:EditableCallbackParams)=>Boolean(e.data.type),
				cellRenderer: FloatComponent,
				cellEditor: FloatEditor,
				cellStyle: (params: CellClassParams) => Boolean(params.data.type)?editCellStyle:undefined,
				cellRendererParams: (params: ICellRendererParams) => {
					const { data } = params;
					return {
						value:
                data.actual_quantity == 0
                	? null
                	: data.actual_quantity,
					};
				},
			},
		]
		: [];

	return [
		{
			headerName: __('Construction content'),
			field: 'structure_ancestry_label',
			width: 200,
			minWidth: 100,
			enableRowGroup: false,
			editable: (e:EditableCallbackParams)=>!Boolean(e.data.type),
			filter: false,
			resizable:true,
			cellDataType:false,
			cellEditor: SelectProgress,
			cellStyle: (params: CellClassParams) => params.data.type?undefined:editCellStyle,
			pinned: isSmallScreen?null:'left',
			valueFormatter:params=>{
				if (typeof params.data.structure_ancestry_label==='object'){
					return params.data.structure_ancestry_label.label;
				}
				return params.data.structure_ancestry_label;
			},
		},
		{
			headerName: __("Unit"),
			field: 'unit',
			width: 80,
			minWidth: 80,
			enableRowGroup: false,
			editable: false,
			filter: false,
			resizable:true,
			// pinned: isSmallScreen?null:'left',
		},
		{
			headerName: __('Monthly planned quantity'),
			field: 'month_plan_quantity',
			width: 110,
			minWidth: 110,
			enableRowGroup: false,
			editable: false,
			cellRenderer: FloatComponent,
			filter: false,
			resizable:true,
			cellRendererParams: (params: ICellRendererParams) => {
				const { data } = params;
				return {
					value:
              data?.month_plan_quantity == 0
              	? null
              	: data?.month_plan_quantity,
				};
			},
		},
		{
			headerName: __('Weekly planned quantity'),
			field: 'week_plan_quantity',
			width: 110,
			minWidth: 110,
			enableRowGroup: false,
			editable: false,
			cellRenderer: FloatComponent,
			filter: false,
			resizable:true,
			cellRendererParams: (params: ICellRendererParams) => {
				const { data } = params;
				return {
					value:
              data.week_plan_quantity == 0
              	? null
              	: data.week_plan_quantity,
				};
			},
		},
		{
			headerName: __('Accumulated completion quantity'),
			field: 'complete_quantity',
			width: 130,
			minWidth: 130,
			filter: false,
			enableRowGroup: false,
			editable: false,
			resizable:true,
			cellRenderer: FloatComponent,
			cellRendererParams: (params: ICellRendererParams) => {
				const { data } = params;
				return {
					value: data.complete_quantity == 0 ? null : data.complete_quantity,
				};
			},
		},
		{
			headerName: __('Today planned quantity'),
			field: 'today_plan_quantity',
			width: 120,
			minWidth: 120,
			filter: false,
			enableRowGroup: false,
			editable: false,
			resizable:true,
			cellRenderer: FloatComponent,
			cellRendererParams: (params: ICellRendererParams) => {
				const { data } = params;
				return {
					value:
              data.today_plan_quantity == 0
              	? null
              	: data.today_plan_quantity,
				};
			},
		},
		{
			headerName: __('Today actual quantity'),
			field: enableAudit
				? 'report_quantity'
				: 'actual_quantity',
			width: 120,
			minWidth: 120,
			filter: false,
			enableRowGroup: false,
			editable: (e:EditableCallbackParams)=>Boolean(e.data.type),
			resizable:true,
			cellRenderer: FloatComponent,
			cellEditor: FloatEditor,
			cellStyle: (params: CellClassParams) => Boolean(params.data.type)?editCellStyle:undefined,
			cellRendererParams: (params: ICellRendererParams) => {
				const { data, value } = params;
				return {
					value: value == 0 ? null : value,
				};
			},
		},
		...auditColumn,
		{
			headerName: __('Tomorrow Plan Quantity'),
			field: 'tomorrow_plan_quantity',
			width: 120,
			minWidth: 120,
			filter: false,
			enableRowGroup: false,
			editable: (e:EditableCallbackParams)=>Boolean(e.data.type),
			resizable:true,
			cellRenderer: FloatComponent,
			cellEditor: FloatEditor,
			cellStyle: (params: any) => Boolean(params.data.type)?editCellStyle:undefined,
			cellRendererParams: (params: ICellRendererParams) => {
				const { data } = params;
				return {
					value:
              data?.tomorrow_plan_quantity == 0
              	? null
              	: data?.tomorrow_plan_quantity,
				};
			},
		},
		{
			headerName: __('Remark'),
			field: 'remark',
			flex: 2,
			minWidth: 300,
			filter: false,
			enableRowGroup: false,
			editable: (e:EditableCallbackParams)=>Boolean(e.data.type),
			resizable:true,
			cellStyle: (params: CellClassParams) => Boolean(params.data.type)?editCellStyle:undefined,
		},
		{
			headerName: __('Actions'),
			field: 'action',
			width: 70,
			pinned: 'right',
			enableRowGroup: false,
			filter: false,
			sortable: false,
			editable: false,
			menuTabs: [],
			resizable:true,
			cellRenderer: Action,
			cellRendererParams: (params: ICellEditorParams) => {
				const {data}=params;
				if (data.type){
					return {
						onRemove:deleteRecord,
						onEdit:editRecord,
					};
				}
				return {
					onRemove:deleteRecord,
				};
			},
		},
	];
}
