<template>
	<div class="subjectContent">
		<span class="editBtn" :onclick="click"> {{ props.params.value }}</span>
	</div>
</template>
<script lang="ts" setup>
import { defineProps } from 'vue';

const props = defineProps<{ params: { value: any, onClick: any, data: any, emit: any, layout: string; }; }>();

async function click() {
	if (props.params.layout === 'link') {
		frappe.set_route('Form', 'Guigu Project Work', props.params.data.work_name);
	} else {
		const recordValue = await frappe.db.get_doc(
			'Guigu Project Work',
			props.params.data.work_name,
			{},
		);
		props.params.emit(recordValue);
	}
}
</script>

<style scoped lang="less">
.subjectContent {
	display: flex;
	overflow: hidden;
	color: #1890ff;
	cursor: pointer;

	.label {
		flex: 1;
		overflow: hidden;
		white-space: nowrap;
		height: auto;
	}

}

.editBtn:hover {
	text-decoration: underline
}
</style>
