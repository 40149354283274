import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_WorkspaceWidget = _resolveComponent("WorkspaceWidget")

  return (_openBlock(), _createBlock(_component_WorkspaceWidget, null, {
    default: _withCtx(() => [
      _createElementVNode("div", { innerHTML: $props.value }, null, 8 /* PROPS */, _hoisted_1)
    ]),
    _: 1 /* STABLE */
  }))
}