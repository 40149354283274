<script lang="ts" setup>
import {ref, computed, watch, markRaw} from 'vue';

import {Key, Source} from './nyloongTable/core';
import Gantt, {Dot, Line, LineMeta} from './nyloongTable/gantt';
import {useGroup, Header, Body, useColumns, type VueColumn} from './nyloongTable/vue';
import useUnit from './useUnit';

const props = defineProps<{
	columns: VueColumn[];
	dateFields: Record<string, string>;
	endDateFields: Record<string, string>;
	dots?: Dot[];
	lines?: Line[];
	rows?: any[];
	parentKey?: Key<string | number>;
	tooltip?(v: any, dates: ([Date, Date | null, (LineMeta | undefined)?] | null)[]): string;


	expanded?: (string | number)[];
	checked?: (string | number)[];

	startFixed?: number;


	rowHeight?: number;


	view?: GlobalView.View;
}>();

const emit = defineEmits<{
	(event: 'update:checked', list: (string | number)[]): void;
	(event: 'update:expanded', list: readonly (number | string)[]): void;
	(event: 'setDay', p: [today: number, dayWidth: number, days: number]): void;
}>();

const {currentUnit} = useUnit(computed(() => props.view?.name || ''));


const checkedIds = computed<any[]>({
	get: () => props.checked || [],
	set: v => {
		emit('update:checked', v);
	},
});
const expandedIds = computed<any[]>({
	get: () => props.expanded || [],
	set: v => {
		emit('update:expanded', v);
	},
});
const selectedId = ref<any>();

const a1 = ref<HTMLElement>();
const a2 = ref<HTMLElement>();
const beginInfo = ref<[today: number, dayWidth: number]>([0, 0]);
watch([a2, beginInfo], async ([el, [today, dayWidth]]) => {
	if (!el) {
		return;
	}
	await new Promise(r => requestAnimationFrame(r));
	const {clientWidth} = el;
	const left = today * dayWidth - clientWidth / 4;
	el.scrollLeft = left;
	console.log(today, dayWidth, clientWidth, left, el.scrollLeft);
});
async function setDay(today: number, dayWidth: number) {
	beginInfo.value = [today, dayWidth];
}
const startFixed = computed(() => props.startFixed ?? 1);
const gantt = computed<VueColumn[]>(() => {
	const unit = currentUnit.value;
	if (!unit) {
		return [];
	}
	const {width, headers, bg, showDate} = unit;
	return [{
		extensions: [Gantt({
			dateFields: props.dateFields,
			endDateFields: props.endDateFields,
			summarize: () => true,
			dayWidth: width,
			headers,
			bg,
			showDate,
			tooltip: props.tooltip,
			dots: props.dots || [],
			lines: props.lines || [],
			onChange: setDay,
		})],
	}];
});
const table = markRaw(new Source({
	idKey: 'name',
	parentKey: props.parentKey,
}));
const source = ref(table);
watch(() => props.rows, rows => {
	table.setValue(rows || []);
}, {immediate: true});
watch(selectedId, v => {
	table.selectedId = v;
}, {immediate: true});
watch(expandedIds, expanded => {
	table.expanded = expanded || [];
}, {immediate: true});
watch(checkedIds, checked => {
	table.checked = checked || [];
}, {immediate: true});
// watch(() => props.parentKey, parentKey => {
// 	table.parentKey = parentKey;
// }, { immediate: true });
table.listen('checkedChange', v => {
	checkedIds.value = v;
});
table.listen('selectedChange', v => {
	selectedId.value = v;
});
table.listen('collapseChange', v => {
	expandedIds.value = v;
});
const [columns, CellRenderer] = useColumns(computed(() => props.columns));
const [ganttColumns, GanttCellRenderer] = useColumns(computed(() => gantt.value));
const group = useGroup(source, columns, startFixed);
const ganttGroup = useGroup(source, ganttColumns);


function scroll1() {
	const h1 = a1.value;
	const h2 = a2.value;
	if (!h1 || !h2) {
		return;
	}
	h2.scrollTop = h1.scrollTop;
}

function scroll2() {
	const h1 = a1.value;
	const h2 = a2.value;
	if (!h1 || !h2) {
		return;
	}
	h1.scrollTop = h2.scrollTop;
}
const inlineSize = ref('50%');
let sizeChanged = false;
watch(columns, columns => {
	if (sizeChanged) {
		return;
	}
	const width = columns.map(v => v.width || 200).reduce((a, b) => a + b, 40);
	inlineSize.value = `min(50%, ${width}px)`;
}, {immediate: true});


let state: any;
let captured = false;
function begin(e: PointerEvent) {
	if (captured || !e.isPrimary) {
		return;
	}
	const el = e.currentTarget as HTMLElement;
	el.setPointerCapture(e.pointerId);
	captured = true;
	const style = getComputedStyle(el);
	const rtl = style.direction.toLowerCase() === 'rtl';
	switch (style.writingMode.toLowerCase()) {
		default:
		case 'horizontal-tb':
			state = rtl ? el.clientWidth - e.offsetX : e.offsetX; break;
		case 'vertical-lr': case 'vertical-rl': case 'sideways-rl':
			state = rtl ? el.clientHeight - e.offsetY : e.offsetY; break;
		case 'sideways-lr':
			state = rtl ? e.offsetY : el.clientHeight - e.offsetY; break;
	}
}
function move(e: PointerEvent) {
	const el = e.currentTarget as HTMLElement;
	if (!el.hasPointerCapture(e.pointerId)) {
		return;
	}
	const root = el.parentElement;
	if (!root) {
		return;
	}
	const rect = root.getBoundingClientRect();
	const style = getComputedStyle(el);
	const rtl = style.direction.toLowerCase() === 'rtl';
	let offset = 0;
	switch (style.writingMode.toLowerCase()) {
		default:
		case 'horizontal-tb':
			if (rtl) {
				offset = rect.x + rect.width - (e.pageX + state);
			} else {
				offset = (e.pageX - state) - rect.x;
			}
			break;

		case 'vertical-lr': case 'vertical-rl': case 'sideways-rl': case 'sideways-lr':
			if (rtl) {
				offset = rect.y + rect.height - (e.pageY + state);
			} else {
				offset = (e.pageY - state) - rect.height;
			}
			break;
	}
	inlineSize.value = `${offset}px`;
	sizeChanged = true;
}
function end(e: PointerEvent) {
	const {pointerId} = e;
	try {
		const el = e.currentTarget as Element;
		el.releasePointerCapture(pointerId);
	} catch (e) {
		console.error(e);
	}
	captured = false;
}
</script>

<template>
	<div class="root">
		<div ref="a1" @scroll="scroll1" class="main"
			:style="{ inlineSize }">
			<Header :group="group" class="header" />

			<Body :group="group" :rowHeight="rowHeight" />
		</div>
		<div @pointerdown="begin" @pointermove="move" @pointercancel="end"
			@pointerup="end" class="resizer" v-if="currentUnit" />
		<div ref="a2" @scroll="scroll2" v-if="currentUnit" class="gantt">
			<Header :group="ganttGroup" class="header" />

			<Body :group="ganttGroup" :rowHeight="rowHeight" />
		</div>
	</div>
	<CellRenderer />
	<GanttCellRenderer />
</template>
<style scoped lang="less">
.header {
	top: 0;
	bottom: 0;
	z-index: 3;
	position: sticky !important;
}

.main {
	overflow: scroll;
}

.resizer {
	position: relative;
	pointer-events: all;
	inset-block: 0;
	inline-size: 1px;
	margin-inline: -3px;
	border: solid transparent;
	border-block-width: 0px;
	border-inline-start-width: 3px;
	border-inline-end-width: 5px;
	border-radius: 4px;
	transition: border-color 0.3s;
	box-sizing: content-box;
	background-color: #d7d7d7;
	background-clip: padding-box;
	cursor: col-resize;

	&:hover {
		border-color: #e7e7e7;
	}
}

.gantt {
	overflow: scroll;
	flex: 1;
}

.root {
	display: flex;
	flex-direction: row;
	width: 100%;

	> :last-child {
		flex: 1;
	}

	--nyloong-table-header-row-height: 32px;
	--nyloong-table-header-background-color: #f5f7f7;
	--nyloong-table-row-border-color: rgba(189, 195, 199, 0.58);
	font-size: 12px;


		:global(.nyloong-table-header:not(.nyloong-table-gantt-header, .nyloong-table-checkable)) {
			padding-inline: 12px;
		}

		:global(.nyloong-table-cell:not(.nyloong-table-gantt-lines, .nyloong-table-checkable)) {
			padding-inline: 12px;
		}
}
</style>
<style lang="less">
@import './nyloongTable/core/style.less';
@import './nyloongTable/gantt/style.less';
</style>
