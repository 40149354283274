import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b2008985"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "member" }
const _hoisted_2 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["ElTable"], {
      "cell-class-name": "member-cell",
      data: $setup.memberList,
      border: "",
      style: {"width":"100%"},
      height: "100%",
      "empty-text": $setup.tt('No Data')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElTableColumn"], {
          fixed: "",
          prop: "user_doc.full_name",
          label: $setup.tt('User'),
          width: "180"
        }, null, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          prop: "role",
          label: $setup.tt('Role')
        }, {
          default: _withCtx((scope) => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.roles, (roleDoc) => {
              return (_openBlock(), _createElementBlock("span", {
                class: "role",
                onClick: $event => ($setup.showPermissions(roleDoc.role))
              }, _toDisplayString($setup.tt(roleDoc.role)), 9 /* TEXT, PROPS */, _hoisted_2))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          prop: "visible",
          label: $setup.tt('Seen'),
          width: "60"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.visible ? $setup.tt('Yes') : $setup.tt('No')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          prop: "viewable",
          label: $setup.tt('Viewable'),
          width: "60"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.viewable ? $setup.tt('Yes') : $setup.tt('No')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          prop: "addible",
          label: $setup.tt('Addible'),
          width: "60"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.addible ? $setup.tt('Yes') : $setup.tt('No')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          prop: "editable",
          label: $setup.tt('Editable'),
          width: "60"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.editable ? $setup.tt('Yes') : $setup.tt('No')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          prop: "deletable",
          label: $setup.tt('Deletable'),
          width: "60"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.deletable ? $setup.tt('Yes') : $setup.tt('No')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          prop: "manageable",
          label: $setup.tt('Manageable'),
          width: "60"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.manageable ? $setup.tt('Yes') : $setup.tt('No')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        (
				$props.permissions.writePermission || $props.permissions.deletePermission
			)
          ? (_openBlock(), _createBlock($setup["ElTableColumn"], {
              key: 0,
              prop: "address",
              label: $setup.tt('Action'),
              width: "60"
            }, {
              default: _withCtx((scope) => [
                ($props.permissions.writePermission)
                  ? (_openBlock(), _createBlock($setup["ElButton"], {
                      key: 0,
                      type: "primary",
                      onClick: $event => ($setup.editMember(scope.row))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.tt('Detail')), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["data", "empty-text"])
  ])), [
    [$setup["vLoading"], $setup.loading]
  ])
}