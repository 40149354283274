import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Page"], null, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.tt('Clause Interpretation Library Page')), 1 /* TEXT */)
    ]),
    sider: _withCtx(() => [
      _createVNode($setup["IndustryTree"], {
        onDataChange: $setup.treeClick,
        currentNode: $setup.currentNode
      }, null, 8 /* PROPS */, ["currentNode"])
    ]),
    default: _withCtx(() => [
      _createVNode($setup["Main"], { value: $setup.mainValue }, null, 8 /* PROPS */, ["value"])
    ]),
    _: 1 /* STABLE */
  }))
}