const noValues = new Set(['', 'no', 'false', '0', '否', '假']);
export default function isIncluding(value: any, def: string = '') {
	const list = def.split('\n').filter((Boolean)).map(v => v.toLowerCase());
	if (typeof value === 'string') {
		return list.includes(value.toLowerCase());
	}
	if (typeof value === 'boolean') {
		if (value) { return list.findIndex(v => !noValues.has(v)) >= 0; }
		return list.findIndex(v => noValues.has(v)) >= 0;
	}
	if (typeof value === 'number') {
		return list.findIndex(v => value === parseFloat(v)) >= 0;
	}
	if (typeof value === 'bigint') {
		return list.findIndex(v => {
			try {
				return value === BigInt(v);
			} catch {
				return false;
			}
		}) >= 0;
	}
	return false;
}
