<template>
	<AgGridVue
		v-loading="loading"
		:key="key"
		class="ag-theme-guigu report_table"
		:columnDefs="columnDefs"
		:rowData="reportResults"
		:defaultColDef="defaultColDef"
		@grid-ready="onGridReady"
		:suppressDragLeaveHidesColumns="smallMeta"
		:getRowId="getRowId"
		:getRowStyle="getRowStyle"
		:localeText="zhCN"
		:suppressRowTransform="true">
	</AgGridVue>
</template>
<script lang="ts" setup>
import {ref, computed, defineComponent, PropType, getCurrentInstance, watch} from 'vue';
import {AgGridVue} from 'ag-grid-vue3';
import {CellEditingStoppedEvent, FirstDataRenderedEvent, GridApi, RowClassParams, RowDataUpdatedEvent} from 'ag-grid-community';

import {Period, ReportResult} from '../type';
import {useMetaQuery} from '../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';
import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';

import {getReportColumns} from './tableColumns';
import {onRowDataUpdatedEvent} from './helper';
interface Props{
	'reportResults': ReportResult[],
	mainType:'Week'|'Month',
	subType:'Week'|'Month',
}
const props = defineProps<Props>();

const smallMeta = useMetaQuery();
const loading = ref<boolean>(false);
const gridApi = ref<GridApi>();
const key = ref<string>('');
const onGridReady = (params: any) => {
	gridApi.value = params.api;
};
watch(()=>[...props.reportResults], ()=>{
	// if (!gridApi.value){ return; }
	// gridApi.value.setRowData(props.reportResults);
	key.value = Math.random().toString();
}, {deep: true});

const columnDefs = computed(() => getReportColumns(props.reportResults));
const defaultColDef = {
	sortable: false,
	filter: true,
	resizable: true,
};

function getRowId(params: any) {
	return params.data.id;
}
function rowDataUpdated(event: RowDataUpdatedEvent) {
	onRowDataUpdatedEvent(event, props.reportResults);
}
function getRowStyle(params:RowClassParams) {
	if (params.data.id.startsWith('temp_plan_')) {
		return {color: '#d36e13'};
	}
}
</script>
<style lang="less" scoped>
.report_table {
	width: 100%;
	height: 100%;
}

.report_table {
	:deep(.rowSpanCell) {
		display: flex;
		align-items: center;
		background: #fff;
		border-bottom: 1px solid #d9dddf;
	}

	:deep(.ag-header-group-cell-label .ag-header-group-text) {
		width: 100%;
		text-align: center;
	}
}
</style>
