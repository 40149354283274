frappe.ui.form.Sidebar.prototype.reload_docinfo = function (callback) {
	frappe.call({
		method: 'frappe.desk.form.load.get_docinfo',
		args: {
			doctype: this.frm.doctype,
			name: this.frm.docname,
		},
		callback: r => {
			// docinfo will be synced
			if (callback) { callback(r.docinfo); }
			this.frm.timeline && this.frm.timeline.refresh();
			this.frm.assign_to.refresh();
			this.frm.attachments.refresh();
			this.frm.comment_box.refresh_attachments(r.docinfo.attachments);
		},
	});
};
