
<template>
	<div class="toolbar">
		<ElSelect class="dataSelect company-select"
			:modelValue="subCompany"
			:size="size"
			@change="subCompanyChange"
			filterable>
			<ElOption
				v-for="item in subCompanies"
				:key="item.name"
				:label="item.company_name"
				:value="item.name" />
		</ElSelect>
		<ElSelect class="dataSelectMultiple"
			:modelValue="designations"
			:size="size"
			multiple
			@change="designationChange"
			collapseTags
			:collapseTagsTooltip="size==='small'"
			filterable>
			<ElOption
				v-for="item in designationList"
				:key="item.name"
				:label="item.job_title"
				:value="item.name" />
		</ElSelect>

		<ElDatePicker
			class="dataSelect"
			v-model="startDatePicker"
			@change="startDateChange"
			:clearable="false"
			type="date"
			:size="size"
			:placeholder="tt('Start Date')"
			:disabledDate="disabledDateStart"
			valueFormat="YYYY-MM-DD" />
		<span class="dataSelectStr">{{ tt('to') }}</span>
		<ElDatePicker
			class="dataSelect"
			v-model="endDatePicker"
			@change="endDateChange"
			clearable
			type="date"
			:size="size"
			:placeholder="tt('End Date')"
			:disabledDate="disabledDate"
			valueFormat="YYYY-MM-DD" />
		<ElTooltip :content="tt('Log Details')" class="calendar">
			<ElButton class="calendarButton"
				:icon="Calendar"
				size="small"
				circle
				@click="navigateToRecordStatistics"></ElButton>
		</ElTooltip>
		<div class="refresh">
			<ElTooltip :content="tt('Log Details')" class="calendarPhone">
				<ElButton class="calendarButtonPhone"
					:icon="Calendar"
					size="default"
					circle
					@click="navigateToRecordStatistics"></ElButton>
			</ElTooltip>
			<ElButtonGroup :size="size" class="ml-4">
				<ElButton :loading="loading" @click="exportTable">{{ tt('Export') }}</ElButton>
				<ElButton :loading="loading" @click="refresh"
					:icon="RefreshRight">
					{{ tt('Refresh') }}
				</ElButton>
			</ElButtonGroup>

		</div>
	</div>
</template>

<script lang="ts" setup>
import {ref, defineProps, defineEmits, onMounted} from 'vue';

import {GridApi} from 'ag-grid-community';
import moment from 'moment';

import {RefreshRight, Calendar} from '@element-plus/icons-vue';
import {ElButtonGroup, ElButton, ElTooltip, ElDatePicker, ElSelect, ElOption} from 'element-plus';

import type {SubCompany, Designation} from './type';
import {routeInit, setRoute} from './helper';
const tt = __;
interface Props {
	subCompany: string
	designations: string[]
	startDate: string
	endDate: string
	refreshValue?: number
	detailGridApi?: GridApi
	loading: boolean
	size: 'small'|'default'
}
const props = defineProps<Props>();
interface Emit {
	(e: 'update:subCompany', subCompany: string): void
	(e: 'update:designations', designations: string[]): void
	(e: 'update:startDate', startDate: string): void
	(e: 'update:endDate', endDate: string): void
	(e: 'update:loading', loading: boolean): void
	(e: 'update:refreshValue', refreshValue: number): void
}
const emit = defineEmits<Emit>();

const subCompanies = ref<SubCompany[]>([]);
const designationList = ref<Designation[]>([]);
const startDatePicker = ref<string>(props.startDate);
const endDatePicker = ref<string>(props.endDate);
const sessionDefaultSubCompany = ref<string>((frappe.defaults.get_user_defaults('company') || [])[0]);
const paramsFilter = ref<Partial<{
	subCompany: string;
	startDate: string;
	endDate: string;
	designations: string[];
}>>({});

// 设置结束时间在开始时间之后
const disabledDate = time => {
	if (startDatePicker.value) {
		return time < moment(startDatePicker.value);
	}
};
// 设置开始时间在结束时间之前
const disabledDateStart = time => {
	//注意这要加一个判断不然没选结束时间的时候开始时间也全部不能选择
	if (endDatePicker.value) {
		return time > moment(endDatePicker.value);
	}
};

function navigateToRecordStatistics() {
	const queryObj = new URLSearchParams({
		date: paramsFilter.value?.endDate || paramsFilter.value?.startDate || '',
	});
	const {subCompany} = props;
	if (subCompany) {
		queryObj.append('subCompany', subCompany);
	}
	for (const designations of props.designations) {
		queryObj.append('designations', designations);
	}
	const url = `?${queryObj.toString()}`;
	frappe.router.push_state(`/app/project_leader_daily_record${url}`);
}

async function initPage() {
	routeInit(paramsFilter);
	const designationRes: Designation[] = await frappe.db.get_list(
		'Guigu Project Designation',
		{
			fields: ['name', 'job_title'],
			limit: 0,
			order_by: 'position asc',
		},
	);
	designationList.value = designationRes;
	const subCompaniesRes: SubCompany[] = await frappe.db.get_list(
		'Company',
		{
			fields: ['name', 'company_name'],
			limit: 0,
		},
	);
	subCompanies.value = subCompaniesRes;
	const subCompanyNames = subCompaniesRes.map(item => item.name);
	let defaultSubCompany: string = '';
	if (subCompanyNames.includes(paramsFilter.value?.subCompany)) {
		defaultSubCompany = paramsFilter.value?.subCompany;
	} else {
		defaultSubCompany = sessionDefaultSubCompany.value || subCompaniesRes[0]?.name || '';
	}
	const designation: string[] = paramsFilter.value?.designations;
	const startDate:string = paramsFilter.value?.startDate || props.startDate || moment().format('YYYY-MM-DD');
	let endDate:string = paramsFilter.value?.endDate || props.endDate || '';
	if (endDate < startDate) {
		endDate = '';
	}
	setRoute({
		subCompany: defaultSubCompany,
		startDate: startDate,
		endDate: endDate,
		designations: paramsFilter.value?.designations || [],
	}, paramsFilter);
	startDatePicker.value = startDate;
	endDatePicker.value = endDate;
	emit('update:subCompany', defaultSubCompany);
	emit('update:startDate', startDate);
	emit('update:endDate', endDate);
	emit('update:designations', designation || []);
}

onMounted(() => {
	initPage();
});

function exportTable() {
	props.detailGridApi?.exportDataAsExcel();
}

function subCompanyChange(value: string) {
	setRoute({subCompany: value}, paramsFilter);
	emit('update:subCompany', value);
}

function designationChange(value: string[]) {
	setRoute({designations: value}, paramsFilter);
	emit('update:designations', value);
}

function startDateChange(value: string) {
	if (!value || value === 'null') {
		startDatePicker.value = paramsFilter?.value?.startDate;
		return;
	}
	startDatePicker.value = value || moment().format('YYYY-MM-DD');
	setRoute({startDate: value || moment().format('YYYY-MM-DD')}, paramsFilter);
	emit('update:startDate', value || moment().format('YYYY-MM-DD'));
}
function endDateChange(value: string) {
	endDatePicker.value = value || '';
	setRoute({endDate: value || ''}, paramsFilter);
	emit('update:endDate', value || moment().format('YYYY-MM-DD'));
}

function refresh() {
	const newValue: number = (props.refreshValue || 0) + 1;
	emit('update:loading', true);
	emit('update:refreshValue', newValue);
}
</script>

<style scoped lang="less">

.toolbar {
	padding: 8px 0;
	.calendar{
		margin-left: 10px;
	}
	:deep(.el-select-tags-wrapper) {
		display: flex !important;
	}
}


@media (max-width: 640px) {
	.toolbar{
		display: flex;
    	flex-direction: column;
	}
	.calendarButton{
		display: none !important;
	}
	.calendarButtonPhone{
		margin-right: 8px !important;
	}
	.calendarButton{
		display: block;
		border-radius: unset !important;
		margin-bottom: 8px !important;
	}
	:deep(.el-date-editor.el-input) {
			width: 100% !important;
			margin-bottom: 8px !important;
	};
	:deep(.ml-4) {
		margin-left: 0px !important;
	};
	.dataSelect,.dataSelectMultiple,.dataSelectStr{
		margin-bottom: 8px !important;
	}
}
@media (min-width: 640px) {
	.refresh {
		float: right;
		margin-top: 8px !important;
	}
	.dataSelect{
		:deep(.el-input__inner) {
			height:22px !important;
		}
	}
	.company-select {
		width: 300px
	}
	.calendarButtonPhone{
		display: none !important;
	}
	.dataSelectStr{
		margin-right: 8px;
    	margin-top: 8px;
		display: inline-block;
    	vertical-align: middle;
	}

	:deep(.el-date-editor.el-input) {
		margin-right: 8px !important;
		margin-top: 8px !important;
	}

	.dataSelect,.dataSelectMultiple{
		margin-right: 8px !important;
		margin-top: 8px !important;
	}
	.calendarButton{
		margin-top: 8px !important;
	};
}

</style>
