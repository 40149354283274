<template>
	<ElFormItem>
		<ElTable :data="fields" style="width: 100%">
			<ElTableColumn type="index" :index="1" />
			<ElTableColumn :label="tt('Field')" #="{ row, $index }" width="120px">
				<ElSelect :modelValue="row.field" filterable defaultFirstOption
					@update:modelValue="set($index, 'field', $event)">
					<ElOption v-for="field in fieldsSelectable" :key="field.value"
						:value="field.value" :label="field.label" />
				</ElSelect>
			</ElTableColumn>
			<ElTableColumn :label="tt('Label')"
				#="{ row, $index }">
				<ElInput :modelValue="row.label"
					@update:modelValue="set($index, 'label', $event)" />
			</ElTableColumn>
			<ElTableColumn v-if="showWidth" :label="tt('Width')"
				#="{ row, $index }">
				<ElInput type="number" :modelValue="row.width"
					@update:modelValue="setWidth($index, 'width', $event || 0)" />
			</ElTableColumn>
			<ElTableColumn v-if="showWidth" :label="tt('Min Width')"
				#="{ row, $index }">
				<ElInput type="number" :modelValue="row.min_width"
					@update:modelValue="setWidth($index, 'min_width', $event || 0)" />
			</ElTableColumn>
			<ElTableColumn v-if="showWidth" :label="tt('Max Width')"
				#="{ row, $index }">
				<ElInput type="number" :modelValue="row.max_width"
					@update:modelValue="setWidth($index, 'max_width', $event || 0)" />
			</ElTableColumn>
			<ElTableColumn :label="tt('Alignment Method')" width="90px"
				#="{ row, $index }">
				<ElSelect :modelValue="row.alignment" filterable defaultFirstOption
					@update:modelValue="set($index, 'alignment', $event)">
					<ElOption v-for="field in alignOption" :key="field.value"
						:value="field.value" :label="field.label" />
				</ElSelect>
			</ElTableColumn>
			<ElTableColumn :label="tt('Show Tag')" width="90px"
				#="{ row, $index }">
				<ElCheckbox v-if="canShowTag(row.field)" :modelValue="Boolean(row.show_tag)"
					@update:modelValue="set($index, 'show_tag', $event ? 1 : 0)" />
			</ElTableColumn>
			<ElTableColumn v-if="group" :label="tt('Grouped')">
				<template #header>
					{{ tt('Grouped') }}
					<ElIcon v-if="groupTip" :title="groupTip"><InfoFilled /></ElIcon>
				</template>
				<template #default="{ row, $index }">
					<ElCheckbox :modelValue="Boolean(row.group)" v-if="groupable(row.field)"
						@update:modelValue="set($index, 'group', $event ? 1 : 0)" />
				</template>
			</ElTableColumn>
			<ElTableColumn v-if="enableLink" :label="tt('Enable Link')"
				#="{ row, $index }">
				<ElCheckbox :modelValue="Boolean(row.enable_link)" v-if="isLink(row.field)"
					@update:modelValue="set($index, 'enable_link', $event ? 1 : 0)" />
			</ElTableColumn>
			<ElTableColumn v-if="showLabel" :label="tt('Show Label')"
				#="{ row, $index }">
				<ElCheckbox :modelValue="Boolean(row.show_label)"
					@update:modelValue="set($index, 'show_label', $event ? 1 : 0)" />
			</ElTableColumn>
			<ElTableColumn :label="tt('Format')" #="{ row, $index }">
				<ElInput :modelValue="row.format" v-if="hasFormat(row.field)"
					@update:modelValue="set($index, 'format', $event || '')" />
			</ElTableColumn>

			<ElTableColumn :label="tt('Operation')" #="{ $index }">
				<ElButtonGroup>
					<ElButton @click="moveTo($index)" text :icon="Sort"
						:title="tt('Move')" />
					<ElButton type="danger" @click="remove($index)" text
						:icon="Delete" :title="tt('Delete')" />
				</ElButtonGroup>
			</ElTableColumn>
		</ElTable>
		<ElButton @click="add">{{ tt('Add') }}</ElButton>

	</ElFormItem>
</template>
<script setup lang="ts">
import {computed, shallowRef, h} from 'vue';
import {
	ElTable, ElTableColumn, ElIcon,
	ElSelect, ElCheckbox, ElInput,
	ElFormItem, ElButtonGroup, ElButton, ElOption, ElMessageBox,
} from 'element-plus';
import {Delete, Sort, InfoFilled} from '@element-plus/icons-vue';

import {viewSettings} from '../../extends/init/defineMainView';
import {getFieldOptions} from '../../utils/FieldDisplayed';
import {parseDisplayField} from '../utils';


const props = defineProps<{
	meta: locals.DocType;
	modelValue: any;
	define?: GlobalView;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: GlobalView.View): void;
}>();
const view = computed({
	get: () => props.modelValue,
	set: v => emit('update:modelValue', v),
});
const setting = computed(() => viewSettings.get(view.value.type));
const cfg = computed(() => setting.value?.configuration?.displayField || {});
const showWidth = computed(() => Boolean(cfg.value.width));
const showLabel = computed(() => Boolean(cfg.value.showLabel));
const enableLink = computed(() => Boolean(cfg.value.enableLink));
const group = computed(() => Boolean(cfg.value.group));
const groupTip = computed(() => {
	const s = cfg.value.group;
	if (typeof s === 'string') {
		return s;
	}
	return null;
});
const fieldOptions = [showWidth, showLabel, enableLink];
const hasFieldOption = computed(() => fieldOptions.some(v => v.value));
const tt = __;
const alignOption = [
	{
		label: tt('Left'),
		value: 'start',
	},
	{
		label: tt('Middle'),
		value: 'center',
	},
	{
		label: tt('Right'),
		value: 'end',
	},
];

const fields = computed({
	get: () => {
		const {fields_displayed} = view.value;
		if (fields_displayed?.length) {
			return fields_displayed;
		}
		return parseDisplayField(view.value.fields);
	},
	set: v => {
		view.value = {
			...view.value,
			fields_displayed: v.map(v => ({...v, idx: undefined})),
			fields: '',
		};
	},
});
function set(index: number, key: keyof GlobalView.Field, value: any) {
	const list = [...fields.value];
	const item: any = list[index];
	if (!item) {
		return;
	}
	item[key] = value;
	fields.value = list;
}
function setWidth(index: number, key: keyof GlobalView.Field, value: any) {
	const list = [...fields.value];
	const item: any = list[index];
	if (!item) {
		return;
	}
	item[key] = Math.max(0, parseInt(value) || 0);
	fields.value = list;
}
function add() {
	fields.value = [...fields.value, {field: ''}];
}
function remove(index: number) {
	const list = [...fields.value];
	if (!list.splice(index, 1).length) {
		return;
	}
	fields.value = list;
}
function move(from: number, to: number) {
	if (from === to) {
		return;
	}
	const list = [...fields.value];
	const item: any = list[from];
	if (!item) {
		return;
	}
	list.splice(from, 1);
	list.splice(to, 0, item);
	fields.value = list;
}
const fieldsSelectable = computed(() => {
	const setting = cfg.value;
	return getFieldOptions(props.meta, {
		...setting,
		titleField: setting.titleField && hasFieldOption.value,
	});
});
function isLink(field?: string) {
	return fieldsSelectable.value.find(v => v.value === field)?.isLink || false;
}
function hasFormat(field?: string) {
	return fieldsSelectable.value.find(v => v.value === field)?.format || false;
}
function groupable(field?: string) {
	return fieldsSelectable.value.find(v => v.value === field)?.groupable || false;
}
function canShowTag(field?: string) {
	const fieldOptions = props.meta.fields.find(item=>item.fieldname === field);
	if (!fieldOptions) {
		return false;
	}
	const showTagTypes = ['Link', 'Date', 'DateTime', 'Select', 'Tree Select', 'Data', 'Float', 'Int', 'Check', 'Guigu Date', 'Tianjy Related Link'];
	return showTagTypes.includes(fieldOptions.fieldtype);
}
function moveTo(index: number) {
	const target = shallowRef<number>(index);
	const field = fields.value[index]?.field;
	const label = fieldsSelectable.value.find(f => f.value === field)?.label || field || '';
	ElMessageBox({
		title: `将 ${label} #${index + 1} 移动到...`,
		message: (): any => {
			const labels = new Map(fieldsSelectable.value.map(v => [v.value, v.label]));
			return h(ElSelect, {
				filterable: true, defaultFirstOption: true,
				modelValue: target.value,
				'onUpdate:modelValue': (val: number) => {
					target.value = val;
				},
			}, fields.value.map(({field}, i) => h(ElOption, {
				value: i,
				label: i === index
					? '<<<位置不变>>>'
					: `${labels.get(field) || field} #${i + 1} ${i < index ? '之前' : '之后'}`,
			})));
		},
	}).then(() => {
		move(index, target.value);
	});
}
</script>
