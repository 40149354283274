import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29b2ed11"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "tree-label" }
const _hoisted_4 = { class: "tree-children" }
const _hoisted_5 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TreeNode = _resolveComponent("TreeNode", true)

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tree-node", { opened: $props.node.open }])
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["tree-link", { active: $props.node.value === $props.selected_node.value }]),
      onClick: _cache[0] || (_cache[0] = $event => ($setup.emit('node-click', $props.node))),
      disabled: $props.node.fetching
    }, [
      _createElementVNode("div", { innerHTML: $setup.icon }, null, 8 /* PROPS */, _hoisted_2),
      _createElementVNode("a", _hoisted_3, _toDisplayString($props.node.label), 1 /* TEXT */)
    ], 10 /* CLASS, PROPS */, _hoisted_1),
    _withDirectives(_createElementVNode("ul", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.node.children, (n) => {
        return (_openBlock(), _createBlock(_component_TreeNode, {
          key: n.value,
          node: n,
          selected_node: $props.selected_node,
          onNodeClick: n => $setup.emit('node-click', n),
          onLoadMore: n => $setup.emit('load-more', n)
        }, null, 8 /* PROPS */, ["node", "selected_node", "onNodeClick", "onLoadMore"]))
      }), 128 /* KEYED_FRAGMENT */)),
      ($props.node.has_more_children)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn btn-xs btn-load-more",
            onClick: _cache[1] || (_cache[1] = $event => ($setup.emit('load-more', $props.node))),
            disabled: $props.node.children_loading
          }, _toDisplayString($props.node.children_loading ? $setup.tt("Loading...") : $setup.tt("Load more")), 9 /* TEXT, PROPS */, _hoisted_5))
        : _createCommentVNode("v-if", true)
    ], 512 /* NEED_PATCH */), [
      [_vShow, $props.node.open]
    ])
  ], 2 /* CLASS */))
}