frappe.ui.form.Form.prototype.set_link_title = function(fieldname, opt1) {
	if (this.fields_dict[fieldname]) {
		this.fields_dict[fieldname].get_link_title = opt1;
	}
};

frappe.ui.form.ControlLink.prototype.set_link_title = async function(value) {
	const doctype = this.get_options();

	if (this.get_link_title && typeof this.get_link_title === 'function') {
		const link_title = await this.get_link_title(value);
		this.translate_and_set_input_value(link_title, value);
		return;
	}
	if (!doctype || !this.is_title_link()) {
		this.translate_and_set_input_value(value, value);
		return;
	}

	const link_title
		= frappe.utils.get_link_title(doctype, value)
		|| (await frappe.utils.fetch_link_title(doctype, value));

	this.translate_and_set_input_value(link_title, value);
};
