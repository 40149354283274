<template>
	<ElFormItem>
		<div class="filterContainer">
			<div class="at">当</div>
			<Filters :doctype="doctype" v-model="filtersDefault" />
		</div>
		<OrFilters :doctype="doctype" v-model="orFiltersDefault" />
	</ElFormItem>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import {ElFormItem} from 'element-plus';

import Filters from './Filters.vue';
import OrFilters from './OrFilters.vue';

const props = defineProps<{
	meta: locals.DocType;
	modelValue: any;
	define?: GlobalView;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: GlobalView.View): void;
}>();
const view = computed({
	get: () => props.modelValue,
	set: v => emit('update:modelValue', v),
});


const filtersDefault = computed({
	get: () => view.value.filters_default,
	set: v => {
		view.value = {...view.value, filters_default: v || null};
	},
});
const orFiltersDefault = computed({
	get: () => view.value.or_filters_default,
	set: v => {
		view.value = {...view.value, or_filters_default: v || null};
	},
});

const doctype = computed(() => props.meta.name);

</script>
<style scoped lang="less">
.filterContainer{
	display: flex;
	align-items: center;
	width: 100%;
}
.at{
	padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-inline-end: 8px;
}
</style>
