import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01d354dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-tree-node-label" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElTree"], {
    class: "organization-tree",
    data: $setup.OrganizationTree,
    props: $setup.defaultProps,
    defaultExpandAll: "",
    highlightCurrent: "",
    expandOnClickNode: false,
    currentNodeKey: $setup.selectedKey,
    onNodeClick: $setup.handleNodeClick,
    emptyText: $setup.tt('No Data'),
    nodeKey: "name"
  }, {
    default: _withCtx(({ node, data }) => [
      _createVNode($setup["ElTooltip"], {
        content: node.label,
        placement: "top-start"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(node.label), 1 /* TEXT */)
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["content"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["data", "currentNodeKey", "emptyText"]))
}