<template>
	<ElTree
		class="organization-tree"
		:data="OrganizationTree"
		:props="defaultProps"
		defaultExpandAll
		highlightCurrent
		:expandOnClickNode="false"
		:currentNodeKey="selectedKey"
		@node-click="handleNodeClick"
		:emptyText="tt('No Data')"
		nodeKey="name"
	>
		<template #default="{ node, data }">
			<ElTooltip
				:content="node.label"
				placement="top-start"
			>
				<span class="custom-tree-node-label">
					{{ node.label }}
				</span>
			</ElTooltip>
		</template>
	</ElTree>
</template>

<script setup lang='ts'>
import {defineProps, computed, ref} from 'vue';
import {ElTree, ElTooltip} from 'element-plus';

import {list2Tree} from '../../../utils';

import type {Organization} from './type';
const tt = __;
interface Props {
	organizationList:Organization[]
	organization:string
}
const props = defineProps<Props>();
const emit = defineEmits<{
	(e: 'update:organization', data:string): void;
}>();
const defaultProps = {
	children: 'children',
	label: 'label',
};
const selectedKey = computed(()=>props.organization);
const OrganizationTree = computed(()=>list2Tree(props.organizationList, 'parent_organization', 'name'));
function handleNodeClick(data:Organization) {
	emit('update:organization', data.name);
}

</script>

<style lang='less' scoped>
.organization-tree{
	flex:1;
	width:100%;
	min-width: 1px;
}
.custom-tree-node-label{
	overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
