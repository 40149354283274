<script lang="ts" setup>
import {computed, ref, watch, toRaw} from 'vue';
import {
	ElInput, ElCheckbox,
	ElForm, ElFormItem,
	ElSelect, ElOption,
} from 'element-plus';

import loadLinkDocTypes from '../../utils/loadLinkDocTypes';

import DoctypeSelect from './DoctypeSelect.vue';
import Filters from './Filters.vue';
import OrFilters from './OrFilters.vue';
import toConfig from './toConfig';


const props = defineProps<{
	value?: any;
}>();
const emit = defineEmits<{
	(event: 'update', data: any, submittable?: boolean): void;
	(event: 'cancel' | 'submit'): void;
}>();

const config = toConfig(props.value);

const doctype = computed({
	get: () => config.value.doctype,
	set: v => {
		config.value.doctype = v;
	},
});

const hasFilters = computed(() => Boolean(config.value.filters.length || config.value.orFilters.length));
watch(hasFilters, hasFilters => {
	if (!hasFilters) {
		return;
	}
	config.value.countWithViewDefault = false;
});
const doctypeLabel = ref('');
watch(doctypeLabel, (v, o) => {
	if (!v) {
		return;
	}
	const l = config.value.label;
	if (!l || l === o) {
		config.value.label = v;
	}
});

watch(doctype, () => {
	config.value.filters = []; config.value.orFilters = [];
});
watch(config, config => {
	emit('update', toRaw(config), Boolean(config.doctype));
}, {immediate: true, deep: true});

function cancel() {
	emit('cancel');
}
function submit() {
	emit('submit');
}
const meta = ref<locals.DocType>();

watch(doctype, async doctype => {
	meta.value = undefined;
	if (!doctype) {
		return;
	}
	let m = frappe.get_meta(doctype);
	if (!m) {
		await new Promise<void>(resolve => {
			frappe.model.with_doctype(doctype, () => {
				resolve();
			});
		});
		m = frappe.get_meta(doctype);
		if (!m) {
			return;
		}
	}
	await loadLinkDocTypes(m);
	meta.value = m;
}, {immediate: true});


const views = ref<{ name: string, label: string }[]>([]);
watch(doctype, async dt => {
	views.value = [];
	const data: {
		type: string;
		name: string;
		label?: string;
		position?: number;
		organizations?: string;
	}[] = await frappe
		.call('guigu.view.get_views', {doctype: dt})
		.then((v: any) => v.message) || [];
	if (dt !== doctype.value) {
		return;
	}
	views.value = data.sort(({position: a}, {position: b}) => (a || 0) - (b || 0))
		.map((({name, label}) => ({name, label: label || name})));
}, {immediate: true});
const tt = __;
</script>

<template>
	<ElForm labelPosition="top" @submit.prevent>
		<ElFormItem :label="tt('DocType')">
			<DoctypeSelect v-model="doctype" @label="doctypeLabel = $event" />
		</ElFormItem>

		<ElFormItem :label="tt('Label')">
			<ElInput v-model="config.label" />
		</ElFormItem>
		<ElFormItem :label="tt('Select View')">
			<ElSelect v-model="config.view" filterable clearable>
				<ElOption v-for="v in views" :key="v.name"
					:value="v.name" :label="v.label" />
			</ElSelect>
		</ElFormItem>
		<ElFormItem v-if="meta">
			<ElCheckbox v-model="config.showCount">{{ tt('显示数量') }}</ElCheckbox>
			<ElCheckbox v-if="config.showCount && config.view"
				v-model="config.countWithViewMust">
				{{ tt('应用视图必要条件') }}
			</ElCheckbox>
			<ElCheckbox v-if="config.showCount && !hasFilters"
				v-model="config.countWithViewDefault">
				{{ tt('应用视图默认条件') }}
			</ElCheckbox>
		</ElFormItem>

		<ElFormItem v-if="meta" :label="tt('Filter')" :key="doctype">
			<div class="filterContainer">
				<div class="at">当</div>
				<Filters :doctype="doctype" v-model="config.filters" />
			</div>
			<OrFilters :doctype="doctype" v-model="config.orFilters" />
		</ElFormItem>
	</ElForm>
</template>
<style scoped lang="less">
.filterContainer{
	display: flex;
	align-items: center;
	width: 100%;
}
.at{
	padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-inline-end: 8px;
}
</style>
