import type { Ref } from 'vue';
import { shallowRef, computed } from 'vue';

import type { View } from '../types';

import type { ViewCon } from './createItem';
import createItem from './createItem';
import getRouteOptions from './getRouteOptions';

async function setDefault(name: string) {
	if (!name) { return; }
	await frappe.call({ method: 'guigu.view.set_default', args: { name } });
	// TODO: 显示提示
}


const viewSort = ({ position: a }: View, { position: b }: View) => a - b;
export default function (docMeta: Ref<locals.DocType>) {
	const defOrganization = shallowRef<string | undefined>(
		frappe.defaults.get_default('Tianjy Organization'),
	);
	const views = shallowRef<View[]>([]);

	function create(v: ViewCon): View | void {
		return createItem(v, docMeta, defOrganization);
	}

	const currentViewId = shallowRef('');
	const current = computed(() => {
		const name = currentViewId.value;
		return views.value.find(v => v.name === name);
	});
	function getDefaultViewName() {
		const def = views.value.find(v => !v.hidden && v.default);
		if (def) { return def.name; }
		return views.value.find(v => !v.hidden)?.name || '';
	}
	function setViews(list: ViewCon[]) {
		const all = [];
		for (const v of list) {
			const item = create(v);
			if (item) { all.push(item); }
		}
		all.sort(viewSort);
		if (!all.length) {
			const item = create({
				name: '',
				label: __('Undefined View'),
				type: 'Guigu List View Configuration',
			});
			if (item) { all.push(item); }
		}
		views.value = all;

	}

	async function save(doctype: string, view: any, configuration: any) {
		const { name, type } = view;
		if (!type && !name) { return; }
		if (name) {
			// 保存
			const { label, group } = await frappe.call('guigu.view.update', {
				name, view, configuration,
			}).then((v: any) => v.message || {});
			const list = [...views.value];
			const item = list.find(v => v.name === name);
			if (!item) { return; }
			item.label = label || name;
			item.group = group || '';
			item.reload();
		} else {
			// 新建
			const v = await frappe.call('guigu.view.create', {
				doctype, type, view, configuration,
			}).then((v: any) => v.message || {});
			const item = create(v);
			if (!item) { return; }
			const list = views.value.filter(v => v.name);
			const { position = 0 } = v;
			for (const l of list) {
				if (l.position >= position) {
					l.position++;
				}
			}
			views.value = [...list, item].sort(viewSort);
			if (!list.length) {
				currentViewId.value = item.name;
			}
		}
	}
	async function move(name: string, to: number) {
		await frappe.call('guigu.view.move', { name, to });
		// 调整顺序
		const list = [...views.value];
		const index = list.findIndex(v => v.name === name);
		if (index < 0) { return; }
		const view = list[index];
		if (!view) { return; }
		let toIndex = list.findIndex(v => v.position >= to);
		if (toIndex < 0) { toIndex = list.length; }
		if (toIndex === index) { return; }

		const minIndex = Math.min(index, toIndex);
		const maxIndex = Math.max(index, toIndex);
		const v = index < toIndex ? -1 : 1;
		for (let i = minIndex; i <= maxIndex; i++) {
			list[i].position += v;
		}
		list.splice(toIndex, 0, ...list.splice(index, 1));
		view.position = to;
		views.value = list;
	}
	async function del(name: string) {
		if (!name) { return; }
		await frappe.call({
			method: 'guigu.view.delete',
			args: { name },
			freeze: true,
			freeze_message: __( 'Deleting'),
		});
		const list = [...views.value];
		const index = list.findIndex(v => v.name === name);
		if (index < 0) { return; }
		list.splice(index, 1);
		if (!list.length) {
			const item = create({
				name: '',
				label: __('Undefined View'),
				type: 'Guigu List View Configuration',
			});
			if (item) { list.push(item); }
		}
		views.value = list;
		if (name === currentViewId.value) {
			currentViewId.value = getDefaultViewName();
		}
	}

	function loadRouteOptions(viewId: string) {
		if (!views.value.length) { return; }
		const data = getRouteOptions(docMeta.value.name);
		if (!data) { return false; }
		const { name } = data;
		const view = views.value.find(v => v.name === name)
			|| views.value.find(v => v.name === viewId);
		if (!view) { return false; }

		view.setFilterFieldValue(data.filterField);
		view.setFilters(data.filters);
		view.setOrFilters(data.orFilters);
		currentViewId.value = view.name;

		return true;
	}

	async function load() {
		const doctype = docMeta.value.name;
		const views: ViewCon[] = await frappe
			.call('guigu.view.get_views', { doctype })
			.then((v: any) => v.message) || [];
		setViews(views);
		const viewId = getDefaultViewName();
		if (loadRouteOptions(viewId)) { return; }
		currentViewId.value = viewId;
	}
	return {
		views: computed(() => views.value), currentViewId, current,
		setViews,
		del,
		delete: del,
		save, move,
		load,
		loadRouteOptions() { loadRouteOptions(currentViewId.value); },
		setDefault,
	};
}
