import { View, widgets } from '../GraphView';
import defineMainView from '../extends/init/defineMainView';
import { Config } from '../GraphView/type/index';

const notValueField = [
	'Image',
	'Attach',
	'Attach Image',
	'Barcode',
	'Button',
	'HTML Editor',
	'Text Editor',
	'Code',
	'Fold',
	'Geolocation',
	'Markdown Editor',
	'HTML',
	'Column Break',
	'Heading',
	'Section Break',
	'Table MultiSelect',
	'Tab Break',
	'Connection Table',
	'Table',
];

defineMainView<Config>({
	type: 'Graph View Configuration',
	// label: 'Graph',

	sider: ['filters', 'filterList'],
	toolbar: ['filterLine'],
	detail: true,
	view: View,
	maxLimit: 0,
	defaultLimit: 0,
	infiniteScroll: true,
	configuration: {
		widgets,
		displayField: {
			useDefault: false,

			fieldFilter(f) {
				return !notValueField.includes(f.fieldtype);
			},
		},
	},
	async getConfigurations(meta, { ...configuration }: any, view) {
		if (configuration.graph_detail) {
			configuration.graph_detail = JSON.parse(configuration.graph_detail);
		}
		if (configuration.filter) { configuration.filter = JSON.parse(configuration.filter); }
		return configuration;
	},
	fields({ name: doctype, fields }, cfg) {
		if (!cfg.fields) {
			return [];
		}
		const filterFields = cfg.fields?.filter(item => {
			const field = fields.find(each => each.fieldname === item);
			if (!field) { return false; }
			const notRequestFieldType = [
				'Button',
				'Heading',
				'HTML',
				'Image',
				'Table',
				'Table MultiSelect',
			];
			if (notRequestFieldType.includes(field?.fieldtype)) {
				return false;
			}
			return true;
		});
		const projectFields = fields.filter(item => item.options === 'Project')
			.map(item => item.fieldname);
		filterFields.push(cfg.connection_field, ...projectFields);
		return filterFields;
	},
});
