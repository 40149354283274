import {createApp} from 'vue';

import App from './index.vue';

function renderDwWorkListTaskView (wrapper:HTMLElement) {
	const app = createApp(App, {});
	app.mount(wrapper);
}

frappe.provide('frappe.dw_task_management');
frappe.dw_task_management.pages = frappe.dw_task_management.pages || {};
frappe.dw_task_management.pages.renderDwWorkListTaskView = renderDwWorkListTaskView;
