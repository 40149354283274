import { StructureItemFormat } from '../../type/diary';
import { Photo, PhotoFormat } from '../../type/image';

export function getPhotoFormat(
	photos: Photo[],
	structures: StructureItemFormat[],
  ):PhotoFormat[] {
	return photos.map(item => {
	  const structure = structures.find(each => {
		if (
		  item.guigu_construction_structure &&
		  each.id === `structure_${item.guigu_construction_structure}`
		) {
		  return true;
		}
		if (
		  item.guigu_construction_structure_progress &&
		  each.id === `structure_progress_${item.guigu_construction_structure_progress}`
		) {
		  return true;
		}
	  });
	  const partString =
		structure?.ancestry
		  ?.map(each => {
			const parentNode = structures.find(every => {
			  const id = every?.ancestry?.[every.ancestry.length - 1] as string;
			  return every.id === each;
			});
			return parentNode?.name;
		  })
		  .join('/') || '';
	  return {
		id: item.name,
		url: item.image,
		description: item.description,
		fileName: item.image_name,
		target: structure?.ancestry?.[structure?.ancestry.length - 1],
		partString,
		diary_id: item.parent,
		date: item.date,
		photographer: item.photographer,
	  };
	});
  }

  export const toFormData = (
	obj: object,
	form?: any,
	namespace?: string,
  ): FormData => {
	const fd = form || new FormData();
	let formKey: string;

	for (const property in obj) {
	  if (obj.hasOwnProperty(property)) {
		if (namespace) {
		  if (obj instanceof Array) {
			formKey = `${namespace}[]`;
		  } else {
			formKey = `${namespace}[${property}]`;
		  }
		} else {
		  formKey = property;
		}

		if (
		  typeof obj[property] === 'object' &&
		  !(obj[property] instanceof File)
		) {
		  toFormData(obj[property], fd, formKey);
		} else {
		  fd.append(formKey, obj[property]);
		}
	  }
	}

	return fd;
  };


export function getStructureName(structures: StructureItemFormat[], id: string) {
	const structure = structures.find(item => item.id === id);
	const progressStructureNodes =
	  structure?.ancestry?.filter(item => {
		const parentNode = structures.find(each => each.id === item);
		return parentNode?.structureProgressId;
	  }) || [];
	const destructureStructureNodes =
	  structure?.ancestry?.filter(item => {
		const parentNode = structures.find(each => each.id === item);
		return parentNode?.structureId;
	  }) || [];

	const progressNodes =
	  structure?.ancestry?.filter(item => item.startsWith('structure_progress')) || [];
	const ancestry = [
	  progressStructureNodes[progressStructureNodes?.length - 1],
	  ...destructureStructureNodes,
	  ...progressNodes,
	].filter(Boolean);
	const newLabel = ancestry
	  .map(item => structures.find(each => each.id === item)?.name)
	  .join('/');
	return newLabel;
  }
