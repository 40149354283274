<script setup lang="ts">
import {computed, shallowRef, watch} from 'vue';
import {ElButton, ElDialog} from 'element-plus';

const tt = __;
const props = defineProps<{
	meta: locals.DocType;
	modelValue?: Record<string, any>;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value?: any): void;
	(event: 'delete', doc: any, mode?: 'root' | 'parent' | 'all', parent?: string): void;
}>();

const visible = computed({
	get: () => Boolean(props.modelValue),
	set: v => {
		if (v) {
			return;
		}
		emit('update:modelValue', undefined);
	},
});
const mode = shallowRef<'' | 'root' | 'parent' | 'all'>('');
const parent = shallowRef<string>();


const title = computed(() => {
	const {meta, modelValue} = props;
	if (!modelValue) {
		return '删除';
	}
	const fieldName = meta.title_field;
	const field = fieldName && meta.fields.find(v => v.fieldname === fieldName)?.fieldname;
	return `删除${field && modelValue[field] || modelValue.name || ''}`;
});
function confirm() {
	visible.value = false;
	const data = props.modelValue;
	if (!data) {
		return;
	}
	const m = mode.value;
	if (!m) {
		return emit('delete', data);
	}
	if (m === 'all') {
		return emit('delete', data, 'all');
	}
	if (m === 'root') {
		return emit('delete', data, 'parent');
	}
	return emit('delete', data, 'parent', parent.value);
}

const tree = shallowRef<any[]>([]);
watch([() => props.meta, () => props.modelValue?.name], async ([meta, name]) => {
	if (!name) {
		return;
	}
	if (!meta) {
		return;
	}
	const titleField = meta.title_field;
	const parentField = meta.nsm_parent_field;
	const fields = ['name as value'];
	if (titleField && meta.fields.find(f => f.fieldname === titleField)) {
		fields.push(`\`${titleField}\` as \`label\``);
	}
	if (parentField && meta.fields.find(f => f.fieldname === parentField)) {
		fields.push(`\`${parentField}\` as \`parent\``);
	}
	const data = await frappe.call('frappe.desk.reportview.get', {
		doctype: meta.name,
		fields,
		page_length: 0,
		view: 'List',
		with_comment_count: false,
	}).then((v: any) => v.message || {});
	Object.assign(frappe.boot.user_info, data.user_info);
	const list = Array.isArray(data) ? data : frappe.utils.dict(data.keys, data.values);
	const mapValue = new Map(list.map(v => [v.value, v]));
	const root: any[] = [];
	for (const a of list) {
		const node = a.parent && mapValue.get(a.parent);
		if (!node) {
			root.push(a);
		} else if (node.children) {
			node.children.push(a);
		} else {
			node.children = [a];
		}
	}
	const current = mapValue.get(name);
	const disableList = current ? [current] : [];
	// eslint-disable-next-line no-cond-assign
	for (let node; node = disableList.pop();) {
		if (node.disabled) {
			continue;
		}
		node.disabled = true;
		for (const child of node.children || []) {
			disableList.push(child);
		}
	}
	tree.value = root;
}, {immediate: true});

</script>
<template>
	<ElDialog v-model="visible" :title="title" appendToBody>
		<div>如何处理后代？</div>
		<div class="menu">
			<ElRadioGroup v-model="mode" class="ml-4">
				<ElRadio label="" size="large">不进行任何操作</ElRadio>
				<ElRadio label="all" size="large">连同后代一同删除</ElRadio>
				<ElRadio label="root" size="large">子项目成为根节点</ElRadio>
				<ElRadio label="parent" size="large">将子项的父节点修改为：</ElRadio>
			</ElRadioGroup>
			<ElTreeSelect
				v-if="mode === 'parent'"
				defaultExpandAll
				v-model="parent"
				placeholder="请选择父级"
				checkStrictly
				filterable
				:data="tree">
			</ElTreeSelect>
		</div>
		<template #footer>
			<ElButton @click="visible = false">{{ tt('Cancel') }}</ElButton>
			<ElButton type="primary" @click="confirm">{{ tt('Confirm') }}</ElButton>
		</template>
	</ElDialog>
</template>

<style scoped lang="less">
.menu {
	display: flex;
	flex-direction: column;

	button {
		margin-left: 0 !important;
		width: 100%;
	}
}
</style>
