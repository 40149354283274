
import { computed, ComputedRef, Ref, ref } from 'vue';

import type { Kanban } from '../types';

export default function useKanban(create: () => void) {
	const kanbanList = ref<Kanban[]>([]);

	const kanbanMap = computed(() => new Map(kanbanList.value.map(v => [v.name, v])));
	const __kanbanId = ref<string>('');
	const kanbanId = computed({
		get: () => {
			const id = __kanbanId.value;
			const map = kanbanMap.value;
			if (map.has(id)) { return id; }
			return kanbanList.value[0]?.name || '';
		}, set: v => {
			if (!v) { return create(); }
			__kanbanId.value = v;
		},
	});
	const kanban = computed(() => kanbanMap.value.get(kanbanId.value));
	return { kanbanList, kanbanMap, kanbanId, kanban };
}
