import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Number Of Fields Fixed')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElInputNumber"], {
          modelValue: $setup.fixedFieldColumns,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.fixedFieldColumns) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Line Height')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElInputNumber"], {
          modelValue: $setup.lineHeight,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.lineHeight) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Default Expand Level')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElInputNumber"], {
          modelValue: $setup.expandedDefault,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.expandedDefault) = $event)),
          mix: "0"
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], null, {
      default: _withCtx(() => [
        _createVNode($setup["ElCheckbox"], {
          modelValue: $setup.showSummaryRow,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.showSummaryRow) = $event)),
          label: $setup.tt('Show Summary Rows')
        }, null, 8 /* PROPS */, ["modelValue", "label"])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode($setup["ElFormItem"], null, {
      default: _withCtx(() => [
        _createVNode($setup["ElCheckbox"], {
          modelValue: $setup.tree,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.tree) = $event)),
          label: $setup.tt('Display In A Tree Structure')
        }, null, 8 /* PROPS */, ["modelValue", "label"])
      ]),
      _: 1 /* STABLE */
    }),
    (!$setup.tree)
      ? (_openBlock(), _createBlock($setup["ElFormItem"], { key: 0 }, {
          default: _withCtx(() => [
            _createVNode($setup["ElCheckbox"], {
              modelValue: $setup.showLineNumber,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.showLineNumber) = $event)),
              label: $setup.tt('Display Line Number')
            }, null, 8 /* PROPS */, ["modelValue", "label"])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Text Color')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElColorPicker"], {
          modelValue: $setup.textColor,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($setup.textColor) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Background Color')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElColorPicker"], {
          modelValue: $setup.bgColor,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($setup.bgColor) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Even Line Background Color')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElColorPicker"], {
          modelValue: $setup.oddBgColor,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => (($setup.oddBgColor) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Horizontal Table Line Color')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElColorPicker"], {
          modelValue: $setup.rowLineColor,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => (($setup.rowLineColor) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Vertical Table Line Color')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElColorPicker"], {
          modelValue: $setup.colLineColor,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => (($setup.colLineColor) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"])
  ], 64 /* STABLE_FRAGMENT */))
}