<template>
	<div class="organization-container">
		<el-tree
			v-loading="loading"
			:data="treeData"
			:props="defaultProps"
			:expandOnClickNode="false"
			highlightCurrent
			nodeKey="key"
			ref="treeRef"
			defaultExpandAll
			:currentNodeKey="currentNodeKey"
			@node-click="handleNodeClick">
		</el-tree>
	</div>
</template>

<script setup lang='ts'>
import { ref, onBeforeMount, computed, watch, onMounted } from 'vue';
const props = defineProps<{
	currentNode: string
}>();

const loading = ref<boolean>(false);

interface Emit {
	(e: 'dataChange', data: any): void
}
const emit = defineEmits<Emit>();
const treeRef = ref();
interface IndustryType {
	name: string,
	industry_version: string
}
interface Project {
	name: string,
	project_abbr_name: string
}

interface TreeData {
	label: string
	key: string
	doctype: string
	filter?: Record<string, string>
	children: TreeData[]
}

const defaultProps = {
	children: 'children',
	label: 'label',
};
const tt = __;
function handleNodeClick(e) {
	emit('dataChange', e);
}

let formatData = ref<TreeData[]>([]);
onBeforeMount(async () => {
	loading.value = true;
	const industryRes: IndustryType[] = await frappe.db.get_list(
		'Guigu Contract Interpret Category',
		{
			fields: ['name', 'industry_version'],
			limit:0,
		},
	);
	const projectsRes: Project[] = await frappe.db.get_list('Project',
		{
			fields: ['name', 'project_abbr_name'],
			filters: { status: 'Open' },
			limit: '',
		},
	);
	const industryObj: Record<string, TreeData> = {};
	for (const item of (industryRes || [])) {
		industryObj[item.industry_version] = {
			label: tt(item.industry_version),
			key: `${item.name}`,
			filter: {
				industry_version: item.name,
			},
			doctype: 'Guigu Industry Contract Interpret',
			children: [],
		};
	}
	const projectArr: TreeData[] = [];
	for (const item of (projectsRes || [])) {
		projectArr.push({
			label: item.project_abbr_name,
			key: item.name,
			doctype: 'Guigu Project Contract Interpret',
			filter: { project: item.name },
			children: [],
		});
	}
	const resultIndustry = {
		label: __('Industry'),
		key: 'Guigu Industry Contract Interpret',
		doctype: 'Guigu Industry Contract Interpret',
		root: true,
		children: Object.values(industryObj),
	};
	const resultProject = {
		label: __('Project'),
		key: 'Guigu Project Contract Interpret',
		doctype: 'Guigu Project Contract Interpret',
		root: true,
		children: projectArr,
	};
	formatData.value = [resultIndustry, resultProject];
	loading.value = false;
});
const treeData = computed(() => formatData.value);
const currentNodeKey = computed(() => props.currentNode);

</script>

<style>
.organization-container {
	height: 100%;
	overflow-y: auto;
}
</style>
