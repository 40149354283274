import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "file-browser" }
const _hoisted_2 = { class: "file-browser-list" }
const _hoisted_3 = { class: "file-filter" }
const _hoisted_4 = ["placeholder"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("a", {
        href: "",
        class: "text-muted text-medium",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.$emit('hide')), ["prevent"]))
      }, _toDisplayString($setup.tt("← Back to upload files")), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "search",
          class: "form-control input-xs",
          placeholder: $setup.tt('Search by filename or extension'),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.search_text) = $event)),
          onInput: _cache[2] || (_cache[2] = (...args) => ($setup.search_by_name && $setup.search_by_name(...args)))
        }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_4), [
          [_vModelText, $setup.search_text]
        ])
      ]),
      _createVNode($setup["TreeNode"], {
        class: "tree with-skeleton",
        node: $setup.node,
        selected_node: $setup.selected_node,
        onNodeClick: _cache[3] || (_cache[3] = n => $setup.toggle_node(n)),
        onLoadMore: _cache[4] || (_cache[4] = n => $setup.load_more(n))
      }, null, 8 /* PROPS */, ["node", "selected_node"])
    ])
  ]))
}