import { resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createBlock($setup["AgGridVue"], {
    key: $setup.key,
    class: "ag-theme-guigu report_table",
    columnDefs: $setup.columnDefs,
    rowData: $props.reportResults,
    defaultColDef: $setup.defaultColDef,
    onGridReady: $setup.onGridReady,
    suppressDragLeaveHidesColumns: $setup.smallMeta,
    getRowId: $setup.getRowId,
    getRowStyle: $setup.getRowStyle,
    localeText: $setup.zhCN,
    suppressRowTransform: true
  }, null, 8 /* PROPS */, ["columnDefs", "rowData", "suppressDragLeaveHidesColumns", "localeText"])), [
    [_directive_loading, $setup.loading]
  ])
}