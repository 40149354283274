<!-- 任务模板表格 -->
<template>
	<AgGridVue
		v-if="!loading"
		class="ag-theme-guigu"
		domLayout="autoHeight"
		:rowData="dataList"
		:rowSelection="'multiple'"
		:defaultColDef="defaultColDef"
		@grid-ready="onGridReady"
		:suppressDragLeaveHidesColumns="smallMeta"
		:localeText="zhCN"
		useValueFormatterForExport
		:getRowId="getRowId"
		@row-selected="handleRowSelected"
	>
	</AgGridVue>
</template>

<script setup lang="ts">
import {ref, watch} from 'vue';
import {AgGridVue} from 'ag-grid-vue3';

import zhCN from '../../../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';
import type {TaskTemplate} from '../../../../../../types';

import {getColumns} from './helper';
interface Props{
	tableData:TaskTemplate[]
	loading:boolean
	smallMeta?: boolean;
}
const props = defineProps<Props>();

const emit = defineEmits<{
	(event: 'getSelectRows', selectRows: TaskTemplate[]): void;
}>();

const gridApi = ref<any>(null);
const dataList = ref<TaskTemplate[]>([]);
// const selectedRows = ref<TaskTemplate[]>([]);
watch([()=>props.smallMeta, gridApi], ([newSmallMeta, api])=>{
	api?.setColumnDefs(getColumns(newSmallMeta));
});

watch(() => props.tableData, val => {
	dataList.value = val;
});
const defaultColDef = {
	filter: true,
	resizable: true,
	suppressMovable: true,
	cellDataType: false,
};
const onGridReady = (params: any) => {
	gridApi.value = params.api;
};
function getRowId(params: any) {
	return params.data.name;
}

// 当行被选中或取消选中时触发的方法
function handleRowSelected() {
	const selectedRows = gridApi.value.getSelectedRows();
	// selectedRows.value = selectedRows;
	emit('getSelectRows', selectedRows);
}
</script>

<style lang="less" scoped>
.template-table {
	height: 500px;
	width: 100%;
	position: relative;
}
</style>
