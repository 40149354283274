<template>
	<ElDatePicker type="datetime" v-model="value"
		:placeholder="label && tt(label)" :teleported="false" />
</template>
<script lang="ts" setup>
import { computed, watch } from 'vue';
import { ElDatePicker } from 'element-plus';

import { toDatetime, toDatetimeString } from './date';


const props = defineProps<{
	label?: string;
	name: string;
	required?: boolean;
	filter?: boolean;
	modelValue?: any;
	fitWidth?: boolean;
	popperClass?: string;
	options?: any;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: any): void;
}>();
const tt = __;


watch(() => props.modelValue, value => {
	const date = toDatetimeString(toDatetime(props.modelValue));
	if (value !== date) {
		emit('update:modelValue', date);
	}
}, { immediate: true });

const value = computed({
	get: () => toDatetime(props.modelValue),
	set: v => emit('update:modelValue', toDatetimeString(v)),
});

</script>
