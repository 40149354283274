<template>
	<div class="toolbar">
		<ElDatePicker
			class="input"
			v-model="date"
			type="date"
			size="small"
			valueFormat="YYYY-MM-DD" />
		<ProjectCom
			default
			noLabel
			v-model="selectedProject"
		></ProjectCom>
	</div>
</template>
<script lang="ts" setup>
import {ref, onMounted, defineEmits, watchEffect, computed} from 'vue';
import moment from 'moment';

import {useMetaQuery} from '../../../components/page/useMetaQuery';
import ProjectCom from '../../../../../../../guigu/guigu/public/js/components/PageComponents/Tools/Project.vue';
interface Emit {
	(e: 'filterChange', date: string, project: string): void
}
const emit = defineEmits<Emit>();
const date = ref<string>(moment().format('YYYY-MM-DD'));
const selectedProject = ref<string>();
const smallMeta = useMetaQuery();

watchEffect(() => {
	if (!date.value || !selectedProject.value) {
		return;
	}
	emit('filterChange', date.value, selectedProject.value);
});

const margin = computed(()=>smallMeta.value ? '8px' : '0');
const flexDirection = computed(()=>smallMeta.value ? 'column' : 'row');

</script>
<style lang="less" scoped>
.toolbar {
	display: flex;
	flex-direction: v-bind(flexDirection);
	& :deep(.input){
		margin-right: 8px;
		margin-bottom: v-bind(margin);
	}
}
</style>
