<template>
	<div v-if="data.length">
		<div class="handsontable-container" :ref="set"></div>
	</div>
	<div v-else>
		<ElEmpty :description="tt('Current Project No Material Records')" :imageSize="200" />
	</div>
</template>

<script setup lang='ts'>
import {ref, shallowRef, watch} from 'vue';
import HyperFormula from 'hyperformula';
import Handsontable from 'handsontable';
import numbro from 'numbro';
import {ElEmpty} from 'element-plus';

import type {Column} from './type.ts';
import {getBodyData, getHeader, getMenus, getSummaryRow} from './helper';
import {saveData, timeSliceSave} from './save';
import customStylesRenderer from './customStylesRenderer.js';

const engine = HyperFormula.buildEmpty({
	licenseKey: 'internal-use-in-handsontable',
	localeLang: 'zh-cn',
});
const tt = __;
interface Props{
	data:any[]
	columns:Column[],
	canCreateRow:boolean,
	canRemoveRow:boolean,
	frm:any,
	field:string,
	name:string
	exportTitle?:string
	readOnly:boolean
	fixedColumnsStart: number
	height:number
	onReadyHatable?:any
	mergeCells?: any
	hiddenColumns?: any
}
const props = defineProps<Props>();
interface Emit{
	(e: 'change', data:Record<string, any>): void,
}
const emit = defineEmits<Emit>();
const hatable = shallowRef<Handsontable | null>(null);
const dataSchema = ref<Record<string, null>>({});
const cells = ref<any>();

watch([hatable, ()=>props.canCreateRow, props.canRemoveRow, ()=>props.exportTitle, ()=>props.readOnly], ()=>{
	if (!hatable.value) {
		return;
	}
	const contextMenu = getMenus(hatable.value, props.canCreateRow, props.canRemoveRow, props.readOnly, props.exportTitle);
	hatable.value.updateSettings({contextMenu});
}, {immediate: true});

watch([()=>[...props.columns], hatable, ()=>[...props.data], ()=>props.canCreateRow, props.name, props.readOnly, () => [...props.mergeCells]], (
	v,
	oldv,
)=>{
	if (!hatable.value) {
		return;
	}
	const {
		headerData,
		columns,
		cells: thisCells,
		mergeCells,
		dataSchema: thisDataSchema,
		hasSummaryRow,
		widths,
	} = getHeader(props.columns, props.readOnly, props.fixedColumnsStart);
	dataSchema.value = thisDataSchema;
	cells.value = thisCells;
	const sourceData = hatable.value.getSourceData();
	const data = sourceData.filter(item=>item.__type__ !== 'summary' && item.__type__ !== 'headerData');
	const dataChange = JSON.stringify(oldv![2]) !== JSON.stringify(v[2]);
	const bodyData = getBodyData(
		columns,
		(data.length && dataChange === false) ? data : props.data,
		(headerData || []).length,
		thisDataSchema,
	);
	const newData = [
		...(headerData || []),
		...bodyData,
	];
	const summaryRow = hasSummaryRow && getSummaryRow(columns, newData.length, thisDataSchema);
	if (hasSummaryRow) {
		newData.push(summaryRow);
	}
	hatable.value.updateSettings({
		cells: thisCells,
		columns,
		data: newData,
		dataSchema: thisDataSchema,
		mergeCells: [...(mergeCells || []), ...(props.mergeCells || [])],
		// mergeCells: props.mergeCells,
		colWidths: widths,
		fixedColumnsStart: props.fixedColumnsStart,
		fixedRowsTop: (headerData || []).length,
		fixedRowsBottom: hasSummaryRow ? 1 : 0,
		hiddenColumns: props?.hiddenColumns || [],
	});
}, {immediate: true});

function afterCreateRow(index:number, amount:number, source:string|undefined) {
	if (source === 'ContextMenu.rowBelow') {
		setTimeout(()=>{
			addFormula.call(this, index, amount, source);
		}, 0);
	}
}
function newTextRender (hotInstance, td, row, column, prop, value, cellProperties) {
	let newValue = '';
	// debugger;
	if (!value || value === '0') {
		newValue = '';
	} else if (typeof value === 'number') {
		const number = numbro(value);

		numbro.zeroFormat('N/A');

		newValue = number.format('0.0000');
	} else {
		newValue = value;
	}

	Handsontable.renderers.TextRenderer(hotInstance, td, row, column, prop, newValue, cellProperties);
}

function set(el:Element, obj:any) {
	Handsontable.renderers.registerRenderer('nullStringRender', newTextRender);
	const hot = new Handsontable(el, {
		data: [],
		columns: [],
		rowHeaders: true,
		colHeaders: true,
		height: props.height || 500,
		width: '100%',
		autoWrapRow: true,
		autoWrapCol: true,
		mergeCells: true,
		licenseKey: 'non-commercial-and-evaluation',
		afterChange,
		beforePaste,
		allowInsertRow: false,
		language: frappe.boot.lang === 'zh' ? 'zh-CN' : undefined,
		formulas: {engine},
		afterCreateRow,
		afterRemoveRow,
		renderer: customStylesRenderer,
	});
	hatable.value = hot;
	props?.onReadyHatable?.(hot);
}

function afterRemoveRow(index:number, amount:number) {
	if (!hatable.value) {
		return;
	}
	saveData(hatable.value, props.field, props.frm, data=>emit('change', data));
}
function beforePaste(data:(string|number)[][], coords:{startRow:number, startCol:number, endRow:number, endCol:number}[]) {
	if (props.readOnly || ! hatable.value) {
		return false;
	}
	const instance = hatable.value.getInstance();
	const sourceData = instance.getSourceData();
	const {columns = []} = instance.getSettings();
	if (typeof columns === 'function') {
		return;
	}
	const summaryColumns = columns.filter(item=>item.summary);
	const summaryCol = columns.map((item, index)=>{
		if (item.rowSummary) {
			return {
				rowSummary: item.rowSummary,
				index: index,
			};
		}
	}).filter(Boolean) as {rowSummary:string, index:number}[];
	const starCol = coords[0].startCol;
	const [{startRow}] = coords;
	const atData = sourceData[startRow];

	if (atData.__type__ === 'headerData' || atData.__type__ === 'summary') {
		return false;
	}
	for (const d of data) {
		for (let i = 0; i < d.length; i++) {
			const value = d[i];
			if (!value) {
				continue;
			}
			if (typeof value !== 'string') {
				continue;
			}
			const n = Number(value.replace(/,/g, ''));
			if (Number.isNaN(n)) {
				continue;
			}
			d[i] = n;
		}
	}
	for (const [index, d] of data.entries()) {
		for (const col of summaryCol) {
			const dataIndex = col.index - starCol;
			const rowIndex = startRow + index;
			if (d.length >= dataIndex) {
				const formulaField = col.rowSummary.match(/\{\{(.+?)\}\}/g);
				let {rowSummary} = col;

				if (formulaField) {
					for (const f of formulaField) {
						const formulaCol = columns.find(col=>col.data === f.replaceAll('{{', '').replaceAll('}}', ''));
						const formulaColIndex = columns.findIndex(col=>col.data === formulaCol?.data);
						const fColName = String.fromCharCode(65 + formulaColIndex);
						rowSummary = rowSummary.replace(f, `${fColName}${rowIndex + 1}`);
					}
				}
				d[dataIndex] = rowSummary;
			}
		}
	}
	if (summaryColumns.length > 0 && (startRow + data.length) > sourceData.length) {
		instance.updateSettings({
			data: sourceData.filter(item=>item.__type__ !== 'summary'),
			cells: cells.value,
		});
	}
}

function addFormula(index:number, amount:number, source:string) {
	const instance = hatable.value;
	if (!instance) {
		return;
	}
	const {columns = []} = instance.getSettings();
	const data:any[] = instance.getSourceData();
	if (typeof columns === 'function') {
		return;
	}
	if (source === 'CopyPaste.paste' && data.every(item=>item.__type__ !== 'summary')) {
		const summaryColumns = columns.filter(item=>item.summary);
		if (summaryColumns.length > 0) {
			const summaryRow = getSummaryRow(columns, data.length, dataSchema.value);
			instance.updateSettings({
				data: [...data, summaryRow],
			});
		}
	}
	if (source === 'CopyPaste.paste') {
		return;
	}
	const rowTotalColumns = columns.filter(item=>item.rowSummary);
	for (const item of rowTotalColumns) {
		const formulaField = item.rowSummary.match(/\{\{(.+?)\}\}/g);
		const col = columns.findIndex(col=>col.data === item.data);
		for (const [rowIndex, d] of data.filter(item=>item.__type__ !== 'summary').entries()) {
			if (d.__type__ === 'headerData') {
				continue;
			}
			let {rowSummary} = item;
			for (const f of formulaField) {
				const formulaCol = columns.find(col=>col.data === f.replaceAll('{{', '').replaceAll('}}', ''));
				const formulaColIndex = columns.findIndex(col=>col.data === formulaCol?.data);
				const fColName = String.fromCharCode(65 + formulaColIndex);
				rowSummary = rowSummary.replace(f, `${fColName}${rowIndex + 1}`);
			}
			instance.setDataAtCell(rowIndex, col, rowSummary);
		}
	}
}

function afterChange(change:any[]|null, source:string) {
	if (source === 'loadData') {
		return;
	}
	if (change === null) {
		return;
	}
	if (change.every(ch=>ch[2] === ch[3])) {
		return;
	}
	const instance = hatable.value;
	props?.onReadyHatable?.(instance);
	if (!instance) {
		return;
	}
	if (source === 'CopyPaste.paste') {
		const {columns = []} = instance.getSettings();
		if (typeof columns === 'function') {
			return;
		}
		const summaryColumns = columns.filter(item=>item.summary);
		const data:any[] = instance.getSourceData();
		if (data.every(item=>item.__type__ !== 'summary')
			&& summaryColumns.length > 0) {
			const summaryRow = getSummaryRow(columns, data.length, dataSchema.value);
			instance.updateSettings({
				data: [...data, summaryRow],
			});
		}
	}

	setTimeout(()=>{
		if (!hatable.value) {
			return;
		}
		const save = timeSliceSave();
		save(hatable.value, props.field, props.frm, data=>emit('change', data));
	});
}
</script>

<style lang='less' scoped>

:deep(.ht_clone_left) {
	z-index: 1;
}

:deep(.ht_clone_top) {
	z-index: 1;
}

:deep(.ht_clone_top_inline_start_corner.ht_clone_top_left_corner) {
	z-index: 1;
}
:deep(.ht_master){
	padding-bottom: 15px;
}
:deep(.ht_clone_inline_start.ht_clone_left.handsontable) {
	display: inline;
}

</style>
