<template>
	<div class="tool-container">
		<div class="tool">
			<h5>{{ tt('2、 Completion Status Of Business Indicators') }}</h5>
			<p class="project-info">{{ tt('（1） Output Value') }}</p>
			<p class="project-info">
				{{
					(tt(
							'1. Completion status of output value: The annual planned settlement output value is {} ten thousand yuan, with a cumulative completion of {} ten thousand yuan as of this month, completing {} of the annual plan.'
						),
						[businessIndicatorsOverview?.yearPlanValue, 1, 20])
				}}
			</p>
			<p class="project-info">
				{{
					(tt(
							'According to the annual plan, the expected construction output value is {} ten thousand yuan by {}, but the actual completion is {} ten thousand yuan less than the monthly plan.'
						),
						[selectedDate, 68446.32, 38630.59])
				}}
			</p>
			<p class="project-info">{{ tt('2. The key projects that need to be focused on include:') }}</p>
			<div id="influence-factor" style="width: 600px; height: 400px; display: none"></div>
			<img :src="url" />

			<p class="project-info">{{ tt('（2） Contract Stock') }}</p>
			<p class="project-info">
				{{
					tt('As of now, the contract stock is {} ten thousand yuan. The classification of contract stock categories is as follows:', [
						541878,
					])
				}}
			</p>
			<div id="stock-category" style="width: 600px; height: 400px; display: none"></div>
			<img :src="url2" />
			<p class="project-info">
				{{
					tt(
						'Note: The completion status of operating income, unfinished construction, accounts receivable, and construction profit indicators can be found in the'
					)
				}}{{ tt('Report on Financial Indicators of Southwest Company for January March 2023') }}
			</p>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { ref, watchEffect, defineProps, onMounted, watch } from 'vue';

import { getChart } from './Echart';
import { BusinessIndicatorsOverview } from './type';

const tt = __;

interface Props {
	selectedDate: string;
}
const props = defineProps<Props>();

// 定义响应式数据
const businessIndicatorsOverview = ref<BusinessIndicatorsOverview>();
const url = ref<string>();
const url2 = ref<string>();
const influenceChart = ref<echarts.ECharts>();
const stockChart = ref<echarts.ECharts>();
// 监视 props 的变化并更新响应式数据
watchEffect(() => {
	getBusinessIndicatorsOverview(props.selectedDate);
});

// 根据您的具体实现，您需要实现获取数据的函数
async function getBusinessIndicatorsOverview(date: string) {
	// TODO: 获取在建项目数
	// const result = await frappe.call<{message:BusinessIndicatorsOverview}>({
	//   method:'',
	//   args:{},
	// });
	const result: { message: BusinessIndicatorsOverview } = {
		message: {
			yearPlanValue: 273785.26,
			byNowValue: 29815.73,
			percentComplete: 0,
			influenceFactor: [
				{
					name: __('Impact Of Contract Settlement Nodes'),
					type: 'bar',
					label: {
						normal: {
							show: true,
							position: 'insideRight',
						},
					},
					data: [335, 310, 234, 135, 1548],
				},
				{
					name: __('The impact of tight financial resources on property owners'),
					type: 'bar',
					label: {
						normal: {
							show: true,
							position: 'insideRight',
						},
					},
					data: [100, 200, 300, 400, 500],
				},
			],
			stockCategory: {
				name: __('Classification Of Contract Stock'),
				type: 'pie',
				radius: ['30%', '70%'],
				avoidLabelOverlap: false,
				label: {
					show: true,
					position: 'inside',
					formatter: '{d}%',
					color: '#000',
				},
				emphasis: {
					label: {
						show: true,
						fontSize: '30',
						fontWeight: 'bold',
					},
				},
				labelLine: {
					show: false,
				},
				data: [
					{ value: 35, name: __('Water Conservancy And Hydropower Engineering'), itemStyle: { color: '#5B9BD5' } },
					{ value: 40, name: __('Pumped Storage Engineering'), itemStyle: { color: '#ED7D31' } },
					{ value: 2, name: __('Photovoltaic Engineering'), itemStyle: { color: '#A5A5A5' } },
					{ value: 35, name: __('Water Resources and Environment'), itemStyle: { color: '#FFC000' } },
					{ value: 40, name: __('Highway Engineering'), itemStyle: { color: '#4072C4' } },
					{ value: 2, name: __('Municipal Engineering'), itemStyle: { color: '#70AD47' } },
					{ value: 7, name: __('Housing Construction Engineering'), itemStyle: { color: '#255E91' } },
				],
			},
		},
	};
	businessIndicatorsOverview.value = result?.message;
}

// const res = [
// 	{
// 		type:'zijinjinzhang',
// 		muzhutian:1,
// 		weifang:2,
// 	},
// 	{
// 		type:'jieusanjiedian',
// 		muzhutian:3,
// 		weifang:4,
// 	}
// ]

// const city = ['muzhutian', 'weifang', e, r, t];
// const dataype = ['jiedianyingx', 'zijinjinzhang'];

// dataype.map(item => {
// 	const dataTypeData = res.find(each => each.type === item);
// 	const chengshiData = city.map(each => dataTypeData[each]);
// 	return {
// 		type: 'bar',
// 		data: chengshiData,
// 	};
// });

watch([businessIndicatorsOverview, influenceChart], () => {
	influenceChart.value?.setOption({
		legend: {
			orient: 'vertical',
			left: 10,
			data: [__('Impact Of Contract Settlement Nodes'), __('The impact of tight financial resources on property owners')],
		},
		yAxis: {
			data: ['柴石滩', '母猪田', '潍坊', '宝丰', '白鹤滩'],
		},
		xAxis: {
			type: 'value',
		},
		series: businessIndicatorsOverview.value?.influenceFactor || [],
	});
});
watch([businessIndicatorsOverview, stockChart], () => {
	stockChart.value?.setOption({
		series: [businessIndicatorsOverview.value?.stockCategory],
	});
});
onMounted(() => {
	renderChart();
	renderChart2();
});
function renderChart() {
	// 指定图表的配置项和数据
	const options = {
		title: {
			text: __('Output Value and Influencing Factors'),
		},
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'shadow',
			},
		},
		legend: {
			data: [__('Sales Revenue'), __('Margin')],
		},
		grid: {
			left: '3%',
			right: '4%',
			bottom: '3%',
			containLabel: true,
		},
		xAxis: {
			type: 'category',
			boundaryGap: [0, 0.01],
		},
	};
	influenceChart.value = getChart(document.getElementById('influence-factor')!, {});
	// 使用刚指定的配置项和数据显示图表。
	influenceChart.value.on('finished', function () {
		url.value = influenceChart.value?.getDataURL();
	});
}
function renderChart2() {
	// 指定图表的配置项和数据
	const option = {
		title: {
			text: __('Classification Of Contract Stock'),
			left: 'center',
		},
		tooltip: {
			trigger: 'item',
			formatter: '{b}: {c} ({d}%)',
		},
		legend: {
			orient: 'vertical',
			left: 10,
			data: [
				__('Water Conservancy And Hydropower Engineering'),
				__('Pumped Storage Engineering'),
				__('Photovoltaic Engineering'),
				__('Water Resources and Environment'),
				__('Highway Engineering'),
				__('Municipal Engineering'),
				__('Housing Construction Engineering'),
			],
		},
	};
	stockChart.value = getChart(document.getElementById('stock-category')!, option);
	// 使用刚指定的配置项和数据显示图表。
	stockChart.value.on('finished', function () {
		url2.value = stockChart.value?.getDataURL();
	});
}
</script>

<style scoped lang="less">
  .tool-container {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    .tool {
      margin: 16px;
      padding: 24px;
      h5 {
        margin: 0 0 16px 0;
        font-size: 20px;
        font-weight: bold;
      }
      .project-info {
        margin: 0;
        font-size: 16px;
        line-height: 2;
        text-indent: 2em;
      }
      img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 16px auto;
      }
    }
  }
</style>
