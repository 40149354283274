/* eslint-disable max-len */
import type { ICellRendererParams, ValueGetterParams } from 'ag-grid-community';

export function getColumns(enableAudit: boolean) {
  const enableAuditColumn = enableAudit
    ? [
        {
          headerName: __('Completed'),
          field: 'report_actual',
          width: 160,
          enableRowGroup: false,
          sortable: true,
          editable: false,
          filter: 'agNumberColumnFilter',
          cellRenderer: 'Float',
          cellRendererParams: (params: ICellRendererParams) => {
            const { value } = params;
            return {
              value: value == 0 ? null : value,
            };
          },
        },
      ]
    : [];
  return [
    {
      headerName: __('Unit'),
      enableRowGroup: false,
      pinned: 'left',
      field: 'unit',
      width: 75,
      sortable: false,
      editable: false,
    },
    {
      headerName: __('Design Quantity'),
      field: 'design_quantity',
      enableRowGroup: false,
      width: 160,
      sortable: true,
      cellRenderer: 'Float',
      filter: 'agNumberColumnFilter',
      editable: false,
      cellRendererParams: (params: ICellRendererParams) => {
        const { value } = params;
        return {
          value: value == 0 ? null : value,
        };
      },
    },
    ...enableAuditColumn,
    {
      headerName: enableAudit?__('Audited'):__('Completed'),
      field: 'actual',
      width: 160,
      enableRowGroup: false,
      sortable: true,
      editable: false,
      filter: 'agNumberColumnFilter',
      cellRenderer: 'Float',
      cellRendererParams: (params: ICellRendererParams) => {
        const { value } = params;
        return {
          value: value == 0 ? null : value,
        };
      },
    },
    {
      headerName: __('No Complete'),
      field: 'unfinished_quantity',
      width: 160,
      sortable: true,
      enableRowGroup: false,
      editable: false,
      cellRenderer: 'Float',
      cellRendererParams: (params: ICellRendererParams) => {
        const { value } = params;
        return {
          value: value == 0 ? null : value,
        };
      },
    },
    {
      headerName: __('Percent Complete'),
	  field: 'complete_rate',
      width: 120,
      sortable: false,
      enableRowGroup: false,
      editable: false,
      cellRenderer: 'SparklineCellRenderer',
    },
  ];
}
