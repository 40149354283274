import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-173cceef"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-container" }
const _hoisted_2 = { class: "header" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_ElSpace = _resolveComponent("ElSpace")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString($setup.tt($props.config.title || $props.config.meta?.name||'')), 1 /* TEXT */),
      _createVNode(_component_ElSpace, null, {
        default: _withCtx(() => [
          _createVNode($setup["Expand"], {
            expanders: $setup.expanders,
            onFold: $setup.fold,
            onExpand: $setup.expand
          }, null, 8 /* PROPS */, ["expanders"]),
          ($setup.permissions.createPermission)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                plain: "",
                type: "primary",
                onClick: $setup.add
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.tt('+Add')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _createVNode($setup["AgGridVue"], {
      class: "ag-theme-guigu",
      onGridReady: $setup.onReady,
      groupDisplayType: "singleColumn",
      getRowId: $setup.getRowId,
      localeText: $setup.zhCN,
      rowData: $props.data,
      rowGroupPanelShow: "always",
      columnDefs: $setup.columns,
      domLayout: "autoHeight",
      suppressDragLeaveHidesColumns: $props.smallMeta,
      autoGroupColumnDef: $setup.autoGroupColumnDef,
      onColumnRowGroupChanged: $setup.columnRowGroupChanged,
      defaultExcelExportParams: $setup.excelExportParams
    }, null, 8 /* PROPS */, ["localeText", "rowData", "columnDefs", "suppressDragLeaveHidesColumns", "defaultExcelExportParams"])
  ]))
}