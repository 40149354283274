export default function saveAs(
	data: Blob | MediaSource | string,
	name: string,
  ) {
	const url = typeof data === 'string' ? data : URL.createObjectURL(data);
	const a = document.createElement('a');
	a.href = url;
	a.download = name;
	a.target = '_blank';
	a.click();
	if (typeof data !== 'string') {
	  setTimeout(() => {
		URL.revokeObjectURL(url);
	  }, 30000);
	}
  }
