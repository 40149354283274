<template>
	<div>{{ title }}</div>
</template>

<script setup lang='ts'>
import { computed, watch } from 'vue';

import { ICellRendererParams } from 'ag-grid-community';
const translate = window.__;

const props = defineProps<{ params: ICellRendererParams }>();
const title = computed(() => {
	const l = props.params.data?.__IS_AGGREGATION__ ? '总计' : '';
	return l;
});
</script>

<style lang='less' scoped>
.link {
	color: #409eff;
}
</style>
