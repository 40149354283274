import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ec52651"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button_container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElButton = _resolveComponent("ElButton")
  const _component_AgGridVue = _resolveComponent("AgGridVue")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ElButton, { onClick: _ctx.expand }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.tt('Expand')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"]),
        _createVNode(_component_ElButton, { onClick: _ctx.collapse }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.tt('Collapse')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])
      ])
    ]),
    _createVNode(_component_AgGridVue, {
      class: "ag-theme-guigu",
      columnDefs: _ctx.columnDefs,
      rowData: _ctx.progressAncestry,
      defaultColDef: _ctx.defaultColDef,
      rowSelection: "multiple",
      animateRows: "true",
      onGridReady: _ctx.onGridReady,
      autoGroupColumnDef: _ctx.autoGroupColumn,
      getDataPath: _ctx.getDataPath,
      treeData: true,
      groupDefaultExpanded: "-1",
      onRowDataUpdated: _ctx.onRowDataUpdated,
      onSelectionChanged: _ctx.selectionChanged,
      style: {"height":"300px"},
      rowMultiSelectWithClick: "",
      groupSelectsChildren: "",
      suppressDragLeaveHidesColumns: _ctx.smallMeta,
      localeText: _ctx.zhCN
    }, null, 8 /* PROPS */, ["columnDefs", "rowData", "defaultColDef", "onGridReady", "autoGroupColumnDef", "getDataPath", "onRowDataUpdated", "onSelectionChanged", "suppressDragLeaveHidesColumns", "localeText"])
  ], 64 /* STABLE_FRAGMENT */))
}