import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AgGridVue = _resolveComponent("AgGridVue")
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createBlock(_component_AgGridVue, {
    class: "ag-theme-guigu week-plan_table",
    columnDefs: _ctx.columnDefs,
    rowData: _ctx.progress,
    defaultColDef: _ctx.defaultColDef,
    rowSelection: "multiple",
    animateRows: "true",
    onGridReady: _ctx.onGridReady,
    autoGroupColumnDef: _ctx.autoGroupColumn,
    getDataPath: _ctx.getDataPath,
    treeData: true,
    onCellValueChanged: _ctx.cellValueChanged,
    onRowGroupOpened: _ctx.rowGroupOpened,
    suppressDragLeaveHidesColumns: _ctx.smallMeta,
    localeText: _ctx.zhCN,
    getRowId: _ctx.getRowId
  }, null, 8 /* PROPS */, ["columnDefs", "rowData", "defaultColDef", "onGridReady", "autoGroupColumnDef", "getDataPath", "onCellValueChanged", "onRowGroupOpened", "suppressDragLeaveHidesColumns", "localeText", "getRowId"])), [
    [_directive_loading, _ctx.loading]
  ])
}