<template>
    <div
		class="remark-container"
    >
		<ElTooltip placement="top" :content="value">
			{{value}}
		</ElTooltip>
    </div>
 </template>
<script lang="ts" setup>
 import {defineProps} from 'vue';
 import {ElTooltip} from 'element-plus';
 const props = defineProps<{params:any}>();
 const {value} = props.params;
 </script>

<style scoped>
.remark-container {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
</style>
