import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock(_KeepAlive, null, [
    ($props.domVisible)
      ? (_openBlock(), _createBlock($setup["Main"], {
          key: 0,
          doctype: $props.doctype,
          meta: $props.meta
        }, null, 8 /* PROPS */, ["doctype", "meta"]))
      : _createCommentVNode("v-if", true)
  ], 1024 /* DYNAMIC_SLOTS */))
}