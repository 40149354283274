import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["ElTable"], {
      data: $setup.fields,
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElTableColumn"], {
          type: "index",
          index: 1
        }),
        _createVNode($setup["ElTableColumn"], {
          label: $setup.tt('Field')
        }, {
          default: _withCtx(({ row, $index }) => [
            _createVNode($setup["ElSelect"], {
              modelValue: row.field,
              filterable: "",
              defaultFirstOption: "",
              "onUpdate:modelValue": $event => ($setup.set($index, 'field', $event))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fieldsSelectable, (field) => {
                  return (_openBlock(), _createBlock($setup["ElOption"], {
                    key: field.value,
                    value: field.value,
                    label: field.label
                  }, null, 8 /* PROPS */, ["value", "label"]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          label: $setup.tt('Width')
        }, {
          default: _withCtx(({ row, $index }) => [
            _createVNode($setup["ElInput"], {
              type: "number",
              modelValue: row.width,
              "onUpdate:modelValue": $event => ($setup.setWidth($index, 'width', $event || 0))
            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          label: $setup.tt('Min Width')
        }, {
          default: _withCtx(({ row, $index }) => [
            _createVNode($setup["ElInput"], {
              type: "number",
              modelValue: row.minWidth,
              "onUpdate:modelValue": $event => ($setup.setWidth($index, 'minWidth', $event || 0))
            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          label: $setup.tt('Max Width')
        }, {
          default: _withCtx(({ row, $index }) => [
            _createVNode($setup["ElInput"], {
              type: "number",
              modelValue: row.maxWidth,
              "onUpdate:modelValue": $event => ($setup.setWidth($index, 'maxWidth', $event || 0))
            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          label: $setup.tt('Enable Link')
        }, {
          default: _withCtx(({ row, $index }) => [
            ($setup.isLink(row.field))
              ? (_openBlock(), _createBlock($setup["ElCheckbox"], {
                  key: 0,
                  modelValue: row.enableLink,
                  "onUpdate:modelValue": $event => ($setup.set($index, 'enableLink', $event || false))
                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          label: $setup.tt('Format')
        }, {
          default: _withCtx(({ row, $index }) => [
            ($setup.hasFormat(row.field))
              ? (_openBlock(), _createBlock($setup["ElInput"], {
                  key: 0,
                  modelValue: row.format,
                  "onUpdate:modelValue": $event => ($setup.set($index, 'format', $event || ''))
                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          label: $setup.tt('Operation')
        }, {
          default: _withCtx(({ $index }) => [
            _createVNode($setup["ElButtonGroup"], null, {
              default: _withCtx(() => [
                _createVNode($setup["ElButton"], {
                  onClick: $event => ($setup.moveTo($index)),
                  text: "",
                  icon: $setup.Sort,
                  title: $setup.tt('Move')
                }, null, 8 /* PROPS */, ["onClick", "icon", "title"]),
                _createVNode($setup["ElButton"], {
                  type: "danger",
                  onClick: $event => ($setup.remove($index)),
                  text: "",
                  icon: $setup.Delete,
                  title: $setup.tt('Delete')
                }, null, 8 /* PROPS */, ["onClick", "icon", "title"])
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["data"]),
    _createVNode($setup["ElButton"], { onClick: $setup.add }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($setup.tt('Add')), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    })
  ], 64 /* STABLE_FRAGMENT */))
}