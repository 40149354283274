<template>
	<div class="subjectContent">
		<span class="editBtn"
			:onclick="click"
		> {{ params.value }}</span>
	</div>
</template>
<script lang="ts" setup>
import {defineProps} from 'vue';
import type {
	ICellRendererParams,
} from 'ag-grid-community';

interface SubjectClickParams extends ICellRendererParams {
	emit: (value: locals.DocType) => void,
	layout: string
}
const props = defineProps<{ params: SubjectClickParams}>();
const {data, emit, layout} = props.params;

async function click() {
	if (layout === 'link') {
		frappe.set_route('Form', 'Guigu Boq Item', data?.name);
	} else {
		const recordValue = await frappe.db.get_doc(
			'Guigu Boq Item',
			data?.name,
			{},
		);
		emit(recordValue);
	}
}
</script>

<style scoped lang="less">
.subjectContent {
	display: flex;
	overflow: hidden;
	color: #1890ff;
	cursor: pointer;

	.label {
		flex: 1;
		overflow: hidden;
		white-space: nowrap;
		height: auto;
	}
}

.editBtn{
	color: #1890ff !important
}
.editBtn:hover{
	text-decoration: underline !important;
}
</style>
