import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52477c03"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = ["download", "href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon")

  return (_openBlock(), _createElementBlock("div", null, [
    ($setup.href)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "link",
          target: "_blank",
          href: $setup.href
        }, _toDisplayString($setup.fileValue), 9 /* TEXT, PROPS */, _hoisted_1))
      : _createCommentVNode("v-if", true),
    ($setup.href)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          target: "_blank",
          download: $setup.fileName,
          href: $setup.href
        }, [
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode($setup["Download"])
            ]),
            _: 1 /* STABLE */
          })
        ], 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true)
  ]))
}