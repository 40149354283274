<template>
	<AgGridVue
		class="ag-theme-guigu work-assessment-container_table"
		:rowData="summaryTableData"
		:columnDefs="columnDefs"
		v-loading="loading"
		@grid-ready="onGridReady"
		animateRows="true"
		:localeText="zhCN"
		:suppressDragLeaveHidesColumns="smallMeta"
		:getRowId="getRowId">
	</AgGridVue>
</template>

<script lang="ts">
import { ref, computed, defineComponent, PropType, getCurrentInstance, watch } from 'vue';
import { AgGridVue } from 'ag-grid-vue3';

import FloatComponent from '../../../../../../../../guigu_pm/guigu_pm/public/js/AgGrid/components/Float.vue';
import { SummaryTableData } from '../../type';
import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';
import { useMetaQuery } from '../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';

import AgGridWorkAssessmentSubject from './AgGridWorkAssessmentSubject.vue';
import { getWorkAssessmentSummaryColumns } from './tableColumns';

export default defineComponent({
	components: {
		FloatComponent,
		AgGridVue,
		AgGridWorkAssessmentSubject,
	},
	props: {
		'company': String,
		'year': String,
		'summaryTableData': Object as PropType<SummaryTableData[]>,
		'monthArr': Object as PropType<string[]>,
		'loading': Boolean,
	},
	emits: {
	},
	setup(props, context) {
		const { appContext } = getCurrentInstance() || {};
		const loading = ref<boolean>(false);
		const gridApi = ref(null);
		const onGridReady = (params: any) => {
			gridApi.value = params.api;
		};
		const visible = ref(false);

		const columnDefs = computed(() => getWorkAssessmentSummaryColumns(props.year || ''));
		const smallMeta = useMetaQuery();
		const defaultColDef = {
			sortable: true,
			filter: true,
			flex: 1,
			resizable: true,
		};

		watch(() => props.loading, () => {
			loading.value = props.loading;
		});

		function getRowId(params: any) {
			return params.data.id;
		}

		return {
			loading,
			onGridReady,
			columnDefs,
			defaultColDef,
			getRowId,
			visible,
			confirm,
			zhCN,
			smallMeta,
		};
	},
});
</script>

<style lang="less" scoped>
.work-assessment-container {
  flex: 1;
}

.work-assessment-container_table {
  height: 100%
}
</style>
