<template>
	<AgGridVue
		class="ag-theme-guigu assess-table"
		:rowData="assessLevelData"
		:defaultColDef="defaultColDef"
		:columnDefs="columnDefs"
		animateRows="true"
		@grid-ready="onGridReady"
		:suppressDragLeaveHidesColumns="smallMeta"
		:localeText="zhCN"
		rowGroupPanelShow="always"
		:getRowId="getRowId">
	</AgGridVue>
</template>
<script setup lang="ts">
import {ref, computed, watch} from 'vue';
import {AgGridVue} from 'ag-grid-vue3';
import {ElMessageBox, ElMessage} from 'element-plus';

import type {LevelData} from '../type';
import {useMetaQuery} from '../../../../../../../../guigu_pm/guigu_pm/public/js/components/page/useMetaQuery';
import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';

import {getColumns} from './helper';

interface Props{
	assessLevelData:LevelData[]
	year: string
}
const props = defineProps<Props>();

const smallMeta = useMetaQuery();
const gridApi = ref<any>(null);

const onGridReady = (params: any) => {
	gridApi.value = params.api;
};

const columnDefs = computed(()=>getColumns(moment(props.year).year()));


const defaultColDef = {
	sortable: true,
	filter: true,
	flex: 1,
	resizable: true,
	minWidth: 150,
	suppressMovable: true,
};

function getRowId(params: any) {
	return params.data.name;
}
</script>
  <style lang="less" scoped>
  .assess-table {
	width: 100%;
	height: 100%;
  }
  </style>
