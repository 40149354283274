import type { Ref } from 'vue';
import { computed, unref } from 'vue';

import type { ViewStyle } from './types';


export default function useStyle(configuration: Ref<ViewStyle> | ViewStyle) {
	const rowHeight = computed(() => Math.max(unref(configuration).lineHeight || 28, 20));
	const style = computed(() => {
		const {
			textColor,
			bgColor,
			oddBgColor,
			rowLineColor,
			colLineColor,
		} = unref(configuration);
		return [
			textColor && `--ag-data-color: ${textColor}`,
			bgColor && `--ag-background-color: ${bgColor}`,
			`--ag-odd-row-background-color: ${oddBgColor || `var(--ag-background-color)`}`,
			rowLineColor && `--ag-row-border-color: ${rowLineColor}`,
			colLineColor && `--ag-cell-horizontal-border:  solid ${colLineColor}`,
			`--ag-row-height: ${rowHeight.value}px`,
		].filter(Boolean).join(';');
	});
	return {
		rowHeight,
		style,
	};
}
