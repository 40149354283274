<template>
	<ElDialog v-model="thisVisible" :title="tt('Batch modify permissions')">
		<div class="container">
			<MemberForm
				v-model:designations="designations"
				v-model:permissions="permissionCheckList"
				v-model:isDefault="isDefault"
				v-model:roles="roleCheckList"
			></MemberForm>
		</div>
		<template #footer>
			<div class="dialog-footer">
				<el-button @click="thisVisible = false">{{tt('Cancel')}}</el-button>
				<el-button type="primary" @click="confirm">
				{{tt('Confirm')}}
				</el-button>
			</div>
		</template>
	</ElDialog>
</template>

<script setup lang='ts'>
import { ref, onMounted, computed, watch } from 'vue';
import type { Member, Permissions, User } from '../type';

import {
	ElDialog, ElButton,
} from 'element-plus';
import MemberForm from './MemberForm.vue';
const tt = __
interface Props{
	visible:Boolean
	organization:string
	selected:Member[]
}
const props = defineProps<Props>();
interface Emit{
	(e: 'update:visible', v: boolean): void,
	(e:'loading'):void
}
const emit = defineEmits<Emit>();
const roleCheckList = ref<string[]|'keep'>([])
const permissionCheckList = ref<string[]|'keep'>([])
const designations = ref<string[]|'keep'>([])
const isDefault = ref<boolean|'keep'>(false)

const thisVisible = computed({
	set(v){
		emit('update:visible', v)
	},
	get(){
		return props.visible
	}
})

function reset(){
	roleCheckList.value = []
	permissionCheckList.value = []
	designations.value = []
	isDefault.value = false
}
async function confirm(){
	emit('loading')
	thisVisible.value = false
	await frappe.call<{ message: Member[] }>({
		method: 'tianjy_organization.tianjy_organization.page.tianjy_organization_config.tianjy_organization_config.bulk_roles',
		args:{
			organization_name: props.organization,
			user_names: props.selected.map(item=>item.user),
			roles: roleCheckList.value,
			permissions:permissionCheckList.value,
			designations:designations.value,
			is_default:isDefault.value?'1':'0',
			members:props.selected.map(item=>item.name),
		}
	});
	reset()
}
</script>

<style lang='less' scoped>
.container{
	max-height: 550px;
	overflow: auto
}
</style>
