export function getFieldName(dt: string, field: string) {
	return frappe.model.get_full_column_name(field, dt);
}
export default function getFields(
	meta: locals.DocType,
	order: { doctype: string; field: string; desc: boolean; }[],
	allFields: (string | undefined)[]
) {
	const doctype = meta.name;
	let fields: [string, string][] = [];
	for (const f of frappe.model.std_fields_list) {
		fields.push([f, doctype]);
	}
	for (const f of allFields) {
		if (!f) { continue; }
		fields.push([f, doctype]);
	}
	const mustFields = new Set(['status', 'name']);
	for (const df of meta.fields) {
		if (mustFields.has(df.fieldname)) {
			fields.push([df.fieldname, df.parent || doctype]);
		}
	}

	for (const f of [meta.title_field, meta.image_field]) {
		if (f) { fields.push([f, doctype]); }
	}
	// for (const f of settings.add_fields || []) {
	// }
	if (meta.track_seen) {
		fields.push(['_seen', doctype]);
	}
	for (const f of ['enabled', 'disabled', 'color']) {
		fields.push([f, doctype]);
	}
	for (const f of order) {
		fields.push([f.field, f.doctype]);
	}
	fields = fields.filter(([field, doctype]) => {
		const is_valid_field = frappe.model.std_fields_list.includes(field) ||
			frappe.meta.has_field(doctype, field) ||
			field === '_seen';
		return is_valid_field;
	});

	return fields;
}
