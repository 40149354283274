<template>
	<ElDialog
		v-model="dialogVisible"
		:title="tt('Enable Process List')"
		width="80%"
		top="5vh"
	>
		<div class="body">
			<ElTable
				ref="tableRef"
				class="table"
				:data="tableData"
				style="width: 100%"
				height="400"
				stripe
				border
				@select="selectionChange"
			>
				<ElTableColumn type="selection" width="38" />
				<ElTableColumn :label="tt('Dw Process Title')" >
					<template #default="scope">
						<div>{{ `${scope.row.full_process_code} ${scope.row.process_name}` }}</div>
					</template>
				</ElTableColumn>
			</ElTable>
		</div>

		<template #footer>
			<div class="dialog-footer">
				<ElButton @click="dialogVisible = false">{{ tt('Cancel') }}</ElButton>
				<ElButton type="primary" @click="confirm">
					{{ tt('Confirm') }}
				</ElButton>
			</div>
		</template>
	</ElDialog>
</template>

<script lang="ts" setup>
import {computed, ref, watch} from 'vue';
import {ElDialog, ElButton, ElTable, ElTableColumn, type TableInstance} from 'element-plus';

import type {OrganizationProcess, Process} from './type';

const tt = __;
interface Props {
	visible:boolean,
	processList:Process[]
	organizationProcessList:OrganizationProcess[]
}
const props = defineProps<Props>();
const tableData = computed(()=>props.processList.filter(item=>!props.organizationProcessList.some(each=>each.dw_process === item.name)));
interface Emit {
	(e: 'update:visible', visible: boolean): void,
	(e: 'relate', process: Process): void,
}
const emit = defineEmits<Emit>();
const selectionData = ref<Process>();
const tableRef = ref<TableInstance>();
const dialogVisible = computed({
	get() {
		return props.visible;
	},
	set(visible:boolean) {
		emit('update:visible', visible);
	},
});

watch([tableRef, dialogVisible], ()=>{
	if (!tableRef.value) {
		return;
	}
	tableRef.value?.clearSelection();
}, {immediate: true});

function selectionChange(selected:Process[], row:Process) {
	const isSelectRow = selected.some(item=>item.name === row.name);
	if (isSelectRow && selectionData.value) {
		tableRef.value?.toggleRowSelection(selectionData.value, false);
	}
	if (isSelectRow) {
		selectionData.value = row;
	} else {
		selectionData.value = undefined;
	}
}
function confirm() {
	if (!selectionData.value) {
		return;
	}
	emit('relate', selectionData.value);
	dialogVisible.value = false;
}
</script>
<style scoped lang="less">
.table :deep(.el-table__header-wrapper  .el-checkbox){
	display:none
}
.table :deep(label){
	margin-bottom: 0;
}
.table :deep(.el-checkbox__original){
	position: absolute;
}
.body{
	display: flex;
	flex-direction: column;
}

</style>
