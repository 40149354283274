<template>
	<div class='main'>
		<ElTooltip class="period" v-for="period in periods">
			<div :class="{selectPeriod: selectPeriod?.name===period.name, periodLabel:true}" @click="clickPeriod(period)">
				{{buttonLabel(period)}}
			</div>
			<template #content>
				<p>{{PERIOD_TYPE[periodType]}}：{{`${String(period.value)?.slice(0, 4)}.${String(period.value)?.slice(4)}`}}</p>
				<p>{{tt('Start Date')}}：{{period.start_date}}</p>
				<p>{{tt('End Date')}}：{{period.end_date}}</p>
			</template>
		</ElTooltip>
	</div>
</template>
<script lang="ts" setup>

import {ref, defineProps, toRefs, watchEffect} from 'vue';

import { Period } from './type';
const tt = __
const PERIOD_TYPE={
	'Year Month':tt('Year Month'),
	'Year Week':tt('Year Week'),
	'Year':tt('Year'),
} as const;
interface Props{
	periods:Period[],
	periodType:'Year'|'Year Month'|'Year Week',
	selectPeriod?:Period
}
const props = defineProps<Props>();
interface Emit{
	(e:'periodChange', period: Period):void
}
const emit = defineEmits<Emit>();

const {periods, periodType, selectPeriod} = toRefs(props);
function buttonLabel(period:Period){
	if (periodType.value === 'Year'){ return period.value; }
	return String(period.value)?.slice(4);
}
function clickPeriod(period:Period){
	emit('periodChange', period);
}
</script>
<style lang="less" scoped>
.main {
	width: 390px;
	padding: 12px 16px;
}

.selectPeriod {
	background-color: #409eff;
	color: #fff;
}

.periodLabel {
	display: inline-block;
	width: 77px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding: 4px 15px;
	cursor: pointer;
	text-align: center;
	border-radius: 4px;
}
</style>
