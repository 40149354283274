<template>
	<Main
		v-if=" meta && name"
		:meta="meta"
		:name="name"
		:isHideClose="isHideClose"
		:isDialog="isDialog"
		v-model:visible="formVisible"
		@onChange="onChange" />
</template>
<script lang="ts" setup>
import {computed} from 'vue';

import Main from './Main.vue';
import type {ChangeValue} from './type';

defineOptions({name: 'FormDetail:Root', inheritAttrs: false});
const props = defineProps<{
	/** 当前 doctype 的信息 */
	meta: locals.DocType;
	/** 主区域的 详情 */
	name: string;
	isHideClose?: boolean;
	// 是否是弹窗
	isDialog?: boolean;
	visible:boolean
}>();


const emit = defineEmits<{
	(event: 'onChange', data: ChangeValue): void;
	(event: 'update:visible', visible: boolean): void;

}>();

function onChange(value: ChangeValue) {
	emit('onChange', value);
}

const formVisible = computed({
	get() {
		return props.visible;
	},
	set(value:boolean) {
		emit('update:visible', value);
	},
});
</script>
