import { viewSettings } from '../../extends/init/defineMainView';

import FilterField from './FilterField.vue';
import Info from './Info.vue';
import Fields from './Fields.vue';
import Buttons from './Buttons.vue';
import Organizations from './Organizations.vue';
import FiltersDefault from './FiltersDefault.vue';
import FiltersMust from './FiltersMust.vue';
import OrderDefault from './OrderDefault.vue';
import DownloadFiles from './DownloadFiles.vue'
const baseMenus: GlobalView.ConfigurationWidget[] = [{
	component: Info,
	label: '基本配置',
}, {
	component: FilterField,
	label: '筛选列表',
	hidden(meta, cfg, view, s) { return !s?.configuration?.filterField; },
}, {
	component: Fields,
	label: '显示字段',
	hidden(meta, cfg, view, s) { return !s?.configuration?.displayField; },
}, {
	component: Buttons,
	label: '按钮',
	group: 'Layout',
}, {
	component: Organizations,
	label: '关联组织',
	group: 'Permissions',
}, {
	component: FiltersDefault,
	label: '默认过滤条件',
	group: 'Search',
}, {
	component: FiltersMust,
	label: '必要过滤条件',
	group: 'Search',
}, {
	component: OrderDefault,
	label: '默认排序规则',
	group: 'Search',
}, {
	component: DownloadFiles,
	label: '下载附件',
}];

export interface MenuItem {
	component: any;
	key: string;
	label: string;
	type?: string;
	hidden?(
		meta: locals.DocType,
		cfg: any,
		view: any,
		define?: GlobalView
	): boolean | void | null;
}

export interface MenuGroup {
	key: string;
	label: string;
	children: MenuItem[];
}

export default function getMenus(): MenuGroup[] {
	const groups: MenuGroup[] = [];
	const groupMap = new Map<string, MenuGroup>();
	const itemSet = new Set<string>();
	function getGroup(key: string) {
		const g = groupMap.get(key);
		if (g) { return g; }
		const group: MenuGroup = { key, label: __(key), children: [] };
		groupMap.set(key, group);
		groups.push(group);
		return group;
	}
	let k = 0;
	for (const { component, label, group, hidden = () => false } of baseMenus) {
		const key = `${k++}`;
		if (itemSet.has(key)) { continue; }
		const g = getGroup(group || '');
		g.children.push({ label: __(label || key), component, key, hidden });
	}
	for (const [type, { configuration }] of viewSettings) {
		const widgets = configuration?.widgets || [];
		let k = 0;
		for (const { component, label, group, hidden } of widgets) {
			const key = `${type}:${k++}`;
			if (itemSet.has(key)) { continue; }
			const g = getGroup(group || type);
			g.children.push({ type, label: __(label), component, key, hidden });
		}
	}
	return groups;
}

export function getTypes() {
	return [...viewSettings].map(([k, v]) => ({ value: k, label: __(v.type || k) }));
}
