import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tree = _resolveComponent("el-tree")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_tree, {
      data: $setup.treeData,
      props: $setup.defaultProps,
      onNodeClick: $setup.handleNodeClick,
      "default-expand-all": ""
    }, null, 8 /* PROPS */, ["data"])
  ]))
}