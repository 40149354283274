import type { Group } from '../../types';

export function getWidths(a: Group[]): [number[], number] {
	let list = a.map(v => 1);
	let n = 1;
	for (let index = a.length - 1; index >= 0; index--) {
		list[index] = n;
		n *= a[index].values.length || 1;
	}
	return [list, n];
}

function toValue(v: any) {
	return ['string', 'number'].includes(typeof v) ? v : '';
}
export function toList(groups: Group[]) {
	let list: Record<string, any>[] = [{}];
	for (const { field, values } of groups) {
		const newList: Record<string, any>[] = [];
		for (const p of list) {
			for (const { value } of values) {
				const v = toValue(value);
				newList.push({ ...p, [field]: v });
			}
		}
		list = newList;
	}
	return list;
}
export function intersection<T>(a: T[], b: T[]): T[] {
	const set = new Set(a);
	return b.filter(a => set.has(a));
}
