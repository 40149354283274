import {createApp} from 'vue';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn';
import VueViewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';

import DailyRecord from './guigu_epc_organization_daily_record/index.vue';

export default function loadEPCOrganizationDailyRecord(wrapper:HTMLElement) {
	const app = createApp(DailyRecord, {});
	app.use(ElementPlus, {size: 'small', locale: zhCn});
	app.use(VueViewer);
	app.mount(wrapper);
}

frappe.provide('frappe.guigu_work_list');
frappe.guigu_work_list.pages = frappe.guigu_work_list.pages || {};
frappe.guigu_work_list.pages.loadEPCOrganizationDailyRecord = loadEPCOrganizationDailyRecord;
