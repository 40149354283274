<template>
	<ElInput v-model="value" type="password"
		:placeholder="label && tt(label)" />
</template>
<script lang="ts" setup>
import { computed, onUnmounted, watch } from 'vue';
import { ElInput } from 'element-plus';


const props = defineProps<{
	label?: string;
	name: string;
	required?: boolean;
	filter?: boolean;
	modelValue?: any;
	fitWidth?: boolean;
	popperClass?: string;
	options?: any;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: any): void;
}>();

const tt = __;


watch(() => props.modelValue, value => {
	if (typeof value !== 'string' && value !== undefined) {
		emit('update:modelValue', undefined);
	}
}, { immediate: true });
const value = computed({
	get: () => {
		const value = props.modelValue;
		return typeof value === 'string' ? value : undefined;
	},
	set: v => emit('update:modelValue', v || undefined),
});
</script>
