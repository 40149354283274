import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55c1e978"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElProgress = _resolveComponent("ElProgress")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_ElTooltip = _resolveComponent("ElTooltip")

  return (_openBlock(), _createBlock(_component_ElTooltip, { visible: $setup.visible }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ElProgress, {
          format: $setup.format,
          percentage: $setup.speed,
          status: $setup.speed === 100 ? undefined : 'success'
        }, null, 8 /* PROPS */, ["percentage", "status"])
      ])
    ]),
    default: _withCtx(() => [
      ($setup.files.length>0)
        ? (_openBlock(), _createBlock(_component_ElTooltip, {
            key: 0,
            content: $setup.tt('Batch Download')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, { onClick: $setup.downloadZip }, {
                default: _withCtx(() => [
                  _createVNode($setup["Download"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["content"]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible"]))
}