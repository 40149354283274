<template>
  <el-dialog v-model="dialogVisible" :title="tt('Create a new temporary task')" :width="width" destroyOnClose @close="cancel">
    <div>
      <el-form class="temp-form" :model="form" :rules="rules" labelWidth="100px">
        <el-form-item :label="tt('Plan Content')" prop="subject">
          <el-input type="textarea" v-model="form.subject" />
        </el-form-item>
        <el-form-item :label="tt('Evaluation Criterion')" prop="standard">
          <el-input type="textarea" v-model="form.standard" />
        </el-form-item>
        <el-form-item :label="tt('Weightage')" prop="total_score">
          <el-input-number v-model="form.total_score" />
        </el-form-item>
        <el-form-item :label="tt('Work Criteria')" prop="work_criteria">
          <el-input type="textarea" v-model="form.work_criteria" />
        </el-form-item>
        <el-form-item :label="tt('Evaluation Basis')" prop="evaluation_basis">
          <el-input type="textarea" v-model="form.evaluation_basis" />
        </el-form-item>
        <el-form-item :label="tt('Complete Percent')" prop="percent_complete">
          <el-input-number v-model="form.percent_complete" />%
        </el-form-item>
        <el-form-item :label="tt('Complete Status')" prop="remark">
          <el-input type="textarea" v-model="form.remark" />
        </el-form-item>
        <!-- <el-form-item :label="tt('Self Assessment')" prop="self_assessment">s
					<el-input-number v-model="form.self_assessment" />
					</el-form-item> -->
        <!-- <el-form-item :label="tt('Direct Superior Rating')" prop="leader_assessment">
					<el-input-number v-model="form.leader_assessment" />
					</el-form-item> -->
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel">{{ tt('Cancel') }}</el-button>
        <el-button type="primary" @click="confirm"> {{ tt('Sure') }} </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
  import { ElMessage } from 'element-plus';
  import { computed, reactive, ref, watch } from 'vue';

  const tt = __;
  interface Props {
    visible: boolean;
    smallMeta: boolean;
  }
  const props = defineProps<Props>();
  interface Emit {
    (e: 'cancel'): void;
    (
      e: 'confirm',
      data: {
        subject: string;
        standard: string;
        total_score: number;
        remark: string;
        self_assessment: number;
        leader_assessment: number;
        percent_complete: number;
        evaluation_basis: string;
        work_criteria: string;
      }
    ): void;
  }
  const emit = defineEmits<Emit>();
  const dialogVisible = ref<boolean>(false);
  const width = computed(() => (props.smallMeta ? '90%' : '40%'));

  const form = reactive({
    subject: '',
    standard: '',
    total_score: 0,
    remark: '',
    self_assessment: 0,
    leader_assessment: 0,
    percent_complete: 0,
    evaluation_basis: '',
    work_criteria: '',
  });
  const rules = reactive({
    subject: [{ required: true, message: __('Please fill in the plan content'), trigger: 'blur' }],
  });
  watch(
    () => props.visible,
    () => {
      dialogVisible.value = props.visible;
    },
    { immediate: true }
  );

  function reset() {
    form.leader_assessment = 0;
    form.remark = '';
    form.self_assessment = 0;
    form.standard = '';
    form.subject = '';
    form.total_score = 0;
    form.percent_complete = 0;
    form.evaluation_basis = '';
    form.work_criteria = '';
  }
  function confirm() {
    if (!form.subject) {
      ElMessage({
        showClose: true,
        message: __('Please fill in the required fields'),
        type: 'error',
      });
      return;
    }
    emit('confirm', form);
    reset();
  }
  function cancel() {
    emit('cancel');
    reset();
  }
</script>

<style lang="less" scoped>
  .temp-form :deep(label) {
    margin-bottom: 0;
  }
</style>
