import { createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c6c387d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["NodeViewWrapper"], {
    class: _normalizeClass({'draggable-item':$setup.isEditable})
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(), _createBlock(_Suspense, null, {
          default: _withCtx(() => [
            _createVNode($setup["ChartBlock"], {
              chartName: _ctx.node.attrs.chart_name,
              data: _ctx.node.attrs.data,
              chart_type: _ctx.node.attrs.chart_type,
              isEditable: $setup.isEditable,
              nodeViewProps: $setup.props,
              onSetChartName: _cache[0] || (_cache[0] = $event => (_ctx.updateAttributes({ chart_name: $event }))),
              onRemove: _cache[1] || (_cache[1] = $event => (_ctx.deleteNode()))
            }, null, 8 /* PROPS */, ["chartName", "data", "chart_type", "isEditable", "nodeViewProps"])
          ]),
          _: 1 /* STABLE */
        }))
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class"]))
}