import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, resolveDirective as _resolveDirective, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b49aac41"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "file-container_timeline",
  ref: "el"
}
const _hoisted_2 = { id: "file-container_table" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_loading = _resolveDirective("loading")
  const _directive_viewer = _resolveDirective("viewer")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dataState, (file) => {
        return _withDirectives((_openBlock(), _createBlock($setup["FileItem"], {
          onClickFolder: $setup.clickFolder,
          onSelectFile: $setup.selectFile,
          onRefresh: _cache[0] || (_cache[0] = $event => ($setup.emit('refresh'))),
          onDelete: $setup.deleteFileDoc,
          data: file,
          folderNameField: $setup.folderNameField,
          isFolderField: $setup.isFolderField,
          fileNameField: $setup.fileNameField,
          meta: $props.meta,
          selected: $props.selected,
          permission: $setup.permission
        }, null, 8 /* PROPS */, ["data", "folderNameField", "isFolderField", "fileNameField", "meta", "selected", "permission"])), [
          [_vShow, $setup.hasFolderSelected]
        ])
      }), 256 /* UNKEYED_FRAGMENT */))
    ])), [
      [_vShow, $setup.fileViewStore.showMode.includes('iconMode')],
      [_directive_loading, $props.loading || $setup.deleteLoading],
      [_directive_viewer]
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      _createVNode($setup["DefaultMain"], {
        sort: $props.sort,
        meta: $props.meta,
        options: $props.options,
        data: $setup.dataState,
        total: $props.total,
        modelValue: $props.modelValue,
        infiniteScroll: $props.infiniteScroll,
        page: $props.page,
        limit: $props.limit,
        group: $props.group,
        view: $props.view,
        detail: $props.detail,
        configuration: $setup.listConfig,
        rowAction: $props.rowAction,
        onRefresh: _cache[1] || (_cache[1] = $event => ($setup.emit('refresh'))),
        onDelete: _cache[2] || (_cache[2] = $event => ($setup.emit('delete', $event))),
        onCreate: _cache[3] || (_cache[3] = (data, extend) => $setup.emit('create', data, extend)),
        onNextPage: _cache[4] || (_cache[4] = $event => ($setup.emit('nextPage'))),
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ($setup.emit('update:modelValue', $event))),
        "onUpdate:selected": _cache[6] || (_cache[6] = $event => ($setup.emit('update:selected', $event))),
        "onUpdate:data": _cache[7] || (_cache[7] = $event => ($setup.emit('update:data', $event))),
        onFilter: _cache[8] || (_cache[8] = (field, operator, value) => $setup.emit('filter', field, operator, value)),
        onSort: _cache[9] || (_cache[9] = (target, docs, before, field) => $setup.emit('sort', target, docs, before, field))
      }, null, 8 /* PROPS */, ["sort", "meta", "options", "data", "total", "modelValue", "infiniteScroll", "page", "limit", "group", "view", "detail", "configuration", "rowAction"])
    ])), [
      [_vShow, $setup.fileViewStore.showMode.includes('tableMode')],
      [_directive_loading, $props.loading || $setup.deleteLoading],
      [_directive_viewer]
    ]),
    _withDirectives(_createVNode($setup["DeleteFolderDialog"], {
      isFolderField: $setup.isFolderField,
      folderNameField: $setup.folderNameField,
      parentField: $setup.parentField,
      meta: $props.meta,
      visible: $setup.visible,
      folder: $setup.deleteFolder,
      onDeleteFolder: $setup.deleteFolderFn
    }, null, 8 /* PROPS */, ["isFolderField", "folderNameField", "parentField", "meta", "visible", "folder"]), [
      [_directive_loading, $setup.deleteLoading]
    ])
  ], 64 /* STABLE_FRAGMENT */))
}