import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AgGridVue = _resolveComponent("AgGridVue")
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createBlock(_component_AgGridVue, {
    class: "ag-theme-guigu",
    style: {"height":"500px"},
    columnDefs: _ctx.columnDefs,
    rowData: _ctx.progressGatherData,
    defaultColDef: _ctx.defaultColDef,
    animateRows: "true",
    onGridReady: _ctx.onGridReady,
    autoGroupColumnDef: _ctx.autoGroupColumn,
    getDataPath: _ctx.getDataPath,
    treeData: true,
    localeText: _ctx.zhCN,
    suppressDragLeaveHidesColumns: _ctx.smallMeta,
    groupDefaultExpanded: "-1"
  }, null, 8 /* PROPS */, ["columnDefs", "rowData", "defaultColDef", "onGridReady", "autoGroupColumnDef", "getDataPath", "localeText", "suppressDragLeaveHidesColumns"])), [
    [_directive_loading, _ctx.loading]
  ])
}