import { ColDef, ValueGetterParams } from 'ag-grid-community';

import Comp from './Comp.vue';

function getAggFunc(field: GlobalView.FieldRenderOptions) {
	switch (field.fieldtype) {
		case 'Int':
			return params => {
				let sum = 0;
				for (const item of (params?.values || [])) { (sum += (item || 0) * 1000); }
				const res = sum ? sum / 1000 : undefined;
				const {options} = field;
				if ( options&& options.trim()&&cstr( options).trim() !== 'File Size'){
					const number_format = options.trim();
					const formatted_value = format_number(res, number_format, 0);
					return isNaN(Number(res)) ? '' : formatted_value;
				}
				return res;
			};
		case 'Float':
			return params => {
				let sum = 0;
				for (const item of (params?.values || [])) { (sum += (item || 0) * 1000); }
				const res = sum ? sum / 1000 : undefined;
				const number_format = field.options?.trim()||get_number_format();
				const precision = field.precision || cint(frappe.boot.sysdefaults.float_precision, null);
				return format_number(res, number_format, precision);
			};
		case 'Currency':
			return 'sum';
		case 'Rating':
			return 'avg';
	}
	return null;
}

export default function getColumns(
	{name}: locals.DocType,
	setFilter: (field: string, operator: string, value: any) => void,
	getRows: () => any[],
	fields: GlobalView.DisplayField[],
	fixedFieldColumns: number,
	isTree?: boolean,
) {
	const meta = {name};
	const fields_order: ColDef[] = fields.map(({
		field, groupable, group, width, maxWidth, minWidth, enableLink, format, alignment, show_tag,
	}) => ({
		headerName: __(field.label || 'Field'),
		field: field.fieldname,
		resizable: true,
		hide: isTree ? false : group,
		rowGroup: isTree ? false : group,
		enableRowGroup: isTree ? false : groupable,
		aggFunc: isTree ? null : getAggFunc(field),
		cellRenderer: Comp,
		width: width || undefined,
		maxWidth: maxWidth || undefined,
		minWidth: minWidth || undefined,
		alignment: alignment || 'left',
		cellRendererParams: {
			docField: field,
			meta, setFilter, getRows, enableLink, format,
			alignment: alignment || 'left', show_tag,

		},
		valueGetter:(params:ValueGetterParams)=>{
			if (!params.data){
				return '';
			}
			if (params.colDef.field==='__Status__'){
				return frappe.get_indicator(params.data, meta.name)?.[0];
			}
			return params.data[params.colDef.field];
		},
	}));
	for (let i = 0; i < fixedFieldColumns; i++) {
		const column = fields_order[i];
		if (!column) { break; }
		column.pinned = 'left';
	}
	return fields_order;
}
