interface Data {
	[key:string]:any
}
interface PathData extends Data{
	path:string[]
}

/**
 * @description list列表转AgGrid树结构的公共函数
 * @param listData 列表
 * @param parentField 父级节点字段
 * @param keyField 用来做唯一值的key
 * @returns
 */
export function agGridTree<T extends Data>(listData: T[], parentField: string | null, keyField:string = 'name') {
	if (!listData || !parentField) {
		return [];
	}
	const listDataCopy:T[] = JSON.parse(JSON.stringify(listData));
	const map: {[key:string]:PathData} = {};
	// 构建映射
	for (const item of listDataCopy) {
		map[item[keyField]] = {...item, path: []};
	}
	for (const item of listDataCopy) {
		let current:Data = item;
		while (current[parentField]) {
			const parentKey = current[parentField];
			if (map[parentKey]) {
				current = map[parentKey];
				map[item[keyField]].path.unshift(parentKey);
			} else {
				break;
			}
		}
		map[item[keyField]].path.push(item[keyField]);
	}
	return listDataCopy.map(item => ({
		...item,
		path: map[item[keyField]].path,
	}));
}
