<template>
	<ElDialog
		v-model="visible"
		:title="tt('Sorting Rules')"
		modal
		width="max(min(400px, 95%), 50%)"
	>
		<div
			class="line"
			v-for="sort in sorts"
			@dragover.prevent
			@dragend="currentSort = undefined"
			@dragstart="currentSort = sort"
			:key="`${sort.doctype || doctype}.${sort.field}`"
			@drop.prevent="drop(sort)"
			:draggable="draggable(sort)"
		>
			<ElButton
				@pointerdown="draggableSort = sort"
				@pointerup="draggableSort = undefined"
				@pointercancel="draggableSort = undefined"
				@touchmove.prevent
				:icon="Rank"
				text
			/>
			<ElButton
				@click="sort.desc = !sort.desc"
				:title="tt(sort.desc ? 'descending' : 'ascending')"
				:icon="sort.desc ? SortDown : SortUp"
				circle
			/>
			<div class="label">
				{{ tt(getLabel(sort)) }}
			</div>
			<ElButton
				@click="remove(sort)"
				:icon="Delete"
				:title="tt('Delete')"
				text
			/>
		</div>

		<template #footer>
			<span class="footer">
				<ElDropdown @command="add">
					<ElButton :title="tt('Add')" :icon="Plus" />
					<template #dropdown>
						<ElDropdownMenu>
							<template v-for="item in options" :key="item.field">
								<ElDropdownItem
									v-if="
										!added.has(`${doctype}.${item.field}`)
									"
									:key="item.field"
									:command="item.field"
								>
									{{ tt(item.label) }}
								</ElDropdownItem>
							</template>
						</ElDropdownMenu>
					</template>
				</ElDropdown>
				<div class="placeholder" />
				<ElButtonGroup>
					<ElButton
						@click="clean"
						:title="tt('Clear')"
						:icon="Delete"
					/>
					<ElButton
						@click="visible = false"
						:title="tt('Cancel')"
						:icon="Close"
					/>
					<ElButton
						type="primary"
						@click="submit"
						:title="tt('Confirm')"
						:icon="Finished"
					/>
				</ElButtonGroup>
			</span>
		</template>
	</ElDialog>
</template>
<script lang="ts" setup>
import {computed, reactive, ref} from 'vue';
import {
	ElButton,
	ElButtonGroup,
	ElDropdown,
	ElDropdownMenu,
	ElDropdownItem,
} from 'element-plus';
import {
	Plus,
	Delete,
	Rank,
	SortDown,
	SortUp,
	Finished,
	Close,
} from '@element-plus/icons-vue';

const tt = __;

const props = defineProps<{
	meta: locals.DocType;
	modelValue: GlobalView.Sort[];
	visible: boolean;
}>();

const emit = defineEmits<{
	(event: 'update:modelValue', sort: GlobalView.Sort[]): void;
	(event: 'update:visible', visible: boolean): void;
}>();
const visible = computed({
	get() {
		return props.visible;
	},
	set(v) {
		emit('update:visible', v);
	},
});

const sorts = computed<GlobalView.Sort[]>(() => {
	if (!visible.value) {
		return reactive([]);
	}
	const list = props.modelValue.map(f => ({...f}));
	return reactive(list);
});
function submit() {
	const {sort_field, sort_order, name: doctype} = props.meta;
	const list = sorts.value;
	const sort = list.length
		? list.map(a => ({
			field: a.field,
			doctype: a.doctype || doctype,
			desc: a.desc || false,
		}))
		: [
			{
				doctype,
				field: sort_field || 'modified',
				desc: sort_order?.toUpperCase() === 'DESC',
			},
		];
	emit('update:modelValue', sort);
	visible.value = false;
}
function clean() {
	sorts.value.length = 0;
}

const doctype = computed(() => props.meta.name);
const options = computed(() => {
	const {meta} = props;
	const doctype = meta.name;
	const list: {
		field: string;
		label: string;
	}[] = [
		{
			field: 'modified',
			label: frappe.meta.get_label(doctype, 'modified'),
		},
		{field: 'name', label: frappe.meta.get_label(doctype, 'name')},
		{
			field: 'creation',
			label: frappe.meta.get_label(doctype, 'creation'),
		},
		{field: 'idx', label: frappe.meta.get_label(doctype, 'Most Used')},
	];

	const {title_field} = meta;
	if (title_field) {
		list.splice(1, 0, {
			field: title_field,
			label: frappe.meta.get_label(doctype, title_field),
		});
	}

	for (const df of meta.fields) {
		if (
			(df.mandatory || df.bold || df.in_list_view || df.reqd)
			&& frappe.model.is_value_type(df.fieldtype)
			&& frappe.perm.has_perm(doctype, df.permlevel, 'read')
		) {
			list.push({field: df.fieldname, label: df.label});
		}
	}
	const seen = new Set();
	return list.filter(({field}) => {
		if (seen.has(field)) {
			return false;
		}
		seen.add(field);
		return true;
	});
});
const labels = computed(() =>
	Object.fromEntries(options.value.map(({label, field}) => [field, label])));
const added = computed(() =>
	new Set(sorts.value.map(f => `${f.doctype || doctype.value}.${f.field}`)));

function add(field: string) {
	sorts.value.push({field});
}
function remove(filter: GlobalView.Sort) {
	const index = sorts.value.indexOf(filter);
	if (index >= 0) {
		sorts.value.splice(index, 1);
	}
}
const currentSort = ref<GlobalView.Sort>();
const draggableSort = ref<GlobalView.Sort>();
function draggable(it: GlobalView.Sort) {
	return draggableSort.value === it;
}
function drop(it: GlobalView.Sort) {
	const old = currentSort.value;
	if (!old) {
		return;
	}
	if (it === old) {
		return;
	}
	const list = sorts.value;
	const oldIndex = list.indexOf(old);
	if (oldIndex < 0) {
		return;
	}
	const newIndex = list.indexOf(it);
	if (newIndex < 0) {
		return;
	}
	if (newIndex === oldIndex) {
		return;
	}
	list.splice(newIndex, 0, ...list.splice(oldIndex, 1));
}
function getLabel({doctype, field}: GlobalView.Sort) {
	if (!doctype) {
		const label = labels.value;
		const l = field in label && label[field];
		if (l) {
			return l;
		}
	}
	return frappe.meta.get_label(doctype, field);
}
</script>

<style scoped lang="less">
.line {
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	flex-wrap: nowrap;
	margin: 4px;
	padding: 4px;

	&:hover {
		background: #0001;
		border-radius: 4px;
	}
}

.label {
	flex: 1;
}

.footer {
	width: 100%;
	display: flex;
	flex-direction: row;
}

.placeholder {
	flex: 1;
}
</style>
