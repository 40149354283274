<template>
	<Page>
		<template #title>
			<h3 class="title">{{ tt('Boq Item List') }}</h3>
		</template>
		<template #tools>
			<Tools
				v-model:project="project"
				v-model:detailType="detailType"
				:loading="loading"
				v-model:refreshValue="refreshValue"
				:gridApi="gridApi"
				@updateRecordValue="updateRecordValue"
			/>
		</template>
		<div class="agcontainer">
			<Skeleton
				:loading="loading"
				ref="skeleton"
				:layout="detailType"
				:hasDetail="recordValue"
			>
				<AgGridTable
					:boqItemList="boqItemListAncestry"
					:layout="detailType"
					@updateRecordValue="updateRecordValue"
					@onGridReady="onGridReady"
				/>
				<template #detail>
					<FormDetail
						v-if="recordValue"
						:meta="docMeta"
						:options="{}"
						v-model:value="recordValue"
					/>
				</template>
			</Skeleton>
		</div>
	</Page>
</template>
<script lang="ts" setup>
import { ref, onMounted, watch, shallowRef } from 'vue';
import type { GridApi } from 'ag-grid-community';

import Page from '../../../../../../../guigu/guigu/public/js/components/page/index.vue';
import Skeleton from '../../../components/DefaultFormDetail/Detail/index.vue';
import FormDetail from '../../../components/DefaultFormDetail/FormDetail/index.vue';

import Tools from './components/Tools.vue';
import AgGridTable from './components/AgGridTable.vue';
import {
	getCodeRuleSetting,
	getWorkListAncestry,
	getBoqItemList,
	getBoqItemStatusListObj,
	getBoqItemUnitListObj,
	getBoqItemTypeListObj,
} from './helper';
import type { BoqItem } from './type';

const tt = __;
const boqItemListAncestry = ref<BoqItem[]>([]);
const project = ref<string>('');
const detailType = ref<string>('modal');

const docMeta = ref<locals.DocType | null | undefined>();
const recordValue = shallowRef<locals.DocType | null | undefined>();
const loading = ref<boolean>(false);
const gridApi = ref<GridApi>();
const refreshValue = ref<number>(0);

watch(
	[project, refreshValue],
	async () => {
		if (!project.value) {
			boqItemListAncestry.value = [];
			return;
		}
		loading.value = true;
		const [
			codeRuleList,
			boqItemList,
			boqItemStatusObj,
			boqItemUnitObj,
			boqItemTypeObj,
		] = await Promise.all([
			getCodeRuleSetting(project.value),
			getBoqItemList(project.value),
			getBoqItemStatusListObj(),
			getBoqItemUnitListObj(),
			getBoqItemTypeListObj(project.value),
		]);
		const newBoqItemList = boqItemList.map(item => ({
			...item,
			boq_unit: boqItemUnitObj[item.guigu_boq_unit],
			boq_status: boqItemStatusObj[item.guigu_boq_status],
			boq_type: boqItemTypeObj[item.guigu_boq_type],
		}));
		const res = await getWorkListAncestry(newBoqItemList, codeRuleList);
		boqItemListAncestry.value = res;
		loading.value = false;
	},
	{ immediate: true },
);

/**
 * 加载aggridtable api
 */
function onGridReady(params: GridApi) {
	gridApi.value = params;
}

/**
 * @description 点击表格标题字段时给recordValue赋值
 */
function updateRecordValue(value: locals.DocType) {
	recordValue.value = value;
}

/** 初始化 */
async function init(docType: string) {
	frappe.model.with_doctype(docType, () => {
		const meta = frappe.get_meta(docType);
		docMeta.value = meta;
	});
}
onMounted(() => init('Guigu Boq Item'));
</script>
<style scoped>
.title {
	margin-bottom: 0;
}
.agcontainer {
	height: 98%;
}
</style>
