import type { SummaryTableData, Project, Designation, ResultSummaryTableData } from './type';
import moment from 'moment';
export function routeInit(paramsFilter: any) {
	const queryObj = new URLSearchParams(window.location.search);
	// 创建一个对象来存储参数
	let paramsObj = {
		subCompany: queryObj.get('subCompany') || '',
		startDate: queryObj.get('startDate') || '',
		endDate: queryObj.get('endDate') || '',
		designations: queryObj.getAll('designations'),
	};
	paramsFilter.value = paramsObj;
}

export function setRoute(filter: { [key: string]: any }, paramsFilter: any ) {
	const newParamsFilter = { ...paramsFilter.value, ...filter };
	const queryObj = new URLSearchParams();
	for (const [k, v] of Object.entries(newParamsFilter)) {
		if (!v && v !== 0) { continue; }
		if (!Array.isArray(v)) {
			queryObj.append(k, v as any);
			continue;
		}
		for (const val of v) {
			queryObj.append(k, val);
		}
	}
	paramsFilter.value = newParamsFilter;
	history.replaceState(null, '', `?${queryObj.toString()}`);
}

function sortData(recordData: ResultSummaryTableData[], sortArr: string[]) {
	// 使用sort方法对主要数组进行排序
	const data = (recordData || []).sort((a, b) => {
		const indexOfA = (sortArr || []).indexOf(`${a.project}_${a.guigu_project_designation}_${a.record_owner}`);
		const indexOfB = (sortArr || []).indexOf(`${b.project}_${b.guigu_project_designation}_${b.record_owner}`);
		return indexOfA - indexOfB;
	});

	return data;
}

export function dailyRecordDataProcessing(
	data: SummaryTableData[],
	userOrderArr: string[],
	projectList: Project[],
	designationList: Designation[],
	userObj: {[key: string]: string},
	delayed_days: number,
) {
	/** 当前存在的数据 */
	const nameObj:{[key: string]: ResultSummaryTableData} = {};
	const userDataKey: string[] = [];
	for (const item of (data || [])) {
		const days = moment(item.creation).diff(moment(item.record_date), 'days')
		if (nameObj[`${item.project}_${item.guigu_project_designation}_${item.record_owner}`]) {
			nameObj[`${item.project}_${item.guigu_project_designation}_${item.record_owner}`][item.record_date] = {
				type_symbol: item?.type_symbol || '',
				color: item?.color || '',
				record_name: item.record_name,
			};
			nameObj[`${item.project}_${item.guigu_project_designation}_${item.record_owner}`].total! += 1;
			nameObj[`${item.project}_${item.guigu_project_designation}_${item.record_owner}`].normal! += delayed_days == 0 ? 1 : (days <= delayed_days ? 1 : 0);
			nameObj[`${item.project}_${item.guigu_project_designation}_${item.record_owner}`].delayed! += delayed_days == 0 ? 0 : (days > delayed_days ? 1 : 0);
		} else {
			nameObj[`${item.project}_${item.guigu_project_designation}_${item.record_owner}`] = {
				...item,
				total: 1,
				record_owner_name: userObj[item.record_owner],
				[item.record_date]: {
					type_symbol: item?.type_symbol || '',
					color: item?.color || '',
					record_name: item.record_name,
				},
				normal: delayed_days == 0 ? 1 : (days <= delayed_days ? 1 : 0),
				delayed: delayed_days == 0 ? 0 : (days > delayed_days ? 1 : 0),
			};
			userDataKey.push(`${item.project}_${item.guigu_project_designation}_${item.record_owner}`);
		}
	}

	let dataValues = Object.values(nameObj);

	const noUserDataKeys = userOrderArr.filter(item => !userDataKey.includes(item));
	const projectObj:{[key: string]: string} = {};
	const designationObj:{[key: string]: string} = {};
	for (const item of projectList) { projectObj[item.name]=item.project_abbr_name; }
	for (const item of designationList) { designationObj[item.name]=item.job_title; }
	for (const item of noUserDataKeys) {
		const splitItem = item.split('_');
		dataValues.push({
			name: item,
			project_abbr_name: projectObj[splitItem[0]],
			guigu_project_designation: splitItem[1],
			project: splitItem[0],
			record_owner: splitItem[2],
			record_owner_name: userObj[splitItem[2]],
			total: 0,
			job_title:designationObj[splitItem[1]],
		});
	}
	return sortData(dataValues, userOrderArr);
}
