<template>
	<div ref="chartRef" class="chart"></div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, reactive, watch, onUnmounted } from 'vue';

import * as echarts from 'echarts';

interface Props {
	data: Record<string, any>[];
	options: Record<string, any>;
}
const props = defineProps<Props>();
const chartRef = ref(null);
let chart: any = null;

const formatOptions = computed(() => {
	const xAxisF = props.options.xAxis?.fieldname;
	const xLabelOption = props.options.xLabel;
	const yLabelOption = props.options.yLabel;
	const methodOption = props.options.method;
	let propsData = props.data || [];
	if (props.options.xAxis?.fieldtype === 'Date' || props.options.xAxis?.fieldtype === 'Date Time') {
		propsData = propsData.sort((pre, next) => pre[xAxisF] > next[xAxisF] ? 1 : -1);
	}
	const xAxisDataSet: Set<string> = new Set;
	let xAxisData: string[] = [];
	if (methodOption === 'Sum' || methodOption === 'Count') {
		for (const item of propsData) {
			const value = item[`${xAxisF}.title`] || item[xAxisF];
			xAxisDataSet.add(__(value));
		}
		xAxisData = Array.from(xAxisDataSet);
	} else {
		xAxisData = propsData.map(item => __(item[`${xAxisF}.title`] || item[xAxisF]));
	}
	const xAxis = {
		'type': 'category',
		'name': __(xLabelOption || props.options.xAxis?.label || __('X-axis')),
		'data': xAxisData,
	};
	const yAxisArr: { label: string, fieldname: string, fieldtype: string; }[] = props.options.yAxis || [];
	const yLabel = yAxisArr.length === 1 ? yAxisArr[0]?.label : '';
	let yAxisLabel = '';
	switch (methodOption) {
		case 'Sum': yAxisLabel = __('Total'); break;
		case 'Count': yAxisLabel = __('Count'); break;
		default: yAxisLabel = __(yLabelOption || yLabel || __('Value'));
	}
	const yAxis = {
		'type': 'value',
		'name': yAxisLabel,
	};
	const series = yAxisArr.map(yAxisF => {
		let data = [];
		if (methodOption === 'Sum') {
			data = xAxisData.map(x => {
				const filterData = propsData.filter(d => {
					const value = d[`${xAxisF}.title`] || d[xAxisF];
					return value === x;
				});
				return filterData.reduce((pre, next) => pre + (next[yAxisF.fieldname] || 0), 0);
			});
		} else if (methodOption === 'Count') {
			data = xAxisData.map(x => {
				const filterData = propsData.filter(d => {
					const value = __(d[`${xAxisF}.title`] || d[xAxisF]);
					return value === x;
				}).map(item=>{
					const valueY = item[`${yAxisF.fieldname}.title`] || item[yAxisF.fieldname];
					return valueY;
				});
				return Array.from(new Set(filterData)).length;
			});
		} else {
			data = propsData.map(item => item[yAxisF.fieldname]);
		}
		return {
			data,
			'type': 'line',
			name: __(yAxisF.label),
			label: {
				show: true,
				position: 'top',
			},
		};
	});
	const legend = {
		top: 'bottom',
		data: yAxisArr.map(item => __(item.label)),
	};
	if (!props.data || xAxisData.length === 0) { return {}; }
	const formatOptions = {
		title: {
			text: props.options.title,
			left: 'center',
		},
		legend,
		color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
		toolbox: {
			show: true,
			feature: {
				saveAsImage: {},
			},
		},
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'cross',
				link: [{
					yAxisIndex: 'all',
					xAxisIndex: 'all',
				}],
			},
		},
		xAxis,
		yAxis,
		series,
	};
	return formatOptions;
});
watch([formatOptions, ()=>props.options.height], setOption, { deep: true });
function setOption() {
	chart?.setOption(formatOptions.value, true);
	if (chartRef.value){
		chartRef.value.style.height = `${props.options.height||440}px`;
	}
	chart.resize({
		height: props.options.height||440,
	});
}

onMounted(() => {
	chart = echarts.init(chartRef.value, 'light', {
		renderer: 'canvas',
	});
	window.addEventListener('resize', chart.resize);
	setOption();
});
onUnmounted(() => {
	window.removeEventListener('resize', chart?.resize);
});
</script>
<style scoped>
.chart {
	height: 20rem;
	border: 1px solid #e2e8f0;
	border-radius: 0.25rem;
}
</style>
