import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({
		borderRadius: '12px',
		backgroundColor: $setup.bgColor,
		padding: '0 10px',
		display: 'flex',
		alignItems: 'center',
		width: '75px',
		height:'20px'
	})
  }, [
    _createElementVNode("span", {
      style: _normalizeStyle({
			width: '8px',
			height: '8px',
			borderRadius: '50%',
			backgroundColor: $setup.color,
			marginRight: '5px'
		})
    }, null, 4 /* STYLE */),
    _createElementVNode("span", {
      style: _normalizeStyle({color: $setup.color,fontSize:'12px'})
    }, _toDisplayString($props.params.data?.status?.status_name), 5 /* TEXT, STYLE */)
  ], 4 /* STYLE */))
}