<template>
	<ElSelect v-model="val" :teleported="false">
		<ElOption
			v-for="option in options	"
			:key="option.value"
			:label="option.label"
			:value="option.value" />
	</ElSelect>
</template>
<script lang="ts" setup>
import { computed, onUnmounted, watch } from 'vue';
import { ElSelect, ElOption } from 'element-plus';

import type { Option } from './options';


const props = defineProps<{
	options: Option[];
	modelValue?: any
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: any): void;
}>();
const values = computed(() => props.options.map(v => v.value));
const value = computed(() => props.modelValue);

function getvalue(values: any[], value: any) {
	if (values.includes(value)) {
		return value;
	}
	return values[0];
}

const val = computed({
	get: () => getvalue(values.value, value.value),
	set: v => emit('update:modelValue', v),
});

watch([values, value], ([values, value]) => {
	const newValue = getvalue(values, value);
	if (value !== newValue) {
		emit('update:modelValue', newValue);
	}
}, { immediate: true });

</script>
