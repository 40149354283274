import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElFormItem"], null, {
    default: _withCtx(() => [
      _createVNode($setup["ElTable"], {
        data: $setup.fields,
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode($setup["ElTableColumn"], {
            type: "index",
            index: 1
          }),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Field'),
            width: "120px"
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElSelect"], {
                modelValue: row.field,
                filterable: "",
                defaultFirstOption: "",
                "onUpdate:modelValue": $event => ($setup.set($index, 'field', $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fieldsSelectable, (field) => {
                    return (_openBlock(), _createBlock($setup["ElOption"], {
                      key: field.value,
                      value: field.value,
                      label: field.label
                    }, null, 8 /* PROPS */, ["value", "label"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Label')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElInput"], {
                modelValue: row.label,
                "onUpdate:modelValue": $event => ($setup.set($index, 'label', $event))
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          ($setup.showWidth)
            ? (_openBlock(), _createBlock($setup["ElTableColumn"], {
                key: 0,
                label: $setup.tt('Width')
              }, {
                default: _withCtx(({ row, $index }) => [
                  _createVNode($setup["ElInput"], {
                    type: "number",
                    modelValue: row.width,
                    "onUpdate:modelValue": $event => ($setup.setWidth($index, 'width', $event || 0))
                  }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]))
            : _createCommentVNode("v-if", true),
          ($setup.showWidth)
            ? (_openBlock(), _createBlock($setup["ElTableColumn"], {
                key: 1,
                label: $setup.tt('Min Width')
              }, {
                default: _withCtx(({ row, $index }) => [
                  _createVNode($setup["ElInput"], {
                    type: "number",
                    modelValue: row.min_width,
                    "onUpdate:modelValue": $event => ($setup.setWidth($index, 'min_width', $event || 0))
                  }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]))
            : _createCommentVNode("v-if", true),
          ($setup.showWidth)
            ? (_openBlock(), _createBlock($setup["ElTableColumn"], {
                key: 2,
                label: $setup.tt('Max Width')
              }, {
                default: _withCtx(({ row, $index }) => [
                  _createVNode($setup["ElInput"], {
                    type: "number",
                    modelValue: row.max_width,
                    "onUpdate:modelValue": $event => ($setup.setWidth($index, 'max_width', $event || 0))
                  }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]))
            : _createCommentVNode("v-if", true),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Alignment Method'),
            width: "90px"
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElSelect"], {
                modelValue: row.alignment,
                filterable: "",
                defaultFirstOption: "",
                "onUpdate:modelValue": $event => ($setup.set($index, 'alignment', $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.alignOption, (field) => {
                    return _createVNode($setup["ElOption"], {
                      key: field.value,
                      value: field.value,
                      label: field.label
                    }, null, 8 /* PROPS */, ["value", "label"])
                  }), 64 /* STABLE_FRAGMENT */))
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Show Tag'),
            width: "90px"
          }, {
            default: _withCtx(({ row, $index }) => [
              ($setup.canShowTag(row.field))
                ? (_openBlock(), _createBlock($setup["ElCheckbox"], {
                    key: 0,
                    modelValue: Boolean(row.show_tag),
                    "onUpdate:modelValue": $event => ($setup.set($index, 'show_tag', $event ? 1 : 0))
                  }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          ($setup.group)
            ? (_openBlock(), _createBlock($setup["ElTableColumn"], {
                key: 3,
                label: $setup.tt('Grouped')
              }, {
                header: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.tt('Grouped')) + " ", 1 /* TEXT */),
                  ($setup.groupTip)
                    ? (_openBlock(), _createBlock($setup["ElIcon"], {
                        key: 0,
                        title: $setup.groupTip
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["InfoFilled"])
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["title"]))
                    : _createCommentVNode("v-if", true)
                ]),
                default: _withCtx(({ row, $index }) => [
                  ($setup.groupable(row.field))
                    ? (_openBlock(), _createBlock($setup["ElCheckbox"], {
                        key: 0,
                        modelValue: Boolean(row.group),
                        "onUpdate:modelValue": $event => ($setup.set($index, 'group', $event ? 1 : 0))
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]))
            : _createCommentVNode("v-if", true),
          ($setup.enableLink)
            ? (_openBlock(), _createBlock($setup["ElTableColumn"], {
                key: 4,
                label: $setup.tt('Enable Link')
              }, {
                default: _withCtx(({ row, $index }) => [
                  ($setup.isLink(row.field))
                    ? (_openBlock(), _createBlock($setup["ElCheckbox"], {
                        key: 0,
                        modelValue: Boolean(row.enable_link),
                        "onUpdate:modelValue": $event => ($setup.set($index, 'enable_link', $event ? 1 : 0))
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]))
            : _createCommentVNode("v-if", true),
          ($setup.showLabel)
            ? (_openBlock(), _createBlock($setup["ElTableColumn"], {
                key: 5,
                label: $setup.tt('Show Label')
              }, {
                default: _withCtx(({ row, $index }) => [
                  _createVNode($setup["ElCheckbox"], {
                    modelValue: Boolean(row.show_label),
                    "onUpdate:modelValue": $event => ($setup.set($index, 'show_label', $event ? 1 : 0))
                  }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]))
            : _createCommentVNode("v-if", true),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Format')
          }, {
            default: _withCtx(({ row, $index }) => [
              ($setup.hasFormat(row.field))
                ? (_openBlock(), _createBlock($setup["ElInput"], {
                    key: 0,
                    modelValue: row.format,
                    "onUpdate:modelValue": $event => ($setup.set($index, 'format', $event || ''))
                  }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Operation')
          }, {
            default: _withCtx(({ $index }) => [
              _createVNode($setup["ElButtonGroup"], null, {
                default: _withCtx(() => [
                  _createVNode($setup["ElButton"], {
                    onClick: $event => ($setup.moveTo($index)),
                    text: "",
                    icon: $setup.Sort,
                    title: $setup.tt('Move')
                  }, null, 8 /* PROPS */, ["onClick", "icon", "title"]),
                  _createVNode($setup["ElButton"], {
                    type: "danger",
                    onClick: $event => ($setup.remove($index)),
                    text: "",
                    icon: $setup.Delete,
                    title: $setup.tt('Delete')
                  }, null, 8 /* PROPS */, ["onClick", "icon", "title"])
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["data"]),
      _createVNode($setup["ElButton"], { onClick: $setup.add }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.tt('Add')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}