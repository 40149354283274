frappe.ui.form.Control.prototype.validate_and_set_in_model = function(value, e, force_set_value = false){
	const me = this;
	const is_value_same = this.get_model_value() === value;
	// 修改frappe代码，如果没有this.doc 就去赋值
	if (this.inside_change_event || (is_value_same && !force_set_value&&this.doc)) {
		return Promise.resolve();
	}

	const old_value = this.get_model_value();
	this.frm?.undo_manager?.record_change({
		fieldname: me.df.fieldname,
		old_value,
		new_value: value,
		doctype: this.doctype,
		docname: this.docname,
		is_child: Boolean(this.doc?.parenttype),
	});
	this.inside_change_event = true;
	function set(value) {
		me.inside_change_event = false;
		return frappe.run_serially([
			() => (me._validated = true),
			() => me.set_model_value(value),
			() => delete me._validated,
			() => {
				me.set_mandatory && me.set_mandatory(value);

				if (me.df.change || me.df.onchange) {
					// onchange event specified in df
					let set = (me.df.change || me.df.onchange).apply(me, [e]);
					me.set_invalid && me.set_invalid();
					return set;
				}
				me.set_invalid && me.set_invalid();
			},
		]);
	}
	value = this.validate(value);
	if (value && value.then) {
		// got a promise
		return value.then(value => set(value));
	}
	// all clear
	return set(value);

};
