import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-884f9e7b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ hidden: $props.hidden }, 'sider']),
    style: _normalizeStyle({
		'--mainView-sider': `max(150px, min(${$props.size}, 50%))`,
		'--mainView-sider2': `max(150px, min(${$props.size}, 90%))`,
	}),
    onClick: _withModifiers($setup.hide, ["self"])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['context', $props.siderClass])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2 /* CLASS */),
    _createElementVNode("div", {
      class: "resize",
      onPointerdown: _cache[0] || (_cache[0] = (...args) => ($setup.siderBegin && $setup.siderBegin(...args))),
      onPointermove: _cache[1] || (_cache[1] = (...args) => ($setup.siderMove && $setup.siderMove(...args))),
      onPointerup: _cache[2] || (_cache[2] = (...args) => ($setup.siderEnd && $setup.siderEnd(...args))),
      onPointercancel: _cache[3] || (_cache[3] = (...args) => ($setup.siderEnd && $setup.siderEnd(...args))),
      onTouchmove: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
    }, null, 32 /* HYDRATE_EVENTS */)
  ], 14 /* CLASS, STYLE, PROPS */, _hoisted_1))
}