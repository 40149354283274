<template>
	<ElPopover
		placement="bottom"
		:visible="visible"
		showArrow
		trigger="click"
	>
		<p>{{ tt("Are you sure you want to delete it?") }}</p>
		<div style="display: flex;">
			<ElButton size="small" @click="visible = false">
				{{ tt("Cancel") }}
			</ElButton>
			<ElButton size="small" type="primary" @click="props.params.removeTableData(props.params.data.name)">
				{{ tt("Sure") }}
			</ElButton>
		</div>
		<template #reference>
			<ElButton
				type="danger"
				size="small"
				@click="visible = true"

			>
				{{ tt('Remove') }}
			</ElButton>
		</template>
	</ElPopover>

</template>

<script setup lang="ts">
import {ref} from 'vue';
import {ElPopover, ElButton} from 'element-plus';
import type {ICellRendererParams} from 'ag-grid-community';
const tt = __;

const props = defineProps<{
	params: ICellRendererParams & {
		removeTableData: (name: string) => void;
	}
}>();
const visible = ref<boolean>(false);
</script>

<style lang="less" scoped></style>
