import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElButtonGroup"], { disabled: $props.loading }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.menuItems, (c) => {
        return (_openBlock(), _createBlock($setup["ElButton"], {
          key: c.key,
          onClick: $event => ($setup.command(c)),
          title: $setup.tt(c.label)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt(c.label)), 1 /* TEXT */)
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick", "title"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["disabled"]))
}