import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"100%","display":"flex","align-items":"center","justify-content":"space-between"} }
const _hoisted_2 = {
  key: 0,
  class: "core_circle"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      (!$setup.isWorkList)
        ? (_openBlock(), _createBlock($setup["Icon"], {
            key: 0,
            icon: $setup.icon
          }, null, 8 /* PROPS */, ["icon"]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("span", {
        style: _normalizeStyle({cursor: $setup.isWorkList ?'pointer':'',textDecoration: $setup.isWorkList ? 'underline' : ''}),
        onClick: $setup.clickLabel
      }, _toDisplayString($setup.fullName), 5 /* TEXT, STYLE */)
    ]),
    ($setup.isCore)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2))
      : _createCommentVNode("v-if", true)
  ]))
}