import { getFieldDisplayed } from '../../utils/FieldDisplayed';
import { parseDisplayField } from '../utils';

import getQuickFilterFields from './getQuickFilterFields';
import { getFilterField } from './filterField';
import parseArray from './parseArray';


function getMaxLimit(maxLimit?: number) {
	if (typeof maxLimit !== 'number') { return Infinity; }
	const n = Math.floor(maxLimit);
	if (!Number.isSafeInteger(n)) { return Infinity; }
	if (n <= 0) { return Infinity; }
	return Math.max(n, 5);
}

function getDefaultLimit(max: number, setting: GlobalView, defaultLimit?: number) {
	for (const m of [defaultLimit, setting.defaultLimit]) {
		if (typeof m !== 'number') { continue; }
		const n = Math.floor(m);
		if (!Number.isSafeInteger(n)) { continue; }
		if (n <= 0) { return max === Infinity ? 0 : Math.min(100, max); }
		return Math.max(0, Math.min(n, max));
	}
	return Math.min(100, max);
}

function getInfiniteScroll(setting: GlobalView, infiniteScroll?: boolean | string) {
	for (const v of [infiniteScroll, setting.infiniteScroll]) {
		if (typeof v === 'boolean') { return v; }
		if (v === 'Yes') { return true; }
		if (v === 'No') { return false; }
	}
	return false;
}

function getView({
	max_limit,
	default_limit,
	infinite_scroll,
	link_field,
	fields_copied_when_creating_children,
	fields_copied_while_copying,
	fields_requested,
	fields,
	download_folder_fields,
	order_default,
	filters_default,
	or_filters_default,
	filters_must,
	name,
	query_filters,
	buttons,
	fields_displayed,
	is_hide_add_btn,
	is_hide_sider,
	...data
}: {
	max_limit?: number;
	default_limit?: number;
	infinite_scroll?: string;
	link_field?: string;
	fields_copied_when_creating_children?: string;
	fields_copied_while_copying?: string;
	fields_requested?: string;
	fields?: string;
	fields_displayed?: any[];
	download_folder_fields?:string
	is_hide_add_btn?:boolean,
	is_hide_sider?:boolean,
	[k: string]: any;
}, meta: locals.DocType, setting: GlobalView): GlobalView.View {
	const nonpageable = Boolean(setting.nonpageable);
	const scrollOnly = Boolean(setting.scrollOnly);

	const maxLimit = Math.min(...[setting.maxLimit, max_limit].map(getMaxLimit));
	const defaultLimit = getDefaultLimit(maxLimit, setting, default_limit);
	const infiniteScroll = scrollOnly ? true : getInfiniteScroll(setting, infinite_scroll);
	const quickFilterMap = new Map(getQuickFilterFields(meta).map(v => [v.fieldname, v]));
	const quickFilters: GlobalView.QuickFilter[] = [];
	for (const s of (query_filters || '').split('\n')) {
		const q = quickFilterMap.get(s);
		if (!q) { continue; }
		quickFilters.push(q);
		quickFilterMap.delete(s);
	}
	const view: GlobalView.View = {
		quickFilters,
		name,
		nonpageable,
		scrollOnly,
		maxLimit,
		defaultLimit,
		infiniteScroll,
		defaultOrder: parseArray(order_default)
			.map(([doctype, field, desc = false]) => ({ doctype, field, desc })),
		filtersDefault: parseArray(filters_default),
		orFiltersDefault: parseArray(or_filters_default),
		filtersMust: parseArray(filters_must),
		fieldsCopiedWhenCreatingChildren:
			(fields_copied_when_creating_children || '').split('\n').filter(Boolean),
		fieldsCopiedWhileCopying: (fields_copied_while_copying || '').split('\n').filter(Boolean),
		fieldsRequested: (fields_requested || '').split('\n').filter(Boolean),
		fields: [],
		downloadFolderFields:[],
		buttons: buttons || [],

	};

	if (link_field) { view.linkField = link_field; }
	const { displayField, filterField } = setting.configuration || {};

	if (filterField) { view.filterField = getFilterField(data, meta); }

	if (displayField) {
		const list = (fields_displayed?.length ?
			fields_displayed.map(v => ({
				...v,
				width: v.width || 200,
				group: Boolean(v.group),
				enableLink: Boolean(v.enable_link),
				showLabel: Boolean(v.show_label),
				minWidth: v.min_width || undefined,
				maxWidth: v.max_width || undefined,
				enable_link: Boolean(v.enable_link),
				show_label: Boolean(v.show_label),
				min_width: v.min_width || undefined,
				max_width: v.max_width || undefined,
			})) : parseDisplayField(fields, true)).filter(v => v.field);
		view.fields = getFieldDisplayed(list, meta, displayField);
		view.titleField = list.find(v => v.field === '__Title__');
	}
	view.downloadFolderFields = (typeof download_folder_fields === 'string' ? download_folder_fields : '')
		.split('\n').map(v => v.split(/\s*[,，]\s*/)[0]).filter(Boolean);
	view.isHideAddBtn = Boolean(is_hide_add_btn);
	view.isHideSider = Boolean(is_hide_sider);
	return view;
}
export default async function loadView(
	viewSetting: GlobalView,
	meta: locals.DocType,
	name?: string,
): Promise<[viewData: any, data: any, view: GlobalView.View, configuration: any]> {
	const { view = { name }, configuration = {} } = name ? await frappe
		.call('guigu.view.get_view', { name })
		.then((v: any) => v.message || {}) : {};
	const { getConfigurations } = viewSetting;
	const viewConfiguration = getView(view, meta, viewSetting);
	return [
		view,
		configuration,
		viewConfiguration,
		await getConfigurations?.(meta, configuration, viewConfiguration) || configuration,
	];
}
