import G6, { Graph, TreeGraph, GraphOptions, IG6GraphEvent, INode, Item, ToolBar, IGroup, Menu } from '@antv/g6';

import './Node';
import {merge} from 'lodash';

import { createToolBar } from './ToolBar';
import { cardWidth, defaultLayout, headerHeight, lineHeight } from './deaultOptions';
import { registerDetailCard } from './Node/detailCard';
import { createMenu } from './Menu';
function mergeMode(target:Record<string, any[]|undefined>, source:Record<string, any[]|undefined>){
	Object.entries(source).forEach(([key, value])=>{
		if (Object.prototype.hasOwnProperty.call(target, key)){
			source[key]?.forEach(each=>{
				const sourceType=typeof each === 'string'?each:each.type;
				if (!target[key]){
					target[key] = [];
					target[key]!.push(source[key]);
				} else {
					const index = target[key]!.findIndex(p=>{
						const type = typeof p === 'string'?p:p.type;
						return type === sourceType;
					});
					if (index>-1){
						target[key]?.splice(index, 1, source[key]);
					} else {
						target[key]?.push(source[key]);
					}
				}
			});
		} else {
			target[key] = value;
		}
	});
}

export class TreeGraphControl{
	graph: TreeGraph;
	minDisNode: INode|undefined;
	customDragEnd: ((dragNode:Item, overNode:INode) => Promise<{isSuccess:boolean, newData?:any}>)|undefined;
	toolbar: any;
	contextMenu: any;
	constructor(options:GraphOptions&{
		onEdit:(item:Item)=>void,
		onRemove:(item:Item)=>void,
		onRemoveConnection:(item:Item)=>void,
		onAddChildren:(item:Item)=>void
		onExpandLevel:(level?:string)=>void
	}){
		this.createToolBar(options.onExpandLevel);
		this.createMenu(options);
		this.graph = this.getTreeGraph(options);
		registerDetailCard(this.graph);
		this.graph.on('afteranimate', () => {
			this.graph.fitCenter();
		});
		this.registerDrag();
	}
	createMenu(options:GraphOptions&{
		onEdit:(item:Item)=>void,
		onRemove:(item:Item)=>void,
		onRemoveConnection:(item:Item)=>void
		onAddChildren:(item:Item)=>void
	}){
		this.contextMenu = createMenu(options.onEdit, options.onRemove, options.onRemoveConnection, options.onAddChildren);
	}
	createToolBar(onExpandLevel:(level?:string)=>void){
		const toolbar = createToolBar(onExpandLevel);
		this.toolbar = toolbar;
	}
	getTreeGraph(options:GraphOptions):TreeGraph{
		const defaultOPtions={
			modes: {
				default: [
					{
						type: 'collapse-expand',
						shouldBegin(e, self){
							const name = e.target.get('name');
							return  name=== 'count'||name==='count-container';
						},
					},
					'drag-canvas',
					'zoom-canvas',
					'drag-node',
					{
						type:'click-select',
						multiple:false,
						shouldBegin(e, self){
							const name = e.target.get('name');
							return  name!== 'count'&&name!=='count-container';
						},
					},
				],
			},
			defaultNode:{
				size: [cardWidth, 26],
				style: {
					fill: '#C6E5FF',
					stroke: '#5B8FF9',
				  },
			},
			plugins: [this.toolbar, this.contextMenu],
			enabledStack:false,
			nodeStateStyles: {
				closest: {
					header:{
						fill: '#f00',
						stroke: '#f00',
					},
				},
				selected:{
					header:{
						fill: '#409eff',
						stroke: '#409eff',
					},
				},
			  },
			defaultEdge: {
				type: 'polyline',
				style: {
				  stroke: '#A3B1BF',
				  endArrow: {
					path:G6.Arrow.triangle(),
					fill:'#A3B1BF',
				  },
				  lineWidth:2,
				  radius:8,
				},
			},
			fitCenter:true,
			fitView:true,
			layout: {
				direction: 'TB',
				...defaultLayout,
			},
		};
		mergeMode(defaultOPtions.modes, options.modes||{});
		delete options.modes;
		const mergedOptions = merge(defaultOPtions, options);
		const graph =  new G6.TreeGraph(mergedOptions);

		return graph;
	}
	registerDrag(){
		this.graph.on('node:dragstart', (e:IG6GraphEvent)=>this.dragStart(e));
		this.graph.on('node:drag', (e:IG6GraphEvent)=>this.drag(e));
		this.graph.on('node:dragend', (e:IG6GraphEvent)=>this.dragEnd(e));
	}
	dragStart(e:IG6GraphEvent){
		this.minDisNode = undefined;
	}
	drag(e:IG6GraphEvent){
		// this.minDisNode = undefined;
		const {item} = e;
		const model = item?.getModel();
		const nodes = this.graph.getNodes();
		const modelHeight = Object.entries(model?.data||{}).length*lineHeight+headerHeight;
		let minDis = Infinity;
		let thisMinDisNode:INode|undefined = undefined;
		nodes.forEach(inode => {
		  const node = inode.getModel();
		  if (!node||!model||node.id === model?.id) { return; }
		  const dis = (node.x! - e.x) * (node.x! - e.x) + (node.y! - e.y) * (node.y! - e.y);
		  const heigh = Object.entries(node.data||{}).length*lineHeight+headerHeight;
		  const f = !(node.y!+heigh<model.y!||node.y!>model.y!+modelHeight||node.x!>model.x!+cardWidth||node.x!+cardWidth<model.x!);
		  if (dis < minDis&&f) {
			minDis = dis;
			thisMinDisNode = inode;
		  }
		});
		if (thisMinDisNode&&model){
			const data = this.graph.findDataById(model.id);
			let isDescent = false;
			const minDisNodeId = thisMinDisNode.getID();
			G6.Util.traverseTree(data, (d:any) => {
			  if (d.id === minDisNodeId) { isDescent = true; }
			});
			if (isDescent){
				thisMinDisNode=undefined;
			}
		}

		if (!thisMinDisNode){
			if (this.minDisNode){
				this.graph.setItemState(this.minDisNode!, 'closest', false);
			}
			this.minDisNode = undefined;
		} else {
			if (this.minDisNode&&this.minDisNode?.getID()!==thisMinDisNode?.getID()){
				this.graph.setItemState(this.minDisNode!, 'closest', false);
			}
			this.minDisNode = thisMinDisNode;
			this.graph.setItemState(thisMinDisNode!, 'closest', true);
		}
	}
	async dragEnd(e:IG6GraphEvent){
		if (!this.minDisNode) {
			return;
		  }
		  const {item} = e;
		  if (!item){ return; }
		  const id = item?.getID();
		  const data = this.graph.findDataById(id);
		  // if the minDisNode is a descent of the dragged node, return
		  let isDescent = false;
		  const minDisNodeId = this.minDisNode.getID();

		  G6.Util.traverseTree(data, (d:any) => {
			if (d.id === minDisNodeId) { isDescent = true; }
		  });
		  if (isDescent) {
			// descriptionDiv.innerHTML = 'Failed. The target node is a descendant of the dragged node.';
			return;
		  }
		  if (typeof this.customDragEnd === 'function'){
			const {isSuccess, newData} = await this.customDragEnd(item, this.minDisNode);
			if (!isSuccess){ return; }
			this.graph.changeData(newData);
			this.graph.hideItem('graph_root');
		  }
	}
	onDragEnd(fn:(dragNode:Item, overNode:INode)=>Promise<{isSuccess:boolean, newData?:any}>){
		this.customDragEnd = fn;
	}
}
