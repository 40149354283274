<template>
	<div class="toolbar">
		<ElSpace>
			<ProjectCom
				default
				noLabel
				v-model="projectValue"
			></ProjectCom>
		</ElSpace>
		<div class="refresh">
			<el-button-group class="ml-4">
				<el-button @click="() => expandAll(true)">{{ tt('Expand') }}</el-button>
				<el-button @click="() => expandAll(false)">{{ tt('Collapse') }}</el-button>
				<el-button :loading="loading" @click="refresh" :title="tt('Refresh')"
					:icon="RefreshRight">
					{{ tt('Refresh') }}
				</el-button>
			</el-button-group>
		</div>

	</div>
</template>
<script lang="ts" setup>

import { ref, onMounted, defineEmits, defineProps, toRefs, computed } from 'vue';
import { RefreshRight } from '@element-plus/icons-vue';
import { GridApi } from 'ag-grid-community';

import { Project } from '../../type';
import ProjectCom from '../../../../../../../../guigu/guigu/public/js/components/PageComponents/Tools/Project.vue';

import { routeInit, setRoute } from './helper';

const tt = __;
interface Props {
	project?: string
	refreshValue?: number
	detailGridApi?: GridApi
	loading?: boolean
}

const props = defineProps<Props>();
const { project, refreshValue, detailGridApi, loading } = toRefs(props);

interface Emit {
	(e: 'update:project', project: string): void,
	(e: 'update:loading', loading: boolean): void,
	(e: 'update:refreshValue', refreshValue: number): void,
}
const emit = defineEmits<Emit>();

const projects = ref<Project[]>();

const paramsFilter = ref<{ [key: string]: string }>({});


onMounted(async () => {
	routeInit(paramsFilter);
	const projectsRes: Project[] = await frappe.db.get_list('Project',
		{
			fields: ['name', 'project_name', 'project_abbr_name'],
			filters: { status: 'Open' },
			limit: '',
		},
	);
	projects.value = projectsRes;
	const projectNames = projectsRes.map(item => item.name);
	const sessionDefaultProject: string = (frappe.defaults.get_user_defaults('project') || [])[0];
	let defaultProject: string = '';
	if (projectNames.includes(paramsFilter.value?.project)) {
		defaultProject = paramsFilter.value?.project;
	} else {
		defaultProject = sessionDefaultProject || projectsRes[0]?.name || '';
	}

	let routeObj: { [key: string]: any } = {};
	routeObj = { project: defaultProject };
	emit('update:project', defaultProject);
	setRoute(paramsFilter, routeObj);
});


function expandAll(expand: boolean) {
	if (expand) {
		detailGridApi?.value?.expandAll();
	} else {
		detailGridApi?.value?.collapseAll();
	}
}

const projectValue = computed({
	get: () => paramsFilter.value?.project || '',
	set: (v: string) => {
		setRoute(paramsFilter, { project: v });
		emit('update:project', v);
	},
});

function refresh() {
	const newValue: number = (refreshValue?.value || 0) + 1;
	emit('update:refreshValue', newValue);
}
</script>
<style lang="less" scoped>
.toolbar {
  padding: 8px 0;
}

.refresh {
  float: right
}
</style>
