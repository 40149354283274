import type { ViewInfo } from '../types';

const dateRegex = /^(\d+)-(0?[1-9]|1[0-2])-(0?[1-9]|[12]\d|3[01])(\s|T|$)/;
function parseDate(value?: string): Date | undefined {
	if (!value || typeof value !== 'string') { return; }
	const result = dateRegex.exec(value);
	if (!result) { return; }
	const [, Y, M, D] = result;
	const date = new Date(Number(Y), Number(M) - 1, Number(D));

	return date;
}
function toDateStr(date: Date) {
	const Y = date.getFullYear();
	const M = date.getMonth() + 1;
	const D = date.getDate();
	return [Y, M, D].map(v => `${v}`.padStart(2, '0')).join('-');
}
const dayMs = 1000 * 60 * 60 * 24;
const dateTrans = new Map<string, (s: Date) => Date>([
	['year', s => {
		const d = new Date(s);
		d.setMonth(0, 1);
		return d;
	}],
	['month', s => {
		const d = new Date(s);
		d.setDate(1);
		return d;
	}],
	['week', s => new Date(Number(s) - ((s.getDay() + 6) % 7) * dayMs)],
]);
export default function pretreatDate(
	list: any[],
	meta: locals.DocType,
	view: ViewInfo,
) {
	const fields = new Set(view.fields.map(v => v.fieldOptions.fieldname));
	if (!fields.size) { return; }
	for (const field of meta.fields) {
		if (field.fieldtype !== 'Date') { continue; }
		const name = field.fieldname;
		for (const [f, t] of dateTrans) {
			const k = `${name}:${f}`;
			if (!fields.has(k)) { continue; }
			for (const item of list) {
				const d = parseDate(item[name]);
				if (!d) { continue; }
				item[k] = toDateStr(t(d));
			}
		}
	}

}
