import { createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Line Height')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElInputNumber"], {
          modelValue: $setup.lineHeight,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.lineHeight) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Text Color')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElColorPicker"], {
          modelValue: $setup.textColor,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.textColor) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Background Color')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElColorPicker"], {
          modelValue: $setup.bgColor,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.bgColor) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Even Line Background Color')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElColorPicker"], {
          modelValue: $setup.oddBgColor,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.oddBgColor) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Horizontal Table Line Color')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElColorPicker"], {
          modelValue: $setup.rowLineColor,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.rowLineColor) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Vertical Table Line Color')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElColorPicker"], {
          modelValue: $setup.colLineColor,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.colLineColor) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"])
  ], 64 /* STABLE_FRAGMENT */))
}