<template>
	<div ref="root" class="root" />
</template>
<script setup lang="ts">
import {computed, shallowRef, watch} from 'vue';

import MultiOrFilters from '../../utils/MultiOrFilters';

const props = defineProps<{
	doctype: string;
	modelValue?: [string, string, string, any][][];
}>();

const emit = defineEmits<{
	(event: 'update:modelValue', value: [string, string, string, any][][]): void;
}>();
const root = shallowRef();
const modelValue = computed({
	get: () => props.modelValue || [],
	set: v => emit('update:modelValue', Array.isArray(v) ? v : []),
});
let editor: any;
watch([() => props.doctype, root], ([dt, el]) => {
	if (editor) {
		// TODO:
	}
	if (!el) {
		return;
	}
	el.innerHTML = '';
	if (!dt) {
		return;
	}
	const current = new MultiOrFilters(el, dt, () => {
		if (!editor) {
			return;
		}
		modelValue.value = editor.value;
	});
	editor = current;
	frappe.model.with_doctype(dt, () => {
		if (!editor) {
			return;
		}
		editor.value = modelValue.value;
	});
}, {immediate: true});

</script>
<style scoped lang="less">
.root {
	width: 100%;

	:deep(.filter-area .filter-box .awesomplete ul[role=listbox] li) {
		max-width: unset;
	}
}
</style>
