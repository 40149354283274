import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eafc004d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "table"
}
const _hoisted_2 = { ref: "set" }
const _hoisted_3 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.dataList.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, null, 512 /* NEED_PATCH */)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createVNode($setup["ElEmpty"], {
          description: $setup.tt('No Records Created'),
          imageSize: 200
        }, null, 8 /* PROPS */, ["description"])
      ]))
}