<template>
  <div v-show="showImage">
    <Upload
      :structureDescendants="structureDescendants"
      :date="date"
      :showDateInput="false"
      @save="save"></Upload>
    <div v-viewer>
      <PhotoItem
        v-for="item in photosFormat"
        :key="item.id"
        :photo="item"
        :structureDescendants="structureDescendants"
        @deletePhoto="deletePhoto"
        @changePhoto="changePhoto"
        @showPhotoInfo="showPhotoInfo"></PhotoItem>
    </div>
    <ImageInfoDialog
      :visible="infoVisible"
      :photo="photoInfo"
      :structureDescendants="structureDescendants"
      @close="closeInfo"
      @confirm="changePhoto"
      @delete="deletePhoto"></ImageInfoDialog>
  </div>
  <div v-show="!showImage">
    <ul>
      <li v-if="!project">{{ tt('Please Select') }}<span class="required-field">{{ tt('Project') }}</span></li>
      <li v-if="!structureName">{{ tt('Please Select') }}<span class="required-field">{{ tt('Construction Structure') }}</span></li>
      <li v-if="!date">{{ tt('Please Select') }}<span class="required-field">{{ tt('Date') }}</span></li>
      {{ tt('to upload images') }}
    </ul>
  </div>
</template>
<script lang="ts" setup>
import { ref, getCurrentInstance, watchEffect, computed } from 'vue';
import { getTreeAncestry } from 'guigu_pm/public/js/utils';

import { StructureItemFormat, StructureItem } from '../../type/diary';
import { ImageFile, ImageFormValue, OriginImage, Photo, PhotoFormat } from '../../type/image';

import { getPhotoFormat, toFormData } from './helper';
import PhotoItem from './PhotoItem.vue';
import Upload from './Upload.vue';
import ImageInfoDialog from './ImageInfoDialog/index.vue';
const tt = __
const { appContext } = getCurrentInstance() || {};
const structureName = ref<string>(appContext?.config.globalProperties.$frm?.doc?.guigu_construction_structure);
const images = ref<Photo[]>(appContext?.config.globalProperties.$frm?.doc?.structure_image || []);
const structureDescendants = ref<StructureItemFormat[]>([]);
const photosFormat = computed(() => getPhotoFormat(images.value, structureDescendants.value));
const date = ref<string>(appContext?.config.globalProperties.$frm?.doc?.date);
const project = ref<string>(appContext?.config.globalProperties.$frm?.doc?.project);
const infoVisible = ref<Boolean>(false);
const photoInfo = ref<PhotoFormat>();
const team = ref<string>(appContext?.config.globalProperties.$frm?.doc?.guigu_team);
const showImage = computed(() => Boolean(date.value && structureName.value && project.value));
frappe.ui.form.on('Guigu Construction Diary', {
  setup(frm: any) {
    structureName.value = frm.doc.guigu_construction_structure;
    date.value = frm.doc.date;
  },
  refresh(frm: any) {
    structureName.value = frm.doc.guigu_construction_structure;
    date.value = frm.doc.date;
    team.value = frm.doc.guigu_team;
    images.value = structuredClone(frm.doc.structure_image || []);
  },
  structure_image(frm: any) {
    images.value = frm.doc.structure_image || [];
  },
  guigu_construction_structure(frm: any) {
    structureName.value = frm.doc.guigu_construction_structure;
  },
  date(frm: any) {
    date.value = frm.doc.date;
  },
  guigu_team(frm: any) {
    team.value = frm.doc.guigu_team;
  },
  project(frm: any) {
    project.value = frm.doc.project;
  },
  after_save(frm: any) {
    images.value = structuredClone(frm.doc.structure_image || []);
  },
});
watchEffect(async () => {
  if (!structureName.value || !team.value) { return; }
  const data = await frappe.call<{ message: StructureItem[] }>({
    method: 'get_structure_descendants',
    doc: appContext?.config.globalProperties.$frm.doc,
    args: {
      structure: structureName.value,
      team: team.value,
      date: date.value,
    },
  });
  const descendants: StructureItemFormat[] = [];
  data?.message.forEach(item => {
    descendants.push({
      code: item.code,
      left: item.left,
      structureId: item.name,
      parent: item.parent ? `structure_${item.parent}` : '',
      right: item.right,
      name: item.structure_name,
      id: `structure_${item.name}`,
    });
    item.progress_item?.forEach(each => {
      descendants.push({
        position: each.position,
        structureProgressId: each.name,
        progressItemId: each.progress_item,
        name: each.progress_item_name,
        parent: `structure_${item.name}`,
        designQuantity: each.design_quantity,
        id: `structure_progress_${each.name}`,
        unit: each.unit,
      });
    });
  });
  const ancestryList = getTreeAncestry<StructureItemFormat>(descendants);
  structureDescendants.value = ancestryList || [];
});
async function save(photoList: (ImageFormValue & { file?: ImageFile })[]) {
  const frm = appContext?.config.globalProperties.$frm;
  const [photo] = photoList;
  const { name, project }: { name: string, structure_image: OriginImage[], project: string } = frm.get_doc();
  const data = {
    file: photo?.file?.raw,
    is_private: 0,
    folder: 'Home',
    doctype: 'Guigu Construction Diary',
    docname: name,
    fieldname: 'image',
    optimize: true,
  };
  const formData = toFormData(data);
  const response = await fetch('/api/method/upload_file', {
    method: 'POST',
    mode: 'cors',
    body: formData,
    headers: {
      'X-Frappe-CSRF-Token': frappe.csrf_token,
    },
  });
  const imageData = await response.json();
  const targetArr = photo.target.split('_');
  const isStructureProgress = photo.target.startsWith('structure_progress_');
  const target = targetArr[targetArr.length - 1];
  const newImage: OriginImage = {
    docstatus: 0,
    doctype: 'Guigu Construction Image',
    image: imageData.message.file_url,
    project,
    date: photo.date,
    description: photo.description,
    image_name: photo.name,
    photographer: photo.photographer,
  };
  if (isStructureProgress) {
    newImage.guigu_construction_structure_progress = target;
  } else {
    newImage.guigu_construction_structure = target;
  }
  frm.add_child('structure_image', newImage);
  frm.save();
}
function deletePhoto(photo: PhotoFormat) {
  const frm = appContext?.config.globalProperties.$frm;
  const index = images.value.findIndex(item => item.name === photo.id);
  if (index > -1) {
    images.value.splice(index, 1);
    frm.set_value('structure_image', images.value);
  }
  infoVisible.value = false;
}
function showPhotoInfo(photo: PhotoFormat) {
  infoVisible.value = true;
  photoInfo.value = photo;
}
function closeInfo() {
  infoVisible.value = false;
}
function changePhoto(newInfo: Photo) {
  const frm = appContext?.config.globalProperties.$frm;
  const index = images.value.findIndex(item => item.name === newInfo.name);
  if (index > -1) {
    Object.entries(newInfo).forEach(([key, value]) => {
      images.value[index][key as keyof Photo] = value;
    });
    frm.set_value('structure_image', images.value);
  }
}
</script>
<style lang="less" scoped>
.required-field {
  color: #ff4d4f
}
</style>
