/**
 * 解决表多选中用户不支持 “ME” 的问题
 */

frappe.ui.form.ControlTableMultiSelect.prototype.validate = function validate(value) {
	const rows = (value || []).slice();

	// validate the value just entered
	if (this.df.ignore_link_validation) {
		return rows;
	}

	const link_field = this.get_link_field();
	if (rows.length === 0) {
		return rows;
	}

	const all_rows_except_last = rows.slice(0, rows.length - 1);
	const last_row = rows[rows.length - 1];

	// validate the last value entered
	const link_value = last_row[link_field.fieldname];

	// falsy value
	if (!link_value) {
		return all_rows_except_last;
	}

	// duplicate value
	if (all_rows_except_last.map(row => row[link_field.fieldname]).includes(link_value)) {
		return all_rows_except_last;
	}

	const validate_promise = this.validate_link_and_fetch(
		link_value
	);

	return validate_promise.then(validated_value => {
		if (validated_value === link_value) {
			return rows;
		} else if (validated_value && !rows.find(v => v[link_field.fieldname] === validated_value)) {
			rows.push({ ...rows.pop(), [link_field.fieldname]: validated_value });
			return rows;
		}
		rows.pop();
		return rows;

	});
};
