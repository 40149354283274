<template>
	<div class="toolbar">
		<div class="input-container">
			{{ tt("Cost Date") }}：
			<el-space>
				<el-tooltip
					effect="dark"
					:content="tt('The month before')"
					placement="top-start">
					<el-button size="small" class="date-btn"
						@click="dateHandleChange('before')">
						{{ '<<' }}
					</el-button>
				</el-tooltip>
				<ElDatePicker
					class="date-input"
					popperClass="month-input"
					v-model="monthValue"
					type="month"
					:teleported="false"
					:clearable="false"
				/>
				<el-tooltip
					effect="dark"
					:content="tt('The month after')"
					placement="top-start">
					<el-button size="small" class="date-btn"
						@click="dateHandleChange('after')">
						{{ '>>' }}
					</el-button>
				</el-tooltip>
			</el-space>
		</div>
		<div class="input-container">
			<el-space>
				<DetailMenu v-model:layout="detailValue"></DetailMenu>
				<el-tooltip
					effect="dark"
					:content="tt('Refresh')"
					placement="top-start">
					<el-button size="small"
						:icon="Refresh" circle
						@click="emit('refresh')">
					</el-button>
				</el-tooltip>
				<el-tooltip
					effect="dark"
					:content="tt('Full Page')"
					placement="top-start">
					<el-button size="small"
						:icon="FullScreen" circle
						@click="emit('fullScreen')">
					</el-button>
				</el-tooltip>
			</el-space>
		</div>
	</div>
</template>

<script setup lang='ts'>
import {computed, onMounted, ref, onUnmounted} from 'vue';
import {Refresh, FullScreen} from '@element-plus/icons-vue';

import DetailMenu from './DetailMenu.vue';
const tt = __;
interface Props{
	month:string
	detail:0|1
	smallMeta:boolean
}
const props = defineProps<Props>();
interface Emit{
	(e: 'update:month', date?: string): void,
	(e: 'update:detail', date?: string): void,
	(e: 'refresh'): void,
	(e: 'fullScreen'): void,
}
const emit = defineEmits<Emit>();
const monthValue = computed({
	get: ()=>props.month || '',
	set: (v:string)=>{
		emit('update:month', moment(v).format('YYYY-MM-DD'));
	},
});
const detailValue = computed({
	get: ()=>props.detail || '',
	set: (v:string)=>{
		emit('update:detail', v);
	},
});

function dateHandleChange(way: string) {
	const newDate = moment(monthValue.value);
	if (way === 'before') {
		newDate.subtract(1, 'month');
	} else {
		newDate.add(1, 'month');
	}
	const formatDate = newDate.startOf('month').format('YYYY-MM-DD');
	monthValue.value = formatDate;
}

const flexDirection = computed(()=>props.smallMeta ? 'column' : 'row');
const margin = computed(()=>props.smallMeta ? '8px' : '0');
const dateWidth = computed(()=>props.smallMeta ? '140px' : 'auto');
const toolBorder = computed(()=>props.smallMeta ? '#fff' : '#d9d9d9');

</script>

<style lang='less' scoped>
.toolbar {
	display: flex;
	position:relative;
	flex-direction: v-bind(flexDirection);
	justify-content: space-between;
	border-bottom: 0;
}
.toolbar::after{
	content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: -8px;
    background:v-bind(toolBorder);
}
.input-container{
	white-space: nowrap;
}
.input{
	margin-bottom: v-bind(margin);
	min-width: 150px;
}
:deep(.date-input){
	margin-bottom: v-bind(margin);
	width:v-bind(dateWidth)
}
.date-btn{
	margin-bottom: v-bind(margin);
}
</style>
