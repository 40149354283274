import { createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElOption = _resolveComponent("ElOption")
  const _component_ElSelect = _resolveComponent("ElSelect")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Number Of Fields Fixed')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElInputNumber"], {
          modelValue: $setup.fixedFieldColumns,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.fixedFieldColumns) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Line Height')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElInputNumber"], {
          modelValue: $setup.lineHeight,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.lineHeight) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Default Expand Level')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElInputNumber"], {
          modelValue: $setup.expandedDefault,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.expandedDefault) = $event)),
          mix: "0"
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Default Unit')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ElSelect, {
          modelValue: $setup.unitDefault,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.unitDefault) = $event)),
          filterable: "",
          defaultFirstOption: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElOption, {
              value: "",
              label: $setup.tt('Default')
            }, null, 8 /* PROPS */, ["label"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.unitOptions, (field) => {
              return (_openBlock(), _createBlock(_component_ElOption, {
                key: field.value,
                value: field.value || 'none',
                label: field.label
              }, null, 8 /* PROPS */, ["value", "label"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"])
  ], 64 /* STABLE_FRAGMENT */))
}