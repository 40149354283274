import { createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElDialog"], {
    modelValue: $setup.dialogVisible,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.dialogVisible) = $event)),
    title: $setup.tt('Update Version'),
    width: $setup.smallMeta?'80%':'500'
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["ElButton"], {
          onClick: _cache[1] || (_cache[1] = $event => ($setup.dialogVisible = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElButton"], {
          type: "primary",
          onClick: $setup.confirm
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Confirm')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode($setup["ElForm"], {
        labelPosition: "top",
        ref: "formRef",
        rules: $setup.rules,
        model: $setup.versionNoFormValue
      }, {
        default: _withCtx(() => [
          _createVNode($setup["ElFormItem"], {
            label: $setup.tt('Version No'),
            prop: "versionNo"
          }, {
            default: _withCtx(() => [
              _createVNode($setup["ElInput"], {
                modelValue: $setup.versionNoFormValue.versionNo,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.versionNoFormValue.versionNo) = $event))
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["rules", "model"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title", "width"]))
}