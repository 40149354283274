import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.selOptions)
    ? (_openBlock(), _createBlock($setup["Select"], {
        key: 0,
        placeholder: $props.label && $setup.tt($props.label),
        style: {width:'140px'},
        modelValue: $setup.val,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.val) = $event)),
        size: $props.size,
        popperClass: $props.popperClass,
        fitInputWidth: $props.fitWidth,
        options: $setup.selOptions
      }, null, 8 /* PROPS */, ["placeholder", "modelValue", "size", "popperClass", "fitInputWidth", "options"]))
    : (_openBlock(), _createBlock(_resolveDynamicComponent($setup.comp), {
        key: 1,
        style: _normalizeStyle($setup.style),
        modelValue: $setup.val,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.val) = $event)),
        options: $props.options,
        name: $props.name,
        label: $props.label,
        size: $props.size,
        filter: $props.filter,
        popperClass: $props.popperClass,
        fitWidth: $props.fitWidth,
        required: $props.required
      }, null, 8 /* PROPS */, ["style", "modelValue", "options", "name", "label", "size", "filter", "popperClass", "fitWidth", "required"]))
}