<template>
	<ElTree
		class="directory-tree"
		:data="directory"
		:props="defaultProps"
		defaultExpandAll
		highlightCurrent
		:expandOnClickNode="false"
		@node-click="handleNodeClick"
		:emptyText="tt('No Data')"
	>
		<template #default="{ node, data }">
			<ElTooltip
				:content="node.label"
				placement="top-start"
			>
				<span class="custom-tree-node-label">
					{{ node.label }}
				</span>
			</ElTooltip>
		</template>
	</ElTree>
</template>

<script setup lang='ts'>
import {defineProps, computed} from 'vue';

import {ElTree, ElTooltip} from 'element-plus';

import {buildTree} from '../utils/getDirectoryTree';
import type {Content} from '../type';
const tt = __;
interface Props {
	content:Content
	editorContainer?:Element
}
const props = defineProps<Props>();
interface Emit{
	(e:'scrollTo', node:Element):void
}
const emit = defineEmits<Emit>();
const defaultProps = {
	children: 'children',
	label: 'label',
};

const directory = computed(()=>{
	const headings = props.content?.content?.filter(item=>item.type === 'heading')?.map(item=>({label: item.content?.[0]?.text || '', level: item.attrs.level})) || [];
	return buildTree(headings);
});
function handleNodeClick(data:{level:number, label:string}) {
	if (!props.editorContainer) {
		return;
	}
	const [node] = $(props.editorContainer).find(`h${data.level}:contains(${data.label})`);
	if (!node) {
		return;
	}
	emit('scrollTo', node);
}

</script>

<style lang='less' scoped>
.directory-tree{
	flex:1;
	width:100%;
	min-width: 1px;
}
.custom-tree-node-label{
	overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
