<template>
	<div class="file-browser">
		<div>
			<a
				href=""
				class="text-muted text-medium"
				@click.prevent="$emit('hide')"
			>
				{{ tt("← Back to upload files") }}
			</a>
		</div>
		<div class="file-browser-list">
			<div class="file-filter">
				<input
					type="search"
					class="form-control input-xs"
					:placeholder="tt('Search by filename or extension')"
					v-model="search_text"
					@input="search_by_name"
				/>
			</div>
			<TreeNode
				class="tree with-skeleton"
				:node="node"
				:selected_node="selected_node"
				@node-click="n => toggle_node(n)"
				@load-more="n => load_more(n)"
			/>
		</div>
	</div>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue';

import TreeNode from './TreeNode.vue';
defineOptions({ name: 'FileBrowser' });
const emit = defineEmits<{ hide: [], select: [any] }>();
const node = ref({
	label: __('Home'),
	value: 'Home',
	children: [],
	children_start: 0,
	children_loading: false,
	is_leaf: false,
	fetching: false,
	fetched: false,
	open: false,
	filtered: true,
});
const selected_node = ref({});
const search_text = ref('');
const page_length = ref(10);
const tt = __;

function select_node(node) {
	if (node.is_leaf) {
		selected_node.value = node;
		emit('select', node);
	}
}
function make_file_node(file) {
	let filename = file.file_name || file.name;
	let label = frappe.utils.file_name_ellipsis(filename, 40);
	return {
		label: label,
		filename: filename,
		file_url: file.file_url,
		value: file.name,
		is_leaf: !file.is_folder,
		// fetched if node is leaf
		fetched: !file.is_folder,
		children: [],
		children_loading: false,
		children_start: 0,
		open: false,
		fetching: false,
		filtered: true,
	};
}
function get_files_in_folder(folder, start) {
	return frappe
		.call('frappe.core.api.file.get_files_in_folder', {
			folder,
			start,
			page_length: page_length.value,
		})
		.then(r => {
			let { files = [], has_more = false } = r.message || {};
			files.sort((a, b) => {
				if (a.is_folder && b.is_folder) {
					return a.modified < b.modified ? -1 : 1;
				}
				if (a.is_folder) {
					return -1;
				}
				if (b.is_folder) {
					return 1;
				}
				return 0;
			});
			files = files.map(file => make_file_node(file));
			return { files, has_more };
		});
}
function toggle_node(node) {
	if (!node.fetched && !node.is_leaf) {
		node.fetching = true;
		node.children_start = 0;
		node.children_loading = false;
		get_files_in_folder(node.value, 0).then(({ files, has_more }) => {
			node.open = true;
			node.children = files;
			node.fetched = true;
			node.fetching = false;
			node.children_start += page_length.value;
			node.has_more_children = has_more;
		});
	} else {
		node.open = !node.open;
		select_node(node);
	}
}
function load_more(node) {
	if (node.has_more_children) {
		let start = node.children_start;
		node.children_loading = true;
		get_files_in_folder(node.value, start).then(({ files, has_more }) => {
			node.children = node.children.concat(files);
			node.children_start += page_length.value;
			node.has_more_children = has_more;
			node.children_loading = false;
		});
	}
}
let folder_node;
const search_by_name = frappe.utils.debounce(function() {
	if (search_text.value === '') {
		node.value = folder_node;
		return;
	}
	if (search_text.value.length < 3) { return; }
	frappe
		.call('frappe.core.api.file.get_files_by_search_text', {text: search_text.value })
		.then(r => {
			let files = r.message || [];
			files = files.map(file => make_file_node(file));
			if (!folder_node) {
				folder_node = node.value;
			}
			node.value = {
				label: __('Search Results'),
				value: '',
				children: files,
				by_search: true,
				open: true,
				filtered: true,
			};
		});
}, 300);
onMounted(()=>{ toggle_node(node.value); });
</script>

<style>
.file-browser-list {
	height: 300px;
	overflow: hidden;
	margin-top: 10px;
}

.file-filter {
	padding: 3px;
}

.tree {
	overflow: auto;
	height: 100%;
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 4rem;
}
</style>
