import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_date_picker, {
      modelValue: $setup.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.value) = $event)),
      class: "date",
      type: "daterange",
      align: "right",
      onChange: $setup.dateChange,
      unlinkPanels: "",
      startPlaceholder: $setup.tt('Start Date'),
      endPlaceholder: $setup.tt('End Date'),
      shortcuts: $setup.shortcuts
    }, null, 8 /* PROPS */, ["modelValue", "startPlaceholder", "endPlaceholder"])
  ]))
}