import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-93c72e22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Page"], null, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.tt('Guigu Pc Sub Daily Record')), 1 /* TEXT */)
    ]),
    tools: _withCtx(() => [
      ($setup.doctypeLoaded)
        ? (_openBlock(), _createBlock($setup["Tools"], {
            key: 0,
            organizations: $setup.organizations,
            subCompany: $setup.selectedSubCompany,
            "onUpdate:subCompany": _cache[0] || (_cache[0] = $event => (($setup.selectedSubCompany) = $event)),
            searchString: $setup.searchString,
            "onUpdate:searchString": _cache[1] || (_cache[1] = $event => (($setup.searchString) = $event)),
            date: $setup.selectedDate,
            "onUpdate:date": _cache[2] || (_cache[2] = $event => (($setup.selectedDate) = $event)),
            showComment: $setup.showComment,
            "onUpdate:showComment": _cache[3] || (_cache[3] = $event => (($setup.showComment) = $event)),
            onSearch: $setup.search,
            onClearSearch: $setup.clearSearch
          }, null, 8 /* PROPS */, ["organizations", "subCompany", "searchString", "date", "showComment"]))
        : _createCommentVNode("v-if", true)
    ]),
    sider: _withCtx(() => [
      _createVNode($setup["OrganizationTree"], {
        organizations: $setup.organizations,
        loading: $setup.loading,
        onOrganizationChange: $setup.organizationChange
      }, null, 8 /* PROPS */, ["organizations", "loading"])
    ]),
    default: _withCtx(() => [
      ($setup.doctypeLoaded)
        ? (_openBlock(), _createBlock($setup["DiaryTimeline"], {
            key: 0,
            subCompany: $setup.selectedSubCompany,
            organization: $setup.organization,
            organizations: $setup.organizations,
            searchString: $setup.searchString,
            date: $setup.selectedDate,
            showComment: $setup.showComment,
            ref: "diaryTimelineRef"
          }, null, 8 /* PROPS */, ["subCompany", "organization", "organizations", "searchString", "date", "showComment"]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }))
}