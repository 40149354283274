import type { ColumnCell, ColumnComponent } from "../types";
import pointerCapture from './pointerCapture';
import { verticalWritingMode } from '../verticalWritingMode';

export interface ColumnState {
	fixed: boolean;
	hidden: boolean;
	resizable: boolean;
	start?: number;
	end?: number;
	width?: number;
}
export default function createHeader(
	requestRender: () => void,
	header: HTMLElement,
	column: ColumnCell): [ColumnComponent, HTMLElement, ColumnState] {
	const resize = document.createElement('div');
	resize.classList.add('nyloong-table-resize');
	const headerComponent = column.header({ column: column.options });
	const el = headerComponent.root;
	el.classList.add('nyloong-table-header');
	const [begin, move, end] = pointerCapture(
		e => {
			const style = getComputedStyle(resize);
			const writingMode = style.writingMode?.toLowerCase();
			const vertical = verticalWritingMode.has(writingMode);
			const rtl = style.direction.toLowerCase() === 'rtl';
			const secDir = rtl !== (writingMode === 'sideways-lr') ? -1 : 1;
			return secDir * (vertical ? e.offsetY : e.offsetX);
		},
		(e, o) => {
			if (!column.resizable) { return; }
			const rect = header.getBoundingClientRect();
			const style = getComputedStyle(resize);
			const writingMode = style.writingMode?.toLowerCase();
			const vertical = verticalWritingMode.has(writingMode);
			const begin = vertical ? rect.y : rect.x;
			const size = vertical ? rect.height : rect.width;
			const client = vertical ? e.clientY : e.clientX;
			const rtl = style.direction.toLowerCase() === 'rtl';
			const secDir = rtl !== (writingMode === 'sideways-lr');
			const offset = secDir ? begin + size - (client + o) : (client - o) - begin;
			column.width = Math.max(
				1,
				column.minWidth || 0,
				Math.min(offset - column.start, column.maxWidth || Infinity)
			);
			requestRender();
		});
	resize.addEventListener('pointerdown', e => {
		if (!column.resizable) { return; }
		begin(e);
	});
	resize.addEventListener('pointermove', move);
	resize.addEventListener('pointercancel', end);
	resize.addEventListener('pointerup', end);
	return [headerComponent, resize, { fixed: false, hidden: false, resizable: false }];
}
