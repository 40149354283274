<template>
	<ElTreeSelect
		v-show="treeData.length>0"
		:modelValue="structureValue"
		@change="changeStructure"
		:props="defaultProps"
		:data="treeData"
		defaultExpandAll
		:filterNodeMethod="filterNodeMethod"
		filterable
		checkStrictly />
</template>
<script lang="ts" setup>
import {watchEffect, toRefs, ref, computed, watch} from 'vue';

import {FormatDiaryStructure, OriginDiaryStructure} from '../type';
import {list2Tree} from '../../../../../utils';

import {formatStructure} from './helper';
interface Props {
	project: string,
	modelValue?: FormatDiaryStructure,
}
const props = defineProps<Props>();
const {project, modelValue} = toRefs(props);

interface Emit {
	(e: 'update:modelValue', structure?: FormatDiaryStructure): void
}
const emit = defineEmits<Emit>();
const formatStructures = ref<FormatDiaryStructure[]>([]);
const defaultProps = {
	disabled(data: FormatDiaryStructure) {
		return !data.isUnitStructure;
	},
	label: 'label',
	children: 'children',
	value: 'id',
};
watchEffect(() => {
	if (!project.value) {
		return;
	}
	loadDiaryStructure(project.value);
});
const structureValue = computed(()=>modelValue?.value?.id);
watchEffect(() => {
	if (modelValue?.value) {
		const hasData = formatStructures.value.some(item => item.id === modelValue.value?.id);
		if (!hasData) {
			emit('update:modelValue');
		}
	}
	if (modelValue?.value || !formatStructures.value || formatStructures.value.length === 0) {
		return;
	}
	const data = formatStructures.value.find(item => item.isUnitStructure);
	emit('update:modelValue', data);
});
async function loadDiaryStructure(project: string) {
	const data = await frappe.call<{ message: OriginDiaryStructure[] }>({
		method: 'guigu_pm.guigu_pm_construction.page.guigu_structure_month_plan.guigu_structure_month_plan.get_diary_structure',
		args: {
			project,
		},
	});
	formatStructures.value = formatStructure(data?.message.sort((pre, next) => pre.lft - next.lft) || []);
}
const treeData = computed(() => list2Tree(formatStructures.value));
function changeStructure(val: string) {
	const data = formatStructures.value.find(item => item.id === val);
	emit('update:modelValue', data);
}
function filterNodeMethod(value: string, data: (FormatDiaryStructure)) {
	return data.label.includes(value);
}
</script>
<style lang="less" scoped>
</style>
