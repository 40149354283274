import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18ddb48b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tool-container" }
const _hoisted_2 = { class: "tool" }
const _hoisted_3 = { class: "project-info" }
const _hoisted_4 = { class: "project-info" }
const _hoisted_5 = { class: "project-info" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_el_table = _resolveComponent("el-table")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h5", null, _toDisplayString($setup.tt('4、 Progress Management Situation')), 1 /* TEXT */),
      _createElementVNode("p", _hoisted_3, _toDisplayString($setup.tt('（1） The progress of key projects is detailed in the table below:')), 1 /* TEXT */),
      _createVNode(_component_el_table, {
        data: $setup.recordsRef,
        border: "true"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "guigu_project",
            label: $setup.tt('Project'),
            width: "180"
          }, {
            default: _withCtx(({ row }) => [
              _createVNode(_component_el_button, {
                type: "text",
                class: "link-button",
                onClick: $event => ($setup.projectFilterClick(row.name))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.guigu_project), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "duration_months",
            label: $setup.tt('Construction Period (Months)'),
            width: "180"
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "month_diff",
            label: $setup.tt('Completed Construction Period (Months)')
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "plan_rate",
            label: $setup.tt('As of this month, the completion rate of the planned image and appearance%')
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "actual_rate",
            label: $setup.tt('Actual image completion rate%')
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "delay_reason",
            label: $setup.tt('Delay Reason')
          }, null, 8 /* PROPS */, ["label"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["data"]),
      _createElementVNode("p", _hoisted_4, _toDisplayString($setup.tt('（2） On site construction output value')), 1 /* TEXT */),
      _createElementVNode("p", null, _toDisplayString($setup.outputValueStr) + " " + _toDisplayString($setup.tt(
            'The monthly progress output value, cumulative output value completion status, and deviation reasons of key projects are detailed in the table below:'
          )), 1 /* TEXT */),
      _createVNode(_component_el_table, {
        data: $setup.projectOutputValueRef,
        border: "true"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "guigu_project",
            label: $setup.tt('Project'),
            width: "180"
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "month_value_plan",
            label: $setup.tt('Monthly Plan'),
            width: "180"
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "month_value_complete",
            label: $setup.tt('Monthly Completion')
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "year_value_plan",
            label: $setup.tt('Year Accumulate Plan')
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "year_value_complete",
            label: $setup.tt('Year Accumulate Actual')
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "delay_reason",
            label: $setup.tt('Deviation Reason')
          }, null, 8 /* PROPS */, ["label"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["data"]),
      _createElementVNode("p", _hoisted_5, _toDisplayString($setup.tt('（3） Major Nodes')), 1 /* TEXT */),
      _createElementVNode("p", null, _toDisplayString($setup.tt('1. The major milestones of each project this month are detailed in the table below')), 1 /* TEXT */),
      _createElementVNode("p", null, _toDisplayString($setup.tt('2. Next month node plan is detailed in the table below')), 1 /* TEXT */)
    ])
  ]))
}