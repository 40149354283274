import { createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["ValueEditor"], {
      meta: $props.meta,
      loading: $setup.loading,
      visible: $setup.valueEditorVisible,
      "onUpdate:visible": _cache[0] || (_cache[0] = $event => (($setup.valueEditorVisible) = $event)),
      field: $setup.editField,
      value: $setup.editValue,
      onUpdate: $setup.saveGroupValue
    }, null, 8 /* PROPS */, ["meta", "loading", "visible", "field", "value"]),
    (_openBlock(), _createBlock($setup["KanbanC"], {
      groups: $setup.stages,
      data: $setup.data,
      onUpdate: $setup.update,
      key: $setup.key,
      onSetting: $setup.edit,
      onAdd: $setup.add,
      onCreate: $setup.create
    }, {
      default: _withCtx(({ value }) => [
        _createVNode($setup["Item"], {
          data: value,
          meta: $props.meta,
          loading: $setup.loading,
          fields: $setup.fieldDef,
          onClickTitle: $event => ($setup.clickTitle($event, value))
        }, null, 8 /* PROPS */, ["data", "meta", "loading", "fields", "onClickTitle"]),
        _renderSlot(_ctx.$slots, "pagination", {}, undefined, true)
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["groups", "data"]))
  ], 64 /* STABLE_FRAGMENT */))
}