<template>
	<AgGridVue
		v-if="!loading"
		class="ag-theme-guigu table"
		style="height:95%; width: 100%;margin: 0;overflow: auto;"
		treeData
		:rowData="dataList"
		animateRows
		@grid-ready="onGridReady"
		:getDataPath="getDataPath"
		:getRowId="getRowId"
		:getRowHeight="getRowHeight"
		:suppressDragLeaveHidesColumns="smallMeta"
		:autoGroupColumnDef="autoGroupColumnDef"
		:localeText="zhCN"
	></AgGridVue>
</template>
<script setup lang="ts">
import {ref, watch} from 'vue';
import {AgGridVue} from 'ag-grid-vue3';
import type {CellClassParams, GridApi} from 'ag-grid-community';

import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';

import {processLevel} from './helper';
import type {Data, LevelStyle} from './type';
import Process from './Process.vue';

const props = defineProps<{
	permission: boolean;
	dataList: Data[];
	levelList:LevelStyle[]
	loading: boolean;
	smallMeta: boolean;
}>();
const emit = defineEmits<{
	(e: 'showProcessModalOrNavigate', name: string): void;
	(e: 'updateData', type: 'delete' | 'create' | 'update' | 'enable' | 'disable', data:any): void;
}>();
const gridApi = ref<GridApi>();
// 新增下级标准流程
function addNextProcess(data: Data) {
	const docName = frappe.model.make_new_doc_and_get_name('Dw Process', true);
	const docDetail = locals['Dw Process'][docName] as locals.Doctype;
	docDetail.parent_dw_process = data.name;
	emit('showProcessModalOrNavigate', docDetail.name);
}
// 启用标准流程
function enableProcess(data: Data) {
	frappe.db.set_value('Dw Process', data.name, 'disable', 0).then(() => {
		emit('updateData', 'enable', data);
		frappe.show_alert({message: __('Enable Successful'), indicator: 'green'}, 2);
	});
}
// 停用标准流程
async function disableProcess(data: Data) {
	// 验证流程是否关联标准工作清单
	const names = await frappe.db.get_list('Dw Standard Worklist', {
		filters: [['work_process', '=', data.name]],
		fields: ['name'], limit: 0,
	});
	if (names.length) {
		return frappe.msgprint({title: __('Cannot Disable'), indicator: 'red',
			message: __('This process has a standard work list in use and cannot be disable'),
		});
	}
	frappe.confirm(
		__('Are you sure you want to disable the process?'),
		() => {
			frappe.db.set_value('Dw Process', data.name, 'disable', 1).then(() => {
				emit('updateData', 'disable', data);
				frappe.show_alert({message: __('Disable Successful'), indicator: 'green'}, 2);
			});
		}, () => {},
	);
}
// 点击流程时根据布局的方式查看详情
function viewProcessDetail(name: string) {
	emit('showProcessModalOrNavigate', name);
}
const autoGroupColumnDef = ref({
	headerName: __('Process Name'),
	field: 'process_name',
	editable: false,
	filter: false,
	flex: 1,
	minWidth: 200,
	cellRendererParams: {
		innerRenderer: Process,
		innerRendererParams: {
			addNextProcess,
			disableProcess,
			enableProcess,
			viewProcessDetail,
			permission: props.permission,
			style: props.levelList,
		},
	},
	cellStyle: (params: CellClassParams) => {
		const levelStyle = props.levelList.find(item => item.level === processLevel[params.data?.level]);
		const {row_background_color, font_color, is_bold} = levelStyle || {};
		return {
			display: 'grid',
			alignItems: 'center',
			backgroundColor: row_background_color,
			color: font_color,
			fontWeight: is_bold ? 'bold' : 'normal',
		};
	},
	sort: 'asc',
	comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
		const codeA = nodeA.data.full_process_code;
		const codeB = nodeB.data.full_process_code;
		if (codeA === codeB) {
			return 0;
		}
		return (codeA > codeB) ? 1 : -1;
	},
});
watch([() => props.permission, () =>props.levelList], () => {
	autoGroupColumnDef.value.cellRendererParams.innerRendererParams.permission = props.permission;
	autoGroupColumnDef.value.cellRendererParams.innerRendererParams.style = props.levelList;
});
function onGridReady(params: CellClassParams) {
	gridApi.value = params.api;
}
function getRowId(params: CellClassParams) {
	return params.data.name;
}
function getDataPath(data: Data) {
	return data.path;
}
function getRowHeight(params: CellClassParams) {
	const levelStyle = props.levelList.find(item => item.level === processLevel[params.data?.level]);
	return levelStyle?.row_height || 30;
}
defineExpose({
	gridApi,
});
</script>
<style scoped>
:deep(.ag-group-contracted){
	height: 100% !important;
}
:deep(.ag-group-expanded){
	height: 100% !important;
}
:deep(.ag-group-child-count){
	height: 100% !important;
}
</style>
