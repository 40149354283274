<template>
	<a
		:href="href" :title="title">
		{{ title }}
	</a>
</template>
<script lang="ts" setup>
import {computed} from 'vue';

interface Params {
	data: any
	value:string,
	label:string,
	options:string
}
const props = defineProps<{ params: Params }>();
const options = computed(() => props.params.options);
const value = computed(()=>props.params.value);
const title = computed(() => props.params.label);
const href = computed(() =>`/app/${frappe.router.slug((options.value || '').split('\n')[0])}/${value.value}`);

</script>

<style scoped>
.disabled{
	cursor: default;
}
</style>
