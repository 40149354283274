export default function createTab(
	name: string,
	readonly: boolean,
	onChange: (name: string) => void,
	onRename: (name: string, newName: string) => void,
	onRemove: (name: string) => void,
) {
	const tab = document.createElement('div');
	tab.className = 'tianjy-spread-sheet-tab';
	tab.addEventListener('click', e => {
		e.stopPropagation();
		onChange(name);
	});
	tab.title = name;
	const label = tab.appendChild(document.createElement('div'));
	const inputer = document.createElement('input');
	inputer.type = 'text';
	inputer.placeholder = __('Tab Label');
	inputer.addEventListener('click', e => { e.stopPropagation(); });
	const labelSpan = document.createElement('span');
	const noLabel = document.createElement('i');
	noLabel.className = 'text-muted';
	const placeholder = label.appendChild(document.createElement('span'));
	placeholder.dataset.placeholder = __('Tab Label');
	label.title = __('Double click to edit label');
	const button = tab.appendChild(document.createElement('button'));
	button.className = 'btn btn-xs';
	button.type = 'button';
	button.title = __('Remove tab');
	button.hidden = readonly;
	button.innerHTML = frappe.utils.icon('remove', 'xs');
	button.addEventListener('click', () => onRemove(name));
	function hideInputer() {
		label.innerHTML = '';
		if (name) {
			labelSpan.innerText = name;
			label.appendChild(labelSpan);
		} else {
			label.appendChild(noLabel);
		}
		label.appendChild(placeholder);

	}
	function updateWidth() {
		placeholder.innerText = inputer.value;
		requestAnimationFrame(() => {
			inputer.style.width = `${Math.max(placeholder.offsetWidth || 0, 25) + 15}px`;
		});
	}
	function submit() {
		const {value} = inputer;
		if (!value || value === name) { return; }
		onRename(name, value);
	}
	inputer.addEventListener('keydown', e => {
		if (e.key === 'Escape') {
			placeholder.innerText = '';
			e.stopPropagation();
			e.preventDefault();
			hideInputer();
		} else if (e.key === 'Enter') {
			e.stopPropagation();
			e.preventDefault();
			hideInputer();
			submit();
		}
	});
	inputer.addEventListener('input', () => {
		updateWidth();

	});
	inputer.addEventListener('blur', e => {
		hideInputer();
		submit();
	});

	label.addEventListener('dblclick', () => {
		if (readonly) { return; }
		inputer.value = name;
		label.innerHTML = '';
		label.appendChild(inputer);
		label.appendChild(placeholder);
		updateWidth();
		inputer.focus();
	});
	hideInputer();
	return {
		get name() { return name; },
		set name(n) {
			name = n;
			tab.title = name;
			hideInputer();
		},
		get active() {
			return tab.classList.contains('active');
		},
		set active(v) {
			if (v) {
				tab.classList.add('active');
			} else {
				tab.classList.remove('active');
			}
		},
		get readonly() { return readonly; },
		set readonly(n) {
			readonly = n;
			button.hidden = readonly;
			if (inputer.parentElement) {
				hideInputer();
			}
			inputer.disabled = readonly;
		},
		el: tab,


	};

}
