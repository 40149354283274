<template>
  <div class="tool-container">
    <div class="tool">
      <h5>{{ tt('1、 Project Situation') }}</h5>
      <p class="project-info">
        {{
          tt(
            'As of {}, Southwest Company has {} ongoing projects (including {} normal construction projects and {} suspended projects), {} unfinished projects (including {} closed construction projects, {} completed but not accepted projects, and {} completed and accepted projects), and {} unfinished projects.',
            [selectedDate, projectOverview?.buildingProjects, projectOverview?.normal, projectOverview?.stop, 0, 0, 0, 0, 0]
          )
        }}
      </p>
      <p class="project-info">
        {{ tt('There are {} unsigned contract projects, of which {} have been constructed and {} have been completed.', [0, 0, 0]) }}
      </p>
      <div id="project-quantity-echart" style="width: 600px; height: 400px; display: none"></div>
      <img :src="url" />
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { ref, watchEffect, defineProps, onMounted, watch } from 'vue';

  import { getChart } from './Echart';
  import { ProjectOverview } from './type';

  interface Props {
    selectedDate: string;
  }

  const tt = __;
  const props = defineProps<Props>();

  // 定义响应式数据
  const projectOverview = ref<ProjectOverview>();
  const url = ref<string>();
  const myChart = ref<echarts.ECharts>();
  // 监视 props 的变化并更新响应式数据
  watchEffect(() => {
    getProjectOverview(props.selectedDate);
  });

  // 根据您的具体实现，您需要实现获取数据的函数
  async function getProjectOverview(date: string) {
    // TODO: 获取在建项目数
    // const result = await frappe.call<{message:ProjectOverview}>({
    //   method:'',
    //   args:{},
    // });
    const result: { message: ProjectOverview } = {
      message: {
        buildingProjects: 24,
        normal: 2,
        stop: 3,
        projectQuantityEChart: {
          name: __('The proportion of ongoing， unfinished， and unfinished projects'),
          type: 'pie',
          radius: ['30%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'inside',
            formatter: '{a|{c}\n{d}%}',
            rich: {
              a: {
                backgroundColor: '#404040',
                borderRadius: 4,
                padding: [2, 4],
                lineHeight: 15,
                color: '#fff',
                width: 35,
                height: 17,
                fontSize: 12,
              },
            },
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '30',
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            { value: 35, name: __('Under Construction Projects'), itemStyle: { color: '#5B9BD5' } },
            { value: 40, name: __('Final Project'), itemStyle: { color: '#ED7D31' } },
            { value: 2, name: __('Projects Not Yet Started'), itemStyle: { color: '#A5A5A5' } },
          ],
        },
      },
    };
    projectOverview.value = result?.message;
  }

  watch([projectOverview, myChart], () => {
    myChart.value?.setOption({
      series: [projectOverview.value?.projectQuantityEChart],
    });
  });
  onMounted(() => {
    renderChart();
  });
  function renderChart() {
    // 指定图表的配置项和数据
    const option = {
      title: {
        text: __('The proportion of ongoing， unfinished， and unfinished projects'),
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)',
      },
      legend: {
        orient: 'vertical',
        left: 10,
        data: [__('Under Construction Projects'), __('Final Project'), __('Projects Not Yet Started')],
      },
    };
    myChart.value = getChart(document.getElementById('project-quantity-echart')!, option);
    // 使用刚指定的配置项和数据显示图表。
    myChart.value.on('finished', function () {
      url.value = myChart.value?.getDataURL();
    });
  }
</script>

<style scoped lang="less">
  .tool-container {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    .tool {
      margin: 16px;
      padding: 24px;
      h5 {
        margin: 0 0 16px 0;
        font-size: 20px;
        font-weight: bold;
      }
      .project-info {
        margin: 0;
        font-size: 16px;
        line-height: 2;
        text-indent: 2em;
      }
      img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 16px auto;
      }
    }
  }
</style>
