<template>
	<div v-loading="loading || localLoading" ref="graphRef" class="container"></div>
</template>

<script lang="ts" setup>
import { computed, ref, watch, onMounted, onUnmounted } from 'vue';

import G6, { Graph, INode, Item, TreeGraph } from '@antv/g6';

import { Config } from './type';
import { getAncestry, getGraphData } from './helper';
import { TreeGraphControl } from './g6';

const props = defineProps<{
	meta: locals.DocType;
	options: Record<string, any>;

	data: locals.Doctype[];
	total: number;
	loading?: boolean;

	modelValue: any;
	selected: any[];

	infiniteScroll: boolean;
	page: number;
	limit: number;
	group: GlobalView.Group[];
	sort: GlobalView.MainLoaderOptions['order'];

	detail?: boolean;
	rowAction?: any;


	view?: GlobalView.View;
	configuration?: Config;
}>();

const emit = defineEmits<{
	(event: 'refresh'): void;
	(event: 'delete', doc: any): void;
	(event: 'create', data: any, extend?: boolean): void;
	(event: 'nextPage'): void;
	(event: 'update:modelValue', value: any): void;
	(event: 'update:selected', value: any[]): void;
	(event: 'update:data', data: locals.Doctype[]): void;
	(event: 'filter', field: string, operator: string, value: any): void;
	(event: 'sort', target: string, docs: string[], before: boolean, field?: string): void
	(event: 'sort', target: string, docs: string[], before: boolean, children?: boolean): void
}>();
const graphRef = ref();
const graph = ref<TreeGraphControl>();
const data = ref<locals.Doctype[]>([]);
const localLoading = ref<boolean>(false);
watch(() => props.data, d => (data.value = d), { immediate: true });

const connectionField = computed(() => props.configuration?.connection_field);
async function onDragEnd(dragNode: Item, overNode: INode) {
	const dragId = dragNode._cfg?.id;
	if (!connectionField.value) { return { isSuccess: false }; }
	if (overNode.isVisible() === false) { return { isSuccess: false }; }
	localLoading.value = true;
	await frappe.db.set_value(
		props.meta.name,
		dragId,
		connectionField.value,
		overNode._cfg?.id
	);
	data.value.forEach(item => {
		if (item.name === dragId) {
			item[connectionField.value!] = overNode._cfg?.id;
		}
	});
	const graphData = getGraphData(data.value, props.view?.fields || [], connectionField.value, props.meta);
	localLoading.value = false;
	return { isSuccess: true, newData: graphData };
}
function onEdit(item: Item) {
	const model = item.getModel();
	if (!model) { return; }
	frappe.set_route('Form', props.meta.name, model.id!);
}
function onRemove(item: Item) {

}
function onRemoveConnection(item: Item) {
	const model = item.getModel();
	if (!model || !connectionField.value) { return; }
	localLoading.value = true;
	frappe.db.set_value(props.meta.name, model.id, { [connectionField.value]: '' });
	data.value.forEach(item => {
		if (item.name === model.id) {
			item[connectionField.value!] = '';
		}
	});
	const graphData = getGraphData(data.value, props.view?.fields || [], connectionField.value, props.meta);
	graph.value?.graph.changeData(graphData);
	graph.value?.graph.hideItem('graph_root');
	localLoading.value = false;
}
function onAddChildren(item: Item) {
	const model = item.getModel();
	if (!model) { return; }
	const parentData = data.value.find(item => item.name === model.id);
	if (!parentData) { return; }
	const newDoc = frappe.model.make_new_doc_and_get_name(props.meta.name);
	frappe.model.set_value(props.meta.name, newDoc, connectionField.value, parentData.name);
	const projectField = props.meta.fields.find(item => item.options === 'Project');
	if (projectField) {
		frappe.model.set_value(props.meta.name, newDoc, projectField.fieldname, parentData[projectField.fieldname]);
	}
	frappe.set_route(['form', props.meta.name, newDoc]);
}
function onExpandLevel(v: string) {
	if (v === '0') { return; }
	if (!graph.value) { return; }
	const initData = graph.value?.graph.save();
	getAncestry([initData]);
	G6.Util.traverseTree(initData, sub => {
		if (sub.ancestry.length <= parseInt(v)) {
			sub.collapsed = false;
		} else {
			sub.collapsed = true;
		}
		return true;
	});
	graph.value.graph.data(initData);
	graph.value.graph.render();
	graph.value.graph.hideItem('graph_root');
}
watch(graphRef, () => {
	if (!graphRef.value) { return; }
	if (graph.value) { }
	graph.value = new TreeGraphControl({
		container: graphRef.value,
		width: graphRef.value.clientWidth - 30,
		height: graphRef.value.clientHeight,
		onEdit,
		onRemove,
		onRemoveConnection,
		onAddChildren,
		onExpandLevel,
	});
	graph.value.graph.on('nodeselectchange', e => {
		let selectId: string = '';
		if (e.select) {
			selectId = e.target.getModel().id;
		} else {
			selectId = '';
		}
		const selectedData = props.data.find(item => selectId === item.name);

		emit('update:modelValue', selectedData);
		emit('update:selected', [selectedData]);
	});
	graph.value.onDragEnd(onDragEnd);
});

watch(() => props.data, () => {
	if (!graph.value) { return; }
	if (!connectionField.value) { return; }
	const initData = getGraphData(props.data, props.view?.fields || [], connectionField.value, props.meta);
	graph.value.graph.data(initData);
	graph.value.graph.render();
	graph.value.graph.hideItem('graph_root');
}, { immediate: true });
function listenWindowResize() {
	if (!graph.value || graph.value.graph.get('destroyed')) { return; }
	if (!graphRef.value || !graphRef.value.scrollWidth || !graphRef.value.scrollHeight) { return; }
	graph.value.graph.changeSize(graphRef.value.scrollWidth, graphRef.value.scrollHeight);
}
onMounted(() => {
	window.addEventListener('resize', listenWindowResize);
});
onUnmounted(() => {
	window.removeEventListener('onresize', listenWindowResize);
});
</script>

<script lang="ts">
export default { inheritAttrs: false };
</script>

<style lang="less" scoped>
.container {
	overflow: hidden;
	position: relative;
	height: 100%;
	width: 100%;
}

:deep(.g6-component-toolbar path) {
	fill: #000;
}

:deep(.g6-component-toolbar [code=redo]) {
	display: none;
}

:deep(.g6-component-toolbar [code=undo]) {
	display: none;
}

:deep(.g6-component-toolbar .graph-view-tooltip-content) {
	position: relative;
}

:deep(.g6-component-toolbar .graph-view-tooltip-content .tooltiptext) {
	visibility: hidden;
	width: 60px;
	background-color: #555;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	top: 125%;
	left: 50%;
	margin-left: -30px;
	opacity: 0;
	transition: opacity 0.3s;
}

:deep(.g6-component-toolbar .graph-view-tooltip-content .tooltiptext::after) {
	content: "";
	position: absolute;
	top: -10px;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent #555 transparent;
}

:deep(.g6-component-toolbar .graph-view-tooltip-content:hover .tooltiptext) {
	visibility: visible;
	opacity: 1;
}
</style>
