import {createApp, h, ref, type App} from 'vue';

import Editor from './editor/index.vue';
frappe.provide('frappe.tianjy_editor');
frappe.tianjy_editor.editor = Editor;
frappe.tianjy_editor.createTianjyEditorPlugin = createTianjyEditorPlugin;
function createTianjyEditorPlugin(components:{
	name:string,
	tool:any,
	block:any
}[]) {
	return {
		install(app:App<any>) {
			app.config.globalProperties.$CustomBlocks = components || [];
		},
	};
}
frappe.tianjy_editor.createApp = function(
	props:{editable:boolean, value:string},
	onChange:(content:string)=>void,
	components:{
		name:string,
		tool:any,
		block:any
	}[],
) {
	const valueRef = ref<string>(props.value || '');
	const editableRef = ref<boolean>(Boolean(props.editable));
	const app = createApp({props: [], render: () =>h(Editor, {
		value: valueRef.value,
		editable: editableRef.value,
		'onUpdate:value': function(json:string) {
			onChange(json);
			valueRef.value = json;
		},
	})});
	app.use({
		install(app) {
			app.config.globalProperties.$CustomBlocks = components || [];
		},
	});


	function setContent(json:string) {
		valueRef.value = json;
	}
	function setEditable(editable:boolean) {
		editableRef.value = editable;
	}
	return {app, valueRef, editableRef, setContent, setEditable};
};
