import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03bfa5ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "root" }
const _hoisted_2 = ["href", "title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.params.data)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: $setup.href,
          onClick: $setup.click,
          title: $setup.value
        }, _toDisplayString($setup.value), 9 /* TEXT, PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true)
  ]))
}