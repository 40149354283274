<template>
	<TimelineItem
		:date="smallMeta && dailyRecords.left === 'show' ? dailyRecords.date : ''"
		v-bind="$attrs">
		<template v-slot:leftTitle
			v-if="dailyRecords.left === 'show' && !smallMeta">
			{{ dailyRecords.date }}
		</template>
		<template v-slot:timeName>
			<DiaryLabel :dailyRecords="dailyRecords" :subCompany="subCompany">
			</DiaryLabel>
		</template>
		<DiaryDetail
			v-if="dailyRecords.data"
			:searchString="searchString"
			:showComment="showComment"
			:daily-record="dailyRecords.data">
		</DiaryDetail>
	</TimelineItem>
</template>
<script lang="ts" setup>
import { defineProps, toRefs, defineEmits, computed } from 'vue';

import TimelineItem from '../../../../../../../guigu_pm/guigu_pm/public/js/components/timeline/TimelineItem.vue';

import DiaryDetail from './DiaryDetail.vue';
import DiaryLabel from './DiaryLabel.vue';
import { DiaryRecordB, TimelineList, TimelineSource } from './type';
import { useMetaQuery } from './useMetaQuery';
interface Props {
	dailyRecords: TimelineList,
	subCompany?: string,
	searchString?: string
	showComment: boolean
}
const smallMeta = useMetaQuery();

const props = defineProps<Props>();
const { dailyRecords } = toRefs(props);
</script>
<style lang="less" scoped>
</style>
