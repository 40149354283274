import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e16ae9e7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Page"], null, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.tt('Comprehensive Monthly Report')), 1 /* TEXT */)
    ]),
    tools: _withCtx(() => [
      _createVNode($setup["Tools"], {
        date: $setup.selectedDate,
        "onUpdate:date": _cache[0] || (_cache[0] = $event => (($setup.selectedDate) = $event))
      }, null, 8 /* PROPS */, ["date"])
    ]),
    default: _withCtx(() => [
      _createVNode($setup["ProjectOverview"], { "selected-date": $setup.selectedDate }, null, 8 /* PROPS */, ["selected-date"]),
      _createVNode($setup["BusinessIndicatorsOverview"], { "selected-date": $setup.selectedDate }, null, 8 /* PROPS */, ["selected-date"]),
      _createVNode($setup["ProfitAndLossSituation"], { "selected-date": $setup.selectedDate }, null, 8 /* PROPS */, ["selected-date"]),
      _createVNode($setup["ProgressManagement"], { "selected-date": $setup.selectedDate }, null, 8 /* PROPS */, ["selected-date"]),
      _createVNode($setup["TechnicalManagement"], { "selected-date": $setup.selectedDate }, null, 8 /* PROPS */, ["selected-date"])
    ]),
    _: 1 /* STABLE */
  }))
}