frappe.ui.form.Control.prototype.get_status = function(explain){
	if (this.df.get_status) {
		return this.df.get_status(this);
	}

	if (
		(!this.doctype && !this.docname) ||
		this.df.parenttype === 'Web Form' ||
		this.df.is_web_form
	) {
		let status = 'Write';

		// like in case of a dialog box
		if (cint(this.df.hidden)) {
			if (explain) { console.log('By Hidden: None'); }
			return 'None';
		} else if (cint(this.df.hidden_due_to_dependency)) {
			if (explain) { console.log('By Hidden Dependency: None'); }
			return 'None';
		} else if (
			cint(this.df.read_only || this.df.is_virtual || this.df.fieldtype === 'Read Only')
		) {
			if (explain) { console.log('By Read Only: Read'); }
			status = 'Read';
		} else if (
			(this.grid && this.grid.display_status == 'Read') ||
			(this.layout && this.layout.grid && this.layout.grid.display_status == 'Read')
		) {
			// parent grid is read
			if (explain) { console.log('By Parent Grid Read-only: Read'); }
			status = 'Read';
		}

		let value = this.value || this.get_model_value();
		value = this.get_parsed_value(value);

		if (
			status === 'Read' &&
			is_null(value) &&
			!['HTML', 'Image', 'Button'].includes(this.df.fieldtype)
		)
		{ status = 'Read'; }

		return status;
	}

	let status = frappe.perm.get_field_display_status(
		this.df,
		frappe.model.get_doc(this.doctype, this.docname),
		this.perm || (this.frm && this.frm.perm),
		explain,
	);

	// Match parent grid controls read only status
	if (
		status === 'Write' &&
		(this.grid || (this.layout && this.layout.grid && !cint(this.df.allow_on_submit)))
	) {
		let grid = this.grid || this.layout.grid;
		if (grid.display_status == 'Read') {
			status = 'Read';
			if (explain) { console.log('By Parent Grid Read-only: Read'); }
		}
	}

	let value = frappe.model.get_value(this.doctype, this.docname, this.df.fieldname);

	if (['Date', 'Datetime'].includes(this.df.fieldtype) && value) {
		value = frappe.datetime.str_to_user(value);
	}

	value = this.get_parsed_value(value);

	// hide if no value
	if (
		this.doctype &&
		status === 'Read' &&
		!this.only_input &&
		is_null(value) &&
		!['HTML', 'Image', 'Button', 'Geolocation'].includes(this.df.fieldtype)
	) {
		if (explain) { console.log('By Hide Read-only, null fields: None'); }
		// 修改frappe 代码， 只读无数据不再隐藏
		status = 'Read';
	}

	return status;
};
