import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElOption = _resolveComponent("ElOption")

  return (_openBlock(), _createBlock($setup["ElFormItem"], {
    label: $setup.tt('Connection Field')
  }, {
    default: _withCtx(() => [
      _createVNode($setup["ElSelect"], {
        modelValue: $setup.connectionField,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.connectionField) = $event)),
        filterable: "",
        defaultFirstOption: "",
        clearable: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.linkOptions, (field) => {
            return (_openBlock(), _createBlock(_component_ElOption, {
              key: field.value,
              value: field.value,
              label: field.label
            }, null, 8 /* PROPS */, ["value", "label"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["label"]))
}