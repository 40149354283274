import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-026fb6fd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "instruction-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "button"
}
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "editor-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElConfigProvider"], { size: "small" }, {
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($props.mode==='edit')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode($setup["VersionForm"])
            ]))
          : _createCommentVNode("v-if", true),
        ($props.mode==='show')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              ($props.editable)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode($setup["ElButton"], {
                      link: "",
                      type: "primary",
                      onClick: $setup.navigate
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.tt('Edit')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("h2", _hoisted_5, _toDisplayString($setup.store.currentVersion.title), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_6, [
          _createVNode($setup["Editor"], {
            editable: $props.editable&&$props.mode==='edit',
            value: $setup.store.content,
            onChange: $setup.editorChange,
            height: "100%"
          }, null, 8 /* PROPS */, ["editable", "value"])
        ])
      ])), [
        [$setup["vLoading"], $setup.store.loading]
      ])
    ]),
    _: 1 /* STABLE */
  }))
}