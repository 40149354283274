<script lang="ts" setup>
import { computed, onMounted, ref, shallowRef, watch, triggerRef } from 'vue';

import {
	ElInput, ElButton,
	ElForm, ElFormItem,
} from 'element-plus';

const tt = __;

const props = defineProps<{
	value?: {
		url:string,
		label:string
	};
}>();
const emit = defineEmits<{
	(event: 'update', data: any, submittable?: boolean): void;
	(event: 'cancel' | 'submit'): void;
}>();

const label = ref<string>(props.value?.label || '');
const url = ref<string>(props.value?.url||'');
const doctypeLabel = ref('');

watch(doctypeLabel, (v, o) => {
	if (!v) { return; }
	const l = label.value;
	if (!l || l === o) {
		label.value = v;
	}
});

watch([label, url], ([label, url]) => {
	emit('update', { label, url}, Boolean(url));
}, { immediate: true, deep: true });

</script>

<template>
	<div>
		<ElForm labelPosition="top">
			<ElFormItem :label="tt('URL')"
				:rules="[
					{
						required: true,
						message: '请选择链接',
						trigger: 'blur',
					}
				]">
				<ElInput v-model="url"
					@label="doctypeLabel = $event" />
			</ElFormItem>
			<ElFormItem :label="tt('Label')" :rules="[
									{
										required: true,
										message: '请输入标题',
										trigger: 'blur',
									}
								]">
				<ElInput v-model="label" />
			</ElFormItem>
		</ElForm>
	</div>
</template>
<style scoped lang="less">
.form-content {
	margin: 1rem;
}

.add-row-btn {
	margin-top: 8px;

}
</style>
