import type {WorkListData} from './type';

export function mergeData(result: WorkListData, separator:string) {
	const {process_list, worklist_list} = result;
	for (const item of process_list) {
		item.is_process = true;
		item.title = `${item.full_process_code} ${item.title}`;
	}
	// 根据worklist_list的work_process字段，找到process_list中对应的name字段
	for (const item of worklist_list) {
		item.is_process = false;
		const processItem = process_list.find(process => process.name === item.work_process);
		if (processItem) {
			item.parent_dw_process = processItem.name;
			item.title = `${processItem?.full_process_code}${separator}${item.full_code} ${item.title}`;
		}
	}
	return [...process_list, ...worklist_list];
}
