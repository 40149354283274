import type { Option } from './selectOptionMap';
function getList(options?: any) {
	if (typeof options === 'string') {
		return options.split('\n').filter(Boolean)
			.map(v => ({ value: v, label: __(v) }));
	}
	if (!Array.isArray(options)) {
		return [];
	}
	return options.map(v => {
		if (typeof v === 'string') {
			return { value: v, label: __(v) };
		}
		if (!v) { return; }
		if (typeof v !== 'object') { return; }
		const { value, label } = v;
		return { value, label: __(label) };
	}).filter(Boolean) as Option[];
}
export default function getSelectOptions(options?: any, isMulti?: boolean): Option[] {
	const list = getList(options);
	if (isMulti) { return list; }
	return [{ value: '', label: '' }, ...list];
}
