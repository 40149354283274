import { toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f977f094"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar" }
const _hoisted_2 = { class: "tool_left" }
const _hoisted_3 = { class: "organization-select" }
const _hoisted_4 = { class: "process-search" }
const _hoisted_5 = { class: "tool_right" }
const _hoisted_6 = { class: "tool_dropdown_menu" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString($setup.tt('Organizational Unit')) + ": ", 1 /* TEXT */),
        _createVNode($setup["ElSelect"], {
          class: "input",
          disabled: "",
          modelValue: $setup.organization,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.organization) = $event)),
          size: "small"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElOption"], {
              label: $setup.organizationList.label,
              value: $setup.organizationList.name
            }, null, 8 /* PROPS */, ["label", "value"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode($setup["ProcessSearchTools"], {
          processes: $props.processes,
          onGetData: $setup.getData
        }, null, 8 /* PROPS */, ["processes"])
      ]),
      _createVNode($setup["ElCheckbox"], {
        class: "applicable-filter",
        size: "small",
        modelValue: $setup.onlyApplicable,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.onlyApplicable) = $event))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.tt('Displays only where applicable')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode($setup["LayoutSelect"], {
          detailType: $setup.detailType,
          "onUpdate:detailType": _cache[2] || (_cache[2] = $event => (($setup.detailType) = $event))
        }, null, 8 /* PROPS */, ["detailType"])
      ]),
      _createVNode($setup["CollapsiblePanel"], { onToggle: $setup.isExpand }),
      ($props.hasPermission)
        ? (_openBlock(), _createBlock($setup["ElButton"], {
            key: 0,
            class: "new_worklist",
            type: "primary",
            size: "small",
            disabled: "",
            onClick: $setup.addWorklist
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('New')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      ($props.hasPermission)
        ? (_openBlock(), _createBlock($setup["ElButton"], {
            key: 1,
            disabled: !$setup.props.showApplyButton,
            type: "primary",
            size: "small",
            onClick: _cache[3] || (_cache[3] = $event => ($setup.emit('applyWorklist')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('Applicable')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled"]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}