<template>
	<div class="toolbar">
		<div class="input-container">
			{{ tt('Status') }}:
			<ElSelect class="input" filterable clearable v-model="taskStateValue" size="small" :placeholder="tt('Please Select')">
				<ElOption v-for="item in taskStateList" :key="item.name" :label="item.status_name" :value="item.name" />
			</ElSelect>
		</div>
		<div class="input-container">
			{{ tt('Priority') }}:
			<ElSelect class="input" filterable clearable v-model="taskPriorityValue" size="small" :placeholder="tt('Please Select')">
				<ElOption v-for="item in taskPriorityList" :key="item" :label="tt(item)" :value="item" />
			</ElSelect>
		</div>
		<div class="input-container">
			{{ tt('Assignee') }}:
			<ElSelect class="input" filterable clearable v-model="taskAssigneeValue" size="small" :placeholder="tt('Please Select')">
				<ElOption v-for="item in taskAssigneeList" :key="item.user" :label="item.user_name" :value="item.user" />
			</ElSelect>
		</div>
	</div>
</template>
<script setup lang="ts">
import {computed, onMounted, ref, watch} from 'vue';
import {ElSelect, ElOption} from 'element-plus';

import type {TaskState, TaskAssignee, Result} from './type';

const tt = __;
const props = defineProps<{
	smallMeta: boolean;
	taskState: string;
	taskPriority: string;
	taskAssignee: string;
	currentOrganization: string;
}>();
const emit = defineEmits<{
	(e: 'update:taskType', type: string): void;
	(e: 'update:taskState', state: string): void;
	(e: 'update:taskPriority', priority: string): void;
	(e: 'update:taskAssignee', assignee: string): void;
}>();
const taskStateList = ref<TaskState[]>([]);
const taskAssigneeList = ref<TaskAssignee[]>([]);
const taskPriorityList = ref<string[]>([]);
const DOCTYPE = 'Tianjy Organization Member';
// 计算查询条件
const taskStateValue = computed({
	get: () => props.taskState || '',
	set: (state: string) =>emit('update:taskState', state),
});
const taskPriorityValue = computed({
	get: () => props.taskPriority || '',
	set: (priority: string) =>emit('update:taskPriority', priority),
});
const taskAssigneeValue = computed({
	get: () => props.taskAssignee || '',
	set: (assignee: string) =>emit('update:taskAssignee', assignee),
});
// 获取任务优先级列表
async function getPriority() {
	await frappe.model.with_doctype('Dw Task');
	const taskMeta = frappe.get_meta('Dw Task');
	const priorityField = taskMeta?.fields.find(item => item.fieldname === 'priority');
	taskPriorityList.value = priorityField?.options?.split('\n') || [];
}
// 获取任务状态列表
async function getTaskStates() {
	const taskStateRes: TaskState[] = await frappe.db.get_list('Dw Task Status', {
		fields: ['name', 'status_name'],
		limit: 0,
	});
	taskStateList.value = taskStateRes;
}
// 获取指派人列表
async function getTaskAssignees() {
	const doctype = `tab${DOCTYPE}`;
	const res = await frappe.call<{message:Result}>({
		method: 'frappe.desk.reportview.get',
		args: {
			doctype: DOCTYPE,
			fields: [
				`\`${doctype}\`.\`user_name\``,
				`\`${doctype}\`.\`user\``,
			],
			filters: [
				['organization', '=', props.currentOrganization],
			],
		},
	});
	const result:Result = res!.message;
	const formatList = result.values.map(data=>{
		const info:any = {};
		for (const [index, key] of result.keys.entries()) {
			info[key] = data[index];
		}
		return info;
	});
	taskAssigneeList.value = formatList || [];
}
watch(() => props.currentOrganization, () => {
	getTaskAssignees();
});
onMounted(async () => {
	getPriority();
	getTaskStates();
	getTaskAssignees();
});
const flexDirection = computed(() => (props.smallMeta ? 'column' : 'row'));
</script>

<style lang="less" scoped>
.toolbar {
	display: flex;
	flex-direction: v-bind(flexDirection);
	flex-wrap: wrap;

	.input-container {
		white-space: nowrap;
		margin-right: 8px;
		margin-bottom: 8px;

		.input {
			min-width: 120px;
		}
	}
}
</style>
