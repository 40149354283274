<template>
	<ElConfigProvider size="small" :locale="zhCn">
		<div class="editor-page">
			<div v-if="!smallMeta&&!hideSidebar" class="sider">
				<Transition name="slide-fade">
					<Directory
						v-show="showSider"
						:content="directoryContent"
						:editorContainer="editorContainerRef"
						@scrollTo="scrollTo"
					/>
				</Transition>
				<ToggleBtn v-model:expand="showSider"></ToggleBtn>
			</div>
			<div class="right-main">
				<Tools v-if="editable" :editor="editor"></Tools>
				<div ref="editorContainerRef" class="container editor-container ck-content">
					<EditorContent :editor="editor" class="editor" />
				</div>
			</div>
		</div>

	</ElConfigProvider>
</template>

<script setup lang='ts'>
import {ref, defineProps, defineEmits, watch, computed, provide} from 'vue';

import {debounce} from 'lodash';
import {ElConfigProvider} from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn.js';
import {useEditor, EditorContent} from '@tiptap/vue-3';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Gapcursor from '@tiptap/extension-gapcursor';
import Document from '@tiptap/extension-document';
import Heading from '@tiptap/extension-heading';
import Image from '@tiptap/extension-image';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import TextAlign from '@tiptap/extension-text-align';

import SlashCommand from './command/commands';
import suggestion from './command/suggestion';
import Chart from './utils/CreateChart';
import Tools from './components/Tools.vue';
import Directory from './components/Directory.vue';
import type {Content} from './type';
import ToggleBtn from './components/ToggleBtn.vue';
import {useMetaQuery} from './utils/useMetaQuery';
const smallMeta = useMetaQuery();
interface Props {
	maxWidth?: number;
	height?: string;
	value:string
	editable?:boolean
	hideSidebar?:boolean
	extensions?:any[]
}

const props = defineProps<Props>();
interface Emit {
	(e: 'change', content: string): void,
}
const emit = defineEmits<Emit>();
const editorContainerRef = ref<HTMLDivElement>();
const showSider = ref<boolean>(true);

provide('editorContainerRef', editorContainerRef);
defineExpose({
	registerBlocks: function() {

	},
});
const content = computed(()=>props.value ? JSON.parse(props.value) : '');
const directoryContent = ref<Content>({type: 'doc'});
watch(()=>props.value, ()=>{
	directoryContent.value = props.value ? JSON.parse(props.value) : {type: 'doc', content: []};
}, {immediate: true});
function saveLayout(json: Content) {
	directoryContent.value = json;
	emit('change', JSON.stringify(json));
}
const updateLayout = debounce(saveLayout, 500);

const editor = useEditor({
	onUpdate: ({editor}: { editor: any; }) => {
		if (!editor.isInitialized) {
			return;
		}
		const json = editor.getJSON();
		updateLayout(json);
	},
	editable: false,
	extensions: [
		Document,
		Heading,
		StarterKit,
		SlashCommand.configure({suggestion}),
		Chart,
		Gapcursor,
		Table.configure({
			resizable: true,
			HTMLAttributes: {
				class: 'tableWrapper',
			},
		}),
		TableRow,
		TableHeader,
		TableCell,
		Placeholder.configure({
			placeholder: ({node}: { node: any; }) => {
				if (node.type.name === 'heading') {
					return __(`Heading {}`, [node.attrs.level - 1]);
				}
				return __('Type / to insert a block');
			},
		}),
		Image.configure({
			inline: true,
			allowBase64: true,
		}),
		BulletList,
		OrderedList,
		ListItem,
		TextAlign.configure({
			types: ['heading', 'paragraph'],
		}),
		...(props.extensions || []),
	],
});

watch([content, editor], () => {
	if (!editor.value) {
		return;
	}
	editor.value.commands.setContent(content.value || '', false);
}, {immediate: true});

watch([()=>props.editable, editor], ()=>{
	if (!editor.value) {
		return;
	}
	editor.value.setEditable(props.editable);
}, {immediate: true});
function scrollTo(node:Element) {
	if (!editorContainerRef.value) {
		return;
	}
	const {top: containerTop} = editorContainerRef.value?.getBoundingClientRect();
	const {top: elTop} = node.getBoundingClientRect();
	const offset = Math.abs(elTop - containerTop + editorContainerRef.value.scrollTop);
	editorContainerRef.value?.scrollTo(0, offset);
}
const maxWidth = computed(() => props.maxWidth || '100%');
const height = computed(() => props.height || 'calc(100vh - 170px)');

const siderWidth = computed(()=>showSider.value ? '250px' : '25px');
</script>

<style lang='less' scoped>
.editor-page{
	display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
	max-width: v-bind(maxWidth);
	height: v-bind(height);
	padding:8px;

}
.sider{
	display: flex;
	width: v-bind(siderWidth);
	border-right:1px solid var(--border-color);
	overflow: auto;
	transition: all 0.5s ease-in-out;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}
.right-main{
	min-width: 1px;
	flex:1;
	height:100%;
	display: flex;
	flex-direction: column;
}
.title {
	height: 75px;
	height: 75px;
	display: flex;
	align-items: center;
	line-height: 75px;
	justify-content: space-between;

	h3 {
		margin: 0;
		word-break: break-all;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.btn-container {
		display: flex;
	}
}

.container {
	margin: 0 auto;
	background: #fff;
}

.editor-container {
	overflow: auto;
	flex:1
}

:deep(.ProseMirror) {
	>*+* {
		margin-top: 0.75em;
	}

	outline: none;
	caret-color: theme('colors.blue.600');
	word-break: break-word;
}

/* Placeholder (on every new line) */
:deep(.ProseMirror p.is-empty::before) {
	content: attr(data-placeholder);
	float: left;
	color: #adb5bd;
	pointer-events: none;
	height: 0;
}
:deep(.ProseMirror h1.is-empty::before) {
	content: attr(data-placeholder);
	float: left;
	color: #adb5bd;
	pointer-events: none;
	height: 0;
}

:deep(.ProseMirror h2.is-empty::before) {
	content: attr(data-placeholder);
	float: left;
	color: #adb5bd;
	pointer-events: none;
	height: 0;
}

:deep(.ProseMirror h3.is-empty::before) {
	content: attr(data-placeholder);
	float: left;
	color: #adb5bd;
	pointer-events: none;
	height: 0;
}

:deep(.ProseMirror h4.is-empty::before) {
	content: attr(data-placeholder);
	float: left;
	color: #adb5bd;
	pointer-events: none;
	height: 0;
}
:deep(.ProseMirror h5.is-empty::before) {
	content: attr(data-placeholder);
	float: left;
	color: #adb5bd;
	pointer-events: none;
	height: 0;
}
:deep(.ProseMirror h6.is-empty::before) {
	content: attr(data-placeholder);
	float: left;
	color: #adb5bd;
	pointer-events: none;
	height: 0;
}
:deep(.ProseMirror) {
	table.tableWrapper {
		border-collapse: collapse;
		table-layout: fixed;
		width: 100%;
		margin: 0;
		overflow: hidden;

		td,
		th {
			min-width: 1em;
			border: 2px solid #ced4da;
			padding: 3px 5px;
			vertical-align: top;
			box-sizing: border-box;
			position: relative;

			>* {
				margin-bottom: 0;
			}
		}

		th {
			font-weight: bold;
			text-align: left;
			background-color: #f1f3f5;
		}

		.selectedCell:after {
			z-index: 2;
			position: absolute;
			content: "";
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: rgba(200, 200, 255, 0.4);
			pointer-events: none;
		}

		.column-resize-handle {
			position: absolute;
			right: -2px;
			top: 0;
			bottom: -2px;
			width: 4px;
			background-color: #adf;
			pointer-events: none;
		}

		p {
			margin: 0;
		}
	}
}

:deep(.ProseMirror) {
	.tableWrapper table {
		border-collapse: collapse;
		table-layout: fixed;
		width: 100%;
		margin: 0;
		overflow: hidden;

		td,
		th {
			min-width: 1em;
			border: 2px solid #ced4da;
			padding: 3px 5px;
			vertical-align: top;
			box-sizing: border-box;
			position: relative;

			>* {
				margin-bottom: 0;
			}
		}

		th {
			font-weight: bold;
			text-align: left;
			background-color: #f1f3f5;
		}

		.selectedCell:after {
			z-index: 2;
			position: absolute;
			content: "";
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: rgba(200, 200, 255, 0.4);
			pointer-events: none;
		}

		.column-resize-handle {
			position: absolute;
			right: -2px;
			top: 0;
			bottom: -2px;
			width: 4px;
			background-color: #adf;
			pointer-events: none;
		}

		p {
			margin: 0;
		}
	}
}

:deep(.tableWrapper) {
	padding: 1rem 0;
	overflow-x: auto;
}

:deep(.resize-cursor) {
	cursor: ew-resize;
	cursor: col-resize;
}

</style>
