<template>
	<div class="toolbar">
		<el-tree-select
			class="dataSelect"
			:size="size"
			:filterable="size === 'small'"
			v-show="organizations.length>0"
			:modelValue="organization"
			:data="organizations"
			defaultExpandAll
			:filterNodeMethod="filterNodeMethod"
			@change="organizationChange"
		/>
		<ElSelect class="dataSelect"
			:size="size"
			:modelValue="pageType"
			@change="pageTypeChange">
			<ElOption
				value="month"
				key="month"
				:label="tt('Monthly Type')" />
			<ElOption
				value="year"
				key="year"
				:label="tt('Yearly')" />
		</ElSelect>
		<ElSelect class="dataSelect"
			:size="size"
			:modelValue="countCategory"
			@change="countCategoryChange">
			<ElOption
				value="date"
				key="date"
				:label="tt('By Date')" />
			<ElOption
				value="category"
				key="category"
				:label="tt('By attendance category')" />
		</ElSelect>
		<ElDatePicker
			class="dataSelect"
			:size="size"
			v-if="pageType === 'month'"
			v-model="monthValue"
			@change="monthChange"
			type="month"
			:clearable="false"
			:placeholder="tt('Select Month')">
		</ElDatePicker>

		<ElDatePicker
			class="dataSelect"
			:size="size"
			v-if="pageType === 'year'"
			v-model="yearValue"
			@change="yearChange"
			type="year"
			:clearable="false"
			:placeholder="tt('Select Year')">
		</ElDatePicker>
		<ElTooltip :content="tt('Log Details')" class="calendar">
			<ElButton class="calendarButton"
				:size="size"
				:icon="Calendar"
				circle
				@click="navigateToRecord"></ElButton>
		</ElTooltip>
		<div class="refresh">
			<ElTooltip :content="tt('Log Details')" class="calendarPhone">
				<ElButton class="calendarButtonPhone"
					:size="size"
					:icon="Calendar"
					circle
					@click="navigateToRecord"></ElButton>
			</ElTooltip>
			<ElButtonGroup :size="size" class="ml-4">
				<ElButton :loading="loading" @click="exportTable">{{ tt('Export') }}</ElButton>
				<ElButton :loading="loading" @click="refresh"
					:icon="RefreshRight">
					{{ tt('Refresh') }}
				</ElButton>
			</ElButtonGroup>
		</div>
	</div>
</template>
<script lang="ts" setup>

import {ref, onMounted, toRefs} from 'vue';
import moment from 'moment';
import {RefreshRight, Calendar} from '@element-plus/icons-vue';
import {GridApi} from 'ag-grid-community';
import {ElSelect, ElOption, ElDatePicker, ElTooltip, ElButton, ElButtonGroup} from 'element-plus';

import {list2Tree} from '../../../../../../../../../guigu_pm/guigu_pm/public/js/utils/index';
import {Organization} from '../type';

import {routeInit, setRoute} from './helper';

const tt = __;

interface Props {
	organization?: string
	pageType?: string
	countCategory?: string
	monthDate?: string
	yearDate?: string
	refreshValue?: number
	detailGridApi?: GridApi
	loading: boolean
	size: string | any
}

const props = defineProps<Props>();
const {organization, pageType, monthDate, yearDate, refreshValue, detailGridApi, loading} = toRefs(props);

interface Emit {
	(e: 'update:organization', organization: string): void,
	(e: 'update:monthDate', monthDate: string): void,
	(e: 'update:yearDate', yearDate: string): void,
	(e: 'update:pageType', type: string): void,
	(e: 'update:countCategory', countCategory: string): void,
	(e: 'update:refreshValue', refreshValue: number): void,
	(e: 'update:loading', loading: boolean): void,
}

const emit = defineEmits<Emit>();

const organizations = ref<Organization[]>([]);
const monthValue = ref<string>(monthDate?.value || moment());
const yearValue = ref<string>(yearDate?.value || '');

const paramsFilter = ref<{ [key: string]: string }>({});
function filterNodeMethod(value: string, data: (FormatDiaryStructure)) {
	return data.label.includes(value);
}
async function onRouteChange() {
	routeInit(paramsFilter);
	const OrganizationRes:{message:Organization[]} = await frappe.call<{message:Organization[]}>({
		method: 'guigu_work_list.guigu_work_list.page.guigu_epc_organization_daily_record.guigu_epc_organization_daily_record.get_organizations',
	});
	const formateData = (OrganizationRes?.message || []).map(item=>({
		...item,
		id: item.name,
		value: item.name,
		parent: item.parent_organization,
	}));
	organizations.value = list2Tree(formateData);
	const organizationNames = (OrganizationRes?.message || []).map(item => item.name);
	const sessionDefaultOrganization: string = (frappe.defaults.get_user_defaults('organization') || [])[0];
	let defaultOrganization: string = '';
	if (organizationNames.includes(paramsFilter.value?.organization)) {
		defaultOrganization = paramsFilter.value?.organization;
	} else {
		defaultOrganization = sessionDefaultOrganization || OrganizationRes.message[0]?.name || '';
	}
	const defaultPageType: string = paramsFilter.value?.pageType || props.pageType || 'month';
	const defaultCountCategory: string = paramsFilter.value?.countCategory || props.countCategory || 'date';
	const monthDate: string = paramsFilter.value?.monthDate || props.monthDate || moment().format('YYYY-MM');
	const yearDate: string = paramsFilter.value?.yearDate || props.yearDate || moment().format('YYYY');
	let routeObj: { [key: string]: any } = {};
	monthValue.value = monthDate;
	yearValue.value = yearDate;
	if (defaultPageType === 'month') {
		routeObj = {organization: defaultOrganization, pageType: defaultPageType, monthDate, yearDate};
		emit('update:pageType', 'month');
	} else {
		routeObj = {organization: defaultOrganization, pageType: defaultPageType, monthDate, yearDate};
		emit('update:pageType', 'year');
	}
	emit('update:countCategory', defaultCountCategory);
	emit('update:monthDate', moment(monthDate).format('YYYY-MM'));
	emit('update:yearDate', yearDate);
	emit('update:organization', defaultOrganization);
	setRoute(paramsFilter, routeObj);
}

onMounted(() => {
	onRouteChange();
});

function exportTable() {
	detailGridApi?.value?.exportDataAsExcel();
}

function organizationChange(newOrganization: string) {
	setRoute(paramsFilter, {organization: newOrganization});
	emit('update:organization', newOrganization);
}

function monthChange(date: moment) {
	const dateValue = date || moment();
	setRoute(paramsFilter, {monthDate: moment(dateValue).format('YYYY-MM')});
	monthValue.value = dateValue;
	emit('update:monthDate', moment(dateValue).format('YYYY-MM'));
}

function yearChange(date: moment) {
	const dateValue = date || moment();
	setRoute(paramsFilter, {yearDate: moment(dateValue).format('YYYY')});
	yearValue.value = dateValue;
	emit('update:yearDate', moment(dateValue).format('YYYY'));
}
function getLastDayOfMonth(year: number, month: number) {
	const date = new Date(year, month - 1, 2);
	date.setMonth(date.getMonth() + 1);
	date.setDate(date.getDate() - 1);
	return date;
}
function getYearLastDate(year: number) {
	return new Date(year, 11, 32);
}
function setRouteForPageType(pageType: string, dateStr: string | number | Date) {
	const currentDate = new Date();
	const targetDate = new Date(dateStr);
	targetDate.setDate(targetDate.getDate() - 1);
	const todayDate = targetDate < currentDate ? targetDate : currentDate;
	const filter = {
		organization: paramsFilter.value?.organization,
		date: moment(todayDate).format('YYYY-MM-DD'),
	};
	const url = `?organization=${filter.organization}&date=${filter.date}`;
	frappe.router.push_state(`/app/guigu_epc_organization_daily_record${url}`);
}

function navigateToRecord() {
	if (paramsFilter.value?.pageType === 'month') {
		const monthDateStr = paramsFilter.value?.monthDate;
		const [year, month] = monthDateStr.split('-').map(Number);
		const lastDayOfLastMonth = getLastDayOfMonth(year, month);
		setRouteForPageType(paramsFilter.value?.pageType, moment(lastDayOfLastMonth).format('YYYY-MM-DD'));
	} else if (paramsFilter.value?.pageType === 'year') {
		const yearDateStr = paramsFilter.value?.yearDate;
		const year = Number(yearDateStr);
		const lastDayOfYear = getYearLastDate(year);
		setRouteForPageType(paramsFilter.value?.pageType, moment(lastDayOfYear).format('YYYY-MM-DD'));
	}
}

function pageTypeChange(type: string) {
	setRoute(paramsFilter, {pageType: type});
	emit('update:pageType', type);
}

function countCategoryChange(cc: string) {
	setRoute(paramsFilter, {countCategory: cc});
	emit('update:countCategory', cc);
}

function refresh() {
	const newValue: number = (refreshValue?.value || 0) + 1;
	emit('update:loading', true);
	emit('update:refreshValue', newValue);
}
</script>
<style lang="less" scoped>
.toolbar {
	.calendar{
		margin-left: 10px;
	}
}

@media (max-width: 640px) {
	.toolbar{
		display: flex;
		flex-direction: column;
	}
	.calendarButton{
		display: none !important;
	}
	.calendarButtonPhone{
		margin-right: 8px !important;
	}
	:deep(.el-date-editor.el-input) {
			width: 100% !important;
			margin-bottom: 8px !important;
			margin-top:0px !important
	};
	:deep(.ml-4) {
		margin-left: 0px !important;
	};
	.dataSelect{
		margin-bottom: 8px !important;
	}
	.refresh{
		margin-top: 0px !important;
	};
}
@media (min-width: 640px) {
	.refresh {
		float: right;
		margin-top: 8px !important;
	}
	.calendarButtonPhone{
		display: none !important;
	}
	.calendarButton{
		margin-top: 8px !important;
	}
	:deep(.el-input__inner) {
		height:22px !important
	}
	:deep(.el-date-editor.el-input) {
		margin-right: 8px !important;
		margin-top: 8px !important;
	}
	.dataSelect{
		margin-right: 8px !important;
		margin-top: 8px !important;
	}
}

</style>
