<template>
	<div class="flex col page-actions justify-content-end">
		<!-- buttons -->
		<div class="custom-actions hidden-xs hidden-md">
			<ViewSwitch />
		</div>
		<div class="standard-actions flex">
			<span class="page-icon-group hidden-xs hidden-sm">
				<button
					class="text-muted btn btn-default icon-btn" title="刷新" @click="$emit('refresh')">
					<svg class="icon  icon-sm" style="">
						<use class="" href="#icon-refresh"></use>
					</svg>
				</button>
			</span>
			<slot />
			<div class="menu-btn-group">
				<button type="button" class="btn btn-default icon-btn" data-toggle="dropdown"
					aria-expanded="false" title="菜单">
					<span>
						<span class="menu-btn-group-label" data-label="">
							<svg class="icon icon-sm">
								<use href="#icon-dot-horizontal"></use>
							</svg>
						</span>
					</span>
				</button>
				<ul class="dropdown-menu dropdown-menu-right" role="menu">
					<li class="user-action">
						<a class="grey-link dropdown-item visible-xs" href="#" @click.prevent="$emit('refresh')">
							<span class="menu-item-label" data-label="%E5%88%B7%E6%96%B0">刷新</span>
						</a>
					</li>
					<li class="dropdown-divider user-action visible-xs"></li>
					<slot name="menu" />
					<MenuBtn
						v-if="importable"
						:title='t("Import", null, "Button in list view menu")'
						@click="importData"
					/>
					<MenuBtn
						v-if="can_set_user_permissions"
						:title='t("User Permissions", null, "Button in list view menu")'
						@click="setUserPermissions"
					/>
					<MenuBtn
						v-if="systemManager"
						:title='t("Role Permissions Manager", null, "Button in list view menu")'
						@click="managePermission"
					/>
					<MenuBtn
						v-if="customizable"
						:title='t("Customize", null, "Button in list view menu")'
						shortcut="Ctrl+J"
						@click="customize"
					/>
				</ul>
			</div>
			<button v-if="secondaryTitle" @click="$emit('secondary')" class="btn btn-secondary btn-default btn-sm">{{secondaryTitle}}</button>
			<!-- <div class="actions-btn-group hide">
				<button type="button" class="btn btn-primary btn-sm" data-toggle="dropdown"
					aria-expanded="false">
					<span class="hidden-xs">
						<span class="actions-btn-group-label">操作</span>
						<svg class="icon icon-xs">
							<use href="#icon-select"></use>
						</svg>
					</span>
				</button>
				<ul class="dropdown-menu dropdown-menu-right" role="menu">
				</ul>
			</div> -->
			<button v-if="primaryTitle" @click="$emit('primary')" class="btn btn-primary btn-sm primary-action">{{primaryTitle}}</button>
		</div>
	</div>

</template>

<script lang="ts">
import ViewSwitch from './ViewSwitch.vue';
import { defineComponent, inject } from 'vue'
import MenuBtn from './MenuBtn.vue'
export default defineComponent({
	components: { ViewSwitch, MenuBtn },
	props: {
		primaryTitle: String,
		secondaryTitle: String,
	},
	setup(p, ctx) {
		const {
			doctype,
			meta,
		} = (inject<() => frappe.guigu.ViewContext>('__guigu__define__view__')!)();
		const importable = Boolean(frappe.model.can_import(doctype, null, meta));
		const can_set_user_permissions = Boolean(frappe.model.can_set_user_permissions(doctype));
		const systemManager = Boolean(frappe.user_roles.includes("System Manager"));
		const customizable = Boolean(
			meta && frappe.model.can_create("Custom Field") &&
			frappe.model.can_create("Property Setter")
		);
		function importData() {
			frappe.set_route("list", "data-import", { reference_doctype: doctype })
		}
		function setUserPermissions() {
			frappe.set_route("list", "user-permission", { allow: doctype })
		}
		function managePermission() {
			frappe.set_route("permission-manager", { doctype })
		}
		function customize() {
			if (meta.custom) {
				frappe.set_route("form", "doctype", doctype);
			} else if (!meta.custom) {
				frappe.set_route("form", "customize-form", {
					doc_type: doctype,
				});
			}
		}
		return {
			importable, importData,
			can_set_user_permissions, setUserPermissions,
			systemManager, managePermission,
			customizable, customize,
			t: __,
		};
	},
});
</script>
