import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b8340741"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElButton = _resolveComponent("ElButton")
  const _component_ElTooltip = _resolveComponent("ElTooltip")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElButton, {
      icon: $setup.DArrowLeft,
      link: "",
      onClick: _cache[0] || (_cache[0] = $event => ($setup.changeYear('minus')))
    }, null, 8 /* PROPS */, ["icon"]),
    _createVNode(_component_ElTooltip, { placement: "top-start" }, {
      content: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString($setup.tt('Year')) + "：" + _toDisplayString($setup.yearPeriods[$setup.yearIndex]?.value), 1 /* TEXT */),
        _createElementVNode("p", null, _toDisplayString($setup.tt('Start Date')) + "：" + _toDisplayString($setup.yearPeriods[$setup.yearIndex]?.start_date), 1 /* TEXT */),
        _createElementVNode("p", null, _toDisplayString($setup.tt('End Date')) + "：" + _toDisplayString($setup.yearPeriods[$setup.yearIndex]?.end_date), 1 /* TEXT */)
      ]),
      default: _withCtx(() => [
        _createTextVNode(" " + _toDisplayString($setup.yearPeriods[$setup.yearIndex]?.value) + _toDisplayString($setup.tt('Year')), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_ElButton, {
      icon: $setup.DArrowRight,
      link: "",
      onClick: _cache[1] || (_cache[1] = $event => ($setup.changeYear('add')))
    }, null, 8 /* PROPS */, ["icon"])
  ]))
}