import { createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-96e2c312"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tools container"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.editor)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode($setup["ElTooltip"], {
          content: $setup.tt('Bold')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElButton"], {
              class: _normalizeClass({ 'is-active': $props.editor.isActive('bold') }),
              onClick: _cache[0] || (_cache[0] = $event => ($props.editor.chain().focus().toggleBold().run()))
            }, {
              default: _withCtx(() => [
                _createVNode($setup["Bold"], { size: 16 })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["class"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"]),
        _createVNode($setup["ElTooltip"], {
          content: $setup.tt('Italic')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElButton"], {
              class: _normalizeClass({ 'is-active': $props.editor.isActive('italic') }),
              onClick: _cache[1] || (_cache[1] = $event => ($props.editor.chain().focus().toggleItalic().run()))
            }, {
              default: _withCtx(() => [
                _createVNode($setup["Italic"], { size: 16 })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["class"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"]),
        _createVNode($setup["ElTooltip"], {
          content: $setup.tt('Delete Line')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElButton"], {
              class: _normalizeClass({ 'is-active': $props.editor.isActive('strike') }),
              onClick: _cache[2] || (_cache[2] = $event => ($props.editor.chain().focus().toggleStrike().run()))
            }, {
              default: _withCtx(() => [
                _createVNode($setup["Strikethrough"], { size: 16 })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["class"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"]),
        _createVNode($setup["ElTooltip"], {
          content: $setup.tt('align-left')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElButton"], {
              onClick: _cache[3] || (_cache[3] = $event => ($props.editor.chain().focus().setTextAlign('left').run()))
            }, {
              default: _withCtx(() => [
                _createVNode($setup["AlignLeft"], { size: 16 })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"]),
        _createVNode($setup["ElTooltip"], {
          content: $setup.tt('Center alignment')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElButton"], {
              onClick: _cache[4] || (_cache[4] = $event => ($props.editor.chain().focus().setTextAlign('center').run()))
            }, {
              default: _withCtx(() => [
                _createVNode($setup["AlignCenter"], { size: 16 })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"]),
        _createVNode($setup["ElTooltip"], {
          content: $setup.tt('Automatic alignment')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElButton"], {
              onClick: _cache[5] || (_cache[5] = $event => ($props.editor.chain().focus().setTextAlign('justify').run()))
            }, {
              default: _withCtx(() => [
                _createVNode($setup["AlignJustify"], { size: 16 })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"]),
        _createVNode($setup["ElTooltip"], {
          content: $setup.tt('align-right')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElButton"], {
              onClick: _cache[6] || (_cache[6] = $event => ($props.editor.chain().focus().setTextAlign('right').run()))
            }, {
              default: _withCtx(() => [
                _createVNode($setup["AlignRight"], { size: 16 })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"]),
        _createVNode($setup["ElTooltip"], {
          content: $setup.tt('Insert Table')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElButton"], {
              onClick: _cache[7] || (_cache[7] = $event => {$props.editor.chain().focus()
				.insertTable({ rows: 3, cols: 3, withHeaderRow: true })
				.run();})
            }, {
              default: _withCtx(() => [
                _createVNode($setup["Table"], { size: 16 })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"]),
        _createVNode($setup["ElTooltip"], {
          content: $setup.tt('Title One')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElButton"], {
              onClick: _cache[8] || (_cache[8] = $event => {$props.editor.chain().focus()
				.setNode('heading', { level: 2 })
				.run();})
            }, {
              default: _withCtx(() => [
                _createVNode($setup["Heading1"], { size: 16 })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"]),
        _createVNode($setup["ElTooltip"], {
          content: $setup.tt('Title Two')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElButton"], {
              onClick: _cache[9] || (_cache[9] = $event => {$props.editor.chain().focus()
				.setNode('heading', { level: 3 })
				.run();})
            }, {
              default: _withCtx(() => [
                _createVNode($setup["Heading2"], { size: 16 })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"]),
        _createVNode($setup["ElTooltip"], {
          content: $setup.tt('Title Three')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElButton"], {
              onClick: _cache[10] || (_cache[10] = $event => {$props.editor.chain().focus()
				.setNode('heading', { level: 4 })
				.run();})
            }, {
              default: _withCtx(() => [
                _createVNode($setup["Heading3"], { size: 16 })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"]),
        _createVNode($setup["ElTooltip"], {
          content: $setup.tt('Title Four')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElButton"], {
              onClick: _cache[11] || (_cache[11] = $event => {$props.editor.chain().focus()
				.setNode('heading', { level: 5 })
				.run();})
            }, {
              default: _withCtx(() => [
                _createVNode($setup["Heading4"], { size: 16 })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"]),
        _createVNode($setup["ElTooltip"], {
          content: $setup.tt('Title Five')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElButton"], {
              onClick: _cache[12] || (_cache[12] = $event => {$props.editor.chain().focus()
				.setNode('heading', { level: 6 })
				.run();})
            }, {
              default: _withCtx(() => [
                _createVNode($setup["Heading5"], { size: 16 })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"]),
        _createVNode($setup["ElTooltip"], {
          content: $setup.tt('Insert Image')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElButton"], { onClick: $setup.insetImage }, {
              default: _withCtx(() => [
                _createVNode($setup["Image"], { size: 16 })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"]),
        _createVNode($setup["ElTooltip"], {
          content: $setup.tt('Ordered List')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElButton"], {
              onClick: _cache[13] || (_cache[13] = $event => {$props.editor.chain().focus().toggleOrderedList().run();})
            }, {
              default: _withCtx(() => [
                _createVNode($setup["ListOrdered"], { size: 16 })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"]),
        _createVNode($setup["ElTooltip"], {
          content: $setup.tt('Unordered list')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElButton"], {
              onClick: _cache[14] || (_cache[14] = $event => {$props.editor.chain().focus().toggleBulletList().run();})
            }, {
              default: _withCtx(() => [
                _createVNode($setup["List"], { size: 16 })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$CustomBlocks, (item) => {
          return (_openBlock(), _createBlock(_resolveDynamicComponent(item.tool), {
            onClick: ()=>$setup.insertCustomBlock(item),
            editor: $props.editor
          }, null, 8 /* PROPS */, ["onClick", "editor"]))
        }), 256 /* UNKEYED_FRAGMENT */))
      ]))
    : _createCommentVNode("v-if", true)
}