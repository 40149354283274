function removeHeaders() {
	const root = document.firstElementChild;
	if (root) {
		(root as HTMLElement).style.setProperty('--navbar-height', '0');
	}
	function remove() {
		const headers = document.getElementsByTagName('header');
		for (let i = 0; i < headers.length; i++) {
			headers[i].hidden = true;
		}
		requestAnimationFrame(removeHeaders);
	}
	remove();
}

if (window.top !== window) {
	removeHeaders();
}
