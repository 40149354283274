import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "action_container"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (!$setup.hideAction)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode($setup["ElButton"], {
          link: "",
          type: "primary",
          onClick: $setup.unlink
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Cancel Process')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ]))
    : _createCommentVNode("v-if", true)
}