
export const extendFields = new Map<string, [string, string, Partial<locals.DocField>, string][]>([
	['Date', [
		['year', __('Year'), { options: 'Year' }, 'dateExtField'],
		['month', __('Month'), { options: 'Month' }, 'dateExtField'],
		['week', __('Week'), { options: 'Week' }, 'dateExtField'],
	]],
]);
export const layouts = [
	{ label: __('Jump to page'), value: 'link' },
	{ label: __('Details Dialog'), value: 'modal' },
	{ label: __('Horizontal Layout'), value: 'right' },
	{ label: __('vertical layout'), value: 'bottom' },
];
