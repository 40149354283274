/* eslint-disable no-param-reassign */
function getFieldName(dt: string, field: string) {
	return frappe.model.get_full_column_name(field, dt);
}

function getParentField(meta: locals.DocType,) {
	const parentField = meta.nsm_parent_field;
	if (!parentField) { return; }
	const field = meta.fields.find(f => f.fieldname === parentField);
	if (!field) { return; }
	if (field.fieldtype !== 'Link') { return; }
	if (field.options !== meta.name) { return; }
	return parentField;
}
function getTitleField(meta: locals.DocType,) {
	const titleField = meta.title_field;
	if (!titleField) { return; }
	const field = meta.fields.find(f => f.fieldname === titleField);
	if (!field) { return; }
	return titleField;
}
async function request(
	doctype: string,
	fields: string[],
	filters: [string, string, string, any][],
	order: [string, string, boolean?][],
	orFilters: [string, string, string, any][][],
	group: {
		doctype: string;
		field: string;
	}[] = [],
) {


	const data = await frappe.call('frappe.desk.reportview.get', {
		doctype, fields, filters,
		order_by: order
			.map(([doctype, field, desc]) =>
				`${getFieldName(doctype, field)} ${desc ? 'DESC' : 'ASC'}`)
			.join(', ') || undefined,
		page_length: 0,
		view: 'List',
		guigu_or_filters: orFilters,
		group_by: group
			.map(({ doctype, field }) => getFieldName(doctype, field))
			.join(', ') || getFieldName(doctype, 'name'),
	}).then((v: any) => v.message || {});
	Object.assign(frappe.boot.user_info, data.user_info);
	const values = !Array.isArray(data)
		? frappe.utils.dict(data.keys, data.values)
		: data;

	return values;
}

async function requestDocList(
	meta: locals.DocType,
	filters: [string, string, string, any][],
	orFilters: [string, string, string, any][][],
	order: [string, string, boolean?][],
	group?: { doctype: string; field: string; }[],
) {
	const doctype = meta.name;
	const fields = [getFieldName(doctype, 'name')];

	const titleField = getTitleField(meta);
	const parentField = getParentField(meta);
	if (!parentField) {
		if (titleField) {
			fields.push(`${getFieldName(doctype, titleField)} as \`label\``);
		}
		return request(doctype, fields, filters, order, orFilters, group);

	}
	fields.push(`${getFieldName(doctype, parentField)} as \`parent\``);
	const values = await request(doctype, fields, filters, order, orFilters, group);
	if (!titleField) { return values; }
	try {
		const data = await request(doctype,
			['name', `\`${titleField}\` as \`label\``],
			[[doctype, 'name', 'in', values.map(v => v.name)]],
			[],
			[],
		);
		const map = new Map(data.map(v => [v.name, v.label]));
		for (const v of values) {
			v.label = map.get(v.name);
		}
	} catch {

	}
	return values;
}

export interface Tree {
	name?: string;
	label: string
	children?: Tree[]
}

export default async function loadTree(
	doctype?: string,
	filters: [string, string, string, any][] = [],
	orFilters: [string, string, string, any][][] = [],
	order: [string, string, boolean?][] = [],
	group?: { doctype: string; field: string; }[],
) {
	if (!doctype) { return []; }

	await new Promise<void>(r => frappe.model.with_doctype(doctype, r));

	const meta = frappe.get_meta(doctype);
	if (!meta) { return []; }
	const list = await requestDocList(meta, filters, orFilters, order, group);
	const map = new Map<string, Tree>(list.map(v => [v.name, v]));
	const root: Tree[] = [];
	for (const n of list) {
		n.label ||= n.name;
		const { parent } = n;
		const p = parent && map.get(parent);
		if (!p) {
			root.push(n);
			continue;
		}
		const { children } = p;
		if (children) {
			children.push(n);
		} else {
			p.children = [n];
		}
	}
	return root;
}


export function findNode(tree:Tree[], name:string):Tree|undefined{
	for (let i in tree) {
		if(tree[i].name==name){
			return tree[i]
		}
		if(tree[i].children){
			const node = findNode(tree[i].children||[], name)
			if(node){
				return node
			}
		}
	}   
}
export function getChildren(list:Tree[],newNodeId:Tree[]=[]) {
	for (let i in list) {
		newNodeId.push(list[i])
	  	if(list[i].children){
			getChildren(list[i].children||[],newNodeId);
		}
	} 
	return newNodeId;      
}
