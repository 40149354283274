import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e99e9e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-container flex w-40 flex-col rounded-md border bg-white p-1 text-base shadow" }
const _hoisted_2 = ["onClick", "onMouseenter"]
const _hoisted_3 = {
  key: 1,
  class: "item"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($options.enabledItems.length)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($options.enabledItems, (item, index) => {
          return (_openBlock(), _createElementBlock("button", {
            class: _normalizeClass(["button flex h-8 w-full cursor-pointer items-center rounded px-1 text-base", { 'bg-gray-100': index === $data.selectedIndex }]),
            key: index,
            onClick: $event => ($options.selectItem(index)),
            onMouseenter: $event => ($data.selectedIndex = index)
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon || 'Minus'), { class: "mr-2 h-4 w-4 text-gray-500" })),
            _createTextVNode(" " + _toDisplayString(item.title), 1 /* TEXT */)
          ], 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_2))
        }), 128 /* KEYED_FRAGMENT */))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, "No result"))
  ]))
}