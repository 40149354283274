<template>
	<div class="tools container" v-if="editor">
		<ElTooltip :content="tt('Bold')">
			<ElButton :class="{ 'is-active': editor.isActive('bold') }" @click="editor.chain().focus().toggleBold().run()">
				<Bold :size="16"></Bold>
			</ElButton>
		</ElTooltip>
		<ElTooltip :content="tt('Italic')">
			<ElButton :class="{ 'is-active': editor.isActive('italic') }"
				@click="editor.chain().focus().toggleItalic().run()">
				<Italic :size="16"></Italic>
			</ElButton>
		</ElTooltip>
		<ElTooltip :content="tt('Delete Line')">
			<ElButton :class="{ 'is-active': editor.isActive('strike') }"
				@click="editor.chain().focus().toggleStrike().run()">
				<Strikethrough :size="16"></Strikethrough>
			</ElButton>
		</ElTooltip>
		<ElTooltip :content="tt('align-left')">
			<ElButton @click="editor.chain().focus().setTextAlign('left').run()">
				<AlignLeft :size="16"></AlignLeft>
			</ElButton>
		</ElTooltip>
		<ElTooltip :content="tt('Center alignment')">
			<ElButton @click="editor.chain().focus().setTextAlign('center').run()">
				<AlignCenter :size="16"></AlignCenter>
			</ElButton>
		</ElTooltip>
		<ElTooltip :content="tt('Automatic alignment')">
			<ElButton @click="editor.chain().focus().setTextAlign('justify').run()">
				<AlignJustify :size="16"></AlignJustify>
			</ElButton>
		</ElTooltip>
		<ElTooltip :content="tt('align-right')">
			<ElButton @click="editor.chain().focus().setTextAlign('right').run()">
				<AlignRight :size="16"></AlignRight>
			</ElButton>
		</ElTooltip>
		<ElTooltip :content="tt('Insert Table')">
			<ElButton @click="editor.chain().focus()
				.insertTable({ rows: 3, cols: 3, withHeaderRow: true })
				.run();">
				<Table :size="16"></Table>
			</ElButton>
		</ElTooltip>
		<ElTooltip :content="tt('Title One')">
			<ElButton @click="editor.chain().focus()
				.setNode('heading', { level: 2 })
				.run();">
				<Heading1 :size="16"></Heading1>
			</ElButton>
		</ElTooltip>
		<ElTooltip :content="tt('Title Two')">
			<ElButton @click="editor.chain().focus()
				.setNode('heading', { level: 3 })
				.run();">
				<Heading2 :size="16"></Heading2>
			</ElButton>
		</ElTooltip>
		<ElTooltip :content="tt('Title Three')">
			<ElButton @click="editor.chain().focus()
				.setNode('heading', { level: 4 })
				.run();">
				<Heading3 :size="16"></Heading3>
			</ElButton>
		</ElTooltip>
		<ElTooltip :content="tt('Title Four')">
			<ElButton @click="editor.chain().focus()
				.setNode('heading', { level: 5 })
				.run();">
				<Heading4 :size="16"></Heading4>
			</ElButton>
		</ElTooltip>
		<ElTooltip :content="tt('Title Five')">
			<ElButton @click="editor.chain().focus()
				.setNode('heading', { level: 6 })
				.run();">
				<Heading5 :size="16"></Heading5>
			</ElButton>
		</ElTooltip>
		<ElTooltip :content="tt('Insert Image')">
			<ElButton @click="insetImage">
				<Image :size="16"></Image>
			</ElButton>
		</ElTooltip>
		<ElTooltip :content="tt('Ordered List')">
			<ElButton @click="editor.chain().focus().toggleOrderedList().run();">
				<ListOrdered :size="16"></ListOrdered>
			</ElButton>
		</ElTooltip>
		<ElTooltip :content="tt('Unordered list')">
			<ElButton @click="editor.chain().focus().toggleBulletList().run();">
				<List :size="16"></List>
			</ElButton>
		</ElTooltip>
		<component
			v-for="item in $CustomBlocks"
			:is="item.tool"
			@click="()=>insertCustomBlock(item)"
			:editor="editor"
		>
		</component>
	</div>
</template>

<script setup lang='ts'>
import {defineProps} from 'vue';

import {
	Bold, Italic, Strikethrough, AlignLeft, AlignCenter, AlignRight, AlignJustify, Table,
	Heading1, Heading2, Heading3, Heading4, Heading5, Image, ListOrdered, List,
} from 'lucide-vue-next';
import {ElButton, ElTooltip} from 'element-plus';
import type {Editor} from '@tiptap/vue-3';

import type {CustomBlock} from '../type';

const tt = __;

interface Props {
	editor?: Editor;
}
const props = defineProps<Props>();

function insetImage() {
	if (!props.editor) {
		return;
	}
	const input = document.createElement('input');
	input.setAttribute('type', 'file');
	input.setAttribute('accept', 'image/*');
	input.addEventListener('change', (event:any) => {
		if (!event.target?.files || event.target.files?.length === 0) {
			return;
		}
		const [file] = event.target.files;
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			const url = reader.result as string;
			props.editor?.chain().focus().setImage({src: url})
				.run();
		};
	});
	input.click();
}

async function insertCustomBlock(cusBlock:CustomBlock) {
	if (!props.editor) {
		return;
	}
	let isContinue:boolean|undefined = true;
	if (typeof cusBlock.beforeInsert === 'function') {
		isContinue = await cusBlock.beforeInsert(props.editor);
	}
	if (isContinue === false) {
		return;
	}

	const {from, to} = props.editor.state.selection;
	const customNodes = props.editor.$nodes('chart') || [];
	const isInSelection = customNodes?.some(item=>item.from >= from && item.to <= to);
	const isSelectedChart = props.editor.state.selection?.node?.type?.name === 'chart';
	if (isInSelection || isSelectedChart) {
		frappe.show_alert({
			indicator: 'orange',
			message: __('Please insert in the blank space'),
		});
		return;
	}
	props.editor.chain().focus()
		.insertContent(`<chart data-type="draggable-item" chart_type="${cusBlock.name}"></chart>`)
		.run();
}
</script>

<style lang='less' scoped>
.tools {
	display: flex;
	flex-flow: wrap;
	align-items: center;

	:deep(button) {
		margin: 5px;
	}
}
</style>
