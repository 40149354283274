<template>
	<div class="toolbar">
		<ElButton v-if="showGaterButton" type="primary" @click="showGaterTable">
			{{ isShowGaterTable ? tt('View the progress of this structure') : tt('View summary progress') }}</ElButton>
		<ElButton v-show="!isShowGaterTable" type="primary" @click="click">{{ tt('Batch Add Structure Progress') }}
		</ElButton>
	</div>
	<StructureProgressTable
		v-show="!isShowGaterTable"
		:structureProgresses="structureProgresses || []"
		:enableAudit="enableAudit"
		:project="project"
		@deleteStructureProgress="deleteStructureProgress"
		@clickComplete="clickComplete"
		@change="structureProgressChange"></StructureProgressTable>
	<ProgressGather
		v-show="isShowGaterTable"
		:structureName="structureName"
		:project="project"></ProgressGather>
	<BulkProgressModal
		:visible="visible"
		:selectedProgresses="selectedProgressesNames"
		:progressItems="progressItems"
		:project="project"
		@close="closeInfo"
		@confirm="confirm"></BulkProgressModal>
</template>
<script lang="ts" setup>
import { ref, getCurrentInstance, watchEffect, computed, watch, onMounted } from 'vue';

import { ProgressItem } from '../../type';
import { OriginStructureProgress } from '../../type/StructureProgress';
import BulkProgressModal from '../BulkProgressModal.vue';
import ProgressGather from '../ProgressGather/index.vue';

import StructureProgressTable from './StructureProgress.vue';
const tt = __
const { appContext } = getCurrentInstance() || {};
const structureName = ref<string>(appContext?.config.globalProperties.$frm?.doc?.name);
const structureProgresses = ref<OriginStructureProgress[]>([]);
const project = ref<string>(appContext?.config.globalProperties.$frm?.doc?.project || '');
const enableAudit = ref<boolean>(false);
const visible = ref<boolean>(false);
const isShowGaterTable = ref<boolean>(false);
const progressItems = ref<ProgressItem[]>([]);

interface Emit {
	(e: 'clickComplete', data: OriginStructureProgress): void
}
const emit = defineEmits<Emit>();
frappe.ui.form.on('Guigu Construction Structure', {
	setup(frm: any) {
		structureName.value = frm.doc.name;
		structureProgresses.value = frm.doc.progress_item || [];
	},
	refresh(frm: any) {
		structureName.value = frm.doc.name;
		structureProgresses.value = frm.doc.progress_item || [];
	},
	project(frm: any) {
		project.value = frm.doc.project;
	},
	progress_item(frm: any) {
		structureProgresses.value = frm.doc.progress_item || [];
	},
});

onMounted(async () => {
	const progressItemRes: ProgressItem[] = await frappe.db.get_list('Guigu Construction Progress Item',
		{
			fields: ['*'],
			filters: { project: project.value, is_temp_item: 0 },
			limit: '',
		}
	);
	progressItems.value = progressItemRes || [];
	structureProgresses.value = appContext?.config.globalProperties.$frm?.doc?.progress_item;
});

watch(project, async () => {
	const data = await frappe.db.get_list('Guigu Construction Project Setting', {
		fields: ['*'],
		filters: { project: project.value },
		limit: 0,
	});
	enableAudit.value = data[0]?.use_audit_volume === 'Yes';
}, { immediate: true });

function deleteStructureProgress(structureProgressId: string) {
	structureProgresses.value = structureProgresses.value.filter(item => item.structure_progress_name !== structureProgressId);
}
const showGaterButton = computed(() => appContext?.config.globalProperties.$frm?.get_doc()?.structure_children?.length > 0);
const selectedProgressesNames = computed(() => (structureProgresses.value || [])?.map(item => item.progress_item));

function click() {
	visible.value = true;
}
function showGaterTable() {
	isShowGaterTable.value = !isShowGaterTable.value;
}
function closeInfo() {
	visible.value = false;
}
function confirm(newStructureProgress: OriginStructureProgress[]) {
	structureProgresses.value = structuredClone(newStructureProgress);
	visible.value = false;
}
function clickComplete(data: OriginStructureProgress) {
	emit('clickComplete', data);
}
function structureProgressChange(newStructureProgress: OriginStructureProgress[]) {
	structureProgresses.value = structuredClone(newStructureProgress);
}
</script>
<style scoped>
.toolbar {
	display: flex;
	justify-content: flex-end;
	margin: 8px 0;
}
</style>
