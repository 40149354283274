import { Ref, computed, shallowReactive, shallowRef, toRaw, unref } from 'vue';

import { defineStore } from 'pinia';

export type ExpanderItem = {
	set(): void,
	key: string | number,
	title: string | [string, Record<string, string>]
};

export type ExpanderValue = {
	value: Iterable<string> | (() => Iterable<string>),
	key?: string | number,
	title: string | [string, Record<string, string>]
};

const ss = defineStore('Guigu:GlobalView:Expander', () => {
	const data = shallowReactive<Record<string, {
		list: ExpanderItem[],
		fold: () => void,
	}>>(Object.create(null));
	return { data };
});
export default function useExpander(view: Ref<string> | string = '') {
	const s = ss();
	const state = computed({
		get: () => {
			const { data } = s;
			const raw = toRaw(data);
			const v = unref(view);
			if (!(v in raw)) {
				raw[v] = {
					list: [],
					fold: () => { },
				};
			}
			return shallowReactive(data[v]);
		}, set: d => {
			s.data[unref(view)] = d;
		},
	});
	const expandable = computed(() => state.value.list);

	let i = 0;
	function setExpandable(values: ExpanderValue[], cb: (list: string[]) => void) {

		state.value = {
			list: values.map(({ value, ...v }) => ({
				...v, key: i++, set: () => {
					cb([...new Set(typeof value === 'function' ? value() : value)]);
				},
			})),
			fold: () => cb([]),
		};
	}

	return {
		expandable, fold() { state.value.fold(); }, setExpandable,
	};
}
