import type { Row } from '../types/Row';
import binarySearch from '../utils/binarySearch';
function* getList(row: Row, expanded: Set<string | number>): Iterable<number> {
	if (!expanded.has(row.id)) { return; }
	for (const r of row.children) {
		yield r.index;
		yield* getList(r, expanded);
	}
}
export function rowVisible(
	row: Row,
	rowMap: Map<string | number, Row>,
	expanded: Set<string | number>,
) {
	let parent = rowMap.get(row.parentId!);
	while (parent) {
		if (!expanded.has(parent.id)) { return false; }
		parent = rowMap.get(parent.parentId!);
	}
	return true;
}

export default function updateCollapse2(
	row: Row,
	closed: boolean,
	rowMap: Map<string | number, Row>,
	expanded: Set<string | number>,
	visible: number[],
) {
	if (!row.children.length) { return false; }
	if (!rowVisible(row, rowMap, expanded)) { return false; }
	// 找到第一个和最后一个 // this.rowData.indexOf(row);
	const { index } = row;
	let first = binarySearch(index + 1, visible, 0, visible.length - 1);
	if (first < 0) { return false; }
	let last = binarySearch(index + row.descendants.length, visible, 0, visible.length - 1);
	if (visible[last] > index + row.descendants.length) { last--; }
	const length = last - first + 1;
	visible.splice(first, length);
	if (closed) { return true; }
	let k = first;
	for (const index of getList(row, expanded)) {
		visible.splice(k, 0, index);
		k++;
	}
	return true;
}
