export interface Option {
	label: string;
	value: any;
}
export const timespan: Option[] = [
	{ label: __('Last Week'), value: 'last week' },
	{ label: __('Last Month'), value: 'last month' },
	{ label: __('Last Quarter'), value: 'last quarter' },
	{ label: __('Last 6 months'), value: 'last 6 months' },
	{ label: __('Last Year'), value: 'last year' },
	{ label: __('Yesterday'), value: 'yesterday' },
	{ label: __('Today'), value: 'today' },
	{ label: __('Tomorrow'), value: 'tomorrow' },
	{ label: __('This Week'), value: 'this week' },
	{ label: __('This Month'), value: 'this month' },
	{ label: __('This Quarter'), value: 'this quarter' },
	{ label: __('This Year'), value: 'this year' },
	{ label: __('Next Week'), value: 'next week' },
	{ label: __('Next Month'), value: 'next month' },
	{ label: __('Next Quarter'), value: 'next quarter' },
	{ label: __('Next 6 months'), value: 'next 6 months' },
	{ label: __('Next Year'), value: 'next year' },
];
export const is: Option[] = [
	{ label: __('Set', null, 'Field value is set'), value: 'set' },
	{ label: __('Not Set', null, 'Field value is not set'), value: 'not set' },
];

export const check: Option[] = [
	{ label: __('Yes', null, 'Checkbox is checked'), value: 'Yes' },
	{ label: __('No', null, 'Checkbox is not checked'), value: 'No' },
];


export const docstatus: Option[] = [
	{ value: 0, label: __('Draft') },
	{ value: 1, label: __('Submitted') },
	{ value: 2, label: __('Cancelled') },
];
