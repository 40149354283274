import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb05b8ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["href"]
const _hoisted_3 = {
  key: 0,
  class: "report-info-container"
}
const _hoisted_4 = { class: "report-info_title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", {
    id: $setup.uniKey,
    ref: "infoRef"
  }, [
    _createElementVNode("a", {
      name: "uniKey",
      href: `#${$setup.uniKey}`
    }, null, 8 /* PROPS */, _hoisted_2),
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: _normalizeClass({'diary-info':true, loading:Boolean(!$setup.diary)})
    }, [
      ($setup.diary)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(`${$setup.teamStructure.teamName}(${$setup.teamStructure.structureName})`), 1 /* TEXT */),
            _createVNode($setup["Progress"], {
              progress: $setup.diary?.progress_actual || [],
              enableAudit: $setup.enableAudit
            }, null, 8 /* PROPS */, ["progress", "enableAudit"]),
            _createVNode($setup["Description"], {
              description: $setup.diary?.description
            }, null, 8 /* PROPS */, ["description"]),
            _createVNode($setup["Person"], {
              personList: $setup.diary.labourer_type_actual || [],
              enableAudit: $setup.enableAudit
            }, null, 8 /* PROPS */, ["personList", "enableAudit"]),
            _createVNode($setup["Machine"], {
              machineList: $setup.diary.machine_type_actual || [],
              enableAudit: $setup.enableAudit
            }, null, 8 /* PROPS */, ["machineList", "enableAudit"]),
            _createVNode($setup["Problem"], {
              problems: $setup.diary?.field_problems || []
            }, null, 8 /* PROPS */, ["problems"]),
            _createVNode($setup["Images"], {
              images: $setup.diary?.structure_image || []
            }, null, 8 /* PROPS */, ["images"])
          ]))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */)), [
      [_directive_loading, Boolean(!$setup.diary)]
    ])
  ], 8 /* PROPS */, _hoisted_1))
}