import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["ElForm"], {
      labelWidth: "auto",
      model: $setup.formValue,
      rules: $setup.rules,
      inline: "",
      class: "instruction-form"
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElFormItem"], {
          label: $setup.tt('Subject'),
          prop: "title"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElInput"], {
              modelValue: $setup.formValue.title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.formValue.title) = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElFormItem"], {
          label: $setup.tt('Version'),
          prop: "version"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElCol"], { span: 18 }, {
              default: _withCtx(() => [
                _createVNode($setup["ElInput"], {
                  disabled: Boolean($setup.store.currentVersion.name),
                  modelValue: $setup.formValue.version,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.formValue.version) = $event))
                }, null, 8 /* PROPS */, ["disabled", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode($setup["ElCol"], { span: 1 }),
            ($setup.store.editable&&$setup.store.currentVersion.name)
              ? (_openBlock(), _createBlock($setup["ElCol"], {
                  key: 0,
                  span: 5
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["ElTooltip"], {
                      content: $setup.tt('Update Version')
                    }, {
                      default: _withCtx(() => [
                        _createVNode($setup["ElButton"], {
                          onClick: _cache[2] || (_cache[2] = $event => ($setup.visible=true)),
                          icon: $setup.Edit
                        }, null, 8 /* PROPS */, ["icon"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["content"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElFormItem"], {
          label: $setup.tt('instruction Compiler'),
          prop: "title"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElInput"], {
              modelValue: $setup.formValue.compiler,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.formValue.compiler) = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElFormItem"], {
          label: $setup.tt('instruction Checker'),
          prop: "title"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElInput"], {
              modelValue: $setup.formValue.checker,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.formValue.checker) = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["model", "rules"]),
    _createVNode($setup["VersionNoDialog"], {
      visible: $setup.visible,
      "onUpdate:visible": _cache[5] || (_cache[5] = $event => (($setup.visible) = $event)),
      versionNo: $setup.formValue.version,
      "onUpdate:versionNo": _cache[6] || (_cache[6] = $event => (($setup.formValue.version) = $event))
    }, null, 8 /* PROPS */, ["visible", "versionNo"])
  ], 64 /* STABLE_FRAGMENT */))
}