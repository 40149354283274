<template>
	<span class="custom-tabs-label">
		<span v-show="!editing" @dblclick="editing = true">{{ title }}</span>
		<span v-show="editing">
			<ElInput v-model="inputTitle" @blur="submit" @keydown.enter="submit"
				@keydown.esc="cancel" @keydown.backspace.stop />
		</span>
	</span>
</template>

<script setup lang='ts'>
import { ref, watch } from 'vue';

import { ElInput } from 'element-plus';
import { Setting } from '@element-plus/icons-vue';

interface Props {
	title: string
}
const props = defineProps<Props>();
interface Emit {
	(event: 'change', oldTitle: string, newTitle: string): void;
}
const emit = defineEmits<Emit>();
const editing = ref(false);
const inputTitle = ref<string>(props.title);
watch(() => props.title, title => {
	inputTitle.value = title;
}, { immediate: true });

function cancel() {
	inputTitle.value = props.title;
	editing.value = false;
}
function submit() {
	if (!editing.value) { return; }
	editing.value = false;
	const newTitle = inputTitle.value;
	const oldTitle = props.title;
	inputTitle.value = oldTitle;
	if (!newTitle || newTitle === oldTitle){ return; }
	emit('change', oldTitle, newTitle);
}
</script>
