import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b54c234"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ViewPageMenuBtn-label" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("li", {
    class: "ViewPageMenuBtn-item",
    onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('click'))),
    tabindex: "0"
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1 /* TEXT */),
    _createCommentVNode(" <kbd v-if=\"shortcut\" class=\"pull-right\">\n\t\t\t<span>{{shortcut}}</span>\n\t\t</kbd> ")
  ]))
}