import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["AgGridVue"], {
    class: "ag-theme-guigu assess-table",
    rowData: $props.assessLevelData,
    defaultColDef: $setup.defaultColDef,
    columnDefs: $setup.columnDefs,
    animateRows: "true",
    onGridReady: $setup.onGridReady,
    suppressDragLeaveHidesColumns: $setup.smallMeta,
    localeText: $setup.zhCN,
    rowGroupPanelShow: "always",
    getRowId: $setup.getRowId
  }, null, 8 /* PROPS */, ["rowData", "columnDefs", "suppressDragLeaveHidesColumns", "localeText"]))
}