frappe.views.pageview.with_page = function (name, callback) {
	if (frappe.standard_pages[name]) {
		if (!frappe.pages[name]) {
			frappe.standard_pages[name]();
		}
		callback();
		return;
	}

	if (
		(locals.Page && locals.Page[name] && locals.Page[name].script) ||
		name == window.page_name
	) {
		// already loaded
		callback();
	} else if (name) {
		// get fresh
		return frappe.call({
			method: 'frappe.desk.desk_page.getpage',
			args: { name: name },
			callback: function (r) {
				callback();
			},
			freeze: true,
		});
	}
};
