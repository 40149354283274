import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e1e1a1e1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fileBoxWrapper" }
const _hoisted_2 = { class: "fileBox" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "image-name" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElTooltip = _resolveComponent("ElTooltip")
  const _component_ElButton = _resolveComponent("ElButton")
  const _component_ElDropdownItem = _resolveComponent("ElDropdownItem")
  const _component_ElPopconfirm = _resolveComponent("ElPopconfirm")
  const _component_ElDropdownMenu = _resolveComponent("ElDropdownMenu")
  const _component_ElDropdown = _resolveComponent("ElDropdown")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "imgbox",
        src: $setup.photo.image
      }, null, 8 /* PROPS */, _hoisted_3),
      _createVNode(_component_ElTooltip, {
        placement: "top",
        content: $setup.photo.image_name
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, _toDisplayString($setup.photo.image_name), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["content"]),
      _createVNode(_component_ElDropdown, { class: "dropdown-container" }, {
        dropdown: _withCtx(() => [
          _createVNode(_component_ElDropdownMenu, null, {
            default: _withCtx(() => [
              _createVNode(_component_ElDropdownItem, { onClick: $setup.download }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.tt("Download")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_ElDropdownItem, { class: "delete" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_ElPopconfirm, {
                      title: $setup.tt('Are you sure you want to delete this picture?'),
                      onConfirm: $setup.deletePhoto,
                      confirmButtonText: $setup.tt('Confirm'),
                      cancelButtonText: $setup.tt('cancel')
                    }, {
                      reference: _withCtx(() => [
                        _createVNode(_component_ElButton, {
                          link: "",
                          type: "danger"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.tt('Delete')), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["title", "confirmButtonText", "cancelButtonText"])
                  ])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_ElButton, {
            class: "iconBall right",
            type: "primary",
            icon: $setup.MoreFilled,
            circle: ""
          }, null, 8 /* PROPS */, ["icon"])
        ]),
        _: 1 /* STABLE */
      })
    ])
  ]))
}