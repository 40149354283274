import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8d020912"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["Page"], null, {
      title: _withCtx(() => [
        _createElementVNode("h3", _hoisted_1, _toDisplayString($props.type === 'project' ? $setup.tt('Project') : $setup.tt('Company')) + _toDisplayString($setup.tt('Monthly Assessment List')), 1 /* TEXT */)
      ]),
      tools: _withCtx(() => [
        _createVNode($setup["Tools"], {
          type: $props.type,
          departments: $setup.departments,
          lockStatus: $setup.lockStatus,
          departmentName: $setup.departmentName,
          "onUpdate:departmentName": _cache[0] || (_cache[0] = $event => (($setup.departmentName) = $event)),
          month: $setup.month,
          "onUpdate:month": _cache[1] || (_cache[1] = $event => (($setup.month) = $event)),
          onChangeLock: $setup.getLockStatus,
          onCreate: $setup.create,
          creatable: $setup.creatable,
          exportable: $setup.exportable,
          lockable: $setup.lockable,
          unlockable: $setup.unlockable
        }, null, 8 /* PROPS */, ["type", "departments", "lockStatus", "departmentName", "month", "creatable", "exportable", "lockable", "unlockable"])
      ]),
      default: _withCtx(() => [
        _createVNode($setup["Table"], {
          permission: $setup.assessPermission,
          assessList: $setup.assessList,
          type: $props.type,
          month: $setup.month,
          department: $setup.departmentName,
          lockStatus: $setup.lockStatus,
          onUpdateLevel: $setup.updateLevel
        }, null, 8 /* PROPS */, ["permission", "assessList", "type", "month", "department", "lockStatus"])
      ]),
      _: 1 /* STABLE */
    })
  ])), [
    [_directive_loading, $setup.loading]
  ])
}