import { createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c36df6b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-config" }
const _hoisted_2 = { class: "form-content" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElButtonGroup = _resolveComponent("ElButtonGroup")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Label')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElInput"], {
          modelValue: $setup.label,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.label) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('description')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElInput"], {
          type: "textarea",
          autosize: { minRows: 4 },
          modelValue: $setup.description,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.description) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElTable"], {
      class: "config-table",
      data: $setup.tableData,
      border: "",
      rowKey: "name",
      expandRowKeys: $setup.expandRowKeys,
      onExpandChange: $setup.expandChange,
      onSelectionChange: $setup.selectionChange,
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElTableColumn"], {
          type: "selection",
          align: "center",
          width: "40"
        }),
        _createVNode($setup["ElTableColumn"], {
          type: "index",
          align: "center"
        }),
        _createVNode($setup["ElTableColumn"], { type: "expand" }, {
          default: _withCtx((props) => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode($setup["ElForm"], { labelPosition: "top" }, {
                default: _withCtx(() => [
                  _createVNode($setup["ElFormItem"], {
                    label: $setup.tt('Type'),
                    rules: [
								{
									required: true,
									message: '请选择类型',
									trigger: 'blur',
								}
							]
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ElSelect"], {
                        modelValue: props.row.type,
                        "onUpdate:modelValue": $event => ((props.row.type) = $event)
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.types, (v) => {
                            return (_openBlock(), _createBlock($setup["ElOption"], {
                              key: v,
                              value: v,
                              label: $setup.tt(v)
                            }, null, 8 /* PROPS */, ["value", "label"]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"]),
                  _createVNode($setup["ElFormItem"], {
                    label: $setup.tt('Icon')
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["IconPicker"], {
                        modelValue: props.row.icon,
                        "onUpdate:modelValue": $event => ((props.row.icon) = $event)
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"]),
                  (props.row.type==='View Link')
                    ? (_openBlock(), _createBlock($setup["ViewLinkConfig"], {
                        key: 0,
                        value: props.row.viewLinkConfig,
                        onUpdate: (data, submittable)=>$setup.updateViewLinkConfig(props.row,data, submittable)
                      }, null, 8 /* PROPS */, ["value", "onUpdate"]))
                    : (props.row.type==='DocType Link')
                      ? (_openBlock(), _createBlock($setup["DoctypeLinkConfig"], {
                          key: 1,
                          value: props.row.doctypeLinkConfig,
                          onUpdate: (data, submittable)=>$setup.updateDoctypeLinkConfig(props.row,data, submittable)
                        }, null, 8 /* PROPS */, ["value", "onUpdate"]))
                      : (props.row.type==='URL')
                        ? (_openBlock(), _createBlock($setup["UrlConfig"], {
                            key: 2,
                            value: props.row.urlConfig,
                            onUpdate: (data, submittable)=>$setup.updateUrlConfig(props.row,data, submittable)
                          }, null, 8 /* PROPS */, ["value", "onUpdate"]))
                        : (_openBlock(), _createBlock($setup["ListConfig"], {
                            key: 3,
                            value: props.row.listLinkConfig,
                            onUpdate: (data, submittable)=>$setup.updateListLinkConfig(props.row,data, submittable)
                          }, null, 8 /* PROPS */, ["value", "onUpdate"]))
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElTableColumn"], {
          label: $setup.tt('Label'),
          prop: "label"
        }, null, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          label: $setup.tt('Link To'),
          prop: "link_to"
        }, null, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          label: $setup.tt('操作'),
          align: "center",
          width: "55"
        }, {
          default: _withCtx(({ $index }) => [
            _createVNode(_component_ElButtonGroup, null, {
              default: _withCtx(() => [
                _createVNode($setup["ElButton"], {
                  class: "btn",
                  onClick: $event => ($setup.moveTo($index)),
                  text: "",
                  icon: $setup.Sort,
                  title: $setup.tt('移动')
                }, null, 8 /* PROPS */, ["onClick", "icon", "title"])
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["data", "expandRowKeys"]),
    _createVNode($setup["ElButton"], {
      class: "add-row-btn",
      onClick: $setup.addRow
    }, {
      default: _withCtx(() => [
        _createTextVNode("添加行")
      ]),
      _: 1 /* STABLE */
    }),
    ($setup.selectedData.length>0)
      ? (_openBlock(), _createBlock($setup["ElButton"], {
          key: 0,
          type: "danger",
          class: "add-row-btn",
          onClick: $setup.deleteRow
        }, {
          default: _withCtx(() => [
            _createTextVNode(" 删除行 ")
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ]))
}