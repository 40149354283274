export default async function loadCount(
	doctype: string,
	field: string,
	allFilters: any[][],
	type: string,
	options?: string,
) {
	const fieldName = field === 'assigned_to' ? '_assign' : field;
	const args = {
		doctype,
		current_filters: allFilters.filter(f_arr => !f_arr.includes(fieldName)),
		field: field === '_assign' ? 'assigned_to' : field,
	};
	const method = 'frappe.desk.listview.get_group_by_count';
	const r = await frappe.call<{
		message: { count: number, name: string }[]
	}>(method, args);

	const values = (r?.message || []).filter(f => f.count !== 0);
	if (type === 'Check') {
		return values.map(v => ({
			...v, label: String(v.name) === '0' ? __('No') : __('Yes'),
		}));
	}
	const list = values.map(v => ({ ...v, label: v.name === null ? __('Not Set') : v.name }));
	if (type !== 'Link' || options !== 'User') { return list; }
	const me = values.find(f => f.name === frappe.session.user);
	const users = list.filter(f => ![
		'Guest',
		'Administrator',
		frappe.session.user,
	].includes(f.name));
	if (me) { users.unshift({ ...me, label: __('Me') }); }
	return users;
}
