/**
 * 实现在视图右上角的视图切换器中添加自定义的视图
 */
import allViews from '../Views';
import switchView from '../lib/switchView';

const oldSetupViews = frappe.views.ListViewSelect.prototype.setup_views;
frappe.views.ListViewSelect.prototype.setup_views = function setup_views(
	this: frappe.views.ListViewSelect
) {
	oldSetupViews.call(this);
	const currentView = this.current_view;
	const s: frappe.guigu.ViewContext = {
		currentView,
		doctype: this.doctype,
		settings: this.list_view.settings,
		meta: this.list_view.meta,
		switchView: switchView.bind(null, this.doctype),
	};
	for (const [name, t] of Object.entries(allViews)) {
		if (currentView === name) { continue; }
		const options = t?.getOption ? t.getOption(s) : {};
		if (!options) { continue; }
		this.add_view_to_menu(options.title || t?.title || frappe.utils.to_title_case(name), options.action || (() => {
			this.set_route(name);
		}));
	}
};
