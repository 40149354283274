import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElDatePicker"], {
    type: "datetime",
    modelValue: $setup.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.value) = $event)),
    placeholder: $props.label && $setup.tt($props.label),
    teleported: false
  }, null, 8 /* PROPS */, ["modelValue", "placeholder"]))
}