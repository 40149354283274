import type { ProcessCellForExportParams } from 'ag-grid-community';


import html2text from '../utils/html2text';

const dateRegex = /^(\d+)-(0?[1-9]|1[0-2])-(0?[1-9]|[12]\d|3[01])(\s|T|$)/;
function parseDate(value?: string): Date | undefined {
	if (!value || typeof value !== 'string') { return; }
	const result = dateRegex.exec(value);
	if (!result) { return; }
	const [, Y, M, D] = result;
	const date = new Date(Number(Y), Number(M) - 1, Number(D));

	return date;
}
function getYearWeek(date: Date) {
	for (let year = date.getFullYear() + 1; ; year--) {
		const start = new Date(year, 0, 1);
		const d = Math.floor((Number(date) - Number(start)) / 86400000);
		const n = Math.ceil((d + ((start.getDay() + 1) - 1)) / 7);
		if (n > 0) { return [n, year]; }
	}
}

const defaultExcelExportParams = {
	skipRowGroups: true,
	onlySelected: true,
	processCellCallback: (params: ProcessCellForExportParams) => {
		const data = params.node?.data;
		const cellRendererParams = params.column.getColDef()?.cellRendererParams;
		const docField = cellRendererParams?.docField;
		if (!docField) { return params.value; }
		const value = params.value || data?.[docField.fieldname];
		switch (docField.fieldtype) {
			case 'Select':
				return __(value);
			case 'Date': case 'Datetime': case 'Guigu Date': {
				const format = cellRendererParams?.format;
				if (format && value) {
					return moment(value).format(format);
				}
				switch (docField.options?.toLowerCase()) {
					case 'quarter': {
						return `${moment(value).format('YYYY')}-${moment(value).quarter()}季度`;
					}
					case 'year':
						return value.replace(/-.*/, '');
					case 'month':
						return value.replace(/^(\d+-\d+).*/, '$1');
					case 'week': {
						const date = parseDate(value);
						if (!date) { return value; }
						return getYearWeek(date).reverse().join('-');
					}
				}
				break;
			}
			case 'Link': case 'Tree Select': case 'Tianjy Enumeration': case 'Tianjy Related Link': {
				const title = data?.[`${docField.fieldname}.title`];
				return title || value;
			}
			case 'Percent': {
				if (value || value === 0) { return `${value}%`; }
			}
			case 'Table MultiSelect':
				if (!value) { return ''; }
				try {
					const labels = (value as { multi_table_label: string }[])
						.map(item => item.multi_table_label)
						.join(', ');
					return labels;
				} catch {
					return '';
				}
			case '__LastComment__':
				if (!value) { return; }
				try {
					const list = typeof value === 'string' ? JSON.parse(value) : value;
					const comment = Array.isArray(list) ? list.pop() : list;
					if (!comment) { return; }
					const userName = comment.comment_by;
					const content = comment.content || '';
					if (!userName) { return html2text(content); }
					const { creation } = comment;
					const time = creation ? moment(creation).format('YYYY-MM-DD HH:mm') : '';
					return `${userName}(${time})：${html2text(content)}`;
				} catch {
					return '';
				}
		}
		return value;
	},
};
export default defaultExcelExportParams;
