const fieldTypes = new Set([
	'select',
	'check',
	'date',
	'datetime',
	'time',

	'small text',
	'text',
	'text editor',
	'html editor',
	'markdown editor',
	'code',
	'read only',
	'color',
	'data',

	// 'attach',
	// 'autocomplete',
	// 'attach image',
	// 'barcode',
	'currency',
	// 'duration',
	'float',
	// 'geolocation',
	// 'icon',
	'int',
	'json',
	'link',
	'long text',
	'password',
	'percent',
	// 'phone',
	'rating',
	// 'signature',
	'guigu date',
	'tree select',
]);
export default fieldTypes;
