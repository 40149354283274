import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElFormItem"], {
    label: $setup.tt('Organization')
  }, {
    default: _withCtx(() => [
      _createVNode($setup["ElTreeSelect"], {
        modelValue: $setup.organizations,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.organizations) = $event)),
        data: $setup.options,
        multiple: "",
        filterable: "",
        defaultFirstOption: "",
        clearable: "",
        checkStrictly: "",
        highlightCurrent: "",
        defaultExpandAll: "",
        style: {"width":"100%"}
      }, null, 8 /* PROPS */, ["modelValue", "data"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["label"]))
}