<template>
	<div class="toolbar">
		<ProjectCom
			default
			noLabel
			v-model="selectedProject"
		></ProjectCom>
		<ElDatePicker
			class="input date-input"
			v-model="dateRange"
			type="daterange"
			unlinkPanels
			:startPlaceholder="tt('Start Date')"
			:endPlaceholder="tt('End Date')"
			:shortcuts="shortcuts"

			valueFormat="YYYY-MM-DD" />
		<ElButton
			class="input sort-btn"
			:type="sort === 'asc' ? 'primary' : 'default'"
			@click="clickSort">
			{{ tt('Time ASC') }}
		</ElButton>
		<ElCheckbox class="checkbox input" v-model="isIncludeChildren"
			:label="tt('Include photos of descendants')" />
		<ZipDownload
			class="input"
			:files="zipFiles"
			:zipName="structureOrProgress.label"></ZipDownload>
	</div>
</template>
<script lang="ts" setup>
import {ref, onMounted, defineEmits, watchEffect, defineProps, computed} from 'vue';
import moment from 'moment';

import ZipDownload from '../../../components/zipDownload/ZipDownload.vue';
import {useMetaQuery} from '../../../components/page/useMetaQuery';
import ProjectCom from '../../../../../../../guigu/guigu/public/js/components/PageComponents/Tools/Project.vue';

import {Photo, Project, StructureORProgress} from './type';

interface Props {
	photoList: Photo[]
	structureOrProgress: StructureORProgress
}
const props = defineProps<Props>();
const smallMeta = useMetaQuery();
const tt = __;
interface Emit {
	(e: 'projectChange', project: string): void
	(e: 'dateRangeChange', dateRange: [string, string]): void
	(e: 'sortChange', sort: ('desc' | 'asc')): void
	(e: 'isIncludeChildrenChange', isIncludeChildren: (boolean)): void
}
const emit = defineEmits<Emit>();
const projects = ref<Project[]>();
const date = new Date();
date.setDate(date.getDate() - 6);
const dateRange = ref<[string, string]>([
	moment(date).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'),
]);
const selectedProject = ref<string>();
const sort = ref<'desc' | 'asc'>('desc');
const isIncludeChildren = ref<boolean>(false);
function clickSort() {
	sort.value = sort.value === 'asc' ? 'desc' : 'asc';
}
onMounted(async () => {
	const projectsRes: Project[] = await frappe.db.get_list(
		'Project',
		{
			fields: ['name', 'project_name', 'project_abbr_name'],
			filters: {status: 'Open'},
			limit: '',
		},
	);
	projects.value = projectsRes;
	const sessionDefaultProject: string = (frappe.defaults.get_user_defaults('project') || [])[0];
	selectedProject.value = sessionDefaultProject || projectsRes[0]?.name || '';
});
watchEffect(() => {
	if (!selectedProject.value) {
		return;
	}
	emit('projectChange', selectedProject.value);
});
watchEffect(() => {
	if (!dateRange.value) {
		return;
	}
	emit('dateRangeChange', dateRange.value);
});

watchEffect(() => {
	if (!sort.value) {
		return;
	}
	emit('sortChange', sort.value);
});
watchEffect(() => {
	emit('isIncludeChildrenChange', isIncludeChildren.value);
});
const shortcuts = [
	{
		text: __('Recent Week'),
		value: () => {
			const end = new Date();
			const start = new Date();
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
			return [start, end];
		},
	},
	{
		text: __('Recent Month'),
		value: () => {
			const end = new Date();
			const start = new Date();
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
			return [start, end];
		},
	},
];
const zipFiles = computed(() => props.photoList.map(photo => ({
	url: photo.image,
	name: `${`${photo?.image_name}${photo?.description}`.slice(0, 100)}.png`,
})));
const margin = computed(()=>smallMeta.value ? '8px' : '0');
const flexDirection = computed(()=>smallMeta.value ? 'column' : 'row');

</script>
<style lang="less" scoped>
.checkbox {
	margin-bottom: 0px !important;
}
.toolbar {
	display: flex;
	flex-direction: v-bind(flexDirection);
	& :deep(.input){
		margin-right: 8px;
		margin-bottom: v-bind(margin);
	}
	& :deep(.date-input){
		width:265px!important;
		flex-grow: 0;
	}
	.sort-btn{
		margin-right: 0px;
	}
}
</style>
