<template>
	<ElDialog v-model="visible" :title="tt('Please Select Department')" width="40%">
		<ElTree
			v-if="departmentTree.length"
			ref="tree"
			class="dw_department_tree"
			:data="departmentTree"
			highlightCurrent
			defaultExpandAll
			checkOnClickNode
			checkStrictly
			showCheckbox
			:props="defaultProps"
			nodeKey="name"
			@check="changeDepartment"
		></ElTree>
		<ElEmpty v-else :description="tt('No Data')"></ElEmpty>
		<template #footer>
			<ElButton @click="closeDialog('Cancel')" size="small">{{ tt('Cancel') }}</ElButton>
			<ElButton type="primary" size="small" @click="closeDialog('Confirm')">
				{{ tt('Confirm') }}
			</ElButton>
		</template>
	</ElDialog>
</template>
<script setup lang="ts">
import {ElDialog, ElTree, ElButton, ElEmpty} from 'element-plus';
import {ref, computed} from 'vue';

import type {Department} from './type';
const props = defineProps<{
	departmentTree: Department[];
	visible: boolean;
	selectedDepartment: string;
}>();
const emit = defineEmits<{
	(e: 'update:visible', value: boolean): void;
	(e: 'distributeDepartment'): void;
	(e: 'update:selectedDepartment', department: string): void;
}>();
const tt = __;
const tree = ref<InstanceType<typeof ElTree>>();
const visible = computed({
	get: () => props.visible,
	set: (value:boolean) => emit('update:visible', value),
});
const defaultProps = {
	label: 'department_name',
	children: 'children',
};

function closeDialog(type:'Cancel'|'Confirm') {
	emit('update:visible', false);
	if (type === 'Confirm') {
		emit('distributeDepartment');
	}
}

// 更新选中的部门
function changeDepartment(node: any) {
	const currentSelected = tree.value?.getCheckedNodes() || [];
	// 确保只有一个部门选中
	for (const item of currentSelected) {
		if (item.name !== node.name) {
			tree.value?.setChecked(item.name, false, false);
		}
	}
	emit('update:selectedDepartment', node.name);
}

</script>
<style>
.dw_department_tree .el-checkbox{
	margin-bottom: 0;
}
</style>
