import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73dc828a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "option-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElPopover"], {
    popperStyle: { 'width': '450px','zIndex':2002 },
    visible: $setup.visible,
    trigger: "click",
    placement: "right"
  }, {
    reference: _withCtx(() => [
      _createVNode($setup["Settings"], {
        class: "setting",
        onClick: $setup.clickSetting,
        size: 16
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode($setup["ElSpace"], null, {
            default: _withCtx(() => [
              _createVNode($setup["ElButton"], {
                type: "danger",
                onClick: $setup.onDelete
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.tt('Delete')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode($setup["X"], {
                class: "close",
                size: 16,
                onClick: $setup.close
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", { visible: $setup.visible }, undefined, true)
        ])
      ])
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["visible"]))
}