import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElButton = _resolveComponent("ElButton")
  const _component_ElPopover = _resolveComponent("ElPopover")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives((_openBlock(), _createBlock(_component_ElButton, {
      class: _normalizeClass($props.class),
      ref: "buttonRef"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($setup.buttonLabel), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["class"])), [
      [$setup["vClickOutside"], $setup.onClickOutside]
    ]),
    _createVNode(_component_ElPopover, {
      width: "416px",
      ref: "popoverRef",
      "virtual-ref": $setup.buttonRef,
      "virtual-triggering": "",
      trigger: "click"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          ($setup.periodType !== 'Year')
            ? (_openBlock(), _createBlock($setup["Header"], {
                key: 0,
                yearPeriods: $setup.yearPeriods,
                selectPeriod: $setup.selectPeriod,
                onYearChange: $setup.yearChange
              }, null, 8 /* PROPS */, ["yearPeriods", "selectPeriod"]))
            : _createCommentVNode("v-if", true),
          _createVNode($setup["PeriodContainer"], {
            periodType: $setup.periodType,
            periods: $setup.contentPeriods,
            selectPeriod: $setup.selectPeriod,
            onPeriodChange: $setup.periodChange
          }, null, 8 /* PROPS */, ["periodType", "periods", "selectPeriod"]),
          _createVNode($setup["Footer"], {
            periodType: $setup.periodType,
            onClickNow: $setup.clickNow
          }, null, 8 /* PROPS */, ["periodType"])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["virtual-ref"])
  ], 64 /* STABLE_FRAGMENT */))
}