<template lang="html">
	<ElButton v-if="!allActions" @click="create" round type="primary">
		<a :href="url">{{ tt('Add') }}</a>
	</ElButton>
	<ElDropdown v-else @command="command">
		<ElButton round type="primary">
			<a>{{ tt('Add') }}</a>
		</ElButton>
		<template #dropdown>
			<ElDropdownMenu>
				<ElDropdownItem v-for="a in allActions" :command="a" :key="a.key">
					<a v-if="a.url && a.action" :href="a.url">
						{{ a.label }}
					</a>
					<a v-else-if="a.url" :href="a.url">{{ a.label }}</a>
					<template v-else>{{ a.label }}</template>
				</ElDropdownItem>
			</ElDropdownMenu>
		</template>
	</ElDropdown>
</template>
<script setup lang="ts">
import {shallowRef, computed, onMounted} from 'vue';
import {ElButton, ElDropdown, ElDropdownMenu, ElDropdownItem} from 'element-plus';


defineOptions({inheritAttrs: false});

const props = defineProps<{
	meta: locals.DocType;
	url: string;
}>();
const emit = defineEmits<{
	create: [];
}>();

function create(e: Event) {
	e.preventDefault();
	emit('create');
}

const tt = __;
interface Method {
	label: string;
	key?: string;
	url?: string;
	action?: (meta: locals.DocType) => void;
}
const actions = shallowRef<Method[]>();
const allActions = computed(() => {
	const list = actions.value;
	if (!list?.length) {
		return;
	}
	return [{
		label: tt('Add'),
		key: '<create>',
		url: props.url,
		action: () => emit('create'),
	}, ...list];
});
function command(item: Method, _: any, event: Event) {
	if (typeof item.action !== 'function') {
		return;
	}
	event.preventDefault();
	item.action(props.meta);
}

onMounted(async () => {
	const {meta} = props;
	const setting = frappe.guigu.GlobalView.settings[meta.name];
	const addMethods = setting?.addMethods;
	const list = typeof addMethods === 'function' ? await addMethods(meta) : addMethods;
	if (!Array.isArray(list)) {
		return;
	}
	const methods: Method[] = [];
	for (const item of list) {
		const {hidden, key, label, action, url} = item;
		if (typeof hidden === 'function' ? hidden.call(item, meta) : hidden) {
			continue;
		}
		methods.push({
			label, key, action: action?.bind(item),
			url: typeof url === 'function' ? url.call(item, meta) : url,
		});
	}
	if (!methods.length) {
		return;
	}
	actions.value = methods;
});

</script>


<style scoped lang="less">
.current {
	background-color: #EEE;
}
</style>
