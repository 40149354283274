<template>
	<div class="header-container">
		<div class="left-container">
			<div>
				<span>{{ tt('Unit') }}:</span
				>{{ assessInfo?.department_doc.label }}
			</div>
			<div>
				<span>{{ tt('Department') }}:</span
				>{{ assessInfo?.organization?.organization_name }}
			</div>
			<div>
				<span>{{ tt('Position') }}:</span>{{ assessInfo?.job }}
			</div>
			<div>
				<span>{{ tt('Name') }}:</span
				>{{ assessInfo?.user_doc.full_name }}
			</div>
			<div>
				<span>{{ tt('Performance Year') }}:</span>{{ month }}
			</div>
		</div>
		<ElSpace>
			<ElButton @click="exportDetail">{{ tt('Export') }}</ElButton>
		</ElSpace>
	</div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';

import type * as Permission from '../../Permission';
import type { AssessInfo } from '../type';
import { getBinaryContent, exportDocx, saveAs } from '../../export/export';
import type { Level } from '../../assessmentList/type';

import { getExportData } from './getExportData';

interface Props {
	assessInfo?: AssessInfo;
	type: string;
	detailPermission: Permission.UserPermission | null;
	assessPermission: Permission.UserPermission | null;
	extraPermission: Permission.UserPermission | null;
	assessUserPermission: Permission.UserPermission;
	smallMeta: boolean;
	lockStatus: boolean;
}
const tt = __;
const props = defineProps<Props>();
interface Emit {
	(e: 'updateLevel', v: Level): void;
	(e: 'update'): void;
}
const emit = defineEmits<Emit>();
const level = ref<Level | ''>('');
const selectLeaderList = ref<{ name: string; full_name: string }[]>([]);

watch(
	() => props.assessInfo,
	() => {
		level.value = props.assessInfo?.level || '';
		getSelectLeaderList();
	},
	{ immediate: true },
);

async function getSelectLeaderList() {
	if (!props.assessInfo) {
		return;
	}
	const filters: [string, string, any][] = [];
	if (props.type === 'project') {
		filters.push(['project', '=', props.assessInfo.department_doc?.name]);
	} else {
		filters.push([
			'sub_company',
			'=',
			props.assessInfo.department_doc?.name,
		]);
	}
	const userMemberType =
		props.type === 'project'
			? 'Guigu Project Member'
			: 'Guigu Sub Company Member';
	const members = await frappe.db.get_list(userMemberType, {
		filters,
		fields: ['user'],
		limit: 0,
	});
	if (members.length === 0) {
		selectLeaderList.value = [];
		return;
	}
	const res = await frappe.db.get_list<{ name: string; full_name: string }>(
		'User',
		{
			filters: [['name', 'in', members.map(item => item.user)]],
			fields: ['name', 'full_name'],
			limit: 0,
		},
	);
	selectLeaderList.value = res || [];
}
async function changeSelectLeader(v: string) {
	if (!props.assessInfo) {
		return;
	}
	let url =
		'guigu_hr.guigu_hr_assessment.page.guigu_hr_assessment_detail.guigu_hr_assessment_detail.update_assess';
	const args: any = {
		name: props.assessInfo?.assess_name,
		field: 'select_leader',
		value: v,
		type: props.type,
	};
	if (!props.assessInfo?.assess_name) {
		url =
			'guigu_hr.guigu_hr_assessment.page.guigu_hr_assessment_detail.guigu_hr_assessment_detail.create_assess';
		args.date = props.assessInfo.date;
		args.user = props.assessInfo.user_doc.name;
		args.type = props.type;
		args.select_leader = v;
		args.department = props.assessInfo.department_doc.name;
	}
	await frappe.call({
		method: url,
		args: args,
	});
	emit('update');
}
function changeLevel(v: Level) {
	if (v === props.assessInfo?.level) {
		return;
	}
	emit('updateLevel', v);
}
const month = computed(() => {
	if (!props.assessInfo) {
		return '';
	}
	return moment(props.assessInfo.date).format('YYYY-MM');
});

async function exportDetail() {
	if (!props.assessInfo) {
		return;
	}
	const template = await getBinaryContent(
		'/assets/guigu_hr/files/month_template.docx',
	);
	const assessData = getExportData(props.assessInfo);
	const out = await exportDocx(template, assessData);
	saveAs(
		out,
		__('{}{}Monthly Assessment Details.docx', [
			props.assessInfo.user_doc.full_name,
			moment(props.assessInfo.date).format('YYYY-MM'),
		]),
	);
}

const flexDirection = computed(() => (props.smallMeta ? 'column' : 'row'));
const margin = computed(() => (props.smallMeta ? '8px' : '0'));
</script>

<style lang="less" scoped>
.header-level {
	width: 80px;
}
.header-container {
	display: flex;
	justify-content: space-between;
	flex-direction: v-bind(flexDirection);
	span {
		margin-right: 16px;
		font-weight: bolder;
	}
	.left-container > div {
		display: inline-block;
		margin-right: 16px;
		margin-bottom: v-bind(margin);
	}
}
</style>
