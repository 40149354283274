<template>
	<AgGridVue
		v-if="!loading"
		class="ag-theme-guigu table"
		animateRows
		suppressRowTransform
		enableRangeSelection
		useValueFormatterForExport
		rowGroupPanelShow="always"
		:rowData="taskList"
		:defaultColDef="defaultColDef"
		:suppressDragLeaveHidesColumns="smallMeta"
		:localeText="zhCN"
		:getRowId="getRowId"
		@grid-ready="onGridReady"
		@cell-Clicked="onCellClicked"
	></AgGridVue>
</template>
<script setup lang="ts">
import {computed, ref, watch} from 'vue';
import {AgGridVue} from 'ag-grid-vue3';
import type {GridApi, CellClassParams} from 'ag-grid-community';

import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';

import {getTaskColumns} from './helper';
import type{TaskData, TaskAssignee} from './type';
const props = defineProps<{
	taskList: TaskData[];
	loading: boolean;
	smallMeta: boolean;
	currentOrganization: string;
}>();
const emit = defineEmits<{
	(e: 'showProcessModalOrNavigate', name: string): void;
}>();
const gridApi = ref<GridApi>();
const users = ref<TaskAssignee[]>([]);
const defaultColDef = computed(()=>({
	sortable: false,
	filter: false,
	minWidth: 100,
	resizable: true,
	editable: false,
}));
// 点击任务主题打开任务详情
function onCellClicked(params: CellClassParams) {
	if (params.colDef.field === 'subject') {
		emit('showProcessModalOrNavigate', params.data.name);
	}
}
const onGridReady = (params: CellClassParams) => {
	gridApi.value = params.api;
};
function getRowId(params: CellClassParams) {
	return params.data.name;
}
// 获取当前组织下的用户
async function getOrganizationUser() {
	const res:TaskAssignee[] = await frappe.db.get_list('Tianjy Organization Member', {
		filters: [['organization', '=', props.currentOrganization]],
		fields: ['user', 'user_name'],
		limit: 0,
	});
	users.value = res;
}
watch(gridApi, async () => {
	await getOrganizationUser();
	gridApi.value?.setColumnDefs(getTaskColumns());
});
</script>
<style lang="less" scoped >
.table {
	width: 100%;
	height: 95%;
	margin: 0;
}
:deep(.ag-paging-panel) {
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
