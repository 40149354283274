import * as XLSX from 'xlsx-js-style';
import Handsontable from 'handsontable';

import createXLSXSheet from './createXLSXSheet.mjs';

function* getXY(width: number, height: number): Iterable<[number, number]> {
	for (let x = 0; x < width; x++) {
		for (let y = 0; y < height; y++) {
			yield [x, y];
		}
	}
}

function isType(v?: string): v is 'text' | 'numeric' {
	if (!v) {
		return false;
	}
	return ['text', 'numeric'].includes(v);
}

function getStyles(hot: Handsontable, width: number, height: number) {
	const styles: TemplateStyle[][] = [];
	for (const [x, y] of getXY(width, height)) {
		try {
			const {
				row, col,
				bold, color, bgColor, italic, underline, className, fontSize,
				type, readOnly,
			} = hot.getCellMeta(y, x);
			while (styles.length <= row) {
				styles.push([]);
			}
			const style: TemplateStyle = {};
			if (bold) {
				style.bold = 1;
			}
			if (italic) {
				style.italic = 1;
			}
			if (underline) {
				style.underline = 1;
			}
			if (color) {
				style.color = color;
			}
			if (bgColor) {
				style.bgColor = bgColor;
			}
			if (fontSize) {
				style.fontSize = fontSize;
			}
			const cname = (
				Array.isArray(className) ? className.join(' ') : className || ''
			).split(' ');
			if (cname.includes('htLeft')) {
				style.left = 1;
			} else if (cname.includes('htCenter')) {
				style.center = 1;
			} else if (cname.includes('htRight')) {
				style.right = 1;
			} else if (cname.includes('htJustify')) {
				style.justify = 1;
			}
			if (cname.includes('htTop')) {
				style.top = 1;
			} else if (cname.includes('htMiddle')) {
				style.middle = 1;
			} else if (cname.includes('htBottom')) {
				style.bottom = 1;
			}
			if (isType(type)) {
				style.type = type;
			}
			if (cname.includes('customCell')) {
				style.bgColor = '#FF0000';
				style.color = '#ffffff';
			}
			if (readOnly) {
				style.readOnly = 1;
			}
			styles[row][col] = style;
		} catch (e) {
			console.error(e);
		}
	}
	return styles;
}

export default function readValue(
	handsontable: Handsontable,
	hasValue?: boolean,
): Template {
	const data = handsontable.getSourceData();
	const maxCol = data.reduce((m, v) => Math.max(m, v.length), 0);
	const maxRow = data.length;
	return {
		data,
		value: hasValue && handsontable.getData().map(item => [...item as any]) || undefined,
		widths: Array(maxCol).fill(0).map((_, i) => handsontable.getColWidth(i)),
		heights: Array(maxRow).fill(0).map((_, i) => handsontable.getRowHeight(i)),
		styles: getStyles(handsontable, maxCol, maxRow),

	};
}

export function exportFile(hatable: any, name: string) {
	const value = readValue(hatable, true);
	const wb = XLSX.utils.book_new();
	const ws = createXLSXSheet(value);
	XLSX.utils.book_append_sheet(wb, ws, name);

	const fileName = name;
	XLSX.writeFile(wb, `${fileName || 'Data'}.xlsx`);
}

export function exportTemplateFile() {
	const data = [['编码', '清单名称', '清单单位', '清单类型', '清单状态', '备注', '对上工程量', '对上单价',
		'对上合价', '对下工程量', '对下单价', '对下合价']];
	const wb = XLSX.utils.book_new();
	const ws = createXLSXSheet({
		value: data,
		data,
		widths: [],
		heights: [],
	});
	XLSX.utils.book_append_sheet(wb, ws, '工程量清单导入模版');

	XLSX.writeFile(wb, '工程量清单导入模版.xlsx');
}
export function exportConsumptionTemplateFile() {
	const data = [['清单项编码', '物资名称', '单耗']];
	const wb = XLSX.utils.book_new();
	const ws = createXLSXSheet({
		value: data,
		data,
		widths: [],
		heights: [],
	});
	XLSX.utils.book_append_sheet(wb, ws, '材料理论耗量导入模版');

	XLSX.writeFile(wb, '材料理论耗量导入模版.xlsx');
}
