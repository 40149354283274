import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElDatePicker = _resolveComponent("ElDatePicker")
  const _component_ElSpace = _resolveComponent("ElSpace")

  return (_openBlock(), _createBlock(_component_ElSpace, null, {
    default: _withCtx(() => [
      _createVNode(_component_ElDatePicker, {
        modelValue: $setup.date,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.date) = $event)),
        valueFormat: "YYYY-MM-DD"
      }, null, 8 /* PROPS */, ["modelValue"]),
      _createVNode($setup["ProjectCom"], {
        class: "project-select",
        default: "",
        noLabel: "",
        modelValue: $setup.selectedProject,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.selectedProject) = $event))
      }, null, 8 /* PROPS */, ["modelValue"])
    ]),
    _: 1 /* STABLE */
  }))
}