<template>
	<div v-loading="loading">
		<Page>
			<template #title>
				<h3 class="title">
					{{ type === 'project' ? tt('Project') : tt('Company')
					}}{{ tt('Weekly Assessment List') }}
				</h3>
			</template>
			<template #tools>
				<Tools
					:type="type"
					:departments="departments"
					:smallMeta="smallMeta"
					:exportable="exportable"
					v-model:departmentName="departmentName"
					v-model:week="week"
				></Tools>
			</template>
			<Table
				:assessList="assessList"
				:type="type"
				:week="week"
				:department="departmentName"
			></Table>
		</Page>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted, toRef } from 'vue';

import * as Permission from '../Permission';
import Page from '../../../../../../../guigu_pm/guigu_pm/public/js/components/page/index.vue';
import { useMetaQuery } from '../../../../../../../guigu_pm/guigu_pm/public/js/components/page/useMetaQuery';

import Tools from './components/Tools.vue';
import Table from './components/Table.vue';
import type { Assess, Company, Level, Project } from './type';

const tt = __;

interface Props {
	type: 'project' | 'subCompany';
}
const props = defineProps<Props>();
const departmentName = ref<string>('');
const week = ref<string>(moment().startOf('week').format('YYYY-MM-DD'));
const loading = ref<boolean>(false);
const assessList = ref<Assess[]>([]);
const departments = ref<{ name: string; label: string }[]>([]);
const department = computed(() =>
	departments.value.find(item => item.name === departmentName.value),
);
const assessMeta = ref<locals.DocType>();
const smallMeta = useMetaQuery();

const assessPermission = Permission.use(() => {
	const department = props.type === 'project' ? 'Project' : 'Company';
	return `Guigu Hr ${department} Week Assess`;
});
// const lockPermission = Permission.use(() => {
// 	const department = props.type === 'project' ? 'Project' : 'Company';
// 	return `Guigu Hr ${department} Work Assessment Lock`;
// });
const exportable = computed(() => Boolean(assessPermission.value?.exportable));

watch(
	() => window.location.search,
	() => {
		const params = new URLSearchParams(window.location.search);
		if (params.get('week')) {
			week.value = params.get('week') || '';
		}
		if (params.get('department')) {
			departmentName.value = params.get('department') || '';
		}
	},
	{ immediate: true },
);

onMounted(async () => {
	await frappe.model.with_doctype('Guigu Hr Project Week Assess');
	assessMeta.value =
		frappe.get_meta('Guigu Hr Project Week Assess') || undefined;
});

watch(
	() => props.type,
	() => {
		getSelectOptions();
	},
	{ immediate: true },
);
async function getSelectOptions() {
	const isProject = props.type === 'project';
	const url = isProject ? 'Project' : 'Company';
	const fields = isProject
		? ['name', 'project_abbr_name']
		: ['name', 'company_name'];
	const filters = isProject ? { status: 'Open' } : {};
	const res: (Project | Company)[] = await frappe.db.get_list(url, {
		fields: fields,
		filters: filters,
		limit: 0,
	});
	departments.value = res.map(item => {
		const label = isProject
			? (item as Project).project_abbr_name
			: (item as Company).company_name;
		return {
			name: item.name,
			label,
		};
	});
	if (!departmentName.value) {
		departmentName.value = res[0]?.name || '';
	}
}

watch(
	[departmentName, week],
	async () => {
		const queryObj = new URLSearchParams({
			department: departmentName.value,
			week: week.value,
		});
		history.replaceState(null, '', `?${queryObj.toString()}`);
		getAssessList();
	},
	{ immediate: true },
);
async function getAssessList() {
	if (!week.value || !props.type || !departmentName.value) {
		return;
	}
	loading.value = true;
	const res = await frappe.call<{ message: Assess[] }>({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_project_week_assessment_list.guigu_hr_project_week_assessment_list.get_assessments',
		args: {
			week: week.value,
			type: props.type,
			department: departmentName.value,
		},
	});
	loading.value = false;
	assessList.value =
		res?.message.sort((pre, next) =>
			pre.user_full_name > next.user_full_name ? 1 : -1,
		) || [];
}
</script>

<style lang="less" scoped>
.title {
	margin-bottom: 0;
}
</style>
