<template>
	<div class="report-info_label">4. {{ tt("Machine Actual") }}</div>
	<AgGridVue
		class="ag-theme-guigu"
		:columnDefs="columnDefs"
		:rowData="machineList"
		rowSelection="multiple"
		animateRows="true"
		groupDefaultExpanded="-1"
		domLayout="autoHeight"
		:suppressDragLeaveHidesColumns="smallMeta"
		:localeText="zhCN"
		:defaultColDef="defaultColDef">
	</AgGridVue>
</template>

<script lang='ts'>
import {AgGridVue} from 'ag-grid-vue3';
import {computed, defineComponent, PropType} from 'vue';

import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';
import {MachineTypeActual} from '../type';
import Integer from '../../../../AgGrid/components/Integer.vue';
import RemarkCellRender from '../agGridComponents/RemarkCellRender.vue';
import {useMetaQuery} from '../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';

import getMachineColumns from './MachineColumn';
const tt = __;
export default defineComponent({
	components: {
		Integer,
		AgGridVue,
		RemarkCellRender,
	},
	props: {
		machineList: Object as PropType<MachineTypeActual[]>,
		enableAudit: Boolean,
	},
	setup(props, context) {
		const smallMeta = useMetaQuery();
		const columnDefs = computed(()=>getMachineColumns(props.enableAudit));
		const defaultColDef = {
			resizable: true,
		};
		return {
			props,
			columnDefs,
			defaultColDef,
			smallMeta,
			zhCN,
		};
	},
});
</script>

<style lang='less' scoped>
:deep(.ag-group-value) {
	flex: 1;
}
</style>
