<template>
	<Main
		:type="type"
		v-model:department="department"
		v-model:user="user"
		v-model:date="date"
		v-model:periodType="periodType"
		v-model:organization="organization"
	/>
</template>

<script setup lang='ts'>
import { getCurrentInstance, onMounted, onUnmounted, shallowRef, computed } from 'vue';

import Main from './Main.vue';
const instance = getCurrentInstance();
const params = shallowRef(new URLSearchParams(window.location.search));
const isInterview = () => window.location.pathname.includes('guigu_hr_assessment_interview');
const update = () => {
	if (!isInterview()){
		instance?.appContext.app.unmount();
		frappe.pages.guigu_hr_assessment_interview = undefined;
	}
	params.value = new URLSearchParams(window.location.search);
};

function switchParams(params: Record<string, string>) {
	if (!isInterview()) { return; }
	const search = new URLSearchParams(window.location.search);
	for (const [k, v] of Object.entries(params)) {
		search.set(k, v);
	}
	let s = search.toString();
	if (s[0] !== '?') { s = `?${s}`; }
	history.replaceState({}, '', s);
	update();
}

const periodType = computed<'week'|'month'>({
	get: () => params.value.get('period_type')||'',
	set(value: any) { if (value) { switchParams({periodType: value}); } },
});

const date = computed<string>({
	get: () => params.value.get('date')||'',
	set(value: any) { if (value) { switchParams({date: value}); } },
});
const type = computed<'project'|'subCompany'>({
	get:() => params.value.get('type')||'',
	set(value: any) { if (value) { switchParams({type: value}); } },
});
const department = computed<string>({
	get: () => params.value.get('department')||'',
	set(value) { if (value) { switchParams({department: value}); } },
});
const user = computed<string>({
	get: () => params.value.get('user')||'',
	set(value) { if (value) { switchParams({user: value}); } },
});
const organization = computed<string>({
	get:() => params.value.get('organization')||'',
	set(value) { if (value) { switchParams({organization: value}); } },
});

onMounted(() => { window.addEventListener('popstate', update); });
onUnmounted(() => { window.removeEventListener('popstate', update); });


</script>
