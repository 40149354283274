import {createApp} from 'vue';

import FileUploaderComponent from './FileUploader.vue';

function FileUploader(
	{
		method,
		doctype,
		docname,
		filetypes,
		fieldname,
	}: {
		doctype?: string;
		docname?: string;
		method?: string;
		filetypes?: string[];
		fieldname?: string,
	},
	onSuccess: (file: any, r?: any) => void,
	onAllSuccess:()=>void,
) {
	/** @type {frappe.ui.Dialog} */
	let dialog;
	function upload_files() {
		dialog.get_primary_btn().prop('disabled', true);
		dialog.get_secondary_btn().prop('disabled', true);
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		return uploader.upload_files();
	}


	dialog = new frappe.ui.Dialog({
		title: __('Upload'),
		primary_action_label: __('Upload'),
		primary_action: () => upload_files(),
	});
	dialog.show();

	dialog.$wrapper.on('hidden.bs.modal', function () {
		$(this).data('bs.modal', null);
		$(this).remove();
	});

	const app = createApp(FileUploaderComponent, {
		showUploadButton: false,
		doctype,
		docname,
		fieldname,
		filetypes,
		method,
		onSuccess,
		onAllSuccess,
		onUpload() {
			dialog.get_primary_btn().prop('disabled', true);
			dialog.get_secondary_btn().prop('disabled', true);
		},
		onClose() {
			dialog.hide();
			app.unmount();
		},
		onHideDialogFooter() {
			dialog.footer.addClass('hide');
			dialog.$wrapper.data('bs.modal')._config.backdrop = 'static';
		},
		onShowDialogFooter() {
			dialog.footer.removeClass('hide');
			dialog.$wrapper.data('bs.modal')._config.backdrop = true;
		},
	});

	const uploader = app.mount(dialog.body);


	// const [uploader] = app.$children;
}
export default FileUploader;
