import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0cea611b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Page"], null, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.tt('Dw Organization Process Setting')), 1 /* TEXT */)
    ]),
    sider: _withCtx(() => [
      _withDirectives(_createVNode($setup["Side"], {
        organizationList: $setup.organizationList,
        organization: $setup.organization,
        "onUpdate:organization": _cache[0] || (_cache[0] = $event => (($setup.organization) = $event))
      }, null, 8 /* PROPS */, ["organizationList", "organization"]), [
        [$setup["vLoading"], $setup.organizationLoading]
      ])
    ]),
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", null, [
        _createVNode($setup["Tools"], {
          relatePermission: $setup.relatePermission,
          onCreateRelation: $setup.createRelation
        }, null, 8 /* PROPS */, ["relatePermission"]),
        _createVNode($setup["Skeleton"], {
          layout: $setup.layoutType,
          doctype: "Dw Process",
          detailName: $setup.detailName,
          onOnChange: $setup.onChange,
          "form-visible": $setup.formVisible,
          "onUpdate:formVisible": _cache[1] || (_cache[1] = $event => (($setup.formVisible) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode($setup["Table"], {
              ref: "aggrid",
              class: "table-container",
              smallMeta: $setup.smallMeta,
              dataList: $setup.dataList,
              onShowDetail: $setup.showDetail,
              onUnLink: $setup.unLink,
              relatePermission: $setup.relatePermission
            }, null, 8 /* PROPS */, ["smallMeta", "dataList", "relatePermission"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["layout", "detailName", "form-visible"])
      ])), [
        [$setup["vLoading"], $setup.loading]
      ]),
      _createVNode($setup["RelateDialog"], {
        visible: $setup.visible,
        "onUpdate:visible": _cache[2] || (_cache[2] = $event => (($setup.visible) = $event)),
        processList: $setup.processList,
        organizationProcessList: $setup.organizationProcessList,
        onRelate: $setup.relate
      }, null, 8 /* PROPS */, ["visible", "processList", "organizationProcessList"])
    ]),
    _: 1 /* STABLE */
  }))
}