import type {Editor} from '@tiptap/vue-3';

import Block from './Block.vue';
import Tool from './Tool.vue';

export default {
	name: 'workBasis',
	tool: Tool,
	block: Block,
	beforeInsert(editor:Editor) {
		const json:{content?:any[]} = editor.getJSON();
		const isInserted = json?.content?.some(item=>item.type === 'chart' && item.attrs?.chart_type === this.name);
		if (isInserted) {
			frappe.show_alert({
				indicator: 'orange',
				message: __('This type of component only allows the insertion of one'),
			});
		}
		return !isInserted;
	},
};
