import G6, { Item, TreeGraph } from '@antv/g6';

import { cardWidth, headerHeight, lineHeight } from '../deaultOptions';
import { RenderData } from '../type/index';
const globalFontSize = 12;

const fittingString = (str: string, maxWidth: number, fontSize: number) => {
	const ellipsis = '...';
	const [ellipsisLength] = G6.Util.getTextSize(ellipsis, fontSize);
	let currentWidth = 0;
	let res = str;
	// distinguish the Chinese charactors and letters
	const pattern = new RegExp('[\u4E00-\u9FA5]+');
	str.split('').forEach((letter, i) => {
		if (currentWidth > maxWidth - ellipsisLength) {
			return;
		}
		if (pattern.test(letter)) {
			// Chinese charactors
			currentWidth += fontSize;
		} else {
			// get the width of single letter according to the fontSize
			currentWidth += G6.Util.getLetterWidth(letter, fontSize);
		}
		if (currentWidth > maxWidth - ellipsisLength) {
			res = `${str.substr(0, i)}${ellipsis}`;
		}
	});
	return res;
};
function getStringWidth(str: string, fontSize: number) {
	let currentWidth = 0;
	const pattern = new RegExp('[\u4E00-\u9FA5]+');
	str.split('').forEach((letter, i) => {
		if (pattern.test(letter)) {
			currentWidth += fontSize;
		} else {
			currentWidth += G6.Util.getLetterWidth(letter, fontSize);
		}
	});
	return currentWidth;
}
function toTreeCount(data: { children?: any[] }[]): number {
	return data.reduce((total: number, cur: any) => {
		if (!cur.children) {
			return total;
		}
		return total + toTreeCount(cur.children);
	}, data.length);
}
const ClassColor = {
	green: '#286840',
	orange: '#9c4621',
	red: '#c53030',
	blue: '#1366ae',
	gray: '#505a62',
};
export function registerDetailCard(graph: TreeGraph) {
	graph.on('afterlayout', () => {
		const layout = graph.get('layout');
		const { direction } = layout;
		const nodes = graph.getNodes();
		nodes.forEach(inode => {
			const { data } = inode.getModel();
			const dataLength = Object.entries(data || {}).length;
			const group = inode?.getContainer();
			const height = dataLength * lineHeight;
			const countContainer = group?.find(el => {
				const f = el.get('name') === 'count-container';
				return f;
			});
			const count = group?.find(el => el.get('name') === 'count');
			countContainer?.attr('x', direction === 'TB' ? cardWidth / 2 : cardWidth);
			countContainer?.attr('y', direction === 'TB' ? height + headerHeight : (height + headerHeight) / 2);
			count?.attr('x', direction === 'TB' ? cardWidth / 2 : cardWidth);
			count?.attr('y', direction === 'TB' ? height + 30 : (height + headerHeight) / 2 + 5);
		});
	});
	G6.registerNode('detailCard', {
		jsx(cfg) {
			const layout = graph.get('layout');
			const width = cardWidth;
			const label = fittingString(cfg.label?.toString() || '', width - 6, globalFontSize);
			const renderData = cfg.data as RenderData;
			const childrenLength = toTreeCount(cfg.children || []);
			const renderDataEntries = Object.entries(renderData || {});
			const bodyHeight = renderDataEntries.length * lineHeight;
			const detailString = renderDataEntries.map((item, index) => {
				const [fieldname, { label, value, origin }] = item;
				if (value === undefined || value === null) { return ''; }
				const str = `${label}: ${__(value)}`;
				const v = fittingString(str, width - 6, globalFontSize);
				const labelString = `${label}: `;
				const labelW = getStringWidth(labelString, globalFontSize);
				const valueString = `${__(value)}`;
				const valueEllipsis = fittingString(valueString, width - 6 - labelW, globalFontSize);
				const colorClass: string = typeof origin === 'string' ? frappe.utils.guess_colour(origin) : '';
				const color = colorClass === 'gray' ? '#c0c4cc' : ClassColor[colorClass] || '#c0c4cc';
				return `<rect style={{height:20}}><text 
					style={{ 
						lineHeight: 20, 
						fontSize:${globalFontSize}, 
						fill: '#1f272e',
						next:inline,
						marginLeft: 4 }}>${labelString}</text><text style={{
							lineHeight: 20, 
							fontSize:${globalFontSize}, 
							fill: '#1f272e',
							stroke:${color},
						}}>${valueEllipsis}</text></rect>`;
			}).join('');
			const { direction } = layout;
			const circleX = direction === 'TB' ? cardWidth / 2 : cardWidth;
			const circleY = direction === 'TB' ? 0 : -(bodyHeight + headerHeight) / 2;
			const childrenLengthString = childrenLength === 0 ? '' : `<circle name='count-container' style={{
				stroke: #c0c4cc,
				r: 15,
				fill: '#fff',
				cursor: 'pointer',
				marginLeft:${circleX},
				marginTop:${circleY},
			}}>
				<text name='count' style={{
					textAlign: 'center', cursor: 'pointer',
				}}>${childrenLength}</text>
			</circle>`;
			return `<group>
			<rect>
				<rect name='header' style={{
					width: ${width},
					height: ${headerHeight},
					fill: #fff,
					radius: [6, 6, 0, 0],
					stroke: #c0c4cc
				}} >
					<text style={{
					marginTop: 2,
					marginLeft: ${cardWidth / 2},
					textAlign: 'center',
					fontWeight: 'bold',
					fontSize:${globalFontSize},
					fill: '#1f272e' }}>${label}</text>
				</rect>
				<rect name='body' style={{
					width: ${width},
					height: ${bodyHeight},
					stroke: #c0c4cc,
					fill: #ffffff,
					radius: [0, 0, 6, 6],
					cursor: 'move',
					next:inline,
				}} draggable="true">

				${detailString}
				</rect>
			</rect>
			${childrenLengthString}
		</group>`;
		},
		getAnchorPoints(cfg) {
			const layout = graph.get('layout');
			const { direction } = layout;
			if (direction === 'TB') {
				return [
					[0.5, 0],
					[0.5, 1],
				];
			}
			return [
				[0, 0.5],
				[1, 0.5],
			];
		},
	},);

}
