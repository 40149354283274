<template>
	<ElFormItem :label="tt('Line Height')">
		<ElInputNumber v-model="lineHeight" />
	</ElFormItem>
	<ElFormItem :label="tt('Text Color')">
		<ElColorPicker v-model="textColor" />
	</ElFormItem>
	<ElFormItem :label="tt('Background Color')">
		<ElColorPicker v-model="bgColor" />
	</ElFormItem>
	<ElFormItem :label="tt('Even Line Background Color')">
		<ElColorPicker v-model="oddBgColor" />
	</ElFormItem>
	<ElFormItem :label="tt('Horizontal Table Line Color')">
		<ElColorPicker v-model="rowLineColor" />
	</ElFormItem>
	<ElFormItem :label="tt('Vertical Table Line Color')">
		<ElColorPicker v-model="colLineColor" />
	</ElFormItem>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import {ElFormItem, ElColorPicker, ElInputNumber} from 'element-plus';

import {ViewStyle} from '../types';
const props = defineProps<{
	modelValue?: ViewStyle;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: ViewStyle): void;
}>();
const style = computed({
	get: () => props.modelValue || {},
	set: v => emit('update:modelValue', v),
});
const tt = __;

const lineHeight = computed({
	get: () => style.value.lineHeight || 0,
	set: lineHeight => {
		style.value = {...style.value, lineHeight: lineHeight || 0};
	},
});
const textColor = computed({
	get: () => style.value.textColor || '',
	set: textColor => {
		style.value = {...style.value, textColor};
	},
});

const bgColor = computed({
	get: () => style.value.bgColor || '',
	set: bgColor => {
		style.value = {...style.value, bgColor};
	},
});

const oddBgColor = computed({
	get: () => style.value.oddBgColor || '',
	set: oddBgColor => {
		style.value = {...style.value, oddBgColor};
	},
});
const rowLineColor = computed({
	get: () => style.value.rowLineColor || '',
	set: rowLineColor => {
		style.value = {...style.value, rowLineColor};
	},
});
const colLineColor = computed({
	get: () => style.value.colLineColor || '',
	set: colLineColor => {
		style.value = {...style.value, colLineColor};
	},
});
</script>
