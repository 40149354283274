import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElDialog"], {
    modelValue: $setup.visible,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.visible) = $event)),
    title: $props.value ? $setup.tt('Modified') : $setup.tt('Create'),
    modal: "",
    width: "min(95%, 400px)"
  }, {
    footer: _withCtx(() => [
      _createVNode($setup["ElButtonGroup"], null, {
        default: _withCtx(() => [
          _createVNode($setup["ElButton"], {
            onClick: $setup.close,
            title: $setup.tt('Cancel'),
            icon: $setup.Close
          }, null, 8 /* PROPS */, ["title", "icon"]),
          _createVNode($setup["ElButton"], {
            type: "primary",
            onClick: $setup.submit,
            title: $setup.tt('Confirm'),
            disabled: !$setup.groups.length,
            icon: $setup.Finished
          }, null, 8 /* PROPS */, ["title", "disabled", "icon"])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _createVNode($setup["ElForm"], null, {
        default: _withCtx(() => [
          _createVNode($setup["ElFormItem"], null, {
            default: _withCtx(() => [
              _createVNode($setup["ElInput"], {
                modelValue: $setup.title,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.title) = $event))
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }),
          (!$props.value)
            ? (_openBlock(), _createBlock($setup["ElFormItem"], { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode($setup["ElCheckbox"], {
                    modelValue: $setup.publish,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.publish) = $event)),
                    label: $setup.tt('Public')
                  }, null, 8 /* PROPS */, ["modelValue", "label"])
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true),
          _createVNode($setup["ElFormItem"], null, {
            default: _withCtx(() => [
              _createVNode($setup["GroupEditor"], {
                modelValue: $setup.groups,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.groups) = $event)),
                options: $setup.groupOptions,
                label: $setup.tt('Group Configuration'),
                checkLabel: $setup.tt('Is Swimming Lane')
              }, null, 8 /* PROPS */, ["modelValue", "options", "label", "checkLabel"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode($setup["ElFormItem"], null, {
        default: _withCtx(() => [
          _createVNode($setup["GroupEditor"], {
            modelValue: $setup.fields,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.fields) = $event)),
            options: $setup.showOptions,
            repeatable: "",
            label: $setup.tt('Can Display Column Configuration'),
            checkLabel: $setup.tt('Show Tags')
          }, null, 8 /* PROPS */, ["modelValue", "options", "label", "checkLabel"])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}