
import isIncluding from './isIncluding.mjs';
import type { DateKey, Line, LineMeta } from './nyloongTable/gantt';
import type { GanttLine } from './types';

function getColor(
	dates: ([Date, Date | null, LineMeta?] | null)[],
	index: number,
	refer: string,
	colors: (string | undefined)[],
): string | undefined | null {
	const current = dates[index];
	if (!current) { return null; }
	const reference = dates.find(v => {
		const meta = v?.[2];
		if (!meta) { return; }
		return meta.name === refer && meta.type === 'line';
	});
	const today = new Date();
	today.setHours(23, 59, 59, 999);
	// 是否已完成
	const [, end] = current;
	const ended = end && end <= today || false;
	// 是否超期
	const planEnd = reference?.[1];
	const overdue = planEnd && planEnd < (ended && end || today) || false;
	const k = ended ? 2 : 0;
	return overdue && colors[k + 1] || colors[k];
}


export default function getLine(v: GanttLine, index: number): Line {
	const {
		width, position, start: startField, end: endField,
		reference, color, borderColor,
		filterField, filterValue,
	} = v;
	const name = v.key || `${index + 1}`;
	const start: DateKey = filterField ? (data: any, dates, endDates) => {
		if (!isIncluding(data[filterField], filterValue)) { return; }
		return dates[startField];
	} : startField;
	const end: DateKey = filterField ? (data: any, dates, endDates) => {
		if (!isIncluding(data[filterField], filterValue)) { return; }
		return endDates[endField];
	} : endField;
	if (!reference) {
		return {
			meta: { name, type: 'line' },
			width, position, start, end,
			color: () => color || '', borderColor: () => borderColor || '',
		};
	}
	const c1 = [
		v.processingColor, v.processingOverdueColor,
		v.completeColor, v.completeOverdueColor,
	];
	const c2 = [
		v.processingBorderColor, v.processingOverdueBorderColor,
		v.completeBorderColor, v.completeOverdueBorderColor,
	];
	return {
		meta: { name, type: 'line' },
		width, position, start, end,
		color: (v, i, d) => getColor(d, i, reference, c1) || color || '',
		borderColor: (v, i, d) => getColor(d, i, reference, c2) || borderColor || '',
	};

}
