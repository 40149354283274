<template>
	<ElPagination
		class="view-pagination"
		:total="total"
		v-model:currentPage="currentPage"
		:pageSize="limit"
		:disabled="loading"
		background
		small
		:pagerCount="5"
		layout="total, prev, pager, next, jumper" />
</template>
<script lang="ts" setup>
import {computed} from 'vue';
import {ElPagination} from 'element-plus';

defineOptions({name: 'Pagination'});

const props = defineProps<{
	total: number;
	loading: boolean;

	page: number;
	limit: number;
}>();
const emit = defineEmits<{
	(event: 'update:page', page: number): void;
}>();
const currentPage = computed({
	get() {
		return props.page;
	},
	set(page) {
		emit('update:page', page);
	},
});
</script>
<style lang="less" scoped>
.view-pagination {
	margin-block: 4px;
}
</style>
