
<template>
	<ElTooltip placement="top" :content="description?.value" :visible="props.isSilentMode && isTooltipVisible">
		<div ref="containerRef" class="node-container" @mouseenter="isTooltipVisible = true" @mouseleave="isTooltipVisible = false">
			<ElTooltip placement="top" :content="description?.value" v-if="!props.isSilentMode">
				<ElIcon class="icon" @click="click" :size="20" :style="{ cursor: 'pointer' }"><ChatLineRound /></ElIcon>
			</ElTooltip>
			<svg class="document-svg" width="100%" height="100%">
				<path :d="bezierPath" stroke="#187dff" fill="transparent"/>
				<path :d="borderPath" stroke="#187dff" fill="transparent" stroke-width="4"/>
			</svg>
		</div>

	</ElTooltip>
</template>

<script setup lang="ts">
import {ref, watch, type Ref} from 'vue';

import {ChatLineRound} from '@element-plus/icons-vue';
import {ElIcon, ElTooltip} from 'element-plus';

interface Props{
	text?:Ref<string>
	description?: Ref<string>
	isSilentMode:Ref<boolean>
}
const props = defineProps<Props>();
const isTooltipVisible = ref<boolean>(false);
const containerRef = ref<any>();
const bezierPath = ref<string>('');
const borderPath = ref<string>('');
interface Emits{
	(e:'edit'):void
	(e:'resize', size:{width:number, height:number}):void
}
const emit = defineEmits<Emits>();
function click() {
	emit('edit');
}
const observer = new ResizeObserver(entries=>{
	if (!entries[0].target) {
		return;
	}
	const gElement = document.querySelector('.lf-canvas-overlay g[transform]');
	// 计算转换倍数，lg在fitView时，可能会对节点进行transform变换
	let xRate = 1;
	let yRate = 1;
	if (gElement) {
		const matrix = window.getComputedStyle(gElement).transform;
		const matrixObject = new WebKitCSSMatrix(matrix);
		xRate = matrixObject.a;
		yRate = matrixObject.d;
	}

	const {height, width} = entries[0].target.getBoundingClientRect();
	const distance = 10;
	// 开始节点的X为0，控制节点的X为width / 4，第二条波浪线开始节点为width / 2
	const helfWidth = width / 2;
	// 计算半径
	const Rad = ((helfWidth / 2) ** 2 + distance ** 2) / (2 * distance);
	// 计算开始节点的Y坐标
	const YPos = height - 2 * distance;
	// 计算控制节点与开始节点的距离
	let CH = 0;
	if (Rad !== distance) {
		CH = Rad ** 2 / (Rad - distance) - (Rad - distance);
	}
	// 计算控制节点的Y坐标
	const CY = YPos - CH;
	console.log('helfWidth', helfWidth);
	console.log('Rad', Rad);
	bezierPath.value = `M 0 ${YPos / yRate} Q ${helfWidth / 2 / xRate} ${CY / yRate}, ${helfWidth / xRate} ${YPos / yRate} T ${width / xRate} ${YPos / yRate}`;
	borderPath.value = `M 0 ${YPos / yRate} L 0,0 L ${width / xRate},0 L ${width / xRate},${YPos / yRate}`;
});
watch(containerRef, ()=>{
	if (!containerRef.value) {
		return;
	}
	observer.observe(containerRef.value);
}, {immediate: true});
</script>

<style lang="less" scoped>
.icon{
	position: absolute;
}
.node-container {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
    text-align: center;
    justify-content: flex-end;
	align-items: center;
}
</style>
