<template>
	<div class="toolbar">
		<!-- <ElSpace> -->
		<ProjectCom
			class="elSelectPadding"
			default
			noLabel
			v-model="projectValue"
		></ProjectCom>
		<el-select :size="size" class="elSelectPadding" :modelValue="pageType"

			@change="pageTypeChange">
			<el-option
				value="detail"
				key="detail"
				:label="tt('assessment details')" />
			<el-option
				value="summary"
				key="summary"
				:label="tt('assessment summary')" />
		</el-select>

		<el-date-picker
			:size="size"
			class="dateSelectPadding"
			v-if="pageType === 'detail'"
			v-model="monthValue"
			@change="monthChange"
			type="month"
			:placeholder="tt('Select Month')">
		</el-date-picker>

		<el-date-picker
			:size="size"
			class="dateSelectPadding"
			v-if="pageType === 'summary'"
			v-model="yearValue"
			@change="yearChange"
			type="year"

			:placeholder="tt('Select Year')">
		</el-date-picker>

		<!-- </ElSpace> -->
		<div class="refresh">
			<ElSelect :size="size" class="modalSelectPadding" :modelValue="detailType"
				@change="detailTypeChange">
				<ElOption
					value="modal"
					key="modal"
					:label="tt('Details Dialog')" />
				<ElOption
					value="link"
					key="link"
					:label="tt('Jump to page')" />
				<ElOption
					value="bottom"
					key="bottom"
					:label="tt('vertical layout')" />
				<ElOption
					value="right"
					key="right"
					:label="tt('Left and right layout')" />
			</ElSelect>
			<el-button-group class="ml-4">
				<el-button :size="size" @click="() => expandAll(true)">{{ tt('Expand') }}</el-button>
				<el-button :size="size" @click="() => expandAll(false)">{{ tt('Collapse') }}</el-button>
				<el-button :size="size" :loading="loading" @click="refresh" :title="tt('Refresh')"
					:icon="RefreshRight">
					{{ tt('Refresh') }}
				</el-button>
			</el-button-group>

		</div>
	</div>
</template>
<script lang="ts" setup>

import {ref, onMounted, onUnmounted, defineEmits, defineProps, toRefs, computed} from 'vue';

import moment from 'moment';
import {GridApi} from 'ag-grid-community';

import {RefreshRight} from '@element-plus/icons-vue';

import {Project} from '../../type';
import ProjectCom from '../../../../../../../../guigu/guigu/public/js/components/PageComponents/Tools/Project.vue';

import {routeInit, setRoute} from './helper';

const tt = __;
interface Props {
	project?: string
	organization?: string
	pageType?: string
	monthDate?: string
	yearDate?: string
	refreshValue?: number
	detailGridApi?: GridApi
	loading?: string
	detailType?: string
}

const props = defineProps<Props>();
const {project, pageType, monthDate, yearDate, refreshValue, detailGridApi, loading} = toRefs(props);

interface Emit {
	(e: 'update:project', project: string): void,
	(e: 'update:monthDate', monthDate: string): void,
	(e: 'update:yearDate', yearDate: string): void,
	(e: 'update:pageType', type: string): void,
	(e: 'update:refreshValue', refreshValue: number): void,
	(e: 'update:detailType', detailType: string): void,
}

const emit = defineEmits<Emit>();

const projects = ref<Project[]>();
const monthValue = ref<string>(monthDate?.value || moment());
const yearValue = ref<string>(yearDate?.value || '');

const paramsFilter = ref<{ [key: string]: string }>({});

const size = ref<string>('small');

function updateComponentSize() {
	const widthElement = window.innerWidth;
	if (widthElement <= 640) {
		size.value = 'default';
	} else {
		size.value = 'small';
	}
}
window.addEventListener('resize', updateComponentSize);

onUnmounted(() => window.removeEventListener('resize', updateComponentSize));

onMounted(async () => {
	updateComponentSize();
	routeInit(paramsFilter);
	const projectsRes: Project[] = await frappe.db.get_list(
		'Project',
		{
			fields: ['name', 'project_name', 'project_abbr_name'],
			filters: {status: 'Open'},
			limit: '',
		},
	);
	projects.value = projectsRes;
	const projectNames = projectsRes.map(item => item.name);
	const sessionDefaultProject: string = (frappe.defaults.get_user_defaults('project') || [])[0];
	let defaultProject: string = '';
	if (projectNames.includes(paramsFilter.value?.project)) {
		defaultProject = paramsFilter.value?.project;
	} else {
		defaultProject = sessionDefaultProject || projectsRes[0]?.name || '';
	}
	const defaultPageType: string = paramsFilter.value?.pageType || props.pageType || 'detail';
	const monthDate: string = paramsFilter.value?.monthDate || props.monthDate || moment().format('YYYY-MM');
	const yearDate: string = paramsFilter.value?.yearDate || props.yearDate || moment().format('YYYY');
	let routeObj: { [key: string]: any } = {};
	monthValue.value = monthDate;
	yearValue.value = yearDate;
	if (defaultPageType === 'detail') {
		routeObj = {project: defaultProject, pageType: defaultPageType, monthDate, yearDate};
		emit('update:pageType', 'detail');
	} else {
		routeObj = {project: defaultProject, pageType: defaultPageType, monthDate, yearDate};
		emit('update:pageType', 'summary');
	}
	emit('update:monthDate', moment(monthDate).format('YYYY-MM'));
	emit('update:yearDate', yearDate);
	emit('update:project', defaultProject);
	setRoute(paramsFilter, routeObj);
});

function expandAll(expand: boolean) {
	if (expand) {
		detailGridApi?.value?.expandAll();
	} else {
		detailGridApi?.value?.collapseAll();
	}
}

const projectValue = computed({
	get: () => paramsFilter.value?.project || '',
	set: (v: string) => {
		setRoute(paramsFilter, {project: v});
		emit('update:project', v);
	},
});

function monthChange(date: moment) {
	const dateValue = date || moment();

	setRoute(paramsFilter, {monthDate: moment(dateValue).format('YYYY-MM')});
	monthValue.value = dateValue;
	emit('update:monthDate', moment(dateValue).format('YYYY-MM'));
}

function yearChange(date: moment) {
	const dateValue = date || moment();
	setRoute(paramsFilter, {yearDate: moment(dateValue).format('YYYY')});
	yearValue.value = dateValue;
	emit('update:yearDate', moment(dateValue).format('YYYY'));
}

function pageTypeChange(type: string) {
	setRoute(paramsFilter, {pageType: type});
	emit('update:pageType', type);
}
function detailTypeChange(type: string) {
	emit('update:detailType', type);
}

function refresh() {
	const newValue: number = (refreshValue?.value || 0) + 1;
	emit('update:refreshValue', newValue);
}
</script>
<style lang="less" scoped>
.toolbar {
  padding: 8px 0;
}

@media (min-width: 640px) {
  .refresh {
    float: right
  }

  .elSelectPadding {
    margin-right: 12px;

    :deep(.select-trigger .el-input .el-input__wrapper) {
      height: 24px;
    }
  }

  .modalSelectPadding {
    :deep(.select-trigger .el-input .el-input__wrapper) {
      height: 24px;
    }
  }

}

@media (max-width: 640px) {
  .refresh {
    float: none;
    padding-top: 12px;
  }

  .dateSelectPadding {
    :deep(.el-date-editor.el-input) {
      width: 100% !important
    }
  }

  .elSelectPadding {
    padding-bottom: 12px;
    width: 100% !important
  }

  .modalSelectPadding {
    padding-bottom: 12px;
    width: 100% !important
  }
}

.ml-4 {
  margin: 0 !important;
  padding: 0 !important
}
</style>
