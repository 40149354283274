<template>
	<ElFormItem :label="tt('Organization')">
		<ElTreeSelect v-model="organizations" :data="options" multiple
			filterable defaultFirstOption clearable checkStrictly highlightCurrent
			defaultExpandAll style="width: 100%" />
	</ElFormItem>
</template>
<script setup lang="ts">
import { computed, shallowRef } from 'vue';

import { ElTreeSelect, ElFormItem } from 'element-plus';

import getAllOrganization, { type Option } from './getAllOrganization';


const props = defineProps<{
	meta: locals.DocType;
	modelValue: any;
	define?: GlobalView;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: GlobalView.View): void;
}>();
const modelValue = computed({
	get: () => props.modelValue,
	set: v => emit('update:modelValue', v),
});
const tt = __;


const options = shallowRef<Option[]>([]);


const organizations = computed({
	get: () => (modelValue.value.organizations || '').split('\n').filter(Boolean),
	set: v => { modelValue.value = { ...modelValue.value, organizations: v.join('\n') }; },
});
getAllOrganization().then(data => {
	options.value = data;
});


</script>
