<template>
	<div v-if="props?.params.data?.is_unit_target" :class="className">
		{{props?.params.data?.diary?tt("Diary Reported"):tt("Diary not Report")}}
	</div>
</template>
<script lang="ts" setup>
import { defineProps, computed} from 'vue';

import { Delete } from '@element-plus/icons-vue';
import {
	ICellRendererParams,
  } from 'ag-grid-community';
const tt = __
const props = defineProps<{params:ICellRendererParams}>();
const className = computed(()=>props?.params.data?.diary?'green':'red');
</script>
<style lang="less" scoped>
.green {
	background-color: #1dff1d;
}

.red {
	background-color: #ff9898;
}
</style>
