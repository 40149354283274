<template>
	<div class="table-container">
		<div class="img-container" v-if="imageData.length > 0">
			<div class="img-item" v-for="img in imageData">
				<img class="img" :src="encodeURI(img || '').replace(/#/g, '%23')" />
			</div>
		</div>
		<AgGridVue
			class="ag-theme-guigu"
			:columnDefs="columnDefs"
			:rowData="rowData"
			:suppressDragLeaveHidesColumns="smallMeta"
			:localeText="zhCN"
			domLayout="autoHeight" />
	</div>
</template>

<script setup lang='ts'>
import type {Component} from 'vue';
import {computed} from 'vue';
import {AgGridVue} from 'ag-grid-vue3';

import {subDocFieldFilter} from '../pretreat';
import {useMetaQuery} from '../../hooks/useMetaQuery';
import zhCN from '../../agGrid/zhCN';

import FileComponent from './childrenTableComponents/File.vue';
import LinkComponent from './childrenTableComponents/Link.vue';
import BaseValueComponent from './childrenTableComponents/BaseValue.vue';
const tt = __;
interface Props {
	data: Record<string, any>;
	field: GlobalView.FieldRenderOptions;
	value: any
	format?: string;
}
const props = defineProps<Props>();
const smallMeta = useMetaQuery();
const subMeta = computed(() => frappe.get_meta(props.field.options || ''));
const fields = computed(() => subDocFieldFilter(subMeta.value?.fields || [], props.field.parent || ''));
const rowData = computed(() => {
	const {value} = props;
	if (Array.isArray(value)) {
		return value;
	}
	return [];
});
const imageExt = new Set([
	'bmp', 'jpg', 'jpeg', 'png', 'gif',
	'tiff', 'svg', 'webp',
	'xbm', 'pjp', 'svgz', 'ico', 'jfif', 'pjpeg', 'avif',
]);
function isImage(v?: string): v is string {
	if (!v) {
		return false;
	}
	const e = v.split('.').pop();
	if (!e) {
		return false;
	}
	return imageExt.has(e.toLowerCase());
}
const imageData = computed(() => {
	const imageFields = fields.value
		.filter(item => ['Attach', 'Attach Image'].includes(item.fieldtype));
	if (!imageFields.length) {
		return [];
	}
	return rowData.value.flatMap(tableRow => imageFields.map(ImageColumn => {
		const value = tableRow[ImageColumn.fieldname];
		if (typeof value !== 'string') {
			return;
		}
		return value;
	})).filter(isImage);
});

const renderer: Record<string, Component> = {
	Link: LinkComponent,
	'Tree Select': LinkComponent,
	'Tianjy Related Link': LinkComponent,
	Attach: FileComponent,
	'Attach Image': FileComponent,
};
const columnDefs = computed(() => fields.value.map(field => ({
	headerName: tt(field.label),
	field: field.fieldname,
	enableRowGroup: false,
	editable: false,
	filter: false,
	flex: 1,
	cellRenderer: renderer[field.fieldtype] || BaseValueComponent,
	cellRendererParams: {field},
})) || []);


</script>

<style lang='less' scoped>
.table-container {
	white-space: normal;
}

.img-container {
	margin-bottom: 8px;
	overflow-x: auto;
	white-space: nowrap;
}

.img-item {
	display: inline-flex;
	width: 100px;
	height: 100px;
	vertical-align: middle;
	align-items: center;
	justify-content: center;
	box-shadow: 4px 4px 15px #666;
}

.img {
	width: 100px;
	height: 100px;
	object-fit: cover;
}
</style>
