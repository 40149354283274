import { create_chart } from './high_chart';

function render_chart(wrapper, options, customOptions = {}, raw_values) {
	for (let key in customOptions) {
		if (typeof options[key] === 'object' && typeof customOptions[key] === 'object') {
			if (key === 'colors' && Array.isArray(customOptions[key])) {
				const colors = customOptions.colors.filter(Boolean);
				if (colors.length === 0) { continue; }
			}
			options[key] = Object.assign(options[key], customOptions[key]);
		} else {
			options[key] = customOptions[key];
		}
	}
	options.colors = options.colors?.filter(Boolean);
	if (!options.colors || options.colors?.length === 0) {
		delete options.colors;
	}
	if (options.type !== 'bcwp') {
		return new frappe.Chart(wrapper, options);
		return;
	}
	const percentOptions = raw_values.y_axis_fields;
	const percentArr = percentOptions.map((item, index) => {
		const denominatorIndex = percentOptions.findIndex(each => each.y_field === item.denominator);
		return {
			field: item.y_field,
			denominator: item.denominator,
			percentTitle: item.percent_title,
			fieldIndex: index,
			denominatorIndex,
		};
	});
	const series = options.data.datasets.map((item, index) => {
		const column_data = {
			yAxis: 0,
			name: __(item.name),
			type: 'column',
			data: item.values,
		};
		const lineData = [];
		for (let i = 0; i < item.values.length; i++) {
			const arr = item.values.slice(0, i + 1);
			const result = arr.reduce((pre, next) => pre + next, 0);
			lineData.push(result);
		}
		const line_data = {
			yAxis: 1,
			name: __(item.name),
			type: 'line',
			data: lineData,
		};
		return [column_data, line_data];
	});
	const chart_args = {
		title: {
			text: null,
		},
		...options,
		tooltip: {
			shared: true,
			formatter: chart => {
				const points = chart.chart.hoverPoints;
				let s = `${chart.chart.hoverPoint.category}</br>`;
				points.forEach((item, index) => {
					const { name } = item.series;
					const value = item.y;
					s = `${s}<span style="color:${item.color}">${name}:${value}</span><br/>`;
					if (index % 2 === 1) {
						const rowIndex = Math.floor(index / 2);
						const percentOption = percentArr.find(each => each.fieldIndex === rowIndex);
						if (percentOption.denominatorIndex > -1) {
							const percentIndex = percentOption.denominatorIndex * 2;
							const columnPercentValue = points[percentIndex]?.y;
							const linePercentValue = points[percentIndex + 1]?.y;
							const columnPercent = columnPercentValue && columnPercentValue > 0 ? `${(points[index - 1].y / columnPercentValue * 100).toFixed(2)}%` : '';
							const linePercent = linePercentValue && linePercentValue > 0 ? `${(value / linePercentValue * 100).toFixed(2)}%` : '';
							s = `${s}<span style="color:${item.color}">${percentOption.percentTitle}:${columnPercent}</span><br/>`;
							s = `${s}<span style="color:${item.color}">${percentOption.percentTitle}:${linePercent}</span><br/>`;
						}
					}
				});
				return s;
			},
		},
		credits: {
			enabled: false,
		},
		yAxis: [{
			'title': { 'text': __('Chart Current Amount') },
		},
		{
			'title': { 'text': __('Chart Cumulative Quantity') },
			'opposite': true,
		}],
		xAxis: {
			categories: options.data.labels,
		},
		series: series.flat(),
	};
	create_chart(wrapper, chart_args);
}
frappe.views.QueryReport.prototype.render_chart = function (options, customOptions, raw_values) {
	this.$chart.empty();
	this.$chart.show();
	this.chart = render_chart(this.$chart[0], options, customOptions, raw_values);
};

frappe.views.QueryReport.prototype.open_create_chart_dialog = function open_create_chart_dialog() {
	const me = this;
	let field_options = frappe.report_utils.get_field_options_from_report(
		this.columns,
		this.raw_data
	);

	function set_chart_values(values) {
		values.y_fields = [];
		values.colors = [];
		if (values.y_axis_fields) {
			values.y_axis_fields.map(f => {
				values.y_fields.push(f.y_field);
				values.colors.push(f.color);
			});
		}

		values.y_fields = values.y_fields.map(d => d.trim()).filter(Boolean);

		return values;
	}

	function preview_chart() {
		const wrapper = $(dialog.fields_dict.chart_preview.wrapper);
		let values = dialog.get_values(true);
		values = set_chart_values(values);

		if (values.x_field && values.y_fields.length) {
			let options = frappe.report_utils.make_chart_options(
				me.columns,
				me.raw_data,
				values
			);
			me.chart_fields = values;
			wrapper.empty();
			render_chart(wrapper[0], options, JSON.parse(values.custom_options || '{}'), dialog.get_values(true));
			wrapper.find('.chart-container .title, .chart-container .sub-title').hide();
			wrapper.show();

			dialog.fields_dict.create_dashoard_chart.df.hidden = 0;
			dialog.refresh();
		} else {
			wrapper[0].innerHTML = `<div class="flex justify-center align-center text-muted" style="height: 120px; display: flex;">
				<div>${__('Please select X and Y fields')}</div>
			</div>`;
		}
	}

	const dialog = new frappe.ui.Dialog({
		title: __('Create Chart'),
		fields: [
			{
				fieldname: 'x_field',
				label: 'X Field',
				fieldtype: 'Select',
				default: me.chart_fields ? me.chart_fields.x_field : null,
				options: field_options.non_numeric_fields,
			},
			{
				fieldname: 'cb_1',
				fieldtype: 'Column Break',
			},
			{
				fieldname: 'chart_type',
				label: 'Type of Chart',
				fieldtype: 'Select',
				options: ['BCWP', 'Bar', 'Line', 'Percentage', 'Pie', 'Donut'],
				default: me.chart_fields ? me.chart_fields.chart_type : 'Bar',
			},
			{
				fieldname: 'sb_1',
				fieldtype: 'Section Break',
				label: 'Y Axis',
			},
			{
				fieldname: 'y_axis_fields',
				fieldtype: 'Table',
				fields: [
					{
						fieldtype: 'Select',
						fieldname: 'y_field',
						name: 'y_field',
						label: __('Y Field'),
						options: field_options.numeric_fields,
						in_list_view: 1,
					},
					{
						fieldtype: 'Select',
						fieldname: 'denominator',
						name: 'denominator',
						label: __('Denominator'),
						options: field_options.numeric_fields,
						in_list_view: 1,
					},
					{
						fieldtype: 'Data',
						fieldname: 'percent_title',
						name: 'percent_title',
						label: __('Percent Title'),
						in_list_view: 1,
					},
					{
						fieldtype: 'Color',
						fieldname: 'color',
						name: 'color',
						label: __('Color'),
						in_list_view: 1,
					},
				],
			},
			{
				fieldname: 'custom_options',
				label: 'Custom Options',
				fieldtype: 'Code',
			},
			{
				fieldname: 'preview_chart_button',
				fieldtype: 'Button',
				label: 'Preview Chart',
				click: preview_chart,
			},
			{
				fieldname: 'sb_2',
				fieldtype: 'Section Break',
				label: 'Chart Preview',
			},
			{
				fieldname: 'chart_preview',
				label: 'Chart Preview',
				fieldtype: 'HTML',
			},
			{
				fieldname: 'create_dashoard_chart',
				label: 'Add Chart to Dashboard',
				fieldtype: 'Button',
				hidden: 1,
				click: () => {
					dialog.hide();
					this.add_chart_to_dashboard();
				},
			},
		],
		primary_action_label: __('Create'),
		primary_action: values => {
			values = set_chart_values(values);

			let options = frappe.report_utils.make_chart_options(
				this.columns,
				this.raw_data,
				values
			);
			me.chart_fields = values;

			let x_field_label = field_options.numeric_fields.filter(
				field => field.value == values.y_fields[0]
			)[0].label;
			let y_field_label = field_options.non_numeric_fields.filter(
				field => field.value == values.x_field
			)[0].label;

			options.title = __('{0}: {1} vs {2}', [
				this.report_name,
				x_field_label,
				y_field_label,
			]);

			this.render_chart(options, JSON.parse(values.custom_options || '{}'), dialog.get_values(true));
			this.add_chart_buttons_to_toolbar(true);

			dialog.hide();
		},
	});

	dialog.show();

	// load preview after dialog animation
	setTimeout(preview_chart, 500);
};
