<template>
	<ElEmpty :description="emptyText||tt('No Instruction')">
		<ElButton v-if="editable" type="primary" @click="create">{{ addButtonText||tt('Add Instruction') }}</ElButton>
	</ElEmpty>
</template>

<script setup lang='ts'>
import {ElEmpty, ElButton} from 'element-plus';
interface Props {
	instructionName: string;
	editable?:boolean
	referenceType:string,
	referenceName:string,
	addButtonText?:string
	emptyText?:string
}

const props = defineProps<Props>();
function create() {
	const searchUrl = new URLSearchParams();
	if (props.instructionName) {
		searchUrl.set('instruction_name', props.instructionName);
	}
	if (props.referenceType) {
		searchUrl.set('reference_type', props.referenceType);
	} if (props.referenceName) {
		searchUrl.set('reference_name', props.referenceName);
	}
	frappe.router.push_state(`/app/dw_instruction?${encodeURIComponent(searchUrl.toString())}`);
}
const tt = __;
</script>

<style lang='less' scoped>

</style>
