import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c8b301b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flow-container" }
const _hoisted_2 = {
  id: "container",
  ref: "flowRef"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, null, 512 /* NEED_PATCH */),
    _createVNode($setup["EditDialog"], {
      visible: $setup.editVisible,
      "onUpdate:visible": _cache[0] || (_cache[0] = $event => (($setup.editVisible) = $event)),
      text: $setup.editModel?.properties.description,
      onConfirm: $setup.confirm
    }, null, 8 /* PROPS */, ["visible", "text"])
  ]))
}