import {defineStore} from 'pinia';
import {ref} from 'vue';

import type {AfterHookParams, Instruction, Version} from './type';
export const DefaultVersion = {
	name: '',
	content: '',
	instruction_version: '',
	change_log: '',
	instruction: '',
	title: '',
	checker: '',
	compiler: '',
};
export const DefaultInstruction = {
	name: '',
	reference_type: '',
	reference_name: '',
	current_version: '',
};

export const useInstructionStore = defineStore('instruction', ()=>{
	const versions = ref<Version[]>([]);
	const currentVersion = ref<Version>(DefaultVersion);
	const instruction = ref<Instruction>(DefaultInstruction);
	const editable = ref<boolean>(false);
	const isSaveNewVersion = ref<boolean>(false);
	const loading = ref<boolean>(true);
	const isChange = ref<boolean>(false);
	const content = ref<string>('');
	let afterSaveHooks:((p:AfterHookParams)=>void)[] = [];
	function setData(originInstruction?:Instruction, originVersions?:Version[]) {
		const version = (originVersions || [])?.find(item=>item.name === originInstruction?.current_version);
		loading.value = false;
		versions.value = originVersions || [];
		currentVersion.value = version || DefaultVersion;
		instruction.value = originInstruction || DefaultInstruction;
		isChange.value = false;
		content.value = currentVersion.value.content;
	}
	async function initInstruction(instructionName:string) {
		const result = await getInstruction(instructionName);
		const {instruction: originInstruction, versions: originVersions} = result?.message || {};
		setData(originInstruction, originVersions);
	}
	async function getInstruction(instructionName:string) {
		const result = await frappe.call<{message:{instruction:Instruction, versions:Version[]}}>({
			method: 'dw_worklist_management.dw_worklist_management.doctype.dw_work_instruction.dw_work_instruction.get_instruction_versions',
			args: {instruction_name: instructionName},
		});
		return result;
	}
	async function loadTemplateInstruction(instructionName:string) {
		loading.value = true;
		const result = await getInstruction(instructionName);
		const {instruction: originInstruction, versions: originVersions} = result?.message || {};
		const version = (originVersions || [])?.find(item=>item.name === originInstruction?.current_version);
		const originContent:{content:{type:string, attrs:{data:string, chart_type:string}}[]} = JSON.parse(version?.content || '{}');

		const charts = originContent.content.filter(item=>item.type === 'chart');
		const copyBlocks = charts.map(item=>{
			let name = [];
			if (item.attrs.chart_type === 'instruction_flow') {
				name = [item.attrs.data];
			} else {
				name = JSON.parse(item.attrs.data);
			}
			return {
				type: item.attrs.chart_type,
				name,
			};
		});
		const {message: copied_blocks} = await frappe.call<{message:{[key:string]:Record<string, string>}}>({
			method: 'dw_worklist_management.api.instruction.block.copy_block',
			args: {block_data: copyBlocks},
		}) || {};
		for (const item of originContent.content) {
			if (item.type !== 'chart') {
				continue;
			}
			let newNames:string = '';
			const blockNames = copied_blocks?.[item.attrs.chart_type] || {};
			if (item.attrs.chart_type === 'instruction_flow') {
				newNames = blockNames[item.attrs.data];
			} else {
				newNames = JSON.stringify(JSON.parse(item.attrs.data)?.map(each=>blockNames[each]));
			}
			item.attrs.data = newNames;
		}
		const newContent = JSON.stringify(originContent);
		currentVersion.value = {
			...version || DefaultVersion,
			content: newContent,
			name: currentVersion.value.name,
			instruction_version: currentVersion.value.instruction_version,
			change_log: currentVersion.value.change_log,
			instruction: currentVersion.value.instruction,
			checker: currentVersion.value.checker || '',
			compiler: currentVersion.value.compiler || '',
		};
		content.value = currentVersion.value.content;
		isChange.value = true;
		loading.value = false;
	}

	function save(extendOptions?:{
		version?:Partial<Version>,
		instruction?:Partial<Instruction>,
	}) {
		loading.value = true;
		const {instruction: exInstruction, version: exVersion} = extendOptions || {};
		const instructionData:Instruction = {
			name: instruction.value.name || '',
			reference_type: instruction.value.reference_type,
			reference_name: instruction.value.reference_name,
			current_version: isSaveNewVersion.value ? '' : currentVersion.value?.name || '',
			...exInstruction,
		};
		const version = {
			name: isSaveNewVersion.value ? '' : currentVersion.value?.name || '',
			title: currentVersion.value.title,
			content: currentVersion.value.content,
			instruction_version: currentVersion.value.instruction_version,
			change_log: currentVersion.value.change_log,
			instruction: currentVersion.value.instruction,
			checker: currentVersion.value.checker,
			compiler: currentVersion.value.compiler,
			...exVersion,
		};
		const notVersionNo = !currentVersion.value.instruction_version;
		const notTitle = !currentVersion.value.title;
		const noChecker = !version.checker;
		const npCompiler = !version.compiler;
		if (notVersionNo || notTitle || npCompiler || noChecker) {
			const arr = [
				notVersionNo ? __('Version') : '',
				notTitle ? __('Subject') : '',
				noChecker ? __('instruction Checker') : '',
				npCompiler ? __('instruction Compiler') : '',
			].filter(Boolean);
			frappe.show_alert({
				message: __('Please input field: {}', [
					arr.join(','),
				]),
				indicator: 'red',
			});
			loading.value = false;
			return;
		}
		saveInstruction(instructionData, version);
	}
	async function saveInstruction(instructionData:Instruction, versionData:Version) {
		const result = await frappe.call<{message:{instruction:Instruction, versions:Version[]}}>({
			method: 'dw_worklist_management.dw_worklist_management.doctype.dw_work_instruction.dw_work_instruction.save_instruction',
			args: {instruction_data: instructionData, version: versionData},
		});
		const {instruction: originInstruction, versions: originVersions} = result?.message || {};
		try {
			for (const hook of afterSaveHooks) {
				hook({instruction: originInstruction, versions: originVersions});
			}
		} catch {

		}

		setData(originInstruction, originVersions);
		frappe.show_alert({message: __('Saved Successfully'), indicator: 'green'});
	}
	async function changeVersionNo(versionNo:string) {
		const version = await frappe.call<{message:Version}>({
			method: 'dw_worklist_management.dw_worklist_management.doctype.dw_work_instruction.dw_work_instruction.change_version_no',
			args: {
				version_name: currentVersion.value?.name || '',
				version_no: versionNo,
			},
		});
		if (version?.message) {
			const index = versions.value.findIndex(item=>item.name === currentVersion.value.name);
			currentVersion.value.name = version.message.name;
			currentVersion.value.instruction_version = version.message.instruction_version;
			if (index >= -1) {
				versions.value.splice(index, 1, currentVersion.value);
			} else {
				versions.value.push(currentVersion.value);
			}
		}
	}
	function setEditable(editableValue:boolean) {
		editable.value = editableValue;
	}
	function setDefault(options:{
		version?:Partial<Version>,
		instruction?:Partial<Instruction>,
	}) {
		versions.value = [];
		currentVersion.value = {...DefaultVersion, ...options.version};
		instruction.value = {...DefaultInstruction, ...options.instruction};
		content.value = currentVersion.value.content;
	}
	function setVersion(newVersion:Partial<Version>) {
		currentVersion.value = {
			...currentVersion.value,
			...newVersion,
		};
		// 不要更新content，tiptap的问题，导致实时更新内容光标会移动
		isChange.value = true;
	}
	function setInstruction(newInstruction:Partial<Instruction>) {
		instruction.value = {
			...instruction.value,
			...newInstruction,
		};
		isChange.value = true;
	}

	function afterSave(fn:(p:AfterHookParams)=>void) {
		afterSaveHooks.push(fn);
	}
	function offAfterSave(fn:(p:AfterHookParams)=>void) {
		const index = afterSaveHooks.findIndex(item=>item === fn);
		afterSaveHooks = afterSaveHooks.splice(index, 1);
	}
	function setEditorContent(contentValue:string) {
		content.value = contentValue;
	}
	function deleteVersion(versionName:string) {
		frappe.call({
			method: 'dw_worklist_management.dw_worklist_management.doctype.dw_work_instruction.dw_work_instruction.delete_version',
			args: {
				version_name: versionName,
			},
		});
		const index = versions.value.findIndex(item=>item.name === versionName);
		versions.value.splice(index, 1);
		if (currentVersion.value.name === versionName) {
			currentVersion.value = DefaultVersion;
		}
	}
	return {
		loading,
		isChange,
		versions,
		currentVersion,
		instruction,
		save,
		setEditable,
		editable,
		isSaveNewVersion,
		initInstruction,
		getInstruction,
		setDefault,
		changeVersionNo,
		setVersion,
		setInstruction,
		loadTemplateInstruction,
		content,
		afterSave,
		offAfterSave,
		setEditorContent,
		deleteVersion,
	};
});
