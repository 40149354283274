import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElOption = _resolveComponent("ElOption")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Time Field')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElSelect"], {
          modelValue: $setup.time_field,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.time_field) = $event)),
          filterable: "",
          defaultFirstOption: "",
          clearable: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dateOptions, (field) => {
              return (_openBlock(), _createBlock(_component_ElOption, {
                key: field.value,
                value: field.value,
                label: field.label
              }, null, 8 /* PROPS */, ["value", "label"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Sort Order Method')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElSelect"], {
          modelValue: $setup.order,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.order) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElOption, {
              value: "Desc",
              label: $setup.tt('DESC')
            }, null, 8 /* PROPS */, ["label"]),
            _createVNode(_component_ElOption, {
              value: "ASC",
              label: $setup.tt('ASC')
            }, null, 8 /* PROPS */, ["label"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"])
  ], 64 /* STABLE_FRAGMENT */))
}