import { vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ea6562f9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "editor-page" }
const _hoisted_2 = {
  key: 0,
  class: "sider"
}
const _hoisted_3 = { class: "right-main" }
const _hoisted_4 = {
  ref: "editorContainerRef",
  class: "container editor-container ck-content"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElConfigProvider"], {
    size: "small",
    locale: $setup.zhCn
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!$setup.smallMeta&&!$props.hideSidebar)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_Transition, {
                name: "slide-fade",
                persisted: ""
              }, {
                default: _withCtx(() => [
                  _withDirectives(_createVNode($setup["Directory"], {
                    content: $setup.directoryContent,
                    editorContainer: $setup.editorContainerRef,
                    onScrollTo: $setup.scrollTo
                  }, null, 8 /* PROPS */, ["content", "editorContainer"]), [
                    [_vShow, $setup.showSider]
                  ])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode($setup["ToggleBtn"], {
                expand: $setup.showSider,
                "onUpdate:expand": _cache[0] || (_cache[0] = $event => (($setup.showSider) = $event))
              }, null, 8 /* PROPS */, ["expand"])
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_3, [
          ($props.editable)
            ? (_openBlock(), _createBlock($setup["Tools"], {
                key: 0,
                editor: $setup.editor
              }, null, 8 /* PROPS */, ["editor"]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_4, [
            _createVNode($setup["EditorContent"], {
              editor: $setup.editor,
              class: "editor"
            }, null, 8 /* PROPS */, ["editor"])
          ], 512 /* NEED_PATCH */)
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["locale"]))
}