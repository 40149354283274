import mountVueToPage from 'guiguLib/mountVueToPage';

import Main from './Main.vue';

function loadGuiguUserWechat(wrapper) {
	mountVueToPage(wrapper, Main);
	wrapper.classList.add('container');
}
frappe.provide('frappe.guigu_wechat');
frappe.guigu_wechat.pages = frappe.guigu_wechat.pages || {};
frappe.guigu_wechat.pages.loadGuiguUserWechat = loadGuiguUserWechat;
