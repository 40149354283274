<template>
	<div>
		<ElTooltip :content="tt('Relate Work List')">
			<ElButton :icon="Link" />
		</ElTooltip>
	</div>
</template>

<script setup lang="ts">
import {Link} from '@element-plus/icons-vue';
import {ElButton, ElTooltip} from 'element-plus';
const tt = __;

</script>
<style scoped lang="less">

</style>
