import { Component, computed, ComputedRef } from 'vue';

frappe.provide('frappe.guigu.GlobalView.actions');
const { actions } = frappe.guigu.GlobalView;

function getAction(name: string | any) {
	if (!name) { return; }
	if (typeof name === 'function') { return name; }
	if (typeof name === 'object') { return name; }
	return name in actions && actions[name];
}

export default function useActions(
	s: ComputedRef<(string | Component)[] | undefined>,
) {
	const map = new Map<string | Component | undefined, Component | undefined>;
	function getFromMap(it: string | Component) {
		if (map.has(it)) {
			return map.get(it);
		}
		const value = getAction(it);
		map.set(it, value);
		return value;
	}

	return computed(() => {
		const sider: Component[] = [];
		const v = s.value;
		for (const it of Array.isArray(v) ? v : [v]) {
			if (!it) { continue; }
			const value = getFromMap(it);
			if (!value) { continue; }
			sider.push(value);
		}
		return sider;
	});


}
