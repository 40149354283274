<template>
	<div class="ViewPageTitleText">
		<div class="ViewPageTitleText-flex">
			<h3 class="ViewPageTitleText-title" :title="title">{{title}}</h3>
			<span class="ViewPageTitleText-indicator"></span>
		</div>
		<div v-if="subtitle" class="ViewPageTitleText-subtitle">{{subtitle}}</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
	props: {
		title: String,
		subtitle: String,
	}
})
</script>
<style scoped>
.ViewPageTitleText {
	flex: 1;
}

.ViewPageTitleText-flex {
	display: flex;
}

.ViewPageTitleText-title {
	cursor: pointer;
	margin-bottom: 0px;
	margin-right: var(--margin-sm);
	max-width: 500px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: middle;
}

.ViewPageTitleText-indicator {
	margin-top: 2px;
	padding: 3px 10px;
	font-size: var(--text-sm);
	border-radius: var(--border-radius);
	font-weight: 500;
	height: 24px;
	display: inline-flex;
	align-items: center;
	white-space: nowrap;
}

.ViewPageTitleText-indicator::before {
	content: "";
	display: inline-table;
	height: 4px;
	width: 4px;
	border-radius: 50%;
	margin-right: 6px;
}













.ViewPageTitleText-subtitle {
	font-size: var(--text-sm);

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	vertical-align: middle;

	color: var(--text-muted) !important;
}
</style>
