<template>
	<X class="setting" @click="onDelete" :size="16"></X>
</template>

<script setup lang="ts">
import {X} from 'lucide-vue-next';

interface Emit {
	(event: 'remove'): void;
}
const emit = defineEmits<Emit>();
async function onDelete() {
	emit('remove');
}
</script>
<style scoped lang="less">
.header {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 8px;
}

.setting {
	cursor: pointer;
}

.close {
	cursor: pointer;
}

.option-container {
	padding: 0 15px;
	max-height: 600px;
	overflow-y: scroll;
}
</style>
