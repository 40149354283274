<template>
	<div class="table-container" v-loading="loading">
		<FixScore
			:linkAssessList="linkAssessList"
			:tempAssessList="tempAssessList"
			:permission="detailPermission"
			:assessmentPermission="assessmentPermission"
			:lockStatus="lockStatus"
			@update="update"
			@remove="(data, type) => emit('remove', data, type)"
			@selectionChanged="onSelectionChanged"
		></FixScore>
		<div v-if="createShow && taskCreatable" class="add-button">
			<ElButton
				v-if="(fixSelectedRows?.value || []).length > 0"
				@click="() => command('assessmentStandardTemplate')"
				type="primary"
				link
				:icon="Plus"
				:title="tt('Assessment Standard Template')"
			>
				{{ tt('Assessment Standard Template') }}
			</ElButton>
			<ElButton
				@click="() => command('createTempTask')"
				type="primary"
				link
				:icon="Plus"
				:title="tt('New Temporary Job')"
			>
				{{ tt('New Temporary Job') }}
			</ElButton>
		</div>
		<ExtraScoreComp
			:extraAssessList="extraAssessList"
			:permission="extraPermission"
			:lockStatus="lockStatus"
			@update="update"
			@remove="(data, type) => emit('remove', data, type)"
		></ExtraScoreComp>
		<div v-if="createShow && extraPermission?.creatable" class="add-button">
			<ElButton
				@click="() => command('createExtraScore')"
				type="primary"
				link
				:icon="Plus"
				:title="tt('Create New Addition and Subtraction Items')"
			>
				{{ tt('Create New Addition and Subtraction Items') }}
			</ElButton>
		</div>
		<LeaderConfirm
			:type="type"
			:assessInfo="assessInfo"
			:assessUserPermission="assessUserPermission"
			:lockStatus="lockStatus"
			@update="emit('update')"
		>
		</LeaderConfirm>

		<div :style="style">
			{{ tt('Instructions For Filling Out The Form') }}:
			<ul class="description">
				<li>
					{{
						tt(
							'In principle, the monthly work plan (key performance indicators) should not exceed 6 items;',
						)
					}}
				</li>
				<li>
					{{
						tt(
							'Achieving standards (goals) refers to the final results or effects achieved by modifying the work plan (key performance indicators);',
						)
					}}
				</li>
				<li>
					{{
						tt(
							'The maximum score for monthly plans is 100, and the total weight of each monthly work plan is 100%. The corresponding weight corresponds to the corresponding score; There should be a monthly key task with a weight of no less than 30% in the general monthly plan;',
						)
					}}
				</li>
				<li>
					{{
						tt(
							'Temporary addition or adjustment of specific weights for work tasks, achieved by adjusting the weights of other monthly plans;',
						)
					}}
				</li>
				<li>
					{{
						tt(
							'The evaluation (scoring) criteria should include specific quantifiable assessment and scoring criteria for the results of the work plan;',
						)
					}}
				</li>
				<li>
					{{
						tt(
							'Other bonus points generally refer to the completion of a certain work plan/task, achieving significant results or significantly exceeding expectations; Design challenge goals achieved; By optimizing management methods and adopting new work methods to efficiently complete related work, as well as other situations that have significantly improved work efficiency and effectiveness, points can be appropriately added according to the actual situation. Generally, the overall monthly bonus does not exceed 10 points;',
						)
					}}
				</li>
				<li>
					{{
						tt(
							'Other deduction items generally refer to those that have a certain impact due to errors in daily work; Not following labor discipline and being negligent in work; Not obeying the work arrangements of direct superiors; Errors that were not deducted in the current period during other assessment periods; The problems in the previous assessment (performance improvement) have not been significantly corrected or have only been partially corrected; As well as other behaviors and events that affect work efficiency and effectiveness, points can be deducted based on actual situations, and there is no lower limit for monthly deduction.',
						)
					}}
				</li>
			</ul>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, watch, ref } from 'vue';
import { ElButton, ElMessage } from 'element-plus';
import { Plus } from '@element-plus/icons-vue';

import type * as Permission from '../../Permission';
import type { AssessDetail, AssessInfo, ExtraScore } from '../type';

import FixScore from './FixScore.vue';
import ExtraScoreComp from './ExtraScore.vue';
import LeaderConfirm from './LeaderConfirm.vue';

interface Props {
	assessInfo?: AssessInfo;
	type: 'project' | 'subCompany';
	detailPermission: Permission.DocPermission | null;
	extraPermission: Permission.DocPermission | null;
	assessmentPermission: Permission.DocPermission | null;
	assessUserPermission: Permission.UserPermission | null;
	lockStatus: boolean;
	assessPermission: Permission.UserPermission | null;
	fixSelectedRows: any;
}

const tt = __;
const props = defineProps<Props>();
interface Emit {
	(e: 'createExtraScore'): void;
	(e: 'createTempTask'): void;
	(e: 'assessmentStandardTemplate'): void;
	(e: 'update'): void;
	(
		e: 'remove',
		data: ExtraScore | AssessDetail,
		type: 'detail' | 'extra',
	): void;
	(e: 'afterCalcScore', score: string): void;
}
const emit = defineEmits<Emit>();
const loading = ref<boolean>(false);
const tempAssessList = ref<
	(AssessDetail & { index: number; type_label: string })[]
>([]);
const linkAssessList = ref<
	(AssessDetail & { index: number; type_label: string })[]
>([]);
const extraAssessList = ref<
	(ExtraScore & { index: number; type_label: string })[]
>([]);
const taskCreatable = computed(() => {
	if (props.detailPermission?.creatable) {
		return true;
	}
	if (!props.lockStatus && props.assessPermission?.readable.has('level')) {
		return true;
	}
	return false;
});
const createShow = computed(() => {
	if (props.lockStatus) {
		return false;
	}
	return (
		props.detailPermission?.creatable || props.extraPermission?.creatable
	);
});
function command(item: string) {
	switch (item) {
		case 'createTempTask':
			emit('createTempTask');
			break;
		case 'createExtraScore':
			emit('createExtraScore');
			break;
		case 'assessmentStandardTemplate':
			emit('assessmentStandardTemplate');
			break;
	}
}
const selectedRows = ref<AssessDetail[]>([]);

function onSelectionChanged(rows: AssessDetail[]) {
	props.fixSelectedRows.value = rows;
	//   selectedRows.value = rows;
}

watch(
	() => props.assessInfo,
	() => {
		loading.value = true;
		const {
			detail = [],
			extra_score = [],
			temp = [],
		} = props.assessInfo || {};
		linkAssessList.value =
			detail
				.filter(item => item.doc_name && item.link_doctype)
				.sort((pre, next) => (pre.subject > next.subject ? 1 : -1))
				.map((item, index) => ({
					...item,
					index: index + 1,
					type_label: __('Plans'),
				})) || [];
		const tempAssess =
			temp
				.sort((pre, next) => (pre.subject > next.subject ? 1 : -1))
				.map((item, index) => ({
					...item,
					index: index + 1,
					type_label: __('adjust'),
				})) || [];
		const totalScore =
			detail
				.map(item => item.total_score || 0)
				.reduce((pre, next) => pre + next, 0) +
			tempAssess
				.map(item => item.total_score || 0)
				.reduce((pre, next) => pre + next, 0);
		const selfAssessment =
			detail
				.map(item => item.self_assessment || 0)
				.reduce((pre, next) => pre + next, 0) +
			tempAssess
				.map(item => item.self_assessment || 0)
				.reduce((pre, next) => pre + next, 0);
		const leaderAssessment =
			detail
				.map(item => item.leader_assessment || 0)
				.reduce((pre, next) => pre + next, 0) +
			tempAssess
				.map(item => item.leader_assessment || 0)
				.reduce((pre, next) => pre + next, 0);
		const allFixScore = selfAssessment * 0.1 + leaderAssessment * 0.9;
		tempAssess.push({
			name: 'auto_calc',
			type_label: __('Total Weight'),
			total_score: parseFloat(totalScore.toFixed(2)),
			self_assessment: parseFloat(selfAssessment.toFixed(2)),
			leader_assessment: parseFloat(leaderAssessment.toFixed(2)),
			autoCalcScore: allFixScore.toFixed(2),
		});
		tempAssessList.value = tempAssess;
		const extraAssess =
			extra_score
				.sort((pre, next) => (pre.type > next.type ? 1 : -1))
				.map((item, index) => ({
					...item,
					index: index + 1,
					type_label:
						item.type === 'Add'
							? __('Assessment Bonus Item')
							: __('Subtraction Item'),
				})) || [];
		const extraScore = extraAssess
			.map(item => (item.type === 'Add' ? item.score : -item.score))
			.reduce((pre, next) => pre + next, 0);
		extraAssess.push({
			name: 'auto_calc',
			type_label: __('Score Summary'),
			score: parseFloat(extraScore.toFixed(2)),
			autoCalcScore: (allFixScore + extraScore).toFixed(2),
		});
		extraAssessList.value = extraAssess;
		emit('afterCalcScore', (allFixScore + extraScore).toFixed(2));
		loading.value = false;
	},
	{ immediate: true },
);

async function update(
	field: string,
	value: string | number,
	data: AssessDetail | ExtraScore,
) {
	await frappe.call({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_assessment_detail.guigu_hr_assessment_detail.update_score',
		args: {
			doctype: data.doctype,
			name: data.name,
			field,
			value,
		},
	});
	ElMessage({
		type: 'success',
		message: __('Saved Successfully'),
	});
	emit('update');
}
const style = computed(() => {
	if (extraAssessList.value.length === 1) {
		return { marginTop: '0px' };
	}
	return { marginTop: '22px' };
});
</script>

<style lang="less" scoped>
.table-container {
	overflow: hidden;
}
.extra-btn_container {
	display: flex;
	justify-content: end;
	padding: 8px 0;
}
.add-button {
	text-align: right;
	padding: 8px 0;
}
.description {
	list-style: decimal;
}
</style>
