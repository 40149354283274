import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fa07df48"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = {
  class: "table-container",
  id: "guigu_project_monthly_boq_completed_quantity"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Page"], null, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.tt('Guigu Project Monthly Boq Completed Quantity')), 1 /* TEXT */),
      _withDirectives(_createElementVNode("span", { class: "indicator-pill whitespace-nowrap orange" }, _toDisplayString($setup.tt('Not Saved')), 513 /* TEXT, NEED_PATCH */), [
        [_vShow, $setup.isModified]
      ])
    ]),
    tools: _withCtx(() => [
      _createVNode($setup["Tools"], {
        onGetQueryParams: $setup.getQueryParams,
        onUpdateAndSubmitData: $setup.updateAndSubmitData,
        onUpdateIsModified: _cache[0] || (_cache[0] = $event => ($setup.isModified =false)),
        onUpdateIsExpand: $setup.updateIsExpand,
        isModified: $setup.isModified,
        "onUpdate:isModified": _cache[1] || (_cache[1] = $event => (($setup.isModified) = $event)),
        company: $setup.company,
        "onUpdate:company": _cache[2] || (_cache[2] = $event => (($setup.company) = $event)),
        project: $setup.project,
        "onUpdate:project": _cache[3] || (_cache[3] = $event => (($setup.project) = $event)),
        month: $setup.month,
        "onUpdate:month": _cache[4] || (_cache[4] = $event => (($setup.month) = $event))
      }, null, 8 /* PROPS */, ["isModified", "company", "project", "month"])
    ]),
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode($setup["Table"], {
          ref: "table",
          dataList: $setup.dataList,
          isExpand: $setup.isExpand,
          hotName: $setup.hotName,
          onUpdateAndSubmitData: $setup.updateAndSubmitData
        }, null, 8 /* PROPS */, ["dataList", "isExpand", "hotName"])
      ])), [
        [$setup["vLoading"], $setup.loading]
      ])
    ]),
    _: 1 /* STABLE */
  }))
}