import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51977e8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "table_container" }
const _hoisted_3 = { class: "pagination" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Page"], null, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.tt('Dw Work List Task View')), 1 /* TEXT */)
    ]),
    tools: _withCtx(() => [
      _createVNode($setup["Tools"], {
        smallMeta: $setup.smallMeta,
        currentOrganization: $setup.currentOrganization,
        selectedWorkList: $setup.selectedWorkList,
        permission: $setup.permission,
        departmentTree: $setup.departmentTree,
        layoutType: $setup.layoutType,
        "onUpdate:layoutType": _cache[0] || (_cache[0] = $event => (($setup.layoutType) = $event)),
        department: $setup.department,
        "onUpdate:department": _cache[1] || (_cache[1] = $event => (($setup.department) = $event)),
        applicable: $setup.applicable,
        "onUpdate:applicable": _cache[2] || (_cache[2] = $event => (($setup.applicable) = $event)),
        onShowProcessModalOrNavigate: $setup.showProcessModalOrNavigate
      }, null, 8 /* PROPS */, ["smallMeta", "currentOrganization", "selectedWorkList", "permission", "departmentTree", "layoutType", "department", "applicable"])
    ]),
    sider: _withCtx(() => [
      _createVNode($setup["TreeSider"], {
        treeList: $setup.treeList,
        loading: $setup.treeLoading,
        permission: $setup.permission,
        allWorklist: $setup.allWorklist,
        onUpdateData: $setup.updateData,
        onSetSideSelected: $setup.setSideSelected
      }, null, 8 /* PROPS */, ["treeList", "loading", "permission", "allWorklist"])
    ]),
    detail: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["FilterCondition"], {
          smallMeta: $setup.smallMeta,
          currentOrganization: $setup.currentOrganization,
          taskState: $setup.taskState,
          "onUpdate:taskState": _cache[3] || (_cache[3] = $event => (($setup.taskState) = $event)),
          taskPriority: $setup.taskPriority,
          "onUpdate:taskPriority": _cache[4] || (_cache[4] = $event => (($setup.taskPriority) = $event)),
          taskAssignee: $setup.taskAssignee,
          "onUpdate:taskAssignee": _cache[5] || (_cache[5] = $event => (($setup.taskAssignee) = $event))
        }, null, 8 /* PROPS */, ["smallMeta", "currentOrganization", "taskState", "taskPriority", "taskAssignee"]),
        _withDirectives((_openBlock(), _createBlock($setup["Skeleton"], {
          layout: $setup.layoutType,
          doctype: "Dw Task",
          detailName: $setup.detailName,
          onOnChange: $setup.onChange,
          "form-visible": $setup.formVisible,
          "onUpdate:formVisible": _cache[6] || (_cache[6] = $event => (($setup.formVisible) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode($setup["Table"], {
              smallMeta: $setup.smallMeta,
              taskList: $setup.taskList,
              loading: $setup.taskLoading,
              currentOrganization: $setup.currentOrganization,
              onShowProcessModalOrNavigate: $setup.showProcessModalOrNavigate
            }, null, 8 /* PROPS */, ["smallMeta", "taskList", "loading", "currentOrganization"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["layout", "detailName", "form-visible"])), [
          [$setup["vLoading"], $setup.taskLoading]
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["ElConfigProvider"], { locale: $setup.locale }, {
          default: _withCtx(() => [
            _createVNode($setup["ElPagination"], {
              "current-page": $setup.currentPage,
              "onUpdate:currentPage": _cache[7] || (_cache[7] = $event => (($setup.currentPage) = $event)),
              "page-size": $setup.pageSize,
              "onUpdate:pageSize": _cache[8] || (_cache[8] = $event => (($setup.pageSize) = $event)),
              background: "",
              small: "",
              layout: "total,sizes,prev, pager, next",
              total: $setup.total,
              pageSizes: [25,50,100,200],
              onCurrentChange: $setup.getTaskList
            }, null, 8 /* PROPS */, ["current-page", "page-size", "total"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["locale"])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}