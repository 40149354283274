
<template>
	<div class="tools">
		<ElButton v-if="relatePermission" type="primary" size="small" @click="createRelation">{{ tt('Enable Process') }}</ElButton>
	</div>
</template>

<script setup lang="ts">
import {ElButton} from 'element-plus';
interface Props{
	relatePermission:boolean
}
defineProps<Props>();
const tt = __;
const emit = defineEmits<{
	(e: 'createRelation',): void;
}>();
function createRelation() {
	emit('createRelation');
}
</script>
<style lang="less" scoped>
.tools{
	display: flex;
	justify-content: flex-end;
	margin-bottom: 8px
}
</style>
