<template>
	<ElButtonGroup :disabled="loading">
		<ElButton v-for="c in menuItems" :key="c.key"
			@click="command(c)" :title="tt(c.label)">
			{{ tt(c.label) }}
		</ElButton>
	</ElButtonGroup>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { ElButton, ElButtonGroup } from 'element-plus';

defineOptions({ name: 'ListAction', inheritAttrs: false });

const props = defineProps<{
	meta: locals.DocType;
	view?: GlobalView.View;
	configuration?: object;

	data: locals.Doctype[];
	total: number;
	options: Record<string, any>;

	loading: boolean;

	page: number;
	limit: number;

	selected?: any[];
	value: any;

	group: GlobalView.Group[];
	sort: GlobalView.Order
	filters?: any;
}>();

const emit = defineEmits<{
	(event: 'refresh'): void;
	(event: 'update:selected', value: any[]): void;
	(event: 'update:expanded', list: string[]): void;
	(event: 'sort', order: GlobalView.Sort[]): void;
	(event: 'update:group', group: GlobalView.Group[]): void;
	(event: 'update:filters', filters: any): void;
}>();

const tt = __;

function command(item: frappe.guigu.GlobalView.Setting.Action) {
	item.action?.(props);
}
function* menu(
	props: frappe.guigu.GlobalView.Setting.ActionParams,
): Iterable<frappe.guigu.GlobalView.Setting.Action> {
	const setting = frappe.guigu.GlobalView.settings[props.meta.name];
	for (const [cond, item] of setting?.actions || []) {
		if (!cond(props)) { continue; }
		yield item;
	}
}

const menuItems = computed(() => [...menu(props)]);
</script>
