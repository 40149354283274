import { resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.comp)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.comp), {
        key: 0,
        class: _normalizeClass({'indicator-pill':$props.value&&$props.showTag, 'indicator-pill-color':$props.value&&$props.showTag}),
        style: _normalizeStyle($setup.style),
        value: $props.value,
        data: $props.data,
        field: $props.field,
        meta: $props.meta,
        maxLine: $props.maxLine,
        enableLink: $props.enableLink,
        noData: $props.noData,
        inline: $props.inline,
        showTitle: $props.showTitle,
        format: $props.format,
        onFilter: $setup.setFilter
      }, null, 40 /* PROPS, HYDRATE_EVENTS */, ["class", "style", "value", "data", "field", "meta", "maxLine", "enableLink", "noData", "inline", "showTitle", "format"]))
    : _createCommentVNode("v-if", true)
}