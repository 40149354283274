import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["AgGridVue"], {
    class: "ag-theme-guigu",
    domLayout: "autoHeight",
    rowData: $setup.td,
    treeData: "",
    defaultColDef: $setup.defaultColDef,
    onGridReady: $setup.onGridReady,
    getDataPath: $setup.getDataPath,
    suppressDragLeaveHidesColumns: $setup.smallMeta,
    localeText: $setup.zhCN,
    useValueFormatterForExport: "",
    autoGroupColumnDef: $setup.autoGroupColumnDef
  }, null, 8 /* PROPS */, ["rowData", "suppressDragLeaveHidesColumns", "localeText"]))
}