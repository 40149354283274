import type { ColDef, ColGroupDef, EditableCallbackParams } from 'ag-grid-community';

import type * as Permission from '../../Permission';
import type { AssessDetail } from '../type';
import AgGridToolTip from '../../AgGridToolTip.vue';

import Action from './AgGridAction.vue';
import Subject from './AgGridSubject.vue';

function isEditable(field: string, lockStatus: boolean, permission: Permission.DocPermission | null, isModify?: boolean) {
  if (lockStatus || !permission) {
    return false;
  }
  if (isModify) {
    if (permission.modifiable.has(field)) {
      return true;
    }
    if (!permission.ifOwner.modifiable.has(field)) {
      return false;
    }
  }
  if (permission.writable.has(field)) {
    return true;
  }
  if (!permission.ifOwner.writable.has(field)) {
    return false;
  }
}
function getEditable(field: string, lockStatus: boolean, permission: Permission.DocPermission | null) {
  const writable = isEditable(field, lockStatus, permission, true);
  if (typeof writable === 'boolean') {
    return writable;
  }
  return (params: EditableCallbackParams<AssessDetail>) => params.data?.user === frappe.user.name;
}
function getActionParams(onRemove: (data: AssessDetail) => void, lockStatus: boolean, permissions: Permission.DocPermission | null) {
  if (lockStatus || !permissions) {
    return {};
  }
  if (permissions.deletable) {
    return { onRemove };
  }
  if (!permissions.ifOwner.deletable) {
    return {};
  }
  return (params: EditableCallbackParams<AssessDetail>) => (params.data?.user === frappe.user.name ? { onRemove } : {});
}
export function getColumns(
  onRemove: (data: AssessDetail) => void,
  permission: Permission.DocPermission | null,
  lockStatus: boolean,
  smallMeta: boolean
): (ColDef | ColGroupDef)[] {
  const columns = [
    {
      headerName: __('Planned Work Content'),
      field: 'subject',
      flex: 1,
      minWidth: 150,
      enableRowGroup: false,
      filter: false,
      cellRenderer: Subject,
      editable: getEditable('subject', lockStatus, permission),
    },
    {
      headerName: __('Work Completion Standard'),
      field: 'standard',
      minWidth: 150,
      enableRowGroup: false,
      editable: getEditable('standard', lockStatus, permission),
      filter: false,
      flex: 1,
      cellRenderer: AgGridToolTip,
    },
    {
      headerName: __('Work Completion Status'),
      field: 'remark',
      minWidth: 150,
      enableRowGroup: false,
      editable: getEditable('remark', lockStatus, permission),
      filter: false,
      flex: 1,
      cellRenderer: AgGridToolTip,
    },
    {
      headerName: __('Operations'),
      width: 70,
      pinned: smallMeta ? undefined : ('right' as const),
      enableRowGroup: false,
      filter: false,
      sortable: false,
      editable: false,
      menuTabs: [],
      cellRenderer: Action,
      cellRendererParams: getActionParams(onRemove, lockStatus, permission),
    },
  ];
  return columns;
}
