<template lang="html">
	<ElDropdown v-if="list.length" @command="setKanban">
		<ElButton circle :icon="Grid" />
		<template #dropdown>
			<ElDropdownMenu>
				<ElDropdownItem v-for="c in list" :key="c.name" :command="c.name" :class="{ ['current']: c.name === kanbanId }">
					{{ c.title }}
				</ElDropdownItem>
				<ElDropdownItem command="" divided :icon="Plus">{{ tt('Create') }}</ElDropdownItem>
			</ElDropdownMenu>
		</template>
	</ElDropdown>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import {ElDropdown, ElButton, ElDropdownMenu, ElDropdownItem} from 'element-plus';
import {Plus, Grid} from '@element-plus/icons-vue';

import type {Kanban} from '../types';

const tt = __;

const props = defineProps<{
	modelValue?: string;
	list: Kanban[];
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', id?: string): void;
}>();

const kanbanId = computed({
	get: () => props.modelValue,
	set: v => emit('update:modelValue', v),
});
/******** 菜单 ********/

function setKanban(n?: string) {
	kanbanId.value = n;
}
</script>
<style scoped>
  .current {
    background-color: #ccffff;
  }
</style>
