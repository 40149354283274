import type { GridApi, RowDragEndEvent, RowNode } from 'ag-grid-community';

import { Data } from './type/index';

export let potentialParent: RowNode | null = null;
export function setPotentialParentForNode(
  api: GridApi,
  overNode?: RowNode | null,
) {
  let newPotentialParent;
  if (overNode) {
    newPotentialParent = overNode;
  } else {
    newPotentialParent = null;
  }
  const alreadySelected = potentialParent === newPotentialParent;
  if (alreadySelected) {
    return;
  }
  const rowsToRefresh = [];
  if (potentialParent) {
    rowsToRefresh.push(potentialParent);
  }
  if (newPotentialParent) {
    rowsToRefresh.push(newPotentialParent);
  }
  potentialParent = newPotentialParent;
  refreshRows(api, rowsToRefresh);
}

export function refreshRows(api: GridApi, rowsToRefresh: RowNode[]) {
  const params = {
    rowNodes: rowsToRefresh,
    force: true,
  };
  api.refreshCells(params);
}

export async function onRowDragEndHelper<T>(
  event: RowDragEndEvent,
  dataSource: Data[],
  dragRequest?: (
    movingData: Data,
    overData: Data,
  ) => Promise<boolean> | boolean,
) {
  const { api: agGridApi } = event;
  if (!potentialParent) {
    return;
  }

  const movingData = event.node.data;
  const overData = event.overNode.data;
  if (!dragRequest) {
    return;
  }
  const isSuccess = await dragRequest(movingData, overData);
  if (!isSuccess) {
    return;
  }
  if (!potentialParent?.data) {
    return;
  }
  const getDataPath = agGridApi.dragAndDropService?.gridOptionsService?.gridOptions?.getDataPath||function(data:any){
    return data.ancestry||[]
  };
  const overNodeAncestry = getDataPath?.(
    potentialParent.data,
  )||potentialParent.data?.ancestry||[];
  const newParentPath = potentialParent.data
    ? overNodeAncestry.slice(0, -1)
    : [];
  const needToChangeParent = !arePathsEqual(
    newParentPath,
    movingData.ancestry.slice(0, -1),
  );
  const invalidMode = isSelectionParentOfTarget(event.node, potentialParent);
  if (invalidMode) {
    console.log('invalid move');
  }
  let dataSourceCopy = dataSource;
  if (needToChangeParent && !invalidMode) {
    changeParentPath(
      dataSourceCopy || [],
      newParentPath,
      movingData,
      agGridApi,
    );
  }
  const rowNeedsToMove = event.node !== potentialParent;
  if (rowNeedsToMove && dataSourceCopy && dataSource) {
    const fromIndex = dataSourceCopy.findIndex(
      item =>
      [...getDataPath(item)].pop() ==
      [...getDataPath(movingData)].pop(),
    );
    const toIndex = dataSourceCopy.findIndex(
      item =>
        [...getDataPath(item)].pop() ==
        [...getDataPath(overData)].pop(),
    );
    dataSourceCopy = movePath<T>(dataSourceCopy.slice(), fromIndex, toIndex);
    dataSource.length = 0;
    dataSource.push(...dataSourceCopy);
  }
  agGridApi?.setRowData(dataSourceCopy || []);
  agGridApi?.clearFocusedCell();
  setPotentialParentForNode(event.api, null);
}

export function changeParentPath<T>(
  store: Data[],
  newParentPath: string[],
  movingData: Data,
  agGridApi: GridApi,
) {
  const oldPath = movingData.ancestry;
  const lastName = oldPath[oldPath.length - 1];
  const newChildPath = newParentPath.slice();
  newChildPath.push(lastName);
  const getDataPath = agGridApi.dragAndDropService?.gridOptionsService?.gridOptions?.getDataPath||function(data:any){
    return data.ancestry||[]
  };
  const data = store.find(
    item =>
    [...getDataPath(item)].pop() ==
    [...getDataPath(movingData)].pop(),
  );
  const dataIndex = store.findIndex(
    item =>
    [...getDataPath(item)].pop() ==
    [...getDataPath(movingData)].pop(),
  );
  if (!data) {
    return;
  }
  data.ancestry = newChildPath;
  store[dataIndex] = JSON.parse(JSON.stringify(data));
  if (
    store.some(
      item => item.ancestry.slice(0, -1).join('/') === oldPath.join('/'),
    )
  ) {
    store
      .filter(
        item => item.ancestry.slice(0, -1).join('/') === oldPath.join('/'),
      )
      .forEach(item => {
        changeParentPath(store, newChildPath, item, agGridApi);
      });
  }
}
export function movePath<T>(
  store: Data[],
  fromIndex: number,
  toIndex: number,
): Data[] {
  const data: (Data | undefined)[] = store;
  const element = data[fromIndex];
  [element].forEach((item, index) => {
    if (fromIndex + index !== toIndex) {
      data.splice(fromIndex + index, 1, undefined);
    }
    data.splice(toIndex + 1, 0, item);
  });

  return data.filter(Boolean) as unknown as Data[];
}
export function arePathsEqual(path1: string[], path2: string[]) {
  if (path1.length !== path2.length) {
    return false;
  }
  let equal = true;
  path1.forEach(function (item, index) {
    if (path2[index] !== item) {
      equal = false;
    }
  });
  return equal;
}

export function isSelectionParentOfTarget(
  selectedNode: RowNode,
  targetNode: RowNode,
) {
  const children = selectedNode.childrenAfterGroup;
  if (!children) {
    return false;
  }
  for (let i = 0; i < children.length; i++) {
    if (targetNode && children[i].key === targetNode.key) {
      return true;
    }
    isSelectionParentOfTarget(children[i], targetNode);
  }
  return false;
}
