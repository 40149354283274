export default function saveAs(
	data: Blob | MediaSource | string,
	name: string,
) {
	const url = typeof data === 'string' ? data : URL.createObjectURL(data);
	const a = document.createElement('a');
	a.href = url;
	a.download = name;
	a.target = '_blank';
	a.click();
	if (typeof data !== 'string') {
		setTimeout(() => {
			URL.revokeObjectURL(url);
		}, 30000);
	}
}


export interface Item {
	id: string,
	parent?: string
}
export interface TreeItem extends Item {
	children?: this[];
}

export function list2Tree<T extends Item>(listData: T[]) {
	const listDataCopy: T[] = JSON.parse(JSON.stringify(listData)); 
	const treeData: (T & TreeItem)[] = [];
	const map: Record<string, any> = {};
	listDataCopy.forEach(item => {
		map[item.id] = item;
	});
	listDataCopy.forEach(item => {
		const parent = map[item.parent || 0];
		if (parent) {
			(parent.children || (parent.children = [])).push(item);
		} else {
			treeData.push(item);
		}
	});
	return treeData;
}

export function tree2list(treeData: TreeItem[], list: Item[]) {
	treeData.forEach(item => {
		const itemCopy = structuredClone(item);
		const { children } = itemCopy;
		delete itemCopy.children;
		list.push(itemCopy);
		if (children&&children?.length > 0) {
			tree2list(children, list);
		}
	});
}

export function getAncestry(
	treeData: TreeItem[],
	parentAncestry: (string | number)[] = [],
) {
	treeData.forEach(item => {
		item.ancestry = [...parentAncestry, item.id];
		if (item?.children && item?.children?.length > 0) {
			getAncestry(item.children, [...parentAncestry, item.id]);
		} else {
			return;
		}
	});
}
export function getTreeAncestry<T extends Item>(data: T[]): (T & { ancestry: string[] })[] {
	const treeData = list2Tree(data);
	getAncestry(treeData);
	const list: (T & { ancestry: string[] })[] = [];
	tree2list(treeData, list);
	return list;
}
