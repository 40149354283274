

frappe.ui.form.ControlFilter = class ControlFilter extends frappe.ui.form.ControlInput {
	$input:HTMLElement|undefined = undefined;
	df:any;
	input_area:HTMLElement|undefined;
	last_value:string = '';
	value:string = '';
	has_input:boolean=false;
	filter_group:any;
	options:string=this.df.options;
	make_input() {
		if (this.$input) { return; }
		if (!this.input_area){ return; }
		const self =this;
		this.options = this.df.options;
		this.create_filter();
		// this.$input = $(this.input_area).find('input');

		this.has_input = true;
	}
	create_filter(){
		if (!this.df.options){ return; }
		$(this.input_area).empty();
		this.filter_group = new frappe.ui.FilterGroup({
			parent: $(this.input_area),
			doctype: this.df.options,
			on_change: () => {
				let filters = this.filter_group.get_filters();
				const value = frappe.utils.get_filter_as_json(filters);
				this.parse_validate_and_set_in_model(value||null);
			},
			base_list:[],
		});
	}
	set_input(value:string) {

		// this.set_disp_area(value);
		// this.set_mandatory && this.set_mandatory(value);
		if (!this.df.options){ return; }
		if (
			this.df.options!==this.filter_group?.doctype
		){	
			if(this.filter_group?.doctype){
				this.parse_validate_and_set_in_model(null);
				this.value=null;
			}
			this.create_filter();
		}
		let filter = value;
		if (typeof value === 'string'){
			filter = frappe.utils.get_filter_from_json(
				this.value,
				this.df.options
			);
		}
		this.last_value = this.value;
		this.value = filter;
		frappe.model.with_doctype(this.df.options, () => {
			this.filter_group.clear_filters();
			this.filter_group.add_filters_to_filter_group(filter);
		});
	}
	format_for_input(val?:string) {
		return val??'';
	}
	validate(v?:string) {
		if (!v) {
			return null;
		}
		return v;
	}
	get_input_value() {
		if (!this.filter_group){ return null; }
		let filters = this.filter_group.get_filters();
		const value = frappe.utils.get_filter_as_json(filters);

		return value||null;
	}
};
