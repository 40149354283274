import {watch, shallowRef, computed, ComputedRef, Ref} from 'vue';

import type {ConnectionSetting, DF, ConnectionSettingOrigin, MappingSetting} from './type/index';

const ExtraColumns = [
	{label: 'Last Comment', fieldname: '__LastComment__', fieldtype: '__LastComment__'},
	{label: 'Created On', fieldname: 'creation', fieldtype: 'Data'},
	{label: 'Created By', fieldname: 'owner', fieldtype: 'Link', options: 'User'},
];
export function getFieldSetting(
	doctype: string,
	df: DF,
	buttonHidden: Ref<{ link: boolean | '', add: boolean | '', delete: boolean | '' }>,
	actionButtonHidden: Ref<{ delete: boolean | '', edit: boolean | '', unlink: boolean | '' }>,
	isHidePagination: Ref<boolean | ''>,
	tableQueries: Ref<{ filters: any } | ''>,
	notSelect: Ref<boolean | ''>,
	isDetailUseDialog: Ref<boolean | ''>,
	deleteTip:Ref<string>,
	hideAddChildButton:Ref<boolean>,
	columnsSetting:Ref<locals.DocField[]>,
	createFieldMap:Ref<MappingSetting[]>,
) {
	const fieldSettingOrigin = shallowRef<ConnectionSettingOrigin>();

	const linkMeta = shallowRef<locals.DocType>();
	const mainMeta = computed(() => frappe.get_meta(doctype) || undefined);
	const middleMeta = shallowRef<locals.DocType>();

	async function getSetting() {
		if (!doctype || !df.fieldname) {
			return;
		}
		const setting = await frappe.call<{ message: ConnectionSettingOrigin }>({
			method: 'guigu.guigu_controls.connection_table.get_setting',
			args: {
				doctype,
				fieldname: df.fieldname,
			},
		});
		fieldSettingOrigin.value = setting?.message;
	}
	watch([() => doctype, () => df.fieldname], () => {
		getSetting();
	}, {immediate: true});
	async function getLinkMeta(linkDoctype: string) {
		if (!linkDoctype) {
			return;
		}
		let local_meta = frappe.get_meta(linkDoctype);
		if (local_meta) {
			linkMeta.value = local_meta;
			return;
		}
		await frappe.model.with_doctype(linkDoctype);
		local_meta = frappe.get_meta(linkDoctype);
		linkMeta.value = local_meta || undefined;
	}
	watch([()=>df.options, () => fieldSettingOrigin.value?.connection_table], () => {
		if (!fieldSettingOrigin.value?.connection_table && !df.options) {
			return;
		}
		getLinkMeta(fieldSettingOrigin.value?.connection_table || df.options);
	}, {immediate: true});
	async function getMiddleMeta(middleDoctype: string) {
		if (!middleDoctype) {
			return;
		}
		let local_meta = frappe.get_meta(middleDoctype);
		if (local_meta) {
			middleMeta.value = local_meta;
			return;
		}
		await frappe.model.with_doctype(middleDoctype);
		local_meta = frappe.get_meta(middleDoctype);
		middleMeta.value = local_meta || undefined;
	}
	watch([() => fieldSettingOrigin.value?.middle_table], () => {
		if (!fieldSettingOrigin.value?.middle_table) {
			return;
		}
		getMiddleMeta(fieldSettingOrigin.value?.middle_table);
	}, {immediate: true});
	const fieldSetting: ComputedRef<ConnectionSetting> = computed(() => {
		const create_field_mapping_setting = linkMeta.value?.fields.map(item => {
			const fetch_fields: string[] = item.fetch_from?.split('.') || [];
			return {
				main_field: fetch_fields[fetch_fields.length - 1],
				link_field: item.fieldname,
			};
		});
		if (!fieldSettingOrigin.value) {
			let columns = columnsSetting.value || linkMeta.value?.fields.filter(item => item.in_list_view === 1) || [];
			if (linkMeta.value?.is_tree) {
				columns = (columns || columnsSetting.value).filter(item => item.fieldname !== linkMeta.value?.title_field);
			}
			const link_to_main_field = mainMeta.value?.links.find(item => item.link_doctype === df.options)?.link_fieldname as string;
			const setting: ConnectionSetting = {
				connection_table: df.options,
				document_type: doctype,
				field: df.fieldname,
				filter_columns: linkMeta.value?.fields.filter(item => item.in_list_view === 1) || [],
				columns,
				create_field_mapping_setting: createFieldMap || create_field_mapping_setting,
				hide_bulkdelete_button: buttonHidden.value.delete === '' ? false : buttonHidden.value.delete,
				hide_add_button: buttonHidden.value.add === '' ? false : buttonHidden.value.add,
				hide_delete_button: actionButtonHidden.value.delete === '' ? false : actionButtonHidden.value.delete,
				hide_edit_button: actionButtonHidden.value.edit === '' ? false : actionButtonHidden.value.edit,
				hide_link_button: buttonHidden.value.link === '' ? false : buttonHidden.value.link,
				hide_unlink_button: actionButtonHidden.value.unlink === '' ? false : actionButtonHidden.value.unlink,
				link_to_main_field,
				type: 'One To Multiple',
				hide_pagination: isHidePagination.value === '' ? false : isHidePagination.value,
				not_select: notSelect.value === '' ? false : notSelect.value,
				table_filter: tableQueries.value === '' ? undefined : tableQueries.value.filters,
				is_detail_use_dialog: isDetailUseDialog.value === '' ? false : isDetailUseDialog.value,
				deleteTip: deleteTip.value,
				hide_add_child_button: hideAddChildButton.value,
			};
			return setting;
		}
		let columns: locals.DocField[] = [];
		if (fieldSettingOrigin.value?.columns?.length === 0) {
			columns = linkMeta.value?.fields.filter(item => item.in_list_view === 1) || [];
		} else {
			if (fieldSettingOrigin.value?.columns) {
				for (const each of fieldSettingOrigin.value?.columns) {
					const f = linkMeta.value?.fields.find(item => item.fieldname === each.field);
					if (ExtraColumns.map(exCol=>exCol.fieldname).includes(each.field)) {
						const extraColumn = ExtraColumns.find(exCol=>exCol.fieldname === each.field);
						if (!extraColumn) {
							return;
						}
						columns.push(extraColumn as locals.DocField);
					}
					if (each.field === '__Title__') {
						const titleField = linkMeta.value?.fields.find(item=>item.fieldname === linkMeta.value.title_field);
						columns.push({
							label: titleField?.label || 'ID',
							fieldname: titleField?.fieldname || 'name',
							fieldtype: 'Title',
						} as locals.DocField);
					}

					if (f) {
						columns.push(f);
					}
				}
			}
			columns = columns.map(item => {
				const fieldSetting = fieldSettingOrigin.value?.columns?.find(each => each.field === item.fieldname);
				return {
					...item,
					label: fieldSetting?.title || item.label,
					width: fieldSetting?.width || item.width || 100,
					minWidth: parseInt(fieldSetting?.min_width || '0') === 0 ? undefined : fieldSetting?.min_width,
					maxWidth: parseInt(fieldSetting?.max_width || '0') === 0 ? undefined : fieldSetting?.max_width,
				};
			});
		}
		if (linkMeta.value?.is_tree) {
			columns = columns.filter(item => item.fieldname !== linkMeta.value?.title_field);
		}
		const titleField = linkMeta.value?.fields?.find(item => item.fieldname === linkMeta.value?.title_field);
		// 要求取 标题 这个列的宽度
		const columnTitleSetting = fieldSettingOrigin.value?.columns?.find(each => each.field === '__Title__');

		let filterColumns: locals.DocField[] = [];
		if (fieldSettingOrigin.value?.filter_columns && fieldSettingOrigin.value?.filter_columns.length > 0) {
			if (fieldSettingOrigin.value?.filter_columns) {
				for (const each of fieldSettingOrigin.value?.filter_columns) {
					const f = linkMeta.value?.fields.find(item => item.fieldname === each.field);
					if (ExtraColumns.map(exCol=>exCol.fieldname).includes(each.field)) {
						const extraColumn = ExtraColumns.find(exCol=>exCol.fieldname === each.field);
						if (!extraColumn) {
							return;
						}
						filterColumns.push(extraColumn as locals.DocField);
					}
					if (each.field === '__Title__') {
						const titleField = linkMeta.value?.fields.find(item=>item.fieldname === linkMeta.value.title_field);
						filterColumns.push({
							label: titleField?.label || 'ID',
							fieldname: titleField?.fieldname || 'name',
							fieldtype: 'Title',
						} as locals.DocField);
					}
					if (f) {
						filterColumns.push(f);
					}
				}
			}
		} else {
			filterColumns = linkMeta.value?.fields.filter(item => item.in_list_view === 1) || [];
		}
		filterColumns = filterColumns.map(item => {
			const fieldSetting = fieldSettingOrigin.value?.filter_columns?.find(each => each.field === item.fieldname);
			return {
				...item,
				label: fieldSetting?.title || item.label,
				width: fieldSetting?.width || item.width || 100,
				minWidth: parseInt(fieldSetting?.min_width || '0') === 0 ? undefined : fieldSetting?.min_width,
				maxWidth: parseInt(fieldSetting?.max_width || '0') === 0 ? undefined : fieldSetting?.max_width,
			};
		});
		if (linkMeta.value?.is_tree) {
			filterColumns = filterColumns.filter(item => item.fieldname !== linkMeta.value?.title_field);
		}
		const filterColumnTitleSetting = fieldSettingOrigin.value?.filter_columns?.find(each => each.field === '__Title__');

		const settingTemp: ConnectionSetting = {
			...fieldSettingOrigin.value,
			columnTitleSetting,
			filterColumnTitleSetting,
			columns,
			filter_columns: filterColumns,
			hide_bulkdelete_button: (buttonHidden.value.delete === '' || buttonHidden.value.delete === undefined) ? Boolean(fieldSettingOrigin.value.hide_bulkdelete_button) : buttonHidden.value.delete,
			hide_add_button: (buttonHidden.value.add === '' || buttonHidden.value.add === undefined) ? Boolean(fieldSettingOrigin.value.hide_add_button) : buttonHidden.value.add,
			hide_delete_button: (actionButtonHidden.value.delete === '' || actionButtonHidden.value.delete === undefined) ? Boolean(fieldSettingOrigin.value.hide_delete_button) : actionButtonHidden.value.delete,
			hide_edit_button: (actionButtonHidden.value.edit === '' || actionButtonHidden.value.edit === undefined) ? Boolean(fieldSettingOrigin.value.hide_edit_button) : actionButtonHidden.value.edit,
			hide_link_button: (buttonHidden.value.link === '' || buttonHidden.value.link === undefined) ? Boolean(fieldSettingOrigin.value.hide_link_button) : buttonHidden.value.link,
			hide_unlink_button: (actionButtonHidden.value.unlink === '' || actionButtonHidden.value.unlink === undefined) ? Boolean(fieldSettingOrigin.value.hide_unlink_button) : actionButtonHidden.value.unlink,
			hide_pagination: (isHidePagination.value === '' || isHidePagination.value === undefined) ? Boolean(fieldSettingOrigin.value.hide_pagination) : isHidePagination.value,
			not_select: notSelect.value,
			deleteTip: deleteTip.value,
			is_detail_use_dialog: (isDetailUseDialog.value === '' || isDetailUseDialog.value === undefined) ? Boolean(fieldSettingOrigin.value.is_detail_use_dialog) : isDetailUseDialog.value,
			table_filter: (tableQueries.value === '' || tableQueries.value === undefined) ? frappe.utils.get_filter_from_json(
				fieldSettingOrigin.value.table_filter,
				fieldSettingOrigin.value.connection_table,
			) : (tableQueries.value?.filters || undefined),
			dialog_filter: frappe.utils.get_filter_from_json(fieldSettingOrigin.value.dialog_filter),
			hide_add_child_button: hideAddChildButton.value,
		};
		return settingTemp;
	});
	return {fieldSetting, linkMeta, mainMeta, middleMeta};
}
