<template>
	<ElRate v-model="value" allowHalf :max="max" />
</template>
<script lang="ts" setup>
import { computed, onUnmounted, watch } from 'vue';
import { ElRate } from 'element-plus';


const props = defineProps<{
	label?: string;
	name: string;
	required?: boolean;
	filter?: boolean;
	modelValue?: any;
	fitWidth?: boolean;
	popperClass?: string;
	options?: any;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: any): void;
}>();
const max = computed(() => parseInt(props.options) || 5);

function getBaseNumber(value: any): number | undefined {
	switch (typeof value) {
		case 'bigint': return Number(value);
		case 'number': return value;
		case 'string': return Number.parseFloat(value);
	}
}
function getValue(value: any) {
	const n = getBaseNumber(value);
	if (n === undefined || isNaN(n)) { return; }
	if (!n) { return n; }
	const level = Math.fround(n * max.value * 2) / (max.value * 2);
	return Math.max(0, Math.min(level, 1));
}

watch(() => props.modelValue, modelValue => {
	const value = getValue(modelValue);
	if (value !== modelValue) {
		emit('update:modelValue', value);
	}
}, { immediate: true });
const value = computed({
	get: () => {
		const value = getValue(props.modelValue);
		if (!value) { return value; }
		return Math.fround(value * max.value * 2) / 2;
	},
	set: v => emit('update:modelValue', v && Math.fround(v * 2) / (max.value * 2)),
});
</script>
