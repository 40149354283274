import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-595af93f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Page"], null, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.tt('Company Task Template Assessment Scoring Page')), 1 /* TEXT */)
    ]),
    tools: _withCtx(() => [
      _createVNode($setup["ToolBar"], {
        company: $setup.company,
        "onUpdate:company": _cache[0] || (_cache[0] = $event => (($setup.company) = $event)),
        organization: $setup.organization,
        "onUpdate:organization": _cache[1] || (_cache[1] = $event => (($setup.organization) = $event)),
        refreshValue: $setup.refreshValue,
        "onUpdate:refreshValue": _cache[2] || (_cache[2] = $event => (($setup.refreshValue) = $event)),
        loading: $setup.loading,
        "onUpdate:loading": _cache[3] || (_cache[3] = $event => (($setup.loading) = $event)),
        detailGridApi: $setup.detailGridApi
      }, null, 8 /* PROPS */, ["company", "organization", "refreshValue", "loading", "detailGridApi"])
    ]),
    sider: _withCtx(() => [
      _createVNode($setup["OrganizationTree"], {
        organizations: $setup.organizations,
        organizationLoading: $setup.organizationLoading,
        organization: $setup.organization,
        "onUpdate:organization": _cache[4] || (_cache[4] = $event => (($setup.organization) = $event))
      }, null, 8 /* PROPS */, ["organizations", "organizationLoading", "organization"])
    ]),
    default: _withCtx(() => [
      _createVNode($setup["DetailTable"], {
        company: $setup.company,
        detailTableData: $setup.detailTableData,
        loading: $setup.loading,
        onOnDetailGridReady: $setup.onDetailGridReady
      }, null, 8 /* PROPS */, ["company", "detailTableData", "loading"])
    ]),
    _: 1 /* STABLE */
  }))
}