<template>
	<div class="update_record_container">
		<ElCard
			v-if="data.length"
			style="width: 90%; margin: 10px auto;"
			v-for="item in data"
			:key="item.name"
			shadow="hover"
		>
			<div class="update_record_item">
				<span class="update_record_label"> {{ tt('Update Date') }}: </span>
				<span>{{ item.update_date }}</span>
			</div>
			<div class="update_record_item">
				<span class="update_record_label"> {{ tt('Subject') }}: </span>
				<span>{{ item.title }}</span>
			</div>
			<Description :description="item.description"></Description>
		</ElCard>
		<ElEmpty :description="tt('No Records Created')" v-else/>
	</div>
</template>

<script setup lang='ts'>
import {ElCard, ElEmpty} from 'element-plus';

import Description from './Description.vue';
import type {CardData} from './type';

const tt = __;
defineProps<{
	data: CardData[]
}>();

</script>

<style scoped lang="less">
.update_record_container{
	height:50vh;
	overflow: auto;
	.update_record_item{
		margin-bottom: 8px;
		.update_record_label {
			margin-right: 8px;
			font-weight: bolder;
		}
	}
}
</style>
