import { computed, shallowReactive } from 'vue';

import { defineStore } from 'pinia';

export type ExpanderItem = {
	set(): void,
	key: string | number,
	title: string | [string, Record<string, string>]
};

export type ExpanderValue = {
	value: Iterable<string> | (() => Iterable<string>),
	key?: string | number,
	title: string | [string, Record<string, string>]
};

const ss = defineStore('Guigu:GlobalView:Expander', () => {
	const data = shallowReactive<{
		list: ExpanderItem[],
		fold: () => void,
	}>({ list: [], fold: () => { } });
	return { data };
});
export default function useExpander() {
	const state = ss();
	const expandable = computed(() => state.data.list);
	let i = 0;
	function setExpandable(values: ExpanderValue[], cb: (list: string[]) => void) {
		state.data = {
			list: values.map(({ value, ...v }) => ({
				...v, key: i++, set: () => {
					cb([...new Set(typeof value === 'function' ? value() : value)]);
				},
			})),
			fold: () => cb([]),
		};
	}

	return {
		expandable, fold() { state.data.fold(); }, setExpandable,
	};
}
