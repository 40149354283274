import type { RowValue } from '../core';

export function getDate(
	data: any,
	dateFields: [string, (v: any) => Date | undefined][],
): Record<string, Date | undefined> {
	return Object.fromEntries(dateFields.map(([s, v]) => [s, v(data)]));
}


export default function getDateData(
	allData: readonly RowValue[],
	dateFields: [string, (v: any) => Date | undefined][],
): Map<number | string, Record<string, Date | undefined>> {
	const allLineData = new Map<number | string, Record<string, Date | undefined>>;

	for (const { id, data } of allData) {
		allLineData.set(id, getDate(data, dateFields));
	}
	return allLineData;
}
