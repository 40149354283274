<template>
	<ElFormItem :label="tt('Grouped')">
		<ElInput v-model="group" />
	</ElFormItem>
	<ElFormItem :label="tt('Link Field')">
		<ElSelect v-model="linkField" filterable defaultFirstOption clearable>
			<ElOption v-for="field in linkFields" :key="field.value"
				:value="field.value" :label="field.label" />
		</ElSelect>
	</ElFormItem>
	<ElFormItem :label="tt('Default Layout')">
		<ElSelect v-model="defaultLayout" filterable defaultFirstOption clearable>
			<ElOption v-for="layout in layouts" :key="layout.value"
				:value="layout.value" :label="layout.label" />
		</ElSelect>
	</ElFormItem>
	<ElFormItem :label="tt('Max Limit')" v-if="!nonpageable">
		<ElInputNumber v-model="maxLimit" />
	</ElFormItem>
	<ElFormItem :label="tt('Default Limit')" v-if="!nonpageable">
		<ElInputNumber v-model="defaultLimit" />
	</ElFormItem>
	<ElFormItem :label="tt('Infinite Scroll')" v-if="!nonpageable && !scrollOnly">
		<ElSelect v-model="infiniteScroll">
			<ElOption value="Default" :label="tt('Default')" />
			<ElOption value="Yes" :label="tt('Yes')" />
			<ElOption value="No" :label="tt('No')" />
		</ElSelect>
	</ElFormItem>
	<ElFormItem :label="tt('Query Filters')">
		<ElSelect v-model="queryFilters" multiple filterable defaultFirstOption
			clearable style="width: 100%">
			<ElOption v-for="field in queryFilterFields" :key="field.fieldname"
				:value="field.fieldname" :label="field.label" />
		</ElSelect>
	</ElFormItem>
	<ElFormItem :label="tt('Fields Copied When Creating Children')">
		<ElSelect v-model="fieldsCopiedWhenCreatingChildren" multiple filterable
			defaultFirstOption clearable style="width: 100%">
			<ElOption v-for="field in fields" :key="field.value"
				:value="field.value" :label="field.label" />
		</ElSelect>
	</ElFormItem>
	<ElFormItem :label="tt('Fields Copied While Copying')">
		<ElSelect v-model="fieldsCopiedWhileCopying" multiple filterable
			defaultFirstOption clearable style="width: 100%">
			<ElOption v-for="field in fields" :key="field.value"
				:value="field.value" :label="field.label" />
		</ElSelect>
	</ElFormItem>
	<ElFormItem :label="tt('Fields Requested')">
		<ElSelect v-model="fieldsRequested" multiple filterable defaultFirstOption
			clearable style="width: 100%">
			<ElOption v-for="field in fields" :key="field.value"
				:value="field.value" :label="field.label" />
		</ElSelect>
	</ElFormItem>
	<ElFormItem>
		<ElCheckbox v-model="isHideAddBtn" :label="tt('Hide Add Button')" />
	</ElFormItem>
	<ElFormItem>
		<ElCheckbox v-model="isHideSider" :label="tt('Hide Side')" />
	</ElFormItem>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import {ElSelect, ElOption, ElInput, ElInputNumber, ElFormItem} from 'element-plus';

import {layouts} from '../constants';
import getQuickFilterFields from '../useViews/getQuickFilterFields';
const props = defineProps<{
	meta: locals.DocType;
	modelValue: any;
	define?: GlobalView;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: GlobalView.View): void;
}>();
const view = computed({
	get: () => props.modelValue,
	set: v => emit('update:modelValue', v),
});
const tt = __;

const nonpageable = computed(() => Boolean(props.define?.nonpageable));
const scrollOnly = computed(() => Boolean(props.define?.scrollOnly));
const fields = computed(() => (props.meta.fields || [])
	.filter(d => !frappe.model.no_value_type.includes(d.fieldtype))
	.map(function toSelect(d) {
		return {
			value: d.fieldname,
			label: `${__(d.label)} (${d.fieldname})`,
		};
	}));
const linkFields = computed(() => (props.meta.fields || [])
	.filter(d => ['Link', 'Dynamic Link'].includes(d.fieldtype))
	.map(function toSelect(d) {
		return {
			value: d.fieldname,
			label: `${__(d.label)} (${d.fieldname})`,
		};
	}));
const group = computed({
	get: () => view.value.group || '',
	set: v => {
		view.value = {...view.value, group: v || ''};
	},
});
const linkField = computed({
	get: () => view.value.link_field,
	set: v => {
		view.value = {...view.value, link_field: v || null};
	},
});

const maxLimit = computed({
	get: () => view.value.max_limit || 0,
	set: v => {
		view.value = {...view.value, max_limit: v || 0};
	},
});

const defaultLayout = computed({
	get: () => view.value.default_layout || 'link',
	set: v => {
		view.value = {...view.value, default_layout: v || 'link'};
	},
});
const defaultLimit = computed({
	get: () => view.value.default_limit || 0,
	set: v => {
		view.value = {...view.value, default_limit: v || 0};
	},
});

const infiniteScroll = computed({
	get: () => view.value.infinite_scroll || 'Default',
	set: v => {
		view.value = {...view.value, infinite_scroll: v || 'Default'};
	},
});

const queryFilters = computed<string[]>({
	get: () => (view.value.query_filters || '').split('\n').filter(Boolean),
	set: v => {
		view.value = {...view.value, query_filters: v.join('\n')};
	},
});
const queryFilterFields = computed(() => getQuickFilterFields(props.meta));

const fieldsCopiedWhenCreatingChildren = computed({
	get: () => (view.value.fields_copied_when_creating_children || '').split('\n').filter(Boolean),
	set: v => {
		view.value = {...view.value, fields_copied_when_creating_children: v.join('\n')};
	},
});
const fieldsCopiedWhileCopying = computed({
	get: () => (view.value.fields_copied_while_copying || '').split('\n').filter(Boolean),
	set: v => {
		view.value = {...view.value, fields_copied_while_copying: v.join('\n')};
	},
});
const fieldsRequested = computed({
	get: () => (view.value.fields_requested || '').split('\n').filter(Boolean),
	set: v => {
		view.value = {...view.value, fields_requested: v.join('\n')};
	},
});
const isHideAddBtn = computed({
	get: () => Boolean(view.value.is_hide_add_btn),
	set: v => {
		view.value = {...view.value, is_hide_add_btn: v};
	},
});
const isHideSider = computed({
	get: () => Boolean(view.value.is_hide_sider),
	set: v => {
		view.value = {...view.value, is_hide_sider: v};
	},
});
</script>
