import defineMainView from 'guiguLib/defineMainView';

import type { KanbanConfiguration } from './types';
import View from './Main.vue';
import LaneConfig from './LaneConfig.vue';

function parseJSON<T>(v: string): T[] {
  try {
    const j = JSON.parse(v);
    if (!j) {
      return [];
    }
    return Array.isArray(j) ? j : [j];
  } catch {
    return [];
  }
}
defineMainView<KanbanConfiguration>({
  type: 'Guigu Kanban Configuration',
  label: 'Kanban',

  nonpageable: true,
  scrollOnly: true,

  sider: [],
  toolbar: ['filterLine'],
  detail: true,
  view: View,
  rowAction: false,
  configuration: {
    filterField: true,
    widgets: [
      {
        component: LaneConfig,
        label: __('Swimming Lanes'),
      },
    ],
    displayField: {
      useDefault: false,

      titleField: true,
      commentField: true,
      tagField: true,

      showLabel: true,
      enableLink: true,
    },
  },
  pretreat: [],
  updateConfigurations(data, meta, old, view) {
    if (!data) {
      return;
    }
    const { groups } = data;
    if (!Array.isArray(groups)) {
      return;
    }
    return {
      groups: groups.map((g: any) => ({
        field: g.field,
        column: Boolean(g.column),
        values: Array.isArray(g.values) ? JSON.stringify(g.values) : '[]',
      })),
    };
  },
  async getConfigurations(
    meta,
    {
      groups,
    }: {
      groups: { field: string; column: any; values: string }[];
    }
  ): Promise<KanbanConfiguration> {
    const data: KanbanConfiguration = {
      groups: groups.map((g) => ({
        field: g.field,
        column: Boolean(g.column),
        values: parseJSON(g.values),
      })),
    };
    return data;
  },
  *fields(meta: locals.DocType, { groups }: KanbanConfiguration) {
    yield* groups.map((g) => g.field) || [];
  },
});
