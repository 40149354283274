function isDistinct(
	doctype: string,
	filters?: [string, string, string, any][] | Record<string, any>
) {
	return Array.isArray(filters) && filters.some(filter => filter[0] !== doctype);
}

export default async function requestDocCount(
	doctype: string,
	filters?: [string, string, string, any][] | Record<string, any>,
	orFilters?: ([string, string, string, any][] | Record<string, any>)[],
): Promise<number> {
	// has a filter with childtable?
	const distinct = isDistinct(doctype, filters)
		|| Array.isArray(orFilters)
		&& orFilters.some(filters => isDistinct(doctype, filters));


	return frappe.xcall('frappe.desk.reportview.get_count', {
		doctype,
		filters,
		guigu_or_filters: orFilters,
		distinct,
	});
}
