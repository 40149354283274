import is_field_editable from './is_field_editable';

export default function edit2(doctype: string, docnames: string[], done: () => void) {

	let field_mappings: Record<string, any> = {};

	frappe.meta.get_docfields(doctype).forEach(field_doc => {
		if (is_field_editable(field_doc)) {
			field_mappings[field_doc.label] = { ...field_doc };
		}
	});

	let field_options = Object.keys(field_mappings).sort();
	const status_regex = /status/i;

	const default_field = field_options.find(value => status_regex.test(value));

	const dialog = new frappe.ui.Dialog({
		title: __('Bulk Edit'),
		fields: [
			{
				fieldtype: 'Select',
				options: field_options,
				default: default_field,
				label: __('Field'),
				fieldname: 'field',
				reqd: 1,
				onchange: () => {
					set_value_field(dialog);
				},
			},
			{
				fieldtype: 'Data',
				label: __('Value'),
				fieldname: 'value',
				onchange() {
					show_help_text();
				},
			},
		],
		primary_action: ({ value }) => {
			const { fieldname } = field_mappings[dialog.get_value('field')];
			dialog.disable_primary_action();
			frappe
				.call({
					method: 'frappe.desk.doctype.bulk_update.bulk_update.submit_cancel_or_update_docs',
					args: {
						doctype,
						freeze: true,
						docnames,
						action: 'update',
						data: {
							[fieldname]: value || null,
						},
					},
				})
				.then(r => {
					let failed = r.message || [];

					if (failed.length && !r._server_messages) {
						dialog.enable_primary_action();
						frappe.throw(
							__('Cannot update {0}', [
								failed.map(f => (f.bold ? f.bold() : f)).join(', '),
							])
						);
					}
					done();
					dialog.hide();
					frappe.show_alert(__('Updated successfully'));
				});
		},
		primary_action_label: __('Update {0} records', [docnames.length]),
	});

	if (default_field) { set_value_field(dialog); } // to set `Value` df based on default `Field`
	show_help_text();

	function set_value_field(dialogObj) {
		const new_df = Object.assign({}, field_mappings[dialogObj.get_value('field')]);
		/* if the field label has status in it and
		if it has select fieldtype with no default value then
		set a default value from the available option. */
		if (
			new_df.label.match(status_regex) &&
			new_df.fieldtype === 'Select' &&
			!new_df.default
		) {
			let options = [];
			if (typeof new_df.options === 'string') {
				options = new_df.options.split('\n');
			}
			//set second option as default if first option is an empty string
			new_df.default = options[0] || options[1];
		}
		new_df.label = __('Value');
		new_df.onchange = show_help_text;

		delete new_df.depends_on;
		dialogObj.replace_field('value', new_df);
		show_help_text();
	}

	function show_help_text() {
		let value = dialog.get_value('value');
		if (value == null || value === '') {
			dialog.set_df_property(
				'value',
				'description',
				__('You have not entered a value. The field will be set to empty.')
			);
		} else {
			dialog.set_df_property('value', 'description', '');
		}
	}

	dialog.refresh();
	dialog.show();
}
