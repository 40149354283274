async function getQrState(
	key: string,
	appid: string,
	agentId?: string,
): Promise<boolean | string | null> {
	let serviceUrl = '/api/method/guigu_wechat.qr_login.state';
	serviceUrl += `?appid=${appid}`;
	if (agentId) {
		serviceUrl += `:${agentId}`;
	}
	serviceUrl += `&key=${encodeURIComponent(key)}`;
	try {
		const response = await fetch(serviceUrl);
		const { message } = await response.json();
		return message;
	} catch {
		return null;
	}
}
interface QrInfo {
	key: string;
	appid: string;
	agentid?: string;
	ticket: string;
}

function createQr(data: QrInfo) {
	const img = document.createElement('img');
	let url = `${location.origin}${location.pathname}`;
	url += `?appid=${data.appid}`;
	const agentId = data.agentid;
	if (agentId) {
		url += `&agentid=${agentId}`;
	}
	url += `&ticket=${encodeURIComponent(data.ticket)}`;
	url = `/api/method/guigu_wechat.qr_login.qr_svg?v=${encodeURIComponent(
		url,
	)}`;
	img.src = url;
	return img;
}
/**
 *
 * @param {Text} text
 */
function createTime(
	text: Text,
	done: () => void,
): [(d: number) => void, () => void] {
	let date = 1000;
	let hd: number | undefined;
	function stop() {
		clearInterval(hd);
	}
	function update() {
		const t = Number(new Date());
		const l = Math.max(0, date - t) / 1000;
		text.textContent = l.toFixed(0).padStart(2, '0');
		if (l > 0) {
			return;
		}
		stop();
		done();
	}
	function start(d: number) {
		date = d;
		clearInterval(hd);
		hd = setInterval(update, 200);
	}
	return [start, stop];
}

async function createQrcode(appid: string, agentId?: string) {
	let serviceUrl = '/api/method/guigu_wechat.qr_login.create';
	serviceUrl += `?appid=${appid}`;
	if (agentId) {
		serviceUrl += `&agentid=${agentId}`;
	}
	const response = await fetch(serviceUrl);
	const { message: data }: { message: QrInfo } = await response.json();
	return data;
}

export default function showCode(
	redirect: string,
	appid: string,
	agentId?: string,
) {
	const text = document.createTextNode('0');
	const root = document.createElement('div');
	root.style.textAlign = 'center';

	const qrParent = root.appendChild(document.createElement('div'));

	const timeParent = root.appendChild(document.createElement('div'));
	const netError = root.appendChild(document.createElement('div'));
	const overdueDiv = root.appendChild(document.createElement('div'));
	const doneDiv = root.appendChild(document.createElement('div'));

	// 不能调__翻译，这里还没有frappe对象
	doneDiv.appendChild(
		document.createTextNode('二维码已被扫'),
	);

	netError.appendChild(document.createTextNode('网络错误'));
	overdueDiv.appendChild(
		document.createTextNode('二维码已过期'),
	);
	const btn1 = overdueDiv.appendChild(document.createElement('button'));
	const btn2 = netError.appendChild(document.createElement('button'));
	btn1.appendChild(document.createTextNode('刷新二维码'));
	btn2.appendChild(document.createTextNode('刷新二维码'));

	timeParent.appendChild(
		document.createTextNode('二维码将于 '),
	);
	timeParent.appendChild(document.createElement('b')).appendChild(text);
	timeParent.appendChild(document.createTextNode(' 秒后过期'));

	function hideAll() {
		netError.hidden = true;
		timeParent.hidden = true;
		overdueDiv.hidden = true;
		doneDiv.hidden = true;
	}
	hideAll();
	document.body.appendChild(root);
	const [start, stop] = createTime(text, function () {
		hideAll();
		overdueDiv.hidden = false;
	});
	let qrKey = '';

	let requiring = false;
	async function requireState() {
		if (requiring) {
			return;
		}
		requiring = true;
		try {
			while (!timeParent.hidden) {
				if (!qrKey) {
					return;
				}
				const code = await getQrState(qrKey, appid, agentId);
				if (code === null) {
					hideAll();
					netError.hidden = false;
					return;
				}
				if (code === false) {
					hideAll();
					overdueDiv.hidden = false;
					return;
				}
				if (typeof code === 'string') {
					hideAll();
					doneDiv.hidden = false;
					location.href = `${redirect}&custom=1&code=${encodeURIComponent(
						code,
					)}`;
					return;
				}
				await new Promise(r => setTimeout(r, 500));
			}
		} finally {
			requiring = false;
		}
	}

	let loading = false;
	async function loadQr() {
		if (loading) {
			return;
		}
		loading = true;
		stop();
		hideAll();
		try {
			const data = await createQrcode(appid, agentId);
			qrKey = data.key;
			qrParent.innerHTML = '';
			qrParent.appendChild(createQr(data));
			start(Number(new Date()) + 100000);
			timeParent.hidden = false;
			requireState();
		} catch {
			netError.hidden = false;
		} finally {
			loading = false;
		}
	}
	btn1.addEventListener('click', loadQr);
	btn2.addEventListener('click', loadQr);
	loadQr();
}
