export default async function loadLinkDocTypes(
	meta: locals.DocType,
	settings: Record<string, any>
) {
	const doctype = meta.name;
	const linkDocTypes = [
		...frappe.model.std_fields_list,
		...meta.fields.filter(df => (
			(frappe.model.is_value_type(df.fieldtype) &&
				frappe.perm.has_perm(doctype, df.permlevel, 'read')) ||
			(df.fieldtype === 'Currency' && df.options && !df.options.includes(':')) ||
			df.fieldname === 'status'
		)),
		meta.title_field,
		meta.image_field,
		...settings.add_fields || [],
		meta.track_seen ? '_seen' : '',
		// this.sort_by,
		'enabled',
		'disabled',
		'color',
	]
		.filter(Boolean)
		.map(f => typeof f === 'string' ? meta.fields.find(v => v.fieldname === f) : f)
		.filter(Boolean)
		.map(df => (df.fieldtype === 'Link'||df.fieldtype === 'Tree Select') ? df.options as string : '')
		.filter(Boolean);

	await Promise.all(
		[...new Set(linkDocTypes)].map(doctype => new Promise<void>(resolve => {
			frappe.model.with_doctype(doctype, () => { resolve(); });
		}))
	);

}
