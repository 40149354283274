import {ElMessage} from 'element-plus';

import requestDocList from '../../utils/requestDocList';
import JSZip from '../../../lib/jszip.min.js';
import FileSaver from '../../../lib/FileSaver.min.js';

interface FileTree {
	name:string,
	title: any;
	url?: string;
	parent: any;
	isFolder: boolean,
	children?: this[]
}
interface File{
	file_name:string, file_url:string, attached_to_name:string, attached_to_field?:string
}
export default async function download(doctype: locals.DocType, docnames: string[], fields: string[]) {
	ElMessage({
		type: 'success',
		message: __('If the file is large，the download time will be longer. Please be patient and wait'),
	});

	const fileListRes = await frappe.call<{message:File[]}>({
		method: 'guigu.view.download.get_download',
		args: {doctype: doctype.name, names: docnames},
	});
	const fileFieldNames = fileListRes?.message?.map(f=>f.attached_to_field).filter(Boolean);
	const fileFields = Array.from(new Set(fileFieldNames)).map(field=>doctype.fields.find(f=>f.fieldname === field)).filter(Boolean) as locals.DocField[];
	const docFields = fields.map(field=>doctype.fields.find(f=>f.fieldname === field)).filter(Boolean) as locals.DocField[];
	const docList = await requestDocList(
		doctype,
		[[doctype.name, 'name', 'in', docnames]],
		{
			fields: [['name', doctype.name], ...docFields.map(f=>[f.fieldname, doctype.name]) as [string, string][]],
			group: [],
			order: [],
		},
	);
	const unitFiles:File[] = [];
	for (const file of fileListRes?.message || []) {
		if (!unitFiles.some(item=>item.file_url === file.file_url && item.attached_to_name === file.attached_to_name)) {
			unitFiles.push(file);
		}
	}
	const folderList = getFolderList(docList, docFields, fileFields, unitFiles);
	downloadZip(folderList);
}
function downloadZip(folderList: FileTree[]) {
	const fileTree = list2Tree(folderList, 'parent');
	const allFilesNumber = folderList.filter(item => !item.isFolder).length;
	const zip = new JSZip();
	const time = moment().format('YYYY-MM-DD');
	const fileFolder = zip.folder(`${time}-export`);
	const fileNames: string[] = [];
	zipFile(allFilesNumber, fileTree, fileNames, fileFolder, zip);
	if (allFilesNumber === 0) {
		zip.generateAsync({type: 'blob'}).then((content: any) => {
			const time = moment().format('YYYY-MM-DD');
			FileSaver.saveAs(content, `${time}-export.zip`);
		});
	}
}

function getFolderList(docList:any[], fields:locals.DocField[], fileFields:locals.DocField[], fileList:File[]) {
	let folderList:FileTree[] = [];
	for (const doc of docList) {
		const files = fileList.filter(item=>item.attached_to_name === doc.name)
			.map(item=>({
				name: item.file_name,
				title: item.file_name,
				url: encodeURI(item.file_url).replace(/#/g, '%23'),
				isFolder: false,
				attachToField: item.attached_to_field,
				parent: '',
			}));
		let preFolderID = '';
		for (const [index, field] of fields.entries()) {
			const folderName = getLabel(doc, field);
			const folderID = index === 0 ? `${field.fieldname}_${folderName}` : `${preFolderID}_${field.fieldname}_${folderName}`;
			if (index === fields.length - 1) {
				for (const f of files) {
					f.parent = folderID;
				}
				const docFiles = files.filter(f=>!fileFields.some(ff=>ff.fieldname === f.attachToField));
				for (const f of docFiles) {
					f.parent = folderID;
				}
				for (const fileField of fileFields) {
					const fileFolderID = `${folderID}_${fileField.fieldname}_${__(fileField.label)}`;
					folderList.push({
						name: fileFolderID,
						title: __(fileField.label),
						isFolder: true,
						parent: folderID,
					});
					const fieldFiles = files.filter(f=>f.attachToField === fileField.fieldname);
					for (const f of fieldFiles) {
						f.parent = fileFolderID;
					}
				}
				folderList = [...folderList, ...files];
			}
			if (!folderList.some(item=>item.name === folderID && item.parent === preFolderID)) {
				folderList.push({
					name: folderID,
					title: folderName,
					isFolder: true,
					parent: preFolderID,
				});
			}
			preFolderID = folderID;
		}
	}
	return folderList;
}

function getLabel(doc:any, field:locals.DocField) {
	const fieldname = ['Link', 'Tree Select', 'Tianjy Related Link'].includes(field.fieldtype) ? `${field.fieldname}.title` : field.fieldname;
	let folderName = doc[fieldname];
	if (field.fieldtype === 'Text Editor') {
		const ele = document.createElement('div');
		ele.innerHTML = folderName;
		folderName = ele.innerText?.replace(/\s+/g, '');
	}
	if (field.fieldtype === 'Guigu Date' && folderName) {
		const type = field.options?.toLowerCase() || 'month';
		const lang = frappe.boot.user?.language || 'en';
		switch (type) {
			case 'week':
				return moment(folderName).format(`gggg-ww[${lang === 'zh' ? __('Week') : 'week'}]`);
			case 'month':
				return moment(folderName).format('YYYY-MM');
			case 'year':
				return moment(folderName).format('YYYY');
			case 'quarter':
				return `${moment(folderName).format('YYYY')}-${moment(folderName).quarter()}${__('Quarter')}`;
		}
	}
	return __(folderName || __('Default Folder'));
}
function zipFile(fileNumber:number, files: FileTree[], fileNames: string[], fileFolder: any, zip: any) {
	const nameCatalogue: Record<string, any> = {};
	for (const [index, file] of files.entries()) {
		if (file.isFolder) {
			const newFileFolder = fileFolder.folder(file.title);
			if (!file.children || file.children.length === 0) {
				continue;
			}
			zipFile(fileNumber, file.children, fileNames, newFileFolder, zip);
			continue;
		}
		const onloadCallback = (url?: string) => {
			const ext = file.title.split('.').pop()?.toLowerCase();
			let newFileName = file?.title.substring(0, file?.title.lastIndexOf('.'));
			const fileName = newFileName;
			nameCatalogue[fileName] = nameCatalogue?.[fileName] + 1 || 0;
			if (fileNames.includes(fileName)) {
				newFileName = `(${fileName}${nameCatalogue[fileName]})`;
			}
			fileNames.push(fileName);
			if (url) {
				fileFolder.file(
					`${newFileName}.${ext}`,
					url.substring(url.indexOf(',') + 1),
					{
						base64: true,
					},
				);
			}
			if (fileNames.length === fileNumber) {
				zip.generateAsync({type: 'blob'}).then((content: any) => {
					const time = moment().format('YYYY-MM-DD');
					FileSaver.saveAs(content, `${time}-export.zip`);
				});
			}
		};
		const onprogressCallback = (progress: number, index?: number) => {
			if (!index) {
				return;
			}
		};
		getBase64(file?.url!, onloadCallback, onprogressCallback, index);
	}
}


function getBase64(
	url: string,
	onloadCallback: (Base64?: string) => any,
	onprogressCallback?: (progress: number, index?: number) => any,
	index?: number,
) {
	const xhr = new XMLHttpRequest();
	xhr.open('GET', `${url}?v=${Math.random()}`, true);
	xhr.responseType = 'blob';
	xhr.onprogress = function (event) {
		const total = event.total === 0 ? event.loaded : event.total;
		return onprogressCallback?.(event.loaded / total, index) || null;
	};
	xhr.onload = () => {
		if (xhr.status === 200) {
			const reader = new FileReader();
			reader.onloadend = function () {
				return onloadCallback?.(String(reader.result)) || null;
			};
			reader.readAsDataURL(xhr.response);
		} else {
			onloadCallback?.();
		}
	};
	xhr.onerror = () => {
		onloadCallback?.();
	};
	xhr.send();
}

interface Item{
	name:string,
	[parent:string]:any
}
interface TreeItemData extends Item {
	children?: this[];
}
function list2Tree<T extends Item>(listData: T[], parentField:string) {
	const listDataCopy:T[] = structuredClone(listData);
	const treeData: (T & TreeItemData)[] = [];
	const map:Record<string, any> = {};
	for (const item of listDataCopy) {
	  map[item.name] = item;
	}
	for (const item of listDataCopy) {
	  const parent = map[item[parentField] || 0];
	  if (parent) {
			(parent.children || (parent.children = [])).push(item);
	  } else {
			treeData.push(item);
	  }
	}
	return treeData;
}
