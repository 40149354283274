import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-85d7136d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tree-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["ElInput"], {
      class: "input_filter",
      modelValue: $setup.filterText,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.filterText) = $event)),
      size: "small",
      style: {"width":"100%"},
      placeholder: $setup.tt('Find Work List')
    }, null, 8 /* PROPS */, ["modelValue", "placeholder"]),
    ($props.treeList.length)
      ? (_openBlock(), _createBlock($setup["ElTree"], {
          key: 0,
          ref: "tree",
          data: $props.treeList,
          props: $setup.defaultProps,
          showCheckbox: "",
          nodeKey: "name",
          expandOnClickNode: false,
          filterNodeMethod: $setup.filterNode,
          onCheck: $setup.checkCheckbox,
          onNodeClick: $setup.nodeClick
        }, {
          default: _withCtx(({ node,data }) => [
            _createVNode($setup["Content"], {
              data: data,
              node: node,
              permission: $props.permission,
              separator: $setup.separator,
              levelList: $setup.levelList,
              processList: $setup.processList,
              onCreatTask: $setup.creatTask
            }, null, 8 /* PROPS */, ["data", "node", "permission", "separator", "levelList", "processList"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["data"]))
      : (_openBlock(), _createBlock($setup["ElEmpty"], {
          key: 1,
          description: $setup.tt('No Data')
        }, null, 8 /* PROPS */, ["description"])),
    ($setup.isShow)
      ? (_openBlock(), _createBlock($setup["ElDialog"], {
          key: 2,
          modelValue: $setup.isShow,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.isShow) = $event)),
          width: "95%",
          style: {"height":"80%"}
        }, {
          default: _withCtx(() => [
            _createVNode($setup["TaskTemplateDialog"], {
              name: $setup.worklistName,
              department: $setup.department,
              onUpdateTaskList: $setup.updateTaskList
            }, null, 8 /* PROPS */, ["name", "department"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"]))
      : _createCommentVNode("v-if", true)
  ])), [
    [$setup["vLoading"], $props.loading]
  ])
}