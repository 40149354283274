<template>
	<span class="ViewPageSidebarToggleBtn" title="切换边栏" @click="switchOpen">
		<svg class="icon icon-md ViewPageSidebarToggleBtn-placeholder">
			<use href="#icon-menu"></use>
		</svg>
		<span class="ViewPageSidebarToggleBtn-icon">
			<svg class="icon icon-md">
				<use :href="open ? '#icon-sidebar-collapse' : '#icon-sidebar-expand'" />
			</svg>
		</span>
	</span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	props: ['open'],
	setup(p, ctx) {
		function switchOpen() {
			const v = !p.open;
			ctx.emit('change', v)
			ctx.emit('update:open', v)
		}
		return {
			switchOpen
		}
	}
})
</script>
<style scoped>
.ViewPageSidebarToggleBtn {
	display: flex;
	margin-right: var(--margin-sm);
	cursor: pointer;
	--icon-stroke: var(--gray-500);
}

.ViewPageSidebarToggleBtn:hover .ViewPageSidebarToggleBtn-placeholder {
	display: none;
}

.ViewPageSidebarToggleBtn-icon {
	display: none;
}

.ViewPageSidebarToggleBtn:hover .ViewPageSidebarToggleBtn-icon {
	display: block;
}
</style>
