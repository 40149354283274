// /* eslint-disable no-unused-expressions */
import { DailyRecordData, SummaryTableData } from '../../type';

export function routeInit(paramsFilter: any,) {
	const [_, ...filters] = frappe.router?.current_route || [];
	const filterObj: { [key: string]: string } = {};
	const keyArr = ['project', 'pageType', 'monthDate', 'yearDate'];
	for (let i = 0; i < filters.length; i++) {
		if ((i & 1) === 0 && keyArr.includes(filters[i])) {
			filterObj[filters[i]] = filters[i + 1];
		}
	}
	paramsFilter.value = filterObj;
}

export function setRoute(paramsFilter: any, filter: { [key: string]: string }) {
	const [pageName] = frappe.router?.current_route || [];
	const newRoute = [pageName];
	const newParamsFilter = { ...paramsFilter.value, ...filter };
	Object.keys(newParamsFilter || {}).forEach(item => {
		newRoute[newRoute.length] = item;
		newRoute[newRoute.length++] = newParamsFilter[item];
	});
	paramsFilter.value = newParamsFilter;
	frappe.set_route(newRoute);
}
/** 将日志记录数据转为obj {[user]: {[date]: [data]}}*/
export function daily_record_to_obj(
	dailyRecordRata: DailyRecordData[],
	type: string
): { [key: string]: { [key: string]: DailyRecordData | number | string } } {
	const obj: { [key: string]: { [key: string]: DailyRecordData | number | string } } = {};
	dailyRecordRata.forEach(item => {
		if (obj[item.record_owner]) {

			if (type === 'month') {
				obj[item.record_owner][item.record_date] = item;
				obj[item.record_owner].diary_count = Number(obj[item.record_owner].diary_count) + 1;
			} else {
				obj[item.record_owner][item.record_date] = item?.count || 0;
				obj[item.record_owner].diary_count = Number(obj[item.record_owner].diary_count) + item?.count || 0;
			}

		} else if (type === 'month') {
			obj[item.record_owner] = {
				[item.record_date]: item,
				diary_count: 1,
				organization_name: item.organization_name,
				record_owner: item.record_owner,
				record_owner_name: item.record_owner_name,

			};
		} else {
			obj[item.record_owner] = {
				[item.record_date]: item?.count || 0,
				diary_count: item?.count || 0,
				organization_name: item.organization_name,
				record_owner: item.record_owner,
				record_owner_name: item.record_owner_name,

			};
		}
	});
	return obj;
}

export function daily_record_data_processing(
	dailyRecordData: DailyRecordData[],
	type: 'month' | 'year',
): SummaryTableData[] {

	const resultArr: SummaryTableData[] = [];
	const dailyRecordObj = daily_record_to_obj(dailyRecordData, type);
	if (type === 'month') {

		return [...Object.values(dailyRecordObj)];
	}

	return [...Object.values(dailyRecordObj)];

}
