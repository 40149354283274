<template>
	<div class="toolbar">
		<div class='placeholder'></div>
		<ElSpace>
			<ElButton type="primary" @click="click">{{ tt('Batch add Construction Structure') }}</ElButton>
		</ElSpace>
		<BulkStructureModal
			:visible="visible"
			:project="project"
			:structure="structureName"
			@close="closeInfo"
		></BulkStructureModal>
	</div>
</template>

<script setup lang='ts'>
import { ref, getCurrentInstance, onMounted, computed, defineEmits, watch } from 'vue';


import BulkStructureModal from './BulkStructureModal.vue';
const tt = __
const {appContext} = getCurrentInstance()||{};
const visible = ref<boolean>(false);
const project = ref<string>(appContext?.config.globalProperties.$frm?.doc?.project);
const structureName = ref<string>(appContext?.config.globalProperties.$frm?.doc?.name);
frappe.ui.form.on('Guigu Construction Structure', {
    refresh(frm: any){
		project.value = frm.doc.project;
    },
    project(frm:any){
      project.value = frm.doc.project;
    },
});

function click(){
	const from = appContext?.config.globalProperties.$frm;
	visible.value=true;
}
function closeInfo(){
    visible.value=false;
}

</script>

<style lang='less' scoped>
.toolbar {
	display: flex;
	align-items: center;
	padding: 8px;
}

.placeholder {
	flex: 1;
}
</style>
