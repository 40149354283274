import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9cc97e97"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-tree-node" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_ElTree = _resolveComponent("ElTree")

  return ($setup.dataSource.length > 0)
    ? (_openBlock(), _createBlock(_component_ElTree, {
        key: 0,
        class: "directory-tree",
        ref: "treeRef",
        data: $setup.dataSource,
        "node-key": "id",
        "default-expanded-keys": $setup.defaultExpandedKeys,
        "highlight-current": "",
        "check-on-click-node": "",
        onNodeClick: $setup.nodeClick,
        onNodeExpand: $setup.nodeExpand,
        onNodeCollapse: $setup.nodeCollapse,
        props: $setup.treeProps,
        "current-node-key": $setup.currentNodeKey,
        "expand-on-click-node": false
      }, {
        default: _withCtx(({ node, data }) => [
          _createElementVNode("span", _hoisted_1, [
            _createElementVNode("span", null, _toDisplayString(data.label), 1 /* TEXT */),
            _createElementVNode("span", {
              onClick: $event => ($setup.setHome(node, data, $event)),
              class: _normalizeClass({ homeContainer: true, isHome: data.defaultHomeField })
            }, [
              _createVNode(_component_el_icon, { size: 20 }, {
                default: _withCtx(() => [
                  _createVNode($setup["HomeFilled"])
                ]),
                _: 1 /* STABLE */
              })
            ], 10 /* CLASS, PROPS */, _hoisted_2),
            _createElementVNode("span", {
              class: _normalizeClass({ favoriteContainer: true, isFavorite: data.favoriteField }),
              onClick: $event => ($setup.setFavorite(node, data, $event))
            }, [
              _createVNode(_component_el_icon, { size: 20 }, {
                default: _withCtx(() => [
                  _createVNode($setup["StarFilled"])
                ]),
                _: 1 /* STABLE */
              })
            ], 10 /* CLASS, PROPS */, _hoisted_3)
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["data", "default-expanded-keys", "current-node-key"]))
    : _createCommentVNode("v-if", true)
}