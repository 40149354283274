import cloneDeep from 'lodash/cloneDeep';
import type Handsontable from 'handsontable';

import type {Column, FormatColumns, TreeItemData, TreeToListItem} from '../type';

import {exportFile} from './exportFile';
import {getRenderer} from './customStylesRenderer';

export function tree2list(treeData:FormatColumns[], list:FormatColumns[]) {
	for (const item of treeData) {
		const itemCopy = cloneDeep(item);
		const {children} = itemCopy;
		list.push(itemCopy);
		if (children?.length) {
			tree2list(children, list);
		}
	}
}
function getMaxFool(tree:Column[]) {
	let max = 0;
	function loop(data:Column[], floor:number) {
		for (const item of data) {
			if (floor > max) {
				max = floor;
			}
			if (item.children?.length) {
				loop(item.children, floor + 1);
			}
		}
	}
	loop(tree, 1);
	return max;
}
export function formatColumns(columns: FormatColumns[]) {
	const list:FormatColumns[] = [...columns];
	for (const item of list) {
		item.row = 0;
	}
	let lastRow = 0;
	let col = 0;
	while (list.length) {
		const top = list.shift();
		if (!top) {
			continue;
		}
		if (top.row !== lastRow) {
			col = top.startCol || 0;
		}
		lastRow = top.row || 0;
		top.col = col;
		top.maxLevel = top.maxLevel ? top.maxLevel : getMaxFool([top]);
		const leafs = findLeafNodes([top], top.field);
		if (top!.children) {
			for (const child of top!.children) {
				child.parentId = top.field;
				child.startCol = col;
				child.maxLevel = getMaxFool([top]) - 1;
				child.row = (top.row || 0) + 1;
				list.push(child);
			}
		}
		col += (leafs.length || 0);
	}
}
export function getWidths(originCols:Column[]) {
	return originCols.map(item=>item.width);
}
export function findNodeInTree<T extends Column>(tree:T[], target:string):T|null {
	for (const node of tree) {
		if (node.field === target) {
			return node;
		}
		if (node.children?.length) {
			const foundNode = findNodeInTree(node.children, target);
			if (foundNode) {
				return foundNode;
			}
		}
	}
	return null;
}
export function findLeafNodes<T extends Column>(tree:T[], target:string) {
	const targetNode = findNodeInTree(tree, target);
	if (!targetNode) {
		return [];
	}
	const leafNodes:T[] = [];
	function traverse(node:T) {
		if (!node.children || node.children.length === 0) {
			leafNodes.push(node);
		} else {
			for (const child of node.children) {
				traverse(child);
			}
		}
	}
	traverse(targetNode);
	return leafNodes;
}

const Align = {
	lft: 'htLeft',
	left: 'htLeft',
	rgt: 'htRight',
	right: 'htRight',
	center: 'htCenter',
	top: 'htTop',
	middle: 'htMiddle',
	bottom: 'htBottom',
};
export const column:Column[] = [
	{
		headerName: __('Boq Item Name'),
		field: 'boq_item_full_name',
		readonly: true,
		align: ['left', 'middle'],
		width: 330,
		bgColor: '#f5f7fa',
	},
	{
		headerName: __('Boq Item Type'),
		field: 'boq_type_name',
		readonly: true,
		align: ['center', 'middle'],
		width: 150,
		bgColor: '#f5f7fa',
	},
	{
		headerName: __('Unit'),
		field: 'unit_name',
		readonly: true,
		align: ['center', 'middle'],
		width: 100,
		bgColor: '#f5f7fa',
	},
	{
		/** 清单对上工程量 */
		headerName: __('Design Quantity'),
		field: 'top_quantity',
		readonly: true,
		precision: 4,
		type: 'numeric',
		align: ['right', 'middle'],
		width: 120,
		bgColor: '#f5f7fa',
	},
	{
		/** 本月对上工程量的开累完成 */
		headerName: __('Last Month Top Quantity Accumulated Completion'),
		field: 'top_accumulated_completion_quantity',
		readonly: true,
		precision: 4,
		type: 'numeric',
		align: ['right', 'middle'],
		width: 150,
		bgColor: '#f5f7fa',
	},
	{
		headerName: __('Last Month Accumulated Top Quantity Completion Proportion'),
		field: 'top_proportion',
		readonly: true,
		align: ['right', 'middle'],
		width: 100,
		bgColor: '#f5f7fa',
	},
	{
		/** 本月对上完成量 */
		headerName: __('Current Month Top Completed Quantity'),
		field: 'top_completed_quantity',
		editable: true,
		align: ['right', 'middle'],
		precision: 4,
		type: 'numeric',
		width: 120,
	},
	{
		/** 图纸量 */
		headerName: __('Drawing Quantity'),
		field: 'drawing_quantity',
		readonly: true,
		precision: 4,
		type: 'numeric',
		align: ['right', 'middle'],
		width: 120,
		bgColor: '#f5f7fa',
	},
	{
		/** 本月实际量的开累完成 */
		headerName: __('Last Month Actual Quantity Accumulated Completion'),
		field: 'drawing_accumulated_completed',
		readonly: true,
		precision: 4,
		type: 'numeric',
		align: ['right', 'middle'],
		width: 150,
		bgColor: '#f5f7fa',
	},
	{
		headerName: __('Last Month Actual Quantity Accumulated Completion Proportion'),
		field: 'drawing_percent_complete',
		readonly: true,
		align: ['right', 'middle'],
		type: 'numeric',
		bgColor: '#f5f7fa',
		width: 150,
	},
	{
		/** 本月实际完成量 */
		headerName: __('Current Month Actual Completed Quantity'),
		field: 'quantity',
		editable: true,
		align: ['right', 'middle'],
		precision: 4,
		type: 'numeric',
		width: 120,
	},
	{
		/** 结算量 */
		headerName: __('Current Month Closing Quantity'),
		field: 'closing_quantity',
		editable: true,
		align: ['right', 'middle'],
		precision: 4,
		type: 'numeric',
		width: 120,
	},
	{
		headerName: __('Remark'),
		field: 'remark',
		editable: true,
		align: ['left', 'middle'],
		width: 450,
	},
];
function getColumnProperty(cols:any[]) {
	return cols.map(item=>{
		const formatCols:any = {
			data: item.field,
			summary: item.summary,
			rowSummary: item.rowSummary,
			source: item.source,
			type: item.type,
			precision: item.precision,
		};
		formatCols.renderer = getRenderer(item.type || 'text');
		if (item.type === 'numeric') {
			const pattern = `0,0.${new Array(item.precision || 2).fill('0').join('')}`;
			formatCols.numericFormat = {pattern};
		}
		return formatCols;
	});
}
function getCells(originCols:FormatColumns[]) {
	const list:FormatColumns[] = [];
	tree2list(originCols, list);
	return function(row:number, col:number, prop: string) {
		const cellProperties:Record<string, any> = {};
		let cellDefine = list.find(item=>item.row === row && item.col === col);
		cellDefine = cellDefine || list.find(item=> item.col === col && !item.children?.length);
		if (!cellDefine) {
			return cellProperties;
		}
		// @ts-ignore
		const rowData = this.instance.getSourceDataAtRow(row);
		const text = rowData?.[cellDefine.field];
		cellProperties.readOnly = cellDefine.readonly;
		cellProperties.bgColor = cellDefine.bgColor;
		cellProperties.color = 'black';
		// 末级清单行可编辑
		if (['remark', 'quantity', 'actual_completed_quantity', 'top_completed_quantity', 'closing_quantity'].includes(prop) && !rowData?.is_boq) {
			cellProperties.editor = false;
			cellProperties.bgColor = '#f5f7fa';
		}
		// 设置对其方式
		if (cellDefine.align) {
			const aligns = typeof cellDefine.align === 'function' ? cellDefine.align(row, rowData, text) : cellDefine.align;
			const className = aligns.map(item => Align[item]).join(' ');
			cellProperties.className = `${cellProperties.className || ''} ${className}`;
		}
		// 设置表头样式;
		if (rowData?.__type__ === 'headerData') {
			cellProperties.bold = 'bold';
			cellProperties.height = '35px';
			cellProperties.fontSize = '14px';
			cellProperties.bgColor = '#999999';
			cellProperties.color = '#ffffff';
			cellProperties.className = 'htCenter htMiddle';
		}
		return cellProperties;
	};
}
export function getHeader(root:Column[]) {
	if (root.length === 0) {
		return {columns: []};
	}
	const list = cloneDeep(root) as Required<FormatColumns>[];
	const originCols = list.flatMap(item=>findLeafNodes([item], item.field));
	const columns = getColumnProperty(originCols);
	const widths = getWidths(originCols);
	formatColumns(list);
	const cells = getCells(list);
	const headerData = [];
	let lastRow = 0;
	let rowHeader:Record<string, any> = {__type__: 'headerData'};
	while (list.length) {
		const top = list.shift();
		if (!top) {
			continue;
		}
		if (top.row !== lastRow) {
			headerData.push(rowHeader);
			rowHeader = {__type__: 'headerData'};
		}
		lastRow = top.row || 0;
		const leafs = findLeafNodes([top], top.field);

		for (const leaf of leafs) {
			rowHeader[leaf.field] = top.headerName;
		}
		if (top!.children) {
			for (const child of top!.children) {
				list.push(child as Required<FormatColumns>);
			}
		}
	}
	headerData.push(rowHeader);
	const dataSchema = Object.fromEntries((columns || []).map(item=>([item.data])));
	const hasSummaryRow = columns?.some(item=>item.summary);
	return {
		headerData,
		columns,
		cells,
		widths,
		dataSchema,
		hasSummaryRow,
	};
}
/**
 * 构建树结构
 * @param listData
 * @param parentField
 * @param keyField
 * @returns
 */
function list2Tree<T extends TreeToListItem>(listData: T[], parentField: string, keyField = 'name') {
	const listDataCopy:T[] = JSON.parse(JSON.stringify(listData));
	const treeData: (T & TreeItemData)[] = [];
	const map:Record<string, any> = {};
	for (const item of listDataCopy) {
		map[item[keyField]] = item;
	}
	for (const item of listDataCopy) {
		const parent = map[item[parentField] || 0];
		if (parent) {
			(parent.__children || (parent.__children = [])).push(item);
		} else {
			treeData.push(item);
		}
	}
	return treeData;
}
/**
 * 增加缩紧
 * @param data
 * @param level
 * @returns
 */
function flattenData(data:any[], level = 0) {
	return data.reduce((acc, item) => {
		const prefix = ' '.repeat(level * 5);
		acc.push({
			name: item.name,
			boq_item_full_name: prefix + item.boq_item_full_name,
			boq_item_name: item.boq_item_name,
			boq_type_name: item.boq_type_name,
			parent_guigu_boq_item: item.parent_guigu_boq_item,
			is_boq: item.is_boq,
			top_quantity: item.top_quantity,
			top_accumulated_completion_quantity: item.top_accumulated_completion_quantity,
			top_proportion: item.top_proportion,
			top_completed_quantity: item.top_completed_quantity,
			drawing_quantity: item.drawing_quantity,
			drawing_accumulated_completed: item.drawing_accumulated_completed,
			drawing_percent_complete: item.drawing_percent_complete,
			quantity: item.quantity,
			closing_quantity: item.closing_quantity,
			unit_name: item.unit_name,
			remark: item.remark,
		});
		if (item.__children && item.__children.length > 0) {
			acc.push(...flattenData(item.__children, level + 1));
		}
		return acc;
	}, []);
}
/**
 * 将数据转换为树形结构
 * @param data
 * @param parentField
 * @param keyField
 * @returns treeData
 */
export function setData(data: [], parentField: string, keyField = 'name') {
	let treeData = list2Tree(data, parentField, keyField);
	treeData = flattenData(treeData);
	treeData = list2Tree(treeData, parentField, keyField);
	return treeData;
}
export function getMenus(exportTitle?:string) {
	const items:Record<string, any> = {
		export: {
			name: __('Export'),
			callback() {
				exportFile(this, exportTitle || 'export');
			},
		},
	};
	return {items};
}
