/**
 * 泳道节点
 */
import {h} from '@logicflow/core';
import {GroupNode} from '@logicflow/extension';

const laneMinSize = {
	width: 100,
	height: 300,
};

class HorizontalLaneModel extends GroupNode.model {
	initNodeData(data) {
		super.initNodeData(data);
		const width = 300;
		const height = 500;
		this.width = data.properties?.nodeSize?.width as number || width;
		this.height = data.properties?.nodeSize?.height as number || height;
		this.foldedWidth = 42;
		this.resizable = true;
		this.zIndex = 1;
		this.text.editable = true;
	}

	setAttributes() {
		this.text = {
			...this.text,
			value: this.text.value || '泳池',
			x: this.x,
			y: this.y - this.height / 2 + 15,
		};
	}


	getTextStyle() {
		const style = super.getTextStyle();
		style.textWidth = 16;
		return style;
	}

	foldGroup(isFolded) {
		this.setProperty('isFolded', isFolded);
		this.isFolded = isFolded;
		// step 1
		if (isFolded) {
			this.x = this.x - this.width / 2 + this.foldedWidth / 2;
			this.unfoldedWidth = this.width;
			this.unfoldedHight = this.height;
			this.width = this.foldedWidth;
		} else {
			this.width = this.unfoldedWidth;
			this.x = this.x + this.width / 2 - this.foldedWidth / 2;
		}
		// step 2
		let allEdges = this.incoming.edges.concat(this.outgoing.edges);
		for (const elementId of this.children) {
			const nodeModel = this.graphModel.getElement(elementId);
			nodeModel.visible = !isFolded;
			allEdges = allEdges.concat(nodeModel.incoming.edges.concat(nodeModel.outgoing.edges));
		}
		// step 3
		this.foldEdge(isFolded, allEdges);
	}
	// 感应泳道变化，调整宽高
	resize(resizeId?:strring, newNodeSize?:{y:number, height:number}) {
		if (!this.children.size) {
			return;
		}
		let minX = null;
		let maxX = null;
		let minY = null;
		let maxY = null;
		const hasMaxX = false;
		let hasMaxY = false;
		// 找到边界
		for (const elementId of this.children) {
			const nodeModel = this.graphModel.getElement(elementId);
			const {x, y, width, height, type, id} = nodeModel;
			if (type !== 'lane') {
				continue;
			}
			if (!minX || (x - width / 2 < minX)) {
				minX = x - width / 2;
			}
			if (!maxX || (x + width / 2 > maxX)) {
				maxX = x + width / 2;
			}
			// pool 的宽度按照调整的lane 的高度来算
			if (id === resizeId) {
				minY = newNodeSize!.y - newNodeSize!.height / 2;
				maxY = newNodeSize!.y + newNodeSize!.height / 2;
				hasMaxY = true;
			}

			if (!hasMaxY && (!minY || (y - height / 2 < minY))) {
				minY = y - height / 2;
			}
			if (!hasMaxY && (!maxY || (y + height / 2 > maxY))) {
				maxY = y + height / 2;
			}
		}
		if (minX && maxX && minY && maxY) {
			this.width = maxX - minX;
			this.height = maxY - minY + 30;
			this.x = minX + (maxX - minX) / 2;
			this.y = minY + (maxY - minY) / 2 - 15;
			this.setAttributes();
			this.resizeChildren({});
		}
	}

	resizeChildren({resizeDir = '', deltaWidth = 0}) {
		const {x, y, height} = this;
		const laneChildren = [];
		for (const elementId of this.children) {
			const nodeModel = this.graphModel.getElement(elementId);
			const {type} = nodeModel;
			if (type === 'lane') {
				laneChildren.push(nodeModel);
			}
		}
		// 按照位置排序
		laneChildren.sort((a, b) => {
			if (a.x < b.x) {
				return -1;
			}
			return 1;
		});
		// 把泳池resize的宽度加进来
		switch (resizeDir) {
			case 'left':{
				// 宽度加在最左边的泳道上
				const [firstLane] = laneChildren;
				firstLane.width = firstLane.width + deltaWidth < laneMinSize.width ? laneMinSize.width : firstLane.width + deltaWidth;
				laneChildren[0] = firstLane;
				break;
			}
			case 'right':{
				// 宽度加在最右侧的泳道上
				const lastLane = laneChildren[laneChildren.length - 1];
				lastLane.width = lastLane.width + deltaWidth < laneMinSize.width ? laneMinSize.width : lastLane.width + deltaWidth;
				laneChildren[laneChildren.length - 1] = lastLane;
				break;
			}
			default: break;
		}
		const poolWidth = laneChildren.reduce((a, b) => a + b.width, 0);
		let aboveNodeWidths = 0;
		for (const [index, nodeModel] of laneChildren.entries()) {
			const {width} = nodeModel;
			nodeModel.changeAttribute({
				width: width,
				height: height - 30,
				x: x - poolWidth / 2 + aboveNodeWidths + width / 2,
				y: y + 15,
			});
			nodeModel.text.x = x - poolWidth / 2 + aboveNodeWidths + width / 2;
			nodeModel.text.y = y + 15 - (height - 30) / 2 + 15;
			aboveNodeWidths += width;
		}
		this.width = poolWidth;
	}

	addChild (childId:string) {
		super.addChild(childId);
		this.graphModel.group.nodeGroupMap?.set(childId, this.id);
	}

	addChildRight({x, y, width, height}:{x:number, y:number, width:number, height:number}) {
		for (const elementId of this.children) {
			const nodeModel = this.graphModel.getElement(elementId);
			const {type, x: childX} = nodeModel;
			if (type !== 'lane') {
				continue;
			}
			// 在被操作的泳道右边
			if (childX > x) {
				nodeModel.changeAttribute({x: childX + 300});
			}
		}
		const {id: laneId} = this.graphModel.addNode({
			type: 'lane',
			properties: {
				nodeSize: {
					width: 300,
					height: height,
				},
			},
			x: x + width / 2 + 150,
			y,
		});
		this.addChild(laneId);
		this.width += 300;
		this.x += 150;
		this.resize();
	}

	addChildLeft({x, y, width, height}:{x:number, y:number, width:number, height:number}) {
		for (const elementId of this.children) {
			const nodeModel = this.graphModel.getElement(elementId);
			const {type, x: childX} = nodeModel;
			if (type !== 'lane') {
				continue;
			}
			// 在被操作的泳道左边
			if (childX < x) {
				nodeModel.changeAttribute({x: childX - 300});
			}
		}
		const {id: laneId} = this.graphModel.addNode({
			type: 'lane',
			properties: {
				nodeSize: {
					width: 300,
					height,
				},
			},
			x: x - width / 2 - 150,
			y,
		});
		this.addChild(laneId);
		this.width += 300;
		this.x -= 150;
		this.resize();
	}

	deleteChild(childId:string) {
		const laneChildren = [];
		for (const elementId of this.children) {
			const nodeModel = this.graphModel.getElement(elementId);
			const {type} = nodeModel;
			if (type === 'lane') {
				laneChildren.push(nodeModel);
			}
		}
		// if (laneChildren.length <= 1) {
		// 	return;
		// }
		this.removeChild(childId);
		this.graphModel.deleteNode(childId);
		this.graphModel.deleteNode(this.id);
		this.resize();
	}
}

class HorizontalLaneView extends GroupNode.view {
	getResizeShape() {
		const {model} = this.props;
		const {x, y, width, height} = model;
		const style = model.getNodeStyle();
		// 标题区域
		const foldRectAttrs = {
			...style,
			x: x - width / 2,
			y: y - height / 2,
			width,
			height: 30,
			stroke: '#187dff',
		};
		// 泳道区域
		const transRectAttrs = {
			...style,
			x: x - width / 2,
			y: y - height / 2 + 30,
			width: width,
			height: height - 30,
			fill: 'transparent',
			stroke: '#999',
		};
		return h('g', {}, [
			// this.getAddAbleShape(),
			h('rect', {...foldRectAttrs}),
			h('rect', {...transRectAttrs}),
			this.getFoldIcon(),
		]);
	}
}

const PoolNode = {
	type: 'pool',
	view: HorizontalLaneView,
	model: HorizontalLaneModel,
};

export default PoolNode;
