import mountVueToPage from 'guiguLib/mountVueToPage';

import Index from './index.vue';
frappe.provide('frappe.guigu_material');

function renderMainMaterialPage (wrapper) {
	mountVueToPage(wrapper, [
		{path: '/:pathMatch(.*)*', component: Index},
	], {
		base: '/app/main_material_verification_and_disposal_statistics',
	});
}
frappe.guigu_material.pages = frappe.guigu_material.pages || {};
frappe.guigu_material.pages.renderMainMaterialPage = renderMainMaterialPage;
