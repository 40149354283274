<template>
	<div class="label">{{ tt('Filter By') }}</div>
	<Item
		:doctype="meta.name" :title="tt('Assigned To')"
		field="_assign" type="Link" options="User"
		isLike
		v-model:filters="filter" :allFilters="allFilters" />
	<Item
		:doctype="meta.name" :title="tt('Created By')"
		field="owner" type="Link" options="User"
		v-model:filters="filter" :allFilters="allFilters" />
	<Item
		v-for="f in groupFields" :key="f.name"
		:doctype="f.parent || meta.name" :title="tt(f.label)"
		:field="f.fieldname" :type="f.fieldtype" :options="f.options"
		v-model:filters="filter" :allFilters="allFilters" />
	<Edit :meta="meta" v-model="groupFieldNames" />
	<Item
		:doctype="meta.name" :title="tt('Label')"
		field="_user_tags" type="Data" isStats isLike
		v-model:filters="filter" :allFilters="allFilters" />
	<div class="end"></div>
</template>
<script setup lang="ts">
import {computed, ref} from 'vue';

import {Filter} from '../types';

import Item from './Item.vue';
import Edit from './Edit.vue';

defineOptions({name: 'FilterSelect', inheritAttrs: false});
const props = defineProps<{
	meta: locals.DocType;
	filters: Filter[];
	allFilters: any;
}>();
const emit = defineEmits<{
	(event: 'update:filters', filters: Filter[]): void
}>();
const tt = __;
const user_settings = frappe.get_user_settings(props.meta.name);
const groupFieldNames = ref<string[]>(user_settings?.group_by_fields as any || []);
const groupFields = computed(() => groupFieldNames.value
	.map(f => props.meta.fields.find(v => v.fieldname === f)!)
	.filter(Boolean)
	.filter(f => ['Select', 'Link', 'Data', 'Int', 'Check'].includes(f.fieldtype)));

const filter = computed({
	get: () => props.filters,
	set: v => emit('update:filters', v),
});

</script>
<style scoped lang="less">
.label {
	margin-bottom: var(--margin-sm);
	font-weight: 500;
	display: flex;
	align-items: center;
	color: var(--text-muted);
	letter-spacing: .04rem;
	font-size: var(--text-sm);
}

.end {
	margin-block-end: 30px;
}
</style>
