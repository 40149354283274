import type { ColDef, ColGroupDef, ICellEditorParams } from 'ag-grid-community';

import * as Permission from '../../Permission';
import type { Assess, Permissions } from '../type';

import Action from './AgGridAction.vue';

export function getColumns(
	gotoDetail: (data: Assess) => void,
	gotoInterview: (data: Assess) => void,
	permission: Permission.DocPermission | null,
	smallMeta: boolean,
): (ColDef | ColGroupDef)[] {
	return [
		{
			headerName: __('Person Name'),
			field: 'full_name',
			enableRowGroup: false,
			editable: false,
			filter: false,
			minWidth: 150,
		},
		{
			headerName: __('Department'),
			field: 'organization',
			enableRowGroup: true,
			minWidth: 65,
			editable: false,
			filter: false,
			rowGroup: true,
		},
		{
			headerName: __('Designation'),
			field: 'job',
			minWidth: 65,
			enableRowGroup: true,
			editable: false,
			filter: false,
		},
		{
			headerName: __('Score Value'),
			cellStyle: { 'text-align': 'right' },
			field: 'all_score',
			minWidth: 65,
			enableRowGroup: false,
			editable: false,
			filter: false,
		},
		{
			headerName: __('Self Rated Total Score'),
			cellStyle: { 'text-align': 'right' },
			field: 'self_score',
			minWidth: 120,
			enableRowGroup: false,
			editable: false,
			filter: false,
		},
		{
			headerName: __('Total Score Of Leadership Rating'),
			cellStyle: { 'text-align': 'right' },
			field: 'leader_score',
			minWidth: 120,
			enableRowGroup: false,
			editable: false,
			filter: false,
		},
		{
			headerName: __('Total Additional Points'),
			cellStyle: { 'text-align': 'right' },
			field: 'extra_score',
			minWidth: 100,
			enableRowGroup: false,
			editable: false,
			filter: false,
		},
		{
			headerName: __('Total Score'),
			cellStyle: { 'text-align': 'right' },
			field: 'total_score',
			cellDataType: false,
			minWidth: 65,
			enableRowGroup: false,
			editable: false,
			filter: false,
		},
		{
			headerName: __('Level'),
			field: 'level',
			minWidth: 65,
			enableRowGroup: true,
			editable: Boolean(permission?.modifiable.has('level')),
			filter: false,
			cellEditor: 'agSelectCellEditor',
			cellEditorParams: {
				values: ['', 'A', 'B+', 'B', 'C'],
				popup: true,
			},
		},
		{
			headerName: __('Actions'),
			width: 130,
			minWidth: 130,
			pinned: smallMeta ? undefined : 'right',
			enableRowGroup: false,
			filter: false,
			sortable: false,
			editable: false,
			menuTabs: [],
			cellRenderer: Action,
			cellRendererParams: (params: ICellEditorParams) => ({
				gotoDetail,
				gotoInterview,
			}),
		},
	];
}
