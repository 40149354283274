import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f6cb2b45"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "detail-width" }
const _hoisted_2 = { class: "detail-container" }
const _hoisted_3 = { class: "label" }
const _hoisted_4 = { class: "value" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString($setup.tt('Description')) + ": ", 1 /* TEXT */),
      _createElementVNode("span", _hoisted_4, [
        _createElementVNode("p", { innerHTML: $props.description }, null, 8 /* PROPS */, _hoisted_5)
      ])
    ])
  ]))
}