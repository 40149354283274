import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aafa2c3b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["xlink:href", "fill"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass($setup.classList),
    "aria-hidden": "true"
  }, [
    _createElementVNode("use", {
      "xlink:href": $setup.iconName,
      fill: $props.color
    }, null, 8 /* PROPS */, _hoisted_1)
  ], 2 /* CLASS */))
}