import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fileList, (file) => {
    return (_openBlock(), _createBlock($setup["UploadFormItem"], {
      ref_for: true,
      ref: $setup.setItemRef,
      photo: file,
      structures: $setup.structures,
      date: $setup.date,
      showDateInput: $setup.showDateInput,
      defaultStructureId: $setup.defaultStructureId,
      onDeleteFile: $setup.deleteFile
    }, null, 8 /* PROPS */, ["photo", "structures", "date", "showDateInput", "defaultStructureId"]))
  }), 256 /* UNKEYED_FRAGMENT */))
}