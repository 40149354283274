import FormDetail from '../FormDetail';
import defineView from '../extends/init/defineView';
import View from '../GlobalView/index.vue';

frappe.provide('frappe.guigu.GlobalView.details');
frappe.guigu.GlobalView.details.form = FormDetail;
frappe.provide('frappe.guigu.GlobalView.settings');


defineView('main', View, {
	title: '综合视图',
	link: { filter: true },
});
