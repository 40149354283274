frappe.ui.form.ControlTableMultiSelect.prototype.custom_awesomplete_filter = function custom_awesomplete_filter(awesomplete) {
	let me = this;

	awesomplete.filter = function (item) {
		let { value } = item;
		if (me.get_options() === 'User' && value === 'ME') {
			value = frappe.user.name;
		}
		if (in_list(me._rows_list, value)) {
			return false;
		}

		return true;
	};
};
