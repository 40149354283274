import { ref } from 'vue';
import { defineStore } from 'pinia';

import { Config, FileViewConfig } from '../type/index';

export const useFileViewStore =  defineStore('Guigu:GlobalView:FileView', ()=> {
	const showMode = ref<'iconMode'|'tableMode'>('iconMode');
	const createdFolder = ref<string>('');
	const updatedFolder = ref<string>('');
	function changeMode(){
		showMode.value = showMode.value === 'iconMode'?'tableMode':'iconMode';
	}
	function setCreatedFolder(value:string){
		createdFolder.value = value;
	}
	function setUpdatedFolder(value:string){
		updatedFolder.value = value;
	}

	return {
		showMode,
		changeMode,
		createdFolder,
		setCreatedFolder,
		updatedFolder,
		setUpdatedFolder,
	};
});
