<template>
	<Timeline
		ref="timeLine"
		:mode="smallMeta ? 'right' : 'left'"
		v-loading="loading"
		@loadMore="loadMore">
		<template #default="{ mode }">
			<DiaryTimelineItem
				:mode="mode"
				v-for="diaryRecord in diaryList"
				@show-daily-record="v=>emit('showDailyRecord', v)"
				:filterOrganization="filterOrganization"
				:searchString="searchString"
				:dailyRecords="diaryRecord"
				:showComment="showComment">
			</DiaryTimelineItem>
		</template>

	</Timeline>
</template>
<script lang="ts" setup>
import {computed, ref, watch, onMounted, onUnmounted} from 'vue';
import moment from 'moment';

import Timeline from '../../../../../../../guigu_pm/guigu_pm/public/js/components/timeline/Timeline.vue';

import {buildData, buildSearchData, generateDay, getQueryParams} from './helper';
import type {DiaryRecord, SubOrganization, TimelineList, UserDailyRecords, User} from './type';
import DiaryTimelineItem from './DiaryTimelineItem.vue';
import {useMetaQuery} from './useMetaQuery';
import useIntersection from './useIntersection';
const smallMeta = useMetaQuery();
const timeLine = ref<{ readonly el?: HTMLElement }>();
useIntersection(computed(() => timeLine.value?.el));

const today = moment(new Date()).add(1, 'days').format('YYYY-MM-DD');
interface Props {
	filterOrganization?: string,
	organization?: SubOrganization,
	date?: string,
	subOrganizations: SubOrganization[]
	searchString?: string
	showComment: boolean
}
const props = defineProps<Props>();
interface Emits {
	(e: 'showDailyRecord', name: string): void
}
const emit = defineEmits<Emits>();
const loading = ref<boolean>(false);
const page = ref<number>(1);
const resultData = ref<Record<string, UserDailyRecords[]>[]>([]);
const searchMode = ref<boolean>(false);
async function loadDiaryRecords() {
	if (!props.filterOrganization || !props.organization || !props.date) {
		resultData.value = [];
		return;
	}
	loading.value = true;
	let userList: User[] = [];
	if (props.organization.type === 'department' && props.organization.members) {
		// 如果是部门，直接使用部门成员列表
		userList = props.organization.members.map(userId => ({
			name: userId,
			// 这里可能需要从 props.subOrganizations 中获取用户的完整信息
			full_name: props.subOrganizations.find(item =>
				item.type === 'member'
				&& item.user?.name === userId)?.user?.full_name || userId,
		}));
	} else {
		// 使用原有的查询逻辑
		const {recordOwners} = getQueryParams(props.organization, props.date, page.value, props.subOrganizations);
		userList = recordOwners;
	}
	const {endDateParam, startDateParam, dayCount, batch}
		= getQueryParams(props.organization, props.date, page.value, props.subOrganizations);
	if (userList.length === 0) {
		resultData.value = []; loading.value = false; return;
	}
	const filters: Record<string, [string, any]>[] = [
		{
			organization: ['=', props.filterOrganization],
		},
		{
			record_date: ['<=', page.value === 1 ? props.date || today : startDateParam],
		},
		{
			record_owner: ['in', Array.from(new Set(userList.map(item => item.name)))],
		},
	];
	const arg: Record<string, any> = {};
	if (searchMode.value) {
		filters.push({
			description: ['like', `%${props.searchString}%`],
		});
		arg.filters = filters;
		arg.limit = 20;
		arg.page = page.value;
	} else {
		filters.push({
			record_date: ['>=', endDateParam],
		});
		arg.filters = filters;
	}
	const data = await frappe.call<{ message: { diary_records: DiaryRecord[] } }>({
		method: 'dw_worklist_management.dw_daily_record.page.dw_organization_daily_record.dw_organization_daily_record.get_diary_records',
		args: arg,
	});
	if (searchMode.value) {
		const {dailyRecordList} = buildSearchData(data?.message.diary_records || []);
		if (page.value === 1) {
			resultData.value = dailyRecordList;
		} else {
			resultData.value = resultData.value.concat(dailyRecordList);
		}
		loading.value = false;
		return;
	}
	const dateArr = batch === 0 ? generateDay(dayCount, startDateParam) : [];
	const {dailyRecordList, dailyRecordListHash} = buildData(dateArr, data?.message.diary_records || [], userList);
	if (page.value === 1) {
		resultData.value = dailyRecordList;
	} else if (dateArr.length) {
		resultData.value = resultData.value.concat(dailyRecordList);
	} else {
		const authorDailyArr: UserDailyRecords[] = userList.map(author => ({
			userId: author.name,
			user: author,
			dailyRecords: dailyRecordListHash[`${author.name}${endDateParam}`] || [],
		}));
		const newArr = [...resultData.value];
		const oldObj = newArr.pop()!;
		const newObj: any = {};
		const [key] = Object.keys(oldObj);
		newObj[key] = [...oldObj[key], ...authorDailyArr];
		resultData.value = [...newArr, newObj];
	}
	loading.value = false;
}
function searchDiary(searchModeProps: boolean) {
	searchMode.value = searchModeProps;
	page.value = 1;
	loadDiaryRecords();
}
defineExpose({
	searchDiary,
});
function loadMore() {
	page.value++;
	loadDiaryRecords();
}
const diaryList = computed(() => {
	const arr: TimelineList[] = [];
	for (const item of resultData.value) {
		const [date] = Object.keys(item);
		for (const [index, each] of item[date].entries()) {
			if (!each.dailyRecords || each.dailyRecords.length === 0) {
				arr.push({
					user: each.user,
					type: 'none',
					left: index === 0 ? 'show' : 'none',
					date,
				});
			}
			for (const every of each.dailyRecords) {
				arr.push({
					user: each.user,
					type: 'user',
					data: every,
					left: index === 0 ? 'show' : 'none',
					date,
				});
			}
		}
	}
	return arr;
});
watch([() => props.filterOrganization, () => props.date, () => props.organization], () => {
	page.value = 1;
	loadDiaryRecords();
}, {immediate: true});

frappe.realtime.on('doc_update', p => {
	if (p.doctype !== 'Dw Daily Record') {
		return;
	}
	page.value = 1;
	loadDiaryRecords();
});
const popstateListener = function () {
	page.value = 1;
	loadDiaryRecords();
};
onMounted(() => {
	window.addEventListener('popstate', popstateListener);
});
onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});

</script>
<style scoped>
</style>
