import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElDatePicker = _resolveComponent("ElDatePicker")
  const _component_ElConfigProvider = _resolveComponent("ElConfigProvider")

  return (_openBlock(), _createBlock(_component_ElConfigProvider, { locale: _ctx.zh }, {
    default: _withCtx(() => [
      _createVNode(_component_ElDatePicker, {
        modelValue: _ctx.date,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.date) = $event)),
        type: "date",
        valueFormat: "YYYY-MM-DD",
        placeholder: _ctx.tt('Please select date'),
        size: "small",
        onChange: _ctx.stopEditing
      }, null, 8 /* PROPS */, ["modelValue", "placeholder", "onChange"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["locale"]))
}