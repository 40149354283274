
import { computed, ComputedRef, ref, watch } from 'vue';

import type { Filter as FilterType, Kanban } from '../types';

export default function useFilter(kanban: ComputedRef<Kanban | undefined>) {
	/******** 过滤器相关 ********/
	const currentFilters = ref<FilterType[]>();
	const defaultFilters = computed(() => kanban.value?.filters || []);
	let filterImmutable = false;
	function setFilterImmutable() {
		filterImmutable = true;
	}
	const filters = computed<FilterType[]>({
		get: () => currentFilters.value || defaultFilters.value,
		set: v => { currentFilters.value = v; },
	});
	watch(kanban, () => {
		if (!filterImmutable) {
			currentFilters.value = undefined;
		}
		filterImmutable = false;
	});

	const allFilters = computed(() => filters.value.map(({ doctype, field, condition, value }) => {
		if (Array.isArray(value)) {
			// eslint-disable-next-line no-param-reassign
			value = value.filter(v => v !== undefined && v !== null);
			if (!value.length) { return; }
		}
		if (value === undefined) { return; }
		if (condition) { return [doctype, field, condition, value]; }
		return [doctype, field, Array.isArray(value) ? 'in' : '=', value];
	}).filter(Boolean) as [string, string, string, any][]);

	return {
		currentFilters,
		filters,
		allFilters,
		setFilterImmutable,
	};

}
