export default class SingleWidgetGroup {
	container: HTMLElement;
	type: string;
	options: Record<string, any>;
	widgets: Record<string, any>;
	api: any;
	block: any;
	widgets_list: frappe.widget.widget_factory.base[] = [];
	widgets_dict: Record<string, frappe.widget.widget_factory.base> = {};
	factory: typeof frappe.widget.widget_factory.base;
	constructor(opts: {
		container: HTMLElement;
		type: string;
		options: Record<string, any>;
		widgets: Record<string, any>;
		api: any;
		block: any;
		factory: typeof frappe.widget.widget_factory.base
	}) {
		this.container = opts.container;
		this.type = opts.type;
		this.options = opts.options;
		this.widgets = opts.widgets;
		this.api = opts.api;
		this.block = opts.block;
		this.factory = opts.factory;
		this.make();
	}

	make() {
		this.add_widget(this.widgets);
	}

	add_widget(widget: Record<string, any>) {
		let widget_object: frappe.widget.widget_factory.base = new this.factory({
			...widget,
			widget_type: this.type,
			container: this.container,
			height: null,
			options: {
				...this.options,
				on_delete: () => this.on_delete(),
				on_edit: () => this.on_edit(widget_object),
			},
		});
		this.widgets_list.push(widget_object);
		this.widgets_dict[widget.name] = widget_object;

		return widget_object;
	}

	on_delete() {
		this.api.blocks.delete();
	}

	on_edit(widget_object: frappe.widget.widget_factory.base) {
		this.block.call("on_edit", widget_object);
	}

	customize() {
		this.widgets_list.forEach((wid) => {
			wid.customize(this.options);
		});
	}
}
