import type {LogicFlow} from '@logicflow/core';


const defaultControls = [
	{
		key: 'download',
		iconClass: 'lf-control-export',
		title: __('Export'),
		text: __('Export'),
		onClick(lf:LogicFlow) {
			lf.getSnapshot();
		},
	},
	// {
	// 	key:'editable',
	// 	iconClass: 'lf-control-mode',
	// 	title: '编辑/只读模式切换',
	// 	text:'切换模式',
	// 	onClick(lf: LogicFlow) {
	// 		lf.graphModel.editConfigModel.updateEditConfig({
	// 			isSilentMode: !lf.graphModel.editConfigModel.isSilentMode,
	// 		});
	// 	},
	// },

];

export default defaultControls;
