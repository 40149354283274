<template>
	<div class="toolbar">
		<div class="left-container">
			<Project
				v-if="type === 'project'"
				class="input"
				noLabel
				v-model="projectValue"
			></Project>
			<ElSelect
				v-else
				class="input"
				filterable
				:modelValue="departmentName"
				@change="changeType"
			>
				<ElOption
					v-for="item in departments"
					:key="item.name"
					:label="item.label"
					:value="item.name"
				/>
			</ElSelect>
			<ElDatePicker
				class="date-input"
				v-model="date"
				valueFormat="YYYY-MM-DD"
				type="month"
				:clearable="false"
			/>
		</div>
		<ElSpace
			v-if="exportable || creatable || hasLookPerm"
			class="btn-container"
		>
			<ElButton v-if="exportable" @click="exportList">{{
				tt('Export')
			}}</ElButton>
			<ElButton v-if="creatable" @click="create">{{
				tt('New Addition')
			}}</ElButton>
			<ElButton v-if="hasLookPerm" type="primary" @click="toggleLock">
				{{ lockStatus ? tt('Unlock') : tt('GuiguLock') }}
			</ElButton>
		</ElSpace>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ElMessage } from 'element-plus';

import type { ExportItem } from '../type';
import { getBinaryContent, exportDocx, zip } from '../../export/export';
import { useMetaQuery } from '../../../../../../../../guigu_pm/guigu_pm/public/js/components/page/useMetaQuery';
import Project from '../../../../../../../../guigu/guigu/public/js/components/PageComponents/Tools/Project.vue';

import { getExportData } from './getExportData';

const tt = __;
interface Props {
	type: 'project' | 'subCompany';
	departmentName?: string;
	month?: string;
	departments: { name: string; label: string }[];
	lockStatus: boolean;

	creatable: boolean;
	exportable: boolean;
	lockable: boolean;
	unlockable: boolean;
}
const props = defineProps<Props>();
interface Emit {
	(e: 'update:departmentName', project: string): void;
	(e: 'update:month', date?: string): void;
	(e: 'changeLock'): void;
	(e: 'create'): void;
}
const emit = defineEmits<Emit>();
const smallMeta = useMetaQuery();
const hasLookPerm = computed(() =>
	props.lockStatus ? props.unlockable : props.lockable,
);
const date = computed({
	get: () => props.month || '',
	set: (v: string) => {
		emit('update:month', v);
	},
});
function changeType(newTypeName: string) {
	emit('update:departmentName', newTypeName);
}
function create() {
	emit('create');
}
const projectValue = computed({
	get: () => props.departmentName || '',
	set: (v: string) => {
		emit('update:departmentName', v);
	},
});
async function exportList() {
	const templatePromise = getBinaryContent(
		'/assets/guigu_hr/files/month_template.docx',
	);

	const exportListPromise = frappe.call<{ message: ExportItem[] }>({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_project_assessment_list.guigu_hr_project_assessment_list.get_export_assessments',
		args: {
			month: props.month,
			type: props.type,
			department: props.departmentName,
		},
	});

	const interviewTemplatePromise = await getBinaryContent(
		'/assets/guigu_hr/files/interview_template.docx',
	);

	const [interviewTemplate, template, exportDataList] = await Promise.all([
		interviewTemplatePromise,
		templatePromise,
		exportListPromise,
	]);
	if (!exportDataList?.message.length) {
		return;
	}
	const assessDataList = getExportData(exportDataList?.message);
	const files: {
		user: string;
		userFolder: string;
		date: string;
		detail: any;
		interview: any;
	}[] = [];
	for (const assessData of assessDataList) {
		const detailOut = await exportDocx(template, assessData.detail);
		const interviewOut = await exportDocx(
			interviewTemplate,
			assessData.interview,
		);
		let userFolder = assessData.user_doc.full_name;
		if (files.some(item => item.user === assessData.user_doc.full_name)) {
			const users = files.filter(
				item => item.user === assessData.user_doc.full_name,
			);
			const userIDs = users.map(item => {
				const res = item.userFolder.match(/\(([0-9]+)\)$/);
				if (res) {
					let n = 1;
					if (!isNaN(parseInt(res[1] || '1'))) {
						n = parseInt(res[1] || '1');
					}
					return n;
				}
				return 0;
			});
			userFolder += `(${Math.max(...userIDs) + 1})`;
		}
		files.push({
			user: assessData.user_doc.full_name,
			userFolder: userFolder,
			date: assessData.date,
			detail: detailOut,
			interview: interviewOut,
		});
	}
	zip(files, 'month');
}

async function toggleLock() {
	const unlock = props.lockStatus;
	if (unlock ? !props.unlockable : !props.lockable) {
		return;
	}
	const url = [
		`guigu_hr.guigu_hr_assessment`,
		`page`,
		`guigu_hr_project_assessment_list`,
		`guigu_hr_project_assessment_list`,
		unlock ? 'unlock' : 'lock',
	].join('.');
	await frappe.call<{ message: ExportItem[] }>(url, {
		month: props.month,
		type: props.type,
		department: props.departmentName,
	});
	ElMessage({
		type: 'success',
		message: unlock ? __('Unlocked') : __('Locked'),
	});
	emit('changeLock');
}
const flexDirection = computed(() => (smallMeta.value ? 'column' : 'row'));
const margin = computed(() => (smallMeta.value ? '8px' : '0'));
</script>

<style lang="less" scoped>
.toolbar {
	display: flex;
	flex-direction: v-bind(flexDirection);
	justify-content: space-between;
}
.input {
	margin-right: 8px;
	margin-bottom: v-bind(margin);
}
:deep(.date-input) {
	margin-bottom: v-bind(margin);
}
</style>
