<template>
	<el-dialog
	  v-model="visible"
	  :title="tt('Picture details')"
	  :width="dialogWidth"
	  :before-close="handleClose"
	  custom-class="progress-dialog"
	>
		<ImageInfo
			ref="infoFormRef"
			:photo="photo"
			:structures="structureDescendants"
			:showDateInput="showDateInput"
			@deleteFile="deleteFile"
		></ImageInfo>
	  <template #footer>
		  <span class="dialog-footer">
			<ElButton @click="handleClose">{{ tt('Cancel') }}</ElButton>
			<ElButton type="primary" @click="confirm">
			  {{ tt('Confirm') }}
			</ElButton>
		  </span>
	  </template>
	</el-dialog>
  </template>
<script lang="ts" setup>
import { defineProps, defineEmits, toRefs, ref, onMounted, onUnmounted } from 'vue';
import { FormInstance } from 'element-plus/lib/components/index.js';

import { ImageFormValue, Photo, PhotoFormat} from '../../../type/image';
import { StructureItemFormat } from '../../../type/diary';
import {useMediaDialogQuery} from '../useMediaQuery';

import ImageInfo from './ImageInfo.vue';
const tt = __
const infoFormRef = ref<{formRef:FormInstance, formValue:ImageFormValue}>();
interface Props{
	visible:boolean,
	photo:PhotoFormat,
	structureDescendants:StructureItemFormat[],
	showDateInput?:boolean
}
interface Emits{
	(e:'close'):void
	(e:'confirm', photo:Photo):void
	(e:'delete', photo:PhotoFormat):void
}
const props = defineProps<Props>();
const {
	visible, structureDescendants, photo, showDateInput,
} = toRefs(props);
const emit = defineEmits<Emits>();

const dialogWidth = useMediaDialogQuery();

function deleteFile(){
	emit('delete', photo.value);
}
function confirm(){
	const formValue = infoFormRef?.value?.formValue;
	if (!formValue){ return; }
	type keys = 'guigu_construction_structure_progress'|'guigu_construction_structure'
	let targetKey:keys = 'guigu_construction_structure_progress';
	let clearKey:keys = 'guigu_construction_structure';
	if (formValue.target.startsWith('structure_progress_')){
		targetKey = 'guigu_construction_structure_progress';
		clearKey = 'guigu_construction_structure';
	} else {
		targetKey = 'guigu_construction_structure';
		clearKey = 'guigu_construction_structure_progress';
	}
	const targetArr = formValue.target.split('_');
	const target = targetArr[targetArr.length-1];
	const newPhoto:Photo={
		name:photo.value.id,
		[targetKey]:target,
		[clearKey]:'',
		date:formValue.date,
		description:formValue.description,
		image_name:formValue.name,
		photographer:formValue.photographer,
	};
	emit('confirm', newPhoto);
	emit('close');
}
function handleClose(){
	emit('close');
}
</script>
