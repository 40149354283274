import type { Column, Group } from './types';

function toString(v: any) {
	return ['string', 'number'].includes(typeof v) ? `${v}` : '';
}

export default function (group: Group, meta: locals.DocType, dataList: any[] = []): Group {
	const { field } = group;
	const list: Column[] = group.values.map(v => ({ ...v }));
	const newList: typeof list = [];
	const map = new Map([...list, ...newList].map(v => [toString(v.value), v]));
	const t = meta.fields.find(f => f.fieldname === field)?.translatable || true;
	for (const it of dataList) {
		const value = toString(it[field]);
		let v = map.get(value);
		if (v) {
			if (!v.title) {
				v.title = toString(it[`${field}.title`]) || (t ? __(value) : value);
			}
			continue;
		}
		v = { title: toString(it[`${field}.title`]) || (t ? __(value) : value), value };
		newList.push(v);
		map.set(value, v);
	}
	const values = [
		...list,
		...newList.sort(({ title: a, value: c }, { title: b, value: d }) =>
			// eslint-disable-next-line no-nested-ternary
			a > b ? 1 : a < b ? -1 : c > d ? 1 : c < d ? -1 : 0
		),
	];
	if (!values.length) {
		values.push({ title: '', value: '' });
	}
	return { ...group, values };
}
