import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d4605974"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar" }
const _hoisted_2 = { class: "input-container" }
const _hoisted_3 = { class: "input-container" }
const _hoisted_4 = { class: "input-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString($setup.tt('Company')) + ": ", 1 /* TEXT */),
      _createVNode($setup["ElSelect"], {
        class: "input",
        filterable: "",
        modelValue: $setup.companyValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.companyValue) = $event)),
        size: "small",
        onChange: $setup.companyChange
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.companies, (item) => {
            return (_openBlock(), _createBlock($setup["ElOption"], {
              key: item.name,
              label: item.company_name,
              value: item.name
            }, null, 8 /* PROPS */, ["label", "value"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createTextVNode(_toDisplayString($setup.tt('Project')) + ": ", 1 /* TEXT */),
      _createVNode($setup["ElSelect"], {
        class: "input",
        filterable: "",
        modelValue: $setup.projectValue,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.projectValue) = $event)),
        size: "small",
        popperClass: "projectLink"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.projects, (item) => {
            return (_openBlock(), _createBlock($setup["ElOption"], {
              key: item.name,
              label: item.project_abbr_name||item.project_name,
              value: item.name
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("strong", null, _toDisplayString(item.project_abbr_name|| item.project_name), 1 /* TEXT */),
                  _createElementVNode("span", null, _toDisplayString(item.description), 1 /* TEXT */)
                ])
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "value"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createTextVNode(_toDisplayString($setup.tt('Material Month')) + ": ", 1 /* TEXT */),
      _createVNode($setup["ElDatePicker"], {
        modelValue: $setup.monthValue,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.monthValue) = $event)),
        teleported: false,
        clearable: false,
        editable: false,
        type: "month",
        valueFormat: "YYYY-MM-DD",
        disabledDate: $setup.disabledDate,
        size: "small"
      }, null, 8 /* PROPS */, ["modelValue"])
    ]),
    _createVNode($setup["ElCheckbox"], {
      class: "material_tool_checkbox",
      modelValue: $setup.isTotal,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.isTotal) = $event)),
      label: $setup.tt('Accumulation'),
      size: "small"
    }, null, 8 /* PROPS */, ["modelValue", "label"]),
    _createVNode($setup["ElCheckbox"], {
      class: "material_tool_checkbox",
      modelValue: $setup.isShowConsume,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.isShowConsume) = $event)),
      label: $setup.tt('Show Consumption Only'),
      size: "small"
    }, null, 8 /* PROPS */, ["modelValue", "label"]),
    _createVNode($setup["ElCheckbox"], {
      class: "material_tool_checkbox",
      modelValue: $setup.isShowDetail,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.isShowDetail) = $event)),
      label: $setup.tt('Show Material Detail'),
      size: "small"
    }, null, 8 /* PROPS */, ["modelValue", "label"])
  ]))
}