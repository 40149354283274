import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent($setup.comp), {
    meta: $props.meta,
    options: $props.options,
    data: $setup.data,
    "onUpdate:data": _cache[0] || (_cache[0] = $event => (($setup.data) = $event)),
    total: $props.total,
    loading: $props.loading,
    modelValue: $setup.modelValue,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.modelValue) = $event)),
    selected: $setup.selected,
    "onUpdate:selected": _cache[2] || (_cache[2] = $event => (($setup.selected) = $event)),
    infiniteScroll: $props.infiniteScroll,
    page: $props.page,
    limit: $props.limit,
    group: $props.group,
    sort: $props.sort,
    filters: $props.filters,
    detail: $props.detail,
    rowAction: $props.rowAction,
    view: $props.view,
    configuration: $setup.configuration,
    "onUpdate:configuration": _cache[3] || (_cache[3] = $event => (($setup.configuration) = $event)),
    onRefresh: _cache[4] || (_cache[4] = $event => ($setup.emit('refresh'))),
    onDelete: _cache[5] || (_cache[5] = (...p) => $setup.emit('delete', ...p)),
    onCreate: _cache[6] || (_cache[6] = (data, extend) => $setup.emit('create', data, extend)),
    onNextPage: _cache[7] || (_cache[7] = $event => ($setup.emit('nextPage'))),
    onFilter: _cache[8] || (_cache[8] = (field, operator, value) => $setup.emit('filter', field, operator, value)),
    onSort: _cache[9] || (_cache[9] = (target, docs, before, children) => $setup.emit('sort', target, docs, before, children))
  }, null, 40 /* PROPS, HYDRATE_EVENTS */, ["meta", "options", "data", "total", "loading", "modelValue", "selected", "infiniteScroll", "page", "limit", "group", "sort", "filters", "detail", "rowAction", "view", "configuration"]))
}