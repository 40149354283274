import type { RowDataProxy } from "../types/Row";

import hideCell from './hideCell';

export default function hideRow(row: RowDataProxy) {
	for (const c of row.shown) {
		c.el.remove();
		hideCell(c);
	}
	row.shown = [];
}
