<template>
  <div class="subjectContent">
    <span class="editBtn" :onclick="click"> {{ props.params.value }}</span>
  </div>
</template>
<script lang="ts" setup>
import { defineProps } from 'vue';

const props = defineProps<{ params: { value: any, onClick: any, data: any } }>();

function click() {
  frappe.set_route('Form', 'Guigu Sub Company Work Template', props.params.data.work_name);
}
</script>

<style scoped lang="less">
.subjectContent {
  display: flex;
  overflow: hidden;
  color: #1890ff;
  cursor: pointer;

  .label {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    height: auto;
  }

}

.editBtn:hover {
  text-decoration: underline
}
</style>
