<template>
	<div class="toolbar">
		<ProjectCom
			class="project-select"
			default
			noLabel
			v-model="projectValue"
		></ProjectCom>
		<Structure
			v-if="project"
			class="input"
			:project="project"
			:modelValue="structure"
			@update:modelValue="changeStructure"></Structure>
		<PeriodPicker
			v-if="project"
			class="input"
			calendarType="Construction Calendar"
			:project="project"
			periodType="Year Week"
			defaultValue
			:modelValue="period"
			@update:model-value="updatePeriod"></PeriodPicker>
	</div>
</template>
<script lang="ts" setup>

import {ref, onMounted, defineEmits, watchEffect, defineProps, toRefs, computed} from 'vue';

import PeriodPicker from '../../../components/PeriodPicker/index.vue';
import {useMetaQuery} from '../../../components/page/useMetaQuery';
import ProjectCom from '../../../../../../../guigu/guigu/public/js/components/PageComponents/Tools/Project.vue';

import {Period, Project} from './type';
import Structure from './Structure.vue';

interface Props {
	project?: string,
	period?: Period,
	structure?: string
}
const props = defineProps<Props>();
const {project, period, structure} = toRefs(props);
interface Emit {
	(e: 'update:project', project: string): void,
	(e: 'update:period', period?: Period): void,
	(e: 'update:structure', structure?: string): void
}
const emit = defineEmits<Emit>();

const smallMeta = useMetaQuery();

const projectValue = computed({
	get: () => props.project || '',
	set: (v: string) => {
		emit('update:project', v);
	},
});

function changeStructure(newStructure?: string) {
	emit('update:structure', newStructure);
}
function updatePeriod(period?: Period) {
	emit('update:period', period);
}

const margin = computed(()=>smallMeta.value ? '8px' : '0');
const flexDirection = computed(()=>smallMeta.value ? 'column' : 'row');

</script>
<style lang="less" scoped>
.toolbar {
	display: flex;
	flex-direction: v-bind(flexDirection);
	& :deep(.input){
		margin-right: 8px;
		margin-bottom: v-bind(margin);
	}
}
</style>
