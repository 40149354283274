import type { ComputedRef } from 'vue';
import { computed, ref, onMounted, onUnmounted } from 'vue';

export default function useDestroyable<T extends {destroy(): void}>(
	create: () => T | undefined,
): ComputedRef<T | undefined> {
	let oldArea: T | undefined;
	const mounted = ref(false);
	onMounted(() => mounted.value = true)
	onUnmounted(() => mounted.value = false)
	const obj = computed(() => {
		if (oldArea) { oldArea.destroy(); }
		if (!mounted.value) { return; }
		return oldArea = create();
	})
	return obj;
}
