import Handsontable from 'handsontable';

export default function setBorder(
	handsontable: Handsontable,
	selection: Handsontable.plugins.ContextMenu.Selection[],
	type: 'all' | 'top' | 'right' | 'bottom' | 'left' | 'inner' | 'clear',
) {
	const customBorders = handsontable.getPlugin('customBorders');
	switch (type) {
		case 'all':
			customBorders.setBorders(selection.map(v => [
				v.start.row,
				v.start.col,
				v.end.row,
				v.end.col,
			] as [number, number, number, number]), {
				left: { color: '#000000', width: 1 },
				right: { color: '#000000', width: 1 },
				top: { color: '#000000', width: 1 },
				bottom: { color: '#000000', width: 1 },
			});
			break;
		case 'clear':
			customBorders.clearBorders(selection.map(v => [
				v.start.row,
				v.start.col,
				v.end.row,
				v.end.col,
			] as [number, number, number, number]));
			break;
		case 'top':
			customBorders.setBorders(selection.map(v => [
				v.start.row,
				v.start.col,
				v.start.row,
				v.end.col,
			] as [number, number, number, number]), {
				top: { color: '#000000', width: 1 },
			});
			break;
		case 'right':
			customBorders.setBorders(selection.map(v => [
				v.start.row,
				v.end.col,
				v.end.row,
				v.end.col,
			] as [number, number, number, number]), {
				right: { color: '#000000', width: 1 },
			});
			break;
		case 'bottom':
			customBorders.setBorders(selection.map(v => [
				v.end.row,
				v.start.col,
				v.end.row,
				v.end.col,
			] as [number, number, number, number]), {
				bottom: { color: '#000000', width: 1 },
			});
			break;
		case 'left':
			customBorders.setBorders(selection.map(v => [
				v.start.row,
				v.start.col,
				v.end.row,
				v.start.col,
			] as [number, number, number, number]), {
				left: { color: '#000000', width: 1 },
			});
			break;
		case 'inner':
			// TODO
			break;

	}
}
