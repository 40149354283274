<template>
	<ElTreeSelect
		v-show="treeData.length>0"
		:modelValue="modelValue"
		@change="changeStructure"
		:props="defaultProps"
		:data="treeData"
		defaultExpandAll
		:filterNodeMethod="filterNodeMethod"
		filterable
		checkStrictly />
</template>
<script lang="ts" setup>
import {watchEffect, toRefs, ref, computed} from 'vue';

import {list2Tree} from '../../../utils';

import {formatStructure} from './helper';
import {FormatStructure, OriginStructure} from './type';
interface Props{
	project:string,
	modelValue?:string,
}
const props = defineProps<Props>();
const {project, modelValue} = toRefs(props);

interface Emit{
	(e:'update:modelValue', structureId?: string):void
}
const emit = defineEmits<Emit>();
const formatStructures = ref<FormatStructure[]>([]);
const defaultProps = {
	label: 'label',
	children: 'children',
	value: 'id',
};
watchEffect(()=>{
	if (!project.value) {
		return;
	}
	loadStructures(project.value);
});
watchEffect(()=>{
	if (modelValue?.value) {
		const hasData = formatStructures.value.some(item=>item.id === modelValue.value);
		if (!hasData) {
			emit('update:modelValue');
		}
	}
	if (modelValue?.value || !formatStructures.value || formatStructures.value.length === 0) {
		return;
	}
	const data = formatStructures.value.find(item=>item.hasTeam);
	emit('update:modelValue', data?.id!);
});
async function loadStructures(project:string) {
	const data:OriginStructure[] = await frappe.db.get_list('Guigu Construction Structure', {
		filters: {
			project,
		},
		fields: ['*'],
		limit: '',
	});
	formatStructures.value = formatStructure(data?.sort((pre, next)=>pre.lft - next.lft) || []);
}
const treeData = computed(()=>list2Tree(formatStructures.value));
function changeStructure(val:string) {
	emit('update:modelValue', val);
}
function filterNodeMethod(value:string, data:(FormatStructure)) {
	return data.label.includes(value);
}
</script>
<style lang="less" scoped>
</style>
