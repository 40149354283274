import { createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["AgGridVue"], {
      class: "ag-theme-guigu",
      style: {"height":"500px"},
      columnDefs: $setup.columnDefs,
      rowSelection: "multiple",
      animateRows: "true",
      onGridReady: $setup.onGridReady,
      suppressDragLeaveHidesColumns: $setup.smallMeta,
      getRowStyle: $setup.getRowStyle,
      localeText: $setup.zhCN,
      onRowDataUpdated: $setup.rowDataUpdated,
      onCellValueChanged: $setup.cellValueChanged
    }, null, 8 /* PROPS */, ["columnDefs", "suppressDragLeaveHidesColumns", "localeText"]),
    _createVNode($setup["ProgressActualDialog"], {
      visible: $setup.visible,
      progressActual: $setup.progressActualInfo,
      enableAudit: $props.enableAudit,
      onClose: $setup.close,
      onConfirm: $setup.confirm
    }, null, 8 /* PROPS */, ["visible", "progressActual", "enableAudit"])
  ], 64 /* STABLE_FRAGMENT */))
}