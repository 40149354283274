// 英语的示例语言环境文件，将其提供给您的语言环境团队进行翻译

const AG_GRID_LOCALE_ZH = {
	// 设置过滤器
	selectAll: '(选择全部)',
	selectAllSearchResults: '(选择所有搜索结果)',
	searchOoo: '搜索.. .',
	blanks: '(空白)',
	noMatches: '没有匹配项',

	// 数字过滤器和文本过滤器
	filterOoo: '过滤...',
	equals: '等于',
	notEqual: '不等于 ',
	empty: '选择一个',
	blank: '空',
	notBlank: '非空',
	//数字过滤器
	lessThan: '小于',
	greaterThan: '大于',
	lessThanOrEqual: '小于或等于',
	greaterThanOrEqual: '大于或等于',
	greaterThanOrEquals: '大于或等于',
	inRange: '在范围内',
	inRangeStart: '从',
	inRangeEnd: '到',

	// 文本过滤器
	contains: '包含',
	notContains: '不包含',
	startsWith: '开始于',
	endsWith: '结束于',

	// 日期过滤器
	dateFormatOoo: 'yyyy-mm-dd',

	// 过滤条件和条件
	andCondition: '并且',
	orCondition: '或者',

	// 过滤按钮
	applyFilter: '应用',
	resetFilter: '重置',
	clearFilter: '清空 ',
	cancelFilter: '取消',

	// 过滤标题
	textFilter: '文本过滤器',
	numberFilter: '数字过滤器',
	dateFilter: '日期过滤球',
	setFilter: '设置过滤器',

	// 侧边栏
	columns: '列',
	filters: '过滤器',

	// 列工具面板
	pivotMode: '枢纽模式',
	groups: '行分组',
	rowGroupColumnsEmptyMessage: '拖到这里设置行分组',
	values: 'Values',
	valueColumnsEmptyMessage: '拖到这里聚合',
	pivots: ' Column Labels',
	pivotColumnsEmptyMessage: '拖到这里设置列标签',

	// 默认组的标题列
	group: '分组',

	// 其他
	loadingOoo: '加载中...',
	noRowsToShow: '没有要显示的行',
	enabled: '启用',

	// 菜单
	pinColumn: '固定列',
	pinLeft: '固定到左侧',
	pinRight: '固定到右侧',
	noPin: '不固定',
	valueAggregation: '数据聚合',
	autosizeThiscolumn: '此列自动尺寸',
	autosizeAllColumns: '所有列自动尺寸',
	groupBy: '分组',
	ungroupBy: '不分组',
	resetColumns: '重置列',
	expandAll: '展开全部',
	collapseAll: '缩起全部',
	copy: '复制',
	ctrlC: ' Ctrl+C',
	copyWithHeaders: '复制头部',
	paste: '粘贴',
	ctrlV: 'Ctrl+V',
	export: '导出',
	csvExport: 'CSV 导出',
	excelExport: 'Excel 导出',
	copyWithGroupHeaders: '复制标题组',
	// 企业菜单聚合和状态栏
	sum: '求和',
	min: '最小值',
	max: '最大值',
	none: '无',
	count: 'Co计数unt',
	avg: '平均',
	total: '合计',
	filteredRows: '已筛选',
	selectedRows: '已选择',
	totalRows: '所有行',
	totalAndFilteredRows: '行',
	more: '更多',
	to: '到',
	of: '的',
	page: '页',
	nextPage: '下一页',
	lastPage: '最后一页',
	firstPage: '第一页',
	previousPage: '上一页',

	// 企业菜单（图表）
	pivotChartAndPivotMode: '数据透视图和数据透视图',
	pivotChart: '数据透视图',
	chartRange: '图表范围',

	columnChart: '列',
	groupedColumn: '分组',
	stackedColumn: '堆叠',
	normalizedColumn: '100%堆叠',

	barChart: '条状图',
	groupedBar: '分组',
	stackedBar: '堆叠',
	normalizedBar: '100% 堆叠',

	pieChart: '饼状图',
	pie: '饼',
	doughnut: '环状图',

	line: '线',

	xyChart: 'XY (分散)',
	scatter: '分散',
	bubble: '气泡',

	areaChart: '区域',
	area: '区域',
	stackedArea: '堆叠',
	normalizedArea: '100% 堆叠',

	histogramChart: '直方图',

	// Charts
	pivotChartTitle: '数据透视图 ',
	rangeChartTitle: '范围图',
	settings: '设置',
	data: '数据',
	format: '格式化',
	Categories: '类别',
	defaultCategory: '(无)',
	series: '系列',
	xyValues: 'XY 值',
	paired: '配对模式',
	axis: '轴',
	navigator: '导航',
	color: '颜色',
	thickness: '厚度',
	xType: 'X 型',
	automatic: '自动',
	category: '类别',
	number: '编号',
	time: '时间',
	xRotation: 'X 旋转',
	yRotation: 'Y 旋转',
	ticks: '刻度',
	width: '宽度',
	height: '高度',
	length: '长度',
	padding: '填充',
	spacing: '间距',
	chart: '图表',
	title: '标题',
	titlePlaceholder: '图表标题-双击编辑',
	background: '背景',
	font: '字体',
	top: '顶部',
	right: '右侧',
	bottom: '底部',
	left: '左',
	labels: '标签',
	size: '大小',
	minSize: '最小尺寸',
	maxSize: '最大尺寸',
	legend: '图例',
	position: '位置',
	markerSize: '标记大小',
	markerStroke: '标记笔画',
	markerPadding: '标记填充',
	itemSpacing: '项目间距',
	itemPaddingX: 'X项目间距',
	itemPaddingY: 'Y项目间距',
	layoutHorizontalSpacing: '水平间距',
	layoutVerticalSpacing: '垂直间距',
	strokeWidth: '描边宽度',
	offset: '偏移量',
	offsets: '偏移量',
	tooltips: '提示',
	callout: '标注',
	markers: '标记',
	shadow: '阴影',
	blur: '模糊',
	xOffset: 'X 偏移',
	yOffset: 'Y 偏移',
	lineWidth: '线宽',
	normal: '普通',
	bold: '粗体',
	italic: '斜体',
	boldItalic: '粗斜体',
	predefined: '预定义',
	fillOpacity: '填充不透明度',
	strokeOpacity: '线不透明度',
	histogramBinCount: '箱数',
	columnGroup: '列 ',
	barGroup: '条',
	pieGroup: '饼',
	lineGroup: '线',
	scatterGroup: 'XY (散列)',
	areaGroup: 'Area',
	histogramGroup: '直方图',
	groupedColumnTooltip: '分组',
	stackedColumnTooltip: ' 堆叠',
	normalizedColumnTooltip: '100% 堆叠',
	groupedBarTooltip: '分组',
	stackedBarTooltip: '堆叠',
	normalizedBarTooltip: '100% 堆叠',
	pieTooltip: '饼',
	doughnutTooltip: '环',
	lineTooltip: '线',
	groupedAreaTooltip: '区域',
	stackedAreaTooltip: '堆叠',
	normalizedAreaTooltip: '100% 堆叠',
	scattercattertip: '分散 ',
	bubbleTooltip: '旗袍',
	histogramTooltip: '直方图',
	noDataToChart: '没有可绘制图表的数据。',
	pivotChartRequiresPivotMode: '透视图需要启用透视模式。',
	chartSettingsToolbarTooltip: '菜单',
	chartLinkToolbarTooltip: '链接到表格',
	chartUnlinkToolbarTooltip: '从网格取消链接',
	chartDownloadToolbarTooltip: '下载图表',

	// ARIA
	ariaHidden: '隐藏',
	ariaVisible: '显示',
	ariaChecked: '已选中',
	ariaUnchecked: '未选中',
	ariaIndeterminate: 'indeter 不确定minate',
	ariaColumnSelectAll: '切换选择所有列',
	ariaInputEditor: '输入编辑器',
	ariaDateFilterInput: '日期过滤输入',
	ariaFilterInput: '过滤输入',
	ariaFilterColumnsInput: '过滤列输入',
	ariaFilterValue: '过滤值',
	ariaFilterFromValue: '从值过滤',
	ariaFilterToValue: '过滤到值',
	ariaFilteringOperator: '过滤运算符',
	ariaColumnToggleVisibility: '列切换可见性',
	ariaColumnGroupToggleVisibility: '列组切换可见性',
	ariaRowSelect: '按空格键选择此行',
	ariaRowDeselect: '按空格键取消选择此行',
	ariaRowToggleSelection: '按空格键切换行选择',
	ariaRowSelectAll: '按空格键切换所有行行选择',
	ariaSearch: '搜索',
	ariaSearchFilterValues: '搜索过滤器值',
};
export default AG_GRID_LOCALE_ZH;
