<template>
  <div ref='rootRef' class="reportList">
    <ReportInfo
      v-for="item in reportList"
      :ref="getRef"
      :key="item.key"
      :uniKey="item.key"
      :teamStructure="item.teamStructure"
      :dayDiaries="dayDiaries"
      :enableAudit="enableAudit" />
    <div v-if="reportList.length===0">{{ tt("No Records Created") }}</div>
  </div>
</template>

<script setup lang='ts'>
import { ref, defineProps, computed, toRefs, watch } from 'vue';

import ReportInfo from './reportInfo/index.vue';
import {isInViewWindow} from './helper';
import { Diary, TeamNav, TeamStructure } from './type';
const tt = __
const rootRef = ref<HTMLDivElement>();
const infoRefs = ref<HTMLDivElement[]>([]);
const observerRef = ref<IntersectionObserver>();
const dayDiaries = ref<Record<string, Diary>>({});
const loadedKeys = ref<string[]>([]);
interface Props{
	date?: string
	project?:string,
	nav:TeamNav[]
}
const props = defineProps<Props>();
const {nav, date, project} = toRefs(props);
const enableAudit = ref<boolean>(false);

watch(()=>project?.value, async ()=>{
  if (!project?.value){ return; }
    const data = await frappe.db.get_list('Guigu Construction Project Setting', {
			fields:['*'],
			filters:{project:project?.value},
      limit:0,
		});
    enableAudit.value = data[0]?.use_audit_volume === 'Yes';
}, {immediate:true});

const reportList = computed(()=>{
	const data: {
      key: string;
      teamStructure: TeamStructure;
    }[] = [];
    nav.value.forEach(item => {
      item.structures.forEach(each => {
        data.push({
          key: `${item.name}_${each.name}_${date?.value}`,
          teamStructure: {
            teamId: item.name,
            teamName: item.team_name,
            structureId: each.name,
            structureName: each.structure_name,
          },
        });
      });
    });
    return data;
});

function getRef(newRef:{infoRef:HTMLDivElement}){
  if (!date?.value){ return; }
	if (newRef == null ||!newRef.infoRef|| infoRefs.value.includes(newRef.infoRef)) {
      return;
    }
    if (newRef.infoRef.id.indexOf(date.value||'') === -1) {
      return;
    }
    infoRefs.value.push(newRef.infoRef);
}

async function getReportInfo(reportUniKey: string, dateParam: string) {
    if (!dateParam) {
      return;
    }
    if (loadedKeys.value.includes(reportUniKey)) {
      return;
    }
    const [teamName, structureId] = reportUniKey.split('_');
    loadedKeys.value.push(reportUniKey);

    const reportInfo = await frappe.call < { message: Diary } >({
      method: 'guigu_pm.guigu_pm_construction.page.diary_report.diary_report.get_diary_info',
      args: {
        project: project?.value,
        date: date?.value,
        structure: structureId,
        team:teamName,
      },
    });
    if (!reportInfo) {
      return;
    }
    dayDiaries.value[reportUniKey] = reportInfo.message;
  }

function getTimeout(infoEntry: IntersectionObserverEntry) {
    setTimeout(() => {
      if (!rootRef.value) {
        return;
      }
      if (!date?.value || dayDiaries.value[infoEntry.target.id]) {
        return;
      }
      const inView = isInViewWindow(rootRef.value, infoEntry.target);
      if (inView) {
        getReportInfo(infoEntry.target.id, date?.value);
      }
    }, 500);
}

watch([rootRef, nav], ()=>{
    observerRef.value?.disconnect();
    if (!rootRef.value) {
      return;
    }
    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.1,
      root: rootRef.value,
    });
    function observerCallback(entries: IntersectionObserverEntry[]) {
      entries.reverse().forEach(entry => {
        if (entry.isIntersecting) {
          location.hash=`#${entry.target.id}`;
          getTimeout(entry);
        } else {
        }
      });
    }
    observerRef.value = observer;
});

watch([infoRefs, observerRef], ()=>{
  if (infoRefs.value.length === 0 || !observerRef.value) {
      return;
    }
    infoRefs.value.forEach(infoRef => {
      observerRef.value?.unobserve(infoRef);
    });
    infoRefs.value.forEach(infoRef => {
      observerRef.value?.observe(infoRef);
    });
}, {deep:true});
</script>

<style lang='less' scoped>
.reportList {
  height: 100%;
  overflow-y: scroll;
}
</style>
