import RowAction from './RowAction/index.vue';

frappe.provide('frappe.guigu.GlobalView.rowActions');
const { rowActions } = frappe.guigu.GlobalView;

export default function getRowActionComp(name: string | any | boolean) {
	if (!name) { return; }
	if (name === true) { return RowAction; }
	if (typeof name === 'function') { return name; }
	if (typeof name === 'object') { return name; }
	return name in rowActions && rowActions[name] || undefined;
}
