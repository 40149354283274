<template>
	<div>
		<ElForm class="form" ref="formRef" :model="form" @submit.prevent :rules="rules">
			<ElFormItem :label="tt('Chart')" prop="chart">
				<ElSelect :placeholder="tt('Please Select')" v-model="form.chart" filterable remote :remoteMethod="search" @focus="focus" defaultFirstOption
					:loading="loading || waiting" @change="changeSystemChart">
					<ElOption v-for="{ value, label, description } in opts" :key="value" :label="label" :value="value">
						{{ label }}
						<!-- <small v-if="description">{{ description }}</small> -->
					</ElOption>
				</ElSelect>
			</ElFormItem>
			<ElFormItem :label="tt('Height')" prop="height">
				<ElInputNumber :modelValue="form.height" @change="changeHeight"></ElInputNumber>
			</ElFormItem>
		</ElForm>
	</div>
</template>

<script setup lang='ts'>
import { ref, defineProps, defineEmits, reactive, watch, inject, computed } from 'vue';

import type { FormInstance, FormRules } from 'element-plus';
import { ElForm, ElSelect, ElOption, ElFormItem, ElInputNumber } from 'element-plus';

import type { ChartOptions, ChartProvide } from '../../../../type';
import useDebounce from '../../../useDebounce';
const formRef = ref<FormInstance>();
const tt = __;
interface Result {
	value: string;
	label?: string;
	description?: string;
}
interface Props {

}

const props = defineProps<Props>();
interface Emit {
	(event: 'remove'): void;
}
const emit = defineEmits<Emit>();
const chart = inject<ChartProvide>('chart');
interface Option {
	value: string;
	label: string;
	description?: string;
}
const opts = ref<Option[]>([]);

const form = reactive({
	chart: chart?.doc.options?.chart,
	height: chart?.doc.options?.height,
});

watch(() => chart?.doc.options, () => {
	form.chart = chart?.doc.options?.chart;
	form.height = chart?.doc.options?.height;
});

const rules = reactive<FormRules>({
	chart: [
		{
			required: true,
			message: __('Please select a chart'),
			trigger: 'change',
		},
	],
});
const fields = ref<locals.DocField[]>([]);

function changeSystemChart(v: string) {
	if (!chart) { return; }
	chart.doc.options.chart = v;
}

function changeHeight(v?: number) {
	if (!chart) { return; }
	chart.doc.options.height = v;
}

const [remoteMethod, loading, waiting] = useDebounce(
	async (query?: string) => {
		const args = {
			txt: query || '', doctype: 'Dashboard Chart',
		};
		return frappe.call<{ results: Result[]; }>({
			type: 'POST',
			method: 'frappe.desk.search.search_link',
			args,
		}).then(v => v?.results || v?.message || []);
	}, 300, {
		render: v => {
			opts.value = [
				...v.map(({ value, label, description }) => ({ value, label: label || tt(value), description })),
			];
		},
	});
let searchString = '';
function search(query?: string) {
	searchString = query || '';
	remoteMethod(query);
}

function focus() {
	if (searchString) { return; }
	if (opts.value.length) { return; }
	if (loading.value) { return; }
	if (waiting.value) { return; }
	remoteMethod('');
}
</script>

<style lang='less' scoped>
:deep(.form label) {
	margin-bottom: 0;
}
</style>
