import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8a8f1afa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "name-container" }
const _hoisted_2 = {
  key: 0,
  class: "name-placeholder"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(_toDisplayString($setup.name) + " ", 1 /* TEXT */),
    ($setup.type === 'progress')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("v-if", true),
    ($setup.type === 'progress'&&$setup.remark)
      ? (_openBlock(), _createBlock($setup["ElTooltip"], {
          key: 1,
          placement: "top",
          content: $setup.remark
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElIcon"], null, {
              default: _withCtx(() => [
                _createVNode($setup["QuestionFilled"], { class: "remark-icon" })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"]))
      : _createCommentVNode("v-if", true)
  ]))
}