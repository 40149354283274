<template>
	<ElCheckbox class="checkbox_center" v-model="val" :label="tt(label || name || '')" />
</template>
<script lang="ts" setup>
import { computed, onUnmounted, watch } from 'vue';
import { ElCheckbox } from 'element-plus';


const props = defineProps<{
	label?: string;
	name: string;
	required?: boolean;
	filter?: boolean;
	modelValue?: any;
	fitWidth?: boolean;
	popperClass?: string;
	options?: any;
}>();
const tt = __;
const emit = defineEmits<{
	(event: 'update:modelValue', value: any): void;
}>();


function getValue(value: any): boolean {
	if (typeof value === 'boolean') {
		return value;
	}
	if (typeof value === 'number') {
		return Boolean(value);
	}
	if (typeof value === 'string') {
		const v = value.toLowerCase();
		if (value.toLowerCase() === 'yes') {
			return true;
		}
	}
	return false;
}
function getModelValue(value: any): number | undefined {
	if (typeof value === 'boolean' || typeof value === 'number') {
		if (value) {
			return 1;
		}
		return props.required ? 0 : undefined;
	}
	if (typeof value === 'string') {
		const v = value.toLowerCase();
		if (v === 'yes') {
			return 1;
		}
		if (v === 'no') {
			return props.required ? 0 : undefined;
		}
	}
	return undefined;
}
const val = computed({
	get: () => getValue(props.modelValue),
	set: v => emit('update:modelValue', getModelValue(v)),
});
watch(() => props.modelValue, value => {
	const newValue = getModelValue(value);
	if (value !== newValue) {
		emit('update:modelValue', newValue);
	}
}, { immediate: true });
</script>

<style scoped>
.checkbox_center {
	margin-bottom: 0;
	margin-right: 0;
}
</style>
