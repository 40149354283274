import {createApp, ref, type App} from 'vue';

import {HtmlResize} from '@logicflow/extension';

import watchMode from '../../utils';

import NodeView from './NodeView.vue';
class CusWavyRectModel extends HtmlResize.model {
	initNodeData(data:any) {
		super.initNodeData(data);
		const width = 150;
		const height = 100;
		this.width = data.properties?.nodeSize?.width as number || width;
		this.height = data.properties?.nodeSize?.height as number || height;
		this.minWidth = 80;
	}
}
class CusWavyRectView extends HtmlResize.view {
	text = ref<string>('');
	description = ref<string>('');
	app: App<Element> | undefined;
	isSilentModeRef = ref<boolean>(false);
	setHtml(rootEl: HTMLElement) {
		const me = this;
		if (this.app) {
			const {text, description} = this.props.model.properties;
			this.text.value = text;
			this.description.value = description;
		} else {
			this.isSilentModeRef.value = this.props.graphModel.editConfigModel.isSilentMode;
			watchMode(this.props.graphModel.editConfigModel, 'isSilentMode', isSilentMode => {
				me.isSilentModeRef.value = isSilentMode;
			});
			this.createApp(rootEl);
		}
	}

	createApp(rootEl: HTMLElement) {
		const node = document.createElement('div');
		node.style.height = '100%';
		node.style.width = '100%';
		rootEl.appendChild(node);

		const {text, description} = this.props.model.properties;
		this.text.value = text;
		this.description.value = description;
		const app = createApp(NodeView, {
			text: this.text,
			description: this.description,
			isSilentMode: this.isSilentModeRef.value,
		});
		app.mount(node);
		const self = this;
		app._props.onEdit = function() {
			self.props.graphModel.eventCenter.emit('edit', self.props.model);
		};
		this.app = app;
	}
}
export default {
	type: 'cusWavyRect',
	model: CusWavyRectModel,
	view: CusWavyRectView,
};
