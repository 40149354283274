const Workspace = frappe.views.Workspace;
const old = Workspace.prototype.initialize_editorjs
import EditorJS from "@editorjs/editorjs";
Workspace.prototype.initialize_editorjs = function initialize_editorjs(blocks) {
	const tools = {
		header: {
			class: this.blocks["header"],
			inlineToolbar: ["HeaderSize", "bold", "italic", "link"],
			config: {
				default_size: 4,
			},
		},
		paragraph: {
			class: this.blocks["paragraph"],
			inlineToolbar: ["HeaderSize", "bold", "italic", "link"],
			config: {
				placeholder: __("Choose a block or continue typing"),
			},
		},
	}
	for (const [name, cla] of Object.entries(frappe.workspace_block.blocks)) {
		if (name === 'header') { continue; }
		if (name === 'paragraph') { continue; }
		if (name === 'spacer') { continue; }
		tools[name] = { class: cla, config: { page_data: this.page_data || [] } }
	}
	this.tools = {
		...tools,
		spacer: this.blocks["spacer"],
		HeaderSize: frappe.workspace_block.tunes["header_size"],
	};
	this.editor = new EditorJS({
		data: {
			blocks: blocks || [],
		},
		tools: this.tools,
		autofocus: false,
		readOnly: true,
		logLevel: "ERROR",
	});
}

export {}
