import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f1c2fe50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main" }
const _hoisted_2 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElTooltip = _resolveComponent("ElTooltip")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.periods, (period) => {
      return (_openBlock(), _createBlock(_component_ElTooltip, { class: "period" }, {
        content: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString($setup.PERIOD_TYPE[$setup.periodType]) + "：" + _toDisplayString(`${String(period.value)?.slice(0, 4)}.${String(period.value)?.slice(4)}`), 1 /* TEXT */),
          _createElementVNode("p", null, _toDisplayString($setup.tt('Start Date')) + "：" + _toDisplayString(period.start_date), 1 /* TEXT */),
          _createElementVNode("p", null, _toDisplayString($setup.tt('End Date')) + "：" + _toDisplayString(period.end_date), 1 /* TEXT */)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass({selectPeriod: $setup.selectPeriod?.name===period.name, periodLabel:true}),
            onClick: $event => ($setup.clickPeriod(period))
          }, _toDisplayString($setup.buttonLabel(period)), 11 /* TEXT, CLASS, PROPS */, _hoisted_2)
        ]),
        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */))
    }), 256 /* UNKEYED_FRAGMENT */))
  ]))
}