<template>
  <div class="tool-container">
    <div class="tool">
      <h5>{{ tt('3、 Profit And Loss Situation Of Each Project') }}</h5>
      <div class="project-info">
        {{ tt('The projects holding the cost analysis meeting this month include') }}
        <el-button type="text" class="link-button" @click="onClick">{{ recordsRef.length }}</el-button>
        {{ tt('Number.') }}{{ tt('The profit and loss situation of each project is detailed in the table below:') }}
      </div>
      <el-table :data="recordsRef" border="true">
        <el-table-column prop="guigu_project" :label="tt('Project')" width="180" #default="{ row }">
          <el-button type="text" class="link-button" @click="projectFilterClick(row.name)">
            {{ row.guigu_project }}
          </el-button>
        </el-table-column>
        <el-table-column prop="total_revenue" :label="tt('Accumulation Revenue')" width="180" />
        <el-table-column prop="total_cost" :label="tt('Accumulation Cost')" />
        <el-table-column prop="self_other_cost" :label="tt('Management Cost')" />
        <el-table-column prop="total_profit" :label="tt('Profit')" />
      </el-table>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { ref, watchEffect, defineProps, onMounted, watch, toRefs } from 'vue';

  const tt = __;
  interface Props {
    selectedDate?: string;
  }
  interface Emit {
    (e: 'update:records', month?: String): void;
  }
  interface Record {
    guigu_project: String;
    total_cost?: Number;
    subcontraction_cost?: Number;
    s;
    material_cost?: Number;
    self_other_cost?: Number;
    total_revenue?: Number;
    total_profit?: Number;
  }

  const props = defineProps<Props>();
  const recordsRef = ref<Record[]>([]);

  async function getRecords(date?: string) {
    if (!date) {
      recordsRef.value = [];
    }
    const { message } = await frappe.call<{ message: Record[] }>({
      method: 'guigu_pc.guigu_pc_month_rep.controls.get_guigu_pc_month_cost_estimation_total',
      args: { selectedDate: props.selectedDate || '' },
    });
    recordsRef.value = message;
  }

  function onClick() {
    frappe.set_route('list', 'Guigu Pc Month Cost Estimation');
  }

  function projectFilterClick(projectName: string) {
    frappe.set_route('list', 'Guigu Pc Month Cost Estimation', { guigu_project: projectName });
  }

  onMounted(async () => {
    getRecords(props.selectedDate);
  });

  watchEffect(() => {
    getRecords(props.selectedDate);
  });
</script>

<style scoped lang="less">
  .tool-container {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    .tool {
      margin: 16px;
      padding: 24px;

      h5 {
        margin: 0 0 16px 0;
        font-size: 20px;
        font-weight: bold;
      }

      .project-info {
        margin: 0;
        font-size: 16px;
        line-height: 2;
        text-indent: 2em;

        .link-button {
          font-size: 16px !important;
        }
      }

      img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 30px auto;
      }
    }
  }
</style>
