import moment from 'moment';

import {list2Tree} from '../../../../../../../guigu_pm/guigu_pm/public/js/utils';

import {DiaryRecordB, SubOrganization, TimelineSource, User, UserDailyRecords} from './type/index';

export function getTimelineData(diaryRecords: DiaryRecordB[]): TimelineSource[] {
	const timelineData: TimelineSource[] = [];
	const dailyRecordByDate: Record<string, DiaryRecordB[]> = {};
	for (const diaryRecord of diaryRecords) {
	  const date = moment(diaryRecord.record_date).format('YYYY-MM-DD');
	  if (!dailyRecordByDate[date]) {
			dailyRecordByDate[date] = [];
	  }
	  dailyRecordByDate[date].push(diaryRecord);
	}
	for (const date of Object.keys(dailyRecordByDate)) {
	  timelineData.push({
			date,
			list: dailyRecordByDate[date],
	  });
	}
	timelineData.sort((pre, next) => (pre.date > next.date ? -1 : 1));
	return timelineData;
}


export function getUsers(memberTree: (SubOrganization&{children?:SubOrganization[]})[], list: SubOrganization[]) {
	return memberTree.map(item => {
		if (item?.children && item?.children.length) {
			getUsers(item?.children, list);
		} else if (item.type === 'member' && !list.some(each=>each.user?.name === item.user?.name)) {
		  list.push(item);
		}
	  });
}
const AUTHOR_RULE_NUMBER = 100;

export function getQueryParams(organization:SubOrganization, date:string, page:number, organizations:SubOrganization[]) {
	let requestUserList: User[] = [];
	let startDateParam = '';
	let endDateParam = '';
	let batch:number = 0;
	let userList:SubOrganization[] = [];
	if (organization.type === 'organization') {
		if (organization.id === 'all') {
			const organizationTree = list2Tree(organizations);
			getUsers(organizationTree, userList);
		} else {
			getUsers([organization], userList);
		}
	} else {
		userList = [organization];
	}
	if (userList.length === 0) {
		return {recordOwners: requestUserList, endDateParam, startDateParam, dayCount: 1, batch};
	}

	let authorArray: SubOrganization[][] = [];
	let dayCount: number = 1;
	if (userList.length < AUTHOR_RULE_NUMBER) {
		authorArray = [userList];
		dayCount = Math.ceil(AUTHOR_RULE_NUMBER / userList.length);
		startDateParam = moment(date)
			.subtract(
				(page - 1) * dayCount,
				'days',
			)
			.format('YYYY-MM-DD');
		endDateParam = moment(startDateParam)
			.subtract(dayCount, 'days')
			.format('YYYY-MM-DD');
		requestUserList = userList.map(user=>user.user!);
	}
	if (
		AUTHOR_RULE_NUMBER <= userList.length
		&& userList.length < AUTHOR_RULE_NUMBER * 2
	) {
		authorArray = [userList];
		dayCount = 1;
		/** 当 15 <=人员数量 < 30 我们每次只读取一天的数据 */
		startDateParam = page === 1
			? date
			: moment(date)
				.subtract((page) - 1, 'days')
				.format('YYYY-MM-DD');
		endDateParam = page === 1
			? date
			: moment(date)
				.subtract(page - 1, 'days')
				.format('YYYY-MM-DD');
		requestUserList = userList.map(user=>user.user!);
	}
	if (userList.length >= AUTHOR_RULE_NUMBER * 2) {
		/** 人员大于30时，要分批获取人员，分批获取完成后，再从下一天循环分批获取 */
		// TODO 人员大于30时可以将分批处理拿到外层，当人员变动时再重新处理
		const arr: SubOrganization[][] = [];
		const floor = Math.floor(userList.length / AUTHOR_RULE_NUMBER);
		const floorArr: number[] = Array.from(Array(floor));
		for (const [index, item] of floorArr.entries()) {
			if (
				index + 1 === floorArr.length
				&& userList.length % AUTHOR_RULE_NUMBER > 0
			) {
				arr.push((userList || []).slice(index * AUTHOR_RULE_NUMBER + 1));
			} else {
				arr.push((userList || []).slice(
					index * AUTHOR_RULE_NUMBER + 1,
					(index + 1) * AUTHOR_RULE_NUMBER + 1,
				));
			}
		}
		authorArray = arr;
		const integer = Math.floor(page / authorArray.length);
		const remainder
			= page % authorArray.length;
		startDateParam
			= integer && remainder === 1
				? moment(date).subtract(integer, 'days').format('YYYY-MM-DD')
				: date;
		endDateParam
			= integer && remainder === 1
				? moment(date).subtract(integer, 'days').format('YYYY-MM-DD')
				: date;
		batch = page % authorArray.length
			? (page % authorArray.length) - 1
			: authorArray.length - 1;
		const users = authorArray[batch];
		requestUserList = users.map(user=>user.user!);
	}
	return {recordOwners: requestUserList, endDateParam, startDateParam, dayCount, batch};
}

export function generateDay(length: number, start_date: string) {
	const dayArr = [];
	for (let i = 0; i < length; i++) {
		dayArr.push(moment(start_date).subtract(i, 'days').format('YYYY-MM-DD'));
	}
	return dayArr;
}
export function buildData(dateArr:string[], dailyRecords:DiaryRecordB[], recordOwners:User[]) {
	const dailyObj:Record<string, DiaryRecordB[]> = {};
	for (const item of dailyRecords) {
		if (dailyObj[`${item?.record_owner}${item?.record_date}`]) {
			dailyObj[`${item?.record_owner}${item?.record_date}`].push(item);
		} else {
			dailyObj[`${item?.record_owner}${item?.record_date}`] = [item];
		}
	}
	const dataList: Record<string, UserDailyRecords[]>[] = [];
	if (dateArr.length) {
		dateArr.forEach((item: string) => {
			const authorDailyArr: UserDailyRecords[] = recordOwners.map(author => ({
				userId: author.name,
				user: author,
				dailyRecords: dailyObj[`${author.name}${item}`] || [],
			}));
			dataList.push({[item]: authorDailyArr});
		});
	}
	return {dailyRecordList: dataList, dailyRecordListHash: dailyObj};
}

export function buildSearchData(dailyRecords:DiaryRecordB[]) {
	const dataList: Record<string, UserDailyRecords[]>[] = [];
	for (const item of dailyRecords) {
		dataList.push({
			[item.record_date]: [{
				userId: item.record_owner,
				user: item.user,
				dailyRecords: [item],
			}],
		});
	}
	return {dailyRecordList: dataList};
}
