import { toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElButtonGroup"], { class: "tool_toggle_State" }, {
    default: _withCtx(() => [
      ($props.levels)
        ? (_openBlock(), _createBlock($setup["ElDropdown"], {
            key: 0,
            onCommand: $setup.clickLevel
          }, {
            dropdown: _withCtx(() => [
              _createVNode($setup["ElDropdownMenu"], null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.levels, (index) => {
                    return (_openBlock(), _createBlock($setup["ElDropdownItem"], {
                      command: index,
                      key: index
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.tt('Level {}',[index])), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["command"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              })
            ]),
            default: _withCtx(() => [
              _createVNode($setup["ElButton"], { size: "small" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.tt('Expand')) + " ", 1 /* TEXT */),
                  _createVNode($setup["ElIcon"], { class: "el-icon--right" }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ArrowDown"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }))
        : (_openBlock(), _createBlock($setup["ElButton"], {
            key: 1,
            size: "small",
            onClick: _cache[0] || (_cache[0] = $event => ($setup.emit('toggle',true)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('Expand')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })),
      _createVNode($setup["ElButton"], {
        size: "small",
        onClick: _cache[1] || (_cache[1] = $event => ($setup.emit('toggle',false)))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.tt('Collapse')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}