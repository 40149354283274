<template>
	<ElDialog
		v-model="dialogVisible"
		title="保存"
		:width="smallMeta?'80%':'500'"
	>
		<ElForm
			labelPosition="top"
			ref="formRef"
			:rules="rules"
			:model="formValue"
		>
			<ElFormItem v-if="store.isSaveNewVersion" :labelPosition="'top'" :label="tt('Version No')" prop="versionNo">
				<ElInput
					v-model="formValue.versionNo"
				/>
			</ElFormItem>
			<ElFormItem v-if="store.isSaveNewVersion" :labelPosition="'top'" :label="tt('instruction Compiler')" prop="compiler">
				<ElInput
					v-model="formValue.compiler"
				/>
			</ElFormItem>
			<ElFormItem v-if="store.isSaveNewVersion" :labelPosition="'top'" :label="tt('instruction Checker')" prop="checker">
				<ElInput
					v-model="formValue.checker"
				/>
			</ElFormItem>
			<ElFormItem :labelPosition="'top'" :label="tt('Change Log')" prop="changelog">
				<ElInput
					v-model="formValue.changeLog"
					:rows="5"
					type="textarea"
				/>
			</ElFormItem>
		</ElForm>
		<template #footer>
			<div class="dialog-footer">
				<ElButton @click="dialogVisible = false">{{ tt('Cancel') }}</ElButton>
				<ElButton type="primary" @click="confirm">
					{{ tt('Confirm') }}
				</ElButton>
			</div>
		</template>
	</ElDialog>
</template>

<script lang="ts" setup>
import {computed, reactive, watch} from 'vue';
import {ElDialog, ElFormItem, ElInput, ElButton, ElForm, type FormRules} from 'element-plus';

import {useMetaQuery} from '../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';

import {useInstructionStore} from './store';
const smallMeta = useMetaQuery();

const tt = __;
interface Props {
	visible:boolean,
}
const props = defineProps<Props>();
interface Emit {
	(e: 'update:visible', visible: boolean): void,
}
const emit = defineEmits<Emit>();
const store = useInstructionStore();
const rules = reactive<FormRules<{versionNo:string}>>({
	versionNo: [
		{required: true, message: __('Please input version no'), trigger: 'blur'},
	],
});

const dialogVisible = computed({
	get() {
		return props.visible;
	},
	set(visible:boolean) {
		emit('update:visible', visible);
	},
});
const formValue = reactive<{changeLog:string, versionNo:string, checker?:string, compiler?:string}>({
	changeLog: '',
	versionNo: '',
	compiler: undefined,
	checker: undefined,
});


watch([
	()=>store.currentVersion.checker,
	()=>store.currentVersion.instruction_version,
	()=>store.currentVersion.change_log,
	()=>store.currentVersion.compiler,
], ()=>{
	formValue.versionNo = store.currentVersion.instruction_version || '';
	formValue.changeLog = store.currentVersion.change_log || '';
	formValue.checker = store.currentVersion.checker;
	formValue.compiler = store.currentVersion.compiler;
}, {immediate: true, deep: true});

function confirm() {
	dialogVisible.value = false;
	store.save({
		version: {
			change_log: formValue.changeLog,
			instruction_version: formValue.versionNo,
			checker: formValue.checker,
			compiler: formValue.compiler,
		},
	});
}
</script>
