<template>
	<Page>
		<template #title>
			<h3 class="title">{{ tt("Day Plan") }}</h3>
		</template>
		<template #tools>
			<ToolBar
				v-model:project="project"
				v-model:dateRange="dateRange"
				v-model:structure="structure"></ToolBar>
		</template>
		<PlanTable
			v-loading="structureLoading || progressPlanLoading"
			:structureDescendants="structureDescendants"
			:progressPlans="progressPlans"
			:project="project"
			:dateRange="dateRange"
			:progress="progress"
			:structure="structure"
			@planChange="planChange"
			@requestProgressPlan="requestProgressPlan"></PlanTable>
	</Page>
</template>
<script lang="ts" setup>
import {ref, watch, watchEffect} from 'vue';
import {RowGroupOpenedEvent} from 'ag-grid-community';
import moment from 'moment';

import Page from '../../../../components/page/index.vue';
import {getTreeAncestry} from '../../../../utils';

import ToolBar from './components/ToolBar.vue';
import {FormatDiaryStructure, NewProgress, NewStructureProgress, Period, ProgressPlan, StructureItem, StructureItemFormat} from './type';
import PlanTable from './components/PlanTable.vue';
import {getProgressPlan2} from './components/helper';
const tt = __;
const project = ref<string>('');
const dateRange = ref<string[]>([]);
const structure = ref<FormatDiaryStructure>();
const structureDescendants = ref<StructureItemFormat[]>([]);
const progressPlans = ref<ProgressPlan[]>([]);
const structureLoading = ref<boolean>(false);
const progressPlanLoading = ref<boolean>(false);
const progress = ref<any[]>([]);
watch(project, () => {
	structureDescendants.value = [];
	progress.value = [];
});
watch(dateRange, () => {
	progressPlans.value = [];
});
watch([structure, dateRange], async () => {
	if (!structure.value || !dateRange.value.length) {
		return;
	}
	progressPlans.value = [];
	structureLoading.value = true;
	const data = await frappe.call<{ message: StructureItem[] }>({
		method: 'guigu_pm.guigu_pm_construction.page.guigu_structure_day_plan.guigu_structure_day_plan.get_structure_descendants',
		args: {
			structure: structure.value.id,
		},
	});
	const descendants: StructureItemFormat[] = [];
	for (const item of data?.message) {
		descendants.push({
			code: item.code,
			left: item.left,
			structureId: item.name,
			parent: item.parent ? `structure_${item.parent}` : '',
			right: item.right,
			name: item.structure_name,
			id: `structure_${item.name}`,
		});
		if (item.progress_item) {
			for (const each of item.progress_item) {
				descendants.push({
					position: each.position,
					structureProgressId: each.name,
					progressItemId: each.progress_item,
					name: each.progress_item_name,
					parent: `structure_${item.name}`,
					designQuantity: each.design_quantity,
					id: `structure_progress_${each.name}`,
					unit: each.unit,
				});
			}
		}
	}
	const ancestryList = getTreeAncestry<StructureItemFormat>(descendants);
	structureDescendants.value = structuredClone(ancestryList);
	progress.value = structuredClone(ancestryList);
	structureLoading.value = false;
});

async function requestProgressPlan(event: RowGroupOpenedEvent) {
	if (!structure.value || !dateRange.value) {
		return;
	}
	progressPlanLoading.value = true;
	const startDate = moment(dateRange.value[0]).subtract(1, 'days').format('YYYY-MM-DD');
	const endDate = moment(dateRange.value[dateRange.value?.length - 1]).subtract(1, 'days').format('YYYY-MM-DD');
	const data = await frappe.call<{ message: ProgressPlan[] }>({
		method: 'guigu_pm.guigu_pm_construction.page.guigu_structure_day_plan.guigu_structure_day_plan.get_structure_diary_plans',
		args: {
			structure: structure.value.id,
			project: project.value,
			start_date: startDate,
			end_date: endDate,
		},
	});
	progressPlans.value.push(...data?.message || []);
	getProgressPlan2(progress.value, data?.message || []);
	event.api.refreshCells();
	progressPlanLoading.value = false;
}
function planChange(newData: { name: string, quantity?: number, summary?: string, plan_remark?: string, guigu_construction_structure_progress: string }) {
	const dataIndex = progressPlans.value.findIndex(item => item.guigu_construction_structure_progress === newData.guigu_construction_structure_progress);
	if (dataIndex > -1) {
		progressPlans.value[dataIndex] = {
			...progressPlans.value[dataIndex],
			name: newData.name,
			quantity: newData.quantity,
			summary: newData.summary,
			plan_remark: newData.plan_remark,
		};
	} else {
		progressPlans.value.push(newData);
	}
	getProgressPlan2(progress.value, progressPlans.value);
}
</script>
<style lang="less" scoped>
.title{
	margin-bottom: 0;
}
</style>
