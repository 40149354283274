function getFieldName(field: string) {
	return frappe.model.get_full_column_name(field, 'Tianjy Organization');
}

interface Result {
	name: string;
	label: string | null;
	parent_organization?: string;
}
export interface Option {
	children?: Option[];
	value: string;
	label: string;
	parent?: string;
}
const doctype = 'Tianjy Organization';
export default async function getAllOrganization() {
	const data = await frappe.call('frappe.desk.reportview.get', {
		doctype,
		fields: ['name', 'label', 'parent_organization'].map(getFieldName),
		filters: [],
		order_by: `${getFieldName('lft')} ASC`,
		start: 0,
		page_length: 0,
		view: 'List',
		with_comment_count: false,
	}).then((v: any) => v.message || {});
	Object.assign(frappe.boot.user_info, data.user_info);
	const values: Result[] = !Array.isArray(data)
		? frappe.utils.dict(data.keys, data.values)
		: data;
	const list: Option[] = values.map(({ name, label, parent_organization }) => ({
		value: name, label: label || name, parent: parent_organization,
	}));
	const map = new Map(list.map(v => [v.value, v]));
	const roots: Option[] = [];
	for (const o of list) {
		const { parent } = o;
		const p = parent && map.get(parent);
		if (!p) {
			roots.push(o);
			continue;
		}
		const { children } = p;
		if (children) {
			children.push(o);
		} else {
			p.children = [o];
		}
	}
	return roots;
}
