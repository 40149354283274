<template>
	<div>
		<Page>
			<template #title>
				<h3 class="title">{{ title }}</h3>
			</template>
			<template #tools>
				<Tools
					:smallMeta="smallMeta"
					v-model:month="month"
					v-model:detail="setting.detail"
					@refresh="getPageSetting"
					@fullScreen="toggleFullscreen"
				></Tools>
			</template>
			<template #sider>
				<Users
					v-loading="userLoading"
					:users="setting.users"
					:user="user"
					@selectUser="selectUser"
				></Users>
			</template>
			<div ref="doctypeTablesRef" v-loading="loading" class="table-container">
				<DoctypeRender
					v-if="!loading"
					:doctypes="setting?.doctypes||[]"
					:useDetail="setting?.detail"
					:doctypeList="doctypeList"
					:smallMeta="smallMeta"
					:user="user"
					:date="month"
					@showDetail="showDetail"
				></DoctypeRender>
			</div>
		</Page>
		<Detail v-if="detailName" @hide="detailName=''" :doctype="detailDoctype" :name="detailName"></Detail>

	</div>
</template>

<script setup lang='ts'>
import {onMounted, onUnmounted, ref, watch, computed} from 'vue';
import moment from 'moment';

import Page from '../../../../../../../guigu_pm/guigu_pm/public/js/components/page/index.vue';
import {useMetaQuery} from '../../../../../../../guigu_pm/guigu_pm/public/js/components/page/useMetaQuery';
import requestDocList from '../../../../../../../guigu/guigu/public/js/utils/requestDocList';
import initData from '../../../../../../../guigu/guigu/public/js/compatible/list/initData';

import Tools from './components/Tools.vue';
import type {DoctypeConfig, PageSetting, User} from './type';
import Users from './components/Users.vue';
import DoctypeRender from './components/DoctypeRender.vue';
import useFullScreen from './useFullScreen';
import Detail from './components/Detail.vue';

const smallMeta = useMetaQuery();
const month = ref<string>(moment().format('YYYY-MM'));
const user = ref<string>('');
const loading = ref<boolean>(true);
const userLoading = ref<boolean>(true);
const setting = ref<PageSetting>({doctypes: [],
	title: '',
	detail: 0,
	users: []});
const doctypeList = ref<any[]>([]);
const settingName = ref<string>('');
const doctypeTablesRef = ref<HTMLDivElement>();
const {isFullscreen, toggleFullscreen} = useFullScreen(doctypeTablesRef);
const detailName = ref<string>('');
const detailDoctype = ref<string>('');

function getSearchParams() {
	const params = new URLSearchParams(window.location.search);
	if (params.get('month')) {
		month.value = params.get('month') || '';
	}
	if (params.get('user')) {
		user.value = params.get('user') || '';
	}
	if (params.get('setting')) {
		settingName.value = params.get('setting') || '';
	}
}
getSearchParams();
$(frappe.pages.guigu_leader_work).on('show', function() {
	getSearchParams();
});

function showDetail(name:string, doctype:string) {
	detailName.value = name;
	detailDoctype.value = doctype;
}
function selectUser(selectUser: User) {
	user.value = selectUser.name;
}

watch([month, user, settingName], async () => {
	const queryObj = new URLSearchParams({
		month: month.value,
		user: user.value,
		setting: settingName.value,
	});
	history.replaceState(null, '', `?${queryObj.toString()}`);
}, {immediate: true});
watch([month, user, setting], async () => {
	getDocumentData();
}, {immediate: true});
async function getDocumentData(updateDoctype?:string) {
	if (!month.value || !user.value) {
		return;
	}
	if (!setting.value?.doctypes?.length) {
		return;
	}
	loading.value = true;
	const requestArr = setting.value?.doctypes.map((item:DoctypeConfig)=>{
		if (updateDoctype && item.document !== updateDoctype) {
			return;
		}
		const fields:[string, string][] = item.columns.map(col=>([col.field === '__LastComment__' ? '_comments' : col.field, item.document]));
		const order = item.sort_field
			? [{doctype: item.document, field: item.sort_field, desc: item.sort_order === 'Desc'}]
			: [];
		const local_meta = frappe.get_meta(item.document);
		if (!local_meta) {
			return;
		}
		const filters = frappe.utils.get_filter_from_json(item.filter || '{}', item.document);
		const field = local_meta.fields.find(each=>each.fieldname === item.user_field);
		if (field?.fieldtype === 'Table MultiSelect' && field.options) {
			const fieldMeta = frappe.get_meta(field.options);
			const tableUserField = fieldMeta?.fields.find(field=>(field.fieldtype === 'Link' || field.fieldtype === 'Tree Select') && field.options === 'User');
			if (tableUserField) {
				if (user.value === 'all') {
					const userIds = setting.value.users
						.filter(usr=>usr.name !== 'all')
						.map(usr=>usr.name);
					filters.push([field.options, tableUserField.fieldname, 'in', userIds]);
				} else {
					filters.push([field.options, tableUserField.fieldname, '=', user.value]);
				}
			}
		} else if (user.value === 'all') {
			const userIds = setting.value.users
				.filter(u=>u.name !== 'all')
				.map(u=>u.name);
			filters.push([item.user_field, 'in', userIds]);
		} else {
			filters.push([item.user_field, '=', user.value]);
		}
		const startDate = moment(month.value).startOf('month').format('YYYY-MM-DD');
		const endDate = moment(month.value).endOf('month').format('YYYY-MM-DD');
		filters.push([item.start_date_field, '<=', endDate]);
		filters.push([item.end_date_field, '>=', startDate]);
		filters.push([item.start_date_field, 'is', 'set']);
		return requestDocList(
			local_meta,
			filters,
			{
				fields: [['name', item.document], ...fields],
				group: [],
				order,
			},
		);
	});
	const results = await Promise.all(requestArr.filter(Boolean));
	const pretreat = [initData].flat()
		.filter(Boolean) as GlobalView.Pretreat[];
	const pretreatPromise = [];
	for (const [index, res] of results.entries()) {
		if (pretreat.length) {
			const docMeta = setting.value?.doctypes[index].meta;
			pretreatPromise.push(Promise.all(pretreat.map(fn => fn(res!, docMeta!, {}, {}))));
		}
	}
	await Promise.all(pretreatPromise);
	loading.value = false;
	if (updateDoctype) {
		const index = setting.value?.doctypes.findIndex(index=>index.document === updateDoctype);
		[doctypeList.value[index]] = results;
	} else {
		doctypeList.value = results || [];
	}
}
watch(settingName, ()=>{
	getPageSetting();
}, {immediate: true});

async function getPageSetting() {
	if (!settingName.value) {
		return;
	}
	userLoading.value = true;
	const res = await frappe.call<{message:PageSetting}>({
		method: 'guigu_pc.guigu_pc.page.guigu_leader_work.guigu_leader_work.get_page_setting',
		args: {
			setting_name: settingName.value,
		},
	});
	if (!res?.message) {
		return;
	}
	const result:PageSetting = res?.message;
	const modelPromiseArr = result.doctypes?.map(item=>new Promise(resolve => frappe.model.with_doctype(item.document, resolve))) || [];
	await Promise.all(modelPromiseArr);

	for (const item of result.doctypes) {
		const local_meta = frappe.get_meta(item.document);
		item.meta = local_meta;
	}
	const columnsModelPromiseArr = result.doctypes?.flatMap(item=>item.columns.map(col=>{
		const field = item.meta?.fields?.find(field=>field.fieldname === col.field);
		if (!field) {
			return;
		}
		if (!['Link', 'Tree Select', 'Table MultiSelect'].includes(field?.fieldtype)) {
			return;
		}
		if (!field.options) {
			return;
		}
		return new Promise(resolve => frappe.model.with_doctype(field.options!, resolve));
	}).filter(Boolean)) || [];
	await Promise.all(columnsModelPromiseArr);
	result.users.unshift({
		name: 'all',
		full_name: `${__('View All User')}(${result.users?.length || 0})`,
	});
	setting.value = result;
	if (!user.value) {
		user.value = setting.value.users?.[0]?.name || '';
	}
	userLoading.value = false;
}
const title = computed(()=>setting.value.title || __('Leader Work Page'));

const popstateListener = function () {
	getDocumentData();
};
onMounted(() => {
	window.addEventListener('popstate', popstateListener);
});
onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});

frappe.realtime.on('doc_update', param => {
	const documents = setting.value.doctypes.map(item=>item.document);
	if (!documents.includes(param.doctype)) {
		return;
	}
	getDocumentData(param.doctype);
});
frappe.realtime.on('list_update', param => {
	const documents = setting.value.doctypes.map(item=>item.document);
	if (!documents.includes(param.doctype)) {
		return;
	}
	getDocumentData(param.doctype);
});

const padding = computed(()=>isFullscreen.value ? '8px' : '0px');
</script>

<style lang='less' scoped>
.title {
	height: 75px;
	line-height: 75px;
	margin-bottom: 0;
}
.table-container{
	height:100%;
	background: #fff;
	padding: v-bind(padding);
	overflow:auto;
	:deep(.el-loading-mask){
		z-index:1000;
	}
}
</style>
