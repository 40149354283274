import { App, shallowRef, createApp, h } from 'vue';
import { ElConfigProvider } from 'element-plus';
// @ts-ignore
import zhCN from 'element-plus/dist/locale/zh-cn';

import * as components from '../components/install';


function createWidgetDialog (
	ConfigComponent: any, title: string,
) {
	return function WidgetDialog(value?: any) {
		const editing = Boolean(value);
		let label = `${editing ? 'Edit' : 'Add'} ${frappe.model.unscrub(title)}`;
		const labelI18n = __(label);
		return new Promise(resolve => {
			let end = false;
			let dialog: frappe.ui.Dialog<any> | undefined;
			let mounted = false;
			let vue: App | undefined;
			let removed = false;
			function remove() {
				if (!mounted) { return; }
				if (removed) { return; }
				removed = true;
				dialog?.hide();
				vue?.unmount();
			}
			function ended() {
				if (end) { return false; }
				end = true;
				remove();
				return true;
			}
			const modelValue = shallowRef(value);
			const submittable = shallowRef(true);
			const onCancel = () => {
				if (!ended()) { return; }
				resolve(null);
			};
			const onSubmit = () => {
				if (!ended()) { return; }
				if (!submittable.value) { return; }
				resolve(modelValue.value || {});
			};
			const onUpdate = (data: any, isSubmittable?: boolean) => {
				modelValue.value = data;
				if (typeof isSubmittable === 'boolean') {
					submittable.value = isSubmittable;
				}
			};
			vue = createApp({
				setup() {
					return () => h(ElConfigProvider, {
						locale: zhCN,
					}, () => h(ConfigComponent, {
						value: value,
						modelValue: modelValue.value,
						'onUpdate:modelValue': onUpdate,
						onUpdate,
						onCancel,
						onSubmit,
					}));
				},
			});
			for (const [name, component] of Object.entries(components)) {
				vue.component(name, component);
			}
			dialog = new frappe.ui.Dialog({
				title: __(labelI18n),
				fields: [{ fieldname: 'main', fieldtype: 'HTML', label: '' }],
				primary_action: onSubmit,
				primary_action_label: __(editing ? 'Save' : 'Add'),
				on_hide: onCancel,
			});

			const p = (dialog.fields_dict.main as any).wrapper as HTMLElement;
			const div = document.createElement('div');
			vue.mount(p.appendChild(div));
			mounted = true;
			if (end) { remove(); }
			dialog.show();
			//@ts-ignore
			window.cur_dialog = dialog;
		});
	};
}

export default createWidgetDialog;
