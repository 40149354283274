<template>
	<div class="body" v-if="doctype&&formVisible">
		<component v-if="doctype" :is="layoutComponent">
			<!-- 公共的 detail 模板 -->
			<template v-if="detailName&&docMeta" #detail>
				<FormDetail
					v-if="docMeta"
					:meta="docMeta"
					isDialog
					:name="detailName"
					v-model:visible="formVisible"
					@onChange="onChange" />
			</template>
		</component>
	</div>
</template>

<script setup lang="ts">
import {ref, computed, watch} from 'vue';

import FormDetail from '../FormDetail/index.vue';
import type {ChangeValue} from '../FormDetail/type';

import DrawerDetail from './DrawerVisible.vue';
import DialogDetail from './DialogVisible.vue';

const props = defineProps<{
	layout?: 'dialog' | 'drawer';
	doctype: string;
	detailName?:string
	visible:boolean
}>();

const emit = defineEmits<{
	(event: 'onChange', data: ChangeValue): void;
	(event: 'update:visible', visible: boolean): void;
}>();

const docMeta = ref<locals.DocType | null | undefined>();

watch(()=>props.doctype, ()=>{
	getMeta(props.doctype);
}, {immediate: true});

async function getMeta(docType: string) {
	frappe.model.with_doctype(docType, () => {
		const meta = frappe.get_meta(docType);
		docMeta.value = meta;
	});
}


const layoutComponent = computed(() => props.layout === 'drawer' ? DrawerDetail : DialogDetail);

function onChange(value: ChangeValue) {
	emit('onChange', value);
}

const formVisible = computed({
	get() {
		return props.visible;
	},
	set(value:boolean) {
		emit('update:visible', value);
	},
});

</script>
<style scoped lang="less">
.body {
	overflow: hidden;
	flex: 1;

	display: flex;
	flex-direction: column;
	border-radius: var(--border-radius-md);
	box-shadow: var(--card-shadow);
	background-color: var(--card-bg);

}
</style>
