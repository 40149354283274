export function parseDisplayField(t?: string, filter?: boolean): GlobalView.Field[] {
	if (!t || typeof t !== 'string') { return []; }

	const numDef = filter ? undefined : 0;
	return t.split('\n').filter(Boolean).map(v => {
		const [field, ...options] = v.split(/\s*[,，]\s*/);
		const enableLink = options.includes('enableLink');
		const showLabel = options.includes('showLabel');
		const group = options.includes('group');
		const size = options.find(v => /^\s*\d*\s*[:：]\s*\d*\s*[:：]\s*\d*\s*$/);
		const [width, minWidth, maxWidth] = (size || '')
			.split(/\s*[:：]\s*/).map(v => parseInt(v) || numDef);
		return {
			field,
			enableLink, showLabel, group,
			width, minWidth, maxWidth,

			enable_link: enableLink,
			show_label: showLabel,
			min_width: minWidth,
			max_width: maxWidth,
		};
	});
}
export function displayField2str(list?: GlobalView.Field[]): string {
	if (!list) { return ''; }
	return list.map(({
		field,
		enableLink,
		showLabel,
		group,
		width,
		minWidth,
		maxWidth,
	}) => {
		const list = [
			field,
			[Math.max(0, width || 0), Math.max(0, minWidth || 0), Math.max(0, maxWidth || 0)]
				.map(v => Number.isSafeInteger(v) && v > 0 ? v : 0).join(':'),
		];
		if (enableLink) {
			list.push('enableLink');
		}
		if (showLabel) {
			list.push('showLabel');
		}
		if (group) {
			list.push('group');
		}
		return list.join(',');
	}).join('\n');
}
