interface Group {
	filter: frappe.ui.FilterGroup,
	el: HTMLElement
}
function createGroup(
	doctype: string,
	on_change: () => void,
	onDelete: (t: Group) => void,
) {
	const el = document.createElement('div');
	el.setAttribute('style', `padding-block-start: 2em; display:flex; align-items:center;`);
	const div = document.createElement('div')
	div.setAttribute('style', `border:1px solid #ccc; border-radius:4px; padding:8px; flex:1`);
	const parent = el.appendChild(div);
	const filter = new frappe.ui.FilterGroup({
		parent: $(parent),
		doctype,
		on_change,
		base_list: [] as any,
	});
	const group: Group = { filter, el };
	const button = document.createElement('button');
	const clearBtn = el.querySelector('.clear-filters');
	if (clearBtn?.parentElement) {
		clearBtn.parentElement.insertBefore(button, clearBtn);
	} else {
		el.appendChild(button);
	}
	button.innerText = '移除分组';
	button.className = 'btn btn-secondary btn-xs';
	button.style.marginInlineEnd = '8px';
	button.addEventListener('click', () => { onDelete(group); el.remove(); });
	return group;
}
function addFilters(
	filter: frappe.ui.FilterGroup,
	value: [string, string, string, any][],
) {
	filter.add_filters_to_filter_group(value.map(([
		doctype, field, condition, value,
	]) => [
			doctype, field, condition, value, false,
		]));
}
export default class MultiOrFilters {
	// value: [string, string, string, any][][]
	_groups: Group[] = [];
	doctype = '';
	_button = document.createElement('button');
	_change: () => void;
	constructor(
		root: HTMLElement,
		doctype: string,
		onChange?: () => void
	) {
		this.doctype = doctype;

		const button = root.appendChild(this._button);
		button.className = 'btn btn-xs';
		button.setAttribute('style', 'margin-block-start:16px; color:#409eff; box-shadow:none;')
		button.innerText = '添加或筛选分组';
		this._change = typeof onChange === 'function'
			? () => onChange()
			: () => { };
		button.addEventListener('click', () => this._add());
	}
	_add(value?: [string, string, string, any][]) {
		const groups = this._groups;
		const button = this._button;
		const parent = button.parentElement;
		if (!parent) { return; }
		const group = createGroup(this.doctype, this._change, group => {
			const index = groups.indexOf(group);
			if (index < 0) { return; }
			groups.splice(index, 1);
			this._change();
		});
		const div = document.createElement('div')
		div.setAttribute('style', `padding: 8px; border:1px solid #ccc; border-radius:4px; margin-inline-end:8px`);
		div.innerHTML='或'
		group.el.prepend(div)
		parent.insertBefore(group.el, button);

		if (value) { addFilters(group.filter, value); }
		groups.push(group);
	}
	set value(values: [string, string, string, any][][]) {
		const groups = this._groups;
		let i = 0;
		let min = Math.min(values.length, groups.length);
		for (; i < min; i++) {
			const { filter } = groups[i];
			filter.clear_filters();
			addFilters(filter, values[i]);
		}
		for (; i < groups.length; i++) {
			groups[i].el.remove();
		}
		for (; i < values.length; i++) {
			this._add(values[i]);
		}
	}
	get value() {
		return this._groups.map(({ filter }) => {
			const filters = filter.get_filters();
			if (!Array.isArray(filters)) { return []; }
			try {
				return filters.map(([
					doctype, field, condition, value,
				]) => ([
					doctype, field, condition, value,
				]));
			} catch {
				return [];
			}
		});
	}
}
