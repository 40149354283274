import { toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElDropdown"], {
    onCommand: $setup.command,
    trigger: "click"
  }, {
    dropdown: _withCtx(() => [
      _createVNode($setup["ElDropdownMenu"], null, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.options, (item) => {
            return _createVNode($setup["ElDropdownItem"], {
              key: item.value,
              command: item.value,
              style: _normalizeStyle({ backgroundColor: $setup.currentSelected === item.label ? '#eeeeee' : '' })
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.tt(item.label)), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["command", "style"])
          }), 64 /* STABLE_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _createVNode($setup["ElButton"], { size: "small" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.tt($setup.currentSelected) || $setup.tt('Layout')) + " ", 1 /* TEXT */),
          _createVNode($setup["ElIcon"], { class: "el-icon--right" }, {
            default: _withCtx(() => [
              _createVNode($setup["ArrowDown"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}