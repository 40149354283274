frappe.form.formatters.TreeSelect = function (value, docfield, options, doc) {
	var doctype = docfield._options || docfield.options;
	var original_value = value;
	let link_title = frappe.utils.get_link_title(doctype, value);

	if (value && value.match && value.match(/^['"].*['"]$/)) {
		value.replace(/^.(.*).$/, '$1');
	}

	if (options && (options.for_print || options.only_value)) {
		return link_title || value;
	}

	if (frappe.form.link_formatters[doctype]) {
		// don't apply formatters in case of composite (parent field of same type)
		if (doc && doctype !== doc.doctype) {
			value = frappe.form.link_formatters[doctype](value, doc, docfield);
		}
	}

	if (!value) {
		return '';
	}
	if (value[0] == "'" && value[value.length - 1] == "'") {
		return value.substring(1, value.length - 1);
	}
	if (docfield && docfield.link_onclick) {
		return repl('<a onclick="%(onclick)s">%(value)s</a>', {
			onclick: docfield.link_onclick.replace(/"/g, '&quot;'),
			value,
		});
	} else if (docfield && doctype) {
		if (frappe.model.can_read(doctype)) {
			return `<a
				href="/app/${encodeURIComponent(frappe.router.slug(doctype))}/${encodeURIComponent(
				original_value
			)}"
				data-doctype="${doctype}"
				data-name="${original_value}"
				data-value="${original_value}">
				${__((options && options.label) || link_title || value)}</a>`;
		}
			return link_title || value;

	}
		return link_title || value;

};
