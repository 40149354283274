import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { style: { height: '100%'} }
const _hoisted_2 = { style: {"max-width":"300px"} }
const _hoisted_3 = { style: {"max-width":"300px"} }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  style: {"max-width":"100%","display":"inline-block","text-overflow":"ellipsis","overflow":"hidden","white-space":"nowrap"}
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElOption = _resolveComponent("ElOption")
  const _component_ElSelect = _resolveComponent("ElSelect")
  const _component_el_tooltip = _resolveComponent("el-tooltip")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", {
      onMouseover: $setup.handleMouseOver,
      onMouseout: $setup.handleMouseOut,
      style: { height: '100%', display: 'flex' }
    }, [
      _withDirectives(_createVNode(_component_ElSelect, {
        ref: "selectRef",
        onChange: $setup.change,
        onFocus: $setup.focus,
        onBlur: $setup.blur,
        modelValue: $setup.selectValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.selectValue) = $event))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.workList, (option) => {
            return (_openBlock(), _createBlock(_component_ElOption, {
              key: option.name,
              label: option.subject,
              value: option.name
            }, null, 8 /* PROPS */, ["label", "value"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"]), [
        [_vShow, $setup.isSelectShow || $setup.isSelectFocus]
      ])
    ], 544 /* HYDRATE_EVENTS, NEED_PATCH */), [
      [_vShow, $setup.isShowSelect]
    ]),
    _createVNode(_component_el_tooltip, {
      ref: "toolTipRef",
      placement: "top-start"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.cellData.context, (item, index) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_3, [
              ($setup.type === 'month')
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(index + 1) + ".", 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
              _createTextVNode(_toDisplayString(item), 1 /* TEXT */)
            ]))
          }), 256 /* UNKEYED_FRAGMENT */))
        ])
      ]),
      default: _withCtx(() => [
        ($setup.type !== 'month')
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($props.params.value), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        _withDirectives(_createElementVNode("a", {
          style: {"max-width":"100%","text-overflow":"ellipsis","overflow":"hidden","white-space":"nowrap"},
          onDblclick: $setup.changeWork,
          onClick: $setup.gotoWork
        }, _toDisplayString($props.params.value || $setup.tt('No Progress Yet')), 545 /* TEXT, HYDRATE_EVENTS, NEED_PATCH */), [
          [_vShow, !$setup.isShowSelect]
        ])
      ]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */)
  ]))
}