import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c22770d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "permission_type_container" }
const _hoisted_2 = { class: "permission_container" }
const _hoisted_3 = {
  key: 0,
  class: "permission_type_container"
}
const _hoisted_4 = { class: "permission_container" }
const _hoisted_5 = { class: "permission_type_container" }
const _hoisted_6 = { class: "permission_container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElDialog"], {
    modelValue: $setup.dialogVisible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.dialogVisible) = $event)),
    title: $setup.tt('Permission'),
    destroyOnClose: "",
    onClose: $setup.cancel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h5", null, _toDisplayString($setup.tt('Inherit Permissions:')), 1 /* TEXT */),
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.members.inherit_members, (inherit_member) => {
            return (_openBlock(), _createElementBlock("div", null, [
              _createElementVNode("h6", null, _toDisplayString($setup.tt('Inherited from')) + "}" + _toDisplayString(inherit_member.organization_doc.label) + ":", 1 /* TEXT */),
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.permissions, (p) => {
                  return _createElementVNode("div", null, [
                    _createElementVNode("span", null, _toDisplayString($setup.tt(p.label)) + ":", 1 /* TEXT */),
                    _createElementVNode("span", null, _toDisplayString(inherit_member[p.value] === 1
									? $setup.tt('Yes')
									: $setup.tt('No')), 1 /* TEXT */)
                  ])
                }), 64 /* STABLE_FRAGMENT */))
              ])
            ]))
          }), 256 /* UNKEYED_FRAGMENT */))
        ])
      ]),
      ($setup.members.organization_members)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("h5", null, _toDisplayString($setup.tt('Authority of this organization:')), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.permissions, (p) => {
                return _createElementVNode("div", null, [
                  _createElementVNode("span", null, _toDisplayString($setup.tt(p.label)) + ":", 1 /* TEXT */),
                  _createElementVNode("span", null, _toDisplayString($setup.members?.organization_members?.[p.value] === 1
							? $setup.tt('Yes')
							: $setup.tt('No')), 1 /* TEXT */)
                ])
              }), 64 /* STABLE_FRAGMENT */))
            ])
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h5", null, _toDisplayString($setup.tt('After Combination Permissions:')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.permissions, (p) => {
            return _createElementVNode("div", null, [
              _createElementVNode("span", null, _toDisplayString($setup.tt(p.label)) + ":", 1 /* TEXT */),
              _createElementVNode("span", null, _toDisplayString($setup.unionPermissions[p.value] === 1 ? $setup.tt('Yes') : $setup.tt('No')), 1 /* TEXT */)
            ])
          }), 64 /* STABLE_FRAGMENT */))
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}