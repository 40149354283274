import { computed, shallowRef, Ref, unref } from 'vue';

export default function useLimit(
	maxLimit: number | Ref<number>,
	defaultLimit?: number | Ref<number>,
) {
	/** 每页条数 */
	const limit = shallowRef<number>(unref(defaultLimit) || unref(maxLimit));
	/** 每页条数 */
	return computed({
		get() { return limit.value; },
		set(v) { limit.value = v <= 0 ? unref(maxLimit) : Math.min(unref(maxLimit), v); },
	});
}
