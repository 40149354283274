frappe.views.Workspace.prototype.get_parent_pages = function (page) {
	this.public_parent_pages = [
		'',
		...this.public_pages.map(p => p.title),
	];
	this.private_parent_pages = [
		'',
		...this.private_pages.map(p => p.title),
	];

	if (page) {
		return page.public ? this.public_parent_pages : this.private_parent_pages;
	}
};
