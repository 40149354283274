import type { Ref } from 'vue';
import { computed, unref, watch, provide, inject } from 'vue';


const minMs = 1000;
const minHeight = 100;

function readShown(root: DOMRect, target: DOMRect): boolean {
	if (target.height <= 0 || target.width <= 0) { return false; }
	if (root.top <= target.top && root.bottom >= target.bottom) {
		return true;
	}
	if (root.top <= target.top && root.bottom >= target.bottom) {
		return true;
	}
	if (root.top <= target.top && root.top - target.bottom >= minHeight) {
		return true;
	}
	if (root.bottom >= target.bottom && target.top - root.bottom >= minHeight) {
		return true;
	}
	return false;
}

const symbol = Symbol();
export default function useIntersection(
	timeLineEl: HTMLElement | Ref<HTMLElement | undefined> | undefined,
) {
	const observerList = new Map<Element, () => void>();
	const observerTimeout = new Map<Element, number>();
	function update(rect: DOMRect, target: Element, io?: IntersectionObserver) {
		const isRead = readShown(rect, target.getBoundingClientRect());
		if (observerTimeout.has(target) === isRead) { return; }
		if (!isRead) { observerTimeout.delete(target); return; }
		observerTimeout.set(target, setTimeout(() => {
			observerTimeout.delete(target);
			const cb = observerList.get(target);
			observerList.delete(target);
			io?.unobserve(target);
			if (cb) {
				cb();
			}
		}, minMs));
	}
	const updateAll = (io?: IntersectionObserver) => {
		const root = unref(timeLineEl);
		if (!root) { return; }
		const rect = root.getBoundingClientRect();
		for (const target of observerList.keys()) {
			update(rect, target, io);
		}
	};
	const io = computed(() => {
		const root = unref(timeLineEl);
		if (!root) { return; }
		const io = new IntersectionObserver(() => { updateAll(io); }, { root });
		updateAll(io);
		for (const a of observerList.keys()) {
			io.observe(a);
		}
		return io;
	});
	watch(io, (_, io) => {
		if (!io) { return; }
		io.disconnect();
	});
	watch(() => {
		const root = unref(timeLineEl);
		if (!root) { return; }
		const listener = () => updateAll(io?.value)
		root.addEventListener('scroll', listener);
		return () => {
			root.removeEventListener('scroll', listener);
		};
	}, (_, remove) => { remove?.(); });
	watch(() => {
		const root = unref(timeLineEl);
		if (!root) { return; }
		const ro = new ResizeObserver(() => {});
		ro.observe(root);
		return () => ro.disconnect()
	}, (_, remove) => { remove?.(); });
	function observe(target: HTMLElement, cb: () => void) {
		observerList.set(target, cb);
		io.value?.observe(target);
		const root = unref(timeLineEl);
		if (root) { update(root.getBoundingClientRect(), target,io?.value); }
		return () => {
			observerList.delete(target);
			io.value?.unobserve(target);
		};
	}
	function unobserve(target: HTMLElement) {
		observerList.delete(target);
		io.value?.unobserve(target);
	}
	provide<Observer>(symbol, {observe, unobserve});
}
interface Observer{
	observe(target: HTMLElement, cb: () => void): () => void
	unobserve(target: HTMLElement): void
}

export function useObserve(
	doctype: string | Ref<string>,
	name: string | Ref<string>,
	looked: boolean | Ref<boolean>,
	updated: any | Ref<any>,
	el: HTMLElement | Ref<HTMLElement | undefined> | undefined,
) {
	const observer = inject<Observer>(symbol);
	if (!observer) { return; }
	watch(() => {
		const root = unref(el);
		if (!root || unref(looked)) { return; }
		return observer.observe(root, () => {
			frappe.call('guigu_pm.doc.set_seen', {
				doctype: unref(doctype),
				name: unref(name),
				date: unref(updated),
			});
		});
	}, (_, c) => c?.());


}
