<template>
	<div class="toolbar">
		<ElSelect filterable :modelValue="project" @change="changeProject">
			<ElOption
				v-for="item in projects"
				:key="item.name"
				:label="item.project_name"
				:value="item.name" />
		</ElSelect>

		<ElSelect :modelValue="pageType" @change="pageTypeChange">
			<ElOption
				value="month"
				key="month"
				label="月度" />
			<ElOption
				value="year"
				key="year"
				label="年度" />
		</ElSelect>
		<ElSelect :modelValue="countCategory" @change="countCategoryChange">
			<ElOption
				value="date"
				key="date"
				label="按照日期" />
			<ElOption
				value="category"
				key="category"
				label="按照考勤类别" />
		</ElSelect>
		<el-date-picker
			v-if="pageType === 'month'"
			v-model="monthValue"
			@change="monthChange"
			type="month"
			:clearable="false"
			placeholder="选择月">
		</el-date-picker>

		<el-date-picker
			v-if="pageType === 'year'"
			v-model="yearValue"
			@change="yearChange"
			type="year"
			:clearable="false"
			placeholder="选择年">
		</el-date-picker>
		<el-tooltip content="日志详情" class="calendar">
			<el-button :icon="Calendar" circle
				@click="navigateToRecord"></el-button>/>
		</el-tooltip>
		<div class="refresh">
			<el-button-group class="ml-4">
				<el-button :loading="loading" @click="exportTable">导出</el-button>
				<el-button :loading="loading" @click="refresh"
					:icon="RefreshRight">
					刷新
				</el-button>
			</el-button-group>
		</div>
	</div>
</template>
<script lang="ts" setup>

import { ref, onMounted, toRefs } from 'vue';
import moment from 'moment';
import { RefreshRight, Calendar } from '@element-plus/icons-vue';
import { GridApi } from 'ag-grid-community';

import { Project } from '../../type';

import { routeInit, setRoute } from './helper';

interface Props {
	project?: string
	pageType?: string
	countCategory?: string
	monthDate?: string
	yearDate?: string
	refreshValue?: number
	detailGridApi?: GridApi
	loading: boolean
}

const props = defineProps<Props>();
const { project, pageType, monthDate, yearDate, refreshValue, detailGridApi, loading } = toRefs(props);

interface Emit {
	(e: 'update:project', project: string): void,
	(e: 'update:monthDate', monthDate: string): void,
	(e: 'update:yearDate', yearDate: string): void,
	(e: 'update:pageType', type: string): void,
	(e: 'update:countCategory', countCategory: string): void,
	(e: 'update:refreshValue', refreshValue: number): void,
	(e: 'update:loading', loading: boolean): void,
}

const emit = defineEmits<Emit>();

const projects = ref<Project[]>();
const monthValue = ref<string>(monthDate?.value || moment());
const yearValue = ref<string>(yearDate?.value || '');

const paramsFilter = ref<{ [key: string]: string }>({});
async function onRouteChange() {
	routeInit(paramsFilter);
	const projectsRes: Project[] = await frappe.db.get_list('Project',
		{
			fields: ['name', 'project_name'],
			filters: { status: 'Open' },
			limit: '',
		},
	);
	projects.value = projectsRes;
	const projectNames = projectsRes.map(item => item.name);
	const sessionDefaultProject: string = (frappe.defaults.get_user_defaults('project') || [])[0];
	let defaultProject: string = '';
	if (projectNames.includes(paramsFilter.value?.project)) {
		defaultProject = paramsFilter.value?.project;
	} else {
		defaultProject = sessionDefaultProject || projectsRes[0]?.name || '';
	}
	let defaultPageType: string = paramsFilter.value?.pageType || props.pageType || 'month';
	let defaultCountCategory: string = paramsFilter.value?.countCategory || props.countCategory || 'date';
	let monthDate: string = paramsFilter.value?.monthDate || props.monthDate || moment().format('YYYY-MM');
	let yearDate: string = paramsFilter.value?.yearDate || props.yearDate || moment().format('YYYY');
	let routeObj: { [key: string]: any } = {};
	monthValue.value = monthDate;
	yearValue.value = yearDate;
	if (defaultPageType === 'month') {
		routeObj = { project: defaultProject, pageType: defaultPageType, monthDate, yearDate };
		emit('update:pageType', 'month');
	} else {
		routeObj = { project: defaultProject, pageType: defaultPageType, monthDate, yearDate };
		emit('update:pageType', 'year');
	}
	emit('update:countCategory', defaultCountCategory);
	emit('update:monthDate', moment(monthDate).format('YYYY-MM'));
	emit('update:yearDate', yearDate);
	emit('update:project', defaultProject);
	setRoute(paramsFilter, routeObj);
}

onMounted(() => {
	onRouteChange();
});

frappe.router?.on('change', async () => {
	const [pageName] = frappe.router?.current_route || [];
	if (pageName === 'project_daily_record_statistics') {
		await onRouteChange();
	}
});

function exportTable() {
	detailGridApi?.value?.exportDataAsExcel();
}


function changeProject(newProject: string) {
	setRoute(paramsFilter, { project: newProject });
	emit('update:project', newProject);
}

function monthChange(date: moment) {
	const dateValue = date || moment();
	setRoute(paramsFilter, { monthDate: moment(dateValue).format('YYYY-MM') });
	monthValue.value = dateValue;
	emit('update:monthDate', moment(dateValue).format('YYYY-MM'));
}

function yearChange(date: moment) {
	const dateValue = date || moment();
	setRoute(paramsFilter, { yearDate: moment(dateValue).format('YYYY') });
	yearValue.value = dateValue;
	emit('update:yearDate', moment(dateValue).format('YYYY'));
}
function getLastDayOfMonth(year: number, month: number) {
	const date = new Date(year, month - 1, 2);
	date.setMonth(date.getMonth() + 1);
	date.setDate(date.getDate() - 1);
	return date;
}
function getYearLastDate(year: number) {
	return new Date(year, 11, 32);
}
function setRouteForPageType(pageType: string, dateStr: string | number | Date) {
	const currentDate = new Date();
	const targetDate = new Date(dateStr);
	const todayDate = targetDate < currentDate ? targetDate : currentDate;
	frappe.set_route([
		'project_work_daily_record',
		'project',
		paramsFilter.value?.project,
		'date',
		todayDate.toISOString().split('T')[0],
	]);

}
function navigateToRecord() {
	if (paramsFilter.value?.pageType === 'month') {
		const monthDateStr = paramsFilter.value?.monthDate;
		const [year, month] = monthDateStr.split('-').map(Number);
		const lastDayOfLastMonth = getLastDayOfMonth(year, month);
		setRouteForPageType(paramsFilter.value?.pageType, lastDayOfLastMonth.toISOString().split('T')[0]);
	} else if (paramsFilter.value?.pageType === 'year') {
		const yearDateStr = paramsFilter.value?.yearDate;
		const year = Number(yearDateStr);
		const lastDayOfYear = getYearLastDate(year);
		setRouteForPageType(paramsFilter.value?.pageType, lastDayOfYear.toISOString().split('T')[0]);
	}
}

function pageTypeChange(type: string) {
	setRoute(paramsFilter, { pageType: type });
	emit('update:pageType', type);
}

function countCategoryChange(cc: string) {
	setRoute(paramsFilter, { countCategory: cc });
	emit('update:countCategory', cc);
}

function refresh() {
	const newValue: number = (refreshValue?.value || 0) + 1;
	emit('update:loading', true);
	emit('update:refreshValue', newValue);
}
</script>
<style lang="less" scoped>
.toolbar {
	padding: 8px 0;
	.calendar{
		margin-left: 10px;
	}
}

.refresh {
	float: right
}
</style>
