<template>
	<ElDropdown @command="command" v-if="hasPerm">
		<ElIcon class="icon"><MoreFilled /></ElIcon>
		<template #dropdown>
			<ElDropdownMenu>
				<ElDropdownItem v-if="addable" command="add">{{ tt('Add Child') }}</ElDropdownItem>
				<ElDropdownItem v-if="copyable" command="copy">{{ tt('Copy') }}</ElDropdownItem>
				<ElDropdownItem v-if="deletable" command="del">{{ tt('Delete') }}</ElDropdownItem>
			</ElDropdownMenu>
		</template>
	</ElDropdown>
</template>

<script lang="ts" setup>
import {computed} from 'vue';
import {MoreFilled} from '@element-plus/icons-vue';
import {ElDropdown, ElDropdownMenu, ElDropdownItem, ElIcon} from 'element-plus';

const tt = __;

defineOptions({name: 'RowAction'});

const props = defineProps<{
	value: any;
	meta: locals.DocType;
	loading: boolean;
	view?: GlobalView.View;
	configuration?: any;
	treeParent?: string;
}>();
const emit = defineEmits<{
	(event: 'delete', name: string, mode?: 'all' | 'parent' | 'root'): void
	(event: 'refresh'): void
	(event: 'create', data?: Record<string, any>, extend?: boolean): void
}>();
function addChild() {
	const parent = props.treeParent;
	if (!parent) {
		return;
	}
	const value = props.value || {};
	const fields = props.view?.fieldsCopiedWhenCreatingChildren || [];
	const data = Object.fromEntries(fields.map(v => [v, value[v]]));
	data[parent] = props.value.name;
	emit('create', data, true);
}
function copy() {
	const fields = props.view?.fieldsCopiedWhileCopying;
	const value = props.value || {};
	if (!fields?.length) {
		const data = {...value};
		delete data.name;
		emit('create', data, true);
		return;
	}
	const data = Object.fromEntries(fields.map(v => [v, value[v]]));
	emit('create', data, true);
}
const deletable = computed(() => frappe.perm.has_perm(props.meta.name, 0, 'delete'));
const creatable = computed(() => frappe.perm.has_perm(props.meta.name, 0, 'create'));
const hasPerm = computed(() => deletable.value || creatable.value);
const copyable = computed(() => creatable.value && !props.meta.allow_copy);
const addable = computed(() => creatable.value && Boolean(props.treeParent));

function command(v: string) {
	switch (v) {
		case 'del': return emit('delete', props.value.name);
		case 'copy': return copy();
		case 'add': return addChild();
	}
}
</script>
<style scoped>
.icon {
	cursor: pointer;
}
</style>
