<template>
	<span :title="title">
		{{ value }}
	</span>
</template>
<script lang="ts" setup>
import { computed } from 'vue';


const dateRegex = /^(\d+)-(0?[1-9]|1[0-2])-(0?[1-9]|[12]\d|3[01])(\s|T|$)/;
function parseDate(value?: string): Date | undefined {
	if (!value || typeof value !== 'string') { return; }
	const result = dateRegex.exec(value);
	if (!result) { return; }
	const [, Y, M, D] = result;
	const date = new Date(Number(Y), Number(M) - 1, Number(D));

	return date;
}
function getYearWeek(date: Date) {
	for (let year = date.getFullYear() + 1; ; year--) {
		const start = new Date(year, 0, 1);
		const d = Math.floor((Number(date) - Number(start)) / 86400000);
		const n = Math.ceil((d + ((start.getDay() + 1) - 1)) / 7);
		if (n > 0) { return [n, year]; }
	}
}


const props = defineProps<{
	value: any;
	data: Record<string, any>;
	field: GlobalView.FieldRenderOptions;
	meta?: locals.DocType;
	maxLine?: number;
	enableLink?: boolean;
	noData?: boolean;
	inline?: boolean;
	format?: string;
	showTitle?: boolean;
}>();
const emit = defineEmits<{
	(event: 'filter', field: string, operator: string, value: any): void;
}>();
const field = computed(() => props.field);
const value = computed(() => {
	const v = props.value;
	if (!v || typeof v !== 'string') { return v; }
	const {format} = props;
	if (format) { return moment(v).format(format); }
	switch (field.value.options) {
		case 'Year':
			return v.replace(/-.*/, '');
		case 'Month':
			return v.replace(/^(\d+-\d+).*/, '$1');
		case 'Week': {
			const date = parseDate(v);
			if (!date) { return v; }
			return getYearWeek(date).reverse().join('-');
		}
	}
	return v;
});
const title = computed(() => {
	if (!props.showTitle) { return; }
	return `${__(field.value.label)}: ${value.value}`;
});
function setFilter() {
	emit('filter', props.field.fieldname, '=', props.value);

}
const tt = __;

</script>
