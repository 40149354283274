import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "report-info_label" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_viewer = _resolveDirective("viewer")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, "6. " + _toDisplayString($setup.tt("Scene Actual Photo")), 1 /* TEXT */),
    _withDirectives((_openBlock(), _createElementBlock("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.images, (photo) => {
        return (_openBlock(), _createBlock($setup["ImageItem"], { photo: photo }, null, 8 /* PROPS */, ["photo"]))
      }), 256 /* UNKEYED_FRAGMENT */))
    ])), [
      [_directive_viewer]
    ])
  ], 64 /* STABLE_FRAGMENT */))
}