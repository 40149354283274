import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d55e521"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar" }
const _hoisted_2 = { class: "input-container" }
const _hoisted_3 = { class: "input-container" }
const _hoisted_4 = { class: "input-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString($setup.tt('Status')) + ": ", 1 /* TEXT */),
      _createVNode($setup["ElSelect"], {
        class: "input",
        filterable: "",
        clearable: "",
        modelValue: $setup.taskStateValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.taskStateValue) = $event)),
        size: "small",
        placeholder: $setup.tt('Please Select')
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.taskStateList, (item) => {
            return (_openBlock(), _createBlock($setup["ElOption"], {
              key: item.name,
              label: item.status_name,
              value: item.name
            }, null, 8 /* PROPS */, ["label", "value"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "placeholder"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createTextVNode(_toDisplayString($setup.tt('Priority')) + ": ", 1 /* TEXT */),
      _createVNode($setup["ElSelect"], {
        class: "input",
        filterable: "",
        clearable: "",
        modelValue: $setup.taskPriorityValue,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.taskPriorityValue) = $event)),
        size: "small",
        placeholder: $setup.tt('Please Select')
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.taskPriorityList, (item) => {
            return (_openBlock(), _createBlock($setup["ElOption"], {
              key: item,
              label: $setup.tt(item),
              value: item
            }, null, 8 /* PROPS */, ["label", "value"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "placeholder"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createTextVNode(_toDisplayString($setup.tt('Assignee')) + ": ", 1 /* TEXT */),
      _createVNode($setup["ElSelect"], {
        class: "input",
        filterable: "",
        clearable: "",
        modelValue: $setup.taskAssigneeValue,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.taskAssigneeValue) = $event)),
        size: "small",
        placeholder: $setup.tt('Please Select')
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.taskAssigneeList, (item) => {
            return (_openBlock(), _createBlock($setup["ElOption"], {
              key: item.user,
              label: item.user_name,
              value: item.user
            }, null, 8 /* PROPS */, ["label", "value"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "placeholder"])
    ])
  ]))
}