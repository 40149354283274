<template>
	<ElSpace>
		<ElDatePicker
			v-model="date"
			valueFormat="YYYY-MM-DD" />
		<ProjectCom
			class="project-select"
			default
			noLabel
			v-model="selectedProject"
		></ProjectCom>
	</ElSpace>
</template>

<script setup lang='ts'>
import {ref, watch, defineEmits, onMounted, watchEffect, onUnmounted, computed} from 'vue';
import moment from 'moment';

import ProjectCom from '../../../../../../../guigu/guigu/public/js/components/PageComponents/Tools/Project.vue';
interface Project {
	name: string,
	project_name: string
	project_abbr_name: string
}
interface Emit {
	(e: 'projectChange', project: string): void
	(e: 'dateChange', date: string): void
}
const emit = defineEmits<Emit>();
const selectedProject = ref<string>();
const date = ref<string>();

watch(() => location.search, () => {
	const searchParams = new URLSearchParams(location.search);
	const searchDate = searchParams.get('date');
	const searchProject = searchParams.get('project');
	if (!searchDate) {
		date.value = moment().subtract(1, 'days').format('YYYY-MM-DD');
	}
	if (searchDate && searchDate !== date.value) {
		date.value = searchDate;
	}
	if (searchProject && searchProject !== selectedProject.value) {
		selectedProject.value = searchProject;
	}
}, {immediate: true});
watchEffect(() => {
	if (!selectedProject.value) {
		return;
	}
	emit('projectChange', selectedProject.value);
});
watchEffect(() => {
	if (!date.value) {
		return;
	}
	emit('dateChange', date.value);
});

</script>

<style lang='less' scoped>
</style>
