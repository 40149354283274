<template>
	<input :ref="input" class="simple-input-editor" @input="inputData"
		:style="{width:'100%'}" @keydown="onKeyDown($event)" v-model="value" />
</template>
<script lang="ts">
	import { nextTick,  onMounted, ref, defineProps, defineExpose, defineComponent } from 'vue';
	const KEY_BACKSPACE = 'Backspace';
	const KEY_DELETE = 'Delete';
	const KEY_ENTER = 'Enter';
	const KEY_TAB = 'Tab';
	export default defineComponent({
		props:['params'],
		setup(props, context) {
			const input = ref();
			const value = ref(props.params.value?.toString());
			function getValue(){
				return value.value?.replaceAll('。', '.');
			}
			function inputData(event:any){
				value.value = event.target.value.replaceAll('。', '.');
			}
			onMounted(()=>{
				nextTick(() => {
					input.value?.focus?.();
				});
			});
			function onKeyDown(event:any) {
				if (event.key === 'Escape') {
					return;
				}
				if (isLeftOrRight(event) || deleteOrBackspace(event)) {
					event.stopPropagation();
					return;
				}
				if (
					!isKeyPressedNumeric(event)
				) {
					if (event.preventDefault) { event.preventDefault(); }
				}
			}

			function isCharNumeric(charStr:string) {
				return /^\d*\.?\d*$/.test(charStr);
			}

			function isKeyPressedNumeric(event:any) {
				let charStr = event.key;
				if (charStr === '。'){
					charStr = '.';
				}
				return isCharNumeric(charStr);
			}
			function isLeftOrRight(event) {
				return ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1;
			}
			function deleteOrBackspace(event) {
				return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.key) > -1;
			}
			return {
				input,
				value,
				getValue,
				inputData,
				onKeyDown,
			};
		},
	});


</script>
