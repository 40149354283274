

import mountVueToPage from 'guiguLib/mountVueToPage';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn';

import DailyRecordStatistics from './daily_record_statistics/index.vue';
export default function loadCompanyDailyRecordStatistics(wrapper:HTMLElement) {
	const app = mountVueToPage(wrapper, DailyRecordStatistics);
	app.use(ElementPlus, {locale: zhCn});
	frappe.router.on('change', () => {
		const [pageName] = frappe.router?.current_route || [];
		if (pageName !== 'sub_company_daily_record_statistics') {
			delete frappe?.pages?.sub_company_daily_record_statistics;
			app?.unmount();
		}
	});
	wrapper.classList.add('container');
}

frappe.provide('frappe.guigu_pc');
frappe.guigu_pc.pages = frappe.guigu_pc.pages || {};
frappe.guigu_pc.pages.loadCompanyDailyRecordStatistics = loadCompanyDailyRecordStatistics;
