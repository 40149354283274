import { Ref, ref } from 'vue';
import widget_map, { WidgetDialog } from '../patches/widget_map';
import createVue from './createVue';
import SingleWidgetGroup from './SingleWidgetGroup';
frappe.provide('frappe.guigu');
const Block = Object.getPrototypeOf(frappe.workspace_block.blocks.header)
function defineWidget(
	key: string,
	MainComponent: any,
	ConfigComponent: any,
	{minWidth, col, maxWidgetCount, icon, title}: {
		col?: number;
		minWidth?: number;
		maxWidgetCount?: number;
		icon?: string;
		title?: string;
	} = {}
) {
	class Dialog extends WidgetDialog {
		make() {
			this.make_dialog();
			window.cur_dialog = this.dialog;
		}
		set_default_values() { return this.dialog }
		hide_field() {}
		show_field() {}
		setup_filter() {}
		canceled = false;
		make_dialog() {
			const el = document.body.appendChild(document.createElement('div'));
			let end = false;
			let mounted = false;
			function remove() {
				if (!mounted) { return }
				vue.$destroy();
				vue.$el.remove()
				el.remove();
			}
			const vue = createVue(ConfigComponent, {
				props: { defaultValue: {...this.values} },
				events: {
					cancel: () => {
						if (end) { return; }
						end = true;
						this.canceled = true;
						debugger
						for (const f of [...this.__cancels]) {
							f();
						}
						remove()
					},
					submit: (data: any) => {
						if (end) { return; }
						end = true;
						remove()
						this.primary_action({
							...data,
							name:  this.values?.name
							|| `${this.type}-${this.label}-${frappe.utils.get_random(20)}`
						});
					}
				},
			});
			if (!end) {
				vue.$mount(el);
				mounted = true;
				if (end) { remove() }
			}

			window.cur_dialog?.hide();
			this.dialog = {
				show() {},
				hide() {
					if (end) { return; }
					end = true;
					remove();
				},
			};
		}
		__cancels = new Set<() => void>;
		addCancel(cancel: () => void) {
			if (this.canceled) {
				cancel();
			} else {
				this.__cancels.add(cancel);
			}
		}
	}

	class Fa extends frappe.widget.widget_factory.base {
		values: any;
		vueValue: Ref<any>
		constructor({widget_type, container, options, new: isNew, in_customize_mode, ...values}: any) {
			super({widget_type, container, options, new: isNew, in_customize_mode, ...values});
			this.values = values;
			const value: Ref<any> = ref(this.values);
			this.vueValue =value;
			const vue =createVue(MainComponent, {
				props: { value: value, editing: in_customize_mode },
				provide: {
					__editing__: in_customize_mode,
				},
				events: {
					edit: () => { this.edit(); }
				}
			});
			const el = document.createElement('div');
			this.container.appendChild(el);
			vue.$mount(el);
			// TODO: 监听 this.body 被移除
		}
		get_config() { return this.values; }
		edit() {
			if (!this.in_customize_mode) { return; }
			this.edit_dialog = new Dialog({
				label: this.label,
				type: this.widget_type,
				values: this.get_config(),
				primary_action: (values) => {
					this.label = this.label;
					values.name = this.name;
					this.new = true;
					this.values = values;
					this.vueValue.value = {...values};
					this.refresh();
					this.options.on_edit && this.options.on_edit(values);
				},
				primary_action_label: __("Save"),
			});

			this.edit_dialog.make();
		}


		make() {
			this.make_widget();
			// this.widget.appendTo(this.container);
		}
		make_widget() {
			// this.widget = $(`<div class="widget" data-widget-name="${this.name ? this.name : ""}">
			// 	<div class="widget-head">
			// 		<div class="widget-label">
			// 			<div class="widget-title"></div>
			// 			<div class="widget-subtitle"></div>
			// 		</div>
			// 		<div class="widget-control"></div>
			// 	</div>
			// 	<div class="widget-body"></div>
			// 	<div class="widget-footer"></div>
			// </div>`);

			// this.title_field = this.widget.find(".widget-title");
			// this.subtitle_field = this.widget.find(".widget-subtitle");
			// this.body = this.widget.find(".widget-body");
			// this.action_area = this.widget.find(".widget-control");
			// this.head = this.widget.find(".widget-head");
			// this.footer = this.widget.find(".widget-footer");
			this.refresh();
		}
		customize(options) {
			// this.in_customize_mode = true;
			// this.action_area.empty();

			// options.allow_sorting &&
			// 	frappe.utils.add_custom_button(
			// 		frappe.utils.icon("drag", "xs"),
			// 		null,
			// 		"drag-handle",
			// 		__("Drag"),
			// 		null,
			// 		this.action_area
			// 	);

			// if (options.allow_hiding || true) {
			// 	if (this.hidden) {
			// 		this.widget.removeClass("hidden");
			// 		this.body.css("opacity", 0.5);
			// 		this.title_field.css("opacity", 0.5);
			// 		this.footer.css("opacity", 0.5);
			// 	}
			// 	const classname = this.hidden ? "fa fa-eye" : "fa fa-eye-slash";
			// 	const title = this.hidden ? __("Show") : __("Hide");
			// 	frappe.utils.add_custom_button(
			// 		`<i class="${classname}" aria-hidden="true"></i>`,
			// 		() => this.hide_or_show(),
			// 		"show-or-hide-button",
			// 		title,
			// 		null,
			// 		this.action_area
			// 	);

			// 	this.show_or_hide_button = this.action_area.find(".show-or-hide-button");
			// }

			// options.allow_edit &&
			// 	frappe.utils.add_custom_button(
			// 		frappe.utils.icon("edit", "xs"),
			// 		() => this.edit(),
			// 		"edit-button",
			// 		__("Edit"),
			// 		null,
			// 		this.action_area
			// 	);
		}



		set_title(max_chars?: number) {
			// let base = this.title || this.label || this.name;
			// let title = max_chars ? frappe.ellipsis(base, max_chars) : base;

			// if (this.icon) {
			// 	let icon = frappe.utils.icon(this.icon, "lg");
			// 	this.title_field[0].innerHTML = `${icon} <span class="ellipsis" title="${title}">${title}</span>`;
			// } else {
			// 	this.title_field[0].innerHTML = `<span class="ellipsis" title="${title}">${title}</span>`;
			// 	if (max_chars) {
			// 		this.title_field[0].setAttribute("title", this.title || this.label);
			// 	}
			// }
			// this.subtitle && this.subtitle_field.html(this.subtitle);
		}

		// delete(animate = true, dismissed = false) {
		// 	let remove_widget = (setup_new) => {
		// 		this.widget.remove();
		// 		!dismissed && this.options.on_delete && this.options.on_delete(this.name, setup_new);
		// 	};

		// 	if (animate) {
		// 		this.widget.addClass("zoom-out");
		// 		// wait for animation
		// 		setTimeout(() => {
		// 			remove_widget(true);
		// 		}, 300);
		// 	} else {
		// 		remove_widget(false);
		// 	}
		// }
		// toggle_width() {
		// 	if (this.width == "Full") {
		// 		this.widget.removeClass("full-width");
		// 		this.width = null;
		// 		this.refresh();
		// 	} else {
		// 		this.widget.addClass("full-width");
		// 		this.width = "Full";
		// 		this.refresh();
		// 	}

		// 	const title = this.width == "Full" ? __("Collapse") : __("Expand");
		// 	this.resize_button.attr("title", title);
		// }

		// hide_or_show() {
		// 	if (!this.hidden) {
		// 		this.body.css("opacity", 0.5);
		// 		this.title_field.css("opacity", 0.5);
		// 		this.footer.css("opacity", 0.5);
		// 		this.hidden = true;
		// 	} else {
		// 		this.body.css("opacity", 1);
		// 		this.title_field.css("opacity", 1);
		// 		this.footer.css("opacity", 1);
		// 		this.hidden = false;
		// 	}
		// 	this.show_or_hide_button.empty();

		// 	const classname = this.hidden ? "fa fa-eye" : "fa fa-eye-slash";
		// 	const title = this.hidden ? __("Show") : __("Hide");

		// 	$(`<i class="${classname}" aria-hidden="true" title="${title}"></i>`).appendTo(
		// 		this.show_or_hide_button
		// 	);
		// }

	}
	class CBlock extends Block {
		static get toolbox() {
			return {
				title: title || __(key) || "Quick List X",
				icon: frappe.utils.icon(icon || "list", "sm"),
			};
		}
		static get isReadOnlySupported() {
			return true;
		}
		constructor(opt) {
			super(opt);
			this.col = this.data?.col || col;
			this.allow_customization = !this.readOnly;
			this.options = {
				allow_sorting: this.allow_customization,
				allow_create: this.allow_customization,
				allow_delete: this.allow_customization,
				allow_hiding: false,
				allow_edit: true,
				allow_resize: true,
				min_width: minWidth,
				max_widget_count: maxWidgetCount,
			};
		}
		new() {
			let me = this;
			this.dialog = new Dialog({
				label: this.label,
				type: key,
				primary_action: (widget) => {
					widget.in_customize_mode = 1;
					this.block_widget = new Fa({
						...widget,
						widget_type: key,
						container: this.wrapper,
						options: {
							...this.options,
							on_delete: () => this.api.blocks.delete(),
							on_edit: () => this.on_edit(this.block_widget),
						},
					});
					this.block_widget.customize(this.options);
					$(this.wrapper).find(".widget").addClass(`${key} edit-mode`);
					this.new_block_widget = this.block_widget.get_config();
					this.add_settings_button();
				},
			});

			if (!this.readOnly && this.data && !this.data.name) {
				this.dialog.make();
				this.dialog.addCancel(() => {
					me.wrapper.closest(".ce-block").remove();
				})
			}
		}
		render() {
			this.wrapper = document.createElement("div");
			this.new();

			if (this.data && this.data._name) {
				this.wrapper.innerHTML = "";
				const {col, _name: v, ...data } = this.data;
				this.block_widget = new SingleWidgetGroup({
					factory: Fa,
					container: this.wrapper,
					type: key,
					options: this.options,
					widgets: {
						...data,
						in_customize_mode: !this.readOnly,
					},
					api: this.api,
					block: this.block,
				});
				if (!this.readOnly) {
					this.block_widget.customize();
				}
			}
			if (!this.readOnly) {
				$(this.wrapper).find(".widget").addClass("quick-list edit-mode");
				this.add_settings_button();
				this.add_new_block_button();
			}
			return this.wrapper;
		}

		validate(savedData: any) { return Boolean(savedData._name); }

		save() {
			if (this.new_block_widget) {
				return {
					...this.new_block_widget,
					new: true,
					col: this.get_col(),
					_name: Boolean(this.new_block_widget.name),
				};
			}
			if (!this.data) {
				return {}
			}
			return {
				...this.data,
				new: false,
				col: this.get_col(),
				_name:Boolean(this.data.name)
			};
		}
	}
	widget_map[key] = Dialog;
	// frappe.widget.widget_factory[key] = Fa;
	frappe.workspace_block.blocks[key] = CBlock

}

frappe.guigu.defineWidget = defineWidget;
for (const p of frappe.guigu.widgets || []) {
	defineWidget(...p);
}
export {};
