import type { ColDef, ColGroupDef, ICellEditorParams } from 'ag-grid-community';

import type { Assess, Permissions } from '../type';

import Action from './AgGridAction.vue';

export function getColumns(
	gotoDetail: (data: Assess) => void,
	gotoInterview: (data: Assess) => void,
	smallMeta: boolean,
): (ColDef | ColGroupDef)[] {
	return [
		{
			headerName: __('Person Name'),
			field: 'full_name',
			enableRowGroup: false,
			editable: false,
			filter: false,
			flex: 1,
			minWidth: 150,
		},
		{
			headerName: __('Department'),
			field: 'organization',
			enableRowGroup: true,
			rowGroup: true,
			minWidth: 65,
			editable: false,
			filter: false,
		},
		{
			headerName: __('Designation'),
			field: 'job',
			minWidth: 65,
			enableRowGroup: true,
			editable: false,
			filter: false,
		},
		{
			headerName: __('Leader Signature Date'),
			field: 'hr_assess_doc.leader_confirm_date',
			minWidth: 65,
			enableRowGroup: true,
			editable: false,
			filter: false,
		},
		{
			headerName: __('Employee Signature Date'),
			field: 'hr_assess_doc.user_confirm_date',
			minWidth: 65,
			enableRowGroup: true,
			editable: false,
			filter: false,
		},
		{
			headerName: __('Actions'),
			width: 130,
			minWidth: 130,
			pinned: smallMeta ? undefined : 'right',
			enableRowGroup: false,
			filter: false,
			sortable: false,
			editable: false,
			menuTabs: [],
			cellRenderer: Action,
			cellRendererParams: (params: ICellEditorParams) => ({
				gotoDetail,
				gotoInterview,
			}),
		},
	];
}
