import { ref } from 'vue';

import switchView from '../lib/switchView';
import allViews, { viewsList } from '../Views';

import createVue from './createVue';
frappe.provide('frappe.guigu');
frappe.provide('frappe.views');


export default function defineView(
	name: string,
	VueComponent: any,
	view?: frappe.guigu.ViewOptions | null
) {
	const viewName = frappe.utils.to_title_case(name);
	const title = view?.title;
	viewsList.push(title ? { value: viewName, label: title } : viewName);

	allViews[name] = view;
	class View extends frappe.views.BaseList {
		constructor(opts: any) {
			super(opts);
			this.show();
			(this as any).parent.list_view = this;
		}
		setup_view() {
			const el = document.createElement('div');
			this.parent.innerHTML = '';
			this.parent.appendChild(el);
			const domVisible = ref(true);
			const $parent = $(this.parent);
			$parent.on('show', _ => { domVisible.value = true; });
			$parent.on('hide', _ => { domVisible.value = false; });

			const vue = createVue(VueComponent, {
				...view?.props || {},
				doctype: this.doctype,
				meta: this.meta,
				domVisible,
			}, {
				'__guigu__define__view__': (): frappe.guigu.ViewContext => ({
					currentView: name.toLowerCase(),
					doctype: this.doctype,
					settings: this.settings,
					meta: this.meta,
					switchView: switchView.bind(null, this.doctype),
				}),
				'__get__page__': () => this.parent.page,
			});
			vue.mount(el);

			// TODO: 监听 parent 被移除
			// vue.unmount()
			// parent.remove()
		}
		set_fields() { }
		build_fields() { }
		_add_field() { }
		setup_view_menu() { }
		set_default_secondary_action() { }

		show_or_hide_sidebar() { }
		set_menu_items() { }
		setup_side_bar() { }
		get view_name() { return viewName; }
		refresh() {
		}
		setup_result_area() {
			// $(this.parent).find('.sidebar-toggle-btn').remove()
			// $(this.parent).find('.layout-side-section').remove()
		}
		setup_no_result_area() { }
		setup_freeze_area() { }
		setup_paging_area() { }

		has_permissions() {
			const can_read = frappe.perm.has_perm(this.doctype, 0, 'read');
			return can_read;
		}

		check_permissions() {
			if (!this.has_permissions()) {
				frappe.set_route('');
				frappe.throw(__('Not permitted to view {0}', [this.doctype]));
			}
			return true;
		}

		// render() {}

	}
	frappe.views[`${viewName}View`] = View;
	if (frappe?.router?.list_views_route) {
		frappe.router.list_views_route[name] = viewName;
	}
	if (frappe?.router?.list_views) {
		frappe.router.list_views.push(name);
	}
}
frappe.guigu.defineView = defineView;
for (const p of frappe.guigu.views || []) {
	defineView(...p);
}
export { };
