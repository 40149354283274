<template>
  <span :class="className"
    :data-filter="dataFilter">
    {{ props.params.value }}
  </span>
</template>
<script lang="ts" setup>
import { defineProps } from 'vue';
import { ref } from 'vue';

const props = defineProps<{ params: { value: any, data: any } }>();
function class_style(bg_color: string, text_color: string) {
  let class_name = `color_${bg_color.replace('#', '')}_${text_color.replace('#', '')}`;
  const style = document.createElement('style');
  style.textContent = `.${class_name} { background-color: ${bg_color};color:${text_color}}
		.${class_name}::before { background: ${text_color}}
		`;
  document.querySelector('head')?.appendChild(style);
  return class_name;
}
const className = `filterable indicator-pill ${class_style(props.params.data?.background_color || '', props.params?.data?.color || '')} ellipsis`;
// color_${(props.params.data.color || '').substring(1)}_${(props.params.data.background_color || '').substring(1)}
const dataFilter = `status,=,${props.params.value}`;
</script>

<style scoped lang="less">
</style>
