import unionBy from 'lodash/unionBy';

import { BoqValue, FormatStructure, OriginStructure, StructureItemFormat } from './type/index';
export function formatStructure(structures: OriginStructure[]): FormatStructure[] {
	return structures.map(item => ({
		id: item.name,
		parent: item.parent_guigu_construction_structure,
		lft: item.lft,
		rgt: item.rgt,
		label: item.structure_name,
		hasTeam: item.has_team,
		code: item.code,
	}));
}

export function getProgressValue(
	progress: StructureItemFormat[],
	progressValue: BoqValue[],
) {
	const exitStructureProgressIds = progressValue.filter(item => item.period_plan_value || item.period_product_value)
		.map(item => item.structure_progress);
	let ancestries = progress.filter(item => item.structureProgressId && exitStructureProgressIds.includes(item.structureProgressId))
		.map(item => item.ancestry).flat();
	ancestries = unionBy(ancestries);
	return progress.map(item => {
		const value = progressValue.find(plan => plan.structure_progress === item.structureProgressId);
		return {
			...item,
			productValue: value,
		};
	}).filter(item => ancestries.includes(item.id));
}
