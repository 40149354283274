<template>
	<AgGridVue
		class="ag-theme-guigu"
		style="height: 500px"
		:columnDefs="columnDefs"
		rowSelection="multiple"
		animateRows="true"
		@grid-ready="onGridReady"
		:suppressDragLeaveHidesColumns="smallMeta"
		:getRowStyle="getRowStyle"
		:localeText="zhCN"
		@rowDataUpdated="rowDataUpdated"
		@cellValueChanged="cellValueChanged">
	</AgGridVue>
	<ProgressActualDialog
		:visible="visible"
		:progressActual="progressActualInfo"
		:enableAudit="enableAudit"
		@close="close"
		@confirm="confirm"></ProgressActualDialog>
</template>
<script lang="ts" setup>
import { ref, computed, defineEmits, getCurrentInstance, defineProps, watch, toRaw } from 'vue';
import { AgGridVue } from 'ag-grid-vue3';
import { CellValueChangedEvent, GridApi, RowClassParams, RowDataUpdatedEvent } from 'ag-grid-community';

import { OriginActual, ProgressActual, ProgressActualFormData, TempActual } from '../type/diary';
import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';

import { getActualColumns } from './progressActualHelper';
import { useMetaQuery } from './useMetaQuery';
import ProgressActualDialog from './ProgressActualDialog.vue';

interface Props {
	progressActual: ProgressActual[],
	enableAudit: boolean
}
const props = defineProps<Props>();
const emit = defineEmits<{
	(event: 'deleteActual', actual: ProgressActual): void;
	(event: 'editActual', actual: ProgressActual, formData: any): void;
	(event: 'actualChange', value: ProgressActual, oldID: string): void;
}>();
const { appContext } = getCurrentInstance() || {};
const smallMeta = useMetaQuery();
const gridApi = ref<GridApi>();
const visible = ref(false);
const progressActualInfo = ref<ProgressActual>();

const onGridReady = (params: any) => {
	gridApi.value = params.api;
};

watch(() => [...props.progressActual], () => {
	setTimeout(function () {
		if (!gridApi.value) { return; }
		gridApi.value.setRowData(props.progressActual);
	}, 0);
}, { deep: true });
function getRowStyle(params: RowClassParams) {
	if (params.data.type === 'temp') {
		return { color: '#d36e13' };
	}
}
function deleteRecord(data: ProgressActual) {
	const frm = appContext?.config.globalProperties.$frm;
	const { name, progress_actual, temp_actual }: { name: string, progress_actual: OriginActual[], temp_actual: TempActual[] } = frm.get_doc();
	if (data.type === 'progress') {
		const actualIndex = progress_actual.findIndex(item => item.guigu_construction_structure_progress === data.guigu_construction_structure_progress);
		if (actualIndex > -1) {
			progress_actual.splice(actualIndex, 1);
			frm.set_value('progress_actual', progress_actual);
		}
	} else {
		const actualIndex = temp_actual.findIndex(item => item.temp_progress_name === data.temp_progress_name);
		if (actualIndex > -1) {
			temp_actual.splice(actualIndex, 1);
			frm.set_value('temp_actual', temp_actual);
		}
	}
	frm.dirty();
	emit('deleteActual', data);
}
function editRecord(data: ProgressActual) {
	visible.value = true;
	progressActualInfo.value = data;
}
function close() {
	visible.value = false;
	progressActualInfo.value = undefined;
}
function confirm(
	editProgressActualInfo: ProgressActual,
	formData: ProgressActualFormData,
) {
	if (editProgressActualInfo.type === 'progress' && !editProgressActualInfo.guigu_construction_structure_progress) { return; }
	if (editProgressActualInfo.type === 'temp' && !editProgressActualInfo.temp_progress_name) { return; }

	const frm = appContext?.config.globalProperties.$frm;
	const { progress_actual, temp_actual }: { progress_actual: OriginActual[], temp_actual: TempActual[] } = frm.get_doc();
	let actual;
	if (editProgressActualInfo.type === 'progress') {
		actual = progress_actual.find(item => item.guigu_construction_structure_progress === editProgressActualInfo.guigu_construction_structure_progress);
	} else {
		actual = temp_actual.find(item => item.temp_progress_name === editProgressActualInfo.temp_progress_name);
	}
	if (!actual) { return; }
	const { actual_quantity, report_quantity, remark, tomorrow_plan_quantity } = formData;
	actual.report_quantity = report_quantity;
	actual.actual_quantity = actual_quantity;
	actual.remark = remark;
	actual.tomorrow_plan_quantity = tomorrow_plan_quantity;
	if (editProgressActualInfo.type === 'progress') {
		frm.set_value('progress_actual', progress_actual);
		emit('editActual', editProgressActualInfo, formData);
	} else {
		frm.set_value('temp_actual', temp_actual);
		emit('editActual', editProgressActualInfo, formData);
	}
	visible.value = false;
}
const columnDefs = computed(() => getActualColumns(deleteRecord, editRecord, props.enableAudit, smallMeta.value));

function getQuantity(data: any, column: string) {
	const { actual_quantity, report_quantity } = data;
	let actualQuantity = actual_quantity;
	const isActualQuantityNull = actual_quantity === 0 || actual_quantity === null || actual_quantity === undefined || actual_quantity === '';
	// 开启审核时，审核量自动填入填报量的值
	if (column === 'report_quantity' && isActualQuantityNull && props.enableAudit) {
		actualQuantity = report_quantity;
	}
	// 未开启审核量时，填报量等于审核量
	const reportQuantity = (props.enableAudit ? report_quantity : actual_quantity) || 0;
	return { actualQuantity, reportQuantity };
}
function cellValueChanged(event: CellValueChangedEvent) {
	const { data, value, colDef, api, rowIndex } = event;
	const { guigu_construction_structure_progress: oldStructureProgress, temp_progress_name: oldTemName } = data;
	const dataCopy = structuredClone(toRaw(data));
	const column: string = colDef.field as string;
	const frm = appContext?.config.globalProperties.$frm;
	const { name, progress_actual, temp_actual }: {
		name: string, progress_actual: OriginActual[], temp_actual:
			TempActual[]
	} = frm.get_doc();
	const { actualQuantity, reportQuantity } = getQuantity(dataCopy, column);
	if (column === 'structure_ancestry_label') {
		const { type } = value;
		// 把aggrid内部的label赋成字符串
		data.structure_ancestry_label = value.label;
		updateAgGridActual(dataCopy, {
			structure_ancestry_label: value.label,
			type,
			guigu_construction_structure_progress: type === 'progress' ? value.value : undefined,
			temp_progress_name: type === 'temp' ? value.value : undefined,
		});
		if (type === 'temp') {
			createTemActual(frm, value);
		} else {
			createProgressActual(frm, value);
		}

	} else {
		const updateData = getUpdateData(dataCopy, column, value);
		const { type } = dataCopy;
		let oldActual = column === 'actual_quantity' ? event.oldValue : data.actual_quantity;
		const complete_quantity = (parseFloat(dataCopy.complete_quantity || '0') + parseFloat(actualQuantity || 0) - parseFloat(oldActual || 0)).toFixed(2);
		updateAgGridActual(dataCopy, {
			actual_quantity: actualQuantity,
			report_quantity: reportQuantity,
			complete_quantity,
		});
		if (type === 'temp') {
			updateTemActual(frm, temp_actual, dataCopy, updateData);
		} else {
			updateProgressActual(frm, progress_actual, dataCopy, updateData);
		}
	}
	const oldID = dataCopy.type === 'progress' ? oldStructureProgress : oldTemName;
	emit('actualChange', dataCopy, oldID);
}
function createTemActual(frm: any, value: { value: string, label: string }) {
	const actual = {
		temp_progress_name: value.value,
	};
	frm.add_child('temp_actual', actual);
}
function createProgressActual(frm: any, value: { value: string, label: string }) {
	const actual = {
		guigu_construction_structure_progress: value.value,
	};
	frm.add_child('progress_actual', actual);
}
function updateTemActual(frm: any, temp_actual: TempActual[], dataCopy: TempActual, updateData: Record<any, any>) {
	const actual = temp_actual.find(item => item.temp_progress_name === dataCopy.temp_progress_name);
	if (!actual) { return; }
	for (const [key, value] of Object.entries(updateData)) {
		actual[key] = value;
	}
	frm.set_value('temp_actual', temp_actual);
}
function updateProgressActual(frm: any, progress_actual: OriginActual[], dataCopy: OriginActual, updateData: Record<string, any>) {
	const actual = progress_actual.find(item => item.guigu_construction_structure_progress === dataCopy.guigu_construction_structure_progress);
	if (!actual) { return; }
	for (const [key, value] of Object.entries(updateData)) {
		actual[key] = value;
	}
	frm.set_value('progress_actual', progress_actual);
}
function updateAgGridActual(data: any, updateData: Record<string, any>) {
	for (const [key, value] of Object.entries(updateData)) {
		data[key] = value;
	}
}
function getUpdateData(data: any, column: string, value: string) {
	const { actualQuantity, reportQuantity } = getQuantity(data, column);
	if (column === 'report_quantity' || column === 'actual_quantity') {
		return {
			report_quantity: reportQuantity,
			actual_quantity: actualQuantity,
		};
	}
	return {
		[column]: value,
	};
}
function rowDataUpdated(event: RowDataUpdatedEvent) {
	const nodes = event.api.getRenderedNodes();
	const editNode = nodes.find(item => {
		if (item.data.type === 'progress') {
			return !item.data.guigu_construction_structure_progress;
		}
		return !item.data.temp_progress_name;

	});
	if (!gridApi.value || editNode?.rowIndex === null || editNode?.rowIndex === undefined) { return; }
	gridApi.value.startEditingCell({ rowIndex: editNode?.rowIndex, colKey: 'structure_ancestry_label' });
}
</script>
