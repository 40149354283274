import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79eb795e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "report-page" }
const _hoisted_2 = { class: "sider" }
const _hoisted_3 = { class: "right-main" }
const _hoisted_4 = { class: "title container" }
const _hoisted_5 = { class: "subject" }
const _hoisted_6 = { class: "btn-container" }
const _hoisted_7 = {
  ref: "editorContainerRef",
  class: "container editor-container ck-content"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElConfigProvider"], {
    size: "small",
    locale: $setup.zhCn
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode($setup["Directory"], {
            content: $setup.directory||{}
          }, null, 8 /* PROPS */, ["content"])
        ]),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode($setup["ElTooltip"], { content: $setup.subject }, {
              default: _withCtx(() => [
                _createElementVNode("h3", _hoisted_5, _toDisplayString($setup.subject), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["content"]),
            _createElementVNode("div", _hoisted_6, [
              ($setup.mode === 'report' && $setup.isPersistence === false && $setup.writePermission)
                ? (_openBlock(), _createBlock($setup["ElButton"], {
                    key: 0,
                    class: "persis-btn",
                    onClick: $setup.setEdit
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.edit ? $setup.tt('View') : $setup.tt('Edit')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true),
              ($setup.mode === 'report' && $setup.isPersistence === false)
                ? (_openBlock(), _createBlock($setup["ElButton"], {
                    key: 1,
                    class: "persis-btn",
                    onClick: $setup.persistent
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Persistence')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          (($setup.mode === 'report' && $setup.edit && !$setup.isPersistence) || ($setup.mode === 'template'))
            ? (_openBlock(), _createBlock($setup["Tools"], {
                key: 0,
                editor: $setup.editor
              }, null, 8 /* PROPS */, ["editor"]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_7, [
            _createVNode($setup["EditorContent"], {
              editor: $setup.editor,
              class: "editor"
            }, null, 8 /* PROPS */, ["editor"])
          ], 512 /* NEED_PATCH */)
        ])), [
          [$setup["vLoading"], $setup.loading]
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["locale"]))
}