<script lang="ts" setup>
import { computed, onMounted, ref, shallowRef, watch, triggerRef } from 'vue';

import {
	ElInput, ElButton,
	ElForm, ElFormItem,
} from 'element-plus';
import { Plus } from '@element-plus/icons-vue';

import Item from '../../components/GuiguFilterDialog/Item.vue';
import { Filter } from '../../GlobalView/types';
import loadLinkDocTypes from '../../utils/loadLinkDocTypes';
import build_options from '../../components/GuiguFilterDialog/build_options';

import DoctypeSelect from './DoctypeSelect.vue';
import { Config } from './type';

const tt = __;

const props = defineProps<{
	value?: Config;
}>();
const emit = defineEmits<{
	(event: 'update', data: any, submittable?: boolean): void;
	(event: 'cancel' | 'submit'): void;
}>();

const label = ref<string>(props.value?.label || '');
const doctype = ref<string>(props.value?.doctype||'');
const meta = ref<locals.DocType>();
let nextId = 1;
const doctypeLabel = ref('');
const filters = ref<Filter[]>((props.value?.filters || []).map((f: Filter) => ({
	...f,
	key: nextId++,
})));

watch(doctypeLabel, (v, o) => {
	if (!v) { return; }
	const l = label.value;
	if (!l || l === o) {
		label.value = v;
	}
});

watch([label, doctype, filters], ([label, doctype, filters]) => {
	emit('update', { label, doctype,  filters}, Boolean(doctype));
}, { immediate: true, deep: true });


watch(doctype, () => { filters.value = []; });

watch(doctype, async doctype => {
	meta.value = undefined;
	if (!doctype) { return; }
	let m = frappe.get_meta(doctype);
	if (!m) {
		await new Promise<void>(resolve => {
			frappe.model.with_doctype(doctype, () => { resolve(); });
		});
		m = frappe.get_meta(doctype);
		if (!m) { return; }
	}
	await loadLinkDocTypes(m);
	meta.value = m;
}, { immediate: true });

const fields = computed(() => {
	const m = meta.value;
	if (!m) { return []; }
	return build_options(m.name);
});
function add() {
	filters.value.push({
		key: nextId++,
		doctype: doctype.value,
		field: 'name',
	});
}
function remove(filter: Filter) {
	const index = filters.value.indexOf(filter);
	if (index >= 0) {
		filters.value.splice(index, 1);
	}
}
</script>

<template>
	<div>
		<ElForm labelPosition="top">
			<ElFormItem :label="tt('DocType')"
				:rules="[
					{
						required: true,
						message: '请选择链接',
						trigger: 'blur',
					}
				]">
				<DoctypeSelect v-model="doctype"
					@label="doctypeLabel = $event" />
			</ElFormItem>
			<ElFormItem :label="tt('Label')" :rules="[
									{
										required: true,
										message: '请输入标题',
										trigger: 'blur',
									}
								]">
				<ElInput v-model="label" />
			</ElFormItem>
			<ElFormItem v-if="meta" :label="tt('Filter')">
				<template #label="{ label }">
					{{ label }}
					<ElButton @click="add()" title="添加" :icon="Plus" />
				</template>
				<Item v-for="filter in filters"
					:key="filter.key"
					:fields="fields"
					:meta="meta"
					withSession
					v-model:doctype="filter.doctype"
					v-model:field="filter.field"
					v-model:condition="filter.condition"
					v-model:value="filter.value"
					@remove="remove(filter)" />
			</ElFormItem>
		</ElForm>
	</div>
</template>
<style scoped lang="less">
.form-content {
	margin: 1rem;
}

.add-row-btn {
	margin-top: 8px;

}
</style>
