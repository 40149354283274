
const dateRegex = /^(\d+)-(0?[1-9]|1[0-2])-(0?[1-9]|[12]\d|3[01])(\s|$)/;
const timeRegex = /^\s*([01]?\d|2[0-3]):([0-5]?\d)(?::([0-5]?\d)(?:\.(\d{0,3}))?)?/;
// eslint-disable-next-line vue/max-len
const datetimeRegex = /^(\d+)-(0?[1-9]|1[0-2])-(0?[1-9]|[12]\d|3[01])\s+([01]?\d|2[0-3]):([0-5]?\d)(?::([0-5]?\d)(?:\.(\d{0,3}))?)?(\s|$)/;
export function toDatetime(value: any) {
	if (typeof value !== 'string') {
		return undefined;
	}
	const result = datetimeRegex.exec(value) || dateRegex.exec(value);
	if (result) {
		const [, Y, M, D, h, m, s, ms] = result;
		return new Date(
			Number(Y),
			Number(M) - 1,
			Number(D),
			Number(h) || 0,
			Number(m) || 0,
			Number(s) || 0,
			ms ? Number(`0.${ms}`) * 1000 : 0,
		);
	}
	const rs = timeRegex.exec(value);
	if (!rs) { return; }
	const [, h, m, s, ms] = rs;
	const date = new Date();
	date.setHours(Number(h) || 0);
	date.setMinutes(Number(m) || 0);
	date.setSeconds(Number(s) || 0);
	date.setMilliseconds(ms ? Number(`0.${ms}`) * 1000 : 0);
	return date;
}
export function toTime(value: any) {
	if (typeof value !== 'string') {
		return undefined;
	}
	const result = datetimeRegex.exec(value);
	if (result) {
		const [, Y, M, D, h, m, s, ms] = result;
		return new Date(
			Number(Y),
			Number(M) - 1,
			Number(D),
			Number(h) || 0,
			Number(m) || 0,
			Number(s) || 0,
			ms ? Number(`0.${ms}`) * 1000 : 0,
		);
	}
	const rs = timeRegex.exec(value);
	if (!rs) { return; }
	const [, h, m, s, ms] = rs;
	const date = new Date();
	date.setHours(Number(h) || 0);
	date.setMinutes(Number(m) || 0);
	date.setSeconds(Number(s) || 0);
	date.setMilliseconds(ms ? Number(`0.${ms}`) * 1000 : 0);
	return date;
}
export function toDate(value: any) {
	if (typeof value !== 'string') {
		return undefined;
	}
	const result = dateRegex.exec(value);
	if (!result) {
		return undefined;
	}
	const [, Y, M, D] = result;
	const date = new Date(Number(Y), Number(M) - 1, Number(D));

	return date;
}

function toDateStr(date: Date) {
	const Y = date.getFullYear();
	const M = date.getMonth() + 1;
	const D = date.getDate();
	const texts: (string | number)[] = [M, D].map(v => `${v}`.padStart(2, '0'));
	texts.unshift(Y);
	return texts.join('-');
}

function toTimeStr(date: Date) {
	const h = date.getHours();
	const m = date.getMinutes();
	const s = date.getSeconds();
	return [h, m, s].map(v => `${v}`.padStart(2, '0')).join(':');
}
export function toDateString(date?: Date) {
	if (!(date instanceof Date)) { return; }
	return toDateStr(date);
}

export function toTimeString(date?: Date) {
	if (!(date instanceof Date)) { return; }
	return toTimeStr(date);
}
export function toDatetimeString(date?: Date) {
	if (!(date instanceof Date)) { return; }
	return `${toDateStr(date)} ${toTimeStr(date)}`;
}
