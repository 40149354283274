import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Link Field')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElSelect"], {
          modelValue: $setup.linkField,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.linkField) = $event)),
          filterable: "",
          defaultFirstOption: "",
          clearable: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.linkFields, (field) => {
              return (_openBlock(), _createBlock($setup["ElOption"], {
                key: field.value,
                value: field.value,
                label: field.label
              }, null, 8 /* PROPS */, ["value", "label"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Max Limit')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElInputNumber"], {
          modelValue: $setup.maxLimit,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.maxLimit) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Default Limit')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElInputNumber"], {
          modelValue: $setup.defaultLimit,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.defaultLimit) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], null, {
      default: _withCtx(() => [
        _createVNode($setup["ElCheckbox"], {
          modelValue: $setup.infiniteScroll,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.infiniteScroll) = $event)),
          label: $setup.tt('Infinite Scroll')
        }, null, 8 /* PROPS */, ["modelValue", "label"])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Number Of Fields Fixed')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElInputNumber"], {
          modelValue: $setup.fixedFieldColumns,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.fixedFieldColumns) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], null, {
      default: _withCtx(() => [
        _createVNode($setup["ElCheckbox"], {
          modelValue: $setup.showSummaryRow,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.showSummaryRow) = $event)),
          label: $setup.tt('Show Summary Rows')
        }, null, 8 /* PROPS */, ["modelValue", "label"])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode($setup["ElFormItem"], null, {
      default: _withCtx(() => [
        _createVNode($setup["ElCheckbox"], {
          modelValue: $setup.tree,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($setup.tree) = $event)),
          label: $setup.tt('Display In A Tree Structure')
        }, null, 8 /* PROPS */, ["modelValue", "label"])
      ]),
      _: 1 /* STABLE */
    }),
    (!$setup.tree)
      ? (_openBlock(), _createBlock($setup["ElFormItem"], { key: 0 }, {
          default: _withCtx(() => [
            _createVNode($setup["ElCheckbox"], {
              modelValue: $setup.showLineNumber,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($setup.showLineNumber) = $event)),
              label: $setup.tt('Display Line Number')
            }, null, 8 /* PROPS */, ["modelValue", "label"])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Additional Request Fields')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElSelect"], {
          modelValue: $setup.fieldsRequested,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => (($setup.fieldsRequested) = $event)),
          multiple: "",
          filterable: "",
          defaultFirstOption: "",
          clearable: "",
          style: {"width":"100%"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fields, (field) => {
              return (_openBlock(), _createBlock($setup["ElOption"], {
                key: field.value,
                value: field.value,
                label: field.label
              }, null, 8 /* PROPS */, ["value", "label"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"])
  ], 64 /* STABLE_FRAGMENT */))
}