import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9c7793bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "organization-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tree = _resolveComponent("el-tree")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_el_tree, {
      ref: "treeRef",
      class: "organization-tree",
      data: $setup.organizationTree,
      props: $setup.optionProps,
      "node-key": "id",
      "expand-on-click-node": false,
      "default-expanded-keys": $setup.rootNodeIds,
      "current-node-key": $props.organization,
      onNodeClick: $setup.nodeClick,
      "highlight-current": ""
    }, null, 8 /* PROPS */, ["data", "default-expanded-keys", "current-node-key"]), [
      [_directive_loading, $setup.props.organizationLoading]
    ])
  ]))
}