import type {
	Api,
	CellParam,
	CellUpdate,
	ColumnOptions,
	ColumnComponent,
	NextColumn,
	ColumnParam,
	CellComponent,
	Extension,
	ColumnDefine,
} from '../types';
import noop from '../utils/noop';
const defaultHeaderRenderer = ({ column: { title = '' } }: ColumnParam): ColumnComponent => {
	const root = document.createElement('div');

	root.innerText = title;
	return {
		root,
		destroy() { },
		update(c) {
		root.innerText = c.title || ''
	},
};
}
const defaultRenderer = ({ value }: CellParam): CellComponent => {
	const root = document.createElement('div');

	function setValue(v: any) {
		root.innerText = v === undefined || v === null ? '' : v;
	}
	setValue(value)
	const update = (row: CellUpdate) => setValue(row.value)

	return { root, destroy() { }, setHidden() { }, update };
}

const noopRender = () => ({
	root: document.createElement('div'),
	destroy: noop,
	setHidden: noop,
	update: noop,
})
const createNoop = (): ColumnDefine => {
	return { updateData: noop, render: noopRender, header: noopRender, customize: noop, }
}
export default function createColumnFn(
	column: ColumnOptions,
	exFns: Extension[],
	exOptions: Record<string, any>,
	api: Api
): [
	NextColumn,
		(allOptions: Record<string, any>[], opt: ColumnOptions) => void,
		() => void,
	] {
	const render = typeof column.render === 'function' ? column.render : defaultRenderer;
	const header = typeof column.header === 'function' ? column.header : defaultHeaderRenderer;

	let destroyed = false;
	const rootUpdates: ((options: Record<string, any>, columnOptions: ColumnOptions) => void)[] = [];
	let fn: NextColumn = (u) => {
		if (destroyed) { return createNoop() }
		rootUpdates.push((_, v) => u(v))
		return ({ ...column, render, header, updateData:noop,customize: noop })
	}
	const updates: ((options: Record<string, any>, columnOptions: ColumnOptions) => void)[][] = [];
	updates[exFns.length] = rootUpdates;
	const destroys: (() => void)[] = [];
	for (let i = exFns.length - 1; i >= 0; i--) {
		const update: typeof rootUpdates = [];
		updates[i] = update;
		const exFn = exFns[i];
		const f = fn
		fn = u => {
			if (destroyed) { return createNoop() }
			const { updateData, updateOptions, destroy, customize, ...r } = exFn(exOptions[i] || {}, u, api, f, column);
			if (typeof updateOptions === 'function') {
				update.push(updateOptions);
			}
			if (typeof destroy === 'function') {
				destroys.push(destroy);
			}
			return { ...r,
				updateData: typeof updateData === 'function' ? updateData : noop,
				customize: typeof customize === 'function' ? customize : noop,
			};
		};
	}
	function updateExtensions(allOptions: Record<string, any>[], opt: ColumnOptions) {
		if (destroyed) { return }
		exOptions = allOptions;
		const allUpdates = updates.map(v => [...v])
		for (let i = 0; i < allUpdates.length; i++) {
			const o = exOptions[i] || {};
			for (const update of allUpdates[i]) {
				update(o, opt)
			}
		}
	}
	function destroy() {
		if (destroyed) { return }
		destroyed = true;
		for (const destroy of destroys) {
			destroy()
		}
	}
	return [fn, updateExtensions, destroy];
}
