import {createApp} from 'vue';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn';
import VueViewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';

import DailyRecord from './index.vue';

export default function loadDwOrganizationDailyRecord(wrapper:HTMLElement) {
	const app = createApp(DailyRecord, {});
	app.use(ElementPlus, {size: 'small', locale: zhCn});
	app.use(VueViewer);
	app.mount(wrapper);
}

frappe.provide('frappe.dw_daily_record.pages');
frappe.dw_daily_record.pages.loadDwOrganizationDailyRecord = loadDwOrganizationDailyRecord;
