<template>
	<div class="table-container">
		<h4 v-if="props.options.title " class="title">{{ props.options.title }}</h4>
		<AgGridVue
			class="ag-theme-guigu"
			@gridReady="onReady"
			groupDisplayType="singleColumn"
			:getRowId="getRowId"
			:localeText="zhCN"
			:rowData="data"
			rowGroupPanelShow="always"
			:columnDefs="columns"
			domLayout="autoHeight"
			suppressDragLeaveHidesColumns
			:autoGroupColumnDef="autoGroupColumnDef" />
	</div>
</template>

<script setup lang="ts">
import {ref, shallowRef, computed, watch, inject} from 'vue';

import {AgGridVue} from 'ag-grid-vue3';
import type {
	SelectionChangedEvent,
	GridApi,
	GetRowIdParams,
	ColumnRowGroupChangedEvent,
	GridReadyEvent,
	RowDragEndEvent,
	ColumnApi,
} from 'ag-grid-community';

import type {ChartProvide} from '../../../../type';

import getColumns from './getColumns';
import zhCN from './zhCN';
import type {Column} from './type';

interface Props {
	data: Record<string, any>[];
	options: Record<string, any>;
}
const props = defineProps<Props>();
const gridApi = shallowRef<GridApi>();
const gridColumnApi = shallowRef<ColumnApi>();
const meta = ref<locals.DocType>();
const chart = inject<ChartProvide>('chart');

async function onReady({api, columnApi}: GridReadyEvent) {
	gridApi.value = api;
	gridColumnApi.value = columnApi;
}
function getRowId({data}: GetRowIdParams<locals.Doctype>) {
	return data.name;
}

const autoGroupColumnDef = {pinned: 'left', resizable: true};
const doctype = computed(() => chart?.doc.source_doctype);

watch(doctype, async ()=>{
	if (!doctype.value) {
		return;
	}
	frappe.model.with_doctype(doctype.value, ()=>{
		if (!doctype.value) {
			return;
		}
		meta.value = frappe.get_meta(doctype.value) || undefined;
	});
}, {immediate: true});

const dataColumns = computed(() => {
	if (!meta.value) {
		return [];
	}
	const fields = (props.options?.columns || [])
		.map((item: Column) => {
			const metaFields = meta.value?.fields;
			let field = metaFields?.find(f => f.fieldname === item.fieldname);
			if (field) {
				field.label = field.label;
			}

			if (item.fieldname === '__LastComment__') {
				field = {
					label: 'Last Comment',
					fieldname: '_comments',
					fieldtype: '__LastComment__',
				};
			}
			if (item.fieldname === '__Status__') {
				field = {
					label: 'Status',
					fieldname: '__Status__',
					fieldtype: '__Status__',
				};
			}
			if (!field) {
				return;
			}
			const groupable = !['name', '__LastComment__'].includes(item.fieldname);
			const format = field.fieldtype === 'Date' ? 'YYYY-MM-DD' : '';
			return {
				field,
				groupable,
				group: Boolean(item.group),
				width: item.size,
				maxWidth: item.max_width,
				minWidth: item.min_width,
				enableLink: false,
				alignment: item.alignment,
				format,
				show_tag: item.show_tag,
			};
		})
		.filter(Boolean);
	return getColumns(
		meta.value,
		() => {},
		() => props.data,
		fields,
		parseInt(props.options.fixed || '0', 10),
	);
});

const columns = computed(() => {
	const list = [...dataColumns.value];
	return list;
});

watch(columns, ()=>{
	const state = columns.value.filter(item=>item.pinned === 'left').map(item=>({colId: item.field || '', pinned: 'left' as const}));
	gridColumnApi?.value?.applyColumnState({
		state,
		defaultState: {pinned: null},
	});
}, {immediate: true});
</script>

<style lang="less" scoped>
  .table-container {
	display: flex;
	flex-direction: column;
	// height: 20rem;
	border: 1px solid #e2e8f0;
	border-radius: 0.25rem;
  }
  .title {
	padding: 4px 8px;
	text-align: center;
  }
</style>
