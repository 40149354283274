import type {WorkListData, Department} from './type';

const {isApplier: is_applier} = frappe.dw_worklist_management.permission;
export const processLevel: string[] = [
	'',
	'Process Level 1',
	'Process Level 2',
	'Process Level 3',
	'Process Level 4',
	'Process Level 5',
	'Process Level 6',
	'Process Level 7',
	'Process Level 8',
	'Process Level 9',
	'Process Level 10',
];
export async function hasPermission(organization:string) {
	if (frappe.session.user === 'Administrator' || frappe.user.has_role('System Manager')) {
		return true;
	}
	const isApplier = await is_applier(organization);
	return isApplier;
}

export function mergeData(result: WorkListData) {
	const {process_list, worklist_list} = result;
	for (const item of process_list) {
		item.is_process = true;
	}
	// 根据worklist_list的work_process字段，找到process_list中对应的name字段
	for (const item of worklist_list) {
		item.is_process = false;
		const processItem = process_list.find(process => process.name === item.work_process);
		if (processItem) {
			item.parent_dw_process = processItem.name;
		}
	}
	return [...process_list, ...worklist_list];
}

// 判断当前的分配的部门是不是筛选部门的子级
export function isChildDepartment(list: Department[], departmentName: string, selectedDepartmentName: string): boolean {
	function checkChildren(dept: Department, targetDeptName: string): boolean {
		if (dept.children) {
			for (const child of dept.children) {
				if (child.name === targetDeptName || checkChildren(child, targetDeptName)) {
					return true;
				}
			}
		}
		return false;
	}

	const department = list.find(item => item.name === departmentName);
	if (!department) {
		return false;
	}
	return checkChildren(department, selectedDepartmentName);
}
