<template>
	<Page>
		<template #title>
			<h3 class="title">{{ title }}</h3>
		</template>
		<template #tools>
			<ToolBar
				v-model:project="project"
				v-model:period="period"
				:type="type">
			</ToolBar>
		</template>
		<template #default>
			<ReportTable
				:mainType="type"
				:subType="type"
				:reportResults="reportResults"></ReportTable>
		</template>
	</Page>
</template>
<script lang="ts" setup>
import {computed, ref, watch} from 'vue';

import Page from '../../../../components/page/index.vue';
import {Period, ReportResult} from '../type';

import ToolBar from './ToolBar.vue';
import ReportTable from './ReportTable.vue';


interface Props{
	type:'Week'|'Month'
}
const props = defineProps<Props>();
const project = ref<string>('');
const period = ref<Period>();
const loading = ref<boolean>(false);
const reportResults = ref<ReportResult[]>([]);

const title = computed(()=>{
	let title = '';
	switch (props.type) {
		case 'Week':
			title = __('Week Report'); break;
		case 'Month':
			title = __('Month Report'); break;
	}
	return title;
});

watch(period, async () => {
	if (!period.value) {
		reportResults.value = []; return;
	}
	loading.value = true;
	const data: {message:ReportResult[]} = await frappe.call<{ message: ReportResult[]}>({
		method: 'guigu_pm.guigu_pm_construction.page.structure_week_report.structure_week_report.get_report',
		args: {
			period: period.value.name,
			type: props.type,
		},
	});
	loading.value = false;
	for (const item of data?.message) {
		if (item.type === 'plan') {
			item.actualQuantity = (parseFloat(item.actualQuantity?.toString() || '0') + parseFloat(item.extraActual?.toString() || '0')).toFixed(2);
			item.finishRate = item.plan && item.actualQuantity ? (
				((item?.actualQuantity || 0) / item?.plan)
				* 100
			).toFixed(2) : undefined;
		}
		const labelArr = item.ancestryLabel.split('/');
		const index = labelArr.findIndex(eachLabel=>eachLabel === item.unitStructureLabel);
		let label = '';
		if (index === -1) {
			label = item.ancestryLabel;
		} else {
			label = labelArr.slice(index + 1).join('/');
		}
		item.ancestryLabel = label;
	}
	(data?.message || []).sort((pre, next)=>pre.teamId > next.teamId ? -1 : 1).sort((pre, next)=>pre.unitStructureId > next.unitStructureId ? -1 : 1);
	reportResults.value = data?.message || [];
});
</script>
<style lang="less" scoped>
.title{
	margin-bottom: 0px;
}
</style>
