
import type {
	ColDef,
	ColGroupDef,
} from 'ag-grid-community';

import Subject from './Subject.vue';

function getTranslatedPriority(data: string) {
	return __(data);
}
export function getColumns(smallMeta: boolean): (ColDef | ColGroupDef)[] {
	const columns: (ColDef | ColGroupDef)[] = [
		{
			headerName: __('Subject'),
			field: 'subject',
			enableRowGroup: false,
			editable: false,
			filter: false,
			flex: 1,
			checkboxSelection: true,
			cellRenderer: Subject,
		},
		// {
		// 	headerName: __('Dw Task Type'),
		// 	field: 'task_type',
		// 	enableRowGroup: false,
		// 	editable: false,
		// 	filter: false,
		// 	flex: 1,
		// 	checkboxSelection: false,
		// },
		{
			headerName: __('Priority'),
			field: 'priority',
			enableRowGroup: false,
			editable: false,
			filter: false,
			// pinned: smallMeta ? undefined : ('left' as const),
			width: 200,
			valueGetter: params => getTranslatedPriority(params.data.priority),
			checkboxSelection: false,
		},
		{
			headerName: __('Necessary'),
			field: 'is_necessary',
			enableRowGroup: false,
			editable: false,
			filter: false,
			// pinned: smallMeta ? undefined : ('left' as const),
			width: 200,
			valueGetter: params => {
				if (params.data.is_necessary) {
					return __('Yes');
				}
				return '';
			},
			checkboxSelection: false,
		},
	];
	return columns;
}
