
export default function addTags(
	doctype: string,
	docnames: string[],
	done: () => void
) {
	const dialog = new frappe.ui.Dialog({
		title: __('Add Tags'),
		fields: [
			{
				fieldtype: 'MultiSelectPills',
				fieldname: 'tags',
				label: __('Tags'),
				reqd: true,
				get_data(txt) {
					return frappe.db.get_link_options('Tag', txt);
				},
			},
		],
		primary_action_label: __('Add'),
		primary_action: () => {
			let args = dialog.get_values();
			if (args && args.tags) {
				dialog.set_message('Adding Tags...');

				frappe.call('frappe.desk.doctype.tag.tag.add_tags', {
					tags: args.tags,
					dt: doctype,
					docs: docnames,
				}).then(() => {
					dialog.hide();
					done();
				});
			}
		},
	});
	dialog.show();
}
