<template>
      <ElTooltip
        :visible="visible"
      >
	  	<template #content>
			<div class="progress-container">
            <ElProgress
              :format="format"
              :percentage="speed"
              
              :status="speed === 100 ? undefined : 'success'"
            >
			</ElProgress>
          </div>
		</template>
		<ElTooltip v-if="files.length>0" :content="tt('Batch Download')">
			<el-icon @click="downloadZip"><Download /></el-icon>
		</ElTooltip>
      </ElTooltip>
</template>
<script lang="ts" setup>
	import {  Download} from '@element-plus/icons-vue';
	import { defineProps, ref, toRefs } from 'vue';

	import JSZip from '../../../lib/jszip.min.js';
	import FileSaver from '../../../lib/FileSaver.min.js';
	const tt = __
	interface Props{
		files: { url: string; name: string }[];
  		zipName: string;
	}
	const props =defineProps<Props>();
	const {files, zipName } = toRefs(props);
	const visible = ref<boolean>(false);
	const speed = ref<number>(0);
	function format(percentage:string){
		return `${percentage}%`;
	}

	function getBase64(
		url: string,
		onloadCallback: (Base64?: string) => any,
		onprogressCallback?: (progress: number, index?: number) => any,
		index?: number,
	) {
		const xhr = new XMLHttpRequest();
		xhr.open('GET', `${url}?v=${Math.random()}`, true);
		xhr.responseType = 'blob';
		xhr.onprogress = function (event) {
			return onprogressCallback?.(event.loaded / event.total, index) || null;
		};
		xhr.onload = () => {
			if (xhr.status === 200) {
			const reader = new FileReader();
			reader.onloadend = function () {
				return onloadCallback?.(String(reader.result)) || null;
			};
			reader.readAsDataURL(xhr.response);
			} else {
			onloadCallback?.();
			}
		};
		xhr.onerror = () => {
			onloadCallback?.();
		};
		xhr.send();
	}

	function downloadZip(){
		visible.value=true;
		const zip = new JSZip();
		const fileFolder = zip.folder(zipName.value);
		const fileNames: string[] = [];
		const nameCatalogue:Record<string, any> = {};
		const progressSpeed: number[] = [];
		for (const [index, file] of files.value.entries()) {
			progressSpeed.push(0);
			const onloadCallback = (url?: string) => {
				progressSpeed[index] = parseInt(`${100 / files.value.length}`);

				const ext = file.name.split('.').pop()?.toLowerCase();
				let newFileName = file?.name.substring(0, file?.name.lastIndexOf('.'));
				const fileName = newFileName;
				nameCatalogue[fileName] = nameCatalogue?.[fileName] + 1 || 0;
				if (fileNames.includes(fileName)) {
				newFileName = `(${fileName}${nameCatalogue[fileName]})`;
				}
				fileNames.push(fileName);
				if (url) {
				fileFolder.file(
					`${newFileName}.${ext}`,
					url.substring(url.indexOf(',') + 1),
					{
					base64: true,
					},
				);
				}
				if (fileNames.length === files.value.length) {
					speed.value=100;
					zip.generateAsync({ type: 'blob' }).then((content: any) => {
						FileSaver.saveAs(content, `${zipName.value}.zip`);
					});
					setTimeout(() => {
						visible.value=false;
						speed.value=0;
					}, 1000);
				}
			};
			const onprogressCallback = (progress: number, index?: number) => {
				if (!index) {
					return;
				}
				progressSpeed[index] = parseInt(`${(progress * 100) / files.value.length}`);
				speed.value=progressSpeed.reduce((pre, next)=>pre+next, 0);
			};
			getBase64(file?.url, onloadCallback, onprogressCallback, index);
		}
	}
</script>
<style lang="less" scoped>
.progress-container {
	width: 100px
}
</style>
