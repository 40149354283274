import type {
	ColSpanParams,
	CellClassParams,
	ColDef,
	ColGroupDef,
	ICellEditorParams,
	RowSpanParams,
	EditableCallbackParams,
	ValueFormatterParams,
} from 'ag-grid-community';

import type * as Permission from '../../Permission';
import type { AssessDetail } from '../type';
import AgGridToolTip from '../../AgGridToolTip.vue';

import Action from './AgGridAction.vue';
import Subject from './AgGridSubject.vue';
export function isEditable(field: string, lockStatus: boolean, permission: Permission.DocPermission | null, isModify?: boolean) {
	if (lockStatus || !permission) {
		return false;
	}
	if (isModify) {
		if (permission.modifiable.has(field)) {
			return true;
		}
		if (!permission.ifOwner.modifiable.has(field)) {
			return false;
		}
	}
	if (permission.writable.has(field)) {
		return true;
	}
	if (!permission.ifOwner.writable.has(field)) {
		return false;
	}
}
function getEditableFun(
	field: string,
	lockStatus: boolean,
	permission: Permission.DocPermission | null,
	assessmentPermission: Permission.DocPermission | null,
) {
	return function editableFun(params: EditableCallbackParams<AssessDetail & { type_label: string }>) {
		if (lockStatus) {
			return false;
		}
		const { type_label, link_doctype, work_owner, assess_user } = params.data || {};
		// 这是任务
		if (Boolean(link_doctype)) {
			let { field } = params.colDef;
			if (!field) {
				return false;
			}
			if (field === 'subject') {
				return false;
			}
			switch (field) {
				case 'remark':
					field = 'assessment_comments';
					break;
				case 'standard':
					field = 'assessment_criteria';
					break;
			}
			if (!assessmentPermission) {
				return false;
			}
			if (!assessmentPermission.modifiable.has(field)) {
				if (work_owner !== frappe.user.name) {
					return false;
				}
				if (!assessmentPermission.ifOwner.modifiable.has(field)) {
					return false;
				}
			}
			if (field === 'self_assessment') {
				return assess_user === frappe.user.name;
			}
			return true;
		}
		if (type_label === __('Total Weight')) {
			return false;
		}
		// 这是临时任务
		const modifiable = isEditable(field, lockStatus, permission, true);
		if (typeof modifiable === 'boolean') {
			return modifiable;
		}
		return (params: EditableCallbackParams<AssessDetail>) => params.data?.user === frappe.user.name;
	};
}
export function getColumns(
	onRemove: (data: AssessDetail) => void,
	permission: Permission.DocPermission | null,
	assessmentPermission: Permission.DocPermission | null,
	lockStatus: boolean,
	smallMeta: boolean,
): (ColDef | ColGroupDef)[] {
	const columns = [];

	if (!lockStatus) {
		columns.push({
			headerCheckboxSelection: true,
			checkboxSelection: (params: any) =>
			// 如果是最后一行或锁定状态，隐藏复选框
				params.node.data.name !== 'auto_calc',
			width: 50,
		});
	}

	columns.push(
		{
			headerName: __('Project'),
			field: 'type_label',
			enableRowGroup: false,
			editable: false,
			filter: false,
			width: 50,
			colSpan: (params: ColSpanParams) => (params.data.type_label === __('Total Weight') ? 3 : 1),
			cellStyle: (params: CellClassParams) => {
				const { data } = params;
				if (data?.type_label === __('Total Weight')) {
					return { display: 'flex', justifyContent: 'center' };
				}
				return;
			},
		},
		{
			headerName: __('Order Number'),
			cellStyle: { 'text-align': 'right' },
			field: 'index',
			menuTabs: [],
			width: 50,
			enableRowGroup: false,
			editable: false,
			filter: false,
		},
		{
			headerName: __('Plan Content'),
			field: 'subject',
			minWidth: 150,
			flex: 1,
			enableRowGroup: false,
			filter: false,
			cellRenderer: Subject,
			editable: getEditableFun('subject', lockStatus, permission, assessmentPermission),
		},
		{
			headerName: __('Evaluation Criterion'),
			field: 'standard',
			minWidth: 150,
			enableRowGroup: false,
			editable: getEditableFun('standard', lockStatus, permission, assessmentPermission),
			filter: false,
			flex: 1,
			cellRenderer: AgGridToolTip,
		},
		{
			headerName: __('Weightage'),
			cellStyle: { 'text-align': 'right' },
			field: 'total_score',
			width: 65,
			menuTabs: [],
			enableRowGroup: false,
			editable: getEditableFun('total_score', lockStatus, permission, assessmentPermission),
			cellEditor: 'agNumberCellEditor',
			filter: false,
		},
		{
			headerName: __('Work Criteria'),
			field: 'work_criteria',
			minWidth: 150,
			enableRowGroup: false,
			editable: getEditableFun('work_criteria', lockStatus, permission, assessmentPermission),
			filter: false,
			flex: 1,
			cellRenderer: AgGridToolTip,
		},
		{
			headerName: __('Evaluation Basis'),
			field: 'evaluation_basis',
			minWidth: 150,
			enableRowGroup: false,
			editable: getEditableFun('evaluation_basis', lockStatus, permission, assessmentPermission),
			filter: false,
			flex: 1,
			cellRenderer: AgGridToolTip,
		},
		{
			headerName: __('Complete Percent'),
			cellStyle: { 'text-align': 'right' },
			field: 'percent_complete',
			width: 90,
			cellDataType: false,
			enableRowGroup: false,
			editable: (params: EditableCallbackParams<AssessDetail & { type_label: string }>) => {
				const { type_label, link_doctype } = params.data || {};
				if (link_doctype || type_label === __('Total Weight')) {
					return false;
				}
				const writable = isEditable('percent_complete', lockStatus, permission);
				if (typeof writable === 'boolean') {
					return writable;
				}
				return params.data?.user === frappe.user.name;
			},
			cellEditor: 'agNumberCellEditor',
			filter: false,
			valueFormatter: (params: ValueFormatterParams) => {
				if (params.data.type_label === __('Total Weight')) {
					return '';
				}
				return parseFloat(params.value?.toString() || '0').toFixed(2);
			},
		},
		{
			headerName: __('Complete Status'),
			field: 'remark',
			minWidth: 150,
			enableRowGroup: false,
			editable: getEditableFun('remark', lockStatus, permission, assessmentPermission),
			filter: false,
			flex: 1,
			cellRenderer: AgGridToolTip,
		},
		{
			headerName: __('Self Assessment'),
			cellStyle: { 'text-align': 'right' },
			field: 'self_assessment',
			width: 75,
			enableRowGroup: false,
			editable: getEditableFun('self_assessment', lockStatus, permission, assessmentPermission),
			cellEditor: 'agNumberCellEditor',
			filter: false,
		},
		{
			headerName: __('Direct Superior Rating'),
			cellStyle: { 'text-align': 'right' },
			field: 'leader_assessment',
			width: 100,
			enableRowGroup: false,
			editable: getEditableFun('leader_assessment', lockStatus, permission, assessmentPermission),
			cellEditor: 'agNumberCellEditor',
			filter: false,
		},
		{
			headerName: __('Actions'),
			width: 80,
			pinned: smallMeta ? undefined : ('right' as const),
			enableRowGroup: false,
			filter: false,
			sortable: false,
			editable: false,
			menuTabs: [],
			cellRenderer: Action,
			cellRendererParams: (params: ICellEditorParams) => {
				if (params.data.link_doctype) {
					return {};
				}
				if (lockStatus || !permission) {
					return {};
				}
				if (permission.deletable) {
					return { onRemove };
				}
				if (!permission.ifOwner.deletable) {
					return {};
				}
				return params.data?.user === frappe.user.name ? { onRemove } : {};
			},
		},
	);

	return columns;
}
