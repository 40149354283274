<script lang="ts" setup>
import {computed, ref, watch} from 'vue';

import ViewLinkView from '../ViewLink/View.vue';
import DocTypeLinkView from '../DocTypeLink/View.vue';

import {TableData} from './type';
import ListView from './ListView.vue';
import UrlView from './UrlView.vue';
const components = {
	'View Link': ViewLinkView,
	'DocType Link': DocTypeLinkView,
	List: ListView,
	URL: UrlView,
};
const options = {
	'View Link': 'viewLinkConfig',
	'DocType Link': 'doctypeLinkConfig',
	List: 'listLinkConfig',
	URL: 'urlConfig',
};
const props = defineProps<{
	value?: {label:string, description:string, tableData:TableData[]};
	editing: boolean;
}>();
const emit = defineEmits<{
	(event: 'edit'): void
}>();
const titleRef = ref<any>();
const tableData = computed<TableData[]>(() => props.value?.tableData || []);
const label = computed<string>(() => props.value?.label || '');
const description = computed<string>(() => props.value?.description || '');
const descriptionEl = $(`
				<button class="btn-reset card-description-btn ml-2">
					${frappe.utils.icon('help', 'sm')}
				</button>
			`);
interface UrlConfig{
	value:any
	icon?: string
	component:Component
}
const urls = computed(() => tableData.value.map(d=>getViewLinkOptions(d)).filter(Boolean) as UrlConfig[]);

function getViewLinkOptions(d:TableData) {
	return {
		value: d[options[d.type] as 'viewLinkConfig'|'doctypeLinkConfig'|'listLinkConfig'],
		icon: d.icon,
		component: components[d.type],
	};
}
watch([description, titleRef], ()=>{
	if (!titleRef.value) {
		return;
	}
	descriptionEl.remove();
	if (description.value) {
		descriptionEl.appendTo(titleRef.value);
		descriptionEl.popover({
			trigger: 'hover',
			placement: 'top',
			content: () => `<div class="card-description small">${__(description.value)}</div>`,
			html: true,
		});
	}
});
</script>

<template>
	<WorkspaceWidget>
		<template #title>
			<div ref="titleRef">
				<svg class="icon  icon-lg" style="">
					<use class="" href="#icon-file"></use>
				</svg>
				<span class="ellipsis">
					{{ label }}
				</span>
			</div>
		</template>
		<div class="widget-body">
			<span v-for="item in urls" :class="['link-item','ellipsis']">
				<svg v-if="item.icon" class="card-icon icon icon-lg" style="">
					<use class="" :href="`#icon-${item.icon}`"></use>
				</svg>
				<span v-else
					:class="['indicator-pill', 'no-margin', 'gray']"></span>
				<component class="shot-cut" :is="item.component" :value="item.value" size="small"></Component>
			</span>
		</div>
	</WorkspaceWidget>
</template>
<style scoped lang="less">
.link-item:last-child {
	margin-bottom: 0px !important;
}

.link-item:first-child {
	margin-top: 18px;
}

.link-item {
	display: flex;
	align-items: center;
	text-decoration: none !important;
	font-size: var(--text-md);
	color: var(--text-color);
	padding: 4px;
	margin-left: -4px;
	margin-bottom: 4px;
	border-radius: var(--border-radius-md);
	cursor: pointer;
	:deep(.widget-control .dropdown-btn){
		display: none;
	}
	:deep(.widget-control .drag-handle){
		display: none;
	}
	:deep(.widget-control .edit-button){
		display: none;
	}
	.shot-cut{
		min-width: 0;
	}
}

.indicator-pill {
	margin-right: var(--margin-sm);
	height: 20px;
	padding: 3px 8px;
}

.card-icon {
	margin: 0;
	width: 20px;
	height: 20px;
	margin-right: 8px;
}
</style>
