
<template>
	<div class="toolbar">
		<div class="tool_left">
			<div class="organization-select">
				{{ tt('Organizational Unit') }}:
				<ElSelect class="input" disabled v-model="organization" size="small">
					<ElOption :label="organizationList.label" :value="organizationList.name" />
				</ElSelect>
			</div>
			<div class="process-search">
				<ProcessSearchTools
					:processes="processes"
					@getData="getData"
				></ProcessSearchTools>
			</div>
			<ElCheckbox class="applicable-filter" size="small" v-model="onlyApplicable">
				{{ tt('Displays only where applicable') }}
			</ElCheckbox>
		</div>
		<div class="tool_right">
			<div class="tool_dropdown_menu">
				<LayoutSelect v-model:detailType="detailType"/>
			</div>
			<CollapsiblePanel @toggle="isExpand" />
			<ElButton
				class="new_worklist"
				v-if="hasPermission"
				type="primary"
				size="small"
				disabled
				@click="addWorklist">
				{{ tt('New') }}
			</ElButton>
			<ElButton
				v-if="hasPermission"
				:disabled="!props.showApplyButton"
				type="primary"
				size="small"
				@click="emit('applyWorklist')">
				{{ tt('Applicable') }}
			</ElButton>
		</div>
	</div>
</template>

<script setup lang="ts">
import {ElButton, ElSelect, ElOption, ElCheckbox} from 'element-plus';
import {computed, watch, ref} from 'vue';

import ProcessSearchTools from '../../../../component/ProcessSearchTools/index.vue';
import LayoutSelect from '../../../../component/LayoutSelect/index.vue';
import CollapsiblePanel from '../../../../component/CollapsiblePanel/index.vue';
import type {process} from '../../../../types';
import type {Organization} from '../type.ts';
const tt = __;


const props = defineProps<{
	levelValue: string;
	layoutType: 'modal'|'link'|'bottom'|'right';
	smallMeta: boolean;
	processes: process[];
	organizationValue: string;
	showOnlyApplicable: boolean;
	showApplyButton:boolean;
	hasPermission: boolean;
}>();
const emit = defineEmits<{
	(e: 'update:levelValue', levelValue: string): void;
	(e: 'update:layoutType', layoutType: string): void;
	(e: 'update:showOnlyApplicable', showOnlyApplicable: boolean): void;
	(e: 'toggleGroupsState', state: boolean, level?:number): void;
	(e: 'openDetail', name: string, isWorklist: boolean): void;
	(e: 'applyWorklist'): void;
}>();
const detailType = computed({
	get: () => props.layoutType,
	set: (layoutType:string) => emit('update:layoutType', layoutType),
});

const onlyApplicable = computed({
	get: () => props.showOnlyApplicable,
	set: (onlyApplicable:boolean) => emit('update:showOnlyApplicable', onlyApplicable),
});

const organizationList = ref<Organization>({name: '', label: ''});
const organization = computed({
	get: () => props.organizationValue,
	set: () => { },
});
watch(() => props.organizationValue, async () => {
	await frappe.db.get_value(
		'Tianjy Organization',
		{name: props.organizationValue}, ['name', 'label'],
		(res: Organization) => {
			organizationList.value = res;
		},
	);
}, {immediate: true});


/**
 * 获取流程分级的value
 * @param levelValue 流程等级的value
 */
function getData(levelValue:string) {
	emit('update:levelValue', levelValue);
}
// 展开层级
function isExpand(value:boolean) {
	emit('toggleGroupsState', value);
}

async function addWorklist() {
	const docName = frappe.model.make_new_doc_and_get_name('Dw Standard Worklist', true);
	const docDetail = locals['Dw Standard Worklist'][docName] as locals.Doctype;
	docDetail.scope = 'Organization Unit Specific';
	docDetail.specific_organization = props.organizationValue;
	emit('openDetail', docDetail.name, false);
}


const flexDirection = computed(() => (props.smallMeta ? 'column' : 'row'));
const margin = computed(() => (props.smallMeta ? '8px' : '0'));
</script>

<style lang="less" scoped>
.toolbar {
    display: flex;
    flex-direction: v-bind(flexDirection);
	justify-content: space-between;

	.tool_left {
		display: flex;
		flex-direction: v-bind(flexDirection);
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;

		.organization-select {
			white-space: nowrap;
			margin:0 8px 8px 0;
		}

		.input-container {
			white-space: nowrap;
			margin:0 8px 8px 0;

			.input {
				margin-bottom: v-bind(margin);
				width: 120px;
			}
		}
		.applicable-filter{
			margin-bottom: 8px;
			margin-right: 8px;
		}
	}

	.tool_right {
		display: flex;
		flex-direction: v-bind(flexDirection);
		justify-content: flex-end;
		flex-wrap: wrap;

		.tool_dropdown_menu{
			margin:0 20px 8px 0;
		}
		.tool_toggle_State {
			margin:0 20px 8px 0;
		}
		.new_worklist{
			margin-bottom: 8px;
		}
	}
}

  :deep(.date-input) {
    margin-bottom: v-bind(margin);
  }
</style>
