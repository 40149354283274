<template>
	<div class="tool-container">
		<div class="tool">
			<h5>{{ tt('5、 Technical Management') }}</h5>
			<p class="project-info">
				{{
					tt(
						'This month, Southwest Company submitted {} construction organization designs and micro engineering plans, with {} actual depth plans. The July plans are currently being prepared or reviewed.'
					)
				}}
			</p>
			<p>
				{{
					tt(
						'（1） The approval and implementation status of this month construction plan, as well as the plans for minor, major, and extremely dangerous situations, are shown in the table below:'
					)
				}}
			</p>
			<p>{{ tt('（2） The approval plan for next month is shown in the table below:') }}</p>
		</div>
	</div>
</template>
<script language="ts" setup>
import { ref, watchEffect, defineProps, onMounted, watch } from 'vue';
import { ElTable, ElTableColumn } from 'element-plus';

const tableData = [{ name: '潍坊项目' }, {}];
const tt = __;
</script>
<style scoped lang="less">
  .tool-container {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    .tool {
      margin: 16px;
      padding: 24px;
      h5 {
        margin: 0 0 16px 0;
        font-size: 20px;
        font-weight: bold;
      }
      .project-info {
        margin: 0;
        font-size: 16px;
        line-height: 2;
        text-indent: 2em;
      }
      img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 30px auto;
      }
    }
  }
</style>
