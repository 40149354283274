import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f87090a7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body" }
const _hoisted_2 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElDialog"], {
    modelValue: $setup.dialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.dialogVisible) = $event)),
    title: $setup.tt('Enable Process List'),
    width: "80%",
    top: "5vh"
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["ElButton"], {
          onClick: _cache[0] || (_cache[0] = $event => ($setup.dialogVisible = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElButton"], {
          type: "primary",
          onClick: $setup.confirm
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Confirm')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["ElTable"], {
          ref: "tableRef",
          class: "table",
          data: $setup.tableData,
          style: {"width":"100%"},
          height: "400",
          stripe: "",
          border: "",
          onSelect: $setup.selectionChange
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElTableColumn"], {
              type: "selection",
              width: "38"
            }),
            _createVNode($setup["ElTableColumn"], {
              label: $setup.tt('Dw Process Title')
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("div", null, _toDisplayString(`${scope.row.full_process_code} ${scope.row.process_name}`), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["data"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}