import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("span", {
    class: "text-right",
    title: $setup.title,
    onClick: $setup.setFilter
  }, _toDisplayString($setup.value), 9 /* TEXT, PROPS */, _hoisted_1))
}