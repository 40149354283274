import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ea5ceeb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElDatePicker = _resolveComponent("ElDatePicker")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElDatePicker, {
      class: "input",
      modelValue: $setup.date,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.date) = $event)),
      type: "date",
      size: "small",
      valueFormat: "YYYY-MM-DD"
    }, null, 8 /* PROPS */, ["modelValue"]),
    _createVNode($setup["ProjectCom"], {
      default: "",
      noLabel: "",
      modelValue: $setup.selectedProject,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.selectedProject) = $event))
    }, null, 8 /* PROPS */, ["modelValue"])
  ]))
}