<template>
	<Page>
		<template #title>
			<h3 class="title">{{ tt("Construction Management Log") }}</h3>
		</template>
		<template #tools>
			<Toolbar
				@filterChange="filterChange">
			</Toolbar>
		</template>
		<DiaryTable
			v-loading="loading"
			:diaryStructure="structure"
			:project="selectedProject"
			:date="selectedDate"
			@deleteDiary="deleteDiary"
			@afterSave="afterSave"></DiaryTable>
	</Page>
</template>
<script lang="ts" setup>
import {ref} from 'vue';

import Page from '../../../components/page/index.vue';
import {getTreeAncestry} from '../../../utils';

import Toolbar from './ToolBar.vue';
import DiaryTable from './DiaryTable.vue';
import {Diary, DiaryStructure, DiaryStructureFormat, Project} from './type';
const tt = __;
const structure = ref<(DiaryStructure & {
	ancestry: string[];
})[]>([]);
const selectedProject = ref<string>();
const selectedDate = ref<string>();
const loading = ref<boolean>(false);
function filterChange(date:string, project:string) {
	selectedProject.value = project;
	selectedDate.value = date;
	structure.value = [];
	if (!project || !date) {
		return;
	}
	getStructures(date, project);
}
async function getStructures(date:string, project:string) {
	loading.value = true;
	const data = await frappe.call<{message:DiaryStructure[]}>({
		method: 'guigu_pm.guigu_pm_construction.page.diary_list.diary_list.get_diary_structures',
		args: {date, project},
	});
	const diaryStructure = getTreeAncestry(data?.message || []);
	structure.value = diaryStructure;
	loading.value = false;
}
function deleteDiary(structureName:string, diary:Diary) {
	const row = structure.value.find(item=>item.name === structureName);
	if (!row) {
		return;
	}
	row.diary = undefined;
}
function afterSave() {
	if (!selectedDate.value || !selectedProject.value) {
		return;
	}
	getStructures(selectedDate.value, selectedProject.value);
}
</script>
<style scoped>
.title{
	margin-bottom: 0;
}
</style>
