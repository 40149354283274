<template>
	<div>
		<a v-if="href" class="link" target="_blank" :href="href">
			{{ fileValue }}
		</a>
		<a v-if="href" target="_blank" :download="fileName" :href="href">
			<el-icon>
				<Download />
			</el-icon>
		</a>
	</div>
</template>

<script setup lang='ts'>
import { computed } from 'vue';

import { Download } from '@element-plus/icons-vue';

const props = defineProps<{ params: any }>();

const href = computed(() => encodeURI(props.params.value || '').replace(/#/g, '%23'));

const fileName = computed(() => {
	const fileNameArr: string[] = (props.params.value || '').split('/');
	const fileNameExt = fileNameArr[fileNameArr.length - 1];
	const arr: string[] = fileNameExt.split('.');
	arr.splice(arr.length - 1, 1);
	const fileName = arr.join('.');
	return fileName;
});

const fileValue = computed(()=>{
	const filenameArr = props.params.value.split('/');
	let filename = filenameArr.pop();
	const isPrivate = filenameArr.some(item=>item==='private');
	filename = `[${isPrivate?__('Private'):__('Public')}]${filename}`;
	return filename
})

</script>

<style lang='less' scoped>
.link {
	margin-right: 8px;
}
</style>
