<template>
	<div id="row_content">
		<div id="title_and_icon">
			<Icon v-if="!isWorkList && level?.icon" :icon="level.icon"></Icon>
			<ElTooltip :content="data.title" effect="light">
				<span id="work_list_title">{{ subject }}</span>
			</ElTooltip>
		</div>
		<div class="button_and_icon">
			<span v-show="data.isCore" class="core_circle" ></span>
			<ElTooltip :content="tt('Create a new task from the task template')" effect="light">
				<ElIcon v-show="permission && isWorkList" @click="emit('creatTask',data)" class="icon_plus">
					<Plus color="blue"/>
				</ElIcon>
			</ElTooltip>
		</div>
	</div>
</template>

<script setup lang='ts'>
import {ElIcon, ElTooltip} from 'element-plus';
import {Plus} from '@element-plus/icons-vue';
import {computed} from 'vue';
import type Node from 'element-plus/es/components/cascader-panel/src/node';

import Icon from '../../../../component/Icon/index.vue';

import type {TreeData, LevelStyle, Process} from './type';
import {processLevel} from './helper';
const props = defineProps<{
	data: TreeData;
	node: Node;
	permission: boolean;
	separator: string;
	levelList:LevelStyle[]
	processList:Process[]
}>();
const emit = defineEmits<{
	(e: 'creatTask', data: TreeData): void
}>();

const tt = __;
const level = computed(() => props.levelList.find(item => (props.data?.level ? item.level === processLevel[props.data?.level] : undefined)));
const isWorkList = computed(() => props.data.type === 'worklist');
const subject = computed(() => {
	if (props.data.type === 'worklist') {
		const process = props.processList.find(item => item.name === props.data.parent);
		return `${process?.full_process_code}${props.separator}${props.data.code} ${props.data.title}`;
	}
	return `${props.data.code} ${props.data.title}`;
});

const bgColor = computed(()=>isWorkList.value ? '' : level.value?.row_background_color);
const color = computed(()=>isWorkList.value ? '' : level.value?.font_color);
const weight = computed(()=>(!isWorkList.value && level.value?.is_bold) ? 'bold' : 'normal');
</script>
<style lang="less" scoped>
#row_content{
	width: 100%;
	display: flex;
	align-items:center;
	flex-direction: row;
	justify-content:'space-between';
	flex-wrap: nowrap;
	background-color: v-bind(bgColor);

	#title_and_icon{
		display: flex;
		align-items: center;
		width: calc(100% - 40px);

		#work_list_title{
			display: inline-block;
			background-color: v-bind(bgColor);
			color: v-bind(color);
			font-weight:v-bind(weight);
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.button_and_icon{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		width: 40px;

		.icon_star{
			margin-right: 10px;
		}
		.icon_plus{
			margin-right: 2px;
		}
	}
}
.core_circle {
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: red;
		margin-right: 10px;
	}
</style>
