export default function toLoginInWechat(redirect: string, appid: string, agentId?: string) {
	const s = Number(new Date);
	sessionStorage.setItem(`__wechat/login/redirect__:${s}`, redirect);
	let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?response_type=code';
	url += '&scope=snsapi_base';
	url += `&appid=${encodeURIComponent(appid)}`;
	url += `&redirect_uri=${encodeURIComponent(`${location.origin}${location.pathname}`)}`;
	url += `&state=${encodeURIComponent(s)}`;
	if (agentId) { url += `&agentid=${encodeURIComponent(agentId)}`; }
	url += `#wechat_redirect`;

	location.href = url;
}
