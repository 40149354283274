<template>
	<div v-show="showTable">
		<div class="toolbar">
			<ElButton :disabled="isCreateDisabled" type="primary" @click="createActual">
				{{ tt('New') }}
			</ElButton>
		</div>
		<ProgressActualTable
			:progressActual="progressActual"
			:enableAudit="enableAudit"
			@actualChange="actualChange"
			@deleteActual="deleteActual"
			@editActual="editActual"></ProgressActualTable>
	</div>
	<div v-show="!showTable">
		<ul>
			<li v-if="!project">{{ tt('Please Select') }}<span class="required-field">{{ tt('Project') }}</span></li>
			<li v-if="!structureName">{{ tt('Please Select') }}<span class="required-field">{{ tt('Construction Structure') }}</span></li>
			<li v-if="!date">{{ tt('Please Select') }}<span class="required-field">{{ tt('Date') }}</span></li>
			{{ tt('to create an progress') }}
		</ul>
	</div>
</template>
<script lang="ts" setup>
import { ref, getCurrentInstance, watchEffect, computed, watch } from 'vue';
import cloneDeep from 'lodash/cloneDeep';

import type { ProgressActual, ProgressActualFormData } from '../type/diary';

import ProgressActualTable from './ProgressActualTable.vue';
const tt=__
const { appContext } = getCurrentInstance() || {};
const structureName = ref<string>(appContext?.config.globalProperties.$frm?.doc?.guigu_construction_structure);
const progressActual = ref<ProgressActual[]>([]);
const date = ref<string>(appContext?.config.globalProperties.$frm?.doc?.date);
const project = ref<string>(appContext?.config.globalProperties.$frm?.doc?.project);
const team = ref<string>(appContext?.config.globalProperties.$frm?.doc?.guigu_team);
let id = 0;
const enableAudit = ref<boolean>(false);
const showTable = computed(() => Boolean(date.value && structureName.value && project.value));
frappe.ui.form.on('Guigu Construction Diary', {
	setup(frm: any) {
		structureName.value = frm.doc.guigu_construction_structure;
		date.value = frm.doc.date;
	},
	refresh(frm: any) {
		structureName.value = frm.doc.guigu_construction_structure;
		date.value = frm.doc.date;
		team.value = frm.doc.guigu_team;
		project.value = frm.doc.project;
	},
	guigu_construction_structure(frm: any) {
		structureName.value = frm.doc.guigu_construction_structure;
	},
	date(frm: any) {
		date.value = frm.doc.date;
	},
	guigu_team(frm: any) {
		team.value = frm.doc.guigu_team;
	},
	project(frm: any) {
		project.value = frm.doc.project;
	},
});

watch(project, async () => {
	const data = await frappe.db.get_list('Guigu Construction Project Setting', {
		fields: ['*'],
		filters: { project: project.value },
		limit: '',
	});
	enableAudit.value = data[0]?.use_audit_volume === 'Yes';
}, { immediate: true });

watchEffect(async () => {
	if (!date.value || !structureName.value || !project.value || !team.value) { return; }
	const data = await frappe.call<{ message: ProgressActual[] }>({
		method: 'get_diary_progress_actual',
		doc: appContext?.config.globalProperties.$frm.doc,
		args: {
			structure: structureName.value,
			date: date.value,
			project: project.value,
			team: team.value,
		},
	});
	progressActual.value = data?.message || [];
});
function deleteActual(actual: ProgressActual) {
	const index = progressActual.value.findIndex(item => {
		if (actual.type==='progress'){
			return item.guigu_construction_structure_progress === actual.guigu_construction_structure_progress;
		}
		if (actual.type==='temp'){
			return item.temp_progress_name === actual.temp_progress_name;
		}
		if (!actual.type){
			return !item.type;
		}
	});
	progressActual.value.splice(index, 1);
}
function editActual(actual: ProgressActual, formData: ProgressActualFormData) {
	const index = progressActual.value.findIndex(item => {
		if (actual.type==='progress'){
			return item.guigu_construction_structure_progress === actual.guigu_construction_structure_progress;
		}
		return item.temp_progress_name === actual.temp_progress_name;
	});
	const { actual_quantity, report_quantity, remark, tomorrow_plan_quantity } = formData;
	const dataCopy = cloneDeep(progressActual.value[index]);
	const oldActual = dataCopy.actual_quantity;
	dataCopy.actual_quantity = actual_quantity;
	dataCopy.report_quantity = report_quantity;
	dataCopy.remark = remark;
	dataCopy.tomorrow_plan_quantity = tomorrow_plan_quantity;
	dataCopy.complete_quantity = (parseFloat(dataCopy.complete_quantity||'0') + parseFloat(actual_quantity||0) - parseFloat(oldActual||0)).toFixed(2);
	progressActual.value.splice(index, 1, dataCopy);
}

function createActual(){
	progressActual.value.push({
		structure_ancestry_label:'',
		id:`new_${++id}`,
	});
}
const isCreateDisabled = computed(()=>progressActual.value.some(i=>{
	if (i.type === 'progress'){
		return !i.guigu_construction_structure_progress;
	}
	return !i.temp_progress_name;
}));

async function actualChange(data:ProgressActual, oldID:string){
	const actual = progressActual.value.find(item=>{
		if (data.type === 'progress'){
			return item.guigu_construction_structure_progress === oldID&&(item.type==='progress'||!item.type);
		}
		return item.temp_progress_name === oldID&&(item.type==='temp'||!item.type);
	});
	if (!oldID){
		const planDetail = await frappe.call<{ message: {
			'unit': string,
			'week_plan_quantity': number,
			'month_plan_quantity': number,
			'complete_quantity': number,
			'today_plan_quantity': number
		} }>({
			method: 'guigu_pm.guigu_pm_construction.doctype.guigu_construction_diary.guigu_construction_diary.get_structure_progress_plan',
			args: {
				name:data.type === 'progress'?data.guigu_construction_structure_progress:data.temp_progress_name,
				type:data.type,
				team:team.value,
				date:date.value,
				structure: appContext?.config.globalProperties.$frm.doc.guigu_construction_structure,
			},
		});
		const { unit, week_plan_quantity, month_plan_quantity, complete_quantity, today_plan_quantity } = planDetail?.message||{};
		data.unit = unit;
		data.week_plan_quantity = week_plan_quantity;
		data.month_plan_quantity = month_plan_quantity;
		data.complete_quantity = complete_quantity;
		data.today_plan_quantity = today_plan_quantity;
	}
	if (!actual){ return; }
	actual.report_quantity = data.report_quantity;
	actual.actual_quantity = data.actual_quantity;
	actual.tomorrow_plan_quantity = data.tomorrow_plan_quantity;
	actual.guigu_construction_structure_progress = data.guigu_construction_structure_progress;
	actual.remark = data.remark;
	actual.week_plan_quantity = data.week_plan_quantity;
	actual.month_plan_quantity = data.month_plan_quantity;
	actual.structure_ancestry_label = data.structure_ancestry_label;
	actual.unit = data.unit;
	actual.complete_quantity = data.complete_quantity;
	actual.today_plan_quantity = data.today_plan_quantity;
	actual.type = data.type;
	actual.temp_progress_name = data.temp_progress_name;
}
</script>
<style scoped>
.required-field {
  color: #ff4d4f
}

.toolbar {
  display: flex;
  justify-content: flex-end;
  margin: 8px 0;
}
</style>
