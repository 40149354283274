import type {
	Api,
	ColumnCell,
	ColumnOptions,
	RowValue,
} from '../types';
import getExtensionList from './getExtensionList';
import createColumn from './createColumn';
import type Source from '../Source';



export default function createColumns(
	requestRender: () => void,
	api: Api,
	oldColumns: ColumnCell[],
	data: readonly RowValue[],
	columns: ColumnOptions[] = [],
) {
	const list: ColumnCell[] = []

	for (const c of columns) {
		const extensions = getExtensionList(c.extensions);
		const exFns = extensions.map(v => v[0]);
		const exOptions = extensions.map(v => v[1]);
		const field = c.field;
		const meta = c.meta;
		const key = c.key || field;
		const length = exFns.length;
		const index = oldColumns.findIndex(c => {
			if (c.meta !== meta) { return }
			if (c.field !== field) { return }
			if (c.key !== key) { return }
			const extensions = c.extensions;
			if (c.extensions.length !== exFns.length) { return; }
			for (let i = 0; i < length; i++) {
				if (extensions[i] !== exFns[i]) { return }
			}
			return true
		})
		const col = index < 0
			? createColumn(api, c, exFns, exOptions, requestRender)
			: oldColumns.splice(index, 1)[0]
		list.push(col);
		if (index >= 0) {
			col.update(c, exOptions)
		} else {
			col.updateData(data);
		}


	}
	for (const c of oldColumns) {
		c.destroy();
	}
	return list;
}
