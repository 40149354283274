import {createApp} from 'vue';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn';

import App from '../common/instruction/instructionPage/index.vue';

function loadDwInstruction (wrapper:HTMLElement) {
	const app = createApp(App, {});
	app.use(ElementPlus, {size: 'small', locale: zhCn});
	app.mount(wrapper);
}

frappe.provide('frappe.dw_worklist_management.pages');
frappe.dw_worklist_management.pages.loadDwInstruction = loadDwInstruction;
