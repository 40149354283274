<template>
	<ElDropdown @command="command" trigger="click">
		<ElButton size="small">
			{{ tt(currentSelected) || tt('Layout') }}
			<ElIcon class="el-icon--right"><ArrowDown /></ElIcon>
		</ElButton>
		<template #dropdown>
			<ElDropdownMenu>
				<ElDropdownItem
					v-for="item in options"
					:key="item.value"
					:command="item.value"
					:style="{ backgroundColor: currentSelected === item.label ? '#eeeeee' : '' }"
				>
					{{ tt(item.label) }}
				</ElDropdownItem>
			</ElDropdownMenu>
		</template>
	</ElDropdown>
</template>

<script setup lang="ts">
import {ElButton, ElDropdown, ElDropdownMenu, ElDropdownItem, ElIcon} from 'element-plus';
import {onMounted, ref} from 'vue';
import {ArrowDown} from '@element-plus/icons-vue';

interface Props {
	detailType?: 'modal'|'link'|'bottom'|'right';
}
defineProps<Props>();
interface Emit {
	(e: 'update:detailType', detailType: string): void,
}
const emit = defineEmits<Emit>();

const tt = __;
const currentSelected = ref<string>('');
const options = [
	{label: 'Details Dialog', value: 'modal'},
	{label: 'Jump To Page', value: 'link'},
	{label: 'Vertical Layout', value: 'bottom'},
	{label: 'Left And Right Layout', value: 'right'},
];

function command(type: string) {
	currentSelected.value = options.find(item => item.value === type)?.label || '';
	emit('update:detailType', type);
}
onMounted(() => {
	const params = new URLSearchParams(window.location.search);
	if (params.get('layout')) {
		currentSelected.value = options.find(item => item.value === params.get('layout'))?.label || '';
	}
});

</script>

<style lang="less" scoped>
.layout-select {
	width: 150px;
}
.active {
  background-color: red;
}
</style>
