import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14ded243"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["ElTable"], {
      data: $setup.pageList,
      border: "",
      style: {"width":"100%"},
      height: $setup.tableHeight
    }, {
      empty: _withCtx(() => [
        _createVNode($setup["Empty"], { ref: "empty" }, null, 512 /* NEED_PATCH */)
      ]),
      default: _withCtx(() => [
        _createVNode($setup["ElTableColumn"], {
          fixed: "",
          prop: "page_name",
          label: "名称",
          width: "250"
        }),
        _createVNode($setup["ElTableColumn"], {
          fixed: "",
          prop: "title",
          label: "标题"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(`${$setup.tt(scope.row.title)}`), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElTableColumn"], {
          prop: "module",
          label: "模块",
          width: "180"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(`${$setup.tt(scope.row.module)}(${scope.row.module})`), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElTableColumn"], {
          prop: "system_page",
          label: "系统页面",
          width: "80"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.system_page?'是':'否'), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElTableColumn"], {
          prop: "standard",
          label: "标准",
          width: "60"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.standard==='Yes'?'是':'否'), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElTableColumn"], {
          prop: "address",
          label: "操作",
          width: "150"
        }, {
          default: _withCtx((scope) => [
            _createVNode($setup["ElButton"], {
              type: "primary",
              onClick: $event => ($setup.gotoPage(scope.row))
            }, {
              default: _withCtx(() => [
                _createTextVNode("转到页面")
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
            _createVNode($setup["ElButton"], {
              type: "primary",
              onClick: $event => ($setup.editPage(scope.row))
            }, {
              default: _withCtx(() => [
                _createTextVNode("详情")
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["data", "height"])
  ])), [
    [$setup["vLoading"], $setup.loading]
  ])
}