/* eslint-disable eqeqeq */
import {
	CellClassParams,
	ColDef,
	ColGroupDef,
	EditableCallbackParams,
	ICellEditorParams,
	ICellRendererParams,
	RowSpanParams,
} from 'ag-grid-community';

import {ReportResult} from '../type';
import FloatComponent from '../../../../AgGrid/components/Float.vue';
import SparklineCellRenderer from '../../../../AgGrid/components/SparklineCellRenderer.vue';

export function getReportColumns(
	reportResults: ReportResult[],
	type:'Week'|'Month',
): (ColDef | ColGroupDef)[] {
	const columns: (ColDef | ColGroupDef)[] = [
		{
		  headerName: __('Team Label'),
		  field: 'teamLabel',
		  sortable: false,
		  enableRowGroup: false,
		  editable: false,
		  pinned: 'left',
		  lockPinned: true,
			//   autoHeight: true,
		  filter: false,
		  width: 100,
		  rowSpan: (params: RowSpanParams) => {
				const {data} = params;
				const currIndex = reportResults.findIndex(item => item.id === data.id);
				const pre = reportResults[currIndex - 1];
				if (pre?.teamId === data.teamId) {
			  return 1;
				}
				const {length} = reportResults.filter(item => item.teamId === data.teamId);
				return length;
		  },
		  cellClass: (params: CellClassParams) => {
				const {data} = params;
				const currIndex = reportResults.findIndex(item => item.id === data.id);
				const pre = reportResults[currIndex - 1];
				return pre?.teamId === data.teamId ? '' : 'rowSpanCell';
		  },
		},
		{
			headerName: __('Unit Structure'),
			field: 'unitStructureLabel',
			sortable: false,
			enableRowGroup: false,
			editable: false,
			pinned: 'left',
			lockPinned: true,
			filter: false,
		  //   autoHeight: true,
			rowSpan: (params: RowSpanParams) => {
			  const {data} = params;
			  const currIndex = reportResults.findIndex(item => item.id === data.id);
			  const pre = reportResults[currIndex - 1];
			  if (
					pre?.teamId === data.teamId
				&& pre?.unitStructureId === data.unitStructureId
			  ) {
					return 1;
			  }
			  return reportResults.filter(item =>
				  item.teamId === data.teamId
				  && item.unitStructureId === data.unitStructureId).length;
			},
			cellClass: (params: CellClassParams) => {
			  const {data} = params;
			  const currIndex = reportResults.findIndex(item => item.id === data.id);
			  const pre = reportResults[currIndex - 1];
			  return (pre?.teamId !== data.teamId
				|| pre?.unitStructureId !== data.unitStructureId)
					? 'rowSpanCell'
					: '';
			},
		  },
		{
		  headerName: __('Construction content'),
		  field: 'ancestryLabel',
		  sortable: false,
		  enableRowGroup: false,
		  editable: false,
		  pinned: 'left',
		  lockPinned: true,
			//   autoHeight: true,
			//   wrapText: true,
		  filter: false,
		},
		{
		  headerName: __('Unit'),
		  field: 'unit',
		  sortable: false,
		  enableRowGroup: false,
		  editable: false,
		  pinned: 'left',
		  lockPinned: true,
		  filter: false,
		  width: 70,
		},
		{
		  headerName: type === 'Week' ? __('Weekly plan and completion status') : __('Monthly plan and completion status'),
		  field: 'parent',
		  sortable: false,
		  enableRowGroup: false,
		  editable: false,
		  filter: false,
		  children: [
				{
			  headerName: __('Remark'),
			  field: 'remark',
			  sortable: false,
			  enableRowGroup: false,
			  editable: false,
			  filter: false,
			  flex: 1,
			  minWidth: 300,
				},
				{
			  headerName: __('Plan Quantity'),
			  field: 'plan',
			  sortable: false,
			  enableRowGroup: false,
			  editable: false,
			  filter: false,
			  width: 100,
			  cellRenderer: FloatComponent,
			  cellRendererParams: (params: ICellRendererParams) => {
						const {value} = params;
						return {
				  value: value == 0 ? null : value,
						};
			  },
				},
				{
			  headerName: __('Completed Quantity'),
			  field: 'actualQuantity',
			  sortable: false,
			  enableRowGroup: false,
			  editable: false,
			  filter: false,
			  width: 100,
			  cellRenderer: FloatComponent,
			  cellRendererParams: (params: ICellRendererParams) => {
						const {value} = params;
						return {
				  value: value == 0 ? null : value,
						};
			  },
				},
				{
			  headerName: __('PC Percent Complete'),
			  field: 'finishRate',
			  sortable: false,
			  enableRowGroup: false,
			  editable: false,
			  filter: false,
			  width: 100,
			  cellRenderer: SparklineCellRenderer,
				},
				{
			  headerName: __('Completion status and summary'),
			  field: 'summary',
			  sortable: false,
			  enableRowGroup: false,
			  editable: false,
			  filter: false,
			  flex: 1,
			  minWidth: 300,
				},
		  ],
		},

	  ];
	  return columns;
}
