
<template>
	<Page>
		<template #title>
			<h3 class="title">{{ tt('Dw Standard Process Library List') }}</h3>
		</template>
		<template #tools>
			<Tools
				:smallMeta="smallMeta"
				:levels="levels"
				:processes="processes"
				:permission="permission"
				v-model:layoutType="layoutType"
				v-model:disable="disable"
				v-model:levelValue="levelValue"
				@expandOrCollapse="expandOrCollapse"
				@showProcessModalOrNavigate="showProcessModalOrNavigate"
			>
			</Tools>
		</template>
		<Skeleton
			v-loading="loading"
			:layout="layoutType"
			doctype="Dw Process"
			:detailName="detailName"
			@onChange="onChange"
			v-model:form-visible="formVisible"
		>
			<Table
				ref="aggrid"
				class="table-container"
				:smallMeta="smallMeta"
				:dataList="dataList"
				:loading="loading"
				:permission="permission"
				:levelList="levelList"
				@updateData="updateData"
				@showProcessModalOrNavigate="showProcessModalOrNavigate"
			></Table>
		</Skeleton>
	</Page>
</template>

<script setup lang="ts">
import {computed, onMounted, onUnmounted, ref, shallowRef, watch} from 'vue';
import type {RowNode} from 'ag-grid-community';
import {vLoading} from 'element-plus';

import Page from '../../../../../../../guigu/guigu/public/js/components/page/index.vue';
import {useMetaQuery} from '../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';
import Skeleton from '../../../component/Skeleton/index.vue';
import {agGridTree} from '../../../utils/index';

import Table from './components/Table.vue';
import Tools from './components/Tools.vue';
import type {Process, Data, ChangeValue, LevelStyle} from './components/type';
import {hasPermission, findAllDescendantsProcess} from './components/helper';

const aggrid = ref<InstanceType<typeof Table> | null>(null);
const smallMeta = useMetaQuery();
const loading = ref<boolean>(false);
const layoutType = ref<'modal' | 'link' | 'bottom' | 'right'>('right');
const disable = ref<'all' | 'enable' | 'disable'>('enable');
const levelValue = ref<string>('');
const formVisible = ref<boolean>(false);
const detailName = ref<string>('');
const separator = ref<string>('.');
const dataList = shallowRef<Data[]>([]);
const processes = ref<Process[]>([]);
const levelList = ref<LevelStyle[]>([]);
const permission = ref<boolean>(false);
const tt = __;

const levels = computed(() => {
	const index = dataList.value.map(item => item.path.length)
		.reduce((max, length) => Math.max(max, length), 0);
	return index ? index - 1 : 0;
});

// 更新dataList数据
function updateData(type: 'delete' | 'create' | 'update' | 'enable' | 'disable', data: any) {
	switch (type) {
		case 'enable':
		case 'disable': {
			const preData = dataList.value.find(item => item.name === data.name);
			const newData = {...preData};
			if (disable.value === 'all') {
				if (!newData) {
					return;
				}
				newData.disable = type === 'enable' ? 0 : 1;
				aggrid.value?.gridApi?.getRowNode(data.name)?.setData(newData);
			} else {
				aggrid.value?.gridApi?.applyTransaction({remove: [preData]});
			}
			break;
		}
		case 'delete':{
			const preData = dataList.value.find(item=>item.name === data.name);
			aggrid.value?.gridApi?.applyTransaction({remove: [preData]});
			break;
		}
		case 'create': {
			const parent = dataList.value.find(item => item.name === data.parent_dw_process);
			const path = parent ? [...parent.path, data.name].filter(Boolean) : [data.name];
			const newData = {...data, path};
			aggrid.value?.gridApi?.applyTransaction({add: [newData]});
			break;
		}
		case 'update': {
			const parent = dataList.value.find(item => item.name === data.parent_dw_process);
			const newData = {...dataList.value.find(item => item.name === data.name)};
			if (!newData) {
				return;
			}
			// 当流程编码改变并且流程不是末级流程时，查找所有的子级流程，修改流程编码
			let children: Data[] = [];
			if (data.full_process_code !== newData?.full_process_code) {
				children = findAllDescendantsProcess(dataList.value, data.name);
				children = children.map(item => {
					// 更新流程编码
					const itemParts = item.full_process_code?.split(separator.value) || [];
					const dataParts = data.full_process_code.split(separator.value);
					const replaceLength = Math.min(dataParts.length, itemParts.length);
					for (let num = 0; num < replaceLength; num++) {
						itemParts[num] = dataParts[num];
					}
					item.full_process_code = itemParts.join(separator.value);
					return item;
				});
			}
			// 更新当前流程
			newData.process_name = data.process_name;
			newData.full_process_code = data.full_process_code;
			newData.parent_dw_process = data.parent_dw_process;
			newData.path = [...(parent?.path || []), data.name].filter(Boolean);
			aggrid.value?.gridApi?.applyTransaction({update: [newData]});
			aggrid.value?.gridApi?.getRowNode(data.name)?.setData(newData);
			// 更新子级流程编码
			aggrid.value?.gridApi?.applyTransaction({update: children});
			for (const item of children) {
				aggrid.value?.gridApi?.getRowNode(item.name)?.setData(item);
			}
			break;
		}
	}
}
// 流程弹窗显示或者跳转页面
function showProcessModalOrNavigate(name: string) {
	if (layoutType.value === 'link') {
		frappe.set_route(['Form', 'Dw Process', name]);
		return;
	}
	detailName.value = name;
	formVisible.value = true;
}
// 流程编辑后返回的数据的函数
function onChange(returnData: ChangeValue) {
	const {type, data} = returnData;
	updateData(type, data);
}
// 展开或收起
function expandOrCollapse(expand: boolean, level?:number) {
	if (expand) {
		aggrid.value?.gridApi?.forEachNode((rowNode: RowNode) => {
			if (level && rowNode.level < level) {
				rowNode.setExpanded(true);
			} else {
				rowNode.setExpanded(false);
			}
		});
	} else {
		aggrid.value?.gridApi?.collapseAll();
	}
}
// 获取流程库数据
async function getProcessList() {
	loading.value = true;
	const args: { [key: string]: string | number} = {name: levelValue.value};
	if (disable.value !== 'all') {
		args.disable = disable.value === 'disable' ? 1 : 0;
	}
	const res = await frappe.call<{ message: any[] }>({
		method: 'dw_worklist_management.dw_worklist_management.doctype.dw_process.dw_process.get_descendant_process',
		args: args,
	});
	if (res?.message) {
		dataList.value = agGridTree(res.message, 'parent_dw_process', 'name') || [];
		if (!processes.value.length) {
			processes.value = res.message;
		}
	}
	loading.value = false;
}
// 获取层级样式
async function getLevelStyleList() {
	levelList.value = await frappe.db.get_list('Dw Process Level Style', {
		fields: ['level', 'row_background_color', 'row_height', 'font_color', 'is_bold', 'icon'],
		limit: 0,
	});
}
// 获取编码连接符
async function getSeparator() {
	separator.value = await frappe.db.get_single_value('Dw Process Separator Rule', 'separator') || '.';
}
function getSearchParams() {
	const params = new URLSearchParams(window.location.search);
	if (params.get('disable')) {
		disable.value = params.get('disable') as ('all' | 'enable' | 'disable') || 'enable';
	}
	if (params.get('layout')) {
		layoutType.value = params.get('layout') as ('modal' | 'link' | 'bottom' | 'right') || 'right';
	}
}
getSearchParams();
$(frappe.pages.dw_standard_process_library_list).on('show', function() {
	getSearchParams();
});
// 更新url路径
function updateUrl() {
	const params = new URLSearchParams(window.location.search);
	params.set('layout', layoutType.value);
	params.set('disable', disable.value);
	history.replaceState(null, '', `?${params.toString()}`);
}
watch([levelValue, disable], () => {
	updateUrl();
	getProcessList();
}, {immediate: true});
watch(layoutType, () => {
	updateUrl();
});
async function popstateListener() {
	await getLevelStyleList();
	permission.value = await hasPermission();
	getProcessList();
	getSeparator();
}

onMounted(async () => {
	await getLevelStyleList();
	permission.value = await hasPermission();
	getProcessList();
	getSeparator();
	window.addEventListener('popstate', popstateListener);
});
onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});
</script>

<style lang="less" scoped>
  .title {
    height: 75px;
    line-height: 75px;
    margin-bottom: 0;
  }
  .table-container {
    height: 100%;
	overflow: hidden;
  }
</style>
