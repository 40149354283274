import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElButton = _resolveComponent("ElButton")

  return (!$setup.hideAction)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        style: $setup.style
      }, [
        _createVNode(_component_ElButton, { onClick: $setup.gotoDetail }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Detail')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_ElButton, {
          type: $setup.btnType,
          onClick: $setup.gotoInterview
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Interview')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["type"])
      ]))
    : _createCommentVNode("v-if", true)
}