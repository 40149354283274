<template>
	<AgGridVue
		class="ag-theme-guigu assess-table"
		:rowData="assessList"
		:defaultColDef="defaultColDef"
		:columnDefs="columnDefs"
		animateRows="true"
		@grid-ready="onGridReady"
		@cellValueChanged="cellValueChanged"
		@selection-changed="onSelectionChanged"
		:suppressDragLeaveHidesColumns="smallMeta"
		domLayout="autoHeight"
		suppressRowTransform
		:localeText="zhCN"
		:getRowId="getRowId"
		rowSelection="multiple"
		:isRowSelectable="isRowSelectable">
	</AgGridVue>
</template>
<script setup lang="ts">
import {ref, computed, watch, toRaw} from 'vue';
import {AgGridVue} from 'ag-grid-vue3';
import type {GridApi, CellValueChangedEvent} from 'ag-grid-community';

import type * as Permission from '../../Permission';
import type {AssessDetail, Permissions} from '../type';
import {useMetaQuery} from '../../../../../../../../guigu_pm/guigu_pm/public/js/components/page/useMetaQuery';
import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';

import {getColumns} from './fixScoreColumns';

interface Props{
	linkAssessList:(AssessDetail&{index:number, type_label:string})[]
	tempAssessList:(AssessDetail&{index:number, type_label:string})[]
	permission: Permission.DocPermission | null
	assessmentPermission: Permission.DocPermission | null
	lockStatus:boolean
}
const props = defineProps<Props>();
interface Emit{
	(e: 'update', field:string, value:string|number, data:AssessDetail): void,
	(e: 'remove', data:AssessDetail, type:'detail'|'extra'): void,
	(e: 'selectionChanged', selectedRows: AssessDetail[]): void,
}
const emit = defineEmits<Emit>();

const smallMeta = useMetaQuery();
const gridApi = ref<GridApi>();

const onGridReady = (params: any) => {
	gridApi.value = params.api;
};
const assessList = computed(()=>[...toRaw(props.linkAssessList), ...toRaw(props.tempAssessList)]);
function onRemove(data:AssessDetail) {
	emit('remove', data, 'detail');
}

watch([
	smallMeta, gridApi, ()=>props.permission, () => props.assessmentPermission,
	()=>props.lockStatus,
	assessList,
], ([
	smallMeta, api, permission, assessmentPermission, lockStatus,
])=>{
	api?.setColumnDefs(getColumns(onRemove, permission, assessmentPermission, lockStatus, smallMeta));
});
const defaultColDef = {
	sortable: false,
	filter: true,
	resizable: true,
	menuTabs: [],
	suppressMovable: true,
};

const columnDefs = computed(() => getColumns(onRemove, props.permission, props.assessmentPermission, props.lockStatus, smallMeta));

const isRowSelectable = (node: any) => node.data.name !== 'auto_calc' && props.lockStatus === false;

async function cellValueChanged(event: CellValueChangedEvent<AssessDetail>) {
	const {data, value, colDef: {field}} = event;
	let fieldFormat:string = '';
	if (data.link_doctype) {
		switch (field) {
			case 'standard': fieldFormat = 'assessment_criteria'; break;
			case 'remark': fieldFormat = 'assessment_comments'; break;
			default: fieldFormat = field || '';
		}
	} else {
		fieldFormat = field || '';
	}
	if (!fieldFormat) {
		return;
	}
	emit('update', fieldFormat as string, value, data);
}
function getRowId(params: any) {
	return params.data.name;
}

function onSelectionChanged(event) {
	const selectedRows = event.api.getSelectedRows();
	emit('selectionChanged', selectedRows);
}
</script>

</script>
  <style lang="less" scoped>
  .assess-table {
	width: 100%;
	:deep(.rowSpanCell) {
		display: flex;
		align-items: center;
		background: #fff;
		border-bottom: 1px solid #d9dddf;
	}
	:deep(.ag-row-last .rowSpanCell){
		border-bottom: 0;
	}
	:deep(.ag-header-group-cell-label .ag-header-group-text) {
		width: 100%;
		text-align: center;
	}
  }
  </style>
