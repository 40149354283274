<template>
	<ElDialog
		v-model="dialogVisible"
		:title="tt('Update Version')"
		:width="smallMeta?'80%':'500'"
	>
		<ElForm
			labelPosition="top"
			ref="formRef"
			:rules="rules"
			:model="versionNoFormValue"
		>
			<ElFormItem :label="tt('Version No')" prop="versionNo">
				<ElInput
					v-model="versionNoFormValue.versionNo"
				/>
			</ElFormItem>
		</ElForm>
		<template #footer>
			<div class="dialog-footer">
				<ElButton @click="dialogVisible = false">{{ tt('Cancel') }}</ElButton>
				<ElButton type="primary" @click="confirm">
					{{ tt('Confirm') }}
				</ElButton>
			</div>
		</template>
	</ElDialog>
</template>

<script lang="ts" setup>
import {computed, reactive, ref, watch} from 'vue';
import {ElDialog, ElFormItem, ElInput, ElButton, ElForm, type FormRules, type FormInstance} from 'element-plus';

import {useMetaQuery} from '../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';

import {useInstructionStore} from './store';
const smallMeta = useMetaQuery();

const tt = __;
interface Props {
	visible:boolean,
}
const props = defineProps<Props>();
interface Emit {
	(e: 'update:visible', visible: boolean): void,
}
const emit = defineEmits<Emit>();
const store = useInstructionStore();
const formRef = ref<FormInstance>();
const rules = reactive<FormRules<{versionNo:string}>>({
	versionNo: [
		{required: true, message: __('Please input version no'), trigger: 'blur'},
	],
});

const dialogVisible = computed({
	get() {
		return props.visible;
	},
	set(visible:boolean) {
		emit('update:visible', visible);
	},
});
const versionNoFormValue = reactive({
	versionNo: store.currentVersion.instruction_version,
});
watch([()=>store.currentVersion.instruction_version, dialogVisible], ()=>{
	if (dialogVisible.value) {
		versionNoFormValue.versionNo = store.currentVersion.instruction_version;
	}
}, {immediate: true});

const submitForm = async (formEl: FormInstance | undefined) => {
	if (!formEl) {
		return;
	}
	await formEl.validate(valid => {
		if (valid) {
			store.changeVersionNo(versionNoFormValue.versionNo);
			dialogVisible.value = false;
		}
	});
};
function confirm() {
	submitForm(formRef.value);
}

</script>
