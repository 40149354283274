import moment from 'moment';


export const processLevel: string[] = [
	'',
	'Process Level 1',
	'Process Level 2',
	'Process Level 3',
	'Process Level 4',
	'Process Level 5',
	'Process Level 6',
	'Process Level 7',
	'Process Level 8',
	'Process Level 9',
	'Process Level 10',
];
// 数据更新后，设置数据格式
export function setData(data: any, path: string[], isProcess: boolean) {
	if (isProcess) {
		return {
			name: data.name,
			title: data.process_name,
			parent_dw_process: data.parent_dw_process,
			disable: data.disable,
			is_process: true,
			level: data.level,
			full_process_code: data.full_process_code,
			root_id: data.root_id,
			path,
		};
	}
	return {
		name: data.name,
		title: data.work_list_title,
		parent_dw_process: data.work_process,
		disable: data.disable,
		is_process: false,
		modified: moment(data.modified).format('YYYY-MM-DD'),
		is_core_work_list: data.is_core_work_list,
		full_code: data.worklist_code,
		organization: data.specific_organization,
		path,
	};
}
