frappe.ui.form.ControlTextEditor = class ControlTextEditor extends frappe.ui.form.ControlCode {
    make_wrapper() {
        super.make_wrapper();
    }

    make_input() {
        this.has_input = true;
        this.make_ckeditor();
    }
    make_ckeditor() {
        const that = this;
        this.disp_area = null;
        const resizeObserver = new ResizeObserver(entries => {
            for (const entry of entries) {
                if (entry.contentBoxSize) {
                    // Firefox implements `contentBoxSize` as a single content rect, rather than an array
                    const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;
                    $(this.input_area).parent().css('--ck-toolbar-dropdown-max-width', `${contentBoxSize.inlineSize}px`);
                } else {
                    $(this.input_area).parent().css('--ck-toolbar-dropdown-max-width', `${entry.contentRect.width}px`);
                }
            }
        });
        resizeObserver.observe($($(this.input_area).parent())[0]);

        this.quill_container = $('<div>').appendTo(this.input_area);
        ClassicEditor.create(this.input_area, {
            toolbar: {
                items: [
                    'fullscreen',
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'underline',
                    'link',
                    'bulletedList',
                    'numberedList',
                    '|',
                    'outdent',
                    'alignment',
                    'indent',
                    '|',
                    'fontBackgroundColor',
                    'fontColor',
                    'fontSize',
                    'fontFamily',
                    '|',
                    'imageInsert',
                    'insertTable',
                    '|',
                    'blockQuote',
                    'mediaEmbed',
                    'undo',
                    'redo',
                    'todoList',
                    'code',
                    'codeBlock',
                    'findAndReplace',
                    'specialCharacters',
                ],
            },
        }).then(editor => {
            that.activeEditor = editor;
            if (that.df.max_height) {
                const ckEditorMain = that.$wrapper.find('.ck-editor__main');
                if (ckEditorMain) {
                    ckEditorMain.css({ 'max-height': that.df.max_height, overflow: 'auto' });
                }
            }
            if (that.df.read_only === '1' || that.df.read_only === 1) {
                editor.enableReadOnlyMode('1');
            } else {
                editor.disableReadOnlyMode('1');
            }

            if (that.value) {
                editor.setData(that.value);
            }
            editor.model.document.on('change:data', () => {
                if (this.alreadySet) { return; }
                that.parse_validate_and_set_in_model(editor.getData());
            });
        });
    }

    alreadySet = false;
    activeEditor: any;

    set_formatted_input(value) {
        let editorValue = this?.activeEditor?.getData() || '';
        if (value === editorValue) { return; }
        if (this.frm && !this.frm.doc.__setContent && this.activeEditor) {
            this.alreadySet = true;
            this.activeEditor.setData(value || '');
            this.alreadySet = false;
        }
    }

    get_input_value() {
        let editorValue = this?.activeEditor?.getData() || '';
        // hack to retain space sequence.
        editorValue = editorValue.replace(/(\s)(\s)/g, ' &nbsp;');
        return editorValue;
    }
};
