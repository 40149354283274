import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.doctypes, (doctype, index) => {
    return (_openBlock(), _createBlock($setup["Table"], {
      key: doctype.document,
      data: $props.doctypeList[index],
      config: doctype,
      smallMeta: $props.smallMeta,
      user: $props.user,
      date: $props.date,
      useDetail: $props.useDetail,
      onShowDetail: _cache[0] || (_cache[0] = (name, detailDoctype)=>$setup.emit('showDetail', name, detailDoctype))
    }, null, 8 /* PROPS */, ["data", "config", "smallMeta", "user", "date", "useDetail"]))
  }), 128 /* KEYED_FRAGMENT */))
}