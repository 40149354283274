<template>
	<div class="action_container" :style="containerStyle" v-if="!hideAction">
		<ElButton class="before" :style="{ 'margin': '0' }" v-for="item in beforeButtons"
			v-bind="item.attr"
			:disabled="readonly"
			@click="customClick(item)">
			{{ item.attr.title }}
		</ElButton>
		<ElTooltip content="编辑" v-if="props.params.onEdit">
			<ElButton :style="{ 'margin': '0', display:'flex' }" :icon="Edit"
				@click="edit" />
		</ElTooltip>
		<ElPopconfirm
			v-if="props.params.onUnConnect"
			title="您确定解除关联吗？"
			@confirm="unConnection"
			confirmButtonText="确定"
			cancelButtonText="取消">
			<template #reference>
				<div :style="{ 'margin': '0', display:'flex' }">
					<ElTooltip content="解除关联">
						<ElButton :disabled="readonly">
							<ElIcon>
								<Link color="#2490EF" />
							</ElIcon>
						</ElButton>
					</ElTooltip>
				</div>
			</template>
		</ElPopconfirm>
		<ElPopconfirm
			v-if="props.params.onRemove"
			:title="params.deleteTip||'您确定直接删除这条数据吗'"
			@confirm="deleteRow"
			confirmButtonText="确定"
			cancelButtonText="取消">
			<template #reference>
				<div :style="{ 'margin': '0', display:'flex' }">
					<ElTooltip content="删除">
						<ElButton
							:disabled="readonly">
							<ElIcon>
								<Delete color="#E24C4C" />
							</ElIcon>
						</ElButton>
					</ElTooltip>
				</div>
			</template>
		</ElPopconfirm>
		<ElButton v-for="item in afterButtons" v-bind="item.attr"
			:disabled="readonly"
			@click="customClick(item)">
			{{ item.attr.title }}
		</ElButton>
	</div>
</template>

<script setup lang='ts'>
import { computed, watch } from 'vue';
import { ElButton, ElPopconfirm, ElTooltip, ElIcon } from 'element-plus';
import { Delete, Link, Edit } from '@element-plus/icons-vue';

import type { CustomActionButton, CustomButton } from '../type';

interface Params {
	data: any
	customActionButton: CustomActionButton[],
	onUnConnect?: (data: any) => void,
	onRemove?: (data: any) => void,
	onEdit?: (data: any) => void
	readonly?: boolean
	deleteTip?:string
}
const props = defineProps<{ params: Params }>();
const hideAction = computed(() => props.params.node.group||props.params.node.data.__IS_AGGREGATION__);
function buttonAttr(buttons: CustomActionButton[]) {
	const elementButtons: CustomActionButton[] = [];
	for (const item of buttons) {
		const buttonAttr = item?.attr && typeof item?.attr === 'function' ? item?.attr(props.params.data) : item?.attr || {};
		if (!buttonAttr?.hidden && buttonAttr?.title) {
			elementButtons.push(
				{
					position: item.position,
					index: item.index,
					attr: buttonAttr,
					fun: item.fun,
				},
			);
		}
	}
	return elementButtons;
}
const beforeButtons = computed(() => {
	const buttons = props.params.customActionButton.filter(item => item.position === 'before').sort((pre, next) => pre.index - next.index);
	const elementButtons: CustomActionButton[] = buttonAttr(buttons);

	return elementButtons;
});
const afterButtons = computed(() => {
	const buttons = props.params.customActionButton.filter(item => item.position === 'after').sort((pre, next) => pre.index - next.index);
	const elementButtons: CustomActionButton[] = buttonAttr(buttons);

	return elementButtons;
});

function customClick(btn: CustomActionButton) {
	if (btn.fun && typeof btn.fun === 'function') {
		btn.fun(props.params.data);
	}
}
const readonly = computed(() => props.params.readonly);

function unConnection() {
	props.params.onUnConnect?.(props.params.data);
}
function deleteRow() {
	props.params.onRemove?.(props.params.data);
}
function edit() {
	props.params.onEdit?.(props.params.data);
}
const containerStyle = {
	'display': 'flex',
	'height': '100%',
	'justify-content': 'space-around',
	'align-items': 'center',
};
</script>

<style lang='less' scoped>
.action_container {
	display: flex;
	height: 100%;
	justify-content: space-around;
	align-items: center;
}
</style>
