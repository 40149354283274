import {Ref} from 'vue';

export default function createDoc(
	docMeta: Ref<locals.DocType>,
	allFilters: Ref<[string, string, string, any][]>,
	options: Record<string, any> = {},
	extend?: boolean,
) {
	const meta = docMeta.value;
	const doctype = meta.name;
	let data = {};
	if (extend) {
		const list = allFilters.value
			.filter(v => (v[0] === doctype || !v[0]))
			.filter(v => v[2] === '=')
			.filter(v => v[3] !== undefined);
		const inFilterList = allFilters.value
			.filter(v => (v[0] === doctype || !v[0]))
			.filter(v => v[2] === 'in')
			.filter(v => Array.isArray(v[3]) && v[3].length === 1);
		const inFilterData = Object.fromEntries(inFilterList.map(v => [v[1], v[3][0]]));
		data = {...Object.fromEntries(list.map(v => [v[1], v[3]])), ...inFilterData};
	}
	const doc: Record<string, any> = frappe.model.get_new_doc(doctype, null, null);
	for (const [k, v] of Object.entries({...data, ...options})) {
		const df = meta.fields.find(v => v.fieldname === k);
		if (!df || df.no_copy) {
			continue;
		}
		let value = v;
		if (df.fieldtype === 'Link' && df.options === 'User') {
			if (v === 'ME') {
				value = frappe.session.user;
			}
		}
		doc[k] = value;
	}
	frappe.set_route('Form', doctype, doc.name);
}
