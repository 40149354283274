<template>
	<span :title="text">
		<span class="title">
			{{ text }}
		</span>
		<ElIcon class="button" @click="add">
			<Plus />
		</ElIcon>
		<ElIcon class="button" @click="edit">
			<Setting />
		</ElIcon>
	</span>
</template>

<script lang="ts" setup name="KanbanBoardRowHeader">
import {computed} from 'vue';
import {ElIcon} from 'element-plus';
import {Setting, Plus} from '@element-plus/icons-vue';

const props = defineProps<{
	title: string;
	n: number;
}>();
const text = computed(() => {
	const {title, n} = props;
	return n ? `${title}(${n})` : `${title}`;
});
const emit = defineEmits<{
	(event: 'setting'): void
	(event: 'add'): void
}>();
function edit() {
	emit('setting');
}
function add() {
	emit('add');
}
</script>


<style lang="less" scoped>
.title {
	flex: 1;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	// max-inline-size: 200px;
}


.button {
	cursor: pointer;
	height: calc(var(--col-group-width) - 22px);
	line-height: calc(var(--col-group-width) - 22px);

	&+& {
		margin-left: 8px;
	}
}
</style>
