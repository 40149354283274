import { createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_alert = _resolveComponent("el-alert")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["AgGridVue"], {
      class: "ag-theme-guigu assess-table",
      rowData: $props.assessList,
      defaultColDef: $setup.defaultColDef,
      animateRows: "true",
      onGridReady: $setup.onGridReady,
      suppressDragLeaveHidesColumns: $setup.smallMeta,
      localeText: $setup.zhCN,
      rowGroupPanelShow: "always",
      getRowId: $setup.getRowId
    }, null, 8 /* PROPS */, ["rowData", "suppressDragLeaveHidesColumns", "localeText"]),
    _createVNode(_component_el_alert, {
      title: $setup.prompt,
      type: "warning",
      "show-icon": "",
      closable: false
    }, null, 8 /* PROPS */, ["title"])
  ], 64 /* STABLE_FRAGMENT */))
}