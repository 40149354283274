<template>
	<span :style="{color}">{{ tt(params.value) }}</span>
</template>

<script setup lang="ts">
import type {CellClassParams} from 'ag-grid-community';
import {computed} from 'vue';
const props = defineProps<{
	params: CellClassParams,
}>();
const tt = __;
const color = computed(() => frappe.utils.guess_colour(props.params.value));
</script>
