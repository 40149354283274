<template >
	<Page>
		<template #title>
			<h3 class="title">{{ tt('Clause Interpretation Library Page') }}</h3>
		</template>
		<template #sider>
			<IndustryTree
				@dataChange="treeClick"
				:currentNode="currentNode" />
		</template>
		<Main
			:value="mainValue" />
	</Page>
</template>
<script setup lang="ts">
import { ref, onBeforeMount, watch, computed, shallowRef } from 'vue';

import Page from '../../../components/page/index.vue';
import Main from '../Main/index.vue';

import IndustryTree from './industry_tree.vue';
const tt = __
const treeDoctype = ref<string>('');
const domVisible = true;
// const data = ref();
const docMeta = ref();
const settings = ref();
const paramsFilter = ref<Record<string, any>>({});
const currentNode = ref<string>('');
const mainValue = ref();

function treeClick(data: any) {
	treeDoctype.value = data.doctype;
	paramsFilter.value = data.filter;
	currentNode.value = data.key;
	const route = frappe.router?.current_route || [];
	route[1] = data.doctype;
	const newRoute = [route[0], route[1]];
	for (const item of Object.keys(data.filter || [])) {
		newRoute[newRoute.length] = item;
		newRoute[newRoute.length++] = data.filter[item];
	}
	frappe.set_route(newRoute);
}

async function init(docType: string, filterObj: { [key: string]: string }) {
	const resultFilter: any[] = [];
	for (const item of Object.keys(filterObj)) {
		resultFilter.push([docType, item, '=', filterObj[item]]);
	}
	mainValue.value = {
		doctype: docType,
		fields: [],
		filters: JSON.stringify(resultFilter),
		tree: true,
	};

}

async function routeInit() {
	const [_, routeDocType, ...filters] = frappe.router?.current_route || [];

	const filterObj = {};
	const keyArr = ['project', 'industry_version'];
	for (let i = 0; i < filters.length; i++) {
		if ((i & 1) === 0 && keyArr.includes(filters[i])) {
			filterObj[filters[i]] = filters[i + 1];
		}
	}

	if (routeDocType) {
		init(routeDocType, filterObj);
		const objValues = Object.values(filterObj || {});
		if (objValues.length) {
			currentNode.value = objValues.join('-');
		} else {
			currentNode.value = routeDocType;
		}
	}
	paramsFilter.value = filterObj;

}

watch(() => [treeDoctype.value, paramsFilter.value], () => {
	if (treeDoctype.value) {
		init(treeDoctype.value, paramsFilter.value || {});
	}
});
onBeforeMount(routeInit);

</script>
<style>
.title {
	margin-bottom: 0;
}
</style>
