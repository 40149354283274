<template>
	<ElDialog
		v-model="dialogVisible"
		:title="tt('Load Instruction Template')"
		width="80%"
		top="5vh"
	>
		<div class="body">
			<ElTable
				ref="tableRef"
				class="table"
				:data="templateList"
				max-height="200"
				style="width: 100%"
				stripe
				border
				@select="selectionChange"
			>
				<ElTableColumn type="selection" width="55" />
				<ElTableColumn property="template_name" :label="tt('Instruction Template Title')" />
			</ElTable>
			<div class="editor-container">
				<h2 class="title">{{ title }}</h2>
				<Editor
					height="100%"
					:value="content"
				></Editor>
			</div>
		</div>

		<template #footer>
			<div class="dialog-footer">
				<ElButton @click="dialogVisible = false">{{ tt('Cancel') }}</ElButton>
				<ElButton type="primary" @click="confirm">
					{{ tt('Confirm') }}
				</ElButton>
			</div>
		</template>
	</ElDialog>
</template>

<script lang="ts" setup>
import {computed, ref, watch} from 'vue';
import {ElDialog, ElButton, ElTable, ElTableColumn, type TableInstance} from 'element-plus';

import type {Template} from './type';
import {useInstructionStore} from './store';
const Editor = frappe.tianjy_editor.editor;

const tt = __;
interface Props {
	visible:boolean,
}
const props = defineProps<Props>();
interface Emit {
	(e: 'update:visible', visible: boolean): void,
}
const emit = defineEmits<Emit>();
const selectionData = ref<Template>();
const tableRef = ref<TableInstance>();
const store = useInstructionStore();
const templateList = ref<Template[]>([]);
const dialogVisible = computed({
	get() {
		return props.visible;
	},
	set(visible:boolean) {
		emit('update:visible', visible);
	},
});
async function getTemplates() {
	const result = await frappe.call<{message:Template[]}>({
		method: 'dw_worklist_management.dw_worklist_management.doctype.dw_work_instruction.dw_work_instruction.get_instruction_templates',
	});
	templateList.value = result?.message || [];
}
watch(dialogVisible, ()=>{
	if (dialogVisible.value) {
		getTemplates();
	}
});
watch([tableRef, dialogVisible], ()=>{
	if (!tableRef.value) {
		return;
	}
	tableRef.value?.clearSelection();
}, {immediate: true});

const content = computed(()=>selectionData.value?.version?.content || '');
const title = computed(()=>selectionData.value?.version?.title || '');

function selectionChange(selected:Template[], row:Template) {
	const isSelectRow = selected.some(item=>item.name === row.name);
	if (isSelectRow && selectionData.value) {
		tableRef.value?.toggleRowSelection(selectionData.value, false);
	}
	if (isSelectRow) {
		selectionData.value = row;
	} else {
		selectionData.value = undefined;
	}
}
function confirm() {
	if (selectionData.value?.work_instruction) {
		store.loadTemplateInstruction(selectionData.value?.work_instruction);
	}
	dialogVisible.value = false;
}
</script>
<style scoped lang="less">
.table :deep(.el-table__header-wrapper  .el-checkbox){
	display:none
}
.table :deep(label){
	margin-bottom: 0;
}
.body{
	display: flex;
	flex-direction: column;
	height: 71vh;
}
.editor-container{
	flex: 1;
	overflow-y: auto;
}
.title{
	text-align: center
}

</style>
