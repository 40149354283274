<template>
	<el-card class="box-card">
		<template #header>
			<div class="card-header">
				<span>{{ tt('To Do') }}</span>
				<el-button v-if="isCurrentOwner&&toDoPermissions.createPermission" class="button" type="primary"
					@click="createToDo">{{ tt('Create to-do') }}</el-button>
			</div>
		</template>
		<el-card v-for="todo in showToDoList">
			<div class="header">
				<div class="header-lft">
					<span class="icon">
						<svg class="icon icon-sm">
							<use href="#icon-calendar"></use>
						</svg>
					</span>
					<el-button class="desc" text @click="edit(todo)">{{
						getDescription(todo.description) }}</el-button>
				</div>
				<div class="delete">
					<el-icon v-if="isCurrentOwner&&toDoPermissions.deletePermission" @click="deleteToDo(todo)"
						:style="{ color: '#ff4d4f', cursor: 'pointer' }">
						<Delete />
					</el-icon>
				</div>
			</div>
			<div class="content">
				<div>{{ todo.date }}</div>
				<div :class="['status', 'indicator-pill', guessColor(todo.status)]">
					{{ tt(todo.status||'') }}
				</div>
			</div>
		</el-card>
	</el-card>
</template>

<script setup lang='ts'>
import { onMounted, ref, computed, watch } from 'vue';
import { TODO } from './type';
import { Delete } from '@element-plus/icons-vue';
import { ElMessage, ElMessageBox } from 'element-plus';

const tt = __;
interface Props{
	daily_record_name:string,
	owner:string
}
const props = defineProps<Props>();
interface Emit{
	(e: 'change', list:any[]): void
}
const emit = defineEmits<Emit>();
const toDoList = ref<TODO[]>([])
const toDoMeta = ref<any>()
let id = 0;


const isCurrentOwner = computed(()=>props.owner === frappe.user.name || frappe.user.has_role('System Manager'))
const toDoPermissions = computed(() => {
	if (!toDoMeta.value) {
		return { deletePermission: false, createPermission: false, writePermission:false };
	}
	const deletePermission = frappe.perm.has_perm("ToDo", 0, 'delete');
	const createPermission = frappe.perm.has_perm("ToDo", 0, 'create');
	const writePermission = frappe.perm.has_perm("ToDo", 0, 'write');
	return { deletePermission, createPermission, writePermission };
});
onMounted(async () => {
	await frappe.model.with_doctype('ToDo');
	toDoMeta.value = frappe.get_meta('ToDo');
})
watch(()=>props.daily_record_name,()=>{
	getToDoList()
},{immediate:true})
async function getToDoList(){
	if(!props.daily_record_name){
		toDoList.value = []
		return;
	}
	const res:TODO[]= await frappe.db.get_list('ToDo',{filters:[
		['reference_type','=', 'Guigu Daily Record B'],
		['reference_name','=', props.daily_record_name],
	], fields:'*'})
	toDoList.value = res||[]
}
const showToDoList = computed(()=>toDoList.value.filter(item=>item.type!=='delete'))
async function createToDo(){
	const fields = toDoMeta.value.fields.filter(item=>item.fieldname!='reference_type'&&item.fieldname!=='reference_name')
	const dialog = new frappe.ui.Dialog({
			title: tt("New ToDo"),
			no_submit_on_enter: true,
			fields: fields,
			primary_action_label: tt("Create"),
			primary_action: function (values:TODO) {
				values.name = `new_${id++}`;
				values.type='create'
				toDoList.value.push(values)
				emit('change', toDoList.value)
				dialog.hide()
			},
		});
	dialog.show();
}
function deleteToDo(todo:TODO){
	ElMessageBox.confirm(
			tt('Are you sure you want to delete this to-do item?'),
			tt('please confirm'),
			{
				confirmButtonText: tt('Confirm'),
				cancelButtonText: tt('Cancel'),
				type: 'warning',
			}
		).then(async () => {
			toDoList.value.forEach(item=>{
				if(todo.name===item.name){
					item.type='delete'
				}
			})
			emit('change', toDoList.value)
			ElMessage({
				type: 'success',
				message: tt('Delete Successful'),
			});
		}).catch(() => {
			ElMessage({
				type: 'info',
				message: tt('Cancel Delete'),
			});
		});
	
}
function edit(todo:TODO){
	if(!isCurrentOwner.value||!toDoPermissions.value.writePermission){
		return
	}
	const fields = toDoMeta.value.fields.filter(item=>item.fieldname!='reference_type'&&item.fieldname!=='reference_name')
	const dialog = new frappe.ui.Dialog({
			title: tt("Edit ToDo"),
			no_submit_on_enter: true,
			fields: fields,
			primary_action_label: tt("Edit"),
			primary_action: function (values:TODO) {
				const index = toDoList.value.findIndex(item=>todo.name===item.name)
				values.name = todo.name;
				values.type=todo.type==='create'?'create':'edit';
				toDoList.value.splice(index, 1, values)
				emit('change', toDoList.value)
				dialog.hide()
			},
		});
	dialog.set_values(todo)
	dialog.show();
}
function getDescription(string:string){
	const ele = document.createElement('div')
	ele.innerHTML = string
	return ele.innerText?.replace(/\s+/g, '');
}
function guessColor(value?:string){
	return frappe.utils.guess_colour(value)
}
</script>

<style lang='less' scoped>
.box-card {
	:deep(.card-header) {
		background-color: #fff;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 0;
		padding:0;
	}
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 7px;

	.header-lft {
		display: flex;
		align-items: center;
		overflow: hidden;

		.icon {
			margin-right: 7px;
		}

		.desc {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}

	.status {
		flex-shrink: 0;
		margin-left: 50px;
	}
}

.content {
	display: flex;
	justify-content: space-between;
}
</style>
