import type { ExportItem } from '../type';

export function getExportData(assessInfoList: ExportItem[]) {
  return assessInfoList.map((assessInfo) => {
    const { date } = assessInfo;
    const linkAssessList = assessInfo.detail
      .filter((item) => item.doc_name && item.link_doctype)
      .map((item) => ({
        subject: item.subject || '',
        standard: item.standard || '',
        total_score: item.total_score || '',
        work_criteria: item.work_criteria || '',
        evaluation_basis: item.evaluation_basis || '',
        remark: item.remark || '',
        self_assessment: item.self_assessment || '',
        leader_assessment: item.leader_assessment || '',
      }));
    const tempAssess = assessInfo.temp.map((item) => ({
      subject: item.subject || '',
      standard: item.standard || '',
      total_score: item.total_score || '',
      work_criteria: item.work_criteria || '',
      evaluation_basis: item.evaluation_basis || '',
      remark: item.remark || '',
      self_assessment: item.self_assessment || '',
      leader_assessment: item.leader_assessment || '',
    }));
    const totalScore =
      assessInfo.detail.map((item) => item.total_score || 0).reduce((pre, next) => pre + next, 0) +
      tempAssess.map((item) => parseFloat(item.total_score.toString() || '0')).reduce((pre, next) => pre + next, 0);
    const selfAssessment =
      assessInfo.detail.map((item) => item.self_assessment || 0).reduce((pre, next) => pre + next, 0) +
      tempAssess.map((item) => parseFloat(item.self_assessment.toString() || '0')).reduce((pre, next) => pre + next, 0);
    const leaderAssessment =
      assessInfo.detail.map((item) => item.leader_assessment || 0).reduce((pre, next) => pre + next, 0) +
      tempAssess.map((item) => parseFloat(item.leader_assessment.toString() || '0')).reduce((pre, next) => pre + next, 0);
    const allFixScore = selfAssessment * 0.1 + leaderAssessment * 0.9;
    const extraAssess =
      assessInfo.extra_score
        .sort((pre, next) => (pre.type > next.type ? 1 : -1))
        .map((item, index) => ({
          ...item,
          subject: item.subject || '',
          reason: item.reason || '',
          score: item.score || '',
          index: index + 1,
          type_label: item.type === 'Add' ? __('Assessment Bonus Item') : __('Subtraction Item'),
        })) || [];
    const extraScore = extraAssess
      .map((item) => (item.type === 'Add' ? parseFloat(item.score.toString() || '0') : -parseFloat(item.score.toString() || '0')))
      .reduce((pre, next) => pre + next, 0);
    const info = {
      date: assessInfo.date,
      user_doc: assessInfo.user_doc,
      department_doc: assessInfo.department_doc,
      total_score: assessInfo.total_score,
      level: assessInfo.level,
      organization: assessInfo.organization,
      detail: {
        level: assessInfo.level || '',
        year: moment(date).format('YYYY'),
        month: moment(date).format('MM'),
        department: assessInfo?.department_doc?.label,
        organization: assessInfo.organization || '',
        name: assessInfo?.user_doc.full_name,
        date: moment(date).format('YYYY-MM'),
        linkAssessList,
        tempAssess,
        totalScore: totalScore.toFixed(2),
        selfTotalScore: selfAssessment.toFixed(2),
        leaderTotalScore: leaderAssessment.toFixed(2),
        extraAssess,
        allScore: (allFixScore + extraScore).toFixed(2),
      },
      interview: {
        level: assessInfo.level,
        user: assessInfo?.user_doc.full_name || '',
        assess_date: assessInfo.interview_record?.assess_date || '',
        organization: assessInfo.interview_record?.organization || '',
        job: assessInfo.interview_record?.job || '',
        leader: assessInfo.interview_record?.leader_doc.full_name || '',
        date: assessInfo?.date ? moment(assessInfo.date).format('YYYY-MM') : '',
        summary: assessInfo.interview_record?.summary || '',
        advantage: assessInfo.interview_record?.advantage || '',
        shortcoming: assessInfo.interview_record?.shortcoming || '',
        requirement: assessInfo.interview_record?.requirement || '',
        learning: assessInfo.interview_record?.learning || '',
        suggestion: assessInfo.interview_record?.suggestion || '',
      },
    };
    return info;
  });
}
