<template>
	<ElCard class="card">
		<template #header>
			<div class="header">
				<span>{{ title }}</span>
				<div v-if="bind">
					<ElSwitch
						v-model="messageEnabled"
						:disabled="!subscribeLoading && disabled"
						:loading="subscribeLoading"
						:title="tt('Subscription Notification')" />
					<ElButton class="btn" :disabled="!bindLoading && disabled" :loading="bindLoading" @click.prevent="unbind">
						{{ tt('Unbind WeChat') }}
					</ElButton>
				</div>
				<a v-else :href="url">
					<ElButton class="btn" :disabled="disabled">{{ tt('Bind WeChat') }}</ElButton>
				</a>
			</div>
		</template>
		<ElImage class="image" disabled="disabled" :src="wechatQrcode" fit="scale-down" />
	</ElCard>
</template>
<script lang="ts" setup>
import {computed, ref, watch} from 'vue';
import {ElButton, ElSwitch, ElCard, ElImage} from 'element-plus';

const tt = __;

const props = defineProps<{
	appid: string;
	agentid?: string;
	name: string;
	title: string;
	loginEnabled: 0 | 1;
	disabled?: boolean;
	bind: boolean;
	enabled?: number;
	wechatQrcode?: string;
	loginUrl?: string;
}>();
const emit = defineEmits<{
	(event: 'unbind', appid: string, agentid?: string): void;
	(event: 'subscribe' | 'unsubscribe', agentid?: string): void;
}>();
const subscribeLoading = ref(false);
const bindLoading = ref(false);
const url = computed(() => {
	const {appid, agentid, name, loginUrl} = props;
	let url = loginUrl || '/wechat_login';
	[url] = url.split('#', 1);
	if (!url.includes('?')) {
		url += '?';
	}
	url += `&appid=${encodeURIComponent(appid)}`;
	url += `&state=${encodeURIComponent(name)}`;
	if (agentid) {
		url += `&agentid=${encodeURIComponent(agentid)}`;
	}
	url += `&redirect_uri=${encodeURIComponent(`${location.origin}${location.pathname}`)}`;
	return url;
});
watch(
	() => props.disabled,
	disabled => {
		if (disabled) {
			return;
		}
		subscribeLoading.value = false;
		bindLoading.value = false;
	},
);
const messageEnabled = computed({
	get: () => Boolean(props.enabled),
	set: v => {
		subscribeLoading.value = true;
		emit(v ? 'subscribe' : 'unsubscribe', props.name);
	},
});

async function unbind() {
	bindLoading.value = true;
	emit('unbind', props.name);
}
</script>

<style scoped>
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .card {
    width: 360px;
    margin: auto;
    margin-block: 20px;
    display: block;
  }

  .image {
    width: 300px;
    height: 300px;
    margin: auto;
    display: block;
  }
</style>
