import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElDialog"], {
    modelValue: $setup.dialogVisible,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.dialogVisible) = $event)),
    title: "保存",
    width: $setup.smallMeta?'80%':'500'
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["ElButton"], {
          onClick: _cache[4] || (_cache[4] = $event => ($setup.dialogVisible = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElButton"], {
          type: "primary",
          onClick: $setup.confirm
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Confirm')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode($setup["ElForm"], {
        labelPosition: "top",
        ref: "formRef",
        rules: $setup.rules,
        model: $setup.formValue
      }, {
        default: _withCtx(() => [
          ($setup.store.isSaveNewVersion)
            ? (_openBlock(), _createBlock($setup["ElFormItem"], {
                key: 0,
                labelPosition: 'top',
                label: $setup.tt('Version No'),
                prop: "versionNo"
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["ElInput"], {
                    modelValue: $setup.formValue.versionNo,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.formValue.versionNo) = $event))
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]))
            : _createCommentVNode("v-if", true),
          ($setup.store.isSaveNewVersion)
            ? (_openBlock(), _createBlock($setup["ElFormItem"], {
                key: 1,
                labelPosition: 'top',
                label: $setup.tt('instruction Compiler'),
                prop: "compiler"
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["ElInput"], {
                    modelValue: $setup.formValue.compiler,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.formValue.compiler) = $event))
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]))
            : _createCommentVNode("v-if", true),
          ($setup.store.isSaveNewVersion)
            ? (_openBlock(), _createBlock($setup["ElFormItem"], {
                key: 2,
                labelPosition: 'top',
                label: $setup.tt('instruction Checker'),
                prop: "checker"
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["ElInput"], {
                    modelValue: $setup.formValue.checker,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.formValue.checker) = $event))
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]))
            : _createCommentVNode("v-if", true),
          _createVNode($setup["ElFormItem"], {
            labelPosition: 'top',
            label: $setup.tt('Change Log'),
            prop: "changelog"
          }, {
            default: _withCtx(() => [
              _createVNode($setup["ElInput"], {
                modelValue: $setup.formValue.changeLog,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.formValue.changeLog) = $event)),
                rows: 5,
                type: "textarea"
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["rules", "model"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "width"]))
}