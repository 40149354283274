/** 实现对非当前组织工作区的隐藏 */
import { getCurrent } from './store';

const { prototype } = frappe.views.Workspace;
prototype.append_item = function append_item(item, container) {
	let is_current_page =
	frappe.router.slug(item.title) == frappe.router.slug(this.get_page_to_show().name) &&
		item.public == this.get_page_to_show().public;
	item.selected = is_current_page;
	if (is_current_page) {
		this.current_page = { name: item.title, public: item.public };
	}

	let $item_container = this.sidebar_item_container(item);
	let sidebar_control = $item_container.find(".sidebar-item-control");

	this.add_sidebar_actions(item, sidebar_control);
	let pages = item.public ? this.public_pages : this.private_pages;
	// 获取所有子级，判断子级里面有没有当前页面，有当前页面就不设置hidden，也就是展开状态
	let descendant=[];
	function getDescendant(pages, title) {
		const children = pages.filter((page) => page.parent_page == title);
		descendant = [...descendant, ... children];
		children.forEach(child=>{
			getDescendant(pages, child.title);
		});
	}
	getDescendant(pages, item.title);
	const isCurrentParent = descendant.some(page=>{
		return frappe.router.slug(page.title) == frappe.router.slug(this.get_page_to_show().name) &&
		page.public == this.get_page_to_show().public;
	});

	let child_items = pages.filter((page) => page.parent_page == item.title);
	if (child_items.length > 0) {
		let child_container = $item_container.find(".sidebar-child-item");
		// 如果是不是当前页面的祖先，才设置不显示子级
		if (!isCurrentParent) {
			child_container.addClass("hidden");
		}
		this.prepare_sidebar(child_items, child_container, $item_container);
	}

	$item_container.appendTo(container);
	this.sidebar_items[item.public ? "public" : "private"][item.title] = $item_container;
	const parents = $item_container.parents();
	// 如果是当前页面，把它的父级都显示出来
	if(is_current_page){
		parents.each(parent=>{
			if ($(parent).hasClass("hidden")) {
				parent.toggleClass("hidden");
			}
		});
	}
	// 这是frappe源码
	// if ($item_container.parents().hasClass("hidden") && is_current_page) {
	// 	$item_container.parents().toggleClass("hidden");
	// }

	this.add_drop_icon(item, sidebar_control, $item_container);

	if (child_items.length > 0) {
		$item_container.find(".drop-icon").first().addClass("show-in-edit-mode");
	}

	
	const { organization } = item;
	if (!organization) { return; }
	const def = getCurrent();
	if (def === organization) { return; }
	const other_organization_item_container = this.sidebar_items[item.public ? 'public' : 'private'][item.title];
	other_organization_item_container.hide();
};
