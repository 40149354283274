<template>
	<ElButtonGroup>
		<ElDropdown @command="layout = $event" v-if="showDetailSwitcher">
			<ElButton :title="tt('Switch Layout')" circle #icon>
				<svg>
					<use href="#icon-dashboard-list" />
				</svg>
			</ElButton>
			<template #dropdown>
				<ElDropdownMenu class="root-menu">
					<template v-for="{ label, value } in layouts" :key="value">
						<ElDropdownItem
							:class="{ ['current']: value === layout }"
							:command="value">
							{{ label }}
						</ElDropdownItem>
					</template>
				</ElDropdownMenu>
			</template>
		</ElDropdown>
		<GuiguViewPageSwitcher />

		<ElDropdown v-if="menuItems" @command="command">
			<ElButton circle :icon="MoreFilled" />
			<template #dropdown>
				<ElDropdownMenu>
					<ElDropdownItem v-if="importable" command="import">
						<a :href="importUrl">
							{{ tt('Import') }}
						</a>
					</ElDropdownItem>
					<ElDropdownItem v-if="hasSystemManger"
						command="permissions">
						<a :href="userPermissionsUrl">
							{{ tt('User Permissions') }}
						</a>
					</ElDropdownItem>
					<ElDropdownItem v-if="hasSystemManger" command="role">
						<a :href="rolePermissionsManagerUrl">
							{{ tt('Role Permissions Manager') }}
						</a>
					</ElDropdownItem>
					<ElDropdownItem v-if="customable" command="customize">
						<a :href="customizeUrl">
							{{ tt('Customize') }}
						</a>
					</ElDropdownItem>
					<ElDropdownItem v-if="editableDocType" command="editDocType">
						<a :href="editDocTypeUrl">
							{{ tt('Edit DocType') }}
						</a>
					</ElDropdownItem>
					<ElDropdownItem v-if="canAdmin" command="admin">
						{{ tt(isAdmin ? '退出管理模式' : '进入管理模式') }}
					</ElDropdownItem>
				</ElDropdownMenu>
			</template>
		</ElDropdown>
	</ElButtonGroup>
</template>

<script lang="ts" setup>
import {computed} from 'vue';
import {ElDropdown, ElButton, ElButtonGroup, ElDropdownMenu, ElDropdownItem} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue';

import GuiguViewPageSwitcher from '../../components/GuiguViewPageSwitcher/index.vue';
import {layouts} from '../constants';


defineOptions({inheritAttrs: false});


const props = defineProps<{
	meta: locals.DocType;
	showDetailSwitcher: boolean;
	layout: string;
	canAdmin?: boolean;
	isAdmin?: boolean;
}>();
const emit = defineEmits<{
	(event: 'addView'): void
	(event: 'update:layout', layout: string): void
	(event: 'update:isAdmin', isAdmin: boolean): void
}>();
const layout = computed({
	get: () => props.layout || 'link',
	set: v => {
		emit('update:layout', v);
	},
});


const doctype = computed(() => props.meta.name);

const tt = __;
function command(item: string) {
	if (item === 'admin') {
		emit('update:isAdmin', !props.isAdmin);
	}
}

const importable = computed(() => frappe.model.can_import(doctype.value, null, props.meta));
const importUrl = computed(() => {
	const path = `/app/data-import`;
	return `${path}?reference_doctype=${encodeURIComponent(doctype.value)}`;
});

const userPermissionsUrl = computed(() => {
	const path = `/app/user-permission`;
	return `${path}?allow=${encodeURIComponent(doctype.value)}`;
});


const hasSystemManger = computed(() => frappe.user_roles.includes('System Manager'));

const rolePermissionsManagerUrl = computed(() => {
	const path = `/app/permission-manager`;
	return `${path}?doctype=${encodeURIComponent(doctype.value)}`;
});


const customable = computed(() => frappe.model.can_create('Custom Field')
	&& frappe.model.can_create('Property Setter'));

const customizeUrl = computed(() => {
	const {meta} = props;
	const doctype = meta.name;
	if (meta.custom) {
		return `/app/doctype/${encodeURIComponent(doctype)}`;
	}
	const path = `/app/customize-form`;
	return `${path}?doc_type=${encodeURIComponent(doctype)}`;
});

const editableDocType = computed(() => !props.meta.custom
	&& frappe.user.has_role('System Manager')
	&& frappe.boot.developer_mode);

const editDocTypeUrl = computed(() => `/app/doctype/${encodeURIComponent(doctype.value)}`);

const menus = [
	importable,
	hasSystemManger,
	customable,
	editableDocType,
	computed(() => props.canAdmin),
];
const menuItems = computed(() => Boolean(menus.find(v => v.value)));
</script>


<style scoped lang="less">
.root-menu :deep(.current) {
	background-color: #EEE;
}
</style>
