<template>
	<ElDialog
		v-model="dialogVisible"
		@close="onCancel"
		title="关联数据"
		width="min(600px, max(min(300px, 100%), 50%))">
		<div class="search-container">
			<FilterInput v-for="f in filterLine" :key="f.fieldname"
				class="filter-input"
				size="small"
				:meta="linkMeta"

				:name="f.fieldname" :label="f.label"
				:type="f.fieldtype" :options="f.options"
				:condition="f.condition || '='"

				v-model="params[f.fieldname]" />
		</div>
		<div
			v-loading="loading"
			class="tree-container">
			<label>请选择：</label>
			<AgGridVue
				class="ag-theme-guigu"
				:columnDefs="columnDefs"
				:rowData="rowData"
				:defaultColDef="defaultColDef"
				rowSelection="multiple"
				animateRows="true"
				@grid-ready="onGridReady"
				:treeData="isTree"
				groupDefaultExpanded="-1"
				:getDataPath="getDataPath"
				domLayout="autoHeight"
				:autoGroupColumnDef="autoGroupColumn"
				:localeText="zhCN"
				@rowSelected="rowSelected"
				:suppressDragLeaveHidesColumns="smallMeta"
				@row-data-updated="onRowDataUpdated" />
			<el-pagination layout="prev, pager, next" :total="total"
				:pageSize="pageSize"
				@current-change="pageChange" />
		</div>
		<template #footer>
			<span class="dialog-footer">
				<ElButton @click="onCancel">{{ tt('Cancel') }}</ElButton>
				<ElButton type="primary" @click="save">{{ tt('Confirm') }}
				</ElButton>
			</span>
		</template>
	</ElDialog>
</template>

<script setup lang='ts'>
import { ref, computed, Component, watch } from 'vue';
import { AgGridVue } from 'ag-grid-vue3';
import { RowSelectedEvent, RowDataUpdatedEvent, ICellRendererParams } from 'ag-grid-community';
import { ElMessage, ElMessageBox } from 'element-plus';

import { debounce } from 'lodash';

import makeFilterLineFields from '../../GlobalView/makeFilterLineFields';
import zhCN from '../../agGrid/zhCN';
import FilterInput from '../../components/GuiguFilterDialog/Input/index.vue';
import { useMetaQuery } from '../../hooks/useMetaQuery';

import getFilters from './getFilters';
import FileComponent from './AggridComponents/File.vue';
import LinkComponent from './AggridComponents/Link.vue';
import BaseValueComponent from './AggridComponents/BaseValue.vue';
import { getFormatData, getTreeAncestry } from './helper';
import { ConnectionSetting, LinkField } from './type';
import LastCommentComponent from './AggridComponents/LastComment.vue';
import GuiguDateComponent from './AggridComponents/GuiguDate.vue';
import Title from './AggridComponents/Title.vue';

const tt = window.__;
interface Props {
	visible: boolean
	linkMeta: locals.DocType
	treeGroupField: string
	originSelectedList: any[]
	main_name: string
	selectDialogQueries?: { filters: any[] | Record<string, any> }
	connectionFieldSetting: ConnectionSetting
}
const props = defineProps<Props>();
interface Emit {
	(e: 'update:visible', visible: boolean): void
	(e: 'afterSave'): void
}

const emit = defineEmits<Emit>();
const loading = ref<boolean>(false);
const dialogLoading = ref<boolean>(false);
const filterText = ref<string>('');
const dialogVisible = ref<boolean>(false);

const params = ref<Record<string, any>>({});

const gridApi = ref(null);
const listData = ref<any[]>([]);
const selected = ref<any[]>(props.originSelectedList);
const total = ref<number>(0);
const page = ref<number>(1);
const pageSize = 10;
const smallMeta = useMetaQuery();

watch(() => props.originSelectedList, () => {
	selected.value = JSON.parse(JSON.stringify(props.originSelectedList));
}, { immediate: true });
const onGridReady = (params: any) => {
	gridApi.value = params.api;
};
const filterLine = computed(() => makeFilterLineFields(props.linkMeta));

const debounceFilter = debounce(search, 800);

watch(params.value, () => {
	debounceFilter();
});
watch(() => props.visible, () => {
	dialogVisible.value = props.visible;
});

const renderer: Record<string, Component> = {
	'Link': LinkComponent,
	'Tianjy Related Link': LinkComponent,
	'Tree Select': LinkComponent,
	'Attach': FileComponent,
	'Attach Image': FileComponent,
	'__LastComment__': LastCommentComponent,
	'Guigu Date': GuiguDateComponent,
	'Title':Title,
};
const columnDefs = computed(() => {
	if (!props.linkMeta) { return []; }
	const rawColumns = props.connectionFieldSetting.filter_columns;
	return rawColumns?.map((item, index) => ({
		headerName: tt(item.label),
		field: item.fieldname,
		minWidth: item.minWidth,
		maxWidth: (rawColumns.length === index + 1) ? undefined : item.maxWidth,
		width: item.width,
		flex: (rawColumns.length === index + 1) ? 1 : 0,
		resizable: true,
		cellRenderer: renderer[item.fieldtype] ? renderer[item.fieldtype] : BaseValueComponent,
		cellRendererParams: (params: ICellRendererParams) => ({ field: item }),
		checkboxSelection: !props.linkMeta?.is_tree && index === 0,
		headerCheckboxSelection: !props.linkMeta?.is_tree && index === 0,
	})) || [];
});
const defaultColDef = {
	sortable: false,
	filter: false,
	editable: false,
	enableRowGroup: false,
	resizable: true,
	minWidth: 100,
};
const isTree = computed(() => Boolean(props.linkMeta?.is_tree));
function getDataPath(data: any) {
	return data.ancestry;
}
const autoGroupColumn = computed(() => {
	const titleField = props.linkMeta?.fields?.find(item => item.fieldname === props.linkMeta?.title_field);
	const setting = props.connectionFieldSetting?.filterColumnTitleSetting;
	return {
		headerName: tt(titleField?.label || '分组'),
		flex: 1,
		minWidth: parseInt(setting?.min_width || '0') === 0 ? undefined : setting?.min_width,
		maxWidth: parseInt(setting?.max_width || '0') === 0 ? undefined : setting?.max_width,
		width: parseInt(setting?.width || '0') === 0 ? undefined : setting?.width,
		enableRowGroup: false,
		editable: false,
		field: props.linkMeta?.title_field || 'name',
		filter: false,
		sortable: false,
		pinned: 'left',
		checkboxSelection: true,
		headerCheckboxSelection: true,
	};
});
watch(() => props.visible, () => {
	if (!props.visible) { return; }
	search();
});

async function search() {
	loading.value = true;
	const defaultFilters = props.selectDialogQueries?.filters || props.connectionFieldSetting.dialog_filter || [];
	const searchFilters = getFilters(
		props.linkMeta?.name || '',
		[],
		params.value,
		filterLine.value,
	);
	const filters: any[] = [];
	if (Array.isArray(defaultFilters)) {
		filters.push(...defaultFilters);
		filters.push(...searchFilters);
	} else {
		const arrayFilters = Object.entries(defaultFilters).map(item => ({
			[item[0]]: item[1],
		}));
		filters.push(...arrayFilters);
		filters.push(...searchFilters);
	}
	const filterData = await frappe.call<{ message: { data: any[], total: number } }>({
		method: 'guigu.guigu_controls.connection_table.get_filter_data',
		args: {
			connection_doctype: props.connectionFieldSetting.connection_table,
			main_doctype: props.connectionFieldSetting.document_type,
			filter_value: filterText.value || '',
			filters,
			fieldname: props.connectionFieldSetting.field,
			limit: pageSize,
			page: page.value,
		},
	});
	listData.value = filterData?.message.data || [];
	total.value = filterData?.message.total || 0;
	loading.value = false;
}
const rowData = computed(() => {
	if (!props.linkMeta?.is_tree) { return listData.value; }
	const formatData = getFormatData(listData.value, props.linkMeta.nsm_parent_field);
	const ancestryList = getTreeAncestry(formatData, props.treeGroupField);
	return ancestryList;
});

function pageChange(newPage: number) {
	page.value = newPage;
	search();
}
function rowSelected(event: RowSelectedEvent) {
	const isSelected = event.node.isSelected();
	const hasSelected = selected.value.some(item => item.name === event.data.name);
	if (isSelected && !hasSelected) {
		const selectedCopy = JSON.parse(JSON.stringify(selected.value));
		selectedCopy.push(event.data);
		selected.value = selectedCopy;
	}
	if (!isSelected && hasSelected) {
		selected.value = selected.value.filter(item => item.name !== event.data.name);
	}
}
function onRowDataUpdated(event: RowDataUpdatedEvent) {
	event.api.forEachNode(node => node.setSelected(selected.value.some(item => item.name === node.data.name)));
}
async function save() {
	const linkedData = selected.value.filter(item => item[props.connectionFieldSetting.link_to_main_field] && item[props.connectionFieldSetting.link_to_main_field] !== props.main_name);
	// TODO 多对多提示？？
	if (linkedData.length > 0 && props.connectionFieldSetting.type === 'One To Multiple') {
		ElMessageBox.confirm(
			`您有${linkedData.length}条数据已经被关联到其他${tt(props.connectionFieldSetting.document_type)}，您确定修改关联吗？`,
			'提示',
			{
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(async () => {
			await saveConnection();
			emit('afterSave');
		}).catch(() => false);
	} else {
		await saveConnection();
		emit('afterSave');
	}
}
async function saveConnection() {
	dialogLoading.value = true;
	await frappe.call<{ message: Record<string, any>[] }>({
		method: 'guigu.guigu_controls.connection_table.save_connection',
		args: {
			parent_doctype: props.connectionFieldSetting.document_type,
			link_doctype: props.linkMeta?.name,
			parent_name: props.main_name,
			fieldname: props.connectionFieldSetting.field,
			link_data: selected.value,
		},
		error: () => {
			ElMessage.error('修改关联失败');
			loading.value = false;
		},
	});
	ElMessage({
		message: '修改关联成功',
		type: 'success',
	});
	dialogLoading.value = false;
}
function onCancel() {
	selected.value = JSON.parse(JSON.stringify(props.originSelectedList));
	emit('update:visible', false);
}
</script>

<style lang='less' scoped>
.search-container {
	border-bottom: 1px solid var(--border-color);
	display: flex;
	flex-direction: row;
	align-content: center;
	overflow: auto;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	padding: var(--padding-sm);
	min-height: 40px;
	box-sizing: content-box;
	gap: 8px;
}

.filter-input {
	width: 160px;
}

.tree-container {
	max-height: 500px;
	overflow-y: scroll;
}
</style>
