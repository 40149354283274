<script lang="ts" setup>
import {computed} from 'vue';

import getFiltersSearch from '../../utils/getFiltersSearch';

import Count from './Count.vue';
import toConfig from './toConfig';


const props = defineProps<{
	value?: any;
	editing: boolean;
	size?:'small'
}>();
const emit = defineEmits<{
	(event: 'edit'): void
}>();

const config = toConfig(props.value);
const doctype = computed(() => config.value.doctype);
const filters = computed(() => config.value.filters);
const orFilters = computed(() => config.value.orFilters);
const text = computed<string>(() => config.value.label || __(doctype.value || ''));
const url = computed(() => {
	const dt = doctype.value;
	if (!dt) {
		return '';
	}
	const root = `/app/${frappe.router.slug(dt)}/view/main`;
	const v = config.value.view;
	if (typeof v !== 'string') {
		return root;
	}
	const search = getFiltersSearch(dt, filters.value, orFilters.value, true);
	if (typeof v === 'string' && v) {
		search.unshift(`=${encodeURIComponent(v)}`);
	}
	return `${root}?${search.join('&')}`;
});
function click(e: Event) {
	if (props.editing) {
		e.preventDefault();
	}
}
</script>

<template>
	<WorkspaceWidget :class="[editing ? 'editing' : undefined, props.size==='small'?'small':undefined]">
		<template #title>
			<a v-if="url" class="link" @click="click" :href="url"
				:title="text">
				<span>
					{{ text }}
				</span>
				<svg v-if="!editing" class="icon ml-2 icon-xs"
					style="stroke: currentColor">
					<use class="" href="#icon-arrow-up-right"></use>
				</svg>
			</a>
		</template>
		<template #button v-if="config.showCount">
			<Count
				:size="props.size"
				:editing="editing"
				:view="config.view"
				:viewMust="config.countWithViewMust"
				:viewDefault="config.countWithViewDefault"
				:doctype="doctype"
				:filters="filters"
				:orFilters="orFilters" />
		</template>
	</WorkspaceWidget>
</template>
<style scoped lang="less">
.small{
	padding: 0!important;
	.link{
		font-size: var(--text-md);
		color:var(--text-color);
		font-weight: 400;
		line-height: 1.5;
	}
}
.link {
	display: flex;
	font-size: 14px;
	font-weight: 500;
	text-decoration: none !important;
	max-width: 100%;
	align-items: center;

	span {
		vertical-align: middle;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

	}

	&:hover {
		text-decoration: underline !important;
		color: var(--blue-500);
	}

	.editing & {
		font-size: 16px;
	}
}
</style>
