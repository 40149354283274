import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElRate"], {
    onClick: $setup.setFilter,
    modelValue: $setup.showValue,
    allowHalf: "",
    max: $setup.max,
    disabled: ""
  }, null, 8 /* PROPS */, ["modelValue", "max"]))
}