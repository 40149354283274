<template>
	<div class="tree-container" v-loading="loading">
		<el-tree 
			v-if="tree.length"
			class="app-tree"
			:data="tree" 
			:props="defaultProps" 
			@node-click="nodeClick" 
			highlight-current
			:expand-on-click-node="false"
			node-key="name"
			:current-node-key="currentNodeKey"
		/>
	</div>
</template>

<script setup lang='ts'>
import { ref, onMounted } from 'vue';
import { App, AppInfo, Module, ModuleInfo, TreeInfo } from './type';
import { ElTree, vLoading } from 'element-plus';
const tt = __;
interface Props{
	(event: 'setCurrent', data: AppInfo|ModuleInfo): void;
}
const props = defineProps<Props>();
interface Emit{

}
const emit = defineEmits<Emit>();
const tree = ref<TreeInfo[]>([])
const defaultProps = {
	label: 'label',
	children: 'children',
};
const currentNodeKey = ref<string>('');
const loading = ref<boolean>(true);

onMounted(()=>{
	getAppList();
})
async function getAppList(){
	loading.value = true
	const {message:{apps, modules}} = await frappe.call<{message:{app:App[],modules:Module[]}}>({
		method: 'guigu.guigu.page.guigu_app_list.guigu_app_list.get_app_list',
	});
	tree.value = apps.map(app=>{
		const childModules = modules.filter(m=>m.app_name === app.app_name).map(m=>({
			...m,
			label:tt(m.name),
			type:'Module'
		}))
		return {
			...app,
			label:tt(app.app_name),
			children:childModules,
			type:'App'
		}
	})
	emit('setCurrent', tree.value[0]);
	currentNodeKey.value = tree.value[0]?.name
	loading.value = false
}

function nodeClick(data: AppInfo|ModuleInfo) {
	emit('setCurrent', data);
}
</script>

<style lang='less' scoped>
.tree-container{
	height: 100%;
	overflow: auto;
}
</style>
