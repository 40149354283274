export default function findCol(
	separate: number[],
	start: number,
	end: number
): [number, number] | undefined {
	if (start >= end) { return; }
	const startIndex = separate.findIndex(v => v > start);
	if (startIndex < 0) { return; }
	const sIndex = Math.max(startIndex - 1, 0);
	const endIndex = separate.findIndex(v => v > end, startIndex);
	const eIndex = endIndex < 0 ? separate.length - 2 : Math.max(endIndex - 1, 0);
	if (sIndex > eIndex) { return; }
	return [sIndex, eIndex];
}
