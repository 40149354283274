import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dae41b19"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-head header" }
const _hoisted_2 = ["title"]
const _hoisted_3 = {
  class: "main",
  ref: "kb"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_GuiguFilterDialog = _resolveComponent("GuiguFilterDialog")
  const _component_GuiguViewPageSwitcher = _resolveComponent("GuiguViewPageSwitcher")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_GuiguFilterDialog, {
      meta: $props.meta,
      modelValue: $setup.filters,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.filters) = $event)),
      visible: $setup.filterVisible,
      "onUpdate:visible": _cache[1] || (_cache[1] = $event => (($setup.filterVisible) = $event))
    }, null, 8 /* PROPS */, ["meta", "modelValue", "visible"]),
    _createVNode($setup["Sorter"], {
      meta: $props.meta,
      modelValue: $setup.sort,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.sort) = $event)),
      visible: $setup.sortVisible,
      "onUpdate:visible": _cache[3] || (_cache[3] = $event => (($setup.sortVisible) = $event))
    }, null, 8 /* PROPS */, ["meta", "modelValue", "visible"]),
    _createVNode($setup["Editor"], {
      meta: $props.meta,
      value: $setup.editItem,
      onUpdate: $setup.save,
      visible: $setup.editorVisible,
      "onUpdate:visible": _cache[4] || (_cache[4] = $event => (($setup.editorVisible) = $event))
    }, null, 8 /* PROPS */, ["meta", "value", "visible"]),
    _createVNode($setup["ValueEditor"], {
      meta: $props.meta,
      loading: $setup.loading,
      visible: $setup.valueEditorVisible,
      "onUpdate:visible": _cache[5] || (_cache[5] = $event => (($setup.valueEditorVisible) = $event)),
      field: $setup.editField,
      value: $setup.editValue,
      onUpdate: $setup.saveGroupValue
    }, null, 8 /* PROPS */, ["meta", "loading", "visible", "field", "value", "onUpdate"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", {
        class: "title",
        title: $setup.title
      }, _toDisplayString($setup.title), 9 /* TEXT, PROPS */, _hoisted_2),
      _createVNode($setup["ElButtonGroup"], { class: "group" }, {
        default: _withCtx(() => [
          _createVNode($setup["KanbanSwitcher"], {
            list: $setup.kanbanList,
            modelValue: $setup.kanbanId,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($setup.kanbanId) = $event))
          }, null, 8 /* PROPS */, ["list", "modelValue"]),
          _createVNode(_component_GuiguViewPageSwitcher)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode($setup["Menu"], {
        disabled: $setup.disabled,
        list: $setup.menuItems,
        onRefresh: $setup.refresh,
        filter: $setup.filters.length,
        onFilter: _cache[7] || (_cache[7] = $event => ($setup.filterVisible = !$setup.filterVisible)),
        sort: $setup.order.length,
        onSort: _cache[8] || (_cache[8] = $event => ($setup.sortVisible = !$setup.sortVisible)),
        onFullscreen: $setup.fullscreen
      }, null, 8 /* PROPS */, ["disabled", "list", "filter", "sort"]),
      _createVNode($setup["ElButton"], {
        disabled: $setup.loading,
        onClick: $setup.add,
        title: $setup.tt('Add'),
        circle: "",
        icon: $setup.Plus,
        type: "primary"
      }, null, 8 /* PROPS */, ["disabled", "title", "icon"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      ($setup.kanban)
        ? (_openBlock(), _createBlock($setup["KanbanC"], {
            groups: $setup.stages,
            data: $setup.data,
            onUpdate: $setup.update,
            key: $setup.key,
            onSetting: $setup.edit,
            onCreate: $setup.createByParam
          }, {
            default: _withCtx(({ value }) => [
              _createVNode($setup["Item"], {
                data: value,
                meta: $props.meta,
                loading: $setup.loading,
                fields: $setup.fieldDef
              }, null, 8 /* PROPS */, ["data", "meta", "loading", "fields"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["groups", "data", "onUpdate"]))
        : (_openBlock(), _createBlock($setup["ElButton"], {
            key: 1,
            onClick: _cache[9] || (_cache[9] = $event => ($setup.kanbanId = ''))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('Create')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
    ], 512 /* NEED_PATCH */)
  ], 64 /* STABLE_FRAGMENT */))
}