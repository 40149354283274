import type { RowDataProxy } from "../types/Row";

import hideRow from './hideRow';

export default function hideAll(list: RowDataProxy[]) {
	for (const r of list) {
		r.el.remove();
		hideRow(r);
	}
	return [];
}
