import type { ColumnCell, ColumnComponent } from "../types";
import type Group from '../Group';
import type {  ColumnState } from './createHeader';
import createHeader from './createHeader';


export default class Header {
	readonly root: HTMLElement
	#group: Group
	#remove: () => void;
	constructor(
		root: HTMLElement | undefined,
		group: Group,
		remove: () => void,
	) {
		this.#remove = remove;
		this.#group = group
		const header = root || document.createElement('div')
		this.root = header;
		header.classList.add('nyloong-table', 'nyloong-table-headers');
		header.appendChild(document.createElement('div')).className = 'nyloong-table-fixed-line';
	}
	#headerMap = new Map<ColumnCell, [ColumnComponent, HTMLElement, ColumnState]>()
	#headerList: [ColumnComponent, HTMLElement, ColumnState][] = []
	_updateColumns(columns: ColumnCell[]) {
		const oldMap = this.#headerMap;
		const newMap = new Map<ColumnCell, [ColumnComponent, HTMLElement, ColumnState]>();
		const list: [ColumnComponent, HTMLElement, ColumnState][] = []
		const requestRender = () => {
			this.#group.requestRender()
		}
		this.#headerList = list;
		this.#headerMap = newMap;
		const header = this.root;
		for (const column of columns) {
			const c = oldMap.get(column) || createHeader(requestRender, header, column)
			oldMap.delete(column);
			newMap.set(column, c);
			list.push(c);
			const [component, resize] = c;
			header.appendChild(component.root);
			header.appendChild(resize);
			component.update?.(column.options);
		}
		for (const c of oldMap.values()) {
			if (!c) { continue; }
			const [component, resize] = c;
			component.root.remove();
			resize.remove();
			component.destroy();
		}

	}


	_hide() {
	}
	#destroyed = false;
	destroy() {
		if (this.#destroyed) { return; }
		this.#destroyed = true;
		this.#remove()
		this._hide();
		for (const c of this.#headerList) {
			if (!c) { continue; }
			const [component, resize] = c;
			component.root.remove();
			resize.remove();
			component.destroy();
		}
		// TODO:
	}
	paused = false;
	requestRender() {
		if (this.#destroyed) { return; }
		this.#group.requestRender(this);
	}
	_render(
		columns: ColumnCell[],
		force = false,
	) {
		if (this.#destroyed) { return; }

		const list = this.#headerList;


		let i = 0;
		for (const { width, hidden, start, fixed, resizable, end } of columns) {
			const item = list[i];
			i++;
			if (!item) { continue; }
			const [{ root, }, resize, state] = item

			if (fixed !== state.fixed) {
				state.fixed = fixed;
				if (fixed) {
					root.classList.add('nyloong-table-header-fixed');
					resize.classList.add('nyloong-table-resize-fixed');
				} else {
					root.classList.remove('nyloong-table-header-fixed');
					resize.classList.remove('nyloong-table-resize-fixed');
				}
			}
			if (hidden !== state.hidden) {
				state.hidden = hidden;
				root.hidden = hidden;
				resize.hidden = hidden;
			}
			if (hidden) { continue; }
			if (state.start !== start) {
				state.start = start
				root.style.setProperty('--nyloong-table-column-start', `${start}px`);
			}
			if (state.end !== end) {
				state.end = end
				resize.style.setProperty('--nyloong-table-column-start', `${end}px`);
			}
			if (resizable !== state.resizable) {
				state.resizable = resizable;
				if (resizable) {
					resize.classList.add('nyloong-table-resize-resizable');
				} else {
					resize.classList.remove('nyloong-table-resize-resizable');
				}
			}
			if (state.width !== width) {
				state.width = width
				root.style.inlineSize = `${width}px`;
			}

		}


	}
}
