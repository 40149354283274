import mountVueToPage from 'guiguLib/mountVueToPage';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn';

import FinishingWorkProjectManagement from './index.vue';

export default function loadFinishingWorkProjectManagement(wrapper:HTMLElement) {
	const app = mountVueToPage(wrapper, [
		{path: '/:pathMatch(.*)*', component: FinishingWorkProjectManagement},
	], {
		base: '/app/finishing_work_project_management',
	});
	app.use(ElementPlus, {size: 'small', locale: zhCn});
}

frappe.provide('frappe.guigu_pc');
frappe.guigu_pc.pages = frappe.guigu_pc.pages || {};
frappe.guigu_pc.pages.loadFinishingWorkProjectManagement = loadFinishingWorkProjectManagement;
