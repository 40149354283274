import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElButton = _resolveComponent("ElButton")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $setup.visible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.visible) = $event)),
    title: $setup.tt('Pictures'),
    width: $setup.dialogWidth,
    "before-close": $setup.handleClose,
    "custom-class": "progress-dialog"
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_ElButton, { onClick: $setup.handleClose }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_ElButton, {
          type: "primary",
          onClick: $setup.confirm
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Confirm')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode($setup["UploadForm"], {
        ref: "uploadFormRef",
        fileList: $setup.fileList,
        structures: $setup.structureDescendants,
        date: $props.date,
        showDateInput: $setup.showDateInput,
        defaultStructureId: $setup.defaultStructureId,
        onDeleteFile: $setup.deleteFile
      }, null, 8 /* PROPS */, ["fileList", "structures", "date", "showDateInput", "defaultStructureId"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title", "width"]))
}