import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_GuiguFieldInput = _resolveComponent("GuiguFieldInput")

  return (_openBlock(), _createBlock($setup["ElDialog"], {
    modelValue: $setup.visible,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($setup.visible) = $event)),
    title: `${$setup.tt('Edit Group Value')}:${$setup.tt($setup.label || $props.field)}`,
    modal: "",
    width: "min(95%, 400px)"
  }, {
    footer: _withCtx(() => [
      _createVNode($setup["ElButton"], {
        onClick: $setup.del,
        title: $setup.tt('Delete'),
        icon: $setup.Delete
      }, null, 8 /* PROPS */, ["title", "icon"]),
      _createVNode($setup["ElButtonGroup"], null, {
        default: _withCtx(() => [
          _createVNode($setup["ElButton"], {
            onClick: _cache[5] || (_cache[5] = $event => ($setup.visible = false)),
            title: $setup.tt('Cancel'),
            icon: $setup.Close
          }, null, 8 /* PROPS */, ["title", "icon"]),
          _createVNode($setup["ElButton"], {
            type: "primary",
            onClick: $setup.submit,
            title: $setup.tt('Confirm'),
            icon: $setup.Finished
          }, null, 8 /* PROPS */, ["title", "icon"])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _createVNode($setup["ElForm"], {
        labelPosition: "top",
        labelWidth: "100px",
        style: {"max-width":"460px"}
      }, {
        default: _withCtx(() => [
          ($setup.visible && $setup.docField)
            ? (_openBlock(), _createBlock($setup["ElFormItem"], {
                key: 0,
                label: $setup.tt('Content')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_GuiguFieldInput, {
                    modelValue: $setup.data.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.data.value) = $event)),
                    listable: "",
                    filter: "",
                    label: $setup.label,
                    name: $props.field,
                    type: $setup.type,
                    options: $setup.options
                  }, null, 8 /* PROPS */, ["modelValue", "label", "name", "type", "options"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]))
            : _createCommentVNode("v-if", true),
          _createVNode($setup["ElFormItem"], {
            label: $setup.tt('Title')
          }, {
            default: _withCtx(() => [
              _createVNode($setup["ElInput"], {
                modelValue: $setup.data.title,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.data.title) = $event))
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElFormItem"], {
            label: $setup.tt('Text Color')
          }, {
            default: _withCtx(() => [
              _createVNode($setup["ElColorPicker"], {
                modelValue: $setup.data.color,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.data.color) = $event))
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElFormItem"], {
            label: $setup.tt('Background Color')
          }, {
            default: _withCtx(() => [
              _createVNode($setup["ElColorPicker"], {
                modelValue: $setup.data.bgColor,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.data.bgColor) = $event))
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElFormItem"], {
            label: $setup.tt('Move To New Location')
          }, {
            default: _withCtx(() => [
              _createVNode($setup["ElInputNumber"], {
                modelValue: $setup.data.index,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.data.index) = $event))
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}