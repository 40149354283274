import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e356c35f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["Page"], null, {
      title: _withCtx(() => [
        _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.tt('Dw Organizational Unit Work List')), 1 /* TEXT */)
      ]),
      tools: _withCtx(() => [
        _createVNode($setup["Tools"], {
          smallMeta: $setup.smallMeta,
          processes: $setup.processes,
          organization: $setup.organization,
          departmentTree: $setup.departmentTree,
          selectedWorkList: $setup.selectedWorkList,
          levelValue: $setup.levelValue,
          "onUpdate:levelValue": _cache[0] || (_cache[0] = $event => (($setup.levelValue) = $event)),
          layoutType: $setup.layoutType,
          "onUpdate:layoutType": _cache[1] || (_cache[1] = $event => (($setup.layoutType) = $event)),
          department: $setup.department,
          "onUpdate:department": _cache[2] || (_cache[2] = $event => (($setup.department) = $event)),
          visible: $setup.visible,
          "onUpdate:visible": _cache[3] || (_cache[3] = $event => (($setup.visible) = $event)),
          onToggleGroupsState: $setup.toggleGroupsState
        }, null, 8 /* PROPS */, ["smallMeta", "processes", "organization", "departmentTree", "selectedWorkList", "levelValue", "layoutType", "department", "visible"])
      ]),
      default: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock($setup["Skeleton"], {
          layout: $setup.layoutType,
          doctype: $setup.doctypeName,
          detailName: $setup.detailName,
          onOnChange: $setup.onChange,
          "form-visible": $setup.formVisible,
          "onUpdate:formVisible": _cache[5] || (_cache[5] = $event => (($setup.formVisible) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode($setup["Table"], {
              ref: "aggrid",
              class: "table-container",
              smallMeta: $setup.smallMeta,
              dataList: $setup.dataList,
              loading: $setup.loading,
              processes: $setup.processes,
              levelStyle: $setup.levelStyle,
              layoutType: $setup.layoutType,
              "onUpdate:layoutType": _cache[4] || (_cache[4] = $event => (($setup.layoutType) = $event)),
              onShowProcessModalOrNavigate: $setup.showProcessModalOrNavigate,
              onUpdateSelectedWorkList: $setup.updateSelectedWorkList
            }, null, 8 /* PROPS */, ["smallMeta", "dataList", "loading", "processes", "levelStyle", "layoutType"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["layout", "doctype", "detailName", "form-visible"])), [
          [$setup["vLoading"], $setup.loading]
        ])
      ]),
      _: 1 /* STABLE */
    }),
    ($setup.visible)
      ? (_openBlock(), _createBlock($setup["DepartmentDialog"], {
          key: 0,
          departmentTree: $setup.departmentTree,
          visible: $setup.visible,
          "onUpdate:visible": _cache[6] || (_cache[6] = $event => (($setup.visible) = $event)),
          selectedDepartment: $setup.selectedDepartment,
          "onUpdate:selectedDepartment": _cache[7] || (_cache[7] = $event => (($setup.selectedDepartment) = $event)),
          onDistributeDepartment: $setup.distributeDepartment
        }, null, 8 /* PROPS */, ["departmentTree", "visible", "selectedDepartment"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}