import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12416ea7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElDialog"], {
    modelValue: $setup.show,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.show) = $event)),
    title: "移动方式",
    width: "min(300px, 100%)"
  }, {
    footer: _withCtx(() => [
      _createVNode($setup["ElButton"], {
        onClick: _cache[0] || (_cache[0] = $event => ($setup.show = false))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.menu, (it) => {
          return (_openBlock(), _createBlock($setup["ElButton"], {
            onClick: $event => ($setup.click(it))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(it.label), 1 /* TEXT */)
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
        }), 256 /* UNKEYED_FRAGMENT */))
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}