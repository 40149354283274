import type { Row } from '../types/Row';
import { rowVisible } from './updateCollapse';

export default function updateVisible(
	rowData: Row[],
	rowMap: Map<string | number, Row>,
	expanded: Set<string | number>) {

	const visible = [];
	const { length } = rowData;
	for (let i = 0; i < length; i++) {
		if (rowVisible(rowData[i], rowMap, expanded)) {
			visible.push(i);
		}
	}
	return visible;
}
