<template>
	<Page>
		<template #title>
			<h3 class="title">{{ tt("Output value report") }}</h3>
		</template>
		<template #tools>
			<ToolBar
				v-model:project="project"
				v-model:period="period"
				v-model:structure="structure"></ToolBar>
		</template>
		<ProductValueTable
			v-loading="structureLoading||progressPlanLoading"
			:structureDescendants="structureDescendants"
			:project="project"
			:period="period"
			:progressValue="progressValue"></ProductValueTable>
	</Page>
</template>

<script setup lang='ts'>
import {ref, defineProps, defineEmits, watch} from 'vue';
import {getTreeAncestry} from 'guigu_pm/public/js/utils';

import Page from '../../../components/page/index.vue';

import ToolBar from './ToolBar.vue';
import ProductValueTable from './ProductValueTable.vue';
import {BoqValue, Period, StructureItem, StructureItemFormat} from './type';
const tt = __;
const project = ref<string>('');
const period = ref<Period>();
const structure = ref<string>('');
const structureLoading = ref<boolean>(false);
const progressPlanLoading = ref<boolean>(false);
const structureDescendants = ref<StructureItemFormat[]>([]);
const progressValue = ref<BoqValue[]>([]);
watch(project, ()=>{
	structureDescendants.value = [];
});
watch([structure], async ()=>{
	structureDescendants.value = [];
	if (!structure.value || !period.value) {
		return;
	}
	structureLoading.value = true;
	const data = await frappe.call < { message: StructureItem[] } >({
		method: 'guigu_pm.guigu_pm_construction.page.structure_boq_value.structure_boq_value.get_structure_descendants',
		args: {
			structure: structure.value,
			period: period.value,
		},
	});
	const descendants:StructureItemFormat[] = [];
	for (const item of data?.message) {
		descendants.push({
			code: item.code,
			left: item.left,
			structureId: item.name,
			parent: item.parent ? `structure_${item.parent}` : '',
			right: item.right,
			name: item.structure_name,
			id: `structure_${item.name}`,
		});
		if (item.progress_item) {
			for (const each of item.progress_item) {
				descendants.push({
					position: each.position,
					structureProgressId: each.name,
					progressItemId: each.progress_item,
					name: each.progress_item_name,
					parent: `structure_${item.name}`,
					designQuantity: each.design_quantity,
					id: `structure_progress_${each.name}`,
					unit: each.unit,
				});
			}
		}
	}
	const ancestryList = getTreeAncestry<StructureItemFormat>(descendants);
	structureDescendants.value = ancestryList;
	structureLoading.value = false;
});
watch([structure], async()=>{
	if (!structure.value || !period.value) {
		return;
	}
	structureLoading.value = true;
	const data = await frappe.call < { message: BoqValue[] } >({
		method: 'guigu_pm.guigu_pm_construction.page.structure_boq_value.structure_boq_value.get_structure_value',
		args: {
			structure: structure.value,
			period: period.value.name,
			main_type: 'Week',
			sub_type: 'Week',
		},
	});
	progressValue.value = data?.message || [];
});
</script>

<style lang='less' scoped>
.title{
	margin-bottom: 0;
}
</style>
