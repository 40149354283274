<template>
	<div v-loading="loading" class="grid-field">
		<label class="control-label">{{ translate(df.label || "") }}</label>
		<span class="help"></span>
		<p class="text-muted small grid-description" v-if="df.description">
			{{
				translate(df.description) }}
		</p>
		<div class="grid-custom-buttons"></div>
		<div class="form-grid-container">
			<Table
				v-if="linkMeta && loading === false"
				:linkMeta="linkMeta"
				:data="tableData"
				:treeGroupField="treeGroupField"
				@remove="onRemove"
				@unConnect="onUnConnect"
				:linkOptions="linkOptions"
				:customActionButton="customActionButton"
				:linkMetaPermissions="linkMetaPermissions"
				:middleMetaPermissions="middleMetaPermissions"
				:connectionFieldSetting="fieldSetting"
				:dragRequest="dragRequest"
				@selectionChanged="selectionChanged"
				:customComponents="customComponents"
				:readonly="readonly"
				@showDetail="v=>detailName=v"
				@createChild="addRow"
				@updateCount="updateCount"
				:isDragEnable="isDragEnable"
				:isNew="isNew"></Table>
		</div>
		<div v-if="!isNew && !readonly" class="small form-clickable-section grid-footer">
			<div v-if="beforeButtons.length > 0" class="button-container before-container">
				<ElButton class="before" v-for="item in beforeButtons"
					v-bind="item.attr"
					:disabled="readonly"
					@click="customClick(item)">
					{{ item.attr.title }}
				</ElButton>
			</div>
			<ElPopconfirm
				v-if="linkMetaPermissions.deletePermission && middleMetaPermissions.deletePermission"
				title="您确定直接删除选中的数据吗？"
				@confirm="deleteSelected"
				confirmButtonText="确定"
				cancelButtonText="取消">
				<template #reference>
					<ElButton id="connection_table_remove" type="danger" v-show="showDeleteBtn&&selected.length > 0"
						:disabled="readonly || selected.length <= 0">
						批量删除
					</ElButton>
				</template>
			</ElPopconfirm>
			<ElButton v-show="showAddBtn" id="connection_table_add"
				v-if="linkMetaPermissions.createPermission && middleMetaPermissions.createPermission"
				:disabled="readonly"
				@click="addRow()">
				添加
			</ElButton>
			<div v-if="betweenButtons.length > 0" class="button-container">
				<ElButton v-for="item in betweenButtons" v-bind="item.attr"
					:disabled="readonly"
					@click="item.fun">
					{{ item.attr.title }}
				</ElButton>
			</div>
			<ElButton v-show="showLinkBtn" id="connection_table_link"
				:disabled="readonly"
				v-if="linkMetaPermissions.writePermission && middleMetaPermissions.createPermission && middleMetaPermissions.deletePermission"
				@click="linkRow">
				关联
			</ElButton>
			<div v-if="afterButtons.length > 0" class="button-container">
				<ElButton v-for="item in afterButtons" v-bind="item.attr"
					:disabled="readonly"
					@click="item.fun">
					{{ item.attr.title }}
				</ElButton>
			</div>
			<div class="pagination_count" v-if="!readonly">
				<div>总数：{{ total }}</div>
				<div>已选择：{{ selectedCount }}</div>
				<div>已筛选：{{ filterCount }}</div>
			</div>
		</div>
		<ConnectionDialog
			v-if="fieldSetting?.link_to_main_field && linkMeta"
			v-model:visible="visible"
			:linkMeta="linkMeta"
			:main_name="frm.doc.name"
			:treeGroupField="treeGroupField"
			:originSelectedList="tableData"
			:selectDialogQueries="selectDialogQueries"
			:connectionFieldSetting="fieldSetting"
			@afterSave="afterSave"></ConnectionDialog>
		<Detail v-if="detailName&&linkMeta" @hide="detailName=''" :doctype="linkMeta.name" :name="detailName"></Detail>
	</div>
</template>

<script setup lang='ts'>
import {ref, watch, computed, onUnmounted, shallowRef} from 'vue';

import {ElButton, ElMessage} from 'element-plus';
import {Component} from 'ag-grid-community';

import {
	ActionButtonVisible,
	CustomButton, DF, CustomActionButton, Data, LinkOptions,
	type MappingSetting,
} from './type';
import Table from './Table.vue';
import ConnectionDialog from './ConnectionDialog.vue';
import {getFieldSetting} from './useFieldSetting';
import Detail from './Detail.vue';

const translate = window.__;
interface Props {
	loadId:number,
	df: DF,
	readonly: boolean;
	buttonHidden: {
		link: boolean | '';
		add: boolean | '';
		delete: boolean | '';
	};
	customButton: CustomButton[];
	actionButtonHidden: ActionButtonVisible;
	customActionButton: CustomActionButton[];
	queries: { filters: any } | ''
	isHidePagination: boolean | ''
	notSelect: boolean | ''
	deleteTip:string
	hideAddChildButton:boolean
	isDetailUseDialog: boolean | ''
	customComponents: Record<string, Function | Component>


	doctype: string
	frm: any
	selectDialogQueries?: { filters: any }
	dragRequest?: (
		movingData: Data,
		overData: Data,
	) => Promise<boolean> | boolean;
	afterTableDataChange?: (data: any[]) => void
	afterDelete?: (data: string[]) => void

	isNew?: boolean|undefined
	isDragEnable:boolean
	columns:locals.DocField[]
	createFieldMap:MappingSetting[]
}

const props = defineProps<Props>();
interface Emit {
	(e: 'link'): void
}
const emit = defineEmits<Emit>();

// TODO:
const tableData = ref<Record<string, any>[]>([]);
// TODO:
const linkOptions = ref<LinkOptions[]>([]);
// TODO:
const treeGroupField = ref<string>('');
const visible = ref<boolean>(false);
const loading = shallowRef<boolean>(false);
const selected = shallowRef<any[]>([]);
const detailName = ref<string>('');
const total = ref<number>(0);
const selectedCount = ref<number>(0);
const filterCount = ref<number>(0);

const {fieldSetting, linkMeta, middleMeta} = getFieldSetting(
	props.doctype,
	props.df,
	computed(() => props.buttonHidden),
	computed(() => props.actionButtonHidden),
	computed(() => props.isHidePagination),
	computed(() => props.queries),
	computed(() => props.notSelect),
	computed(() => props.isDetailUseDialog),
	computed(() => props.deleteTip),
	computed(() => props.hideAddChildButton),
	computed(()=>props.columns),
	computed(()=>props.createFieldMap),
);

let treeGroupFieldAjax: any;
async function getTreeGroupField() {
	if (!fieldSetting.value?.connection_table || !props.frm.doc.name || props.frm.doc.name.startsWith('new')) {
		return;
	}
	treeGroupFieldAjax?.abort();
	treeGroupFieldAjax = frappe.call({
		method: 'guigu.guigu_controls.connection_table.get_connection_table_tree_group_field',
		args: {
			link_doctype: fieldSetting.value?.connection_table,
		},
		callback(data: { message: string }) {
			treeGroupField.value = data?.message || '';
		},
	});
}
let loadAjax: any;
async function loadData() {
	loading.value = true;
	if (!fieldSetting.value?.connection_table || !fieldSetting.value?.document_type || !props.frm.doc.name || props.frm.doc.name.startsWith('new')) {
		loading.value = false;
		return;
	}
	loadAjax?.abort();
	loadAjax = frappe.call({
		method: 'guigu.guigu_controls.connection_table.get_connection_table_data',
		args: {
			parent_doctype: fieldSetting.value?.document_type,
			link_doctype: fieldSetting.value?.connection_table,
			parent_name: props.frm.doc.name,
			fieldname: fieldSetting.value.field,
			filters: fieldSetting.value.table_filter,
		},
		callback(data: { message: { data: Record<string, any>[], link_options: LinkOptions[] } }) {
			setTimeout(()=>{
				tableData.value = data?.message.data || [];
				linkOptions.value = data?.message.link_options || [];
				loading.value = false;
			});
		},
	});
}
watch(() => props.isNew, () => {
	if (props.isNew) {
		setTimeout(() => {
			tableData.value = [];
		}, 0);
	}
}, {immediate: true});
watch([fieldSetting, () => props.frm.doc.name, ()=>props.loadId], () => {
	getTreeGroupField();
	loadData();
}, {immediate: true});
watch(()=>[...tableData.value], () => {
	props.afterTableDataChange?.(tableData.value);
}, {immediate: true});

onUnmounted(() => {
	treeGroupFieldAjax?.abort();
	loadAjax?.abort();
});
const middleMetaPermissions = computed(() => {
	if (props.df.read_only === '1') {
		return {deletePermission: false, createPermission: false, writePermission: false};
	}
	if (fieldSetting.value.type !== 'Multiple To Multiple') {
		return {deletePermission: true, createPermission: true, writePermission: true};
	}
	if (!middleMeta.value) {
		return {deletePermission: false, createPermission: false};
	}
	const deletePermission = frappe.perm.has_perm(middleMeta.value.name, 0, 'delete');
	const createPermission = frappe.perm.has_perm(middleMeta.value.name, 0, 'create');
	const writePermission = frappe.perm.has_perm(middleMeta.value.name, 0, 'write');
	return {deletePermission, createPermission, writePermission};
});

const linkMetaPermissions = computed(() => {
	if (props.df.read_only === '1') {
		return {deletePermission: false, createPermission: false};
	}
	if (!linkMeta.value) {
		return {deletePermission: false, createPermission: false};
	}
	const deletePermission = frappe.perm.has_perm(linkMeta.value.name, 0, 'delete');
	const createPermission = frappe.perm.has_perm(linkMeta.value.name, 0, 'create');
	const writePermission = frappe.perm.has_perm(linkMeta.value.name, 0, 'write');
	return {deletePermission, createPermission, writePermission};
});

let registered = false;
function addRow(parentData?:any) {
	if (fieldSetting.value.type === 'Multiple To Multiple' && !registered) {
		const mainName = props.frm.doc.name as string;
		async function after_save(frm: any) {
			if (!fieldSetting.value.middle_table || !fieldSetting.value.link_to_connection_field || !fieldSetting.value.link_to_main_field) {
				return;
			}
			loading.value = true;
			if (!mainName || !frm.doc.name) {
				return;
			}
			if (mainName.startsWith('new')) {
				return;
			}
			await frappe.call({
				method: 'guigu.guigu_controls.connection_table.create_middle',
				args: {
					middle_table: fieldSetting.value.middle_table,
					link_to_main_field: fieldSetting.value.link_to_main_field,
					link_to_connection_field: fieldSetting.value.link_to_connection_field,
					main_doc_name: mainName,
					link_doc_name: frm.doc.name,
				},
			});
			loading.value = false;
		}
		frappe.ui.form.on(fieldSetting.value?.connection_table, 'after_save', after_save);
		registered = true;
	}
	if (fieldSetting.value?.create_field_mapping_setting && fieldSetting.value?.create_field_mapping_setting.length > 0) {
		const newName = frappe.model.make_new_doc_and_get_name(fieldSetting.value?.connection_table);
		const data: Record<string, any> = locals[fieldSetting.value?.connection_table][newName] as Record<string, any>;
		for (const item of fieldSetting.value?.create_field_mapping_setting) {
			data[item.link_field] = props.frm.doc[item.main_field];
		}
		data[fieldSetting.value.link_to_main_field] = props.frm.doc.name;
		if (parentData && linkMeta.value?.nsm_parent_field) {
			data[linkMeta.value?.nsm_parent_field] = parentData.name;
		}
		frappe.set_route(['Form', fieldSetting.value?.connection_table, data.name]);
	} else {
		const doctype = fieldSetting.value?.connection_table;
		frappe.model.with_doctype(doctype, function () {
			const new_doc:any = frappe.model.get_new_doc(doctype, null, null, true);

			set_link_field(doctype, new_doc);
			if (parentData && linkMeta.value?.nsm_parent_field) {
				new_doc[linkMeta.value?.nsm_parent_field] = parentData.name;
			}
			frappe.ui.form.make_quick_entry(doctype, null, null, new_doc);
		});
	}
}
function set_link_field(doctype:string, new_doc:any) {
	for (const df of frappe.get_meta(doctype).fields) {
		if (df.fieldtype === 'Link' && df.options === fieldSetting.value?.document_type) {
			new_doc[df.fieldname] = props.frm.doc.name;
		} else if (['Link', 'Dynamic Link'].includes(df.fieldtype) && props.frm.doc[df.fieldname]) {
			new_doc[df.fieldname] = props.frm.doc[df.fieldname];
		} else if (df.fieldtype === 'Table' && df.options && df.reqd) {
			const row = new_doc[df.fieldname][0];
			set_link_field(df.options, row);
		}
	}
}
function linkRow() {
	emit('link');
	visible.value = true;
}
function afterSave() {
	loadData();
	visible.value = false;
}

function selectionChanged(data: any[]) {
	selected.value = data;
}
async function deleteRows(names: string[]) {
	if (!fieldSetting.value.connection_table) {
		return;
	}
	loading.value = true;
	const res = await frappe.call<{ message: Record<string, any>[] }>({
		method: 'guigu.guigu_controls.connection_table.remove_Rows',
		args: {
			parent_doctype: fieldSetting.value.document_type,
			fieldname: fieldSetting.value.field,
			link_doctype: fieldSetting.value.connection_table,
			main_doc_name: props.frm.doc.name,
			link_names: names,
		},
		error: () => {
			ElMessage.error('删除失败');
			loading.value = false;
		},
	});
	const successNames: string[] = res.message;
	if (successNames.length === 0) {
		ElMessage.error('删除失败');
		loading.value = false;
		return;
	}
	ElMessage({
		message: `删除成功${successNames.length}条`,
		type: 'success',
	});
	tableData.value = tableData.value.filter(item => !successNames.includes(item.name));
	selected.value = selected.value.filter(item => !successNames.includes(item.name));
	loading.value = false;
	props.afterDelete?.(successNames);
}
function deleteSelected() {
	deleteRows(selected.value.map(item => item.name));
}
function onRemove(data: any) {
	deleteRows([data.name]);
}
async function onUnConnect(data: any) {
	if (!fieldSetting.value.connection_table) {
		return;
	}
	loading.value = true;
	await frappe.call<{ message: Record<string, any>[] }>({
		method: 'guigu.guigu_controls.connection_table.unConnect_row',
		args: {
			parent_doctype: fieldSetting.value.document_type,
			link_doctype: fieldSetting.value.connection_table,
			fieldname: fieldSetting.value.field,
			link_doc_name: data.name,
			main_doc_name: props.frm.doc.name,
		},
		error: () => {
			ElMessage.error('解除关联失败');
			loading.value = false;
		},
	});
	ElMessage({
		message: '解除关联成功',
		type: 'success',
	});
	const index = tableData.value.findIndex(item => item.name === data.name);
	// tableData.value =
	tableData.value.splice(index, 1);
	loading.value = false;
}


const beforeButtons = computed(() => props.customButton.filter(item => item.position === 'before').sort((pre, next) => pre.index - next.index));
const betweenButtons = computed(() => props.customButton.filter(item => item.position === 'between').sort((pre, next) => pre.index - next.index));
const afterButtons = computed(() => props.customButton.filter(item => item.position === 'after').sort((pre, next) => pre.index - next.index));


const showAddBtn = computed(() => !fieldSetting.value.hide_add_button);
const showLinkBtn = computed(() => !fieldSetting.value.hide_link_button);
const showDeleteBtn = computed(() => !fieldSetting.value.hide_bulkdelete_button);

function customClick(btn: CustomButton) {
	if (btn.fun && typeof btn.fun === 'function') {
		btn.fun();
	}
}

function updateCount(data:{total:number, selectedCount:number, filterCount:number}) {
	total.value = data.total;
	selectedCount.value = data.selectedCount;
	filterCount.value = data.filterCount;
}
frappe.realtime.on('doc_update', p => {
	if (p.doctype !== linkMeta.value?.name) {
		return;
	}
	loadData();
});
const removeBtnLeft = computed(()=>beforeButtons.value.length > 0 ? '12px' : '0');
const addBtnLeft = computed(()=>{
	const flag = linkMetaPermissions.value.deletePermission && middleMetaPermissions.value.deletePermission
		&& showDeleteBtn.value && selected.value.length > 0 || (beforeButtons.value.length > 0);
	return flag ? '12px' : '0';
});

</script>

<style lang='less' scoped>
.grid-footer{
	padding-top: 0;
}
#connection_table_remove {
	margin-left: v-bind(removeBtnLeft);
}
#connection_table_add {
	margin-left: v-bind(addBtnLeft);
}
#connection_table_link {
	margin-left: 12px;
}

.button-container {
	display: inline-block;
	margin-left: 12px;
}
.before-container{
	margin-left: 0;
}
.pagination_count{
	display: flex;
	float: right;
	&>div{
		margin-right: 8px;
	}
}
</style>
