/* eslint-disable eqeqeq */
import {
	CellClassParams,
	ColDef,
	ColGroupDef,
	ICellRendererParams,
  } from 'ag-grid-community';

import { StructureItemFormat } from './type/index';


  export function getColumns(
	productValues: StructureItemFormat[]
  ):  (ColDef | ColGroupDef)[] {
	return [
		{
			headerName: __("Weekly output value"),
			field: 'productValue.period_product_value',
			width: 110,
			minWidth: 110,
			enableRowGroup: false,
			editable: false,
			cellRenderer: 'FloatComponent',
			filter: false,
			cellRendererParams: (params: ICellRendererParams) => {
			  const { value } = params;
			  return {
				value:
				  value == 0
					? null
					: value,
			  };
			},
			aggFunc: params => {
				let total = 0;
				params.values.forEach(value => total += value);
				return total;
			},
		  },
		{
		  headerName: __("Weekly planned output value"),
		  field: 'productValue.period_plan_value',
		  width: 110,
		  minWidth: 110,
		  enableRowGroup: false,
		  editable: false,
		  cellRenderer: 'FloatComponent',
		  filter: false,
		  cellRendererParams: (params: ICellRendererParams) => {
			const { value } = params;
			return {
			  value:
			  value == 0
				  ? null
				  : value,
			};
		  },
		  aggFunc: params => {
			let total = 0;
			params.values.forEach(value => total += value);
			return total;
		  },
		},
		{
		  headerName: __("Week Value Percent"),
		  field: 'productValue.complete_rate',
		  width: 130,
		  minWidth: 130,
		  filter: false,
		  enableRowGroup: false,
		  editable: false,
		  cellRenderer: 'SparklineCellRenderer',
		  cellRendererParams: (params: ICellRendererParams) => {
			const { value } = params;
			return {
			  value:value===0?null:value,
			};
		  },
		  aggFunc: params => {
			const {data} = params;
			if (!data?.id){ return 0; }
			const children = productValues.filter(item=>item.ancestry?.includes(data.id));
			const value = children.reduce((pre, next)=>pre+(next?.productValue?.period_product_value||0), 0);
			const plan = children.reduce((pre, next)=>pre+(next?.productValue?.period_plan_value||0), 0);
			if (plan==0){ return 0; }
			return (value/plan*100).toFixed(2);
		  },
		},
	  ];
  }
