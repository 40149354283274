<template>
	<div class="toolbar">
		<div class="left-container">
			<Project
				v-if="type==='project'"
				class="input"
				noLabel
				v-model="projectValue"
			></Project>
			<ElSelect v-else class="input" filterable :modelValue="departmentName" @change="changeType">
				<ElOption
					v-for="item in departments"
					:key="item.name"
					:label="item.label"
					:value="item.name" />
			</ElSelect>
			<ElDatePicker
				class="date-input"
				v-model="date"
				valueFormat="YYYY-MM-DD"
				type="year"
				:clearable="false"
			/>
		</div>
	</div>
</template>

<script setup lang='ts'>
import {computed} from 'vue';

import {useMetaQuery} from '../../../../../../../../guigu_pm/guigu_pm/public/js/components/page/useMetaQuery';
import Project from '../../../../../../../../guigu/guigu/public/js/components/PageComponents/Tools/Project.vue';
interface Props{
	type:'project'|'subCompany'
	departmentName?: string,
	year?: string,
	departments:{name:string, label:string}[]
}
const props = defineProps<Props>();
interface Emit{
	(e: 'update:departmentName', project: string): void,
	(e: 'update:year', date?: string): void,
}
const emit = defineEmits<Emit>();
const smallMeta = useMetaQuery();

const date = computed({
	get: ()=>props.year || '',
	set: (v:string)=>{
		emit('update:year', v);
	},
});

const projectValue = computed({
	get: () => props.departmentName || '',
	set: (v: string) => {
		emit('update:departmentName', v);
	},
});

function changeType(newTypeName:string) {
	emit('update:departmentName', newTypeName);
}
const flexDirection = computed(()=>smallMeta.value ? 'column' : 'row');
const margin = computed(()=>smallMeta.value ? '8px' : '0');

</script>

<style lang='less' scoped>
@media (max-width: 640px) {
	.toolbar {
		display: flex;
		justify-content: column;
	}
	.input{
		width: 100%;
		margin-bottom: 8px
	}
	:deep(.el-date-editor.el-input) {
			width: 100% !important;
			margin-top:0px !important
	};
}

@media (min-width: 640px) {
	.input{
		margin-right: 8px !important;
	}
}


</style>
