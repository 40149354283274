import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38bc13ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Page"], null, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.tt('Dw Standard Work List')), 1 /* TEXT */)
    ]),
    tools: _withCtx(() => [
      _createVNode($setup["Tools"], {
        smallMeta: $setup.smallMeta,
        processList: $setup.processList,
        hasPermission: $setup.hasPermission,
        disable: $setup.disable,
        "onUpdate:disable": _cache[0] || (_cache[0] = $event => (($setup.disable) = $event)),
        process: $setup.process,
        "onUpdate:process": _cache[1] || (_cache[1] = $event => (($setup.process) = $event)),
        layoutType: $setup.layoutType,
        "onUpdate:layoutType": _cache[2] || (_cache[2] = $event => (($setup.layoutType) = $event)),
        scope: $setup.scope,
        "onUpdate:scope": _cache[3] || (_cache[3] = $event => (($setup.scope) = $event)),
        onToggleExpand: $setup.toggleExpand,
        onCreateWorkList: $setup.createWorkList
      }, null, 8 /* PROPS */, ["smallMeta", "processList", "hasPermission", "disable", "process", "layoutType", "scope"])
    ]),
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createBlock($setup["Skeleton"], {
        layout: $setup.layoutType,
        doctype: "Dw Standard Worklist",
        detailName: $setup.detailName,
        onOnChange: $setup.updateData,
        "form-visible": $setup.formVisible,
        "onUpdate:formVisible": _cache[5] || (_cache[5] = $event => (($setup.formVisible) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode($setup["Table"], {
            ref: "aggrid",
            class: "table-container",
            smallMeta: $setup.smallMeta,
            dataList: $setup.dataList,
            loading: $setup.loading,
            hasPermission: $setup.hasPermission,
            organizationMap: $setup.organizationMap,
            processList: $setup.processList,
            levelStyle: $setup.levelStyle,
            layoutType: $setup.layoutType,
            "onUpdate:layoutType": _cache[4] || (_cache[4] = $event => (($setup.layoutType) = $event)),
            onUpdateData: $setup.updateData,
            onCreateWorkList: $setup.createWorkList,
            onShowDetail: $setup.showDetail
          }, null, 8 /* PROPS */, ["smallMeta", "dataList", "loading", "hasPermission", "organizationMap", "processList", "levelStyle", "layoutType"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["layout", "detailName", "form-visible"])), [
        [$setup["vLoading"], $setup.loading]
      ])
    ]),
    _: 1 /* STABLE */
  }))
}