export default function submitOrCancel(
	doctype: string,
	docnames: string[],
	submit: boolean,
	done: () => void,
): void {
	const action = submit ? 'submit' : 'cancel';
	frappe.call({
		method: 'frappe.desk.doctype.bulk_update.bulk_update.submit_cancel_or_update_docs',
		args: { doctype, action, docnames },
	}).then(r => {
		let failed = r.message;
		if (!failed) { failed = []; }

		if (failed.length && !r._server_messages) {
			frappe.throw(
				__('Cannot {0} {1}', [action, failed.map(f => f.bold()).join(', ')])
			);
		}
		if (failed.length < docnames.length) {
			frappe.utils.play_sound(action);
			done();
		}
	});
}
