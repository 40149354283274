<template>
	<div class="page" v-loading="loading">
		<ElTable :data="pageList" border style="width: 100%" :height="tableHeight">
			<template #empty>
				<Empty ref="empty"></Empty>
			</template>
			<ElTableColumn fixed prop="page_name" label="名称" width="250" >
			</ElTableColumn>
			<ElTableColumn fixed prop="title" label="标题" >
				<template #default="scope">
					{{ `${tt(scope.row.title)}` }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="module" label="模块" width="180" >
				<template #default="scope">
					{{ `${tt(scope.row.module)}(${scope.row.module})` }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="system_page" label="系统页面" width="80" >
				<template #default="scope">
					{{ scope.row.system_page?'是':'否' }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="standard" label="标准" width="60" >
				<template #default="scope">
					{{ scope.row.standard==='Yes'?'是':'否' }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="address" label="操作" width="150" >
				<template #default="scope">
					<ElButton type="primary" @click="gotoPage(scope.row)">转到页面</ElButton>
					<ElButton type="primary" @click="editPage(scope.row)">详情</ElButton>
				</template>
			</ElTableColumn>
		</ElTable>
	</div>
</template>

<script setup lang='ts'>
import {ref, watch, onMounted, onUnmounted} from 'vue';
import {ElTable, ElTableColumn, ElButton, vLoading} from 'element-plus';

import type {AppInfo, Page, ModuleInfo} from './type';
import Empty from './Empty.vue';
const tt = __;
interface Props{
	current?:AppInfo|ModuleInfo
}
const props = defineProps<Props>();
const pageList = ref<Page[]>([]);
const loading = ref<boolean>(true);
const empty = ref<HTMLElement>();
const tableHeight = ref<string>('100%');
watch(() => props.current, async () => {
	getPageList();
}, {
	deep: true,
	immediate: true,
});
function editPage(data:Page) {
	frappe.set_route(['form', 'Page', data.name]);
}
function gotoPage(data:Page) {
	frappe.set_route(data.name);
}
async function getPageList() {
	tableHeight.value = '100%';
	loading.value = true;
	if (!props.current) {
		return;
	}
	const res = await frappe.call<{message:Page}>({
		method: 'guigu.guigu.page.guigu_app_list.guigu_app_list.get_page_list',
		args: {
			type: props.current.type,
			name: props.current.name,
		},
	});
	pageList.value = res.message || [];
	loading.value = false;
}
watch(empty, () => {
	if (empty.value && pageList.value.length === 0) {
		tableHeight.value = '182px';
	}
});
const popstateListener = function () {
	getPageList();
};
onMounted(() => {
	window.addEventListener('popstate', popstateListener);
});
onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});
</script>

<style lang='less' scoped>
.page{
	height: 100%;
	display: flex;
    flex-direction: column;
	.btn-container{
		text-align: right;
		margin-bottom: 8px;
	}
	.role{
		cursor: pointer;
		margin-right: 4px;
		&:hover{
			text-decoration: underline;
		}
	}
}
</style>
