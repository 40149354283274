import { ref } from 'vue';

import { Filter } from '../../GlobalView/types';

export default function toConfig(def: any) {
	return ref<{
		doctype: string;
		label: string;
		view?: string;
		showCount: boolean;
		countWithViewMust: boolean;
		countWithViewDefault: boolean;
		filters: [string, string, string, any][];
		orFilters: [string, string, string, any][][];
	}>({
		doctype: String(def?.doctype || ''),
		label: String(def?.label || ''),
		view: String(def?.view || '') || undefined,
		showCount: Boolean(def?.showCount),
		countWithViewMust: Boolean(def?.countWithViewMust),
		countWithViewDefault: Boolean(def?.countWithViewDefault),
		filters: (def?.filters || []).map((f: Filter | any[]) => {
			if (Array.isArray(f)) { return f; }
			const { doctype, field, condition, value } = f;
			return [doctype, field, condition, value];
		}),
		orFilters: def?.orFilters || [],
	});


}
