import type { RowValue } from '../core';

import type { DateGetter, Dot } from './types';


export interface DotInfo extends Dot {
	date: DateGetter;
}
export function getDotDate(
	data: any,
	dateDate: Record<string, Date | undefined>,
	endDateDate: Record<string, Date | undefined>,
	dots: DotInfo[],
) {
	return dots.map(({ date }) => date(data, dateDate, endDateDate) || null);
}
export default function getDotData(
	allData: readonly RowValue[],
	allDateData: Map<string | number, Record<string, Date | undefined>>,
	allEndDateData: Map<string | number, Record<string, Date | undefined>>,
	dots: DotInfo[],
	todayStart: Date,
	todayEnd: Date,
): [Date, Date, Map<number | string, (Date | null)[]>] {
	let dotStartDate = todayStart;
	let dotEndDate = todayEnd;
	const allDotData = new Map<number | string, (Date | null)[]>;
	for (const { id, data } of allData) {
		const dates: (Date | null)[] = getDotDate(data, allDateData.get(id) || {}, allEndDateData.get(id) || {}, dots);
		for (const date of dates) {
			if (!date) { continue; }
			if (dotStartDate > date) { dotStartDate = date; }
			if (dotEndDate < date) { dotEndDate = date; }
		}
		allDotData.set(id, dates);
	}
	return [dotStartDate, dotEndDate, allDotData];
}
