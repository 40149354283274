<template>
	<ElForm class="member-form" label-width="auto">
		<ElFormItem :label="tt('Designation')">
			<el-checkbox v-if="!isCreate" v-model="isDesignationKeep" label="keep" value="keep">{{ tt('Invariant')
				}}</el-checkbox>
			<el-select :disabled="isDesignationKeep" multiple v-model="thisDesignations">
				<el-option v-for="item in allDesignations" :key="item.name" :label="item.designation_name" :value="item.name" />
			</el-select>
		</ElFormItem>
		<ElFormItem :label="tt('Permission')">
			<el-checkbox v-if="!isCreate" v-model="isKeepPermission" label="keep" value="keep">{{ tt('Invariant')
				}}</el-checkbox>
			<div></div>
			<el-checkbox-group :disabled="isKeepPermission" v-model="thisPermission">
				<el-checkbox label="visible" value="visible">{{
					tt('Seen')
				}}</el-checkbox>
				<el-checkbox label="viewable" value="viewable">{{ tt('Viewable') }}</el-checkbox>
				<el-checkbox v-show="showPermissions" label="addible" value="addible">{{ tt('Addible') }}</el-checkbox>
				<el-checkbox v-show="showPermissions" label="editable" value="editable">{{ tt('Editable') }}</el-checkbox>
				<el-checkbox v-show="showPermissions" label="deletable" value="deletable">{{ tt('Deletable') }}</el-checkbox>
				<el-checkbox label="manageable" value="manageable">{{ tt('Manageable') }}</el-checkbox>
			</el-checkbox-group>
		</ElFormItem>
		<ElFormItem :label="tt('Default')">
			<el-checkbox v-if="!isCreate" v-model="isKeepDefault" label="keep" value="keep">{{ tt('Invariant')
				}}</el-checkbox>
			<el-checkbox :disabled="isKeepDefault" v-model="thisIsDefault" label="default" value="default">{{
				tt('Default') }}</el-checkbox>
		</ElFormItem>
		<ElFormItem :label="tt('Role')">
			<el-checkbox v-model="isKeepRole" label="keep" value="keep">{{ tt('Invariant') }}</el-checkbox>
			<el-checkbox :disabled="isKeepRole" v-model="checkAll" :indeterminate="isIndeterminate"
				@change="handleCheckAllChange">{{ tt('Check all') }}</el-checkbox>
			<el-checkbox-group :disabled="isKeepRole" v-model="thisRoles" @change="handleCheckedChange">
				<el-checkbox v-for="item in allRoles" :label="item" :value="item">{{ tt(item) }}</el-checkbox>
			</el-checkbox-group>
		</ElFormItem>
	</ElForm>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue';
import type { Member, Permissions, User } from '../type';

import {
	ElCheckboxGroup,
	ElCheckbox,
	ElSelect,
	ElForm,
	ElFormItem,
	ElOption,
} from 'element-plus';
const tt = __;

interface Props {
	designations: string[] | 'keep';
	permissions: string[] | 'keep';
	isDefault: boolean | 'keep';
	roles: string[] | 'keep';
	isCreate?: boolean;
}
const props = defineProps<Props>();

interface Emit {
	(e: 'update:designations', v: string[] | 'keep'): void;
	(e: 'update:permissions', v: string[] | 'keep'): void;
	(e: 'update:isDefault', v: string[] | 'keep'): void;
	(e: 'update:roles', v: string[] | 'keep'): void;
}
const emit = defineEmits<Emit>();
const allRoles = ref<string[]>([]);
const checkAll = ref<boolean>(false);
const isIndeterminate = ref<boolean>(true);
const allDesignations = ref([]);
const isDesignationKeep = ref<boolean>(false);
const isKeepPermission = ref<boolean>(false);
const isKeepDefault = ref<boolean>(false);
const isKeepRole = ref<boolean>(false);

watch(isKeepRole, () => {
	if (isKeepRole.value) {
		thisRoles.value = 'keep';
	}
});

watch(isKeepDefault, () => {
	if (isKeepDefault.value) {
		thisIsDefault.value = 'keep';
	}
});

watch(isKeepPermission, () => {
	if (isKeepPermission.value) {
		thisPermission.value = 'keep';
	}
});

watch(isDesignationKeep, () => {
	if (isDesignationKeep.value) {
		thisDesignations.value = 'keep';
	}
});
const thisDesignations = computed({
	set(v) {
		emit('update:designations', v);
	},
	get() {
		return props.designations === 'keep' ? [] : props.designations;
	},
});
const thisPermission = computed({
	set(v) {
		emit('update:permissions', v);
	},
	get() {
		return props.permissions === 'keep' ? [] : props.permissions;
	},
});
const thisIsDefault = computed({
	set(v) {
		emit('update:isDefault', v);
	},
	get() {
		return props.isDefault === 'keep' ? false : props.isDefault;
	},
});
const thisRoles = computed({
	set(v) {
		emit('update:roles', v);
	},
	get() {
		return props.roles === 'keep' ? [] : props.roles;
	},
});
watch(
	() => [...thisPermission.value],
	() => {
		const p = ['addible', 'editable', 'deletable'];
		if (thisPermission.value.includes('viewable')) {
			return;
		}
		if (thisPermission.value.every(c => !p.includes(c))) {
			return;
		}
		thisPermission.value = thisPermission.value.filter(c => !p.includes(c));
	},
	{ deep: true },
);

async function getDesignations() {
	const res = await frappe.call<{ message: Member[]; }>({
		method: 'tianjy_organization.tianjy_organization.page.tianjy_organization_config.tianjy_organization_config.get_designations',
	});
	allDesignations.value = res?.message || [];
}
const showPermissions = computed(() => {
	return Array.from(thisPermission.value).includes('viewable');
});

const handleCheckAllChange = (val: boolean) => {
	thisRoles.value = val ? allRoles.value : [];
	isIndeterminate.value = false;
};
function handleCheckedChange(value: string[]) {
	const checkedCount = value.length;
	checkAll.value = checkedCount === allRoles.value.length;
	isIndeterminate.value =
		checkedCount > 0 && checkedCount < allRoles.value.length;
}
onMounted(() => {
	getRoles();
	getDesignations();
});
async function getRoles() {
	const res = await frappe.xcall(
		'frappe.core.doctype.user.user.get_all_roles',
	);
	allRoles.value = res;
}
</script>

<style lang="less" scoped>
.member-form :deep(label) {
	margin-bottom: 0;
}
</style>
