import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fc53cff3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tools" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.relatePermission)
      ? (_openBlock(), _createBlock($setup["ElButton"], {
          key: 0,
          type: "primary",
          size: "small",
          onClick: $setup.createRelation
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Enable Process')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ]))
}