<template>
	<div class="photo-info-container">
		<div class='left'>
			<div class='photo-image-container'>
				<img :src="photo.url" />
			</div>
			<ElButton
				class='photo-delete'

				type="danger"
				@click='deleteFile'
			>
			{{ tt('Delete') }}
			</ElButton>
      </div>
	  <div class='right'>
        <ElForm
		  ref="formRef"
		  label-width='auto'

		  :rules="rules"
		  :model="form"
        >
          <ElFormItem :label="tt('File Name')" props="name">
            <ElInput v-model='form.name'/>
          </ElFormItem>
		  <ElFormItem v-if="showDateInput" :label="tt('Date')" props="date" rules="{ required: true, trigger: 'blur' }">
			<ElDatePicker v-model="form.date" type="date" @change="changeDate" />
		  </ElFormItem>
          <ElFormItem :label="tt('Structure')" props="target">
            <ElTreeSelect
				default-expand-all

				v-model="form.target"
				:data="treeStructure"
				:render-after-expand="true"
				:filter-node-method="filterNodeMethod"
				:props="defaultProps"
				@change="changeTarget"
				filterable
				check-strictly
			/>
          </ElFormItem>
          <ElFormItem
            :label="tt('Author')"
            props="photographer"
          >
            <ElInput v-model="form.photographer" @change="changeAuthor"/>
          </ElFormItem>
          <ElFormItem :label="tt('Description')" props="description">
            <ElInput :rows="2" type="textarea" v-model="form.description" />
          </ElFormItem>
        </ElForm>
      </div>
	</div>
</template>
<script lang="ts" setup>
import { reactive, computed, defineEmits, defineProps, watchEffect, toRefs, ref, defineExpose} from 'vue';
import { FormInstance } from 'element-plus/lib/components/index.js';
import { list2Tree, TreeItem } from 'guigu_pm/public/js/utils';

import {PhotoFormat} from '../../../type/image';
import { StructureItemFormat } from '../../../type/diary';
import { getStructureName } from '../helper';
const tt = __
interface Props{
	photo:PhotoFormat,
	structures:StructureItemFormat[],
	showDateInput?:boolean,
}
interface Emits{
	(e:'deleteFile', photo:PhotoFormat):void
}
const props = defineProps<Props>();
const {structures, photo} = toRefs(props);
const emits = defineEmits<Emits>();
const formRef = ref<FormInstance>();
const form = reactive({
  name: photo.value.fileName,
  date: photo.value.date,
  target: photo.value.target,
  photographer: photo.value.photographer,
  description: photo.value.description,
});

defineExpose({
	formRef,
	formValue:form,
});

watchEffect(()=>{
	form.name= photo.value?.fileName;
	form.target = photo.value?.target;
	form.photographer = photo.value?.photographer;
	form.date = photo.value?.date;
	form.description= photo.value?.description;
});
const rules = reactive<FormRules>({
  name: [
    { required: true, trigger: 'blur' },
  ],
  target:[
    { required: true, trigger: 'blur' },
  ],
  photographer:[
    { required: true, trigger: 'blur' },
  ],
});

const treeStructure = computed(()=>list2Tree<StructureItemFormat>(structures.value));
const defaultProps = {
	children: 'children',
	label: 'name',
	value:'id',
};

function filterNodeMethod(value:string, data:(StructureItemFormat & TreeItem)){
	return data.name.includes(value);
}

function changeTarget(value: string){
	const newLabel = getStructureName(structures.value, value);
	form.name = `${form.date}-${newLabel || ''}-${form.photographer}`;
}

function changeAuthor(value:string){
	const newLabel = getStructureName(structures.value, form.target!);
	form.name = `${form.date}-${newLabel || ''}-${value}`;
}
function changeDate(v:string){
	const newLabel = getStructureName(structures.value, form.target!);
	form.name = `${v}-${newLabel || ''}-${form.photographer}`;
}

function deleteFile(){
	emits('deleteFile', photo.value);
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 640px) {
	.photo-info-container {
		flex-direction: column;

		.left {
			width: 100%;
			border-right: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
}

@media screen and (min-width: 640px) {
	.photo-info-container {
		flex-direction: row;

		.left {
			width: 100px;
			border-right: 1px solid #eee;
		}
	}
}

.photo-info-container {
	display: flex;
	border-bottom: 1px solid #eee;

	&:last-child {
		border-bottom: 0;
	}

	padding: 8px 0;

	.photo-image-container {
		width: 84px;
		height: 84px;
		display: flex;
		align-items: center;

		img {
			width: 100%;
		}
	}

	.photo-delete {
		margin-top: 8px;
	}

	.right {
		flex: 1;
	}
}
</style>
