import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d07a51b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "required-field" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "required-field" }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "required-field" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_viewer = _resolveDirective("viewer")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", null, [
      _createVNode($setup["Upload"], {
        structureDescendants: $setup.structureDescendants,
        date: $setup.date,
        showDateInput: false,
        onSave: $setup.save
      }, null, 8 /* PROPS */, ["structureDescendants", "date"]),
      _withDirectives((_openBlock(), _createElementBlock("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.photosFormat, (item) => {
          return (_openBlock(), _createBlock($setup["PhotoItem"], {
            key: item.id,
            photo: item,
            structureDescendants: $setup.structureDescendants,
            onDeletePhoto: $setup.deletePhoto,
            onChangePhoto: $setup.changePhoto,
            onShowPhotoInfo: $setup.showPhotoInfo
          }, null, 8 /* PROPS */, ["photo", "structureDescendants"]))
        }), 128 /* KEYED_FRAGMENT */))
      ])), [
        [_directive_viewer]
      ]),
      _createVNode($setup["ImageInfoDialog"], {
        visible: $setup.infoVisible,
        photo: $setup.photoInfo,
        structureDescendants: $setup.structureDescendants,
        onClose: $setup.closeInfo,
        onConfirm: $setup.changePhoto,
        onDelete: $setup.deletePhoto
      }, null, 8 /* PROPS */, ["visible", "photo", "structureDescendants"])
    ], 512 /* NEED_PATCH */), [
      [_vShow, $setup.showImage]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createElementVNode("ul", null, [
        (!$setup.project)
          ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
              _createTextVNode(_toDisplayString($setup.tt('Please Select')), 1 /* TEXT */),
              _createElementVNode("span", _hoisted_2, _toDisplayString($setup.tt('Project')), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true),
        (!$setup.structureName)
          ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
              _createTextVNode(_toDisplayString($setup.tt('Please Select')), 1 /* TEXT */),
              _createElementVNode("span", _hoisted_4, _toDisplayString($setup.tt('Construction Structure')), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true),
        (!$setup.date)
          ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
              _createTextVNode(_toDisplayString($setup.tt('Please Select')), 1 /* TEXT */),
              _createElementVNode("span", _hoisted_6, _toDisplayString($setup.tt('Date')), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true),
        _createTextVNode(" " + _toDisplayString($setup.tt('to upload images')), 1 /* TEXT */)
      ])
    ], 512 /* NEED_PATCH */), [
      [_vShow, !$setup.showImage]
    ])
  ], 64 /* STABLE_FRAGMENT */))
}