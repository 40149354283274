<template>
	<div class="file-uploader">
		<div class="file-preview-area" v-show="files.length">
			<div class="file-preview-container" v-if="!show_image_cropper">
				<FilePreview v-for="(file, i) in files"
					:file="file"
					@remove="remove_file(file)"
					@rename="file.name = $event"
					@toggle_optimize="file.optimize = !file.optimize"
					@toggle_image_cropper="toggle_image_cropper(i)"
				/>
			</div>
		</div>
		<UploadArea
			v-show="!show_image_cropper && !show_file_browser"
			@rename="file.name = $event"
			:has="Boolean(files.length)"
			:filetypes="filetypes"
			@addFiles="addFiles"
			@showBrowser="show_file_browser = true"
		/>
		<ImageCropper
			v-if="show_image_cropper"
			:file="files[crop_image_with_index]"
			@toggle="toggle_image_cropper(-1)"
		/>
		<FileBrowser
			v-if="show_file_browser"
			@select="selected_node = $event"
			@hide="show_file_browser = false"
		/>
	</div>
</template>
<script setup lang="ts">
import {ref, watch, computed} from 'vue';

import FilePreview from './FilePreview.vue';
import UploadArea from './UploadArea.vue';
import FileBrowser from './FileBrowser.vue';
import WebLink from './WebLink.vue';
import ImageCropper from './ImageCropper.vue';
import check_restrictions from './check_restrictions';
import type {FileInfo} from './upload';
import uploadFile from './upload';

const props = defineProps<{
	showUploadButton?: boolean;
	doctype?: string;
	docname?: string;
	fieldname?: string;
	method?: string;
	filetypes?: string[];
}>();
const folder = 'Home';

const emit = defineEmits<{
	upload: [],
	close: [],
	showDialogFooter: [],
	hideDialogFooter: [],
	success: [file: any, r?: any],
	allSuccess:[]
}>();

const webLink = ref('');

const files = ref<FileInfo[]>([]);
const uploading = ref(false);
const show_file_browser = ref(false);
const show_image_cropper = ref(false);
const crop_image_with_index = ref(-1);
const tt = __;

const selected_node = ref<any>();
watch(show_file_browser, show => {
	selected_node.value = null;
});

function close() {
	emit('close');
}

const upload_complete = computed(() => files.value.length > 0
	&& files.value.every(file => file.total !== 0 && file.progress === file.total));


function remove_file(file) {
	files.value = files.value.filter(f => f !== file);
}
function toggle_image_cropper(index) {
	crop_image_with_index.value = show_image_cropper.value ? -1 : index;
	if (show_image_cropper.value) {
		emit('showDialogFooter');
	} else {
		emit('hideDialogFooter');
	}
	show_image_cropper.value = !show_image_cropper.value;
}

function addFiles(file_array: (File | string)[]) {
	const type = props.filetypes;

	files.value = [
		...files.value,
		...Array.from(file_array).filter(f => (f instanceof File ? check_restrictions(f, type) : f))
			.map(file => {
				if (typeof file === 'string') {
					return {
						isLink: true,
						file_obj: null,
						cropper_file: null,
						crop_box_data: null,
						optimize: false,
						name: '',
						doc: null,
						progress: 0,
						total: 0,
						failed: false,
						file_url: file,
						request_succeeded: false,
						error_message: null,
						uploading: false,
					};
				}
				const is_image = file.type.startsWith('image');
				const size_kb = file.size / 1024;
				return {
					file_obj: file,
					cropper_file: file,
					crop_box_data: null,
					optimize: size_kb > 200 && is_image && !file.type.includes('svg'),
					name: file.name,
					doc: null,
					progress: 0,
					total: 0,
					failed: false,
					request_succeeded: false,
					error_message: null,
					uploading: false,
				};
			}),
	];
}


function upload_via_file_browser() {
	const selected_file = selected_node.value;
	close();
	if (!selected_file) {
		frappe.msgprint(__('Click on a file to select it.'));
		return Promise.reject(new Error());
	}
	uploading.value = true;
	return uploadFile({file_url: selected_file.file_url}, {
		doctype: props.doctype,
		docname: props.docname,
		fieldname: props.fieldname,
		method: props.method,
		success(file_doc, r) {
			emit('success', file_doc, r);
		},
	});
}
async function upload_files() {
	if (show_file_browser.value) {
		return upload_via_file_browser();
	}
	uploading.value = true;
	return frappe.run_serially(files.value.map((file, i) => () => uploadFile(file, {
		doctype: props.doctype,
		docname: props.docname,
		fieldname: props.fieldname,
		method: props.method,
		success(file_doc, r) {
			emit('success', file_doc, r);
			if (i === files.value.length - 1 && files.value.every(file => file.request_succeeded)) {
				emit('allSuccess');
				close();
			}
		},
	})));
}

function upload() {
	emit('upload');
	upload_files();
}
defineOptions({name: 'FileUploader'});
defineExpose({upload_files});
</script>
