<template>
  <div :style="style" v-if="!hideAction">
    <ElButton @click="gotoDetail">{{ tt('Detail') }}</ElButton>
    <ElButton :type="btnType" @click="gotoInterview">{{ tt('Interview') }}</ElButton>
  </div>
</template>
<script lang="ts" setup>
  import { computed } from 'vue';
  const tt = __;

  const props = defineProps<{ params: any }>();
  const hideAction = computed(() => props.params.node.group || props.params.node.data.__IS_AGGREGATION__);

  function gotoDetail() {
    props.params.gotoDetail(props.params.data);
  }
  function gotoInterview() {
    props.params.gotoInterview(props.params.data);
  }

  const style = {
    display: 'flex',
    alignItems: 'center',
  };
  const btnType = computed(() => {
    if (props.params.data.interview) {
      return 'success';
    }
    return '';
  });
</script>
