import { createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-855d3594"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body" }
const _hoisted_2 = { class: "editor-container" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElDialog"], {
    modelValue: $setup.dialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.dialogVisible) = $event)),
    title: $setup.tt('Load Instruction Template'),
    width: "80%",
    top: "5vh"
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createVNode($setup["ElButton"], {
          onClick: _cache[0] || (_cache[0] = $event => ($setup.dialogVisible = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElButton"], {
          type: "primary",
          onClick: $setup.confirm
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Confirm')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["ElTable"], {
          ref: "tableRef",
          class: "table",
          data: $setup.templateList,
          "max-height": "200",
          style: {"width":"100%"},
          stripe: "",
          border: "",
          onSelect: $setup.selectionChange
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElTableColumn"], {
              type: "selection",
              width: "55"
            }),
            _createVNode($setup["ElTableColumn"], {
              property: "template_name",
              label: $setup.tt('Instruction Template Title')
            }, null, 8 /* PROPS */, ["label"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["data"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString($setup.title), 1 /* TEXT */),
          _createVNode($setup["Editor"], {
            height: "100%",
            value: $setup.content
          }, null, 8 /* PROPS */, ["value"])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}