<template>
	<ElDatePicker v-if="dataType"
		:type="dataType" v-model="val"
		unlinkPanels rangeSeparator="~" :teleported="false" />
	<Types v-else
		filter
		v-model="val"
		:required="required"
		:type="type"
		:name="name"
		:label="label"
		:options="options" />
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { ElDatePicker } from 'element-plus';

import Types from '../../GuiguFieldInput/index.vue';

const props = defineProps<{
	meta: locals.DocType;
	name: string;
	label?: string;
	required?: boolean;
	type: string;
	options?: any;
	modelValue?: any
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: any): void;
}>();

const dataType = computed(() => {
	switch (props.type?.toLowerCase()) {
		case 'date': return 'daterange';
		case 'datetime': return 'datetimerange';
		case 'time': return 'timerange';
	}
});

function getvalue(type: any, value: any) {
	if (!type) {
		return value;
	}
	// TODO: 处理类型
	switch (type) {
		case 'daterange':
		case 'datetimerange':
		case 'timerange':
	}
	return value;
}
const val = computed({
	get: () => getvalue(dataType.value, props.modelValue),
	set: val => emit('update:modelValue', val),
});

</script>
