import { computed, onMounted, ref, onUnmounted, type Ref } from 'vue';
export default function useFullScreen(viewElement: Ref<HTMLElement|undefined>) {
	const isFullscreen = ref<Boolean>(false);

	function onFullscreen() {
		isFullscreen.value = document.fullscreenElement !== null;
	}
	onMounted(() => {
		document.addEventListener('fullscreenchange', onFullscreen);
	});
	onUnmounted(() => {
		window.removeEventListener('fullscreenchange', onFullscreen);
	});

	function fullScreen() {
		const element = viewElement.value;
		if (!element) {
			return;
		}
		if (element.mozFullScreenEnabled) {
			return Promise.reject(new Error(__('Full Screen Mode Disabled')));
		}
		let result = null;
		if (element.requestFullscreen) {
			result = element.requestFullscreen();
		} else if (element.mozRequestFullScreen) {
			result = element.mozRequestFullScreen();
		} else if (element.msRequestFullscreen) {
			result = element.msRequestFullscreen();
		} else if (element.webkitRequestFullscreen) {
			result = element.webkitRequestFullScreen();
		}
		return result || Promise.reject(new Error(__('Full Screen Not Supported')));
	}
	function cancelFullscreen() {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.msExitFullscreen) {
			document.msExitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		}
	}
	function toggleFullscreen() {
		if (isFullscreen.value) {
			cancelFullscreen();
		} else {
			fullScreen();
		}
	}
	return {
		isFullscreen,
		fullScreen,
		cancelFullscreen,
		toggleFullscreen,
	};
}
