
import mountVueToPage from 'guiguLib/mountVueToPage';

import Index from './index.vue';
frappe.provide('frappe.guigu_pm_boq');

function renderBoqCompletedQuantityPage (wrapper) {
	mountVueToPage(wrapper, [
		{path: '/:pathMatch(.*)*', component: Index},
	], {
		base: '/app/guigu_project_monthly_boq_completed_quantity',
	});
}
frappe.guigu_pm_boq.pages = frappe.guigu_pm_boq.pages || {};
frappe.guigu_pm_boq.pages.renderBoqCompletedQuantityPage = renderBoqCompletedQuantityPage;
