<template>
	<div class="input-container">
		<span v-if="!noLabel">{{ tt('Project') }}:</span>
		<ElSelect
			class="input"
			filterable
			v-model="projectValue"
			:popperClass="popperClassProject"
		>
			<ElOption
				v-for="item in projects"
				:key="item.name"
				:label="item.project_abbr_name||item.project_name"
				:value="item.name"
			>
				<p>
					<strong>{{ item.project_abbr_name|| item.project_name }}</strong>
					<span>{{ item.description }}</span>
				</p>
			</ElOption>
		</ElSelect>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useMetaQuery } from 'guigu/public/js/hooks/useMetaQuery';
import { ElSelect, ElOption } from 'element-plus';


const tt = __;

interface Props {
	modelValue:string;
	fields?:string[]
	filters?:([string, string, any] | [string, string, string, any])[]
	noLabel?:boolean
	default?:boolean
	/** 排序字段 */
	orderBy?:string
}
const smallMeta = useMetaQuery();
const props = defineProps<Props>();
interface Emit {
	(e: 'update:modelValue', project: string): void;
}
const emit = defineEmits<Emit>();
interface Project {
	name:string,
	project_abbr_name:string,
	project_name:string,
	project_construction_status:string
	description:string
}
const projects = ref<Project[]>([]);

const projectValue = computed({
	get: () => props.modelValue || '',
	set: (v: string) => {
		emit('update:modelValue', v);
	},
});

watch([() => [...props.filters || []], () => [...props.fields || []]], () => {
	getProjects();
}, { immediate: true });

async function getProjects() {
	const fields = [
		'name', 'project_abbr_name', 'project_name', 'project_construction_status',
		...props.fields||[],
	];
	const filters = [
		['status', '=', 'open'] as [string, string, string],
		...props.filters||[],
	];
	let params = {
		fields,
		filters,
		limit: 0,
	}
	if (props.orderBy){
		params['order_by'] = props.orderBy
	}
	const projectsRes: Project[] = await frappe.db.get_list('Project',params);
	const emptyProject = {name:'', project_abbr_name:'', project_name:'', project_construction_status:'', description:''};
	projects.value = [ emptyProject, ...projectsRes.map(p=>{
		p.description = `${p.name},${__(p.project_name)}${p.project_construction_status?',':''}${__(p.project_construction_status||'')}`;
		return p;
	})];
	const projectNames = projectsRes.map(item => item.name);
	const hasInValidateValue = props.modelValue&&!projectNames.includes(props.modelValue);
	if (props.default&&(!props.modelValue||hasInValidateValue)){
		const [sessionDefaultProject]:[string] = (frappe.defaults.get_user_defaults('project') || []);
		projectValue.value = sessionDefaultProject || projectsRes[0]?.name || '';
	}
}
const popperClassProject= computed(() => `projectLink`);

const margin = computed(() => (smallMeta.value ? '8px' : '0'));
</script>

<style lang="less" scoped>
.input-container{
	display: inline-flex;
}
.input {
	margin-bottom: v-bind(margin);
	min-width: 150px;
	flex: 1;
}
.projectLink {
	.el-select-dropdown__item {
		height: 59px !important;
	}

	.el-select-dropdown__item > p{
		display: flex;
		flex-direction: column;

	}
	.el-select-dropdown__item > p > span {
		font-size: 12px;
		line-height: 1em;
	}
}
</style>
