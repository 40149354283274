import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d703835"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar" }
const _hoisted_2 = { class: "refresh" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" <div> "),
    _createVNode($setup["ElSelect"], {
      class: "dataSelect",
      size: $props.size,
      filterable: $props.size === 'small',
      modelValue: $setup.company,
      onChange: $setup.changeCompany
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.subCompanies, (item) => {
          return (_openBlock(), _createBlock($setup["ElOption"], {
            key: item.name,
            label: item.company_name,
            value: item.name
          }, null, 8 /* PROPS */, ["label", "value"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["size", "filterable", "modelValue"]),
    _createVNode($setup["ElSelect"], {
      class: "dataSelect",
      size: $props.size,
      modelValue: $setup.pageType,
      onChange: $setup.pageTypeChange
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElOption"], {
          value: "month",
          key: "month",
          label: $setup.tt('Monthly Type')
        }, null, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElOption"], {
          value: "year",
          key: "year",
          label: $setup.tt('Yearly')
        }, null, 8 /* PROPS */, ["label"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["size", "modelValue"]),
    _createVNode($setup["ElSelect"], {
      class: "dataSelect",
      size: $props.size,
      modelValue: $props.countCategory,
      onChange: $setup.countCategoryChange
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElOption"], {
          value: "date",
          key: "date",
          label: $setup.tt('By Date')
        }, null, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElOption"], {
          value: "category",
          key: "category",
          label: $setup.tt('By attendance category')
        }, null, 8 /* PROPS */, ["label"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["size", "modelValue"]),
    ($setup.pageType === 'month')
      ? (_openBlock(), _createBlock($setup["ElDatePicker"], {
          key: 0,
          class: "dataSelect",
          size: $props.size,
          modelValue: $setup.monthValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.monthValue) = $event)),
          onChange: $setup.monthChange,
          type: "month",
          clearable: false,
          placeholder: $setup.tt('Select Month')
        }, null, 8 /* PROPS */, ["size", "modelValue", "placeholder"]))
      : _createCommentVNode("v-if", true),
    ($setup.pageType === 'year')
      ? (_openBlock(), _createBlock($setup["ElDatePicker"], {
          key: 1,
          modelValue: $setup.yearValue,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.yearValue) = $event)),
          onChange: $setup.yearChange,
          type: "year",
          size: "small",
          clearable: false,
          placeholder: $setup.tt('Select Year')
        }, null, 8 /* PROPS */, ["modelValue", "placeholder"]))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["ElTooltip"], {
      content: $setup.tt('Switch to the Log Details page'),
      class: "calendar"
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElButton"], {
          class: "calendarButton",
          size: $props.size,
          icon: $setup.Calendar,
          circle: "",
          onClick: $setup.navigateToRecord
        }, null, 8 /* PROPS */, ["size", "icon"]),
        _createTextVNode("/> ")
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["content"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["ElTooltip"], {
        content: $setup.tt('Switch to the Log Details page'),
        class: "calendarPhone"
      }, {
        default: _withCtx(() => [
          _createVNode($setup["ElButton"], {
            class: "calendarButtonPhone",
            size: $props.size,
            icon: $setup.Calendar,
            circle: "",
            onClick: $setup.navigateToRecord
          }, null, 8 /* PROPS */, ["size", "icon"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["content"]),
      _createVNode($setup["ElButtonGroup"], {
        size: $props.size,
        class: "ml-4"
      }, {
        default: _withCtx(() => [
          _createVNode($setup["ElButton"], {
            loading: $setup.loading,
            onClick: $setup.exportTable
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('Export')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["loading"]),
          _createVNode($setup["ElButton"], {
            loading: $setup.loading,
            onClick: $setup.refresh,
            icon: $setup.RefreshRight
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('Refresh')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["loading", "icon"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["size"])
    ])
  ]))
}