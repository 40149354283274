<template>
	<AgGridVue
		class="ag-theme-guigu work-assessment-sumary-container_table"
		:rowData="summaryTableData"
		:columnDefs="columnDefs"
		:localeText="zhCN"
		@grid-ready="onGridReady"
		animateRows="true"
		:getRowId="getRowId">
	</AgGridVue>
</template>

<script lang="ts">
import {ref, computed, defineComponent, PropType, getCurrentInstance, watch} from 'vue';

import {AgGridVue} from 'ag-grid-vue3';
import {GridApi} from 'ag-grid-community';

import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';

import AgGridAttandanceIcon from './AgGridAttandanceIcon.vue';
import type {SummaryTableData} from './type';
import {getSummaryColumns} from './tableColumns';

export default defineComponent({
	components: {
		AgGridVue,
		AgGridAttandanceIcon,
	},
	props: {
		startDate: String,
		endDate: String,
		summaryTableData: Object as PropType<SummaryTableData[]>,
		loading: Boolean,
		isPhone: String,
	},
	emits: {
		onDetailGridReady(params: GridApi) { },
	},
	setup(props, context) {
		const {appContext} = getCurrentInstance() || {};
		const gridApi = ref(null);

		const loading = ref<boolean>(false);
		const onGridReady = (params: any) => {
			gridApi.value = params.api;
			context.emit('onDetailGridReady', params.api);
		};
		const visible = ref(false);
		watch(() => props.loading, () => {
			loading.value = props.loading;
		});

		const columnDefs = computed(() => getSummaryColumns(
			props.startDate || '',
			props.endDate || '',
			props.isPhone,
		));

		const defaultColDef = {
			sortable: true,
			filter: true,
			flex: 1,
			resizable: true,
		};

		const getRowId = (params: any) => params.data.name;

		return {
			loading,
			onGridReady,
			columnDefs,
			defaultColDef,
			getRowId,
			visible,
			confirm,
			zhCN,
		};
	},
});
</script>

<style lang="less" scoped>
.work-assessment-container {
	flex: 1;
}

.work-assessment-sumary-container_table {
	height: 100%;
}
</style>
