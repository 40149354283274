<template>
	<div v-if="isEditable" class="tools">
		<GripVertical class="drag-handle" contenteditable="false" draggable="true" data-drag-handle :size="16">
		</GripVertical>
		<BlockActions @remove="remove" :editable="isEditable">
		</BlockActions>
	</div>
	<div class="chart-container" ref="blockRef">
		<component
			:is="BlockComponent"
			ref="BlockComponentRef"
			:chartName="chartName"
			@setData="setData"
			:data="data"
			:editable="isEditable"
		>
		</component>
	</div>
</template>

<script setup lang="ts">
import {computed, inject, ref, watch, defineProps, type Ref, getCurrentInstance, type Component} from 'vue';

import {GripVertical} from 'lucide-vue-next';

import type {CustomBlock} from '../type';

import BlockActions from './BlockActions.vue';
import LoadingComponent from './Loading.vue';
interface Emit {
	(e: 'setChartName', chartName: string): void,
	(e: 'setData', data: string): void,
	(e: 'remove'): void,
}
const emit = defineEmits<Emit>();
interface Props {
	chartName?: string;
	nodeViewProps: any;
	isEditable: boolean;
	chartType:string
	data:string
}
const props = defineProps<Props>();

const blockRef = ref<HTMLDivElement|null>(null);
const editorContainerRef = inject<Ref<HTMLDivElement>>('editorContainerRef');
const BlockComponentRef = ref<{
	remove:()=>void|Promise<void>
}>();
const BlockComponent = ref<Component>(LoadingComponent);
function remove() {
	BlockComponentRef.value?.remove();
	emit('remove');
}
function setData(data:string) {
	emit('setData', data);
}
const instance = getCurrentInstance();

function getTimeout(entry:IntersectionObserverEntry) {
	if (BlockComponent.value.__name !== LoadingComponent.__name) {
		return;
	}
	setTimeout(async () => {
		if (!blockRef.value) {
			return;
		}
		const blockRect = blockRef.value?.getBoundingClientRect();
		const rootEl = editorContainerRef?.value || document.body;
		const rootRect = rootEl.getBoundingClientRect();
		if (blockRect.bottom < rootRect.top || blockRect.top > rootRect.bottom) {
			return;
		}
		const blocks = instance?.appContext?.config?.globalProperties?.$CustomBlocks as CustomBlock[];
		const block = blocks.find(item=>item.name === props.chartType);
		BlockComponent.value = block?.block || LoadingComponent;
	}, 500);
}

function observerCallback(entries: IntersectionObserverEntry[]) {
	for (const entry of entries.reverse()) {
		if (entry.isIntersecting) {
			getTimeout(entry);
		} else {
		}
	}
}

const observer = new IntersectionObserver(observerCallback, {
	threshold: 0.1,
	root: editorContainerRef?.value || document.body,
});

watch(blockRef, () => {
	if (!blockRef.value) {
		return;
	}
	observer.observe(blockRef.value);
}, {deep: true});

</script>
<style scoped lang="less">
.placeholder {
	height: 20rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #e2e8f0;
	border-radius: 0.25rem;
}

.tools {
	display: flex;
	justify-content: space-between;
	padding: 0 20px;

	.drag-handle {
		cursor: grab;
	}
}
</style>
