const Workspace = frappe.views.Workspace;
const old = Workspace.prototype.prepare_editorjs
Workspace.prototype.prepare_editorjs = function prepare_editorjs() {
	if (this.editor) {
		this.editor.isReady.then(() => {
			// TODO: 全局
			for (const name of Object.keys(frappe.workspace_block.blocks)) {
				if (name === 'header') { continue; }
				if (name === 'paragraph') { continue; }
				if (name === 'spacer') { continue; }
				const tool = this.editor.configuration.tools[name];
				if (!tool || typeof tool !== 'object') { continue; }
				const config = tool.config;
				if (!config || typeof config !== 'object') { continue; }
				if (!config.page_data) { continue; }
				config.page_data = this.page_data;
			}
			this.editor.render({ blocks: this.content || [] });
		});
	} else {
		this.initialize_editorjs(this.content);
	}
}
export {}
