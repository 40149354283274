import createSpreadSheet from './createSpreadSheet';

function parse(value) {
	if (!value) { return []; }
	const sheets = JSON.parse(value);
	if (typeof sheets !== 'object' || !sheets) { return []; }
	if (!Array.isArray(sheets)) {
		return [{...sheets, name: 'sheet1'}];
	}
	return sheets.map(sheet => {
		if (sheet.data) { return sheet; }
		const name = sheet.name || sheet.title;
		const value = sheet.value || sheet.tableOptions;
		return {...value, name};
	});
}
frappe.ui.form.ControlTianjySpreadSheet = class ControlExcel extends frappe.ui.form.ControlInput {
	df: any;
	currentValue = '[]';
	input_area: HTMLElement | undefined;
	static horizontal = false;
	only_input: boolean = false;
	set_input_areas() {
		this.label_area = this.label_span = this.$wrapper.find('label').get(0);
		this.input_area = this.$wrapper.find('.control-input').get(0);
	}
	refresh_input() {
		let me = this;
		if (me.disp_status === 'None') { return; }
		// refresh value
		if (me.frm) {
			me.value = frappe.model.get_value(me.doctype, me.docname, me.df.fieldname);
		} else if (me.doc) {
			me.value = me.doc[me.df.fieldname] || '';
		}

		let is_fetch_from_read_only = me.read_only_because_of_fetch_from();

		const readonly = Boolean(this.disp_status !== 'Write' || is_fetch_from_read_only);
		$(me.input_area).toggle(true);
		const {value} = me;
		let {sheets} = this;
		if (!sheets) {
			sheets = [];
			this.sheets = sheets;
		}

		if (this.currentValue !== value) {
			try {
				this.currentValue = value;
				sheets = parse(value);
				this.sheets = sheets;
			} catch {}
		}
		const {xlsxEditor} = this;
		if (xlsxEditor) {
			xlsxEditor.setValue(sheets, readonly);
		} else {
			this.has_input = true;
			this.xlsxEditor = createSpreadSheet(
				this.input_area!, {
					value: sheets, readonly, modifible: true, set: sheets => {
						const json = JSON.stringify(sheets);
						this.sheets = sheets;
						this.currentValue = json;
						if (this.value === json) { return; }
						this.parse_validate_and_set_in_model(json);
					},
					height: `calc(100vh - var(--navbar-height, 0) - var(--page-head-height, 0))`,
				},
			);
			if (me.df.on_make) {
				me.df.on_make(me);
			}
		}
		me.set_description();
		me.set_label();
		me.set_doc_url();
		me.set_mandatory(me.value);
		me.set_bold();
		me.set_required();
	}
	add_sheets(...list) {
		const {xlsxEditor} = this;
		if (!xlsxEditor) { return; }
		xlsxEditor.addSheets(list.flat().filter(Boolean).map(sheet => {
			if (sheet.data) { return sheet; }
			const name = sheet.name || sheet.title;
			const value = sheet.value || sheet.tableOptions;
			return {...value, name};
		}));
	}
	set_named_expressions(e) {
		if (!e) { return; }
		const {xlsxEditor} = this;
		if (!xlsxEditor) { return; }
		xlsxEditor.namedExpressions = e;
	}
	validate(v?: string) {
		if (!v) {
			return '';
		}
		return v;
	}
	get_queries() {
		const args = {};
		this.set_custom_query(args);
		return args;
	}

	is_valid_value(value: any, key: string) {
		if (value) { return true; }
		// check if empty value is valid
		if (this.frm) {
			let field = frappe.meta.get_docfield(this.frm.doctype, key);
			// empty value link fields is invalid
			return !field || !['Link', 'Dynamic Link', 'Tree Select'].includes(field.fieldtype);
		}
		return value !== undefined;
	}
	set_nulls(obj: Record<string, any>) {
		$.each(obj, (key, value) => {
			if (!this.is_valid_value(value, key)) {
				delete obj[key];
			}
		});
		return obj;
	}

	set_custom_query(args: Record<string, any>) {
		if (this.get_query || this.df.get_query) {
			let get_query = this.get_query || this.df.get_query;
			if ($.isPlainObject(get_query)) {
				let filters = null;
				if (get_query.filters) {
					// passed as {'filters': {'key':'value'}}
					filters = get_query.filters;
				} else if (get_query.query) {
					// passed as {'query': 'path.to.method'}
					args.query = get_query;
				} else {
					// dict is filters
					filters = get_query;
				}

				if (filters) {
					filters = this.set_nulls(filters);

					// extend args for custom functions
					$.extend(args, filters);

					// add "filters" for standard query (search.py)
					args.filters = filters;
				}
			} else if (typeof get_query === 'string') {
				args.query = get_query;
			} else {
				// get_query by function
				let q = get_query(
					(this.frm && this.frm.doc) || this.doc,
					this.doctype,
					this.docname,
				);

				if (typeof q === 'string') {
					// returns a string
					args.query = q;
				} else if ($.isPlainObject(q)) {
					// returns a plain object with filters
					if (q.filters) {
						this.set_nulls(q.filters);
					}

					// turn off value translation
					if (q.translate_values !== undefined) {
						this.translate_values = q.translate_values;
					}

					// extend args for custom functions
					$.extend(args, q);
					args.filters = q.filters;
				}
			}
		}
		if (this.df.filters) {
			this.set_nulls(this.df.filters);
			if (!args.filters) { args.filters = {}; }
			$.extend(args.filters, this.df.filters);
		}
	}
};
frappe.model.all_fieldtypes.push('Tianjy Spread Sheet');
