<script setup lang="ts">
import { computed } from 'vue';
import { ICellRendererParams } from 'ag-grid-community';

import FieldRenderer from '../../../../../../../guigu/guigu/public/js/FieldRenderer/index.vue';


interface Params extends ICellRendererParams {
	docField: locals.DocField;
	meta: locals.DocType;
	enableLink?: boolean;
	getRows(): any[];
}

const props = defineProps<{
	params: Params
}>();


const docField = computed(() => props.params.docField);
const meta = computed(() => props.params.meta);
const enableLink = computed(() => props.params.enableLink);
const data = computed(() => {
	const { params } = props;
	const { data } = params;
	if (data) { return data; }
	const { value } = params;
	const { fieldname } = docField.value;
	const item = params.getRows().find(v => v[fieldname] === value);
	return item;
});
const value = computed(() => props.params.value);
const text = computed(() => {
	const v = value.value;
	if (v || v === 0) {
		return String(v);
	}
	return '';
});

</script>
<template>
	<div class="ellipsis">
		<FieldRenderer
			v-if="data"
			:value="value"
			:field="docField"
			:data="data"
			:meta="meta"
			:enableLink="enableLink"
			ellipsis
			inline
			showTitle />
		<template v-else-if="text">{{ text }}</template>
	</div>
</template>
