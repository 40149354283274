<template>
	<div @click="visible = !visible" class="btn">
		<a>{{ tt('Edit Filters') }}</a>
	</div>
	<ElDialog v-model="visible"
		:title="tt('Select Filters')"
		width="min(600px, max(min(300px, 100%), 50%))">
		<ElInput v-model="input" :placeholder="tt('Search')" />
		<ElCheckboxGroup v-model="selected">
			<ElRow :gutter="20">
				<ElCol :span="12" v-for="df in options" :key="df.value"
					v-show="filter(df.label)">
					<ElCheckbox :label="df.value">{{ df.label }}</ElCheckbox>
				</ElCol>
			</ElRow>
		</ElCheckboxGroup>
		<template #footer>
			<span class="dialog-footer">
				<ElButton @click="visible = false">{{ tt('Cancel') }}</ElButton>
				<ElButton type="primary" @click="save">{{ tt('Confirm') }}
				</ElButton>
			</span>
		</template>
	</ElDialog>
</template>
<script setup lang="ts">
import {computed, ref, watch} from 'vue';
import {ElDialog, ElButton, ElCheckboxGroup, ElCheckbox, ElRow, ElCol} from 'element-plus';


const props = defineProps<{
	modelValue: string[];
	meta: locals.DocType;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: string[]): void
}>();
const input = ref('');
function filter(label: string) {
	const {value} = input;
	if (!value) {
		return true;
	}
	return label.includes(value);
}
const selected = ref(props.modelValue);
const visible = ref(false);
const tt = __;
const fields = computed(() => props.meta.fields.filter(f =>
	['Select', 'Link', 'Data', 'Int', 'Check'].includes(f.fieldtype)));
const options = computed(() => fields.value.map(df => ({
	label: __(df.label),
	value: df.fieldname,
	checked: props.modelValue.includes(df.fieldname),
})));
function save() {
	const {value} = selected;
	frappe.model.user_settings.save(
		props.meta.name,
		'group_by_fields', value.length && value || null,
	);
	emit('update:modelValue', value);
	visible.value = false;
}
watch(visible, v => {
	if (v) {
		selected.value = props.modelValue;
	}
});
</script>
<style scoped lang="less">
.btn {
	margin-top: var(--margin-md);
	font-size: var(--text-sm);
	color: var(--primary);

	a:hover {
		text-decoration: underline;
	}

}
</style>
