import GridRow from '../../../../../../frappe/frappe/public/js/frappe/form/grid_row';

import GuiguGirdRowForm from './frappe.form.grid_row_form';

GridRow.prototype.show_form = function () {
	let me = this;
	if (frappe.utils.is_xs()) {
		$(this.grid.form_grid).css('min-width', '0');
		$(this.grid.form_grid).css('position', 'unset');
	}
	if (!this.grid_form) {
		this.grid_form = new GuiguGirdRowForm({
			row: this,
		});
	}
	this.grid_form.render();
	this.row.toggle(false);
	// this.form_panel.toggle(true);


	let cannot_add_rows =
		this.grid.cannot_add_rows || (this.grid.df && this.grid.df.cannot_add_rows);
	this.wrapper
		.find(
			'.grid-insert-row-below, .grid-insert-row, .grid-duplicate-row, .grid-append-row',
		)
		.toggle(!cannot_add_rows);

	this.wrapper
		.find('.grid-delete-row')
		.toggle(!(this.grid.df && this.grid.df.cannot_delete_rows));

	if (this.frm.isPosition) {
		const dialog = $(this.wrapper).find('dialog');
		this.wrapper.find('.guigu_dialog_modal').on('click', function (e) {
			if (e.target.className === 'guigu_dialog_modal') {
				me.toggle_view();
			}
		});
		dialog[0]?.show();
	} else {
		frappe.dom.freeze('', 'dark grid-form');
	}

	if (cur_frm) { cur_frm.cur_grid = this; }
	this.wrapper.addClass('grid-row-open');

	if (
		!frappe.dom.is_element_in_viewport(this.wrapper) &&
		!frappe.dom.is_element_in_modal(this.wrapper)
	) {
		// -15 offset to make form look visually centered
		frappe.utils.scroll_to(this.wrapper, true, -15);
	}

	if (this.frm) {
		this.frm.script_manager.trigger(`${this.doc.parentfield}_on_form_rendered`);
		this.frm.script_manager.trigger('form_render', this.doc.doctype, this.doc.name);
	}
};
GridRow.prototype.hide_form = function () {
	if (frappe.utils.is_xs()) {
		$(this.grid.form_grid).css('min-width', '738px');
		$(this.grid.form_grid).css('position', 'relative');
	}
	if (this.frm.isPosition) {
		this.wrapper.find('.guigu_dialog_modal').off('click');
		const dialog = $(this.wrapper).find('dialog')[0];
		dialog?.close();
	}
	frappe.dom.unfreeze();
	this.row.toggle(true);
	if (!frappe.dom.is_element_in_modal(this.row)) {
		frappe.utils.scroll_to(this.row, true, 15);
	}
	this.refresh();
	if (cur_frm) { cur_frm.cur_grid = null; }
	this.wrapper.removeClass('grid-row-open');

};
