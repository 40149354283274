/* eslint-disable id-length */
import {ref, watch, type ComputedRef, type Ref} from 'vue';

import {isCtrlKey} from 'handsontable/helpers';

import LogicFlow, {BaseNodeModel, type Definition} from '@logicflow/core';

import getDefaultPanel from './defaultDndPanel';
import defaultControls from './defaultControl';
import * as cusNodes from './custom_node';

export default function useFlow(el:Ref<HTMLElement|undefined>, data:ComputedRef<Record<string, any>>, extralProps: Partial<Definition>) {
	const editVisible = ref<boolean>(false);
	const editModel = ref<BaseNodeModel>();
	const flow = ref<LogicFlow>();

	watch([data, flow.value], ()=>{
		if (!flow.value) {
			return;
		}
		flow.value.render(data.value);
	});

	watch(el, ()=>{
		if (!el.value) {
			return;
		}
		const lf = createFlow(el.value, data, extralProps);
		registerCustomNodes(lf);
		registerEvents(lf);
		for (const item of lf.extension.control?.controlItems) {
			switch (item.key) {
				case 'zoom-in':item.text = __('Zoom In'); break;
				case 'zoom-out':item.text = __('Zoom Out'); break;
				case 'reset':item.text = __('Reset Size'); break;
				case 'undo':item.text = __('Undo Step'); break;
				case 'redo':item.text = __('Redo Step'); break;
			}
		}
		if (!extralProps.isSilentMode) {
			for (const item of defaultControls) {
				lf.extension.control.addItem(item);
			}
		}

		lf.render(data.value || {});

		// edit编辑模式时创建拖拽面板
		if (!extralProps.isSilentMode) {
			registerTools(lf);
		}

		flow.value = lf;
	});
	function registerEvents(lf:LogicFlow) {
		lf.on('edit', (model:BaseNodeModel) => {
			editModel.value = model;
			editVisible.value = true;
		});
		lf.on('node:dnd-add, edge:add', ({data}) => {
			lf.setProperties(data.id, {});
			const {x, y, type, id} = data;
			if (type === 'pool') {
				const poolModel = lf.getNodeModelById(id);
				const {width, height} = poolModel;
				const {id: laneId} = lf.addNode({
					type: 'lane',
					text: __('Lane'),
					properties: {
						nodeSize: {
							width: width,
							height: height - 30,
						},
					},
					x,
					y: y + 15,
				});
				poolModel.addChild(laneId);
			}
		});
		lf.on('node:resize', ({oldNodeSize, newNodeSize}) => {
			const {id, type} = oldNodeSize;
			const deltaWidth = newNodeSize.width - oldNodeSize.width;
			// const resizeDir = newNodeSize.y - oldNodeSize.y > 0 ? 'below': 'above'
			// 节点宽度变大，x右移， 方向为right
			// 节点宽度变大， x左移， 方向为left
			// 节点宽度变小， x右移， 方向为left
			// 节点宽度变小， x左移，方向为right
			let resizeDir = 'right';
			if (deltaWidth > 0 && (newNodeSize.x - oldNodeSize.x) < 0) {
				resizeDir = 'left';
			} else if (deltaWidth < 0 && (newNodeSize.x - oldNodeSize.x) > 0) {
				resizeDir = 'left';
			}
			if (type === 'pool') {
				// 泳池缩放，泳道一起调整
				lf.getNodeModelById(id).resizeChildren({resizeDir, deltaWidth});
			} else if (type === 'lane') {
				// 泳道缩放， 调整泳池
				const groupId = lf.extension.group.nodeGroupMap.get(id);
				if (groupId) {
					lf.getNodeModelById(groupId).resize(id, newNodeSize);
				}
			}
		});
		lf.on('history:change', () => {
			extralProps.onChange(lf.getGraphData());
		});
	}
	return {editVisible, editModel, flow};
}
function deleteConfirm (lf:LogicFlow, data:any) {
	const {type, id, children = []} = data;
	if (type === 'lane') {
		// 通知上层泳池节点， 重新分配空间
		const groupId = lf.extension.group.nodeGroupMap.get(id);
		if (groupId) {
			const group = lf.getNodeModelById(groupId);
			// group?.removeChild(id)
			// group?.resize()
			group?.deleteChild(id);
			return false;
		}
	}
	if (type === 'pool') {
		// 删除全部child
		for (const childId of children) {
			lf.deleteNode(childId);
		}
	}
	return true;
}

export function createFlow(el:HTMLElement, data:Record<string, any>, extralProps:Record<string, any>) {
	const lf: LogicFlow = new LogicFlow({
		stopScrollGraph: false,
		stopZoomGraph: false,
		width: el.parentElement?.offsetWidth,
		height: el.parentElement?.offsetHeight,
		autoWrap: true,
		grid: {
			visible: false,
			type: 'dot',
			size: 1,
		},
		keyboard: {
			enabled: true,
		},
		background: {
			backgroundImage: 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdHRlcm4gaWQ9ImdyaWQiIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHBhdGggZD0iTSAwIDEwIEwgNDAgMTAgTSAxMCAwIEwgMTAgNDAgTSAwIDIwIEwgNDAgMjAgTSAyMCAwIEwgMjAgNDAgTSAwIDMwIEwgNDAgMzAgTSAzMCAwIEwgMzAgNDAiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2QwZDBkMCIgb3BhY2l0eT0iMC4yIiBzdHJva2Utd2lkdGg9IjEiLz48cGF0aCBkPSJNIDQwIDAgTCAwIDAgMCA0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZDBkMGQwIiBzdHJva2Utd2lkdGg9IjEiLz48L3BhdHRlcm4+PC9kZWZzPjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JpZCkiLz48L3N2Zz4=")',
			backgroundRepeat: 'repeat',
		},
		guards: {
			beforeDelete: data => deleteConfirm(lf, data),
		},
		...extralProps,
		container: el,
	});
	return lf;
}
function registerTools(lf: LogicFlow) {
	const panelItems = getDefaultPanel(lf);
	lf.extension.dndPanel.setPatternItems(panelItems);
	// lf.extension.miniMap.show();
}
function registerCustomNodes(lf:LogicFlow) {
	for (const [key, node] of Object.entries(cusNodes)) {
		lf.register(node);
	}
}
