
export const extendFields = new Map<string, [string, string, Partial<locals.DocField>, string][]>([
	['Date', [
		['year', '年', { options: 'Year' }, 'dateExtField'],
		['month', '月', { options: 'Month' }, 'dateExtField'],
		['week', '周', { options: 'Week' }, 'dateExtField'],
	]],
]);

export const displayField = {
	useDefault: true,

	titleField: true,
	commentField: true,
	tagField: true,
	dateExtField: true,

	width: true,
	enableLink: true,
};
