<template>
	<div @click="onClick" class="divCell"
		:style="{ cursor: hasOwner, background: backgroundColor }">
		{{ props.params.value?.type_symbol || '' }}
	</div>
</template>
<script lang="ts" setup>
import { defineProps, computed } from 'vue';

const props = defineProps<{ params: { value: any, showDetail: any, data: any, organization: string, date: string } }>();
const hasOwner = computed(() => (props.params.data?.record_owner === frappe.user.name || props.params.value?.record_name) ? 'pointer' : 'default');
const backgroundColor = computed(() => props.params.value?.record_name ? props.params.value?.color || '#373b415e' : '');
function onClick() {
	if (props.params.value?.record_name) {
		props.params.showDetail(props.params.value?.record_name);
	} else if (props.params.data?.record_owner === frappe.user.name || props.params.value?.record_name) {
		let doc = frappe.model.get_new_doc('Guigu Epc Daily Record');
		doc.organization = props.params.organization;
		doc.record_date = props.params.date;
		doc.record_owner = props.params.data?.record_owner;
		frappe.set_route('Form', 'Guigu Epc Daily Record', doc.name);
	}
}
</script>

<style scoped lang="less">
.divCell {
	width: 100%;
	height: 100%;
	text-align: center;
}
</style>
