import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Detail"], {
    columnSize: $setup.resizeDetailColumn,
    "onUpdate:columnSize": _cache[1] || (_cache[1] = $event => (($setup.resizeDetailColumn) = $event)),
    rowSize: $setup.resizeDetailRow,
    "onUpdate:rowSize": _cache[2] || (_cache[2] = $event => (($setup.resizeDetailRow) = $event)),
    model: true,
    row: false
  }, {
    default: _withCtx(() => [
      ($setup.meta&&$setup.detail)
        ? (_openBlock(), _createBlock($setup["FormDetail"], {
            key: 0,
            loading: $setup.loading,
            meta: $setup.meta,
            options: {},
            value: $setup.detail,
            "onUpdate:value": _cache[0] || (_cache[0] = $event => ($setup.emit('hide')))
          }, null, 8 /* PROPS */, ["loading", "meta", "value"]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["columnSize", "rowSize"]))
}