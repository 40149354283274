import type { RowApi, RowValue, ColumnCell, Listener } from '../types';
import type { Cell } from '../types/Row';
import noop from '../utils/noop';

export default function createCell(
	rowApi: RowApi,
	row: RowValue,
	column: ColumnCell,
	index: number,
	destroy: () => void,
): Cell {
	const { render, field } = column;
	let destroyed = false;
	const cancelSet = new Set<() => void>();
	const { listen } = rowApi;

	const comp = render({
		...row, value: field ? row.data?.[field] : undefined,
	}, Object.assign(Object.create(rowApi), {
		listen: (k: string | symbol, fn: Listener<any>) => {
			if (destroyed) { return noop; }
			const c = listen(k as any, fn);
			const cc = () => { c(); cancelSet.delete(cc); };
			cancelSet.add(cc);
			return cc;
		},
	}));
	const el = comp.root;
	el.classList.add('nyloong-table-cell');
	return {
		el, index, column,
		destroy() {
			if (destroyed) { return; }
			destroyed = true;
			comp.destroy();
			for (const k of [...cancelSet]) {
				k();
			}
			destroy();
		},
		setHidden: comp.setHidden.bind(comp),
		update(row) {
			comp.update({ ...row, value: field ? row.data?.[field] : undefined });
		},
	};
}
