<template>
	<ElInput :style="{width:'140px'}" v-model="value" :placeholder="description" />
</template>
<script lang="ts" setup>
import { computed, onUnmounted, watch } from 'vue';
import { ElInput } from 'element-plus';


const props = defineProps<{
	modelValue?: any
	label?: string
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: any): void;
}>();

watch(() => props.modelValue, value => {
	if (typeof value !== 'string' && value !== undefined) {
		emit('update:modelValue', undefined);
	}
}, { immediate: true });
const regex = /^%[^%]*%$/;
const value = computed({
	get: () => {
		const value = props.modelValue;
		if (typeof value !== 'string') { return; }
		if (!regex.test(value)) { return value; }
		return value.slice(1, -1);
	},
	set: v => {
		if (typeof v !== 'string' || !v) {
			emit('update:modelValue', undefined);
		} else {
			emit('update:modelValue', v.includes('%') ? v : `%${v}%`);
		}
	},
});
const description = computed(() => __(props.label || 'use % as wildcard'));
</script>
