<template>
	<ElSelect v-model="modelValue" multiple filterable defaultFirstOption clearable
		style="width: 100%">
		<ElOption v-for="field in options" :key="field.value"
			:value="field.value" :label="field.label" />
	</ElSelect>
</template>
<script setup lang="ts">
import { computed, shallowRef, watch } from 'vue';

import { ElSelect, ElOption } from 'element-plus';
const props = defineProps<{
	doctype: string;
	modelValue?: string | null;
}>();

const emit = defineEmits<{
	(event: 'update:modelValue', value: string | null): void;
}>();

const modelValue = computed({
	get: () => {
		try {
			return JSON.parse(props.modelValue || '').map((v: any) => JSON.stringify(v));
		} catch {
			return [];
		}
	},
	set: v => emit('update:modelValue', `[${v.join(',')}]` || null),
});
function getOptions(doctype: string) {
	const list: any[] = (frappe.get_meta(doctype)?.fields || []).filter(df =>
		frappe.model.is_value_type(df.fieldtype) &&
		frappe.perm.has_perm(doctype, df.permlevel, 'read')
	);
	list.unshift(
		{ fieldname: 'name', label: frappe.meta.get_label(doctype, 'name') },
		{ fieldname: 'creation', label: frappe.meta.get_label(doctype, 'creation') },
		{ fieldname: 'modified', label: frappe.meta.get_label(doctype, 'modified') },
	);

	return list.map(v => [{
		label: __(v.label || v.fieldname),
		value: JSON.stringify([doctype, v.fieldname]),
	}, {
		label: `${__(v.label || v.fieldname)} (倒序)`,
		value: JSON.stringify([doctype, v.fieldname, true]),
	}]).flat();

}
const options = shallowRef<{
	label: any;
	value: string;
}[]>([]);
watch(() => props.doctype, dt => {
	options.value = getOptions(dt);
	frappe.model.with_doctype(dt, () => {
		if (dt !== props.doctype) { return; }
		options.value = getOptions(dt);
	});
}, { immediate: true });
</script>
