frappe.views.ListView.prototype.set_fields = async function set_fields() {
	this.link_field_title_fields = {};
	const fields = [].concat(
		frappe.model.std_fields_list,
		this.get_fields_in_list_view(),
		[this.meta.title_field, this.meta.image_field],
		this.settings.add_fields || [],
		this.meta.track_seen ? '_seen' : null,
		this.sort_by,
		'enabled',
		'disabled',
		'color',
	);

	await Promise.all(fields.map(f => new Promise(resolve => {
		const df
					= typeof f === 'string' ? frappe.meta.get_docfield(this.doctype, f) : f;
		if (
			df
					&& (df.fieldtype == 'Link' || df.fieldtype == 'Tree Select' || df.fieldtype == 'Tianjy Related Link')
					&& frappe.boot.link_title_doctypes.includes(df.options)
		) {
			frappe.model.with_doctype(df.options, () => {
				const meta = frappe.get_meta(df.options);
				if (meta.show_title_field_in_link) {
					this.link_field_title_fields[
						typeof f === 'string' ? f : f.fieldname
					] = meta.title_field;
				}

				this._add_field(f);
				resolve();
			});
		} else {
			this._add_field(f);
			resolve();
		}
	})));

	for (const f of this.fields) {
		const df = frappe.meta.get_docfield(f[1], f[0]);
		if (df && df.fieldtype === 'Currency' && df.options && !df.options.includes(':')) {
			this._add_field(df.options);
		}
	}
};
