import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-316421e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "noCommit" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElButton = _resolveComponent("ElButton")

  return (_openBlock(), _createElementBlock("div", null, [
    ($props.dailyRecords.type === 'none')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode($setup["Avatar"], {
            title: $props.dailyRecords.user?.full_name,
            url: $props.dailyRecords.user?.user_image,
            name: $props.dailyRecords.user?.name
          }, null, 8 /* PROPS */, ["title", "url", "name"]),
          _createElementVNode("span", _hoisted_2, _toDisplayString($props.dailyRecords.user?.full_name) + ":" + _toDisplayString($setup.tt('not submitted')), 1 /* TEXT */),
          ($setup.showCreate)
            ? (_openBlock(), _createBlock(_component_ElButton, {
                key: 0,
                type: "primary",
                link: "",
                onClick: $setup.createDiary
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.tt('+Add')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}