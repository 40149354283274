import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9b0d3bd4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["hidden"]
const _hoisted_2 = ["hidden"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "main",
      hidden: $props.hidden
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 8 /* PROPS */, _hoisted_1),
    _createElementVNode("div", {
      class: "resize",
      hidden: $props.hidden,
      onPointerdown: _cache[0] || (_cache[0] = (...args) => ($setup.detailBegin && $setup.detailBegin(...args))),
      onPointermove: _cache[1] || (_cache[1] = (...args) => ($setup.detailMove && $setup.detailMove(...args))),
      onPointerup: _cache[2] || (_cache[2] = (...args) => ($setup.detailEnd && $setup.detailEnd(...args))),
      onPointercancel: _cache[3] || (_cache[3] = (...args) => ($setup.detailEnd && $setup.detailEnd(...args))),
      onTouchmove: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
    }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_2)
  ], 64 /* STABLE_FRAGMENT */))
}