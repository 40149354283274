import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-87e12a9f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "root" }
const _hoisted_2 = ["href", "title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: $setup.href,
      onClick: $setup.click,
      title: $setup.value
    }, _toDisplayString($setup.value), 9 /* TEXT, PROPS */, _hoisted_2),
    ($setup.rowAction)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.rowAction), {
          key: 0,
          onDelete: $setup.del,
          value: $setup.data,
          treeParent: $setup.treeParent
        }, null, 40 /* PROPS, HYDRATE_EVENTS */, ["value", "treeParent"]))
      : _createCommentVNode("v-if", true)
  ]))
}