import { Filter } from '../GlobalView/types';


function get(filter: [string, string, string, any] | Filter) {
	if (Array.isArray(filter)) { return filter; }
	const { doctype, field, condition, value } = filter;
	return [doctype, field, condition, value];
}
export function getFilterItemSearch(
	filter: [string, string, string, any] | Filter,
	dt?: string,
	withSession?: boolean
): string {
	const [doctype, field, con, any] = get(filter);
	const df = doctype && doctype !== dt ? `${doctype}.${field}` : field;
	if (!withSession || con !== 'session') {
		const value = con === '=' || !con ? any : JSON.stringify([con, any]);
		return `${df}=${encodeURIComponent(value)}`;
	}
	if (typeof any !== 'string' || !any) { return ''; }
	const value = frappe.boot.user.defaults[frappe.scrub(any)];
	if (typeof value !== 'string' || !value) { return ''; }
	return `${df}=${encodeURIComponent(value)}`;
}
function addId(s: string[]) {
	const nextIds = new Map<string, number>();

	return s.map(v => {
		const index = v.indexOf('=');
		if (index < 0) { return v; }
		const k = v.slice(0, index);
		const id = nextIds.get(k) || 0;
		nextIds.set(k, id + 1);
		if (!id) { return v; }
		return `${id}*${v}`;
	});
}

export default function getFiltersSearch(
	dt: string,
	filters: ([string, string, string, any] | Filter)[],
	orFilters: ([string, string, string, any] | Filter)[][],
	withSession?: boolean
) {
	return [
		addId(filters.map(v => getFilterItemSearch(v, dt, withSession))),
		...orFilters.map((v, k) => addId(v.map(v => getFilterItemSearch(v, dt, withSession)))
			.map(r => r ? `${k}:${r}` : r)),
	].flat().filter(Boolean);
}
