frappe.provide('frappe.guigu');
export default function defineView(...p: Parameters<frappe.guigu.defineView>) {
	const { defineView } = frappe.guigu;
	if (defineView) {
		defineView(...p);
	} else if (!frappe.guigu.views) {
		frappe.guigu.views = [p];
	} else {
		frappe.guigu.views.push(p);
	}
}
