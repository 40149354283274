export default function usePointerCapture<T>(
	downFn: (event: PointerEvent) => T,
	moveFn: (event: PointerEvent, value: T) => void,
): [
		(event: PointerEvent) => void,
		(event: PointerEvent) => void,
		(event: PointerEvent) => void,
	] {
	let state: any;
	let captured = false;
	function begin(event: PointerEvent) {
		if (captured) {
			return;
		}
		state = downFn(event);
		(event.currentTarget as Element).setPointerCapture(event.pointerId);
		captured = true;
	}
	function move(event: PointerEvent) {
		const el = event.currentTarget as Element;
		if (!el.hasPointerCapture(event.pointerId)) {
			return;
		}
		moveFn(event, state);
	}
	function end(event: PointerEvent) {
		const {pointerId} = event;
		try {
			const el = event.currentTarget as Element;
			el.releasePointerCapture(pointerId);
		} catch (event) {
			console.error(event);
		}
		captured = false;
	}
	return [begin, move, end];
}
