import { createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["ElForm"], {
      class: "form",
      ref: "formRef",
      "label-position": "top",
      model: $setup.form,
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
      rules: $setup.rules
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElFormItem"], {
          label: $setup.tt('Templates'),
          prop: "content"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElInput"], {
              type: "textarea",
              autosize: "",
              rows: 4,
              class: "textarea",
              modelValue: $setup.form.content,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.form.content) = $event)),
              placeholder: $setup.tt('Please enter the template'),
              resize: "vertical",
              onChange: $setup.changeContent
            }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElFormItem"], {
          label: $setup.tt('Reference field')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElTable"], {
              data: $setup.fields,
              height: "300"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["ElTableColumn"], {
                  prop: "label",
                  formatter: $setup.formatter,
                  label: $setup.tt('Name')
                }, null, 8 /* PROPS */, ["label"]),
                _createVNode($setup["ElTableColumn"], {
                  prop: "fieldname",
                  label: $setup.tt('Identification'),
                  formatter: $setup.fieldnameFormatter
                }, null, 8 /* PROPS */, ["label"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["data"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["model", "rules"])
  ]))
}