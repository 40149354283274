import {createApp} from 'vue';

import App from './index.vue';

function renderDwLibraryList(wrapper: HTMLElement) {
	const app = createApp(App, {});
	app.mount(wrapper);
}

frappe.provide('frappe.dw_worklist_management');
frappe.dw_worklist_management.pages = frappe.dw_worklist_management.pages || {};
frappe.dw_worklist_management.pages.renderDwLibraryList = renderDwLibraryList;
