
<template>
	<Page>
		<template #title>
			<h3 class="title">{{ tt('Dw Organization Process Setting') }}</h3>
		</template>
		<template #sider>
			<Side
				v-loading="organizationLoading"
				:organizationList="organizationList"
				v-model:organization="organization"
			></Side>
		</template>
		<div v-loading="loading">
			<Tools
				:relatePermission="relatePermission"
				@create-relation="createRelation"
			></Tools>
			<Skeleton
				:layout="layoutType"
				doctype="Dw Process"
				:detailName="detailName"
				@onChange="onChange"
				v-model:form-visible="formVisible"
			>
				<Table
					ref="aggrid"
					class="table-container"
					:smallMeta="smallMeta"
					:dataList="dataList"
					@showDetail="showDetail"
					@unLink="unLink"
					:relatePermission="relatePermission"
				></Table>
			</Skeleton>
		</div>
		<RelateDialog
			v-model:visible="visible"
			:processList="processList"
			:organizationProcessList="organizationProcessList"
			@relate="relate"
		></RelateDialog>
	</Page>
</template>

<script setup lang="ts">
import {computed, onMounted, onUnmounted, ref, watch} from 'vue';
import {vLoading} from 'element-plus';

import Page from '../../../../../../../guigu/guigu/public/js/components/page/index.vue';
import {useMetaQuery} from '../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';
import Skeleton from '../../../component/Skeleton/index.vue';
import {isManager} from '../../../utils';
import type {ChangeValue} from '../../../component/FormDetail/type';

import Table from './Table.vue';
import type {LevelStyle, Organization, Process, OrganizationProcess, OrganizationProcessData} from './type';
import Side from './Side.vue';
import Tools from './Tools.vue';
import RelateDialog from './RelateDialog.vue';
const aggrid = ref<InstanceType<typeof Table> | null>(null);
const layoutType = ref<'modal'|'link'|'bottom'|'right'>('modal');
const smallMeta = useMetaQuery();
const loading = ref<boolean>(true);
const organizationLoading = ref<boolean>(true);
const organizationList = ref<Organization[]>([]);
const organization = ref<string>(frappe.tianjy.organization.getCurrent());
const formVisible = ref<boolean>(false);
const detailName = ref<string>('');
const organizationProcessList = ref<OrganizationProcess[]>([]);
const processList = ref<Process[]>([]);
const visible = ref<boolean>(false);
const relatePermission = ref<boolean>(false);
const tt = __;

function createRelation() {
	visible.value = true;
}
async function relate(data:Process) {
	loading.value = true;
	visible.value = false;
	const res = await frappe.db.insert({
		doctype: 'Dw Organization Process Setting',
		organization: organization.value,
		dw_process: data.name,
	});
	organizationProcessList.value.push(res);
	loading.value = false;
}
async function unLink(data:OrganizationProcessData) {
	try {
		loading.value = true;
		await frappe.db.delete_doc('Dw Organization Process Setting', data.organization_process);
		const index = organizationProcessList.value.findIndex(item=>item.name === data.organization_process);
		organizationProcessList.value.splice(index, 1);
		loading.value = false;
	} catch (error:any) {
		// eslint-disable-next-line no-console
		console.log(error);
		loading.value = false;
	}
}
// 流程弹窗显示
function showDetail(data:OrganizationProcessData) {
	if (layoutType.value === 'link') {
		frappe.set_route(['Form', 'Dw Process', data.name]);
		return;
	}
	detailName.value = data.name;
	formVisible.value = true;
}

function onChange(returnData:ChangeValue) {
	const {type, data} = returnData;
	switch (type) {
		case 'update':{
			const processIndex = processList.value.findIndex(item=>item.name === data.name);
			if (data.parent_dw_process) {
				organizationProcessList.value = organizationProcessList.value.filter(item=>item.dw_process !== data.name);
				processList.value.splice(processIndex, 1);
			} else {
				processList.value[processIndex].full_process_code = data.full_process_cod;
				processList.value[processIndex].process_name = data.process_name;
			}
			break;
		}
		case 'delete': {
			const processIndex = processList.value.findIndex(item=>item.name === data.name);
			processList.value.splice(processIndex, 1);
			organizationProcessList.value = organizationProcessList.value.filter(item=>item.dw_process !== data.name);
			break;
		}
	}
}

async function getOrganizationWorkSetting() {
	loading.value = true;
	const res = await frappe.db.get_list<OrganizationProcess>('Dw Organization Process Setting', {
		limit: 0,
		fields: ['*'],
		filters: [
			['organization', '=', organization.value],
		],
	});
	organizationProcessList.value = res;
	loading.value = false;
}
const dataList = computed(()=>processList.value.filter(pro=>organizationProcessList.value.some(setting=>setting.dw_process === pro.name)).map(process=>{
	const organizationProcess = organizationProcessList.value.find(op=>op.dw_process === process.name);
	if (!organizationProcess) {
		return;
	}
	return {
		...process,
		organization_process: organizationProcess.name,
		label: `${process?.full_process_code} ${process.process_name}`,
		relate_date: organizationProcess.creation,
	};
}).filter(Boolean));

function getSearchParams() {
	const params = new URLSearchParams(window.location.search);
	if (params.get('organization')) {
		organization.value = params.get('organization') || frappe.tianjy.organization.getCurrent();
	}
}
getSearchParams();
$(frappe.pages.guigu_leader_work).on('show', function() {
	getSearchParams();
});
// 更新URL路径
function updateUrl() {
	const params = new URLSearchParams(window.location.search);
	params.set('organization', organization.value);
	history.replaceState(null, '', `?${params.toString()}`);
}
watch(
	[organization],
	async () => {
		updateUrl();
		getOrganizationWorkSetting();
	},
	{immediate: true},
);


async function getOrganizations() {
	organizationLoading.value = true;
	const res = await frappe.db.get_list<Organization>('Tianjy Organization', {
		limit: 0,
		fields: ['*'],
	});
	organizationList.value = res;
	organizationLoading.value = false;
}
async function getProcessList() {
	const res = await frappe.db.get_list<Process>('Dw Process', {
		limit: 0,
		fields: ['*'],
		filters: [
			['parent_dw_process', 'is', 'not set'],
		],
	});
	processList.value = res;
}

async function getPermission() {
	if (frappe.session.user === 'Administrator' || frappe.user.has_role('System Manager')) {
		relatePermission.value = true;
		return;
	}
	const isManagerPermission = await isManager();
	relatePermission.value = isManagerPermission;
}
onMounted(() => {
	getOrganizations();
	getProcessList();
	getPermission();
	window.addEventListener('popstate', popstateListener);
});
function popstateListener() {
	getOrganizations();
	getProcessList();
	getOrganizationWorkSetting();
}
onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});
</script>
<style lang="less" scoped>
  .title {
    height: 75px;
    line-height: 75px;
    margin-bottom: 0;
  }
  .table-container {
    height: 100%;
	overflow: auto;
  }
</style>
