
import { ref, type Ref } from 'vue';
const isSilentModeRef = ref<boolean>(false);
const hooks: ((isSilentModeRef: boolean) => void)[] = [];

export default function watchMode(
	obj: Record<string, any>,
	key: string,
	func: (isSilentModeRef: boolean) => void,
) {
	const watchedMap: Map<object, string[]> = new Map();
	hooks.push(func);
	if (watchedMap.has(obj)) {
		const arr = watchedMap.get(obj);
		if (arr && arr.includes(key)) {
			return;
		}
	}
	if (!watchedMap.has(obj)) {
		watchedMap.set(obj, new Array);
	}

	const array = watchedMap.get(obj)!;

	if (!array.includes(key)) {
		array.push(key);
	}

	Object.defineProperty(obj, key, {
		set(value) {
			isSilentModeRef.value = value;
			for (const fun of hooks) { fun(isSilentModeRef.value); }
		},
		// get() {
		// 	return isSilentModeRef.value;
		// },
	});

}
