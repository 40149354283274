
<template>
	<div class="number">
		{{formatValue}}
	</div>
 </template>
<script lang="ts" setup>
 import {defineProps} from 'vue';
 const props = defineProps<{params:any}>();
 // eslint-disable-next-line eqeqeq
 const formatValue = props.params.value==null||props.params.value==undefined
 	?''
	:parseFloat(parseFloat(String(props.params.value)).toFixed(0)).toLocaleString();
 </script>

<style scoped>
.number {
	text-align: right;
}
</style>
