import { createVNode as _createVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8a87b16c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElDatePicker = _resolveComponent("ElDatePicker")
  const _component_ElButton = _resolveComponent("ElButton")
  const _component_ElCheckbox = _resolveComponent("ElCheckbox")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["ProjectCom"], {
      default: "",
      noLabel: "",
      modelValue: $setup.selectedProject,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.selectedProject) = $event))
    }, null, 8 /* PROPS */, ["modelValue"]),
    _createVNode(_component_ElDatePicker, {
      class: "input date-input",
      modelValue: $setup.dateRange,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.dateRange) = $event)),
      type: "daterange",
      unlinkPanels: "",
      startPlaceholder: $setup.tt('Start Date'),
      endPlaceholder: $setup.tt('End Date'),
      shortcuts: $setup.shortcuts,
      valueFormat: "YYYY-MM-DD"
    }, null, 8 /* PROPS */, ["modelValue", "startPlaceholder", "endPlaceholder"]),
    _createVNode(_component_ElButton, {
      class: "input sort-btn",
      type: $setup.sort === 'asc' ? 'primary' : 'default',
      onClick: $setup.clickSort
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($setup.tt('Time ASC')), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["type"]),
    _createVNode(_component_ElCheckbox, {
      class: "checkbox input",
      modelValue: $setup.isIncludeChildren,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.isIncludeChildren) = $event)),
      label: $setup.tt('Include photos of descendants')
    }, null, 8 /* PROPS */, ["modelValue", "label"]),
    _createVNode($setup["ZipDownload"], {
      class: "input",
      files: $setup.zipFiles,
      zipName: $props.structureOrProgress.label
    }, null, 8 /* PROPS */, ["files", "zipName"])
  ]))
}