<template>
	<TimelineItem
		:date="smallMeta && dailyRecords.left === 'show' ? dailyRecords.date : ''"
		v-bind="$attrs">
		<template #leftTitle
			v-if="dailyRecords.left === 'show' && !smallMeta">
			{{ dailyRecords.date }}
		</template>
		<template #timeName>
			<DiaryLabel :dailyRecords="dailyRecords" :filterOrganization="filterOrganization">
			</DiaryLabel>
		</template>
		<DiaryDetail
			v-if="dailyRecords.data"
			:searchString="searchString"
			:showComment="showComment"
			@show-daily-record="v=>emit('showDailyRecord', v)"
			:dailyRecord="dailyRecords.data">
		</DiaryDetail>
	</TimelineItem>
</template>
<script lang="ts" setup>
import {defineProps, toRefs, defineEmits, computed} from 'vue';

import TimelineItem from '../../../../../../../guigu_pm/guigu_pm/public/js/components/timeline/TimelineItem.vue';

import DiaryDetail from './DiaryDetail.vue';
import DiaryLabel from './DiaryLabel.vue';
import {DiaryRecordB, TimelineList, TimelineSource} from './type';
import {useMetaQuery} from './useMetaQuery';
interface Props {
	dailyRecords: TimelineList,
	filterOrganization?: string,
	searchString?: string
	showComment: boolean
}
const smallMeta = useMetaQuery();

const props = defineProps<Props>();
interface Emits {
	(e: 'showDailyRecord', name: string): void
}
const emit = defineEmits<Emits>();

const {dailyRecords} = toRefs(props);
</script>
<style lang="less" scoped>
</style>
