<template>
	<div class="report-info_label">6. {{ tt("Scene Actual Photo") }}</div>
	<div v-viewer>
		<ImageItem
			v-for="photo in images"
			:photo="photo"
		></ImageItem>
	</div>
</template>

<script setup lang='ts'>
import { ref, defineProps, defineEmits } from 'vue';

import { Image } from '../type';

import ImageItem from './ImageItem.vue';
const tt = __
interface Props{
	images:Image[]
}
const props = defineProps<Props>();

</script>

<style lang='less' scoped>

</style>
