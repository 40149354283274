import {createApp, h, ref} from 'vue';

import {createTianjyEditorPlugin} from './instruction/blocks';
import Instruction from './instruction/instruction/index.vue';

frappe.provide('frappe.dw_worklist_management.common');
frappe.dw_worklist_management.common.render_instruction = function(
	instructionName:string,
	editable:boolean,
	referenceType:string,
	referenceName:string,
	options?:{
		title?:string
		addButtonText?:string
		emptyText?:string
	},
) {
	const editableRef = ref<boolean>(editable);
	const instructionRef = ref<string>(instructionName);
	const referenceNameRef = ref<string>(referenceName);
	const app = createApp({props: [], render: () =>h(Instruction, {
		instructionName: instructionRef.value,
		editable: editableRef.value,
		referenceType,
		referenceName: referenceNameRef.value,
		mode: 'show',
		options,
	})});
	const plugin = createTianjyEditorPlugin(referenceType);
	app.use(plugin);
	function setInstruction(name:string) {
		instructionRef.value = name;
	}
	function setEditable(editable:boolean) {
		editableRef.value = editable;
	}
	function setReferenceName(name:string) {
		referenceNameRef.value = name;
	}
	return {app, editableRef, instructionRef, setInstruction, setEditable, setReferenceName};
};
