import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14d12dad"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["Page"], { siderStyle: { width: '620px' } }, {
      title: _withCtx(() => [
        _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.tt('Organizational personnel')), 1 /* TEXT */)
      ]),
      sider: _withCtx(() => [
        _createVNode($setup["Users"], {
          loading: $setup.loading,
          "onUpdate:loading": _cache[0] || (_cache[0] = $event => (($setup.loading) = $event)),
          modelValue: $setup.user,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.user) = $event)),
          permissions: $setup.userPermissions
        }, null, 8 /* PROPS */, ["loading", "modelValue", "permissions"])
      ]),
      default: _withCtx(() => [
        _createVNode($setup["ElTabs"], {
          modelValue: $setup.activeName,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.activeName) = $event)),
          class: "user-tabs"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElTabPane"], {
              class: "tab-container",
              label: $setup.tt('Basic Information'),
              name: "info"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["FormDetail"], {
                  name: $setup.user?.name,
                  doctype: "User"
                }, null, 8 /* PROPS */, ["name"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode($setup["ElTabPane"], {
              class: "tab-container",
              label: $setup.tt('Organization'),
              name: "organization"
            }, {
              default: _withCtx(() => [
                ($setup.user)
                  ? (_openBlock(), _createBlock($setup["Organization"], {
                      key: 0,
                      type: "organization",
                      permissions: $setup.memberPermissions,
                      user: $setup.user.name,
                      allOrganizationList: $setup.organizationList,
                      onRefresh: $setup.getOrganizations
                    }, null, 8 /* PROPS */, ["permissions", "user", "allOrganizationList"]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode($setup["ElTabPane"], {
              class: "tab-container",
              label: $setup.tt('Inheritance organization'),
              name: "inherit_organization"
            }, {
              default: _withCtx(() => [
                ($setup.user)
                  ? (_openBlock(), _createBlock($setup["Organization"], {
                      key: 0,
                      type: "inherit",
                      permissions: $setup.memberPermissions,
                      user: $setup.user.name,
                      allOrganizationList: $setup.organizationList,
                      onRefresh: $setup.getOrganizations,
                      onLoading: _cache[2] || (_cache[2] = $event => ($setup.loading = true))
                    }, null, 8 /* PROPS */, ["permissions", "user", "allOrganizationList"]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    })
  ])), [
    [$setup["vLoading"], $setup.loading]
  ])
}