<template>
	<ElFormItem :label="tt('Is Folder Field')">
		<ElSelect v-model="is_folder_field" filterable defaultFirstOption clearable>
			<ElOption v-for="field in checkOptions" :key="field.value"
				:value="field.value" :label="field.label" />
		</ElSelect>
	</ElFormItem>
	<ElFormItem :label="tt('File Name Field')">
		<ElSelect v-model="file_name_field" filterable defaultFirstOption clearable>
			<ElOption v-for="field in textOptions" :key="field.value"
				:value="field.value" :label="field.label" />
		</ElSelect>
	</ElFormItem>
	<ElFormItem :label="tt('Folder Name Field')">
		<ElSelect v-model="folder_name_field" filterable defaultFirstOption clearable>
			<ElOption v-for="field in textOptions" :key="field.value"
				:value="field.value" :label="field.label" />
		</ElSelect>
	</ElFormItem>
	<ElFormItem :label="tt('Parent Field')">
		<ElSelect v-model="parent_field" filterable defaultFirstOption clearable>
			<ElOption v-for="field in linkOptions" :key="field.value"
				:value="field.value" :label="field.label" />
		</ElSelect>
	</ElFormItem>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { ElSelect, ElFormItem } from 'element-plus';

const props = defineProps<{
	meta: locals.DocType;
	modelValue: any;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: GlobalView.View): void;
}>();
const cfg = computed({
	get: () => props.modelValue,
	set: v => emit('update:modelValue', v),
});
const tt = __;

const is_folder_field = computed<string>({
	get: () => cfg.value.is_folder_field || '',
	set: v => { cfg.value = { ...cfg.value, is_folder_field: v || null }; },
});

const file_name_field = computed<string>({
	get: () => cfg.value.file_name_field || '',
	set: v => { cfg.value = { ...cfg.value, file_name_field: v || null }; },
});
const folder_name_field = computed<string>({
	get: () => cfg.value.folder_name_field || '',
	set: v => { cfg.value = { ...cfg.value, folder_name_field: v || null }; },
});


const parent_field = computed<string>({
	get: () => cfg.value.parent_field || '',
	set: v => { cfg.value = { ...cfg.value, parent_field: v || null }; },
});


const fields = computed(() => props.meta.fields || []);


const checkOptions = computed(() => fields.value.filter(d => ['Check'].includes(d.fieldtype))
	.map(d => ({
		value: d.fieldname,
		label: `${__(d.label)} (${d.fieldname})`,
	})));
const textType = new Set(['Data', 'Text', 'Small Text', 'Long Text', 'Text Editor']);
const textOptions = computed(() => fields.value.filter(d => textType.has(d.fieldtype))
	.map(d => ({
		value: d.fieldname,
		label: `${__(d.label)} (${d.fieldname})`,
	})));
const linkOptions = computed(() => fields.value.filter(d => ['Link'].includes(d.fieldtype))
	.map(d => ({
		value: d.fieldname,
		label: `${__(d.label)} (${d.fieldname})`,
	})));
</script>
