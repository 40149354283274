<template>
	<div class="timeline-container" ref="el" v-if="mode !== 'right'">
		<div class="manageContainer">
			<div class="manageContent noBorder">
				<div :class="{ 'timeLine': true }">
					<div v-viewer>
						<slot :mode="mode"></slot>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="timeline-container" ref="el" v-else>
		<div class="manageContainer">
			<div class="manageContent noBorder">
				<div :class="{ 'timeLine': true }">
					<div v-viewer>
						<slot :mode="mode"></slot>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useInfiniteScroll } from '@vueuse/core';
interface Props {
	mode?: 'left' | 'right'
	leftWidth?: string
}
const props = defineProps<Props>();
const leftWidth = computed(() => props.leftWidth || '100px');

const el = ref<HTMLElement>();
interface Emit {
	(e: 'loadMore'): void
}
const emit = defineEmits<Emit>();
defineExpose<{ readonly el?: HTMLElement }>({ get el() { return el.value; } });

useInfiniteScroll(
	el,
	() => {
		// load more
		emit('loadMore');
	},
	{ distance: 10 },
);
</script>
<style lang="less" scoped>
.timeline-container {
	height: 100%;
	overflow-y: scroll;
}

.manageContainer {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	height: 100%;

	.manageContent {
		&.noBorder {
			border-left: none;
		}
	}
}

.timeLine {
	display: block;
}

.left-margin {
	margin-left: v-bind(leftWidth);
}
</style>
