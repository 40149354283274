
export default function deleteSelected(doctype: string, docnames: string[], done: () => void) {

	frappe.call<{ message?: string[], _server_messages?: string }>({
		method: 'frappe.desk.reportview.delete_items',
		freeze: true,
		args: { items: docnames, doctype },
	}).then(r => {
		let failed = r?.message;
		if (!failed) { failed = []; }

		if (failed.length && !r?._server_messages) {
			frappe.throw(
				__('Cannot delete {0}', [failed.map(f => f.bold()).join(', ')])
			);
		}
		if (failed.length < docnames.length) {
			frappe.utils.play_sound('delete');
			done();
		}
	});

}
