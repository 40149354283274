<template>
	<div class="header-container">
		<div class="left-container">
			<div>
				<span>{{ tt('Assessor') }}:</span>
				<el-select
					@change="changeSelectLeader"
					filterable
					:disabled="select_leader_disabled"
					:modelValue="assessInfo?.select_leader_doc?.name"
				>
					<el-option
						v-for="user in selectLeaderList"
						:label="user.full_name"
						:value="user.name"
					/>
				</el-select>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import type * as Permission from '../../Permission';
import type { AssessInfo } from '../type';

const tt = __;
interface Props {
	assessInfo?: AssessInfo;
	type: string;
	detailPermission: Permission.DocPermission | null;
	assessUserPermission: Permission.UserPermission;
	smallMeta: boolean;
	lockStatus: boolean;
}
const props = defineProps<Props>();
interface Emit {
	(e: 'update'): void;
}
const emit = defineEmits<Emit>();
const selectLeaderList = ref<{ name: string; full_name: string }[]>([]);

const select_leader_disabled = computed(() => {
	if (props.lockStatus) {
		return true;
	}
	if (Boolean(props.assessInfo?.assess_name)) {
		return (
			props.assessInfo?.leader_confirm_date ||
			!props.assessUserPermission.writable.has('select_leader')
		);
	}
	return !(
		props.assessUserPermission.creatable &&
		props.assessUserPermission.writable.has('select_leader')
	);
});
watch(
	() => props.assessInfo,
	() => {
		getSelectLeaderList();
	},
	{ immediate: true },
);

async function getSelectLeaderList() {
	if (!props.assessInfo) {
		return;
	}
	const filters: [string, string, any][] = [];
	if (props.type === 'project') {
		filters.push(['project', '=', props.assessInfo.department_doc?.name]);
	} else {
		filters.push([
			'sub_company',
			'=',
			props.assessInfo.department_doc?.name,
		]);
	}
	const userMemberType =
		props.type === 'project'
			? 'Guigu Project Member'
			: 'Guigu Sub Company Member';
	const members = await frappe.db.get_list(userMemberType, {
		filters,
		fields: ['user'],
		limit: 0,
	});
	if (members.length === 0) {
		selectLeaderList.value = [];
		return;
	}
	const res = await frappe.db.get_list<{ name: string; full_name: string }>(
		'User',
		{
			filters: [['name', 'in', members.map(item => item.user)]],
			fields: ['name', 'full_name'],
			limit: 0,
		},
	);
	selectLeaderList.value = res || [];
}

async function changeSelectLeader(v: string) {
	if (!props.assessInfo) {
		return;
	}
	let url =
		'guigu_hr.guigu_hr_assessment.page.guigu_hr_week_assessment_detail.guigu_hr_week_assessment_detail.update_assess';
	const args: any = {
		name: props.assessInfo?.assess_name,
		field: 'select_leader',
		value: v,
		type: props.type,
	};
	if (!props.assessInfo?.assess_name) {
		url =
			'guigu_hr.guigu_hr_assessment.page.guigu_hr_week_assessment_detail.guigu_hr_week_assessment_detail.create_assess';
		args.date = props.assessInfo.date;
		args.user = props.assessInfo.user_doc.name;
		args.type = props.type;
		args.select_leader = v;
		args.department = props.assessInfo.department_doc.name;
	}
	await frappe.call({
		method: url,
		args: args,
	});
	emit('update');
}
const flexDirection = computed(() => (props.smallMeta ? 'column' : 'row'));
const margin = computed(() => (props.smallMeta ? '8px' : '0'));
</script>

<style lang="less" scoped>
.header-container {
	display: flex;
	flex-direction: v-bind(flexDirection);
	justify-content: space-between;
	margin-bottom: 8px;
	span {
		margin-right: 16px;
		font-weight: bolder;
	}
	.left-container > div {
		display: inline-block;
		margin-right: 16px;
		margin-bottom: v-bind(margin);
	}
}
</style>
