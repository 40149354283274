<template>
	{{ time }}
</template>

<script setup lang='ts'>
import { computed } from 'vue';

interface Props {
	data: Record<string, any>;
	field: GlobalView.FieldRenderOptions;
	value: any
	format?: string;

}

const props = defineProps<Props>();

const time = computed(() => moment(props.value, 'HH:mm:ss').format('HH:mm:ss'));
</script>

<style lang='less' scoped>
</style>
