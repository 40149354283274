import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElOption = _resolveComponent("ElOption")
  const _component_ElSelect = _resolveComponent("ElSelect")

  return (_openBlock(), _createBlock(_component_ElSelect, {
    size: "small",
    modelValue: _ctx.selected,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.selected) = $event)),
    onChange: _ctx.stopEditing,
    placeholder: _ctx.tt('Please Select')
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectOptions, (option) => {
        return (_openBlock(), _createBlock(_component_ElOption, {
          key: option.value,
          value: option.value,
          label: option.label
        }, null, 8 /* PROPS */, ["value", "label"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "onChange", "placeholder"]))
}