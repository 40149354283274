import { renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22242d45"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "skeleton-body",
  ref: "body"
}
const _hoisted_2 = { class: "maindata" }
const _hoisted_3 = { class: "main" }
const _hoisted_4 = { class: "data" }
const _hoisted_5 = { class: "pagination" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['layout', $props.classes.skeletonClass])
  }, [
    _createVNode($setup["Sider"], {
      siderClass: $props.classes.siderClass,
      show: $setup.showSidebar,
      "onUpdate:show": _cache[0] || (_cache[0] = $event => (($setup.showSidebar) = $event)),
      size: $setup.resizeRider,
      "onUpdate:size": _cache[1] || (_cache[1] = $event => (($setup.resizeRider) = $event)),
      hidden: !$setup.hasSider || !$setup.showSidebar
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "sider", {}, undefined, true)
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["siderClass", "show", "size", "hidden"]),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode(" 工具栏 "),
      _createElementVNode("div", {
        class: _normalizeClass(['toolbar', $props.classes.toolbarClass])
      }, [
        ($setup.hasFilterField)
          ? (_openBlock(), _createBlock($setup["SidebarToggleBtn"], {
              key: 0,
              modelValue: $setup.showFilterField,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.showFilterField) = $event))
            }, null, 8 /* PROPS */, ["modelValue"]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", {
          class: _normalizeClass(['toolbox', { ['toolbox-hidden']: $setup.toolBoxHidden }]),
          onClick: _cache[4] || (_cache[4] = _withModifiers($event => ($setup.toolBoxHidden = true), ["self"]))
        }, [
          _createElementVNode("div", null, [
            _createVNode($setup["ElButton"], {
              class: "toolbox-close",
              circle: "",
              icon: $setup.CloseBold,
              title: $setup.tt('Close'),
              onClick: _cache[3] || (_cache[3] = $event => ($setup.toolBoxHidden = true))
            }, null, 8 /* PROPS */, ["icon", "title"]),
            _renderSlot(_ctx.$slots, "toolbar", {}, undefined, true)
          ])
        ], 2 /* CLASS */),
        _createVNode($setup["ElButton"], {
          class: "toolbox-btn",
          title: $setup.tt('More'),
          icon: $setup.MoreFilled,
          onClick: _cache[5] || (_cache[5] = $event => ($setup.toolBoxHidden = !$setup.toolBoxHidden))
        }, null, 8 /* PROPS */, ["title", "icon"]),
        _createElementVNode("div", {
          class: _normalizeClass(['actions', $props.classes.actionClass])
        }, [
          _renderSlot(_ctx.$slots, "action", {}, undefined, true)
        ], 2 /* CLASS */)
      ], 2 /* CLASS */),
      _createElementVNode("div", _hoisted_2, [
        ($setup.hasFilterField)
          ? (_openBlock(), _createBlock($setup["FilterField"], {
              key: 0,
              modelValue: $setup.resizeFilterField,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($setup.resizeFilterField) = $event)),
              hidden: !$setup.showFilterField
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "filterField", {}, undefined, true)
              ]),
              _: 3 /* FORWARDED */
            }, 8 /* PROPS */, ["modelValue", "hidden"]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", {
          class: _normalizeClass({ ['context']: true, ['skeleton-row']: $setup.row })
        }, [
          _createCommentVNode(" 主区域栏 "),
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "pagination", {}, undefined, true)
            ])
          ])), [
            [$setup["vLoading"], $props.loading || false]
          ]),
          _createCommentVNode(" 详情区 "),
          ($setup.hasDetail)
            ? (_openBlock(), _createBlock($setup["Detail"], {
                key: 0,
                columnSize: $setup.resizeDetailColumn,
                "onUpdate:columnSize": _cache[7] || (_cache[7] = $event => (($setup.resizeDetailColumn) = $event)),
                rowSize: $setup.resizeDetailRow,
                "onUpdate:rowSize": _cache[8] || (_cache[8] = $event => (($setup.resizeDetailRow) = $event)),
                model: $setup.modal,
                row: $setup.row
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "detail", {}, undefined, true)
                ]),
                _: 3 /* FORWARDED */
              }, 8 /* PROPS */, ["columnSize", "rowSize", "model", "row"]))
            : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */)
      ])
    ], 512 /* NEED_PATCH */)
  ], 2 /* CLASS */))
}