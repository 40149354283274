<template>
	<ElSelect v-model="cond" :teleported="false">
		<template v-for="c in conditions" :key="c.value">
			<ElOption
				v-if="validConditions.has(c.value)"
				:label="c.label"
				:value="c.value" />
		</template>
	</ElSelect>
</template>
<script lang="ts" setup>
import { computed, watch } from 'vue';
import { ElSelect, ElOption } from 'element-plus';


const props = defineProps<{
	type?: string;
	options?: string;
	modelValue?: string;
	withSession?: boolean;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', modelValue: string): void;
}>();

const cond = computed({
	get: () => props.modelValue || '=',
	set: cond => emit('update:modelValue', cond),
});

interface Condition {
	value: string;
	label: String;
}
const conditions: Condition[] = [
	{ value: '=', label: __('Equals') },
	{ value: '!=', label: __('Not Equals') },
	{ value: 'like', label: __('Like') },
	{ value: 'not like', label: __('Not Like') },
	{ value: 'in', label: __('In') },
	{ value: 'not in', label: __('Not In') },
	{ value: 'is', label: __('Is') },
	{ value: '>', label: '>' },
	{ value: '<', label: '<' },
	{ value: '>=', label: '>=' },
	{ value: '<=', label: '<=' },
	{ value: 'Between', label: __('Between') },
	{ value: 'Timespan', label: __('Timespan') },
	{ value: 'descendants of', label: __('Descendants Of') },
	{ value: 'not descendants of', label: __('Not Descendants Of') },
	{ value: 'ancestors of', label: __('Ancestors Of') },
	{ value: 'not ancestors of', label: __('Not Ancestors Of') },
	{ value: 'session', label: __('Session Defaults') },
];
const nestedSetConditions = [
	'descendants of', 'not descendants of', 'ancestors of', 'not ancestors of',
];
const defaultConditions = new Set([
	'=', '!=',
	'like', 'not like',
	'in', 'not in',
	'is',
	'>', '<', '>=', '<=',
	'Between', 'Timespan',
]);

const conditionMap: Record<string, string[]> = {
	Date: ['=', '!=', 'in', 'not in', 'is', '>', '<', '>=', '<=', 'Between', 'Timespan'],
	Datetime: ['=', '!=', 'in', 'not in', 'is', '>', '<', '>=', '<=', 'Between', 'Timespan'],
	Data: ['=', '!=', 'like', 'not like', 'in', 'not in', 'is', '>', '<', '>=', '<='],
	Select: ['=', '!=', 'in', 'not in', 'is', '>', '<', '>=', '<='],
	Link: ['=', '!=', 'like', 'not like', 'in', 'not in', 'is'],
	Currency: ['=', '!=', 'like', 'not like', 'in', 'not in', 'is', '>', '<', '>=', '<='],
	Color: ['=', '!=', 'like', 'not like', 'in', 'not in', 'is', '>', '<', '>=', '<='],
	Check: ['='],
	Duration: ['=', '!=', 'in', 'not in', 'is', '>', '<', '>=', '<=', 'Between'],
};
const validConditions = computed(() => {
	const { type } = props;
	if (!type) { return defaultConditions; }
	const { options } = props;

	const conditions = conditionMap[type] || defaultConditions;
	const set = new Set(conditions);
	if (type !== 'Link') { return set; }
	const { withSession } = props;
	if (withSession) {
		set.add('session');
	}
	if (!frappe.boot.nested_set_doctypes.includes(options || '')) { return set; }
	for (const c of nestedSetConditions) {
		set.add(c);
	}
	return set;
});
// const filters_config = frappe.boot.additional_filters_config || undefined;
// if (filters_config) {
// 	for (let [key, filter] of Object.entries(filters_config)) {
// 		conditions.push([key, __(filter.label)]);
// 		for (let [fieldtype, list] of Object.keys(invalid_condition_map)) {
// 			if (!filter.valid_for_fieldtypes.includes(fieldtype)) {
// 				list.push(key);
// 			}
// 		}
// 	}
// }


watch(() => props.type, type => {
	if (!type) {
		return;
	}
	if (validConditions.value.has(cond.value)) {
		return;
	}
	if (type === 'Data') {
		cond.value = 'like';
		return;
	}
	if (type === 'Date' || type === 'Datetime') {
		cond.value = 'Between';
		return;
	}
	cond.value = '=';
}, { immediate: true });


</script>
