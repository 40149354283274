<template>
	<ElFormItem label="过滤字段">
		<ElSelect v-model="filterField" filterable defaultFirstOption clearable>
			<ElOption v-for="field in fields" :key="field.value"
				:value="field.value" :label="field.label" />
		</ElSelect>
	</ElFormItem>
	<ElFormItem v-if="showLevelUnfolded" label="默认展开级别">
		<ElInputNumber v-model="filterFieldLevelUnfolded" />
	</ElFormItem>
	<ElFormItem v-if="filterField">
		<template #label>
			<ElCheckbox v-model="filter_field_all_show" label="显示“全选”项" />
		</template>
		<ElInput v-if="filter_field_all_show" v-model="filter_field_all_label"
			placeholder="“全部”标签" />
	</ElFormItem>
	<ElFormItem v-if="filterField">
		<template #label>
			<ElCheckbox v-model="filter_field_null_show" label="显示“空”项" />
		</template>
		<ElInput v-if="filter_field_null_show" v-model="filter_field_null_label"
			placeholder="“空”标签" />
	</ElFormItem>
	<ElFormItem v-if="filterField">
		<template #label>
			<ElCheckbox v-model="filter_field_children_show" label="查询下级数据" />
		</template>
	</ElFormItem>
	<ElFormItem label="过滤条件" v-if="filterFieldDoctype">
		<div class="filterContainer">
			<div class="at">当</div>
			<Filters :doctype="filterFieldDoctype" v-model="filter_field_filters" />
		</div>
		<OrFilters :doctype="filterFieldDoctype" v-model="orFilters" />
	</ElFormItem>
	<ElFormItem label="排序" v-if="filterFieldDoctype">
		<Sorts :doctype="filterFieldDoctype" v-model="filter_field_sorts" />
	</ElFormItem>
</template>
<script setup lang="ts">
import {computed, shallowRef, watch} from 'vue';
import {ElSelect, ElOption, ElCheckbox, ElInput, ElFormItem, ElInputNumber} from 'element-plus';

import OrFilters from './OrFilters.vue';
import Filters from './Filters.vue';
import Sorts from './Sorts.vue';

const props = defineProps<{
	meta: locals.DocType;
	modelValue: any;
	define?: GlobalView;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: GlobalView.View): void;
}>();
const view = computed({
	get: () => props.modelValue,
	set: v => emit('update:modelValue', v),
});

const types = new Set(['Link', 'Tree Select', 'Select', 'Tianjy Related Link']);
const linkTypes = new Set(['Link', 'Tree Select', 'Tianjy Related Link']);
const fields = computed(() => (props.meta.fields || [])
	.filter(d => types.has(d.fieldtype))
	.map(function toSelect(d) {
		return {
			value: d.fieldname,
			label: `${__(d.label)} (${d.fieldname})`,
		};
	}));
const filterField = computed({
	get: () => view.value.filter_field,
	set: v => {
		view.value = {
			...view.value,
			filter_field: v || null,
			filter_field_filters: null,
			filter_field_or_filters: null,
			filter_field_sorts: null,
		};
	},
});

const filter_field_all_show = computed({
	get: () => Boolean(view.value.filter_field_all_show),
	set: v => {
		view.value = {...view.value, filter_field_all_show: v ? 1 : 0};
	},
});

const filter_field_all_label = computed({
	get: () => view.value.filter_field_all_label,
	set: v => {
		view.value = {...view.value, filter_field_all_label: v || null};
	},
});

const filter_field_null_show = computed({
	get: () => Boolean(view.value.filter_field_null_show),
	set: v => {
		view.value = {...view.value, filter_field_null_show: v ? 1 : 0};
	},
});
const filter_field_children_show = computed({
	get: () => Boolean(view.value.filter_field_children_show),
	set: v => {
		view.value = {...view.value, filter_field_children_show: v ? 1 : 0};
	},
});

const filterFieldLevelUnfolded = computed({
	get: () => view.value.filter_field_level_unfolded,
	set: v => {
		view.value = {...view.value, filter_field_level_unfolded: v || 0};
	},
});
const filter_field_null_label = computed({
	get: () => view.value.filter_field_null_label,
	set: v => {
		view.value = {...view.value, filter_field_null_label: v || null};
	},
});
const filter_field_filters = computed({
	get: () => view.value.filter_field_filters,
	set: v => {
		view.value = {...view.value, filter_field_filters: v || null};
	},
});
const orFilters = computed({
	get: () => view.value.filter_field_or_filters,
	set: v => {
		view.value = {...view.value, filter_field_or_filters: v || null};
	},
});
const filter_field_sorts = computed({
	get: () => view.value.filter_field_sorts,
	set: v => {
		view.value = {...view.value, filter_field_sorts: v || null};
	},
});
watch([fields, filterField], ([fields, value]) => {
	if (!value) {
		return;
	}
	if (fields.find(v => v.value === value)) {
		return;
	}
	filterField.value = undefined;
}, {immediate: true});

const filterFieldDoctype = computed(() => {
	const fieldname = filterField.value;
	if (!fieldname) {
		return;
	}
	const field = fieldname && props.meta.fields.find(d => d.fieldname === fieldname);
	if (!field) {
		return;
	}
	if (!['Link', 'Tree Select', 'Tianjy Related Link'].includes(field.fieldtype)) {
		return;
	}
	return field.options;
});
const showLevelUnfolded = shallowRef(false);
const linkDoctype = computed(() => {
	const {value} = filterField;
	if (!value) {
		return false;
	}
	return (props.meta.fields || [])
		.find(d => linkTypes.has(d.fieldtype) && d.fieldname === value)?.options;
});
watch(linkDoctype, async dt => {
	if (!dt) {
		showLevelUnfolded.value = false;
		return;
	}
	const meta = frappe.get_meta(dt);
	if (meta) {
		showLevelUnfolded.value = meta.is_tree;
		return;
	}
	await new Promise<void>(resolve => {
		frappe.model.with_doctype(dt, () => {
			resolve();
		}, true);
	});
	if (dt !== linkDoctype.value) {
		return;
	}
	showLevelUnfolded.value = frappe.get_meta(dt)?.is_tree || false;
}, {immediate: true});
</script>
<style scoped lang="less">
.filterContainer{
	display: flex;
	align-items: center;
	width: 100%;
}
.at{
	padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-inline-end: 8px;
}
</style>
