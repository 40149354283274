import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0fe9ac46"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Page"], null, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.title), 1 /* TEXT */)
    ]),
    tools: _withCtx(() => [
      _createVNode($setup["ToolBar"], {
        project: $setup.project,
        "onUpdate:project": _cache[0] || (_cache[0] = $event => (($setup.project) = $event)),
        period: $setup.period,
        "onUpdate:period": _cache[1] || (_cache[1] = $event => (($setup.period) = $event)),
        type: $props.type
      }, null, 8 /* PROPS */, ["project", "period", "type"])
    ]),
    default: _withCtx(() => [
      _createVNode($setup["ReportTable"], {
        mainType: $props.type,
        subType: $props.type,
        reportResults: $setup.reportResults
      }, null, 8 /* PROPS */, ["mainType", "subType", "reportResults"])
    ]),
    _: 1 /* STABLE */
  }))
}