<template>
	<div>
		<ElTooltip
			:content="tt('Related BOQ')"
			placement="top"
			v-if="props.params.onLink"
		>
			<ElButton type="primary" :icon="Link" @click="clickLink"></ElButton>
		</ElTooltip>
		<ElTooltip
			:content="tt('Delete')"
			placement="top"
			v-if="props.params.onRemove"
		>
			<ElButton type="danger" :icon="Delete" @click="click"></ElButton>
		</ElTooltip>
	</div>
</template>
<script lang="ts" setup>
import { Delete, Link } from '@element-plus/icons-vue';
import { defineProps} from 'vue';
const tt = __
const props = defineProps<{params:any}>();

function click(){
   props.params.onRemove(props.params.data);
}
function clickLink(){
	props.params.onLink(props.params.data);
}
</script>
