
<template>
	<Timeline
		ref="timeLine"
		:mode="smallMeta ? 'right' : 'left'"
		v-loading="loading"
		@loadMore="loadMore">
		<template #default="{ mode }">
			<DiaryTimelineItem
				:mode="mode"
				v-for="diaryRecord in diaryList"
				:project="project"
				:searchString="searchString"
				:dailyRecords="diaryRecord"
				:showComment="showComment">
			</DiaryTimelineItem>
		</template>

	</Timeline>
</template>
<script lang="ts" setup>
import {computed, ref, watch, onMounted, onUnmounted} from 'vue';
import moment from 'moment';

import Timeline from '../../../../components/timeline/Timeline.vue';

import {buildData, buildSearchData, generateDay, getQueryParams} from './helper';
import type {DiaryRecordB, TimelineList, UserDailyRecords} from './type';
import DiaryTimelineItem from './DiaryTimelineItem.vue';
import {useMetaQuery} from './useMetaQuery';
import useIntersection from './useIntersection';
const smallMeta = useMetaQuery();
const timeLine = ref<{ readonly el?: HTMLElement }>();
useIntersection(computed(() => timeLine.value?.el));

const today = moment(new Date()).add(1, 'days').format('YYYY-MM-DD');
interface Props {
	project?: string,
	designations: string[]
	date: string,
	searchString?: string
	showComment: boolean
}
const props = defineProps<Props>();

const loading = ref<boolean>(false);
const page = ref<number>(1);
const resultData = ref<Record<string, UserDailyRecords[]>[]>([]);
const searchMode = ref<boolean>(false);
async function loadDiaryRecords() {
	const designationStr = props.designations ? props.designations.join(',') : '';
	if (!props.project || !designationStr || !props.date) {
		resultData.value = [];
		return;
	}
	loading.value = true;
	const {recordOwners, actualMemberOwners, endDateParam, startDateParam, dayCount, batch}
		= await getQueryParams(props.project, props.designations, props.date, page.value);
	if (recordOwners.length === 0) {
		resultData.value = []; loading.value = false; return;
	}

	const filters: Record<string, [string, any]>[] = [
		{
			record_date: ['<=', page.value === 1 ? props.date || today : startDateParam],
		},
		{
			record_owner: ['in', Array.from(new Set(recordOwners.map(item => item.name)))],
		},
	];
	if (props.project !== 'all') {
		filters.push({project: ['=', props.project]});
	}
	const arg: Record<string, any> = {};
	if (searchMode.value) {
		filters.push({
			description: ['like', `%${props.searchString}%`],
		});
		arg.filters = filters;
		arg.limit = 20;
		arg.page = page.value;
	} else {
		filters.push({
			record_date: ['>=', endDateParam],
		});
		arg.filters = filters;
	}
	const data = await frappe.call<{ message: { diary_records: DiaryRecordB[] } }>({
		method: 'guigu_pm.guigu_pm_daily_record.page.project_leader_daily_record.project_leader_daily_record.get_diary_records',
		args: arg,
	});
	if (searchMode.value) {
		const {dailyRecordList} = buildSearchData(data?.message.diary_records || []);
		if (page.value === 1) {
			resultData.value = dailyRecordList;
		} else {
			resultData.value = resultData.value.concat(dailyRecordList);
		}
		loading.value = false;
		return;
	}
	const dateArr = batch === 0 ? generateDay(dayCount, startDateParam) : [];
	const {dailyRecordList, dailyRecordListHash} = buildData(dateArr, data?.message.diary_records || [], actualMemberOwners);
	if (page.value === 1) {
		resultData.value = dailyRecordList;
	} else if (dateArr.length) {
		resultData.value = resultData.value.concat(dailyRecordList);
	} else {
		const authorDailyArr: UserDailyRecords[] = actualMemberOwners.map(author => ({
			userId: `${author.name}${author.project}`,
			user: author,
			dailyRecords: dailyRecordListHash[`${author.name}${endDateParam}`] || [],
		}));
		const newArr = [...resultData.value];
		const oldObj = newArr.pop()!;
		const newObj: any = {};
		const [key] = Object.keys(oldObj);
		newObj[key] = [...oldObj[key], ...authorDailyArr];
		resultData.value = [...newArr, newObj];
	}
	loading.value = false;
}
function searchDiary(searchModeProps: boolean) {
	searchMode.value = searchModeProps;
	page.value = 1;
	loadDiaryRecords();
}
defineExpose({
	searchDiary,
});
function loadMore() {
	page.value++;
	loadDiaryRecords();
}
const diaryList = computed(() => {
	const arr: TimelineList[] = [];

	for (const item of resultData.value) {
		const [date] = Object.keys(item);
		for (const [index, each] of item[date].entries()) {
			if (!each.dailyRecords || each.dailyRecords.length === 0) {
				arr.push({
					user: each.user,
					type: 'none',
					left: index === 0 ? 'show' : 'none',
					date,
				});
			}
			for (const every of each.dailyRecords) {
				arr.push({
					user: each.user,
					type: 'user',
					data: every,
					left: index === 0 ? 'show' : 'none',
					date,
				});
			}
		}
	}
	return arr;
});
watch([() => props.project, () => props.date, () => props.designations], () => {
	page.value = 1;
	loadDiaryRecords();
});

const popstateListener = function () {
	page.value = 1;
	loadDiaryRecords();
};
onMounted(() => {
	loadDiaryRecords();
	window.addEventListener('popstate', popstateListener);
});
onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});

</script>
<style scoped>
</style>
