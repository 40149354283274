import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tool-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElButton = _resolveComponent("ElButton")
  const _component_ElSpace = _resolveComponent("ElSpace")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElSpace, null, {
      default: _withCtx(() => [
        _createVNode(_component_ElButton, {
          type: "primary",
          size: "small",
          onClick: $setup.toggleShowMode
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.fileViewStore.showMode === 'iconMode' ? "大图标" : "列表"), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        ($setup.isShowDownloadBtn)
          ? (_openBlock(), _createBlock($setup["ZipDownload"], {
              key: 0,
              selected: $props.selected,
              "is-folder-field": $setup.isFolderField,
              "parent-field": $setup.parentField,
              meta: $props.meta,
              "folder-name-field": $setup.folderNameField,
              "file-name-field": $setup.fileNameField
            }, null, 8 /* PROPS */, ["selected", "is-folder-field", "parent-field", "meta", "folder-name-field", "file-name-field"]))
          : _createCommentVNode("v-if", true),
        ($setup.isShowCreateBtn)
          ? (_openBlock(), _createBlock(_component_ElButton, {
              key: 1,
              type: "primary",
              size: "small",
              onClick: $setup.createFolder
            }, {
              default: _withCtx(() => [
                _createTextVNode("新建文件夹 ")
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        ($setup.isShowCreateBtn)
          ? (_openBlock(), _createBlock(_component_ElButton, {
              key: 2,
              type: "primary",
              size: "small",
              onClick: $setup.createFile
            }, {
              default: _withCtx(() => [
                _createTextVNode("上传文件 ")
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ]))
}