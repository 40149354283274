import { createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cbdb5e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "title container"
}
const _hoisted_2 = { class: "container guigu-container" }
const _hoisted_3 = { class: "tools" }
const _hoisted_4 = { class: "tools small-meta_tools" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "popover-tools" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_popover = _resolveComponent("el-popover")
  const _component_el_drawer = _resolveComponent("el-drawer")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.hasTitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode($setup["ToggleBtn"], {
            expand: $setup.showSider,
            "onUpdate:expand": _cache[0] || (_cache[0] = $event => (($setup.showSider) = $event))
          }, null, 8 /* PROPS */, ["expand"]),
          _renderSlot(_ctx.$slots, "title", {}, undefined, true)
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "tools", {}, undefined, true)
      ], 512 /* NEED_PATCH */), [
        [_vShow, $setup.hasTools&&!$setup.smallMeta]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        (!$setup.hasTitle)
          ? (_openBlock(), _createBlock($setup["ToggleBtn"], {
              key: 0,
              expand: $setup.showSider,
              "onUpdate:expand": _cache[1] || (_cache[1] = $event => (($setup.showSider) = $event))
            }, null, 8 /* PROPS */, ["expand"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5)),
        _createVNode(_component_el_popover, {
          placement: "bottom-start",
          width: 300,
          trigger: "click"
        }, {
          reference: _withCtx(() => [
            _createVNode(_component_el_icon, { size: "20px" }, {
              default: _withCtx(() => [
                _createVNode($setup["MoreFilled"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "tools", {}, undefined, true)
            ])
          ]),
          _: 3 /* FORWARDED */
        })
      ], 512 /* NEED_PATCH */), [
        [_vShow, $setup.smallMeta]
      ]),
      _createElementVNode("div", {
        class: _normalizeClass([$props.mode === 'vertical'?'vertical':'horizon', 'main-container' ]),
        ref: "container"
      }, [
        _withDirectives(_createElementVNode("div", {
          style: _normalizeStyle($props.siderStyle),
          class: _normalizeClass(['sider', {onlySider:$setup.hasSider&&!$setup.hasDetail&&!$setup.hasMain}]),
          ref: "sider"
        }, [
          _renderSlot(_ctx.$slots, "sider", {}, undefined, true)
        ], 6 /* CLASS, STYLE */), [
          [_vShow, $setup.hasSider&&!$setup.smallMeta&&$setup.showSider]
        ]),
        _withDirectives(_createElementVNode("div", {
          class: _normalizeClass([$props.mode === 'vertical'?'vertical':'horizon', 'resizer-container' ]),
          ref: "resizerContainer"
        }, [
          _createElementVNode("div", {
            class: "resizer",
            title: "侧边栏resizer",
            onPointerdown: $setup.dragLRController
          }, null, 32 /* HYDRATE_EVENTS */)
        ], 2 /* CLASS */), [
          [_vShow, $setup.hasSider&&($setup.hasDetail||$setup.hasMain)&&!$setup.smallMeta&&$setup.showSider]
        ]),
        _createElementVNode("div", {
          class: _normalizeClass([$props.rightMode === 'vertical'?'vertical':'horizon','right']),
          ref: "rightContainer"
        }, [
          _withDirectives(_createElementVNode("div", {
            style: _normalizeStyle($props.mainStyle),
            class: _normalizeClass(['main', {noDetail:!$setup.hasDetail}]),
            ref: "main"
          }, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 6 /* CLASS, STYLE */), [
            [_vShow, $setup.hasMain]
          ]),
          _withDirectives(_createElementVNode("div", {
            class: _normalizeClass([$props.rightMode === 'vertical'?'vertical':'horizon', 'resizer-container' ]),
            ref: "mainResizerContainer"
          }, [
            _createElementVNode("div", {
              class: "resizer",
              title: "main resizer",
              onPointerdown: $setup.dragLRController
            }, null, 32 /* HYDRATE_EVENTS */)
          ], 2 /* CLASS */), [
            [_vShow, $setup.hasDetail&&$setup.hasMain]
          ]),
          _withDirectives(_createElementVNode("div", {
            style: _normalizeStyle($props.detailStyle),
            class: "detail"
          }, [
            _renderSlot(_ctx.$slots, "detail", {}, undefined, true)
          ], 4 /* STYLE */), [
            [_vShow, $setup.hasDetail]
          ])
        ], 2 /* CLASS */)
      ], 2 /* CLASS */)
    ]),
    _createVNode(_component_el_drawer, {
      modelValue: $setup.showDrawer,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.showDrawer) = $event)),
      onClose: $setup.closeDrawer,
      title: "",
      direction: "ltr",
      size: "50%"
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "sider", {}, undefined, true)
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["modelValue"])
  ], 64 /* STABLE_FRAGMENT */))
}