import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["TimelineItem"], _mergeProps({
    date: $setup.smallMeta && $setup.dailyRecords.left === 'show' ? $setup.dailyRecords.date : ''
  }, _ctx.$attrs), _createSlots({
    timeName: _withCtx(() => [
      _createVNode($setup["DiaryLabel"], {
        dailyRecords: $setup.dailyRecords,
        subCompany: $props.subCompany
      }, null, 8 /* PROPS */, ["dailyRecords", "subCompany"])
    ]),
    default: _withCtx(() => [
      ($setup.dailyRecords.data)
        ? (_openBlock(), _createBlock($setup["DiaryDetail"], {
            key: 0,
            searchString: $props.searchString,
            showComment: $props.showComment,
            "daily-record": $setup.dailyRecords.data
          }, null, 8 /* PROPS */, ["searchString", "showComment", "daily-record"]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 2 /* DYNAMIC */
  }, [
    ($setup.dailyRecords.left === 'show' && !$setup.smallMeta)
      ? {
          name: "leftTitle",
          fn: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.dailyRecords.date), 1 /* TEXT */)
          ]),
          key: "0"
        }
      : undefined
  ]), 1040 /* FULL_PROPS, DYNAMIC_SLOTS */, ["date"]))
}