import type { Group } from '../core';
import type { Component } from 'vue';
import { computed, ref, watch, h } from 'vue';
import useBody from './useBody';

const Body: Component<{
	group?: Group
	rowHeight?: number;
}> = {
	props: ['group', 'rowHeight'],
	setup(props) {
		const root = ref();
		const current = useBody(root, computed(() => props.group))

		watch([current, () => props.rowHeight], ([current, rowHeight]) => {
			if (!current || !rowHeight) { return; }
			current.rowHeight = rowHeight;
		})
		return () => h('div', { ref: root })
	},
};

export default Body;
