<template>
	<div v-loading="loading">
		<Page>
			<template #title>
				<h3 class="title">
					{{ type === 'project' ? tt('Project') : tt('Company')
					}}{{ tt('Personnel Performance Evaluation Report') }}
				</h3>
			</template>
			<template #tools>
				<Header
					:type="type"
					:departments="departments"
					:smallMeta="smallMeta"
					v-model:departmentName="departmentName"
					v-model:year="year"
				></Header>
			</template>
			<Table :assessLevelData="levelData" :year="year"></Table>
		</Page>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import Page from '../../../../../../../guigu_pm/guigu_pm/public/js/components/page/index.vue';
import { useMetaQuery } from '../../../../../../../guigu_pm/guigu_pm/public/js/components/page/useMetaQuery';

import Header from './components/Header.vue';
import Table from './components/Table.vue';
import type { LevelData, Company, Project } from './type';

const tt = __;

interface Props {
	type: 'project' | 'subCompany';
}
const props = defineProps<Props>();
const departmentName = ref<string>('');
const year = ref<string>(moment().format('YYYY-MM-DD'));
const loading = ref<boolean>(false);
const levelData = ref<LevelData[]>([]);
const departments = ref<{ name: string; label: string }[]>([]);
const department = computed(() =>
	departments.value.find(item => item.name === departmentName.value),
);
const smallMeta = useMetaQuery();

watch(
	() => window.location.search,
	() => {
		const params = new URLSearchParams(window.location.search);
		if (params.get('year')) {
			year.value = params.get('year') || '';
		}
		if (params.get('department')) {
			departmentName.value = params.get('department') || '';
		}
	},
	{ immediate: true },
);

watch(
	() => props.type,
	() => {
		getSelectOptions();
	},
	{ immediate: true },
);
async function getSelectOptions() {
	const isProject = props.type === 'project';
	const url = isProject ? 'Project' : 'Company';
	const fields = isProject
		? ['name', 'project_abbr_name']
		: ['name', 'company_name'];
	const filters = isProject ? { status: 'Open' } : {};
	const res: (Project | Company)[] = await frappe.db.get_list(url, {
		fields: fields,
		filters: filters,
		limit: 0,
	});
	departments.value = res.map(item => {
		const label = isProject
			? (item as Project).project_abbr_name
			: (item as Company).company_name;
		return {
			name: item.name,
			label,
		};
	});
	if (!departmentName.value) {
		departmentName.value = res[0]?.name || '';
	}
}
watch(
	[departmentName, year],
	async () => {
		// 清空现有数据，确保重新加载
		levelData.value = [];
		const queryObj = new URLSearchParams({
			department: departmentName.value,
			year: year.value,
		});
		history.replaceState(null, '', `?${queryObj.toString()}`);
		await getLevelReportData();
	},
	{ immediate: true },
);
async function getLevelReportData() {
	if (!year.value || !props.type || !departmentName.value) {
		return;
	}
	loading.value = true;

	const res = await frappe.call<{ message: LevelData[] }>({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_company_level_report.guigu_hr_company_level_report.get_level_data',
		args: {
			year: moment(year.value || '').year(),
			type: props.type,
			department: departmentName.value,
		},
	});

	loading.value = false;
	levelData.value = res?.message || [];
}
</script>

<style lang="less" scoped>
.title {
	margin-bottom: 0;
}
</style>
