
<template>
	<div class="tree-container" v-loading="loading">
		<ElInput
			class="input_filter"
			v-model="filterText"
			size="small"
			style="width: 100%"
			:placeholder="tt('Find Work List')"
		/>
		<ElTree
			ref="tree"
			v-if="treeList.length"
			:data="treeList"
			:props="defaultProps"
			showCheckbox
			nodeKey="name"
			:expandOnClickNode="false"
			:filterNodeMethod="filterNode"
			@check="checkCheckbox"
			@node-click="nodeClick"
		>
			<template #default="{ node,data }">
				<Content
					:data="data"
					:node="node"
					:permission="permission"
					:separator="separator"
					:levelList="levelList"
					:processList="processList"
					@creatTask="creatTask" />
			</template>
		</ElTree>
		<ElEmpty v-else :description="tt('No Data')"></ElEmpty>
		<ElDialog v-if="isShow" v-model="isShow" width="95%" style="height:80%">
			<TaskTemplateDialog :name="worklistName" :department="department" @updateTaskList="updateTaskList" />
		</ElDialog>
	</div>
</template>
<script setup lang='ts'>
import {ElTree, vLoading, ElInput, ElDialog, ElEmpty} from 'element-plus';
import {computed, onMounted, ref, watch} from 'vue';
import type {TreeNodeData} from 'element-plus/es/components/tree/src/tree.type';

import TaskTemplateDialog from './TaskTemplateDialog.vue';
import type {TreeData, TaskData, LevelStyle, WorkListData} from './type';
import Content from './Content.vue';

const props = defineProps<{
	treeList: TreeData[];
	loading: boolean;
	permission: boolean;
	allWorklist: WorkListData;
}>();
const emit = defineEmits<{
	(event: 'setSideSelected', data: string[]): void;
	(event: 'updateData', type:'create'|'update'|'delete', data?: TaskData): void;
}>();
const tree = ref<InstanceType<typeof ElTree> | null>(null);
const filterText = ref<string>('');
const isShow = ref<boolean>(false);
const worklistName = ref<string>('');
const department = ref<string>('');
const levelList = ref<LevelStyle[]>([]);
const separator = ref<string>('.');
const tt = __;

const processList = computed(() => props.allWorklist.process_list || []);
const defaultProps = {
	label: 'title',
	children: 'children',
};
const filterNode = (value: string, data: TreeNodeData) => {
	if (!value) {
		return true;
	}
	return data.title.includes(value);
};
// 从模版创建任务后更新数据
function updateTaskList() {
	emit('updateData', 'create');
}
// 获取当前选中的节点下工作清单的names
function checkCheckbox() {
	const data = tree.value?.getCheckedNodes(true);
	const names = data!.filter(item => item.type === 'worklist')
		.map(item => item.name);
	emit('setSideSelected', names);
}
// 点击节点选中复选框
function nodeClick(node: TreeNodeData) {
	const key = node.name;
	const checkedKeys = tree.value?.getCheckedKeys() || [];
	if (checkedKeys.includes(key)) {
		tree.value?.setChecked(key, false, true);
	} else {
		tree.value?.setChecked(key, true, true);
	}
	checkCheckbox();
}
// 点击 + 从模板中创建任务
function creatTask(data:any) {
	isShow.value = true;
	worklistName.value = data.name;
	department.value = data.department;
}
// 获取层级样式
async function getLevelStyleList() {
	levelList.value = await frappe.db.get_list('Dw Process Level Style', {
		fields: ['level', 'row_background_color', 'row_height', 'font_color', 'is_bold', 'icon'],
		limit: 0,
	});
}
// 获取编码连接符
async function getSeparator() {
	separator.value = await frappe.db.get_single_value('Dw Process Separator Rule', 'separator') || '.';
}

onMounted(() => {
	getLevelStyleList();
	getSeparator();
});
watch(filterText, val => {
	tree.value!.filter(val);
});

</script>
<style lang='less' scoped>
.tree-container{
	height: 95%;
	overflow: auto;

	.input_filter{
		margin-bottom: 8px;
	}
}
:deep(.el-checkbox){
	margin-bottom: 0;
}
:deep(.el-checkbox__original){
	display: none;
}
</style>
