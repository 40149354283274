<template>
	<ElButton v-if="show"
		:icon="Switch" :disabled="loading" size="small"
		:title="tt('Reorder')" @click="click" />
</template>

<script lang="ts" setup>
import {computed} from 'vue';
import {ElButton} from 'element-plus';
import {Switch} from '@element-plus/icons-vue';


defineOptions({inheritAttrs: false});

const props = defineProps<{
	meta: locals.DocType;
	view?: GlobalView.View;
	configuration?: object;

	data: locals.Doctype[];
	total: number;
	options: Record<string, any>;

	loading: boolean;

	page: number;
	limit: number;

	selected?: any[];
	value: any;

	group: GlobalView.Group[];
	sort: GlobalView.Order
	filters?: any;
}>();

const emit = defineEmits<{
	(event: 'refresh'): void;
	(event: 'update:selected', value: any[]): void;
	(event: 'update:expanded', list: string[]): void;
	(event: 'sort', order: GlobalView.Sort[]): void;
	(event: 'update:group', group: GlobalView.Group[]): void;
	(event: 'update:filters', filters: any): void;
}>();

const tt = __;
const show = computed(() => {
	if (!frappe.user_roles.includes('System Manager')) {
		return false;
	}
	if (props.configuration?.tree) {
		return false;
	}
	const {meta} = props;
	const sort_field = meta.guigu_sort_field;
	if (!sort_field) {
		return false;
	}
	const field = meta.fields.find(r =>r.fieldname === sort_field && r.fieldtype === 'Int');
	if (!field) {
		return false;
	}
	return true;
});
function click() {
	frappe.call('guigu.doc.sort.rebuild_int', {doctype: props.meta.name});
}


</script>
