import registerGlobalView from 'guiguLib/registerGlobalView';

import Timeline from './index.vue';
import Config from './Config.vue';
import pretreat from './pretreat';

registerGlobalView('timeline', Timeline);
export { Timeline as View, pretreat };

export const widgets: GlobalView.ConfigurationWidget[] = [{
	component: Config,
	label: '字段',
}];
