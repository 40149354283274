import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6776554e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_input_number = _resolveComponent("el-input-number")
  const _component_el_form = _resolveComponent("el-form")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $setup.dialogVisible,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($setup.dialogVisible) = $event)),
    title: $setup.tt('Create a new temporary task'),
    width: $setup.width,
    destroyOnClose: "",
    onClose: $setup.cancel
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_el_button, { onClick: $setup.cancel }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: $setup.confirm
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Sure')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_form, {
          class: "temp-form",
          model: $setup.form,
          rules: $setup.rules,
          labelWidth: "100px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: $setup.tt('Plan Content'),
              prop: "subject"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: $setup.form.subject,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.form.subject) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: $setup.tt('Evaluation Criterion'),
              prop: "standard"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: $setup.form.standard,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.form.standard) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: $setup.tt('Weightage'),
              prop: "total_score"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input_number, {
                  modelValue: $setup.form.total_score,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.form.total_score) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: $setup.tt('Work Criteria'),
              prop: "work_criteria"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: $setup.form.work_criteria,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.form.work_criteria) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: $setup.tt('Evaluation Basis'),
              prop: "evaluation_basis"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: $setup.form.evaluation_basis,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.form.evaluation_basis) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: $setup.tt('Complete Percent'),
              prop: "percent_complete"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input_number, {
                  modelValue: $setup.form.percent_complete,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.form.percent_complete) = $event))
                }, null, 8 /* PROPS */, ["modelValue"]),
                _createTextVNode("% ")
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: $setup.tt('Complete Status'),
              prop: "remark"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: $setup.form.remark,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($setup.form.remark) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createCommentVNode(" <el-form-item :label=\"tt('Self Assessment')\" prop=\"self_assessment\">s\n\t\t\t\t\t<el-input-number v-model=\"form.self_assessment\" />\n\t\t\t\t\t</el-form-item> "),
            _createCommentVNode(" <el-form-item :label=\"tt('Direct Superior Rating')\" prop=\"leader_assessment\">\n\t\t\t\t\t<el-input-number v-model=\"form.leader_assessment\" />\n\t\t\t\t\t</el-form-item> ")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model", "rules"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title", "width"]))
}