<template>
	<AgGridVue
		v-if="!loading"
		class="ag-theme-guigu table"
		style="width: 100%;margin: 0;overflow: auto;"
		treeData
		:rowData="dataList"
		:columnDefs="columnDefs"
		:defaultColDef="defaultColDef"
		animateRows
		@grid-ready="onGridReady"
		:getDataPath="getDataPath"
		:getRowId="getRowId"
		:getRowHeight="getRowHeight"
		:suppressDragLeaveHidesColumns="smallMeta"
		:autoGroupColumnDef="autoGroupColumnDef"
		:localeText="zhCN"
	></AgGridVue>
</template>
<script setup lang="ts">
import {ref, watch, onMounted} from 'vue';
import {AgGridVue} from 'ag-grid-vue3';
import type {CellClassParams, GridApi, ColDef, ColGroupDef, ValueGetterParams} from 'ag-grid-community';

import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';

import StandardWorklist from './StandardWorklist.vue';
import type {LevelStyle, TableData, Organization, ProcessData} from './type';
import {processLevel} from './helper';

const props = defineProps<{
	hasPermission: boolean;
	dataList: TableData[];
	organizationMap: Map<string, Organization>;
	loading: boolean;
	levelStyle: LevelStyle[];
	smallMeta: boolean;
	processList: ProcessData[];
}>();
const emit = defineEmits<{
	(e: 'showDetail', name: string): void;
	(e: 'updateData', params:{ type: 'delete' | 'create' | 'update'|'enable'|'disable', data: any}): void;
	(e: 'createWorkList', data: TableData): void;
}>();
const gridApi = ref<GridApi>();
const separator = ref<string>('.');

// 添加标准工作清单
function addStandardWorklist(data: TableData) {
	emit('createWorkList', data);
}
// 启用标准工作清单
async function enableStandardWorklist(data: TableData) {
	await frappe.db.set_value('Dw Standard Worklist', data.name, 'disable', 0);
	emit('updateData', {type: 'enable', data});
	frappe.show_alert({message: __('Enable Successful'), indicator: 'green'}, 2);
}
// 停用标准工作清单
async function disableStandardWorklist(data: any) {
	// 验证是否有组织单元工作清单关联
	const names = await frappe.db.get_list('Dw Organization Worklist', {
		filters: [['dw_standard_worklist', '=', data.name]],
		fields: ['name'], limit: 0,
	});
	if (names.length) {
		return frappe.msgprint({title: __('Cannot Disable'), indicator: 'red',
			message: __('The standard work list is associated with the organizational unit work list and cannot be disable'),
		});
	}
	frappe.confirm(
		__('Are you sure you want to disable the standard work list?'),
		async() => {
			await frappe.db.set_value('Dw Standard Worklist', data.name, 'disable', 1);
			emit('updateData', {type: 'disable', data});
			frappe.show_alert({message: __('Disable Successful'), indicator: 'green'}, 2);
		},
	);
}
// 点击标准工作清单查看详情
function editDetail(name: string) {
	emit('showDetail', name);
}
const defaultColDef = ref<ColDef>();
watch(()=>props.levelStyle, ()=>{
	defaultColDef.value = {
		sortable: true,
		filter: true,
		resizable: true,
		suppressMovable: true,
		cellStyle: (params: CellClassParams) => {
			const rowStyle = props.levelStyle.find(item => item.level === processLevel[params.data?.level]);
			const {is_bold, font_color, row_background_color} = rowStyle || {};
			let commonStyles:any = {
				display: 'grid',
				alignItems: 'center',
			};
			if (params.data.is_process) {
				commonStyles = {
					...commonStyles,
					fontWeight: is_bold ? 'bold' : 'normal',
					color: font_color,
					backgroundColor: row_background_color,
				};
			}
			return commonStyles;
		},
	};
});
const autoGroupColumnDef = ref<ColGroupDef|ColDef>();
watch([() => props.hasPermission, ()=>props.levelStyle, ()=>props.processList, separator], () => {
	autoGroupColumnDef.value = {
		headerName: __('Work List Title'),
		field: 'title',
		editable: false,
		filter: false,
		flex: 2,
		cellRendererParams: {
			innerRenderer: StandardWorklist,
			innerRendererParams: {
				disableStandardWorklist,
				addStandardWorklist,
				enableStandardWorklist,
				editDetail,
				hasPermission: props.hasPermission,
				levelStyle: props.levelStyle,
				separator: separator.value,
				processList: props.processList,
			},
		},
		sort: 'asc',
		comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
			const {is_process: isProcessA, code: codeA} = nodeA.data;
			const {is_process: isProcessB, code: codeB} = nodeB.data;
			if (isProcessA && isProcessB) {
				if (codeA === codeB) {
					return 0;
				}
				return (codeA > codeB) ? 1 : -1;
			}
			if (!isProcessA && !isProcessB) {
				if (codeA === codeB) {
					return 0;
				}
				return (codeA > codeB) ? 1 : -1;
			}
			if (isProcessB) {
				return 1;
			}
			return -1;
		},
	};
});
const columnDefs = [
	{
		headerName: __('Customize Organizational Unit'),
		field: 'organization',
		editable: false,
		filter: false,
		flex: 1,
		valueGetter: (params: ValueGetterParams) => {
			if (props.organizationMap !== undefined) {
				return props.organizationMap.get(params.data.organization ?? '')?.label || '';
			}
		},
	},
	{
		headerName: __('Last Updated On'),
		field: 'modified',
		editable: false,
		filter: false,
		flex: 1,
	},
];

function onGridReady(params: CellClassParams) {
	gridApi.value = params.api;
}
function getRowId(params: CellClassParams) {
	return params.data.name;
}
function getDataPath(data: any) {
	return data.path;
}
function getRowHeight(params:CellClassParams) {
	if (!params.data.is_process) {
		return 30;
	}
	const style = props.levelStyle.find(item => item.level === processLevel[params.data?.level]);
	return style?.row_height || 30;
}
// 获取编码连接符
async function getSeparator() {
	separator.value = await frappe.db.get_single_value('Dw Process Separator Rule', 'separator') || '.';
}
onMounted(() => {
	getSeparator();
});
defineExpose({
	gridApi,
});
</script>
<style scoped>
:deep(.ag-group-contracted){
	height: 100% !important;
}
:deep(.ag-group-expanded){
	height: 100% !important;
}
:deep(.ag-group-child-count){
	height: 100% !important;
}
</style>
