import type { Config } from '../FileView';
import { View, Toolbar, Sider, RowAction, widgets } from '../FileView';
import defineMainView from '../extends/init/defineMainView';

frappe.provide('frappe.views.file');


function getFieldName(
	sCfg: any,
	config: any,
	meta: locals.DocType,
	field: string,
) {
	return sCfg[field]
		|| config[field]
		|| meta.fields.find(item => item.fieldname === field)?.fieldname
		|| 'name';
}

function getParentField(meta: locals.DocType) {
	const treeParentField = meta.is_tree === 1 ? meta.nsm_parent_field : '';
	if (!treeParentField) { return; }
	if (meta.fields.some(item => item.fieldname === treeParentField)) {
		return treeParentField;
	}


}

defineMainView<Config>({
	type: 'Guigu File View Configuration',


	toolbar: ['filterLine', Toolbar],
	view: View,
	sider: [Sider],
	rowAction: RowAction,
	detail: true,
	configuration: {
		widgets,
		displayField: {
			useDefault: true,

			titleField: true,
			commentField: true,
			tagField: true,
			dateExtField: true,

			width: true,
			enableLink: true,
		},
	},
	defaultLimit: 30,
	infiniteScroll: true,
	getConfigurations(meta, configuration: any) {
		const sCfg = frappe.views.file[meta.name] || {};

		const isFolderField = getFieldName(sCfg, configuration, meta, 'is_folder_field');
		const fileNameField = getFieldName(sCfg, configuration, meta, 'file_name_field');
		const folderNameField = getFieldName(sCfg, configuration, meta, 'folder_name_field');
		const parentField = sCfg.parent_field
			|| configuration.parent_field
			|| getParentField(meta) || '';

		const projectFieldObj = meta.fields.find(item => item.options === 'Project');
		const projectField = projectFieldObj?.name;

		return {
			doctype: meta.name,
			isFolderField,
			fileNameField,
			folderNameField,
			parentField,
			projectField,
		};
	},
	*fields(meta, cfg) {
		yield 'status';
		yield 'name';
		yield 'enabled';
		yield 'disabled';
		yield 'color';
		yield cfg?.isFolderField;
		yield cfg?.fileNameField;
		yield cfg?.fileNameField;
		yield cfg?.folderNameField;
		yield cfg?.parentField;
		yield* meta.fields
			.filter(item => item.fieldtype === 'Attach' || item.fieldtype === 'Attach Image')
			.map(item => item.fieldname);

	},
	*defaultOrder(order, meta, cfg) {
		yield* order;
		const { isFolderField, fileNameField, folderNameField } = cfg || {};
		yield { field: isFolderField, desc: true };
		yield { field: folderNameField, desc: true };
		yield { field: fileNameField, desc: true };
	},
});
