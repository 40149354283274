import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["ElTooltip"], {
      content: $setup.tt('Instruction Flow')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElButton"], { icon: $setup.Connection }, null, 8 /* PROPS */, ["icon"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["content"])
  ]))
}