<template>
	<div :style="{
		borderRadius: '12px',
		backgroundColor: bgColor,
		padding: '0 10px',
		display: 'flex',
		alignItems: 'center',
		width: '75px',
		height:'20px'
	}">
		<span :style="{
			width: '8px',
			height: '8px',
			borderRadius: '50%',
			backgroundColor: color,
			marginRight: '5px'
		}"></span>
		<span :style="{color,fontSize:'12px'}">{{ params.data?.status?.status_name }}</span>
	</div>
</template>

<script setup lang="ts">
import type {CellClassParams} from 'ag-grid-community';
import {computed} from 'vue';
const props = defineProps<{
	params: CellClassParams,
}>();
const bgColor = computed(() => props.params.data?.status?.background_color);
const color = computed(()=>props.params?.data?.status?.font_color);
</script>
