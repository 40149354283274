<template>
	<div>{{ value }}</div>
</template>

<script setup lang='ts'>
import { computed, watch } from 'vue';
const translate = window.__;

const props = defineProps<{ params: any }>();
const value = computed(() => {
	if (props.params.node.level === -1) {
		return '';
	}
	return (props.params.value || 0).toString() === '1' ? '是' : '否';
});
</script>

<style lang='less' scoped>
.link {
	color: #409eff;
}
</style>
