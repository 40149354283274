<template>
	<div style="height:100%;display: flex;align-items: center;justify-content: space-between;">
		<div>
			<Icon v-if="!isWorkList" :icon="icon"/>
			<span :style="{cursor: isWorkList ?'pointer':'',textDecoration: isWorkList ? 'underline' : ''}"
				@click="clickLabel">
				{{ fullName }}
			</span>
		</div>
		<span class="core_circle" v-if="isCore"></span>
	</div>
</template>
<script setup lang="ts">
import type {ICellRendererParams} from 'ag-grid-community';
import {computed} from 'vue';

import Icon from '../../../../component/Icon/index.vue';

import {processLevel} from './helper';
import type {LevelStyle, Process} from './type';
const props = defineProps<{
	params: ICellRendererParams & {
		viewDetail: (name: string, doctype:string) => void,
		levelStyle: LevelStyle[]
		processes: Process[];
		separator: string;
	}
}>();
const isWorkList = computed(() => !props.params.data?.is_process);
const isCore = computed(() => props.params.data?.is_core_work_list === 1);
const icon = computed(() => props.params.levelStyle.find(item =>
	item.level === processLevel[props.params.data?.level])?.icon || '');

const fullName = computed(() => {
	const {data, processes, separator} = props.params;
	if (data.is_process) {
		return `${data.full_process_code} ${data.title}`;
	}
	const process = processes.find(item => item.name === data?.parent_dw_process);
	const prefix = process ? `${process.full_process_code}${separator}` : '';
	return `${prefix}${data.full_code} ${data.title}`;
});
// 点击组织单元工作清单label查看详情
function clickLabel() {
	if (isWorkList.value) {
		props.params.viewDetail(props.params.data.name, 'Dw Organization Worklist');
	}
}
</script>
<style>
	.core_circle {
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: red;
		margin-right: 20px;
	}
</style>
