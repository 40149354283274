<script lang="ts" setup>
import {computed, onMounted, shallowRef} from 'vue';

import requestDocCount from '../../utils/requestDocCount';

const props = defineProps<{
	doctype: string;
	filters: [string, string, string, any][]
	orFilters: [string, string, string, any][][]
	editing?: boolean;
	view?: string;
	viewMust?: boolean;
	viewDefault?: boolean;
	size?:'small'
}>();
const loading = shallowRef(true);
const error = shallowRef('');
const count = shallowRef(0);
onMounted(async () => {
	if (props.editing) {
		return;
	}
	try {
		const {doctype} = props;
		await new Promise<void>(resolve => {
			frappe.model.with_doctype(doctype, () => {
				resolve();
			}, true);
		});
		if (!frappe.perm.has_perm(doctype)) {
			error.value = '无权限';
			return;
		}
		let filters = [...props.filters];
		let orFilters = [...props.orFilters];
		const {view} = props;
		if (view) {
			const viewDefault = !(filters.length || orFilters.length) && props.viewDefault;
			const {viewMust} = props;
			if (viewMust || viewDefault) {
				const cfg = await frappe.xcall<any>('guigu.view.get_view_only', {name: view});
				if (cfg && viewDefault) {
					filters = JSON.parse(cfg.filters) || [];
					orFilters = JSON.parse(cfg.or_filters) || [];
				}
				if (cfg && viewMust) {
					filters.push(...JSON.parse(cfg.must) || []);
				}
			}
		}
		count.value = await requestDocCount(doctype, filters, orFilters);
		loading.value = false;
	} catch (e) {
		console.error(e);
		error.value = '网络错误';
	}
});
const title = computed(() => {
	if (props.editing) {
		return '编辑模式下不显示';
	}
	if (loading.value) {
		return '加载中';
	}
	if (error.value) {
		return error.value;
	}
});
const value = computed(() => {
	if (props.editing) {
		return '...';
	}
	if (loading.value) {
		return '...';
	}
	if (error.value) {
		return '×';
	}
	return count.value;
});
</script>

<template>
	<div class="widget-control">
		<div :class="['indicator-pill', 'ellipsis', 'gray', props.size==='small'?'small':undefined]" :title="title">{{ value }}</div>
	</div>
</template>
<style scoped lang="less">
.small{
	height: 20px;
}
</style>
