<template>
	<Quarter v-if="showQuarter" :placeholder="label && tt(label)" :value="modelValue" originInput @update:value="changeQuarter"></Quarter>
	<ElDatePicker
		v-else
		v-model="value"
		:type="type"
		:format="format"
		placeholder="请选择" :teleported="false" />
</template>

<script setup lang='ts'>
import { watch, computed } from 'vue';

import { ElDatePicker } from 'element-plus';

import Quarter from '../../guigu_controls/guigu_date_picker/Quarter.vue';

import { toDate, toDateString } from './date';
const props = defineProps<{
	label?: string;
	name: string;
	required?: boolean;
	filter?: boolean;
	modelValue?: any;
	fitWidth?: boolean;
	popperClass?: string;
	options?: any;
}>();

const emit = defineEmits<{
	(event: 'update:modelValue', value: any): void;
}>();
const tt = __;

const type = computed(() => props.options?.toLowerCase() || 'month');
const showQuarter = computed(()=>props.options?.toLocaleLowerCase()==='quarter');

function getFormatDate(value?: Date | string) {
	if (!value) {
		return;
	}
	let formatValue = moment(value).format('YYYY-MM-DD');
	if (type.value === 'week') {
		formatValue = moment(value).add(1, 'days').isoWeekday(1)
			.format('YYYY-MM-DD');
	}
	return formatValue;
}
watch(() => props.modelValue, value => {
	const date = toDateString(toDate(getFormatDate(props.modelValue)));
	if (type.value === 'week') {
		value = moment(value).add(1, 'days').isoWeekday(1)
			.format('YYYY-MM-DD');
	}
	if (value !== date) {
		emit('update:modelValue', date);
	}
}, { immediate: true });

const value = computed({
	get: () => toDate(props.modelValue),
	set: v => {
		emit('update:modelValue', getFormatDate(v));
	},
});

const lang = computed(() => {
	let lang = 'en';
	frappe.boot.user && (lang = frappe.boot.user.language);
	return lang;
});

const format = computed(() => {
	switch (props.options) {
		case 'Week':
		case 'week':
			return `gggg-ww[${lang.value === 'zh' ? '周' : 'week'}]`;
		case 'year':
		case 'Year':
			return 'YYYY';
		default:
			return 'YYYY-MM';
	}
});
function changeQuarter(v:string){
	emit('update:modelValue', v);
}
</script>

<style lang='less'>
</style>
