<!-- 流程清单过滤组件 -->
<template>
	<div class="filter_container">
		<span>{{ tt('Dw Process') }}:</span>
		<ElCascader
			popperClass="worklist-cascader"
			:placeholder="tt('Please Select')"
			:options="options"
			:props="cascaderProps"
			filterable
			clearable
			size="small"
			v-model="selectvalue"
			@change="cascaderChange"
		/>
	</div>
</template>

<script lang="ts" setup>
import {ElCascader} from 'element-plus';
import {watch, ref, defineEmits} from 'vue';

import type {process} from '../../types';
const tt = __;

const selectvalue = ref<string[]>([]);
const urlProcessParam = ref<string>('');

// processes：流程清单的一维数组
const props = defineProps<{
	processes: process[]
}>();

interface Options {
	name: string,
	processName: string,
	children: Options[]
}

const options = ref<Options[]>([]);
const cascaderProps = {
	value: 'name',
	label: 'process_name',
	children: 'children',
	checkStrictly: true,
};

watch(
	() => props.processes,
	value => {
		let option = [];
		if (value) {
			option = setProcessTree(value);
		}
		options.value = option;
		if (urlProcessParam.value) {
			const parents = findProcessLevelAndParents(urlProcessParam.value, value);
			selectvalue.value = parents;
			emit('getData', parents[parents.length - 1]);
		}
	},
	{immediate: true},
);

const emit = defineEmits<{(event: 'getData', process: string,): void;}>();

function setUrlParam(process: string) {
	const params = new URLSearchParams(window.location.search);
	params.set('process', process);
	history.replaceState(null, '', `?${params.toString()}`);
}

function cascaderChange() {
	const currentSelect = selectvalue.value;
	if (currentSelect && currentSelect.length > 0) {
		setUrlParam(currentSelect[currentSelect.length - 1]);
		emit('getData', currentSelect[currentSelect.length - 1]);
	} else {
		setUrlParam('');
		emit('getData', '');
	}
}

function findProcessLevelAndParents(processName: string, processes: process[]) {
	const parents: string[] = [];
	if (processes.length > 0) {
		function collectParents(process: process) {
			if (process.parent_dw_process !== '') {
				const parent = processes.find(ps => ps.name === process.parent_dw_process);
				if (!parent) {
					return;
				}
				parents.unshift(parent.name);
				collectParents(parent);
			}
		}

		const foundProcess = processes.find(process => process.name === processName);
		if (!foundProcess) {
			return [];
		}

		collectParents(foundProcess);

		parents.push(processName);
	}
	return parents;
}

// TODO v-model 来做，url参数变化由用户决定
// 监视路径中的流程筛选值
watch(
	() => window.location.search,
	() => {
		const params = new URLSearchParams(window.location.search);
		if (params.get('process')) {
			urlProcessParam.value = params.get('process') || '';
		}
	},
	{immediate: true},
);

//处理数据生成树
function setProcessTree(list: process[]) {
	const map = new Map();
	const tree = [];

	for (const item of list) {
		map.set(item.name, {...item, children: []});
	}

	for (const item of list) {
		const node = map.get(item.name);
		if (item.parent_dw_process) {
			const parentNode = map.get(item.parent_dw_process);
			if (parentNode) {
				parentNode.children.push(node);
			}
		} else {
			tree.push(node);
		}
	}

	return tree;
}

</script>

<style scoped>
.filter_container{
	margin-bottom: 8px;
	white-space: nowrap;
	margin-right: 8px;
}

.worklist-cascader label{
	margin-bottom: 0;
}

</style>
<style>
.worklist-cascader .el-cascader-panel .el-radio{
	margin-bottom: 0;
}
.worklist-cascader .el-radio__original{
	position: absolute!important;
}
</style>
