import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { style: {overflow:'auto', height:'100%', 'line-height':'100%'} }
const _hoisted_2 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tag = _resolveComponent("el-tag")
  const _component_el_space = _resolveComponent("el-space")
  const _component_ElTooltip = _resolveComponent("ElTooltip")

  return (_openBlock(), _createBlock(_component_ElTooltip, {
    placement: "top",
    content: $setup.labelString
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_space, { style: { height:'100%', 'line-height':'100%'} }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.value, (d) => {
              return (_openBlock(), _createBlock(_component_el_tag, null, {
                default: _withCtx(() => [
                  _createElementVNode("a", {
                    href: d.multi_table_url
                  }, _toDisplayString($setup.tt(d.multi_table_label)), 9 /* TEXT, PROPS */, _hoisted_2)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["content"]))
}