import isIncluding from './isIncluding.mjs';
import type { Dot } from './nyloongTable/gantt';
import type { GanttDot } from './types';

const s = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
const p = s.appendChild(document.createElementNS('http://www.w3.org/2000/svg', 'path'));
p.setAttribute('fill', 'currentColor');
p.setAttribute('d', 'M5 0 L 0 5 L 5 10 L 10 5 Z');
s.setAttribute('viewBox', '0 0 11 11');
function getIcon(icon: string) {
	// TODO:
	return s;
}

export default function getDot({
	date, icon, size, color, position, filterField, filterValue,
}: GanttDot): Dot {
	return {
		date: filterField ? (data: any, dates) => {
			if (!isIncluding(data[filterField], filterValue)) { return; }
			return dates[date];
		}: date, icon: getIcon(icon), size, color, position,
	};
}
