<template>
	<div class="toolbar">
		<div class="input-container">
			{{ tt('Company:') }}
			<ElSelect class="input" filterable v-model="companyValue">
				<ElOption v-for="item in companies" :key="item.name" :label="item.company_name" :value="item.name" />
			</ElSelect>
		</div>
		<Project
			class="input-container"
			v-model="projectValue"
			:orderBy="'sort_code'"
			:filters="filters"
		></Project>
		<div class="input-container">
			{{ tt('Year:') }}<ElDatePicker class="date-input" v-model="yearValue" valueFormat="YYYY" type="year" :clearable="false" />
		</div>
		<div class="input-container">
			{{ tt('Starting Month:')
			}}<ElDatePicker
				class="date-input"
				popperClass="month-input"
				v-model="startMonthValue"
				valueFormat="MM"
				:format="tt('MM Month')"
				type="month"
				:teleported="false"
				:clearable="false" />
		</div>
		<div class="input-container">
			{{ tt('End Month:')
			}}<ElDatePicker
				class="date-input"
				popperClass="month-input"
				v-model="endMonthValue"
				valueFormat="MM"
				:format="tt('MM Month')"
				type="month"
				:teleported="false"
				:clearable="false" />
		</div>
	</div>
</template>

<script setup lang="ts">
import {computed, onMounted, ref, watch} from 'vue';

import type {Company} from '../type';
import Project from '../../../../../../../../guigu/guigu/public/js/components/PageComponents/Tools/Project.vue';

const tt = __;

interface Props {
	company: string;
	project:string;
	year: string;
	startMonth: string;
	endMonth: string;
	smallMeta: boolean;
}
const props = defineProps<Props>();
interface Emit {
	(e: 'update:company', company: string): void;
	(e: 'update:project', project: string): void;
	(e: 'update:year', date?: string): void;
	(e: 'update:startMonth', date?: string): void;
	(e: 'update:endMonth', date?: string): void;
}
const emit = defineEmits<Emit>();
const companies = ref<Company[]>([]);
const filters = ref<[string, string, any][]>([
	['project_construction_status', '=', 'Project State Finishing'],
]);
const yearValue = computed({
	get: () => props.year || '',
	set: (v: string) => {
		emit('update:year', v);
	},
});
const startMonthValue = computed({
	get: () => props.startMonth || '',
	set: (v: string) => {
		emit('update:startMonth', v);
	},
});
const endMonthValue = computed({
	get: () => props.endMonth || '',
	set: (v: string) => {
		emit('update:endMonth', v);
	},
});
const companyValue = computed({
	get: () => props.company || '',
	set: (v: string) => {
		emit('update:company', v);
	},
});
const projectValue = computed({
	get: () => props.project || '',
	set: (v: string) => {
		emit('update:project', v);
	},
});
watch(companyValue, newCompany => {
	if (newCompany) {
		projectValue.value = '';
		filters.value = [
			['project_construction_status', '=', 'Project State Finishing'],
			['company', '=', newCompany],
		];
	}
});
onMounted(async () => {
	getCompanies();
});
async function getCompanies() {
	const subCompaniesRes: Company[] = await frappe.db.get_list('Company', {
		fields: ['name', 'company_name'],
		limit: 0,
	});
	companies.value = subCompaniesRes;
	if (!props.company) {
		emit('update:company', subCompaniesRes[0]?.name || '');
	}
	filters.value.push(['company', '=', subCompaniesRes[0].name]);
}
const flexDirection = computed(() => (props.smallMeta ? 'column' : 'row'));
const margin = computed(() => (props.smallMeta ? '8px' : '0'));
</script>

<style lang="less" scoped>
  .toolbar {
    display: flex;
    flex-direction: v-bind(flexDirection);
  }
  .input-container {
    white-space: nowrap;
    margin-right: 8px;
  }
  .input {
    margin-bottom: v-bind(margin);
    min-width: 150px;
  }
  :deep(.date-input) {
    margin-bottom: v-bind(margin);
  }
  :deep(.month-input .el-date-picker__header) {
    display: none;
  }
</style>
