import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-81d8fa35"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tools" }
const _hoisted_2 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createBlock($setup["ElDialog"], {
    modelValue: $setup.dialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.dialogVisible) = $event)),
    title: $setup.tt('Dw Work Instruction Work List'),
    width: $setup.smallMeta?'80%':'700'
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["ElButton"], {
          onClick: _cache[0] || (_cache[0] = $event => ($setup.dialogVisible = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElButton"], {
          type: "primary",
          onClick: $setup.confirm
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Confirm')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode($setup["ProcessSearchTools"], {
            processes: $setup.processList,
            onGetData: $setup.getData
          }, null, 8 /* PROPS */, ["processes"])
        ]),
        _createVNode($setup["ElTable"], {
          ref: "tableRef",
          class: "work_list_table",
          data: $setup.tableList,
          style: {"width":"100%"},
          stripe: "",
          rowKey: "name",
          border: "",
          onSelect: $setup.selectionChange
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElTableColumn"], {
              type: "selection",
              selectable: $setup.selectable,
              width: "55"
            }),
            _createVNode($setup["ElTableColumn"], {
              prop: "title",
              label: $setup.tt('Subject')
            }, null, 8 /* PROPS */, ["label"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["data"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title", "width"])), [
    [$setup["vLoading"], $setup.loading]
  ])
}