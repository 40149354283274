import {cloneDeep} from 'lodash';

import {RenderData} from './type/index';

export interface Item {
	id: string,
	[parent: string]: any
}
export interface TreeItemData extends Item {
	children?: this[];
}
export function list2Tree<T extends Item>(listData: T[], parentField: string) {
	const listDataCopy: T[] = cloneDeep(listData);
	const treeData: (T & TreeItemData)[] = [];
	const map: Record<string, any> = {};
	for (const item of listDataCopy) {
		map[item.id] = item;
	}
	for (const item of listDataCopy) {
		const parent = map[item[parentField] || 0];
		if (parent) {
			(parent.children || (parent.children = [])).push(item);
		} else {
			treeData.push(item);
		}
	}
	return treeData;
}
export function getAncestry(
	treeData: any[],
	parentAncestry: (string | number)[] = [],
) {
	for (const item of treeData) {
		item.ancestry = [...parentAncestry, item.id];
		if (item?.children && item?.children?.length > 0) {
			getAncestry(item.children, [...parentAncestry, item.id]);
		} else {
			continue;
		}
	}
}
export function getGraphData(
	initData: Record<string, any>[],
	fields: GlobalView.DisplayField[],
	connection_field: string,
	meta: locals.DocType,
) {
	const data = initData.map(row => {
		const renderDataEntries = fields.map(({fieldOptions: docField}) => {
			const field = docField.fieldname;
			let value = row[field];
			if (docField.fieldtype === 'Link' || docField.fieldtype === 'Tree Select' || docField.fieldtype === 'Tianjy Related Link') {
				value = __(row[`${docField.fieldname || ''}.title`]);
			}
			if (docField.fieldtype === 'Percent') {
				const precision = docField?.precision || cint(frappe.boot.sysdefaults.float_precision, null);
				value = `${isNaN(parseFloat(value)) ? null : flt(value, precision)}%`;
			}
			if (docField.fieldtype === 'Float') {
				const number_format = docField.options?.trim() || get_number_format();
				const precision = docField.precision || cint(frappe.boot.sysdefaults.float_precision, null);
				value = format_number(value, number_format, precision);
			}
			if (docField.fieldtype === 'Guigu Date' && value) {
				const type = docField.options?.toLowerCase() || 'month';
				let lang: string | null = 'en';
				if (frappe.boot.user) {
					lang = frappe.boot.user.language;
				}
				switch (type) {
					case 'week':
						value = moment(value).format(`gggg-ww[${lang === 'zh' ? '周' : 'week'}]`);
					case 'month':
						value = moment(value).format('YYYY-MM');
					case 'year':
						value = moment(value).format('YYYY');
					case 'quarter':
						value = `${moment(value).format('YYYY')}-${moment(value).quarter()}季度`;
				}
			}
			if (value === undefined || value === null) {
				return;
			}
			const label = field === 'name' ? 'ID' : __(docField.label || '');
			return [field, {label, value: __(value), origin: row[field]}];
		}).filter(Boolean) as [string, { label: string, value: any }][];
		const renderData: RenderData = Object.fromEntries(renderDataEntries);
		return {
			id: row.name,
			[connection_field]: row[connection_field],
			label: row[meta.title_field || 'name'],
			data: renderData,
			type: 'detailCard',
		};
	});
	const tree = list2Tree(data, connection_field);
	const defaultRoot = {
		id: 'graph_root',
		label: 'root',
		children: tree,
	};
	return defaultRoot;
}
