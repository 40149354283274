
import { getFieldDisplayed } from '../../../../../../../guigu/guigu/public/js/GlobalView/useViews/FieldDisplayed';

import type { ViewStyle, ViewInfo } from './types';
import { displayField } from './constants';

function parseArray(v?: string | any[] | null): any[] {
	if (typeof v === 'string') {
		// eslint-disable-next-line no-param-reassign
		try { v = JSON.parse(v); } catch { }
	}
	return Array.isArray(v) ? v : [];
}


function getMaxLimit(maxLimit?: number) {
	if (typeof maxLimit !== 'number') { return Infinity; }
	const n = Math.floor(maxLimit);
	if (!Number.isSafeInteger(n)) { return Infinity; }
	if (n <= 0) { return Infinity; }
	return Math.max(n, 5);
}
function getDefaultLimit(max: number, defaultLimit?: number) {
	if (typeof defaultLimit !== 'number') { return Math.min(100, max); }
	const n = Math.floor(defaultLimit);
	if (!Number.isSafeInteger(n)) { return Math.min(100, max); }
	if (n <= 0) { return max === Infinity ? Infinity : Math.min(100, max); }
	return Math.max(0, Math.min(n, max));
}

function getInfiniteScroll(infiniteScroll?: boolean | string) {
	if (typeof infiniteScroll === 'boolean') { return infiniteScroll; }
	if (infiniteScroll === 'Yes') { return true; }
	if (infiniteScroll === 'No') { return false; }
	return false;
}

export default function getView({
	maxLimit: _maxLimit,
	defaultLimit,
	infiniteScroll,
	linkField,
	fields,
	order,
	filters,

	fixedFieldColumns,
	tree,
	showLineNumber,
	showSummaryRow,
	style,
}: {
	maxLimit?: number;
	defaultLimit?: number;
	infiniteScroll?: string;
	linkField?: string;
	fields?: GlobalView.Field[];
	[k: string]: any;
	style?: ViewStyle;
} = {}, meta: locals.DocType): ViewInfo {

	const maxLimit = getMaxLimit(_maxLimit);
	const fieldList = fields?.filter(v => v.field) || [];

	const view: ViewInfo = {
		maxLimit,
		defaultLimit: getDefaultLimit(maxLimit, defaultLimit),
		infiniteScroll: getInfiniteScroll(infiniteScroll),
		order: parseArray(order)
			.map(([doctype, field, desc = false]) => ({ doctype, field, desc })),
		filters: parseArray(filters),

		fields: getFieldDisplayed(fieldList, meta, displayField),
		titleField: fieldList.find(v => v.field === '__Title__'),

		tree: Boolean(tree),
		showLineNumber: Boolean(showLineNumber),
		showSummaryRow: Boolean(showSummaryRow),
		style: style || {},
	};
	if (typeof fixedFieldColumns === 'number') {
		view.fixedFieldColumns = fixedFieldColumns;
	}

	if (linkField) { view.linkField = linkField; }
	return view;
}
