<template>
	<AgGridVue
		class="ag-theme-guigu work-assessment-container_table"
		:rowData="detailTableData"
		:columnDefs="columnDefs"
		:autoGroupColumnDef="autoGroupColumnDef"
		:localeText="zhCN"
		v-loading="loading"
		@grid-ready="onGridReady"
		:getRowId="getRowId"
		rowGroupPanelShow="always"
		@cellValueChanged="cellValueChanged"
		:suppressDragLeaveHidesColumns="smallMeta"
		:leftFixed="5">
	</AgGridVue>
</template>

<script lang="ts">
import { ref, computed, defineComponent, PropType, getCurrentInstance, watch, defineEmits } from 'vue';
import { AgGridVue } from 'ag-grid-vue3';
import { CellValueChangedEvent, GridApi } from 'ag-grid-community';
import { ElMessage } from 'element-plus';

import FloatComponent from '../../../../AgGrid/components/Float.vue';
import FloatEditor from '../../../../AgGrid/components/CommanFloatEditor.vue';
import { DetailTableData } from '../../type';
import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';
import { useMetaQuery } from '../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';

import AgGridWorkAssessmentSubject from './AgGridWorkAssessmentSubject.vue';
import AgGridWorkAssessmentStatus from './AgGridWorkAssessmentStatus.vue';
import AgGridWorkAssessmentPriority from './AgGridWorkAssessmentPriority.vue';
import { getWorkAssessmentDetailColumns } from './tableColumns';

export default defineComponent({
	components: {
		FloatComponent,
		AgGridVue,
		FloatEditor,
		AgGridWorkAssessmentSubject,
		AgGridWorkAssessmentStatus,
		AgGridWorkAssessmentPriority,
	},
	props: {
		'project': String,
		'loading': Boolean,
		'detailTableData': Object as PropType<DetailTableData[]>,
		'layout': String,
	},
	emits: {
		onDetailGridReady(params: GridApi) { },
		updateRecordValue(value: any) { },
	},
	setup(props, context) {
		const smallMeta = useMetaQuery();
		const { appContext } = getCurrentInstance() || {};
		const loading = ref<boolean>(false);
		const gridApi = ref(null);
		const onGridReady = (params: any) => {
			gridApi.value = params.api;
			context.emit('onDetailGridReady', params.api);
		};
		const recordValue = ref();
		const visible = ref(false);
		async function updateRecordValue(value: any) {
			recordValue.value = {...value};
		}

		watch(() => recordValue.value, () => {
			context.emit('updateRecordValue', recordValue.value);
		});

		const columnDefs = computed(() => getWorkAssessmentDetailColumns(updateRecordValue, props.layout));
		watch(() => props.loading, () => {
			loading.value = props.loading;
		});

		function getDataPath(data: any) {
			return data.ancestry;
		}
		const autoGroupColumnDef = {
			headerName: __('Grouped'),
			// field: 'project_user',
			width: 150,
			minWidth: 150,
			editable: false,
			pinned: 'left',
			sortable: true,
			filter: true,
			flex: 1,
			resizable: true,
			expanded: true,
		};
		async function cellValueChanged(event: CellValueChangedEvent) {
			const { data, value, colDef } = event;
			try {
				const result = await frappe.call({
					method: 'guigu_pm.guigu_pm_project_work.page.project_work_assessment.project_work_assessment.update_work_assessment',
					args: {
						name: data.assessment_name,
						field: colDef.field,
						value: Number(value || 0),
					},
				});
				ElMessage({
					message: __('Successfully Updated'),
					type: 'success',
				});
			} catch {
				ElMessage({
					message: __('Update failed'),
					type: 'success',
				});
			}
		}
		function getRowId(params: any) {
			return params.data.id;
		}

		return {
			loading,
			cellValueChanged,
			onGridReady,
			columnDefs,
			getDataPath,
			autoGroupColumnDef,
			getRowId,
			visible,
			zhCN,
			confirm,
			smallMeta,
		};
	},
});
</script>

<style lang="less" scoped>
.work-assessment-container {
  flex: 1;
}

.work-assessment-container_table {
  height: 100%
}
</style>
