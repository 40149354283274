<template>
	<div class="input-container">
		<span v-if="!noLabel">{{ tt('Company') }}:</span>
		<ElSelect
			class="input"
			v-model="companyValue"
			:popperClass="popperClassProject"
			size="small"
			:placeholder="tt('Please Select Company')"
		>
			<ElOption
				v-for="item in companyList"
				:key="item.name"
				:label="item.company_name"
				:value="item.name"
			>
			</ElOption>
		</ElSelect>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import { useMetaQuery } from 'guigu/public/js/hooks/useMetaQuery';
import { ElSelect, ElOption } from 'element-plus';

const tt = __;

interface Props {
	company: string;
	fields?: string[];
	noLabel?: boolean;
	default?: boolean;
	/** 排序字段 */
	orderBy?: string;
	paramsFilter?: { [key: string]: string };
}

const smallMeta = useMetaQuery();
const props = defineProps<Props>();

interface Emit {
	(e: 'update:company', company: string): void;
	(e: 'setRoute', value: { [key: string]: string }): void;
}
const emit = defineEmits<Emit>();

interface Company {
	name: string;
	company_name: string;
	abbr: string;
}
const companyList = ref<Company[]>([]);

const companyValue = computed({
	get: () => props.company || '',
	set: (v: string) => {
		emit('setRoute', { company: v });
		emit('update:company', v);
	},
});

async function getCompanyList() {
	const fields = ['name', 'company_name', 'abbr'];
	const params = {
		fields,
		limit: 0,
	};
	if (props.orderBy) {
		params.order_by = props.orderBy;
	}
	const companyListRes: Company[] = await frappe.db.get_list(
		'Company',
		params,
	);
	companyList.value = [...companyListRes];

	const companyNames = companyListRes.map(item => item.name);
	const hasInValidateValue =
		props.company && !companyNames.includes(props.company);
	if (props.default && (!props.company || hasInValidateValue)) {
		const [sessionDefaultCompany]: [string] =
			frappe.defaults.get_user_defaults('company') || [];
		const detaultCom = companyNames.includes(
			props.paramsFilter?.company || '',
		)
			? props.paramsFilter?.company
			: '';
		companyValue.value =
			detaultCom ||
			sessionDefaultCompany ||
			companyListRes[0]?.name ||
			'';
	}
}
const popperClassProject = computed(() => `projectLink`);

const margin = computed(() => (smallMeta.value ? '8px' : '0'));
onMounted(() => [getCompanyList()]);
</script>

<style lang="less" scoped>
.input-container {
	display: inline-flex;
}
.input {
	margin-bottom: v-bind(margin);
	min-width: 150px;
	flex: 1;
}
.projectLink {
	// .el-select-dropdown__item {
	// 	height: 59px !important;
	// }

	.el-select-dropdown__item > p {
		display: flex;
		flex-direction: column;
	}
	.el-select-dropdown__item > p > span {
		font-size: 12px;
		line-height: 1em;
	}
}
</style>
