import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.groups, ({ values, field }, g) => {
    return (_openBlock(), _createElementBlock(_Fragment, null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.width / $props.widths[g] / (values.length || 1), (_) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(values, (value, i) => {
            return (_openBlock(), _createBlock($setup["Title"], {
              class: _normalizeClass(['header', { ['column']: $props.cc }]),
              style: _normalizeStyle($setup.getStyle(value, $props.widths[g], g, (_ - 1) * (values.length || 1) + i)),
              title: value.title || value.value,
              n: $setup.getList(g, ((_ - 1) * (values.length || 1) + i) * $props.widths[g]),
              onSetting: $event => ($setup.edit(field, value)),
              onAdd: $event => ($setup.add(field, i))
            }, null, 8 /* PROPS */, ["class", "style", "title", "n", "onSetting", "onAdd"]))
          }), 256 /* UNKEYED_FRAGMENT */))
        ], 64 /* STABLE_FRAGMENT */))
      }), 256 /* UNKEYED_FRAGMENT */))
    ], 64 /* STABLE_FRAGMENT */))
  }), 256 /* UNKEYED_FRAGMENT */))
}