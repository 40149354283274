<template>
	<div class="header">
		<ElButton :icon="DArrowLeft" link @click="changeYear('minus')"></ElButton>
		<ElTooltip
			placement="top-start"
		>
			<template #content>
				<p>{{tt('Year')}}：{{yearPeriods[yearIndex]?.value}}</p>
				<p>{{tt('Start Date')}}：{{yearPeriods[yearIndex]?.start_date}}</p>
				<p>{{tt('End Date')}}：{{yearPeriods[yearIndex]?.end_date}}</p>
			</template>
			{{yearPeriods[yearIndex]?.value}}{{ tt('Year') }}
		</ElTooltip>
		<ElButton :icon="DArrowRight" link @click="changeYear('add')"></ElButton>
	</div>
</template>
<script lang="ts" setup>
import {DArrowLeft, DArrowRight} from '@element-plus/icons-vue';
import {ref, defineProps, toRefs, watchEffect, computed, defineEmits, watch} from 'vue';

import { Period } from './type';
const tt = __
interface Props{
	yearPeriods:Period[]
	selectPeriod?:Period
}
const props = defineProps<Props>();
interface Emit{
	(e:'yearChange', year: Period):void
}
const emit = defineEmits<Emit>();
const {yearPeriods, selectPeriod} = toRefs(props);

const yearIndex = ref<number>(0);
watchEffect(()=>{
	if (!selectPeriod?.value){ return; }
	const defaultYear = String(selectPeriod.value.value).slice(0, 4);
    if (!defaultYear || !yearPeriods.value) { return; }
    const index = yearPeriods.value.findIndex(item => String(item.value) === defaultYear);
	yearIndex.value = index;
});

watch([yearIndex, yearPeriods], ()=>{
	if (!yearPeriods.value[yearIndex.value]){ return; }
	emit('yearChange', yearPeriods.value[yearIndex.value]);
});
function changeYear(type:'add'|'minus'){
	if (type==='add'){
		if (yearIndex.value+1>=yearPeriods.value.length){ return; }
		// emit('yearChange', yearPeriods.value[yearIndex.value+1]);
		yearIndex.value++;
	} else {
		if (yearIndex.value===0){ return; }
		// emit('yearChange', yearPeriods.value[yearIndex.value-1]);
		yearIndex.value--;
	}
}
</script>
<style lang="less" scoped>
.header {
	padding: 8px;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #f0f0f0;
}
</style>
