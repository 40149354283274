export default function findRow(
	rowHeight: number,
	start: number,
	end: number,
	max: number
): [number, number] | undefined {
	const sIndex = Math.max(0, Math.floor(start / rowHeight));
	const eIndex = Math.min(max, Math.ceil(end / rowHeight));
	if (sIndex > eIndex) { return; }
	return [sIndex, eIndex];
}
