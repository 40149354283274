
export interface TimelineSource {
	date: string;
	list: Record<string, any>[];
}

export function getTimelineData(
	data: Record<string, any>[],
	desc: boolean,
	timelineField: string
): TimelineSource[] {
	const dataByDate: Record<string, any[]> = {};
	data.forEach(item => {
		const date = moment(item[timelineField]).format('YYYY-MM-DD');
		if (!dataByDate[date]) {
			dataByDate[date] = [];
		}
		dataByDate[date].push(item);
	});
	const timelineData: TimelineSource[]
		= Object.entries(dataByDate).map(([date, list]) => ({ date, list }));
	if (desc) {
		timelineData.sort((pre, next) => (pre.date > next.date ? -1 : 1));
	} else {
		timelineData.sort((pre, next) => (pre.date > next.date ? 1 : -1));
	}
	return timelineData;
}
