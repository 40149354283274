import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-290f9242"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "line" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["ElSelect"], {
      modelValue: $setup.selectedField,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.selectedField) = $event)),
      filterable: "",
      suffixIcon: null,
      teleported: false,
      class: "field"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.fields, (df) => {
          return (_openBlock(), _createBlock($setup["ElOption"], {
            key: `${df.doctype}.${df.field}`,
            label: df.doctype === $props.meta.name
					? $setup.tt(df.label || df.field)
					: `${$setup.tt(df.label || df.field)} (${$setup.tt(df.doctype)})`
				,
            value: `${df.doctype}.${df.field}`
          }, {
            default: _withCtx(() => [
              (df.doctype === $props.meta.name)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString($setup.tt((df.label || df.field))), 1 /* TEXT */)
                  ], 64 /* STABLE_FRAGMENT */))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString($setup.tt((df.label || df.field))) + " ", 1 /* TEXT */),
                    _createElementVNode("small", null, _toDisplayString($setup.tt((df.doctype))), 1 /* TEXT */)
                  ], 64 /* STABLE_FRAGMENT */))
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "value"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"]),
    _createVNode($setup["Condition"], {
      modelValue: $setup.cond,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.cond) = $event)),
      type: $setup.type,
      options: $setup.options,
      class: "condition",
      withSession: $props.withSession
    }, null, 8 /* PROPS */, ["modelValue", "type", "options", "withSession"]),
    _createVNode($setup["Input"], {
      condition: $setup.cond,
      type: $setup.type || '',
      options: $setup.options,
      name: $props.field,
      listable: "",
      required: "",
      modelValue: $setup.val,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.val) = $event)),
      meta: $setup.docMeta,
      class: "input"
    }, null, 8 /* PROPS */, ["condition", "type", "options", "name", "modelValue", "meta"]),
    _createVNode($setup["ElButton"], {
      onClick: $setup.remove,
      icon: $setup.Delete,
      title: "移除",
      text: ""
    }, null, 8 /* PROPS */, ["icon"])
  ]))
}