import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19309e79"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-container" }
const _hoisted_2 = {
  key: 1,
  class: "placeholder"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.meta&&$setup.detail)
      ? (_openBlock(), _createBlock($setup["FormDetail"], {
          key: 0,
          loading: $setup.loading,
          meta: $setup.meta,
          options: {},
          value: $setup.detail,
          isHideClose: ""
        }, null, 8 /* PROPS */, ["loading", "meta", "value"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.tt('Template Preview')), 1 /* TEXT */))
  ]))
}