import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElInput"], {
    modelValue: $setup.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.value) = $event)),
    rows: 2,
    type: "textarea",
    placeholder: $props.label && $setup.tt($props.label)
  }, null, 8 /* PROPS */, ["modelValue", "placeholder"]))
}