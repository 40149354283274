<template>
	<div class="photo-info-container">
		<div class='left'>
			<div class='photo-image-container'>
				<img :src="url" />
			</div>
			<!-- 暂时注释这个删除,因为暂时只能单选 -->
			<!-- <ElButton
				class='photo-delete'
				type="danger"
				@click='deleteFile'
			>
			删除
			</ElButton> -->
		</div>
		<div class='right'>
			<ElForm
				ref="formRef"
				label-width='auto'
				:rules="rules"
				:model="form">
				<ElFormItem :label="tt('File Name')" props="name">
					<ElInput v-model='form.name' />
				</ElFormItem>
				<ElFormItem v-if="showDateInput" :label="tt('Date')" props="date"
					rules="{ required: true, trigger: 'blur' }">
					<ElDatePicker v-model="form.date" type="date"
						@change="changeDate" />
				</ElFormItem>
				<ElFormItem :label="tt('Structure')" props="target">
					<ElTreeSelect
						default-expand-all
						v-model="form.target"
						:data="treeStructure"
						:render-after-expand="true"
						:filter-node-method="filterNodeMethod"
						:props="defaultProps"
						@change="changeTarget"
						filterable
						check-strictly />
				</ElFormItem>
				<ElFormItem
					:label="tt('Author')"
					props="photographer">
					<ElInput v-model="form.photographer"
						@change="changeAuthor" />
				</ElFormItem>
				<ElFormItem :label="tt('Description')" props="description">
					<ElInput :rows="2" type="textarea"
						v-model="form.description" />
				</ElFormItem>
			</ElForm>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { reactive, computed, defineEmits, defineProps, watchEffect, toRefs, ref, watch, defineExpose} from 'vue';
import { FormInstance } from 'element-plus/lib/components/index.js';
import { list2Tree, TreeItem } from 'guigu_pm/public/js/utils';

import {ImageFile} from '../../../type/image';
import { StructureItemFormat } from '../../../type/diary';
import { getStructureName } from '../helper';
const tt = __
interface Props{
	photo:ImageFile,
	structures:StructureItemFormat[],
	date:string,
	showDateInput?:boolean,
	defaultStructureId?: string;
}
interface Emits{
	(e:'deleteFile', uid:string):void
}
const props = defineProps<Props>();
const {defaultStructureId, structures, date, photo} = toRefs(props);
const emits = defineEmits<Emits>();
const formRef = ref<FormInstance>();
const form = reactive({
  name: '',
  date: date.value,
  target: '',
  photographer: '',
  description: '',
});
defineExpose({
	formRef,
	key:photo.value.uid,
	formValue:form,
});
const url = ref<string>();
watch(photo.value, function(){
	const reader = new FileReader();
    reader.readAsDataURL(photo.value.raw);
    reader.onload = function (event) {
	  url.value = (event?.target?.result as string)||'';
    };
});
watchEffect(()=>{
	const structureId = defaultStructureId?.value||
		structures.value?.reduce((pre, next) =>
       		pre?.ancestry!.length < next?.ancestry!.length ? pre : next,
      	).id;
	const newLabel = getStructureName(structures.value, structureId);
	form.name=`${date.value}-${newLabel}-${frappe.session.logged_in_user}`;
	form.target = structureId;
	form.photographer = frappe.session.logged_in_user;
	form.date = date.value;
});
const rules = reactive<FormRules>({
  name: [
    { required: true, trigger: 'blur' },
  ],
  target:[
    { required: true, trigger: 'blur' },
  ],
  photographer:[
    { required: true, trigger: 'blur' },
  ],
});

const treeStructure = computed(()=>list2Tree<StructureItemFormat>(structures.value));
const defaultProps = {
	children: 'children',
	label: 'name',
	value:'id',
};

function filterNodeMethod(value:string, data:(StructureItemFormat & TreeItem)){
	return data.name.includes(value);
}

function changeTarget(value: string){
	const newLabel = getStructureName(structures.value, value);
	form.name = `${date.value}-${newLabel || ''}-${form.photographer}`;
}

function changeAuthor(value:string){
	const newLabel = getStructureName(structures.value, form.target);
	form.name = `${date.value}-${newLabel || ''}-${value}`;
}
function changeDate(v:string){
	const newLabel = getStructureName(structures.value, form.target);
	form.name = `${v}-${newLabel || ''}-${form.photographer}`;
}

function deleteFile(){
	emits('deleteFile', photo.value.uid);
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 640px) {
	.photo-info-container {
		flex-direction: column;

		.left {
			width: 100%;
			border-right: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
}

@media screen and (min-width: 640px) {
	.photo-info-container {
		flex-direction: row;

		.left {
			width: 100px;
			border-right: 1px solid #eee;
		}
	}
}

.photo-info-container {
	display: flex;
	border-bottom: 1px solid #eee;

	&:last-child {
		border-bottom: 0;
	}

	padding: 8px 0;

	.photo-image-container {
		width: 84px;
		height: 84px;
		display: flex;
		align-items: center;
		margin-bottom: 8px;

		img {
			width: 100%;
		}
	}

	.photo-delete {
		margin-top: 8px;
	}

	.right {
		flex: 1;
	}
}
</style>
