import type { Group } from '../core';
import type { Component } from 'vue';
import { computed, ref, h } from 'vue';
import useHeader from './useHeader';

const Header: Component<{ group?: Group }> = {
	props: ['group'],
	setup(props: {
		group?: Group | undefined
	}) {
		const root = ref();
		useHeader(root, computed(() => props.group))

		return () => h('div', { ref: root })
	},
}

export default Header;
