<template>
	<div :class="[{ hidden }, 'sider']" :style="{
		'--mainView-sider': `max(150px, min(${size}, 50%))`,
		'--mainView-sider2': `max(150px, min(${size}, 90%))`,
	}" @click.self="hide">
		<div :class="['context', siderClass]">
			<slot />
		</div>
		<div class="resize"
			@pointerdown="siderBegin"
			@pointermove="siderMove"
			@pointerup="siderEnd"
			@pointercancel="siderEnd"
			@touchmove.prevent />
	</div>
</template>
<script setup lang="ts">

import usePointerCapture from './usePointerCapture';


const props = defineProps<{
	size: string;
	show: boolean;
	hidden?: boolean
	siderClass?: string;
}>();

const emit = defineEmits<{
	(event: 'refresh'): void
	(event: 'update:size', size: string): void
	(event: 'update:show', show: boolean): void
}>();
function hide() {
	if (window.innerWidth > 992) {
		return;
	}
	emit('update:show', false);
}
const [siderBegin, siderMove, siderEnd] = usePointerCapture(
	e => e.offsetX,
	(e, x) => {
		const parent = (e.currentTarget as Element).parentElement;
		if (!parent) {
			return;
		}
		const rect = parent.getBoundingClientRect();
		emit('update:size', `${e.pageX - rect.x - x}px`);
	},
);
</script>
<style scoped lang="less">
.sider {
	display: flex;
	flex-direction: row;
	grid-area: sider;
	inline-size: var(--mainView-sider);

	&.hidden {
		display: none;
	}

	@media screen and (max-width: 992px) {
		background: #0003;
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 1020;
		transition: .2s margin-left, .2s width;

		&.hidden {
			display: flex;
			margin-left: calc(-1 * var(--mainView-sider2));
			width: var(--mainView-sider2);
		}
	}
}

.context {
	overflow: auto;
	flex: 1;
	padding-inline-end: var(--padding-md);
	padding-inline-start: 2px;

	@media screen and (max-width: 992px) {
		background: #FFFFFF;
		flex: 0 var(--mainView-sider2);
		padding: var(--padding-md);
	}

}

.resize {
	z-index: 7;
	box-sizing: content-box;
	width: 1px;
	margin: 0 -5px;
	background: #d9d9d9;
	background-clip: padding-box;
	border: transparent solid;
	border-width: 0 5px;
	cursor: col-resize;
	margin-block: 5px;

	&:hover {
		border-color: #0000001a;
		transition: border-color 2s ease;
	}

	.hidden & {
		display: none;
	}

	@media screen and (max-width: 992px) {
		display: block;
		margin-block: 0;

		.hidden & {
			display: block;
		}
	}
}
</style>
