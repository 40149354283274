<script lang="ts" setup>
import {computed, ref, watch} from 'vue';

import getFiltersSearch from '../../utils/getFiltersSearch';

const props = defineProps<{
	value?: any;
	editing: boolean;
}>();
const emit = defineEmits<{
	(event: 'edit'): void
}>();
const label = computed<string>(() => props.value?.label || '');

const url = computed(()=>{
	const {doctype, filters, label} = props.value || {};
	if (!doctype) {
		return '';
	}
	const root = `/app/${frappe.router.slug(doctype)}/view/list`;
	const search = getFiltersSearch(doctype, filters, [], true);

	return `${root}?${search}`;
});
function click(e: Event) {
	if (props.editing) {
		e.preventDefault();
	}
}
</script>

<template>
	<WorkspaceWidget class="small">
		<template #title>
			<a v-if="url" class="link" @click="click" :href="url"
				:title="label">
				<span>
					{{ label }}
				</span>
				<svg v-if="!editing" class="icon ml-2 icon-xs"
					style="stroke: currentColor">
					<use class="" href="#icon-arrow-up-right"></use>
				</svg>
			</a>
		</template>
	</WorkspaceWidget>
</template>
<style scoped lang="less">

.small{
	padding: 0!important;
	.link{
		font-size: var(--text-md);
		color:var(--text-color);
		font-weight: 400;
		line-height: 1.5;
	}
}
.link {
	display: flex;
	font-size: 14px;
	font-weight: 500;
	text-decoration: none !important;
	max-width: 100%;
	align-items: center;

	span {
		vertical-align: middle;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

	}

	&:hover {
		text-decoration: underline !important;
		color: var(--blue-500);
	}

	.editing & {
		font-size: 16px;
	}
}
</style>
