import {
	onMounted, computed, onUnmounted, shallowRef, Ref, watch, unref,
} from 'vue';

import initData from '../../compatible/list/initData';

import getFields from './getFields';
import getGroup from './getGroup';
import loadList from './loadList';
import pretreatDate from './pretreatDate';

export default function useMain(
	meta: Ref<locals.DocType> | locals.DocType,
	setting: Ref<GlobalView> | GlobalView,
	configuration: Ref<object> | object,
	view: Ref<GlobalView.View> | GlobalView.View,
	filters: Ref<[string, string, string, any][]> | [string, string, string, any][],
	orFilters: Ref<[string, string, string, any][][]> | [string, string, string, any][][],
	order: Ref<GlobalView.Order[]> | GlobalView.Order[],
	group: Ref<GlobalView.Group[]> | GlobalView.Group[],
	infiniteScroll: Ref<boolean> | boolean,
	limit: Ref<number> | number,
) {
	const loadDataFn = computed(() => {
		const loadFn = unref(setting).load;
		const load = typeof loadFn === 'function' ? loadFn : loadList;
		return load;
	});

	/** 主区域数据 */
	const list = shallowRef<any[]>([]);
	/** 主区域数据 */
	const data = computed({
		get: () => list.value,
		set: l => {
			const data = l.map(v => ({ ...v }));
			list.value = data;
		},
	});
	/** 第几页 */
	const pageRef = shallowRef<number>(1);
	/** 总条数 */
	const total = shallowRef(0);
	/** 响应数据中其他选项 */
	const options = shallowRef({});
	/** 主区域数据加载中 */
	const loadingCount = shallowRef(1);
	/** 是否正在加载 */
	const loading = computed(() => Boolean(loadingCount.value));

	const limitInfinite = computed(() => {
		const l = unref(limit);
		return l > 0 ? l : Infinity;
	});

	const groupBy = computed(() => getGroup(unref(meta).name, unref(group)));
	const fields = computed(() => getFields(
		unref(meta),
		unref(view),
		unref(configuration),
		unref(order),
		unref(setting).fields,
	));
	const pageOptions = computed(() => {
		const opts: GlobalView.MainLoaderOptions = {
			order: unref(order),
			group: groupBy.value,
			fields: fields.value,
			orFilters: unref(orFilters),
		};
		const limit = limitInfinite.value;
		if (limit === Infinity) {
			opts.all = true;
		} else {
			const p = pageRef.value;
			opts.limit = limit;
			opts.page = p;
			opts.offset = (p - 1) * limit;
		}
		return opts;
	});
	const unmounted = (() => {
		const unmounted = shallowRef(false);
		onUnmounted(() => { unmounted.value = true; });
		return unmounted;
	})();
	async function load(append?: boolean) {
		const load = loadDataFn.value;
		const docMeta = unref(meta);
		const viewData = unref(view);
		const {
			values,
			total: newTotal,
			...opts
		} = await load(docMeta, [
			...unref(filters),
			...viewData.filtersMust,
		], pageOptions.value);
		pretreatDate(values, docMeta, viewData);
		const pretreat = [initData, unref(setting).pretreat].flat()
			.filter(Boolean) as GlobalView.Pretreat[];
		if (pretreat.length) {
			const cfg = unref(configuration);
			await Promise.all(pretreat.map(f => f(values, docMeta, cfg, viewData)));
		}
		total.value = newTotal;
		data.value = append ? [...data.value, ...values] : values;
		options.value = opts;
	}
	let timeout: any;
	let lastTime = 0;
	function cancel() {
		clearTimeout(timeout);
		lastTime = 0;
	}
	onUnmounted(() => { cancel(); });
	/** 加载主区域数据 */
	async function reload(append?: boolean) {
		cancel();
		if (unmounted.value) { return; }
		if (!filters.value){ return }
		loadingCount.value++;
		try {
			await load(append);
		} finally {
			loadingCount.value--;
		}
	}
	function delayRequest() {
		if (unmounted.value) { return; }
		const now = Number(new Date());
		if (!lastTime) { lastTime = now + 5 * 1000; }
		clearTimeout(timeout);
		timeout = setTimeout(reload, Math.min(500, lastTime - now), false);
	}
	watch(limitInfinite, limit => {
		if (unref(infiniteScroll) || limit === Infinity) {
			pageRef.value = 1;
		}
		delayRequest();

	});
	function refresh() {
		// 无限滚动刷新需要回到顶部
		if (unref(infiniteScroll)) {
			pageRef.value = 1;
		}
		reload(false);
	}
	function nextPage() {
		if (limitInfinite.value === Infinity) { return; }
		const infScroll = unref(infiniteScroll);
		// 无限滚动时，禁止在加载时调用 nextPage
		if (infScroll && loading.value) { return; }
		pageRef.value++;
		reload(infScroll);
	}

	function reRequest() {
		if (unmounted.value) { return; }
		if (unref(infiniteScroll)) {
			pageRef.value = 1;
		}
		delayRequest();

	}

	async function sortData(
		target: string,
		docs: string[],
		before: boolean,
		field?: string,
	): Promise<void>
	async function sortData(
		target: string,
		docs: string[],
		before: boolean,
		children?: boolean,
	): Promise<void>
	async function sortData(
		target: string,
		docs: string[],
		before: boolean,
		field?: string | boolean,
	) {
		if (unmounted.value) { return; }
		if (field && typeof field !== 'boolean') { return; }
		loadingCount.value++;
		try {
			await frappe.call('guigu.tree.tree_sort', {
				doctype: unref(meta).name,
				target, docs, before,
				children: Boolean(field),
			});
		} finally {
			loadingCount.value--;
		}
		await reload();

	}
	const page = computed({
		get: () => pageRef.value,
		set: p => {
			if (p === pageRef.value) { return; }
			pageRef.value = p;
			delayRequest();
		},
	});


	onMounted(() => { reload().finally(() => loadingCount.value--); });
	return { data, total, options, loading, refresh, nextPage, sortData, reRequest, page };
}
