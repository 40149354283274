import date2n from './date2n';
import n2date from './n2date';
import type { GanttHeader } from './types';

export default function getBgGroup(
	get: GanttHeader,
	start: Date,
	end: Date,
) {
	let key = '';
	let length = 0;
	let widths: number[] = [];
	const s = Math.floor(date2n(start));
	const e = Math.floor(date2n(end));
	for (let d = s; d <= e; d++) {
		const date = n2date(d);
		const newKey = get(date);
		if (newKey !== key && length) {
			widths.push(length);
			length = 0;
		}
		key = newKey;
		length++;
	}
	if (length) { widths.push(length); }
	return widths;

}
