<template lang="html">
	<QuickFilter v-for="f in quickFilters" :key="f.fieldname"
		:meta="meta" :quickFilter="f" v-model:filters="filters" />
</template>
<script setup lang="ts">
import { computed } from 'vue';

import type { Filter } from '../types';

import QuickFilter from './QuickFilter.vue';

defineOptions({
	name: 'QuickFilterLine',
	inheritAttrs: false,
});

const props = defineProps<{
	meta: locals.DocType;
	filters: Filter[]
	quickFilters: {
		fieldtype: string;
		label?: any;
		options?: string | undefined;
		fieldname: string;
		condition: string;
	}[]
}>();


const emit = defineEmits<{
	(event: 'update:filters', filters: Filter[]): void
}>();


const filters = computed({
	get: () => props.filters,
	set: v => { emit('update:filters', v); },
});


</script>
