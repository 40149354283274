<!-- 组织单元工作清单标准任务模板 -->
<template>
	<h5>{{ tt('Standard task template') }}</h5>
	<div>
		<TaskTemplateTable
			:tableData="tableData"
			:loading="loading"
			:smallMeta="smallMeta"
			@getSelectRows="getSelectRows">
		</TaskTemplateTable>
		<div class="operation-button">
			<ElButton
				v-if="!props.frm.is_new() && manager"
				type="primary"
				size="small"
				@click="enableTemplate"
			>
				{{ tt('Enable') }}
			</ElButton>
		</div>
	</div>
</template>

<script lang="ts" setup>
import {onMounted, ref, defineProps, onUnmounted} from 'vue';
import {ElButton, ElMessage} from 'element-plus';

import TaskTemplateTable from '../../dw_standard_worklist/task_template/DwTaskTemplateList/component/TaskTemplateTable.vue';
import type {TaskTemplate} from '../../../../types';
import {useMetaQuery} from '../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';
import {registerEvent, triggerEvent} from '../eventBus';
const tt = __;

const props = defineProps<{
	frm: any
}>();
const tableData = ref<TaskTemplate[]>([]);
const loading = ref<boolean>(false);
const smallMeta = useMetaQuery();
const selectData = ref<TaskTemplate[]>([]);
const standardWorklistName = ref<string>('');
const manager = ref<boolean>(false);
const inUseStandardTemplateNames = ref<string[]>([]);

registerEvent('getEnableTemplate', function() {
	getEnableTemplate();
});

// 获取一级任务模板列表;
async function getTableData() {
	loading.value = true;
	const res = await frappe.call<{ message: TaskTemplate[]}>({
		method: 'dw_worklist_management.dw_task_management.doctype.dw_task_template.dw_task_template.get_root_task_template',
		args: {
			name: standardWorklistName.value,
		},
	});
	if (res?.message && res.message.length > 0) {
		tableData.value = res.message;
	} else {
		tableData.value = [];
	}
	loading.value = false;
	getEnableTemplate();
}

async function getEnableTemplate() {
	const result = await frappe.db.get_list('Dw Task Template', {
		filters: [
			['reference_type', '=', 'Dw Organization Worklist'],
			['reference_name', '=', props.frm.doc.name],
			['disable', '=', 0],
		],
		fields: ['*'],
		limit: 0,
	});
	if (result.length > 0) {
		const inUseNames = [];
		for (const td of tableData.value) {
			const enabled = result.find(item => {
				if (item.source_task_template && item.source_task_template === td.name) {
					return item;
				}
			});
			td.enabled = false;
			if (enabled) {
				inUseNames.push(td.name);
				td.enabled = true;
			}
		}
		inUseStandardTemplateNames.value = inUseNames;
	} else {
		inUseStandardTemplateNames.value = [];
	}
}

// 获取启用中的标准任务模板
async function enableTemplate() {
	const names = [];
	if (selectData.value.length === 0) {
		ElMessage({
			message: __('Select a template and enable it'),
			type: 'warning',
		});
		return;
	}
	// 剔除已启用的
	for (const td of selectData.value) {
		if (!inUseStandardTemplateNames.value.includes(td.name)) {
			names.push(td.name);
		}
	}
	await frappe.call<{ message: any }>({
		method: 'dw_worklist_management.dw_task_management.doctype.dw_task_template.dw_task_template.enable_standard_task_templates',
		args: {
			organization_worklist_id: props.frm.doc.name,
			task_templates: names,
		},
	});
	ElMessage({
		message: __('Execution Succeeded'),
		type: 'success',
	});
	getEnableTemplate();
	triggerEvent('organizationWorkListChange');
}

async function is_applier(specific_organization: string) {
	if (!specific_organization) {
		return false;
	}
	const appliers = await frappe.db.get_list('Dw Organization Worklist Member', {
		filters: [['organization_unit', '=', specific_organization]],
		fields: ['user'],
		limit: 0,
	});
	return appliers.some(item => item.user === frappe.session.user);
}

async function getFormEditable() {
	if (frappe.session.user === 'Administrator' || frappe.user.has_role('System Manager')) {
		return true;
	}
	if (props.frm.doc.doctype === 'Dw Organization Worklist' && props.frm.doc.organization_unit) {
		return is_applier(props.frm.doc.organization_unit);
	}
	return false;
}

function getSelectRows(rows: TaskTemplate[]) {
	selectData.value = rows;
}

const popstateListener = function () {
	getTableData();
};

async function setPermission() {
	manager.value = await getFormEditable();
}

onMounted(() => {
	standardWorklistName.value = props.frm.doc.dw_standard_worklist;
	getTableData();
	setPermission();
	window.addEventListener('popstate', popstateListener);
});

onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});

</script>


<style lang="less" scoped>
.operation-button{
	margin-top: 10px;
	display: flex;
	flex-direction: row;
}
</style>
