<template>
	<ElTree
		class="structure-tree"
		:key="project"
		:props="optionProps"
		:load="loadNode"
		lazy
		node-key="name"
		@node-click="nodeClick"
		highlight-current />
</template>
<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';
import type Node from 'element-plus/es/components/tree/src/model/node';

import { StructureORProgress } from './type';

interface Props {
	project: string;
}
interface Emit{
	(e:'structureChange', structureORProgress:StructureORProgress):void
}
const emit = defineEmits<Emit>();
const props = defineProps <Props>();
interface Tree {
	name: string;
	leaf?: boolean;
}

const optionProps = {
  label: 'label',
  isLeaf: 'leaf',
};
async function loadNode(node: Node, resolve: (data: Tree[]) => void) {
	if (!props.project) { return; }
	const nameArr = node?.data?.name?.split('_')||[];
	const data = await frappe.call < { message: StructureORProgress[] } >({
		method: 'guigu_pm.guigu_pm_construction.page.photo_timeline.photo_timeline.load_structure_lazy',
		args: { project: props.project, structure: nameArr[nameArr.length-1] },
	});
	resolve(data?.message||[]);
}
function nodeClick(structureORProgress:StructureORProgress){
	emit('structureChange', structureORProgress);
}
</script>
<style lang="less" scoped>
.structure-tree {
	height: 100%;
	overflow-y: scroll;
}
</style>
