import { View, widgets, pretreat } from '../Timeline';
import defineMainView from '../extends/init/defineMainView';
import { Config } from '../Timeline/type/index';

frappe.provide('frappe.views.timeline');
const notValueField = [
	'Column Break',
	'Heading',
	'Section Break',
	'Table MultiSelect',
	'Tab Break',
	'Connection Table',
];

defineMainView<Config>({
	type: 'Guigu Timeline View Configuration',
	label: 'Timeline',

	sider: ['filters', 'filterList'],
	toolbar: ['filterLine'],
	detail: true,
	view: View,
	infiniteScroll: true,
	pretreat: [pretreat],
	configuration: {
		filterField: true,
		widgets,
		displayField: {
			useDefault: true,
			dateExtField: true,

			fieldFilter: f => !notValueField.includes(f.fieldtype),
			commentField: true,
		},
	},
	async getConfigurations(meta, { time_field, order }: any, view) {
		const config = frappe.views.timeline[meta.name];
		if (!config) { return { time_field, order }; }

		const dateOrder = config?.init?.order?.find(item => item.field === config.time_field);
		return {
			time_field: config.time_field || time_field,
			order: order || (dateOrder?.desc ? 'Desc' : 'Asc'),
		};
	},
	*defaultOrder(order, meta, cfg) {
		yield* order;
		const timeField = cfg.time_field;
		if (timeField) {
			yield { field: timeField, desc: cfg.order.toUpperCase() !== 'ASC' };
		}
	},
});
