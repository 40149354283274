<template>
	<div class="form-container">
		<ElForm>
			<el-form-item label="应用名称">
				{{ appInfo.app_name }}
			</el-form-item>
			<el-form-item label="应用版本">
				{{ appInfo.app_version }}
			</el-form-item>
			<el-form-item label="git分支">
				{{ appInfo.git_branch }}
			</el-form-item>
		</ElForm>
	</div>
</template>

<script setup lang='ts'>
import { ref } from 'vue';
import { ElForm, ElFormItem } from 'element-plus';
import { AppInfo } from './type';

interface Props{
	appInfo:AppInfo
}
const props = defineProps<Props>();

</script>

<style lang='less' scoped>
.form-container{
	padding: 8px;
}
</style>
