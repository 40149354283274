import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-162140cf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-container" }
const _hoisted_2 = { class: "left-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElOption = _resolveComponent("ElOption")
  const _component_ElSelect = _resolveComponent("ElSelect")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString($setup.tt('Total Score')) + ":", 1 /* TEXT */),
        _createTextVNode(_toDisplayString($props.totalScore), 1 /* TEXT */)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString($setup.tt('Level')) + ":", 1 /* TEXT */),
        _createVNode(_component_ElSelect, {
          disabled: 
						$props.lockStatus || !$props.assessPermission?.writable.has('level')
					,
          class: "header-level",
          modelValue: $setup.level,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.level) = $event)),
          onChange: $setup.changeLevel
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElOption, {
              label: "",
              value: ""
            }),
            _createVNode(_component_ElOption, {
              key: "A",
              label: "A",
              value: "A"
            }),
            _createVNode(_component_ElOption, {
              key: "B+",
              label: "B+",
              value: "B+"
            }),
            _createVNode(_component_ElOption, {
              key: "B",
              label: "B",
              value: "B"
            }),
            _createVNode(_component_ElOption, {
              key: "C",
              label: "C",
              value: "C"
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled", "modelValue"])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString($setup.tt('Assessor')) + ":", 1 /* TEXT */),
        _createVNode(_component_el_select, {
          onChange: $setup.changeSelectLeader,
          filterable: "",
          disabled: $setup.select_leader_disabled,
          modelValue: $props.assessInfo?.select_leader_doc?.name
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.selectLeaderList, (user) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                label: user.full_name,
                value: user.name
              }, null, 8 /* PROPS */, ["label", "value"]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled", "modelValue"])
      ])
    ])
  ]))
}