
import {createApp} from 'vue';

import Excel from './excel/index.vue';
frappe.provide('frappe.guigu_material');

export interface RenderProps{
	frm:any,
	renderField:string,
	props:Record<string, any>
}

frappe.guigu_material.excel_render = function({
	frm,
	renderField,
	props,
}:RenderProps) {
	$(frm.fields_dict.html.wrapper).html('');
	const readOnly = frm.fields_dict[renderField].disp_status !== 'Write';
	const html_wrapper = $(frm.fields_dict[renderField].wrapper).find('div.html');
	const appName = `${props.field}_app`;
	if (html_wrapper.length === 0) {
		$(frm.fields_dict[renderField].wrapper).html(`
			<div class="html">
			</div>
		`);
		const app = createApp(Excel, {
			...props,
			frm,
			name: frm.doc.name,
			readOnly,
		});
		app.mount($(frm.fields_dict[renderField].wrapper).find('.html')[0]);
		frm[appName] = app;
	}
	if (frm[appName]) {
		frm[appName]._instance.props.columns = props.columns;
		frm[appName]._instance.props.data = props.data;
		frm[appName]._instance.props.frm = frm;
		frm[appName]._instance.props.name = frm.doc.name;
		frm[appName]._instance.props.readOnly = readOnly;
		frm[appName]._instance.props.mergeCells = props.mergeCells;
	}
};
