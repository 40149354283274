import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.value && $setup.docMeta && $setup.name)
    ? (_openBlock(), _createBlock($setup["Main"], {
        key: `${$setup.docMeta.name}:${$props.value.name}`,
        meta: $setup.docMeta,
        options: $props.options,
        loading: $props.loading,
        name: $setup.name,
        isHideClose: $props.isHideClose,
        value: $setup.doc,
        "onUpdate:value": _cache[0] || (_cache[0] = $event => (($setup.doc) = $event)),
        onRefresh: $setup.refresh
      }, null, 8 /* PROPS */, ["meta", "options", "loading", "name", "isHideClose", "value"]))
    : _createCommentVNode("v-if", true)
}