<template>
	<UploadFormItem
		v-for="file in fileList"
		:ref="setItemRef"
		:photo="file"
		:structures="structures"
		:date="date"
		:showDateInput="showDateInput"
		:defaultStructureId="defaultStructureId"
		@deleteFile="deleteFile"
	></UploadFormItem>
</template>
<script lang="ts" setup>
import { FormInstance } from 'element-plus/lib/components/index.js';
import { defineProps, ref, defineEmits, toRefs, defineExpose, onUnmounted } from 'vue';

import { StructureItemFormat } from '../../../type/diary';
import {ImageFile, ImageFormValue} from '../../../type/image';

import UploadFormItem from './UploadFormItem.vue';
interface Props{
	fileList:ImageFile[],
	structures:StructureItemFormat[],
	date:string
	showDateInput?:boolean,
	defaultStructureId?:string
}
interface Emits{
	(e:'deleteFile', uid:string):void
}
const props = defineProps<Props>();
const emit = defineEmits<Emits>();
const { structures, fileList, date, showDateInput, defaultStructureId} = toRefs(props);
let itemRefs:{formRef:FormInstance, key:string, formValue:ImageFormValue}[]= [];
function setItemRef(el:{formRef:FormInstance, key:string, formValue:ImageFormValue}){
	if (!el){ return; }
	if (!el.formRef){ return; }
	if (itemRefs.some(item=>item.key === el.key)){
		return;
	}
	itemRefs.push(el);
}
onUnmounted(()=>{
	itemRefs = [];
});
defineExpose({
	formRefs:itemRefs,
});
function deleteFile(uid:string){
	emit('deleteFile', uid);
}
</script>
