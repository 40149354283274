import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aff4bafa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "attendance" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.attendanceTypes, (type) => {
      return (_openBlock(), _createElementBlock("span", {
        key: type.name,
        class: "type"
      }, [
        _createElementVNode("span", null, _toDisplayString(type.type_name) + ":", 1 /* TEXT */),
        _createElementVNode("span", {
          style: _normalizeStyle({ background: type.color || '#373b415e', padding: '4px 8px' })
        }, _toDisplayString(type.type_symbol), 5 /* TEXT, STYLE */)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}