const old_make_field = frappe.ui.Filter.prototype.make_field;
frappe.ui.Filter.prototype.make_field = function(df, old_fieldtype){

	let old_text = this.field ? this.field.get_value() : null;
	this.hide_invalid_conditions(df.fieldtype, df.original_type);
	this.toggle_nested_set_conditions(df);
	let field_area = this.filter_edit_area.find('.filter-field').empty().get(0);
	df.input_class = 'input-xs';
	let f = frappe.ui.form.make_control({
		df: df,
		parent: field_area,
		only_input: true,
	});
	f.refresh();

	this.field = f;
	if (old_text && f.fieldtype === old_fieldtype) {
		this.field.set_value(old_text);
	}
	if (this.value!==old_text){
		let {value} = this;
		if (this.field.df.original_type === 'Check') {
			value = value == 1 ? 'Yes' : 'No';
		}
		if (['in', 'not in'].includes(this.condition) && Array.isArray(value)) {
			value = value.join(',');
		}
		if (Array.isArray(value)) {
			this.field.set_value(value);
		} else if (value !== undefined || value !== null) {
			this.field.set_value((`${value}`).trim());
		}
	}
	this.bind_filter_field_events();
};
