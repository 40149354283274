
function format_attachment_url(url) {
	let filename = '';
	if (url){
		const filenameArr = url?.split('/');
		filename = filenameArr.pop();
		const isPrivate = filenameArr.some(item=>item==='private');
		filename = `[${isPrivate?__('Private'):__('Public')}]${filename}`;
	}
	return url ? `<a href="${url}" target="_blank">${filename}</a>` : '';
}
frappe.form.formatters.Attach = format_attachment_url;
frappe.form.formatters.AttachImage = format_attachment_url;
