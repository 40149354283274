import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.expanders.length)
    ? (_openBlock(), _createBlock($setup["ElButtonGroup"], { key: 0 }, {
        default: _withCtx(() => [
          _createVNode($setup["ElButton"], {
            onClick: _cache[0] || (_cache[0] = $event => ($setup.set())),
            round: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('Fold')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode($setup["ElDropdown"], {
            onCommand: $setup.set,
            teleported: false
          }, {
            dropdown: _withCtx(() => [
              _createVNode($setup["ElDropdownMenu"], null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.expanders, (item) => {
                    return (_openBlock(), _createBlock($setup["ElDropdownItem"], {
                      key: item.key,
                      command: item
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.title), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["command"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              })
            ]),
            default: _withCtx(() => [
              _createVNode($setup["ElButton"], { round: "" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.tt('Expand')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }))
    : _createCommentVNode("v-if", true)
}