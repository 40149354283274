import type { Line, LineMeta } from './types';
import date2n from './date2n';

export default function renderLine(
	data: any,
	allDates: ([Date, Date | null, (LineMeta | undefined)?] | null)[],
	{ color, className, title, borderColor }: Line,
	index: number,
	todayN: number,
	prefix: string) {

	const date = allDates[index];
	if (!date) { return; }
	const [start, end] = date;
	// 计算开始位置及总长度
	const startN = date2n(start);
	const endN = date2n(end || new Date);
	const width = endN - startN;
	const left = startN - todayN;

	const el = document.createElement('div');
	el.className = 'nyloong-table-gantt-line';
	el.style.setProperty('--nyloong-table-gantt-line-begin', `${left}`);
	el.style.setProperty('--nyloong-table-gantt-line-size', `${width}`);
	el.style.setProperty('--nyloong-table-gantt-line-position', `var(${prefix}-line-position-${index})`);
	el.style.setProperty('--nyloong-table-gantt-line-weight', `var(${prefix}-line-height-${index})`);
	if (typeof color === 'function') {
		const c = color(data, index, allDates);
		if (c) { el.style.background = c; }
	}
	if (typeof borderColor === 'function') {
		const c = borderColor(data, index, allDates);
		if (c) { el.style.borderColor = c; }
	}
	if (typeof className === 'function') {
		const v = className(data, index, allDates);
		if (v) { el.className += ` ${v}`; }
	}
	if (typeof title === 'function') {
		const v = title(data, index, allDates);
		if (v) { el.title += title; }
	}
	return el;
}
