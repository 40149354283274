<template>
	<span :style="{ color: isRed }">{{ props.params.currentCount }}</span>
</template>
<script lang="ts" setup>
import { defineProps } from 'vue';

const props = defineProps<{ params: { value: any, totalCount: number, currentCount: number } }>();
const isRed = Number(props.params?.currentCount || 0) < props.params?.totalCount ? 'red' : 'black';
</script>

<style scoped lang="less">
</style>
