<template>
	<div class="button-container">
		<el-button
			type="primary"
			link
			v-if="props.params.clickDiary"
			@click="clickDiary">
			<a>
				{{ props.params?.data?.diary ? tt("Log viewing"):tt("Log filling")}}
			</a>
		</el-button>
		<ElPopconfirm
			v-if="props.params.onRemove"
			:title="tt('Are you sure you want to delete it?')"
			@confirm="remove"
			:confirm-button-text="tt('Confirm')"
			:cancel-button-text="tt('Cancelled')">
			<template #reference>
				<div class="delete-icon">
					<ElTooltip placement="top" :content="tt('Delete')">
						<ElIcon>
							<Delete color='#ff4d4f' />
						</ElIcon>
					</ElTooltip>
				</div>
			</template>
		</ElPopconfirm>
	</div>
</template>
<script lang="ts" setup>
import { defineProps, toRefs } from 'vue';
import { Delete } from '@element-plus/icons-vue';
import {
	ICellRendererParams,
} from 'ag-grid-community';
const tt = __
const props = defineProps<{ params: ICellRendererParams }>();
function clickDiary() {
	props.params.clickDiary(props.params.data);
}
function remove() {
	props.params.onRemove(props.params.data);
}
</script>
<style lang="less" scoped>
.button-container {
	height: 27px;
}

.delete-icon {
	display: inline-block;
	height: 18px;
	line-height: 20px;
	vertical-align: middle;
}
</style>
