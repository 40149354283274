import type { Ref } from 'vue';
import { computed, unref } from 'vue';

import getLink from './getLink';

export default function useDocHref(
	meta: Ref<locals.DocType> | locals.DocType,
	data: Ref<any> | any,
	linkField?: Ref<string | undefined> | string,
) {
	const link = computed(() => getLink(unref(data), unref(meta), unref(linkField)));
	const href = computed(() => {
		const linkValue = link.value || [unref(meta).name, unref(data).name];
		const [doctype, name] = linkValue;
		return `/app/${frappe.router.slug(doctype)}/${encodeURIComponent(name)}`;
	});
	return href;

}
