import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3cc7a199"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "member" }
const _hoisted_2 = { class: "btn-container" }
const _hoisted_3 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($props.permissions.createPermission)
        ? (_openBlock(), _createBlock($setup["ElButton"], {
            key: 0,
            type: "primary",
            onClick: $setup.createMember
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('New personnel added')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      ($props.permissions.createPermission)
        ? (_openBlock(), _createBlock($setup["ElButton"], {
            key: 1,
            type: "primary",
            onClick: $setup.batchCreateMembers
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('Batch adding personnel')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      ($props.permissions.createPermission && $setup.selectedMembers.length)
        ? (_openBlock(), _createBlock($setup["ElButton"], {
            key: 2,
            type: "primary",
            onClick: $setup.bulkEditRoles
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('Batch editing')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      ($props.permissions.deletePermission && $setup.selectedMembers.length)
        ? (_openBlock(), _createBlock($setup["ElButton"], {
            key: 3,
            type: "danger",
            onClick: $setup.bulkDelete
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('Bulk Delete')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _createVNode($setup["ElTable"], {
      "cell-class-name": "member-cell",
      onSelectionChange: $setup.handleSelectionChange,
      data: $setup.memberList,
      border: "",
      style: {"width":"100%"},
      height: "100%",
      "empty-text": $setup.tt('No Data')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElTableColumn"], {
          type: "selection",
          width: "55"
        }),
        _createVNode($setup["ElTableColumn"], {
          fixed: "",
          prop: "user_doc.full_name",
          label: $setup.tt('User'),
          width: "180"
        }, null, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          prop: "role",
          label: $setup.tt('Role')
        }, {
          default: _withCtx((scope) => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.roles, (roleDoc) => {
              return (_openBlock(), _createElementBlock("span", {
                class: "role",
                onClick: $event => ($setup.showPermissions(roleDoc.role))
              }, _toDisplayString($setup.tt(roleDoc.role)), 9 /* TEXT, PROPS */, _hoisted_3))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          prop: "visible",
          label: $setup.tt('Seen'),
          width: "60"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.visible ? $setup.tt('Yes') : $setup.tt('No')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          prop: "viewable",
          label: $setup.tt('Viewable'),
          width: "60"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.viewable ? $setup.tt('Yes') : $setup.tt('No')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          prop: "addible",
          label: $setup.tt('Addible'),
          width: "60"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.addible ? $setup.tt('Yes') : $setup.tt('No')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          prop: "editable",
          label: $setup.tt('Editable'),
          width: "60"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.editable ? $setup.tt('Yes') : $setup.tt('No')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          prop: "deletable",
          label: $setup.tt('Deletable'),
          width: "60"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.deletable ? $setup.tt('Yes') : $setup.tt('No')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          prop: "manageable",
          label: $setup.tt('Manageable'),
          width: "60"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.manageable ? $setup.tt('Yes') : $setup.tt('No')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        (
				$props.permissions.writePermission || $props.permissions.deletePermission
			)
          ? (_openBlock(), _createBlock($setup["ElTableColumn"], {
              key: 0,
              prop: "address",
              label: $setup.tt('Action'),
              width: "130"
            }, {
              default: _withCtx((scope) => [
                ($props.permissions.writePermission)
                  ? (_openBlock(), _createBlock($setup["ElButton"], {
                      key: 0,
                      type: "primary",
                      onClick: $event => ($setup.editMember(scope.row))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.tt('Edit')), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                  : _createCommentVNode("v-if", true),
                ($props.permissions.deletePermission)
                  ? (_openBlock(), _createBlock($setup["ElButton"], {
                      key: 1,
                      type: "danger",
                      onClick: $event => ($setup.deleteMember(scope.row))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.tt('Delete')), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["data", "empty-text"]),
    _createVNode($setup["BulkMembers"], {
      visible: $setup.visible,
      "onUpdate:visible": _cache[0] || (_cache[0] = $event => (($setup.visible) = $event)),
      organization: $props.organization,
      memberList: $setup.memberList,
      onLoading: _cache[1] || (_cache[1] = $event => ($setup.loading = true))
    }, null, 8 /* PROPS */, ["visible", "organization", "memberList"]),
    _createVNode($setup["BulkRoles"], {
      visible: $setup.roleVisible,
      "onUpdate:visible": _cache[2] || (_cache[2] = $event => (($setup.roleVisible) = $event)),
      selected: $setup.selectedMembers,
      organization: $props.organization,
      onLoading: _cache[3] || (_cache[3] = $event => ($setup.loading = true))
    }, null, 8 /* PROPS */, ["visible", "selected", "organization"])
  ])), [
    [$setup["vLoading"], $setup.loading]
  ])
}