<template>
	<ElForm labelPosition="top" @submit.prevent>
		<ElFormItem :label="tt('Label')">
			<ElInput v-model="label" />
		</ElFormItem>
		<ElFormItem :label="tt('DocType')">
			<DoctypeSelect v-model="doctype" @label="doctypeLabel = $event" />
		</ElFormItem>
		<ElTabs v-model="activeName" v-if="meta">
			<ElTabPane label="基本配置" name="info">
				<Info :meta="meta" v-model="view" />
			</ElTabPane>
			<ElTabPane label="显示字段" name="fields">
				<ElFormItem>
					<Fields :meta="meta" v-model="view.fields" />
				</ElFormItem>
			</ElTabPane>
			<ElTabPane label="过滤条件" name="filters">
				<ElFormItem>
					<div class="filterContainer">
						<div class="at">当</div>
						<Filters :doctype="doctype" v-model="view.filters" />
					</div>
					<OrFilters :doctype="doctype" v-model="view.orFilters" />
				</ElFormItem>
			</ElTabPane>
			<ElTabPane label="排序规则" name="sorts">
				<ElFormItem>
					<Sorts :doctype="doctype" v-model="view.order" />
				</ElFormItem>
			</ElTabPane>
			<ElTabPane label="样式" name="style">
				<Style v-model="view.style" />
			</ElTabPane>
		</ElTabs>
	</ElForm>
</template>

<script setup lang="ts">
import {computed, ref, toRaw, watch} from 'vue';
import {ElForm, ElTabPane, ElTabs, ElFormItem, ElInput} from 'element-plus';

import Info from './Info.vue';
import Fields from './Fields.vue';
import Style from './Style.vue';
import Sorts from './Sorts.vue';
import Filters from './Filters.vue';
import OrFilters from './OrFilters.vue';
import DoctypeSelect from './DoctypeSelect.vue';

const tt = __;
const activeName = ref('info');

export interface MenuItem {
	component: any;
	key: string;
	label: string;
}

const props = defineProps<{
	value?: any;
}>();
const emit = defineEmits<{
	(event: 'update', data: any, submittable?: boolean): void;
	(event: 'cancel' | 'submit'): void;
}>();

const view = ref<any>(props.value || {});
const doctypeLabel = ref('');

const label = computed({
	get: () => view.value.label,
	set: label => {
		view.value = {...view.value, label};
	},
});
watch(doctypeLabel, (v, o) => {
	if (!v) {
		return;
	}
	const l = label.value;
	if (!l || l === o) {
		label.value = v;
	}
});

watch(view, view => {
	emit('update', toRaw(view));
}, {deep: true});
const doctype = computed({
	get: () => view.value.doctype,
	set: doctype => {
		view.value = {
			...view.value, doctype,
			fields: [],
			fieldsRequested: [],
			filters: undefined,
			order: undefined,
			linkField: undefined,
		};
	},
});

const meta = ref<locals.DocType>();
watch(doctype, async dt => {
	if (!dt) {
		return;
	}
	const m = meta.value;
	if (m?.name === dt) {
		return;
	}
	if (m) {
		meta.value = undefined;
	}
	await new Promise<void>(resolve => {
		frappe.model.with_doctype(dt, () => {
			resolve();
		}, true);
	});
	const m2 = frappe.get_meta(dt);
	if (!m2 || m2.name !== doctype.value) {
		return;
	}
	meta.value = m2;
}, {immediate: true});


</script>
<style scoped lang="less">
.filterContainer{
	display: flex;
	align-items: center;
	width: 100%;
}
.at{
	padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-inline-end: 8px;
}
</style>
