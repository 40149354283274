function getFieldName(dt: string, field: string) {
	return frappe.model.get_full_column_name(field, dt);
}

const linkType = new Set(['Link', 'Tree Select', 'Tianjy Related Link']);
const linkMap = new Map<string, [string, string]>([
	['Tianjy Enumeration', ['Tianjy Enumeration Value', 'label']],
]);
export default async function requestDocList(
	meta: locals.DocType,
	filters: [string, string, string, any][],
	{fields, group, limit, offset, order, orFilters}: GlobalView.MainLoaderOptions,
) {
	const doctype = meta.name;
	const fieldSet = new Set<string>();
	const titleField = meta.title_field;
	const links = new Map<string, {
		field: string;
		props: Set<string>;
	}>();
	function addLink(dt: string, titleField: string, prop: string) {
		const v = links.get(dt);
		if (v) {
			v.props.add(prop); return;
		}
		links.set(dt, {field: titleField, props: new Set([prop])});
	}
	const fileFields: string[] = [];
	const multiTableFields: string[] = [];
	for (const [field, dt] of fields) {
		const meta = frappe.get_meta(dt);
		if (!meta) {
			continue;
		}
		let docField = meta.fields.find(f => f.fieldname === field)
			|| frappe.model.std_fields.find(f => f.fieldname === field);
		if (!docField) {
			continue;
		}
		if (docField.fieldtype === 'Table MultiSelect' || docField.fieldtype === 'Tianjy Tree Table MultiSelect') {
			multiTableFields.push(docField.fieldname);
			continue;
		}
		fieldSet.add(getFieldName(dt, field));
		if (docField.fieldtype === 'Attach' || docField.fieldtype === 'Attach Image') {
			fileFields.push(docField.fieldname);
		}
		const targetDoctype = linkMap.get(docField.fieldtype);
		if (targetDoctype) {
			const {fieldname} = docField;
			const [options, titleField] = targetDoctype;
			if (dt === doctype) {
				addLink(options, titleField, fieldname);
				continue;
			}
			if (options === dt) {
				continue;
			}
			const fieldSql = `${fieldname}.${titleField}`;
			const name = `${fieldname}.title`;
			fieldSet.add(`${fieldSql} as \`${name}\``);
			continue;
		}
		if (docField.fieldtype === 'Dynamic Link') {
			const {options} = docField;
			if (!options) {
				continue;
			}
			docField = meta.fields.find(f => f.fieldname === options);
			if (!docField) {
				continue;
			}
			if (linkType.has(docField.fieldtype)) {
				continue;
			}
			fieldSet.add(getFieldName(dt, docField.fieldname));
		} else if (!linkType.has(docField.fieldtype)) {
			continue;
		}
		let {options} = docField;
		if (typeof options !== 'string') {
			continue;
		}
		[options] = options.split('\n');
		if (!options) {
			continue;
		}
		const {fieldname} = docField;
		if (fieldname === 'name') {
			continue;
		}
		const title_field = options === doctype
			? titleField
			: frappe.get_meta(options)?.title_field;
		if (!title_field) {
			continue;
		}
		if (dt === doctype) {
			addLink(options, title_field, fieldname);
			continue;
		}
		if (options === dt) {
			continue;
		}
		const fieldSql = `${fieldname}.${title_field}`;
		const name = `${fieldname}.title`;
		fieldSet.add(`${fieldSql} as \`${name}\``);
	}
	const allLinks = new Map<string, {
		field: string;
		props: Set<string>;
	}>();

	for (const [k, {field, props}] of links) {
		allLinks.set(k, {field, props});
	}
	const data = await frappe.call('frappe.desk.reportview.get', {
		doctype, fields: [...fieldSet], filters,
		order_by: order
			.map(({doctype, field, desc}) =>
				`${getFieldName(doctype, field)} ${desc ? 'DESC' : 'ASC'}`)
			.join(', ') || undefined,
		start: offset,
		page_length: limit,
		view: 'List',
		guigu_or_filters: orFilters,
		group_by: group
			.map(({doctype, field}) => getFieldName(doctype, field))
			.join(', ') || getFieldName(doctype, 'name'),
		with_comment_count: false,
	}).then((v: any) => v.message || {});
	Object.assign(frappe.boot.user_info, data.user_info);
	const values = Array.isArray(data)
		? data
		: frappe.utils.dict(data.keys, data.values);
	const promises: PromiseLike<any>[] = [];
	for (const [dt, {field, props}] of allLinks) {
		if (!frappe.perm.has_perm(dt)) {
			continue;
		}
		const list = [...props].flatMap(p => values.map(v => v[p])).filter(Boolean);
		promises.push(frappe.call('frappe.desk.reportview.get', {
			doctype: dt,
			fields: ['*'],
			filters: [[dt, 'name', 'in', [...new Set(list)]]],
			page_length: 0,
			view: 'List',
			with_comment_count: false,
		}).then((v: any) => v.message || {}).then(data => {
			Object.assign(frappe.boot.user_info, data.user_info);
			const mapValue = Array.isArray(data)
				? data
				: frappe.utils.dict(data.keys, data.values);
			const map = new Map(mapValue.map(v => [v.name, v[field]]));
			const styleMap = new Map(mapValue.map(v => [v.name, {
				backgroundColor: v.background_color || v.background_colors || v.background,
				color: v.color || v.colors,
			}]));
			for (const p of props) {
				for (const v of values) {
					v[`${p}.title`] = map.get(v[p]);
					v[`${p}.style`] = styleMap.get(v[p]);
				}
			}
		}));
	}
	if (fileFields.length) {
		const files = values.flatMap(v => fileFields.map(f => v[f])).filter(Boolean);
		const getFields = [
			'attached_to_field', 'attached_to_name', 'file_url', 'file_name',
		];
		if (files.length) {
			promises.push(frappe.call('frappe.desk.reportview.get', {
				doctype: 'File',
				fields: getFields.map(f => getFieldName('File', f)),
				filters: [
					['File', 'attached_to_doctype', '=', doctype],
					['File', 'attached_to_name', 'in', values.map(i => i.name)],
					['File', 'attached_to_field', 'in', fileFields],
					['File', 'file_url', 'in', files],
				],
				page_length: 0,
				view: 'List',
				with_comment_count: false,
			}).then((v: any) => v.message || {}).then(data => {
				Object.assign(frappe.boot.user_info, data.user_info);
				const fileList = Array.isArray(data)
					? data
					: frappe.utils.dict(data.keys, data.values);
				const map = new Map<string, Map<string, Map<string, string>>>();
				for (const {
					attached_to_field, attached_to_name, file_url, file_name,
				} of fileList) {
					let docMap = map.get(attached_to_name);
					if (!docMap) {
						docMap = new Map();
						map.set(attached_to_name, docMap);
					}
					let fileMap = docMap.get(file_url);
					if (!fileMap) {
						fileMap = new Map();
						docMap.set(file_url, fileMap);
					}
					fileMap.set(attached_to_field, file_name);
				}
				for (const v of values) {
					const docMap = map.get(v.name);
					if (!docMap) {
						continue;
					}
					for (const field of fileFields) {
						const value = v[field];
						if (!value) {
							continue;
						}
						const label = docMap.get(value)?.get(field);
						if (!label) {
							continue;
						}
						v[`${field}.label`] = label;
					}
				}
			}));
		}
	}
	if (multiTableFields.length) {
		promises.push(frappe.call<{message:File[]}>({
			method: 'guigu.view.multi_table.get_multi_table',
			args: {
				doctype: doctype,
				fieldnames: multiTableFields,
				names: values.map(item=>item.name),
			},

		}).then((v: any) => v.message || {}).then(data => {
			const multiTableList = Array.isArray(data)
				? data
				: frappe.utils.dict(data.keys, data.values);
			const map = new Map<string, Map<string, any[]>>();
			for (const tableData of multiTableList) {
				let fieldMap = map.get(tableData.parent);
				if (!fieldMap) {
					fieldMap = new Map();
					map.set(tableData.parent, fieldMap);
				}
				let dataList = fieldMap.get(tableData.parentfield);
				if (!dataList) {
					dataList = [];
					fieldMap.set(tableData.parentfield, dataList);
				}
				dataList.push(tableData);
			}
			for (const v of values) {
				const dataList = map.get(v.name);
				if (!dataList) {
					continue;
				}
				for (const field of multiTableFields) {
					v[field] = dataList.get(field);
				}
			}
		}));
	}
	if (fields.some(f => f[0] === '_comments')) {
		const commentFields = [
			'name', 'creation', 'content', 'owner', 'comment_type', 'reference_name', 'comment_by',
		];
		promises.push(frappe.call('frappe.desk.reportview.get', {
			doctype: 'Comment',
			fields: commentFields.map(f => getFieldName('Comment', f)),
			filters: [
				['Comment', 'reference_doctype', '=', doctype],
				['Comment', 'reference_name', 'in', values.map(i => i.name)],
				['Comment', 'comment_type', '=', 'Comment'],
			],
			page_length: 0,
			view: 'List',
			with_comment_count: false,
			order_by: `${getFieldName('Comment', 'creation')} DESC`,
		}).then((v: any) => v.message || {}).then(data => {
			Object.assign(frappe.boot.user_info, data.user_info);
			const mapValue = Array.isArray(data)
				? data
				: frappe.utils.dict(data.keys, data.values);
			for (const v of values) {
				v._comments = JSON.stringify(mapValue.find(c => c.reference_name === v.name) || {});
			}
		}));
	}
	await Promise.all(promises.map(p => p.then(null, () => { })));
	return values;
}
