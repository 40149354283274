<template>
	<div class="member" v-loading="loading">
		<ElTable cell-class-name="member-cell" :data="memberList" border style="width: 100%" height="100%" :empty-text="tt('No Data')">
			<ElTableColumn fixed prop="user_doc.full_name" :label="tt('User')" width="180" />
			<ElTableColumn prop="role" :label="tt('Role')">
				<template #default="scope">
					<span class="role" @click="showPermissions(roleDoc.role)" v-for="roleDoc in scope.row.roles">{{
						tt(roleDoc.role) }}</span>
				</template>
			</ElTableColumn>
			<ElTableColumn prop="visible" :label="tt('Seen')" width="60">
				<template #default="scope">
					{{ scope.row.visible ? tt('Yes') : tt('No') }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="viewable" :label="tt('Viewable')" width="60">
				<template #default="scope">
					{{ scope.row.viewable ? tt('Yes') : tt('No') }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="addible" :label="tt('Addible')" width="60">
				<template #default="scope">
					{{ scope.row.addible ? tt('Yes') : tt('No') }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="editable" :label="tt('Editable')" width="60">
				<template #default="scope">
					{{ scope.row.editable ? tt('Yes') : tt('No') }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="deletable" :label="tt('Deletable')" width="60">
				<template #default="scope">
					{{ scope.row.deletable ? tt('Yes') : tt('No') }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="manageable" :label="tt('Manageable')" width="60">
				<template #default="scope">
					{{ scope.row.manageable ? tt('Yes') : tt('No') }}
				</template>
			</ElTableColumn>

			<ElTableColumn v-if="
				permissions.writePermission || permissions.deletePermission
			" prop="address" :label="tt('Action')" width="60">
				<template #default="scope">
					<ElButton v-if="permissions.writePermission" type="primary" @click="editMember(scope.row)">{{ tt('Detail') }}
					</ElButton>
				</template>
			</ElTableColumn>
		</ElTable>
	</div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { ElButton, ElTable, ElTableColumn, vLoading } from 'element-plus';

import type { Member, Permissions } from '../type';

import { showPermissions } from './helper';

interface Props {
	organization: string;
	permissions: Permissions;
}
const props = defineProps<Props>();
const memberList = ref<Member[]>([]);
const loading = ref<boolean>(false);
const tt = __;

watch(
	() => props.organization,
	() => {
		getMembers();
	},
	{ immediate: true },
);

async function getMembers() {
	if (!props.organization) {
		return;
	}
	loading.value = true;
	const res = await frappe.call<{ message: Member[]; }>({
		method: 'tianjy_organization.tianjy_organization.page.tianjy_organization_config.tianjy_organization_config.get_members',
		args: {
			organization_name: props.organization,
			is_inherit: 1,
		},
	});
	memberList.value = res?.message || [];
	loading.value = false;
}

function editMember(member: Member) {
	frappe.set_route(['form', 'Tianjy Organization Member', member.name]);
}

frappe.socketio.doctype_subscribe('Tianjy Organization Member');

frappe.realtime.on('list_update', p => {
	if (
		p.doctype !== 'Tianjy Organization Member' &&
		p.doctype !== 'Tianjy Organization Role'
	) {
		return;
	}
	getMembers();
});

const popstateListener = function (event: any) {
	getMembers();
};
onMounted(() => {
	window.addEventListener('popstate', popstateListener);
});
onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});
</script>

<style lang="less" scoped>
.member {
	height: 100%;
	display: flex;
	flex-direction: column;

	.btn-container {
		text-align: right;
		margin-bottom: 8px;
	}

	.role {
		cursor: pointer;
		margin-right: 4px;

		&:hover {
			text-decoration: underline;
		}
	}

	:deep(.member-cell .cell) {
		overflow: hidden;
		white-space: nowrap;
	}
}
</style>
