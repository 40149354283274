<template>
	<AgGridVue
		v-if="!loading"
		class="ag-theme-guigu table"
		style="height: 95%;width: 100%;margin: 0;overflow: auto;"
		treeData
		animateRows
		suppressRowClickSelection
		rowSelection="multiple"
		:rowData="dataList"
		:columnDefs="columnDefs"
		:defaultColDef="defaultColDef"
		:suppressDragLeaveHidesColumns="smallMeta"
		:autoGroupColumnDef="autoGroupColumnDef"
		:localeText="zhCN"
		:getDataPath="getDataPath"
		:getRowId="getRowId"
		:getRowHeight="getRowHeight"
		@grid-ready="onGridReady"
		@selection-changed="onSelectionChanged"
	></AgGridVue>
</template>
<script setup lang="ts">
import {onMounted, ref, watch} from 'vue';
import {AgGridVue} from 'ag-grid-vue3';
import type {CellClassParams, ColDef, ColGroupDef, GridApi} from 'ag-grid-community';

import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';

import OrganizationalUnitWorklist from './OrganizationalUnitWorklist.vue';
import type {AgGridData, Process, LevelStyle} from './type';
import {processLevel} from './helper';

const props = defineProps<{
	dataList: AgGridData[];
	loading: boolean;
	processes: Process[];
	levelStyle: LevelStyle[];
	smallMeta: boolean;
}>();
const emit = defineEmits<{
	(e: 'showProcessModalOrNavigate', name: string, doctype:string): void;
	(e: 'updateSelectedWorkList', workLists: string[]): void;
}>();
const gridApi = ref<GridApi>();
const separator = ref<string>('.');
const selectedRows = ref<AgGridData[]>([]);

// 点击组织单元工作清单时查看详情
function viewDetail(name: string, doctype:string) {
	emit('showProcessModalOrNavigate', name, doctype);
}
// 切换子节点选中的状态
function toggleChildrenSelection(parentRow: AgGridData, select: boolean = true) {
	const roots = gridApi.value?.getModel().getRootNode().childrenAfterFilter;
	let allRows:any = [];
	if (roots.length > 0) {
		for (let index = 0; index < roots.length; index++) {
			if (roots[index].data.name === parentRow.path[0]) {
				allRows = roots[index].allLeafChildren;
			}
		}
	}
	for (const rowNode of allRows) {
		if (parentRow.path.every((part, index) => part === rowNode.data.path[index])) {
			rowNode.setSelected(select, false);
		}
	}
}
// 选择的组织单元工作清单改变
function onSelectionChanged(event: any) {
	const currentSelectedRows = gridApi.value?.getSelectedRows();
	if (!['checkboxSelected', 'rowClicked'].includes(event.source)) {
		return;
	}
	// 记录当前选中的行
	const previouslySelectedRows = selectedRows.value;
	const previouslySelectedRowsPlain = previouslySelectedRows.map((row: AgGridData) => ({...row}));
	const newlySelectedRows = currentSelectedRows!.filter((row: AgGridData) => !previouslySelectedRowsPlain.includes(row));
	const deselectedRows = currentSelectedRows?.length === 0
		? [...previouslySelectedRowsPlain]
		: previouslySelectedRowsPlain?.filter((row: AgGridData) =>
			!currentSelectedRows?.some((item: AgGridData) => item.name === row.name));
	for (const item of newlySelectedRows) {
		toggleChildrenSelection(item, true);
	}
	for (const item of deselectedRows) {
		toggleChildrenSelection(item, false);
	}
	selectedRows.value = gridApi.value?.getSelectedRows() || [];
}
// 清空选中行
function clearSelectRows() {
	selectedRows.value = [];
	gridApi.value?.deselectAll();
}
watch(selectedRows, () => {
	const workLists = selectedRows.value.filter((row: AgGridData) => !row.is_process)
		.map((row: AgGridData) => row.name);
	emit('updateSelectedWorkList', workLists);
});

const defaultColDef = ref<ColDef>();
const autoGroupColumnDef = ref<ColGroupDef|ColDef>();
watch(() =>props.levelStyle, () => {
	defaultColDef.value = {
		sortable: true,
		filter: true,
		resizable: true,
		suppressMovable: true,
		cellStyle: (params: CellClassParams) => {
			const rowStyle = props.levelStyle.find(item => item.level === processLevel[params.data?.level]);
			const {row_background_color = '', font_color = '', is_bold = false} = rowStyle || {};
			return {
				display: 'grid',
				alignItems: 'center',
				backgroundColor: params.data.is_work_list ? '' : row_background_color,
				color: params.data.is_work_list ? '' : font_color,
				fontWeight: !params.data.is_work_list && is_bold ? 'bold' : 'normal',
			};
		},
	};
});
watch([() =>props.levelStyle, () =>props.processes, separator], () => {
	autoGroupColumnDef.value = {
		headerName: __('Work List Title'),
		field: 'title',
		editable: false,
		filter: false,
		flex: 3,
		checkboxSelection: true,
		cellRendererParams: {
			suppressCount: true,
			innerRenderer: OrganizationalUnitWorklist,
			innerRendererParams: {
				viewDetail,
				levelStyle: props.levelStyle,
				separator: separator.value,
				processes: props.processes,
			},
		},
		sort: 'asc',
		comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
			const {full_process_code: codeA, is_process: isProcessA, full_code: workCodeA} = nodeA.data;
			const {full_process_code: codeB, is_process: isProcessB, full_code: workCodeB} = nodeB.data;
			if (isProcessA && isProcessB) {
				if (codeA === codeB) {
					return 0;
				}
				return (codeA > codeB) ? 1 : -1;
			}
			if (!isProcessA && !isProcessB) {
				if (workCodeA === workCodeB) {
					return 0;
				}
				return (workCodeA > workCodeB) ? 1 : -1;
			}
			if (isProcessB) {
				return 1;
			}
			return -1;
		},
	};
});
const columnDefs = ref([
	{
		headerName: __('Organizational Unit Self Build Work List'),
		field: 'scope',
		editable: false,
		filter: false,
		flex: 1,
		valueGetter: (params: CellClassParams) => {
			if (params.data?.is_work_list) {
				return params.data?.scope === 'Global' ? __('Yes') : '';
			}
		},
	},
	{
		headerName: __('Dw Department'),
		field: 'department_name',
		editable: false,
		filter: false,
		flex: 1,
	},
]);
function onGridReady(params: CellClassParams) {
	gridApi.value = params.api;
}
function getRowId(params: CellClassParams) {
	return params.data.name;
}
function getDataPath(data: AgGridData) {
	return data.path;
}
function getRowHeight(params: CellClassParams) {
	if (!params.data.is_process) {
		return 30;
	}
	const style = props.levelStyle.find(item => item.level === processLevel[params.data?.level]);
	return style?.row_height || 30;
}
// 获取编码连接符
async function getSeparator() {
	separator.value = await frappe.db.get_single_value('Dw Process Separator Rule', 'separator') || '.';
}

onMounted(() => {
	getSeparator();
});
defineExpose({
	gridApi,
	clearSelectRows,
});
</script>
