<template>
	<el-dialog
	  v-model="visible"
	  :title="translate('Batch adding structure Progress')"
	  width="50%"
	  :before-close="handleClose"
	  custom-class="progress-dialog"
	>
		<div>
			<ElForm ref="formRef" :model="form" :rules="rules" label-width="120px">
				<ElFormItem :label="translate('structure Progress Code')" prop="codeTemplate">
					<ElCol :span="22">
						<ElInput v-model="form.codeTemplate"></ElInput>
					</ElCol>
					<ElCol :span="2" class="text-center">
						<ElTooltip placement="top" :content="description">
							<ElIcon><QuestionFilled/></ElIcon>
						</ElTooltip>
					</ElCol>
				</ElFormItem>
				<ElFormItem :label="translate('Code Rule')" required>
					<ElCol :span="7">
						<ElFormItem required prop="codeRuleStart">
							<ElInput v-model.number="form.codeRuleStart"></ElInput>
						</ElFormItem>
					</ElCol>
					<ElCol :span="2" class="text-center">
						-
					</ElCol>
					<ElCol :span="7">
						<ElFormItem required prop="codeRuleEnd">
							<ElInput v-model.number="form.codeRuleEnd"></ElInput>
						</ElFormItem>
					</ElCol>
					<ElCol :span="1" class="text-center">
					</ElCol>
					<ElCol :span="7">
						<ElFormItem required prop="codeFormat">
							<ElSelect v-model="form.codeFormat">
								<ElOption :label="translate('Unit')" value="single"></ElOption>
								<ElOption :label="translate('Rule Format Double')" value="double"></ElOption>
								<ElOption :label="translate('Zh')" value="zh"></ElOption>
							</ElSelect>
						</ElFormItem>
					</ElCol>
				</ElFormItem>
				<ElFormItem :label="translate('Name')" prop="nameTemplate">
					<ElCol :span="22">
						<ElInput v-model="form.nameTemplate"></ElInput>
					</ElCol>
					<ElCol :span="2" class="text-center">
						<ElTooltip placement="top" :content="description">
							<ElIcon><QuestionFilled/></ElIcon>
						</ElTooltip>
					</ElCol>
				</ElFormItem>
				<ElFormItem :label="translate('Name Rule')" required>
					<ElCol :span="7">
						<ElFormItem required prop="nameRuleStart">
							<ElInput v-model.number="form.nameRuleStart"></ElInput>
						</ElFormItem>
					</ElCol>
					<ElCol :span="2" class="text-center">
						-
					</ElCol>
					<ElCol :span="7">
						<ElFormItem required prop="nameRuleEnd">
							<ElInput v-model.number="form.nameRuleEnd"></ElInput>
						</ElFormItem>
					</ElCol>
					<ElCol :span="1" class="text-center">
					</ElCol>
					<ElCol :span="7">
						<ElFormItem required prop="nameFormat">
							<ElSelect v-model="form.nameFormat">
								<ElOption :label="translate('Unit')" value="single"></ElOption>
								<ElOption :label="translate('Rule Format Double')" value="double"></ElOption>
								<ElOption :label="translate('Zh')" value="zh"></ElOption>
							</ElSelect>
						</ElFormItem>
					</ElCol>
				</ElFormItem>
				<ElFormItem :label="translate('Project')">
					<ElInput disabled v-model="form.project"></ElInput>
				</ElFormItem>
				<ElFormItem :label="translate('Type')" prop="type">
					<ElSelect v-model="form.type">
						<ElOption v-for="item in structureTypes" :label="item.type_name" :value="item.name"></ElOption>
					</ElSelect>
				</ElFormItem>
				<ElFormItem :label="translate('Status')">
					<ElSelect v-model="form.status">
						<ElOption v-for="item in statuses" :label="translate(item)" :value="item"></ElOption>
					</ElSelect>
				</ElFormItem>
				<ElFormItem :label="translate('Actual Start Date')">
					<ElDatePicker v-model="form.startDate"></ElDatePicker>
				</ElFormItem>
				<ElFormItem :label="translate('Actual End Date')">
					<ElDatePicker v-model="form.endDate"></ElDatePicker>
				</ElFormItem>
			</ElForm>
		</div>
	  <template #footer>
		  <span class="dialog-footer">
			<ElButton @click="handleClose">{{ translate('Cancel') }}</ElButton>
			<ElButton type="primary" @click="confirm">
			  {{ translate('Confirm') }}
			</ElButton>
		  </span>
	  </template>
	</el-dialog>
</template>

<script setup lang='ts'>
import { QuestionFilled } from '@element-plus/icons-vue';
import { FormInstance } from 'element-plus/lib/components/index.js';
import { ref, defineProps, defineEmits, toRefs, watch, getCurrentInstance, reactive, computed } from 'vue';
import moment from 'moment';

import { convertToChinaNum } from './helper';
const translate = window.__;
const description = translate('{{}} can be used as a placeholder at a certain position');
const {appContext} = getCurrentInstance()||{};
interface Props{
	visible:boolean,
	project:string
	structure:string
}
const props = defineProps<Props>();
const {
	visible,
	project,
	structure,
} = toRefs(props);
const structureTypes=ref<any[]>([]);
const formRef = ref<FormInstance>();
const rules = reactive({
  nameTemplate: [
    { required: true, message:translate('Items Required') },
  ],
  codeTemplate:[
    { required: true, message:translate('Items Required') },
  ],
  codeRuleStart:[
    { required: true, message:translate('Items Required') },
	{type:'number', message:translate('Must be a number')},
  ],
  codeRuleEnd:[
    { required: true, message:translate('Items Required') },
	{type:'number', message:translate('Must be a number')},
  ],
  nameRuleStart:[
    { required: true, message:translate('Items Required') },
	{type:'number', message:translate('Must be a number')},
  ],
  nameRuleEnd:[
    { required: true, message:translate('Items Required') },
	{type:'number', message:translate('Must be a number')},
  ],
  type:[
		{ required: true, message:translate('Items Required') },
	],
});

const form = reactive({
	project:'',
	structure:'',
	status:'',
	type:'',
	startDate:'',
	endDate:'',
	codeTemplate:'',
	nameTemplate:'',
	codeRuleStart:0,
	codeRuleEnd:0,
	nameRuleStart:0,
	nameRuleEnd:0,
	codeFormat:'',
	nameFormat:'',
});
watch(project, async ()=>{
	const projectResult = await frappe.db.get_doc('Project', 'PROJ-0001');
	form.project = projectResult.project_name;
}, {immediate:true});
watch(project, async ()=>{
	const structureTypesRes = await frappe.db.get_list('Guigu Construction Structure Type', {
		filters:{
			project:project.value,
		},
		fields:['name', 'type_name'],
		limit:'',
	});
	structureTypes.value = structureTypesRes;
}, {immediate:true});
const statuses = computed(()=>{
	const frm = appContext?.config.globalProperties.$frm;
	const statusString = frm.fields_dict.status.df.options;
	return statusString.split('\n');
});

interface Emit{
	(e:'close'):void
	(e:'confirm', progress:any[]):void
}
const emit = defineEmits<Emit>();

function handleClose(){
	emit('close');
}
async function confirm(){
	if (!formRef.value){
		return;
	}
	formRef.value.validate(async (valid:boolean, fields:any[])=>{
		if (valid){
			const {
				codeRuleStart, codeRuleEnd, nameRuleStart, nameRuleEnd,
				codeFormat, nameFormat, codeTemplate, nameTemplate,
				startDate, endDate, status, type,
			} = form;
			const frm = appContext?.config.globalProperties.$frm;

			const codeDiff = Math.abs(codeRuleStart-codeRuleEnd);
			const nameDiff = Math.abs(nameRuleStart-nameRuleEnd);
			const diff = Math.min(codeDiff, nameDiff);
			const codeAction = codeRuleEnd - codeRuleStart>0?1:-1;
			const nameAction = nameRuleEnd - nameRuleStart>0?1:-1;
			let codeIndex = codeRuleStart;
			let nameIndex = nameRuleStart;
			const children = [];
			for (let i = 0; i<=diff; i++){
				let codeField:string|number=codeIndex;
				let nameField:string|number= nameIndex;
				if (codeFormat === 'zh'){
					codeField = convertToChinaNum(codeIndex);
				} else if (codeFormat === 'double'){
					codeField = codeIndex>-1&&codeIndex<10?`0${codeIndex}`:codeIndex;
				}
				if (nameFormat === 'zh'){
					nameField = convertToChinaNum(nameIndex);
				} else if (nameFormat === 'double'){
					nameField = nameIndex>-1&&nameIndex<10?`0${nameIndex}`:nameIndex;
				}
				const code = codeTemplate.replace('{{}}', codeField.toString());
				const name = nameTemplate.replace('{{}}', nameField.toString());
				children.push({
					project:project.value,
					code,
					actual_start_date:startDate?moment(startDate).format('YYYY-MM-DD'):'',
					actual_end_date:endDate?moment(endDate).format('YYYY-MM-DD'):'',
					status,
					guigu_construction_structure_type:type,
					structure_name:name,
					parent_guigu_construction_structure:structure.value,
				});
				codeIndex += (codeAction);
				nameIndex += (nameAction);
			}
			const data = await frappe.call < { message: any[] } >({
				method: 'guigu_pm.guigu_pm_construction.doctype.guigu_construction_structure.guigu_construction_structure.bulk_structures',
				args: {
					structures: children,
				},
			});
			const { structure_children}:{structure_children:any[]} = frm.get_doc();
			const newStructureChildren = data?.message?.map(item=>({
				name:item.name,
				structure_children_name:item.name,
				structure_item_children_code:item.code,
				structure_item_children_name:item.structure_name,

			}));
			frm.set_value('structure_children', structure_children.concat(newStructureChildren));
			frm.refresh();
			emit('close');
		} else {
		}
	});
}

</script>

<style lang='less' scoped>

</style>
