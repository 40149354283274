import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9e98278c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-container"
}
const _hoisted_2 = {
  key: 1,
  class: "placeholder"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.options.content)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($setup.text), 1 /* TEXT */))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.tt('Please configure the template string')), 1 /* TEXT */))
}