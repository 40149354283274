
<template>
	<div class="project-container">
		<ElTree
			class="organization-tree"
			:data="projectList"
			:props="optionProps"
			nodeKey="id"
			v-loading="projectLoading"
			:expandOnClickNode="false"
			@node-click="nodeClick"
			:currentNodeKey="selectedKey"
			:defaultCheckedKeys="[1]"
			highlightCurrent>
			<template #default="{ node, data }">
				<span class="custom-tree-node">
					<span :class="{ 'bolder': data.name === 'all'}">
						{{ node.label }}</span>
				</span>
			</template>
		</ElTree>
	</div>
</template>
<script lang="ts" setup>
import { ref, computed, watch } from 'vue';

import type { Project } from './type';
interface Props {
	projectList: Project[]
	projectLoading: boolean
}
const props = defineProps<Props>();

interface Emit {
	(e: 'projectChange', project: string): void
}
const emit = defineEmits<Emit>();
const selectedKey = ref<string>('all');
const optionProps = {
	label: 'project_abbr_name',
};

function nodeClick(e: any) {
	selectedKey.value = e.name;
	emit('projectChange', e.name);
}
</script>
<style scoped>
.bolder {
	font-weight: bolder;
}

.project-container {
	height: 100%;
	overflow-y: auto;
}
</style>
