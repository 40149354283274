import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fc97064b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "graphRef",
  class: "container"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512 /* NEED_PATCH */)), [
    [_directive_loading, $props.loading || $setup.localLoading]
  ])
}