import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bfdd4e01"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = {
  class: "table-container",
  id: "main_material_verification_and_disposal_statistics"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createBlock($setup["Page"], null, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.tt('Main Material Verification And Disposal Statistics')), 1 /* TEXT */)
    ]),
    tools: _withCtx(() => [
      _createVNode($setup["Tools"], { onUpdateIndexParams: $setup.updateIndexParams })
    ]),
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode($setup["Table"], {
          hotDataList: $setup.hotDataList,
          hotName: $setup.hotName,
          isTotal: $setup.isTotal,
          isShowConsume: $setup.isShowConsume,
          isShowDetail: $setup.isShowDetail
        }, null, 8 /* PROPS */, ["hotDataList", "hotName", "isTotal", "isShowConsume", "isShowDetail"])
      ])), [
        [_directive_loading, $setup.loading]
      ])
    ]),
    _: 1 /* STABLE */
  }))
}