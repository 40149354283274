<template>
	<ElDrawer class="bbb" v-model="visibleValue" direction="rtl" title="请输入描述" modalClass="aaa" customClass="bbb">
		<div>
			<ElInput v-model="textValue" type="textarea"></ElInput>
		</div>
		<template #footer>
			<div style="flex: auto">
				<ElButton @click="cancelClick">{{ tt('Cancel') }}</ElButton>
				<ElButton type="primary" @click="confirmClick">{{ tt('Confirm') }}</ElButton>
			</div>
		</template>
	</ElDrawer>
</template>

<script setup lang="ts">
import {computed, ref, watch} from 'vue';

import {ElInput, ElDrawer, ElButton} from 'element-plus';
const tt = __;
interface Props{
	visible:boolean
	text?:string
}
const props = defineProps<Props>();
interface Emits{
	(e:'update:visible', v:boolean):void
	(e:'update:text', v:string):void
	(e:'confirm', v:string):void
}
const emit = defineEmits<Emits>();
const visibleValue = computed({
	get() {
		return props.visible;
	},
	set(v) {
		emit('update:visible', v);
	},
});

const textValue = ref<string>(props.text || '');
watch(()=>props.text, ()=>{
	textValue.value = props.text || '';
});

function cancelClick() {
	visibleValue.value = false;
}
function confirmClick() {
	visibleValue.value = false;
	emit('confirm', textValue.value);
}

</script>

<style lang="less" scoped>

</style>
