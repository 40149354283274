import registerGlobalView from 'guiguLib/registerGlobalView';

import GraphView from './index.vue';
import Config from './Config.vue';

registerGlobalView('GraphView', GraphView);
export { GraphView as View };

export const widgets: GlobalView.ConfigurationWidget[] = [{
	component: Config,
	label: '字段',
}];
