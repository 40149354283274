<template lang="html">
	<div :class="['global-layout', classes.skeletonClass]" v-if="loaded">
		<div class="header">
			<SidebarToggleBtn v-if="sider.length" v-model="showSidebar" />
			<ElIcon v-if="isAdmin" :title="tt('Add View')" @click="newView"
				class="add">
				<Plus />
			</ElIcon>
			<ElTabs class="tabs" v-model="currentViewId" v-if="shown">
				<template v-for="view in views">
					<ElTabPane :name="view.name" #label
						v-if="isAdmin || view.name === currentViewId || !view.hidden && (view.group === group || group === null)">
						<a :href="view.search" @click.prevent>
							{{ view.label || view.name }}
						</a>
						<ElDropdown @command="run($event, view)"
							v-if="isAdmin && view.name === currentViewId">
							<ElIcon>
								<ArrowDown />
							</ElIcon>
							<template #dropdown>
								<ElDropdownMenu>
									<ElDropdownItem command="copy">
										复制
									</ElDropdownItem>
									<ElDropdownItem command="edit">
										编辑
									</ElDropdownItem>
									<ElDropdownItem command="move">
										移动
									</ElDropdownItem>
									<ElDropdownItem command="setDefault">
										设为默认
									</ElDropdownItem>
									<ElDropdownItem command="delete">
										删除
									</ElDropdownItem>
								</ElDropdownMenu>
							</template>
						</ElDropdown>
					</ElTabPane>
				</template>
			</ElTabs>
			<RootMenu :meta="meta" :showDetailSwitcher="showDetailSwitcher"
				@addView="newView" v-model:layout="layout" :canAdmin="canAdmin"
				v-model:isAdmin="isAdmin" />
			<Add v-if="!current?.view?.isHideAddBtn" @create="create"
				:url="createUrl"
				:meta="meta" />
		</div>
		<template v-for="view in views" :key="view.name">
			<KeepAlive v-if="view.configuration && view.view">
				<Main
					v-if="shown && view.name === currentViewId"
					:meta="docMeta"
					class="body"
					:setting="globalView"
					:sider="sider"
					:toolbar="toolbar"
					:actions="actions"
					:mainComp="mainComp"
					:detailComp="detailComp"
					:rowActionComp="rowActionComp"
					:link="link"
					:layout="layout"

					:state="view"
					:view="view.view"
					:loading="view.loading"
					:showDetailSwitcher="showDetailSwitcher"
					:showDetail="showDetail"
					:configuration="view.configuration"
					@update="view.update($event)" />
			</KeepAlive>
			<template v-else-if="shown && view.name === currentViewId">
				<!--- TODO: 加载中 -->
			</template>
		</template>
	</div>
	<Configuration :meta="meta" :view="editingView"
		:configuration="editingConfiguration" v-model:visible="editing"
		@update="update" />
</template>
<script setup lang="ts">
import {onMounted, shallowRef, computed, watch, toRefs, toRef, h} from 'vue';
import {useWindowSize} from '@vueuse/core';
import {
	ElMessageBox,
	ElTabs, ElTabPane, ElIcon, ElDropdown, ElDropdownMenu, ElDropdownItem,
	ElSelect, ElOption,
} from 'element-plus';
import {ArrowDown, Plus} from '@element-plus/icons-vue';

import loadLinkDocTypes from '../utils/loadLinkDocTypes';

import type {View} from './types';
import RootMenu from './RootMenu/index.vue';
import SidebarToggleBtn from './SidebarToggleBtn.vue';
import Add from './Add.vue';
import Main from './Main.vue';
import useViews from './useViews';
import {initResizeStorage} from './resizeStorage';
import getDetailComp from './getDetailComp';
import useSider from './useSider';
import useToolbar from './useToolbar';
import Configuration from './Configuration/index.vue';
import getMainComponent from './getViewComp';
import getRowActionComp from './getRowActionComp';
import useActions from './useActions';
import createId from './createId.mjs';

const tt = __;
const props = defineProps<{
	doctype: string;
	meta: locals.DocType;
	domVisible: boolean;
}>();
const domVisible = toRef(props, 'domVisible');
const show = shallowRef(false);
const shown = computed(() => domVisible.value && show.value);

watch(domVisible, v => {
	show.value = v;
}, {immediate: true});

const docMeta = shallowRef(props.meta);

const editing = shallowRef(false);
const editingView = shallowRef();
const editingConfiguration = shallowRef();


const canAdmin = computed(() => frappe.user.has_role('System Manager'));

const isAdmin = shallowRef(false);


const {
	views, current, currentViewId, load: loadViews, save: saveView,
	del: deleteView, move: moveView, loadRouteOptions, setDefault,
} = useViews(docMeta);
const layout = computed({
	get: () => current.value?.layout || 'link',
	set: v => {
		const view = current.value;
		if (!view) {
			return;
		}
		view.layout = v;
	},
});
const link = computed(() => !['modal', 'right', 'bottom'].includes(layout.value));

const globalView = computed<GlobalView>(() => current.value?.viewSetting || {type: ''});
const group = computed(() => current.value?.group ?? null);
const sider = useSider(computed(() => current.value?.view?.isHideSider ? [] : globalView.value.sider));
const toolbar = useToolbar(computed(() => globalView.value.toolbar));
const actions = useActions(computed(() => globalView.value.actions));
const mainComp = computed(() => getMainComponent(globalView.value.view));
const detailComp = computed(() => getDetailComp(globalView.value.detail));
const rowActionComp = computed(() => getRowActionComp(globalView.value.rowAction));


const {width, height} = useWindowSize();
const showDetail = computed(() => width.value >= 738);
const showDetailSwitcher = computed(() => Boolean(showDetail.value && detailComp.value));


const classes = computed<GlobalView.SkeletonClassNames>(() => globalView.value || {});

const {showSidebar} = toRefs(initResizeStorage(props.meta.name));

watch(current, current => {
	current?.load();
});
function newView() {
	editingView.value = {};
	editingConfiguration.value = {};
	editing.value = true;
}

async function update(view: any, configuration: any) {
	saveView(props.meta.name, view, configuration).then(() => {
		editing.value = false;
	});
}
function removeName(obj: any): any {
	for (const v of Object.values(obj)) {
		if (!Array.isArray(v)) {
			continue;
		}
		for (const it of v) {
			if (!it || typeof it !== 'object') {
				continue;
			}
			delete it.name;
			removeName(it);
		}
	}
	return obj;
}
function create() {
	return current.value?.create({}, true);
}
const createUrl = computed(() => {
	const {doctype} = props.meta;
	let path = `/app`;
	path = `${path}/${frappe.router.slug(doctype)}`;
	path = `${path}/${frappe.router.slug(`new-${doctype}-${createId.value}`)}`;
	return path;
});

async function run(cmd: string, current: View) {
	switch (cmd) {
		case 'copy': case 'edit': {
			const data = await current.load().catch(() => null);
			if (!data) {
				return;
			}
			const [view, configuration] = data;
			const v = removeName(structuredClone(view));
			editingView.value = cmd === 'edit' ? v : {
				...v,
				name: '',
				position: current.position + 1,
			};
			editingConfiguration.value = removeName(structuredClone(configuration));
			editing.value = true;
			return;
		}
		case 'delete': {
			const {name} = current;
			if (!name) {
				return;
			}

			ElMessageBox.confirm('确认要删除吗?').then(() => deleteView(name));
			return;
		}
		case 'move': {
			const {position} = current;
			const target = shallowRef<number>(position);
			ElMessageBox({
				title: `将 ${current.label || current.name} 移动到...`,
				message: (): any => h(ElSelect, {
					filterable: true,
					modelValue: target.value,
					'onUpdate:modelValue': (val: number) => {
						target.value = val;
					},
				}, views.value.map((view, i) => h(ElOption, {
					value: view.position,
					label: view === current
						? '<<<位置不变>>>'
						: `${view.label || view.name} ${view.position < position ? '之前' : '之后'}`,
				}))),
			}).then(() => {
				const to = target.value;
				if (to === position) {
					return;
				}
				moveView(current.name, to);
			});
			return;
		}
		case 'setDefault': {
			const {name} = current;
			if (!name) {
				return;
			}
			setDefault(name);
			return;
		}
	}
}

watch(() => current.value?.search, search => {
	if (!props.domVisible || !search) {
		return;
	}
	window.history.replaceState(null, '', search);
}, {immediate: true});

watch(() => props.domVisible, v => {
	if (!v) {
		return;
	}
	loadRouteOptions();
}, {immediate: true});


const loaded = shallowRef(false);
async function init() {
	const meta = docMeta.value;
	await Promise.all([
		loadLinkDocTypes(meta),
		loadViews(),
	]);
	loaded.value = true;
}
onMounted(init);

</script>


<style scoped lang="less">
.global-layout {
	height: calc(100vh - var(--navbar-height));
	margin: auto;


	width: 100%;
	padding-inline: 15px;

	display: flex;
	flex-direction: column;

}

.add {
	display: block;
	margin-inline: .5em;
}

.header {
	display: flex;
	align-items: center;

	.tabs {
		flex: 1;
		width: 1px;

		:global(.el-tabs__item) {
			display: inline-flex;
			align-items: center;
			align-content: center;
			justify-content: space-between;
		}
	}
}

.body {
	flex: 1;
}
</style>
