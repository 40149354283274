import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9c478f09"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "confirm_div" }
const _hoisted_2 = { class: "confirm_div" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createBlock(_component_el_form, {
    labelPosition: "top",
    class: "detail-form",
    model: $setup.form,
    labelWidth: "100px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_row, { gutter: 10 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, { label: "" }, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", null, _toDisplayString($setup.tt('Assessor (Signature)')) + ":" + _toDisplayString($props.assessInfo?.leader_doc?.full_name) + " " + _toDisplayString($setup.tt('Confirmed ({})', [$setup.form.leaderConfirmDate])), 1 /* TEXT */)
                  ], 512 /* NEED_PATCH */), [
                    [_vShow, $setup.form.leaderConfirmDate]
                  ]),
                  _withDirectives(_createVNode(_component_el_checkbox, {
                    label: $setup.tt('Assessment Person Sign'),
                    modelValue: $setup.isLeaderConfirmed,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.isLeaderConfirmed) = $event)),
                    onChange: $setup.leaderToConfirm,
                    disabled: $setup.leader_confirm_disabled
                  }, null, 8 /* PROPS */, ["label", "modelValue", "disabled"]), [
                    [_vShow, !$setup.form.leaderConfirmDate]
                  ])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_col, { span: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, { label: "" }, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", null, _toDisplayString($setup.tt('Assessed Person (Signature)')) + ": " + _toDisplayString($setup.props.assessInfo?.user_doc.full_name) + " " + _toDisplayString($setup.tt('Confirmed ({})', [$setup.form.userConfirmDate])), 1 /* TEXT */)
                  ], 512 /* NEED_PATCH */), [
                    [_vShow, $setup.form.userConfirmDate]
                  ]),
                  _withDirectives(_createVNode(_component_el_checkbox, {
                    label: $setup.tt('Assessed Person'),
                    modelValue: $setup.isUserConfirmed,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.isUserConfirmed) = $event)),
                    onChange: $setup.userToConfirm,
                    disabled: $setup.user_confirm_disabled
                  }, null, 8 /* PROPS */, ["label", "modelValue", "disabled"]), [
                    [_vShow, !$setup.form.userConfirmDate]
                  ])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["model"]))
}