import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElFormItem"], null, {
    default: _withCtx(() => [
      _createVNode($setup["Filters"], {
        doctype: $setup.doctype,
        modelValue: $setup.filtersMust,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.filtersMust) = $event))
      }, null, 8 /* PROPS */, ["doctype", "modelValue"])
    ]),
    _: 1 /* STABLE */
  }))
}