<template>
	<div ref="tableContainer" class="table-container">
		<AgGridVue
			v-if="data && !isHidden"
			class="ag-theme-guigu"
			:columnDefs="columnDefs"
			:rowData="rowData"
			:defaultColDef="defaultColDef"
			rowSelection="multiple"
			animateRows="true"
			@grid-ready="onGridReady"
			:treeData="isTree"
			groupDefaultExpanded="-1"
			:getDataPath="getDataPath"
			:getRowNodeId="getRowId"
			domLayout="autoHeight"
			:autoGroupColumnDef="autoGroupColumn"
			:localeText="zhCN"
			:rowClassRules="rowClassRules"
			:rowDrag="dragEnable"
			@RowDragEnd="onRowDragEnd"
			@RowDragMove="onRowDragMove"
			@RowDragLeave="onRowDragLeave"
			excludeChildrenWhenTreeDataFiltering
			:rowGroupPanelShow="rowGroupPanelShow"
			:pagination="!connectionFieldSetting?.hide_pagination"
			:paginationPageSize="10"
			suppressPaginationPanel
			@ColumnRowGroupChanged="ColumnRowGroupChanged"
			@selectionChanged="selectionChanged"
			@first-data-rendered="onFirstDataRendered"
			:groupAllowUnbalanced="false"
			@paginationChanged="paginationChanged"
			@filterChanged="filterChanged"
			groupSelectsFiltered
			:overlayNoRowsTemplate="overlayNoRowsTemplate"
			:suppressDragLeaveHidesColumns="smallMeta" />
		<div class="pagination">
			<ElPagination v-if="!connectionFieldSetting?.hide_pagination" layout="prev, pager, next" :total="total"
				:pageSize="10"
				@current-change="pageChange" />
		</div>
	</div>
</template>

<script setup lang='ts'>
import {ref, computed, watch, shallowRef, Component} from 'vue';

import {AgGridVue} from 'ag-grid-vue3';
import {ElButton, ElPagination} from 'element-plus';
import type {
	ColumnRowGroupChangedEvent,
	FirstDataRenderedEvent,
	RowDragEndEvent,
	RowDragLeaveEvent,
	RowDragMoveEvent,
	SelectionChangedEvent,
	GridApi,
	ColumnApi,
	ColDef,
	PaginationChangedEvent,
	FilterChangedEvent,
} from 'ag-grid-community';
import {useWindowSize} from '@vueuse/core';

import zhCN from '../../agGrid/zhCN';
import {useMetaQuery} from '../../hooks/useMetaQuery';

import {getFormatData, getTreeAncestry} from './helper';
import type {
	CustomActionButton, LinkMetaPermissions,
	Data, LinkOptions, ConnectionSetting,
} from './type';
import {
	onRowDragEndHelper,
	potentialParent,
	setPotentialParentForNode,
} from './dragMove';
import {getAutoGroupColumn, getColumns, getCheckBoxColumn} from './getColumns';

interface Props {
	linkMeta?: locals.DocType
	data: any[]
	treeGroupField: string
	linkMetaPermissions: LinkMetaPermissions
	middleMetaPermissions: LinkMetaPermissions
	customActionButton: CustomActionButton[]
	dragRequest?: (
		movingData: Data,
		overData: Data,
	) => Promise<boolean> | boolean;
	linkOptions: LinkOptions[]
	connectionFieldSetting?: ConnectionSetting
	isNew?: boolean|undefined
	customComponents?: Record<string, Function | Component>
	readonly?: boolean;
	isDragEnable:boolean
}
const props = defineProps<Props>();
interface Emit {
	(e: 'remove', data: any): void
	(e: 'unConnect', data: any): void
	(e: 'selectionChanged', data: any[]): void
	(e: 'showDetail', name: string): void
	(e: 'createChild', data: any): void
	(e: 'updateCount', data: {total:number, selectedCount:number, filterCount:number}): void
}
const emit = defineEmits<Emit>();
const total = ref<number>(0);
const gridApi = ref<GridApi | null>(null);
const gridColumnApi = shallowRef<ColumnApi>();
const {width, height} = useWindowSize();
const isMobile = computed(() => width.value < 640);
const selectedCount = ref<number>(0);
const filterCount = ref<number>(0);
const rowGroupPanelShow = computed(()=>{
	if (isTree.value || props.connectionFieldSetting?.is_hide_group_panel === 1) {
		return '';
	}
	return 'always';
});
watch([isMobile, gridColumnApi], ([isMobile, columnApi]) => {
	if (!isMobile || !columnApi) {
		return;
	}
	try {
		const cols = columnApi.getAllGridColumns() || [];
		columnApi.setColumnsPinned(cols.map(v => v.getColId()), null);
	} catch {}
}, {immediate: true});
const tableContainer = ref<HTMLElement>();
const isHidden = ref<boolean>(true);
const smallMeta = useMetaQuery();
const isGrouped = ref<boolean>(false);
const observer = new ResizeObserver(entries => {
	isHidden.value = (entries[0]?.target as HTMLElement).offsetParent === null;
});
watch(tableContainer, () => {
	if (!tableContainer.value) {
		return;
	}
	observer.observe(tableContainer.value);
});

const onGridReady = (params: any) => {
	gridApi.value = params.api;
	gridColumnApi.value = params.columnApi;
};
function pageChange(newPage: number) {
	gridApi.value?.paginationGoToPage?.(newPage - 1);
}

const rowData = computed(() => {
	if (!props.linkMeta?.is_tree) {
		const list = props.data.map(v => ({...v, ancestry: [v.name]}));
		if (!props.connectionFieldSetting?.columns) {
			return list;
		}
		for (const {fieldname, fieldtype} of props.connectionFieldSetting?.columns) {
			for (const r of list) {
				const v = r[fieldname];
				if (v !== undefined && v !== null) {
					continue;
				}
				r[fieldname] = ['Int', 'Float', 'Currency'].includes(fieldtype) ? 0 : '';
			}
		}
		return list;
	}
	const formatData = getFormatData(props.data, props.linkMeta.nsm_parent_field);
	const ancestryList = getTreeAncestry(formatData, props.treeGroupField);
	return ancestryList;
});

watch([()=>[...rowData.value]], () => {
	total.value = rowData.value.length;
	emit('updateCount', {total: total.value, selectedCount: selectedCount.value, filterCount: filterCount.value});
}, {immediate: true});

function getDataPath(data: any) {
	return data.ancestry;
}

const isTree = computed(() => Boolean(props.linkMeta?.is_tree));

const defaultColDef = computed(()=>({
	sortable: false,
	filter: true,
	minWidth: 100,
	resizable: true,
	editable: false,
	enableRowGroup: rowGroupPanelShow.value === 'always',
}));
const dragEnable = computed(() => Boolean(props.isDragEnable && props.dragRequest && props.linkMetaPermissions.writePermission));
const autoGroupColumn = computed(() => getAutoGroupColumn(
	dragEnable.value,
	smallMeta.value,
	emit,
	props.linkMetaPermissions,
	props.middleMetaPermissions,
	props.linkMeta,
	props.connectionFieldSetting,
	potentialParent,
));

const rowClassRules = {
	'hover-over'(params: any) {
		return params.node === potentialParent;
	},
};
function ColumnRowGroupChanged(event: ColumnRowGroupChangedEvent) {
	const hasGroup = Boolean(event.api.getModel().columnModel.groupDisplayColumns.length > 0);
	isGrouped.value = hasGroup;
	if (!gridApi.value) {
		return;
	}
	let columnDefs = gridApi.value.getColumnDefs?.();
	if (hasGroup) {
		columnDefs = columnDefs?.filter(item => (item as ColDef).field !== 'checkbox');
	} else {
		columnDefs?.unshift(getCheckBoxColumn(isTree.value, dragEnable.value));
	}
	gridApi.value.setColumnDefs?.(columnDefs || []);
	gridApi.value.refreshCells();
}
const columnDefs = computed(() => getColumns(
	props.linkOptions,
	props.linkMetaPermissions,
	props.middleMetaPermissions,
	props.customActionButton,
	props.isNew,
	smallMeta.value,
	emit,
	dragEnable.value,
	isTree.value,
	props.connectionFieldSetting,
	props.customComponents,
	props.readonly,
));


function getRowId(data: Data) {
	return data.name;
}
function onRowDragEnd(event: RowDragEndEvent) {
	if (!props.dragRequest) {
		return;
	}
	onRowDragEndHelper(event, rowData.value, props.dragRequest);
}
function onRowDragMove(event: RowDragMoveEvent) {
	if (!props.dragRequest) {
		return;
	}
	setPotentialParentForNode(event.api, event.overNode);
}
function onRowDragLeave(event: RowDragLeaveEvent) {
	if (!props.dragRequest) {
		return;
	}
	setPotentialParentForNode(event.api, null);
}
function selectionChanged(event: SelectionChangedEvent) {
	selectedCount.value = event.api.getSelectedRows().length;
	emit('selectionChanged', event.api.getSelectedRows());
	emit('updateCount', {total: total.value, selectedCount: selectedCount.value, filterCount: filterCount.value});
}
function onFirstDataRendered(params: FirstDataRenderedEvent) {
	params.columnApi.autoSizeColumns(['action'], false);
}

function paginationChanged(event:PaginationChangedEvent) {
	const rows = event.api.getRenderedNodes().map(item=>item.data).filter(Boolean);
	const aggregationRow = getAggregationRow(rows);
	event.api.setPinnedBottomRowData(aggregationRow ? [aggregationRow] : []);
}
function getAggregationRow(rows:any[]) {
	if (!rows.length) {
		return;
	}
	if (!props.connectionFieldSetting?.total_columns?.length) {
		return;
	}
	const sumArr = props.connectionFieldSetting.total_columns.map(field=>[
		field.field,
		rows.map(v => Number(v[field.field]) || 0).reduce((a, b) => a + b, 0).toFixed(2),
	]);
	const aggRow = Object.fromEntries(sumArr);
	aggRow.__IS_AGGREGATION__ = true;
	return aggRow;
}
function filterChanged(event:FilterChangedEvent) {
	if (Object.keys(event.api.getFilterModel()).length > 0) {
		filterCount.value = event.api.getModel().getRowCount();
	} else {
		filterCount.value = 0;
	}
	emit('updateCount', {total: total.value, selectedCount: selectedCount.value, filterCount: filterCount.value});
}
const overlayNoRowsTemplate = "<div style='font-size:14px' class='grid-empty text-center'><img src='/assets/frappe/images/ui-states/grid-empty-state.svg' alt='Grid Empty State' class='grid-empty-illustration'>无数据</div>";
const minHeight = computed(()=>Boolean(rowData.value.length) ? '50px' : '111px');
</script>

<style lang='less' scoped>
.ag-theme-guigu :deep(.ag-root-wrapper) {
	overflow: visible;
	border:1px solid var(--table-border-color);
	border-radius: var(--border-radius-md);
}
.ag-theme-guigu :deep(.ag-root) {
	border-radius: var(--border-radius-md);
}
.ag-theme-guigu :deep(.ag-watermark) {
	display: none;
}
.ag-theme-guigu :deep(.ag-group-value){
	flex:1;
}
.ag-theme-guigu :deep(.ag-layout-auto-height .ag-center-cols-clipper),
.ag-theme-guigu :deep(.ag-layout-auto-height .ag-center-cols-container){
	min-height: v-bind(minHeight);
}
.pagination{
	display: flex;
    align-items: center;
	justify-content: space-between;
}
</style>
