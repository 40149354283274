<template>
	<Detail
		v-model:columnSize="resizeDetailColumn"
		v-model:rowSize="resizeDetailRow"
		:model="true" :row="false"
	>
		<FormDetail
			:loading="loading"
			v-if="meta&&detail"
			:meta="meta"
			:options="{}"
			:value="detail"
			@update:value="emit('hide')"
		></FormDetail>
	</Detail>
</template>

<script setup lang='ts'>
import {ref, watch, shallowRef, toRefs} from 'vue';

import FormDetail from '../../../../../../../guigu/guigu/public/js/FormDetail';
import Detail from '../../../../../../../guigu/guigu/public/js/GlobalView/Skeleton/Detail.vue';
import {injectResizeStorage} from '../../../../../../../guigu/guigu/public/js/GlobalView/resizeStorage';

const meta = shallowRef<locals.DocType>();

interface Props{
	doctype:string,
	name?:string
}
const props = defineProps<Props>();
const detail = ref<any>();
const loading = ref<boolean>(false);
const emit = defineEmits<{
	(event: 'hide'): void;
}>();
const {
	resizeRider,
	resizeDetailColumn,
	resizeDetailRow,
	showFilterField,
	showSidebar,
	resizeFilterField,
} = toRefs(injectResizeStorage());

watch(()=>props.doctype, ()=>{
	getMeta(props.doctype);
}, {immediate: true});
watch(()=>props.name, ()=>{
	getDetail();
}, {immediate: true});
async function getDetail() {
	loading.value = true;
	if (!props.name) {
		detail.value = undefined;
		loading.value = false;
		return;
	}
	detail.value = await frappe.db.get_doc(props.doctype, props.name);
	loading.value = false;
}
async function getMeta(doctype: string) {
	if (!doctype) {
		return;
	}
	let local_meta = frappe.get_meta(doctype);
	if (local_meta) {
		// local_meta.hide_toolbar = true;
		meta.value = local_meta;
		return;
	}
	await frappe.model.with_doctype(doctype);
	local_meta = frappe.get_meta(doctype);
	// local_meta!.hide_toolbar = true;
	meta.value = local_meta || undefined;
}
</script>

<style lang='less' scoped>
</style>
