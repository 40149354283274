import {h} from '@logicflow/core';
import {GroupNode} from '@logicflow/extension';

// 泳道
class LaneModel extends GroupNode.model {
	initNodeData(data) {
		super.initNodeData(data);
		const width = 300;
		const height = 470;
		this.width = data.properties?.nodeSize?.width as number || width;
		this.height = data.properties?.nodeSize?.height as number || height;
		if (data.properties) {
			this.properties = {
				...this.properties,
				...data.properties,
			};
		}
		this.draggable = false;
		this.resizable = true;
		this.zIndex = 1;
		this.text.editable = true;
	}
	setAttributes() {
		this.text = {
			...this.text,
			value: this.text.value || __('Lane'),
			x: this.x,
			y: this.y - this.height / 2 + 15,
		};
	}
	getTextStyle() {
		const style = super.getTextStyle();
		style.textWidth = 16;
		return style;
	}
	getNodeStyle() {
		const style = super.getNodeStyle();
		style.stroke = '#187dff';
		return style;
	}
	changeAttribute({width, height, x, y}) {
		if (width) {
			this.width = width;
		}
		if (height) {
			this.height = height;
		}
		if (x) {
			this.x = x;
		}
		if (y) {
			this.y = y;
		}
	}
}

class LaneView extends GroupNode.view {
	getOperateIcon() {
		const {model} = this.props;
		const {isSelected} = model;
		if (!isSelected || this.props.graphModel.editConfigModel.isSilentMode === true) {
			return null;
		}
		return [
			this.addRightIcon(),
			this.addLeftIcon(),
			this.deleteIcon(),
		];
	}
	addRightIcon() {
		const {x, y, width, height, id} = this.props.model;
		return h('g', {
			cursor: 'pointer',
			onClick: () => {
				const groupId = this.props.graphModel.group.nodeGroupMap.get(id);
				if (groupId) {
					const groupModel = this.props.graphModel.getNodeModelById(groupId);
					groupModel.addChildRight({x, y, width, height});
				}
			},
		}, [
			h('rect', {
				height: 20,
				width: 7,
				strokeWidth: 1,
				fill: '#fff',
				stroke: '#000',
				x: x + width / 2 + 15,
				y: y - height / 2 + 3,
			}),
			h('rect', {
				height: 20,
				width: 7,
				strokeWidth: 1,
				fill: '#fff',
				stroke: '#000',
				strokeDasharray: '3 3',
				x: x + width / 2 + 22,
				y: y - height / 2 + 3,
			}),
		]);
	}
	addLeftIcon() {
		const {x, y, width, height, id} = this.props.model;
		return h('g', {
			cursor: 'pointer',
			onClick: () => {
				const groupId = this.props.graphModel.group.nodeGroupMap.get(id);
				if (groupId) {
					const groupModel = this.props.graphModel.getNodeModelById(groupId);
					groupModel.addChildLeft({x, y, width, height});
				}
			},
		}, [
			h('rect', {
				height: 20,
				width: 7,
				strokeWidth: 1,
				fill: '#fff',
				stroke: '#000',
				strokeDasharray: '3 3',
				x: x + width / 2 + 15,
				y: y - height / 2 + 32 + 5,
			}),
			h('rect', {
				height: 20,
				width: 7,
				strokeWidth: 1,
				fill: '#fff',
				stroke: '#000',
				x: x + width / 2 + 22,
				y: y - height / 2 + 32 + 5,
			}),
		]);
	}
	deleteIcon() {
		const {x, y, width, height, id} = this.props.model;
		return h(
			'g',
			{
				cursor: 'pointer',
				onClick: () => {
					const groupId = this.props.graphModel.group.nodeGroupMap.get(id);
					if (groupId) {
						const groupModel = this.props.graphModel.getNodeModelById(groupId);
						groupModel.deleteChild(id);
					}
				},
			},
			[
				h('rect', {
					height: 20,
					width: 20,
					rx: 2,
					ry: 2,
					strokeWidth: 1,
					fill: 'transparent',
					stroke: 'transparent',
					x: x + width / 2 + 14,
					y: y - height / 2 + 65,
				}),
				h(
					'svg', {
						transform: 'translate(1.000000, 1.000000)',
						// fill: '#3C96FE',
						x: x + width / 2 + 14,
						y: y - height / 2 + 65,
						width: 20,
						height: 20,
					},
					[
						h('path', {
							'pointer-events': 'none',
							d: 'M15.3,1.4 L12.6,1.4 L12.6,0 L5.4,0 L5.4,1.4 L0,1.4 L0,2.8 L2,2.8 L2,17.3 C2,17.6865993 2.31340068,18 2.7,18 L15.3,18 C15.6865993,18 16,17.6865993 16,17.3 L16,2.8 L18,2.8 L18,1.4 L15.3,1.4 Z M14.6,16.6 L3.4,16.6 L3.4,2.8 L14.6,2.8 L14.6,16.6 Z',
						}),
						h('path', {
							'pointer-events': 'none',
							d: 'M6,5.4 L7.4,5.4 L7.4,14.4 L6,14.4 L6,5.4 Z M10.6,5.4 L12,5.4 L12,14.4 L10.6,14.4 L10.6,5.4 Z',
						}),
					],
				),
			],
		);
	}
	getResizeShape() {
		const {model} = this.props;
		const {x, y, width, height} = model;
		const style = model.getNodeStyle();
		// 标题区域
		const foldRectAttrs = {
			...style,
			x: x - width / 2,
			y: y - height / 2,
			width,
			height: 30,
			stroke: '#187dff',
		};
		// 泳道区域
		const transRectAttrs = {
			...style,
			x: x - width / 2,
			y: y - height / 2 + 30,
			width: width,
			height: height - 30,
			fill: '#fff',
			stroke: '#187dff',
		};
		return h('g', {}, [
			// super.getResizeShape(),
			h('rect', {...foldRectAttrs}),
			h('rect', {...transRectAttrs}),
			this.getOperateIcon(),
		]);
	}
}


const LaneNode = {
	type: 'lane',
	view: LaneView,
	model: LaneModel,
};

export default LaneNode;
