import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fb8bf3ff"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tool-container" }
const _hoisted_2 = { class: "tool" }
const _hoisted_3 = { class: "project-info" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_el_table = _resolveComponent("el-table")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h5", null, _toDisplayString($setup.tt('3、 Profit And Loss Situation Of Each Project')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString($setup.tt('The projects holding the cost analysis meeting this month include')) + " ", 1 /* TEXT */),
        _createVNode(_component_el_button, {
          type: "text",
          class: "link-button",
          onClick: $setup.onClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.recordsRef.length), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createTextVNode(" " + _toDisplayString($setup.tt('Number.')) + _toDisplayString($setup.tt('The profit and loss situation of each project is detailed in the table below:')), 1 /* TEXT */)
      ]),
      _createVNode(_component_el_table, {
        data: $setup.recordsRef,
        border: "true"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "guigu_project",
            label: $setup.tt('Project'),
            width: "180"
          }, {
            default: _withCtx(({ row }) => [
              _createVNode(_component_el_button, {
                type: "text",
                class: "link-button",
                onClick: $event => ($setup.projectFilterClick(row.name))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.guigu_project), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "total_revenue",
            label: $setup.tt('Accumulation Revenue'),
            width: "180"
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "total_cost",
            label: $setup.tt('Accumulation Cost')
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "self_other_cost",
            label: $setup.tt('Management Cost')
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "total_profit",
            label: $setup.tt('Profit')
          }, null, 8 /* PROPS */, ["label"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["data"])
    ])
  ]))
}