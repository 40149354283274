
<template>
	<div class="organization-container">
		<ElTree
			class="organization-tree"
			:data="organizationTree"
			:props="optionProps"
			nodeKey="id"
			v-loading="organizationLoading"
			:defaultExpandedKeys="rootNodeIds"
			:expandOnClickNode="false"
			@node-click="nodeClick"
			:currentNodeKey="selectedKey"
			highlightCurrent>
			<template #default="{ node, data }">
				<span class="custom-tree-node">
					<Avatar
						v-if="data.type === 'member' && data.id !== 'my'"
						:url="data.user?.user_image"
						:name="data.user?.name"
						:title="data.user?.full_name"></Avatar>
					<span
						:class="{ 'bolder': data.id === 'all' || data.id === 'my' }">{{
						node.label }}</span>
				</span>
			</template>
		</ElTree>
	</div>
</template>
<script lang="ts" setup>
import { ref, computed, watch } from 'vue';

import { list2Tree } from '../../../utils';

import Avatar from './Avatar.vue';
import type { Organization } from './type';
interface Props {
	organizations: Organization[]
	organizationLoading: boolean
}
const props = defineProps<Props>();

interface Emit {
	(e: 'organizationChange', organizationORUser: Organization): void
}
const emit = defineEmits<Emit>();
const selectedKey = ref<string>('');
const optionProps = {
	label: 'title',
};

watch(() => props.organizations, () => {
	if (props.organizations[1] && props.organizations[1].user?.name === frappe.session.user) {
		selectedKey.value = props.organizations[1].id;
		emit('organizationChange', props.organizations[1]);
	} else if (props.organizations[0]) {
		selectedKey.value = props.organizations[0].id;
		emit('organizationChange', props.organizations[0]);
	}
}, { immediate: true });

const organizationTree = computed(() => {
	const organizationTree = list2Tree(props.organizations);
	return organizationTree;
});

const rootNodeIds = computed(() => {
	const ids: string[] = [];
	for (const item of (organizationTree.value || [])) {
		if (item.id !== 'all') {
			ids.push(item.id);
		}
	}
	return ids;
});

function nodeClick(organizationORUser: Organization, node: Node) {
	selectedKey.value = organizationORUser.id;
	emit('organizationChange', organizationORUser);
}
</script>
<style scoped>
.bolder {
	font-weight: bolder;
}

.organization-container {
	height: 100%;
	overflow-y: auto;
}
</style>
