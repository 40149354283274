import { createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElFormItem"], null, {
    default: _withCtx(() => [
      _createVNode($setup["ElTable"], {
        data: $setup.buttons,
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode($setup["ElTableColumn"], {
            type: "index",
            index: 1
          }),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Field')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElInput"], {
                modelValue: row.label,
                "onUpdate:modelValue": $event => ($setup.set($index, 'label', $event || ''))
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Sub Button')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElCheckbox"], {
                modelValue: row.level ? true : false,
                "onUpdate:modelValue": $event => ($setup.set($index, 'level', $event ? 1 : 0))
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Enable Link')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElInput"], {
                modelValue: row.url,
                "onUpdate:modelValue": $event => ($setup.set($index, 'url', $event || ''))
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Operation')
          }, {
            default: _withCtx(({ $index }) => [
              _createVNode($setup["ElButtonGroup"], null, {
                default: _withCtx(() => [
                  _createVNode($setup["ElButton"], {
                    onClick: $event => ($setup.moveTo($index)),
                    text: "",
                    icon: $setup.Sort,
                    title: $setup.tt('Move')
                  }, null, 8 /* PROPS */, ["onClick", "icon", "title"]),
                  _createVNode($setup["ElButton"], {
                    type: "danger",
                    onClick: $event => ($setup.remove($index)),
                    text: "",
                    icon: $setup.Delete,
                    title: $setup.tt('Delete')
                  }, null, 8 /* PROPS */, ["onClick", "icon", "title"])
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["data"]),
      _createVNode($setup["ElButton"], { onClick: $setup.add }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.tt('Add')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}