<template>
	<div class="root ag-theme-guigu">
		<AgGridVue
			class="ag"
			:style="style"
			@selectionChanged="selectionChanged"
			rowSelection="multiple"
			@gridReady="onReady"
			@columnRowGroupChanged="columnRowGroupChanged"
			@RowDragEnd="onRowDragEnd"
			groupDisplayType="singleColumn"
			:rowHeight="rowHeight"
			:getRowId="getRowId"
			:isRowSelectable="isRowSelectable"
			:localeText="zhCN"
			:rowData="data"
			rowGroupPanelShow="always"
			:defaultExcelExportParams="excelExportParams"
			:columnDefs="columnDefs"
			:suppressDragLeaveHidesColumns="smallMeta"
			suppressAggFuncInHeader
			:autoGroupColumnDef="autoGroupColumnDef" />
		<slot name="pagination" />
		<Sort :meta="meta" v-model="dragInfo" />
	</div>
</template>
<script lang="ts" setup>
import {computed, shallowRef, watch} from 'vue';
import {AgGridVue} from 'ag-grid-vue3';
import type {
	SelectionChangedEvent, GridApi, GetRowIdParams,
	ColumnRowGroupChangedEvent, GridReadyEvent, Column, RowDragEndEvent, ColumnApi,
} from 'ag-grid-community';
import {useWindowSize} from '@vueuse/core';

import {useExpander} from '../Expander';
import {useMetaQuery} from '../hooks/useMetaQuery';
import zhCN from '../agGrid/zhCN';

import defaultExcelExportParams from './defaultExcelExportParams';
import getColumns from './getColumns';
import {ListConfiguration} from './types';
import useAggregation from './useAggregation';
import useStyle from './useStyle';
import Title from './Title/index.vue';
import Sort from './Sort.vue';


const props = defineProps<{
	meta: locals.DocType;
	options: Record<string, any>;

	data: locals.Doctype[];
	total: number;
	loading?: boolean;

	modelValue: any;
	selected?: any[];

	infiniteScroll: boolean;
	page: number;
	limit: number;
	group: GlobalView.Group[];
	sort: GlobalView.MainLoaderOptions['order'];
	filters?: any;

	detail?: boolean;
	rowAction?: any;


	view?: GlobalView.View;
	configuration?: ListConfiguration;
}>();
const emit = defineEmits<{
	(event: 'refresh'): void;
	(event: 'delete', doc: any): void;
	(event: 'create', data: any, extend?: boolean): void;
	(event: 'nextPage'): void;
	(event: 'update:modelValue', value: any): void;
	(event: 'update:selected', value: any[]): void;
	(event: 'update:data', data: locals.Doctype[]): void;
	(event: 'filter', field: string, operator: string, value: any): void;
	(event: 'update:configuration', cfg: any): void;

	(event: 'sort', target: string, docs: string[], before: boolean, field?: string): void
	(event: 'sort', target: string, docs: string[], before: boolean, children?: boolean): void
}>();
const smallMeta = useMetaQuery();
const configuration = computed(() => props.configuration || {});
const gridApi = shallowRef<GridApi>();
const gridColumnApi = shallowRef<ColumnApi>();
const {width, height} = useWindowSize();
const isMobile = computed(() => width.value < 640);
watch([isMobile, gridColumnApi], ([isMobile, columnApi]) => {
	if (!isMobile || !columnApi) {
		return;
	}
	const cols = columnApi.getAllGridColumns() || [];
	columnApi.setColumnsPinned(cols.map(v => v.getColId()), null);
}, {immediate: true});
function nextPage() {
	emit('nextPage');
}
function refresh() {
	emit('refresh');
}
function setValue(value?: any) {
	emit('update:modelValue', value);
}
const selected = computed({
	get: () => props.selected,
	set: v => emit('update:selected', v || []),
});
const data = computed({
	get: () => {
		const fields = props.view?.fields.map(f => f.fieldOptions);
		if (!fields?.length) {
			return props.data;
		}
		const list = props.data.map(v => ({...v}));
		for (const {fieldname, fieldtype} of fields) {
			for (const r of list) {
				const v = r[fieldname];
				if (v !== undefined && v !== null) {
					continue;
				}
				r[fieldname] = ['Int', 'Float', 'Currency'].includes(fieldtype) ? 0 : '';
			}
		}
		return list;
	},
	set: v => emit('update:data', v),
});

function setFilter(field: string, operator: string, value: any) {
	emit('filter', field, operator, value);
}

function updateExpanded(expanded: any[]) {
	const api = gridApi.value;
	if (!api) {
		return;
	}
	api.collapseAll();
	const ids = new Set(expanded);
	for (const id of ids) {
		const node = api.getRowNode(id);
		if (!node) {
			continue;
		}
		api.setRowNodeExpanded(node, true);
	}
}

const dragInfo = shallowRef<GlobalView.DragInfo>();
function onRowDragEnd({overNode, event, nodes}: RowDragEndEvent) {
	if (!overNode) {
		return;
	}
	const list = nodes.map(n => n.data);
	if (!list.length) {
		return;
	}
	const target = overNode.data;
	dragInfo.value = {target, list, x: event.pageX, y: event.pageY};
}
function getRowId({data}: GetRowIdParams<locals.Doctype>) {
	return data.name;
}
watch(() => props.data, d => {
	selected.value = [];
	const api = gridApi.value;
	if (api) {
		api.deselectAll();
	}
});
function updateSelected(selected?: any[], api?: GridApi<any>) {
	if (!selected || !Array.isArray(selected) || !api) {
		return;
	}
	const selectedInApi = api.getSelectedNodes();
	const selectedIds = new Set(selected.map(v => v.name));
	for (const node of selectedInApi) {
		const {id} = node;
		if (selectedIds.has(id)) {
			selectedIds.delete(id);
		} else {
			node.setSelected(false);
		}
	}
	for (const id of selectedIds) {
		const node = api.getRowNode(id);
		if (node) {
			node.setSelected(true);
		}
	}
}
watch(gridApi, api => {
	updateSelected(selected.value, api);
});
watch(selected, list => {
	updateSelected(list, gridApi.value);
});

function selectionChanged(event: SelectionChangedEvent) {
	selected.value = event.api.getSelectedRows();
}
const titleDocField = computed(() => {
	const fieldName = props.meta.title_field;
	const {fields} = props.meta;
	return fieldName && fields.find(v => v.fieldname === fieldName) || null;
});


async function onReady({api, columnApi}: GridReadyEvent) {
	gridApi.value = api;
	gridColumnApi.value = columnApi;
}

function preventClick(e: Event, data: any) {
	if (props.detail) {
		e.preventDefault();
	}
	setValue(data?.name === props.modelValue?.name ? undefined : data);
}
const state = {
	get meta() {
		return props.meta;
	},
	get rowAction() {
		return props.rowAction;
	},
	get titleField() {
		return titleDocField.value;
	},
	get linkField() {
		return props.view?.linkField;
	},
};

const dataColumns = computed(() => getColumns(
	props.meta,
	setFilter,
	() => props.data,
	props.view?.fields || [],
	configuration.value.fixedFieldColumns || 0,
	false,
));
function del(data: any) {
	emit('delete', data);
}

const titleDataColumn = computed(() => {
	const {width, maxWidth, minWidth} = props.view?.titleField || {};
	const titleField = titleDocField.value;
	return {
		colId: `id`,
		resizable: true,
		checkboxSelection: true,
		headerCheckboxSelection: true,
		enableRowGroup: false,
		pinned: 'left',
		rowDrag: true,

		headerName: titleField ? __(titleField.label) : 'ID',
		width: width || 200, maxWidth, minWidth,
		field: titleField?.fieldname || 'name',
		cellRendererParams: {
			preventClick,
			del,
			getState: () => state,
		},
		cellRenderer: Title,
	};
});

const columns = computed(() => {
	const list = [titleDataColumn.value, ...dataColumns.value];
	if (configuration.value.showLineNumber) {
		list.unshift({
			headerName: '序号',
			width: 50,
			resizable: true,
			enableRowGroup: false,
			pinned: 'left',
			cellRenderer({node}: any) {
				if (!node) {
					return;
				}
				const no = node.childIndex + 1;
				return isNaN(no) ? '' : no;
			},
		});
	}
	return list;
});
const {setExpandable, expandable} = useExpander(computed(() => props.view?.name || ''));

const aggregation = useAggregation(
	computed(() => props.view),
	configuration,
	titleDocField,
	computed(() => props.meta),
	computed(() => props.data),
);
watch([aggregation, gridApi], ([aggregation, api]) => {
	if (!api) {
		return;
	}
	api.setPinnedBottomRowData(aggregation ? [aggregation] : []);
}, {immediate: true});

const groupColumns = shallowRef<Column[]>([]);
const expanders = computed(() => {
	if (props.loading) {
		return [];
	}
	return groupColumns.value.map((v, i) => ({
		value() {
			const api = gridApi.value;
			if (!api) {
				return [];
			}
			const ids: string[] = [];
			api.forEachNode(node => {
				if (node.level > i) {
					return;
				}
				ids.push(node.id as string);
			});
			return ids;
		},
		title: v.getColDef()?.headerName || `第 ${i + 1} 级`,
	}));
});
watch(gridColumnApi, columnApi=> {
	if (!columnApi) {
		return;
	}
	groupColumns.value = (columnApi.getColumns() || []).filter(c => c.getColDef()?.rowGroup);
});
watch(expanders, list => {
	setExpandable(list, updateExpanded);
	const expandedDefault = props.configuration?.expandedDefault || 0;
	if (expandedDefault <= 0) {
		return;
	}
	const ex = expandable.value;
	if (!ex.length) {
		return;
	}
	const level = Math.min(ex.length, expandedDefault) - 1;
	ex[level]?.set();
}, {immediate: true});

const {rowHeight: {value: rowHeight}, style: {value: style}} = useStyle(configuration);

const columnDefs = columns.value;
const autoGroupColumnDef = {pinned: 'left', resizable: true};
function columnRowGroupChanged(event: ColumnRowGroupChangedEvent<any>) {
	const {rowGroupColumns} = event.columnApi.columnModel;
	groupColumns.value = rowGroupColumns ? [...rowGroupColumns] : [];
}
function isRowSelectable(node: any) {
	return Boolean(node.data);
}
const hasSelected = computed(() => Boolean(selected.value.length));
const excelExportParams = computed(() => ({
	...defaultExcelExportParams,
	onlySelected: hasSelected.value,
}));
</script>
<style scoped lang="less">
.root {
	width: 100%;
	height: 100%;
	padding: 8px;
	position: relative;
}

.ag {
	width: 100%;
	height: 100%;

	:global(.ag-cell-wrapper) {
		height: 100%
	}

	:global(.ag-group-value) {
		flex: 1;
		height: 100%
	}

	:global(.ag-full-width-container) {

		:global(.ag-group-value) {
			flex: unset;
		}
	}
}
</style>
