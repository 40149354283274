import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c2b6f81"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "operation-button" }
const _hoisted_2 = { style: {"display":"flex"} }
const _hoisted_3 = { class: "dialog-footer" }
const _hoisted_4 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h5", null, _toDisplayString($setup.tt('Organization unit task templates')), 1 /* TEXT */),
    _withDirectives((_openBlock(), _createElementBlock("div", null, [
      _createVNode($setup["AgGridVue"], {
        class: "ag-theme-guigu",
        domLayout: "autoHeight",
        rowData: $setup.tableData,
        rowSelection: 'multiple',
        defaultColDef: $setup.defaultColDef,
        onGridReady: $setup.onGridReady,
        suppressDragLeaveHidesColumns: $setup.smallMeta,
        localeText: $setup.zhCN,
        useValueFormatterForExport: "",
        getRowId: $setup.getRowId,
        onRowSelected: $setup.handleRowSelected
      }, null, 8 /* PROPS */, ["rowData", "suppressDragLeaveHidesColumns", "localeText"]),
      _createElementVNode("div", _hoisted_1, [
        (!$setup.props.frm.is_new() && $setup.manager)
          ? (_openBlock(), _createBlock($setup["ElButton"], {
              key: 0,
              type: "primary",
              onClick: $setup.createTaskTemplate,
              size: "small"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.tt('new')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createVNode($setup["ElPopover"], {
          placement: "bottom",
          visible: $setup.visible,
          showArrow: "",
          trigger: "click"
        }, {
          reference: _withCtx(() => [
            (!$setup.props.frm.is_new() && $setup.manager && $setup.selectData.length > 0)
              ? (_openBlock(), _createBlock($setup["ElButton"], {
                  key: 0,
                  type: "danger",
                  size: "small",
                  onClick: _cache[1] || (_cache[1] = $event => ($setup.visible = true))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($setup.tt('Disable')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true)
          ]),
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString($setup.tt("Are you sure you want to disable it")) + "?", 1 /* TEXT */),
            _createElementVNode("div", _hoisted_2, [
              _createVNode($setup["ElButton"], {
                size: "small",
                onClick: _cache[0] || (_cache[0] = $event => ($setup.visible = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.tt("Cancel")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode($setup["ElButton"], {
                size: "small",
                type: "primary",
                onClick: $setup.disableTemplate
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.tt("Sure")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["visible"])
      ])
    ])), [
      [$setup["vLoading"], $setup.deleteLoading]
    ]),
    ($setup.dialogVisible)
      ? (_openBlock(), _createBlock($setup["ElDialog"], {
          key: 0,
          modelValue: $setup.dialogVisible,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.dialogVisible) = $event)),
          title: $setup.tt('Batch create task'),
          width: "80%"
        }, {
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode($setup["ElButton"], {
                onClick: _cache[2] || (_cache[2] = $event => ($setup.cancelDialogVisible=true)),
                size: "small"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode($setup["ElButton"], {
                type: "primary",
                size: "small",
                onClick: $setup.confirm
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.tt('Confirm')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ]),
          default: _withCtx(() => [
            _createVNode($setup["EditTable"], {
              tableData: $setup.taskTemplate,
              taskTypeList: $setup.taskTypeList,
              user: $setup.organizationUser
            }, null, 8 /* PROPS */, ["tableData", "taskTypeList", "user"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "title"]))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["ElDialog"], {
      modelValue: $setup.cancelDialogVisible,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.cancelDialogVisible) = $event)),
      title: $setup.tt('Prompt')
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode($setup["ElButton"], {
            onClick: _cache[4] || (_cache[4] = $event => ($setup.cancelDialogVisible = false)),
            size: "small"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode($setup["ElButton"], {
            type: "primary",
            size: "small",
            onClick: $setup.cancelCreate
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('Confirm')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", null, _toDisplayString($setup.tt("Are you sure to cancel the creation task? The maintained information will be cleared")), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "title"])
  ], 64 /* STABLE_FRAGMENT */))
}