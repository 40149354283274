import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-edcd8373"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Page"], { mainStyle: {display:'flex', flexDirection:'column'} }, {
      title: _withCtx(() => [
        _createElementVNode("h3", _hoisted_2, _toDisplayString($setup.tt('Statistics of project leadership work logs')), 1 /* TEXT */)
      ]),
      tools: _withCtx(() => [
        _createVNode($setup["ToolBar"], {
          subCompany: $setup.selectedSubCompany,
          "onUpdate:subCompany": _cache[0] || (_cache[0] = $event => (($setup.selectedSubCompany) = $event)),
          designations: $setup.selectedDesignations,
          "onUpdate:designations": _cache[1] || (_cache[1] = $event => (($setup.selectedDesignations) = $event)),
          startDate: $setup.selectedStartDate,
          "onUpdate:startDate": _cache[2] || (_cache[2] = $event => (($setup.selectedStartDate) = $event)),
          endDate: $setup.selectedEndDate,
          "onUpdate:endDate": _cache[3] || (_cache[3] = $event => (($setup.selectedEndDate) = $event)),
          refreshValue: $setup.refreshValue,
          "onUpdate:refreshValue": _cache[4] || (_cache[4] = $event => (($setup.refreshValue) = $event)),
          loading: $setup.loading,
          "onUpdate:loading": _cache[5] || (_cache[5] = $event => (($setup.loading) = $event)),
          size: $setup.size,
          detailGridApi: $setup.detailGridApi
        }, null, 8 /* PROPS */, ["subCompany", "designations", "startDate", "endDate", "refreshValue", "loading", "size", "detailGridApi"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode($setup["SummaryTable"], {
            startDate: $setup.selectedStartDate,
            endDate: $setup.selectedEndDate,
            loading: $setup.loading,
            summaryTableData: $setup.summaryTableData,
            isPhone: $setup.size,
            onOnDetailGridReady: $setup.onDetailGridReady
          }, null, 8 /* PROPS */, ["startDate", "endDate", "loading", "summaryTableData", "isPhone"])
        ]),
        _createVNode($setup["AttendanceType"], { attendanceTypes: $setup.attendanceTypes }, null, 8 /* PROPS */, ["attendanceTypes"])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}