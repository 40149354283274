<template>
	<div class="detail-width">
		<div class="detail-container">
			<span class="label"> {{ tt('Description') }}: </span>
			<span class="value">
				<p v-html="description"></p>
			</span>
		</div>
	</div>
</template>
<script lang="ts" setup>
const tt = __;
defineProps<{
	description:string
}>();

</script>
<style lang="less" scoped>
.detail-width {
	position: relative;
	flex: 1;
	overflow: auto;
}
.detail-container {
	position: relative;
	border-radius: 8px;
	margin-right: 8px;
	padding: 8px;
	padding-top: 0px;
	padding-left: 0px;

	&:hover {
		background: #f0f2f5;

		.to_detail {
			opacity: 1;
		}
	}
	.label {
		margin-right: 8px;
		font-weight: bolder;

		.fullname {
			vertical-align: bottom;
			line-height: 25px;
		}
	}

	.value {
		flex: 1;
		white-space: pre-wrap;
		word-break: break-all;
	}
}
</style>
