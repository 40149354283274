<template>
	<ElFormItem :label="tt('Connection Field')">
		<ElSelect v-model="connectionField" filterable defaultFirstOption clearable>
			<ElOption v-for="field in linkOptions" :key="field.value"
				:value="field.value" :label="field.label" />
		</ElSelect>
	</ElFormItem>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { ElSelect, ElFormItem } from 'element-plus';

const props = defineProps<{
	meta: locals.DocType;
	modelValue: any;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: GlobalView.View): void;
}>();
const cfg = computed({
	get: () => props.modelValue,
	set: v => emit('update:modelValue', v),
});
const tt = __;

const connectionField = computed<string>({
	get: () => cfg.value.connection_field || '',
	set: v => { cfg.value = { ...cfg.value, connection_field: v || null }; },
});

const fields = computed(() => props.meta.fields || []);

const linkOptions = computed(() => fields.value.filter(d => ['Link'].includes(d.fieldtype))
	.map(d => ({
		value: d.fieldname,
		label: `${__(d.label)} (${d.fieldname})`,
	})));
</script>
