import { Ref } from 'vue';

import {User, DiaryRecordB, UserDailyRecords, Designation, Project, MemberData} from './type';

export function routeInit(paramsFilter:Ref) {
	const queryObj = new URLSearchParams(window.location.search);
	// 创建一个对象来存储参数
	let paramsObj:{[key: string]: string} = {};
	const keyArr = ['subCompany', 'designations', 'date'];
	// 遍历每个参数，并将它们存储在 params 对象中
	for (const item of keyArr) {
		if (queryObj.get(item))  {
			paramsObj[item] = queryObj.get(item) || '';
		}
	}
	paramsFilter.value = paramsObj;
}

export function setRoute(filter: { [key: string]: string }, paramsFilter:Ref) {
	const newParamsFilter = { ...paramsFilter.value, ...filter };
	const queryObj = new URLSearchParams(newParamsFilter);
	paramsFilter.value = newParamsFilter;
	history.replaceState(null, '', `?${queryObj.toString()}`);
}

export async function getUsers(project: string, designations: string[]):Promise<
{userList: User[], projectDesignationUserData: User[]}
>{
	/** 获取项目下的全部member */
	const memberResult = await frappe.call<{message: MemberData[]}>({
		method:'guigu_pm.guigu_pm_daily_record.page.project_leader_daily_record.project_leader_daily_record.get_project_member',
		args:{
			'project': project === 'all'? '':project,
			'designations': designations,
		},
	});
	const memberData = memberResult?.message || [];
	/** 一个user 可能会对应多个member， 所以查user之前需要去重 */
	const memberUser = [...new Set(memberData.map(item => item.user))];
	/** 这是全部项目符合职位的member的user去重后对应的人员
	 * 这个主要是用于往后端借口中返回，查询人员对应的日志数据
	*/
	const userData = await frappe.db.get_list<User>('User', {
		filters:[['name', 'in', memberUser]],
		fields: ['*'],
		limit: 0,
	});

	const userDataObj:{[key: string]: User} = {};
	for (const item of userData) {
		userDataObj[item.name] = item;
	}
	/** 全部项目职位对应的人员
	 * 但是在显示时，只要是全部项目职位对应的人员不管有没有写日志都需要把人显示出来
	 * 所以，还需要这样一组数据
	*/
	const projectDesignationUserData = memberData.map(item => ({
		...item,
		...userDataObj[item.user],
	}));
	return {userList: userData, projectDesignationUserData};
}

const AUTHOR_RULE_NUMBER = 20;

export async function getQueryParams(
	project:string,
	designations: string[],
	date:string,
	page:number,
):Promise<{
	recordOwners: any[],
	actualMemberOwners: any[],
	endDateParam: string,
	startDateParam:string,
	dayCount:number,
	batch: number
}>{
	let requestUserList: User[] = [];
    let startDateParam = '';
    let endDateParam = '';
	let batch:number = 0;
	// let userList:User[] = [];
	let { userList, projectDesignationUserData} = await getUsers(project, designations);

	if (projectDesignationUserData.length === 0){
		return {recordOwners: userList, actualMemberOwners: projectDesignationUserData, endDateParam, startDateParam, dayCount:1, batch};
	}
	let authorArray: User[][] = [];
	let dayCount: number = 1;
	if (projectDesignationUserData.length < AUTHOR_RULE_NUMBER) {
		authorArray = [projectDesignationUserData];
		dayCount = Math.ceil(AUTHOR_RULE_NUMBER / projectDesignationUserData.length);
		startDateParam = moment(date)
			.subtract(
				(page - 1) * dayCount,
				'days',
			)
			.format('YYYY-MM-DD');
		endDateParam = moment(startDateParam)
			.subtract(dayCount, 'days')
			.format('YYYY-MM-DD');
		requestUserList = projectDesignationUserData;
	}
	if (
		AUTHOR_RULE_NUMBER <= projectDesignationUserData.length &&
		projectDesignationUserData.length < AUTHOR_RULE_NUMBER * 2
	) {
		authorArray = [projectDesignationUserData];
		dayCount = 1;
		/** 当 15 <=人员数量 < 30 我们每次只读取一天的数据 */
		startDateParam = page === 1
			? date
			: moment(date)
				.subtract((page) - 1, 'days')
				.format('YYYY-MM-DD');
		endDateParam = page===1
			? date
			: moment(date)
				.subtract(page - 1, 'days')
				.format('YYYY-MM-DD');
		requestUserList = projectDesignationUserData;
	}
	if (projectDesignationUserData.length >= AUTHOR_RULE_NUMBER * 2) {
		/** 人员大于30时，要分批获取人员，分批获取完成后，再从下一天循环分批获取 */
		// TODO 人员大于30时可以将分批处理拿到外层，当人员变动时再重新处理
		const arr: User[][] = [];
		const floor = Math.floor(projectDesignationUserData.length / AUTHOR_RULE_NUMBER);
		const floorArr: number[] = Array.from(Array(floor));
		for (const [index, item] of floorArr.entries()) {
			if (
				index + 1 === floorArr.length &&
				projectDesignationUserData.length % AUTHOR_RULE_NUMBER > 0
			) {
				arr.push((projectDesignationUserData || []).slice(index * AUTHOR_RULE_NUMBER));
			} else {
				arr.push(
				(projectDesignationUserData || []).slice(
					index * AUTHOR_RULE_NUMBER,
					(index + 1) * AUTHOR_RULE_NUMBER,
				),
				);
			}
		}
		authorArray = arr;
		const integer = Math.floor(
			page / authorArray.length,
		);
		const remainder =
			page % authorArray.length;
		startDateParam =
			integer && remainder === 1
			? moment(date).subtract(integer, 'days').format('YYYY-MM-DD')
			: date;
		endDateParam =
			integer && remainder === 1
			? moment(date).subtract(integer, 'days').format('YYYY-MM-DD')
			: date;
		batch = page % authorArray.length
			? (page % authorArray.length) - 1
			: authorArray.length - 1;
		const users =  authorArray[batch];
		requestUserList = users;
	}
	return {
		recordOwners: userList,
		actualMemberOwners: requestUserList,
		endDateParam,
		startDateParam,
		dayCount,
		batch};
  }

  export function generateDay(length: number, start_date: string){
    const dayArr = [];
    for (let i = 0; i < length; i++) {
      dayArr.push(moment(start_date).subtract(i, 'days').format('YYYY-MM-DD'));
    }
    return dayArr;
  }
  export function buildData(dateArr:string[], dailyRecords:DiaryRecordB[], actualMemberOwners:User[]){
	const dailyObj:Record<string, DiaryRecordB[]> = {};
	for (const item of dailyRecords) {
      if (dailyObj[`${item?.record_owner}${item?.record_date}${item.project}`]) {
        dailyObj[`${item?.record_owner}${item?.record_date}${item.project}`].push(item);
      } else {
        dailyObj[`${item?.record_owner}${item?.record_date}${item.project}`] = [item];
      }
    }
    const dataList: Record<string, UserDailyRecords[]>[] = [];
    if (dateArr.length) {
		dateArr.forEach((item: string) => {
			const authorDailyArr: UserDailyRecords[] = actualMemberOwners.map(author => ({
				userId: `${author.name}${author.project}`,
				user:author,
				dailyRecords: dailyObj[`${author.name}${item}${author.project}`] || [],
			}));
			dataList.push({ [item]: authorDailyArr });
		});
    }
	return {dailyRecordList:dataList, dailyRecordListHash:dailyObj};
}

export function buildSearchData(dailyRecords:DiaryRecordB[]){
	const dataList: Record<string, UserDailyRecords[]>[] = [];
    for (const item of dailyRecords) {
      dataList.push({
        [item.record_date]: [{
			userId: item.record_owner,
			user:item.user,
			dailyRecords:[item],
		}],
      });
    }
	return {dailyRecordList:dataList};
}
