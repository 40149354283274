<template>
	<div class="page">
		<Page :mainStyle="{display:'flex', flexDirection:'column'}">
			<template #title>
				<h3 class="title">{{ tt('Company Log Attendance Page') }}</h3>
			</template>
			<template #tools>
				<ToolBar
					v-model:company="company"
					v-model:monthDate="monthDate"
					v-model:yearDate="yearDate"
					v-model:pageType="pageType"
					v-model:countCategory="countCategory"
					v-model:refreshValue="refreshValue"
					v-model:loading="loading"
					:size="size"
					:detailGridApi="detailGridApi">
				</ToolBar>
			</template>
			<div class="container" ref="containerRef">
				<SummaryTable
					:pageType="pageType"
					:countCategory="countCategory"
					:monthDate="monthDate"
					:yearDate="yearDate"
					:company="company"
					:summaryTableData="summaryTableData"
					:attendanceTypes="attendanceTypes"
					:loading="loading"
					:isPhone="size"
					@onDetailGridReady="onDetailGridReady">
				</SummaryTable>
			</div>
			<AttendanceType
				v-if="company && countCategory == 'date' && pageType == 'month'"
				:company="company"
				:attendanceTypes="attendanceTypes" />
		</Page>
	</div>
</template>

<script lang="ts" setup>
import {onMounted, onUnmounted, ref, watch} from 'vue';
import {GridApi} from 'ag-grid-community';

import Page from '../../../../../../../../guigu_pm/guigu_pm/public/js/components/page/index.vue';
import {SummaryMessage, SummaryTableData} from '../type';

import ToolBar from './components/ToolBar.vue';
import SummaryTable from './components/SummaryTable.vue';
import AttendanceType from './components/AttendanceType.vue';
import {daily_record_data_processing} from './components/helper';
import { ElLoading } from 'element-plus'
const tt = __;
const company = ref<string>('');
const monthDate = ref<string>();
const yearDate = ref<string>();
const pageType = ref<'month' | 'year'>('month');
const countCategory = ref<'date' | 'category'>('date');
const refreshValue = ref<number>(0);
const attendanceTypes = ref([]);

const loading = ref<boolean>(false);

const summaryTableData = ref<SummaryTableData[]>([]);
const detailGridApi = ref<GridApi>();
const size = ref<'' | 'small' | 'default' | 'large' | undefined>('small');
const containerRef = ref<HTMLDivElement|undefined>()

function updateComponentSize() {
	const widthElement = window.innerWidth;
	if (widthElement <= 640) {
		size.value = 'default';
	} else {
		size.value = 'small';
	}
}

function onDetailGridReady(params: any) {
	detailGridApi.value = params;
}

async function getAttendanceType() {
	const projectAttendanceType = await frappe.db.get_list('Guigu Pc Daily Record Attendance Type', {
		filters: [],
		fields: ['*'],
		limit: 0,
	});
	attendanceTypes.value = projectAttendanceType;
}
watch([company, pageType, countCategory, yearDate, monthDate, refreshValue], async () => {
	if (!yearDate.value || !company.value) {
		return;
	}
	loading.value = true;
	if (countCategory.value === 'category') {
		const data = await frappe.call<{ message: SummaryMessage }>({
			method:
				'guigu_pc.guigu_pc_daily_record.page.sub_company_daily_record_statistics.sub_company_daily_record_statistics.get_attendance_dairy',
			args: {
				sub_company: company.value,
				type: pageType.value,
				date: pageType.value === 'month' ? monthDate.value : yearDate.value,
			},
		});

		const result = daily_record_data_processing(data?.message?.daily_record || [], 'year', data?.message?.member_sort || []);
		summaryTableData.value = result;
	} else {
		const data = await frappe.call<{ message: SummaryMessage }>({
			method:
				'guigu_pc.guigu_pc_daily_record.page.sub_company_daily_record_statistics.sub_company_daily_record_statistics.get_organization_dairy',
			args: {
				sub_company: company.value,
				type: pageType.value,
				date: pageType.value === 'month' ? monthDate.value : yearDate.value,
			},
		});
		const result = daily_record_data_processing(data?.message?.daily_record || [], pageType.value, data?.message?.member_sort || []);
		summaryTableData.value = result;
	}
	await getAttendanceType();
	loading.value = false;

});
let loadingInstance:any
watch(loading,()=>{
	if(loading.value){
		loadingInstance = ElLoading.service({
			target:containerRef.value,
		})
	}else{
		loadingInstance?.close()
	}
},{immediate:true})
onMounted(() => {
	window.addEventListener('resize', updateComponentSize);
});

onUnmounted(() => {
	window.removeEventListener('resize', updateComponentSize);
});
</script>
<style lang="css" scoped>
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  :deep(.toggle-btn) {
    display: none;
  }
  .title {
    height: 75px;
    line-height: 75px;
    margin-bottom: 0;
  }
</style>
