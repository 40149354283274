import type {
  CellClassParams,
  ColDef,
  ColGroupDef,
  ColSpanParams,
  EditableCallbackParams,
  ICellEditorParams,
  RowSpanParams,
} from 'ag-grid-community';

import type * as Permission from '../../Permission';
import type { ExtraScore, Permissions } from '../type';
import AgGridToolTip from '../../AgGridToolTip.vue';

import Action from './AgGridAction.vue';
import { isEditable } from './fixScoreColumns';

function getEditableFun(field: string, lockStatus: boolean, permission: Permission.DocPermission | null) {
  return function editableFun(params: EditableCallbackParams<ExtraScore & { type_label: string }>) {
    if (params.data?.type_label === __('Score Summary')) {
      return false;
    }
    const writable = isEditable(field, lockStatus, permission, true);
    if (typeof writable === 'boolean') {
      return writable;
    }
    return params.data?.user === frappe.user.name;
  };
}
export function getColumns(
  assessDetailList: (ExtraScore & { type_label: string })[],
  onRemove: (data: ExtraScore) => void,
  permission: Permission.DocPermission | null,
  lockStatus: boolean,
  smallMeta: boolean
): (ColDef | ColGroupDef)[] {
  const extraAssessLength = (assessDetailList || []).filter((item) => item.name !== 'auto_calc').length;
  const columns = [
    {
      headerName: __('Other Bonus And Deduction Points'),
      field: 'type_label',
      enableRowGroup: false,
      editable: false,
      filter: false,
      colSpan: (params: ColSpanParams) => (params.data.type_label === __('Score Summary') ? 4 : 1),
      cellStyle: (params: CellClassParams) => {
        const { data } = params;
        if (data?.type_label === __('Score Summary')) {
          return { display: 'flex', justifyContent: 'center' };
        }
        return;
      },
    },
    {
      headerName: __('Order Number'),
      cellStyle: { 'text-align': 'right' },
      field: 'index',
      menuTabs: [],
      width: 50,
      enableRowGroup: false,
      editable: false,
      filter: false,
    },
    {
      headerName: __('Specific Items For Adding Or Subtracting Points'),
      field: 'subject',
      flex: 1,
      minWidth: 150,
      enableRowGroup: false,
      filter: false,
      cellRenderer: AgGridToolTip,
      editable: getEditableFun('subject', lockStatus, permission),
    },
    {
      headerName: __('Reasons For Adding Or Subtracting Points'),
      field: 'reason',
      flex: 1,
      minWidth: 150,
      menuTabs: [],
      enableRowGroup: false,
      filter: false,
      cellRenderer: AgGridToolTip,
      editable: getEditableFun('reason', lockStatus, permission),
    },
    {
      headerName: __('Rating'),
      cellStyle: { 'text-align': 'right' },
      field: 'score',
      width: 65,
      enableRowGroup: false,
      filter: false,
      editable: getEditableFun('score', lockStatus, permission),
      cellEditor: 'agNumberCellEditor',
    },
    {
      headerName: extraAssessLength === 0 ? __('Final Score') : __('Operations'),
      width: 80,
      pinned: smallMeta ? undefined : ('right' as const),
      enableRowGroup: false,
      filter: false,
      sortable: false,
      editable: false,
      menuTabs: [],
      cellRenderer: Action,
      cellRendererParams: (params: ICellEditorParams) => {
        if (lockStatus || !permission) {
          return {};
        }
        if (permission.deletable) {
          return { onRemove };
        }
        if (!permission.ifOwner.deletable) {
          return {};
        }
        return params.data?.user === frappe.user.name ? { onRemove } : {};
      },
    },
  ];
  return columns;
}
