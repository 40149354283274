import type { Column, Group } from './types';

function toValue(v: any) {
	return ['string', 'number'].includes(typeof v) ? v : '';
}
function getNewColumns(
	list: Column[],
	data: Partial<Column> & { value: any, index?: number } | boolean | number | string
): Column[] | void {
	const columns: Column[] = list.map(({ title, value, color, bgColor }) => ({
		title, value: toValue(value), color, bgColor,
	}));
	if (typeof data !== 'object') {
		const val = toValue(data);
		const col = columns.findIndex(v => v.value === val);
		if (col < 0) { return; }
		columns.splice(col, 1);
		return columns;
	}
	const { title, color, bgColor, value, index } = data;
	const val = toValue(value);
	let updated = false;
	let oldIndex = columns.findIndex(v => v.value === val);
	if (oldIndex < 0) {
		oldIndex = columns.push({ value: val, title: val }) - 1;
		updated = true;
	}
	const col = columns[oldIndex];
	if (typeof title === 'string' && col.title !== title) {
		col.title = title;
		updated = true;
	}
	if ((typeof color === 'string' || color === null) && col.color !== color) {
		col.color = color;
		updated = true;
	}
	if ((typeof bgColor === 'string' || bgColor === null) && col.bgColor !== bgColor) {
		col.bgColor = bgColor;
		updated = true;
	}
	if (index && index > 0) {
		const k = index - 1;
		if (k !== oldIndex) {
			columns.splice(k, 0, ...columns.splice(oldIndex, 1));
			updated = true;
		}
	}
	if (!updated) { return; }
	return columns;
}

export default function getNewGroups(
	groups: Group[],
	field: string,
	data: Partial<Column> & { value: any, index?: number } | boolean | number | string
): Group[] | void {
	const index = groups.findIndex(g => g.field === field);
	if (index < 0) { return; }
	const group = groups[index];
	if (!group) { return; }
	const values = getNewColumns(group.values, data);
	if (!values) { return; }
	return [
		...groups.slice(0, index),
		{ ...group, values },
		...groups.slice(index + 1),
	];
}
