<template>
	<div class="editor-loading" v-loading="true">
	</div>
</template>

<script setup lang="ts">

import {vLoading} from 'element-plus';
</script>
<style scoped lang="less">
.editor-loading{
	height: 400px;
}
</style>
