
export function getFormatData(data:Record<string, any>[], parentField:string){
	return data.map(item=>({
			...item,
			lft:item.lft,
			rgt:item.rgt,
			id:item.name,
			parent:item[parentField],
		}));
}
interface Item{
	id:string,
	parent?:string
	lft:number,
	rgt:number
	[other:string]:any
}
export interface TreeItem extends Item {
	children?: this[];
  }
  export function tree2list(treeData: TreeItem[], list: Item[]) {
	treeData.forEach(item => {
	  const itemCopy = JSON.parse(JSON.stringify(item));
	  const { children } = itemCopy;
	  delete itemCopy.children;
	  list.push(itemCopy);
	  if (children?.length > 0) {
		tree2list(children, list);
	  }
	});
  }
export function list2Tree<T extends Item>(listData: T[], treeGroupField:string) {
	const listDataCopy:T[] = JSON.parse(JSON.stringify(listData));
	const treeData: (T & TreeItem)[] = [];
	const map:Record<string, any> = {};
	listDataCopy.forEach(item => {
	  map[item.id] = item;
	});
	listDataCopy.forEach(item => {
	  let parent = map[item.parent || 0];
	  if (!parent){
		[parent] = listDataCopy.filter(each=>{
			if (!treeGroupField){
				return each.lft<item.lft&&each.rgt>item.rgt;
			}
				return each.lft<item.lft&&each.rgt>item.rgt&&each[treeGroupField] === item[treeGroupField];
		}).sort((pre, next)=>pre.lft-next.lft);
	  }
	  if (parent) {
		(parent.children || (parent.children = [])).push(item);
	  } else {
		treeData.push(item);
	  }
	});
	return treeData;
  }


  export function getAncestry(
	treeData: TreeItem[],
	parentAncestry: (string | number)[] = [],
  ) {
	treeData.forEach(item => {
	  item.ancestry = [...parentAncestry, item.id];
	  if (item?.children && item?.children?.length > 0) {
		getAncestry(item.children, [...parentAncestry, item.id]);
	  } else {
		return;
	  }
	});
  }
  export function getTreeAncestry<T extends Item>(data: T[], treeGroupField:string):(T&{ancestry:string[]})[] {
	const treeData = list2Tree(data, treeGroupField);
	getAncestry(treeData);
	const list: (T&{ancestry:string[]})[] = [];
	tree2list(treeData, list);
	return list;
  }
