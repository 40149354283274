import {define} from 'tianjy_workspace';

import vue2renderer from '../vue2renderer';
import createWidgetDialog from '../createWidgetDialog';

import View from './index.vue';
import Config from './Configuration/index.vue';

define(
	'DocList',
	vue2renderer(View),
	createWidgetDialog(Config, '视图'),
	{
		title: '视图',
	},
);
