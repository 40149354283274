import {exportFile} from './exportFile';
interface Cell {
	row:number,
	col:number,
	className: string
}
export function getHeader(data: Array<Array<Array<string>>>):Promise<{
	dataHeader: string[][], dataCell: {row:number, col:number, class: string}[]
}> {
	const dataHeaderCell: Cell[] = [];
	let headerErrorString = '';
	const dataHeader = data.map((item, index) => {
		if (item[1].length) {
			dataHeaderCell.push({row: 0, col: index, className: 'customCell'});
			headerErrorString += `${item[0]}: ${item[1].join(',')}\n`;
		}
		return item[0];
	});
	return {dataHeader: [...dataHeader, headerErrorString], dataHeaderCell};
}
export function getData(data: Array<Array<Array<string[]>>>):Promise<{
	dataRow: string[][], dataRowCell: Cell[]
}> {
	const dataRowCell: Cell[] = [];
	const dataRow = data.map((item, rowIndex) =>{
		let rowErrorSting = '';
		const row = item.map((row, columnIndex) => {
			if (row[1].length) {
				dataRowCell.push({row: rowIndex + 1, col: columnIndex, className: 'customCell'});
				rowErrorSting += `${row[0]}: ${row[1].join(',')}\n`;
			}

			return row[0];
		});
		return [...row, rowErrorSting];
	});
	return {dataRow, dataRowCell};
}

export function getMenus() {
	const items:Record<string, any> = {
		export: {
			name: __('Export'),
			callback() {
				exportFile(this, '工程量清单导入');
			},
		},
	};
	return {items};
}
