import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d1487784"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tools"
}
const _hoisted_2 = {
  class: "chart-container",
  ref: "blockRef"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.isEditable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode($setup["GripVertical"], {
            class: "drag-handle",
            contenteditable: "false",
            draggable: "true",
            "data-drag-handle": "",
            size: 16
          }),
          _createVNode($setup["BlockActions"], {
            onRemove: $setup.remove,
            editable: $props.isEditable
          }, null, 8 /* PROPS */, ["editable"])
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_resolveDynamicComponent($setup.BlockComponent), {
        ref: "BlockComponentRef",
        chartName: $props.chartName,
        onSetData: $setup.setData,
        data: $props.data,
        editable: $props.isEditable
      }, null, 40 /* PROPS, HYDRATE_EVENTS */, ["chartName", "data", "editable"]))
    ], 512 /* NEED_PATCH */)
  ], 64 /* STABLE_FRAGMENT */))
}