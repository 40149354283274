import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e2aaf468"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scroll-container" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "aggrid-container ag-theme-guigu" }
const _hoisted_4 = { class: "dialog-footer" }
const _hoisted_5 = { class: "create-btn" }
const _hoisted_6 = { class: "footer-buttons" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives((_openBlock(), _createBlock($setup["ElDialog"], {
      modelValue: $setup.dialogVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.dialogVisible) = $event)),
      onClose: $setup.onCancel,
      title: $setup.tt('Assessment Standard Template'),
      class: "dialog",
      width: 'min(600px, max(min(300px, 100%), 50%))'
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode($setup["ElLink"], {
              class: "cus-link-btn",
              type: "primary",
              onClick: $setup.create
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.tt('Create New Assessment Criteria')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createElementVNode("span", _hoisted_6, [
            _createVNode($setup["ElButton"], { onClick: $setup.onCancel }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode($setup["ElButton"], {
              class: "confirm-btn",
              type: "primary",
              onClick: $setup.confirm
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.tt('Confirm')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode($setup["AgGridVue"], {
                class: "aggrid",
                columnDefs: $setup.columnDefs,
                rowData: $setup.rowData,
                defaultColDef: $setup.defaultColDef,
                animateRows: "true",
                onGridReady: $setup.onGridReady,
                domLayout: "autoHeight",
                localeText: $setup.zhCN,
                rowSelection: "single",
                scroll: "",
                suppressRowClickSelection: false,
                suppressDragLeaveHidesColumns: $setup.smallMeta,
                onRowDataUpdated: _ctx.onRowDataUpdated,
                isRowSelectable: _ctx.isRowSelectable
              }, null, 8 /* PROPS */, ["rowData", "localeText", "suppressDragLeaveHidesColumns", "onRowDataUpdated", "isRowSelectable"])
            ])
          ])), [
            [_directive_loading, $setup.loading]
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "title", "width"])), [
      [_directive_loading, $setup.dialogLoading]
    ])
  ]))
}