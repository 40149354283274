import { computed, Ref, shallowRef, unref } from 'vue';


export default function useSort(
	meta: Ref<locals.DocType> | locals.DocType,
	setting: Ref<GlobalView> | GlobalView,
	configuration: Ref<object> | object,
	view: Ref<GlobalView.View> | GlobalView.View,
) {
	/** 排序 */
	const order = shallowRef<GlobalView.Order[]>([]);
	/** 默认排序 */
	const defaultOrder = computed(() => {
		const orderDefault = unref(view).defaultOrder;
		const order = unref(setting).defaultOrder;
		if (typeof order !== 'function') { return orderDefault; }
		return [...order(orderDefault, unref(meta), unref(configuration))];
	});
	/** 排序 */
	function setOrder(slot: GlobalView.Sort[]) {
		const doctype = unref(meta).name;
		order.value = slot.map(a => ({
			field: a.field,
			doctype: a.doctype || doctype,
			desc: a.desc || false,
		}));
	}
	setOrder(defaultOrder.value);

	const sort = computed(() => {
		const sort = order.value;
		if (sort.length) { return sort; }
		const { sort_field, sort_order, name } = unref(meta);
		return [{
			doctype: name,
			field: sort_field || 'modified',
			desc: sort_order?.toUpperCase() === 'DESC',
		}];
	});
	return {
		sort,
		setOrder,
		orderN: computed(() => order.value.length),
	};
}
