import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c6f332b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    innerHTML: $setup.imageHTML,
    class: "user-image"
  }, null, 8 /* PROPS */, _hoisted_1))
}