<template>
	<div v-loading="loading">
		<Page>
			<template #title>
				<h3 class="title">
					{{ type === 'project' ? tt('Project') : tt('Company')
					}}{{ tt('Weekly Assessment Detail') }}
				</h3>
				<span
					class="indicator-pill whitespace-nowrap blue"
					v-if="lockStatus"
				>
					<span>{{ tt('Already Locked') }}</span>
				</span>
			</template>
			<template #sider>
				<Member :users="members" v-model="userValue" />
			</template>
			<template #tools>
				<Header
					:type="type"
					:assessUserPermission="assessUserPermission"
					:assessInfo="assessInfo"
					:detailPermission="detailPermission"
					:lockStatus="lockStatus"
					:smallMeta="smallMeta"
					@update="getAssess"
				></Header>
			</template>
			<DetailHeader
				:type="type"
				:assessUserPermission="assessUserPermission"
				:assessInfo="assessInfo"
				:detailPermission="detailPermission"
				:lockStatus="lockStatus"
				:smallMeta="smallMeta"
				@update="getAssess"
			/>
			<div v-if="lockStatus">
				<el-alert
					:title="prompt"
					type="warning"
					show-icon
					:closable="false"
				/>
			</div>
			<Detail
				:type="type"
				:detailPermission="detailPermission"
				:assessUserPermission="assessUserPermission"
				:lockStatus="lockStatus"
				:assessInfo="assessInfo"
				@update="getAssess"
				@remove="onRemove"
				@createTempTask="createTempTask"
			></Detail>
		</Page>
		<CreateTempDialog
			:visible="visible"
			:smallMeta="smallMeta"
			@cancel="visible = false"
			@confirm="confirmCreateTemp"
		></CreateTempDialog>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';

import * as Permission from '../Permission';
import Page from '../../../../../../../guigu_pm/guigu_pm/public/js/components/page/index.vue';
import { useMetaQuery } from '../../../../../../../guigu_pm/guigu_pm/public/js/components/page/useMetaQuery';
import Member from '../Member/index.vue';
import type { Assess } from '../weekAssessmentList/type';

import Header from './components/Header.vue';
import Detail from './components/Detail.vue';
import DetailHeader from './components/DetailHeader.vue';
import CreateTempDialog from './components/CreateTempDialog.vue';
import type { AssessDetail, AssessInfo, ExtraScore } from './type';

const tt = __;
const prompt = __(
	'The record has been locked. If you need to modify it, please contact the HR department to unlock it',
);
const props = defineProps<{
	week?: string;
	type: 'project' | 'subCompany';
	department?: string;
	user?: string;
	organization?: string;
}>();
const emit = defineEmits<{
	'update:week': [string?];
	'update:department': [string?];
	'update:user': [string?];
}>();

const members = ref<{ label: string; value: string }[]>([]);
const userValue = computed({
	get: () => props.user,
	set: u => emit('update:user', u),
});
const isCurrentUser = computed(() => userValue.value === frappe.user.name);
const commonArgs = computed(() => {
	const { week, department, user, type } = props;
	if (!week) {
		return null;
	}
	if (!department) {
		return null;
	}
	if (!user) {
		return null;
	}
	if (!type) {
		return null;
	}
	return { week, department, user, type };
});
const assessInfo = ref<AssessInfo>();
const loading = ref<boolean>(false);
const visible = ref<boolean>(false);
const smallMeta = useMetaQuery();
const lockStatus = ref<boolean>(false);

const detailPermission = Permission.use(() => {
	const department = props.type === 'project' ? 'Project' : 'Company';
	return `Guigu Hr ${department} Week Assess Detail`;
});

const assessPermission = Permission.use(() => {
	const department = props.type === 'project' ? 'Project' : 'Company';
	return `Guigu Hr ${department} Week Assess`;
});
const assessUserPermission = computed(() =>
	Permission.ifOwner(assessPermission.value, isCurrentUser.value),
);

async function getLockStatus() {
	const { week, department, type } = props;
	if (!week || !type || !department) {
		return;
	}
	const res = await frappe.call<{ message: boolean }>({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_project_assessment_list.guigu_hr_project_assessment_list.get_lock_status',
		args: { month: week, type, department },
	});
	lockStatus.value = Boolean(res?.message);
}
async function getAssess() {
	const args = commonArgs.value;
	if (!args) {
		return;
	}
	loading.value = true;
	const res = await frappe.call<{ message: AssessInfo }>({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_week_assessment_detail.guigu_hr_week_assessment_detail.get_assessment',
		args: { ...args },
	});
	assessInfo.value = res?.message;
	loading.value = false;
}

async function getAssessListUser() {
	if (
		!props.week ||
		!props.type ||
		!props.department ||
		!props.organization
	) {
		return;
	}
	loading.value = true;
	const res = await frappe.call<{ message: Assess[] }>({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_project_week_assessment_list.guigu_hr_project_week_assessment_list.get_assessments_user',
		args: {
			week: props.week,
			type: props.type,
			department: props.department,
			organization: props.organization,
		},
	});
	loading.value = false;
	members.value =
		(res?.message || []).sort((pre, next) =>
			pre.full_name > next.full_name ? 1 : -1,
		) || [];
}
watch(
	[
		() => props.week,
		() => props.type,
		() => props.department,
		() => props.organization,
	],
	() => {
		getAssessListUser();
	},
	{ immediate: true },
);

watch(
	[
		() => props.week,
		() => props.type,
		() => props.department,
		() => props.user,
		() => assessUserPermission.value?.creatable,
	],
	() => {
		getAssess();
		getLockStatus();
	},
	{ immediate: true },
);

function onRemove(data: ExtraScore | AssessDetail) {
	ElMessageBox.confirm(
		__('Are you sure you want to delete this assessment record?'),
		__('please confirm'),
		{
			confirmButtonText: __('Sure'),
			cancelButtonText: __('Cancel'),
			type: 'warning',
		},
	)
		.then(async () => {
			loading.value = true;
			const doctype =
				props.type === 'project'
					? 'Guigu Hr Project Week Assess Detail'
					: 'Guigu Hr Company Week Assess Detail';
			await frappe.call({
				method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_week_assessment_detail.guigu_hr_week_assessment_detail.delete_score',
				args: {
					doctype,
					name: data.name,
				},
			});
			ElMessage({ type: 'success', message: __('Delete Successful') });
			getAssess();
		})
		.catch(() => {
			ElMessage({ type: 'info', message: __('Cancel Delete') });
		});
}

function createTempTask() {
	visible.value = true;
}
async function confirmCreateTemp(data: {
	subject: string;
	standard: string;
	total_score: number;
	remark: string;
	self_assessment: number;
	leader_assessment: number;
	percent_complete: number;
}) {
	const args = commonArgs.value;
	if (!args) {
		return;
	}
	visible.value = false;
	await frappe.call({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_week_assessment_detail.guigu_hr_week_assessment_detail.create_temp_task',
		args: { data, ...args },
	});
	ElMessage({
		type: 'success',
		message: __('Saved Successfully'),
	});
	getAssess();
}
</script>

<style lang="less" scoped>
.title {
	font-size: 1.25rem;
	font-weight: bold;
	margin-right: 15px;
	margin-bottom: 0;
}
.el-alert {
	margin-top: 5px;
	margin-bottom: 10px;
	height: 32px;
}
</style>
