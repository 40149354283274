import moment from 'moment';

import type {ResultProcess, ResultWorkList, TableData, InputData} from './type';

export const processLevel: string[] = [
	'',
	'Process Level 1',
	'Process Level 2',
	'Process Level 3',
	'Process Level 4',
	'Process Level 5',
	'Process Level 6',
	'Process Level 7',
	'Process Level 8',
	'Process Level 9',
	'Process Level 10',
];

// 页面按钮权限判断
export async function getPermission() {
	if (frappe.session.user === 'Administrator' || frappe.user.has_role('System Manager')) {
		return true;
	}
	const res = await frappe.db.get_list('Dw Process Manager', {
		fields: ['user'],
		limit: 0,
	});
	const appliers = res.map(item => item.user);
	return appliers.includes(frappe.session.user);
}
// 处理数据
export function transformData(process:ResultProcess[], workList:ResultWorkList[]) :TableData[] {
	const processItems = process.map(item => ({
		name: item.name,
		title: item.title,
		level: item.level,
		parent: item.parent_dw_process,
		modified: '',
		is_process: 1 as const,
		code: item.full_process_code,
	}));
	const workListItems = workList.map(item => ({
		name: item.name,
		title: item.title,
		parent: item.work_process,
		disable: item.disable,
		is_process: 0 as const,
		is_core: item.is_core_work_list,
		code: item.full_code,
		modified: item.modified,
		organization: item.specific_organization,
	}));
	return [...processItems, ...workListItems];
}

// 数据更新后，设置数据格式
export function setData(data: InputData, path: string[]): TableData {
	const newData = {
		name: data.name,
		title: data.work_list_title,
		parent: data.work_process,
		modified: moment(data.modified).format('YYYY-MM-DD'),
		disable: data.disable,
		is_process: 0 as const,
		is_core: data.is_core_work_list,
		code: data.worklist_code,
		organization: data.specific_organization,
		path,
	};
	return newData;
}
