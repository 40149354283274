<template>
	<component v-if="comp" :is="comp"
		:class="{'indicator-pill':value&&showTag, 'indicator-pill-color':value&&showTag}"
		:style="style"
		:value="value"
		:data="data"
		:field="field"
		:meta="meta"
		:maxLine="maxLine"
		:enableLink="enableLink"
		:noData="noData"
		:inline="inline"
		:showTitle="showTitle"
		:format="format"
		@filter="setFilter" />
</template>
<script lang="ts" setup>
import { computed, type CSSProperties, type StyleValue } from 'vue';


import Default from './Default.vue';
import Check from './Check.vue';
import Color from './Color.vue';
import Currency from './Currency.vue';
import Date from './Date.vue';
import Datetime from './Datetime.vue';
import Duration from './Duration.vue';
import DynamicLink from './DynamicLink.vue';
import File from './File.vue';
import Float from './Float.vue';
import GuiguDate from './GuiguDate.vue';
import HTML from './HTML.vue';
import Icon from './Icon.vue';
import Image from './Image.vue';
import Int from './Int.vue';
import LastComment from './LastComment.vue';
import Link from './Link.vue';
import Percent from './Percent.vue';
import Rating from './Rating.vue';
import Select from './Select.vue';
import Signature from './Signature.vue';
import TianjyEnumeration from './TianjyEnumeration.vue';
import Status from './Status.vue';
import Tag from './Tag.vue';
import Text from './Text.vue';
import TableMultiSelect from './TableMultiSelect.vue';
const props = defineProps<{
	value: any;
	data: Record<string, any>;
	field: GlobalView.FieldRenderOptions;
	meta?: locals.DocType;
	maxLine?: number;
	enableLink?: boolean;
	noData?: boolean;
	inline?: boolean;
	format?: string;
	showTitle?: boolean;
	showTag?:boolean
}>();
const emit = defineEmits<{
	(event: 'filter', field: string, operator: string, value: any): void;
}>();
function setFilter(field: string, operator: string, value: any) {
	emit('filter', field, operator, value);
}

const comp = computed(() => {
	const { field } = props;
	switch (field.fieldtype) {
		case '__Status__': return Status;
		case '__Id__': return Default;
		case '__Tag__': return Tag;
		case '__LastComment__': return LastComment;
		case 'Check': return Check;
		case 'Color': return Color;
		case 'Currency': return Currency;
		case 'Date': return Date;
		case 'Datetime': return Datetime;
		case 'Duration': return Duration;
		case 'Dynamic Link': return DynamicLink;
		case 'Attach': return File;
		case 'Attach Image': return File;
		case 'Float': return Float;
		case 'Guigu Date': return GuiguDate;
		case 'Tree Select':
		case 'Link': return Link;
		case 'Tianjy Related Link': return Link;
		case 'Icon': return Icon;
		case 'Image': return Image;
		case 'Int': return Int;
		case 'Percent': return Percent;
		case 'Rating': return Rating;
		case 'Select': return Select;
		case 'Signature': return Signature;
		case 'Small Text':
		case 'Markdown Editor':
		case 'Text': return Text;
		case 'Text Editor':
		case 'HTML':
		case 'HTML Editor': return HTML;
		case 'Code': return;
		case 'Tianjy Tree Table MultiSelect':
		case 'Table MultiSelect': return TableMultiSelect;
		case 'Tianjy Enumeration': return TianjyEnumeration;
	}
	return Default;

});
const style = computed<CSSProperties>(()=>{
	if (!props.meta){
		return {};
	}
	let cusStyle = {};
	if (['Link', 'Tree Select', 'Tianjy Related Link'].includes(props.field.fieldtype)){
		const styleFieldname = `${props.field.fieldname || ''}.style`;
		cusStyle = props.data[styleFieldname]||{};
	}
	const setting = frappe.guigu.GlobalView.settings[props.meta.name];
	if (!setting){
		return cusStyle;
	}
	const styleSetting = setting?.style?.[props.field.fieldname];
	if (!styleSetting){
		return cusStyle;
	}
	if (typeof styleSetting === 'function'){
		cusStyle = styleSetting(props.data, props.meta)||{};
	} else {
		cusStyle = styleSetting||{};
	}
	return cusStyle;
});

const color = computed(()=>style.value.color||'var(--text-on-gray)');
const background = computed(()=>style.value.backgroundColor||style.value.background||'var(--bg-gray)');

</script>

<style scoped lang="less">
.indicator-pill.indicator-pill-color::before{
	background: v-bind(color)!important;
}
.indicator-pill.indicator-pill-color{
	background: v-bind(background)!important;
    color: v-bind(color)!important;
}
</style>
