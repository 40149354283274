<template>
	<div class="table-container">
		<div class="header">
			<span>{{ tt(config.title || config.meta?.name||'') }}</span>
			<ElSpace>
				<Expand :expanders="expanders" @fold="fold" @expand="expand"></Expand>
				<el-button plain v-if="permissions.createPermission" type="primary" @click="add"> {{ tt('+Add') }} </el-button>
			</ElSpace>
		</div>
		<AgGridVue
			class="ag-theme-guigu"
			@gridReady="onReady"
			groupDisplayType="singleColumn"
			:getRowId="getRowId"
			:localeText="zhCN"
			:rowData="data"
			rowGroupPanelShow="always"
			:columnDefs="columns"
			domLayout="autoHeight"
			:suppressDragLeaveHidesColumns="smallMeta"
			:autoGroupColumnDef="autoGroupColumnDef"
			@columnRowGroupChanged="columnRowGroupChanged"
			:defaultExcelExportParams="excelExportParams" />
	</div>
</template>

<script setup lang="ts">
import {shallowRef, computed, watch} from 'vue';

import {AgGridVue} from 'ag-grid-vue3';
import type {
	GridApi,
	GetRowIdParams,
	ColumnRowGroupChangedEvent,
	GridReadyEvent,
	ColumnApi,
	CellClassParams,
} from 'ag-grid-community';
import moment from 'moment';

import {Plus} from '@element-plus/icons-vue';
import {ElMessage, ElMessageBox} from 'element-plus';

import getColumns from '../../../../../../../../guigu/guigu/public/js/DefaultMain/getColumns';
import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';
import type {Column, DoctypeConfig, ExpanderItem} from '../type';
import Title from '../../../../../../../../guigu/guigu/public/js/DefaultMain/Title/index.vue';
import defaultExcelExportParams from '../../../../../../../../guigu/guigu/public/js/DefaultMain/defaultExcelExportParams';

import Action from './Action.vue';
import Expand from './Expand.vue';
const tt = __;
interface Props {
	data: Record<string, any>[];
	config: DoctypeConfig;
	smallMeta: boolean;
	user: string;
	date: string;
	useDetail: 0 | 1;
}
const props = defineProps<Props>();
interface Emit {
	(e: 'showDetail', name: string, doctype: string): void;
}
const emit = defineEmits<Emit>();
const gridApi = shallowRef<GridApi>();
const gridColumnApi = shallowRef<ColumnApi>();
const groupColumns = shallowRef<Column[]>([]);

const expanders = computed(() => groupColumns.value.map((col, index) => ({
	value() {
		const api = gridApi.value;
		if (!api) {
			return [];
		}
		const ids: string[] = [];
		api.forEachNode(node => {
			if (node.level > index) {
				return;
			}
			ids.push(node.id as string);
		});
		return ids;
	},
	key: col.getColDef().field,
	title: col.getColDef()?.headerName || __('Level {}', [index + 1]),

})));
function expand(expanded: ExpanderItem) {
	const api = gridApi.value;
	if (!api) {
		return;
	}
	api.collapseAll();
	for (const id of expanded.value()) {
		const node = api.getRowNode(id);
		if (!node) {
			continue;
		}
		api.setRowNodeExpanded(node, true);
	}
}
function fold() {
	const api = gridApi.value;
	if (!api) {
		return;
	}
	api.collapseAll();
}

watch(gridColumnApi, columnApi => {
	if (!columnApi) {
		return;
	}
	groupColumns.value = (columnApi.getColumns() || []).filter(col => col.getColDef()?.rowGroup);
});
const permissions = computed(() => {
	const deletePermission = frappe.perm.has_perm(props.config.document, 0, 'delete');
	const createPermission = frappe.perm.has_perm(props.config.document, 0, 'create');
	const writePermission = frappe.perm.has_perm(props.config.document, 0, 'write');
	return {deletePermission, createPermission, writePermission};
});

async function onReady({api, columnApi}: GridReadyEvent) {
	gridApi.value = api;
	gridColumnApi.value = columnApi;
}
function getRowId({data}: GetRowIdParams<locals.Doctype>) {
	return data.name;
}

const autoGroupColumnDef = {pinned: 'left', resizable: true};
function add() {
	const newName = frappe.model.make_new_doc_and_get_name(props.config.document);
	const newData: Record<string, any> = locals[props.config.document][newName] as Record<string, any>;
	const field = props.config.meta?.fields.find(each => each.fieldname === props.config.user_field);
	if (props.user !== 'all') {
		if (field?.fieldtype === 'Table MultiSelect' && field.options) {
			const fieldMeta = frappe.get_meta(field.options);
			const tableUserField = fieldMeta?.fields.find(field => (field.fieldtype === 'Link' || field.fieldtype === 'Tree Select') && field.options === 'User');
			const newUserName = frappe.model.make_new_doc_and_get_name(field.options);
			const newUserData: Record<string, any> = locals[field.options][newUserName] as Record<string, any>;
			if (tableUserField) {
				newUserData[tableUserField.fieldname] = props.user;
				newData[props.config.user_field] = [newUserData];
			}
		} else {
			newData[props.config.user_field] = props.user;
		}
	}

	const startDate = moment(props.date).startOf('month').format('YYYY-MM-DD');
	const endDate = moment(props.date).endOf('month').format('YYYY-MM-DD');
	newData[props.config.end_date_field] = endDate;
	newData[props.config.start_date_field] = startDate;
	frappe.set_route(['Form', props.config.document, newData.name]);
}
function onEdit(data: any) {
	if (props.useDetail) {
		emit('showDetail', data.name, props.config.document);
	} else {
		frappe.set_route('Form', props.config.document, data.name);
	}
}
async function onRemove(data: any) {
	await frappe.db.delete_doc(props.config.document, data.name);
	ElMessage({type: 'success', message: __('Delete Successful')});
}
const actionColumn = {
	enableRowGroup: false,
	pinned: 'right',
	headerName: __('Actions'),
	width: 100,
	field: 'actions',
	menuTabs: [],
	cellRendererParams: {
		onEdit: permissions.value.writePermission ? onEdit : undefined,
		onRemove: permissions.value.deletePermission ? onRemove : undefined,
	},
	cellRenderer: Action,
};
const titleDocField = computed(() => {
	const titleFieldName = props.config.meta?.title_field;
	const titleField = props.config.meta?.fields.find(field => field.fieldname === titleFieldName) || null;
	return (titleFieldName && titleField) || null;
});
const dataColumns = computed(() => {
	const fields = props.config.columns
		.map((item: Column) => {
			const metaFields = props.config.meta?.fields || [];
			let field: Partial<locals.DocField>|undefined = metaFields.find(field => field.fieldname === item.field);
			if (field) {
				field.label = item.title || field.label;
			}

			if (item.field === '__LastComment__') {
				field = {
					label: 'Last Comment',
					fieldname: '_comments',
					fieldtype: '__LastComment__',
				};
			}
			if (item.field === '__Status__') {
				field = {
					label: 'Status',
					fieldname: '__Status__',
					fieldtype: '__Status__',
				};
			}
			if (item.field === titleDocField.value?.fieldname) {
				return;
			}
			if (!field) {
				return;
			}
			const groupable = !['name', '__LastComment__'].includes(item.field);
			const format = field.fieldtype === 'Date' ? 'YYYY-MM-DD' : '';
			return {
				field,
				groupable,
				group: item.field === props.config.group_by,
				width: item.width,
				maxWidth: item.max_width,
				minWidth: item.min_width,
				enableLink: false,
				format,
				show_tag: item.show_tag,
			};
		})
		.filter(Boolean);
	return getColumns(
		props.config.meta!,
		() => {},
		() => props.data,
		fields,
		props.config.fixed_columns,
		false,
	);
});


function preventClick(event: Event, data: any) {
	if (props.useDetail) {
		event.preventDefault();
		emit('showDetail', data.name, props.config.document);
	}
}
const state = {
	get meta() {
		return props.config.meta;
	},
	get rowAction() {
		return false;
	},
	get titleField() {
		return titleDocField.value;
	},
	get linkField() {
		return false;
	},
};
const titleDataColumn = computed(() => {
	const titleField = titleDocField.value;
	const titleConfig = props.config.columns.filter((item: Column) => item.field === titleField?.fieldname);
	return {
		colId: `id`,
		resizable: true,
		enableRowGroup: false,
		pinned: 'left',
		headerName: titleField ? __(titleField.label) : 'ID',
		width: titleConfig.length > 0 ? titleConfig[0].width : 200,
		field: titleField?.fieldname || 'name',
		cellRendererParams: {
			preventClick,
			del: onRemove,
			getState: () => state,
		},
		cellRenderer: Title,
	};
});

const columns = computed(() => {
	let list = [];
	const NoColum = {
		headerName: __('No.'), width: 50, suppressSizeToFit: true, suppressSorting: true, suppressMenu: true,
		pinned: 'left',
		cellRenderer: function (params: CellClassParams) {
			if (params.node.group) {
				return '';
			}
			return parseInt(String(params.node.childIndex || 0), 10) + 1;
		},
	};
	const hasPermission = permissions.value.writePermission || permissions.value.deletePermission;
	if (hasPermission && !props.config.hide_actions) {
		list = [titleDataColumn.value, ...dataColumns.value, actionColumn];
	} else {
		list = [titleDataColumn.value, ...dataColumns.value];
	}
	if (props.config.show_no_column === 1) {
		list = [NoColum, ...list];
	}
	return list;
});
function columnRowGroupChanged(event: ColumnRowGroupChangedEvent<any>) {
	const {rowGroupColumns} = event.columnApi.columnModel;
	groupColumns.value = rowGroupColumns ? [...rowGroupColumns] : [];
}

const excelExportParams = computed(() => ({
	...defaultExcelExportParams,
	onlySelected: false,
}));
</script>

<style lang="less" scoped>
  .table-container {
    margin-bottom: 24px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
  }
</style>
