import { ColDef } from 'ag-grid-community';

import Comp from './Comp.vue';

function getAggFunc(field: GlobalView.FieldRenderOptions) {
	switch (field.fieldtype) {
		case 'Int':
		case 'Float':
		case 'Currency':
			return 'sum';
		case 'Rating':
			return 'avg';
	}
	return null;
}

export default function getColumns(
	meta: locals.DocType,
	getRows: () => any[],
	fields: GlobalView.DisplayField[],
	fixedFieldColumns: number,
	isTree?: boolean
) {
	const fields_order: ColDef[] = fields.map(({
		fieldOptions, groupable, width, maxWidth, minWidth, enableLink,
	}) => ({
		headerName: __(fieldOptions.label || 'Field'),
		field: fieldOptions.fieldname,
		resizable: true,
		enableRowGroup: isTree ? false : groupable,
		aggFunc: isTree ? null : getAggFunc(fieldOptions),
		cellRenderer: Comp,
		width, maxWidth, minWidth,
		cellRendererParams: {
			docField: fieldOptions,
			meta, getRows, enableLink,
		},
	}));
	for (let i = 0; i < fixedFieldColumns; i++) {
		const column = fields_order[i];
		if (!column) { break; }
		column.pinned = 'left';
	}
	return fields_order;
}
