import cloneDeep from 'lodash/cloneDeep';

import type {HotData, Column, Merge, FormatColumns} from '../type';
import {findLeafNodes, getWidths, formatColumns, tree2list} from '../../../../excel/helper';

import {getRenderer, getCustomRenderer} from './customStylesRenderer';

const Align = {
	lft: 'htLeft',
	left: 'htLeft',
	rgt: 'htRight',
	right: 'htRight',
	center: 'htCenter',
	top: 'htTop',
	middle: 'htMiddle',
	bottom: 'htBottom',
};

/**
 * 获取handsontable的列
 * @param project
 * @param month
 * @param isTotal
 * @return columns
 */
export function getColumn(title: string, isShowConsume: boolean, isTotal: boolean, isShowDetail:boolean) {
	const column:Column[] = [
		{
			headerName: __('{} Main Material Verification And Disposal Statistics', [title]),
			field: 'title',
			editable: false,
			bold: true,
			align: ['center', 'middle'],
			children: [
				{
					headerName: __('Main Classification'),
					field: 'main_classification',
					editable: false,
					align: ['center', 'middle'],
					width: 100,
				},
				{
					headerName: __('Material Name'),
					field: 'material_name',
					editable: false,
					align: ['center', 'middle'],
					width: 150,
				},
				{
					headerName: __('Specification'),
					field: 'material_specification',
					editable: false,
					align: ['center', 'middle'],
					width: 150,
				},
				{
					headerName: __('Measurement Unit'),
					field: 'unit',
					editable: false,
					align: ['center', 'middle'],
					width: 100,
				},
				{
					headerName: __('Opening Inventory'),
					field: 'opening_inventory',
					editable: false,
					align: ['right', 'middle'],
					width: 120,
				},
				{
					headerName: __('Accumulated Inventory'),
					field: 'inbound',
					editable: false,
					align: ['center', 'middle'],
					children: [
						{
							headerName: 'A',
							field: 'inbound_quantity',
							editable: false,
							align: ['right', 'middle'],
							width: 120,
						},
					],
				},
				{
					headerName: __('Accumulated Inventory Out'),
					field: 'outbound',
					editable: false,
					align: ['center', 'middle'],
					children: [
						{
							headerName: 'B',
							field: 'outbound_quantity',
							editable: false,
							align: ['right', 'middle'],
							width: 120,
						},
					],
				},
				{
					headerName: __('End Period Inventory'),
					field: 'end_period_inventory',
					editable: false,
					align: ['center', 'middle'],
					children: [
						{
							headerName: 'C',
							field: 'ending_inventory',
							editable: false,
							align: ['right', 'middle'],
							width: 120,
						},
					],
				},
				{
					headerName: __('Consumed Amount'),
					field: 'consumed_amount',
					editable: false,
					align: ['center', 'middle'],
					children: [
						{
							headerName: 'D',
							field: 'consumption',
							editable: false,
							align: ['right', 'middle'],
							width: 120,
						},
					],
				},
				{
					headerName: __('Theory Consumed Amount'),
					field: 'theory_consumed_amount',
					editable: false,
					align: ['center', 'middle'],
					children: [
						{
							headerName: 'E',
							field: 'theory_consumption',
							editable: false,
							align: ['right', 'middle'],
							width: 120,
						},
					],
				},
				{
					headerName: __('Excess Consumption'),
					field: 'excess_consumption',
					editable: false,
					align: ['center', 'middle'],
					children: [
						{
							headerName: 'F=D-E',
							field: 'excess_consumption',
							editable: false,
							align: ['right', 'middle'],
							width: 120,
						},
					],
				},
				{
					headerName: __('Excess Consumption Rate'),
					field: 'excess_consumption_rate',
					editable: false,
					align: ['center', 'middle'],
					children: [
						{
							headerName: 'F/E',
							field: 'excess_consumption_rate',
							editable: false,
							align: ['right', 'middle'],
							width: 120,
						},
					],
				},
			],
		}];
	if (isShowConsume) {
		if (column[0].children) {
			column[0].children.splice(4, 4);
		}
	}
	if (!isShowDetail) {
		if (column[0].children) {
			column[0].children.splice(2, 1);
		}
	}
	if (!isTotal) {
		if (column[0].children) {
			column[0].children.push({
				headerName: __('Remark'),
				field: 'remark',
				editable: false,
				width: 150,
				align: ['center', 'middle'],
			});
		}
	}
	return column;
}

/**
 * 获取合并单元格数据
 * @param data
 * @param isTotal
 * @returns mergeCells
 */
export function getMergeCells(data: HotData[], isShowConsume:boolean, isTotal:boolean, isShowDetail:boolean) {
	const mergeCells:Merge[] = [
		{row: 1, col: 0, rowspan: 2, colspan: 1},
		{row: 1, col: 1, rowspan: 2, colspan: 1},
		{row: 1, col: 2, rowspan: 2, colspan: 1},
		{row: 1, col: 3, rowspan: 2, colspan: 1},
	];
	// 显示全部时，增加‘期初库存’列的合并
	if (!isShowConsume) {
		mergeCells.push({row: 1, col: 4, rowspan: 2, colspan: 1});
	}
	// 显示备注时，增加‘备注’列的合并
	if (!isTotal) {
		mergeCells.push({row: 1, col: isShowConsume ? 8 : 12, rowspan: 2, colspan: 1});
	}
	// 标题行的合并
	if (isShowConsume && isTotal) {
		// 情况1: ‘开累’ 和 ‘只显示消耗量’ 都勾选
		mergeCells.push({row: 0, col: 0, rowspan: 1, colspan: isShowDetail ? 8 : 7});
	} else if (!isTotal && isShowConsume) {
		// 情况2: ‘开累’ 未勾选，‘只显示消耗量’ 勾选
		mergeCells.push({row: 0, col: 0, rowspan: 1, colspan: isShowDetail ? 9 : 8});
	} else if (isTotal && !isShowConsume) {
		// 情况3: ‘开累’ 勾选，‘只显示消耗量’ 未勾选
		mergeCells.push({row: 0, col: 0, rowspan: 1, colspan: isShowDetail ? 12 : 11});
	} else if (!isTotal && !isShowConsume) {
		// 情况4: ‘开累’ 未勾选，‘只显示消耗量’ 未勾选
		mergeCells.push({row: 0, col: 0, rowspan: 1, colspan: isShowDetail ? 13 : 12});
	}
	// 合并大类相同的行
	let mergeIndex = 3;
	let startRow = 3;
	for (const [index, item] of data.entries()) {
		// 如果当前项的 main_classification 与下一项不同，或者这是最后一项
		if (item.main_classification !== (data[index + 1]?.main_classification) || !data[index + 1]) {
			let endRow = index + 3;
			if (!data[index + 1]) {
				endRow = mergeIndex;
			}
			if (startRow !== endRow) {
				mergeCells.push({row: startRow, col: 0, rowspan: endRow - startRow + 1, colspan: 1});
			}
			startRow = mergeIndex + 1;
		}
		mergeIndex += 1;
	}
	// 合计行需要合并的单元格
	for (const [index, item] of data.entries()) {
		if (item.is_total) {
			mergeCells.push({row: index + 3, col: 1, rowspan: 1, colspan: 2});
		}
	}
	return mergeCells;
}

function getCells(originCols:FormatColumns[], readOnly:boolean) {
	const list:FormatColumns[] = [];
	tree2list(originCols, list);
	return function(row:number, col:number) {
		const cellProperties:Record<string, any> = {};
		let cellDefine = list.find(item=>item.row === row && item.col === col);
		cellDefine = cellDefine || list.find(item=> item.col === col && !item.children?.length);
		if (!cellDefine) {
			return cellProperties;
		}
		// @ts-ignore
		const rowData = this.instance.getSettings().data[row];
		cellProperties.color = 'black';
		// 设置readonly
		if (readOnly) {
			cellProperties.readOnly = true;
		}
		// 设置样式和对齐;
		if (rowData?.__type__ === 'headerData') {
			cellProperties.bold = 'bold';
			cellProperties.fontSize = '14px';
			cellProperties.bgColor = '#999999';
			cellProperties.color = '#ffffff';
			cellProperties.className = `${cellProperties.className || ''} htCenter htMiddle`;
		} else if (cellDefine.align) {
			const aligns = typeof cellDefine.align === 'function' ? cellDefine.align(row, rowData, text) : cellDefine.align;
			const className = aligns.map(item => Align[item]).join(' ');
			cellProperties.className = `${cellProperties.className || ''} ${className}`;
		}
		// 合计行设置样式
		if (rowData.is_total) {
			cellProperties.bgColor = '#f5f7fa';
			cellProperties.bold = true;
		}
		return cellProperties;
	};
}

export function getHeader(root:Column[], readOnly:boolean) {
	if (root.length === 0) {
		return {columns: []};
	}
	const list = cloneDeep(root) as Required<FormatColumns>[];
	const originCols = list.flatMap(item=>findLeafNodes([item], item.field));
	const columns = getColumns(originCols);
	const widths = getWidths(originCols);
	formatColumns(list);
	const cells = getCells(list, readOnly);
	const headerData = [];
	let lastRow = 0;
	let rowHeader:Record<string, any> = {__type__: 'headerData'};
	while (list.length) {
		const top = list.shift();
		if (!top) {
			continue;
		}
		if (top.row !== lastRow) {
			headerData.push(rowHeader);
			rowHeader = {__type__: 'headerData'};
		}
		lastRow = top.row || 0;
		const leafs = findLeafNodes([top], top.field);

		for (const leaf of leafs) {
			rowHeader[leaf.field] = top.headerName;
		}
		if (top!.children) {
			for (const child of top!.children) {
				list.push(child as Required<FormatColumns>);
			}
		}
	}
	headerData.push(rowHeader);
	const dataSchema = Object.fromEntries((columns || []).map(item=>([item.data])));
	const hasSummaryRow = columns?.some(item=>item.summary);
	return {
		headerData,
		columns,
		cells,
		widths,
		dataSchema,
		hasSummaryRow,
	};
}
export function getColumns(cols:Column[]) {
	return cols.map(item=>{
		const formatCols:any = {
			data: item.field,
			summary: item.summary,
			rowSummary: item.rowSummary,
			source: item.source,
			type: item.type,
			precision: item.precision,
			renderer: item.renderer,
		};
		if (item.editable === false) {
			formatCols.editor = item.editable;
		}
		if (formatCols.renderer) {
			formatCols.renderer = getCustomRenderer(formatCols.renderer);
		} else {
			formatCols.renderer = getRenderer(item.type || 'text');
		}

		if (item.type === 'numeric') {
			const pattern = `0,0.${new Array(item.precision || 2).fill('0').join('')}`;
			formatCols.numericFormat = {pattern};
		}
		return formatCols;
	});
}
