import getRedirectUrl from './getRedirectUrl';
import showCode from './showCode';
import toLoginInWechat from './toLoginInWechat';

function showTip(text: string) {
	const root = document.body.appendChild(document.createElement('div'));
	root.style.marginBlock = '32px';
	root.style.marginInline = '8px';
	const t = root.appendChild(document.createElement('div'));
	t.style.fontSize = '24px';
	t.style.textAlign = 'center';
	t.appendChild(document.createTextNode(text));
}

async function sendCodeToTicket(
	code: string,
	ticket: string,
	appid: string,
	agentId?: string,
) {
	let serviceUrl = '/api/method/guigu_wechat.qr_login.set_ticket_code';
	serviceUrl += `?ticket=${encodeURIComponent(ticket)}`;
	serviceUrl += `&code=${encodeURIComponent(code)}`;
	serviceUrl += `&appid=${encodeURIComponent(appid)}`;
	if (agentId) {
		serviceUrl += `:${encodeURIComponent(agentId)}`;
	}
	const response = await fetch(serviceUrl);
	const { message: data }: { message: boolean } = await response.json();
	return data;
}
function back(code: string, key: string) {
	const redirect = sessionStorage.getItem(`__wechat/login/redirect__:${key}`);
	if (!redirect) {
		return false;
	}
	if (redirect.startsWith('ticket:')) {
		const [ticket, name] = redirect.slice(7).split('@');
		const [appid, agentid] = (name || '').split(':');
		sendCodeToTicket(code, ticket, appid, agentid).then(ok => {
			if (ok) {
				showTip(__('Logging in on the computer'));
			} else {
				showTip(__('QR Code Timeout'));
			}
		});
		return true;
	}
	location.href = `${redirect}&code=${encodeURIComponent(code)}`;
	return true;
}

(function () {
	const params: Record<string, any> = {};
	for (const it of location.search.substring(1).split('&')) {
		const index = it.indexOf('=');
		if (index <= 0) {
			continue;
		}
		params[decodeURIComponent(it.substring(0, index))] = decodeURIComponent(
			it.substring(index + 1),
		);
	}
	const {
		code,
		state = '',
		ticket,
		appid,
		agentid: agentId,
		redirect_uri,
		redirect = redirect_uri,
	} = params;

	if (ticket) {
		// ticket 表示扫描的电脑端二维码
		// TODO: 判断微信和企业微信
		if (navigator.userAgent.toLowerCase().includes('micromessenger')) {
			toLoginInWechat(
				`ticket:${ticket}@${appid || ''}${
					agentId ? `:${agentId}` : ''
				}`,
				appid,
				agentId,
			);
		} else {
			showTip(__('Please use WeChat/Enterprise WeChat to scan the code'));
		}
		return;
	}

	if (!redirect) {
		// 没有 ticket 和 redirect 表示扫码跳转返回
		if (!code) {
			return;
		}
		if (back(code, state)) {
			return;
		}
		return;
	}
	// 其他情况表示还未创建二维码
	// 获取跳转返回的二维码
	const url = getRedirectUrl(redirect, state, appid, agentId);
	if (!url) {
		showTip(__('Invalid Request'));
		return;
	}
	if (navigator.userAgent.toLowerCase().includes('micromessenger')) {
		toLoginInWechat(url, appid, agentId);
	} else {
		showCode(url, appid, agentId);
	}
})();
