<template>
	<span class="percent">{{ formatValue }}%</span>
</template>
<script lang="ts" setup>
import { computed } from 'vue';


const props = defineProps<{
	value: any;
	data: Record<string, any>;
	field: GlobalView.FieldRenderOptions;
	meta?: locals.DocType;
	maxLine?: number;
	enableLink?: boolean;
	noData?: boolean;
	inline?: boolean;
	format?: string;
	showTitle?: boolean;
}>();
const emit = defineEmits<{
	(event: 'filter', field: string, operator: string, value: any): void;
}>();
const field = computed(() => props.field);
const value = computed(() => props.value);
const formatValue = computed(()=>{
	const precision = props.field?.precision || cint(frappe.boot.sysdefaults.float_precision, null);
	return isNaN(parseFloat(props.value)) ? null : flt(props.value, precision);
});
const title = computed(() => {
	if (!props.showTitle) { return; }
	return `${__(field.value.label)}: ${value.value}`;
});
function setFilter() {
	emit('filter', props.field.fieldname, '=', props.value);
}
const size = computed(() => `${value.value}%`);
const tt = __;
</script>
<style scoped lang="less">
.percent {
	text-align: end;

	&::before {
		pointer-events: none;
		content: '';
		position: absolute;
		block-size: 100%;
		inline-size: v-bind(size);
		inset-block: 0;
		inset-inline-start: 0;
		background-color: #6666FF66;
	}
}
</style>
