import type { Extension, ExtensionOption } from '../types';

export type ExtensionList = [Extension, Record<string, any>][]
export default function getExtensionList(
	extensions?: ExtensionOption | ExtensionOption[]
): ExtensionList {
	if (!extensions) { return []; }
	if (!Array.isArray(extensions)) {
		return getExtensionList([extensions]);
	}
	const list: ExtensionList = [];
	for (const extension of extensions) {
		if (typeof extension === 'function') {
			list.push([extension, {}]);
			continue;
		}
		const { extension: e } = extension;
		if (typeof e !== 'function') { continue; }
		list.push([e, extension]);
	}
	return list;
}
