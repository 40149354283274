import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.value)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: _normalizeClass($setup.className),
        title: $setup.title,
        onClick: $setup.setFilter
      }, _toDisplayString($setup.tt($setup.value)), 11 /* TEXT, CLASS, PROPS */, _hoisted_1))
    : _createCommentVNode("v-if", true)
}