<template>
	<AgGridVue
		class="ag-theme-guigu work-assessment-sumary-container_table"
		:rowData="summaryTableData"
		:columnDefs="columnDefs"
		:localeText="zhCN"
		@grid-ready="onGridReady"
		animateRows="true"
		v-loading="loading"
		:getRowId="getRowId">
	</AgGridVue>
</template>

<script lang="ts">
import { ref, computed, defineComponent, PropType, getCurrentInstance, watch } from 'vue';
import { AgGridVue } from 'ag-grid-vue3';
import { GridApi } from 'ag-grid-community';

import { SummaryTableData } from '../../type';
import zhCN from '../../../../AgGrid/locals/zhCN';

import AgGridAttandanceIcon from './AgGridAttandanceIcon.vue';
import AgGridAttandanceCount from './AgGridAttandanceCount.vue';
import { getWorkAssessmentSummaryColumns } from './tableColumns';

export default defineComponent({
	components: {
		AgGridVue,
		AgGridAttandanceIcon,
		AgGridAttandanceCount,
	},
	props: {
		'project': String,
		'pageType': String,
		'countCategory': String,
		'monthDate': String,
		'yearDate': String,
		'summaryTableData': Object as PropType<SummaryTableData[]>,
		'attendanceTypes': Object as PropType<any[]>,
		'monthArr': Object as PropType<string[]>,
		'loading': Boolean,
	},
	emits: {
		onDetailGridReady(params: GridApi) { },
	},
	setup(props, context) {
		const { appContext } = getCurrentInstance() || {};
		const gridApi = ref(null);
		// const columnDefs = ref<Column[]>([]);
		const loading = ref<boolean>(false);
		const onGridReady = (params: any) => {
			gridApi.value = params.api;
			context.emit('onDetailGridReady', params.api);
		};
		const visible = ref(false);
		watch(() => props.loading, () => {
			loading.value = props.loading;
		});

		const columnDefs = computed(() => getWorkAssessmentSummaryColumns(
			props.pageType || 'month',
			props.pageType === 'month' ? props.monthDate || '' : props.yearDate || '',
			props.project || '',
			props.countCategory || '',
			props.attendanceTypes || [],
		));

		const defaultColDef = {
			sortable: true,
			filter: true,
			flex: 1,
			resizable: true,
		};

		function getRowId(params: any) {
			return `${params.data.record_owner}${params.data.record_owner_name}${params.data.organization_name}`;
		}
		return {
			loading,
			onGridReady,
			columnDefs,
			defaultColDef,
			getRowId,
			visible,
			confirm,
			zhCN,
		};
	},
});
</script>

<style lang="less" scoped>
.work-assessment-container {
	flex: 1;
}

.work-assessment-sumary-container_table {
	height: 100%;
}
</style>
