<template>
	<span :title="title">{{ value }}</span>
</template>
<script lang="ts" setup>
import { computed } from 'vue';


const props = defineProps<{
	value: any;
	data: Record<string, any>;
	field: GlobalView.FieldRenderOptions;
	meta?: locals.DocType;
	maxLine?: number;
	enableLink?: boolean;
	noData?: boolean;
	inline?: boolean;
	format?: string;
	showTitle?: boolean;
}>();
const emit = defineEmits<{
	(event: 'filter', field: string, operator: string, value: any): void;
}>();
const field = computed(() => props.field);
const value = computed(() => {
	function stripHtml(txt: string) {
		return String(txt || '').replace(/<[^>]*>/g, '');
	}
	const { value } = props;
	const { field } = props;


	const strip_html_required =
		field.fieldtype === 'Text Editor' ||
		(field.fetch_from && ['Text', 'Small Text'].includes(field.fieldtype));

	return strip_html_required ? stripHtml(value) : value;
});
const title = computed(() => {
	if (!props.showTitle) { return; }
	return `${__(field.value.label)}: ${value.value}`;
});

function setFilter() {
	emit('filter', props.field.fieldname, '=', props.value);

}
const tt = __;

</script>
