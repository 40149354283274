interface Node {
	name: string;
	parentId?: string;
	children: Node[];
}

function* expandList(list: Node[], level: number): Iterable<string> {
	for (const { name } of list) {
		yield name;
	}
	const childLevel = level - 1;
	if (childLevel <= 0) { return; }
	for (const { children } of list) {
		yield* expandList(children, childLevel);
	}
}

function getMaxLevel(values: Node[]) {
	const levels = new Map();
	function getLevel(v: Node): number {
		const { name } = v;
		if (levels.has(name)) {
			return levels.get(name) || 0;
		}
		const { children } = v;
		if (!children.length) {
			levels.set(name, 0);
			return 0;
		}
		const level = Math.max(...children.map(getLevel)) + 1;
		levels.set(name, level);
		return level;
	}
	let level = 0;
	for (const value of values) {
		level = Math.max(getLevel(value), level);
	}
	return level;
}
function toTree(data: Node[]) {
	const root: Node[] = [];
	const map = Object.fromEntries(data.map(v => [v.name, v]));
	for (const item of data) {
		const { parentId } = item;
		const parent = parentId && parentId in map && map[parentId];
		if (parent) {
			parent.children.push(item);
		} else {
			root.push(item);
		}
	}
	return root;

}

export default function getTreeExpander(data: any[], parentFiled: string = '') {
	const values = toTree(data.map(v => ({
		name: v.name, children: [], parentId: v[parentFiled],
	})));
	if (!values.length) { return []; }
	const level = getMaxLevel(values);
	return Array(level).fill(0).map((_, i) => ({
		value: () => expandList(values, i + 1),
		title: __('Level {}', [i + 1]),
	}));

}
