<template>
	<span :title="title">{{ value }}</span>
</template>
<script lang="ts" setup>
import { computed } from 'vue';


const props = defineProps<{
	value: any;
	data: Record<string, any>;
	field: GlobalView.FieldRenderOptions;
	meta?: locals.DocType;
	maxLine?: number;
	enableLink?: boolean;
	noData?: boolean;
	inline?: boolean;
	format?: string;
	showTitle?: boolean;
}>();
const emit = defineEmits<{
	(event: 'filter', field: string, operator: string, value: any): void;
}>();
const field = computed(() => props.field);
const value = computed(() => {
	const v = props.value;
	if (!v || typeof v !== 'string') { return v; }
	const type = field.value.options?.toLowerCase() || 'month';
	let lang = frappe.boot.user?.language || 'en';
	switch (type) {
		case 'week':
			return moment(v).format(`gggg-ww[${lang === 'zh' ? '周' : 'week'}]`);
		case 'month':
			return moment(v).format('YYYY-MM');
		case 'year':
			return moment(v).format('YYYY');
		case 'quarter':
			return `${moment(v).format('YYYY')}-${moment(v).quarter()}季度`;
		default: return '';
	}
});
const title = computed(() => {
	if (!props.showTitle) { return; }
	return `${__(field.value.label)}: ${value.value}`;
});
const tt = __;

</script>
