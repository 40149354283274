import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64f0110b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "operation-button" }
const _hoisted_2 = { style: {"display":"flex"} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["TaskTemplateTable"], {
      tableData: $setup.tableData,
      loading: $setup.loading,
      smallMeta: $setup.smallMeta,
      onGetSelectRows: $setup.getSelectRows
    }, null, 8 /* PROPS */, ["tableData", "loading", "smallMeta"]),
    _createElementVNode("div", _hoisted_1, [
      (!$setup.props.frm.is_new())
        ? (_openBlock(), _createBlock($setup["ElButton"], {
            key: 0,
            type: "primary",
            onClick: $setup.createTaskTemplate,
            size: "small"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('new')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      _createVNode($setup["ElPopover"], {
        placement: "bottom",
        visible: $setup.visible,
        showArrow: "",
        trigger: "click"
      }, {
        reference: _withCtx(() => [
          (!$setup.props.frm.is_new() && $setup.canBeDelete && $setup.selectData.length > 0)
            ? (_openBlock(), _createBlock($setup["ElButton"], {
                key: 0,
                type: "danger",
                size: "small",
                onClick: _cache[1] || (_cache[1] = $event => ($setup.visible = true))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.tt('Disable')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]),
        default: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString($setup.tt("Are you sure you want to disable it")) + "?", 1 /* TEXT */),
          _createElementVNode("div", _hoisted_2, [
            _createVNode($setup["ElButton"], {
              size: "small",
              onClick: _cache[0] || (_cache[0] = $event => ($setup.visible = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.tt("Cancel")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode($setup["ElButton"], {
              size: "small",
              type: "primary",
              onClick: $setup.disableTemplate
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.tt("Sure")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["visible"])
    ])
  ])), [
    [$setup["vLoading"], $setup.deleteLoading]
  ])
}