export default function print(doctype: string, docs: locals.Doctype<string>[]) {
	const get_letterhead_options = () => {
		const letterhead_options = [__('No Letterhead')];
		frappe.call({
			method: 'frappe.client.get_list',
			args: {
				doctype: 'Letter Head',
				fields: ['name', 'is_default'],
				limit_page_length: 0,
			},
			async: false,
			callback(r) {
				if (r.message) {
					r.message.forEach(letterhead => {
						letterhead_options.push(letterhead.name);
					});
				}
			},
		});
		return letterhead_options;
	};
	const print_settings = frappe.model.get_doc(':Print Settings', 'Print Settings');
	const allow_print_for_draft = cint(print_settings.allow_print_for_draft);
	const is_submittable = frappe.model.is_submittable(doctype);
	const allow_print_for_cancelled = cint(print_settings.allow_print_for_cancelled);

	const valid_docs = docs
		.filter(doc => (
			!is_submittable ||
			doc.docstatus === 1 ||
			(allow_print_for_cancelled && doc.docstatus == 2) ||
			(allow_print_for_draft && doc.docstatus == 0) ||
			frappe.user.has_role('Administrator')
		))
		.map(doc => doc.name);

	const invalid_docs = docs.filter(doc => !valid_docs.includes(doc.name));

	if (invalid_docs.length > 0) {
		frappe.msgprint(__('You selected Draft or Cancelled documents'));
		return;
	}

	if (valid_docs.length === 0) {
		frappe.msgprint(__('Select atleast 1 record for printing'));
		return;
	}

	const dialog = new frappe.ui.Dialog({
		title: __('Print Documents'),
		fields: [
			{
				fieldtype: 'Select',
				label: __('Letter Head'),
				fieldname: 'letter_sel',
				default: __('No Letterhead'),
				options: get_letterhead_options(),
			},
			{
				fieldtype: 'Select',
				label: __('Print Format'),
				fieldname: 'print_sel',
				options: frappe.meta.get_print_formats(doctype),
			},
			{
				fieldtype: 'Select',
				label: __('Page Size'),
				fieldname: 'page_size',
				options: frappe.meta.get_print_sizes(),
				default: print_settings.pdf_page_size,
			},
			{
				fieldtype: 'Float',
				label: __('Page Height (in mm)'),
				fieldname: 'page_height',
				depends_on: 'eval:doc.page_size == "Custom"',
				default: print_settings.pdf_page_height,
			},
			{
				fieldtype: 'Float',
				label: __('Page Width (in mm)'),
				fieldname: 'page_width',
				depends_on: 'eval:doc.page_size == "Custom"',
				default: print_settings.pdf_page_width,
			},
		],
	});

	dialog.set_primary_action(__('Print'), args => {
		if (!args) { return; }
		const { default_print_format } = frappe.get_meta(doctype);
		const with_letterhead = args.letter_sel == __('No Letterhead') ? 0 : 1;
		const print_format = args.print_sel ? args.print_sel : default_print_format;
		const json_string = JSON.stringify(valid_docs);
		const letterhead = args.letter_sel;

		let pdf_options;
		if (args.page_size === 'Custom') {
			if (args.page_height === 0 || args.page_width === 0) {
				frappe.throw(__('Page height and width cannot be zero'));
			}
			pdf_options = JSON.stringify({
				'page-height': args.page_height,
				'page-width': args.page_width,
			});
		} else {
			pdf_options = JSON.stringify({ 'page-size': args.page_size });
		}

		const w = window.open(
			`/api/method/frappe.utils.print_format.download_multi_pdf?` +
			`doctype=${encodeURIComponent(doctype)
			}&name=${encodeURIComponent(json_string)
			}&format=${encodeURIComponent(print_format)
			}&no_letterhead=${with_letterhead ? '0' : '1'
			}&letterhead=${encodeURIComponent(letterhead)
			}&options=${encodeURIComponent(pdf_options)}`
		);

		if (!w) {
			frappe.msgprint(__('Please enable pop-ups'));
			return;
		}
	});

	dialog.show();
}
