import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createBlock($setup["Timeline"], {
    ref: "timeLine",
    mode: $setup.smallMeta ? 'right' : 'left',
    onLoadMore: $setup.loadMore
  }, {
    default: _withCtx(({ mode }) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.diaryList, (diaryRecord) => {
        return (_openBlock(), _createBlock($setup["DiaryTimelineItem"], {
          mode: mode,
          project: $props.project,
          searchString: $props.searchString,
          dailyRecords: diaryRecord,
          showComment: $props.showComment
        }, null, 8 /* PROPS */, ["mode", "project", "searchString", "dailyRecords", "showComment"]))
      }), 256 /* UNKEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["mode"])), [
    [_directive_loading, $setup.loading]
  ])
}