<script lang="ts" setup>
import {ref, computed, watch} from 'vue';

const props = defineProps<{
	value?: any;
}>();
const emit = defineEmits<{
	(event: 'update', data: any, submittable?: boolean): void;
	(event: 'cancel' | 'submit'): void;
}>();


const value = ref(props.value);
watch(value, v => emit('update', v));
const root = ref<HTMLElement>();
watch(root, (el, old) => {
	if (el === old) {
		return;
	}
	if (old) {
		// TODO: 销毁
	}
	if (!el) {
		return;
	}
	ClassicEditor.create(el, {
		toolbar: {
			items: [
				'fullscreen',
				'heading',
				'|',
				'bold',
				'italic',
				'underline',
				'link',
				'bulletedList',
				'numberedList',
				'|',
				'outdent',
				'alignment',
				'indent',
				'|',
				'fontBackgroundColor',
				'fontColor',
				'fontSize',
				'fontFamily',
				'|',
				'imageInsert',
				'insertTable',
				'|',
				'blockQuote',
				'mediaEmbed',
				'undo',
				'redo',
				'todoList',
				'code',
				'codeBlock',
				'findAndReplace',
				'specialCharacters',
			],
		},
	}).then(editor => {
		editor.setData(value.value || '');
		editor.model.document.on('change:data', () => {
			value.value = editor.getData();
		});
		return editor;
	});
});
function cancel() {
	emit('cancel');
}
function submit() {
	emit('submit');
}
</script>

<template>
	<div class="root">
		<div ref="root"></div>
	</div>
</template>

<style scoped>
.root :deep(.ck.ck-editor__top .ck-sticky-panel .ck-toolbar) {
	border-bottom-width: 1px;
}

.root :deep(.ck.ck-editor__top){
	position: sticky;
	top: 63px;
	z-index: 4;
}
</style>
