<template>
	<div>
		<div class="button_container">
			<ElButton @click="expand">{{ tt('Expand') }}</ElButton>
			<ElButton @click="collapse">{{tt('Collapse')}}</ElButton>
		</div>
	</div>
	<AgGridVue
		class="ag-theme-guigu"
		:columnDefs="columnDefs"
		:rowData="progressAncestry"
		:defaultColDef="defaultColDef"
		rowSelection="multiple"
		animateRows="true"
		@grid-ready="onGridReady"
		:autoGroupColumnDef="autoGroupColumn"
		:getDataPath="getDataPath"
		:treeData="true"
		groupDefaultExpanded="-1"
		@rowDataUpdated="onRowDataUpdated"
		@selectionChanged="selectionChanged"
		style="height: 300px"
		rowMultiSelectWithClick
		groupSelectsChildren
		:suppressDragLeaveHidesColumns="smallMeta"
		:localeText="zhCN"></AgGridVue>
</template>

<script lang='ts'>
import { GridApi, RowDataUpdatedEvent, RowNode, SelectionChangedEvent, ValueFormatterParams } from 'ag-grid-community';
import { AgGridVue } from 'ag-grid-vue3';
import { ref, watch, defineEmits, toRefs, computed, defineComponent, PropType } from 'vue';

import FloatComponent from '../../../../AgGrid/components/Float.vue';
import { getTreeAncestry } from '../../../../utils';
import { ProgressItem } from '../type';
import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';
import { useMetaQuery } from '../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';

import { getColumns } from './helper';
const tt = __
export default defineComponent({
	components:{
		FloatComponent,
		AgGridVue,
	},
	props:{
		'selectedProgresses':Object as PropType<string[]>,
		'progressItems':Object as PropType<ProgressItem[]>,
	},
	setup(props, context) {
		const smallMeta = useMetaQuery();
		const gridApi = ref();
		const onGridReady = (params:any) => {
			gridApi.value = params.api;
		};

		const defaultColDef = {
			sortable: true,
			filter: true,
			flex: 1,
			editable:false,
			resizable: true,
		};

		const progressAncestry = computed(()=>{
			const formatProgress = props.progressItems?.map(item=>({
				...item,
				id:item.name,
				parent:item.parent_guigu_construction_progress_item,
			}));
			return getTreeAncestry(formatProgress||[]);
		});

		const autoGroupColumn = ref({
			sortable: false,
			headerName: tt('Construction Progress'),
			field:'progress_name',
			editable: false,
			filter: 'agTextColumnFilter',
			enableRowGroup: false,
			minWidth: 300,
			flex: 1,
			checkboxSelection: true,
			valueFormatter:(params:ValueFormatterParams)=>`${params.data.code} ${params.data.progress_name}`,
		});
		function getDataPath(data:any){
			return data.ancestry;
		}
		const columnDefs = getColumns();
		watch([()=>gridApi.value, props.progressItems, ()=>props.selectedProgresses], () => {
			if (!gridApi.value ) {
				return;
			}
			if ((props.progressItems || []).length === 0) {
				return;
			}
			setSelectedIds(gridApi.value, props.selectedProgresses||[]);
		});
		async function setSelectedIds(eventApi: GridApi, selectedIds: string[]) {
			const allIds: number[] = [];
			eventApi?.forEachNode((node: RowNode) => {
				allIds.push(node.data.id);
				if (selectedIds.includes(node.data.id)) {
					node.setSelected(true);
				} else {
					node.setSelected(false);
				}
			});
		}
		function onRowDataUpdated(event: RowDataUpdatedEvent) {
			setSelectedIds(event.api, props.selectedProgresses||[]);
		}
		function selectionChanged(event:SelectionChangedEvent){
			const rows = event.api.getSelectedRows();
			context.emit('selectChange', rows.map(item => item.id));
		}
		function expand(){
			gridApi.value?.expandAll();
		}
		function collapse(){
			gridApi.value?.collapseAll();
		}
		return {
			selectionChanged,
			columnDefs,
			getDataPath,
			autoGroupColumn,
			defaultColDef,
			progressAncestry,
			onGridReady,
			onRowDataUpdated,
			zhCN,
			collapse,
			expand,
			smallMeta,
		};
	},
});
</script>

<style lang='less' scoped>
.button_container {
	text-align: right;
	margin-bottom: 8px;
}
</style>
