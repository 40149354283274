import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52e7bd38"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "day-report-container" }
const _hoisted_3 = { class: "header" }
const _hoisted_4 = { class: "tools" }
const _hoisted_5 = { class: "main" }
const _hoisted_6 = { class: "day-report_board" }
const _hoisted_7 = { class: "day-report_nav" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["Header"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode($setup["Toolbar"], {
          onDateChange: $setup.dateChange,
          onProjectChange: $setup.projectChange
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode($setup["ReportList"], {
            date: $setup.date,
            project: $setup.project,
            nav: $setup.nav
          }, null, 8 /* PROPS */, ["date", "project", "nav"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode($setup["NavTree"], {
            date: $setup.date,
            nav: $setup.nav
          }, null, 8 /* PROPS */, ["date", "nav"])
        ])
      ])
    ])
  ]))
}