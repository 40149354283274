<template>
	<AgGridVue
		class="ag-theme-guigu work-assessment-sumary-container_table"
		:rowData="summaryTableData"
		:columnDefs="columnDefs"
		:localeText="zhCN"
		@grid-ready="onGridReady"
		animateRows="true"
		:getRowId="getRowId">
	</AgGridVue>
</template>

<script lang="ts">
import { ref, computed, defineComponent, PropType, getCurrentInstance, watch } from 'vue';
import { AgGridVue } from 'ag-grid-vue3';
import { CellValueChangedEvent, GridApi, Column } from 'ag-grid-community';

import { SummaryTableData } from '../../type';
import zhCN from '../../../../../../../../guigu_pm/guigu_pm/public/js/AgGrid/locals/zhCN';

import AgGridAttendanceIcon from './AgGridAttendanceIcon.vue';
import AgGridAttendanceCount from './AgGridAttendanceCount.vue';
import { getWorkAssessmentSummaryColumns } from './tableColumns';

export default defineComponent({
	components: {
		AgGridVue,
		AgGridAttendanceIcon,
		AgGridAttendanceCount,
	},
	props: {
		'company': String,
		'pageType': String,
		'monthDate': String,
		'yearDate': String,
		'summaryTableData': Object as PropType<SummaryTableData[]>,
		'monthArr': Object as PropType<string[]>,
	},
	emits: {
		onDetailGridReady(params: GridApi) { },
	},
	setup(props, context) {
		const { appContext } = getCurrentInstance() || {};
		const loading = ref<boolean>(false);
		const gridApi = ref(null);
		// const columnDefs = ref<Column[]>([]);
		const onGridReady = (params: any) => {
			gridApi.value = params.api;
			context.emit('onDetailGridReady', params.api);
		};
		const visible = ref(false);

		const columnDefs = computed(() => getWorkAssessmentSummaryColumns(
			props.pageType || 'month',
			props.pageType === 'month' ? props.monthDate || '' : props.yearDate || '',
			props.company || ''
		));

		const defaultColDef = {
			sortable: true,
			filter: true,
			flex: 1,
			resizable: true,
		};
		async function cellValueChanged(event: CellValueChangedEvent) {

		}

		function getRowId(params: any) {
			return params.data.id;
		}

		return {
			loading,
			cellValueChanged,
			onGridReady,
			columnDefs,
			defaultColDef,
			getRowId,
			visible,
			confirm,
			zhCN,
		};
	},
});
</script>

<style lang="less" scoped>
.work-assessment-container {
	flex: 1;
}

.work-assessment-sumary-container_table {
	height: 100%;
}
</style>
