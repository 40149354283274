import { createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createSlots as _createSlots, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-88491ed4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "body"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.doctype&&$setup.formVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($props.doctype)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.layoutComponent), { key: 0 }, _createSlots({ _: 2 /* DYNAMIC */ }, [
              ($props.detailName&&$setup.docMeta)
                ? {
                    name: "detail",
                    fn: _withCtx(() => [
                      ($setup.docMeta)
                        ? (_openBlock(), _createBlock($setup["FormDetail"], {
                            key: 0,
                            meta: $setup.docMeta,
                            isDialog: "",
                            name: $props.detailName,
                            visible: $setup.formVisible,
                            "onUpdate:visible": _cache[0] || (_cache[0] = $event => (($setup.formVisible) = $event)),
                            onOnChange: $setup.onChange
                          }, null, 8 /* PROPS */, ["meta", "name", "visible"]))
                        : _createCommentVNode("v-if", true)
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1024 /* DYNAMIC_SLOTS */))
          : _createCommentVNode("v-if", true)
      ]))
    : _createCommentVNode("v-if", true)
}