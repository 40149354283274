<template>
	<Main
		:type="type"
		v-model:week="week"
		v-model:department="department"
		v-model:user="user"
		v-model:organization="organization"
	/>
</template>

<script setup lang='ts'>
import { computed, getCurrentInstance, onMounted, onUnmounted, shallowRef } from 'vue';

import Main from './Main.vue';

const instance = getCurrentInstance();

const params = shallowRef(new URLSearchParams(window.location.search));

const inDetail = () => window.location.pathname.includes('guigu_hr_week_assessment_detail');

const update = () => {
	if (!inDetail()) {
		instance?.appContext.app.unmount();
		frappe.pages.guigu_hr_week_assessment_detail = undefined;
	}
	params.value = new URLSearchParams(window.location.search);
};
function switchParams(params: Record<string, string>) {
	if (!inDetail()) { return; }
	const search = new URLSearchParams(window.location.search);
	for (const [k, v] of Object.entries(params)) {
		search.set(k, v);
	}
	let s = search.toString();
	if (s[0] !== '?') { s = `?${s}`; }
	history.replaceState({}, '', s);
	update();
}
const type = computed(() => params.value.get('type') as ('project' | 'subCompany' | '') || '');
const week = computed({
	get: () => params.value.get('week') || '',
	set(value) { if (value) { switchParams({week: value}); } },
});
const department = computed({
	get: () => params.value.get('department') || '',
	set(value) { if (value) { switchParams({department: value}); } },
});
const user = computed({
	get: () => params.value.get('user') || '',
	set(value) { if (value) { switchParams({user: value}); } },
});
const organization = computed({
	get: () => params.value.get('organization') || '',
	set(value) { if (value) { switchParams({organization: value}); } },
});
onMounted(() => { window.addEventListener('popstate', update); });
onUnmounted(() => { window.removeEventListener('popstate', update); });
</script>
