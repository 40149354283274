<template>
	<ElSelect class="limit" v-model="value" size="small"
		v-if="pageSizes.length > 1"
		:disabled="loading">
		<ElOption
			v-for="item in pageSizes"
			:key="item"
			:label="`${item}`+ translate('Items/Page')"
			:value="item" />
		<ElOption v-if="maxLimit === Infinity" :key="0" :label="translate('Hide Pagination')"
			:value="Infinity" />
	</ElSelect>
</template>
<script lang="ts" setup>
import {computed} from 'vue';
import {ElSelect, ElOption} from 'element-plus';

defineOptions({name: 'Limit'});

const translate = __;

const props = defineProps<{
	loading: boolean;
	modelValue: number;
	maxLimit: number;
}>();
const allSizes = computed(() => {
	const v = props.maxLimit;
	const list: number[] = [];
	for (const k of [100, 500, 1000, 10000]) {
		if (k >= v) {
			break;
		}
		list.push(k);
	}
	if (v === Infinity) {
		return list;
	}
	list.push(v);
	return list;
});

const pageSizes = computed(() => {
	const v = props.modelValue;
	const sizes = allSizes.value;
	if (v <= 0 || sizes.includes(v) || v === Infinity) {
		return sizes;
	}
	const index = sizes.findIndex(k => k > v);
	if (index < 0) {
		return [...sizes, v];
	}
	const list = [...sizes];
	list.splice(index, 0, v);
	return list;
});
const emit = defineEmits<{
	(event: 'update:modelValue', limit: number): void;
}>();
const value = computed({
	get: () => props.modelValue,
	set: v => {
		emit('update:modelValue', v);
	},
});
</script>
<style lang="less" scoped>
.limit {
	margin-block: 4px;
	width: 100px;
}
</style>
