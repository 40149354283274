<template>
	<ElTree
		class="container"
		:data="showTree"
		nodeKey="name"
		:defaultExpandedKeys="defaultExpandedKeys"
		:expandOnClickNode="false"
		@node-click="click"
		:props="{ class: customNodeClass }" />
</template>

<script setup lang="ts">

import {computed, watch, shallowRef} from 'vue';

import {ElTree} from 'element-plus';


import loadTree, {Tree} from './loadTree';

const props = defineProps<{
	modelValue?: any;
	meta: locals.DocType;
	field: GlobalView.FilterField;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: any): void
}>();
function click({name}: Tree) {
	emit('update:modelValue', name);
}

const customNodeClass = (data: Tree) => {
	if (data.name === props.modelValue) {
		return 'guigu-is-selected-value';
	}
	return {};
};
const data = shallowRef<Tree[]>([]);

const showTree = computed(() => {
	const {allLabel, nullLabel} = props.field;
	if (!allLabel && !nullLabel) {
		return data.value;
	}
	const list = [...data.value];
	if (nullLabel) {
		list.unshift({name: '', label: nullLabel});
	}
	if (allLabel) {
		list.unshift({name: undefined, label: allLabel});
	}
	return list;
});

watch(() => props.field, async field => {
	if (!field) {
		if (props.modelValue !== undefined) {
			emit('update:modelValue', undefined);
		}
		return;
	}
	const {fieldtype} = field;
	if (fieldtype === 'Link' || fieldtype === 'Tree Select' || fieldtype === 'Tianjy Related Link') {
		const list = await loadTree(field.options, field.filters, field.orFilters, field.order);
		if (field === props.field) {
			data.value = list;
		}
	} else if (fieldtype === 'Select') {
		data.value = (field.options || '').split('\n').filter(Boolean).map(v => ({
			name: v, label: __(v),
		}));
	}
	// TODO: 拉取
}, {immediate: true});
const levelUnfolded = computed(() => props.field?.levelUnfolded || 0);
function* getKeys(level: number, tree?: Tree[]) {
	if (level <= 0 || !tree) {
		return;
	}
	for (const a of tree) {
		yield a.name;
		yield* getKeys(level - 1, a.children);
	}
}
const defaultExpandedKeys = computed(() => {
	const level = levelUnfolded.value;
	const tree = data.value;
	return [...getKeys(level, tree)];
});


</script>

<style scoped lang="less">
.container {
	background: none;

	:deep(.guigu-is-selected-value) {

		>.el-tree-node__content:first-child >.el-tree-node__label {
			color: #626aef;
		}

	}
}
</style>
