<template>
	<div class="table">
		<p>当前导入文件中有错误，请修改后重新提交</p>
		<div ref="hot"></div>
	</div>
	<!-- <div v-else>
		<ElEmpty :description="tt('No Records Created')" :imageSize="200" />
		</div> -->
</template>
<script setup lang="ts">
import {ref, shallowRef, onMounted, onUnmounted, watch} from 'vue';
import Handsontable from 'handsontable';
// import {ElEmpty} from 'element-plus';

import 'handsontable/dist/handsontable.full.css';


import {getData, getHeader, getMenus} from '../tableHelper';

const props = defineProps<{
	headerList: [];
	dataList: [];
	hotName:string
}>();
const tt = __;
const hot = ref<HTMLElement>();
const hatable = shallowRef<Handsontable>();

/**
 * 创建表格
 * @param container
 */
function createHot(container: HTMLElement) {
	const {dataHeader, dataHeaderCell} = getHeader(props.headerList);
	const {dataRow, dataRowCell} = getData(props.dataList);
	hatable.value = new Handsontable(container, {
		data: [dataHeader, ...dataRow],
		contextMenu: true,
		// columns: [],
		cell: [...dataHeaderCell, ...dataRowCell],
		colHeaders: true,
		rowHeaders: true,
		height: '320px',
		width: '100%',
		autoWrapRow: true,
		autoWrapCol: true,
		licenseKey: 'non-commercial-and-evaluation',
		language: frappe.boot.lang === 'zh' ? 'zh-CN' : undefined,
	});
	const contextMenu = getMenus(
		hatable.value as Handsontable, false, false, false,
		__('{} Main Material Verification And Disposal Statistics', [props.hotName]),
	);
	hatable.value?.updateSettings({contextMenu});
}
// 监视hot节点，存在之后创建handsontable实例
watch(hot, () => {
	if (hot.value) {
		createHot(hot.value);
	}
}, {immediate: true});
</script>

<style lang="less" scoped>
.table {
	width: 100%;
	height: 100%;
	:deep(.customCell){
		background-color: red;
		color: white
	}
}

:deep(.ht_clone_left) {
	z-index: 1;
}
:deep(.ht_clone_top) {
	z-index: 1;
}
:deep(.ht_clone_top_inline_start_corner.ht_clone_top_left_corner) {
	z-index: 1;
}
:deep(.ht_master) {
	padding-bottom: 15px;
}
:deep(.ht_clone_inline_start.ht_clone_left.handsontable) {
	display: inline;
}
</style>
