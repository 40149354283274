<template>
  <div :style="style">
    <div v-if="showScore">{{ score }}</div>
    <ElButton v-if="props.params.onRemove && !showScore" type="danger" @click="deleteData">{{ tt('Delete') }}</ElButton>
  </div>
</template>
<script lang="ts" setup>
  import { computed } from 'vue';
  const tt = __;
  const props = defineProps<{ params: any }>();

  function deleteData() {
    props.params.onRemove(props.params.data);
  }
  const score = computed(() => props.params.data.autoCalcScore);
  const showScore = computed(
    () => props.params.data.type_label === __('Total Weight') || props.params.data.type_label === __('Score Summary')
  );
  const style = {
    display: 'flex',
    alignItems: 'center',
  };
</script>
