<template>
  <div class="tool-container">
    <div class="tool">
      <h5>{{ tt('4、 Progress Management Situation') }}</h5>
      <p class="project-info">{{ tt('（1） The progress of key projects is detailed in the table below:') }}</p>
      <el-table :data="recordsRef" border="true">
        <el-table-column prop="guigu_project" :label="tt('Project')" width="180" #default="{ row }">
          <el-button type="text" class="link-button" @click="projectFilterClick(row.name)">
            {{ row.guigu_project }}
          </el-button>
        </el-table-column>
        <el-table-column prop="duration_months" :label="tt('Construction Period (Months)')" width="180" />
        <el-table-column prop="month_diff" :label="tt('Completed Construction Period (Months)')" />
        <el-table-column prop="plan_rate" :label="tt('As of this month, the completion rate of the planned image and appearance%')" />
        <el-table-column prop="actual_rate" :label="tt('Actual image completion rate%')" />
        <el-table-column prop="delay_reason" :label="tt('Delay Reason')" />
      </el-table>
      <p class="project-info">{{ tt('（2） On site construction output value') }}</p>
      <p>
        {{ outputValueStr }}
        {{
          tt(
            'The monthly progress output value, cumulative output value completion status, and deviation reasons of key projects are detailed in the table below:'
          )
        }}
      </p>
      <el-table :data="projectOutputValueRef" border="true">
        <el-table-column prop="guigu_project" :label="tt('Project')" width="180"> </el-table-column>
        <el-table-column prop="month_value_plan" :label="tt('Monthly Plan')" width="180" />
        <el-table-column prop="month_value_complete" :label="tt('Monthly Completion')" />
        <el-table-column prop="year_value_plan" :label="tt('Year Accumulate Plan')" />
        <el-table-column prop="year_value_complete" :label="tt('Year Accumulate Actual')" />
        <el-table-column prop="delay_reason" :label="tt('Deviation Reason')" />
      </el-table>
      <p class="project-info">{{ tt('（3） Major Nodes') }}</p>
      <p>{{ tt('1. The major milestones of each project this month are detailed in the table below') }}</p>
      <p>{{ tt('2. Next month node plan is detailed in the table below') }}</p>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { ref, watchEffect, defineProps, onMounted, watch, toRefs } from 'vue';

  const tt = __;

  interface Props {
    selectedDate?: string;
  }
  interface Record {
    guigu_project: String;
    duration_months?: String;
    month_diff?: String;
    plan_rate?: String;
    actual_rate?: String;
    delay_reason?: String;
  }

  const props = defineProps<Props>();
  const recordsRef = ref<Record[]>([]);
  const projectOutputValueRef = ref<Record[]>([]);
  const outputValueStr = ref<string>('');

  async function getRecords(date?: string) {
    if (!date) {
      recordsRef.value = [];
    }
    const { message } = await frappe.call<{ message: Record[] }>({
      method: 'guigu_pc.guigu_pc_month_rep.controls.get_construction_appearance',
      args: { selectedDate: props.selectedDate || '' },
    });
    const result = await frappe.call<{ message: Record[] }>({
      method: 'guigu_pc.guigu_pc_month_rep.controls.get_construction_output_value',
      args: { selectedDate: props.selectedDate || '' },
    });
    const { project_value_plan, current_month_complete, end_current_month_plan, end_current_month_complete, all_project_value_progress } =
      result?.message;
    /** 偏差额 */
    const deviationNumber = NP.minus(end_current_month_complete || 0, end_current_month_plan || 0);
    const deviationStr =
      deviationNumber === 0
        ? __('Invariant')
        : `${deviationNumber > 0 ? __('Increase') : __('Reduce')}${deviationNumber}` + __('Ten thousand yuan');
    const completeRate =
      project_value_plan && project_value_plan !== 0 ? NP.divide(current_month_complete || 0, project_value_plan || 0).toFixed(2) : 0;
    outputValueStr.value = __(
      'Completion status of output value: The annual planned construction output value is {} ten thousand yuan, with a cumulative completion of {} ten thousand yuan, accounting for {}% of the annual plan. According to the annual plan, the construction output value should be completed by the end of March 2023, with an actual output value of {} ten thousand yuan, which is higher than the annual plan.',
      [
        project_value_plan || 0,
        current_month_complete || 0,
        completeRate,
        end_current_month_plan || 0,
        end_current_month_complete || 0,
        deviationStr,
      ]
    );
    recordsRef.value = message;
    projectOutputValueRef.value = all_project_value_progress;
  }

  function projectFilterClick(projectName: string) {
    frappe.set_route('list', 'Guigu Pc Project Month Construction Appearance', { guigu_project: projectName });
  }

  onMounted(async () => {
    getRecords(props.selectedDate);
  });

  watchEffect(() => {
    getRecords(props.selectedDate);
  });
</script>

<style scoped lang="less">
  .tool-container {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    .tool {
      margin: 16px;
      padding: 24px;

      h5 {
        margin: 0 0 16px 0;
        font-size: 20px;
        font-weight: bold;
      }

      .project-info {
        margin: 0;
        font-size: 16px;
        line-height: 2;
        text-indent: 2em;

        .link-button {
          font-size: 16px !important;
        }
      }

      img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 30px auto;
      }
    }
  }
</style>
