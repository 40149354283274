import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8d668397"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar" }
const _hoisted_2 = { class: "input-container" }
const _hoisted_3 = { class: "input-container" }
const _hoisted_4 = { class: "input-container" }
const _hoisted_5 = { class: "input-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElOption = _resolveComponent("ElOption")
  const _component_ElSelect = _resolveComponent("ElSelect")
  const _component_ElDatePicker = _resolveComponent("ElDatePicker")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString($setup.tt('Company:')) + " ", 1 /* TEXT */),
      _createVNode(_component_ElSelect, {
        class: "input",
        filterable: "",
        modelValue: $setup.companyValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.companyValue) = $event))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.companies, (item) => {
            return (_openBlock(), _createBlock(_component_ElOption, {
              key: item.name,
              label: item.company_name,
              value: item.name
            }, null, 8 /* PROPS */, ["label", "value"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ]),
    _createVNode($setup["Project"], {
      class: "input-container",
      modelValue: $setup.projectValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.projectValue) = $event)),
      orderBy: 'sort_code',
      filters: $setup.filters
    }, null, 8 /* PROPS */, ["modelValue", "filters"]),
    _createElementVNode("div", _hoisted_3, [
      _createTextVNode(_toDisplayString($setup.tt('Year:')), 1 /* TEXT */),
      _createVNode(_component_ElDatePicker, {
        class: "date-input",
        modelValue: $setup.yearValue,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.yearValue) = $event)),
        valueFormat: "YYYY",
        type: "year",
        clearable: false
      }, null, 8 /* PROPS */, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createTextVNode(_toDisplayString($setup.tt('Starting Month:')), 1 /* TEXT */),
      _createVNode(_component_ElDatePicker, {
        class: "date-input",
        popperClass: "month-input",
        modelValue: $setup.startMonthValue,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.startMonthValue) = $event)),
        valueFormat: "MM",
        format: $setup.tt('MM Month'),
        type: "month",
        teleported: false,
        clearable: false
      }, null, 8 /* PROPS */, ["modelValue", "format"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createTextVNode(_toDisplayString($setup.tt('End Month:')), 1 /* TEXT */),
      _createVNode(_component_ElDatePicker, {
        class: "date-input",
        popperClass: "month-input",
        modelValue: $setup.endMonthValue,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.endMonthValue) = $event)),
        valueFormat: "MM",
        format: $setup.tt('MM Month'),
        type: "month",
        teleported: false,
        clearable: false
      }, null, 8 /* PROPS */, ["modelValue", "format"])
    ])
  ]))
}