import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0aab6acf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: { display:'flex', alignItems:'center'} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      style: _normalizeStyle({ color: $setup.canClick?'#409eff':'',minWidth:0, overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis', flex:1}),
      onClick: $setup.click
    }, _toDisplayString($setup.label), 5 /* TEXT, STYLE */),
    ($setup.hasPerm)
      ? (_openBlock(), _createBlock($setup["ElDropdown"], {
          key: 0,
          onCommand: $setup.command
        }, {
          dropdown: _withCtx(() => [
            _createVNode($setup["ElDropdownMenu"], null, {
              default: _withCtx(() => [
                ($setup.addable)
                  ? (_openBlock(), _createBlock($setup["ElDropdownItem"], {
                      key: 0,
                      command: "add"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("添加子项")
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          default: _withCtx(() => [
            _createVNode($setup["ElIcon"], { style: {cursor: 'pointer'} }, {
              default: _withCtx(() => [
                _createVNode($setup["MoreFilled"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ]))
}