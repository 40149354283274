<template>
	<ElDialog v-model="thisVisible" :title="tt('Batch selection of new personnel')">
		<div class="container">
			<el-steps :active="active" finish-status="success" align-center>
				<el-step :title="tt('Select personnel')" />
				<el-step :title="tt('Edit permissions')" />
			</el-steps>
			<el-table
				v-show="active===1"
				:data="users"
				@selection-change="handleSelectionChange"
				style="width: 100%"
				max-height="700px"
			>
				<el-table-column type="selection" width="55" />
				<el-table-column property="name" label="ID" width="150" />
				<el-table-column property="full_name" :label="tt('Person Name')"/>
			</el-table>
			<MemberForm
				isCreate
				v-show="active===2"
				v-model:designations="designations"
				v-model:permissions="permissionCheckList"
				v-model:isDefault="isDefault"
				v-model:roles="roleCheckList"
			></MemberForm>
		</div>

		<template #footer>
			<div class="dialog-footer">
				<el-button @click="cancel()">{{tt('Cancel')}}</el-button>
				<el-button v-show="active>1" type="primary" @click="pre">
					{{ tt('Previous step') }}
				</el-button>
				<el-button :disabled="selectedUsers.length===0" type="primary" @click="next">
					{{ active===2?tt('Confirm'):tt('Next Steps') }}
				</el-button>
			</div>
		</template>
	</ElDialog>
</template>

<script setup lang='ts'>
import { ref, onMounted, computed, watch } from 'vue';
import type { Member, Permissions, User } from '../type';

import {
	ElDialog, ElTable, ElButton, ElTableColumn, ElCheckboxGroup, ElCheckbox,
	ElSteps, ElStep
} from 'element-plus';
import MemberForm from './MemberForm.vue';
const tt = __
interface Props{
	visible:Boolean
	organization:string
	memberList:Member[]
}
const props = defineProps<Props>();
interface Emit{
	(e: 'update:visible', v: boolean): void,
	(e: 'loading'): void,
}
const emit = defineEmits<Emit>();
const users = ref<User[]>([])
const selectedUsers = ref<User[]>([])
const active = ref(1)

const roleCheckList = ref<string[]|'keep'>([])
const permissionCheckList = ref<string[]|'keep'>([])
const designations = ref<string[]|'keep'>([])
const isDefault = ref<boolean|'keep'>(false)

function reset(){
	active.value = 1
	roleCheckList.value = []
	permissionCheckList.value = []
	designations.value = []
	isDefault.value = false
	selectedUsers.value = []
}
function pre(){
	if (active.value-- < 1) active.value = 1
}
function next(){
	if(active.value === 2){
		confirm()
	}
	if (active.value++ > 2) active.value = 2
}

const thisVisible = computed({
	set(v){
		emit('update:visible', v)
	},
	get(){
		return props.visible
	}
})
watch([()=>props.organization, ()=>[...props.memberList]],()=>{
	getUsers()
}, {immediate:true})
async function getUsers(){
	const res = await frappe.call<{ message: Member[] }>({
		method: 'tianjy_organization.tianjy_organization.page.tianjy_organization_config.tianjy_organization_config.get_users',
		args:{
			organization_name: props.organization
		}
	});
	users.value = res?.message||[]
}
const handleSelectionChange = (val: User[]) => {
  selectedUsers.value = val
}

function cancel(){
	reset()
	thisVisible.value = false
}
async function confirm(){
	emit('loading')
	thisVisible.value = false
	await frappe.call<{ message: Member[] }>({
		method: 'tianjy_organization.tianjy_organization.page.tianjy_organization_config.tianjy_organization_config.bulk_add_members',
		args:{
			organization_name: props.organization,
			user_names: selectedUsers.value.map(item=>item.name),
			roles: roleCheckList.value,
			permissions:permissionCheckList.value,
			designations:designations.value,
			is_default:isDefault.value?'1':'0',
		}
	});
	reset()
}
</script>

<style lang='less' scoped>
.container{
	max-height: 550px;
	overflow: auto;
}
</style>
