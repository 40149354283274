import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AgGridVue = _resolveComponent("AgGridVue")
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createBlock(_component_AgGridVue, {
    class: "ag-theme-guigu work-assessment-container_table",
    rowData: _ctx.detailTableData,
    columnDefs: _ctx.columnDefs,
    autoGroupColumnDef: _ctx.autoGroupColumnDef,
    localeText: _ctx.zhCN,
    onGridReady: _ctx.onGridReady,
    getRowId: _ctx.getRowId,
    rowGroupPanelShow: "always",
    onCellValueChanged: _ctx.cellValueChanged,
    suppressDragLeaveHidesColumns: _ctx.smallMeta,
    leftFixed: 5
  }, null, 8 /* PROPS */, ["rowData", "columnDefs", "autoGroupColumnDef", "localeText", "onGridReady", "getRowId", "onCellValueChanged", "suppressDragLeaveHidesColumns"])), [
    [_directive_loading, _ctx.loading]
  ])
}