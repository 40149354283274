<template lang="html">
	<GuiguFilterDialog :meta="meta" v-model="filters" v-model:visible="filterVisible" />
	<Sorter :meta="meta" v-model="sort" v-model:visible="sortVisible" />
	<Editor :meta="meta" :value="editItem" @update="save" v-model:visible="editorVisible" />
	<ValueEditor
		:meta="meta"
		:loading="loading"
		v-model:visible="valueEditorVisible"
		:field="editField"
		:value="editValue"
		@update="saveGroupValue" />
	<div class="page-head header">
		<h3 class="title" :title="title">{{ title }}</h3>
		<ElButtonGroup class="group">
			<KanbanSwitcher :list="kanbanList" v-model="kanbanId" />
			<GuiguViewPageSwitcher />
		</ElButtonGroup>
		<Menu
			:disabled="disabled"
			:list="menuItems"
			@refresh="refresh"
			:filter="filters.length"
			@filter="filterVisible = !filterVisible"
			:sort="order.length"
			@sort="sortVisible = !sortVisible"
			@fullscreen="fullscreen" />
		<ElButton :disabled="loading" @click="add" :title="tt('Add')" circle :icon="Plus" type="primary" />
	</div>
	<div class="main" ref="kb">
		<KanbanC v-if="kanban" :groups="stages" :data="data" @update="update" :key="key" @setting="edit" @create="createByParam" #="{ value }">
			<Item :data="value" :meta="meta" :loading="loading" :fields="fieldDef" />
		</KanbanC>
		<ElButton v-else @click="kanbanId = ''">{{ tt('Create') }}</ElButton>
	</div>
</template>
<script setup lang="ts">
import {computed, onActivated, onDeactivated, onMounted, onUnmounted, ref, watch} from 'vue';
import {ElButton, ElButtonGroup} from 'element-plus';
import {Plus} from '@element-plus/icons-vue';

import requestDocList from '../../../../../guigu/guigu/public/js/utils/requestDocList';
import loadLinkDocTypes from '../utils/loadLinkDocTypes';
import * as services from '../services';
import type {Column, Kanban} from '../types';

import KanbanC from './Kanban/index.vue';
import getFieldDef from './getFieldDef';
import Item from './Item.vue';
import Sorter from './Sorter.vue';
import KanbanSwitcher from './KanbanSwitcher.vue';
import ValueEditor from './ValueEditor.vue';
import Editor from './Editor/index.vue';
import Menu from './Menu.vue';
import menu from './menu';
import getFields from './getFields';
import setFullscreen from './utils/setFullscreen';
import getStages from './getStages';
import parseFiltersFromRouteOptions from './parseFiltersFromRouteOptions';
import {setUrl} from './setUrl';
import useKanban from './useKanban';
import useFilter from './useFilter';
import useSort from './useSort';
import useServices from './useServices';

const tt = __;

const props = defineProps<{
	doctype: string;
	meta: locals.DocType;
}>();

const docMeta = computed(() => props.meta);

const initedCount = ref(1);
const inited = computed(() => !initedCount.value);
const loadingCount = ref(0);
const loading = computed(() => Boolean(loadingCount.value + initedCount.value));
const filterVisible = ref(false);
const sortVisible = ref(false);
const editorVisible = ref(false);
const editItem = ref<Kanban>();
const domVisible = ref(false);
onActivated(() => {
	domVisible.value = true;
});
onDeactivated(() => {
	domVisible.value = false;
});

const {kanbanList, kanbanMap, kanbanId, kanban} = useKanban(() => {
	editItem.value = undefined;
	editorVisible.value = true;
});
const title = computed(() => kanban.value?.title || __(props.doctype));
const {currentFilters, filters, allFilters, setFilterImmutable} = useFilter(kanban);
const {order, sort} = useSort(computed(() => props.meta));

const kb = ref();
function fullscreen() {
	setFullscreen(kb.value);
}

const fields = computed(() => kanban.value?.fields || []);

const editField = ref('');
const editValue = ref<Column>();
const valueEditorVisible = ref(false);
function edit(field: string, value?: Column) {
	editField.value = field;
	editValue.value = value;
	valueEditorVisible.value = true;
}
/******** 查询数据 ********/

/** 主区域数据 */
const data = ref<any[]>([]);

const unmounted = ref(false);
onUnmounted(() => {
	unmounted.value = true;
});
let timeout: any;
let lastTime = 0;
const settings = frappe.listview_settings[props.doctype] || {};
/** 加载主区域数据 */
async function refresh() {
	lastTime = 0;
	if (unmounted.value) {
		return;
	}
	loadingCount.value++;
	try {
		const values = await requestDocList(docMeta.value, allFilters.value, {
			fields: getFields(props.meta, sort.value, [
				...fields.value.map(v => v.field),
				...(kanban.value?.groups.map(g => g.field) || []),
			]),
			order: sort.value,
			limit: 0,
			group: [],
			offset: 0,
		});
		await settings.initData?.(values);
		data.value = values;
	} finally {
		loadingCount.value--;
	}
}
watch([sort, allFilters, fields], () => {
	if (unmounted.value) {
		return;
	}
	if (!inited.value) {
		return;
	}
	const now = Number(new Date());
	if (!lastTime) {
		lastTime = now + 5 * 1000;
	}
	clearTimeout(timeout);
	timeout = setTimeout(refresh, Math.min(500, lastTime - now));
});

/******** 创建按钮 ********/
function create(options: Record<string, any>, extend?: boolean) {
	const doctype = docMeta.value.name;
	let data = {};
	if (extend) {
		const list = allFilters.value
			.filter(v => v[0] === doctype || !v[0])
			.filter(v => v[2] === '=')
			.filter(v => v[3] !== undefined);
		data = Object.fromEntries(list.map(v => [v[1], v[3]]));
	}
	frappe.new_doc(docMeta.value.name, {...data, ...options});
}
function createByParam(v: any) {
	create(v, true);
}
function del(name: string, doctype = docMeta.value.name) {
	frappe.model.delete_doc(doctype, name, () => {});
}
function add() {
	const doctype = docMeta.value.name;
	const list = allFilters.value
		.filter(v => v[0] === doctype || !v[0])
		.filter(v => v[2] === '=')
		.filter(v => v[3] !== undefined);
	create(Object.fromEntries(list.map(v => [v[1], v[3]])));
}
/******** 初始化 ********/

async function init(name?: string) {
	initedCount.value++;
	try {
		const meta = docMeta.value;
		const [list] = await Promise.all([services.load(meta.name), loadLinkDocTypes(meta, settings)]);
		kanbanList.value = list;
		const map = kanbanMap.value;
		const id = kanbanId.value;
		if (name && map.has(name)) {
			kanbanId.value = name;
		} else if (!map.has(id)) {
			kanbanId.value = list[0]?.name || '';
		}
		await refresh();
	} finally {
		initedCount.value--;
	}
}
onMounted(() =>
	init().finally(() => {
		initedCount.value--;
	}));
/******** 自动刷新 ********/
const updated = ref(false);

watch(
	() => domVisible.value && updated.value,
	v => {
		if (!v) {
			return;
		}
		updated.value = false;
		if (inited.value) {
			return;
		}
		refresh();
	},
);

watch([domVisible, allFilters], ([v, f]) => {
	if (!v) {
		return;
	}
	setUrl(kanbanId.value, f, docMeta.value.name);
});

frappe.realtime.on('list_update', p => {
	if (p.doctype !== docMeta.value.name) {
		return;
	}
	updated.value = true;
});
/******** 数据处理 ********/
const stages = computed(() => kanban.value?.groups.map(g => getStages(g, docMeta.value, data.value)) || []);
const key = ref(0);
watch(stages, () => key.value++);
/******** 菜单 ********/
const {update, saveKanban, createKanban, deleteKanban, saveGroupValue} = useServices(
	computed(() => props.doctype),
	kanbanList,
	kanban,
	loadingCount,
	loading,
	data,
	stages,
	init,
	refresh,
);
function save(data: any, name?: string) {
	if (name) {
		saveKanban(name, data);
	} else {
		createKanban(data);
	}
}
const disabled = computed(() => loading.value || Boolean(kanban.value));

const menuItems = computed(() => [
	...menu(
		props.meta,
		() => saveKanban(kanban.value, {filters: filters.value}),
		() => deleteKanban(kanban.value),
		() => {
			editItem.value = kanban.value;
			editorVisible.value = true;
		},
		kanban.value,
	),
]);

/******** 路由处理 ********/
watch(
	domVisible,
	v => {
		if (!v) {
			return;
		}
		const doctype = docMeta.value.name;
		const data = parseFiltersFromRouteOptions(doctype);
		if (!data) {
			return;
		}
		const [name, allFilters] = data;
		const map = kanbanMap.value;
		if (map.size && !map.has(name)) {
			return;
		}
		// params.value = allParams;
		if (Object.keys(allFilters).length) {
			if (name !== kanbanId.value) {
				setFilterImmutable();
				kanbanId.value = name;
			}
			currentFilters.value = allFilters;
		} else if (name !== kanbanId.value) {
			kanbanId.value = name;
		}
	},
	{immediate: true},
);
/******** 视图部分 ********/
const fieldDef = computed(() => getFieldDef(props.meta, kanban.value?.fields));

function addBlock(key: string) {
	// blocks.value.push({
	//   name: blocks.value.length,
	//   status: stage,
	//   title: '标题',
	// });
}
</script>
<style scoped lang="less">
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 4px;
    height: 70px;
    padding-inline: 15px;
  }

  .main {
    height: calc(100vh - var(--navbar-height) - 75px);

    &:fullscreen {
      max-width: unset !important;
      background-color: var(--bg-color);
    }

    overflow: auto;
  }

  .title {
    flex: 1;
    cursor: pointer;
    margin-bottom: 0px;
    margin-right: var(--margin-sm);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  .group :deep(.el-button-group > .el-dropdown) {
    > .el-button {
      border-radius: 0;
      border-left-color: var(--el-button-border-color);
    }

    + .el-dropdown > .el-button {
      border-left-color: var(--el-button-divide-border-color);
    }

    &:first-child > .el-button.is-circle {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    &:last-child > .el-button.is-circle {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
  }
</style>
