<template>
  <div class="toolbar">
    <ElSpace>
      <ElSelect filterable :model-value="company" @change="changeCompany">
        <ElOption v-for="item in subCompanies" :key="item.name" :label="item.company_name" :value="item.name" />
      </ElSelect>
    </ElSpace>
    <div class="refresh">
      <el-button-group class="ml-4">
        <el-button @click="() => expandAll(true)">{{ tt('Expand') }}</el-button>
        <el-button @click="() => expandAll(false)">{{ tt('Collapse') }}</el-button>
        <el-button :loading="loading" @click="refresh" :title="tt('Refresh')" :icon="RefreshRight">{{ tt('Refresh') }}</el-button>
      </el-button-group>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { ref, onMounted, defineEmits, defineProps, toRefs } from 'vue';
  import { RefreshRight } from '@element-plus/icons-vue';
  import { GridApi } from 'ag-grid-community';

  import { SubCompany, Organization } from '../../type';

  import { routeInit, setRoute } from './helper';

  const tt = __;
  interface Props {
    company?: string;
    refreshValue?: number;
    detailGridApi?: GridApi;
    loading?: boolean;
  }

  const props = defineProps<Props>();
  const { company, refreshValue, detailGridApi, loading } = toRefs(props);

  interface Emit {
    (e: 'update:company', company: string): void;
    (e: 'update:loading', loading: boolean): void;
    (e: 'update:refreshValue', refreshValue: number): void;
  }
  const emit = defineEmits<Emit>();

  const subCompanies = ref<SubCompany[]>();

  const paramsFilter = ref<{ [key: string]: string }>({});

  onMounted(async () => {
    routeInit(paramsFilter);
    const subCompaniesRes: SubCompany[] = await frappe.db.get_list('Company', {
      fields: ['name', 'company_name'],
      limit: 0,
    });
    subCompanies.value = subCompaniesRes;

    const companyNames = subCompaniesRes.map((item) => item.name);
    const sessionDefaultCompany: string = (frappe.defaults.get_user_defaults('company') || [])[0];
    let defaultCompany: string = '';
    if (companyNames.includes(paramsFilter.value?.company)) {
      defaultCompany = paramsFilter.value?.company;
    } else {
      defaultCompany = sessionDefaultCompany || subCompaniesRes[0]?.name || '';
    }

    let routeObj: { [key: string]: any } = {};
    routeObj = { company: defaultCompany };
    emit('update:company', defaultCompany);
    setRoute(paramsFilter, routeObj);
  });

  function expandAll(expand: boolean) {
    if (expand) {
      detailGridApi?.value?.expandAll();
    } else {
      detailGridApi?.value?.collapseAll();
    }
  }

  function changeCompany(newCompany: string) {
    setRoute(paramsFilter, { company: newCompany });
    emit('update:company', newCompany);
  }

  function refresh() {
    const newValue: number = (refreshValue?.value || 0) + 1;
    emit('update:refreshValue', newValue);
  }
</script>
<style lang="less" scoped>
  .toolbar {
    padding: 8px 0;
  }

  .refresh {
    float: right;
  }
</style>
