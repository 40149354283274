import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-882a49e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "remark-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["ElTooltip"], {
      placement: "top",
      content: $setup.value
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($setup.value), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["content"])
  ]))
}