<template>
	<ElFormItem>
		<ElTable :data="fields" style="width: 100%">
			<ElTableColumn type="index" :index="1" />
			<ElTableColumn type="expand" #="{ row, $index }">
				<ElRow :gutter="8">
					<ElCol :span="6"></ElCol>
					<ElCol :span="6">{{ tt('Gantt Percent') }}</ElCol>
					<ElCol :span="6">{{ tt('Plan') }}</ElCol>
					<ElCol :span="6">{{ tt('Disabled') }}</ElCol>
				</ElRow>
				<ElRow :gutter="8">
					<ElCol :span="6">{{ tt('Estimation method value') }}</ElCol>
					<ElCol :span="6">
						<ElInput
							:modelValue="row.method_percent || ''"
							@update:modelValue="set(
								$index,
								'method_percent',
								$event || null,
							)" />
					</ElCol>
					<ElCol :span="6">
						<ElInput
							:modelValue="row.method_plan || ''"
							@update:modelValue="set(
								$index,
								'method_plan',
								$event || null,
							)" />
					</ElCol>
					<ElCol :span="6">
						<ElInput :modelValue="row.method_disabled || ''"
							@update:modelValue="set(
								$index,
								'method_disabled',
								$event || null,
							)" />
					</ElCol>
				</ElRow>
				<ElRow :gutter="8">
					<ElCol :span="6"></ElCol>
					<ElCol :span="6">{{ tt('Percentage estimation') }}</ElCol>
					<ElCol :span="6">{{ tt('Plan estimation') }}</ElCol>
					<ElCol :span="6">{{ tt('Summary estimate') }}</ElCol>
				</ElRow>
				<ElRow :gutter="8">
					<ElCol :span="6">{{ tt('Color') }}</ElCol>
					<ElCol :span="6">
						<ElColorPicker
							:modelValue="row.percent_color || ''"
							@update:modelValue="set(
								$index,
								'percent_color',
								$event || null,
							)" />
					</ElCol>
					<ElCol :span="6">
						<ElColorPicker
							:modelValue="row.plan_color || ''"
							@update:modelValue="set(
								$index,
								'plan_color',
								$event || null,
							)" />
					</ElCol>
					<ElCol :span="6">
						<ElColorPicker :modelValue="row.aggregate_color || ''"
							@update:modelValue="set(
								$index,
								'aggregate_color',
								$event || null,
							)" />
					</ElCol>
				</ElRow>
				<ElRow :gutter="8">
					<ElCol :span="6">{{ tt('Border Color') }}</ElCol>
					<ElCol :span="6">
						<ElColorPicker
							:modelValue="row.percent_border_color || ''"
							@update:modelValue="set(
								$index,
								'percent_border_color',
								$event || null,
							)" />
					</ElCol>
					<ElCol :span="6">
						<ElColorPicker
							:modelValue="row.plan_border_color || ''"
							@update:modelValue="set(
								$index,
								'plan_border_color',
								$event || null,
							)" />
					</ElCol>
					<ElCol :span="6">
						<ElColorPicker
							:modelValue="row.aggregate_border_color || ''"
							@update:modelValue="set(
								$index,
								'aggregate_border_color',
								$event || null,
							)" />
					</ElCol>
				</ElRow>

			</ElTableColumn>

			<ElTableColumn :label="tt('Estimation method field')" #="{ row, $index }">
				<ElSelect :modelValue="row.method" filterable defaultFirstOption
					@update:modelValue="set($index, 'method', $event)" clearable>
					<ElOption v-for="field in methodFields" :key="field.value"
						:value="field.value" :label="field.label" />
				</ElSelect>
			</ElTableColumn>

			<ElTableColumn :label="tt('Default estimation method')" #="{ row, $index }">
				<ElSelect :modelValue="row.method_default" filterable
					defaultFirstOption
					@update:modelValue="set($index, 'method_default', $event)">
					<ElOption value="Percent" :label="tt('Gantt Percent')" />
					<ElOption value="Plan" :label="tt('Plan')" />
					<ElOption value="Disabled" :label="tt('Disabled')" />
				</ElSelect>
			</ElTableColumn>


			<ElTableColumn :label="tt('Start Date')" #="{ row, $index }">
				<ElSelect :modelValue="row.start" filterable defaultFirstOption
					@update:modelValue="set($index, 'start', $event)">
					<ElOption v-for="field in dateFields" :key="field.value"
						:value="field.value" :label="field.label" />
				</ElSelect>
			</ElTableColumn>
			<ElTableColumn :label="tt('End Date')" #="{ row, $index }">
				<ElSelect :modelValue="row.end" filterable defaultFirstOption
					@update:modelValue="set($index, 'end', $event)">
					<ElOption v-for="field in dateFields" :key="field.value"
						:value="field.value" :label="field.label" />
				</ElSelect>
			</ElTableColumn>
			<ElTableColumn :label="tt('Gantt Percent')" #="{ row, $index }">
				<ElSelect :modelValue="row.percent" filterable defaultFirstOption
					@update:modelValue="set($index, 'percent', $event)">
					<ElOption v-for="field in percentFields" :key="field.value"
						:value="field.value" :label="field.label" />
				</ElSelect>
			</ElTableColumn>
			<ElTableColumn :label="tt('Expected Completion Date')" #="{ row, $index }">
				<ElSelect :modelValue="row.plan" filterable defaultFirstOption
					@update:modelValue="set($index, 'plan', $event)">
					<ElOption v-for="field in dateFields" :key="field.value"
						:value="field.value" :label="field.label" />
				</ElSelect>
			</ElTableColumn>

			<ElTableColumn :label="tt('Position')" #="{ row, $index }">
				<ElInput type="number" :modelValue="row.position || ''"
					@update:modelValue="setWidth($index, 'position', $event || 0)" />
			</ElTableColumn>
			<ElTableColumn :label="tt('Width')" #="{ row, $index }">
				<ElInput type="number" :modelValue="row.width || ''"
					@update:modelValue="setWidth($index, 'width', $event || 0)" />
			</ElTableColumn>


			<ElTableColumn :label="tt('Operation')" #="{ $index }">
				<ElButtonGroup>
					<ElButton @click="moveTo($index)" text :icon="Sort"
						:title="tt('Move')" />
					<ElButton type="danger" @click="remove($index)" text
						:icon="Delete" :title="tt('Delete')" />
				</ElButtonGroup>
			</ElTableColumn>
		</ElTable>
		<ElButton @click="add">{{ tt('Add') }}</ElButton>

	</ElFormItem>
</template>
<script setup lang="ts">
import {computed, ref, h} from 'vue';
import {
	ElTable, ElTableColumn,
	ElSelect, ElRow, ElCol, ElInput, ElColorPicker,
	ElFormItem, ElButtonGroup, ElButton, ElMessageBox, ElOption,
} from 'element-plus';
import {Delete, Sort} from '@element-plus/icons-vue';

const props = defineProps<{
	meta: locals.DocType;
	modelValue: any;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: GlobalView.View): void;
}>();
const view = computed({
	get: () => props.modelValue,
	set: v => emit('update:modelValue', v),
});
const tt = __;

const fields = computed<any[]>({
	get: () => view.value.estimated_lines || [],
	set: v => {
		view.value = {...view.value, estimated_lines: v};
	},
});
function set(index: number, key: string, value: any) {
	const list = [...fields.value];
	const item: any = list[index];
	if (!item) {
		return;
	}
	item[key] = value;
	fields.value = list;
}
function setWidth(index: number, key: any, value: any) {
	const list = [...fields.value];
	const item: any = list[index];
	if (!item) {
		return;
	}
	item[key] = Math.max(0, parseInt(value) || 0);
	fields.value = list;
}

function add() {
	fields.value = [...fields.value, {
		method_percent: 'Percent',
		method_plan: 'Plan',
		method_disabled: 'Disabled',
	}];
}
function remove(index: number) {
	const list = [...fields.value];
	if (!list.splice(index, 1).length) {
		return;
	}
	fields.value = list;
}
function move(from: number, to: number) {
	if (from === to) {
		return;
	}
	const list = [...fields.value];
	const item: any = list[from];
	if (!item) {
		return;
	}
	list.splice(from, 1);
	list.splice(to, 0, item);
	fields.value = list;
}

const dateFields = computed(() => {
	const fields = props.meta.fields || [];
	return fields
		.filter(d => ['Date', 'Datetime'].includes(d.fieldtype))
		.map(d => ({value: d.fieldname, label: `${__(d.label)} (${d.fieldname})`}));
});
const methodFields = computed(() => {
	const fields = props.meta.fields || [];
	return fields
		.filter(d => ['Select'].includes(d.fieldtype))
		.map(d => ({value: d.fieldname, label: `${__(d.label)} (${d.fieldname})`}));
});
const percentFields = computed(() => {
	const fields = props.meta.fields || [];
	return fields
		.filter(d => ['Percent'].includes(d.fieldtype))
		.map(d => ({value: d.fieldname, label: `${__(d.label)} (${d.fieldname})`}));
});
function moveTo(index: number) {
	const target = ref<number>(index);
	ElMessageBox({
		title: `将 #${index + 1} 移动到...`,
		message: (): any => h(ElSelect, {
			filterable: true,
			defaultFirstOption: true,
			modelValue: target.value,
			'onUpdate:modelValue': (val: number) => {
				target.value = val;
			},
		}, fields.value.map(({start, end}, i) => h(ElOption, {
			value: i,
			label: i === index
				? '<<<位置不变>>>'
				: [
					` #${i + 1} `,
					i < index ? '之前' : '之后',
				].join(''),
		}))),
	}).then(() => {
		move(index, target.value);
	});
}

</script>
