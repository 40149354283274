import type {
	CellClassParams,
	ColDef,
	ColGroupDef,
	ICellEditorParams,
	ICellRendererParams,
	RowSpanParams,
	EditableCallbackParams,
	ValueGetterParams,
} from 'ag-grid-community';

import type { BusinessType, BusinessTypeContent, FinishingWork } from '../type';

import AgGridToolTip from './AgGridToolTip.vue';

export function getColumns(smallMeta: boolean, businessTypes: BusinessType[], refresh: () => void, editable: boolean): (ColDef | ColGroupDef)[] {
	const columns: (ColDef | ColGroupDef)[] = [
		{
			headerName: __('Project'),
			field: 'project_label',
			enableRowGroup: false,
			editable: false,
			filter: false,
			pinned: smallMeta ? undefined : ('left' as const),
			width: 150,
			minWidth: 150,
			rowSpan: (params: RowSpanParams<FinishingWork>) => {
				const { data, api } = params;
				const rows: FinishingWork[] = api.getModel().rowsToDisplay.map(item => item.data);
				const currIndex = rows.findIndex(item => item.name === data?.name);
				const pre = rows[currIndex - 1];
				if (pre?.project_name === data?.project_name) {
					return 1;
				}
				const { length } = rows.filter(item => item.project_name === data?.project_name);
				return length;
			},
			cellClass: (params: CellClassParams<FinishingWork>) => {
				const { data, api } = params;
				const rows: FinishingWork[] = api.getModel().rowsToDisplay.map(item => item.data);
				const currIndex = rows.findIndex(item => item.name === data?.name);
				const pre = rows[currIndex - 1];
				return pre?.project_name === data?.project_name ? '' : 'rowSpanCell';
			},
		},
	];
	const businessTypeColumns: (ColDef | ColGroupDef)[] = businessTypes.map(item => ({
		headerName: item.type_name,
		field: `${item.name}.context_string`,
		enableRowGroup: false,
		editable: (params: EditableCallbackParams) => {
			const { data } = params;
			return (data.type === 'future' || data.type === 'year') && editable;
		},
		filter: false,
		minWidth: 225,
		flex: 1,
		cellRenderer: AgGridToolTip,
		cellRendererParams: {
			refresh,
		},
	}));
	const dateColumn: ColDef | ColGroupDef = {
		headerName: __('Category'),
		field: 'date',
		enableRowGroup: false,
		editable: false,
		filter: false,
		width: 135,
		minWidth: 135,
		pinned: smallMeta ? undefined : ('left' as const),
		valueGetter: (params: ValueGetterParams<FinishingWork>) => {
			const { data, colDef } = params;
			if (data?.type === 'year') {
				return __('Annual target');
			}
			if (data?.type === 'future') {
				return __('Remaining Work As Of The Previous Year');
			}
			return __('{}Monthly Progress', [data?.[colDef.field||'']]);
		},
	};
	columns.push(dateColumn, ...businessTypeColumns);
	return columns;
}
