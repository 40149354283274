

import mountVueToPage from 'guiguLib/mountVueToPage';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn';

import DailyRecordStatistics from './epc_daily_record_statistics/index.vue';
export default function loadEPCDailyRecordStatics(wrapper:HTMLElement) {
	const app = mountVueToPage(wrapper, DailyRecordStatistics);
	app.use(ElementPlus, {locale: zhCn});
	frappe.router.on('change', () => {
		const [pageName] = frappe.router?.current_route || [];
		if (pageName !== 'epc_daily_record_statistics') {
			delete frappe?.pages?.epc_daily_record_statistics;
			app?.unmount();
		}
	});
}

frappe.provide('frappe.guigu_work_list');
frappe.guigu_work_list.pages = frappe.guigu_work_list.pages || {};
frappe.guigu_work_list.pages.loadEPCDailyRecordStatics = loadEPCDailyRecordStatics;
