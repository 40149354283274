<template>
  <span v-if="props.params.value"
    :class="className"
    :data-filter="priorityFilter">
    {{ props.params.value }}
  </span>
</template>
<script lang="ts" setup>
import { defineProps } from 'vue';
import { ref } from 'vue';

const props = defineProps<{ params: { value: any, data: any } }>();
const priorityFilter = `priority,=,${props.params.data?.priority_name}`;
const className = `filterable indicator-pill ${frappe.utils.guess_colour(props.params.data?.priority_name)} ellipsis`;
</script>

<style scoped lang="less">
</style>
