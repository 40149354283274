<template>
	<div class="bg" v-if="isDialog"></div>
	<div
		:class="['detail', { ['skeleton-row']: row, ['is-dialog']: isDialog }]">
		<div class="close"></div>
		<slot v-if="hasDetailSlot"/>
		<FormDetail
			v-if="!hasDetailSlot&&docMeta"
			:meta="docMeta"
			:isDialog="isDialog"
			:name="name"
			v-model:visible="formVisible"
			@onChange="onChange"
		></FormDetail>
	</div>
	<div :class="['resize', { ['skeleton-row']: row }]" v-if="!isDialog"
		@pointerdown="detailBegin"
		@pointermove="detailMove"
		@pointerup="detailEnd"
		@pointercancel="detailEnd"
		@touchmove.prevent />
</template>
<script setup lang="ts">

import {computed, ref, shallowRef, useSlots, watch} from 'vue';

import FormDetail from '../FormDetail/index';
import type {ChangeValue} from '../FormDetail/type';

import usePointerCapture from './usePointerCapture';
const props = defineProps<{
	isDialog?: boolean;
	row?: boolean;
	doctype:string;
	name:string;
	defaultCreateFormValues?:Record<string, any>
	visible?:boolean
}>();
const emit = defineEmits<{
	(event: 'onChange', data: ChangeValue): void;
	(event: 'update:visible', visible: boolean): void;
}>();
const columnSize = ref('50%');
const rowSize = ref('50%');
const slots = useSlots();
const hasDetailSlot = shallowRef(Boolean(slots.detail?.()?.length));
const docMeta = ref<locals.DocType|null>();

watch(()=>props.doctype, ()=>{
	getMeta(props.doctype);
}, {immediate: true});

async function getMeta(docType: string) {
	frappe.model.with_doctype(docType, () => {
		const meta = frappe.get_meta(docType);
		docMeta.value = meta;
	});
}

function onChange(value: ChangeValue) {
	emit('onChange', value);
}
const formVisible = computed({
	get() {
		return props.visible;
	},
	set(value:boolean) {
		emit('update:visible', value);
	},
});

const [detailBegin, detailMove, detailEnd] = usePointerCapture(
	event => [event.offsetX, event.offsetY],
	(event, [xPosition, yPosition]) => {
		const parent = (event.currentTarget as Element).parentElement;
		if (!parent) {
			return;
		}
		const rect = parent.getBoundingClientRect();
		if (props.row) {
			rowSize.value = `${rect.width - (event.pageX - rect.x - xPosition)}px`;
		} else {
			columnSize.value = `${rect.height - (event.pageY - rect.y - yPosition)}px`;
		}
	},
);
</script>
<style scoped lang="less">
.bg {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1020;
	background: #00000066;

}

.detail {
	flex: 0 v-bind(columnSize);
	overflow: auto;
	order: 3;

	&.skeleton-row {
		flex: 0 v-bind(rowSize);
	}

	&.is-dialog {
		position: fixed;
		top: 30px;
		left: 30px;
		right: 30px;
		bottom: 30px;
		z-index: 1020;
		background: #FFF;
		border: 10px solid #FFF;
		border-width: 10px 0;
		border-radius: 10px;
	}
}

.resize {
	flex: 0 1px;
	z-index: 2;
	box-sizing: content-box;
	background: #d9d9d9;
	background-clip: padding-box;
	border-color: transparent;
	border-style: solid;
	border-width: 5px 0;
	margin: -5px 0;
	cursor: row-resize;
	order: 2;

	&:hover {
		border-color: #0000001a;
		transition: border-color 2s ease;
	}

	&.skeleton-row {
		margin: 0 -5px;
		border-width: 0 5px;
		cursor: col-resize;
	}
}
</style>
