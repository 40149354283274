<template lang="html">
	<KeepAlive v-if="loaded">
		<Main v-if="domVisible && docMeta" :meta="docMeta" :cfg="view"
			:label="label" class="widget" />
	</KeepAlive>
</template>
<script setup lang="ts">
import {computed, onMounted, shallowRef, watch} from 'vue';


import loadLinkDocTypes from '../../../../../../../guigu/guigu/public/js/utils/loadLinkDocTypes';

import Main from './Main.vue';


const props = defineProps<{
	value?: any;
	editing: boolean;
}>();

const domVisible = shallowRef(true);
const docMeta = shallowRef();


const view = computed(() => props.value || {});
const error = shallowRef('');
const label = computed(() => {
	const {label, doctype} = view.value;
	if (label) {
		return label;
	}
	if (doctype) {
		return __('doctype');
	}
	return `<${__('No Title')}>`;
});

const loaded = shallowRef(false);
async function init(doctype: string) {
	if (props.editing) {
		return;
	}
	try {
		let meta = {};
		const {value} = view;
		if (!doctype) {
			error.value = 'error';
			return;
		}
		await new Promise<void>(resolve => {
			frappe.model.with_doctype(doctype, () => {
				resolve();
			}, true);
		});
		meta = await frappe.get_meta(doctype);
		docMeta.value = meta;
		await loadLinkDocTypes(meta);
		loaded.value = true;
	} catch (e) {
		console.log(e);
		error.value = 'error';
	}
}
watch(() => props.value, () => {
	if (props.value) {
		init(props.value.doctype);
	}
});
onMounted(() => init(props.value?.doctype));

</script>
<style scoped lang="less">
.widget {
	border-radius: var(--border-radius-md);
	box-shadow: var(--card-shadow);
	background-color: var(--card-bg);

}
</style>
