import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = {
  key: 1,
  class: "tags-empty"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.tags.length)
    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.tags, (tag) => {
        return (_openBlock(), _createElementBlock("span", {
          class: "tag-pill",
          title: tag
        }, _toDisplayString(tag), 9 /* TEXT, PROPS */, _hoisted_1))
      }), 256 /* UNKEYED_FRAGMENT */))
    : (_openBlock(), _createElementBlock("span", _hoisted_2, "-"))
}