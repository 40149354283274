<template>
	<Quarter v-if="showQuarter" :inputClass="inputClass" :value="date" @update:value="changeQuarter"></Quarter>
	<div v-show="!showQuarter" class="guigu_date_editor_container" ref="dateRef">
		<el-date-picker
			class="guigu_date_editor"
			v-model="date"
			:type="type"
			:format="format"
			placeholder="请选择"
			:disabledDate="disabledDate"
			@change="change" />
	</div>
</template>

<script setup lang='ts'>
import { ref, watch, computed, onMounted } from 'vue';

import { DF } from './type';
import Quarter from './Quarter.vue';
interface Props {
	value: string,
	df: DF,
	dateControl: any
	inputClass: string,
	maxDate?: Date,
	minDate?: Date,
	disableDateSection?: Date[],
	disableDateArray?: Date[],
	disableFun?: Function
}

const props = defineProps<Props>();
interface Emit {
	(e: 'change', data: string | undefined): void
	(e: 'focus'): void
}
const emit = defineEmits<Emit>();

const date = ref<string>(props.value);
const dateRef = ref();
const showQuarter = computed(()=>props.df.options?.toLocaleLowerCase()==='quarter');
watch(() => props.value, async () => {
	date.value = props.value;
});
watch(dateRef, () => {
	if (!dateRef.value) { return; }
	const input = dateRef.value.querySelector('input');
	$(input).removeClass('el-input__inner');
	$(input).addClass(props.inputClass);
	$(input).attr('readonly', false);
	const prefix = dateRef.value.querySelector('.el-input__prefix');
	$(prefix).hide();
});
function getFormatDate(value?: Date | string) {
	if (!value) {
		return;
	}
	let formatValue = moment(value).format('YYYY-MM-DD');
	if (type.value === 'week') {
		formatValue = moment(value).add(1, 'days').format('YYYY-MM-DD');
	}
	return formatValue;
}
function change() {
	const formatValue = getFormatDate(date.value);
	emit('change', formatValue);
}
function changeQuarter(v:string){
	emit('change', v);
}
const lang = computed(() => {
	let lang = 'en';
	frappe.boot.user && (lang = frappe.boot.user.language);
	return lang;
});

const type = computed(() => props.df.options?.toLowerCase() || 'month');
const format = computed(() => {
	switch (props.df.options) {
		case 'Week':
		case 'week':
			return `gggg-ww[${lang.value === 'zh' ? '周' : 'week'}]`;
		case 'year':
		case 'Year':
			return 'YYYY';
		default:
			return 'YYYY-MM';
	}
});

let disableDate = ref(null);

function disabledDate(time:Date) {
	if (props.maxDate) {
		return time < props.maxDate;
	} else if (props.minDate) {
		return time > props.minDate;
	} else if (props.disableDateSection && props.disableDateSection.length === 2){
		return time.getTime() >= props.disableDateSection[0].getTime() && time.getTime() <= props.disableDateSection[1].getTime();
	} else if (props.disableDateArray && props.disableDateArray.length) {
		return props.disableDateArray.includes(time);
	}
}
</script>

<style lang='less' scoped>
.guigu_date_editor_container {
	display:flex;
	align-items: center;
	height: var(--input-height);
	:deep(.el-date-editor.el-input){
		height:auto
	}
	:deep(.el-input__suffix){
		position: absolute;
		right: 4px
	}
	:deep(.el-input__wrapper) {
		padding: 0;
	}

	:deep(.guigu_date_editor.el-date-editor.el-input) {
		width: 100%;

		.el-input__wrapper {
			box-shadow: none;
		}
	}
}
.grid-body .editable-row .guigu_date_editor_container{
	height: 38px;
}
</style>
