<template>
	<Main
		:meta="meta"
		:options="options"
		:loading="loading"
		:linkField="linkField"
		v-model:value="value"
		@refresh="refresh" />
</template>
<script lang="ts" setup>
import { computed } from 'vue';

import Main from '../FormDetail/index.vue';
const props = defineProps<{
	/** 当前 doctype 的信息 */
	meta: locals.DocType
	/** 数据选项 */
	options: Record<string, any>;

	/** 主区域当前选中的数据 */
	selected: any[];
	/** 主区域的 value */
	value?: any;

	/** 主区域数据是否在加载中 */
	loading?: boolean;

	view?: GlobalView.View;
}>();
const emit = defineEmits<{
	(event: 'refresh'): void;
	(event: 'update:value', value?: any): void;
}>();
function refresh() {
	emit('refresh');
}
const value = computed({
	get: () => props.value,
	set: v => emit('update:value', v),
});
const linkField = computed(() => props.view?.linkField);
</script>
<script lang="ts">
export default {};
</script>
