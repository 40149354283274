import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f33f08e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "instruction-container" }
const _hoisted_2 = { class: "instruction-wrapper" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_resolveDynamicComponent($setup.InstructionComponent), {
        instructionName: $props.instructionName,
        editable: false,
        mode: "show",
        referenceType: $props.referenceType,
        referenceName: $props.referenceName
      }, null, 8 /* PROPS */, ["instructionName", "referenceType", "referenceName"]))
    ])
  ]))
}