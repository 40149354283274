import * as Highcharts from 'highcharts';
import * as echarts from 'echarts';
// 加载 Highstock 或 Highmaps 方式类似
// import Highcharts from 'highcharts/highstock';

// 加载导出模块
// import * as Exporting from 'highcharts/modules/exporting';
// 初始化导出模块
// Exporting(Highcharts);

// 其他模块的加载方式同上

// 初始化图表

export function create_echarts(wrapper, options){
	const chart = echarts.init(wrapper, null, {
		renderer: 'canvas',
		useDirtyRect: false,
	  });
	chart.setOption({
		...options,
	});
	window.addEventListener('resize', chart.resize);
}

export function create_chart(wrapper, options){
	Highcharts.setOptions({
		chart: {
			backgroundColor: {
				linearGradient: [0, 0, 500, 500],
				stops: [
					[0, 'rgb(255, 255, 255)'],
					[1, 'rgb(240, 240, 255)'],
					],
			},
			// borderWidth: 2,
			plotBackgroundColor: 'rgba(255, 255, 255, .9)',
			plotShadow: true,
			plotBorderWidth: 1,
		},
		credits:{
			enabled:false,
		},
		plotOptions:{
			series:{
				marker:{
					enabled:false,
				},
			},
		},
	});

	Highcharts.chart(wrapper, options);
}
