import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"100%","display":"flex","align-items":"center","justify-content":"space-between"} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode($setup["Icon"], { icon: $setup.icon }, null, 8 /* PROPS */, ["icon"]),
      _createElementVNode("span", {
        style: {"cursor":"pointer","text-decoration":"underline"},
        onClick: $setup.clickLabel
      }, _toDisplayString($setup.fullName), 1 /* TEXT */),
      _withDirectives(_createElementVNode("span", { style: {"color":"red"} }, _toDisplayString(`（${$setup.tt('Deactivate')})`), 513 /* TEXT, NEED_PATCH */), [
        [_vShow, $setup.disable]
      ])
    ]),
    ($props.params.permission)
      ? (_openBlock(), _createBlock($setup["ElDropdown"], {
          key: 0,
          onCommand: $setup.command,
          trigger: "click"
        }, {
          dropdown: _withCtx(() => [
            _createVNode($setup["ElDropdownMenu"], null, {
              default: _withCtx(() => [
                (!$setup.disable)
                  ? (_openBlock(), _createBlock($setup["ElDropdownItem"], {
                      key: 0,
                      command: "add"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.tt('Add Child')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true),
                ($setup.isFinal && !$setup.disable)
                  ? (_openBlock(), _createBlock($setup["ElDropdownItem"], {
                      key: 1,
                      command: "disable",
                      style: {"color":"red"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.tt('Deactivate')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true),
                ($setup.isFinal && $setup.disable)
                  ? (_openBlock(), _createBlock($setup["ElDropdownItem"], {
                      key: 2,
                      command: "enable"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.tt('Enable')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          default: _withCtx(() => [
            _createVNode($setup["ElIcon"], { style: {"cursor":"pointer"} }, {
              default: _withCtx(() => [
                _createVNode($setup["MoreFilled"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ]))
}