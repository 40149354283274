import * as echarts from 'echarts';

export function getChart(wrap: HTMLElement, optionParams: echarts.EChartsCoreOption) {
  const myChart = echarts.init(wrap);
  const options: echarts.EChartsCoreOption = {
    ...optionParams,
  };
  myChart.setOption(options);
  return myChart;
}
