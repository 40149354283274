<template>
	<el-dialog v-model="dialogVisible" :title="tt('Key Tasks For The New Week')" :width="width" destroyOnClose @close="cancel">
		<div>
			<el-form class="temp-form" :model="form" :rules="rules" labelWidth="100px">
				<el-form-item :label="tt('Plan Content')" prop="subject">
					<el-input type="textarea" v-model="form.subject" />
				</el-form-item>
				<el-form-item :label="tt('Evaluation Criterion')" prop="standard">
					<el-input type="textarea" v-model="form.standard" />
				</el-form-item>
				<el-form-item :label="tt('Complete Status')" prop="remark">
					<el-input type="textarea" v-model="form.remark" />
				</el-form-item>
			</el-form>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="cancel">{{ tt('Cancel') }}</el-button>
				<el-button type="primary" @click="confirm">{{ tt('Sure') }}</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup lang="ts">
import { ElMessage } from 'element-plus';
import { reactive, ref, watch, computed } from 'vue';

const tt = __;

interface Props {
	visible: boolean;
	smallMeta: boolean;
}
const props = defineProps<Props>();
interface Emit {
	(e: 'cancel'): void;
	(e: 'confirm', data: { subject: string; standard: string; remark: string }): void;
}
const emit = defineEmits<Emit>();
const dialogVisible = ref<boolean>(false);
const width = computed(() => (props.smallMeta ? '90%' : '40%'));
const form = reactive({
	subject: '',
	standard: '',
	remark: '',
});
const rules = reactive({
	subject: [{ required: true, message: __('Please fill in the plan content'), trigger: 'blur' }],
});
watch(
	() => props.visible,
	() => {
		dialogVisible.value = props.visible;
	},
	{ immediate: true },
);

function reset() {
	form.remark = '';
	form.standard = '';
	form.subject = '';
}
function confirm() {
	if (!form.subject) {
		ElMessage({
			showClose: true,
			message: __('Please fill in the required fields'),
			type: 'error',
		});
		return;
	}
	emit('confirm', form);
	reset();
}
function cancel() {
	emit('cancel');
	reset();
}
</script>

<style lang="less" scoped>
  .temp-form :deep(label) {
    margin-bottom: 0;
  }
</style>
