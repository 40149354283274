import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6fe9bb1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "update_record_container" }
const _hoisted_2 = { class: "update_record_item" }
const _hoisted_3 = { class: "update_record_label" }
const _hoisted_4 = { class: "update_record_item" }
const _hoisted_5 = { class: "update_record_label" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.data.length)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($props.data, (item) => {
          return (_openBlock(), _createBlock($setup["ElCard"], {
            style: {"width":"90%","margin":"10px auto"},
            key: item.name,
            shadow: "hover"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", _hoisted_3, _toDisplayString($setup.tt('Update Date')) + ": ", 1 /* TEXT */),
                _createElementVNode("span", null, _toDisplayString(item.update_date), 1 /* TEXT */)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString($setup.tt('Subject')) + ": ", 1 /* TEXT */),
                _createElementVNode("span", null, _toDisplayString(item.title), 1 /* TEXT */)
              ]),
              _createVNode($setup["Description"], {
                description: item.description
              }, null, 8 /* PROPS */, ["description"])
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */))
        }), 128 /* KEYED_FRAGMENT */))
      : (_openBlock(), _createBlock($setup["ElEmpty"], {
          key: 1,
          description: $setup.tt('No Records Created')
        }, null, 8 /* PROPS */, ["description"]))
  ]))
}