

frappe.provide('frappe.guigu_work_list');

export interface RenderProps{
	frm:any,
	fieldProps:{
		weekOptionsField:string,
		monthOptionsField:string,
		dayOptionsField:string,
	}
}

const FieldOptions={
	weekOptionsField:'Every Day\nMonday\nTuesday\nWednesday\nThursday\nFriday\nSaturday\nSunday',
	monthOptionsField:'Every Month\nJan\nFeb\nMar\nApr\nMay\nJun\nJul\nAug\nSep\nOct\nNov\nDec',
	dayOptionsField:'Every Day\n1\n2\n3\n4\n5\n6\n7\n8\n9\n10\n11\n12\n13\n14\n15\n16\n17\n18\n19\n20\n21\n22\n23\n24\n25\n26\n27\n28',
};
frappe.guigu_work_list.timing_render = function(
	{
		frm,
		fieldProps,
	}:RenderProps,
){
	for (const fieldKey in fieldProps){
		const field = fieldProps[fieldKey as keyof typeof fieldProps];
		const readOnly = frm.fields_dict[field].disp_status !== 'Write';

		const fieldControl = frm.fields_dict[field];
		fieldControl.df.fieldtype ='Multi Select';
		fieldControl.df.options = FieldOptions[fieldKey as keyof typeof fieldProps];
		fieldControl.df.formatter = function(value:string, df:any, options:string, doc:any){
			const vArr = (value||'').split(',');
			const format_value = vArr.map(item=>__(item)).join(',');
			return format_value;
		};
		frm.layout.replace_field(field, fieldControl.df);
		frm.layout.attach_doc_and_docfields(true);
	}
};


frappe.guigu_work_list.register_timing = function({
	doctype,
	weekOptionsField = 'week_options',
	monthOptionsField = 'month_options',
	dayOptionsField = 'day_options',
	dateTypeField = 'date_type',
	reverseOrderField = 'reverse_order',
	ruleTemplateField = 'guigu_epc_task_timing_rule_template',
	periodField = 'create_time_period',
}:{
	weekOptionsField?:string,
	monthOptionsField?:string,
	dayOptionsField?:string,
	dateTypeField?:string,
	reverseOrderField?:string,
	ruleTemplateField?:string
	periodField?:string,
	doctype:string
}){
	frappe.ui.form.on(doctype, {
		refresh(frm: any) {
			frappe.guigu_work_list.timing_render({
				frm,
				fieldProps:{
					weekOptionsField,
					monthOptionsField,
					dayOptionsField,
				},
			});
			frm.trigger('hide_fields');
			frm.trigger('readonly');
			frm.trigger('set_label');
		},
		[ruleTemplateField](frm:any){
			frm.trigger('readonly');
			frm.trigger('setField');
		},
		[dateTypeField](frm:any){
			frm.trigger('hide_fields');
		},
		reverse_order(frm:any) {
			frm.trigger('set_label');
		},
		set_label(frm:any){
			if (frm.doc.reverse_order) {
				frm.set_df_property(dayOptionsField, 'label', 'Create Reverse Days');
			} else {
				frm.set_df_property(dayOptionsField, 'label', 'Create Days');
			}
		},
		async setField(frm:any){
			if (!frm.doc[ruleTemplateField]){
				return;
			}
			const template = await frappe.db.get_doc('Guigu Epc Task Timing Rule Template', frm.doc[ruleTemplateField]);
			frm.set_value({
				[periodField]: template.create_time_period,
				[dateTypeField]: template.date_type,
				[monthOptionsField]: template.month_options,
				[dayOptionsField]: template.day_options,
				[reverseOrderField]: template.reverse_order,
				[weekOptionsField]: template.week_options,
			});
		},
		readonly(frm:any){
			frm.set_df_property(dateTypeField, 'read_only', Boolean(frm.doc[ruleTemplateField]));
			frm.set_df_property(monthOptionsField, 'read_only', Boolean(frm.doc[ruleTemplateField]));
			frm.set_df_property(dayOptionsField, 'read_only', Boolean(frm.doc[ruleTemplateField]));
			frm.set_df_property(reverseOrderField, 'read_only', Boolean(frm.doc[ruleTemplateField]));
			frm.set_df_property(weekOptionsField, 'read_only', Boolean(frm.doc[ruleTemplateField]));
			frm.set_df_property(periodField, 'read_only', Boolean(frm.doc[ruleTemplateField]));
		},
		hide_fields(frm){
			hide_field(monthOptionsField);
			hide_field(weekOptionsField);
			hide_field(dayOptionsField);
			hide_field(reverseOrderField);
			if (frm.doc[dateTypeField] === 'Week'){
				unhide_field(weekOptionsField);
				frm.doc[monthOptionsField] = ',';
				frm.doc[dayOptionsField] = ',';
				frm.refresh_field(monthOptionsField);
				frm.refresh_field(dayOptionsField);
				frm.set_value(reverseOrderField, 0);
			} else if (frm.doc[dateTypeField] === 'Month') {
				unhide_field(monthOptionsField);
				unhide_field(dayOptionsField);
				unhide_field(reverseOrderField);
				frm.doc[weekOptionsField] = ',';
				frm.refresh_field(weekOptionsField);
			}
		},
	});
};
