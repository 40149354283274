<template>
	<div>
		<AgGridVue
			class="ag-theme-guigu"
			:columnDefs="columnDefs"
			:rowData="structureProgresses"
			rowDragMultiRow
			rowSelection="multiple"
			@gridReady="gridApi = $event.api"
			:getRowId="getRowId"
			domLayout="autoHeight"
			@cellValueChanged="cellValueChanged"
			:detailRowAutoHeight="true"
			:groupDefaultExpanded="1"
			:suppressDragLeaveHidesColumns="smallMeta"
			:localeText="zhCN"
			:defaultColDef="defaultColDef" />
		<LinkBoqDialog
			:project="project"
			:visible="visible"
			:structureProgress="structureProgressLink"
			@close="close"
			@confirm="confirm"></LinkBoqDialog>
	</div>
</template>

<script setup lang='ts'>
import { ref, defineProps, defineEmits, computed, getCurrentInstance, watch } from 'vue';
import { AgGridVue } from 'ag-grid-vue3';
import { GridApi } from 'ag-grid-community';

import { OriginStructureProgress, StructureProgressBoq } from '../../type/StructureProgress';
import { useMetaQuery } from '../../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';
import zhCN from '../../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';

import LinkBoqDialog from './LinkBoqDialog.vue';
import { getColumns } from './structureProgressColumns';
import { getStructureProgressBopColumns } from './StructureProgressBoqColumns';

const { appContext } = getCurrentInstance() || {};

interface Props {
	structureProgresses: OriginStructureProgress[]
	enableAudit: boolean
	project: string
}
const props = defineProps<Props>();
interface Emit {
	(e: 'deleteStructureProgress', structureProgress: string): void,
	(e: 'clickComplete', data: OriginStructureProgress): void,
	(e: 'change', data: OriginStructureProgress[]): void
}
const emit = defineEmits<Emit>();
const gridApi = ref<GridApi>();
const visible = ref<boolean>(false);
const structureProgressLink = ref<OriginStructureProgress>();
const smallMeta = useMetaQuery();
const defaultColDef = {
	resizable: true,
};
function deleteRecord(data: OriginStructureProgress) {
	const frm = appContext?.config.globalProperties.$frm;
	const { progress_item }: { progress_item: OriginStructureProgress[] } = frm.get_doc();
	const progressIndex = progress_item.findIndex(item => item.structure_progress_name === data.structure_progress_name);
	if (progressIndex > -1) {
		const progress_item_clone = structuredClone(progress_item);
		progress_item_clone.splice(progressIndex, 1);
		if (progress_item_clone.length === 0) {
			frm.set_value('progress_item', '');
		} else {
			frm.set_value('progress_item', progress_item_clone);
		}
	}
	emit('deleteStructureProgress', data.structure_progress_name!);
}

function cellValueChanged(event: any) {
	const { data } = event;
	const frm = appContext?.config.globalProperties.$frm;
	const { progress_item }: { progress_item: OriginStructureProgress[] } = frm.get_doc();
	const structureProgress = progress_item.find(item => item.progress_item === data.progress_item);
	if (structureProgress) {
		structureProgress.design_quantity = data.design_quantity;
		frm.set_value('progress_item', progress_item);
	}
}
function clickComplete(data: OriginStructureProgress) {
	emit('clickComplete', data);
}
function onLink(data: OriginStructureProgress) {
	structureProgressLink.value = data;
	visible.value = true;
}
const columnDefs = computed(() => getColumns(
	props.enableAudit,
	deleteRecord,
	clickComplete,
	onLink,
));
function getRowId({ data }: { data: OriginStructureProgress }) {
	return data.structure_progress_name;
}
function close() {
	visible.value = false;
}
function confirm(formData: StructureProgressBoq) {
	const frm = appContext?.config.globalProperties.$frm;
	const { progress_item }: { progress_item: OriginStructureProgress[] } = frm.get_doc();
	const structureProgress = progress_item.find(item => item.structure_progress_name === structureProgressLink.value?.structure_progress_name);
	if (!structureProgress) { return; }
	structureProgress.boq.push(formData);
	frm.set_value('progress_item', [...progress_item]);
	emit('change', progress_item);
	visible.value = false;
}

function deleteBoq(data: StructureProgressBoq) {
	const frm = appContext?.config.globalProperties.$frm;
	const { progress_item }: { progress_item: OriginStructureProgress[] } = frm.get_doc();
	const structureProgress = progress_item.find(item => item.structure_progress_name === data.structure_progress);
	if (!structureProgress) { return; }
	const index = structureProgress.boq.findIndex(item => item.boq_item === data.boq_item);
	structureProgress.boq.splice(index, 1);
	frm.set_value('progress_item', [...progress_item]);
	emit('change', progress_item);
}

function detailCellValueChanged(event: any) {
	const { data } = event;
	const frm = appContext?.config.globalProperties.$frm;
	const { progress_item }: { progress_item: OriginStructureProgress[] } = frm.get_doc();
	const structureProgress = progress_item.find(item => item.structure_progress_name === data.structure_progress);
	if (structureProgress) {
		const boq = structureProgress.boq.find(item => item.boq_item === data.boq_item);
		if (!boq) { return; }
		boq.coefficient = data.coefficient;
		boq.price = data.price;
		frm.set_value('progress_item', progress_item);
		emit('change', progress_item);
	}
}
const detailCellRendererParams = computed(() => ({
	detailGridOptions: {
		columnDefs: getStructureProgressBopColumns(deleteBoq),
		groupDefaultExpanded: 1,
		detailRowAutoHeight: true,
		getRowId: ({ data }: { data: StructureProgressBoq }) => `${data.name}-${data.boq_item}-${data.structure_progress}`,
		onCellValueChanged: detailCellValueChanged,
	},
	getDetailRowData: params => {
		params.successCallback(params.data.boq);
	},
}));
</script>

<style lang='less' scoped>
</style>
