import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9a9883af"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href", "download"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("span", {
    style: {display:'flex'},
    title: $setup.title
  }, [
    ($setup.href)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          style: $setup.urlStyle,
          target: "_blank",
          href: $setup.href
        }, _toDisplayString($setup.label), 9 /* TEXT, PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    ($setup.href)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          href: $setup.href,
          download: $setup.download
        }, [
          _createVNode($setup["ElIcon"], null, {
            default: _withCtx(() => [
              _createVNode($setup["Download"])
            ]),
            _: 1 /* STABLE */
          })
        ], 8 /* PROPS */, _hoisted_3))
      : _createCommentVNode("v-if", true)
  ], 8 /* PROPS */, _hoisted_1))
}