<template>
	<span class="toggle-btn" :title="translate('vertical layout')" @click="switchOpen">
		<svg class="icon icon-md placeholder">
			<use href="#icon-menu"></use>
		</svg>
		<span class="icon_status">
			<svg class="icon icon-md">
				<use
					:href="modelValue ? '#icon-sidebar-collapse' : '#icon-sidebar-expand'" />
			</svg>
		</span>
	</span>
</template>

<script setup lang="ts">
const translate = __;

const props = defineProps<{
	modelValue?: boolean;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', v: boolean): void
}>();
function switchOpen() {
	emit('update:modelValue', !props.modelValue);
}
</script>
<style scoped lang="less">
.toggle-btn {
	display: flex;
	margin-right: var(--margin-sm);
	cursor: pointer;
	--icon-stroke: var(--gray-500);

	.icon_status {
		display: none;
	}

	&:hover {
		.placeholder {
			display: none;
		}

		.icon_status {
			display: block;
		}
	}


}
</style>
