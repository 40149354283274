import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c8fb6fd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "row_content" }
const _hoisted_2 = { id: "title_and_icon" }
const _hoisted_3 = { id: "work_list_title" }
const _hoisted_4 = { class: "button_and_icon" }
const _hoisted_5 = { class: "core_circle" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!$setup.isWorkList && $setup.level?.icon)
        ? (_openBlock(), _createBlock($setup["Icon"], {
            key: 0,
            icon: $setup.level.icon
          }, null, 8 /* PROPS */, ["icon"]))
        : _createCommentVNode("v-if", true),
      _createVNode($setup["ElTooltip"], {
        content: $props.data.title,
        effect: "light"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_3, _toDisplayString($setup.subject), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["content"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createElementVNode("span", _hoisted_5, null, 512 /* NEED_PATCH */), [
        [_vShow, $props.data.isCore]
      ]),
      _createVNode($setup["ElTooltip"], {
        content: $setup.tt('Create a new task from the task template'),
        effect: "light"
      }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode($setup["ElIcon"], {
            onClick: _cache[0] || (_cache[0] = $event => ($setup.emit('creatTask',$props.data))),
            class: "icon_plus"
          }, {
            default: _withCtx(() => [
              _createVNode($setup["Plus"], { color: "blue" })
            ]),
            _: 1 /* STABLE */
          }, 512 /* NEED_PATCH */), [
            [_vShow, $props.permission && $setup.isWorkList]
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["content"])
    ])
  ]))
}