/* eslint-disable eqeqeq */
import type {
	ColDef,
	ColGroupDef,
	ICellRendererParams,
  } from 'ag-grid-community';

  export default function getProgressColumns(enableAudit: boolean) {
	const auditColumn = enableAudit
    ? [
        {
          headerName: __("Today's review volume"),
          field: 'actual_quantity',
          sortable: false,
          editable: false,
          width: 140,
          filter: false,
          enableRowGroup: false,
          cellRenderer: 'Float',
          cellRendererParams: (params: ICellRendererParams) => {
            const { value } = params;
            return {
              value: value == 0 ? null : value,
            };
          },
        },
      ]
    : [];
	const initColumns: (ColDef | ColGroupDef)[] = [
	  {
		headerName: __('Unit'),
		field: 'unit',
		width: 75,
		sortable: false,
		editable: false,
		filter: false,
		enableRowGroup: false,
		lockPinned: true,
		pinned: 'left',
	  },
	  {
		headerName: __('This Week Plan'),
		field: 'week_plan',
		sortable: false,
		editable: false,
		width: 140,
		filter: false,
		enableRowGroup: false,
		cellRenderer: 'Float',
		cellRendererParams: (params: ICellRendererParams) => {
		  const { value } = params;
		  return {
			value: value == 0 ? null : value,
		  };
		},
	  },
	  {
		headerName: __("Completed quantity this week"),
		field: 'week_finished',
		sortable: false,
		editable: false,
		width: 140,
		filter: false,
		enableRowGroup: false,
		cellRenderer: 'Float',
		cellRendererParams: (params: ICellRendererParams) => {
		  const { value } = params;
		  return {
			value: value == 0 ? null : value,
		  };
		},
	  },
	  {
		headerName: __("Unfinished quantity this week"),
		field: 'week_unfinished',
		sortable: false,
		editable: false,
		width: 140,
		filter: false,
		enableRowGroup: false,
		cellRenderer: 'Float',
		cellRendererParams: (params: ICellRendererParams) => {
		  const { value } = params;
		  return {
			value: value == 0 ? null : value,
		  };
		},
	  },
	  {
		headerName: __("Completion ratio this week"),
		field: 'week_completion_rate',
		sortable: false,
		editable: false,
		width: 140,
		filter: false,
		enableRowGroup: false,
		cellRenderer: 'SparklineCellRenderer',
	  },
	  {
		headerName: __("Today Plan"),
		field: 'today_plan',
		sortable: false,
		editable: false,
		width: 140,
		filter: false,
		enableRowGroup: false,
		cellRenderer: 'Float',
		cellRendererParams: (params: ICellRendererParams) => {
		  const { value } = params;
		  return {
			value: value == 0 ? null : value,
		  };
		},
	  },
	  {
		headerName: __("Today's completion volume"),
		field: enableAudit?'report_quantity':'actual_quantity',
		sortable: false,
		editable: false,
		width: 140,
		filter: false,
		enableRowGroup: false,
		cellRenderer: 'Float',
		cellRendererParams: (params: ICellRendererParams) => {
		  const { value } = params;
		  return {
			value: value == 0 ? null : value,
		  };
		},
	  },
	  ...auditColumn,
	  {
		headerName: __("Today's unfinished quantity"),
		field: 'today_unfinished',
		sortable: false,
		editable: false,
		width: 140,
		filter: false,
		enableRowGroup: false,
		cellRenderer: 'Float',
		cellRendererParams: (params: ICellRendererParams) => {
		  const { value } = params;
		  return {
			value: value == 0 ? null : value,
		  };
		},
	  },
	  {
		headerName: __("Today's completion ratio"),
		field: 'today_completion_rate',
		sortable: false,
		editable: false,
		width: 140,
		filter: false,
		enableRowGroup: false,
		cellRenderer: 'SparklineCellRenderer',
	  },
	  {
		headerName: __("Remark"),
		field: 'remark',
		sortable: false,
		editable: false,
		minWidth: 300,
		flex: 1,
		filter: false,
		enableRowGroup: false,
		cellRenderer: 'RemarkCellRender',
	  },
	];
	return initColumns;
  }
