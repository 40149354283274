<template>
	<div class="bg" v-if="model"></div>
	<div :class="['detail', { ['detail-row']: row, ['model']: model }]"
		:style="style">
		<div class="close"></div>
		<slot />
	</div>
	<div :class="['resize', { ['row']: row }]" v-if="!model"
		@pointerdown="detailBegin"
		@pointermove="detailMove"
		@pointerup="detailEnd"
		@pointercancel="detailEnd"
		@touchmove.prevent />
</template>
<script setup lang="ts">
import {computed} from 'vue';

import usePointerCapture from './usePointerCapture';

const props = defineProps<{
	columnSize: string;
	rowSize: string;
	model?: Boolean;
	row?: Boolean;
}>();
const emit = defineEmits<{
	(event: 'refresh'): void
	(event: 'update:columnSize', size: string): void
	(event: 'update:rowSize', size: string): void
}>();

const [detailBegin, detailMove, detailEnd] = usePointerCapture(
	e => [e.offsetX, e.offsetY],
	(e, [x, y]) => {
		const parent = (e.currentTarget as Element).parentElement;
		if (!parent) {
			return;
		}
		const rect = parent.getBoundingClientRect();
		if (props.row) {
			emit('update:rowSize', `${rect.width - (e.pageX - rect.x - x)}px`);
		} else {
			emit('update:columnSize', `${rect.height - (e.pageY - rect.y - y)}px`);
		}
	},
);
const style = computed(() => ({
	'--global-view-column-size': props.columnSize,
	'--global-view-row-size': props.rowSize,
}));

</script>
<style scoped lang="less">
.bg {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1020;
	background: #00000066;

}

.detail {
	flex: 0 var(--global-view-column-size);
	overflow: auto;
	order: 3;

	&.detail-row {
		flex: 0 var(--global-view-row-size);
	}

	&.model {
		position: fixed;
		top: 30px;
		left: 30px;
		right: 30px;
		bottom: 30px;
		z-index: 1020;
		background: #FFF;
		border: 10px solid #FFF;
		border-width: 10px 0;
		border-radius: 10px;
	}
}

.resize {
	flex: 0 1px;
	z-index: 7;
	box-sizing: content-box;
	background: #d9d9d9;
	background-clip: padding-box;
	border-color: transparent;
	border-style: solid;
	border-width: 5px 0;
	margin: -5px 0;
	cursor: row-resize;
	order: 2;

	&:hover {
		border-color: #0000001a;
		transition: border-color 2s ease;
	}

	&.row {
		margin: 0 -5px;
		border-width: 0 5px;
		cursor: col-resize;
	}
}
</style>
