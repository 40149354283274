import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["src", "title"]
const _hoisted_2 = ["src", "title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.inline)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        ($setup.options && $setup.value)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: $setup.value,
              title: $setup.title,
              style: {"max-height":"30px","max-width":"100%"}
            }, null, 8 /* PROPS */, _hoisted_1))
          : (_openBlock(), _createBlock($setup["ElIcon"], { key: 1 }, {
              default: _withCtx(() => [
                _createVNode($setup["Picture"])
              ]),
              _: 1 /* STABLE */
            }))
      ], 64 /* STABLE_FRAGMENT */))
    : ($setup.options && $setup.value)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: $setup.value,
          title: $setup.title,
          style: {"max-width":"100%"}
        }, null, 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true)
}