import {define} from 'tianjy_workspace';

import vue2renderer from '../vue2renderer';
import createWidgetDialog from '../createWidgetDialog';

import View from './View.vue';
import Config from './Config.vue';

define(
	'Richtext',
	vue2renderer(View),
	createWidgetDialog(Config, '富文本'),
	{
		title: '富文本',
	},
);
