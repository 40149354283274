import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36f24568"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar" }
const _hoisted_2 = { class: "dataSelectStr" }
const _hoisted_3 = { class: "refresh" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["ElSelect"], {
      class: "dataSelect company-select",
      modelValue: $props.subCompany,
      size: $props.size,
      onChange: $setup.subCompanyChange,
      filterable: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.subCompanies, (item) => {
          return (_openBlock(), _createBlock($setup["ElOption"], {
            key: item.name,
            label: item.company_name,
            value: item.name
          }, null, 8 /* PROPS */, ["label", "value"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "size"]),
    _createVNode($setup["ElSelect"], {
      class: "dataSelectMultiple",
      modelValue: $props.designations,
      size: $props.size,
      multiple: "",
      onChange: $setup.designationChange,
      collapseTags: "",
      collapseTagsTooltip: $props.size==='small',
      filterable: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.designationList, (item) => {
          return (_openBlock(), _createBlock($setup["ElOption"], {
            key: item.name,
            label: item.job_title,
            value: item.name
          }, null, 8 /* PROPS */, ["label", "value"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "size", "collapseTagsTooltip"]),
    _createVNode($setup["ElDatePicker"], {
      class: "dataSelect",
      modelValue: $setup.startDatePicker,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.startDatePicker) = $event)),
      onChange: $setup.startDateChange,
      clearable: false,
      type: "date",
      size: $props.size,
      placeholder: $setup.tt('Start Date'),
      disabledDate: $setup.disabledDateStart,
      valueFormat: "YYYY-MM-DD"
    }, null, 8 /* PROPS */, ["modelValue", "size", "placeholder"]),
    _createElementVNode("span", _hoisted_2, _toDisplayString($setup.tt('to')), 1 /* TEXT */),
    _createVNode($setup["ElDatePicker"], {
      class: "dataSelect",
      modelValue: $setup.endDatePicker,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.endDatePicker) = $event)),
      onChange: $setup.endDateChange,
      clearable: "",
      type: "date",
      size: $props.size,
      placeholder: $setup.tt('End Date'),
      disabledDate: $setup.disabledDate,
      valueFormat: "YYYY-MM-DD"
    }, null, 8 /* PROPS */, ["modelValue", "size", "placeholder"]),
    _createVNode($setup["ElTooltip"], {
      content: $setup.tt('Log Details'),
      class: "calendar"
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElButton"], {
          class: "calendarButton",
          icon: $setup.Calendar,
          size: "small",
          circle: "",
          onClick: $setup.navigateToRecordStatistics
        }, null, 8 /* PROPS */, ["icon"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["content"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode($setup["ElTooltip"], {
        content: $setup.tt('Log Details'),
        class: "calendarPhone"
      }, {
        default: _withCtx(() => [
          _createVNode($setup["ElButton"], {
            class: "calendarButtonPhone",
            icon: $setup.Calendar,
            size: "default",
            circle: "",
            onClick: $setup.navigateToRecordStatistics
          }, null, 8 /* PROPS */, ["icon"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["content"]),
      _createVNode($setup["ElButtonGroup"], {
        size: $props.size,
        class: "ml-4"
      }, {
        default: _withCtx(() => [
          _createVNode($setup["ElButton"], {
            loading: $props.loading,
            onClick: $setup.exportTable
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('Export')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["loading"]),
          _createVNode($setup["ElButton"], {
            loading: $props.loading,
            onClick: $setup.refresh,
            icon: $setup.RefreshRight
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('Refresh')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["loading", "icon"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["size"])
    ])
  ]))
}