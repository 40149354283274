import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_WorkspaceWidget = _resolveComponent("WorkspaceWidget")

  return (_openBlock(), _createBlock(_component_WorkspaceWidget, null, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString($setup.tt('This is the title')), 1 /* TEXT */)
    ]),
    subtitle: _withCtx(() => [
      _createTextVNode(_toDisplayString($setup.tt('This is a subheading')), 1 /* TEXT */)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, _toDisplayString($setup.v), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }))
}