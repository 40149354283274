import { isRef } from '@vue/composition-api';
import Vue from 'vue';
function getProps(d?: any) {
	if (!d) { return {} }
	const props: Record<string, any> = {};
	for (const [k,v] of Object.entries(d)) {
		props[k] = isRef(v) ? v.value : v;
	}
	return props;
}
export default function createVue<T extends Record<never,any>>(Component: any,{
	props: propsData, provide, events
}: {
	props?: any, provide?: any, events?: Record<string, any>;
} = {}): Vue & T {
	return new Vue({ provide, render(h) {
		const props = getProps(propsData);
		return h(Component, { props, on: events });
	} }) as Vue & T;
}


// import {createApp, provide, h, isRef} from 'vue';
// export default function createVue(Component: any, {
// 	props: propsData, provide: provideData, events
// }: {
// 	props?: any, provide?: any, events?: Record<string, any>;
// } = {}) {
// 	const C = createApp({
// 		setup() {
// 			if (provideData) {
// 				for (const [k,v] of Object.entries(provideData)) {
// 					provide(k, v);
// 				}
// 			}
// 			return () => h(Component, getProps(propsData))
// 		}
// 	});
// 	C.component('ViewPage', ViewPage);
// 	C.component('MenuBtn', MenuBtn);
// 	return C
// }
