import G6, { Item } from '@antv/g6';
import insertCss from 'insert-css';

export function createMenu(
	onEdit:(item:Item)=>void,
	onRemove:(item:Item)=>void,
	onRemoveConnection:(item:Item)=>void,
	onAddChildren:(item:Item)=>void
	){
	insertCss(`
		#g6_contextMenu {
			list-style-type: none;
			padding: 10px 8px;
			background-color: rgba(255, 255, 255, 0.9);
			border: 1px solid #e2e2e2;
			border-radius: 4px;
			font-size: 12px;
			color: #545454;
			margin-bottom:0;
		}
		#g6_contextMenu li {
			cursor: pointer;
			list-style-type:none;
			list-style: none;
			padding:4px 0;
		}
		#g6_contextMenu li:hover {
			background: #409eff1a;
		}
	`);
	const contextMenu = new G6.Menu({
		getContent(evt) {
			if (!evt){ return ''; }
			const {item}=evt;
			const noParent = !item?._cfg?.parent?._cfg||item._cfg.parent._cfg.id==='graph_root';
			const deleteConnection = noParent?'':`<li code='removeConnection'>删除关联关系</li>`;
			return `
				<ul id='g6_contextMenu'>
					<li code='edit'>编辑</li>
					<li code='addChildren'>添加子节点</li>
					${deleteConnection}
				</ul>`;
		},
		handleMenuClick: (target, item) => {
		  const code = target.getAttribute('code');
		  switch (code){
			case 'edit': onEdit?.(item); break;
			case 'removeConnection': onRemoveConnection?.(item); break;
			case 'remove': onRemove(item); break;
			case 'addChildren': onAddChildren(item); break;
		  }
		},
		// offsetX and offsetY include the padding of the parent container
		// 需要加上父级容器的 padding-left 16 与自身偏移量 10
		offsetX: 16,
		// 需要加上父级容器的 padding-top 24 、画布兄弟元素高度、与自身偏移量 10
		offsetY: 0,
		// the types of items that allow the menu show up
		// 在哪些类型的元素上响应
		itemTypes: ['node'],
	  });
	return contextMenu;
}
