<template>
	<Page>
		<template #title>
			<h3 class="title">{{ tt('Dw Worklist Clipping Enable') }}</h3>
		</template>
		<template #tools>
			<Tools
				:smallMeta="smallMeta"
				:levels="levels"
				:processes="processes"
				:showApplyButton="showApplyButton"
				:hasPermission="hasPermission"
				v-model:organizationValue="organizationValue"
				v-model:levelValue="levelValue"
				v-model:layoutType="layoutType"
				v-model:showOnlyApplicable="showOnlyApplicable"
				@toggleGroupsState="toggleGroupsState"
				@openDetail="openDetail"
				@applyWorklist="applyWorklist"
			/>
		</template>
		<Skeleton
			v-loading="loading"
			:layout="layoutType"
			:doctype="currentDoctype"
			:detailName="detailName"
			@onChange="onChange"
			v-model:form-visible="formVisible"
		>
			<Table
				ref="aggrid"
				class="table-container"
				:smallMeta="smallMeta"
				:dataList="dataList"
				:loading="loading"
				:hasPermission="hasPermission"
				:processes="processes"
				v-model:layoutType="layoutType"
				@openDetail="openDetail"
				@changeApplyStatus="changeApplyStatus"
				@cancelUse="cancelUse"
				@deleteWorklist="deleteWorklist"
			></Table>
		</Skeleton>
	</Page>
</template>

<script setup lang="ts">
import {computed, onMounted, onUnmounted, ref, shallowRef, watch} from 'vue';
import {ElMessage, vLoading} from 'element-plus';

import Page from '../../../../../../../guigu/guigu/public/js/components/page/index.vue';
import {useMetaQuery} from '../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';
import Skeleton from '../../../component/Skeleton/index.vue';
import type {process} from '../../../types';
import {agGridTree} from '../../../utils/index';
import {mergeData} from '../dw_organizational_unit_work_list/components/helper';

import Table from './components/Table.vue';
import Tools from './components/Tools.vue';
import type {Worklist, WorkListData} from './type.ts';
import {setData} from './components/helper';

interface ApiResponse {
	success: boolean;
	message?: string;
	organization_work_list?:any[]
}

const smallMeta = useMetaQuery();
const tt = __;

const levelValue = ref<string>('');
const layoutType = ref<'modal' | 'link' | 'bottom' | 'right'>('right');
const aggrid = ref<InstanceType<typeof Table> | null>(null);
const organizationValue = ref<string>(frappe.tianjy.organization.getCurrent() || '');
const formVisible = ref<boolean>(false);
const detailName = ref<string>('');
const showOnlyApplicable = ref<boolean>(false);
const loading = ref<boolean>(false);
const showApplyButton = ref<boolean>(false);
const processes = ref<process[]>([]);
const sourceData = ref<Worklist[]>([]);
const dataList = shallowRef<Worklist[]>([]);
const hasPermission = ref<boolean>(false);
const currentDoctype = ref<string>('');

const levels = computed(() => dataList.value.map(item => item.path.length).reduce((max, length) => Math.max(max, length), 0) - 1);

/**
 * 展开或收起
 */
function toggleGroupsState(state: boolean) {
	if (state) {
		// 展开全部
		aggrid.value?.gridApi?.expandAll();
	} else {
		// 收起全部
		aggrid.value?.gridApi?.collapseAll();
	}
}


function openDetail(name: string, isProcess: boolean) {
	if (isProcess) {
		currentDoctype.value = 'Dw Process';
	} else {
		currentDoctype.value = 'Dw Standard Worklist';
	}
	if (layoutType.value === 'link') {
		if (isProcess) {
			frappe.set_route('Form', 'Dw Process', name);
		} else {
			frappe.set_route('Form', 'Dw Standard Worklist', name);
		}
		return;
	}
	detailName.value = name;
	formVisible.value = true;
}
async function getProcess() {
	if (!organizationValue.value) {
		return;
	}
	const results = await frappe.call<{message:process[]}>({
		method: 'dw_worklist_management.dw_worklist_management.doctype.dw_standard_worklist.dw_standard_worklist.get_organization_process_list',
		args: {
			organization: organizationValue.value,
		},
	});
	processes.value = results?.message || [];
}
watch(organizationValue, async ()=>{
	getProcess();
}, {immediate: true});

function onChange(returnData: any) {
	const {type, data} = returnData;
	switch (type) {
		case 'delete': {
			const preData = dataList.value.find(item => item.name === data.name);
			const preDataIndex = dataList.value.findIndex(item => item.name === data.name);
			dataList.value.splice(preDataIndex, 1);
			aggrid.value?.gridApi?.applyTransaction({remove: [preData]});
			break;
		}
		case 'create': {
			const parent = dataList.value.find(item => item.name === data.work_process);
			const path = parent ? [...(parent.path || []), data.name].filter(Boolean) : [data.name];
			const newData = setData(data, path, false);
			dataList.value.push(newData);
			aggrid.value?.gridApi?.applyTransaction({add: [newData]});
			break;
		}
		case 'update': {
			const preData = dataList.value.find(item => item.name === data.name);
			if (preData?.is_process) {
				// 本级编码改变 或者 上级流程改变
				const isSameCode = data?.full_process_code === preData?.full_process_code;
				const isSameParent = data?.parent_dw_process === preData?.parent_dw_process;
				if (!isSameCode || !isSameParent) {
					getProcess();
					getTableData();
					return;
				}
				const parent = dataList.value.find(item => item.name === data.parent_dw_process);
				const path = parent ? [...(parent.path || []), data.name] : [data.name];
				const newData = setData(data, path, true);
				const preDataIndex = dataList.value.findIndex(item => item.name === data.name);
				dataList.value.splice(preDataIndex, 1, newData);
				aggrid.value?.gridApi?.applyTransaction({update: [newData]});
				aggrid.value?.gridApi?.getRowNode(data.name)?.setData(newData);
			} else {
				// 编辑工作清单，直接更新工作清单
				const parent = dataList.value.find(item => item.name === data.work_process);
				const path = parent ? [...(parent.path || []), data.name] : [data.name];
				const newData = setData(data, path, false);
				const preDataIndex = dataList.value.findIndex(item => item.name === data.name);
				dataList.value.splice(preDataIndex, 1, newData);
				aggrid.value?.gridApi?.applyTransaction({update: [newData]});
				aggrid.value?.gridApi?.getRowNode(data.name)?.setData(newData);
			}
			break;
		}
	}
}

async function getTableData() {
	loading.value = true;
	const res = await frappe.call<{ message: WorkListData }>({
		method: 'dw_worklist_management.dw_worklist_management.doctype.dw_standard_worklist.dw_standard_worklist.get_worklist',
		args: {
			organization: organizationValue.value,
			process: levelValue.value || '',
		},
	});
	if (res?.message) {
		const list = mergeData(res.message);
		dataList.value = agGridTree(list, 'parent_dw_process', 'name') || [];
		sourceData.value = agGridTree(list, 'parent_dw_process', 'name') || [];
		if (showOnlyApplicable.value) {
			getOrganizationWorklist();
		}
		loading.value = false;
	}
}

async function getOrganizationWorklist() {
	const newDataList = sourceData.value.filter(sr => sr.is_process || sr.applicable);
	dataList.value = newDataList;
}

watch(() => levelValue.value, () => {
	if (showOnlyApplicable.value) {
		getOrganizationWorklist();
	} else {
		getTableData();
	}
});

// 改变使用按钮可见状态
function changeApplyStatus(value: boolean) {
	showApplyButton.value = value;
}


async function applyWorklist() {
	const selectRows = aggrid.value?.gridApi?.getSelectedRows() || [];
	const worklist = selectRows.filter(row=>!row.is_process).map(row=>({
		name: row.name,
		responsible_role: row.responsible_role,
	}));
	const res = await frappe.call<{ message: ApiResponse; } >({
		method: 'dw_worklist_management.dw_worklist_management.doctype.dw_organization_worklist.dw_organization_worklist.application_worklist',
		args: {
			organization: organizationValue.value,
			worklist,
		},
	});
	if (res?.message) {
		const tableData = dataList.value;
		if (res.message.success) {
			ElMessage({
				message: __('Execution Succeeded'),
				type: 'success',
			});
			for (const oldData of dataList.value) {
				const exist = worklist.some(item => item.name === oldData.name);
				const newOrganizationWorklist = res.message.organization_work_list?.find(item=>item.dw_standard_worklist === oldData.name);
				if (exist) {
					oldData.applicable = 1;
					oldData.org_worklist_name = newOrganizationWorklist?.name;
				}
			}
		}
		dataList.value = Array.from(tableData);
		// 执行完成后清空当前选中行
		aggrid.value?.clearSelectRows();
		setTimeout(() => {
			aggrid.value?.gridApi?.expandAll();
		}, 0);
	}
}

async function cancelUse(cancelData: Worklist | undefined) {
	if (cancelData?.org_worklist_name) {
		const res = await frappe.db.set_value(
			'Dw Organization Worklist',
			cancelData?.org_worklist_name,
			{
				applicable: 0,
				adjustment_instruction: cancelData.adjustment_instruction,
			},
		);
		if (res?.message) {
			const preData = dataList.value.find(item=>item.name === cancelData.name);
			if (preData) {
				preData.applicable = 0;
				preData.adjustment_instruction = cancelData.adjustment_instruction;
				aggrid.value?.gridApi?.applyTransaction({update: [preData]});
				aggrid.value?.gridApi?.getRowNode(preData.name)?.setData(preData);
			}

			ElMessage({
				message: __('Execution Succeeded'),
				type: 'success',
			});
			if (showOnlyApplicable.value) {
				getOrganizationWorklist();
			}
			setTimeout(() => {
				aggrid.value?.gridApi?.expandAll();
			}, 0);
			return true;
		}
	}
	return false;
}

async function deleteWorklist(data: Worklist) {
	// 删除
	await frappe.db.delete_doc(
		'Dw Standard Worklist',
		data.name,
	);
}

async function isManager() {
	const managers = await frappe.db.get_list('Dw Organization Worklist Member', {
		filters: {organization_unit: organizationValue.value},
		fields: ['user'],
		limit: 0,
	});
	return managers.some(item => item.user === frappe.session.user);
}

async function getPermission() {
	if (frappe.session.user === 'Administrator' || frappe.user.has_role('System Manager')) {
		hasPermission.value = true;
		return;
	}
	hasPermission.value = await isManager();
}
const popstateListener = function () {
	getPermission();
	getTableData();
};

onMounted(() => {
	getPermission();
	getTableData();
	window.addEventListener('popstate', popstateListener);
});
onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});

watch(
	() => window.location.search,
	() => {
		const params = new URLSearchParams(window.location.search);
		if (params.get('organization')) {
			organizationValue.value = params.get('organization') || frappe.tianjy.organization.getCurrent();
		}
		if (params.get('layout')) {
			layoutType.value = params.get('layout') as ('modal' | 'link' | 'bottom' | 'right') || 'right';
		}
		if (params.get('showOnlyApplicable')) {
			showOnlyApplicable.value = params.get('showOnlyApplicable') === '1';
		}
	},
	{immediate: true},
);
watch([organizationValue, layoutType], ()=> {
	const params = new URLSearchParams(window.location.search);
	params.set('organization', organizationValue.value);
	params.set('layout', layoutType.value);
	history.replaceState(null, '', `?${params.toString()}`);
}, {immediate: true});
watch(showOnlyApplicable, val => {
	const params = new URLSearchParams(window.location.search);
	params.set('showOnlyApplicable', val ? '1' : '0');
	history.replaceState(null, '', `?${params.toString()}`);
	if (val) {
		getOrganizationWorklist();
		return;
	}
	dataList.value = sourceData.value;
});
</script>

<style lang="less" scoped>
  .title {
    height: 75px;
    line-height: 75px;
    margin-bottom: 0;
  }
  .table-container {
    height: 100%;
	overflow: hidden;
  }
  .organization-and-filter {
	display: flex;
	flex-direction: row;
	padding: 8px 0;
  }
</style>
