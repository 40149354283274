import { openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5cadf47"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "photo-item-container" }
const _hoisted_2 = { class: "image" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "photo-info" }
const _hoisted_5 = { class: "photo-part-container" }
const _hoisted_6 = { class: "photo-Button" }
const _hoisted_7 = { class: "delete-icon" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")
  const _component_ElPopconfirm = _resolveComponent("ElPopconfirm")
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")
  const _component_ElDropdown = _resolveComponent("ElDropdown")
  const _component_el_tree_select = _resolveComponent("el-tree-select")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_ElTooltip = _resolveComponent("ElTooltip")
  const _component_ElSpace = _resolveComponent("ElSpace")
  const _component_ElInput = _resolveComponent("ElInput")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock("img", {
        src: $setup.photo.url,
        key: $setup.photo.url
      }, null, 8 /* PROPS */, _hoisted_3)),
      _createVNode(_component_ElDropdown, { class: "dropdown-container" }, {
        dropdown: _withCtx(() => [
          _createVNode(_component_el_dropdown_menu, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_dropdown_item, { onClick: $setup.download }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.tt('Download')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_el_dropdown_item, { onClick: $setup.showPhotoInfo }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.tt('Details')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_el_dropdown_item, { class: "delete" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_ElPopconfirm, {
                      title: $setup.tt('Are you sure you want to delete this picture?'),
                      onConfirm: $setup.deletePhoto,
                      "confirm-button-text": $setup.tt('Confirm'),
                      "cancel-button-text": $setup.tt('Cancel')
                    }, {
                      reference: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.tt('Delete')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["title", "confirm-button-text", "cancel-button-text"])
                  ])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            class: "iconBall",
            type: "primary",
            icon: $setup.MoreFilled,
            circle: ""
          }, null, 8 /* PROPS */, ["icon"])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("label", null, _toDisplayString($setup.tt('Structure')) + ":", 1 /* TEXT */),
        _withDirectives(_createVNode(_component_el_tree_select, {
          "default-expand-all": "",
          class: "photo-part",
          modelValue: $setup.photo.target,
          "onUpdate:modelValue": $setup.onTargetChange,
          data: $setup.treeStructure,
          "render-after-expand": true,
          "filter-node-method": $setup.filterNodeMethod,
          props: $setup.defaultProps,
          filterable: "",
          "check-strictly": ""
        }, null, 8 /* PROPS */, ["modelValue", "data"]), [
          [_vShow, $setup.editPart]
        ]),
        _withDirectives(_createElementVNode("span", {
          class: "photo-part par-dev",
          onClick: _cache[0] || (_cache[0] = $event => ($setup.editPart=true))
        }, _toDisplayString($setup.photo.partString), 513 /* TEXT, NEED_PATCH */), [
          [_vShow, !$setup.editPart]
        ]),
        _createElementVNode("span", _hoisted_6, [
          _createVNode(_component_ElSpace, null, {
            default: _withCtx(() => [
              _createVNode(_component_ElTooltip, {
                content: $setup.tt('Download')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, {
                    onClick: $setup.download,
                    style: {cursor: 'pointer'}
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["Download"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["content"]),
              _createElementVNode("div", null, [
                _createVNode(_component_ElPopconfirm, {
                  title: $setup.tt('Are you sure you want to delete this picture?'),
                  onConfirm: $setup.deletePhoto,
                  "confirm-button-text": $setup.tt('Confirm'),
                  "cancel-button-text": $setup.tt('Cancel')
                }, {
                  reference: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_ElTooltip, {
                        content: $setup.tt('Delete')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_icon, { style: { color: '#ff4d4f', cursor: 'pointer' } }, {
                            default: _withCtx(() => [
                              _createVNode($setup["Delete"])
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["content"])
                    ])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["title", "confirm-button-text", "cancel-button-text"])
              ])
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("label", null, _toDisplayString($setup.tt('Simple description')) + ":", 1 /* TEXT */),
        _createVNode(_component_ElInput, {
          modelValue: $setup.photo.description,
          "onUpdate:modelValue": $setup.onDescriptionChange,
          type: "textarea"
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_ElInput, {
          modelValue: $setup.photo.fileName,
          "onUpdate:modelValue": $setup.onFileNameChange
        }, null, 8 /* PROPS */, ["modelValue"])
      ])
    ])
  ]))
}