<template>
	<Page>
		<template #title>
			<h3 class="title">{{ tt('Project assessment scoring page') }}</h3>
		</template>
		<template #tools>
			<ToolBar
				v-model:project="project"
				v-model:monthDate="monthDate"
				v-model:yearDate="yearDate"
				v-model:pageType="pageType"
				v-model:refreshValue="refreshValue"
				v-model:loading="loading"
				v-model:detailType="detailType"
				:detailGridApi="detailGridApi">
			</ToolBar>
		</template>
		<template #sider>
			<OrganizationTree
				:organizations="organizations"
				:organizationLoading="organizationLoading"
				v-model:organization="organization">
			</OrganizationTree>
		</template>
		<Skeleton :loading="loading" ref="skeleton"
			:layout="detailType"
			:hasDetail="recordValue">
			<DetailTable
				v-if="pageType === 'detail'"
				:loading="loading"
				:project="project"
				:detailTableData="detailTableData"
				:layout="detailType"
				@updateRecordValue="updateRecordValue"
				@onDetailGridReady="onDetailGridReady">
			</DetailTable>
			<template #detail>
				<FormDetail v-if="recordValue"
					:meta="docMeta"
					options="{}"
					v-model:value="recordValue" />
			</template>
		</Skeleton>

		<SummaryTable
			v-if="pageType === 'summary'"
			:year="yearDate"
			:loading="loading"
			:project="project"
			:summaryTableData="summaryTableData">
		</SummaryTable>
	</Page>
</template>

<script lang="ts" setup>
import {ref, watch, shallowRef} from 'vue';
import moment from 'moment';
import {GridApi} from 'ag-grid-community';
import {onMounted} from 'vue';

import Skeleton from '../../../components/DefaultFormDetail/Detail/index.vue';
import Page from '../../../components/page/index.vue';
import NP from '../../../../lib/number-precision/index.umd.js';
import {DetailTableData, SummaryMessage, SummaryTableData} from '../type';
import {Organization} from '../type';
import FormDetail from '../../../components/DefaultFormDetail/FormDetail/index.vue';

import OrganizationTree from './components/OrganizationTree.vue';
import ToolBar from './components/ToolBar.vue';
import DetailTable from './components/DetailTable.vue';
import SummaryTable from './components/SummaryTable.vue';

const tt = __;
const project = ref<string>('');
const organization = ref<string>('');
const monthDate = ref<string>();
const yearDate = ref<string>();
const pageType = ref<string>('detail');
const refreshValue = ref<number>(0);
const organizations = ref<Organization[]>([]);
const detailType = ref<string>('modal');

const detailTableData = ref<DetailTableData[]>([]);
const summaryTableData = ref<SummaryTableData[]>([]);
const detailGridApi = ref<GridApi>();

const loading = ref<boolean>(false);
const organizationLoading = ref<boolean>(false);

const docMeta = ref();
const recordValue = shallowRef();

function updateRecordValue(value: any) {
	recordValue.value = value;
}

function onDetailGridReady(params: any) {
	detailGridApi.value = params;
}

watch(() => project?.value, async () => {
	if (project?.value) {
		organizationLoading.value = true;
		const organizationsRes: Organization[] = await frappe.db.get_list(
			'Guigu Project Organization',
			{
				filters: {project: project.value, stop_using: 0},
				fields: ['name', 'parent_guigu_project_organization', 'organization_name'],
				limit: '',
			},
		);
		const resultOrganization: Organization[] = [];
		const organizationNames: string[] = [];
		for (const item of (organizationsRes || [])) {
			organizationNames.push(item.name);
			resultOrganization.push({
				id: item.name,
				name: item.name,
				parent_guigu_project_organization: item.parent_guigu_project_organization,
				label: item.organization_name,
				parent: item.parent_guigu_project_organization,
			});
		}
		organizations.value = resultOrganization;
		organizationLoading.value = false;
	}
});

watch([project, pageType, monthDate, organization, refreshValue], async () => {
	if (pageType.value !== 'detail') {
		return;
	}
	if (!organization.value) {
		return;
	}
	if (!monthDate.value || !project.value) {
		return;
	}
	loading.value = true;
	const data = await frappe.call({
		method: 'guigu_pm.guigu_pm_project_work.page.project_work_assessment.project_work_assessment.get_project_work_assessment',
		args: {
			project: project.value,
			organization: organization.value,
			month: moment(monthDate.value).format('YYYY-MM'),
		},
	});
	const newData = (data?.message || []).map(item => ({...item, id: `${item.work_name}${item.assessment_name}`}));
	detailTableData.value = newData;
	loading.value = false;
});

watch([project, pageType, yearDate, organization, refreshValue], async () => {
	if (pageType.value !== 'summary') {
		return;
	}
	if (!organization.value) {
		return;
	}
	if (!yearDate.value || !project.value) {
		return;
	}
	loading.value = true;
	const monthArr = [];
	for (let i = 1; i <= 12; i++) {
		if (i < 10) {
			monthArr.push(`${yearDate.value}-0${i}`);
		} else {
			monthArr.push(`${yearDate.value}-${i}`);
		}
	}
	const data = await frappe.call<{ message: SummaryMessage }>({
		method: 'guigu_pm.guigu_pm_project_work.page.project_work_assessment.project_work_assessment.get_summary_user_work_assessment',
		args: {
			project: project.value,
			organization: organization.value,
			month_arr: monthArr,
		},
	});
	const data_obj: { [key: string]: SummaryTableData } = {};
	for (const item of (data?.message || [])) {
		const new_assessment_date = (item.assessment_date || '').substring(0, 7);
		if (data_obj[item.project_user]) {
			data_obj[item.project_user][new_assessment_date] = NP.plus(data_obj[item.project_user][new_assessment_date] || 0, item.leader_assessment || 0);
			data_obj[item.project_user].summary = NP.plus(data_obj[item.project_user]?.summary || 0, item.leader_assessment || 0);
		} else {
			data_obj[item.project_user] = {
				[new_assessment_date]: item.leader_assessment,
				user_name: item.user_name,
				user_id: item.project_user,
				summary: item.leader_assessment || 0,
				id: item.id,
			};
		}
	}
	summaryTableData.value = Object.values(data_obj).sort((item: SummaryTableData) => item.project_user);
	loading.value = false;
});

async function init(docType: string) {
	frappe.model.with_doctype(docType, () => {
		const meta = frappe.get_meta(docType);
		const metaFields = meta.fields.map(item => {
			if (item.fieldname !== 'subject') {
				return {...item, in_list_view: 0};
			}
			return item;
		});
		meta.fields = metaFields;
		docMeta.value = meta;
	});
}

onMounted(() => init('Guigu Project Work'));


</script>
<style lang="css" scoped>
.container {
	height: calc(100vh - 60px);
	display: flex;
	flex-direction: column;
}

.title {
	margin-bottom: 0;
}
</style>
