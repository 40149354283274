function toUrl(t: string) {
	try {
		return new URL(t, location.href);
	} catch {
		return;
	}
}
function isSafeUrl(url: URL) {
	if (url.origin === location.origin) { return true; }
	if (url.protocol.toLowerCase() !== 'https:') { return false; }
	if (url.hostname.toLowerCase().endsWith('.guiguyun.cn')) { return true; }
	return false;
}
export default function getRedirectUrl(
	redirect: string,
	state: string,
	appid: string,
	agentId?: string,
) {
	const url = toUrl(redirect);
	if (!url || !isSafeUrl(url)) { return; }
	let p = `${url.origin}${url.pathname}`;
	p += `?appid=${encodeURIComponent(appid)}`;
	if (agentId) {
		p += `&agentid=${encodeURIComponent(agentId)}`;
	}
	p += `&state=${encodeURIComponent(state)}`;
	p += `&state=${encodeURIComponent(state)}`;
	return p;
}
