import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElFormItem"], null, {
    default: _withCtx(() => [
      _createVNode($setup["ElTable"], {
        data: $setup.fields,
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode($setup["ElTableColumn"], {
            type: "index",
            index: 1
          }),
          _createVNode($setup["ElTableColumn"], { type: "expand" }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElRow"], { gutter: 8 }, {
                default: _withCtx(() => [
                  _createVNode($setup["ElCol"], { span: 6 }),
                  _createVNode($setup["ElCol"], { span: 6 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Gantt Percent')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode($setup["ElCol"], { span: 6 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Plan')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode($setup["ElCol"], { span: 6 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Disabled')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode($setup["ElRow"], { gutter: 8 }, {
                default: _withCtx(() => [
                  _createVNode($setup["ElCol"], { span: 6 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Estimation method value')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode($setup["ElCol"], { span: 6 }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ElInput"], {
                        modelValue: row.method_percent || '',
                        "onUpdate:modelValue": $event => ($setup.set(
								$index,
								'method_percent',
								$event || null,
							))
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */),
                  _createVNode($setup["ElCol"], { span: 6 }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ElInput"], {
                        modelValue: row.method_plan || '',
                        "onUpdate:modelValue": $event => ($setup.set(
								$index,
								'method_plan',
								$event || null,
							))
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */),
                  _createVNode($setup["ElCol"], { span: 6 }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ElInput"], {
                        modelValue: row.method_disabled || '',
                        "onUpdate:modelValue": $event => ($setup.set(
								$index,
								'method_disabled',
								$event || null,
							))
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */),
              _createVNode($setup["ElRow"], { gutter: 8 }, {
                default: _withCtx(() => [
                  _createVNode($setup["ElCol"], { span: 6 }),
                  _createVNode($setup["ElCol"], { span: 6 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Percentage estimation')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode($setup["ElCol"], { span: 6 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Plan estimation')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode($setup["ElCol"], { span: 6 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Summary estimate')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode($setup["ElRow"], { gutter: 8 }, {
                default: _withCtx(() => [
                  _createVNode($setup["ElCol"], { span: 6 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Color')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode($setup["ElCol"], { span: 6 }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ElColorPicker"], {
                        modelValue: row.percent_color || '',
                        "onUpdate:modelValue": $event => ($setup.set(
								$index,
								'percent_color',
								$event || null,
							))
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */),
                  _createVNode($setup["ElCol"], { span: 6 }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ElColorPicker"], {
                        modelValue: row.plan_color || '',
                        "onUpdate:modelValue": $event => ($setup.set(
								$index,
								'plan_color',
								$event || null,
							))
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */),
                  _createVNode($setup["ElCol"], { span: 6 }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ElColorPicker"], {
                        modelValue: row.aggregate_color || '',
                        "onUpdate:modelValue": $event => ($setup.set(
								$index,
								'aggregate_color',
								$event || null,
							))
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */),
              _createVNode($setup["ElRow"], { gutter: 8 }, {
                default: _withCtx(() => [
                  _createVNode($setup["ElCol"], { span: 6 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Border Color')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode($setup["ElCol"], { span: 6 }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ElColorPicker"], {
                        modelValue: row.percent_border_color || '',
                        "onUpdate:modelValue": $event => ($setup.set(
								$index,
								'percent_border_color',
								$event || null,
							))
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */),
                  _createVNode($setup["ElCol"], { span: 6 }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ElColorPicker"], {
                        modelValue: row.plan_border_color || '',
                        "onUpdate:modelValue": $event => ($setup.set(
								$index,
								'plan_border_color',
								$event || null,
							))
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */),
                  _createVNode($setup["ElCol"], { span: 6 }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ElColorPicker"], {
                        modelValue: row.aggregate_border_color || '',
                        "onUpdate:modelValue": $event => ($setup.set(
								$index,
								'aggregate_border_color',
								$event || null,
							))
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Estimation method field')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElSelect"], {
                modelValue: row.method,
                filterable: "",
                defaultFirstOption: "",
                "onUpdate:modelValue": $event => ($setup.set($index, 'method', $event)),
                clearable: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.methodFields, (field) => {
                    return (_openBlock(), _createBlock($setup["ElOption"], {
                      key: field.value,
                      value: field.value,
                      label: field.label
                    }, null, 8 /* PROPS */, ["value", "label"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Default estimation method')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElSelect"], {
                modelValue: row.method_default,
                filterable: "",
                defaultFirstOption: "",
                "onUpdate:modelValue": $event => ($setup.set($index, 'method_default', $event))
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["ElOption"], {
                    value: "Percent",
                    label: $setup.tt('Gantt Percent')
                  }, null, 8 /* PROPS */, ["label"]),
                  _createVNode($setup["ElOption"], {
                    value: "Plan",
                    label: $setup.tt('Plan')
                  }, null, 8 /* PROPS */, ["label"]),
                  _createVNode($setup["ElOption"], {
                    value: "Disabled",
                    label: $setup.tt('Disabled')
                  }, null, 8 /* PROPS */, ["label"])
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Start Date')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElSelect"], {
                modelValue: row.start,
                filterable: "",
                defaultFirstOption: "",
                "onUpdate:modelValue": $event => ($setup.set($index, 'start', $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dateFields, (field) => {
                    return (_openBlock(), _createBlock($setup["ElOption"], {
                      key: field.value,
                      value: field.value,
                      label: field.label
                    }, null, 8 /* PROPS */, ["value", "label"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('End Date')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElSelect"], {
                modelValue: row.end,
                filterable: "",
                defaultFirstOption: "",
                "onUpdate:modelValue": $event => ($setup.set($index, 'end', $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dateFields, (field) => {
                    return (_openBlock(), _createBlock($setup["ElOption"], {
                      key: field.value,
                      value: field.value,
                      label: field.label
                    }, null, 8 /* PROPS */, ["value", "label"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Gantt Percent')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElSelect"], {
                modelValue: row.percent,
                filterable: "",
                defaultFirstOption: "",
                "onUpdate:modelValue": $event => ($setup.set($index, 'percent', $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.percentFields, (field) => {
                    return (_openBlock(), _createBlock($setup["ElOption"], {
                      key: field.value,
                      value: field.value,
                      label: field.label
                    }, null, 8 /* PROPS */, ["value", "label"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Expected Completion Date')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElSelect"], {
                modelValue: row.plan,
                filterable: "",
                defaultFirstOption: "",
                "onUpdate:modelValue": $event => ($setup.set($index, 'plan', $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dateFields, (field) => {
                    return (_openBlock(), _createBlock($setup["ElOption"], {
                      key: field.value,
                      value: field.value,
                      label: field.label
                    }, null, 8 /* PROPS */, ["value", "label"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Position')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElInput"], {
                type: "number",
                modelValue: row.position || '',
                "onUpdate:modelValue": $event => ($setup.setWidth($index, 'position', $event || 0))
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Width')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElInput"], {
                type: "number",
                modelValue: row.width || '',
                "onUpdate:modelValue": $event => ($setup.setWidth($index, 'width', $event || 0))
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Operation')
          }, {
            default: _withCtx(({ $index }) => [
              _createVNode($setup["ElButtonGroup"], null, {
                default: _withCtx(() => [
                  _createVNode($setup["ElButton"], {
                    onClick: $event => ($setup.moveTo($index)),
                    text: "",
                    icon: $setup.Sort,
                    title: $setup.tt('Move')
                  }, null, 8 /* PROPS */, ["onClick", "icon", "title"]),
                  _createVNode($setup["ElButton"], {
                    type: "danger",
                    onClick: $event => ($setup.remove($index)),
                    text: "",
                    icon: $setup.Delete,
                    title: $setup.tt('Delete')
                  }, null, 8 /* PROPS */, ["onClick", "icon", "title"])
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["data"]),
      _createVNode($setup["ElButton"], { onClick: $setup.add }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.tt('Add')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}