<template lang="html">
	<WorkspaceWidget v-loading="loading || false">
		<template #title>
			{{ label }}
		</template>
		<template #button>
			<Expander :loading="loading" />
			<ElButton @click="refresh" :title="tt('Refresh')" :disabled="loading" circle
				:icon="RefreshRight" />
		</template>
		<!-- 主区域栏 -->
		<component :is="comp" class="ag-theme-guigu ag"
			:style="style"
			:rowHeight="rowHeight" :meta="meta" :data="data"
			:loading="loading" :detail="detail" :configuration="view" />
	</WorkspaceWidget>
</template>
<script setup lang="ts">
import {computed, onActivated, onDeactivated, shallowRef, toRef, watch} from 'vue';
import {ElButton} from 'element-plus';
import {RefreshRight} from '@element-plus/icons-vue';

import Expander from './Expander';
import useMain from './useMain';
import useLimit from './useLimit';
import type {ViewInfo} from './types';
import loadView from './loadView';
import getFilters from './getFilters';
import List from './List.vue';
import Tree from './Tree.vue';
import useStyle from './useStyle';
const tt = __;
defineOptions({name: 'GlobalView'});

const props = defineProps<{
	meta: locals.DocType;
	cfg: any;
	label: string;
}>();

const docMeta = toRef(props, 'meta');
const view = computed<ViewInfo>(() => loadView(props.cfg, docMeta.value));
const comp = computed(() => view.value.tree ? Tree : List);

const queryFilters = computed(() => getFilters(view.value.filters.map(([
	doctype, field, condition, value,
]) => ({
	doctype, field, condition, value,
}))));


const domVisible = shallowRef(false);
onActivated(() => {
	domVisible.value = true;
});
onDeactivated(() => {
	domVisible.value = false;
});


/******** 查询数据 ********/
/** 排序 */
const sort = computed(() => {
	const meta = docMeta.value;
	const {name: doctype} = meta;
	const sort = view.value.order.map(a => ({
		field: a.field,
		doctype: a.doctype || doctype,
		desc: a.desc || false,
	}));
	if (sort.length) {
		return sort;
	}
	const {sort_field, sort_order} = meta;
	return [{
		doctype,
		field: sort_field || 'modified',
		desc: sort_order?.toUpperCase() === 'DESC',
	}];
});
const infiniteScroll = computed(() => view.value.infiniteScroll);
/** 每页条数 */
const limit = useLimit(Infinity, Infinity);
/** 第几页 */
const page = shallowRef<number>(1);
const {
	data, total, loading: queryLoading, refresh, nextPage, reRequest,
} = useMain(
	docMeta,
	view,
	queryFilters,
	sort,
	infiniteScroll,
	limit,
	page,
);
const loading = computed(() => queryLoading.value);
watch([sort, queryFilters], reRequest);

/******** 详情区域 ********/
const detail = computed(() => false);

/******** 数据状态 ********/
const value = shallowRef<any>();
watch(detail, s => {
	if (s) {
		return;
	} value.value = undefined;
});


/******** 自动刷新 ********/
const updated = shallowRef(false);

watch(() => domVisible.value && updated.value, v => {
	if (!v) {
		return;
	}
	updated.value = false;
	refresh();
});

frappe.realtime.on('list_update', p => {
	if (p.doctype !== docMeta.value.name) {
		return;
	}
	updated.value = true;
});

/******** 其他 ********/
const {rowHeight, style} = useStyle(view.value.style);

</script>
<style scoped lang="less">
.pagination {
	display: flex;
	flex-direction: row;
	align-items: center;
}


.ag {
	:deep(.ag-group-value) {
		flex: 1;
	}

	:deep(.ag-full-width-container) {

		:deep(.ag-group-value) {
			flex: unset;
		}
	}
}
</style>
