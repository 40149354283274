import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-840331f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElSelect"], {
    modelValue: $setup.val,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.val) = $event)),
    filterable: "",
    remote: "",
    key: $setup.key,
    teleported: false,
    remoteMethod: $setup.search,
    placeholder: $props.label && $setup.tt($props.label),
    onFocus: $setup.focus,
    allowCreate: $props.filter,
    defaultFirstOption: "",
    popperClass: $setup.popperClassProject,
    fitInputWidth: $props.fitWidth,
    loading: $setup.loading || $setup.waiting
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.allOptions, (option) => {
        return (_openBlock(), _createBlock($setup["ElOption"], {
          key: option.value,
          label: option.label,
          value: option.value
        }, {
          default: _withCtx(() => [
            ($setup.isProject)
              ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
                  _createElementVNode("strong", null, _toDisplayString(option.label), 1 /* TEXT */),
                  _createElementVNode("span", null, _toDisplayString(option.description), 1 /* TEXT */),
                  _createElementVNode("span", null, _toDisplayString(option.constructionStatus ? $setup.tt(option.constructionStatus) : ''), 1 /* TEXT */)
                ]))
              : _createCommentVNode("v-if", true),
            (!$setup.isProject)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("strong", null, _toDisplayString(option.label), 1 /* TEXT */),
                  _createElementVNode("small", null, _toDisplayString(option.description), 1 /* TEXT */)
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "value"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "placeholder", "allowCreate", "popperClass", "fitInputWidth", "loading"]))
}