import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21d9082d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter_container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString($setup.tt('Dw Process')) + ":", 1 /* TEXT */),
    _createVNode($setup["ElCascader"], {
      popperClass: "worklist-cascader",
      placeholder: $setup.tt('Please Select'),
      options: $setup.options,
      props: $setup.cascaderProps,
      filterable: "",
      clearable: "",
      size: "small",
      modelValue: $setup.selectvalue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.selectvalue) = $event)),
      onChange: $setup.cascaderChange
    }, null, 8 /* PROPS */, ["placeholder", "options", "modelValue"])
  ]))
}