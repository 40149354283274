import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01ba0446"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "required-field" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "required-field" }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "required-field" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElButton = _resolveComponent("ElButton")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ElButton, {
          disabled: $setup.isCreateDisabled,
          type: "primary",
          onClick: $setup.createActual
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('New')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled"])
      ]),
      _createVNode($setup["ProgressActualTable"], {
        progressActual: $setup.progressActual,
        enableAudit: $setup.enableAudit,
        onActualChange: $setup.actualChange,
        onDeleteActual: $setup.deleteActual,
        onEditActual: $setup.editActual
      }, null, 8 /* PROPS */, ["progressActual", "enableAudit"])
    ], 512 /* NEED_PATCH */), [
      [_vShow, $setup.showTable]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createElementVNode("ul", null, [
        (!$setup.project)
          ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
              _createTextVNode(_toDisplayString($setup.tt('Please Select')), 1 /* TEXT */),
              _createElementVNode("span", _hoisted_3, _toDisplayString($setup.tt('Project')), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true),
        (!$setup.structureName)
          ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
              _createTextVNode(_toDisplayString($setup.tt('Please Select')), 1 /* TEXT */),
              _createElementVNode("span", _hoisted_5, _toDisplayString($setup.tt('Construction Structure')), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true),
        (!$setup.date)
          ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
              _createTextVNode(_toDisplayString($setup.tt('Please Select')), 1 /* TEXT */),
              _createElementVNode("span", _hoisted_7, _toDisplayString($setup.tt('Date')), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true),
        _createTextVNode(" " + _toDisplayString($setup.tt('to create an progress')), 1 /* TEXT */)
      ])
    ], 512 /* NEED_PATCH */), [
      [_vShow, !$setup.showTable]
    ])
  ], 64 /* STABLE_FRAGMENT */))
}