import FormBuilder from './FormBuilder';

frappe.provide('frappe.ui');
let ImportPreview;
Object.defineProperty(frappe.ui, 'FormBuilder', {
	configurable: true,
	get() {
		return FormBuilder;
	},
	set(v) {
	},
});
