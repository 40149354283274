<template>
	<AgGridVue
		class="ag-theme-guigu assess-table"
		:rowData="assessList"
		:defaultColDef="defaultColDef"
		animateRows="true"
		@grid-ready="onGridReady"
		@cellValueChanged="cellValueChanged"
		:suppressDragLeaveHidesColumns="smallMeta"
		:localeText="zhCN"
		rowGroupPanelShow="always"
		@cellEditingStarted="cellEditingStarted"
		:getRowId="getRowId"
	>
	</AgGridVue>
	<el-alert :title="prompt" type="warning" show-icon :closable="false" />
</template>
<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { AgGridVue } from 'ag-grid-vue3';
import type { CellEditingStartedEvent } from 'ag-grid-community';

import * as Permission from '../../Permission';
import type { Assess, Level, Permissions } from '../type';
import { useMetaQuery } from '../../../../../../../../guigu_pm/guigu_pm/public/js/components/page/useMetaQuery';
import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';

import { getColumns } from './helper';

interface Props {
	assessList: Assess[];
	permission: Permission.DocPermission | null;
	month: string;
	type: string;
	department: string;
	lockStatus: boolean;
}
const props = defineProps<Props>();
interface Emit {
	(e: 'updateLevel', data: Assess, level: Level): void;
}
const emit = defineEmits<Emit>();
const prompt = __(
	'Tip: If there are no records of relevant members in the list, it indicates that the member has not created any assessment records in the corresponding month',
);
const smallMeta = useMetaQuery();
const gridApi = ref<any>(null);

const onGridReady = (params: any) => {
	gridApi.value = params.api;
};

function gotoDetail(data: Assess) {
	if (!props.month || !props.department || !props.type) {
		return;
	}
	frappe.router.push_state(
		`guigu_hr_assessment_detail?user=${data.name}&month=${props.month}&department=${props.department}&type=${props.type}&organization=${data?.organization_id}`,
	);
}
function gotoInterview(data: Assess) {
	if (!props.month || !props.department || !props.type) {
		return;
	}
	frappe.router.push_state(
		`guigu_hr_assessment_interview?user=${data.name}&date=${props.month}&department=${props.department}&type=${props.type}&period_type=month&organization=${data?.organization_id}`,
	);
}
watch(
	[smallMeta, gridApi, () => props.permission],
	([smallMeta, api, permission]) => {
		api?.setColumnDefs(
			getColumns(gotoDetail, gotoInterview, permission, smallMeta),
		);
	},
);
const defaultColDef = {
	sortable: true,
	filter: true,
	flex: 1,
	resizable: true,
	minWidth: 150,
	suppressMovable: true,
};

async function cellValueChanged(event: any) {
	const {
		data,
		value,
		colDef: { field },
	} = event;
	emit('updateLevel', data, value);
}
function getRowId(params: any) {
	return params.data.row_name;
}
function cellEditingStarted(event: CellEditingStartedEvent) {
	if (props.lockStatus) {
		event.api.stopEditing();
	}
}
</script>
<style lang="less" scoped>
.assess-table {
	width: 100%;
	height: 93%;
}
.el-alert {
	height: 32px;
}
</style>
