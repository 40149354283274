import { createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"height":"100%","display":"flex","align-items":"center","justify-content":"space-between"} }
const _hoisted_2 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_3 = {
  key: 0,
  class: "core_circle"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode($setup["Icon"], { icon: $setup.icon }, null, 8 /* PROPS */, ["icon"]),
      _createElementVNode("span", {
        style: _normalizeStyle({
				cursor: $setup.isProcess ? '' : 'pointer',
				textDecoration: $setup.isProcess ? '' : 'underline'
			}),
        onClick: $setup.clickLabel
      }, _toDisplayString($setup.fullName), 5 /* TEXT, STYLE */),
      _withDirectives(_createElementVNode("span", { style: {"color":"red"} }, _toDisplayString(`（${$setup.tt('Deactivate')})`), 513 /* TEXT, NEED_PATCH */), [
        [_vShow, $setup.disable]
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      ($setup.isCore)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3))
        : _createCommentVNode("v-if", true),
      ($props.params.hasPermission)
        ? (_openBlock(), _createBlock($setup["ElDropdown"], {
            key: 1,
            onCommand: $setup.command,
            trigger: "click"
          }, {
            dropdown: _withCtx(() => [
              _createVNode($setup["ElDropdownMenu"], null, {
                default: _withCtx(() => [
                  ($setup.isProcess)
                    ? (_openBlock(), _createBlock($setup["ElDropdownItem"], {
                        key: 0,
                        command: "add"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.tt('New Work List')), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true),
                  (!$setup.isProcess && !$setup.disable)
                    ? (_openBlock(), _createBlock($setup["ElDropdownItem"], {
                        key: 1,
                        command: "disable",
                        style: {"color":"red"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.tt('Deactivate')), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true),
                  (!$setup.isProcess && $setup.disable)
                    ? (_openBlock(), _createBlock($setup["ElDropdownItem"], {
                        key: 2,
                        command: "enable"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.tt('Enable')), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            default: _withCtx(() => [
              _createVNode($setup["ElIcon"], { style: {"cursor":"pointer"} }, {
                default: _withCtx(() => [
                  _createVNode($setup["MoreFilled"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}