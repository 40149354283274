<template>
	<a class="disabled"
		@click="click">
		{{ value }}
	</a>
</template>
<script lang="ts" setup>
import { computed } from 'vue';


const props = defineProps<{
	value: any;
	data: Record<string, any>;
	field: GlobalView.FieldRenderOptions;
	meta?: locals.DocType;
	maxLine?: number;
	enableLink?: boolean;
	noData?: boolean;
	inline?: boolean;
	format?: string;
	showTitle?: boolean;
}>();
const emit = defineEmits<{
	(event: 'filter', field: string, operator: string, value: any): void;
}>();
const field = computed(() => props.field);
const value = computed(() => {
	const fieldname = `${field.value.fieldname || ''}.title`;
	if (fieldname) {
		return __(props.data[fieldname] || props.value);
	}
	return __(props.value);
});

function click(e: Event) {
	if (props.enableLink) { return; }
	e.preventDefault();
}
</script>

<style scoped>
.disabled{
	cursor: default;
}
</style>
