<template>
	<ElSelect v-model="val" :placeholder="label && tt(label)" :teleported="false"
		:popperClass="popperClass" :fitInputWidth="fitWidth">
		<ElOption
			v-for="option in opts"
			:key="option.value"
			:label="option.label"
			:value="option.value" />
	</ElSelect>
</template>
<script lang="ts" setup>
import { computed, onUnmounted, watch } from 'vue';
import { ElSelect, ElOption } from 'element-plus';

import getSelectOptions from './getSelectOptions';

const props = defineProps<{
	label?: string;
	name?: string;
	required?: boolean;
	filter?: boolean;
	modelValue?: any;
	fitWidth?: boolean;
	popperClass?: string;
	options?: any;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: any): void;
}>();
const tt = __;

const opts = computed(() => getSelectOptions(props.options));
const values = computed(() => opts.value.map(v => v.value));
function getValue(values: any[], value: any) {
	if (values.includes(value)) {
		return value;
	}
	if (!props.required && (value === '' || value === undefined)) {
		return;
	}
	return values[0];
}

const value = computed(() => props.modelValue);
const val = computed({
	get: () => getValue(values.value, value.value),
	set: v => emit('update:modelValue', values.value.includes(v) ? v : undefined),
});
watch([values, value], ([values, value]) => {
	const newValue = getValue(values, value);
	if (value !== newValue) {
		emit('update:modelValue', newValue);
	}
}, { immediate: true });
</script>
