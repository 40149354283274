import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a99a39ef"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "table-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createBlock($setup["Page"], null, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString($setup.tt('Work Progress Of The Final Project')), 1 /* TEXT */)
    ]),
    tools: _withCtx(() => [
      _createVNode($setup["Tools"], {
        smallMeta: $setup.smallMeta,
        company: $setup.company,
        "onUpdate:company": _cache[0] || (_cache[0] = $event => (($setup.company) = $event)),
        project: $setup.project,
        "onUpdate:project": _cache[1] || (_cache[1] = $event => (($setup.project) = $event)),
        year: $setup.year,
        "onUpdate:year": _cache[2] || (_cache[2] = $event => (($setup.year) = $event)),
        startMonth: $setup.startMonth,
        "onUpdate:startMonth": _cache[3] || (_cache[3] = $event => (($setup.startMonth) = $event)),
        endMonth: $setup.endMonth,
        "onUpdate:endMonth": _cache[4] || (_cache[4] = $event => (($setup.endMonth) = $event))
      }, null, 8 /* PROPS */, ["smallMeta", "company", "project", "year", "startMonth", "endMonth"])
    ]),
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode($setup["Table"], {
          workList: $setup.finishingWorks,
          businessTypes: $setup.businessTypes,
          loading: $setup.loading,
          onBeforeUpdate: _cache[5] || (_cache[5] = $event => ($setup.cellChangeLoading = true)),
          onAfterUpdate: $setup.afterUpdate,
          onRefresh: $setup.getFinishingWork
        }, null, 8 /* PROPS */, ["workList", "businessTypes", "loading"])
      ])), [
        [_directive_loading, $setup.loading || $setup.cellChangeLoading]
      ])
    ]),
    _: 1 /* STABLE */
  }))
}