<template>
	<ElSelect v-model="val" multiple :placeholder="label && tt(label)"
		:teleported="false" :popperClass="popperClass" :fitInputWidth="fitWidth"
	>
		<ElOption
			v-for="option in opts"
			:key="option.value"
			:label="option.label"
			:value="option.value" />
	</ElSelect>
</template>
<script lang="ts" setup>
import { computed, onUnmounted, watch } from 'vue';
import { ElSelect, ElOption } from 'element-plus';

import getSelectOptions from './getSelectOptions';


const props = defineProps<{
	label?: string;
	name: string;
	required?: boolean;
	filter?: boolean;
	modelValue?: any;
	fitWidth?: boolean;
	popperClass?: string;
	options?: any;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: any): void;
}>();
const tt = __;

const opts = computed(() => getSelectOptions(props.options, true));

const values = computed(() => new Set(opts.value.map(v => v.value)));
const value = computed(() => props.modelValue);

function getValue(values: Set<any>, value: any): any[] {

	if (!Array.isArray(value)) {
		return values.has(value) ? [value] : [];
	}
	if (value.findIndex((v: any) => !values.has(v)) < 0) {
		return value;
	}
	return value.filter((v: any) => values.has(v));
}

const val = computed({
	get: () => getValue(values.value, value.value),
	set: v => emit('update:modelValue', v),
});
watch([values, value], ([values, value]) => {
	const newValue = getValue(values, value);
	if (value !== newValue) {
		emit('update:modelValue', newValue);
	}
}, { immediate: true });

</script>
