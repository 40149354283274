<template lang="html">
	<FilterInput
		class="input" size="small" :meta="meta"

		:name="quickFilter.fieldname" :label="quickFilter.label"
		:type="quickFilter.fieldtype" :options="quickFilter.options"
		:condition="quickFilter.condition || '='"
		:fitWidth="fitWidth"

		v-model="value" />
</template>
<script setup lang="ts">
import {computed} from 'vue';

import FilterInput from '../../components/GuiguFilterDialog/Input/index.vue';
import type {Filter} from '../types';

import useMatchMediaMaxWidth738 from './useMatchMediaMaxWidth738.mjs';
const props = defineProps<{
	meta: locals.DocType;
	filters: Filter[];
	quickFilter: {
		fieldtype: string;
		label?: any;
		options?: string | undefined;
		fieldname: string;
		condition: string;
	}
}>();

const emit = defineEmits<{
	(event: 'update:filters', filters: Filter[]): void
}>();
const fitWidth = useMatchMediaMaxWidth738();

const quickFilter = computed(() => props.quickFilter);
const meta = computed(() => props.meta);

const xTypes = new Set(['Check', 'Select']);
const yTypes = new Set(['Select']);
function getFilterFinder() {
	const qf = quickFilter.value;
	const {fieldname: fn, fieldtype} = qf;
	const cond = quickFilter.value.condition || '=';
	const dt = meta.value.name;
	if (!xTypes.has(fieldtype)) {
		return (f: Filter) => f.doctype === dt && f.field === fn && (f.condition || '=') === cond;
	}
	return (f: Filter) =>
		f.doctype === dt && f.field === fn && (f.condition || '=') === cond && f.value;
}
const value = computed({
	get: () => props.filters.find(getFilterFinder())?.value,
	set: value => {
		const {condition, fieldname: field, fieldtype} = quickFilter.value;
		const doctype = meta.value.name;
		const filters = [...props.filters];
		const index = filters.findIndex(getFilterFinder());
		if (yTypes.has(fieldtype) && !value || value === undefined || value === null) {
			if (index < 0) {
				return;
			}
			filters.splice(index, 1);
			emit('update:filters', filters);
			return;
		}
		const key = index < 0 ? filters.length : index;
		filters[key] = {doctype, field, condition, value};
		emit('update:filters', filters);
	},
});


</script>
<style scoped>
.input {
		width: 160px;
	@media screen and (max-width: 738px) {
	width: 100%;
	}
}
</style>
