<template>
	<ElFormItem :label="tt('Number Of Fields Fixed')">
		<ElInputNumber v-model="fixedFieldColumns" />
	</ElFormItem>
	<ElFormItem :label="tt('Line Height')">
		<ElInputNumber v-model="lineHeight" />
	</ElFormItem>
	<ElFormItem :label="tt('Default Expand Level')">
		<ElInputNumber v-model="expandedDefault" mix="0" />
	</ElFormItem>
	<ElFormItem :label="tt('Default Unit')">
		<ElSelect v-model="unitDefault" filterable defaultFirstOption>
			<ElOption value="" :label="tt('Default')" />
			<ElOption v-for="field in unitOptions" :key="field.value"
				:value="field.value || 'none'" :label="field.label" />
		</ElSelect>
	</ElFormItem>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import {ElInputNumber, ElFormItem} from 'element-plus';

import {options as unitOptions} from './useUnit';
const props = defineProps<{
	meta: locals.DocType;
	name?: string;
	type: string;
	modelValue: any;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: GlobalView.View): void;
}>();
const cfg = computed({
	get: () => props.modelValue,
	set: v => emit('update:modelValue', v),
});
const tt = __;

const fixedFieldColumns = computed({
	get: () => cfg.value.fixed_field_columns || 0,
	set: v => {
		cfg.value = {...cfg.value, fixed_field_columns: v || 0};
	},
});
const lineHeight = computed({
	get: () => cfg.value.line_height || 0,
	set: v => {
		cfg.value = {...cfg.value, line_height: v || 0};
	},
});
const expandedDefault = computed({
	get: () => cfg.value.expanded_default || 0,
	set: v => {
		cfg.value = {...cfg.value, expanded_default: v || 0};
	},
});
const unitDefault = computed({
	get: () => cfg.value.unit_default || '',
	set: v => {
		cfg.value = {...cfg.value, unit_default: v || null};
	},
});

</script>
