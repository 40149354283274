<template>
	<div class="member" v-loading="loading">
		<div class="btn-container">
			<ElButton v-if="permissions.createPermission" type="primary" @click="createMember">{{ tt('New personnel added') }}
			</ElButton>
			<ElButton v-if="permissions.createPermission" type="primary" @click="batchCreateMembers">
				{{ tt('Batch adding personnel') }}
			</ElButton>
			<ElButton v-if="permissions.createPermission && selectedMembers.length" type="primary" @click="bulkEditRoles">
				{{ tt('Batch editing') }}
			</ElButton>
			<ElButton v-if="permissions.deletePermission && selectedMembers.length" type="danger" @click="bulkDelete">{{
				tt('Bulk Delete') }}
			</ElButton>
		</div>
		<ElTable cell-class-name="member-cell" @selection-change="handleSelectionChange" :data="memberList" border
			style="width: 100%" height="100%" :empty-text="tt('No Data')">
			<el-table-column type="selection" width="55" />
			<ElTableColumn fixed prop="user_doc.full_name" :label="tt('User')" width="180" />
			<ElTableColumn prop="role" :label="tt('Role')">
				<template #default="scope">
					<span class="role" @click="showPermissions(roleDoc.role)" v-for="roleDoc in scope.row.roles">{{
						tt(roleDoc.role) }}</span>
				</template>
			</ElTableColumn>
			<ElTableColumn prop="visible" :label="tt('Seen')" width="60">
				<template #default="scope">
					{{ scope.row.visible ? tt('Yes') : tt('No') }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="viewable" :label="tt('Viewable')" width="60">
				<template #default="scope">
					{{ scope.row.viewable ? tt('Yes') : tt('No') }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="addible" :label="tt('Addible')" width="60">
				<template #default="scope">
					{{ scope.row.addible ? tt('Yes') : tt('No') }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="editable" :label="tt('Editable')" width="60">
				<template #default="scope">
					{{ scope.row.editable ? tt('Yes') : tt('No') }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="deletable" :label="tt('Deletable')" width="60">
				<template #default="scope">
					{{ scope.row.deletable ? tt('Yes') : tt('No') }}
				</template>
			</ElTableColumn>
			<ElTableColumn prop="manageable" :label="tt('Manageable')" width="60">
				<template #default="scope">
					{{ scope.row.manageable ? tt('Yes') : tt('No') }}
				</template>
			</ElTableColumn>

			<ElTableColumn v-if="
				permissions.writePermission || permissions.deletePermission
			" prop="address" :label="tt('Action')" width="130">
				<template #default="scope">
					<ElButton v-if="permissions.writePermission" type="primary" @click="editMember(scope.row)">{{ tt('Edit') }}
					</ElButton>
					<ElButton v-if="permissions.deletePermission" type="danger" @click="deleteMember(scope.row)">{{ tt('Delete')
						}}</ElButton>
				</template>
			</ElTableColumn>
		</ElTable>
		<BulkMembers v-model:visible="visible" :organization="organization" :memberList="memberList"
			@loading="loading = true">
		</BulkMembers>
		<BulkRoles v-model:visible="roleVisible" :selected="selectedMembers" :organization="organization"
			@loading="loading = true"></BulkRoles>
	</div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue';
import {
	ElMessageBox,
	ElMessage,
	ElButton,
	ElTable,
	ElTableColumn,
	vLoading,
} from 'element-plus';

import type { Member, Permissions } from '../type';

import { showPermissions } from './helper';
import BulkMembers from './BulkMembers.vue';
import edit from './edit';
import BulkRoles from './BulkRoles.vue';
interface Props {
	organization: string;
	permissions: Permissions;
}
const props = defineProps<Props>();
const memberList = ref<Member[]>([]);
const loading = ref<boolean>(false);
const tt = __;
const visible = ref<boolean>(false);
const roleVisible = ref<boolean>(false);
const selectedMembers = ref<Member[]>([]);
watch(
	() => props.organization,
	() => {
		getMembers();
	},
	{ immediate: true },
);

async function getMembers() {
	if (!props.organization) {
		return;
	}
	loading.value = true;
	const res = await frappe.call<{ message: Member[]; }>({
		method: 'tianjy_organization.tianjy_organization.page.tianjy_organization_config.tianjy_organization_config.get_members',
		args: {
			organization_name: props.organization,
			is_inherit: 0,
		},
	});
	memberList.value = res?.message || [];
	loading.value = false;
}

function createMember() {
	const newDoc = frappe.model.make_new_doc_and_get_name(
		'Tianjy Organization Member',
	);
	frappe.model.set_value(
		'Tianjy Organization Member',
		newDoc,
		'organization',
		props.organization,
	);
	frappe.set_route(['form', 'Tianjy Organization Member', newDoc]);
}
function batchCreateMembers() {
	visible.value = true;
}

function editMember(member: Member) {
	frappe.set_route(['form', 'Tianjy Organization Member', member.name]);
}

function bulkDelete() {
	ElMessageBox.confirm(
		__('Are you sure to delete this {} individual?', [selectedMembers.value.length]),
		__('please confirm'),
		{
			confirmButtonText: __('Sure'),
			cancelButtonText: __('Cancel'),
			type: 'warning',
		},
	)
		.then(async () => {
			loading.value = true;
			const deletePromises = selectedMembers.value.map(item => {
				return frappe.db.delete_doc(
					'Tianjy Organization Member',
					item.name,
				);
			});
			await Promise.all(deletePromises);
			getMembers();
			ElMessage({
				type: 'success',
				message: __('Delete Successful'),
			});
		})
		.catch(() => {
			ElMessage({
				type: 'info',
				message: __('Cancel Delete'),
			});
		});
}
function deleteMember(member: Member) {
	ElMessageBox.confirm(__('Are you sure you want to delete this person?'), __('please confirm'), {
		confirmButtonText: __('Sure'),
		cancelButtonText: __('Cancel'),
		type: 'warning',
	})
		.then(async () => {
			loading.value = true;
			await frappe.db.delete_doc(
				'Tianjy Organization Member',
				member.name,
			);
			getMembers();
			ElMessage({
				type: 'success',
				message: __('Delete Successful'),
			});
		})
		.catch(() => {
			ElMessage({
				type: 'info',
				message: __('Cancel Delete'),
			});
		});
}

const handleSelectionChange = (val: Member[]) => {
	selectedMembers.value = val;
};

function bulkEditRoles() {
	roleVisible.value = true;
}
frappe.socketio.doctype_subscribe('Tianjy Organization Member');

frappe.realtime.on('list_update', p => {
	if (
		p.doctype !== 'Tianjy Organization Member' &&
		p.doctype !== 'Tianjy Organization Role'
	) {
		return;
	}
	getMembers();
});

const popstateListener = function (event: any) {
	getMembers();
};
onMounted(() => {
	window.addEventListener('popstate', popstateListener);
});
onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});
</script>

<style lang="less" scoped>
.member {
	height: 100%;
	display: flex;
	flex-direction: column;

	.btn-container {
		text-align: right;
		margin-bottom: 8px;
	}

	.role {
		cursor: pointer;
		margin-right: 4px;

		&:hover {
			text-decoration: underline;
		}
	}

	:deep(.member-cell .cell) {
		overflow: hidden;
		white-space: nowrap;
	}
}
</style>
