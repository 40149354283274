
import type { Group } from '../core';
import type { Ref } from 'vue';
import { onActivated, onDeactivated, ref, watch, unref } from 'vue';
import useDestroyable from './useDestroyable';

export default function useHeader(
	root?: Ref<HTMLElement | undefined | null> | HTMLElement | undefined | null,
	group?: Ref<Group | undefined> | Group | undefined) {
	const paused = ref(false);
	onActivated(() => { paused.value = false; });
	onDeactivated(() => { paused.value = true; });

	const current = useDestroyable(() => {
		const g = unref(group);
		if (!g) { return; }
		const r = unref(root);
		if (!r) { return; }
		return g.createHeader(r);
	});

	watch([current, paused], ([current, paused]) => {
		if (!current) { return; }
		current.paused = paused;
	}, { immediate: true });
	return current
}
