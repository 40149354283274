import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-caaf1f74"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "report-info_label" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, "2. " + _toDisplayString($setup.tt("Construction Description")), 1 /* TEXT */),
    ($props.description)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "description-container",
          innerHTML: $props.description
        }, null, 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    (!$props.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($setup.tt("No Construction Description")), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}