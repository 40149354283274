import { onMounted, ref, watch } from 'vue';

export async function getMeta(doctype:string){
	let local_meta = frappe.get_meta(doctype);
	if (local_meta){
		return local_meta;
	}
	await frappe.model.with_doctype(doctype);
	local_meta = frappe.get_meta(doctype);
	return local_meta||undefined;
}
export function useGetMeta(dfOptions?:string){
	const excelMeta = ref<locals.DocType>();
	const options = ref<string>('');
	const field = ref<string>('');

	watch(()=>dfOptions, async ()=>{
		if (!dfOptions){ return; }
		const [op, f] = dfOptions.split('.');
		options.value = op?.match(/\[([^\]]+)\]/)?.[1]||'';
		field.value = f?.match(/\[([^\]]+)\]/)?.[1]||'';
		if (!options.value){ return; }
		excelMeta.value = await getMeta(options.value);
	}, {immediate:true});
	return {excelMeta, options, field};
}
