import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createBlock($setup["AgGridVue"], {
    class: "ag-theme-guigu week-plan_table",
    columnDefs: $setup.columnDefs,
    rowData: $props.structureDescendants,
    getRowStyle: $setup.getRowStyle,
    defaultColDef: $setup.defaultColDef,
    rowSelection: "multiple",
    animateRows: "true",
    onGridReady: $setup.onGridReady,
    onCellValueChanged: $setup.cellValueChanged,
    onRowDataUpdated: $setup.rowDataUpdated,
    onCellEditingStopped: $setup.cellEditingStopped,
    suppressDragLeaveHidesColumns: $setup.smallMeta,
    localeText: $setup.zhCN,
    getRowId: $setup.getRowId
  }, null, 8 /* PROPS */, ["columnDefs", "rowData", "suppressDragLeaveHidesColumns", "localeText"])), [
    [_directive_loading, $setup.loading]
  ])
}