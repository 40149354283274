import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a0327c9a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sider-container" }
const _hoisted_2 = { class: "btn-container" }
const _hoisted_3 = { class: "user-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($props.permissions.createPermission)
        ? (_openBlock(), _createBlock($setup["ElButton"], {
            key: 0,
            type: "primary",
            onClick: $setup.createUser
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('New personnel')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, [
        _createVNode($setup["ElForm"], {
          class: "filter-form",
          inline: "",
          model: $setup.filterForm
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElFormItem"], {
              label: $setup.tt('Status'),
              clearable: ""
            }, {
              default: _withCtx(() => [
                _createVNode($setup["ElSelect"], {
                  modelValue: $setup.filterForm.enabled,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.filterForm.enabled) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["ElOption"], {
                      key: "1",
                      label: $setup.tt('Active'),
                      value: 1
                    }, null, 8 /* PROPS */, ["label"]),
                    _createVNode($setup["ElOption"], {
                      key: "0",
                      label: $setup.tt('Disable'),
                      value: 0
                    }, null, 8 /* PROPS */, ["label"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode($setup["ElFormItem"], {
              label: $setup.tt('User:')
            }, {
              default: _withCtx(() => [
                _createVNode($setup["ElInput"], {
                  modelValue: $setup.filterForm.full_name,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.filterForm.full_name) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode($setup["ElFormItem"], null, {
              default: _withCtx(() => [
                _createVNode($setup["ElButton"], {
                  type: "primary",
                  onClick: $setup.applyFilter
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($setup.tt('App')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode($setup["ElButton"], { onClick: $setup.clearFilter }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($setup.tt('clear')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model"])
      ]),
      _createVNode($setup["ElTable"], {
        ref: "tableRef",
        data: $setup.userList,
        border: "",
        style: {"width":"100%"},
        height: "100%",
        highlightCurrentRow: "",
        currentRowKey: "name",
        onCurrentChange: $setup.handleCurrentChange,
        "empty-text": $setup.tt('No Data')
      }, {
        default: _withCtx(() => [
          _createVNode($setup["ElTableColumn"], {
            fixed: "",
            prop: "full_name",
            label: $setup.tt('User')
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            prop: "email",
            label: $setup.tt('Email Account'),
            width: "180"
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            prop: "enabled",
            label: $setup.tt('Status'),
            width: "60"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", {
                class: _normalizeClass({ activity: scope.row.enabled })
              }, _toDisplayString(scope.row.enabled ? $setup.tt('Active') : $setup.tt('Disable')), 3 /* TEXT, CLASS */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          ($props.permissions.deletePermission)
            ? (_openBlock(), _createBlock($setup["ElTableColumn"], {
                key: 0,
                prop: "address",
                label: $setup.tt('Actions'),
                width: "80"
              }, {
                default: _withCtx((scope) => [
                  _createVNode($setup["ElButton"], {
                    type: "danger",
                    onClick: $event => ($setup.deleteUser(scope.row))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Delete')), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["data", "empty-text"])
    ])
  ]))
}