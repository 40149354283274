import { createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["ElForm"], { labelPosition: "top" }, {
      default: _withCtx(() => [
        _createVNode($setup["ElFormItem"], {
          label: $setup.tt('DocType'),
          rules: [
					{
						required: true,
						message: '请选择链接',
						trigger: 'blur',
					}
				]
        }, {
          default: _withCtx(() => [
            _createVNode($setup["DoctypeSelect"], {
              modelValue: $setup.doctype,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.doctype) = $event)),
              onLabel: _cache[1] || (_cache[1] = $event => ($setup.doctypeLabel = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElFormItem"], {
          label: $setup.tt('Label'),
          rules: [
									{
										required: true,
										message: '请输入标题',
										trigger: 'blur',
									}
								]
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElInput"], {
              modelValue: $setup.label,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.label) = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        ($setup.meta)
          ? (_openBlock(), _createBlock($setup["ElFormItem"], {
              key: 0,
              label: $setup.tt('Filter')
            }, {
              label: _withCtx(({ label }) => [
                _createTextVNode(_toDisplayString(label) + " ", 1 /* TEXT */),
                _createVNode($setup["ElButton"], {
                  onClick: _cache[3] || (_cache[3] = $event => ($setup.add())),
                  title: "添加",
                  icon: $setup.Plus
                }, null, 8 /* PROPS */, ["icon"])
              ]),
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filters, (filter) => {
                  return (_openBlock(), _createBlock($setup["Item"], {
                    key: filter.key,
                    fields: $setup.fields,
                    meta: $setup.meta,
                    withSession: "",
                    doctype: filter.doctype,
                    "onUpdate:doctype": $event => ((filter.doctype) = $event),
                    field: filter.field,
                    "onUpdate:field": $event => ((filter.field) = $event),
                    condition: filter.condition,
                    "onUpdate:condition": $event => ((filter.condition) = $event),
                    value: filter.value,
                    "onUpdate:value": $event => ((filter.value) = $event),
                    onRemove: $event => ($setup.remove(filter))
                  }, null, 8 /* PROPS */, ["fields", "meta", "doctype", "onUpdate:doctype", "field", "onUpdate:field", "condition", "onUpdate:condition", "value", "onUpdate:value", "onRemove"]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ]))
}