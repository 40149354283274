import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href", "download"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    ($setup.value)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: $setup.href,
          style: {"margin-inline-end":"8px"}
        }, _toDisplayString($props.params.value), 9 /* TEXT, PROPS */, _hoisted_1))
      : _createCommentVNode("v-if", true),
    ($setup.value)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          href: $setup.href,
          download: $setup.name,
          style: {"font-size":"inherit"}
        }, [
          _createVNode($setup["ElIcon"], null, {
            default: _withCtx(() => [
              _createVNode($setup["Download"])
            ]),
            _: 1 /* STABLE */
          })
        ], 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true)
  ]))
}