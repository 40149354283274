<template>
	<div v-loading="loading">
		<Page>
			<template #title>
				<h3 class="title">
					{{ isProject ? tt('Project') : tt('Company')
					}}{{ tt('Monthly Assessment Details') }}
				</h3>
				<span
					class="indicator-pill whitespace-nowrap blue"
					v-if="lockStatus"
				>
					<span>{{ tt('Already Locked') }}</span>
				</span>
			</template>
			<template #sider>
				<Member :users="members" v-model="userValue" />
			</template>
			<template #tools>
				<Header
					:type="type"
					:assessUserPermission="assessUserPermission"
					:assessInfo="assessInfo"
					:detailPermission="detailPermission"
					:extraPermission="extraPermission"
					:assessPermission="assessPermission"
					:smallMeta="smallMeta"
					:lockStatus="lockStatus"
					@updateLevel="updateLevel"
					@update="getAssess"
				></Header>
			</template>
			<DetailHeader
				:type="type"
				:assessUserPermission="assessUserPermission"
				:assessInfo="assessInfo"
				:detailPermission="detailPermission"
				:extraPermission="extraPermission"
				:assessPermission="assessPermission"
				:smallMeta="smallMeta"
				:totalScore="totalScore"
				:lockStatus="lockStatus"
				@updateLevel="updateLevel"
				@update="getAssess"
			/>
			<div v-if="lockStatus">
				<el-alert
					:title="prompt"
					type="warning"
					show-icon
					:closable="false"
				/>
			</div>
			<Detail
				:type="type"
				:detailPermission="detailPermission"
				:extraPermission="extraPermission"
				:assessmentPermission="assessmentPermission"
				:assessUserPermission="assessUserPermission"
				:lockStatus="lockStatus"
				:assessInfo="assessInfo"
				:fixSelectedRows="fixSelectedRows"
				@createExtraScore="createExtraScore"
				@createTempTask="createTempTask"
				@assessmentStandardTemplate="assessmentStandardTemplate"
				@update="getAssess"
				@remove="onRemove"
				@afterCalcScore="score => (totalScore = score)"
			></Detail>
		</Page>
		<CreateTempDialog
			:visible="visible"
			:smallMeta="smallMeta"
			@cancel="visible = false"
			@confirm="confirmCreateTemp"
		></CreateTempDialog>
		<CreateExtraDialog
			:visible="extraVisible"
			:smallMeta="smallMeta"
			@cancel="extraVisible = false"
			@confirm="confirmCreateExtra"
		></CreateExtraDialog>
		<AssessmentTemplateDialog
			:visible="templateVisible"
			:smallMeta="smallMeta"
			:assessInfo="assessInfo"
			:type="props.type"
			:fixSelectedRows="fixSelectedRows"
			@cancel="templateVisible = false"
			@confirm="confirmAssessmentTemplate"
		></AssessmentTemplateDialog>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';

import * as Permission from '../Permission';
import Page from '../../../../../../../guigu_pm/guigu_pm/public/js/components/page/index.vue';
import { useMetaQuery } from '../../../../../../../guigu_pm/guigu_pm/public/js/components/page/useMetaQuery';
import type { Level } from '../assessmentList/type';
import Member from '../Member/index.vue';
import type { Assess } from '../assessmentList/type';

import Header from './components/Header.vue';
import Detail from './components/Detail.vue';
import DetailHeader from './components/DetailHeader.vue';
import CreateTempDialog from './components/CreateTempDialog.vue';
import CreateExtraDialog from './components/CreateExtraDialog.vue';
import AssessmentTemplateDialog from './components/AssessmentTemplateDialog.vue';
import type { AssessDetail, AssessInfo, ExtraScore } from './type';

const tt = __;
const prompt = __(
	'The record has been locked. If you need to modify it, please contact the HR department to unlock it',
);

const props = defineProps<{
	month?: string;
	type: 'project' | 'subCompany' | '';
	department?: string;
	user?: string;
	organization?: string;
}>();
const emit = defineEmits<{
	'update:month': [string?];
	'update:department': [string?];
	'update:user': [string?];
}>();

const members = ref<{ label: string; value: string }[]>([]);
const fixSelectedRows = ref<any>([]);
const userValue = computed({
	get: () => props.user,
	set: u => emit('update:user', u),
});
const commonArgs = computed(() => {
	const { month, department, user, type } = props;
	if (!month) {
		return null;
	}
	if (!department) {
		return null;
	}
	if (!user) {
		return null;
	}
	if (!type) {
		return null;
	}
	return { month, department, user, type };
});
const assessInfo = ref<AssessInfo>();
const loading = ref<boolean>(false);
const visible = ref<boolean>(false);
const extraVisible = ref<boolean>(false);
const templateVisible = ref<boolean>(false);
const smallMeta = useMetaQuery();
const totalScore = ref<string>('');
const lockStatus = ref<boolean>(false);
const isProject = computed(() => props.type === 'project');
const isCurrentUser = computed(() => userValue.value === frappe.user.name);

const extraPermission = Permission.use(() => {
	const department = props.type === 'project' ? 'Project' : 'Company';
	return `Guigu Hr ${department} Month Assess Extra Score`;
});
const detailPermission = Permission.use(() => {
	const department = props.type === 'project' ? 'Project' : 'Company';
	return `Guigu Hr ${department} Month Assess Detail`;
});
const assessPermission = Permission.use(() => {
	const department = props.type === 'project' ? 'Project' : 'Company';
	return `Guigu Hr ${department} Month Assess`;
});

const workDoctype = computed(() => {
	const department = props.type === 'project' ? 'Project' : 'Sub Company';
	return `Guigu ${department} Work`;
});
const assessmentPermission = Permission.use(
	workDoctype,
	() => `${workDoctype.value} Assessment`,
);
const assessUserPermission = computed(() =>
	Permission.ifOwner(assessPermission.value, isCurrentUser.value),
);
async function getLockStatus() {
	const { month, department, type } = props;
	if (!month || !type || !department) {
		return;
	}
	const res = await frappe.call<{ message: boolean }>({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_project_assessment_list.guigu_hr_project_assessment_list.get_lock_status',
		args: { month, type, department },
	});
	lockStatus.value = Boolean(res?.message);
}

async function getAssess() {
	const args = commonArgs.value;
	if (!args) {
		return;
	}
	loading.value = true;
	const res = await frappe.call<{ message: AssessInfo }>({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_assessment_detail.guigu_hr_assessment_detail.get_assessment',
		args: { ...args },
	});
	assessInfo.value = res?.message;
	loading.value = false;
}

async function getAssessListUser() {
	if (
		!props.month ||
		!props.type ||
		!props.department ||
		!props.organization
	) {
		return;
	}
	loading.value = true;
	const res = await frappe.call<{ message: Assess[] }>({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_project_assessment_list.guigu_hr_project_assessment_list.get_assessments_user',
		args: {
			month: props.month,
			type: props.type,
			department: props.department,
			organization: props.organization,
		},
	});
	loading.value = false;
	members.value =
		(res?.message || []).sort((pre, next) =>
			pre.full_name > next.full_name ? 1 : -1,
		) || [];
}

watch(
	[
		() => props.month,
		() => props.type,
		() => props.department,
		() => props.organization,
	],
	() => {
		getAssessListUser();
	},
	{ immediate: true },
);

watch(
	[
		() => props.month,
		() => props.type,
		() => props.department,
		() => props.user,
		() => assessUserPermission?.value?.creatable,
	],
	() => {
		getAssess();
		getLockStatus();
	},
	{ immediate: true },
);

function onRemove(
	data: ExtraScore | AssessDetail,
	scoreType: 'detail' | 'extra',
) {
	ElMessageBox.confirm(
		__('Are you sure to delete {}?', [
			scoreType === 'detail'
				? __('Assessment Records')
				: __('Add and Subtract Sub Items'),
		]),
		__('please confirm'),
		{
			confirmButtonText: __('Sure'),
			cancelButtonText: __('Cancel'),
			type: 'warning',
		},
	)
		.then(async () => {
			loading.value = true;
			let doctype = '';
			if (scoreType === 'detail') {
				doctype = isProject.value
					? 'Guigu Hr Project Month Assess Detail'
					: 'Guigu Hr Company Month Assess Detail';
			} else {
				doctype = isProject.value
					? 'Guigu Hr Project Month Assess Extra Score'
					: 'Guigu Hr Company Month Assess Extra Score';
			}
			await frappe.call({
				method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_assessment_detail.guigu_hr_assessment_detail.delete_score',
				args: {
					doctype,
					name: data.name,
				},
			});
			ElMessage({ type: 'success', message: __('Delete Successful') });
			getAssess();
		})
		.catch(() => {
			ElMessage({ type: 'info', message: __('Cancel Delete') });
		});
}

function createTempTask() {
	visible.value = true;
}
async function confirmCreateTemp(data: {
	subject: string;
	standard: string;
	total_score: number;
	remark: string;
	self_assessment: number;
	leader_assessment: number;
	percent_complete: number;
}) {
	const args = commonArgs.value;
	if (!args) {
		return;
	}
	visible.value = false;
	await frappe.call({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_assessment_detail.guigu_hr_assessment_detail.create_temp_task',
		args: { data, ...args },
	});
	ElMessage({
		type: 'success',
		message: __('Saved Successfully'),
	});
	getAssess();
}
function createExtraScore() {
	extraVisible.value = true;
}
async function confirmCreateExtra(data: any) {
	const args = commonArgs.value;
	if (!args) {
		return;
	}
	extraVisible.value = false;
	await frappe.call({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_assessment_detail.guigu_hr_assessment_detail.create_extra_score',
		args: { data, ...args },
	});
	ElMessage({
		type: 'success',
		message: __('Saved Successfully'),
	});
	getAssess();
}

function assessmentStandardTemplate() {
	templateVisible.value = true;
}
async function confirmAssessmentTemplate() {
	templateVisible.value = false;
	getAssess();
}
async function updateLevel(v: Level) {
	const args = commonArgs.value;
	if (!args) {
		return;
	}
	await frappe.call({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_project_assessment_list.guigu_hr_project_assessment_list.update_level',
		args: {
			...args,
			level: v,
			hr_assess_name: assessInfo.value?.assess_name,
		},
	});
	getAssess();
}
</script>

<style lang="less" scoped>
.title {
	font-size: 1.25rem;
	font-weight: bold;
	margin-right: 15px;
	margin-bottom: 0;
}
.el-alert {
	margin-top: 5px;
	margin-bottom: 10px;
	height: 32px;
}
</style>
