
import type {ColDef, ColGroupDef} from 'ag-grid-community';

import State from './State.vue';
import Priority from './Priority.vue';
import type {TreeToListItem, TreeItemData, Process, WorkList, TreeData, TemplateCreateTask} from './type.ts';
const {isManager: is_manager, isApplier: is_applier} = frappe.dw_worklist_management.permission;
// 权限验证
export async function hasPermission() {
	const organization_unit = frappe.tianjy.organization.getCurrent();
	if (!organization_unit) {
		return false;
	}
	if (frappe.user.has_role(['System Manager', 'DW Work List User'])) {
		return true;
	}
	const [manager, member] = await Promise.all([is_manager(), is_applier(organization_unit)]);
	return manager || member;
}

export const processLevel: string[] = [
	'',
	'Process Level 1',
	'Process Level 2',
	'Process Level 3',
	'Process Level 4',
	'Process Level 5',
	'Process Level 6',
	'Process Level 7',
	'Process Level 8',
	'Process Level 9',
	'Process Level 10',
];
//获取任务列组
export function getTaskColumns(): (ColDef | ColGroupDef)[] {
	const columns: (ColDef | ColGroupDef)[] = [
		{
			headerName: __('Subject'),
			field: 'subject',
			enableRowGroup: false,
			width: 300,
			minWidth: 150,
			cellStyle: ()=>({
				textDecoration: 'underline',
				color: '#589ef8',
				cursor: 'pointer',
			}),
		},
		{
			headerName: __('Work List'),
			field: 'work_list_title',
			enableRowGroup: true,
			width: 300,
			minWidth: 120,
		},
		{
			headerName: __('Task Type'),
			field: 'task_type',
			enableRowGroup: false,
			width: 150,
			minWidth: 100,
		},
		{
			headerName: __('Priority'),
			field: 'priority',
			enableRowGroup: false,
			width: 120,
			minWidth: 80,
			cellRenderer: Priority,
			cellStyle: ()=>({
				display: 'flex',
				alignItems: 'center',
			}),
		},
		{
			headerName: __('Status'),
			field: 'status',
			enableRowGroup: false,
			width: 150,
			minWidth: 80,
			cellRenderer: State,
			cellStyle: ()=>({
				display: 'flex',
				alignItems: 'center',
			}),
		},
		{
			headerName: __('Assignee'),
			field: 'assignee',
			enableRowGroup: false,
			width: 150,
			minWidth: 80,
		},
		{
			headerName: __('Plan Start'),
			field: 'plan_start',
			enableRowGroup: false,
			width: 150,
			minWidth: 100,
		},

		{
			headerName: __('Plan End'),
			field: 'plan_end',
			enableRowGroup: false,
			width: 150,
			minWidth: 100,
		},
		{
			headerName: __('Last Comment'),
			field: 'last_comment',
			enableRowGroup: false,
			width: 400,
			minWidth: 200,
		},
	];
	return columns;
}
/**
 * 构建树结构
 * @param listData
 * @param parentField
 * @param keyField
 * @returns
 */
function list2Tree<T extends TreeToListItem>(listData: T[], parentField: string, keyField = 'name') {
	const treeData: (T & TreeItemData)[] = [];
	const map:Record<string, any> = {};
	for (const item of listData) {
		map[item[keyField]] = item;
	}
	for (const item of listData) {
		const parent = map[item[parentField] || 0];
		if (parent) {
			(parent.children || (parent.children = [])).push(item);
		} else {
			treeData.push(item);
		}
	}
	return treeData;
}
// 合并数据
export function mergeData(process_list:Process[], worklist_list:WorkList[]) {
	// 根据worklist_list的work_process字段，找到process_list中对应的name字段
	const processArr:TreeData[] = process_list.map(item => ({
		name: item.name,
		title: item.title,
		code: item.full_process_code,
		level: item.level,
		parent: item.parent_dw_process,
		type: 'process',
	}));
	const worklistArr:TreeData[] = worklist_list.map(item => ({
		name: item.name,
		title: item.title,
		code: item.full_code,
		parent: item.work_process,
		isCore: item.is_core_work_list,
		type: 'worklist',
		department: item.department,
		department_name: item.department_name,
	}));
	const treeData = list2Tree<(TreeData)>([...processArr, ...worklistArr], 'parent', 'name');
	return treeData;
}
export function addPathAttribute(data: TemplateCreateTask[]): TemplateCreateTask[] {
	// 创建一个映射来存储每个节点及其父节点
	const nodeMap: Record<string, TemplateCreateTask> = {};
	data.map(item => {
		nodeMap[item.name] = item;
	});
	function buildPath(currentNode: TemplateCreateTask, path: string[] = []): void {
		path.push(currentNode.name);
		currentNode.path = [...path];

		// 查找当前节点的子节点
		for (const childNode of Object.values(nodeMap)) {
			if (childNode.parent_dw_task_template === currentNode.name) {
				buildPath(childNode, path);
			}
		}
		// 移除当前节点，以便递归完成后路径正确
		path.pop();
	}
	// 从根节点开始构建路径
	const rootNodes = data.filter(node => !node.parent_dw_task_template);
	for (const rootNode of rootNodes) {
		buildPath(rootNode, []);
	}

	return data;
}
