
<template>
	<Page>
		<template #title>
			<h3 class="title">{{ tt('Main Material Verification And Disposal Statistics') }}</h3>
		</template>
		<template #tools>
			<Tools @updateIndexParams="updateIndexParams"></Tools>
		</template>
		<div v-loading="loading" class="table-container" id="main_material_verification_and_disposal_statistics">
			<Table
				:hotDataList="hotDataList"
				:hotName="hotName"
				:isTotal="isTotal"
				:isShowConsume="isShowConsume"
				:isShowDetail="isShowDetail"
			></Table>
		</div>
	</Page>
</template>

<script setup lang="ts">
import {onMounted, onUnmounted, ref, watch} from 'vue';

import Page from '../../../../../../../guigu/guigu/public/js/components/page/index.vue';

import Table from './components/Table.vue';
import Tools from './components/Tools.vue';
import type {QueryParams, HotData} from './type';

const company = ref<string>('');
const project = ref<string>('');
const month = ref<string>('');
const isTotal = ref<boolean>(true);
const isShowConsume = ref<boolean>(true);
const isShowDetail = ref<boolean>(false);
const loading = ref<boolean>(false);
const hotDataList = ref<HotData[]>([]);
const hotName = ref<string>('');
const tt = __;

/**
 * 接受Tools子组件传参
 * @param data
 * @return 无
 */
function updateIndexParams(data: QueryParams) {
	company.value = data.company;
	project.value = data.project;
	month.value = data.month;
	isTotal.value = data.isTotal;
	isShowConsume.value = data.isShowConsume;
	isShowDetail.value = data.isShowDetail;
	hotName.value = data.hotName;
	getList();
}
/**
 * 显示合计物资
 */
function totalMaterial(data:HotData[]) {
	const source = JSON.parse(JSON.stringify(data));
	// 使用 Map 来存储每个 category 对应的 is_total 记录
	const categoryMap = new Map();
	for (const item of source) {
		if (item.category && !categoryMap.has(item.category)) {
			// 如果当前 category 还没有对应的 is_total 记录，则添加当前记录
			categoryMap.set(item.category, item);
		} else if (item.category && item.is_total) {
			// 如果当前 category 已经有记录，但当前记录是 is_total合计行，则更新为当前记录
			categoryMap.set(item.category, item);
		}
	}
	// 将 Map 中的值转换为数组，并处理每个项
	const hotDataList = Array.from(categoryMap.values()).map(item => {
		const newItem = {...item};
		delete newItem.is_total;
		newItem.is_sum = true;
		//去掉合计字段
		newItem.material_name = newItem.material_name.replace('合计', '');
		return newItem;
	});
	return hotDataList;
}
async function getList() {
	if (!project.value || !company.value || !month.value) {
		return;
	}
	loading.value = true;
	const res = await frappe.call<{ message: HotData[] }>({
		method: 'guigu_material.guigu_material.page.main_material_verification_and_disposal_statistics.main_material_verification_and_disposal_statistics.get_material_data',
		args: {
			company: company.value,
			project: project.value,
			month: month.value,
			is_total: isTotal.value,
		},
	});
	loading.value = false;
	if (isShowDetail.value) {
		if (isShowConsume.value) {
			hotDataList.value = res?.message.filter(item => item.consumption !== 0 || item.theory_consumption !== 0) || [];
		} else {
			hotDataList.value = res?.message || [];
		}
	} else {
		hotDataList.value = totalMaterial(res!.message);
	}
}


// 监视‘显示物资明细’勾选框
watch(isShowDetail, () => {
	getList();
});
const popstateListener = function () {
	getList();
};
onMounted(() => {
	window.addEventListener('popstate', popstateListener);
});
onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});
</script>

<style lang="less" scoped>
  .title {
    height: 75px;
    line-height: 75px;
    margin-bottom: 0;
  }
  .table-container {
    height: 100%;
	overflow: hidden;
  }
</style>
