import { ref, onMounted, onUnmounted, Ref } from 'vue';

export function useMediaDialogQuery(): Ref<string>{
	const dialogWidth = ref<string>('50%');
	let mQuery:MediaQueryList;
	function mediaChange(){
		if (mQuery.matches){
			dialogWidth.value='100%';
		} else {
			dialogWidth.value='50%';
		}
	}
	onMounted(()=>{
		mQuery = window.matchMedia('(max-width: 640px)');
		if (mQuery.matches){
			dialogWidth.value='100%';
		} else {
			dialogWidth.value='50%';
		}
		mQuery.addEventListener('change', mediaChange);
	});
	onUnmounted(()=>{
		mQuery.removeEventListener('change', mediaChange);
	});
	return dialogWidth;
}
