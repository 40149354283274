import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75066e70"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "organization-container" }
const _hoisted_2 = { class: "custom-tree-node" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElTree = _resolveComponent("ElTree")
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.organizationTree && $setup.organizationTree.length > 0)
      ? (_openBlock(), _createBlock(_component_ElTree, {
          key: 0,
          class: "organization-tree",
          data: $setup.organizationTree,
          props: $setup.optionProps,
          nodeKey: "id",
          defaultExpandedKeys: $setup.rootNodeIds,
          expandOnClickNode: false,
          onNodeClick: $setup.nodeClick,
          currentNodeKey: $setup.selectedKey,
          highlightCurrent: ""
        }, {
          default: _withCtx(({ node, data }) => [
            _createElementVNode("span", _hoisted_2, [
              (data.type === 'member' && data.id !== 'my')
                ? (_openBlock(), _createBlock($setup["Avatar"], {
                    key: 0,
                    url: data.user?.user_image,
                    name: data.user?.name,
                    title: data.user?.full_name
                  }, null, 8 /* PROPS */, ["url", "name", "title"]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("span", {
                class: _normalizeClass({ 'bolder': data.id === 'all' || data.id === 'my' })
              }, _toDisplayString(node.label), 3 /* TEXT, CLASS */)
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["data", "defaultExpandedKeys", "currentNodeKey"]))
      : _createCommentVNode("v-if", true)
  ])), [
    [_directive_loading, $props.loading]
  ])
}