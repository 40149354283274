<template>
	<img :src="value">
</template>
<script lang="ts" setup>
import { computed } from 'vue';


const props = defineProps<{
	value: any;
	data: Record<string, any>;
	field: GlobalView.FieldRenderOptions;
	meta?: locals.DocType;
	maxLine?: number;
	enableLink?: boolean;
	noData?: boolean;
	inline?: boolean;
	format?: string;
	showTitle?: boolean;
}>();
const emit = defineEmits<{
	(event: 'filter', field: string, operator: string, value: any): void;
}>();
const field = computed(() => props.field);
const value = computed(() => props.value);
const title = computed(() => {
	if (!props.showTitle) { return; }
	return `${__(field.value.label)}`;
});
function setFilter() {
	emit('filter', props.field.fieldname, '=', props.value);

}
const tt = __;

</script>
