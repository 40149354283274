import { DF } from './type';
import getFilters from './getFilters';

export function getAllFilters(
	doctype:string,
	queries:{
    	filters: any;
	},
	filterLine:any,
	params:Record<string, any>
){
	const defaultFilters = queries?.filters || [];
	const searchFilters = getFilters(
		doctype || '',
		[],
		params,
		filterLine,
	);
	const filters: any[] = [];
	if (Array.isArray(defaultFilters)) {
		filters.push(...defaultFilters);
		filters.push(...searchFilters);
	} else {
		const arrayFilters = Object.entries(defaultFilters).map(item => ({
			[item[0]]: item[1],
		}));
		filters.push(...arrayFilters);
		filters.push(...searchFilters);
	}
	const allFilters = filters.map(filter=>{
		if (Array.isArray(filter)){
			if (filter.length===4){ return filter; }
			if (filter.length===3){ return [doctype, ...filter]; }
		} else {
			return Object.entries(filter).map(i=>[doctype, i[0], ...i[1]]).flat();
		}
	}).filter(Boolean);
	return allFilters;
}
export function createDoc(
	doctypeMeta:locals.DocType,
	treeControl:any,
	df:DF,
	allFilters:any[]
){
	const doctype = doctypeMeta.name;
	if (!doctype) { return; }
	let dfTem = df;
	if (treeControl.frm && treeControl.frm.doctype !== df.parent) {
		// incase of grid use common df set in grid
		dfTem = treeControl.frm.get_docfield(treeControl.doc.parentfield, df.fieldname);
	}
	// set values to fill in the new document
	if (dfTem && dfTem.get_route_options_for_new_doc) {
		frappe.route_options = dfTem.get_route_options_for_new_doc(treeControl);
	} else {
		frappe.route_options = {};
	}

	const list = allFilters
			.filter(v => (v[0] === doctype || !v[0]))
			.filter(v => v[2] === '=')
			.filter(v => v[3] !== undefined);
	let data = (Object.fromEntries(list.map(v => [v[1], v[3]])));
	for (const [k, v] of Object.entries(data)) {
		const df = doctypeMeta.fields.find(v => v.fieldname === k);
		if (!df || df.no_copy) { continue; }
		if (doctypeMeta?.title_field === k){
			// partially entered name field
			frappe.route_options.name_field = v;
			continue;
		}
		let value = v;
		if (df.fieldtype === 'Link' && df.options === 'User') {
			if (v === 'ME') {
				value = frappe.session.user;
			}
		}
		frappe.route_options[k] = value;
	}
	// reference to calling link
	frappe._from_link = treeControl;
	frappe._from_link_scrollY = $(document).scrollTop();
	const routes = [...frappe.get_route()];
	if (routes.length > 2 && routes[0] === 'List') {
		frappe.guigu._backView = () => {
			const route = [frappe.router.slug(routes[1]), 'view', ...routes.slice(2)];
			frappe.set_route(route);
			frappe.guigu._backView = null;
		};
	} else {
		frappe.guigu._backView = null;
	}
	frappe.ui.form.make_quick_entry(doctype, doc => treeControl.set_value(doc.name));

}
