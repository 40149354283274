<template lang="html">
	<ElDialog v-model="visible" :title="`${tt('Edit Group Value')}:${tt(label || field)}`" modal width="min(95%, 400px)">
		<ElForm labelPosition="top" labelWidth="100px" style="max-width: 460px">
			<ElFormItem :label="tt('Content')" v-if="visible && docField">
				<GuiguFieldInput v-model="data.value" listable filter :label="label" :name="field" :type="type" :options="options" />
			</ElFormItem>
			<ElFormItem :label="tt('Title')">
				<ElInput v-model="data.title" />
			</ElFormItem>
			<ElFormItem :label="tt('Text Color')">
				<ElColorPicker v-model="data.color" />
			</ElFormItem>
			<ElFormItem :label="tt('Background Color')">
				<ElColorPicker v-model="data.bgColor" />
			</ElFormItem>
			<ElFormItem :label="tt('Move To New Location')">
				<ElInputNumber v-model="data.index" />
			</ElFormItem>
		</ElForm>
		<template #footer>
			<ElButton @click="del" :title="tt('Delete')" :icon="Delete" />
			<ElButtonGroup>
				<ElButton @click="visible = false" :title="tt('Cancel')" :icon="Close" />
				<ElButton type="primary" @click="submit" :title="tt('Confirm')" :icon="Finished" />
			</ElButtonGroup>
		</template>
	</ElDialog>
</template>
<script setup lang="ts">
  import { computed, ref, watch } from 'vue';
  import { ElInputNumber, ElForm, ElFormItem, ElColorPicker, ElDialog, ElButton, ElButtonGroup, ElInput } from 'element-plus';
  import { Finished, Close, Delete } from '@element-plus/icons-vue';

  import type { Column } from '../types';
  const tt = __;

  const props = defineProps<{
    meta: locals.DocType;
    value?: Column;
    loading: boolean;
    field: string;
    visible: boolean;
  }>();
  const emit = defineEmits<{
    (event: 'update:visible', visible: boolean): void;
    (event: 'update', field: string, value: any): void;
  }>();
  const docField = computed(() => {
    const { field, meta } = props;
    return meta.fields.find((f) => f.fieldname === field) || frappe.model.std_fields.find((f) => f.fieldname === field);
  });
  const options = computed(() => docField.value?.options);
  const type = computed(() => docField.value?.fieldtype);
  const label = computed(() => docField.value?.label);
  const visible = computed({
    get: () => props.visible,
    set: (v) => emit('update:visible', v),
  });

  const data = ref<Column & { index?: number }>({ title: '', value: undefined, color: undefined });
  watch(
    [() => props.value, () => props.visible],
    ([c, v]) => {
      if (!v) {
        return;
      }
      data.value = c ? { ...c } : { title: '', value: '', color: undefined };
    },
    { immediate: true }
  );
  function submit() {
    emit('update', props.field, data.value);
    visible.value = false;
  }
  function del() {
    emit('update', props.field, data.value.value);
    visible.value = false;
  }
</script>
