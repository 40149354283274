// @ts-check
import { ref } from 'vue';
import mountVueToPage from 'guiguLib/mountVueToPage';

import * as components from '../components/install';

/**
 * @param {any} MainComponent
 */
export default function vue2renderer(MainComponent) {
	/**
	 * @param {import('tianjy_workspace').RendererContext<any>} ctx
	 */
	return ctx => {
		/** @type {import ('vue').Ref<any>} */
		const vueValue = ref(ctx.value);
		ctx.listenValue(v => { vueValue.value = v; });
		mountVueToPage(ctx.container, MainComponent, {
			props: {
				value: vueValue,
				editing: ctx.editing,
				onEdit: () => { ctx.edit(); },
			}, provided: {
				__editing__: ctx.editing,
				'__guigu:Workspace:Block__': ctx.block,
				'__guigu:Workspace:onEdit__': () => { ctx.edit(); },
			},
			components,
		});
	};
}
