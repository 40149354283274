import get_dialog_constructor from './get_dialog_constructor';

const BaseWidget = frappe.widget.widget_factory.base


const old = BaseWidget.prototype.edit;
BaseWidget.prototype.edit = function edit() {
	const dialog_class = get_dialog_constructor(this.widget_type);
	this.edit_dialog = new dialog_class({
		label: this.label,
		type: this.widget_type,
		values: this.get_config(),
		primary_action: (data) => {
			Object.assign(this, data);
			data.name = this.name;
			this.new = true;
			this.refresh(data);
			this.options.on_edit && this.options.on_edit(data);
		},
		primary_action_label: __("Save"),
	});

	this.edit_dialog.make();
}
