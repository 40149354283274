<template>
	<div class="photo-item-container">
		<div class="image">
			<img :src="photo.url" :key="photo.url"/>
			<ElDropdown class='dropdown-container'>
				<el-button class="iconBall" type="primary" :icon="MoreFilled" circle />
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item @click="download">{{ tt('Download') }}</el-dropdown-item>
						<el-dropdown-item @click="showPhotoInfo">{{ tt('Details') }}</el-dropdown-item>
						<el-dropdown-item class="delete">
							<div>
								<ElPopconfirm
									:title="tt('Are you sure you want to delete this picture?')"
									@confirm="deletePhoto"
									:confirm-button-text="tt('Confirm')"
									:cancel-button-text="tt('Cancel')"
								>
									<template #reference>
										{{ tt('Delete') }}
									</template>
								</ElPopconfirm>
							</div>
						</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</ElDropdown>
		</div>
		<div class="photo-info">
			<div class="photo-part-container">
				<label>{{ tt('Structure') }}:</label>
				<el-tree-select
					default-expand-all

					v-show="editPart"
					class='photo-part'
					:modelValue="photo.target"
					@update:modelValue="onTargetChange"
					:data="treeStructure"
					:render-after-expand="true"
					:filter-node-method="filterNodeMethod"
					:props="defaultProps"
					filterable
					check-strictly
				/>
				<span
					v-show="!editPart"
					class="photo-part par-dev"
					@click="editPart=true"
				>
					{{photo.partString}}
				</span>
				<span class='photo-Button'>
					<ElSpace>
						<ElTooltip :content="tt('Download')">
							<el-icon @click="download" :style="{cursor: 'pointer'}"><Download /></el-icon>
						</ElTooltip>
						<div>
							<ElPopconfirm
								:title="tt('Are you sure you want to delete this picture?')"
								@confirm="deletePhoto"
								:confirm-button-text="tt('Confirm')"
								:cancel-button-text="tt('Cancel')"
							>
								<template #reference>
									<div class="delete-icon">
										<ElTooltip :content="tt('Delete')">
										<el-icon :style="{ color: '#ff4d4f', cursor: 'pointer' }"><Delete /></el-icon>
										</ElTooltip>
									</div>
								</template>
							</ElPopconfirm>
						</div>
					</ElSpace>
				</span>
			</div>

			<div>
          		<label>{{ tt('Simple description') }}:</label>
				<ElInput
					:modelValue='photo.description'
					@update:modelValue = 'onDescriptionChange'
					type='textarea'
				/>
			</div>
			<div>
				<ElInput

					:modelValue="photo.fileName"
					@update:modelValue = 'onFileNameChange'
				/>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import {defineProps, toRefs, ref, computed} from 'vue';
import { Delete, MoreFilled, Download} from '@element-plus/icons-vue';
import saveAs, { list2Tree, TreeItem } from 'guigu_pm/public/js/utils';

import { Photo, PhotoFormat } from '../../type/image';
import { StructureItemFormat } from '../../type/diary';
const tt = __
interface Props{
	photo:PhotoFormat,
	structureDescendants:StructureItemFormat[]
}
const props = defineProps<Props>();
interface Emits{
	(e:'showPhotoInfo', photo:PhotoFormat):void
	(e:'deletePhoto', photo:PhotoFormat):void
	(e:'changePhoto', photo:Photo):void
}
const emit = defineEmits<Emits>();
const {photo, structureDescendants} = toRefs(props);
const treeStructure = computed(()=>list2Tree<StructureItemFormat>(structureDescendants.value));
const defaultProps = {
	children: 'children',
	label: 'name',
	value:'id',
};
const editPart = ref(false);
function filterNodeMethod(value:string, data:(StructureItemFormat & TreeItem)){
	return data.name.includes(value);
}
function showPhotoInfo(){
	emit('showPhotoInfo', photo.value);
}
function download(){
	if (!photo.value.url){ return; }
	saveAs(photo.value.url, photo.value.fileName!);
}
function deletePhoto(){
	emit('deletePhoto', photo.value);
}

function onTargetChange(value:string){
	type keys = 'guigu_construction_structure_progress'|'guigu_construction_structure'
	let targetKey:keys = 'guigu_construction_structure_progress';
	let clearKey:keys = 'guigu_construction_structure';
	if (value.startsWith('structure_progress_')){
		targetKey = 'guigu_construction_structure_progress';
		clearKey = 'guigu_construction_structure';
	} else {
		targetKey = 'guigu_construction_structure';
		clearKey = 'guigu_construction_structure_progress';
	}
	const targetArr = value.split('_');
	const target = targetArr[targetArr.length-1];
	editPart.value = !editPart.value;
	const newInfo:Photo={
		name:photo.value.id,
		[targetKey]:target,
		[clearKey]:'',
	};
	emit('changePhoto', newInfo);
}
function onDescriptionChange(value:string){
	const newInfo:Photo={
		name:photo.value.id,
		description:value,
	};
	emit('changePhoto', newInfo);
}
function onFileNameChange(value:string){
	const newInfo:Photo={
		name:photo.value.id,
		image_name:value,
	};
	emit('changePhoto', newInfo);
}
</script>
<style lang="less" scoped>
:deep(.delete) {
	color: #ff4d4f
}

.delete-icon {
	height: 13px;
}

.photo-item-container {
	display: flex;
	padding: 20px 0px;
	border-bottom: 1px solid #e8e8e8;
	align-items: center;
}

@media screen and (max-width: 640px) {
	.photo-item-container {
		flex-direction: column;

	}
}

@media screen and (min-width: 640px) {
	.photo-item-container {
		flex-direction: row;
	}
}

.photo-item-container:last-child {
	border-bottom: 0;
}

.photo-info {
	flex: 1;
	padding: 0px 20px;

	label {
		font-weight: bolder;
		margin-right: 8px;
		margin-bottom: 0 !important;
	}

	>div {
		margin-bottom: 8px;
	}

	>div:last-child {
		margin-bottom: 0px;
	}

	.photo-Button {
		display: flex;
		margin-left: 8px;
	}

	.photo-part-container {
		display: flex;
	}

	.photo-part {
		flex: 1;
	}

	.par-dev {
		border: 1px solid #d9d9d9;
	}
}

.image {
	position: relative;
	display: inline-block;
	cursor: pointer;
	width: 100px;
	height: 100px;
	background-color: #f6f6f6;

	>img {
		position: absolute;
		top: 0px;
		right: 0;
		bottom: 0;
		left: 0px;
		max-width: 100px;
		max-height: 100px;
		margin: auto;
	}

	&.inset-shadow::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		box-shadow: 0 0 8px 4px inset;
		content: '';
	}

	.dropdown-container {
		position: absolute;
		top: 6px;
		right: 40px;
		display: block;
		background-color: #91d5ff;
	}

	.iconBall {
		position: absolute;
		width: 32px;
		height: 32px;
		color: white;
		font-size: 16px;
		line-height: 32px;
		text-align: center;
		background-color: #91d5ff;
		border-radius: 16px;
		opacity: 0;
		transform: rotate(-90deg);
	}

	&:hover {
		background-color: #f0f6ff;

		.iconBall {
			opacity: 0.8;

			&:hover {
				background-color: #91d5ff;
				opacity: 1;
			}
		}
	}
}
</style>
