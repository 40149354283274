<template>
	<TimelineItem
		:date="date">
		<PhotoThumbnail
			v-for="photo in photos.list"
			:photo="photo"
			@showPhotoInfo="showPhotoInfo"
			@deletePhoto="deletePhoto"></PhotoThumbnail>
	</TimelineItem>
</template>
<script lang="ts" setup>
import {defineProps, toRefs, defineEmits} from 'vue';

import TimelineItem from '../../../components/timeline/TimelineItem.vue';

import {Photo, TimelineSource} from './type';
import PhotoThumbnail from './PhotoThumbnail.vue';
interface Emits{
	(e:'showPhotoInfo', photo:Photo):void
	(e:'deletePhoto', photo:Photo):void
}
const emit = defineEmits<Emits>();
interface Props{
	photos:TimelineSource,
	date:string,
}
const props = defineProps<Props>();
const {date, photos} = toRefs(props);

function showPhotoInfo(photo:Photo) {
	emit('showPhotoInfo', photo);
}
function deletePhoto(photo:Photo) {
	emit('deletePhoto', photo);
}
</script>
<style lang="less" scoped>
</style>
