<template>
	<span v-if="inline" :title="inlineTitle">
			{{ inlineTitle }}
	</span>
	<div v-else >
		<span v-if="userName" :style="titleStyle" :title="userTitle">
		{{ userName }}({{ time }})：
		</span>
		<span :style="valueStyle" :title="valueTitle">{{ value }}</span>
	</div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import type { StyleValue } from 'vue';

import html2text from '../utils/html2text';


const props = defineProps<{
	value: any;
	data: Record<string, any>;
	field: GlobalView.FieldRenderOptions;
	meta?: locals.DocType;
	maxLine?: number;
	enableLink?: boolean;
	noData?: boolean;
	inline?: boolean;
	format?: string;
	showTitle?: boolean;
}>();
const emit = defineEmits<{
	(event: 'filter', field: string, operator: string, value: any): void;
}>();
const field = computed(() => props.field);

const comment = computed(() => {
	try {
		const { value } = props;
		if (!value) { return; }
		const list = typeof props.value === 'string' ? JSON.parse(props.value) : props.value;
		if (!Array.isArray(list)) {
			return list;
		}
		return list.pop();
	} catch {
		return;
	}
});
const titleStyle = computed<StyleValue | undefined>(() => {
	if (props.inline) { return; }
	return {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	};
});
const valueStyle = computed<StyleValue | undefined>(() => {
	if (props.inline) { return; }
	const { maxLine } = props;
	if (!maxLine) {
		return { display: 'block' };
	}
	return {
		wordBreak: 'break-all',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		WebkitBoxOrient: 'vertical',
		WebkitLineClamp: maxLine,
		overflow: 'hidden',
	};
});
const value = computed(() => html2text(comment.value?.content || ''));
const valueTitle = computed(() => `${value.value}`);
const inlineTitle = computed(() => {
	if (userName.value){
		return `${userName.value}(${time.value}):${value.value}`
	}
	return `${value.value}`
});
const userName = computed(() => {
	const user = comment.value?.comment_by;
	return user || '';
});
const time = computed(() => {
	const creation = comment.value?.creation;
	if (!creation) { return ''; }
	return moment(creation).format('YYYY-MM-DD HH:mm');
});
function setFilter() {
	emit('filter', props.field.fieldname, '=', props.value);
}
const userTitle = computed(() => `${userName.value}(${time.value})`);
const tt = __;

</script>
