import type {App, Component, Ref, ShallowRef} from 'vue';
import {createApp, h, ref, shallowRef} from 'vue';

import ElementPlus from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn';

import ConnectionTable from './index.vue';
import type {ActionButtonVisible, CustomActionButton, CustomButton, MappingSetting} from './type';

frappe.model.no_value_type.push('Connection Table');

frappe.ui.form.ControlConnectionTable = class ControlConnectionTable extends frappe.ui.form.Control {
	df: any;
	value: string = '';
	custom_queries: any;
	df_custom_queries: any;
	button_visible: { link: boolean | '', add: boolean | '', delete: boolean | '' } = {link: '', add: '', delete: ''};
	customButton: {
		position: 'before' | 'after' | 'between'
		index: number
		attr: Record<string, any>
		fun: Function
	}[] = [];
	buttonHiddenRef: Ref<{
		link: boolean | '',
		add: boolean | '',
		delete: boolean | ''
	}>;
	loadIdRef:Ref<number>;
	readonlyRef: Ref<boolean>;
	isNewRef: Ref<boolean>;
	dfRef: Ref<any>;
	isDragEnableRef: Ref<Boolean>;
	customButtonRef: Ref<CustomButton[]>;
	actionButtonHiddenRef: ShallowRef<ActionButtonVisible>;
	customActionButtonRef: Ref<CustomActionButton[]>;
	queriesRef: ShallowRef<{ filters: any } | ''>;
	isHidePaginationRef: ShallowRef<boolean | ''>;
	notSelectRef: ShallowRef<boolean | ''>;
	isDetailUseDialogRef: ShallowRef<boolean|''>;
	deleteTipRef: ShallowRef<string|''>;
	hideAddChildButtonRef:ShallowRef<boolean>;
	columnsRef:ShallowRef<locals.DocField[]>;
	createFieldMapRef:ShallowRef<MappingSetting[]>;
	customComponentsRef: Ref<Record<string, Function | Component>>;
	selectDialogQueriesRef: ShallowRef<any>;
	actionButtonVisible: { 'delete': boolean | '', 'edit': boolean | '', 'unlink': boolean | '' } = {delete: '', edit: '', unlink: ''};
	customComponents: { [fieldname: string]: (rowData: any) => void | Component } = {};
	customActionButton: {
		position: 'before' | 'after'
		index: number
		attr: Record<string, any>|((data: Record<string, any>) => Record<string, any>),
		fun: (data: Record<string, any>) => void
	}[] = [];
	app?: App<Element>;
	dragRequest: ((movingData: any, overData: any) => Promise<boolean>) | undefined;
	afterTableDataChangeFn: ((data: any[]) => void) | undefined;
	afterDeleteFn: ((names: string[]) => void) | undefined;
	make() {
		const loadIdRef = ref(0);
		const readonlyRef = ref(this.disp_status !== 'Write');
		const isDragEnableRef = ref(Boolean(this.dragRequest));
		const isNewRef = ref(this.frm.is_new());
		const dfRef = shallowRef(this.df);
		const buttonHiddenRef = shallowRef(Object.fromEntries(Object.entries(this.button_visible || {})
			.map(([k, v]) => [k, v === '' ? '' : !v])) as any);
		const customButtonRef = ref<CustomButton[]>([]);
		const actionButtonHiddenRef = shallowRef<ActionButtonVisible>({delete: '', edit: '', unlink: ''});
		const customActionButtonRef = ref<CustomActionButton[]>([]);
		const queriesRef = shallowRef<{ filters: any } | ''>('');
		const isHidePaginationRef = shallowRef<boolean | ''>('');
		const notSelectRef = shallowRef<boolean>(false);
		const isDetailUseDialogRef = shallowRef<boolean>(false);
		const deleteTipRef = shallowRef<string>('');
		const hideAddChildButtonRef = shallowRef<boolean>(false);
		const columnsRef = shallowRef<locals.DocField[]>();
		const createFieldMapRef = shallowRef<MappingSetting[]>();
		const customComponentsRef = ref<Record<string, Function | Component>>(this.customComponents || {});

		const selectDialogQueriesRef = shallowRef<any>();
		this.loadIdRef = loadIdRef;
		this.readonlyRef = readonlyRef;
		this.isNewRef = isNewRef;
		this.dfRef = dfRef;
		this.buttonHiddenRef = buttonHiddenRef;
		this.customButtonRef = customButtonRef;
		this.actionButtonHiddenRef = actionButtonHiddenRef;
		this.customActionButtonRef = customActionButtonRef;
		this.isHidePaginationRef = isHidePaginationRef;
		this.notSelectRef = notSelectRef;
		this.isDetailUseDialogRef = isDetailUseDialogRef;
		this.deleteTipRef = deleteTipRef;
		this.hideAddChildButtonRef = hideAddChildButtonRef;
		this.columnsRef = columnsRef;
		this.createFieldMapRef = createFieldMapRef;
		this.customComponentsRef = customComponentsRef;
		this.queriesRef = queriesRef;
		this.selectDialogQueriesRef = selectDialogQueriesRef;
		this.isDragEnableRef = isDragEnableRef;
		super.make();
		const me = this;
		Object.defineProperty(this, 'get_query', {
			get() {
				return me.custom_queries;
			},
			set(val) {
				me.custom_queries = val;
				const args = me.get_custom_queries();
				queriesRef.value = args;
			},
			configurable: true,
		});
		Object.defineProperty(this.df, 'get_query', {
			get() {
				return me.custom_queries;
			},
			set(val) {
				me.df_custom_queries = val;
				const args = me.get_custom_queries();
				queriesRef.value = args;
			},
			configurable: true,
		});
		this.create_connection_table();
	}
	create_connection_table() {
		this.$wrapper.addClass('form-group');
		const onLink = () => {
			const args = this.get_link_queries();
			this.set_vue_props('select_dialog_queries', args);
		};
		const dragRequest = (...p) => this.dragRequest?.(...p);
		const afterTableDataChange = (...p) => {
			this.afterTableDataChangeFn?.(...p);
		};
		const afterDelete = (...p) => {
			this.afterDeleteFn?.(...p);
		};
		const {
			dfRef,
			isNewRef,
			readonlyRef,
			buttonHiddenRef,
			customButtonRef,
			actionButtonHiddenRef,
			customActionButtonRef,
			isHidePaginationRef,
			customComponentsRef,
			queriesRef,
			selectDialogQueriesRef,
			loadIdRef,
			isDragEnableRef,
			notSelectRef,
			isDetailUseDialogRef,
			deleteTipRef,
			hideAddChildButtonRef,
			columnsRef,
			createFieldMapRef,
		} = this;
		const app = createApp({props: [], render: () =>h(ConnectionTable, {
			loadId: loadIdRef.value,
			df: dfRef.value,
			readonly: readonlyRef.value,
			buttonHidden: buttonHiddenRef.value,
			customButton: customButtonRef.value,
			actionButtonHidden: actionButtonHiddenRef.value,
			customActionButton: customActionButtonRef.value,
			isHidePagination: isHidePaginationRef.value,
			notSelect: notSelectRef.value,
			isDetailUseDialog: isDetailUseDialogRef.value,
			deleteTip: deleteTipRef.value,
			hideAddChildButton: hideAddChildButtonRef.value,
			columns: columnsRef.value,
			createFieldMap: createFieldMapRef.value,
			customComponents: customComponentsRef.value,
			queries: queriesRef.value,
			doctype: this.doctype,
			frm: this.frm,
			isNew: isNewRef.value,
			selectDialogQueries: selectDialogQueriesRef.value,
			onLink,
			dragRequest,
			isDragEnable: isDragEnableRef.value,
			afterTableDataChange,
			afterDelete,
		})});
		app.use(ElementPlus, {size: 'small', locale: zhCn});
		app.mount(this.$wrapper?.[0]);
		this.app = app;
		if (this.customButton) {
			for (const item of this.customButton) {
				this.addCustomButton(item.position, item.index, item.attr, item.fun);
			}
		}
		for (const item of Object.entries(this.actionButtonVisible || {})) {
			this.setActionButtonVisible(item[0] as 'delete' | 'unlink' | 'unlink', item[1]);
		}
		if (this.customActionButton) {
			for (const item of this.customActionButton) {
				this.addCustomActionButton(item.position, item.index, item.attr, item.fun);
			}
		}
		if (this.dragRequest) {
			this.onRowDragEnd(this.dragRequest);
		}
		if (this.afterTableDataChangeFn) {
			this.afterTableDataChange(this.afterTableDataChangeFn);
		}
		if (this.afterDeleteFn) {
			this.afterDelete(this.afterDeleteFn);
		}
	}
	get_status(explain) {
		if (this.df.get_status) {
			return this.df.get_status(this);
		}
		if (this.df.is_virtual) {
			return 'Read';
		}

		if (
			(!this.doctype && !this.docname)
			|| this.df.parenttype === 'Web Form'
			|| this.df.is_web_form
		) {
			let status = 'Write';

			// like in case of a dialog box
			if (cint(this.df.hidden)) {
				// eslint-disable-next-line
				if (explain) console.log("By Hidden: None"); // eslint-disable-line no-console
				return 'None';
			} else if (cint(this.df.hidden_due_to_dependency)) {
				// eslint-disable-next-line
				if (explain) console.log("By Hidden Dependency: None"); // eslint-disable-line no-console
				return 'None';
			} else if (
				cint(this.df.read_only || this.df.is_virtual || this.df.fieldtype === 'Read Only')
			) {
				// eslint-disable-next-line
				if (explain) console.log("By Read Only: Read"); // eslint-disable-line no-console
				status = 'Read';
			} else if (
				(this.grid && this.grid.display_status == 'Read')
				|| (this.layout && this.layout.grid && this.layout.grid.display_status == 'Read')
			) {
				// parent grid is read
				if (explain) {
					console.log('By Parent Grid Read-only: Read');
				} // eslint-disable-line no-console
				status = 'Read';
			}

			let value = this.value || this.get_model_value();
			value = this.get_parsed_value(value);

			if (
				status === 'Read'
				&& is_null(value)
				&& !in_list(['HTML', 'Image', 'Button'], this.df.fieldtype)
			) {
				status = 'Read';
			}

			return status;
		}

		let status = frappe.perm.get_field_display_status(
			this.df,
			frappe.model.get_doc(this.doctype, this.docname),
			this.perm || (this.frm && this.frm.perm),
			explain,
		);

		// Match parent grid controls read only status
		if (
			status === 'Write'
			&& (this.grid || (this.layout && this.layout.grid && !cint(this.df.allow_on_submit)))
		) {
			const grid = this.grid || this.layout.grid;
			if (grid.display_status == 'Read') {
				status = 'Read';
				if (explain) {
					console.log('By Parent Grid Read-only: Read');
				} // eslint-disable-line no-console
			}
		}

		let value = frappe.model.get_value(this.doctype, this.docname, this.df.fieldname);

		if (in_list(['Date', 'Datetime'], this.df.fieldtype) && value) {
			value = frappe.datetime.str_to_user(value);
		}

		value = this.get_parsed_value(value);

		// hide if no value
		if (
			this.doctype
			&& status === 'Read'
			&& !this.only_input
			&& is_null(value)
			&& !in_list(['HTML', 'Image', 'Button', 'Geolocation', 'Connection Table'], this.df.fieldtype)
		) {
			// eslint-disable-next-line
			if (explain) console.log("By Hide Read-only, null fields: None"); // eslint-disable-line no-console
			status = 'None';
		}

		return status;
	}

	setButtonVisible(id: 'link' | 'add' | 'delete', visible: boolean | '') {
		const {buttonHiddenRef} = this;
		if (!buttonHiddenRef) {
			return;
		}
		this.button_visible[id] = visible;
		buttonHiddenRef.value = {
			...buttonHiddenRef.value,
			[id]: visible === '' ? '' : !visible,
		};
	}

	addCustomButton(
		position: 'before' | 'after' | 'between',
		index: number,
		attr: string | Record<string, any>,
		fun: any,
	) {
		if (!this.app) {
			return;
		}
		const {customButtonRef} = this;
		if (!customButtonRef) {
			return;
		}
		const btnParams: Record<string, any> = {};
		if (typeof attr === 'string') {
			btnParams.title = attr;
		} else {
			Object.assign(btnParams, attr);
		}
		if (!this.customButton.some(item => item.position === position && item.attr.title === btnParams.title)) {
			this.customButton.push({
				position,
				index,
				attr: btnParams,
				fun,
			});
		}
		if (customButtonRef.value.some(item => item.position === position && item.attr.title === attr.title)) {
			return;
		}
		customButtonRef.value.push({
			position,
			index,
			attr: btnParams,
			fun,
		});
	}
	removeCustomButton(position: 'before' | 'after' | 'between', id: string) {
		if (!this.app) {
			return;
		}
		const {customButtonRef} = this;
		if (!customButtonRef) {
			return;
		}
		this.customButton = this.customButton.filter(item => item.position !== position || item.attr.title !== id);
		customButtonRef.value = customButtonRef.value.filter(item => item.position !== position || item.attr.title !== id);
	}
	setActionButtonVisible(id: 'delete' | 'edit' | 'unlink', visible: boolean | '') {
		if (!this.app) {
			return;
		}
		const {actionButtonHiddenRef} = this;
		if (!actionButtonHiddenRef) {
			return;
		}
		this.actionButtonVisible[id] = visible;
		actionButtonHiddenRef.value = {
			...actionButtonHiddenRef.value,
			[id]: visible === '' ? '' : !visible,
		};
	}
	setCustomComponents(components: Record<string, Function | Component>) {
		this.customComponents = components;
		const {customComponentsRef} = this;
		if (!customComponentsRef) {
			return;
		}
		customComponentsRef.value = components;
	}
	addCustomActionButton(
		position: 'before' | 'after',
		index: number,
		attr: Record<string, any>|((data: Record<string, any>) => Record<string, any>),
		fun: (data: Record<string, any>) => void,
	) {
		if (!this.app) {
			return;
		}
		const {customActionButtonRef} = this;
		if (!customActionButtonRef) {
			return;
		}
		if (!this.customActionButton.some(item => item.position === position && item.index === index)) {
			this.customActionButton.push({
				position,
				index,
				attr,
				fun,
			});
		}
		if (customActionButtonRef.value.some(item => item.position === position && item.index === index)) {
			return;
		}
		customActionButtonRef.value.push({
			position,
			index,
			attr,
			fun,
		});
	}
	removeCustomActionButton(position: 'before' | 'after', id: string) {
		if (!this.app) {
			return;
		}
		const {customActionButtonRef} = this;
		if (!customActionButtonRef) {
			return;
		}
		this.customActionButton = this.customActionButton.filter(item => item.position !== position || item.attr.title !== id);
		customActionButtonRef.value = customActionButtonRef.value.filter(item => item.position !== position || item.attr.title !== id);
	}
	onRowDragEnd(dragRequest: (movingData: any, overData: any) => Promise<boolean>) {
		this.dragRequest = dragRequest;
		this.isDragEnableRef.value = Boolean(dragRequest);
	}
	afterTableDataChange(fn: (data: any[]) => void) {
		this.afterTableDataChangeFn = fn;
	}
	afterDelete(fn: (data: string[]) => void) {
		this.afterDeleteFn = fn;
	}
	hidePagination(hide: boolean) {
		const {isHidePaginationRef} = this;
		if (!isHidePaginationRef) {
			return;
		}
		isHidePaginationRef.value = hide;
	}
	setNotSelect(noSelect:boolean) {
		const {notSelectRef} = this;
		if (!notSelectRef) {
			return;
		}
		notSelectRef.value = noSelect;
	}
	setIsDetailUseDialog(noSelect:boolean) {
		const {isDetailUseDialogRef} = this;
		if (!isDetailUseDialogRef) {
			return;
		}
		isDetailUseDialogRef.value = noSelect;
	}
	setDeleteTip(deleteTip:string) {
		const {deleteTipRef} = this;
		if (!deleteTipRef) {
			return;
		}
		deleteTipRef.value = deleteTip;
	}
	setHideAddChildButton(hideAddChildButton:boolean) {
		const {hideAddChildButtonRef} = this;
		if (!hideAddChildButtonRef) {
			return;
		}
		hideAddChildButtonRef.value = hideAddChildButton;
	}
	setColumns(columns:locals.DocField[]) {
		const {columnsRef} = this;
		if (!columnsRef) {
			return;
		}
		columnsRef.value = columns;
	}
	setCreateFieldMap(map:MappingSetting[]) {
		const {createFieldMapRef} = this;
		if (!createFieldMapRef) {
			return;
		}
		createFieldMapRef.value = map;
	}
	refresh_input() {
		this.dfRef.value = this.df;
		this.isNewRef.value = this.frm.is_new();
		this.readonlyRef.value = this.disp_status !== 'Write';
		this.loadIdRef.value++;
	}
	refresh_connection_table() {
		this.create_connection_table();
	}


	get_value() {
		return this.__value || null;
	}
	set_input(value: string) {
		this.__value = value;
	}

	validate(v?: string) {
		return this.get_value();
	}
	is_valid_value(value: any, key: string) {
		if (value) {
			return true;
		}
		// check if empty value is valid
		if (this.frm) {
			const field = frappe.meta.get_docfield(this.frm.doctype, key);
			// empty value link fields is invalid
			return !field || !['Link', 'Dynamic Link', 'Tianjy Related Link', 'Tree Select'].includes(field.fieldtype);
		}
		return value !== undefined;
	}
	set_nulls(obj: Record<string, any>) {
		$.each(obj, (key, value) => {
			if (!this.is_valid_value(value, key)) {
				delete obj[key];
			}
		});
		return obj;
	}
	get_link_queries() {
		const args = {};
		this.set_custom_query(args, 'select_dialog_filters');
		return args;
	}
	get_custom_queries(): any {
		const args = {};
		this.set_custom_query(args, 'filters');
		return args;
	}
	set_vue_props(propsKey: string, value: any) {
		const {selectDialogQueriesRef} = this;
		if (!selectDialogQueriesRef) {
			return;
		}
		if (propsKey !== 'select_dialog_queries' && propsKey !== 'selectDialogQueries') {
			return;
		}
		selectDialogQueriesRef.value = value;
	}
	set_custom_query(
		args: Record<string, any>,
		filtertype: 'filters' | 'select_dialog_filters',
	) {
		if (this.get_query || this.df.get_query) {
			const get_query = this.get_query || this.df.get_query;
			if ($.isPlainObject(get_query)) {
				let filters = null;
				if (get_query[filtertype]) {
					// passed as {'filters': {'key':'value'}}
					filters = get_query[filtertype];
				} else if (filtertype === 'filters' && get_query.query) {
					// passed as {'query': 'path.to.method'}
					args.query = get_query;
				} else if (filtertype === 'select_dialog_filters' && get_query.link_query) {
					// passed as {'query': 'path.to.method'}
					args.link_query = get_query;
				} else if (filtertype !== 'select_dialog_filters') {
					// dict is filters
					filters = get_query;
				}

				if (filters) {
					filters = this.set_nulls(filters);

					// extend args for custom functions
					$.extend(args, filters);

					// add "filters" for standard query (search.py)
					args.filters = filters;
				}
			} else if (typeof get_query === 'string' && filtertype === 'filters') {
				args.query = get_query;
			} else {
				// get_query by function
				const q = get_query(
					(this.frm && this.frm.doc) || this.doc,
					this.doctype,
					this.docname,
				);

				if (typeof q === 'string' && filtertype === 'filters') {
					// returns a string
					args.query = q;
				} else if ($.isPlainObject(q)) {
					// returns a plain object with filters
					const temp_filters = q[filtertype];
					if (temp_filters) {
						this.set_nulls(temp_filters);
					}

					// turn off value translation
					if (q.translate_values !== undefined) {
						this.translate_values = q.translate_values;
					}

					// extend args for custom functions
					$.extend(args, {filters: temp_filters});
				}
			}
		}
		if (this.df.filters) {
			this.set_nulls(this.df.filters);
			if (!args.filters) {
				args.filters = {};
			}
			$.extend(args.filters, this.df.filters);
		}
	}
};
