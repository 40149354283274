import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.props.params.value)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: _normalizeClass($setup.className),
        "data-filter": $setup.priorityFilter
      }, _toDisplayString($setup.props.params.value), 1 /* TEXT */))
    : _createCommentVNode("v-if", true)
}