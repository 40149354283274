import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "info"
}
const _hoisted_2 = { style: {"margin-top":"20px"} }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElDialog"], {
    modelValue: $setup.visible,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.visible) = $event)),
    title: $setup.tt('Prompt')
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createVNode($setup["ElButton"], {
          onClick: _cache[1] || (_cache[1] = $event => ($setup.visible = false)),
          size: "small"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElButton"], {
          type: "primary",
          size: "small",
          onClick: $setup.inapplicability
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Confirm')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    default: _withCtx(() => [
      ($setup.cData?.is_core_work_list)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", null, _toDisplayString($setup.tt('Are you sure you want to cancel the applicable job list')) + "? " + _toDisplayString($setup.tt('Note the reason for cancelling the core work list')), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_2, [
              _createVNode($setup["ElInput"], {
                modelValue: $setup.cData.adjustment_instruction,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.cData.adjustment_instruction) = $event)),
                rows: 3,
                type: "textarea"
              }, null, 8 /* PROPS */, ["modelValue"])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($setup.tt('Are you sure you want to cancel the applicable job list')) + "? ", 1 /* TEXT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}