export function getWeekDay(date: string) {
	const week = moment(date).format('dddd');

	const days = ['一', '二', '三', '四', '五', '六', '日'];
	const englishDays = [
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
		'Sunday',
	];

	const index = englishDays.indexOf(week);
	if (index !== -1&&frappe.boot.lang === 'zh') {
		return days[index];
	}
	return week;
}

function getCategoryColumns(types: any[]) {
	const attendanceColumns: any[] = [];
	for (const item of types || []) {
		attendanceColumns.push({
			headerName: item.type_name,
			field: item.name,
			colId: item.name,
			width: 80,
			minWidth: 100,
			resizable: true,
		});
	}
	return attendanceColumns;
}

export function getWorkAssessmentSummaryColumns(
	pageType: string,
	date: string,
	organization: string,
	countCategory: string,
	types: any[],
	isPhone: string,
	showDetail: (name: string) => void,
) {
	const commanColumn = [
		{
			headerName: __('Department'),
			field: 'organization_name',
			colId: 'organization_name',
			width: 150,
			minWidth: 100,
			resizable: true,
			pinned: isPhone === 'default' ? '' : 'left',
		},
		{
			headerName: __('Person Name'),
			field: 'record_owner_name',
			colId: 'record_owner_name',
			width: 80,
			minWidth: 100,
			resizable: true,
			pinned: 'left',
		},
	];
	let countColumns = {};
	if (pageType === 'month') {
		const dayCount = moment(date).daysInMonth();
		countColumns = {
			headerName: __('Number Of Logs {}', [dayCount]),
			field: 'diary_count',
			width: 100,
			minWidth: 100,
			resizable: true,
			pinned: isPhone === 'default' ? '' : 'left',
			cellRenderer: 'AgGridAttandanceCount',
			cellRendererParams: (params: any) => {
				const { value } = params;
				return {
					currentCount: value,
					totalCount: dayCount,
				};
			},
		};
	}
	if (pageType === 'year') {
		const startOfYear = moment(`${date}-01-01`, 'YYYY-MM-DD');
		const endOfYear = moment(`${date}-12-31`, 'YYYY-MM-DD');
		const daysInYear = endOfYear.diff(startOfYear, 'days') + 1;
		countColumns = {
			headerName: __('Number Of Logs {}', [daysInYear]),
			field: 'diary_count',
			width: 110,
			minWidth: 110,
			resizable: true,
			pinned: isPhone === 'default' ? '' : 'left',
			cellRenderer: 'AgGridAttandanceCount',
			cellRendererParams: (params: any) => {
				const { value } = params;
				return {
					currentCount: value,
					totalCount: 365,
				};
			},
		};
	}

	if (countCategory === 'category') {
		const categoryColumns = getCategoryColumns(types || []);
		return [...commanColumn, countColumns, ...categoryColumns];
	}
	if (pageType === 'month') {
		const dayCount = moment(date).daysInMonth();
		const dayColumn: any[] = [];
		for (let i = 1; i < dayCount + 1; i++) {
			dayColumn.push({
				headerName: getWeekDay(`${date}-${i < 10 ? `0${i}` : i}`),
				groupId: `week${date}-${i < 10 ? `0${i}` : i}`,
				children: [
					{
						headerName: `${i}`,
						field: `${date}-${i < 10 ? `0${i}` : i}.type_symbol`,
						colId: `${date}-${i < 10 ? `0${i}` : i}`,
						width: 40,
						minWidth: 40,
						resizable: true,
						suppressMenu: false,
						menuTabs: [],
						cellRenderer: 'AgGridAttandanceIcon',
						cellRendererParams: (params: any) => {
							const { value } = params;
							return {
								value: params.data[
									`${date}-${i < 10 ? `0${i}` : i}`
								],
								date: `${date}-${i < 10 ? `0${i}` : i}`,
								organization,
								showDetail,
							};
						},
					},
				],
			});
		}
		return [...commanColumn, countColumns, ...dayColumn];
	}
	const monthColumn: any[] = [];
	for (let i = 1; i < 13; i++) {
		monthColumn.push({
			headerName: __('{}Month', [i]),
			field: `${date}-${i < 10 ? `0${i}` : i}`,
			width: 60,
			minWidth: 60,
			suppressMenu: false,
			menuTabs: [],
			resizable: true,
		});
	}
	return [...commanColumn, countColumns, ...monthColumn];
}
