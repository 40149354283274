import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bf24fae2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = {
  key: 0,
  class: "btn-group"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives((_openBlock(), _createElementBlock("div", null, [
      _createElementVNode("h4", _hoisted_1, _toDisplayString($setup.tt('Instruction Template File')), 1 /* TEXT */),
      _createVNode($setup["AgGridVue"], {
        class: "ag-theme-guigu",
        columnDefs: $setup.columnDefs,
        rowData: $setup.list,
        defaultColDef: $setup.defaultColDef,
        rowSelection: "multiple",
        animateRows: "true",
        onGridReady: $setup.onGridReady,
        groupDefaultExpanded: "-1",
        getRowNodeId: $setup.getRowId,
        domLayout: "autoHeight",
        localeText: $setup.zhCN,
        excludeChildrenWhenTreeDataFiltering: "",
        suppressPaginationPanel: "",
        groupAllowUnbalanced: false,
        groupSelectsFiltered: "",
        suppressDragLeaveHidesColumns: $setup.smallMeta
      }, null, 8 /* PROPS */, ["columnDefs", "rowData", "defaultColDef", "localeText", "suppressDragLeaveHidesColumns"])
    ])), [
      [$setup["vLoading"], $setup.loading]
    ]),
    ($props.editable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode($setup["ElButton"], {
            type: "primary",
            onClick: $setup.add
          }, {
            default: _withCtx(() => [
              _createTextVNode("添加")
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["Dialog"], {
      doctype: $setup.DOCTYPE,
      detailName: $setup.detailValue?.name,
      visible: $setup.formVisible,
      "onUpdate:visible": _cache[0] || (_cache[0] = $event => (($setup.formVisible) = $event)),
      onOnChange: $setup.onChange
    }, null, 8 /* PROPS */, ["detailName", "visible"])
  ], 64 /* STABLE_FRAGMENT */))
}