import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4df8d067"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "title"]
const _hoisted_2 = { class: "field" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_GuiguFieldRenderer = _resolveComponent("GuiguFieldRenderer")

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("a", {
      href: $setup.href,
      title: $setup.title,
      class: "title"
    }, _toDisplayString($setup.title), 9 /* TEXT, PROPS */, _hoisted_1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.fields, (field) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createTextVNode(_toDisplayString(field.showLabel && field.title ? `${field.title}：` : undefined) + " ", 1 /* TEXT */),
        _createVNode(_component_GuiguFieldRenderer, {
          meta: $props.meta,
          data: $props.data,
          value: $props.data[field.name],
          field: field.field
        }, null, 8 /* PROPS */, ["meta", "data", "value", "field"])
      ]))
    }), 256 /* UNKEYED_FRAGMENT */))
  ]))
}