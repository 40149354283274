<template>
	<div class="content page-container">
		<div class="page-head flex">
			<div class="container">
				<div class="row flex align-center page-head-content justify-between">
					<div class="col-md-4 col-sm-6 col-xs-8 page-title">
						<SidebarToggleBtn v-if="hasSidebar" v-model:open="showSidebar" />
						<TitleText :title="title" :subtitle="subtitle" />
					</div>
					<PageActions :secondaryTitle="secondaryTitle" :primaryTitle="primaryTitle"
						@refresh="$emit('refresh')" @primary="$emit('primary')" @secondary="$emit('secondary')">
						<slot name="action" />
						<template #menu>
							<slot name="menu" />
						</template>
					</PageActions>
				</div>
			</div>
		</div>
		<div class="ViewPage-container">
			<div class="ViewPage-clearfix"></div>
			<div class="ViewPage-row">
				<Sidebar v-if="hasSidebar" v-model:open="showSidebar">
					<slot name="sidebar" />
				</Sidebar>
				<div class="ViewPage-wrapper">
					<div class="ViewPage-card">
						<Toolbox>
							<slot name="toolbox" />
						</Toolbox>
						<div class="ViewPage-main">
							<slot />
						</div>
					</div>
					<div class="ViewPage-footer">
						<slot name="footer" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import PageActions from './PageActions.vue'
import TitleText from './TitleText.vue'
import SidebarToggleBtn from './SidebarToggleBtn.vue'
import Sidebar from './Sidebar.vue'
import Toolbox from './Toolbox.vue'
/**
 * @prop primaryTitle 主按钮的标题，设置后将会现实主按钮
 * @prop secondaryTitle 次按钮的标题，设置后将会现实次按钮
 * @prop title 页面标题
 * @prop subtitle 页面副标题
 *
 * @event refresh 点击刷新按钮
 * @event primary 点击主按钮
 * @event secondary 点击次按钮
 *
 * @slot action 工具栏按钮
 * @slot menu 菜单内项目，只能用 <ViewPageMenuBtn> 组件
 * @slot sidebar 侧边栏内的组件
 * @slot default 主体部分
 * @slot footer 底部内容
 *
 * @slot toolbar 工具栏
 */
import { computed, defineComponent, ref } from 'vue'
export default defineComponent({
	props: {
		secondaryTitle: String,
		primaryTitle: String,
		title: String,
		subtitle: String,
	},
	components: {
		TitleText,
		PageActions,
		SidebarToggleBtn,
		Sidebar,
		Toolbox
	},
	setup(p, ctx) {
		const showSidebar = ref(false);
		const hasSidebar = computed(() => ctx.slots.sidebar)
		return { showSidebar, hasSidebar };
	},
})
</script>
<style scoped>
.ViewPage-container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}


@media (min-width: 576px) {
	.ViewPage-container {
		max-width: 540px;
	}
}

@media (min-width: 768px) {
	.ViewPage-container {
		max-width: 840px;
	}
}

@media (min-width: 992px) {
	.ViewPage-container {
		max-width: 1090px;
	}
}

@media (min-width: 1200px) {
	.ViewPage-container {
		max-width: 1290px;
	}

}







.ViewPage-clearfix::after {
	display: block;
	clear: both;
	content: "";
}

.ViewPage-row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}

@media (max-width: 991px) {
	.ViewPage-row {
		position: relative;
	}

}


.ViewPage-wrapper {
	margin-bottom: var(--page-bottom-margin);
	flex-basis: 0;
	flex-grow: 1;
	min-width: 0;
	max-width: 100%;
}

.ViewPage-card {
	border-radius: var(--border-radius-md);
	box-shadow: var(--card-shadow);
	padding: var(--padding-sm);
	background-color: var(--card-bg);
}

@media (max-width: 767px) {
	.ViewPage-card {
		border-left-color: transparent !important;
		border-right-color: transparent !important;
	}

}

.ViewPage-card {
	overflow: hidden;
	border-radius: var(--border-radius-md);
	box-shadow: var(--card-shadow);
	padding: 0px;
	background-color: var(--card-bg);
}


.ViewPage-main {
	padding: 0 var(--padding-xs);
}

.ViewPage-footer {
	display: block;
}
</style>
