<template>
	<ElDropdown @command="exec">
		<ElButton circle title="切换视图">
			<svg class="icon icon-sm">
				<use href="#icon-list" />
			</svg>
		</ElButton>
		<template #dropdown>
			<ElDropdownMenu>
				<ElDropdownItem
					v-for="[name, action, icon, command] in views"
					:command="command" :key="command">
					<span class="menu-item-icon">
						<svg class="icon icon-sm">
							<use :href="'#icon-' + icon" />
						</svg>
					</span>
					{{name}}

				</ElDropdownItem>
			</ElDropdownMenu>
		</template>
	</ElDropdown>

</template>

<script lang="ts" setup>
import { inject } from 'vue';
import { ElDropdown, ElButton, ElDropdownMenu, ElDropdownItem } from 'element-plus';

import getViews from './getViews';

const ctx = (inject<() => frappe.guigu.ViewContext>('__guigu__define__view__')!)();
const currentView = __(frappe.utils.to_title_case(ctx.currentView));
const views = getViews(ctx);
function exec(command: string) {
	const item = views.find(v => v[3] === command);
	if (!item) { return; }
	const [, action] = item;
	action();
}
</script>
