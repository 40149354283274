export function setUrl(
	name: string,
	filters: [string, string, string, any][],
	dt: string
) {
	if (!name) { return; }
	function get_url_with_filters() {
		const query_params = filters
			.map(([doctype, field, con, any]) => {
				const df = doctype && doctype !== dt ? `${doctype}.${field}` : field;
				const value = con === '=' ? any : JSON.stringify([con, any]);
				return `${df}=${encodeURIComponent(value)}`;
			})
			.join('&');
		if (!query_params) { return `?=${name}`; }
		return `?=${encodeURIComponent(name)}&${query_params}`;
	}
	window.history.replaceState(null, '', get_url_with_filters());

}
