import { Filter } from '../types';

const orRegex = /^(\d*):/;
const noRegex = /^(\d*)\*/;
function* parse(dt: string, options: any): Iterable<[string, string, string, any, number?]> {
	for (let [field, value] of Object.entries(options)) {
		if (field === '-' || !field) {
			yield ['', field, '=', value];
			continue;
		}
		let doctype: null | string = null;
		let k = undefined;
		const r = orRegex.exec(field);
		if (r) {
			const [v, n] = r;
			field = field.slice(v.length);
			if (n) {
				k = parseInt(n) || 0;
			} else {
				k = -1;
			}
		}
		field = field.replace(noRegex, '');
		if (field.includes('.')) {
			[doctype, field] = field.split('.');
		}
		doctype ||= frappe.meta.get_doctype_for_field(dt, field);
		if (!doctype) { continue; }

		if (Array.isArray(value) && value[0].startsWith('[') && value[0].endsWith(']')) {
			for (const v of value) {
				const value = JSON.parse(v);
				if (Array.isArray(value)) {
					yield ([doctype, field, value[0], value[1], k]);
				} else {
					yield ([doctype, field, '=', value, k]);
				}
			}
			continue;
		}
		if (typeof value === 'string' && value.startsWith('[') && value.endsWith(']')) {
			value = JSON.parse(value);
		}
		if (Array.isArray(value)) {
			yield ([doctype, field, value[0], value[1], k]);
		} else {
			yield ([doctype, field, '=', value, k]);
		}
	}
}
export default function getRouteOptions(currentDoctype: string) {
	const options = frappe.route_options;
	frappe.route_options = null;
	let name = undefined;
	let filterField: any = undefined;
	const filters: Filter[] = [];
	const orFilters: Filter[][] = [];
	const nullOrFilters: Filter[] = [];
	let has = false;
	for (const [d, f, c, v, k] of options ? parse(currentDoctype, options) : []) {
		if (k === -1) {
			nullOrFilters.push({ doctype: d, field: f, condition: c, value: v });
			continue;
		}
		if (typeof k === 'number') {
			const list = orFilters[k];
			if (Array.isArray(list)) {
				list.push({ doctype: d, field: f, condition: c, value: v });
			} else {
				orFilters[k] = [{ doctype: d, field: f, condition: c, value: v }];
			}
			continue;
		}
		if (!d) {
			if (f === '') {
				if (name === undefined) { name = v; }
				has = true;
			} else if (f === '-') {
				if (filterField === undefined) { filterField = v; }
				has = true;
			}
			continue;
		}
		has = true;
		filters.push({ doctype: d, field: f, condition: c, value: v });

	}

	if (!has) { return; }
	return {
		name,
		filterField,
		filters,
		orFilters: [...nullOrFilters.map(v => [v]), ...orFilters.filter(Boolean)],
	};
}
