import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bfdad8d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sider-container" }
const _hoisted_2 = { class: "btn-container" }
const _hoisted_3 = { class: "tree-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($props.permissions.createPermission)
        ? (_openBlock(), _createBlock($setup["ElButton"], {
            key: 0,
            type: "primary",
            onClick: $setup.createOrganization
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('New Organization')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      ($setup.organizationTree.length > 0)
        ? (_openBlock(), _createBlock($setup["ElTree"], {
            key: 0,
            data: $setup.organizationTree,
            onNodeClick: $setup.handleNodeClick,
            defaultExpandAll: "",
            highlightCurrent: "",
            nodeKey: "name",
            currentNodeKey: $setup.currentNodeKey,
            expandOnClickNode: false,
            draggable: "",
            onNodeDrop: $setup.handleDrop,
            allowDrop: $setup.allowDrop
          }, {
            default: _withCtx(({ node, data }) => [
              _createVNode($setup["TreeItem"], {
                node: node,
                onReload: $setup.getOrganizations
              }, null, 8 /* PROPS */, ["node"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["data", "currentNodeKey"]))
        : _createCommentVNode("v-if", true)
    ])
  ])), [
    [$setup["vLoading"], $setup.loading]
  ])
}