import {createApp} from 'vue';

import TaskTemplateList from './StandardTaskTemplateList.vue';

const renderStandardTaskTemplateList = function (frm: any, html: HTMLElement) {
	if (frm.standard_worklist_task_template) {
		frm.standard_worklist_task_template.unmount();
	}
	const app = createApp(TaskTemplateList, {frm});
	app.mount(html);
	frm.standard_worklist_task_template = app;
};

frappe.provide('frappe.dw_worklist_management.doctype.dw_organization_worklist');
frappe.dw_worklist_management.doctype.dw_organization_worklist
	= frappe.dw_worklist_management.doctype.dw_organization_worklist || {};
frappe.dw_worklist_management.doctype.dw_organization_worklist.renderStandardTaskTemplateList
	= renderStandardTaskTemplateList;
