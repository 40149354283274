frappe.ui.form.ControlComment.prototype.submit = function () {
	this.on_submit && this.on_submit(this.get_value(), this.attachments?.attachments || []);
};

frappe.ui.form.ControlComment.prototype.update_state = function () {
	const value = this.get_value();
	const attachments = this.attachments?.attachments || [];
	if (strip_html(value).trim() != '' || value.includes('img') || attachments.length > 0) {
		this.button.removeClass('btn-default').addClass('btn-primary');
	} else {
		this.button.addClass('btn-default').removeClass('btn-primary');
	}
};
