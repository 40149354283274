<template>
  <div v-if="!officials">...</div>
  <div v-else>
    <Item
      v-for="{ appid, name, title, agentid, login_enabled, qrcode_image, login_url } in officials"
      :key="name"
      :appid="appid"
      :name="name"
      :agentid="agentid"
      :title="title"
      :loginEnabled="login_enabled"
      :bind="name in users"
      :enabled="users[name]"
      :disabled="loading"
      :wechatQrcode="qrcode_image"
      :loginUrl="login_url"
      @subscribe="subscribe"
      @unsubscribe="unsubscribe"
      @unbind="unbind" />
  </div>
</template>
<script lang="ts" setup>
  import { onMounted, ref } from 'vue';

  import * as service from './service';
  import { State } from './service';
  import type { Official } from './service';
  import Item from './Item.vue';
  import * as params from './params';

  const inited = ref(false);
  const loading = ref(false);
  const officials = ref<Official[]>();
  const users = ref<Record<string, any>>({});

  async function loadBind() {
    const { message_enabled, officials: list } = (await service.status()) || { officials: [], message_enabled: {} };

    users.value = message_enabled;
    function getK(a: Official) {
      return [a.name in message_enabled ? 1 : 0, a.login_enabled ? 1 : 0].reduce((a, s) => a * 2 + s);
    }
    officials.value = list.sort((a, b) => getK(b) - getK(a));
  }
  function setError(e: string) {
    // TODO: 错误提示
    return loadBind();
  }

  const { code } = params;
  async function init() {
    if (history.replaceState) {
      history.replaceState(history.state, '', '?');
    }
    const state = await service.loginByWechatCode(params.state || params.appid || '', code, params.custom);
    switch (state) {
      case State.error:
        return setError(__('Network Abnormality'));
      case State.unknown:
        return setError(__('Unknown Error'));
      case State.different:
        return setError(__('WeChat has been bound by another user'));
      case State.unassociated:
        return setError(__('Failed to associate user'));
      case State.invalid:
        return loadBind();
      case State.ok: {
        location.reload();
        return;
      }
      case State.associated:
        return loadBind();
      case State.same:
        return loadBind();
      case State.login:
        return loadBind();
    }
  }
  async function subscribe(name: string) {
    if (loading.value) {
      return;
    }
    loading.value = true;
    try {
      await service.subscribe(name);
      await loadBind();
    } finally {
      loading.value = false;
    }
  }
  async function unsubscribe(name: string) {
    if (loading.value) {
      return;
    }
    loading.value = true;
    try {
      await service.unsubscribe(name);
      await loadBind();
    } finally {
      loading.value = false;
    }
  }

  async function unbind(name: string) {
    if (loading.value) {
      return;
    }
    loading.value = true;
    try {
      // TODO: 重新加载
      await service.unbind(name);
      await loadBind();
    } finally {
      loading.value = false;
    }
  }
  onMounted(async () => {
    inited.value = false;
    try {
      if (!code) {
        return await loadBind();
      }
      return await init();
    } finally {
      inited.value = true;
    }
  });
</script>
