import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import './patches/localstorage';
import './extends';
import './workspaces';
import './views';
import './settings';
import './feature/index.mjs';
import './exportDocx';
