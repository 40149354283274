<template>
	<ElButtonGroup>
		<ElDropdown @command="layout = $event">
			<ElButton :title="tt('Switch Layout')" circle #icon>
				<svg>
					<use href="#icon-dashboard-list" />
				</svg>
			</ElButton>
			<template #dropdown>
				<ElDropdownMenu class="root-menu">
					<template v-for="{ label, value } in layouts" :key="value">
						<ElDropdownItem :class="{ ['current']: value === layout }" :command="value">
							{{ label }}
						</ElDropdownItem>
					</template>
				</ElDropdownMenu>
			</template>
		</ElDropdown>
	</ElButtonGroup>
</template>

<script lang="ts" setup>
import {computed} from 'vue';
import {ElDropdown, ElButton, ElButtonGroup, ElDropdownMenu, ElDropdownItem} from 'element-plus';

const tt = __;
defineOptions({inheritAttrs: false});

const layouts = [
	{label: __('Jump to page'), value: 0},
	{label: __('Details Dialog'), value: 1},
];
const props = defineProps<{
	layout: 0 | 1;
}>();
const emit = defineEmits<{
	(event: 'update:layout', layout: string): void;
}>();
const layout = computed({
	get: () => props.layout || 0,
	set: v => {
		emit('update:layout', v);
	},
});
</script>

<style scoped lang="less">
  .root-menu .current {
    background-color: #eee;
  }
</style>
