import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b2ea25b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { ref: "excelContainerRef" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = {
  key: 0,
  class: "btn-container"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($setup.writePermission && $setup.excelMetaPermissions.read && $props.df.options)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode($setup["ElButton"], {
              type: "primary",
              onClick: $setup.importSheets,
              disabled: $props.readonly
            }, {
              default: _withCtx(() => [
                _createTextVNode("加载模版表格")
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled"])
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", null, [
      _createVNode($setup["ElTabs"], {
        class: "tab-container",
        type: "border-card",
        tabPosition: "bottom",
        modelValue: $setup.editableTabsValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.editableTabsValue) = $event)),
        editable: !$props.readonly && $setup.writePermission && $setup.excelMetaPermissions.write,
        onTabAdd: $setup.tabAdd,
        onTabRemove: $setup.tabRemove,
        onTabChange: $setup.tabChange
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sheets, (item) => {
            return (_openBlock(), _createBlock($setup["ElTabPane"], {
              key: item.key,
              label: item.name,
              name: item.key
            }, {
              label: _withCtx(() => [
                _createVNode($setup["TabLabel"], {
                  title: item.name,
                  onChange: $setup.changeTitle
                }, null, 8 /* PROPS */, ["title"])
              ]),
              default: _withCtx(() => [
                _createVNode($setup["Table"], {
                  value: item.value,
                  readOnly: $props.readonly || !$setup.writePermission,
                  formula: $setup.hyperFormula,
                  name: item.name,
                  getSheets: $setup.getSheets,
                  onAdd: $event => ($setup.hotTableRefs[item.name] = $event),
                  onRemove: $event => (delete $setup.hotTableRefs[item.name]),
                  onChange: $setup.emitChange,
                  onInit: $event => ($setup.initState[item.key] = true)
                }, null, 8 /* PROPS */, ["value", "readOnly", "formula", "name", "onAdd", "onRemove", "onInit"])
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "name"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "editable"])
    ])), [
      [$setup["vLoading"], $setup.loading]
    ]),
    ($setup.excelMeta)
      ? (_openBlock(), _createBlock($setup["SelectExcelTemplateDialog"], {
          key: 0,
          visible: $setup.visible,
          excelMeta: $setup.excelMeta,
          query: $setup.query,
          onAddSheets: $setup.addSheets,
          onCancel: $setup.cancel
        }, null, 8 /* PROPS */, ["visible", "excelMeta", "query"]))
      : _createCommentVNode("v-if", true)
  ], 512 /* NEED_PATCH */))
}