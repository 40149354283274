import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9df94a0f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = {
  key: 0,
  class: "indicator-pill whitespace-nowrap blue"
}
const _hoisted_3 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_alert = _resolveComponent("el-alert")
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["Page"], null, {
      title: _withCtx(() => [
        _createElementVNode("h3", _hoisted_1, _toDisplayString($props.type === 'project' ? $setup.tt('Project') : $setup.tt('Company')) + _toDisplayString($setup.tt('Weekly Assessment Detail')), 1 /* TEXT */),
        ($setup.lockStatus)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _createElementVNode("span", null, _toDisplayString($setup.tt('Already Locked')), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      sider: _withCtx(() => [
        _createVNode($setup["Member"], {
          users: $setup.members,
          modelValue: $setup.userValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.userValue) = $event))
        }, null, 8 /* PROPS */, ["users", "modelValue"])
      ]),
      tools: _withCtx(() => [
        _createVNode($setup["Header"], {
          type: $props.type,
          assessUserPermission: $setup.assessUserPermission,
          assessInfo: $setup.assessInfo,
          detailPermission: $setup.detailPermission,
          lockStatus: $setup.lockStatus,
          smallMeta: $setup.smallMeta,
          onUpdate: $setup.getAssess
        }, null, 8 /* PROPS */, ["type", "assessUserPermission", "assessInfo", "detailPermission", "lockStatus", "smallMeta"])
      ]),
      default: _withCtx(() => [
        _createVNode($setup["DetailHeader"], {
          type: $props.type,
          assessUserPermission: $setup.assessUserPermission,
          assessInfo: $setup.assessInfo,
          detailPermission: $setup.detailPermission,
          lockStatus: $setup.lockStatus,
          smallMeta: $setup.smallMeta,
          onUpdate: $setup.getAssess
        }, null, 8 /* PROPS */, ["type", "assessUserPermission", "assessInfo", "detailPermission", "lockStatus", "smallMeta"]),
        ($setup.lockStatus)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_el_alert, {
                title: $setup.prompt,
                type: "warning",
                "show-icon": "",
                closable: false
              }, null, 8 /* PROPS */, ["title"])
            ]))
          : _createCommentVNode("v-if", true),
        _createVNode($setup["Detail"], {
          type: $props.type,
          detailPermission: $setup.detailPermission,
          assessUserPermission: $setup.assessUserPermission,
          lockStatus: $setup.lockStatus,
          assessInfo: $setup.assessInfo,
          onUpdate: $setup.getAssess,
          onRemove: $setup.onRemove,
          onCreateTempTask: $setup.createTempTask
        }, null, 8 /* PROPS */, ["type", "detailPermission", "assessUserPermission", "lockStatus", "assessInfo"])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode($setup["CreateTempDialog"], {
      visible: $setup.visible,
      smallMeta: $setup.smallMeta,
      onCancel: _cache[1] || (_cache[1] = $event => ($setup.visible = false)),
      onConfirm: $setup.confirmCreateTemp
    }, null, 8 /* PROPS */, ["visible", "smallMeta"])
  ])), [
    [_directive_loading, $setup.loading]
  ])
}