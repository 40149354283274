<template>
	<div class="report-info_label">2. {{tt("Construction Description")}}</div>
	<div class="description-container" v-if="description" v-html="description">
	</div>
	<div v-if="!description">{{ tt("No Construction Description") }}</div>
</template>

<script setup lang='ts'>
import { ref, defineProps, defineEmits } from 'vue';

interface Props{
	description?:string
}
const props = defineProps<Props>();
interface Emit{

}
const tt = __
const emit = defineEmits<Emit>();

</script>

<style lang='less' scoped>
.description-container {
	margin: 13px 0;
}
</style>
