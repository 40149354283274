import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-126db64e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "header-container",
  ref: "toolDev"
}
const _hoisted_2 = { class: "left-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_ElSpace = _resolveComponent("ElSpace")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString($setup.tt('Unit')) + ":", 1 /* TEXT */),
        _createTextVNode(_toDisplayString($props.assessInfo?.department_doc.label), 1 /* TEXT */)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString($setup.tt('Department')) + ":", 1 /* TEXT */),
        _createTextVNode(_toDisplayString($props.assessInfo?.organization?.organization_name), 1 /* TEXT */)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString($setup.tt('Position')) + ":", 1 /* TEXT */),
        _createTextVNode(_toDisplayString($props.assessInfo?.job_doc?.job_title || ''), 1 /* TEXT */)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString($setup.tt('Name')) + ":", 1 /* TEXT */),
        _createTextVNode(_toDisplayString($props.assessInfo?.user_doc.full_name), 1 /* TEXT */)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString($setup.tt('Performance Year')) + ":", 1 /* TEXT */),
        _createTextVNode(_toDisplayString($setup.month), 1 /* TEXT */)
      ])
    ]),
    _createCommentVNode(" <div class=\"btn-container\"> "),
    _createVNode(_component_ElSpace, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          onClick: _cache[0] || (_cache[0] = $event => ($setup.emit('exportInterview')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Export')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        ($setup.show)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              type: "primary",
              onClick: _cache[1] || (_cache[1] = $event => ($setup.emit('save')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.tt('Save')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createCommentVNode(" </div> ")
  ], 512 /* NEED_PATCH */))
}