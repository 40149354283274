import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49355d88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "role_type_container" }
const _hoisted_2 = { class: "role_container" }
const _hoisted_3 = { class: "role_type_container" }
const _hoisted_4 = { class: "role_container" }
const _hoisted_5 = { class: "role_type_container" }
const _hoisted_6 = { class: "role_container" }
const _hoisted_7 = { class: "role_type_container" }
const _hoisted_8 = { class: "role_container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElDialog"], {
    modelValue: $setup.dialogVisible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.dialogVisible) = $event)),
    title: $setup.tt('Role'),
    destroyOnClose: "",
    onClose: $setup.cancel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode(_toDisplayString($setup.tt('Personnel Role:')) + " ", 1 /* TEXT */),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.roles.uer_doc?.roles, (role) => {
            return (_openBlock(), _createBlock($setup["RoleItem"], {
              role: role.role
            }, null, 8 /* PROPS */, ["role"]))
          }), 256 /* UNKEYED_FRAGMENT */))
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString($setup.tt('Inherit Role:')) + " ", 1 /* TEXT */),
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.roles.inherit_roles, (inherit_role) => {
            return (_openBlock(), _createElementBlock("div", null, [
              _createElementVNode("div", null, _toDisplayString($setup.tt('Inherited from')) + " " + _toDisplayString(inherit_role.organization.label) + ":", 1 /* TEXT */),
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(inherit_role.roles, (role) => {
                  return (_openBlock(), _createBlock($setup["RoleItem"], {
                    role: role.role
                  }, null, 8 /* PROPS */, ["role"]))
                }), 256 /* UNKEYED_FRAGMENT */))
              ])
            ]))
          }), 256 /* UNKEYED_FRAGMENT */))
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createTextVNode(_toDisplayString($setup.tt('Role of this organization:')) + " ", 1 /* TEXT */),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.roles.organization_role_list, (role) => {
            return (_openBlock(), _createBlock($setup["RoleItem"], {
              role: role.role
            }, null, 8 /* PROPS */, ["role"]))
          }), 256 /* UNKEYED_FRAGMENT */))
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createTextVNode(_toDisplayString($setup.tt('After Combination Roles:')) + " ", 1 /* TEXT */),
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.unionRoles, (role) => {
            return (_openBlock(), _createBlock($setup["RoleItem"], { role: role }, null, 8 /* PROPS */, ["role"]))
          }), 256 /* UNKEYED_FRAGMENT */))
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}