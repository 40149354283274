import { asBlob } from 'html-docx-js-typescript';
function saveAs(data: Blob, name: string) {
	const a = document.createElement('a');
	const url = URL.createObjectURL(data);
	a.href = url;
	a.download = name;
	a.click();
	setTimeout(() => { URL.revokeObjectURL(url); }, 10000);
}
function blob2url(blob: Blob) {
	return new Promise<string>(r => {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		reader.onload = () => r(reader.result as any);
	});
}
async function toDocx(el: HTMLElement) {
	const ele = el.cloneNode(true) as HTMLElement;
	for (const image of ele.querySelectorAll('img')) {
		const url = image.src;
		if (!/^https?:\/\/|^\//i.test(url)) { continue; }
		const dataUrl = await fetch(url).then(r => r.blob()).then(blob2url)
			.catch(() =>null);
		if (dataUrl) { image.src = dataUrl; }
	}
	return asBlob(ele.outerHTML);
}
function patch(PrintView: any) {
	const {prototype} = PrintView;
	const old_setup_toolbar = prototype.setup_toolbar;
	prototype.setup_toolbar = function setup_toolbar() {
		old_setup_toolbar.call(this, ...arguments);
		this.page.add_button(__('DOCX'), () => {
			toDocx(this.$print_format_body.find('html')[0]).then(data => {
				saveAs(data, 'file.docx');
			});
		}, { icon: 'small-file' });
	};
}

if (frappe.ui.form.PrintView) {
	patch(frappe.ui.form.PrintView);
} else {
	let PrintView;
	Object.defineProperty(frappe.ui.form, 'PrintView', {
		get() {
			return PrintView;
		},
		set(pv) {
			if (PrintView === pv) { return; }
			PrintView = pv;
			patch(PrintView);
		},
		configurable: true,
	});
}
