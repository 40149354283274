import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "organization-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tree = _resolveComponent("el-tree")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_el_tree, {
      data: $setup.treeData,
      props: $setup.defaultProps,
      expandOnClickNode: false,
      highlightCurrent: "",
      nodeKey: "key",
      ref: "treeRef",
      defaultExpandAll: "",
      currentNodeKey: $setup.currentNodeKey,
      onNodeClick: $setup.handleNodeClick
    }, null, 8 /* PROPS */, ["data", "currentNodeKey"]), [
      [_directive_loading, $setup.loading]
    ])
  ]))
}