<template>
	<div class="timeline-container" ref="el">
		<div class="manageContainer">
			<div class="manageContent noBorder">
				<div id="guigu_timeline_container"
					:class="{ timeLine: true, 'left-margin': mode === 'left' }">
					<div v-viewer key="timeline" id="guigu-timeline-view">
						<slot></slot>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { ref, toRefs, onMounted, computed } from 'vue';

import { useInfiniteScroll } from '@vueuse/core';
interface Props {
	mode?: 'left' | 'right'
	leftWidth?: string
}
const props = defineProps<Props>();
const leftWidth = computed(() => props.leftWidth || '100px');

const el = ref<HTMLElement>();
interface Emit {
	(e: 'loadMore'): void
}
const emit = defineEmits<Emit>();
onMounted(() => {
	const timeLineContainer = $('#guigu_timeline_container');
	$(timeLineContainer).on('hide', e => { e.stopPropagation(); });
});

useInfiniteScroll(
	el,
	() => {
		// load more
		emit('loadMore');
	},
	{ distance: 10 }
);
</script>
<style lang="less" scoped>
.timeline-container {
	height: 100%;
	overflow-y: scroll;
}

.manageContainer {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	height: 100%;

	.manageContent {
		&.noBorder {
			border-left: none;
		}
	}
}

.timeLine {
	display: block;
}

.left-margin {
	margin-left: v-bind(leftWidth);
}
</style>
