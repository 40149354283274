<template>
	<ElDropdown class="gant-dropdown" @command="unit = $event" :disabled="loading"
		:teleported="false">
		<ElButton circle title="单位" :icon="Coin" />
		<template #dropdown>
			<ElDropdownMenu>
				<template v-for="{ value, label } in options" :key="value">
					<ElDropdownItem :command="value"
						:class="{ ['current']: unit === value }">
						{{ label }}
					</ElDropdownItem>
				</template>
			</ElDropdownMenu>
		</template>
	</ElDropdown>
</template>
<script lang="ts" setup>
import {computed} from 'vue';
import {ElDropdown, ElButton, ElDropdownMenu, ElDropdownItem} from 'element-plus';
import {Coin} from '@element-plus/icons-vue';

import useUnit, {options} from './useUnit';
import type {GanttConfiguration} from './types';

defineOptions({inheritAttrs: false});

const props = defineProps<{
	loading?: boolean;
	configuration: GanttConfiguration;
	view?: GlobalView.View;
}>();
const {unit} = useUnit(computed(() => props.view?.name || ''));
const def = props.configuration?.unitDefault;
if (typeof def === 'string') {
	unit.value = def;
}
</script>
<style scoped lang="less">
.gant-dropdown{
	:deep(.current) {
		background-color: #EEE;
	}
}
</style>
