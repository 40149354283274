import { createVNode as _createVNode, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02aad357"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pagination" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Skeleton"], {
    classes: $props.setting,
    loading: $setup.loading,
    ref: "skeleton",
    layout: $props.state.layout
  }, _createSlots({
    action: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.actions, (component) => {
        return (_openBlock(), _createBlock(_resolveDynamicComponent(component), {
          meta: $props.meta,
          view: $setup.view,
          configuration: $setup.configuration,
          data: $setup.data,
          total: $setup.total,
          options: $setup.options,
          loading: $setup.loading,
          onRefresh: $setup.refresh,
          page: $setup.page,
          limit: $setup.limit,
          value: $setup.value,
          selected: $setup.selected,
          "onUpdate:selected": _cache[4] || (_cache[4] = $event => (($setup.selected) = $event)),
          group: $setup.group,
          "onUpdate:group": _cache[5] || (_cache[5] = $event => (($setup.group) = $event)),
          onSort: $setup.setOrder,
          sort: $setup.sort,
          filters: $setup.filters,
          "onUpdate:filters": _cache[6] || (_cache[6] = $event => (($setup.filters) = $event)),
          allFilters: $setup.allFilters,
          quickFilters: $setup.quickFilters,
          infiniteScroll: $setup.infiniteScroll
        }, null, 40 /* PROPS, HYDRATE_EVENTS */, ["meta", "view", "configuration", "data", "total", "options", "loading", "onRefresh", "page", "limit", "value", "selected", "group", "onSort", "sort", "filters", "allFilters", "quickFilters", "infiniteScroll"]))
      }), 256 /* UNKEYED_FRAGMENT */)),
      _createVNode($setup["Buttons"], { buttons: $setup.buttons }, null, 8 /* PROPS */, ["buttons"]),
      _createVNode($setup["Menu"], {
        meta: $props.meta,
        loading: $setup.loading,
        selected: $setup.selected,
        filter: $setup.hasFilter,
        sorter: $setup.orderN,
        view: $setup.view,
        onRefresh: $setup.refresh,
        onFullscreen: $setup.fullscreen,
        onToggleSorter: _cache[7] || (_cache[7] = $event => ($setup.sortVisible = !$setup.sortVisible)),
        onToggleFilter: _cache[8] || (_cache[8] = $event => ($setup.filterVisible = !$setup.filterVisible))
      }, null, 8 /* PROPS */, ["meta", "loading", "selected", "filter", "sorter", "view", "onRefresh"])
    ]),
    default: _withCtx(() => [
      _createVNode($setup["FilterDialog"], {
        meta: $props.meta,
        modelValue: $setup.filters,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.filters) = $event)),
        or: $setup.orFilters,
        "onUpdate:or": _cache[1] || (_cache[1] = $event => (($setup.orFilters) = $event)),
        visible: $setup.filterVisible,
        "onUpdate:visible": _cache[2] || (_cache[2] = $event => (($setup.filterVisible) = $event))
      }, null, 8 /* PROPS */, ["meta", "modelValue", "or", "visible"]),
      _createVNode($setup["Sorter"], {
        meta: $props.meta,
        value: $setup.sort,
        onUpdate: $setup.setOrder,
        visible: $setup.sortVisible,
        "onUpdate:visible": _cache[3] || (_cache[3] = $event => (($setup.sortVisible) = $event))
      }, null, 8 /* PROPS */, ["meta", "value", "onUpdate", "visible"]),
      (_openBlock(), _createBlock(_resolveDynamicComponent($props.mainComp), {
        meta: $props.meta,
        configuration: $setup.configuration,
        view: $setup.view,
        data: $setup.data,
        total: $setup.total,
        options: $setup.options,
        loading: $setup.loading,
        onRefresh: $setup.refresh,
        onNextPage: $setup.nextPage,
        onSort: $setup.sortData,
        onCreate: $setup.create,
        onDelete: $setup.del,
        "onUpdate:configuration": $setup.update,
        selected: $setup.selected,
        "onUpdate:selected": _cache[18] || (_cache[18] = $event => (($setup.selected) = $event)),
        modelValue: $setup.modelValue,
        "onUpdate:modelValue": _cache[19] || (_cache[19] = $event => (($setup.modelValue) = $event)),
        filters: $setup.filters,
        "onUpdate:filters": _cache[20] || (_cache[20] = $event => (($setup.filters) = $event)),
        rowAction: $setup.rowAction,
        infiniteScroll: $setup.infiniteScroll,
        page: $setup.page,
        limit: $setup.limit,
        group: $setup.group,
        sort: $setup.sort,
        detail: $setup.detail
      }, _createSlots({ _: 2 /* DYNAMIC */ }, [
        (!$setup.nonpageable && ($setup.infiniteScroll || $setup.isAll))
          ? {
              name: "limit",
              fn: _withCtx(() => [
                _createVNode($setup["Limit"], {
                  loading: $setup.loading,
                  modelValue: $setup.limit,
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => (($setup.limit) = $event)),
                  maxLimit: $setup.maxLimit
                }, null, 8 /* PROPS */, ["loading", "modelValue", "maxLimit"])
              ]),
              key: "0"
            }
          : undefined,
        (!$setup.nonpageable && $setup.showInfiniteScrollBtn)
          ? {
              name: "infiniteScroll",
              fn: _withCtx(() => [
                _createVNode($setup["ElButton"], {
                  onClick: $setup.nextPage,
                  disabled: $setup.loading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("继续加载")
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick", "disabled"])
              ]),
              key: "1"
            }
          : undefined,
        (!$setup.nonpageable && ($setup.infiniteScroll || $setup.isAll || $setup.showInfiniteScrollBtn))
          ? {
              name: "pagination",
              fn: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  ($setup.infiniteScroll || $setup.isAll)
                    ? (_openBlock(), _createBlock($setup["Limit"], {
                        key: 0,
                        loading: $setup.loading,
                        modelValue: $setup.limit,
                        "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => (($setup.limit) = $event)),
                        maxLimit: $setup.maxLimit
                      }, null, 8 /* PROPS */, ["loading", "modelValue", "maxLimit"]))
                    : _createCommentVNode("v-if", true),
                  ($setup.showInfiniteScrollBtn)
                    ? (_openBlock(), _createBlock($setup["ElButton"], {
                        key: 1,
                        onClick: $setup.nextPage,
                        disabled: $setup.loading
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" 继续加载 ")
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["onClick", "disabled"]))
                    : _createCommentVNode("v-if", true)
                ])
              ]),
              key: "2"
            }
          : undefined
      ]), 1064 /* PROPS, HYDRATE_EVENTS, DYNAMIC_SLOTS */, ["meta", "configuration", "view", "data", "total", "options", "loading", "onRefresh", "onNextPage", "onSort", "selected", "modelValue", "filters", "rowAction", "infiniteScroll", "page", "limit", "group", "sort", "detail"]))
    ]),
    _: 2 /* DYNAMIC */
  }, [
    ($props.toolbar.length)
      ? {
          name: "toolbar",
          fn: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.toolbar, (component) => {
              return (_openBlock(), _createBlock(_resolveDynamicComponent(component), {
                meta: $props.meta,
                view: $setup.view,
                configuration: $setup.configuration,
                data: $setup.data,
                total: $setup.total,
                options: $setup.options,
                loading: $setup.loading,
                onRefresh: $setup.refresh,
                page: $setup.page,
                limit: $setup.limit,
                value: $setup.value,
                selected: $setup.selected,
                "onUpdate:selected": _cache[9] || (_cache[9] = $event => (($setup.selected) = $event)),
                group: $setup.group,
                "onUpdate:group": _cache[10] || (_cache[10] = $event => (($setup.group) = $event)),
                onSort: $setup.setOrder,
                sort: $setup.sort,
                filters: $setup.filters,
                "onUpdate:filters": _cache[11] || (_cache[11] = $event => (($setup.filters) = $event)),
                allFilters: $setup.allFilters,
                quickFilters: $setup.quickFilters,
                infiniteScroll: $setup.infiniteScroll
              }, null, 40 /* PROPS, HYDRATE_EVENTS */, ["meta", "view", "configuration", "data", "total", "options", "loading", "onRefresh", "page", "limit", "value", "selected", "group", "onSort", "sort", "filters", "allFilters", "quickFilters", "infiniteScroll"]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]),
          key: "0"
        }
      : undefined,
    ($props.sider.length)
      ? {
          name: "sider",
          fn: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.sider, (component, index) => {
              return (_openBlock(), _createBlock(_resolveDynamicComponent(component), {
                meta: $props.meta,
                view: $setup.view,
                configuration: $setup.configuration,
                data: $setup.data,
                total: $setup.total,
                options: $setup.options,
                loading: $setup.loading,
                onRefresh: $setup.refresh,
                page: $setup.page,
                limit: $setup.limit,
                value: $setup.value,
                selected: $setup.selected,
                "onUpdate:selected": _cache[12] || (_cache[12] = $event => (($setup.selected) = $event)),
                group: $setup.group,
                "onUpdate:group": _cache[13] || (_cache[13] = $event => (($setup.group) = $event)),
                onSort: $setup.setOrder,
                sort: $setup.sort,
                filters: $setup.filters,
                "onUpdate:filters": _cache[14] || (_cache[14] = $event => (($setup.filters) = $event)),
                allFilters: $setup.allFilters,
                quickFilters: $setup.quickFilters,
                infiniteScroll: $setup.infiniteScroll
              }, null, 40 /* PROPS, HYDRATE_EVENTS */, ["meta", "view", "configuration", "data", "total", "options", "loading", "onRefresh", "page", "limit", "value", "selected", "group", "onSort", "sort", "filters", "allFilters", "quickFilters", "infiniteScroll"]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]),
          key: "1"
        }
      : undefined,
    ($setup.filterField)
      ? {
          name: "filterField",
          fn: _withCtx(() => [
            _createVNode($setup["FilterField"], {
              modelValue: $setup.filterFieldValue,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => (($setup.filterFieldValue) = $event)),
              field: $setup.filterField,
              meta: $props.meta
            }, null, 8 /* PROPS */, ["modelValue", "field", "meta"])
          ]),
          key: "2"
        }
      : undefined,
    (!$setup.nonpageable && !$setup.isAll && !$setup.infiniteScroll)
      ? {
          name: "pagination",
          fn: _withCtx(() => [
            _createVNode($setup["Limit"], {
              loading: $setup.loading,
              modelValue: $setup.limit,
              "onUpdate:modelValue": _cache[21] || (_cache[21] = $event => (($setup.limit) = $event)),
              maxLimit: $setup.maxLimit,
              class: "limit"
            }, null, 8 /* PROPS */, ["loading", "modelValue", "maxLimit"]),
            _createVNode($setup["Pagination"], {
              total: $setup.total,
              loading: $setup.loading,
              limit: $setup.limit,
              page: $setup.page,
              "onUpdate:page": _cache[22] || (_cache[22] = $event => (($setup.page) = $event))
            }, null, 8 /* PROPS */, ["total", "loading", "limit", "page"])
          ]),
          key: "3"
        }
      : undefined,
    ($setup.detailComp && $setup.value && $props.showDetail)
      ? {
          name: "detail",
          fn: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent($setup.detailComp), {
              meta: $props.meta,
              isPosition: $setup.isPosition,
              configuration: $setup.configuration,
              view: $setup.view,
              options: $setup.options,
              selected: $setup.selected,
              value: $setup.value,
              "onUpdate:value": _cache[23] || (_cache[23] = $event => (($setup.value) = $event)),
              loading: $setup.loading,
              onRefresh: $setup.refresh
            }, null, 40 /* PROPS, HYDRATE_EVENTS */, ["meta", "isPosition", "configuration", "view", "options", "selected", "value", "loading", "onRefresh"]))
          ]),
          key: "4"
        }
      : undefined
  ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["classes", "loading", "layout"]))
}