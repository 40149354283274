import { CellClassParams, ColDef, FirstDataRenderedEvent, RowDataUpdatedEvent } from 'ag-grid-community';

import { FormatDiaryStructure, OriginDiaryStructure, StructureItemFormat, ProgressPlan } from '../type/index';
import { ReportResult } from '../type';

  export function formatStructure(structures:OriginDiaryStructure[]):FormatDiaryStructure[]{
	return structures.map(item=>({
			id:item.name,
			parent:item.parent_guigu_construction_structure,
			lft:item.lft,
			rgt:item.rgt,
			label:item.structure_name,
			isUnitStructure:item.is_unit_structure,
			code:item.code,
		}));
  }

  function cellStyleFn(
	params: CellClassParams,
	reports: ReportResult[],
	rowHeight: Record<string, number>,
  ) {
	const { data, colDef } = params;
	const currIndex = reports.findIndex(
	  item => item.structureProgressId === data.structureProgressId,
	);
	const pre = reports[currIndex - 1];

	const structureProgressIds = reports
	  .filter(item => {
		if (colDef.field === 'teamLabel') {
		  return item.teamId === data.teamId;
		}
		if (colDef.field === 'structureLabel') {
		  return (
			item.teamId === data.teamId && item.structureId === data.structureId
		  );
		}
		return false;
	  })
	  .map(item => item.structureProgressId);
	const height =
	  structureProgressIds.reduce(
		(pre, current) => pre + (rowHeight[current] || 0),
		0,
	  ) - 1;
	if (pre?.teamId !== data.teamId || pre?.structureId != data?.structureId) {
	  return { background: '#fff', height, borderRight: '1px solid #f0f0f0' };
	}
	return;
  }

  function getRowHeights(event: RowDataUpdatedEvent) {
	const rowHeights: Record<string, number> = {};
	event.api.forEachNode(node => {
	  const heights: number[] = Object.values(node.__autoHeights);
	  rowHeights[node?.data?.structureProgressId] = Math.max(...heights);
	});
	return rowHeights;
  }

  export function onRowDataUpdatedEvent(
	event: RowDataUpdatedEvent,
	reports: ReportResult[],
  ) {
	const columns = event.api.getColumnDefs();
	const rowHeights = getRowHeights(event);
	columns?.forEach((col: ColDef) => {
	  if (col.field === 'teamLabel') {
		col.cellStyle = (params: CellClassParams) =>
		  cellStyleFn(params, reports, rowHeights);
	  }
	  if (col.field === 'structureLabel') {
		col.cellStyle = (params: CellClassParams) =>
		  cellStyleFn(params, reports, rowHeights);
	  }
	});
	event.api.setColumnDefs(columns || []);
  }
