<template>
	<AgGridVue
		v-if="!loading"
		class="ag-theme-guigu table"
		style="height: 303px;width: 100%;margin: 0;overflow: auto;"
		:rowData="dataList"
		:rowHeight="30"
		:defaultColDef="defaultColDef"
		animateRows="true"
		@grid-ready="onGridReady"
		:suppressDragLeaveHidesColumns="smallMeta"
		:localeText="zhCN"
		suppressRowTransform
		useValueFormatterForExport
		:getRowId="getRowId">
	</AgGridVue>
</template>
<script setup lang="ts">
import {ref, watch} from 'vue';
import {AgGridVue} from 'ag-grid-vue3';
import type {CellClassParams, GridApi} from 'ag-grid-community';

import zhCN from '../../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';

import {getColumns} from './helper';

interface Props{
	dataList:any[]
	loading:boolean
	smallMeta: boolean;
}
const props = defineProps<Props>();

const gridApi = ref<GridApi | null>(null);
const onGridReady = (params: CellClassParams) => {
	gridApi.value = params.api;
};

watch([()=>props.smallMeta, gridApi], ([newSmallMeta, api])=>{
	api?.setColumnDefs(getColumns(newSmallMeta));
});
const defaultColDef = {
	sortable: true,
	filter: true,
	resizable: true,
	suppressMovable: true,
	cellDataType: false,
	cellStyle: () => ({
		display: 'flex',
		alignItems: 'center',
	}),
};

function getRowId(params: CellClassParams) {
	return params.data.name;
}
</script>
