import type {
	ColumnOptions,
	Options,
	RowValue,
	Key,
	IdKey,
	EventContext,
	RowEventMap,
	EventMap,
} from '../types';
import Group from '../Group';
import { isEq } from '../utils/isEq';
import { createEmit, createListen } from '../utils/event';
import { createEmitRow, createListenRow } from '../utils/rowEvent';
import type { Row } from '../types/Row';

import updateCollapse from './updateCollapse';
import updateVisible from './updateVisible';
import setValue from './setValue';
export default class Source {
	#rowData: Row[] = [];
	#rowMap = new Map<string | number, Row>();
	#idKey?: IdKey<object>;
	#parentKey?: Key<string | number, object>;
	#visibleRowIndexes: number[] = [];

	#rowEvents = new Map<string | number, Map<string | symbol, Set<(v: any, ctx: EventContext) => void>>>;
	readonly emitRow = createEmitRow<RowEventMap>(this.#rowEvents);
	readonly listenRow = createListenRow<RowEventMap>(this.#rowEvents);

	#events: Record<any, Set<(v: any, ctx: EventContext) => boolean>> = {};
	readonly emit = createEmit<EventMap>(this.#events);
	readonly listen = createListen<EventMap>(this.#events);

	#selectable = false;
	get selectable() { return this.#selectable; }
	set selectable(v: boolean) {
		if (v) {
			this.#selectable = true;
			return;
		}
		this.#selectable = false;
		const old = this.#selectedId;
		if (old === undefined) { return; }
		this.#selectedId = undefined;
		this.requestRender();
		this.emit('selectedChange', undefined);
		this.emitRow(old, 'selectedChange', false);

	}
	paused = false;
	#selectedId?: number | string;
	get selectedId() { return this.#selectedId; }
	set selectedId(v) {
		if (!this.#selectable) { return; }
		const old = this.#selectedId;
		if (v === old) { return; }
		this.#selectedId = v;
		this.requestRender();
		this.emit('selectedChange', v);
		if (old !== undefined) {
			this.emitRow(old, 'selectedChange', false);
		}
		if (v !== undefined) {
			this.emitRow(v, 'selectedChange', true);
		}
	}
	toggleSelected(id: number | string) {
		if (!this.#selectable) { return; }
		const old = this.#selectedId;
		const selectedId = old === id ? undefined : id;
		this.#selectedId = selectedId;
		this.requestRender();
		this.emit('selectedChange', selectedId);
		if (old !== undefined && old !== id) {
			this.emitRow(old, 'selectedChange', false);
		}
		if (id !== undefined) {
			this.emitRow(id, 'selectedChange', true);
		}
	}


	#checkedList: (number | string)[] = [];
	#checkedSet = new Set<number | string>();
	#allChecked = false;
	get checked() { return [...this.#checkedList]; }
	set checked(v) {
		const had = new Set<number | string>();
		const rowMap = this.#rowMap;
		const newList: (number | string)[] = [];
		for (const k of v) {
			if (had.has(k)) { continue; }
			if (!rowMap.has(k)) { continue; }
			had.add(k);
			newList.push(k);
		}
		if (isEq(this.#checkedList, newList)) { return; }
		const oldSet = this.#checkedSet;
		const checkedSet = new Set(newList);
		this.#checkedList = newList;
		this.#checkedSet = checkedSet;
		this.#allChecked = checkedSet.size === rowMap.size;
		this.requestRender();
		this.emit('checkedChange', [...newList]);
		for (const v of checkedSet) {
			if (oldSet.has(v)) { continue; }
			this.emitRow(v, 'checkedChange', true);
		}
		for (const v of oldSet) {
			if (checkedSet.has(v)) { continue; }
			this.emitRow(v, 'checkedChange', false);
		}
	}
	isChecked(id: string | number) {
		return this.#checkedSet.has(id);
	}
	setChecked(id: number | string, checked?: boolean) {
		const rowMap = this.#rowMap;
		if (!rowMap.has(id)) { return false; }
		const checkedSet = this.#checkedSet;
		if (typeof checked === 'boolean') {
			if (checked === checkedSet.has(id)) { return checked; }
		} else {
			checked = !checkedSet.has(id);
		}
		const checkedList = this.#checkedList;
		if (checked) {
			checkedList.push(id);
			checkedSet.add(id);
			this.#allChecked = checkedSet.size === rowMap.size;
		} else {
			const index = checkedList.indexOf(id);
			if (index >= 0) { checkedList.splice(index, 1); }
			checkedSet.delete(id);
			this.#allChecked = false;
		}
		this.requestRender();
		this.emit('checkedChange', [...this.#checkedList]);
		this.emitRow(id, 'checkedChange', checked);
		return checked;
	}
	get allChecked() { return this.#allChecked; }
	checkedAll() {
		const newList = this.#rowData.map(v => v.id);
		if (isEq(this.#checkedList, newList)) { return; }
		const oldChecked = this.#checkedSet;
		const checkedSet = new Set(newList);
		this.#checkedList = newList;
		this.#checkedSet = checkedSet;
		this.#allChecked = true;
		this.requestRender();
		this.emit('checkedChange', [...this.#checkedList]);
		for (const v of checkedSet) {
			if (oldChecked.has(v)) { continue; }
			this.emitRow(v, 'checkedChange', true);
		}
	}
	cleanChecked() {
		const checkedList = this.#checkedList;
		if (!checkedList.length) { return; }
		this.#checkedList = [];
		this.#checkedSet = new Set();
		this.#allChecked = false;
		this.requestRender();
		this.emit('checkedChange', [...this.#checkedList]);
		for (const v of checkedList) {
			this.emitRow(v, 'checkedChange', false);
		}
	}

	#expanded = new Set<number | string>();
	get expanded(): (string | number)[] { return [...this.#expanded]; }
	set expanded(list) {
		const nl = [...list];
		const expanded = this.#expanded;
		if (nl.length === expanded.size && nl.findIndex(v => !expanded.has(v)) < 0) { return; }
		const rowMap = this.#rowMap;
		const rowData = this.#rowData;
		const oldExpanded = new Set(expanded);
		const newExpanded = new Set<Row>();
		expanded.clear();
		for (const k of nl) {
			const row = rowMap.get(k);
			if (!row) { continue; }
			expanded.add(k);
			if (!oldExpanded.delete(k)) {
				newExpanded.add(row);
			}
		}
		for (const k of oldExpanded) {
			rowMap.get(k)?.emit('collapseChange', true);
		}
		for (const row of newExpanded) {
			row.emit('collapseChange', false);
		}

		this.#visibleRowIndexes = updateVisible(rowData, rowMap, expanded);
		this.requestRender();
	}
	isCollapsed(k: string | number) { return !this.#expanded.has(k); }
	setCollapse(k: number | string, closed?: boolean) {
		const rowMap = this.#rowMap;
		const row = rowMap.get(k);
		if (!row) { return; }
		const expanded = this.#expanded;
		const c = typeof closed === 'boolean' ? closed : expanded.has(k);
		if (expanded.has(k) !== c) { return; }
		expanded[c ? 'delete' : 'add'](k);
		const visible = this.#visibleRowIndexes;
		if (updateCollapse(row, c, rowMap, expanded, visible)) {
			this.requestRender();
		}
		row.emit('collapseChange', c);
		this.emit('collapseChange', [...this.#expanded]);
	}
	isAllChecked() {
		return this.#allChecked;
	}
	hasChecked() {
		return this.#checkedSet.size > 0;
	}
	constructor({
		idKey,
		parentKey,
	}: Options = {}) {
		this.#idKey = idKey;
		this.#parentKey = parentKey;
	}

	createGroup(columns?: ColumnOptions[], startFixed?: number) {
		const area = new Group(
			this,
			() => this.#data,
			() => { this.#groups.delete(area); },
		);
		area.startFixed = startFixed || 0;
		area.requestRender = () => this.requestRender(area);
		this.requestRender(area);
		this.#groups.add(area);
		area.setColumns(columns);
		return area;
	}
	#groups = new Set<Group>();
	#renderId = requestAnimationFrame(() => { this.#render(); });
	#renderSet = new Set();
	#renderAll = false;
	requestRender(p?: any) {
		if (p) {
			this.#renderSet.add(p);
		} else {
			this.#renderAll = true;
		}
	}
	*#getNeedRender(): Iterable<[Group, boolean]> {
		const areas = [...this.#groups];
		if (this.#renderAll) {
			this.#renderAll = false;
			this.#renderSet.clear();
			for (const area of areas) {
				yield [area, true];
			}
			return;
		}
		const set = new Set(this.#renderSet);
		this.#renderSet.clear();
		for (const area of areas) {
			yield [area, set.has(area)];
		}
	}
	#destroyed = false;
	destroy() {
		if (this.#destroyed) { return; }
		this.#destroyed = true;
		cancelAnimationFrame(this.#renderId);
		for (const c of [...this.#groups]) {
			c.destroy();
		}
	}
	#hoverId: string | number | undefined;
	#render() {
		if (this.#destroyed) { return; }
		this.#renderId = requestAnimationFrame(() => { this.#render(); });
		if (this.paused) { return; }
		const needRenderList = [...this.#getNeedRender()];

		const visibleRowIndexes = this.#visibleRowIndexes;
		const selectedId = this.#selectedId;
		const checkedSet = this.#checkedSet;
		const rowMap = this.#rowMap;
		const rowData = this.#rowData;
		const hoverId = this.#hoverId;

		for (let [area, force] of needRenderList) {
			area._render(
				rowData,
				rowMap,
				visibleRowIndexes,
				hoverId,
				selectedId,
				checkedSet,
				force,
			);
		}
	}
	#data: readonly RowValue[] = [];
	setValue(rows: object[]) {
		const oldMap = this.#rowMap;
		const [list, map] = setValue(
			this,
			rows,
			(id?: string | number) => { this.#hoverId = id; this.requestRender(); },
			oldMap,
			this.#idKey,
			this.#parentKey,
		);
		this.#rowData = list;
		this.#rowMap = map;
		const data = list.map(v => v.value);
		this.#data = Object.freeze(data);
		for (const area of this.#groups) {
			area._updateData(data);
		}

		// TODO: 折叠处理
		const expanded = this.#expanded;
		this.#visibleRowIndexes = updateVisible(list, map, expanded);
		this.requestRender();

		const newList: (number | string)[] = [];
		for (const k of this.#checkedList) {
			if (!map.has(k)) { continue; }
			newList.push(k);
		}
		if (isEq(this.#checkedList, newList)) { return; }
		const checkedSet = new Set(newList);
		this.#checkedList = newList;
		this.#checkedSet = checkedSet;
		this.#allChecked = checkedSet.size === map.size;
		this.requestRender();
		this.emit('checkedChange', [...this.#checkedList]);
	}
}
