
// 2048 -> 2KB
let max_file_size = 0;

frappe.call('frappe.core.api.file.get_max_file_size')
	.then(res => { max_file_size = Number(res.message); });

export default function check_restrictions(file: File, filetypes?: string[]) {

	let is_correct_type = true;
	let valid_file_size = true;

	if (filetypes?.length) {
		is_correct_type = filetypes.some(type => {
			// is this is a mime-type
			if (type.includes('/')) {
				if (!file.type) { return false; }
				return file.type.match(type);
			}

			// otherwise this is likely an extension
			if (type[0] === '.') {
				return file.name.endsWith(type);
			}
			return false;
		});
	}

	if (max_file_size && file.size != null) {
		valid_file_size = file.size < max_file_size;
	}

	if (!is_correct_type) {
		console.warn('File skipped because of invalid file type', file);
		frappe.show_alert({
			message: __('File "{0}" was skipped because of invalid file type', [file.name]),
			indicator: 'orange',
		});
	}
	if (!valid_file_size) {
		console.warn('File skipped because of invalid file size', file.size, file);
		frappe.show_alert({
			message: __('File "{0}" was skipped because size exceeds {1} MB', [file.name, max_file_size / (1024 * 1024)]),
			indicator: 'orange',
		});
	}

	return is_correct_type && valid_file_size;
}
