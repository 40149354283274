import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElInput = _resolveComponent("ElInput")
  const _component_ElCol = _resolveComponent("ElCol")
  const _component_ElIcon = _resolveComponent("ElIcon")
  const _component_ElTooltip = _resolveComponent("ElTooltip")
  const _component_ElFormItem = _resolveComponent("ElFormItem")
  const _component_ElOption = _resolveComponent("ElOption")
  const _component_ElSelect = _resolveComponent("ElSelect")
  const _component_ElDatePicker = _resolveComponent("ElDatePicker")
  const _component_ElForm = _resolveComponent("ElForm")
  const _component_ElButton = _resolveComponent("ElButton")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $setup.visible,
    "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => (($setup.visible) = $event)),
    title: $setup.translate('Batch adding structure Progress'),
    width: "50%",
    "before-close": $setup.handleClose,
    "custom-class": "progress-dialog"
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_ElButton, { onClick: $setup.handleClose }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.translate('Cancel')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_ElButton, {
          type: "primary",
          onClick: $setup.confirm
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.translate('Confirm')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_ElForm, {
          ref: "formRef",
          model: $setup.form,
          rules: $setup.rules,
          "label-width": "120px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElFormItem, {
              label: $setup.translate('structure Progress Code'),
              prop: "codeTemplate"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElCol, { span: 22 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ElInput, {
                      modelValue: $setup.form.codeTemplate,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.form.codeTemplate) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_ElCol, {
                  span: 2,
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ElTooltip, {
                      placement: "top",
                      content: $setup.description
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ElIcon, null, {
                          default: _withCtx(() => [
                            _createVNode($setup["QuestionFilled"])
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["content"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_ElFormItem, {
              label: $setup.translate('Code Rule'),
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElCol, { span: 7 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ElFormItem, {
                      required: "",
                      prop: "codeRuleStart"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ElInput, {
                          modelValue: $setup.form.codeRuleStart,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.form.codeRuleStart) = $event)),
                          modelModifiers: { number: true }
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_ElCol, {
                  span: 2,
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" - ")
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_ElCol, { span: 7 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ElFormItem, {
                      required: "",
                      prop: "codeRuleEnd"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ElInput, {
                          modelValue: $setup.form.codeRuleEnd,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.form.codeRuleEnd) = $event)),
                          modelModifiers: { number: true }
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_ElCol, {
                  span: 1,
                  class: "text-center"
                }),
                _createVNode(_component_ElCol, { span: 7 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ElFormItem, {
                      required: "",
                      prop: "codeFormat"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ElSelect, {
                          modelValue: $setup.form.codeFormat,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.form.codeFormat) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ElOption, {
                              label: $setup.translate('Unit'),
                              value: "single"
                            }, null, 8 /* PROPS */, ["label"]),
                            _createVNode(_component_ElOption, {
                              label: $setup.translate('Rule Format Double'),
                              value: "double"
                            }, null, 8 /* PROPS */, ["label"]),
                            _createVNode(_component_ElOption, {
                              label: $setup.translate('Zh'),
                              value: "zh"
                            }, null, 8 /* PROPS */, ["label"])
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_ElFormItem, {
              label: $setup.translate('Name'),
              prop: "nameTemplate"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElCol, { span: 22 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ElInput, {
                      modelValue: $setup.form.nameTemplate,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.form.nameTemplate) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_ElCol, {
                  span: 2,
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ElTooltip, {
                      placement: "top",
                      content: $setup.description
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ElIcon, null, {
                          default: _withCtx(() => [
                            _createVNode($setup["QuestionFilled"])
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["content"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_ElFormItem, {
              label: $setup.translate('Name Rule'),
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElCol, { span: 7 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ElFormItem, {
                      required: "",
                      prop: "nameRuleStart"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ElInput, {
                          modelValue: $setup.form.nameRuleStart,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.form.nameRuleStart) = $event)),
                          modelModifiers: { number: true }
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_ElCol, {
                  span: 2,
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" - ")
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_ElCol, { span: 7 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ElFormItem, {
                      required: "",
                      prop: "nameRuleEnd"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ElInput, {
                          modelValue: $setup.form.nameRuleEnd,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($setup.form.nameRuleEnd) = $event)),
                          modelModifiers: { number: true }
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_ElCol, {
                  span: 1,
                  class: "text-center"
                }),
                _createVNode(_component_ElCol, { span: 7 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ElFormItem, {
                      required: "",
                      prop: "nameFormat"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ElSelect, {
                          modelValue: $setup.form.nameFormat,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($setup.form.nameFormat) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ElOption, {
                              label: $setup.translate('Unit'),
                              value: "single"
                            }, null, 8 /* PROPS */, ["label"]),
                            _createVNode(_component_ElOption, {
                              label: $setup.translate('Rule Format Double'),
                              value: "double"
                            }, null, 8 /* PROPS */, ["label"]),
                            _createVNode(_component_ElOption, {
                              label: $setup.translate('Zh'),
                              value: "zh"
                            }, null, 8 /* PROPS */, ["label"])
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_ElFormItem, {
              label: $setup.translate('Project')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElInput, {
                  disabled: "",
                  modelValue: $setup.form.project,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => (($setup.form.project) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_ElFormItem, {
              label: $setup.translate('Type'),
              prop: "type"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElSelect, {
                  modelValue: $setup.form.type,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => (($setup.form.type) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.structureTypes, (item) => {
                      return (_openBlock(), _createBlock(_component_ElOption, {
                        label: item.type_name,
                        value: item.name
                      }, null, 8 /* PROPS */, ["label", "value"]))
                    }), 256 /* UNKEYED_FRAGMENT */))
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_ElFormItem, {
              label: $setup.translate('Status')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElSelect, {
                  modelValue: $setup.form.status,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => (($setup.form.status) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.statuses, (item) => {
                      return (_openBlock(), _createBlock(_component_ElOption, {
                        label: $setup.translate(item),
                        value: item
                      }, null, 8 /* PROPS */, ["label", "value"]))
                    }), 256 /* UNKEYED_FRAGMENT */))
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_ElFormItem, {
              label: $setup.translate('Actual Start Date')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElDatePicker, {
                  modelValue: $setup.form.startDate,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => (($setup.form.startDate) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_ElFormItem, {
              label: $setup.translate('Actual End Date')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElDatePicker, {
                  modelValue: $setup.form.endDate,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => (($setup.form.endDate) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model", "rules"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}