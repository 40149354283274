<template>
	<div :ref="set"></div>
</template>

<script setup lang='ts'>
import { shallowRef, watch } from 'vue';

import create from './lib/create.mjs';
import type { XLSXEditor } from './lib/types.mjs';


const props = defineProps<{
	value?: any;
	readOnly?: boolean;
	name: string;
	formula: any;
	getSheets: () => any;
}>();

const emit = defineEmits<{
	(e: 'init'): void
	(e: 'add', table: any): void
	(e: 'remove', table: any): void
	(e: 'change'): void
}>();


const hatable = shallowRef<XLSXEditor | null>(null);

watch([() => props.name, hatable], ([name, table]) => {
	if (!table) { return; }
	table.name = name;
});
watch([() => props.value, () => Boolean(props.readOnly), hatable], ([value, readOnly, table]) => {
	if (!table || !value) { return; }
	table.setValue(value, readOnly);
});
const height = 'calc(100vh - var(--navbar-height, 0) - var(--page-head-height, 0) - var(--el-tabs-header-height, 0))';

function set(el, obj) {
	const old = obj.table;
	if (el && old) { return; }
	if (old) {
		obj.table = null;
		hatable.value = null;
		old.destroy();
		emit('remove', old);
	}
	if (!el) { return; }
	const table = create(el, {
		height,
		formula: props.formula,
		name: props.name,
		readOnly: props.readOnly,
		inited: () => emit('init'),
		getSheets: () => props.getSheets(),
	}, () => emit('change'));
	hatable.value = table;
	obj.table = table;
	emit('add', table);
}
</script>


<style lang='less' scoped>
:deep(.ht_clone_left) {
	z-index: 1;
}

:deep(.ht_clone_top) {
	z-index: 1;
}

:deep(.ht_clone_top_inline_start_corner.ht_clone_top_left_corner) {
	z-index: 1;
}
</style>
