import type {App} from 'vue';
import {createApp, h, ref} from 'vue';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn';

import Excel from './index.vue';

frappe.ui.form.ControlExcel = class ControlExcel extends frappe.ui.form.ControlInput {
	df: any;
	valueRef = ref('');
	readonlyRef = ref(false);
	input_area: HTMLElement | undefined;
	app: App<Element> | undefined = undefined;
	static horizontal = false;
	only_input: boolean = false;
	set_input_areas() {
		this.label_area = this.label_span = this.$wrapper.find('label').get(0);
		this.input_area = this.$wrapper.find('.control-input').get(0);
	}
	refresh_input() {
		const me = this;
		if (me.disp_status === 'None') {
			return;
		}
		// refresh value
		if (me.frm) {
			me.value = frappe.model.get_value(me.doctype, me.docname, me.df.fieldname);
		} else if (me.doc) {
			me.value = me.doc[me.df.fieldname] || '';
		}

		const is_fetch_from_read_only = me.read_only_because_of_fetch_from();

		const readonly = Boolean(this.disp_status !== 'Write' || is_fetch_from_read_only);
		$(me.input_area).toggle(true);
		this.readonlyRef.value = readonly;
		this.valueRef.value = me.value;

		if (!me.has_input) {
			me.has_input = true;
			this.create_excel();
			if (me.df.on_make) {
				me.df.on_make(me);
			}
		}
		me.set_description();
		me.set_label();
		me.set_doc_url();
		me.set_mandatory(me.value);
		me.set_bold();
		me.set_required();
	}
	create_excel() {
		const getQueries = () => this.get_queries();
		const {doctype, df, valueRef, readonlyRef} = this;
		const onChange = (json:string) => {
			if (readonlyRef.value) {
				return;
			}
			if (valueRef.value === json) {
				return;
			}
			this.parse_validate_and_set_in_model(json);
			valueRef.value = json;
		};
		const app = createApp({props: [], render: () =>h(Excel, {
			doctype, df, getQueries, onChange,
			value: valueRef.value,
			readonly: readonlyRef.value,
		})});
		app.use(ElementPlus, {size: 'small', locale: zhCn});
		app.mount(this.input_area);
		this.app = app;
		this.has_input = true;
	}
	refresh_excel() {
		const me = this;
		const {valueRef} = this;
		if (me.frm) {
			valueRef.value = frappe.model.get_value(me.doctype, me.docname, me.df.fieldname);
		} else if (me.doc) {
			valueRef.value = me.doc[me.df.fieldname] || '';
		}
	}
	validate(v?: string) {
		if (!v) {
			return '';
		}
		return v;
	}
	get_queries() {
		const args = {};
		this.set_custom_query(args);
		return args;
	}

	is_valid_value(value: any, key: string) {
		if (value) {
			return true;
		}
		// check if empty value is valid
		if (this.frm) {
			const field = frappe.meta.get_docfield(this.frm.doctype, key);
			// empty value link fields is invalid
			return !field || !['Link', 'Dynamic Link', 'Tianjy Related Link', 'Tree Select'].includes(field.fieldtype);
		}
		return value !== undefined;
	}
	set_nulls(obj: Record<string, any>) {
		$.each(obj, (key, value) => {
			if (!this.is_valid_value(value, key)) {
				delete obj[key];
			}
		});
		return obj;
	}

	set_custom_query(args: Record<string, any>) {
		if (this.get_query || this.df.get_query) {
			const get_query = this.get_query || this.df.get_query;
			if ($.isPlainObject(get_query)) {
				let filters = null;
				if (get_query.filters) {
					// passed as {'filters': {'key':'value'}}
					filters = get_query.filters;
				} else if (get_query.query) {
					// passed as {'query': 'path.to.method'}
					args.query = get_query;
				} else {
					// dict is filters
					filters = get_query;
				}

				if (filters) {
					filters = this.set_nulls(filters);

					// extend args for custom functions
					$.extend(args, filters);

					// add "filters" for standard query (search.py)
					args.filters = filters;
				}
			} else if (typeof get_query === 'string') {
				args.query = get_query;
			} else {
				// get_query by function
				const q = get_query(
					(this.frm && this.frm.doc) || this.doc,
					this.doctype,
					this.docname,
				);

				if (typeof q === 'string') {
					// returns a string
					args.query = q;
				} else if ($.isPlainObject(q)) {
					// returns a plain object with filters
					if (q.filters) {
						this.set_nulls(q.filters);
					}

					// turn off value translation
					if (q.translate_values !== undefined) {
						this.translate_values = q.translate_values;
					}

					// extend args for custom functions
					$.extend(args, q);
					args.filters = q.filters;
				}
			}
		}
		if (this.df.filters) {
			this.set_nulls(this.df.filters);
			if (!args.filters) {
				args.filters = {};
			}
			$.extend(args.filters, this.df.filters);
		}
	}
};
