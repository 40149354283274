<template>
	<div class="file-preview">
		<div class="file-icon">
			<img v-if="is_image" :src="src" :alt="file.name">
			<div class="fallback" v-else v-html="icon('file', 'md')">
			</div>
		</div>
		<div class="file-detail">
			<div class="input-container" v-if="!file.isLink">
				<input :value="filename" class="file-name-input"
					@change="rename($event.target.value)" />
				<span class="file-ext">{{ ext }}</span>
			</div>

			<div>
				<span class="file-size" v-if="file.file_obj">
					{{ file_size(file.file_obj.size) }}
				</span>
				<span class="file-size" v-else-if="file.isLink">{{ file.file_url }}</span>
			</div>

			<div class="flex config-area">
				<label v-if="optimizable" class="frappe-checkbox">
					<input type="checkbox" :checked="optimize" @change="emit('toggle_optimize')">优化
				</label>
			</div>
			<div>
				<span v-if="file.error_message" class="file-error text-danger">
					{{ file.error_message }}
				</span>
			</div>
		</div>
		<div class="file-actions">
			<ProgressRing
				v-show="file.uploading && !uploaded && !file.failed"
				primary="var(--primary-color)"
				secondary="var(--gray-200)"
				:radius="24"
				:progress="progress"
				:stroke="3"
			/>
			<div v-if="uploaded" v-html="icon('solid-success', 'lg')"></div>
			<div v-if="file.failed" v-html="icon('solid-error', 'lg')"></div>
			<div class="file-action-buttons">
				<button
					v-if="is_cropable"
					class="btn btn-crop muted"
					@click="emit('toggle_image_cropper')"
					v-html="icon('crop', 'md')"
				></button>
				<button
					v-if="!uploaded && !file.uploading && !file.failed"
					class="btn muted"
					@click="emit('remove')"
					v-html="icon('delete', 'md')"
				></button>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { ref, computed, watch } from 'vue';

import ProgressRing from './ProgressRing.vue';
import type { FileInfo } from './upload';
defineOptions({ name: 'FilePreview' });
const props = defineProps<{
	file: FileInfo
}>();
const emit = defineEmits<{
	toggle_image_cropper: [],
	remove: [],
	rename: [string],
	toggle_optimize: [],
}>();
const optimize = ref(props.file.optimize);
const {icon} = frappe.utils;


const filename = computed(() => {
	const {name} = props.file;
	const index = name.lastIndexOf('.');
	if (index <= 0) { return name; }
	return name.slice(0, index);
});
const ext = computed(() => {
	const {name} = props.file;
	const index = name.lastIndexOf('.');
	if (index <= 0) { return ''; }
	return name.slice(index);
});
function rename(name: string) {
	emit('rename', name + ext.value);
}

const uploaded = computed(() => props.file.request_succeeded);
const is_image = computed(() => {
	const {file_obj} = props.file;
	if (!file_obj) { return false; }
	return file_obj.type.startsWith('image');
});
const optimizable = computed(() => {
	const {file_obj} = props.file;
	if (!file_obj) { return false; }
	let is_svg = file_obj.type === 'image/svg+xml';
	return is_image.value && !is_svg && !uploaded.value && !props.file.failed;
});
const is_cropable = computed(() => {
	const {file_obj} = props.file;
	if (!file_obj) { return false; }
	let croppable_types = ['image/jpeg', 'image/png'];
	return !uploaded.value
		&& !props.file.uploading
		&& !props.file.failed
		&& croppable_types.includes(file_obj.type);
});
const progress = computed(() => {
	let value = Math.round((props.file.progress * 100) / props.file.total);
	if (isNaN(value)) {
		value = 0;
	}
	return value;
});

const src = computed(() => {
	if (!is_image.value) { return ''; }
	if (!window.FileReader) { return ''; }
	const file = props.file.file_obj;
	if (!file) { return ''; }
	return URL.createObjectURL(file);
});
watch(src, (_, old) => {
	if (!old) { return; }
	URL.revokeObjectURL(old);
});


function file_size(value: number) {
	return frappe.form.formatters.FileSize(value);
}
function file_name(value: string) {
	return value;
	// return frappe.utils.file_name_ellipsis(value, 9);
}

</script>

<style>
.file-preview {
	display: flex;
	align-items: center;
	padding: 0.75rem;
	border: 1px solid transparent;
}

.file-preview + .file-preview {
	border-top-color: var(--border-color);
}

.file-preview:hover {
	background-color: var(--bg-color);
	border-color: var(--dark-border-color);
	border-radius: var(--border-radius);
}

.file-preview:hover + .file-preview {
	border-top-color: transparent;
}

.file-icon {
	border-radius: var(--border-radius);
	width: 2.625rem;
	height: 2.625rem;
	overflow: hidden;
	margin-right: var(--margin-md);
	flex-shrink: 0;
}

.file-icon img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.file-icon .fallback {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid var(--border-color);
	border-radius: var(--border-radius);
}

.file-name {
	font-size: var(--text-base);
	font-weight: var(--text-bold);
	color: var(--text-color);
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.file-size {
	font-size: var(--text-sm);
	color: var(--text-light);
}

.file-actions {
	width: 3rem;
	flex-shrink: 0;
	margin-left: auto;
	text-align: center;
}

.file-actions .btn {
	padding: var(--padding-xs);
	box-shadow: none;
}

.file-action-buttons {
	display: flex;
	justify-content: flex-end;
}

.muted {
	opacity: 0.5;
	transition: 0.3s;
}

.muted:hover {
	opacity: 1;
}

.frappe-checkbox {
	font-size: var(--text-sm);
	color: var(--text-light);
	display: flex;
	align-items: center;
	padding-top: 0.25rem;
}

.config-area {
	gap: 0.5rem;
}

.file-error {
	font-size: var(--text-sm);
	font-weight: var(--text-bold);
}

.file-detail {
	flex: 1;
}

.input-container {
	display: flex;
}

.file-name-input {
	width: 100%;
}

.file-ext {
	margin-left: 5px;
}
</style>
