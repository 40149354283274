import type Ref from 'vue';
import { computed, onMounted, onUnmounted, ref } from 'vue';

const mql = matchMedia('(max-width: 738px)');

const list = new Set<Ref<boolean>>();
mql.addEventListener('change', e => {
	const {matches} = e;
	for (const r of [...list]) {
		r.value = matches;
	}
});

export default function useMatchMediaMaxWidth738() {
	const r = ref(mql.matches);
	onMounted(() => {
		list.add(r);
	});
	onUnmounted(() => {
		list.delete(r);
	});
	return computed(() => r.value);

}
