<template>
	<div>
		<ElDialog
			v-model="dialogVisible"
			v-loading="dialogLoading"
			@close="onCancel"
			:title="tt('Assessment Standard Template')"
			class="dialog"
			:width="'min(600px, max(min(300px, 100%), 50%))'"
		>
			<div class="scroll-container">
				<div v-loading="loading" class="container">
					<div class="aggrid-container ag-theme-guigu">
						<AgGridVue
							class="aggrid"
							:columnDefs="columnDefs"
							:rowData="rowData"
							:defaultColDef="defaultColDef"
							animateRows="true"
							@grid-ready="onGridReady"
							domLayout="autoHeight"
							:localeText="zhCN"
							rowSelection="single"
							scroll
							:suppressRowClickSelection="false"
							:suppressDragLeaveHidesColumns="smallMeta"
							@row-data-updated="onRowDataUpdated"
							:isRowSelectable="isRowSelectable"
						/>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<span class="create-btn">
						<ElLink
							class="cus-link-btn"
							type="primary"
							@click="create"
						>
							{{ tt('Create New Assessment Criteria') }}
						</ElLink>
					</span>
					<span class="footer-buttons">
						<ElButton @click="onCancel">{{
							tt('Cancel')
						}}</ElButton>
						<ElButton
							class="confirm-btn"
							type="primary"
							@click="confirm"
							>{{ tt('Confirm') }}</ElButton
						>
					</span>
				</div>
			</template>
		</ElDialog>
	</div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { AgGridVue } from 'ag-grid-vue3';
import { ElDialog, ElButton, ElLink, ElMessage } from 'element-plus';

import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';
import { useMetaQuery } from '../../../../../../../../guigu_pm/guigu_pm/public/js/components/page/useMetaQuery';
import type { AssessInfo } from '../type';
const tt = __;

interface Props {
	visible: boolean;
	assessInfo: AssessInfo;
	fixSelectedRows: any;
	type: 'project' | 'subCompany' | '';
}
const props = defineProps<Props>();
interface Emit {
	(e: 'update:visible', visible: boolean): void;
	(e: 'change', selected: string, label?: string): void;
	(e: 'confirm'): void;
}

const emit = defineEmits<Emit>();

const loading = ref<boolean>(false);
const dialogLoading = ref<boolean>(false);
const dialogVisible = ref<boolean>(false);
const small = ref<boolean>(true);

const params = ref<Record<string, any>>({});
const gridApi = ref(null);
const listData = ref<any[]>([]);
const smallMeta = useMetaQuery();

const columnDefs = [
	{
		headerName: __('Subject'),
		field: 'subject',
		filter: true,
		checkboxSelection: true,
		tooltipField: 'subject',
	},
	{
		headerName: __('Guigu Assessment Criteria'),
		field: 'assessment_criteria',
		tooltipField: 'assessment_criteria',
	},
	{
		headerName: __('Work Criteria'),
		field: 'work_criteria',
		tooltipField: 'work_criteria',
	},
	{
		headerName: __('Evaluation Basis'),
		field: 'evaluation_basis',
		tooltipField: 'evaluation_basis',
	},
];

const defaultColDef = {
	resizable: true,
	minWidth: 100,
};

const onGridReady = (params: any) => {
	gridApi.value = params.api;
};

watch(
	() => props.visible,
	newValue => {
		dialogVisible.value = newValue;
		if (newValue) {
			search();
		}
	},
);

async function search() {
	try {
		loading.value = true;
		const filters =
			props.type === 'project'
				? [
						['category', '=', 'Project'],
						['project', '=', props.assessInfo.department_doc.name],
				  ]
				: [
						['category', '=', 'Company'],
						['company', '=', props.assessInfo.department_doc.name],
				  ];

		const response = await frappe.db.get_list(
			'Guigu Project Work Assessment Criteria',
			{
				fields: '*',
				filters: filters,
			},
		);

		listData.value = response;
	} catch (error) {
		console.error('Error fetching data:', error);
	} finally {
		loading.value = false;
	}
}

const rowData = computed(() => listData.value);

function create() {
	const category_name = props.type === 'project' ? 'project' : 'company';
	if (category_name === 'project') {
		frappe.call({
			method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_assessment_detail.guigu_hr_assessment_detail.get_company_name',
			args: { project: props.assessInfo.department_doc.name },
			callback: function (r) {
				frappe.new_doc('Guigu Project Work Assessment Criteria', {
					category: 'Project',
					project: props.assessInfo.department_doc.name,
					company: r.message,
				});
			},
		});
	} else {
		frappe.new_doc('Guigu Project Work Assessment Criteria', {
			category: 'Company',
			project: '',
			company: props.assessInfo.department_doc.name,
		});
	}
}

async function confirm() {
	const selectedNodes = gridApi.value.getSelectedNodes();
	if (selectedNodes.length === 0) {
		ElMessage({
			showClose: true,
			message: __(
				'Please select the assessment template that needs to be applied',
			),
			type: 'error',
		});
		return;
	}

	const selectedRowsCount = selectedNodes[0]?.data;

	dialogLoading.value = true;

	try {
		await frappe.call({
			method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_assessment_detail.guigu_hr_assessment_detail.update_assessment',
			args: {
				fix_selected_rows_str: props.fixSelectedRows.value,
				update_data: selectedRowsCount,
			},
		});
		emit('confirm');
	} catch (error) {
		ElMessage({
			showClose: true,
			message: error.message || __('An error occurred'),
			type: 'error',
		});
	} finally {
		dialogLoading.value = false;
	}
}

function onCancel() {
	emit('cancel');
}
</script>

<style lang="less" scoped>
.scroll-container {
	height: 350px; /* 设定固定高度，可根据需要调整 */
	overflow-y: auto;
	padding-left: -20px; /* 保持滚动条和对话框右边缘有间距 */
}

.container {
	padding: 0;
	.aggrid-container {
		.aggrid {
			height: 100%;
			width: 100%;
		}
	}
}

.dialog-footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: calc(100% - 20px); /* 保持和ag-grid宽度一致 */
}

.create-btn {
	float: left;
}
.cus-link-btn:after {
	border-bottom: 0 !important;
}
.footer-buttons {
	display: flex;
	gap: 10px;
}
.confirm-btn {
	margin-right: -20px;
}
:deep(.dialog) .el-dialog__body {
	padding: 0 var(--el-dialog-padding-primary);
}
</style>
