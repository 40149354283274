import { Field, Column, Filter, Kanban } from './types';

async function call<T>(name: string, data: object): Promise<T> {
	return frappe.call(name, data).then((v: any) => v.message || {});

}

function parseJSON<T>(v: string): T[] {
	try {
		const j = JSON.parse(v);
		if (!j) { return []; }
		return Array.isArray(j) ? j : [j];
	} catch {
		return [];
	}
}
export async function load(doctype: string, onlyPublic?: boolean): Promise<Kanban[]> {
	const v = onlyPublic;
	const data: any = { doctype };
	if (onlyPublic) { data.onlyPublic = 1; }
	const list = await call<{
		name: string;
		title: string;
		field: string;
		fields: string;
		columns: string;
		filters: string;
		private: any;
		groups: {
			field: string;
			column: any;
			values: string;
		}[];
	}[]>('guigu_kanban.kanban.load', data);
	return list.map(v => ({
		name: v.name,
		title: v.title,
		groups: v.groups.map(g => ({
			field: g.field,
			column: Boolean(g.column),
			values: parseJSON(g.values),
		})),
		fields: parseJSON(v.fields),
		filters: parseJSON(v.filters),
		private: Boolean(v.private),
	}));
}
export async function create(
	doctype: string, { private: priv, ...data }: {
		private?: boolean;
		title?: string;
		groups?: any;
		filters?: any;
		fields?: any;
	}): Promise<string> {
	if (!priv) {
		return call('guigu_kanban.kanban.create', { ...data, doctype });
	}
	return call('guigu_kanban.kanban.create', { ...data, doctype, private: 1 });
}

export async function del(name: string) {
	return call('guigu_kanban.kanban.delete', { name });

}

export async function save(name: string, data: {
	title?: string;
	groups?: any;
	filters?: any;
	fields?: any;
}) {
	return call('guigu_kanban.kanban.save', { ...data, name });
}
export async function saveFilters(name: string, filters: any) {
	return call('guigu_kanban.kanban.save', { name, filters });
}


export async function updateDoc(doctype: string, name: string, value: any) {
	return call('guigu_kanban.kanban.update_doc', { doctype, name, value });
}
