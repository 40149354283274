<template>
	<ElDialog v-model="thisVisible" :title="tt('Batch Join Organization')">
		<div class="container">
			<el-steps :active="active" finish-status="success" align-center>
				<el-step :title="tt('Select Organization')" />
				<el-step :title="tt('Edit permissions')" />
			</el-steps>
			<el-table v-show="active === 1" :data="organizations" @selection-change="handleSelectionChange"
				style="width: 100%" max-height="700px">
				<el-table-column type="selection" width="55" />
				<el-table-column property="name" label="ID" width="150" />
				<el-table-column property="label" :label="tt('Organization')" />
			</el-table>
			<MemberForm isCreate v-show="active === 2" v-model:designations="designations"
				v-model:permissions="permissionCheckList" v-model:roles="roleCheckList"></MemberForm>
		</div>

		<template #footer>
			<div class="dialog-footer">
				<el-button @click="cancel()">{{ tt('Cancel') }}</el-button>
				<el-button v-show="active > 1" type="primary" @click="pre">
					{{ tt('Previous step') }}
				</el-button>
				<el-button :disabled="selectedOrganizations.length === 0" type="primary" @click="next">
					{{ active === 2 ? tt('Confirm') : tt('Next Steps') }}
				</el-button>
			</div>
		</template>
	</ElDialog>
</template>

<script setup lang='ts'>
import { ref, onMounted, computed, watch } from 'vue';
import type { Member, Permissions, User } from '../type';

import {
	ElDialog, ElTable, ElButton, ElTableColumn, ElCheckboxGroup, ElCheckbox,
	ElSteps, ElStep
} from 'element-plus';
import MemberForm from './MemberForm.vue';
const tt = __;
interface Props {
	visible: Boolean;
	user: string;
	memberList: Member[];
}
const props = defineProps<Props>();
interface Emit {
	(e: 'update:visible', v: boolean): void,
	(e: 'loading'): void,
}
const emit = defineEmits<Emit>();
const organizations = ref<{ label: string, name: string; }[]>([]);
const selectedOrganizations = ref<User[]>([]);
const active = ref(1);

const roleCheckList = ref<string[] | 'keep'>([]);
const permissionCheckList = ref<string[] | 'keep'>([]);
const designations = ref<string[] | 'keep'>([]);

function reset() {
	active.value = 1;
	roleCheckList.value = [];
	permissionCheckList.value = [];
	designations.value = [];
	selectedOrganizations.value = [];
}
function pre() {
	if (active.value-- < 1) active.value = 1;
}
function next() {
	if (active.value === 2) {
		confirm();
	}
	if (active.value++ > 2) active.value = 2;
}

const thisVisible = computed({
	set(v) {
		emit('update:visible', v);
	},
	get() {
		return props.visible;
	}
});
watch([() => props.user, () => [...props.memberList]], () => {
	getAllOrganizations();
}, { immediate: true });
async function getAllOrganizations() {
	const res = await frappe.call<{ message: Member[]; }>({
		method: 'tianjy_organization.tianjy_organization.page.tianjy_organization_members.tianjy_organization_members.get_all_organizations',
		args: {
			user: props.user
		}
	});
	organizations.value = res?.message || [];
}
const handleSelectionChange = (val: User[]) => {
	selectedOrganizations.value = val;
};

function cancel() {
	reset();
	thisVisible.value = false;
}
async function confirm() {
	emit('loading');
	thisVisible.value = false;
	await frappe.call<{ message: Member[]; }>({
		method: 'tianjy_organization.tianjy_organization.page.tianjy_organization_members.tianjy_organization_members.bulk_add_members',
		args: {
			user: props.user,
			organization_names: selectedOrganizations.value.map(item => item.name),
			roles: roleCheckList.value,
			permissions: permissionCheckList.value,
			designations: designations.value,
		}
	});
	reset();
}
</script>

<style lang='less' scoped>
.container {
	max-height: 550px;
	overflow: auto;
}
</style>
