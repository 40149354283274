import type {Data} from './type';
export const processLevel: string[] = [
	'',
	'Process Level 1',
	'Process Level 2',
	'Process Level 3',
	'Process Level 4',
	'Process Level 5',
	'Process Level 6',
	'Process Level 7',
	'Process Level 8',
	'Process Level 9',
	'Process Level 10',
];
// 页面按钮权限判断
export async function hasPermission() {
	const organization_unit = frappe.tianjy.organization.getCurrent();
	if (!organization_unit) {
		return false;
	}
	if (frappe.session.user === 'Administrator' || frappe.user.has_role('System Manager')) {
		return true;
	}
	const res = await frappe.db.get_list('Dw Process Manager', {
		filters: [['user', '=', frappe.session.user]],
		fields: ['user'],
		limit: 0,
	});
	return res.length > 0;
}
// 查找所有子级流程
export function findAllDescendantsProcess(list:Data[], currentName:string) {
	const descendants = [];
	const children = list.filter(item => item.parent_dw_process === currentName);
	descendants.push(...children);
	for (const child of children) {
		const childDescendants:Data[] = findAllDescendantsProcess(list, child.name);
		descendants.push(...childDescendants);
	}
	return descendants;
}
