import fieldTypes from './fieldTypes';

export interface Field {
	doctype: string;
	field: string;
	label: string;
	type: string;
	options?: string;
}

function cstr(s?: string | null) {
	if (s === null || s === undefined) { return ''; }
	return `${s}`;
}
function sort(a: any, b: any) {
	return cstr(a.label).localeCompare(cstr(b.label));
}


function to_boolean(v?: boolean | number | string): boolean {
	if (!v) { return false; }
	if (typeof v === 'boolean') { return v; }
	const s = parseInt(String(v));
	return Boolean(!isNaN(s) && s);
}
export default function build_options(
	docType: string,
) {
	const fields: Field[] = [];

	let table_fields: frappe.model.Field[] = [];
	function add_field_option(df: any) {
		// show fields where user has read access and if report hide flag is not set
		if (!frappe.perm.has_perm(docType, df.permlevel, 'read')) {
			return;
		}

		if (df.fieldname === 'docstatus' && !frappe.model.is_submittable(docType)) {
			return;
		}
		if (frappe.model.table_fields.includes(df.fieldtype)) {
			table_fields.push(df);
			return;
		}
		const { parent: doctype, fieldname: field } = df;

		const { fieldtype: type } = df;
		if (frappe.model.no_value_type.includes(type)) { return; }
		if (!fieldTypes.has(type.toLowerCase())) {
			return;
		}
		const { options, label } = df;
		fields.push({ doctype, field, label, type, options });
	}

	let main_table_fields: frappe.model.Field[] = frappe.model.std_fields.map(df => {
		var opts = { ...df, parent: docType };
		if (df.fieldname === 'name') { opts.options = docType; }
		return opts;
	});

	if (to_boolean(locals.DocType[docType]?.istable)) {
		main_table_fields.push({
			fieldname: 'parent',
			fieldtype: 'Data',
			label: 'Parent',
			parent: docType,
		});
	}

	// main ta ble
	main_table_fields = main_table_fields.concat(frappe.meta.docfield_list[docType]);
	for (const df of main_table_fields.sort(sort)) {
		add_field_option(df);
	}

	// child tables
	for (const table_df of table_fields) {
		if (!table_df.options) {
			continue;
		}
		let child_table_fields = frappe.meta.docfield_list[table_df.options] || [];

		if (table_df.fieldtype === 'Table MultiSelect') {
			const link_field = frappe.meta
				.get_docfields(table_df.options)
				.find(df => df.fieldtype === 'Link');
			child_table_fields = link_field ? [link_field] : [];
		}
		for (const df of child_table_fields.sort(sort)) {
			add_field_option(df);
		}
	}
	return fields;
}
