import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    ref: _ctx.input,
    class: "simple-input-editor",
    onInput: _cache[0] || (_cache[0] = (...args) => (_ctx.inputData && _ctx.inputData(...args))),
    style: { width: '100%' },
    onKeydown: _cache[1] || (_cache[1] = $event => (_ctx.onKeyDown($event))),
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.value) = $event))
  }, null, 544 /* HYDRATE_EVENTS, NEED_PATCH */)), [
    [_vModelText, _ctx.value]
  ])
}