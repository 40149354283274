<template>
	<el-tree-select
		class="organization_select"
		v-model="value"
		:data="data"
		multiple
		checkOnClickNode
		checkStrictly
		defaultExpandAll
		filterable
		:renderAfterExpand="false"
	/>
</template>

<script setup lang='ts'>
import { computed, onMounted, ref, watch } from 'vue';

import {list2Tree} from './helper.ts';
import type {Project, Company} from './type.ts';
interface Props{
	type:'Company'|'Project'
}
const tt = __;
const props = defineProps<Props>();
const value = ref<string>('');
const data = ref<{value:string, label:string}[]>([]);
defineExpose({
	getValue,
});
function getValue(){
	return value.value;
}
watch(()=>props.type, ()=>{
	getSelectOptions();
}, {immediate:true});
async function getSelectOptions(){
	value.value = '';
	const doctype = props.type === 'Project'?'Project':'Company';
	const res:(Project|Company)[] = await frappe.db.get_list(doctype,
		{
			fields: ['*'],
			limit: 0,
		},
	);
	const departments = res?.map(item=>({
		...item,
		value:item.name,
		parent:item.parent_company,
		label:props.type === 'Project'?item.project_abbr_name:item.company_name,
	}));
	const tree = list2Tree(departments, 'parent');
	data.value = tree;
}
</script>

<style lang='less' scoped>
.organization_select{
	width:100%;
}
</style>
