import Grid from '../../../../../../frappe/frappe/public/js/frappe/form/grid';
import GridRow from '../../../../../../frappe/frappe/public/js/frappe/form/grid_row';

Grid.prototype.render_result_rows = function ($rows, append_row){
	let result_length = this.grid_pagination.get_result_length();
	let {page_index} = this.grid_pagination;
	let {page_length} = this.grid_pagination;
	if (!this.grid_rows) {
		return;
	}
	for (let ri = (page_index - 1) * page_length; ri < result_length; ri++) {
		let d = this.data[ri];
		if (!d) {
			return;
		}
		if (d.idx === undefined) {
			d.idx = ri + 1;
		}
		if (d.name === undefined) {
			d.name = `row ${d.idx}`;
		}
		let grid_row;
		if (this.grid_rows[ri] && !append_row) {
			grid_row = this.grid_rows[ri];
			grid_row.doc = d;
			grid_row.refresh();
		} else {
			grid_row = new GridRow({
				parent: $rows,
				parent_df: this.df,
				docfields: this.docfields,
				doc: d,
				frm: this.frm,
				grid: this,
			});
			this.grid_rows[ri] = grid_row;
		}

		this.grid_rows_by_docname[d.name] = grid_row;
	}
	this.toggle_checkboxes(this.display_status !== 'Read');
};

frappe.ui.form.ControlTable.prototype.make= function(){
	frappe.ui.form.Control.prototype.make.call(this);
	// add title if prev field is not column / section heading or html
	this.grid = new Grid({
		frm: this.frm,
		df: this.df,
		parent: this.wrapper,
		control: this,
	});

	if (this.frm) {
		this.frm.grids[this.frm.grids.length] = this;
	}

	this.$wrapper.on('paste', ':text', e => {
		const table_field = this.df.fieldname;
		const {grid} = this;
		const {grid_pagination} = grid;
		const {grid_rows} = grid;
		const {doctype} = grid;
		const row_docname = $(e.target).closest('.grid-row').data('name');
		const in_grid_form = $(e.target).closest('.form-in-grid').length;

		let pasted_data = frappe.utils.get_clipboard_data(e);

		if (!pasted_data || in_grid_form) { return; }

		let data = frappe.utils.csv_to_array(pasted_data, '\t');

		if (data.length === 1 && data[0].length === 1) { return; }

		let fieldnames = [];
		// for raw data with column header
		if (this.get_field(data[0][0])) {
			for (const column of data[0]) {
				fieldnames.push(this.get_field(column));
			}
			data.shift();
		} else {
			// no column header, map to the existing visible columns
			const visible_columns = grid_rows[0].get_visible_columns();
			let target_column_matched = false;
			for (const column of visible_columns) {
				// consider all columns after the target column.
				if (
					target_column_matched ||
					column.fieldname === $(e.target).data('fieldname')
				) {
					fieldnames.push(column.fieldname);
					target_column_matched = true;
				}
			}
		}

		let row_idx = locals[doctype][row_docname].idx;
		let data_length = data.length;
		for (const [i, row] of data.entries()) {
			setTimeout(() => {
				let blank_row = !row.filter(Boolean).length;
				if (!blank_row) {
					if (row_idx > this.frm.doc[table_field].length) {
						this.grid.add_new_row();
					}

					if (row_idx > 1 && (row_idx - 1) % grid_pagination.page_length === 0) {
						grid_pagination.go_to_page(grid_pagination.page_index + 1);
					}

					const row_name = grid_rows[row_idx - 1].doc.name;
					for (const [data_index, value] of row.entries()) {
						if (fieldnames[data_index]) {
							frappe.model.set_value(
								doctype,
								row_name,
								fieldnames[data_index],
								value,
							);
						}
					}
					row_idx++;
					if (data_length >= 10) {
						let progress = i + 1;
						frappe.show_progress(
							__('Processing'),
							progress,
							data_length,
							null,
							true,
						);
					}
				}
			}, 0);
		}
		return false; // Prevent the default handler from running.
	});
};
