import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElPagination"], {
    class: "pagination",
    total: $props.total,
    currentPage: $setup.currentPage,
    "onUpdate:currentPage": _cache[0] || (_cache[0] = $event => (($setup.currentPage) = $event)),
    pageSize: $props.limit,
    disabled: $props.loading,
    background: "",
    small: "",
    pagerCount: 5,
    layout: "total, prev, pager, next, jumper"
  }, null, 8 /* PROPS */, ["total", "currentPage", "pageSize", "disabled"]))
}