import type { RangeType } from 'handsontable/common';
import Handsontable from 'handsontable';
import 'handsontable/languages/zh-CN';
import HyperFormula from 'hyperformula';

import type { Template } from './types.mjs';
import customStylesRenderer from './customStylesRenderer.mjs';
import rendererStyleMenu from './rendererStyleMenu.mjs';
import readValue from './readValue.mjs';
import toSettings from './toSettings.mjs';
import setBorder from './setBorder.mts';
import getStartRange from './getStartRange.mts';
import setType from './setType.mts';

export interface XLSXEditor {
	readonly el: HTMLElement;
	destroy(): void
	getValue(): Template;
	name: string;
	formulasEnabled: boolean;
	readonly destroyed: boolean;
	readValue(): Template;
	setValue(value: Template, readOnly?: boolean);
	readOnly: boolean;
	getData(): any[][];
	updateFormulas(): void
}

const language = (() => {
	const { lang } = frappe.boot;
	if (!lang || typeof lang !== 'string') { return 'zh-CN'; }
	const l = lang.toLowerCase();
	if (l === 'zh' || l.startsWith('zh-')) { return 'zh-CN'; }
})();

export default function create(el: HTMLElement, {
	formula: engine,
	name,
	readOnly: ro,
	inited,
	isInputMode,
	exportXLSX,
	onChange,
	onPaste,
}: {
	formula: HyperFormula;
	name?: string;
	readOnly?: boolean;
	isInputMode(): boolean;
	inited?: () => void;
	exportXLSX(name?: string): void,
	onChange: (changed: [number, number, any, any][]) => void,
	onPaste: (data: any[][], coords: RangeType[]) => void,
}, cb?: (editor: XLSXEditor) => void): XLSXEditor {
	el.style.overscrollBehavior = 'contain';
	el.style.isolation = 'isolate';
	let sheetName = name || '';


	let readOnly = Boolean(ro);
	const disabled = () => readOnly || isInputMode();

	let setting = false;
	const table: Handsontable = new Handsontable(el, {
		startRows: 8,
		startCols: 6,
		rowHeaders: true,
		colHeaders: true,
		contextMenu: {
			items: {
				row_above: { disabled },
				row_below: { disabled },
				hr0: '---------' as any,
				col_left: { disabled },
				col_right: { disabled },
				hr1: '---------' as any,
				remove_row: { disabled },
				remove_col: { disabled },
				hr2: '---------' as any,
				undo: {},
				redo: {},
				sp3: '---------' as any,
				make_read_only: { disabled },
				hr3: '---------' as any,
				alignment: { disabled },
				border: {
					disabled, name: __('Border'), submenu: {
						items: [
							{
								key: 'border:all', name: __('All Border'), callback(key, selection, clickEvent) {
									setBorder(this, selection, 'all');
								}
							},
							// { key: 'border:inner', name: __('Inner Border'), callback(key, selection, clickEvent) {
							// 	setBorder(this, selection, 'inner');
							// }},
							{
								key: 'border:outer', name: __('Outer Border'), callback(key, selection, clickEvent) {
									setBorder(this, selection, 'top');
									setBorder(this, selection, 'right');
									setBorder(this, selection, 'bottom');
									setBorder(this, selection, 'left');
								}
							},
							{
								key: 'border:top', name: __('Top Border'), callback(key, selection, clickEvent) {
									setBorder(this, selection, 'top');
								}
							},
							{
								key: 'border:right', name: __('Right Border'), callback(key, selection, clickEvent) {
									setBorder(this, selection, 'right');
								}
							},
							{
								key: 'border:bottom', name: __('Bottom Border'), callback(key, selection, clickEvent) {
									setBorder(this, selection, 'bottom');
								}
							},
							{
								key: 'border:left', name: __('Left Border'), callback(key, selection, clickEvent) {
									setBorder(this, selection, 'left');
								}
							},
							{
								key: 'border:clear', name: __('Clear Border'), callback(key, selection, clickEvent) {
									setBorder(this, selection, 'clear');
								}
							},
						]
					}
				},
				hr4: '---------' as any,

				freeze: {
					name() {
						const range = getStartRange(this);
						if (!range) { return __('Freeze Here'); }
						const [col, row] = range;
						if (!col && !row) { return __('Unfreeze'); }
						return __('Freeze Here');
					},
					disabled() {
						return disabled() || !getStartRange(this);
					},
					callback(key, selection, clickEvent) {
						const range = getStartRange(this);
						if (!range) { return; }
						const [col, row] = range;
						this.updateSettings({
							fixedColumnsStart: col,
							fixedRowsTop: row,
						});
						const s = this.getSettings();
						Object.defineProperty(s, 'freeze', {
							configurable: true,
							get() { return [col, row]; },
							enumerable: true,
						});
						this.runHooks('afterCellFreeze' as any);
					},
				},
				hr5: '---------' as any,
				copy: {},
				cut: {},
				hr6: '---------' as any,
				mergeCells: { disabled },
				resumeEvaluation: {
					disabled, name: __('Recalculate'), callback() {
						const formulas = this.getPlugin('formulas');
						if (formulas.enabled) {
							formulas.disablePlugin();
						}
						const fs = this.getSettings().formulas;
						this.updateSettings({ formulas: fs && { ...fs } });
						formulas.enablePlugin();
						// this.render();
					}
				},
				formulasEnabled: {
					disabled,
					name() {
						const formulas = this.getPlugin('formulas');
						return __(formulas.enabled ? 'Show Formulas' : 'Hide Formulas');
					},
					callback() {
						const formulas = this.getPlugin('formulas');
						const enabled = !formulas.enabled;
						if (enabled) {
							const fs = this.getSettings().formulas;
							this.updateSettings({ formulas: fs && { ...fs } });
							formulas.enablePlugin();
						} else {
							formulas.disablePlugin();
						}
						this.render();
					},
				},
				'type': {
					disabled, name: __('Data Type'), submenu: {
						items: [{
							key: 'type:numeric', name: __('Numeric'),
							callback(type, range) { setType(this, range, 'numeric'); },
						}, {
							key: 'type:text', name: __('Text'),
							callback(type, range) { setType(this, range, 'text'); },
						}]
					}
				},
				hr7: '---------' as any,
				export: {
					disabled: isInputMode, name: __('Export'),
					callback() { exportXLSX(); },
				},
				hr8: '---------' as any,
				style: {
					disabled,
					renderer() {
						return rendererStyleMenu(table, disabled());
					},
					disableSelection: false,
					isCommand: true,
				},
			},
		},
		height: '100%',
		copyPaste: true,
		trimWhitespace: false,
		manualColumnResize: true,
		manualRowResize: true,
		mergeCells: [],
		customBorders: [],
		language,
		renderer: customStylesRenderer,
		licenseKey: 'non-commercial-and-evaluation',
		// @ts-ignore
		formulas: { engine, sheetName },
		afterInit: typeof inited === 'function' ? inited : undefined,
		beforePaste: (data, coords) => {
			if (readOnly) {
				data.length = 0;
				coords.length = 0;
				return;
			}
			for (const d of data) {
				for (let i = 0; i < d.length; i++) {
					const value = d[i];
					if (!value) { continue; }
					if (typeof value !== 'string') { continue; }
					const n = Number(value.replace(/,/g, ''));
					if (Number.isNaN(n)) { continue; }
					d[i] = n;
				}
			}
		},
		afterPaste(data, coords) {
			onPaste(data, coords);
		},
		afterChange(changes, source) {
			if (setting) { return; }
			if (!changes?.length) { return; }
			onChange(changes as any);
		},
	});
	let destroyed = false;

	function initFormulas() {
		const fs = table.getSettings().formulas;
		table.updateSettings({ formulas: fs && { ...fs } });
	}
	function updateFormulas() {
		const formulas = table.getPlugin('formulas');
		if (!formulas.enabled) { return; }
		formulas.disablePlugin();
		initFormulas();
		formulas.enablePlugin();

	}
	function setValue(value: Template) {
		setting = true;
		const settings = toSettings(value, readOnly);
		const sheetId = engine.getSheetId(sheetName);
		if (typeof sheetId === 'number') {
			engine.removeSheet(sheetId);
		}
		// @ts-ignore
		table.updateSettings(settings);
		setting = false;
	}
	const editor: XLSXEditor = {
		get el() { return el; },
		get destroyed() { return destroyed; },
		destroy() {
			if (destroyed) { return; }
			destroyed = true;
			table.destroy();
			const sheetId = engine.getSheetId(sheetName);
			if (typeof sheetId === 'number') {
				engine.removeSheet(sheetId);
			}
			el.remove();

		},
		get readOnly() { return readOnly; },
		set readOnly(ro) {
			if (destroyed) { return; }
			if (readOnly === Boolean(ro)) { return; }
			readOnly = !readOnly;
			setValue(readValue(table));
		},
		getValue() { return readValue(table); },
		setValue(value, ro) {
			if (destroyed) { return; }
			if (value.readonly) {
				readOnly = true;
			} else if (typeof ro === 'boolean') { readOnly = ro; }
			setValue(value);
		},
		get formulasEnabled() { return table.getPlugin('formulas').enabled; },
		set formulasEnabled(v) {
			const enabled = Boolean(v);
			const formulas = table.getPlugin('formulas');
			if (formulas.enabled === enabled) { return; }
			if (enabled) {
				initFormulas();
				formulas.enablePlugin();
			} else {
				formulas.disablePlugin();
			}
			table.render();
		},
		updateFormulas,
		readValue() {
			const formulas = table.getPlugin('formulas');
			if (formulas.enabled) { return readValue(table, true); }
			initFormulas();
			formulas.enablePlugin();
			const value = readValue(table, true);
			formulas.disablePlugin();
			return value;
		},
		get name() { return sheetName; },
		set name(newName) {
			const name = newName || '';
			if (name === sheetName) { return; }
			const sheetId = engine.getSheetId(sheetName);
			if (typeof sheetId !== 'number') { return; }
			sheetName = name;
			engine.renameSheet(sheetId, sheetName);
		},

		getData() { return table.getData().map((v: any) => [...v as any]); },
	};

	if (typeof cb === 'function') {
		let timeout: any;
		table.addHook('afterRender', () => {
			clearTimeout(timeout);
			timeout = setTimeout(() => { cb(editor); }, 1);
		});
	}

	return editor;

}
