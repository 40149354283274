<template>
	<ElConfigProvider :locale="zh">
		<ElDatePicker
			v-model="date"
			type="date"
			valueFormat="YYYY-MM-DD"
			:placeholder="tt('Please select date')"
			size="small"
			@change="stopEditing"
		/>
	</ElConfigProvider>
</template>

<script lang="ts">
import {ref, defineComponent} from 'vue';
import {ElDatePicker, ElConfigProvider} from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn';
const tt = __;
export default defineComponent({
	props: ['params'],
	components: {
		ElDatePicker, ElConfigProvider,
	},
	setup(props) {
		const date = ref<string>('');
		const zh = ref(zhCn);
		function getValue() {
			return date.value;
		}
		function stopEditing() {
			props.params.stopEditing();
		}
		return {
			props,
			date,
			getValue,
			stopEditing,
			tt,
			zh,
		};
	},

});

</script>

<style lang="less" scoped>
:deep(.el-select) {
	line-height: 32px;
}
</style>
