<template>
	<div class="tool_container">
		<div class="pm_toolbar">
			<div class="input-container">
				{{ tt('Company') }}:
				<ElSelect class="input" filterable v-model="companyValue" size="small" @change="value => confirmChange('company', value)">
					<ElOption v-for="item in companies" :key="item.name" :label="item.company_name" :value="item.name" />
				</ElSelect>
			</div>
			<div class="input-container">
				{{ tt('Project') }}:
				<ElSelect class="input"
					filterable
					v-model="projectValue"
					size="small"
					popperClass="projectLink"
					@change="value => confirmChange('project', value)">
					<ElOption
						v-for="item in projects"
						:key="item.name"
						:label="item.project_abbr_name || item.project_name"
						:value="item.name">
						<p>
							<strong>{{ item.project_abbr_name || item.project_name }}</strong>
							<span>{{ item.description }}</span>
						</p>
					</ElOption>
				</ElSelect>
			</div>
			<div class="input-container">
				{{ tt('Date') }}:
				<ElDatePicker
					v-model="monthValue"
					:teleported="false"
					:clearable="false"
					:editable="false"
					type="month"
					valueFormat="YYYY-MM-DD"
					:disabledDate="disabledDate"
					@change="value => confirmChange('month', value)"
					size="small" />
			</div>
		</div>
		<div class="completed_qty_btn">
			<ElButtonGroup class="boq_button_group">
				<ElButton size="small" @click="()=>emit('updateIsExpand',true)">{{ tt('Expand') }}</ElButton>
				<ElButton size="small" @click="()=>emit('updateIsExpand',false)">{{ tt('Collapse') }}</ElButton>
			</ElButtonGroup>
			<ElButton type="primary" size="small" :disabled="!isModified" @click="clickSave">
				{{ tt('Save') }}
			</ElButton>
		</div>
	</div>
</template>

<script setup lang="ts">
import {onMounted, ref, watch} from 'vue';
import {ElDatePicker, ElButton, ElSelect, ElOption, ElMessageBox, ElButtonGroup} from 'element-plus';
import moment, {type Moment} from 'moment';

import type {Company, Projects} from '../type';

const emit = defineEmits<{(event: 'updateIsModified'): void,
	(event:'updateIsExpand', value:boolean):void,
	(event: 'updateAndSubmitData', params: {
		source: string}):void,
	(event: 'getQueryParams', params: {
		company: string;
		project: string;
		month: string;
		projectName: string;
	}): void,
}>();
const props = defineProps<{
	isModified: boolean;
	company: string;
	project: string;
	month: string;
}>();
const tt = __;
const companies = ref<Company[]>([]);
const projects = ref<Projects[]>([]);
const companyValue = ref<string>(props.company || '');
const projectValue = ref<string>(props.project || '');
const monthValue = ref<string>(props.month || '');
const disabledDate = (time: Moment) => time > moment().startOf('month');

/**
 * 更新查询参数
 */
function updateParams() {
	const projectName = projects.value.find(item => item.name === projectValue.value)?.project_abbr_name || '';
	emit('getQueryParams', {
		company: companyValue.value,
		project: projectValue.value,
		month: monthValue.value,
		projectName,
	});
}
/**
 * 获取项目列表
 * 过滤条件：已选择公司下的项目
 */
async function getProjects(searchProject?: string) {
	const projectRes: Projects[] = await frappe.db.get_list('Project', {
		filters: [['company', '=', companyValue.value]],
		fields: ['name', 'project_abbr_name', 'project_name', 'project_construction_status'],
		limit: 0,
	});
	if (projectRes.length === 0) {
		projects.value = [];
		return;
	}
	projects.value = projectRes.map(item=>{
		item.description = `${item.name},${__(item.project_name)}${item.project_construction_status ? ',' : ''}${__(item.project_construction_status)}`;
		return item;
	});
	if (projectValue.value === '') {
		const projectExists = projects.value.some(item => item.name === searchProject);
		projectValue.value = projectExists ? (searchProject || '') : projects.value[0].name;
	}
	updateParams();
}
/**
 * 获取公司列表
 */
async function getCompanies() {
	const companiesRes: Company[] = await frappe.db.get_list('Company', {
		fields: ['name', 'company_name'],
		limit: 0,
	});
	companies.value = companiesRes;
	if (companyValue.value === '') {
		companyValue.value = companies.value[0]?.company_name || '';
	}
	getProjects();
}
function clickSave() {
	emit('updateAndSubmitData', {source: 'tools'});
}

// 应用更改的方法
function applyChange(type: 'company' | 'project' | 'month', newValue: string) {
	if (type === 'company') {
		companyValue.value = newValue;
		projectValue.value = '';
		getProjects();
	} else if (type === 'project') {
		projectValue.value = newValue;
	} else if (type === 'month') {
		monthValue.value = newValue;
	}
	updateParams();
}

// 恢复之前的值的方法
function revertChange(type: 'company' | 'project' | 'month') {
	if (type === 'company') {
		companyValue.value = props.company;
	} else if (type === 'project') {
		projectValue.value = props.project;
	} else if (type === 'month') {
		monthValue.value = props.month;
	}
}
/**
 * 查询条件改变时确认
 * @param type
 * @param value
 */
function confirmChange(type: 'company' | 'project' | 'month', value: string) {
	if (props.isModified) {
		ElMessageBox.confirm(
			__('Data has not been saved, are you sure you want to leave?'),
			__('please confirm'),
			{
				confirmButtonText: __('Confirm'),
				cancelButtonText: __('Cancel'),
				type: 'warning',
			},
		).then(() => {
			applyChange(type, value);
			emit('updateIsModified');
		}).catch(() => {
			revertChange(type);
		});
	} else {
		applyChange(type, value);
	}
}
// 监听参数变化
watch(
	[companyValue, projectValue, monthValue],
	([newCompany, newProject, newMonth]) => {
		const queryObj = new URLSearchParams({
			company: newCompany || '',
			project: newProject || '',
			month: newMonth || '',
		});
		history.replaceState(null, '', `?${queryObj.toString()}`);
	},
);
// 获取路径参数
function getSearchParams() {
	const params = new URLSearchParams(window.location.search);
	const searchCompany = params.get('company');
	const searchProject = params.get('project') || '';
	if (searchCompany) {
		companyValue.value = searchCompany;
		getProjects(searchProject);
	}
	if (params.get('month')) {
		monthValue.value = params.get('month') || '';
	}
	updateParams();
}
onMounted(async () => {
	getCompanies();
	getSearchParams();
});
</script>

<style lang="less" scoped>
  .tool_container {
    display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 8px;
	border-bottom: 1px solid #ccc;

	.pm_toolbar {
		display: flex;
		flex-direction: row;

		.input-container {
			white-space: nowrap;
			margin-right: 10px;
		}
	}
	.completed_qty_btn{
		.boq_button_group {
			margin-right: 20px;
		}
	}
  }
    @media (min-width: 640px) {
	.input-container  {
		display: flex;
		margin-right: 8px;
	}
	.completed_qty_btn{
		float: right
	}
}

@media (max-width: 640px) {
	.input-container  {
		width: 100%;
	}
	.completed_qty_btn{
		float: none;
		padding-top: 12px;
	}
}
  .projectLink {
	.el-select-dropdown__item {
		height: 59px !important;
	}
	.el-select-dropdown__item > p{
		display: flex;
		flex-direction: column;

	}
	.el-select-dropdown__item > p > span {
		font-size: 12px;
		line-height: 1em;
	}
  }
</style>
