<template>
	<div :class="['ViewPage-sidebar', open ? 'ViewPage-sidebar-open' : null]">
		<div class="ViewPage-sidebar-main">
			<slot />
		</div>
		<div class="ViewPage-sidebar-close" @click="close"></div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	props: ['open'],
	setup(p, ctx) {
		function close() {
			ctx.emit('close')
			ctx.emit('update:open', false)
		}
		return {
			close
		}
	}
})
</script>
<style scoped>
.ViewPage-sidebar {
	display: none;
	flex: 0 0 16.6666666667%;
	max-width: 16.6666666667%;
	font-size: var(--text-md);
	padding-right: 30px;
}

.ViewPage-sidebar.ViewPage-sidebar-open {
	display: block;
}

@media (max-width: 991px) {
	.ViewPage-sidebar {
		display: block;
		flex: unset;
		max-width: unset;
	}
}

.ViewPage-sidebar-close {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	opacity: 0.3;
	background: #000;
	z-index: 1041;
	height: 100%;
	width: 100%;
	transition: transform .5s;
}

@media (max-width: 991px) {
	.ViewPage-sidebar-main {
		margin-top: 0 !important;
		position: fixed;
		background: var(--fg-color);
		top: 0;
		left: 0;
		transform: translateX(-110%);
		transition: transform .5s;
		z-index: 9999;
		box-shadow: var(--shadow-base);
		height: 100%;
		width: 40%;
		display: block !important;
		transition: transform 200ms ease-in-out;
		padding: var(--padding-md);
	}

	.ViewPage-sidebar-open .ViewPage-sidebar-close {
		display: block;
	}

	.ViewPage-sidebar-open .ViewPage-sidebar-main {
		transform: translateX(0);
		overflow-y: auto;
	}
}

@media (max-width: 767px) {
	.ViewPage-sidebar-main {
		width: 60%;
	}

}
</style>
