<template>
	<ElFormItem>
		<ElTable :data="fields" style="width: 100%">
			<ElTableColumn type="index" :index="1" />
			<ElTableColumn :label="tt('Field')" #="{ row, $index }">
				<ElSelect :modelValue="row.date" filterable defaultFirstOption
					@update:modelValue="set($index, 'date', $event)">
					<ElOption v-for="field in fieldOptions" :key="field.value"
						:value="field.value" :label="field.label" />
				</ElSelect>
			</ElTableColumn>
			<ElTableColumn :label="tt('Display control field')" #="{ row, $index }">
				<ElSelect :modelValue="row.filter_field"
					@update:modelValue="set($index, 'filter_field', $event)" clearable>
					<ElOption v-for="field in filterFields" :key="field.value"
						:value="field.value" :label="field.label" />
				</ElSelect>
			</ElTableColumn>
			<ElTableColumn :label="tt('Displays control field values')" #="{ row, $index }">
				<ElSelect
					:modelValue="getList(row.filter_value)" filterable defaultFirstOption
					@update:modelValue="set($index, 'filter_value', setList($event))"
					multiple allowCreate :reserveKeyword="false">
					<ElOption :label="tt('Manual input')" value="" disabled />
				</ElSelect>
			</ElTableColumn>

			<ElTableColumn :label="tt('Color')"
				#="{ row, $index }">
				<ElColorPicker :modelValue="row.color || ''"
					@update:modelValue="set($index, 'color', $event || null)" />
			</ElTableColumn>
			<ElTableColumn :label="tt('Size')"
				#="{ row, $index }">
				<ElInput type="number" :modelValue="row.size"
					@update:modelValue="setWidth($index, 'size', $event || 0)" />
			</ElTableColumn>
			<ElTableColumn :label="tt('Position')"
				#="{ row, $index }">
				<ElInput type="number" :modelValue="row.position"
					@update:modelValue="setWidth($index, 'position', $event || 0)" />
			</ElTableColumn>
			<ElTableColumn :label="tt('Operation')" #="{ $index }">
				<ElButton type="danger" @click="remove($index)" text :icon="Delete"
					:title="tt('Delete')" />
			</ElTableColumn>
		</ElTable>
		<ElButton @click="add">{{ tt('Add') }}</ElButton>

	</ElFormItem>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import {
	ElTable, ElTableColumn,
	ElSelect, ElInput, ElColorPicker,
	ElFormItem, ElButton, ElOption,
} from 'element-plus';
import {Delete} from '@element-plus/icons-vue';

const props = defineProps<{
	meta: locals.DocType;
	modelValue: any;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: GlobalView.View): void;
}>();
const view = computed({
	get: () => props.modelValue,
	set: v => emit('update:modelValue', v),
});
const tt = __;

const fields = computed<any[]>({
	get: () => view.value.dots || [],
	set: v => {
		view.value = {...view.value, dots: v};
	},
});
function set(index: number, key: string, value: any) {
	const list = [...fields.value];
	const item: any = list[index];
	if (!item) {
		return;
	}
	item[key] = value;
	fields.value = list;
}
function setWidth(index: number, key: any, value: any) {
	const list = [...fields.value];
	const item: any = list[index];
	if (!item) {
		return;
	}
	item[key] = Math.max(0, parseInt(value) || 0);
	fields.value = list;
}

function add() {
	fields.value = [...fields.value, {}];
}
function remove(index: number) {
	const list = [...fields.value];
	if (!list.splice(index, 1).length) {
		return;
	}
	fields.value = list;
}
function move(from: number, to: number) {
	if (from === to) {
		return;
	}
	const list = [...fields.value];
	const item: any = list[from];
	if (!item) {
		return;
	}
	list.splice(from, 1);
	list.splice(to, 0, item);
	fields.value = list;
}
function getList(v?: string | string[]) {
	if (typeof v === 'string') {
		return v.split('\n').filter(Boolean);
	}
	if (Array.isArray(v)) {
		return v.filter(Boolean);
	}
	return [];
}
function setList(v?: string | string[]) {
	if (typeof v === 'string') {
		return v.split('\n').filter(Boolean).join('\n');
	}
	if (Array.isArray(v)) {
		return v.filter(Boolean).join('\n');
	}
	return '';
}
const fieldOptions = computed(() => {
	const fields = props.meta.fields || [];
	return fields
		.filter(d => ['Date', 'Datetime'].includes(d.fieldtype))
		.map(d => ({
			value: d.fieldname,
			label: `${__(d.label)} (${d.fieldname})`,
		}));
});
const filterFields = computed(() => {
	const fields = props.meta.fields || [];
	return fields
		.filter(d => ['Select'].includes(d.fieldtype))
		.map(d => ({value: d.fieldname, label: `${__(d.label)} (${d.fieldname})`}));
});
</script>
