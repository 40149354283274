import showSender from './showSender';
import isSendable from './isSendable';

async function refresh(frm: frappe.ui.form.Form): Promise<void> {
	if (frm.is_new()) { return; }
	const promise = isSendable(frm.doctype);
	// @ts-ignore
	frm.sendablePromise = promise;
	// @ts-ignore
	if (!await promise || promise !== frm.sendablePromise) { return; }
	if (frm.is_new()) { return; }
	frm.add_custom_button('Send Wechat Remind', () => { showSender(frm.doc as any); });

}

const { prototype } = frappe.ui.form.ScriptManager;
const old = prototype.get_handlers;
prototype.get_handlers = function get_handlers(...p) {
	const handlers = old.call(this, ...p);
	const [event_name] = p;
	if (event_name === 'refresh') {
		handlers.new_style.push(refresh);
	}
	return handlers;
};
