<template>
	<div v-show="fileViewStore.showMode.includes('iconMode')"
		id="file-container_timeline"
		v-loading="loading || deleteLoading" v-viewer
		ref="el">
		<FileItem
			v-show="hasFolderSelected"
			v-for="file in dataState"
			@clickFolder="clickFolder"
			@selectFile="selectFile"
			@refresh="emit('refresh')"
			@delete="deleteFileDoc"
			:data="file" :folderNameField="folderNameField"
			:isFolderField="isFolderField" :fileNameField="fileNameField"
			:meta="meta" :selected="selected" :permission="permission">
		</FileItem>
	</div>
	<div v-show="fileViewStore.showMode.includes('tableMode')"
		id="file-container_table"
		v-loading="loading || deleteLoading" v-viewer>
		<DefaultMain
			:sort="sort" :meta="meta" :options="options" :data="dataState"
			:total="total" :modelValue="modelValue"
			:infiniteScroll="infiniteScroll" :page="page"
			:limit="limit" :group="group"
			:view="view"
			:detail="detail"
			:configuration="listConfig"
			:rowAction="rowAction"
			@refresh="emit('refresh')"
			@delete="emit('delete', $event)"
			@create="(data, extend) => emit('create', data, extend)"
			@nextPage="emit('nextPage')"
			@update:modelValue="emit('update:modelValue', $event)"
			@update:selected="emit('update:selected', $event)"
			@update:data="emit('update:data', $event)"
			@filter="(field, operator, value) => emit('filter', field, operator, value)"
			@sort="(target, docs, before, field) => emit('sort', target, docs, before, field)">
		</DefaultMain>
	</div>
	<DeleteFolderDialog v-loading="deleteLoading" :isFolderField="isFolderField"
		:folderNameField="folderNameField" :parentField="parentField"
		:meta="meta"
		:visible="visible" :folder="deleteFolder"
		@deleteFolder="deleteFolderFn"></DeleteFolderDialog>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';

import { useInfiniteScroll } from '@vueuse/core';
import { ElMessage, ElMessageBox } from 'element-plus';

import { Filter } from '../GlobalView/types';
import DefaultMain from '../DefaultMain';

import DeleteFolderDialog from './DeleteFolderDialog.vue';
import FileItem from './FileItem.vue';
import TableRowAction from './TableRowAction.vue';
import { useFileViewStore } from './stores/fileView';
import { Config, FileViewConfig } from './type';

window.addEventListener('storage', e => {
	if (e.key && e.key === 'deleteFile' && e.newValue) {
		const isLoading = e.newValue.includes('true');
		deleteLoading.value = isLoading;
		if (isLoading) { return; }
		if (e.newValue.includes('error')) { deleteLoading.value = false; return; }
		const arr = e.newValue.split('_');
		const [_, name] = arr;
		const dataArr = dataState.value.filter(item => item.name !== name);
		dataState.value = dataArr;
	}
	if (e.key && e.key === 'refresh' && e.newValue) {
		emit('refresh');
	}
});

const el = ref<HTMLElement>();
const props = defineProps<{
	meta: locals.DocType;
	options: Record<string, any>;

	data: locals.Doctype[];
	total: number;
	loading?: boolean;

	modelValue: any;
	selected: any[];

	infiniteScroll: boolean;
	page: number;
	limit: number;
	group: GlobalView.Group[];
	sort: GlobalView.MainLoaderOptions['order'];
	filters: Filter[]

	detail?: boolean;
	rowAction?: any;


	view?: GlobalView.View;
	configuration: Config;
}>();

const emit = defineEmits<{
	(event: 'refresh'): void;
	(event: 'delete', doc: any): void;
	(event: 'create', data: any, extend?: boolean): void;
	(event: 'nextPage'): void;
	(event: 'update:modelValue', value: any): void;
	(event: 'update:selected', value: any[]): void;
	(event: 'update:data', data: locals.Doctype[]): void;
	(event: 'filter', field: string, operator: string, value: any): void;
	(event: 'update:filters', data: Filter[]): void


	(event: 'sort', target: string, docs: string[], before: boolean, field?: string): void
	(event: 'sort', target: string, docs: string[], before: boolean, children?: boolean): void
}>();

const dataState = ref<locals.Doctype[]>([]);
const visible = ref<boolean>(false);
const deleteFolder = ref<any>();
const deleteLoading = ref<boolean>(false);
const fileViewStore = useFileViewStore();

const listConfig = computed(() => ({
	infiniteScroll: true,
	fieldsDisplayed: [
		{
			field: isFolderField,
		},
		{
			field: fileNameField,
		},
		{
			field: folderNameField,
		}, {
			field: parentField,
		},
	],
}));
watch(() => props.data, () => {
	dataState.value = props.data;
});
onMounted(() => {
	const timeLineContainer = $('#file-container_timeline');
	$(timeLineContainer).on('hide', e => { e.stopPropagation(); });
	const tableContainer = $('#file-container_table');
	$(tableContainer).on('hide', e => { e.stopPropagation(); });
});

useInfiniteScroll(
	el,
	() => {
		// load more
		if (props.data.length === props.total) { return; }
		emit('nextPage');
	},
	{ distance: 10 }
);

const {
	isFolderField, fileNameField, folderNameField, parentField,
} = computed(() => {
	const { isFolderField, fileNameField, folderNameField, parentField } = props.configuration || {};
	return { isFolderField, fileNameField, folderNameField, parentField };
}).value;

const hasFolderSelected = computed(() => {
	const parentFilter = props.filters.find(item => item.field === parentField);
	const dataInParent = props.data.every(item => item[parentField] === parentFilter?.value);
	const selectAll = parentFilter?.value === 'not set';
	return (parentFilter && dataInParent || selectAll);
});

const permission = computed(() => {
	const writePermission = frappe.perm.has_perm(props.meta.name, 0, 'write');
	const deletePermission = frappe.perm.has_perm(props.meta.name, 0, 'delete');
	const exportPermission = frappe.perm.has_perm(props.meta.name, 0, 'export');

	return { write: writePermission, delete: deletePermission, export: exportPermission };
});

function clickFolder(folderName: string) {
	const filters = props.filters.filter(item => item.field !== parentField);
	emit('update:selected', []);
	if (!parentField) { return; }
	emit('update:filters', [
		...filters, {
			doctype: props.meta.name,
			field: parentField,
			condition: '=',
			value: folderName,
		},
	]);
}
function selectFile(isSelected: boolean, file: any) {
	if (isSelected) {
		const selectedArr = props.selected.filter(item => item.name !== file.name);
		selectedArr.push(file);
		emit('update:selected', selectedArr);
	} else {
		const selectedArr = props.selected.filter(item => item.name !== file.name);
		emit('update:selected', selectedArr);
	}
}
async function deleteFolderFn(deleteOption: { action: string, parent?: string }) {
	visible.value = false;
	deleteLoading.value = true;
	const { action, parent } = deleteOption;
	const data = await frappe.call<{ message: any }>({
		method: 'guigu.fileview.fileview.delete_folder',
		args: { doctype: props.meta.name, parent_field: parentField, name: deleteFolder.value?.name, action, parent },
		error() {
			deleteLoading.value = false;
		},
	});
	const timeStamp = (new Date).getTime();
	fileViewStore.setUpdatedFolder(`${timeStamp}_${deleteFolder.value.name || ''}`);
	const dataArr = dataState.value.filter(item => item.name !== deleteFolder.value.name);
	dataState.value = dataArr;
	deleteLoading.value = false;
}
function deleteFileDoc(data: locals.Doctype) {
	if (data[isFolderField] === 1) {
		visible.value = true;
		deleteFolder.value = data;
	} else {
		ElMessageBox.confirm(
			'您确认删除此文件吗?',
			'请确认',
			{
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}
		).then(async () => {
			deleteLoading.value = true;
			frappe.call({
				method: 'frappe.client.delete',
				args: {
					doctype: props.meta.name,
					name: data.name,
				},
				error() {
					deleteLoading.value = false;
				},
				callback() {
					const dataArr = dataState.value.filter(item => item.name !== data.name);
					dataState.value = dataArr;
					deleteLoading.value = false;
				},
			});
		}).catch(() => {
			deleteLoading.value = false;
			ElMessage({
				type: 'info',
				message: '取消删除',
			});
		});
	}
}

const popstateListener = function (event) {
	emit('refresh');
};
onMounted(() => {
	window.addEventListener('popstate', popstateListener);
});
onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});

</script>

<style scoped lang="less">
#file-container_timeline {
	overflow-y: scroll;
	height: 100%;
}

#file-container_table {
	overflow-y: scroll;
	height: 100%;
}
</style>
