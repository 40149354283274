<template>
	{{ percent }}
</template>

<script setup lang='ts'>
import { computed } from 'vue';

interface Props {
	data: Record<string, any>;
	field: GlobalView.FieldRenderOptions;
	format?: string;
	value: any

}
const props = defineProps<Props>();

const percent = computed(() => {
	const precision = props.field?.precision || cint(frappe.boot.sysdefaults.float_precision, null);
	const value = isNaN(parseFloat(props.value)) ? null : flt(props.value, precision);
	return `${value}%`;
});
</script>

<style lang='less' scoped>
</style>
