
<template>
	<Page>
		<template #title>
			<h3 class="title">{{ tt('Guigu Project Monthly Boq Completed Quantity') }}</h3>
			<span class="indicator-pill whitespace-nowrap orange" v-show="isModified">
				{{ tt('Not Saved') }}
			</span>
		</template>
		<template #tools>
			<Tools
				@getQueryParams="getQueryParams"
				@updateAndSubmitData="updateAndSubmitData"
				@updateIsModified="isModified =false"
				@updateIsExpand="updateIsExpand"
				v-model:isModified="isModified"
				v-model:company="company"
				v-model:project="project"
				v-model:month="month"
			></Tools>
		</template>
		<div v-loading="loading" class="table-container" id="guigu_project_monthly_boq_completed_quantity">
			<Table
				ref="table"
				:dataList="dataList"
				:isExpand="isExpand"
				:hotName="hotName"
				@updateAndSubmitData="updateAndSubmitData"
			></Table>
		</div>
	</Page>
</template>

<script setup lang="ts">
import {onMounted, onUnmounted, ref} from 'vue';
import {vLoading} from 'element-plus';
import moment from 'moment';

import Page from '../../../../../../../guigu/guigu/public/js/components/page/index.vue';

import {setData} from './components/helper';
import type {QueryParams, SaveData, BoqData, ExposedMethods} from './type';
import Table from './components/Table.vue';
import Tools from './components/Tools.vue';

const company = ref<string>('');
const project = ref<string>('');
const month = ref<string>(moment().startOf('month').format('YYYY-MM-DD'));
const loading = ref<boolean>(false);
const dataList = ref<BoqData[]>([]);
const isModified = ref<boolean>(false);
const SavedData = ref<SaveData[]>([]);
const isExpand = ref<boolean>(true);
const hotName = ref<string>('');
const table = ref<ExposedMethods>();
const tt = __;

/**
 * 保存数据
 * @return 无
 */
async function saveData() {
	loading.value = true;
	await frappe.call({
		method: 'guigu_pm.guigu_pm_boq.page.guigu_project_monthly_boq_completed_quantity.guigu_project_monthly_boq_completed_quantity.save_data',
		args: {
			company: company.value,
			project: project.value,
			month: month.value,
			data: SavedData.value,
		},
	});
	loading.value = false;
}
async function getList() {
	if (!project.value || !company.value || !month.value) {
		return;
	}
	loading.value = true;
	const res = await frappe.call<{ message:[] }>({
		method: 'guigu_pm.guigu_pm_boq.page.guigu_project_monthly_boq_completed_quantity.guigu_project_monthly_boq_completed_quantity.get_boq_data',
		args: {
			company: company.value,
			project: project.value,
			month: month.value,
		},
	});
	loading.value = false;
	dataList.value = setData(res?.message || [], 'parent_guigu_boq_item', 'name');
}
/**
 * 接受子组件传参
 * @param data
 * @return 无
 */
function getQueryParams(data:QueryParams) {
	company.value = data.company;
	project.value = data.project;
	month.value = data.month;
	hotName.value = `${data.projectName}-${moment(data.month).format('YYYY年MM月')}-${__('Monthly Boq Completed Quantity')}`;
	getList();
}
/**
 * 更新要保存的完工数据或者提交数据
 * @param data
 * @return 无
 */
async function updateAndSubmitData(data: any) {
	// 更新数据
	if (data.source === 'table') {
		isModified.value = true;
		SavedData.value = data.savedData;
		return;
	}
	debugger;
	// 保存数据之前校验数据
	if (SavedData.value.some(item => !/^-?\d+(\.\d+)?$/.test(item.quantity))) {
		frappe.msgprint({
			title: __('Incorrect Data'),
			indicator: 'red',
			message: __('The number of completions must be valid figures'),
		});
		return;
	}
	// 点击保存，提交数据
	isModified.value = false;
	await saveData();
	frappe.show_alert({
		message: __('Save Successfully'),
		indicator: 'green',
	}, 2);
}

function updateIsExpand(value: boolean) {
	if (value) {
		table.value?.expand();
	} else {
		table.value?.collapse();
	}
}

const popstateListener = function () {
	getList();
};
onMounted(() => {
	window.addEventListener('popstate', popstateListener);
});
onUnmounted(() => {
	window.removeEventListener('popstate', popstateListener);
});
</script>

<style lang="less" scoped>
  .title {
    height: 75px;
    line-height: 75px;
    margin-bottom: 0;
	margin-right: 8px;
  }
  .table-container {
    height: 100%;
	overflow: hidden;
  }
</style>
