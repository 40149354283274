import Handsontable from 'handsontable';

export function getNewTitle(titles:string[], newTitle:string):string{
	if (titles.includes(newTitle)){
		const res = newTitle.match(/\d+$/);
		let title = newTitle;
		if (res){
			title = `${title.substring(0, res.index||title.length)}${(parseInt(res[0])+1)}`;
		} else {
			title = `${title}${1}`;
		}
		return getNewTitle(titles, title);
	}
	return newTitle;
}
export function defaultSourceDataFactory(){
	return [
		[
			null,
			null,
			null,
			null,
			null,
		],
		[
			null,
			null,
			null,
			null,
			null,
		],
		[
			null,
			null,
			null,
			null,
			null,
		],
		[
			null,
			null,
			null,
			null,
			null,
		],
		[
			null,
			null,
			null,
			null,
			null,
		],
	];
}
export function defaultCellsMetaDataFactory(){
	return [
		{
			'visualRow': 0,
			'visualCol': 0,
			'row': 0,
			'col': 0,
			'prop': 0,
		},
		{
			'visualRow': 0,
			'visualCol': 1,
			'row': 0,
			'col': 1,
			'prop': 1,
		},
		{
			'visualRow': 0,
			'visualCol': 2,
			'row': 0,
			'col': 2,
			'prop': 2,
		},
		{
			'visualRow': 0,
			'visualCol': 3,
			'row': 0,
			'col': 3,
			'prop': 3,
		},
		{
			'visualRow': 0,
			'visualCol': 4,
			'row': 0,
			'col': 4,
			'prop': 4,
		},
		{
			'visualRow': 1,
			'visualCol': 0,
			'row': 1,
			'col': 0,
			'prop': 0,
		},
		{
			'visualRow': 1,
			'visualCol': 1,
			'row': 1,
			'col': 1,
			'prop': 1,
		},
		{
			'visualRow': 1,
			'visualCol': 2,
			'row': 1,
			'col': 2,
			'prop': 2,
		},
		{
			'visualRow': 1,
			'visualCol': 3,
			'row': 1,
			'col': 3,
			'prop': 3,
		},
		{
			'visualRow': 1,
			'visualCol': 4,
			'row': 1,
			'col': 4,
			'prop': 4,
		},
		{
			'visualRow': 2,
			'visualCol': 0,
			'row': 2,
			'col': 0,
			'prop': 0,
		},
		{
			'visualRow': 2,
			'visualCol': 1,
			'row': 2,
			'col': 1,
			'prop': 1,
		},
		{
			'visualRow': 2,
			'visualCol': 2,
			'row': 2,
			'col': 2,
			'prop': 2,
		},
		{
			'visualRow': 2,
			'visualCol': 3,
			'row': 2,
			'col': 3,
			'prop': 3,
		},
		{
			'visualRow': 2,
			'visualCol': 4,
			'row': 2,
			'col': 4,
			'prop': 4,
		},
		{
			'visualRow': 3,
			'visualCol': 0,
			'row': 3,
			'col': 0,
			'prop': 0,
		},
		{
			'visualRow': 3,
			'visualCol': 1,
			'row': 3,
			'col': 1,
			'prop': 1,
		},
		{
			'visualRow': 3,
			'visualCol': 2,
			'row': 3,
			'col': 2,
			'prop': 2,
		},
		{
			'visualRow': 3,
			'visualCol': 3,
			'row': 3,
			'col': 3,
			'prop': 3,
		},
		{
			'visualRow': 3,
			'visualCol': 4,
			'row': 3,
			'col': 4,
			'prop': 4,
		},
		{
			'visualRow': 4,
			'visualCol': 0,
			'row': 4,
			'col': 0,
			'prop': 0,
		},
		{
			'visualRow': 4,
			'visualCol': 1,
			'row': 4,
			'col': 1,
			'prop': 1,
		},
		{
			'visualRow': 4,
			'visualCol': 2,
			'row': 4,
			'col': 2,
			'prop': 2,
		},
		{
			'visualRow': 4,
			'visualCol': 3,
			'row': 4,
			'col': 3,
			'prop': 3,
		},
		{
			'visualRow': 4,
			'visualCol': 4,
			'row': 4,
			'col': 4,
			'prop': 4,
		},
	];
}


export const menuFactory=function(writePermission:boolean, afterTypeChange:()=>void){
	if (!writePermission){
		return ['row_above', 'row_below', '---------', 'col_left', 'col_right' ];
	}
	return {
		items:{
			'row_above':{},
			'row_below':{},
			sp1: '---------',
			'col_left':{},
			'col_right':{},
			sp2: '---------',
			'remove_row':{},
			'remove_col':{},
			'---------':{},
			'undo':{},
			'redo':{},
			sp3: '---------',
			'make_read_only':{},
			sp4: '---------',
			'alignment':{},
			'copy':{},
			'cut':{},
			'mergeCells':{},
			'type':{
				name:'类型',
				submenu:{
					items:[
						{
							key:'type:number',
							name:'数字',
							callback(type:string, range:{start:{row:number, col:number}, end:{row:number, col:number}}[]){
								for (const item of range) {
									const startRow = item.start.row;
									const endRow = item.end.row;
									const startCol = item.start.col;
									const endCol = item.end.col;
									for (let row =startRow; row<=endRow; row++ ){
										for (let col =startCol; col<=endCol; col++){
											this.setCellMetaObject(row, col, {
												type:'numeric',
												validator:undefined,
												className:'htRight',
												numericFormat:{
													pattern: '0,0.00',
												},
												renderer:'numeric',
												editor:'numeric',
											});
										}
									}
									// this.render();
									afterTypeChange();
								}
							},
						},
						{
							key:'type:text',
							name:'文本',
							callback(type:string, range:{start:{row:number, col:number}, end:{row:number, col:number}}[]){
								for (const item of range) {
									const startRow = item.start.row;
									const endRow = item.end.row;
									const startCol = item.start.col;
									const endCol = item.end.col;
									for (let row =startRow; row<=endRow; row++ ){
										for (let col =startCol; col<=endCol; col++){
											this.setCellMetaObject(row, col, {
												type:'text',
												validator:undefined,
												numericFormat:undefined,
												className:'htLeft',
												renderer:'text',
												editor:'text',
												dataType:'text',
											});
										}
									}

									// this.render();
									afterTypeChange();
								}
							},
						},
					],
				},
			},
		},
	};
};
