import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d1606b6f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "document-svg",
  width: "100%",
  height: "100%"
}
const _hoisted_2 = ["d"]
const _hoisted_3 = ["d"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElTooltip"], {
    placement: "top",
    content: $props.description?.value,
    visible: $setup.props.isSilentMode && $setup.isTooltipVisible
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        ref: "containerRef",
        class: "node-container",
        onMouseenter: _cache[0] || (_cache[0] = $event => ($setup.isTooltipVisible = true)),
        onMouseleave: _cache[1] || (_cache[1] = $event => ($setup.isTooltipVisible = false))
      }, [
        (!$setup.props.isSilentMode)
          ? (_openBlock(), _createBlock($setup["ElTooltip"], {
              key: 0,
              placement: "top",
              content: $props.description?.value
            }, {
              default: _withCtx(() => [
                _createVNode($setup["ElIcon"], {
                  class: "icon",
                  onClick: $setup.click,
                  size: 20,
                  style: { cursor: 'pointer' }
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["ChatLineRound"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["content"]))
          : _createCommentVNode("v-if", true),
        (_openBlock(), _createElementBlock("svg", _hoisted_1, [
          _createElementVNode("path", {
            d: $setup.bezierPath,
            stroke: "#187dff",
            fill: "transparent"
          }, null, 8 /* PROPS */, _hoisted_2),
          _createElementVNode("path", {
            d: $setup.borderPath,
            stroke: "#187dff",
            fill: "transparent",
            "stroke-width": "4"
          }, null, 8 /* PROPS */, _hoisted_3)
        ]))
      ], 544 /* HYDRATE_EVENTS, NEED_PATCH */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["content", "visible"]))
}