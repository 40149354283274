import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f254d0cd"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "icon icon-md",
  style: {}
}
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "icon-popover-container" }
const _hoisted_4 = { class: "icon-content" }
const _hoisted_5 = ["id", "onClick"]
const _hoisted_6 = {
  class: "icon icon-md",
  style: {}
}
const _hoisted_7 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElPopover"], {
    placement: "bottom",
    width: 270,
    trigger: "click"
  }, {
    reference: _withCtx(() => [
      _createVNode($setup["ElInput"], { value: $setup.inputValue }, {
        prepend: _withCtx(() => [
          (_openBlock(), _createElementBlock("svg", _hoisted_1, [
            _createElementVNode("use", {
              class: "",
              href: `#icon-${$setup.inputValue}`
            }, null, 8 /* PROPS */, _hoisted_2)
          ]))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["value"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createVNode($setup["ElInput"], {
            modelValue: $setup.searchString,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.searchString) = $event)),
            "suffix-icon": $setup.Search,
            placeholder: "请搜索",
            onChange: $setup.changeSearch
          }, null, 8 /* PROPS */, ["modelValue", "suffix-icon"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.icons, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              id: item,
              class: "icon-wrapper",
              onClick: $event => ($setup.selectIcon(item))
            }, [
              (_openBlock(), _createElementBlock("svg", _hoisted_6, [
                _createElementVNode("use", {
                  class: "",
                  href: `#icon-${item}`
                }, null, 8 /* PROPS */, _hoisted_7)
              ]))
            ], 8 /* PROPS */, _hoisted_5))
          }), 256 /* UNKEYED_FRAGMENT */))
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}