frappe.ui.FilterGroup.prototype.init_filter_popover = function () {
	this.filter_button.popover({
		content: this.get_filter_area_template(),
		template: `
			<div class="filter-popover popover">
				<div class="arrow"></div>
				<div class="popover-body popover-content">
				</div>
			</div>
		`,
		html: true,
		trigger: 'manual',
		container: document.fullscreenElement ? this.filter_button.parent() : 'body',
		placement: 'bottom',
		offset: '-100px, 0',
	});
};
