<template>
	<AgGridVue
		@gridReady="onReady"
		@columnRowGroupChanged="columnRowGroupChanged"
		groupDisplayType="singleColumn"
		:rowHeight="rowHeight"
		:getRowId="getRowId"
		:localeText="zhCN"
		:rowData="data"
		:columnDefs="columnDefs"
		domLayout="autoHeight"
		:suppressDragLeaveHidesColumns="smallMeta"
		:autoGroupColumnDef="autoGroupColumnDef" />
</template>
<script lang="ts" setup>
import { computed, shallowRef, watch } from 'vue';

import { AgGridVue } from 'ag-grid-vue3';
import type {
	GridApi, GetRowIdParams,
	ColumnRowGroupChangedEvent, GridReadyEvent, Column, ColumnApi,
} from 'ag-grid-community';
import { useWindowSize } from '@vueuse/core';

import { useMetaQuery } from '../../hooks/useMetaQuery';
import zhCN from '../../agGrid/zhCN';

import type { ViewInfo } from './types';
import { useExpander } from './Expander';
import getColumns from './getColumns';
import useAggregation from './useAggregation';
import Title from './Title/index.vue';


const props = defineProps<{
	meta: locals.DocType;

	data: locals.Doctype[];
	loading?: boolean;
	rowHeight: number;


	detail?: boolean;

	configuration: ViewInfo;
}>();
const smallMeta = useMetaQuery();
const configuration = computed(() => props.configuration);
const gridApi = shallowRef<GridApi>();

const gridColumnApi = shallowRef<ColumnApi>();
const { width, height } = useWindowSize();
const isMobile = computed(() => width.value < 640);
watch([isMobile, gridColumnApi], ([isMobile, columnApi]) => {
	if (!isMobile || !columnApi) { return; }
	const cols = columnApi.getAllGridColumns() || [];
	columnApi.setColumnsPinned(cols.map(v => v.getColId()), null);
}, { immediate: true });

const data = computed(() => {
	const fields = configuration.value.fields.map(f => f.fieldOptions);
	if (!fields.length) { return props.data; }
	const list = props.data.map(v => ({ ...v }));
	for (const { fieldname, fieldtype } of fields) {
		for (const r of list) {
			const v = r[fieldname];
			if (v !== undefined && v !== null) { continue; }
			r[fieldname] = ['Int', 'Float', 'Currency'].includes(fieldtype) ? 0 : '';
		}
	}
	return list;
});


function updateExpanded(expanded: any[]) {
	const api = gridApi.value;
	if (!api) { return; }
	api.collapseAll();
	const ids = new Set(expanded);
	for (const id of ids) {
		const node = api.getRowNode(id);
		if (!node) { continue; }
		api.setRowNodeExpanded(node, true);

	}
}


function getRowId({ data }: GetRowIdParams<locals.Doctype>) {
	return data.name;
}

const titleDocField = computed(() => {
	const fieldName = props.meta.title_field;
	const { fields } = props.meta;
	return fieldName && fields.find(v => v.fieldname === fieldName) || null;
});


async function onReady({ api, columnApi }: GridReadyEvent) {
	gridApi.value = api;
	gridColumnApi.value = columnApi;
}

function preventClick(e: Event, data: any) {
	if (props.detail) {
		e.preventDefault();
	}
}
const state = {
	get meta() { return props.meta; },
	get titleField() { return titleDocField.value; },
	get linkField() { return configuration.value.linkField; },
};

const dataColumns = computed(() => getColumns(
	props.meta,
	() => props.data,
	configuration.value.fields,
	(configuration.value.fixedFieldColumns || 0) - 1,
	false,
));

const titleDataColumn = computed(() => {
	const { width, maxWidth, minWidth } = configuration.value.titleField || {};
	const titleField = titleDocField.value;
	return {
		colId: `id`,
		resizable: true,
		enableRowGroup: false,
		pinned: 'left',

		headerName: titleField ? __(titleField.label) : 'ID',
		width: width || 200, maxWidth, minWidth,
		field: titleField?.fieldname || 'name',
		cellRendererParams: {
			preventClick,
			getState: () => state,
		},
		cellRenderer: Title,
	};
});

const columns = computed(() => {
	const list = [titleDataColumn.value, ...dataColumns.value];
	if (configuration.value.showLineNumber) {
		list.unshift({
			headerName: '序号',
			width: 50,
			resizable: true,
			enableRowGroup: false,
			pinned: 'left',
			cellRenderer({ node }: any) {
				if (!node) { return; }
				const no = node.childIndex + 1;
				return isNaN(no) ? '' : no;
			},
		});
	}
	return list;
});
const { setExpandable } = useExpander();

const aggregation = useAggregation(
	configuration,
	titleDocField,
	computed(() => props.meta),
	computed(() => props.data),
);
watch([aggregation, gridApi], ([aggregation, api]) => {
	if (!api) { return; }
	api.setPinnedBottomRowData(aggregation ? [aggregation] : []);
}, { immediate: true });

const groupColumns = shallowRef<Column[]>([]);
const expanders = computed(() => {
	if (props.loading) { return []; }
	return groupColumns.value.map((v, i) => ({
		value() {
			const api = gridApi.value;
			if (!api) { return []; }
			const ids: string[] = [];
			api.forEachNode(node => {
				if (node.level > i) { return; }
				ids.push(node.id as string);
			});
			return ids;
		},
		title: v.getColDef()?.headerName || `第 ${i + 1} 级`,
	}));
});
watch(expanders, list => { setExpandable(list, updateExpanded); }, { immediate: true });

const { rowHeight } = props;

const columnDefs = columns.value;
const autoGroupColumnDef = { pinned: 'left', resizable: true };
function columnRowGroupChanged({ columns }: ColumnRowGroupChangedEvent<any>) {
	groupColumns.value = columns ? [...columns] : [];
}
</script>
