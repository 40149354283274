<template>
	<AgGridVue
		class="ag-theme-guigu diary_list-table"
		:columnDefs="columnDefs"
		:rowData="diaryStructure"
		:defaultColDef="defaultColDef"
		rowSelection="multiple"
		animateRows="true"
		@grid-ready="onGridReady"
		:treeData="true"
		groupDefaultExpanded="-1"
		:getDataPath="getDataPath"
		:autoGroupColumnDef="autoGroupColumn"
		:suppressDragLeaveHidesColumns="smallMeta"
		:localeText="zhCN">
	</AgGridVue>
</template>
<script lang="ts" >
import {ref, computed, defineComponent, PropType, getCurrentInstance, watch, onUnmounted} from 'vue';
import {AgGridVue} from 'ag-grid-vue3';


import zhCN from '../../../../../../../guigu/guigu/public/js/agGrid/zhCN';
import {useMetaQuery} from '../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';

import {getColumns} from './helper';
import {DiaryStructure, DiaryStructureFormat} from './type';
import Action from './AgGridAction.vue';
import AgGridDiary from './AgGridDiary.vue';
import AgGridDiaryStatus from './AgGridDiaryStatus.vue';
const tt = __;
export default defineComponent({
	components: {
		AgGridVue,
		Action,
		AgGridDiary,
		AgGridDiaryStatus,
	},
	emits: ['deleteDiary', 'afterSave'],
	props: {
		diaryStructure: Object as PropType<(DiaryStructure & {
			ancestry: string[];
		})[]>,
		date: String,
		project: String,
	},
	setup(props, context) {
		const smallMeta = useMetaQuery();
		const gridApi = ref(null);
		const onGridReady = (params:any) => {
			gridApi.value = params.api;
		};
		const defaultColDef = {
			sortable: true,
			filter: true,
			flex: 1,
			resizable: true,
		};
		onUnmounted(()=>{
			frappe.ui.form.off('Guigu Construction Diary', 'refresh');
		});
		function clickDiary(data:DiaryStructureFormat) {
			if (data.diary) {
				frappe.set_route('Form', 'Guigu Construction Diary', data.diary.name);
			} else {
				// 新建日志、带入参数
				const newDiaryName = frappe.model.make_new_doc_and_get_name('Guigu Construction Diary');
				frappe.model.set_value('Guigu Construction Diary', newDiaryName, 'project', props.project);
				frappe.model.set_value('Guigu Construction Diary', newDiaryName, 'date', props.date);
				frappe.model.set_value('Guigu Construction Diary', newDiaryName, 'guigu_construction_structure', data.name);
				frappe.model.set_value('Guigu Construction Diary', newDiaryName, 'guigu_team', data.team.name);

				frappe.ui.form.on('Guigu Construction Diary', {
					refresh(frm:any) {
						frm.set_value('date', props.date);
						frm.set_df_property('guigu_team', 'formatter', ()=> data.team.organization_name);
						frm.set_df_property('guigu_construction_structure', 'formatter', ()=>data.structure_name);
						frm.set_df_property('project', 'read_only', 1);
						frm.set_df_property('date', 'read_only', 1);
						frm.set_df_property('guigu_construction_structure', 'read_only', 1);
						frm.set_df_property('guigu_team', 'read_only', 1);
					},
					after_save(frm:any) {
						setTimeout(()=>{
							context.emit('afterSave');
						}, 500);
					},
				});
				frappe.set_route(['form', 'Guigu Construction Diary', newDiaryName]);
			}
		}
		async function onRemove(data:DiaryStructureFormat) {
			try {
				const result = await frappe.db.delete_doc('Guigu Construction Diary', data.diary?.name);
				context.emit('deleteDiary', data.name, data.diary);
			} catch {

			}
		}
		const columnDefs = computed(()=>getColumns(clickDiary, onRemove));
		function getDataPath(data:any) {
			return data.ancestry;
		}
		const autoGroupColumn = ref({
			headerName: tt('Construction content'),
			flex: 1,
			minWidth: 230,
			enableRowGroup: false,
			editable: false,
			field: 'structure_name',
			filter: false,
			sortable: false,
		});
		return {
			onGridReady,
			defaultColDef,
			columnDefs,
			zhCN,
			getDataPath,
			autoGroupColumn,
			smallMeta,
		};
	},
});
</script>
<style scoped>
.diary_list-table {
	width: 100%;
	height: 100%;
}
</style>
