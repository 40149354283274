<template>
	<div class="table-container" v-loading="loading">
		<FixScore
			:tempAssessList="tempAssessList"
			:permission="detailPermission"
			:lockStatus="lockStatus"
			@update="update"
			@remove="(data, type) => emit('remove', data)"
		></FixScore>
		<div class="add-button">
			<ElButton
				v-if="!lockStatus && detailPermission?.creatable"
				@click="createTempTask"
				link
				:icon="Plus"
				:title="tt('Add Weekly Key Task')"
				type="primary"
			>
				{{ tt('Add') }}
			</ElButton>
		</div>
		<el-form
			labelPosition="top"
			class="detail-form"
			:model="form"
			labelWidth="100px"
		>
			<el-row :gutter="10">
				<el-col :span="12">
					<el-form-item
						:label="
							tt('Last Week Problem Rectification Arrangement')
						"
						prop="rectification_record"
					>
						<el-input
							v-model="form.rectification_record"
							:rows="4"
							type="textarea"
							@change="v => change('rectification_record', v)"
							:disabled="isDisabled('rectification_record')"
						/>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item
						:label="
							tt('Reason Explanation For Incomplete Completion')
						"
						prop="uncomplete_reason"
					>
						<el-input
							type="textarea"
							:rows="4"
							v-model="form.uncomplete_reason"
							@change="v => change('uncomplete_reason', v)"
							:disabled="isDisabled('uncomplete_reason')"
						/>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="12">
					<el-form-item
						:label="
							tt(
								'Assessment of work for this week, existing gaps, and improvement suggestions for next week',
							)
						"
						prop="suggestion"
					>
						<el-input
							v-model="form.suggestion"
							:rows="4"
							type="textarea"
							@change="v => change('suggestion', v)"
							:disabled="isDisabled('suggestion')"
						/>
					</el-form-item>
					<el-form-item label="">
						<div
							v-show="form.leaderConfirmDate"
							class="confirm_div"
						>
							<div>
								{{ tt('Assessor (Signature)') }}:{{
									assessInfo?.leader_doc?.full_name
								}}
								{{
									tt('Confirmed ({})', [
										form.leaderConfirmDate,
									])
								}}
							</div>
						</div>
						<el-checkbox
							v-show="!form.leaderConfirmDate"
							:label="tt('Assessment Person Sign')"
							v-model="isLeaderConfirmed"
							@change="leaderToConfirm"
							:disabled="leader_confirm_disabled"
						/>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item
						:label="
							tt(
								'Provide work support and requirements to the work planner for this week',
							)
						"
						prop="support"
					>
						<el-input
							v-model="form.support"
							:rows="4"
							type="textarea"
							@change="v => change('support', v)"
							:disabled="isDisabled('support')"
						/>
					</el-form-item>
					<el-form-item label="">
						<div v-show="form.userConfirmDate" class="confirm_div">
							<div>
								{{ tt('Assessed Person (Signature)') }}:{{
									props.assessInfo?.user_doc.full_name
								}}
								{{
									tt('Confirmed ({})', [form.userConfirmDate])
								}}
							</div>
						</div>
						<el-checkbox
							v-show="!form.userConfirmDate"
							:label="tt('Assessed Person')"
							v-model="isUserConfirmed"
							@change="userToConfirm"
							:disabled="user_confirm_disabled"
						/>
					</el-form-item>
				</el-col>
			</el-row>
			<div class="description">
				{{ tt('Explanation') }}:{{
					tt(
						'① This table serves as a work schedule and a summary of work completion status;',
					)
				}}{{
					tt(
						'② Confirm the weekly plan with the department head before the end of each Monday and have both parties sign it;',
					)
				}}
				{{
					tt(
						'③ Summarize the completion status of last week plan every Monday. If the planned work is not completed, please specify the reason.',
					)
				}}
			</div>
		</el-form>
	</div>
</template>

<script setup lang="ts">
import { computed, watch, ref, reactive, shallowRef } from 'vue';
import { Plus } from '@element-plus/icons-vue';
import { ElButton, ElMessage } from 'element-plus';

import type * as Permission from '../../Permission';
import type { AssessDetail, AssessInfo, ExtraScore } from '../type';

import FixScore from './FixScore.vue';

const tt = __;
interface Props {
	type: 'project' | 'subCompany';
	assessInfo?: AssessInfo;
	detailPermission: Permission.DocPermission | null;
	assessUserPermission: Permission.UserPermission;
	lockStatus: boolean;
}
const props = defineProps<Props>();
interface Emit {
	(e: 'update'): void;
	(e: 'createTempTask'): void;
	(e: 'remove', data: ExtraScore | AssessDetail): void;
}

const isDisabled = (field: string) => {
	if (props.lockStatus) {
		return true;
	}
	if (props.assessInfo?.assess_name) {
		return !props.assessUserPermission.writable.has(field);
	}
	return !props.assessUserPermission.creatable;
};

const user_confirm_disabled = computed((field: string) => {
	if (props.lockStatus) {
		return true;
	}
	const is_current_user =
		props.assessInfo?.user_doc?.name === frappe.user.name;
	if (props.assessInfo?.assess_name) {
		return !(
			is_current_user &&
			props.assessUserPermission?.writable.has('user_confirm_date')
		);
	}
	return !(is_current_user && props.assessUserPermission?.creatable);
});

const leader_confirm_disabled = computed((field: string) => {
	if (props.lockStatus) {
		return true;
	}
	const is_current_user =
		props.assessInfo?.select_leader_doc?.name === frappe.user.name;
	if (props.assessInfo?.assess_name) {
		return !(
			is_current_user &&
			props.assessUserPermission?.writable.has('leader_confirm_date')
		);
	}
	return !(is_current_user && props.assessUserPermission?.creatable);
});

const emit = defineEmits<Emit>();
const loading = ref<Boolean>(false);
const tempAssessList = ref<
	(AssessDetail & { index: number; type_label: string })[]
>([]);
const linkAssessList = ref<
	(AssessDetail & { index: number; type_label: string })[]
>([]);
const form = reactive({
	uncomplete_reason: '',
	rectification_record: '',
	suggestion: '',
	support: '',
	userConfirmDate: '',
	leaderConfirmDate: '',
});
const isUserConfirmed = ref<boolean>(!!form.userConfirmDate);
const isLeaderConfirmed = ref<boolean>(!!form.leaderConfirmDate);

watch(
	() => form.userConfirmDate,
	newVal => {
		isUserConfirmed.value = !!newVal;
	},
);

watch(
	() => form.leaderConfirmDate,
	newVal => {
		isLeaderConfirmed.value = !!newVal;
	},
);

watch(
	() => props.assessInfo,
	() => {
		loading.value = true;
		const {
			detail = [],
			temp = [],
			uncomplete_reason,
			rectification_record,
			suggestion,
			support,
			leader_confirm_date,
			user_confirm_date,
		} = props.assessInfo || {};
		linkAssessList.value =
			detail
				.filter(item => item.doc_name && item.link_doctype)
				.sort((pre, next) => (pre.subject > next.subject ? 1 : -1))
				.map((item, index) => ({
					...item,
					index: index + 1,
					type_label: __('Plan'),
				})) || [];
		const tempAssess =
			temp
				.sort((pre, next) => (pre.subject > next.subject ? 1 : -1))
				.map((item, index) => ({
					...item,
					index: index + 1,
					type_label: __('adjust'),
				})) || [];
		tempAssessList.value = tempAssess;
		form.uncomplete_reason = uncomplete_reason || '';
		form.rectification_record = rectification_record || '';
		form.suggestion = suggestion || '';
		form.support = support || '';
		form.userConfirmDate = user_confirm_date;
		form.leaderConfirmDate = leader_confirm_date;
		loading.value = false;
	},
	{ immediate: true },
);

function createTempTask() {
	emit('createTempTask');
}

async function update(
	field: string,
	value: string | number,
	data: AssessDetail | ExtraScore,
) {
	await frappe.call({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_week_assessment_detail.guigu_hr_week_assessment_detail.update_score',
		args: {
			doctype: data.doctype,
			name: data.name,
			field,
			value,
		},
	});
	ElMessage({
		type: 'success',
		message: __('Saved Successfully'),
	});
	emit('update');
}

async function change(field: string, value: string) {
	if (!props.assessInfo) {
		return;
	}
	let url =
		'guigu_hr.guigu_hr_assessment.page.guigu_hr_week_assessment_detail.guigu_hr_week_assessment_detail.update_assess';
	const args: any = {
		name: props.assessInfo?.assess_name,
		field,
		value,
		type: props.type,
	};
	if (!props.assessInfo?.assess_name) {
		url =
			'guigu_hr.guigu_hr_assessment.page.guigu_hr_week_assessment_detail.guigu_hr_week_assessment_detail.create_assess';
		args.date = props.assessInfo.date;
		args.user = props.assessInfo.user_doc.name;
		args.type = props.type;
		args.department = props.assessInfo.department_doc.name;
	}
	await frappe.call({
		method: url,
		args: args,
	});
	emit('update');
}
async function userToConfirm() {
	if (!props.assessInfo) {
		return;
	}
	let url =
		'guigu_hr.guigu_hr_assessment.page.guigu_hr_week_assessment_detail.guigu_hr_week_assessment_detail.update_assess';
	const args: any = {
		name: props.assessInfo?.assess_name,
		field: 'user_confirm_date',
		value: moment().format('YYYY-MM-DD'),
		type: props.type,
	};
	if (!props.assessInfo.assess_name) {
		url =
			'guigu_hr.guigu_hr_assessment.page.guigu_hr_week_assessment_detail.guigu_hr_week_assessment_detail.create_assess';
		args.date = props.assessInfo.date;
		args.user = props.assessInfo.user_doc.name;
		args.type = props.type;
		args.department = props.assessInfo.department_doc.name;
	}
	await frappe.call({
		method: url,
		args,
		callback: response => handleConfirmCallback('user_confirm_date'),
	});
	emit('update');
}

async function leaderToConfirm(value: string | number | boolean) {
	if (!props.assessInfo) {
		return;
	}
	await frappe.call({
		method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_week_assessment_detail.guigu_hr_week_assessment_detail.leader_confirm',
		args: {
			name: props.assessInfo?.assess_name,
			date: props.assessInfo.date,
			user: props.assessInfo.user_doc.name,
			type: props.type,
			assessment_department: props.assessInfo.department_doc.name,
		},
		callback: response => handleConfirmCallback('leader_confirm_date'),
	});
	emit('update');
}
function handleConfirmCallback(field: string) {
	const alertDiv = document.createElement('div');
	alertDiv.innerHTML = `
		<div style="display: flex; align-items: center; justify-content: space-between;">
			<span>${__('Signed confirmation completed')}</span>
			<button 
				id='cancel-signature-btn'
				style="background: none; border: none; color: #aaa; font-weight: bold; cursor: pointer; margin-left: 10px;" 
				onmouseover="this.style.color='#1C86EE'" 
				onmouseout="this.style.color='#aaa'">${__('Revoke Signature')}
			</button>
		</div>
	`;

	frappe.show_alert(
		{
			message: alertDiv.innerHTML,
			indicator: 'green',
		},
		60,
	);

	document
		.querySelector('#cancel-signature-btn')
		.addEventListener('click', async () => {
			await frappe.call({
				method: 'guigu_hr.guigu_hr_assessment.page.guigu_hr_week_assessment_detail.guigu_hr_week_assessment_detail.cancel_signature',
				args: {
					name: props.assessInfo?.assess_name,
					type: props.type,
					field,
				},
			});
			// 通过明确设置签字状态
			if (field === 'user_confirm_date') {
				form.userConfirmDate = '';
				isUserConfirmed.value = false;
			} else if (field === 'leader_confirm_date') {
				form.leaderConfirmDate = '';
				isLeaderConfirmed.value = false;
			}
			emit('update');
			frappe.show_alert(
				{
					message: __('Revoked signature confirmation'),
					indicator: 'blue',
				},
				10,
			);
		});
}
</script>

<style lang="less" scoped>
.table-container {
	overflow: hidden;
}

.add-button {
	text-align: right;
	padding: 8px 0;
}

.extra-btn_container {
	display: flex;
	justify-content: end;
	padding: 8px 0;
}

.detail-form {
	margin-top: 8px;
}

.confirm_div {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.description {
	font-size: 12px;
}
</style>
