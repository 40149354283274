<template>
	<el-tabs v-loading="loading" v-model="activeName" class="sidler-tab">
		<el-tab-pane label="目录" name="first" class="directory">
			<FolderTree :meta="meta"
				:parent-field="parentField"
				:folderList="formatFolderList"
				@setFavorite="setFavorite"
				@setCurrentFolder="setCurrentFolder"
				@setHome="setHome" :filters="filters">
			</FolderTree>
		</el-tab-pane>
		<el-tab-pane label="收藏" name="second">
			<FolderTree :meta="meta"
				:parent-field="parentField"
				:folderList="favoriteFolders"
				@setFavorite="setFavorite"
				@setCurrentFolder="setCurrentFolder"
				@setHome="setHome" :filters="filters">
			</FolderTree>
		</el-tab-pane>
	</el-tabs>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';

import { cloneDeep } from 'lodash';

import { Filter } from '../GlobalView/types';

import FolderTree from './FolderTree.vue';
import { DMSFActions, formatData, FormatFolder } from './helper';
import { useFileViewStore } from './stores/fileView';
import { Config } from './type';
const props = defineProps<{
	meta: locals.DocType;
	filters:Filter[]
	configuration:Config
}>();

const emit = defineEmits<{
	(event:'update:filters', data:Filter[]):void
	(event: 'update:selected', value: any[]): void;

}>();
const activeName = ref('first');
const formatFolderList = ref<FormatFolder[]>([]);
const loading = ref<boolean>(false);
const fileViewStore = useFileViewStore();

const {
	isFolderField, fileNameField, folderNameField, parentField,
} = computed(()=>{
	const {isFolderField, fileNameField, folderNameField, parentField} = props.configuration||{};
	return {isFolderField, fileNameField, folderNameField, parentField};
}).value;

async function getFolders(){
	loading.value = true;
	formatFolderList.value = [];
	if (!isFolderField){
		loading.value = false;
		return;
	}
	const data = await frappe.call<{ message: any[] }>({
		method: 'guigu.fileview.fileview.get_folders',
		args: { doctype: props.meta.name, is_folder_field:isFolderField },
	});
	const favorite_data = await frappe.call<{ message: DMSFActions[] }>({
		method: 'guigu.fileview.fileview.get_folder_actions',
		args: { doctype: props.meta.name},
	});
	const folders= (data?.message||[]).map(item=>{
		if (!item[parentField]){
			return {
				...item,
				[parentField]:'all',
			};
		}
		return item;
	});
	folders.unshift({
		name:'all',
		[isFolderField]:1,
		[folderNameField]:'全部文件',
	});
	const formatList = formatData(folders, favorite_data?.message||[], {
		folderNameField,
	});
	formatFolderList.value = formatList;
	loading.value = false;
}

watch([()=>props.meta.name, ()=>isFolderField, ()=>fileViewStore.createdFolder, ()=>fileViewStore.updatedFolder], async ()=>{
	getFolders();
}, {immediate:true});


const popstateListener = function(event) {
	getFolders();
};
onMounted(()=>{
	window.addEventListener('popstate', popstateListener);
});
onUnmounted(()=>{
	window.removeEventListener('popstate', popstateListener);
});

const favoriteFolders = computed(()=>formatFolderList.value.filter(item=>item.favoriteField === 1));
async function setHome(data:FormatFolder){
	loading.value=true;
	const res = await frappe.call<{ message: boolean }>({
		method: 'guigu.fileview.fileview.set_home',
		args: {
			doctype: props.meta.name,
			name:data.name,
			value:data.defaultHomeField === 1?0:1,
		},
	});
	if (res?.message!==true){
		loading.value=false;
		return;
	}
	const oldHomeFolders = formatFolderList.value.filter(item=>item.defaultHomeField === 1);
	for (let oldHome of oldHomeFolders){
		const index = formatFolderList.value.findIndex(item=>item.id === oldHome.id);
		const folder = formatFolderList.value[index];
		if (folder){
			const copyFolder = cloneDeep(folder);
			copyFolder.defaultHomeField = 0;
			formatFolderList.value.splice(index, 1, copyFolder);
		}
	}
	const index = formatFolderList.value.findIndex(item=>item.id === data.id);
	const folder = formatFolderList.value[index];
	if (folder){
		const copyFolder = cloneDeep(folder);
		copyFolder.defaultHomeField = data.defaultHomeField === 1?0:1;
		formatFolderList.value.splice(index, 1, copyFolder);
	}
	loading.value=false;
}
async function setFavorite(data:FormatFolder){
	loading.value=true;
	const res = await frappe.call<{ message: boolean }>({
		method: 'guigu.fileview.fileview.set_favorite',
		args: {
			doctype: props.meta.name,
			name:data.name,
			value:data.favoriteField === 1?0:1,
		},
	});
	if (res?.message!==true){
		loading.value=false;
		return;
	}
	const index = formatFolderList.value.findIndex(item=>item.id === data.id);
	const folder = formatFolderList.value[index];
	if (folder){
		const copyFolder = cloneDeep(folder);
		copyFolder.favoriteField = data.favoriteField === 1?0:1;
		formatFolderList.value.splice(index, 1, copyFolder);
	}
	loading.value=false;
}
function setCurrentFolder(folderName:string){
	const filters = props.filters.filter(item=>item.field!==parentField);
	emit('update:selected', []);
	if (!parentField){ return; }
	let parentFilter = {
			doctype: props.meta.name,
			field: parentField,
			condition: '=',
			value: folderName,
		};
	if (folderName==='all'){
		parentFilter = {
			doctype: props.meta.name,
			field: parentField,
			condition: 'is',
			value: 'not set',
		};
	}
	emit('update:filters', [
		...filters, parentFilter,
	]);
}
</script>

<style scoped lang="less">
.sidler-tab {
	height: calc(100vh - 2*var(--padding-md) - var(--navbar-height) - 75px);
	display: flex;
	flex-direction: column;

	:deep(.el-tabs__content) {
		height: 100%;
	}

	.directory {
		height: 100%;
	}

	:deep(.el-tabs__nav-wrap) {
		height: 46px;
	}
}
</style>
