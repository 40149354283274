// /* eslint-disable no-unused-expressions */
import { DailyRecordData, SummaryTableData } from '../../type';

export function routeInit(paramsFilter: any) {
	const keyArr = ['project', 'pageType', 'monthDate', 'yearDate', 'countCategory'];
	const queryObj = new URLSearchParams(window.location.search);
	// 创建一个对象来存储参数
	let paramsObj:{[key: string]: string} = {};
	// 遍历每个参数，并将它们存储在 params 对象中
	for (const item of keyArr) {
		if (queryObj.get(item)) {
			paramsObj[item] = queryObj.get(item) || '';
		}
	}
	paramsFilter.value = paramsObj;
}

export function setRoute(paramsFilter: any, filter: { [key: string]: string }) {
	const newParamsFilter = { ...paramsFilter.value, ...filter };
	const queryObj = new URLSearchParams(newParamsFilter);
	paramsFilter.value = newParamsFilter;
	history.replaceState(null, '', `?${queryObj.toString()}`);
}
/** 将日志记录数据转为obj {[user]: {[date]: [data]}}*/
export function daily_record_to_obj(
	dailyRecordRata: DailyRecordData[],
	type: string,
): { [key: string]: { [key: string]: DailyRecordData | number | string } } {
	const obj: { [key: string]: { [key: string]: DailyRecordData | number | string } } = {};
	for (const item of dailyRecordRata) {
		if (obj[`${item?.organization_id}_${item.record_owner}`]) {

			if (type === 'month') {
				if (item?.record_date) {
					obj[`${item?.organization_id}_${item.record_owner}`][item.record_date] = item;
					obj[`${item?.organization_id}_${item.record_owner}`].diary_count = Number(obj[`${item?.organization_id}_${item.record_owner}`].diary_count) + 1;
				}

			} else if (item.record_date || item?.guigu_pm_daily_record_attendance_type) {
				obj[`${item?.organization_id}_${item.record_owner}`][item?.record_date || item?.guigu_pm_daily_record_attendance_type] = item?.count || 0;
				obj[`${item?.organization_id}_${item.record_owner}`].diary_count = Number(obj[`${item?.organization_id}_${item.record_owner}`].diary_count) + item?.count || 0;
			}

		} else if (type === 'month') {
			/** 这里根据record_date 因为要是显示组织中的没写日志的人
			 * 没有日志数据的人员返回的数据中没有record_date 这个字段 */
			if (item?.record_date) {
				obj[`${item?.organization_id}_${item.record_owner}`] = {
					[item.record_date]: item,
					diary_count: 1,
					organization_id: item?.organization_id,
					organization_name: item.organization_name,
					record_owner: item.record_owner,
					record_owner_name: item.record_owner_name,

				};
			} else {
				obj[`${item?.organization_id}_${item.record_owner}`] = {
					diary_count: 0,
					organization_name: item.organization_name,
					organization_id: item?.organization_id,
					record_owner: item.record_owner,
					record_owner_name: item.record_owner_name,

				};
			}

		} else if (item?.record_date || item.guigu_pm_daily_record_attendance_type) {
			obj[`${item?.organization_id}_${item.record_owner}`] = {
				[item?.record_date || item.guigu_pm_daily_record_attendance_type]: item?.count || 0,
				diary_count: item?.count || 0,
				organization_id: item?.organization_id,
				organization_name: item.organization_name,
				record_owner: item.record_owner,
				record_owner_name: item.record_owner_name,
			};
		} else {
			obj[`${item?.organization_id}_${item.record_owner}`] = {
				diary_count: item?.count || 0,
				organization_id: item?.organization_id,
				organization_name: item.organization_name,
				record_owner: item.record_owner,
				record_owner_name: item.record_owner_name,

			};
		}
	}
	return obj;
}

function sortData(recordData: any[], sortArr: string[]) {
	// 使用sort方法对主要数组进行排序
	const sortedArray = (recordData || []).sort((a, b) => {
		if (b?.organization_id&&a?.organization_id ) {
			const indexOfA = (sortArr || []).indexOf(`${a?.organization_id}_${a.record_owner}`);
			const indexOfB = (sortArr || []).indexOf(`${b?.organization_id}_${b.record_owner}`);
			return indexOfA - indexOfB;
		}
		if (!a?.organization_id){
			return 1;
		}
		return -1;

	});
	return sortedArray;
}


export function dailyRecordDataProcessing(
	dailyRecordData: DailyRecordData[],
	type: 'month' | 'year',
	sortArr: string[],
): SummaryTableData[] {
	const dailyRecordObj = daily_record_to_obj(dailyRecordData, type);
	if (type === 'month') {
		return sortData([...Object.values(dailyRecordObj)], sortArr);
	}
	return sortData([...Object.values(dailyRecordObj)], sortArr);
}
