// @ts-check
import * as store from '../store';

import createTree from './tree';
import { setCurrent } from './tree';
const { Toolbar } = frappe.ui.toolbar;
const old_make = Toolbar.prototype.make;

function logout() {
	var me = this;
	me.logged_out = true;
	store.setCurrent('');
	return frappe.call({
		method: "logout",
		callback: function (r) {
			if (r.exc) {
				return;
			}
			me.redirect_to_login();
		},
	});
}
frappe.Application.prototype.logout = logout;


async function getOrganizationName() {
	let thisOrganization = store.getCurrent();
	if (!thisOrganization) {
		const defaultMembers = await frappe.db.get_list('Tianjy Organization Member', {
			filters: [
				['user', '=', frappe.session.user],
				['default', '=', 1]

			],
			fields: ['*'],
		});
		const { message: allOrganizations } = await frappe.call({ method: 'tianjy_organization.lib.viewable' });

		thisOrganization = defaultMembers[0]?.organization || allOrganizations[0]?.name;
		if (thisOrganization) {
			await setCurrent(thisOrganization);
		}
	}
	if (!thisOrganization) {
		return;
	}
	try {
		const { message } = await frappe.call('frappe.desk.search.get_link_title', {
			doctype: 'Tianjy Organization',
			docname: thisOrganization,
		});
		if (message && typeof message === 'string') {
			return message;
		}
	} catch {
		return;
	}
	store.setCurrent('');
}

async function addProjectSelect() {
	const ul = document.querySelector('header .navbar-nav:last-child');
	if (!ul) { return; }
	const li = document.createElement('li');
	ul.insertBefore(li, ul.firstChild);
	li.style.display = 'flex';
	li.style.alignItems = 'center';
	li.style.justifyContent = 'center';


	const btn = li.appendChild(document.createElement('a'));
	li.classList.add('nav-item');
	btn.className = 'nav-list text-muted';

	btn.href = '#';
	btn.title = __('Switch Organization');
	const name = await getOrganizationName();
	function selectOrganization() {
		const dialog = new frappe.ui.Dialog({
			title: __('Switch Organization'),
			primary_action_label: __('Close'),
		});
		/** @type {HTMLElement}  */
		const p = dialog.body;
		p.className = 'tianjy-organization-list';
		dialog.show();

		frappe.call({ method: 'tianjy_organization.lib.viewable' })
			.then(data => data?.message || []).then(list => {
				const ul = createTree(list);
				p.appendChild(ul);
			});

	}
	if (!name) {
		let d = new frappe.ui.Dialog({
			title: __('Prompt'),
			fields: [{ label: __('You have not set the current organization. Would you like to choose an organization now?'), fieldtype: 'Heading' }],
			primary_action_label: __('Select Organization'),
			secondary_action_label: __('Cancel'),
			primary_action() {
				d.hide();
				selectOrganization();
			},
			secondary_action() {
				d.hide();
			},
		});
		d.show();
	}
	const organizationName = name || __('Select Organization');
	btn.appendChild(document.createTextNode(organizationName));
	btn.addEventListener('click', e => {
		e.preventDefault();
		selectOrganization();
	});
}


Toolbar.prototype.make = function () {
	old_make.call(this);
	addProjectSelect();
};

addProjectSelect();
