
frappe.ui.form.ControlTextEditor = class ControlTextEditor extends frappe.ui.form.ControlCode {
    make_wrapper() {
        super.make_wrapper();
    }

    make_input() {
        this.has_input = true;
        if (this.df.options === 'tinymce') {
            this.make_tinymce_editor();
        } else {
            this.make_ckeditor();
        }
    }
    make_ckeditor() {
        const that = this;
        const resizeObserver = new ResizeObserver(entries => {
            for (const entry of entries) {
                if (entry.contentBoxSize) {
                    // Firefox implements `contentBoxSize` as a single content rect, rather than an array
                    const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;
                    $(this.input_area).parent().css('--ck-toolbar-dropdown-max-width', `${contentBoxSize.inlineSize}px`);
                } else {
                    $(this.input_area).parent().css('--ck-toolbar-dropdown-max-width', `${entry.contentRect.width}px`);
                }
            }
        });
        resizeObserver.observe($($(this.input_area).parent())[0]);

        this.quill_container = $('<div>').appendTo(this.input_area);
        ClassicEditor.create(this.input_area, {
            toolbar: {
                items: [
                    'fullscreen',
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'underline',
                    'link',
                    'bulletedList',
                    'numberedList',
                    '|',
                    'outdent',
                    'alignment',
                    'indent',
                    '|',
                    'fontBackgroundColor',
                    'fontColor',
                    'fontSize',
                    'fontFamily',
                    '|',
                    'imageInsert',
                    'insertTable',
                    '|',
                    'blockQuote',
                    'mediaEmbed',
                    'undo',
                    'redo',
                    'todoList',
                    'code',
                    'codeBlock',
                    'findAndReplace',
                    'specialCharacters',
                ],
            },
        }).then(editor => {
            that.activeEditor = editor;
            if (that.value) {
                editor.setData(that.value);
            }
            editor.model.document.on('change:data', () => {
                if (this.alreadySet) { return; }
                if (editor.getData()) {
                    that.parse_validate_and_set_in_model(editor.getData());
                }

            });
        });
    }
    make_tinymce_editor() {
        const that = this;
        this.quill_container = $('<div>').appendTo(this.input_area);
        tinymce.init({
            target: this.input_area,
            language: 'zh_CN',
            language_url: 'js/tinymce_language/zh_CN.js',
            toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment | footnotes | mergetags',
            font_size_formats: '10px 11px 12px 13px 14px 15px 16px 17px 18px 19px 20px 24px 36px',
            plugins: [
                'autoresize', 'autolink', 'charmap', 'emoticons', 'fullscreen', 'help',
                'image', 'link', 'lists', 'searchreplace',
                'table', 'visualblocks', 'visualchars', 'wordcount',
            ],
            powerpaste_googledocs_import: 'prompt',
            automatic_uploads: true,
            entity_encoding: 'raw',
            convert_urls: true,
            content_css: false,
            toolbar_sticky: true,
            promotion: false,
            images_upload_handler(blobInfo, prgressFn) {
                return new Promise((resolve, reject) => {
                    resolve(`data:${blobInfo.blob().type};base64,${blobInfo.base64()}`);
                });
            },
            setup(editor) {
                that.editor_id = editor.id;
                editor.on('Change', function (e) {
                    that.parse_validate_and_set_in_model(e.level.content);
                });
                editor.on('init', function (e) {
                    editor.setContent(that.value);
                });
            },
        });
        this.activeEditor = tinymce.activeEditor;
    }

    alreadySet = false;
    activeEditor: any;

    set_formatted_input(value) {
        let editorValue = '';
        const thisDfoptions = this.df.options;
        if (thisDfoptions === 'tinymce') {
            editorValue = this.activeEditor.getContent();
        } else {
            editorValue = this?.activeEditor?.getData() || '';
        }
        if (thisDfoptions === 'tinymce') {
            const replaceEditorValue = (editorValue || '').replace(/\n|(\s)/g, '');
            const replaceValue = (value || '').replace(/<br data-mce-bogus="1">|&nbsp;|\n|\s/g, '').replace(/data-mce-style="[^"]*"/g, '');
            if (replaceValue === replaceEditorValue) { return; }
        } else if (value === editorValue) { return; }
        if (this.frm && !this.frm.doc.__setContent && this.activeEditor) {
            if (thisDfoptions === 'tinymce') {
                this.activeEditor.setContent(value || '');
            } else {
                this.alreadySet = true;
                this.activeEditor.setData(value || '');
                this.alreadySet = false;
            }
        }

    }
};
