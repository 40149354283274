
import { FileDocData } from './type/index';
interface Custom_Field{
	folderNameField:string,
}
export interface FormatFolder{
	name:string,
	id:string,
	label:string,
	favoriteField?:0|1,
	defaultHomeField?:0|1,
	[key: string]:any
}
export interface DMSFActions{
	target_type:string,
	target_id:string,
	user_id:string,
	action_type:'favorite'|'home'
}
export function formatData(data: FileDocData[], dmsfActions:DMSFActions[], {
	folderNameField,
}:Custom_Field):FormatFolder[]{
	const treeItems = data.map(item=>{
		let label = item.name;
		if (folderNameField){
			label = item[folderNameField];
		}
		const isFavorite = dmsfActions.some(favorite=>favorite.target_id === item.name&&favorite.action_type==='favorite');
		const defaultHomeField = dmsfActions.some(action=>action.target_id === item.name&&action.action_type==='home');
		const formatData = {
			...item,
			name:item.name,
			id:item.name,
			label,
			favoriteField:isFavorite?1:0 as 0|1,
			defaultHomeField:defaultHomeField?1:0 as 0|1,
		};
		return formatData;
	});
	return treeItems;
}

export interface Item{
	name:string,
	[parent:string]:any
}
export interface TreeItemData extends Item {
  children?: this[];
}
export function list2Tree<T extends Item>(listData: T[], parentField:string) {
	const listDataCopy:T[]  = JSON.parse(JSON.stringify(listData));
	const treeData: (T & TreeItemData)[] = [];
	const map:Record<string, any> = {};
	listDataCopy.forEach(item => {
	  map[item.name] = item;
	});
	listDataCopy.forEach(item => {
	  const parent = map[item[parentField] || 0];
	  if (parent) {
		(parent.children || (parent.children = [])).push(item);
	  } else {
		treeData.push(item);
	  }
	});
	return treeData;
  }


  export function formatFiles(fileList:any[], meta:locals.DocType, isFolderField:string, parentField:string, folderNameField:string, fileNameField:string){
	const field = meta.fields.find(item=>item.fieldtype==='Attach' ||item.fieldtype==='Attach Image');
	if (!field){ return []; }

	const formatList = (fileList||[]).map(file=>{
		if (file[isFolderField] === 1){
			return {
				name:file.name,
				title:file[folderNameField]||file.name||'',
				parent:file[parentField],
				isFolder:true,
			};
		}
		const value:string = encodeURI(file[field?.fieldname||'']||'').replace(/#/g, '%23');
		if (!value){ return; }
		const arr = value.split('.');
		const ext = arr[arr.length-1].toLowerCase();
		const urlArr = value.split('/');
		const urlFileArr = urlArr[urlArr.length-1].split('.');
		const defaultFileName = urlFileArr[urlFileArr.length-2];
		return {
			name:file.name,
			title:`${file[fileNameField]||defaultFileName}.${ext}`,
			url:value,
			parent:file[parentField],
			isFolder:false,
		};
	}).filter(Boolean) as ({
		name: any;
		title: any;
		url?: string;
		parent: any;
		isFolder:boolean
	})[];
	return formatList;
  }
