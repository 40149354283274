/* eslint-disable eqeqeq */
import { CellClassParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';

export const editCellStyle = { border: '1px solid #ccc' };

function checkPermission() {
  const allRoles = frappe?.user_roles || [];
  const permission = ['System Manager', 'Guigu Pc Sub Company Department Manager', 'Guigu Pc Sub Company Department Member'];
  return permission.some((item) => allRoles.includes(item));
}

export function getWorkAssessmentDetailColumns() {
  const columns = [
    {
      headerName: __('Employee User'),
      field: 'user',
      width: 100,
      minWidth: 100,
      editable: false,
      filter: true,
      resizable: true,
      enableRowGroup: true,
      pinned: 'left',
      rowGroup: true,
      hide: true,
    },
    {
      headerName: __('Template Name'),
      // flex: 1,
      minWidth: 230,
      // enableRowGroup: false,
      editable: false,
      field: 'template_name',
      filter: false,
      sortable: false,
      resizable: true,
      pinned: 'left',
      cellRenderer: 'AgGridWorkAssessmentSubject',
    },
    {
      headerName: __('Type'),
      field: 'type_name',
      width: 120,
      minWidth: 120,
      editable: false,
      filter: true,
      resizable: true,
      enableRowGroup: true,
    },
    {
      headerName: __('Status'),
      field: 'status',
      width: 100,
      minWidth: 100,
      editable: false,
      filter: true,
      resizable: true,
      cellRenderer: 'AgGridWorkAssessmentStatus',
      enableRowGroup: true,
    },
    {
      headerName: __('Priority'),
      field: 'priority',
      width: 100,
      minWidth: 100,
      editable: false,
      filter: true,
      resizable: true,
      cellRenderer: 'AgGridWorkAssessmentPriority',
      enableRowGroup: true,
    },
    {
      headerName: __('Assigned User'),
      field: 'assigner_user',
      width: 100,
      minWidth: 100,
      editable: false,
      filter: true,
      resizable: true,
      enableRowGroup: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.node?.group && params.node.field == params.colDef.field) {
          if (params.colDef.enableValue) {
            return params.value;
          }
          return params.node.field ? params.node.allLeafChildren[0]?.data[params.node.field] || __('empty') : __('empty');
        }
      },
    },
    {
      headerName: __('Plan Start Expression'),
      field: 'plan_start_date_expression',
      width: 100,
      minWidth: 100,
      editable: false,
      filter: true,
      resizable: true,
    },
    {
      headerName: __('Plan Start Expression'),
      field: 'plan_end_date_expression',
      width: 100,
      minWidth: 100,
      editable: false,
      filter: true,
      resizable: true,
    },
    {
      headerName: __('Assessment Function'),
      field: 'assessment_function',
      width: 100,
      minWidth: 100,
      filter: true,
      resizable: true,
      editable: false,
    },
    {
      headerName: __('Assessment Total Score'),
      field: 'total_score',
      width: 100,
      resizable: true,
      minWidth: 100,
      editable: () => checkPermission(),
      cellEditor: 'FloatEditor',
      cellStyle: (params: CellClassParams) => {
        const { data } = params;
        if (data?.id && checkPermission()) {
          return editCellStyle;
        }
        return;
      },
      enableValue: true,
      aggFunc: 'sum',
      valueGetter: (params: ValueGetterParams) => {
        try {
          return parseInt(params.data.total_score || '0');
        } catch {
          return null;
        }
      },
    },
    {
      headerName: __('Assessment Criteria'),
      field: 'assessment_criteria',
      width: 100,
      minWidth: 100,
      resizable: true,
      editable: false,
    },
  ];
  return columns;
}
