<template>
	<ElFormItem>
		<div>
			<ElSelect :placeholder="tt('Add grouping configuration')" @change="add" filterable defaultFirstOption>
				<template v-for="item in optionsAddible" :key="item.field">
					<ElOption v-if="!added.has(`${item.field}`)" :key="item.field" :value="item.field" :label="tt(item.label)"> </ElOption>
				</template>
			</ElSelect>
			<ElButton @click="groups = []" :title="tt('Clear')" :icon="Delete" />
		</div>
		<div
			class="line"
			v-for="group in groups"
			:key="`${group.field}`"
			@dragover.prevent
			@dragend="currentGroup = undefined"
			@dragstart="currentGroup = group"
			@drop.prevent="drop(group)"
			:draggable="draggable(group)">
			<ElButton
				@pointerdown="draggableGroup = group"
				@pointerup="draggableGroup = undefined"
				@pointercancel="draggableGroup = undefined"
				@touchmove.prevent
				:icon="Rank"
				text />
			<div class="label">
				{{ tt(getLabel(group)) }}
			</div>

			<ElCheckbox
				:modelValue="Boolean(group.column)"
				@update:modelValue="setColumn(group, $event)"
				:label="tt('Is Swimming Lane')"
				size="large" />
			<ElButton @click="remove(group)" :icon="Delete" :title="tt('Delete')" text />
		</div>
	</ElFormItem>
</template>
<script lang="ts" setup>
import {computed, shallowRef} from 'vue';
import {ElFormItem, ElButton, ElCheckbox, ElSelect, ElOption} from 'element-plus';
import {Delete, Rank} from '@element-plus/icons-vue';

import groupFieldTypes from './groupFieldTypes';

const props = defineProps<{
	meta: locals.DocType;
	name?: string;
	type: string;
	modelValue: any;
}>();

const emit = defineEmits<{
	(event: 'update:modelValue', value: any): void;
}>();

interface Group {
	field: string;
	column?: 0 | 1;
	values: any;
}

const cfg = computed({
	get: () => props.modelValue,
	set: v => emit('update:modelValue', v),
});
const groups = computed<Group[]>({
	get: () => cfg.value?.groups || [],
	set: groups => {
		cfg.value = {...cfg.value, groups};
	},
});

const allOptions = computed(() => {
	const {meta} = props;
	const doctype = meta.name;
	const list: { label: any; field: string }[] = [];
	function add_field_option({fieldname: field, label, fieldtype: type, permlevel}: any) {
		if (!groupFieldTypes.has(type.toLowerCase())) {
			return;
		}
		if (!frappe.perm.has_perm(doctype, permlevel, 'read')) {
			return;
		}
		if (field === 'docstatus' && !frappe.model.is_submittable(doctype)) {
			return;
		}
		if (!frappe.model.is_value_type(type)) {
			return;
		}
		list.push({field, label: __(label || field)});
	}
	for (const df of frappe.model.std_fields) {
		add_field_option(df);
	}
	if (meta.istable) {
		add_field_option({fieldname: 'parent', fieldtype: 'Data', label: 'Parent'});
	}
	for (const df of meta.fields) {
		add_field_option(df);
	}
	const seen = new Set(['_user_tags', '_liked_by', '_comments']);
	return list.filter(({field}) => {
		if (seen.has(field)) {
			return false;
		}
		seen.add(field);
		return true;
	});
});

function setColumn(group: Group, column: any) {
	const list = [...groups.value];
	const index = list.indexOf(group);
	if (index < 0) {
		return;
	}
	list[index] = {...group, column: column ? 1 : 0};
	groups.value = list;
}

const added = computed(() => new Set(groups.value.map(f => `${f.field}`)));
const optionsAddible = computed(() => {
	const addedList = added.value;
	return allOptions.value.filter(({field}) => !addedList.has(field));
});
const labels = computed(() => Object.fromEntries(allOptions.value.map(({label, field}) => [field, label])));

function add(field: string) {
	if (added.value.has(field)) {
		return;
	}
	groups.value = [...groups.value, {field, column: 0, values: '[]'}];
}
function remove(filter: Group) {
	const {value} = groups;
	const index = value.indexOf(filter);
	if (index < 0) {
		return;
	}
	groups.value = [...value.slice(0, index), ...value.slice(index + 1)];
}
const currentGroup = shallowRef<Group>();
const draggableGroup = shallowRef<Group>();
function draggable(it: Group) {
	return draggableGroup.value === it;
}
function drop(it: Group) {
	const old = currentGroup.value;
	if (!old) {
		return;
	}
	if (it === old) {
		return;
	}
	let list = groups.value;
	const oldIndex = list.indexOf(old);
	if (oldIndex < 0) {
		return;
	}
	const newIndex = list.indexOf(it);
	if (newIndex < 0) {
		return;
	}
	if (newIndex === oldIndex) {
		return;
	}
	list = [...list.slice(0, oldIndex), ...list.slice(oldIndex + 1)];
	groups.value = [...list.slice(0, newIndex), old, ...list.slice(newIndex)];
}
function getLabel({field}: Group) {
	const label = labels.value;
	return (field in label && label[field]) || field;
}

const tt = __;
</script>

<style scoped lang="less">
  .line {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    margin: 4px;
    padding: 4px;
    inline-size: 100%;

    &:hover {
      background: #0001;
      border-radius: 4px;
    }
  }

  .label {
    flex: 1;
  }

  .footer {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .placeholder {
    flex: 1;
  }
</style>
