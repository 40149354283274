import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElFormItem"], null, {
    default: _withCtx(() => [
      _createVNode($setup["ElTable"], {
        data: $setup.fields,
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode($setup["ElTableColumn"], {
            type: "index",
            index: 1
          }),
          _createVNode($setup["ElTableColumn"], { type: "expand" }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElRow"], { gutter: 8 }, {
                default: _withCtx(() => [
                  _createVNode($setup["ElCol"], { span: 4 }),
                  _createVNode($setup["ElCol"], { span: 5 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('In Progress')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode($setup["ElCol"], { span: 5 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('In progress and overdue')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode($setup["ElCol"], { span: 5 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Completed')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode($setup["ElCol"], { span: 5 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Completed but overdue')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode($setup["ElRow"], { gutter: 8 }, {
                default: _withCtx(() => [
                  _createVNode($setup["ElCol"], { span: 4 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Color')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode($setup["ElCol"], { span: 5 }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ElColorPicker"], {
                        modelValue: row.processing_color || '',
                        "onUpdate:modelValue": $event => ($setup.set(
								$index,
								'processing_color',
								$event || null,
							))
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */),
                  _createVNode($setup["ElCol"], { span: 5 }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ElColorPicker"], {
                        modelValue: row.processing_overdue_color || '',
                        "onUpdate:modelValue": $event => ($setup.set(
								$index,
								'processing_overdue_color',
								$event || null,
							))
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */),
                  _createVNode($setup["ElCol"], { span: 5 }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ElColorPicker"], {
                        modelValue: row.complete_color || '',
                        "onUpdate:modelValue": $event => ($setup.set(
								$index,
								'complete_color',
								$event || null,
							))
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */),
                  _createVNode($setup["ElCol"], { span: 5 }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ElColorPicker"], {
                        modelValue: row.complete_overdue_color || '',
                        "onUpdate:modelValue": $event => ($setup.set(
								$index,
								'complete_overdue_color',
								$event || null,
							))
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */),
              _createVNode($setup["ElRow"], { gutter: 8 }, {
                default: _withCtx(() => [
                  _createVNode($setup["ElCol"], { span: 4 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Border Color')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode($setup["ElCol"], { span: 5 }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ElColorPicker"], {
                        modelValue: row.processing_border_color || '',
                        "onUpdate:modelValue": $event => ($setup.set(
								$index,
								'processing_border_color',
								$event || null,
							))
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */),
                  _createVNode($setup["ElCol"], { span: 5 }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ElColorPicker"], {
                        modelValue: row.processing_overdue_border_color || '',
                        "onUpdate:modelValue": $event => ($setup.set(
								$index,
								'processing_overdue_border_color',
								$event || null,
							))
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */),
                  _createVNode($setup["ElCol"], { span: 5 }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ElColorPicker"], {
                        modelValue: row.complete_border_color || '',
                        "onUpdate:modelValue": $event => ($setup.set(
								$index,
								'complete_border_color',
								$event || null,
							))
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */),
                  _createVNode($setup["ElCol"], { span: 5 }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ElColorPicker"], {
                        modelValue: row.complete_overdue_border_color || '',
                        "onUpdate:modelValue": $event => ($setup.set(
								$index,
								'complete_overdue_border_color',
								$event || null,
							))
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */),
              _createVNode($setup["ElRow"], { gutter: 8 }, {
                default: _withCtx(() => [
                  _createVNode($setup["ElCol"], { span: 8 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Display control field')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode($setup["ElCol"], { span: 16 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.tt('Displays control field values')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode($setup["ElRow"], { gutter: 8 }, {
                default: _withCtx(() => [
                  _createVNode($setup["ElCol"], { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ElSelect"], {
                        modelValue: row.filter_field,
                        filterable: "",
                        defaultFirstOption: "",
                        "onUpdate:modelValue": $event => ($setup.set($index, 'filter_field', $event)),
                        clearable: ""
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filterFields, (field) => {
                            return (_openBlock(), _createBlock($setup["ElOption"], {
                              key: field.value,
                              value: field.value,
                              label: field.label
                            }, null, 8 /* PROPS */, ["value", "label"]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */),
                  _createVNode($setup["ElCol"], { span: 16 }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ElSelect"], {
                        modelValue: $setup.getList(row.filter_value),
                        filterable: "",
                        defaultFirstOption: "",
                        "onUpdate:modelValue": $event => ($setup.set($index, 'filter_value', $setup.setList($event))),
                        multiple: "",
                        allowCreate: "",
                        reserveKeyword: false,
                        style: {"width":"100%"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["ElOption"], {
                            label: "手动输入",
                            value: "",
                            disabled: ""
                          })
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Label')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElInput"], {
                modelValue: row.label || '',
                "onUpdate:modelValue": $event => ($setup.set($index, 'label', $event))
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Start Date')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElSelect"], {
                modelValue: row.start,
                filterable: "",
                defaultFirstOption: "",
                "onUpdate:modelValue": $event => ($setup.set($index, 'start', $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fieldOptions, (field) => {
                    return (_openBlock(), _createBlock($setup["ElOption"], {
                      key: field.value,
                      value: field.value,
                      label: field.label
                    }, null, 8 /* PROPS */, ["value", "label"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('End Date')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElSelect"], {
                modelValue: row.end,
                filterable: "",
                defaultFirstOption: "",
                "onUpdate:modelValue": $event => ($setup.set($index, 'end', $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fieldOptions, (field) => {
                    return (_openBlock(), _createBlock($setup["ElOption"], {
                      key: field.value,
                      value: field.value,
                      label: field.label
                    }, null, 8 /* PROPS */, ["value", "label"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Position')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElInput"], {
                type: "number",
                modelValue: row.position || '',
                "onUpdate:modelValue": $event => ($setup.setWidth($index, 'position', $event || 0))
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Width')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElInput"], {
                type: "number",
                modelValue: row.width || '',
                "onUpdate:modelValue": $event => ($setup.setWidth($index, 'width', $event || 0))
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Color')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElColorPicker"], {
                modelValue: row.color || '',
                "onUpdate:modelValue": $event => ($setup.set($index, 'color', $event || null))
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Border Color')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElColorPicker"], {
                modelValue: row.border_color || '',
                "onUpdate:modelValue": $event => ($setup.set($index, 'border_color', $event || null))
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Key')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElInput"], {
                modelValue: row.key || '',
                "onUpdate:modelValue": $event => ($setup.set($index, 'key', $event))
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Gantt Reference')
          }, {
            default: _withCtx(({ row, $index }) => [
              _createVNode($setup["ElInput"], {
                modelValue: row.reference || '',
                "onUpdate:modelValue": $event => ($setup.set($index, 'reference', $event))
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            label: $setup.tt('Operation')
          }, {
            default: _withCtx(({ $index }) => [
              _createVNode($setup["ElButtonGroup"], null, {
                default: _withCtx(() => [
                  _createVNode($setup["ElButton"], {
                    onClick: $event => ($setup.moveTo($index)),
                    text: "",
                    icon: $setup.Sort,
                    title: $setup.tt('Move')
                  }, null, 8 /* PROPS */, ["onClick", "icon", "title"]),
                  _createVNode($setup["ElButton"], {
                    type: "danger",
                    onClick: $event => ($setup.remove($index)),
                    text: "",
                    icon: $setup.Delete,
                    title: $setup.tt('Delete')
                  }, null, 8 /* PROPS */, ["onClick", "icon", "title"])
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["data"]),
      _createVNode($setup["ElButton"], { onClick: $setup.add }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.tt('Add')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}