<template>
	<div class="fileBoxWrapper">
		<div class="fileBox">
			<img class="imgbox" :src="photo.image" />
			<ElDropdown class="dropdown-container">
				<ElButton class="iconBall right" type="primary" :icon="MoreFilled"
					circle />
				<template #dropdown>
					<ElDropdownMenu>
						<ElDropdownItem @click="download">{{ tt("Download") }}</ElDropdownItem>
					</ElDropdownMenu>
				</template>
			</ElDropdown>
		</div>
	</div>
</template>
<script lang="ts" setup>
import {defineProps, toRefs} from 'vue';
import {MoreFilled} from '@element-plus/icons-vue';

import {Image} from '../type';
import saveAs from '../../../../utils';
const tt = __;
interface Props{
	photo:Image,
}
const props = defineProps<Props>();

const {photo} = toRefs(props);

function download() {
	if (!photo.value.image) {
		return;
	}
	saveAs(photo.value.image, photo.value.image_name!);
}
</script>
<style lang="less" scoped>
.fileBoxWrapper {
	display: inline-block;
	width: 100px;
	height: 100px;
}

.imgbox {
	display: block;
	width: 84px;
	height: 84px;
	object-fit: cover;
	overflow: hidden;
	overflow: hidden;
	border-radius: 4px;
}

.fileBox {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100px;
	padding: 8px 8px 8px 8px;
	border: 1px solid white;
	border-radius: 6px;
	cursor: pointer;

	.name {
		width: 116px;
		margin-top: 8px;
		overflow: hidden;
		font-size: 14px;
		line-height: 18px;
		text-align: center;
		word-wrap: break-word;
		word-break: break-all;
	}

	.dropdown-container {
		position: absolute;
		top: 6px;
		right: 0px;
		display: block;
	}

	.iconBall {
		position: absolute;
		width: 32px;
		height: 32px;
		color: white;
		font-size: 16px;
		line-height: 32px;
		text-align: center;
		background-color: #e6f7ff;
		border-radius: 16px;
		padding: 0;
		transform: rotate(-90deg);

		&.left {
			top: 6px;
			left: 6px;
			display: block;
			opacity: 0;
			background-color: #91d5ff;
		}

		&.right {
			top: 6px;
			right: 6px;
			display: block;
			opacity: 0;
			background-color: #91d5ff;
		}
	}

	&:hover {
		background-color: #f0f6ff;
		border: 1px solid #f0f6ff;

		.iconBall {
			&.right {
				display: block;
				opacity: 0.8;

				&:hover {
					background-color: #1890ff;
					opacity: 1;
				}
			}
		}
	}
}
</style>
