
const groupFieldTypes = new Set([
	'select',
	'check',
	'date',
	'datetime',
	'time',

	'small text',
	'text',
	'code',
	'color',
	'data',
	'currency',
	'float',
	'int',
	'link',
	'long text',
	'percent',
	'rating',
]);
export default groupFieldTypes;
