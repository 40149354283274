import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a0cdcc3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 3,
  class: "private"
}
const _hoisted_4 = { class: "file-title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")
  const _component_el_dropdown = _resolveComponent("el-dropdown")
  const _component_el_tooltip = _resolveComponent("el-tooltip")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'img-item': true, isSelected: $setup.isSelected })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "cursor-area",
        onClick: $setup.click
      }, [
        _createVNode(_component_el_icon, {
          class: "check",
          size: 30,
          onClick: _cache[0] || (_cache[0] = $event => ($setup.selectFile($event)))
        }, {
          default: _withCtx(() => [
            _createVNode($setup["Check"])
          ]),
          _: 1 /* STABLE */
        }),
        ($props.permission.write || $props.permission.export || $props.permission.delete)
          ? (_openBlock(), _createBlock(_component_el_dropdown, {
              key: 0,
              onCommand: $setup.clickMenu,
              onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"])),
              class: "dropdown"
            }, {
              dropdown: _withCtx(() => [
                _createVNode(_component_el_dropdown_menu, { class: "guigu-file-view_dropdown" }, {
                  default: _withCtx(() => [
                    ($props.permission.write)
                      ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                          key: 0,
                          command: "edit"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("编辑")
                          ]),
                          _: 1 /* STABLE */
                        }))
                      : _createCommentVNode("v-if", true),
                    ($setup.fileIcon !== 'folder' && $props.permission.export)
                      ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                          key: 1,
                          command: "download"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("下载")
                          ]),
                          _: 1 /* STABLE */
                        }))
                      : _createCommentVNode("v-if", true),
                    ($props.permission.delete)
                      ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                          key: 2,
                          class: "delete",
                          command: "delete"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("删除")
                          ]),
                          _: 1 /* STABLE */
                        }))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("span", null, [
                  _createVNode(_component_el_icon, {
                    onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
                    class: "menu",
                    size: 30
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["Menu"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        ($setup.fileIcon === 'img')
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: "img",
              src: encodeURI($props.data[$setup.fileField] || '').replace(/#/g, '%23')
            }, null, 8 /* PROPS */, _hoisted_2))
          : (_openBlock(), _createBlock($setup["SvgIcon"], {
              key: 2,
              onClick: $setup.clickIcon,
              size: "84px",
              iconClass: `icon-${$setup.fileIcon}`
            }, null, 8 /* PROPS */, ["iconClass"])),
        ($props.data[$setup.fileField] && $props.data[$setup.fileField].includes('private'))
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "私有"))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_el_tooltip, {
          content: $setup.title,
          placement: "top"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.title), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"])
      ])
    ])
  ], 2 /* CLASS */))
}