import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5401bdf3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-container" }
const _hoisted_2 = { class: "left-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString($setup.tt('Assessor')) + ":", 1 /* TEXT */),
        _createVNode(_component_el_select, {
          onChange: $setup.changeSelectLeader,
          filterable: "",
          disabled: $setup.select_leader_disabled,
          modelValue: $props.assessInfo?.select_leader_doc?.name
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.selectLeaderList, (user) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                label: user.full_name,
                value: user.name
              }, null, 8 /* PROPS */, ["label", "value"]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled", "modelValue"])
      ])
    ])
  ]))
}