import OtherConfig from './OtherConfig.vue';
export { default } from './index.vue';
export type * from './types';

export {default as RebuildSort} from './RebuildSort.vue';

export const widgets: GlobalView.ConfigurationWidget[] = [{
	component: OtherConfig,
	label: '其他',
}];
