import type { Source, Group } from '../core';
import type { ComputedRef, Ref } from 'vue';
import type { VueColumn } from './types';
import { unref, watch } from 'vue';
import useDestroyable from './useDestroyable';

export default function useGroup(
	source?: Source | Ref<Source | undefined | null>,
	columns?: VueColumn[] | Ref<VueColumn[]>,
	startFixed?: number | Ref<number | undefined>,
): ComputedRef<Group | undefined> {
	const group = useDestroyable(() => {
		const s = unref(source);
		if (!s) { return }
		return s.createGroup([], unref(startFixed));
	})
	watch([group, () => unref(columns) || []], ([group, columns]) => {
		if (!group) { return; }
		group.setColumns(columns);
	})
	return group;
}
