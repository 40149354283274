import mountVueToPage from 'guiguLib/mountVueToPage';

import ProjectSelect from './index.vue';

const { Toolbar } = frappe.ui.toolbar;

const old_make = Toolbar.prototype.make;
function addProjectSelect() {
	const ul = document.querySelector('header .navbar-nav:last-child');
	if (!ul) { return; }
	const li = document.createElement('li');
	ul.insertBefore(li, ul.firstChild);
	mountVueToPage(li, ProjectSelect);

}
addProjectSelect();
Toolbar.prototype.make = function () {
	old_make.call(this);
	addProjectSelect();
};
