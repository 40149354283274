import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Main"], {
    meta: $props.meta,
    options: $props.options,
    loading: $props.loading,
    linkField: $setup.linkField,
    value: $setup.value,
    "onUpdate:value": _cache[0] || (_cache[0] = $event => (($setup.value) = $event)),
    onRefresh: $setup.refresh
  }, null, 8 /* PROPS */, ["meta", "options", "loading", "linkField", "value"]))
}