const _events:Record<string, Function[]> = {};
export function registerEvent(event:string, fn:Function) {
	_events[event] = _events[event] || [];
	_events[event].push(fn);
}
export function removeEvent(event:string, fn:Function) {
	_events[event] = _events[event].filter(item=>item !== fn);
}
export function triggerEvent(event:string, params?:any) {
	for (const fn of (_events[event] || [])) {
		try {
			fn(params);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	}
}
