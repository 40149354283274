import type {ColDef, ColGroupDef} from 'ag-grid-community';

import Priority from '../../../../../dw_task_management/page/dw_work_list_task_view/components/Priority.vue';

import Subject from './Subject.vue';
import State from './State.vue';
import type {TaskData} from './types.ts';
export function getColumns(smallMeta: boolean): (ColDef | ColGroupDef)[] {
	const columns: (ColDef | ColGroupDef)[] = [
		{
			headerName: __('Subject'),
			field: 'title',
			enableRowGroup: false,
			editable: false,
			filter: false,
			pinned: smallMeta ? undefined : ('left' as const),
			flex: 2,
			minWidth: 180,
			cellRenderer: Subject,
		},
		{
			headerName: __('Priority'),
			field: 'priority',
			enableRowGroup: false,
			editable: false,
			filter: false,
			flex: 1,
			minWidth: 150,
			cellRenderer: Priority,
		},
		{
			headerName: __('Status'),
			field: 'status',
			enableRowGroup: false,
			editable: false,
			filter: false,
			flex: 1,
			minWidth: 150,
			cellRenderer: State,
		},
		{
			headerName: __('Assignee'),
			field: 'assignee',
			enableRowGroup: false,
			editable: false,
			filter: false,
			flex: 1,
			minWidth: 150,
		},
		{
			headerName: __('Plan Start'),
			field: 'plan_start',
			enableRowGroup: false,
			editable: false,
			filter: false,
			flex: 1,
			minWidth: 150,
		},
		{
			headerName: __('Plan End'),
			field: 'plan_end',
			enableRowGroup: false,
			editable: false,
			filter: false,
			flex: 1,
			minWidth: 150,
		},
		{
			headerName: __('Last Comment'),
			field: 'last_comment',
			enableRowGroup: false,
			editable: false,
			filter: false,
			flex: 2,
			minWidth: 150,
		},
	];
	return columns;
}
/**
 * 处理数据（翻译、去除最后评论的标签）
 */
export function setData(data: TaskData[]) {
	const agData = data.map((item:TaskData) => {
		// 翻译
		item.priority = __(item.priority);
		// 解析标签内容
		if (item.last_comment) {
			const tempDiv = document.createElement('div');
			tempDiv.innerHTML = item.last_comment;
			item.last_comment = tempDiv.textContent || '';
			tempDiv.remove();
		} else {
			item.last_comment = '';
		}
		return item;
	});
	return agData;
}
