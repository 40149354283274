import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href", "title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("a", {
      style: $setup.style,
      href: $setup.href,
      title: $setup.props.params.value,
      onClick: $setup.click
    }, _toDisplayString($props.params.value), 9 /* TEXT, PROPS */, _hoisted_1)
  ]))
}