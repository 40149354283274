import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34c145cb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inheritable-organization" }
const _hoisted_2 = { class: "btn-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($props.permissions.createPermission)
        ? (_openBlock(), _createBlock($setup["ElButton"], {
            key: 0,
            type: "primary",
            onClick: $setup.createInherit
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.tt('Inheritance organization')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _createVNode($setup["ElTable"], {
      data: $setup.inheritList,
      border: "",
      style: {"width":"100%"},
      height: "100%",
      "empty-text": $setup.tt('No Data')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElTableColumn"], {
          prop: "inherit_from_organization_doc.label",
          label: $setup.tt('Inherited from')
        }, null, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          prop: "visible",
          label: $setup.tt('Seen'),
          width: "60"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.visible ? $setup.tt('Yes') : $setup.tt('No')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          prop: "viewable",
          label: $setup.tt('Viewable'),
          width: "60"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.viewable ? $setup.tt('Yes') : $setup.tt('No')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          prop: "addible",
          label: $setup.tt('Addible'),
          width: "60"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.addible ? $setup.tt('Yes') : $setup.tt('No')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          prop: "editable",
          label: $setup.tt('Editable'),
          width: "60"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.editable ? $setup.tt('Yes') : $setup.tt('No')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          prop: "deletable",
          label: $setup.tt('Deletable'),
          width: "60"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.deletable ? $setup.tt('Yes') : $setup.tt('No')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElTableColumn"], {
          prop: "manageable",
          label: $setup.tt('Manageable'),
          width: "60"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.manageable ? $setup.tt('Yes') : $setup.tt('No')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        (
				$props.permissions.writePermission || $props.permissions.deletePermission
			)
          ? (_openBlock(), _createBlock($setup["ElTableColumn"], {
              key: 0,
              prop: "address",
              label: $setup.tt('Action'),
              width: "130"
            }, {
              default: _withCtx((scope) => [
                ($props.permissions.writePermission)
                  ? (_openBlock(), _createBlock($setup["ElButton"], {
                      key: 0,
                      type: "primary",
                      onClick: $event => ($setup.editInherit(scope.row))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.tt('Edit')), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                  : _createCommentVNode("v-if", true),
                ($props.permissions.deletePermission)
                  ? (_openBlock(), _createBlock($setup["ElButton"], {
                      key: 1,
                      type: "danger",
                      onClick: $event => ($setup.deleteInherit(scope.row))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.tt('Delete')), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["data", "empty-text"])
  ])), [
    [$setup["vLoading"], $setup.loading]
  ])
}