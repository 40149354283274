
import type { Line } from './nyloongTable/gantt';
import type { GanttEstimatedLine } from './types';

function getMethod({
	method, method_default, method_percent, method_plan, method_disabled,
}: GanttEstimatedLine, data: any) {
	if (method) {
		const m = data[method];
		if (m === method_percent) { return 'Percent'; }
		if (m === method_plan) { return 'Plan'; }
		if (m === method_disabled) { return 'Disabled'; }
	}
	return method_default;
}


export default function getEstimatedLine(v: GanttEstimatedLine, index: number): Line {
	const { width, position, plan, start, percent, end,
		percent_color,
		plan_color,
		percent_border_color,
		plan_border_color,
		aggregate_color,
		aggregate_border_color,
	} = v;
	const name = `-${index + 1}`;
	return {
		meta: { name, type: 'estimated' },
		width, position, start(data: any, dates, endDates) {
			if (!end || endDates[end]) { return; }
			const method = getMethod(v, data);
			if (method === 'Plan') {
				return plan && endDates[plan] && new Date || '';
			}
			if (method !== 'Percent') { return ''; }
			const s = start && dates[start];
			if (!s) { return; }
			const p = percent && data[percent];
			if (!p) { return; }
			if (p <= 0 || p >= 100) { return; }
			return new Date;
		}, end(data: any, dates, endDates) {
			if (!end || endDates[end]) { return; }
			const method = getMethod(v, data);
			if (method === 'Plan') {
				return plan ? endDates[plan] : '';
			}
			if (method !== 'Percent') { return ''; }
			const s = start && dates[start];
			if (!s) { return; }
			const p = percent && data[percent];
			if (!p) { return; }
			if (p <= 0 || p >= 100) { return; }
			const ss = Number(s);
			return new Date((Number(new Date) - ss) / p * 100 + ss);

		},
		color: ({ data, children }) => {
			if (children.length) { return aggregate_color || ''; }
			const method = getMethod(v, data);
			if (method === 'Plan') {
				return plan_color || '';
			}
			if (method === 'Percent') {
				return percent_color || '';
			}
			return '';

		}, borderColor: ({ data, children }) => {
			if (children.length) { return aggregate_border_color || ''; }
			const method = getMethod(v, data);
			if (method === 'Plan') {
				return plan_border_color || '';
			}
			if (method === 'Percent') {
				return percent_border_color || '';
			}
			return '';

		},
	};
}
