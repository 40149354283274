import moment from 'moment';

import { Period } from '../../../guigu_pm_construction/page/week_plan/type/index';

export async function getTodyPeriod(project:string, calendarType:string, periodType:string){
	const today = moment().format('YYYY-MM-DD');
	const todayPeriods:Period[] = await frappe.db.get_list('Guigu Calendar Period', {
		filters:{
			project,
			guigu_calendar_type:calendarType,
			start_date:['<=', today ],
			end_date:['>=', today ],
			period_type:periodType,
		},
		fields:['*'],
		limit:'',
	});
	return todayPeriods;
}
export async function getContentPeriod(
	project:string, calendarType:string, periodType:string, yearPeriod?:string
	){
	const res:Period[] = await frappe.db.get_list('Guigu Calendar Period', {
		filters:{
			project,
			guigu_calendar_type:calendarType,
			period_type:periodType,
			value:yearPeriod&&['like', `${yearPeriod}%`],
		},
		fields:['*'],
		order_by:'value asc',
		limit:'',
	});
	return res;
}
