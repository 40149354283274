import type {
	ICellRendererParams,
} from 'ag-grid-community';

export function getBoqItemColumns(emit: () => void, layout: string) {
	const columns = [{
		headerName: __('Boq Item Full Name'),
		field: 'boq_item_full_name',
		width: 400,
		editable: false,
		cellRenderer: 'AgGridBoqItemSubject',
		pinned: 'left',
		resizable: true,
		tooltipField: 'boq_item_full_name',
		cellRendererParams: (params: ICellRendererParams) => {
			const { value } = params;
			return {
				value,
				emit,
				layout,
			};
		},
	},
	{
		headerName: __('Boq Item Type'),
		field: 'boq_type',
		width: 140,
		editable: false,
	},
	{
		headerName: __('Boq Item Status'),
		field: 'boq_status',
		width: 140,
		editable: false,
		cellRenderer: 'AgGridBoqItemStatus',
	},
	{
		headerName: __('Boq Item Unit'),
		field: 'boq_unit',
		width: 140,
		editable: false,
	},
	{
		headerName: __('Material Theory Consumption'),
		field: 'material_theory_consumption',
		width: 320,
		editable: false,
	},
	];
	return columns;
}
