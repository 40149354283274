<template>
	<div class="root">
		<template v-if="params.data">
			<span v-if="aggregation">合计</span>
			<template v-else>
				<a :href="href" @click="click" :title="value">{{ value }}</a>
				<component v-if="rowAction" :is="rowAction" :value="data"
					:meta="meta" @delete="del" :treeParent="treeParent" />
			</template>

		</template>
	</div>
</template>
<script lang="ts" setup>
import {computed} from 'vue';
import type {ICellRendererParams} from 'ag-grid-community';

import html2text from '../../utils/html2text';
import useDocHref from '../../utils/useDocHref';

const props = defineProps<{
	params: ICellRendererParams & {
		preventClick(e: Event, data: any): void;
		del(data: any): void;
		getState(): {
			meta: locals.DocType;
			treeParent?: string;
			rowAction: any;
			titleField: locals.DocField | null;
			linkField?: string;
		}
	}
}>();
const state = props.params.getState();
const meta = computed(() => state.meta);
const treeParent = computed(() => {
	if (meta.value.fields.find(f => f.fieldname === 'is_group')) {
		if (!(props.params.data || {}).is_group) {
			return;
		}
	}
	return state.treeParent;
});

function click(e: Event) {
	const {params} = props;
	params.preventClick(e, params.data);
}

const data = computed(() => props.params.data);
const aggregation = computed(() => data.value.__IS_AGGREGATION__);

const rowAction = computed(() => state.rowAction);
const value = computed(() => {
	const type = state.titleField?.fieldtype || '';
	if (['HTML', 'HTML Editor', 'Text Editor'].includes(type)) {
		return html2text(props.params.value);
	}
	return props.params.value;
});
const linkField = computed(() => state.linkField);
const href = useDocHref(meta, data, linkField);
function del() {
	props.params.del(data.value);
}
</script>
<style lang="less" scoped>
.root {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;

	>:first-child {
		flex: 1;

		overflow: hidden;
		text-overflow: ellipsis;
	}
}
</style>
