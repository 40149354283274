import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElOption = _resolveComponent("ElOption")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Is Folder Field')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElSelect"], {
          modelValue: $setup.is_folder_field,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.is_folder_field) = $event)),
          filterable: "",
          defaultFirstOption: "",
          clearable: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.checkOptions, (field) => {
              return (_openBlock(), _createBlock(_component_ElOption, {
                key: field.value,
                value: field.value,
                label: field.label
              }, null, 8 /* PROPS */, ["value", "label"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('File Name Field')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElSelect"], {
          modelValue: $setup.file_name_field,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.file_name_field) = $event)),
          filterable: "",
          defaultFirstOption: "",
          clearable: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.textOptions, (field) => {
              return (_openBlock(), _createBlock(_component_ElOption, {
                key: field.value,
                value: field.value,
                label: field.label
              }, null, 8 /* PROPS */, ["value", "label"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Folder Name Field')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElSelect"], {
          modelValue: $setup.folder_name_field,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.folder_name_field) = $event)),
          filterable: "",
          defaultFirstOption: "",
          clearable: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.textOptions, (field) => {
              return (_openBlock(), _createBlock(_component_ElOption, {
                key: field.value,
                value: field.value,
                label: field.label
              }, null, 8 /* PROPS */, ["value", "label"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode($setup["ElFormItem"], {
      label: $setup.tt('Parent Field')
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElSelect"], {
          modelValue: $setup.parent_field,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.parent_field) = $event)),
          filterable: "",
          defaultFirstOption: "",
          clearable: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.linkOptions, (field) => {
              return (_openBlock(), _createBlock(_component_ElOption, {
                key: field.value,
                value: field.value,
                label: field.label
              }, null, 8 /* PROPS */, ["value", "label"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"])
  ], 64 /* STABLE_FRAGMENT */))
}