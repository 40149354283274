import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0fb56b91"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElDialog"], {
    modelValue: $setup.thisVisible,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.thisVisible) = $event)),
    title: $setup.tt('Batch editing')
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["ElButton"], {
          onClick: _cache[3] || (_cache[3] = $event => ($setup.thisVisible = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElButton"], {
          type: "primary",
          onClick: $setup.confirm
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Confirm')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["MemberForm"], {
          designations: $setup.designations,
          "onUpdate:designations": _cache[0] || (_cache[0] = $event => (($setup.designations) = $event)),
          permissions: $setup.permissionCheckList,
          "onUpdate:permissions": _cache[1] || (_cache[1] = $event => (($setup.permissionCheckList) = $event)),
          roles: $setup.roleCheckList,
          "onUpdate:roles": _cache[2] || (_cache[2] = $event => (($setup.roleCheckList) = $event))
        }, null, 8 /* PROPS */, ["designations", "permissions", "roles"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}