<template>
	<ElTooltip placement="top" :content="labelString">
		<div :style="{overflow:'auto'}">
			<el-space>
				<el-tag v-for="d in value" >
					<a :href="d.multi_table_url">{{ tt(d.multi_table_label) }}</a>
				</el-tag>
			</el-space>
		</div>
	</ElTooltip>
</template>
<script lang="ts" setup>
import { computed } from 'vue';


const props = defineProps<{
	params:any
}>();

const value = computed(()=>(props.params.value||[]).map(item=>({
	...item,
	multi_table_url:`/app/${frappe.router.slug(item.multi_table_link)}/${item.multi_table_value}`,
})));
const labelString = computed(()=>(props.params.value||[]).map(item=>item.multi_table_label).join(','));
const tt = __;

</script>
