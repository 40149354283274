
<template>

	<AgGridVue class="ag-theme-guigu boq_item-table"
		v-loading="loading"
		:columnDefs="columnDefs"
		:rowData="boqItemList"
		treeData="true"
		:localeText="zhCN"
		:getDataPath="getDataPath"
		:getRowId="getRowId"
		:autoGroupColumnDef="autoGroupColumnDef"
		@grid-ready="onGridReady"
	/>
</template>
<script lang="ts">
import {defineComponent, computed, type PropType} from 'vue';
import {AgGridVue} from 'ag-grid-vue3';
import type {
	GridReadyEvent,
} from 'ag-grid-community';

import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';
import type {BoqItem} from '../type';

import {getBoqItemColumns} from './BoqItemColumns';
import AgGridBoqItemSubject from './AgGridBoqItemSubject.vue';
import AgGridBoqItemStatus from './AgGridBoqItemStatus.vue';

export default defineComponent({
	components: {
		AgGridVue,
		AgGridBoqItemSubject,
		AgGridBoqItemStatus,
	},
	emits: ['updateRecordValue', 'onGridReady'],
	props: {
		boqItemList: Object as PropType<BoqItem[]>,
		layout: String,
		loading: Boolean,
	},
	setup(props, context) {
		async function updateRecordValue(value: any) {
			context.emit('updateRecordValue', {...value});
		}

		async function onGridReady(event: GridReadyEvent) {
			context.emit('onGridReady', event.api);
		}

		function getDataPath(data: any) {
			return data.ancestry;
		}

		function getRowId(params: any) {
			return params.data.newCode;
		}

		const autoGroupColumnDef = {
			headerName: __('Boq Item Code'),
			width: 250,
			minWidth: 150,
			editable: false,
			pinned: 'left',
			sortable: true,
			filter: true,
			flex: 1,
			resizable: true,
			expanded: true,
		};

		const columnDefs = computed(()=>getBoqItemColumns(updateRecordValue, props.layout));

		return {
			columnDefs,
			zhCN,
			getDataPath,
			autoGroupColumnDef,
			getRowId,
			onGridReady,
			loading: props.loading,
		};
	},
});
</script>

<style scoped>
.title {
	margin-bottom: 0;
}
.boq_item-table{
	height: 100%;
}
</style>
