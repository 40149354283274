<template>
	<AgGridVue
		v-loading="loading"
		class="ag-theme-guigu week-plan_table"
		:columnDefs="columnDefs"
		:rowData="progress"
		:defaultColDef="defaultColDef"
		rowSelection="multiple"
		animateRows="true"
		@grid-ready="onGridReady"
		:autoGroupColumnDef="autoGroupColumn"
		:getDataPath="getDataPath"
		:treeData="true"
		@cellValueChanged="cellValueChanged"
		@rowGroupOpened="rowGroupOpened"
		:suppressDragLeaveHidesColumns="smallMeta"
		:localeText="zhCN"
		:getRowId="getRowId">
	</AgGridVue>
</template>
<script lang="ts">
import {ref, computed, defineComponent, PropType, getCurrentInstance, watch} from 'vue';
import {AgGridVue} from 'ag-grid-vue3';
import {ICellRendererParams, RowGroupOpenedEvent} from 'ag-grid-community';
import moment from 'moment';

import {FormatDiaryStructure, NewProgress, NewStructureProgress, Period, ProgressFormData, ProgressPlan, StructureItemFormat} from '../type';
import FloatComponent from '../../../../../AgGrid/components/Float.vue';
import FloatEditor from '../../../../../AgGrid/components/FloatEditor.vue';
import {useMetaQuery} from '../../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';
import zhCN from '../../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';

import {getPlanColumns} from './tableColumns';

export default defineComponent({
	components: {
		FloatComponent,
		AgGridVue,
		FloatEditor,
	},
	props: {
		structureDescendants: Object as PropType<StructureItemFormat[]>,
		progressPlans: Object as PropType<ProgressPlan[]>,
		project: String,
		structure: Object as PropType<FormatDiaryStructure|undefined>,
		progress: Object as PropType<(StructureItemFormat & {
			progressPlans?: ProgressPlan | undefined;
		})[]>,
		dateRange: Object as PropType<string[]>,
	},
	emits: {
	},
	setup(props, context) {
		const smallMeta = useMetaQuery();
		const {appContext} = getCurrentInstance() || {};
		const loading = ref<boolean>(false);
		const gridApi = ref(null);
		const onGridReady = (params: any) => {
			gridApi.value = params.api;
		};

		const autoGroupColumn = ref({
			headerName: __('Construction content'),
			flex: 1,
			minWidth: 230,
			enableRowGroup: false,
			editable: false,
			field: 'name',
			filter: false,
			sortable: false,
			pinned: 'left',
		});
		watch(() => props.dateRange, () => {
			gridApi.value?.collapseAll();
		});
		watch(() => props.structureDescendants, () => {
			gridApi.value?.collapseAll();
		});
		const columnDefs = computed(() => getPlanColumns(props.dateRange));
		function getDataPath(data: any) {
			return data.ancestry;
		}
		const defaultColDef = {
			sortable: true,
			filter: true,
			flex: 1,
			resizable: true,
		};
		function rowGroupOpened(event: RowGroupOpenedEvent) {
			const {expanded, data} = event;
			if (!expanded) {
				return;
			}
			const structureProgress = props.structureDescendants?.filter(item => item.parent === data.id && item.progressItemId);
			const unRequestProgress = structureProgress?.filter(item => !props.progressPlans?.some(each => each.guigu_construction_structure_progress === item.structureProgressId));
			if (!unRequestProgress || unRequestProgress?.length === 0) {
				return;
			}
			context.emit('requestProgressPlan', event);
		}
		async function cellValueChanged(event: any) {
			const {data, value, colDef: {field}} = event;
			const actualDiaryDate = moment(field).subtract(1, 'days').format('YYYY-MM-DD');
			loading.value = true;
			let newData;
			if (value?.diary_name) {
				if (value?.actual_name) {
					const res = await frappe.db.set_value(
						'Guigu Construction Progress Actual',
						value.actual_name,
						'tomorrow_plan_quantity',
						value?.quantity,
						() => { },
					);
				} else {
					const newProgressActual = await frappe.db.insert({
						doctype: 'Guigu Construction Progress Actual',
						parent: value?.diary_name,
						parentfield: 'progress_actual',
						parenttype: 'Guigu Construction Diary',
						guigu_construction_structure_progress: data.structureProgressId,
						tomorrow_plan_quantity: value?.quantity,
					});
				}
			} else {
				const newDiary = await frappe.db.insert({
					doctype: 'Guigu Construction Diary',
					project: props.project,
					date: actualDiaryDate,
					guigu_team: props.structure?.constructionTeam,
					guigu_construction_structure: props.structure?.id,
				});
				const newProgressActual = await frappe.db.insert({
					doctype: 'Guigu Construction Progress Actual',
					parent: newDiary.name,
					parentfield: 'progress_actual',
					parenttype: 'Guigu Construction Diary',
					guigu_construction_structure_progress: data.structureProgressId,
					tomorrow_plan_quantity: value?.quantity,
				});
			}
			loading.value = false;
			if (!newData) {
				return false;
			}
			context.emit('planChange', newData);
		}
		function getRowId(params: any) {
			return params.data.id;
		}

		return {
			rowGroupOpened,
			loading,
			cellValueChanged,
			onGridReady,
			autoGroupColumn,
			columnDefs,
			getDataPath,
			defaultColDef,
			getRowId,
			close,
			confirm,
			smallMeta,
			zhCN,
		};
	},
});
</script>
<style lang="less" scoped>
.week-plan_table {
  width: 100%;
  height: 100%;
}
</style>
