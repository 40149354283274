import {createApp, h, ref} from 'vue';

import {createTianjyEditorPlugin} from '../../../dw_worklist_management/common/instruction/blocks';

import InstructionDialog from './index.vue';
frappe.provide('frappe.dw_task_management.doctype.dw_task.dw_auto_task_schedule_rule');

frappe.dw_task_management.doctype.dw_task.render_instruction_dialog = function(organizationWorklist:string) {
	const organizationWorklistRef = ref<string>(organizationWorklist);
	const app = createApp({props: [], render: () =>h(InstructionDialog, {
		organizationWorklist: organizationWorklistRef.value,
	})});
	const plugin = createTianjyEditorPlugin('Dw Organization Worklist');
	app.use(plugin);
	function setOrganizationWorklist(name:string) {
		organizationWorklistRef.value = name;
	}
	return {
		app,
		setOrganizationWorklist,
	};
};
