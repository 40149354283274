import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElTree"], {
    class: "container",
    data: $setup.showTree,
    nodeKey: "name",
    defaultExpandedKeys: $setup.defaultExpandedKeys,
    expandOnClickNode: false,
    onNodeClick: $setup.click,
    props: { class: $setup.customNodeClass }
  }, null, 8 /* PROPS */, ["data", "defaultExpandedKeys", "props"]))
}