frappe.ui.form.ControlFloat.prototype.format_for_input =function(value){
	let number_format;
	if (this.df.fieldtype === 'Float' && this.df.options && this.df.options.trim()) {
		if (!this.get_doc()[this.df.options]&&!cur_frm.doc[this.df.options]&&this.df.options.indexOf(':') === -1){
			number_format = this.df.options.trim();
		} else {
			number_format = this.get_number_format();
		}
	}
	let formatted_value = format_number(value, number_format, this.get_precision());
	return isNaN(Number(value)) ? '' : formatted_value;
};

frappe.ui.form.ControlInt.prototype.format_for_input =function(value){
	if (this.df.options && this.df.options.trim()){
		const number_format = this.df.options.trim();
		const formatted_value = format_number(value, number_format, 0);
		return isNaN(Number(value)) ? '' : formatted_value;
	}
	return value == null ? '' : value;
};

const {Float} = frappe.form.formatters;
frappe.form.formatters.Float = function(value, docfield, options, doc){
	const res = Float(value, docfield, options, doc);
	let precision =
			docfield.precision ||
			cint(frappe.boot.sysdefaults && frappe.boot.sysdefaults.float_precision) ||
			null;
	if (docfield.options && docfield.options.trim()) {
		if (!doc[docfield.options]&&!cur_frm.doc[docfield.options]&&docfield.options.indexOf(':') === -1){
			const number_format = docfield.options.trim();
			let formatted_value = format_number(value, number_format, precision);
			return isNaN(Number(value)) ? '' : formatted_value;
		}
	}
	return res;
};

const {Int} = frappe.form.formatters;
frappe.form.formatters.Int = function(value, docfield, options){
	const res = Int(value, docfield, options);
	if (docfield.options && docfield.options.trim()&&cstr(docfield.options).trim() !== 'File Size'){
		const number_format = docfield.options.trim();
		const formatted_value = format_number(value, number_format, 0);
		return isNaN(Number(value)) ? '' : formatted_value;
	}
	return res;
};

frappe.number_format_info['###.##'] = { decimal_str: '.', group_sep: '' };
frappe.number_format_info['###.###'] = { decimal_str: '.', group_sep: '' };
