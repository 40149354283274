import DefaultMain from '../DefaultMain';

frappe.provide('frappe.guigu.GlobalView.mains');
const { mains } = frappe.guigu.GlobalView;
export default function getComp(name: string | any) {
	if (!name) { return DefaultMain; }
	if (typeof name === 'function') { return name; }
	if (typeof name === 'object') { return name; }
	return name in mains && mains[name] || DefaultMain;
}
