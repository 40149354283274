/* eslint-disable eqeqeq */
import {
	CellClassParams,
	ColDef,
	ColGroupDef,
	EditableCallbackParams,
	ICellEditorParams,
	ICellRendererParams,
	ValueSetterParams,
} from 'ag-grid-community';

export const editCellStyle = { border: '1px solid #ccc' };

export function getPlanColumns(
	dayList: string[]
): (ColDef | ColGroupDef)[] {
	const cusColumn = (dayList || []).map(item => ({
		headerName: item,
		field: item,
		width: 120,
		minWidth: 120,
		enableRowGroup: false,
		editable: (params: EditableCallbackParams) => Boolean(params.data.structureProgressId),
		filter: false,
		cellRenderer: 'FloatComponent',
		cellEditor: 'FloatEditor',
		cellStyle: (params: CellClassParams) => {
			const { data } = params;
			if (data?.structureProgressId) {
				return editCellStyle;
			}
			return;
		},
		cellRendererParams: (params: ICellRendererParams) => {
			const { data, colDef, value } = params;
			return {
				value: value?.quantity || null,
			};
		},
		valueSetter: (params: ValueSetterParams) => {
			if (!params.colDef.field) {
				return false;
			}

			params.oldValue = params.data[params.colDef?.field];
			params.data[params.colDef?.field] = {
				actual_name: params.data[params.colDef?.field]?.actual_name,
				diary_name: params.data[params.colDef?.field]?.diary_name,
				quantity: params.newValue,
			};
			return true;
		},
		cellEditorParams: (params: ICellEditorParams) => {
			const { data, value } = params;
			return {
				format: 'float',
				value: value?.quantity || null,
			};
		},
	}));
	return [
		{
			headerName: __("Unit"),
			field: 'unit',
			width: 80,
			minWidth: 80,
			enableRowGroup: false,
			editable: false,
			filter: false,
			pinned: 'left',
		},
		{
			headerName: __("Design Quantity"),
			field: 'designQuantity',
			width: 110,
			minWidth: 110,
			enableRowGroup: false,
			editable: false,
			cellRenderer: 'FloatComponent',
			filter: false,
		},
		...cusColumn,
	];
}
