<template>
	<div>
		<div v-if="dailyRecords.type==='none'">
			<Avatar
				:title="dailyRecords.user?.full_name"
				:url="dailyRecords.user?.user_image"
				:name="dailyRecords.user?.name"></Avatar>
			<span class="noCommit">{{ dailyRecords.user?.full_name}}:{{ tt('not submitted') }}</span>
			<ElButton v-if="showCreate" type="primary" link @click="createDiary">+{{ tt('Add Item') }}
			</ElButton>
		</div>
	</div>
</template>

<script setup lang='ts'>
import { ref, defineProps, defineEmits, computed } from 'vue';

import Avatar from './Avatar.vue';
import { TimelineList } from './type';
const tt = __
const f = frappe;
interface Props{
	dailyRecords:TimelineList,
	project?:string,
}
const props = defineProps<Props>();
interface Emit{

}
const emit = defineEmits<Emit>();
const showCreate = computed(()=>{
	const isCurrentUser = frappe.session.user === props.dailyRecords?.user?.name;
	const createPermission = frappe.perm.has_perm('Guigu Daily Record B', 0, 'create');
	return isCurrentUser&&createPermission;
});
function createDiary(){
	const newDiaryName = frappe.model.make_new_doc_and_get_name('Guigu Daily Record B');
	frappe.model.set_value('Guigu Daily Record B', newDiaryName, 'record_date', props.dailyRecords.date);
	frappe.model.set_value('Guigu Daily Record B', newDiaryName, 'project', props.project);
	frappe.set_route(['form', 'Guigu Daily Record B', newDiaryName]);
}
</script>

<style lang='less' scoped>
.noCommit {
	font-weight: 400;
	font-size: 14px;
}
</style>
