<template>
    <div
		class="name-container"
    >
		{{name}}
		<div v-if="type === 'progress'" class="name-placeholder"></div>
		<ElTooltip v-if="type === 'progress'&&remark" placement="top" :content="remark">
			<ElIcon><QuestionFilled class="remark-icon" /></ElIcon>
		</ElTooltip>
    </div>
 </template>
<script lang="ts" setup>
 import {defineProps} from 'vue';
 import {ElTooltip, ElIcon} from 'element-plus';
 import { QuestionFilled } from '@element-plus/icons-vue';

 const props = defineProps<{params:any}>();
 const {value, name, remark, type} = props.params;
 </script>

<style scoped>
.name-container {
	display: flex;
	align-items: center;
}

.name-placeholder {
	flex: 1;
}

.remark-icon {
	float: right;
	font-size: 12px;
}
</style>
