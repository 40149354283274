export function getFieldName(dt: string, field: string) {
	return frappe.model.get_full_column_name(field, dt);
}
export default function getFields(
	meta: locals.DocType,
	view: GlobalView.View,
	configuration: object,
	order: { doctype: string; field: string; desc: boolean; }[],
	getFields?: GlobalView.FieldGetter,
) {
	const doctype = meta.name;
	let fields: [string, string][] = [];
	for (const f of frappe.model.std_fields_list) {
		fields.push([f, doctype]);
	}
	if (typeof getFields === 'function') {
		for (const f of getFields(meta, configuration)) {
			if (!f) { continue; }
			if (typeof f === 'string') { fields.push([f, doctype]); continue; }
			if (!Array.isArray(f)) { continue; }
			const [k, v] = f;
			if (!k || typeof k !== 'string') { continue; }
			fields.push([k, typeof v === 'string' && v || doctype]);
		}
	}
	const { linkField } = view;
	if (linkField) { fields.push([linkField, doctype]); }
	fields.push([meta.title_field || '', doctype]);
	const settingFields = [
		view.fieldsCopiedWhenCreatingChildren,
		view.fieldsCopiedWhileCopying,
		view.fieldsRequested,
	].flat();
	for (const field of settingFields) {
		if (!field) { continue; }
		fields.push([field, doctype]);
	}
	for (const { fieldOptions: { fieldname, fieldtype, options } } of view.fields) {
		fields.push([fieldname, doctype]);
		if (fieldtype === 'Image' && options) {
			fields.push([options, doctype]);
		}
	}

	if (meta.track_seen) {
		fields.push(['_seen', doctype]);
	}
	for (const f of ['enabled', 'disabled', 'color']) {
		fields.push([f, doctype]);
	}
	for (const f of order) {
		fields.push([f.field, f.doctype]);
	}
	fields = fields.filter(([field, dt]) => {
		if (!field) { return false; }
		if (dt === doctype && field === '_comments') { return true; }
		if (frappe.model.std_fields_list.includes(field)) { return true; }
		if (field === '_seen') { return true; }
		const df = frappe.get_meta(dt)?.fields.find(df => df.fieldname === field);
		if (!df) { return false; }
		if (df.is_virtual) { return false; }
		if (!frappe.perm.has_perm(doctype, df.permlevel, 'read')) { return false; }
		const type = df.fieldtype;
		if (!type) { return false; }
		if (type === 'Table MultiSelect'||type === 'Tianjy Tree Table MultiSelect'){ return true; }
		return !frappe.model.no_value_type.includes(type);
	});

	return fields;
}
