import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fd17e16a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-container" }
const _hoisted_2 = {
  key: 0,
  class: "title"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.props.options.title )
      ? (_openBlock(), _createElementBlock("h4", _hoisted_2, _toDisplayString($setup.props.options.title), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["AgGridVue"], {
      class: "ag-theme-guigu",
      onGridReady: $setup.onReady,
      groupDisplayType: "singleColumn",
      getRowId: $setup.getRowId,
      localeText: $setup.zhCN,
      rowData: $props.data,
      rowGroupPanelShow: "always",
      columnDefs: $setup.columns,
      domLayout: "autoHeight",
      suppressDragLeaveHidesColumns: "",
      autoGroupColumnDef: $setup.autoGroupColumnDef
    }, null, 8 /* PROPS */, ["localeText", "rowData", "columnDefs"])
  ]))
}