import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")
  const _component_el_dropdown = _resolveComponent("el-dropdown")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.permission.write || $setup.permission.export || $setup.permission.delete)
      ? (_openBlock(), _createBlock(_component_el_dropdown, {
          key: 0,
          onCommand: $setup.clickMenu,
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
          class: "dropdown"
        }, {
          dropdown: _withCtx(() => [
            _createVNode(_component_el_dropdown_menu, { class: "guigu-file-view_dropdown" }, {
              default: _withCtx(() => [
                ($setup.permission.write)
                  ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                      key: 0,
                      command: "edit"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("编辑")
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true),
                ($setup.fileIcon !== 'folder' && $setup.permission.export)
                  ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                      key: 1,
                      command: "download"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("下载")
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true),
                ($setup.permission.delete)
                  ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                      key: 2,
                      class: "delete",
                      command: "delete"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("删除")
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("span", null, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode($setup["MoreFilled"])
                ]),
                _: 1 /* STABLE */
              })
            ])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _withDirectives(_createVNode($setup["DeleteFolderDialog"], {
      isFolderField: $setup.isFolderField,
      folderNameField: $setup.folderNameField,
      parentField: $setup.parentField,
      meta: $props.meta,
      visible: $setup.visible,
      folder: $setup.deleteFolder,
      onDeleteFolder: $setup.deleteFolderFn
    }, null, 8 /* PROPS */, ["isFolderField", "folderNameField", "parentField", "meta", "visible", "folder"]), [
      [_directive_loading, $setup.deleteLoading]
    ])
  ], 64 /* STABLE_FRAGMENT */))
}