import { App, Plugin, createApp } from 'vue';

import ElementPlus from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn';

import GuiguDate from './index.vue';

frappe.ui.form.ControlGuiguDate = class ControlGuiguDate extends frappe.ui.form.ControlInput {
	app: App<Element> | undefined = undefined;
	$input: HTMLElement | undefined = undefined;
	df: any;
	input_area: HTMLElement | undefined;
	last_value: string = '';
	value: string = '';
	has_input: boolean = false;
	make_input() {
		if (this.$input || this.app) { return; }

		const propsDefault: Record<string, any> = {
			df: this.df,
			dateControl: this,
			inputClass: 'input-with-feedback form-control',
			maxDate: undefined,
			minDate: undefined,
			disableDateSection: undefined,
		};
		if (this.df.input_css) {
			propsDefault.css = this.df.input_css;
		}
		if (this.df.input_class) {
			propsDefault.inputClass += ` ${this.df.input_class}`;
		}
		const app = createApp(GuiguDate, {
			...propsDefault,
		});
		let lang = 'en';
		frappe.boot.user && (lang = frappe.boot.user.language);
		app.use(ElementPlus, { size: 'small', locale: lang==='zh'?zhCn:'' });
		app.mount(this.input_area);
		this.$input = $(this.input_area).find('input');

		if (app._props) {
			app._props.onChange = (value: string) => {
				this.parse_validate_and_set_in_model(value);
				this.app._instance.props.value = value;
			};
		}
		this.app = app;
		this.has_input = true;
	}

	set_input(value: string) {
		this.last_value = this.value;
		this.value = value;
		this.set_formatted_input(value);
		this.set_disp_area(value);
	}
	set_formatted_input(value) {
		this.app._instance.props.value = value;
	}
	format_for_input(val?: string) {
		return val == null ? '' : val;
	}
	validate(value?: string) {
		if (value && !frappe.datetime.validate(value)) {
			let sysdefaults = frappe.sys_defaults;
			let date_format =
				sysdefaults && sysdefaults.date_format ? sysdefaults.date_format : 'yyyy-mm-dd';
			frappe.msgprint(__('Date {0} must be in format: {1}', [value, date_format]));
			return '';
		}
		return value;
	}
	get_input_value() {
		return this.app?._instance?.props?.value || null;
	}

	// 禁用该日期之后的日期
	set_disable_after_date(date: Date) {
		if (this.app?._instance?.props) {
			this.app._instance.props.minDate = date;
		}
	}
	// 禁用该日期之前的日期
	set_disable_before_date(date: Date) {
		if (this.app?._instance.props) {
			this.app._instance.props.maxDate = date;
		}
	}

	// 禁用这段时间 [开始日期，结束日期]
	set_disable_date_section(section: Date[]) {
		if (this.app?._instance.props) {
			this.app._instance.props.disableDateSection = section;
		}
	}
};
