import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex h-full w-full flex-col items-center justify-center" }
const _hoisted_2 = { class: "mt-2 text-gray-600" }
const _hoisted_3 = { class: "text-center font-light text-gray-400" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FeatherIcon = _resolveComponent("FeatherIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" Invalid Widget "),
    _createVNode(_component_FeatherIcon, {
      name: $props.icon,
      class: _normalizeClass(["h-6 w-6", $props.iconClass])
    }, null, 8 /* PROPS */, ["name", "class"]),
    _createElementVNode("span", _hoisted_2, _toDisplayString($props.title), 1 /* TEXT */),
    _createElementVNode("span", _hoisted_3, _toDisplayString($props.message), 1 /* TEXT */)
  ]))
}