<template>
	<span :title="title">
		<a v-if="href" :class="enableLink ? null : 'disabled'" target="_blank"
			:href="href" @click="click">
			{{ label }}
		</a>
		<a v-if="href" :href="href" :download="download">
			<ElIcon>
				<Download />
			</ElIcon>
		</a>
	</span>
</template>
<script lang="ts" setup>
import {computed} from 'vue';
import {ElIcon} from 'element-plus';
import {Download} from '@element-plus/icons-vue';


const props = defineProps<{
	value: any;
	data: Record<string, any>;
	field: GlobalView.FieldRenderOptions;
	meta?: locals.DocType;
	maxLine?: number;
	enableLink?: boolean;
	noData?: boolean;
	inline?: boolean;
	format?: string;
	showTitle?: boolean;
}>();
const emit = defineEmits<{
	(event: 'filter', field: string, operator: string, value: any): void;
}>();
const field = computed(() => props.field);
const href = computed(() => encodeURI(props.value || '')?.replace(/#/g, '%23'));
const label = computed(() => {
	const name = props.value;
	if (!name) {
		return name;
	}
	const filenameArr = name.split('/');
	let filename = filenameArr.pop();
	const isPrivate = filenameArr.some(item=>item === 'private');
	filename = `[${isPrivate ? __('Private') : __('Public')}]${filename}`;
	return filename;
});
const title = computed(() => {
	if (!props.showTitle) {
		return;
	}
	return `${__(field.value.label)}: ${label.value}`;
});
function setFilter() {
	emit('filter', props.field.fieldname, '=', props.value);
}
const download = computed(() => (props.value || '').split('/').pop() || '');
const tt = __;
function click(e: Event) {
	if (props.enableLink) {
		return;
	}
	e.preventDefault();
}

</script>

<style scoped>
.disabled{
	cursor: default;
}
</style>
