import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64939dfe"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "image-cropper-actions" }
const _hoisted_3 = { class: "btn-group" }
const _hoisted_4 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("img", {
        ref: "image",
        src: $setup.src,
        alt: $props.file.name
      }, null, 8 /* PROPS */, _hoisted_1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.aspect_ratio_buttons, (button) => {
            return _createElementVNode("button", {
              type: "button",
              class: _normalizeClass(["btn btn-default btn-sm", {
							active: isNaN($setup.aspect_ratio)
								? isNaN(button.value)
								: button.value === $setup.aspect_ratio
						}]),
              key: button.label,
              onClick: $event => ($setup.aspect_ratio = button.value)
            }, _toDisplayString(button.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_4)
          }), 64 /* STABLE_FRAGMENT */))
        ])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          class: "btn btn-sm margin-right",
          onClick: _cache[0] || (_cache[0] = $event => ($setup.emit('toggle_image_cropper')))
        }, _toDisplayString($setup.tt("Back")), 1 /* TEXT */),
        _createElementVNode("button", {
          class: "btn btn-primary btn-sm",
          onClick: $setup.crop_image
        }, _toDisplayString($setup.tt("Crop")), 1 /* TEXT */)
      ])
    ])
  ]))
}