import {createApp} from 'vue';

import App from './components/index.vue';

function loadGuiguAppList (wrapper:HTMLElement) {
	const app = createApp(App);
	app.mount(wrapper);
}
frappe.provide('frappe.guigu');
frappe.guigu.pages = frappe.guigu.pages || {};
frappe.guigu.pages.loadGuiguAppList = loadGuiguAppList;
