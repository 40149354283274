import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElDropdown"], {
    class: "gant-dropdown",
    onCommand: _cache[0] || (_cache[0] = $event => ($setup.unit = $event)),
    disabled: $props.loading,
    teleported: false
  }, {
    dropdown: _withCtx(() => [
      _createVNode($setup["ElDropdownMenu"], null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.options, ({ value, label }) => {
            return (_openBlock(), _createBlock($setup["ElDropdownItem"], {
              key: value,
              command: value,
              class: _normalizeClass({ ['current']: $setup.unit === value })
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(label), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["command", "class"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _createVNode($setup["ElButton"], {
        circle: "",
        title: "单位",
        icon: $setup.Coin
      }, null, 8 /* PROPS */, ["icon"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["disabled"]))
}