<template>
	<div>配置文件夹</div>
	<ElFormItem>
		<ElTable :data="download_folder_fields" style="width: 100%">
			<ElTableColumn type="index" :index="1" />
			<ElTableColumn :label="tt('Field')" #="{ row, $index }">
				<ElSelect :modelValue="row.field" filterable defaultFirstOption
					@update:modelValue="set($index, 'field', $event)">
					<ElOption v-for="field in fieldsSelectable" :key="field.value"
						:value="field.value" :label="field.label" />
				</ElSelect>
			</ElTableColumn>
			<ElTableColumn :label="tt('Operation')" #="{ $index }">
				<ElButtonGroup>
					<ElButton @click="moveTo($index)" text :icon="Sort"
						:title="tt('Move')" />
					<ElButton type="danger" @click="remove($index)" text
						:icon="Delete" :title="tt('Delete')" />
				</ElButtonGroup>
			</ElTableColumn>
		</ElTable>
		<ElButton @click="add">{{ tt('Add') }}</ElButton>

	</ElFormItem>
</template>
<script setup lang="ts">
import {computed, shallowRef, h} from 'vue';
import {
	ElTable, ElTableColumn, ElIcon,
	ElSelect, ElCheckbox, ElInput,
	ElFormItem, ElButtonGroup, ElButton, ElOption, ElMessageBox,
} from 'element-plus';
import {Delete, Sort, InfoFilled} from '@element-plus/icons-vue';


const props = defineProps<{
	meta: locals.DocType;
	modelValue: any;
	define?: GlobalView;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: GlobalView.View): void;
}>();
const view = computed({
	get: () => props.modelValue,
	set: v => emit('update:modelValue', v),
});
const tt = __;

const download_folder_fields = computed({
	get: () => {
		const {download_folder_fields} = view.value;
		return (typeof download_folder_fields === 'string' ? download_folder_fields : '')
			.split('\n').map(v => v.split(/\s*[,，]\s*/)[0])
			.map(field => ({field}));
	},
	set: list => {
		view.value = {
			...view.value,
			download_folder_fields: list.map(({field}) => field).join('\n'),
		};
	},
});
function set(index: number, key: keyof GlobalView.Field, value: any) {
	const list = [...download_folder_fields.value];
	const item: any = list[index];
	if (!item) {
		return;
	}
	item[key] = value;
	download_folder_fields.value = list;
}
function add() {
	download_folder_fields.value = [...download_folder_fields.value, {field: ''}];
}
function remove(index: number) {
	const list = [...download_folder_fields.value];
	if (!list.splice(index, 1).length) {
		return;
	}
	download_folder_fields.value = list;
}
function move(from: number, to: number) {
	if (from === to) {
		return;
	}
	const list = [...download_folder_fields.value];
	const item: any = list[from];
	if (!item) {
		return;
	}
	list.splice(from, 1);
	list.splice(to, 0, item);
	download_folder_fields.value = list;
}
const fieldsSelectable = computed(() => {
	const linkType = new Set(['Link', 'Tree Select', 'Tianjy Related Link']);

	const fieldOptions = (props.meta.fields || [])
		.filter((field: locals.DocField) =>
			!frappe.model.no_value_type.includes(field.fieldtype))
		.filter(d => !d.is_virtual).map(f=>({
			value: f.fieldname,
			label: `${__(f.label)}`,
			isLink: linkType.has(f.fieldtype),
			groupable: true,
		}));
	return fieldOptions;
});
function moveTo(index: number) {
	const target = shallowRef<number>(index);
	const field = download_folder_fields.value[index]?.field;
	const label = fieldsSelectable.value.find(f => f.value === field)?.label || field || '';
	ElMessageBox({
		title: `将 ${label} #${index + 1} 移动到...`,
		message: (): any => {
			const labels = new Map(fieldsSelectable.value.map(v => [v.value, v.label]));
			return h(ElSelect, {
				filterable: true, defaultFirstOption: true,
				modelValue: target.value,
				'onUpdate:modelValue': (val: number) => {
					target.value = val;
				},
			}, download_folder_fields.value.map(({field}, i) => h(ElOption, {
				value: i,
				label: i === index
					? '<<<位置不变>>>'
					: `${labels.get(field) || field} #${i + 1} ${i < index ? '之前' : '之后'}`,
			})));
		},
	}).then(() => {
		move(index, target.value);
	});
}
</script>
