import {define} from 'tianjy_workspace';

import vue2renderer from '../vue2renderer';
import createWidgetDialog from '../createWidgetDialog';

import View from './View.vue';
import Config from './Config.vue';

define(
	'DocTypeLink',
	vue2renderer(View),
	createWidgetDialog(Config, 'DocType Link'),
	{
		title: 'DocType Link',
		col: 3,
		minWidth: 3,
		deprecated: true,
	},
);
