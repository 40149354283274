<template>
	<div class="title">
		<span class="toggle-btn" :title="tt('Toggle Sidebar')">
			<svg class="icon icon-md sidebar-toggle-placeholder">
				<use href="#icon-menu"></use>
			</svg>
			<span class="sidebar-toggle-icon">
				<svg class="icon icon-md">
					<use
						href="#icon-sidebar-expand" />
				</svg>
			</span>
		</span>
		<h3 class="title">{{ tt("Construction Diary Report") }}</h3>
	</div>
</template>

<script setup lang='ts'>
const tt = __
</script>

<style lang='less' scoped>
.title {
	height: 75px;
	display: flex;
	align-items: center;
	line-height: 75px;
}
.toggle-btn {
	display: flex;
	margin-right: var(--margin-sm);
	cursor: pointer;
	--icon-stroke: var(--gray-500);

	.sidebar-toggle-placeholder {
		transition: all 0.5s ease-in-out;
	}

	.sidebar-toggle-icon {
		transition: all 0.5s ease-in-out;
		display: none;
	}

}
</style>
