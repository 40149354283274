import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6cf34095"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElCard"], { class: "card" }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString($props.title), 1 /* TEXT */),
        ($props.bind)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode($setup["ElSwitch"], {
                modelValue: $setup.messageEnabled,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.messageEnabled) = $event)),
                disabled: !$setup.subscribeLoading && $props.disabled,
                loading: $setup.subscribeLoading,
                title: $setup.tt('Subscription Notification')
              }, null, 8 /* PROPS */, ["modelValue", "disabled", "loading", "title"]),
              _createVNode($setup["ElButton"], {
                class: "btn",
                disabled: !$setup.bindLoading && $props.disabled,
                loading: $setup.bindLoading,
                onClick: _withModifiers($setup.unbind, ["prevent"])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.tt('Unbind WeChat')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["disabled", "loading", "onClick"])
            ]))
          : (_openBlock(), _createElementBlock("a", {
              key: 1,
              href: $setup.url
            }, [
              _createVNode($setup["ElButton"], {
                class: "btn",
                disabled: $props.disabled
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.tt('Bind WeChat')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["disabled"])
            ], 8 /* PROPS */, _hoisted_3))
      ])
    ]),
    default: _withCtx(() => [
      _createVNode($setup["ElImage"], {
        class: "image",
        disabled: "disabled",
        src: $props.wechatQrcode,
        fit: "scale-down"
      }, null, 8 /* PROPS */, ["src"])
    ]),
    _: 1 /* STABLE */
  }))
}