import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4962b07a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElDialog"], {
    modelValue: $setup.dialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.dialogVisible) = $event)),
    title: $setup.tt('Instruction Version List'),
    width: $setup.smallMeta?'80%':'700'
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["ElButton"], {
          onClick: _cache[0] || (_cache[0] = $event => ($setup.dialogVisible = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Cancel')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElButton"], {
          type: "primary",
          onClick: $setup.confirm
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.tt('Confirm')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode($setup["ElTable"], {
        ref: "tableRef",
        class: "table",
        data: $setup.store.versions,
        style: {"width":"100%"},
        stripe: "",
        onSelect: $setup.selectionChange
      }, {
        default: _withCtx(() => [
          _createVNode($setup["ElTableColumn"], {
            type: "selection",
            width: "55"
          }),
          _createVNode($setup["ElTableColumn"], {
            property: "instruction_version",
            label: $setup.tt('Version'),
            width: "120"
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            property: "compiler",
            label: $setup.tt('instruction Compiler'),
            width: "120"
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            property: "checker",
            label: $setup.tt('instruction Checker'),
            width: "120"
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            property: "change_log",
            label: $setup.tt('Change Log')
          }, null, 8 /* PROPS */, ["label"]),
          _createVNode($setup["ElTableColumn"], {
            fixed: "right",
            label: $setup.tt('Actions'),
            minWidth: "60"
          }, {
            default: _withCtx((data) => [
              _createVNode($setup["ElPopconfirm"], {
                title: $setup.tt('Are you sure you want to directly delete this data?'),
                onConfirm: ()=>$setup.deleteVersion(data.row),
                confirmButtonText: $setup.tt('Confirm'),
                cancelButtonText: $setup.tt('Cancel')
              }, {
                reference: _withCtx(() => [
                  (data.row.name!==$setup.store.currentVersion.name)
                    ? (_openBlock(), _createBlock($setup["ElButton"], {
                        key: 0,
                        type: "danger",
                        size: "small"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.tt('Delete')), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["title", "onConfirm", "confirmButtonText", "cancelButtonText"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["data"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title", "width"]))
}