<template>
	<div class="root">
		<a :href="href" @click="click" :title="value">{{ value }}</a>
		<component v-if="rowAction" @delete="del" :is="rowAction" :value="data" :treeParent="treeParent" />
	</div>
</template>

<script setup lang="ts">
import {computed} from 'vue';

import useDocHref from '../utils/useDocHref';
import html2text from '../utils/html2text';

import type {Column} from './nyloongTable/core';

defineOptions({
	inheritAttrs: false,
});
const props = defineProps<{
	value: any;
	data: Record<string, any>;
	column: Column;
	level: number;
}>();

const colMeta = computed(() => props.column.meta);
const meta = computed(() => colMeta.value?.meta);


function click(e: Event) {
	colMeta.value.preventClick(e, props.data);
}
function del() {
	colMeta.value.del(data.value);
}

const rowAction = computed(() => colMeta.value.rowAction);

const data = computed(() => props.data);

const treeParent = computed(() => {
	const docMeta: locals.DocType = meta.value;
	if (!docMeta) {
		return;
	}
	const parent = docMeta.nsm_parent_field;
	if (!parent) {
		return;
	}
	const {fields} = docMeta;
	if (!fields.find(f => f.fieldname === parent)) {
		return;
	}
	if (fields.find(f => f.fieldname === 'is_group')) {
		if (!data.value?.is_group) {
			return;
		}
	}
	return parent;
});

const value = computed(() => {
	const type = colMeta.value.titleField?.fieldtype || '';
	if (['HTML', 'HTML Editor', 'Text Editor'].includes(type)) {
		return html2text(props.value);
	}
	return props.value;
});
const linkField = computed(() => colMeta.value.linkField);
const href = useDocHref(meta, data, linkField);

</script>
<style lang="less" scoped>
.root {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;

	>:first-child {
		flex: 1;

		overflow: hidden;
		text-overflow: ellipsis;
	}
}
</style>
