import type { Filter } from '../../../../../guigu/guigu/public/js/GlobalView/types';

export default function getFilters(
	doctype: string,
	filters: Filter[],
	params: Record<string, any>,
	lineFilters: {
		fieldtype: string;
		label?: any;
		options?: string | undefined;
		fieldname: string;
		condition: string;
	}[],
) {
	const lineParamsFilters = lineFilters.map(({ fieldname, condition }) => {
		const v = fieldname in params && params[fieldname] || undefined;
		if (v === undefined) { return; }
		if (v === null) { return [doctype, fieldname, 'is', 'not set']; }
		return [doctype, fieldname, condition || '=', v];
	}).filter(Boolean) as [string, string, string, any][];
	const list = filters.map(({ doctype, field, condition, value }) => {
		if (Array.isArray(value)) {
			// eslint-disable-next-line no-param-reassign
			value = value.filter(v => v !== undefined && v !== null);
			if (!value.length) { return; }
		}
		if (value === undefined) { return; }
		if (condition) { return [doctype, field, condition, value]; }
		return [doctype, field, Array.isArray(value) ? 'in' : '=', value];
	}).filter(Boolean) as [string, string, string, any][];
	return [...lineParamsFilters, ...list];
}
