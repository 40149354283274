<template>
	<ElTree
		v-if="dataSource.length > 0"
		class="directory-tree"
		ref="treeRef"
		:data="dataSource"
		node-key="id"
		:default-expanded-keys="defaultExpandedKeys"
		highlight-current
		check-on-click-node
		@node-click="nodeClick"
		@node-expand="nodeExpand"
		@node-collapse="nodeCollapse"
		:props="treeProps"
		:current-node-key="currentNodeKey"
		:expand-on-click-node="false">
		<template #default="{ node, data }">
			<span class="custom-tree-node">
				<span>{{ data.label }}</span>
				<span @click="setHome(node, data, $event)"
					:class="{ homeContainer: true, isHome: data.defaultHomeField }">
					<el-icon :size="20">
						<HomeFilled />
					</el-icon>
				</span>
				<span
					:class="{ favoriteContainer: true, isFavorite: data.favoriteField }"
					@click="setFavorite(node, data, $event)">
					<el-icon :size="20">
						<StarFilled />
					</el-icon>
				</span>
			</span>
		</template>
	</ElTree>
</template>

<script setup lang='ts'>
import { ref, watch, computed } from 'vue';
import { HomeFilled, StarFilled } from '@element-plus/icons-vue';

import { Filter } from '../GlobalView/types';

import { formatData, FormatFolder, list2Tree } from './helper';

interface Props {
	meta: locals.DocType;
	parentField: string;
	folderList: FormatFolder[]
	filters: Filter[]

}
const props = defineProps<Props>();
interface Emit {
	(event: 'setFavorite', data: FormatFolder): void;
	(event: 'setHome', data: FormatFolder): void;
	(event: 'setCurrentFolder', folderName: string): void;
}
const emit = defineEmits<Emit>();
const treeRef = ref();
const currentNodeKey = ref<string>('');
const expandedKeys = ref<string[]>([]);
const treeProps = {
	label: 'label',
	children: 'children',
};

watch([() => props.folderList, () => props.filters], () => {
	const folderFilters = props.filters.filter(item => item.field === props.parentField);
	if (folderFilters.length > 0) {
		const filterFolderName = folderFilters[0].value === 'not set' ? 'all' : folderFilters[0].value;
		if (currentNodeKey.value === filterFolderName) { return; }
		currentNodeKey.value = filterFolderName;
		return;
	}
	const homeFolder = props.folderList.find(item => item.defaultHomeField);
	const defaultFolder = homeFolder ? homeFolder : props.folderList[0];
	if (!defaultFolder) { return; }
	currentNodeKey.value = defaultFolder.name;
	emit('setCurrentFolder', defaultFolder.name);
}, { deep: true });

function nodeExpand(data: FormatFolder) {
	expandedKeys.value.push(data.name);
}
function nodeCollapse(data: FormatFolder) {
	expandedKeys.value = expandedKeys.value.filter(item => item !== data.name);
}
const defaultExpandedKeys = computed(() => {
	if (expandedKeys.value.length > 0) { return expandedKeys.value; }
	const nameArr = props.folderList.map(item => item.name);
	const keys = props.folderList.filter(item => !nameArr.includes(item[props.parentField]) || !item[props.parentField])
		.map(item => item.name);
	return keys;
});
const dataSource = computed(() => {
	const treeData = list2Tree(props.folderList, props.parentField);
	return treeData;
});
async function setHome(node: Node, data: FormatFolder, event: Event) {
	event.stopPropagation();
	emit('setHome', data);
}
async function setFavorite(node: Node, data: FormatFolder, event: Event) {
	event.stopPropagation();
	emit('setFavorite', data);
}

function nodeClick(data: FormatFolder) {
	emit('setCurrentFolder', data.name);
}
</script>

<style lang='less' scoped>
.directory-tree {
	height: 100%;
	overflow-y: auto;
	background: transparent;
}

.custom-tree-node {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	padding-right: 8px;

	.homeContainer {
		opacity: 0;

		&.isHome {
			opacity: 1;
		}
	}

	.favoriteContainer {
		opacity: 0;

		&.isFavorite {
			opacity: 1;
		}
	}

	.isHome {
		color: #1f89e5;
	}

	.isFavorite {
		color: #1f89e5;
	}

	&:hover {
		.homeContainer {
			opacity: 1;
		}

		.favoriteContainer {
			opacity: 1;
		}
	}
}

:deep(.el-tree-node__label) {
	flex: 1
}
</style>
