import type { ColDef, ColGroupDef } from 'ag-grid-community';

export function getColumns(year: string) {
  let columns: (ColDef | ColGroupDef)[] = [
    {
      headerName: __('Organization'),
      field: 'organization_name',
      pinned: 'left',
      width: 150,
      minWidth: 150,
      enableRowGroup: true,
      editable: false,
    },
    {
      headerName: __('Person Name'),
      field: 'user_name',
      pinned: 'left',
      width: 80,
      minWidth: 80,
      enableRowGroup: true,
      editable: false,
    },
    {
      headerName: 'A',
      field: 'A',
      pinned: 'left',
      width: 60,
      minWidth: 60,
      enableRowGroup: true,
      editable: false,
    },
    {
      headerName: 'B+',
      field: 'B+',
      pinned: 'left',
      width: 60,
      minWidth: 60,
      enableRowGroup: true,
      editable: false,
    },
    {
      headerName: 'B',
      field: 'B',
      pinned: 'left',
      width: 60,
      minWidth: 60,
      enableRowGroup: true,
      editable: false,
    },

    {
      headerName: 'C',
      field: 'C',
      pinned: 'left',
      width: 60,
      minWidth: 60,
      enableRowGroup: true,
      editable: false,
    },
  ];
  for (let i = 0; i < 12; i++) {
    const month = `${year}-${i + 1 < 10 ? `0${String(i + 1)}` : i + 1}`;
    columns.push({
      headerName: `${i + 1 < 10 ? `0${String(i + 1)}` : i + 1}${__('Month')} `,
      field: month,
      enableRowGroup: false,
      minWidth: 90,
      editable: false,
    });
  }
  return columns;
}
