import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aae4b606"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "detail-container" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "detail-item" }
const _hoisted_4 = { class: "value" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElButton = _resolveComponent("ElButton")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: $setup.href,
      onClick: $setup.click
    }, [
      _createVNode(_component_ElButton, {
        class: "to_detail",
        size: "small",
        type: "default"
      }, {
        default: _withCtx(() => [
          _createTextVNode("详情")
        ]),
        _: 1 /* STABLE */
      })
    ], 8 /* PROPS */, _hoisted_2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fields, (field) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createElementVNode("span", {
          class: "label",
          style: _normalizeStyle([$setup.blockType.includes(field.fieldtype) ? $setup.blockCss : {}, field.labelStyle])
        }, _toDisplayString($setup.tt(field.label || '')) + ": ", 5 /* TEXT, STYLE */),
        _createElementVNode("span", _hoisted_4, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(field.component), {
            field: field.field,
            format: field.format,
            data: $props.detail,
            value: $props.detail[field.fieldname]
          }, null, 8 /* PROPS */, ["field", "format", "data", "value"]))
        ])
      ]))
    }), 256 /* UNKEYED_FRAGMENT */))
  ]))
}