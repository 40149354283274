import { getMonthWeek } from '../../weekAssessmentDetail/components/getExportData';
import type { ExportItem } from '../type';

export function getExportData(assessInfoList: ExportItem[]) {
	return assessInfoList.map(assessInfo => {
		const {
			name,
			date,
			leader_confirm_date,
			user_confirm_date,
			user_doc,
			department_doc,
			type,
			organization,
			uncomplete_reason = '',
			rectification_record = '',
			suggestion = '',
			support = '',
			leader_doc,
		} = assessInfo;
		const linkAssessList =
      assessInfo.detail
      	?.filter(item => item.doc_name && item.link_doctype)
      	.map(item => ({
      		subject: item.subject || '',
      		standard: item.standard || '',
      		remark: item.remark || '',
      	})) || [];
		const tempAssess =
      assessInfo.temp?.map(item => ({
      	subject: item.subject || '',
      	standard: item.standard || '',
      	remark: item.remark || '',
      })) || [];
		const info = {
			name: name,
			date: date,
			user_doc: user_doc,
			department_doc: department_doc,
			type: type,
			organization: organization,
			detail: {
				year: moment(date).format('YYYY'),
				month: moment(date).format('MM'),
				week: getMonthWeek(date),
				department: department_doc?.label || '',
				name: user_doc?.full_name,
				date: moment(date).format('YYYY-MM'),
				linkAssessList,
				tempAssess,
				uncomplete_reason: uncomplete_reason || '',
				rectification_record: rectification_record || '',
				suggestion: suggestion || '',
				support: support || '',
				leader_confirm_date: leader_confirm_date ? moment(leader_confirm_date).format(__('YYYY Year MM Month DD Day')) : '',
				user_confirm_date: user_confirm_date ? moment(user_confirm_date).format(__('YYYY Year MM Month DD Day')) : '',
				leader: leader_doc?.full_name || '',
				user: user_doc?.full_name || '',
			},
			interview: {
				user: user_doc.full_name || '',
				assess_date: assessInfo.interview_record?.assess_date || '',
				organization: assessInfo.interview_record?.organization || '',
				job: assessInfo.interview_record?.job || '',
				leader: assessInfo.interview_record?.leader_doc?.full_name || '',
				date: date ? moment(assessInfo.date).format(__('YYYY-ww Week')) : '',
				summary: assessInfo.interview_record?.summary || '',
				advantage: assessInfo.interview_record?.advantage || '',
				shortcoming: assessInfo.interview_record?.shortcoming || '',
				requirement: assessInfo.interview_record?.requirement || '',
				learning: assessInfo.interview_record?.learning || '',
				suggestion: assessInfo.interview_record?.suggestion || '',
			},
		};
		return info;
	});
}
