<template>
	<el-tree-select
		class="organization_select"
		v-model="value"
		:data="data"
		multiple
		checkOnClickNode
		checkStrictly
		defaultExpandAll
		filterable
		:renderAfterExpand="false"
	/>
</template>

<script setup lang='ts'>
import { onMounted, ref } from 'vue';

import {list2Tree} from './helper.ts';
import type {Organization} from './type.ts';
const value = ref<string>('');
const data = ref<{value:string, label:string}[]>([]);
defineExpose({
	getValue,
});
function getValue(){
	return value.value;
}
onMounted(() => {
	getOrganizations();
});

async function getOrganizations(){
	const res: Organization[] = await frappe.db.get_list('Tianjy Organization',
		{
			fields: ['*'],
			limit: 0,
		},
	);
	const organizations = res?.map(item=>({
		...item,
		value:item.name,
		parent:item.parent_organization,
	}));
	const tree = list2Tree(organizations, 'parent');
	data.value = tree;
}
</script>

<style lang='less' scoped>
.organization_select{
	width:100%;
}
</style>
