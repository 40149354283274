<template>
	<div style="display: flex;align-items: center;justify-content: space-between;">
		<div :style="titleContainerStyle">
			<span><Icon :icon="icon"></Icon></span>
			<span :style="titleStyle" @click="clickLabel">{{ fullName }}</span>
		</div>
		<div style="display: flex;align-items: center;">
			<span :style="circleStyle" v-if="isCore"></span>
			<ElDropdown trigger="click" @command="command" v-if="isShow">
				<ElIcon style="cursor: pointer;"><MoreFilled /></ElIcon>
				<template #dropdown>
					<ElDropdownMenu>
						<ElDropdownItem command="del" style="color:red">{{ tt('Delete') }}</ElDropdownItem>
					</ElDropdownMenu>
				</template>
			</ElDropdown>
		</div>

	</div>
</template>
<script setup lang="ts">
import type {ICellRendererParams} from 'ag-grid-community';
import {MoreFilled} from '@element-plus/icons-vue';
import {ElIcon, ElDropdown, ElDropdownMenu, ElDropdownItem} from 'element-plus';
import {computed} from 'vue';

import type {process} from '../../../../types';
import type {Worklist} from '../type';
import type {LevelStyle} from '../../dw_standard_process_library_list/components/type';
import Icon from '../../../../component/Icon/index.vue';

import {processLevel} from './helper';
const tt = __;

const props = defineProps<{
	params: ICellRendererParams & {
		levelList: LevelStyle[];
		viewDetail: (name: string, isProcess: boolean) => void;
		hasPermission: boolean;
		processes: process[];
		separator: string;
		deleteWorklist: (data: Worklist) => void;
	}
}>();
/**
 * 点击流程label查看详情
 */
function clickLabel() {
	props.params.viewDetail(props.params.data.name, props.params.data.is_process);
}
const isCore = computed(() => props.params.data.is_core_work_list);

const icon = computed(() => props.params.levelList.find(item =>
	item.level === processLevel[props.params.data?.level])?.icon || '');

const isShow = computed(() => props.params.hasPermission
	&& !props.params.data.is_process
	&& props.params.data.scope !== 'Global');

const fullName = computed(() => {
	const {data, processes, separator} = props.params;
	if (data.is_process) {
		return `${data.full_process_code} ${data.title}`;
	}
	const process = processes.find(item => item.name === data?.parent_dw_process);
	const prefix = process ? `${process.full_process_code}${separator}` : '';
	return `${prefix}${data.full_code} ${data.title}`;
});

function command(action: 'add' | 'del') {
	if (!props.params.data) {
		return;
	}
	switch (action) {
		// case 'add':return props.params.addStandardWorklist(props.params.data);
		case 'del':return	props.params.deleteWorklist(props.params.data);
	}
}
const circleStyle = {
	display: 'inline-block',
	width: '10px',
	height: '10px',
	borderRadius: '50%',
	backgroundColor: 'red',
	marginRight: '20px',
};
const titleStyle = {
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	cursor: 'pointer',
	textDecoration: 'underline',
};
const titleContainerStyle = {
	display: 'flex',
	minWidth: 0,
};
</script>
<style scoped>
</style>
