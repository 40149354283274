<template>
	<div class="report-info_label">1. {{ tt("Construction Progress") }}</div>
	<AgGridVue
		class="ag-theme-guigu"
		:columnDefs="columnDefs"
		:rowData="progressAncestryList"
		rowSelection="multiple"
		animateRows="true"
		:autoGroupColumnDef="autoGroupColumnDef"
		:getDataPath="getDataPath"
		:treeData="true"
		groupDefaultExpanded="-1"
		domLayout="autoHeight"
		:suppressDragLeaveHidesColumns="smallMeta"
		:localeText="zhCN"
		:defaultColDef="defaultColDef">
	</AgGridVue>
</template>

<script lang='ts'>
import {AgGridVue} from 'ag-grid-vue3';
import {ref, defineProps, defineEmits, computed, defineComponent, PropType} from 'vue';

import zhCN from '../../../../../../../../guigu/guigu/public/js/agGrid/zhCN';
import {Progress} from '../type';
import Float from '../../../../AgGrid/components/Float.vue';
import {getTreeAncestry} from '../../../../utils';
import RemarkCellRender from '../agGridComponents/RemarkCellRender.vue';
import NameOrRemarkCellRender from '../agGridComponents/NameOrRemarkCellRender.vue';
import SparklineCellRenderer from '../agGridComponents/SparklineCellRenderer.vue';
import {useMetaQuery} from '../../../../../../../../guigu/guigu/public/js/hooks/useMetaQuery';

import getProgressColumns from './ProgressColumn';
const tt = __;
export default defineComponent({
	components: {
		Float,
		AgGridVue,
		SparklineCellRenderer,
		NameOrRemarkCellRender,
		RemarkCellRender,
	},
	props: {
		progress: Object as PropType<Progress[]>,
		enableAudit: Boolean,
	},
	setup(props, context) {
		const smallMeta = useMetaQuery();
		const autoGroupColumnDef = {
			headerName: __('Construction content'),
			lockPinned: true,
			pinned: 'left',
			flex: 1,
			minWidth: 225,
			editable: false,
			field: 'label',
			filter: false,
			sortable: false,
			enableRowGroup: false,
			cellRendererParams: (params: any) => {
				const {data}:{data:Progress} = params;
				return {
					innerRenderer: 'NameOrRemarkCellRender',
					name: data.label,
					remark: data.progress_remark,
					type: data?.type,
				};
			},
		};
		function getDataPath(data:any) {
			return data.ancestry;
		}
		const columnDefs = computed(()=>getProgressColumns(props.enableAudit));
		const progressAncestryList = getTreeAncestry<Progress>(props.progress || []);
		const defaultColDef = {
			resizable: true,
		};
		return {
			props,
			autoGroupColumnDef,
			getDataPath,
			columnDefs,
			progressAncestryList,
			defaultColDef,
			smallMeta,
			zhCN,
		};
	},
});
</script>

<style lang='less' scoped>
:deep(.ag-group-value) {
	flex: 1;
}
</style>
