<template>
	<el-dialog
	  v-model="visible"
	  :title="tt('Pictures')"
	  :width="dialogWidth"
	  :before-close="handleClose"
	  custom-class="progress-dialog"
	>
		<UploadForm
			ref="uploadFormRef"
			:fileList="fileList"
			:structures="structureDescendants"
			:date="date"
			:showDateInput="showDateInput"
			:defaultStructureId="defaultStructureId"
			@deleteFile="deleteFile"
		></UploadForm>
	  <template #footer>
		  <span class="dialog-footer">
			<ElButton @click="handleClose">{{ tt('Cancel') }}</ElButton>
			<ElButton type="primary" @click="confirm">
			  {{ tt('Confirm') }}
			</ElButton>
		  </span>
	  </template>
	</el-dialog>
  </template>
<script lang="ts" setup>
import { defineProps, defineEmits, toRefs, computed, ref, watch } from 'vue';
import { FormInstance } from 'element-plus/lib/components/index.js';

import {ImageFile, ImageFormValue} from '../../../type/image';
import { StructureItemFormat } from '../../../type/diary';
import { useMediaDialogQuery } from '../useMediaQuery';

import UploadForm from './UploadForm.vue';
const tt = __
interface Props{
	visible:boolean,
	fileList:ImageFile[],
	structureDescendants:StructureItemFormat[],
	date:string,
	showDateInput?:boolean
	defaultStructureId?:string
}
interface Emits{
	(e:'close'):void
	(e:'confirm', forms?:{formRef:FormInstance, key:string, formValue:ImageFormValue}[]):void
}
const props = defineProps<Props>();
const {
	visible, structureDescendants, fileList:fileListProps, showDateInput, defaultStructureId,
} = toRefs(props);
const emit = defineEmits<Emits>();
const fileList = ref<ImageFile[]>([]);

const dialogWidth = useMediaDialogQuery();

watch(fileListProps, function(){
	fileList.value = fileListProps.value;
}, {deep:true});
const uploadFormRef = ref<{
	formRefs:{formRef:FormInstance, key:string, formValue:ImageFormValue}[]
}>();
function deleteFile(uid:string){
	const newList = fileList.value.filter(item=>item.uid!==uid);
	fileList.value = newList;
}
function confirm(){
	emit('confirm', uploadFormRef?.value?.formRefs);
}
function handleClose(){
	emit('close');
}
</script>
