/* eslint-disable eqeqeq */
import { CellClassParams, ICellEditorParams, ICellRendererParams } from 'ag-grid-community';

import FloatComponent from '../../../../../AgGrid/components/Float.vue';
import FloatEditor from '../../../../../AgGrid/components/FloatEditor.vue';
import SparklineCellRenderer from '../../../../../AgGrid/components/SparklineCellRenderer.vue';
import { OriginStructureProgress } from '../../type/StructureProgress';

import Action from './AgGridAction.vue';
const editCellStyle = { border: '1px solid #ccc' };

export function getColumns(
	enableAudit:boolean,
	deleteRecord:(structureProgressName:OriginStructureProgress)=>void,
	clickComplete:(structureProgressName:OriginStructureProgress)=>void,
	onLink:(structureProgressName:OriginStructureProgress)=>void,
){
	const reportColumn = enableAudit
    ? [
		{
			headerName: __('Report Quantity'),
			field: 'report_quantity',
			width: 130,
			minWidth: 130,
			filter: false,
			enableRowGroup: false,
			editable: false,
			cellRenderer: FloatComponent,
			cellRendererParams: (params: ICellRendererParams) => {
			  const { data } = params;
			  return {
				value: data?.report_quantity == 0 ? null : data?.report_quantity,
			  };
			},
		  },
      ]
    : [];
	return [
		{
		  headerName: __('Construction Progress'),
		  field: 'progress_item_title',
		  flex: 1,
		  minWidth: 225,
		  enableRowGroup: false,
		  editable: false,
		  filter: false,
		  pinned: 'left',
		  cellRenderer: 'agGroupCellRenderer',
		},
		{
		  headerName: __('Design Quantity'),
		  field: 'design_quantity',
		  width: 110,
		  minWidth: 110,
		  enableRowGroup: false,
		  editable: true,
          cellEditor: FloatEditor,
          cellStyle: (params: CellClassParams) => editCellStyle,
		  cellRenderer: FloatComponent,
		  filter: false,
		  cellRendererParams: (params: ICellRendererParams) => {
			const { data } = params;
			return {
			  value:
				data?.design_quantity == 0
				  ? null
				  : data?.design_quantity,
			};
		  },
		},
		...reportColumn,
		{
		  headerName: enableAudit?__('Audit volume'):__('Completed Quantity'),
		  field: 'completed_quantity',
		  width: 120,
		  minWidth: 120,
		  filter: false,
		  enableRowGroup: false,
		  editable: false,
		  cellRenderer: FloatComponent,
		  cellRendererParams: (params: ICellRendererParams) => {
			const { data } = params;
			return {
			  value:
				data?.completed_quantity == 0
				  ? null
				  : data?.completed_quantity,
				onClick:clickComplete,
			};
		  },
		},
		{
			headerName: __('Percent Complete'),
			field: 'completed_quantity_rate',
			sortable: false,
			editable: false,
			minWidth: 140,
			flex: 1,
			filter: false,
			enableRowGroup: false,
			cellRenderer: SparklineCellRenderer,
		},
		{
		  headerName: __('Actions'),
		  width: 110,
		  pinned: 'right',
		  enableRowGroup: false,
		  filter: false,
		  sortable: false,
		  editable: false,
		  menuTabs: [],
		  cellRenderer: Action,
		  cellRendererParams: (params: ICellEditorParams) => {
			const { data } = params;
			return {
				onRemove: deleteRecord,
				onLink,
			 };
		  },
		},
	  ];
}
