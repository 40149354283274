<template>
	<div :style="{textAlign: 'right',color:Boolean(props.params.onClick)?'#2490ef':'', cursor:Boolean(props.params.onClick)?'pointer':''}" @click="click">
		{{formatValue}}
	</div>
 </template>
<script lang="ts" setup>
 import {defineProps} from 'vue';
 const props = defineProps<{params:any}>();
 // eslint-disable-next-line eqeqeq
 const formatValue = props.params.value==null||props.params.value==undefined
 	?''
	:parseFloat(parseFloat(String(props.params.value)).toFixed(2)).toLocaleString(
            undefined,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 },
          );
 function click(){
	props.params.onClick?.(props.params.data);
 }
 </script>

<style scoped lang="less">
.number {
	text-align: right;
}

.canClick {
	color: #2490ef;
	cursor: pointer;
}
</style>
