<template>
	<div class="tree-container">
		<ElTree
			ref="userTree"
			v-if="users?.length"
			:data="users"
			:props="defaultProps"
			@node-click="handleNodeClick"
			highlightCurrent
			nodeKey="name"
			:currentNodeKey="currentNodeKey"
		/>
	</div>
</template>

<script setup lang='ts'>
import {ElTree} from 'element-plus';
import {ref, watch} from 'vue';

import {type User} from '../type';
interface Props{
	users: User[]
	user?: string
}
const props = defineProps<Props>();
interface Emit{
	(e: 'selectUser', data: User): void,
}
const emit = defineEmits<Emit>();
const currentNodeKey = ref<string>('');
const userTree = ref<InstanceType<typeof ElTree>>();

watch(() => props.user, () => {
	currentNodeKey.value = props.user || '';
}, {immediate: true});
watch(() => props.users, () => {
	if (userTree.value) {
		userTree.value.store.currentNodeKey = currentNodeKey.value;
	}
}, {immediate: true});

const defaultProps = {
	children: 'children',
	label: 'full_name',
	value: 'name',
};
function handleNodeClick(data:User) {
	emit('selectUser', data);
}
</script>

<style lang='less' scoped>
.tree-container{
	height: 100%;
	overflow: auto;
}
</style>
