frappe.views.Workspace.prototype.get_page_to_show = function () {
	let default_page;

	if (
		localStorage.current_page &&
		this.all_pages.filter((page) => page.title == localStorage.current_page).length != 0
	) {
		default_page = {
			name: localStorage.current_page,
			public: localStorage.is_current_page_public == "true" || localStorage.is_current_page_public == "1",
		};
	} else if (Object.keys(this.all_pages).length !== 0) {
		default_page = { name: this.all_pages[0].title, public: true };
	} else {
		default_page = { name: "Build", public: true };
	}

	const route = frappe.get_route();
	const page = (route[1] == "private" ? route[2] : route[1]) || default_page.name;
	const is_public = route[1] ? route[1] != "private" : default_page.public;
	return { name: page, public: is_public };
};
