<template>
	<li class="ViewPageMenuBtn-item" @click="$emit('click')" tabindex="0">
		<span class="ViewPageMenuBtn-label">{{title}}</span>
		<!-- <kbd v-if="shortcut" class="pull-right">
			<span>{{shortcut}}</span>
		</kbd> -->
	</li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/**
 * @prop title 显示的标题
 * @prop shortcut 快捷键
 *
 * @event click 快捷键
 */
export default defineComponent({
	props: {
		title: String,
		shortcut: String,
	}
})
</script>
<style scoped>
.ViewPageMenuBtn-item:hover,
.ViewPageMenuBtn-item:focus {
	color: var(--text-color);
	text-decoration: none;
	background-color: var(--fg-hover-color);
}

.ViewPageMenuBtn-item {
	border-radius: 4px;
	display: block;
	width: 100%;
	padding: var(--padding-sm) var(--padding-sm);
	clear: both;
	font-weight: 400;
	color: var(--text-color);
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}

.ViewPageMenuBtn-label {
	margin-right: var(--margin-md);
}
</style>
