<template>
	<span class="toggle-btn" :title="tt('Toggle Sidebar')"
		@click="switchOpen">
		<svg class="icon icon-md">
			<use
				:href="modelValue ? '#icon-sidebar-collapse' : '#icon-sidebar-expand'" />
		</svg>
	</span>
</template>

<script setup lang="ts">
const tt = __;
const props = defineProps<{
	modelValue?: boolean;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', v: boolean): void;
}>();
function switchOpen() {
	emit('update:modelValue', !props.modelValue);
}
</script>
<style scoped lang="less">
.toggle-btn {
	display: flex;
	margin-right: var(--margin-sm);
	cursor: pointer;
	--icon-stroke: var(--gray-500);
}
</style>
