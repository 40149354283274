<template>
	<Page>
		<template #title>
			<h3 class="title">{{ tt('Guigu Pc Sub Daily Record') }}</h3>
		</template>
		<template #tools>
			<Tools v-if="doctypeLoaded" :organizations="organizations" v-model:subCompany="selectedSubCompany"
				v-model:searchString="searchString" v-model:date="selectedDate" v-model:showComment="showComment"
				@search="search" @clearSearch="clearSearch"></Tools>
		</template>
		<template #sider>
			<OrganizationTree :organizations="organizations" :loading="loading" @organizationChange="organizationChange"></OrganizationTree>
		</template>
		<DiaryTimeline v-if="doctypeLoaded" :subCompany="selectedSubCompany" :organization="organization"
			:organizations="organizations" :searchString="searchString" :date="selectedDate" :showComment="showComment"
			ref="diaryTimelineRef"></DiaryTimeline>
	</Page>
</template>
<script lang="ts" setup>
import {ref, onMounted, watch} from 'vue';
import moment from 'moment';

import Page from '../../../../../../../guigu_pm/guigu_pm/public/js/components/page/index.vue';

import OrganizationTree from './OrganizationTree.vue';
import DiaryTimeline from './DiaryTimeline.vue';
import Tools from './Tools.vue';
import type {SubCompanyOrganization} from './type';

const tt = __;

const showComment = ref<boolean>(false);
const selectedSubCompany = ref<string>('');
const selectedDate = ref<string>(moment().format('YYYY-MM-DD'));
const loading = ref<boolean>(false);
const organizations = ref<SubCompanyOrganization[]>([]);
const organization = ref<SubCompanyOrganization>();
const doctypeLoaded = ref<boolean>(false);
const searchString = ref<string>('');
const diaryTimelineRef = ref<{
	searchDiary:(searchModeProps: boolean) => void;
}>();
function search() {
	diaryTimelineRef.value?.searchDiary(Boolean(searchString.value));
}
function clearSearch() {
	searchString.value = '';
	diaryTimelineRef.value?.searchDiary(false);
}
async function getOrganizations() {
	loading.value = true;
	organizations.value = [];
	const data = await frappe.call<{ message: SubCompanyOrganization[]; }>({
		method: 'guigu_pc.guigu_pc_daily_record.page.gg_sub_company_daily_record.gg_sub_company_daily_record.get_organizations',
		args: {sub_company: selectedSubCompany.value},
	});
	const org = data?.message || [];
	const isInOrganization = org.some(item => item.user?.name === frappe.session.user);
	if (isInOrganization) {
		org.unshift({
			id: 'my',
			title: __('My'),
			type: 'member',
			user: {name: frappe.session.user, full_name: frappe.session.user_fullname},
		});
	}
	org.unshift({
		id: 'all',
		title: __('All'),
		type: 'organization',
	});
	organizations.value = org;
	loading.value = false;
}
watch(selectedSubCompany, () => {
	getOrganizations();
});

function organizationChange(newOrganizationORUser: SubCompanyOrganization) {
	organization.value = newOrganizationORUser;
}
onMounted(async () => {
	doctypeLoaded.value = false;
	await frappe.model.with_doctype('Guigu Pc Sub Daily Record');
	doctypeLoaded.value = true;
});
</script>
<style scoped>
.title {
	margin-bottom: 0;
}
</style>
