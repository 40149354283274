import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f0bbc239"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-container" }
const _hoisted_2 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$props.noLabel)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($setup.tt('Project')) + ":", 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["ElSelect"], {
      class: "input",
      filterable: "",
      modelValue: $setup.projectValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.projectValue) = $event)),
      popperClass: $setup.popperClassProject
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.projects, (item) => {
          return (_openBlock(), _createBlock($setup["ElOption"], {
            key: item.name,
            label: item.project_abbr_name||item.project_name,
            value: item.name
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", null, [
                _createElementVNode("strong", null, _toDisplayString(item.project_abbr_name|| item.project_name), 1 /* TEXT */),
                _createElementVNode("span", null, _toDisplayString(item.description), 1 /* TEXT */)
              ])
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "value"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "popperClass"])
  ]))
}