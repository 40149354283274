export default async function loadTags(
	doctype: string,
	field: string,
	allFilters: any[][]
) {

	const args = {
		stats: [field],
		doctype,
		filters: allFilters.filter(f_arr => !f_arr.includes('_user_tags')),
	};
	const r = await frappe.call<{
		message: { stats: { [k: string]: [string, number][] } }
	}>({ method: 'frappe.desk.reportview.get_sidebar_stats', type: 'GET', args });
	const stats = r?.message?.stats?.[field] || [];
	const count = field === '_user_tags' && stats.pop()?.[1] || 0;
	const list: {
		label: string;
		count: number;
		name: any;
	}[] = stats.map(([label, count]) => ({ label, count, name: label }));
	if (count) { list.push({ label: __('No Tags'), count, name: null }); }
	return list;
}
