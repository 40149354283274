import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Main"], {
    type: $setup.type,
    department: $setup.department,
    "onUpdate:department": _cache[0] || (_cache[0] = $event => (($setup.department) = $event)),
    user: $setup.user,
    "onUpdate:user": _cache[1] || (_cache[1] = $event => (($setup.user) = $event)),
    date: $setup.date,
    "onUpdate:date": _cache[2] || (_cache[2] = $event => (($setup.date) = $event)),
    periodType: $setup.periodType,
    "onUpdate:periodType": _cache[3] || (_cache[3] = $event => (($setup.periodType) = $event)),
    organization: $setup.organization,
    "onUpdate:organization": _cache[4] || (_cache[4] = $event => (($setup.organization) = $event))
  }, null, 8 /* PROPS */, ["type", "department", "user", "date", "periodType", "organization"]))
}