import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElEmpty"], {
    description: $props.emptyText||$setup.tt('No Instruction')
  }, {
    default: _withCtx(() => [
      ($props.editable)
        ? (_openBlock(), _createBlock($setup["ElButton"], {
            key: 0,
            type: "primary",
            onClick: $setup.create
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.addButtonText||$setup.tt('Add Instruction')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["description"]))
}