import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ellipsis" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.data)
      ? (_openBlock(), _createBlock($setup["FieldRenderer"], {
          key: 0,
          value: $setup.value,
          field: $setup.docField,
          data: $setup.data,
          meta: $setup.meta,
          enableLink: $setup.enableLink,
          ellipsis: "",
          inline: "",
          showTitle: ""
        }, null, 8 /* PROPS */, ["value", "field", "data", "meta", "enableLink"]))
      : ($setup.text)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString($setup.text), 1 /* TEXT */)
          ], 64 /* STABLE_FRAGMENT */))
        : _createCommentVNode("v-if", true)
  ]))
}