import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-99cc2424"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "menu" }
const _hoisted_3 = { style: {"flex":"auto"} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElDrawer"], {
    modelValue: $setup.visible,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($setup.visible) = $event)),
    appendToBody: "",
    destroyOnClose: "",
    direction: "rtl",
    size: "80%",
    beforeClose: $setup.handleClose,
    class: "global-view-drawer",
    customClass: "drawer"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["ElSelect"], {
          modelValue: $setup.type,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.type) = $event)),
          disabled: $setup.isUpdate,
          filterable: "",
          defaultFirstOption: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.types, (type) => {
              return (_openBlock(), _createBlock($setup["ElOption"], {
                key: type.value,
                value: type.value,
                label: type.label
              }, null, 8 /* PROPS */, ["value", "label"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "disabled"]),
        _createVNode($setup["ElInput"], {
          modelValue: $setup.label,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.label) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createBlock($setup["ElMenu"], {
          defaultActive: $setup.active,
          key: $setup.active,
          onSelect: _cache[2] || (_cache[2] = $event => ($setup.active = $event))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.menu, (g) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: g.key
              }, [
                (g.key)
                  ? (_openBlock(), _createBlock($setup["ElMenuItemGroup"], {
                      key: 0,
                      title: g.label
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(g.children, (m) => {
                          return (_openBlock(), _createBlock($setup["ElMenuItem"], {
                            key: m.key,
                            index: m.key
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(m.label), 1 /* TEXT */)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["index"]))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["title"]))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(g.children, (m) => {
                      return (_openBlock(), _createBlock($setup["ElMenuItem"], {
                        key: m.key,
                        index: m.key
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(m.label), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["index"]))
                    }), 128 /* KEYED_FRAGMENT */))
              ], 64 /* STABLE_FRAGMENT */))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["defaultActive"]))
      ]),
      _createVNode($setup["ElForm"], {
        class: "menu-form",
        labelPosition: "top",
        onSubmit: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
      }, {
        default: _withCtx(() => [
          ($setup.current)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                ($setup.current.type)
                  ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.current.component), {
                      key: 0,
                      meta: $props.meta,
                      name: $setup.view.name,
                      type: $setup.type,
                      modelValue: $setup.currentConfiguration,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.currentConfiguration) = $event))
                    }, null, 8 /* PROPS */, ["meta", "name", "type", "modelValue"]))
                  : (_openBlock(), _createBlock(_resolveDynamicComponent($setup.current.component), {
                      key: $setup.current.key,
                      meta: $props.meta,
                      define: $setup.define,
                      modelValue: $setup.view,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.view) = $event))
                    }, null, 8 /* PROPS */, ["meta", "define", "modelValue"]))
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["ElButton"], { onClick: $setup.cancelClick }, {
          default: _withCtx(() => [
            _createTextVNode("取消")
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ElButton"], {
          type: "primary",
          onClick: $setup.confirmClick
        }, {
          default: _withCtx(() => [
            ($setup.isUpdate)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode("更新")
                ], 64 /* STABLE_FRAGMENT */))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode("新建")
                ], 64 /* STABLE_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}