<template>
	<ElButton :class="class" ref="buttonRef" v-click-outside="onClickOutside">{{ buttonLabel }}
	</ElButton>
	<ElPopover
		width="416px"
		ref="popoverRef"
		:virtual-ref="buttonRef"
		virtual-triggering
		trigger="click">
		<div>
			<Header
				v-if="periodType !== 'Year'"
				:yearPeriods="yearPeriods"
				:selectPeriod="selectPeriod"
				@yearChange="yearChange"></Header>
			<PeriodContainer
				:periodType="periodType"
				:periods="contentPeriods"
				:selectPeriod="selectPeriod"
				@periodChange="periodChange"></PeriodContainer>
			<Footer
				:periodType="periodType"
				@clickNow="clickNow"></Footer>
		</div>
	</ElPopover>
</template>
<script lang="ts" setup>
import { ClickOutside as vClickOutside } from 'element-plus';
import { ref, defineProps, toRefs, watchEffect, computed, unref } from 'vue';

import { Period } from './type';
import { getContentPeriod, getTodyPeriod } from './service';
import Header from './Header.vue';
import PeriodContainer from './PeriodContainer.vue';
import Footer from './Footer.vue';
interface Props {
	calendarType: 'Management Calendar' | 'Construction Calendar',
	periodType: 'Year' | 'Year Month' | 'Year Week'
	project: string,
	modelValue?: Period | string
	defaultValue?: boolean
	placeholder?: string
	class?:string
}
const props = defineProps<Props>();
const { calendarType, project, modelValue, periodType, defaultValue, placeholder } = toRefs(props);

interface Emit {
	(e: 'update:modelValue', period?: Period): void
}
const emit = defineEmits<Emit>();
const popoverRef = ref();
const buttonRef = ref();
const yearPeriods = ref<Period[]>([]);
const selectPeriod = ref<Period>();
const contentPeriods = ref<Period[]>([]);
const buttonLabel = computed(() => {
	if (selectPeriod.value) {
		return periodType.value === 'Year'
			? `${selectPeriod.value?.value.toString().slice(0, 4)}`
			: `${selectPeriod.value?.value.toString().slice(0, 4)}.${selectPeriod.value?.value.toString().slice(4)}`;
	}
	return placeholder?.value || __('Please Select');

});
watchEffect(async () => {
	if (!project.value || !calendarType.value) { return; }
	const res = await frappe.db.get_list('Guigu Calendar Period', {
		filters: { 'project': project.value, guigu_calendar_type: calendarType.value, period_type: 'Year' },
		fields: ['*'],
		order_by: 'value asc',
		limit: '',
	});
	yearPeriods.value = structuredClone(res);
});

watchEffect(async () => {
	if (modelValue?.value) {
		if (typeof modelValue.value === 'string') {
			const res: Period = await frappe.db.get_doc('Guigu Calendar Period', modelValue.value, {});
			if (res.project !== project.value) {
				emit('update:modelValue');
				return;
			}
			selectPeriod.value = res;
		} else {
			if (modelValue.value.project !== project.value) {
				emit('update:modelValue');
				return;
			}
			selectPeriod.value = modelValue.value;
		}
		return;
	}
	if (!project.value || !calendarType.value || !periodType.value || !defaultValue?.value) { return; }
	const todayPeriods = await getTodyPeriod(project.value, calendarType.value, periodType.value);
	if (!todayPeriods[0]) { return; }
	emit('update:modelValue', todayPeriods[0]);
	[selectPeriod.value] = todayPeriods;
});
watchEffect(() => {
	loadPeriod();
});
async function loadPeriod(yearPeriod?: Period) {
	if (!project.value) { contentPeriods.value = []; }
	if (!project.value || !calendarType.value || !periodType.value) { return; }
	const res = await getContentPeriod(project.value, calendarType.value, periodType.value, yearPeriod?.value);
	contentPeriods.value = res;
}
function yearChange(yearPeriod: Period) {
	loadPeriod(yearPeriod);
}
function periodChange(period: Period) {
	emit('update:modelValue', period);
	unref(popoverRef).hide?.();
}
function onClickOutside() {
	unref(popoverRef).popperRef?.delayHide?.();
}
async function clickNow() {
	if (!project.value || !calendarType.value || !periodType.value) { return; }
	const todayPeriods = await getTodyPeriod(project.value, calendarType.value, periodType.value);
	emit('update:modelValue', todayPeriods[0]);
	unref(popoverRef).hide?.();
}
</script>
<style lang="less" scoped>
</style>
