/* eslint-disable eqeqeq */
import { CellClassParams, EditableCallbackParams, ICellEditorParams, ICellRendererParams } from 'ag-grid-community';

import FloatComponent from '../../../../../AgGrid/components/Float.vue';
import FloatEditor from '../../../../../AgGrid/components/FloatEditor.vue';
import { OriginStructureProgress, StructureProgressBoq } from '../../type/StructureProgress';

import Action from './AgGridAction.vue';
const editCellStyle = { border: '1px solid #ccc' };

export function getStructureProgressBopColumns(
	deleteRecord:(data:StructureProgressBoq)=>void,
){
	return [
		{
		  headerName: __('Boq'),
		  field: 'boq_item_title',
		  flex: 1,
		  minWidth: 225,
		  enableRowGroup: false,
		  editable: false,
		  filter: false,
		  pinned: 'left',
		},
		{
		  headerName: __('Unit'),
		  field: 'unit',
		  width: 110,
		  enableRowGroup: false,
		  editable: false,
		  filter: false,
		  pinned: 'left',
		},
		{
		  headerName: __('Coefficient'),
		  field: 'coefficient',
		  width: 120,
		  minWidth: 120,
		  filter: false,
		  enableRowGroup: false,
		  editable: (params:EditableCallbackParams<StructureProgressBoq>)=>!params?.data?.is_total_price_project,
		  cellEditor: FloatEditor,
		  cellRenderer: FloatComponent,
		  cellRendererParams: (params: ICellRendererParams) => {
			const { data } = params;
			return {
			  value:
				data?.coefficient == 0
				  ? null
				  : data?.coefficient,
			};
		  },
		  cellStyle: (params: CellClassParams) => !params?.data?.is_total_price_project?editCellStyle:{},
		},
		{
			headerName: __('Comprehensive unit price'),
			field: 'price',
			sortable: false,
			minWidth: 140,
			flex: 1,
			filter: false,
			enableRowGroup: false,
			editable: (params:EditableCallbackParams<StructureProgressBoq>)=>params?.data?.is_total_price_project,
			cellEditor: FloatEditor,
			cellRenderer: FloatComponent,
			cellRendererParams: (params: ICellRendererParams) => {
				const { data } = params;
				return {
				value:
					data?.price == 0
					? null
					: data?.price,
				};
			},
			cellStyle: (params: CellClassParams) => params?.data?.is_total_price_project?editCellStyle:{},
		},
		{
		  headerName: __('Actions'),
		  width: 80,
		  pinned: 'right',
		  enableRowGroup: false,
		  filter: false,
		  sortable: false,
		  editable: false,
		  menuTabs: [],
		  cellRenderer: Action,
		  cellRendererParams: (params: ICellEditorParams) => ({
				onRemove: deleteRecord,
			 }),
		},
	  ];
}
