import { Component, computed, ComputedRef } from 'vue';

import FilterList from './FilterList/index.vue';
import FilterSelect from './FilterSelect/index.vue';
frappe.provide('frappe.guigu.GlobalView.siderCustomize');
const customize = frappe.guigu.GlobalView.siderCustomize;

function getCustomize(name: string | any) {
	if (name === 'filterList') { return FilterList; }
	if (name === 'filters') { return FilterSelect; }
	if (!name) { return; }
	if (typeof name === 'function') { return name; }
	if (typeof name === 'object') { return name; }
	return name in customize && customize[name];
}


export default function useSider(
	s: ComputedRef<(string | Component<any>)[] | undefined>,
) {
	const map = new Map<string | Component | undefined, Component | undefined>;
	function getFromMap(it?: string | Component) {
		if (map.has(it)) {
			return map.get(it);
		}
		const value = getCustomize(it);
		map.set(it, value);
		return value;
	}
	return computed(() => {
		const sider: Component[] = [];
		const v = s.value;
		for (const it of Array.isArray(v) ? v : [v]) {
			if (!it) { continue; }
			const value = getFromMap(it);
			if (!value) { continue; }
			sider.push(value);
		}
		return sider;
	});

}
