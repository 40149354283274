<script lang="ts" setup>
import {computed} from 'vue';

import {Filter} from '../../GlobalView/types';
import allViews from '../../extends/Views';
import getFiltersSearch from '../../utils/getFiltersSearch';

interface Option {
	value: string;
	label: string;
	filter?: boolean;
	getLink?: (doctype: string, filters: Filter[], options?: any) => string
}
const views = Object.entries(allViews)
	.map(([k, v]) => {
		const link = v?.link;
		if (!link) {
			return;
		}
		const label = v?.title || frappe.utils.to_title_case(k);
		if (typeof link !== 'object') {
			return {value: k, label};
		}
		const {filter, getLink} = link;
		return {value: k, label, filter, getLink};
	}).filter(Boolean as any as (v: any) => v is Option);
const viewMap = new Map(views.map(v => [v.value, v]));

const props = defineProps<{
	value?: any;
	editing: boolean;
	size?:'small'
}>();
const emit = defineEmits<{
	(event: 'edit'): void
}>();

const doctype = computed<string>(() => props.value?.doctype || '');
const label = computed<string>(() => props.value?.label || '');
const view = computed<string>(() => props.value?.view || '');
const currentView = computed(() => viewMap.get(view.value));
const filters = computed<Filter[]>(() => props.value?.filters || []);
const option = computed<any>(() => props.value?.option);
const text = computed<string>(() => label.value || __(doctype.value || ''));
const url = computed(() => {
	const dt = doctype.value;
	if (!dt) {
		return '';
	}
	const view = currentView.value;
	if (!view) {
		return `/app/${frappe.router.slug(dt)}`;
	}
	const {getLink} = view;
	const root = `/app/${frappe.router.slug(dt)}/view/${view.value}`;
	if (typeof getLink !== 'function') {
		const search = getFiltersSearch(dt, filters.value, [], true);
		return search.length ? `${root}?${search.join('&')}` : root;
	}
	const l = getLink(dt, filters.value, option.value);
	if (!l || typeof l !== 'string') {
		return root;
	}
	if ('/?#'.includes(l[0])) {
		return `${root}${l}`;
	}
	return `${root}/${l}`;
});
function click(e: Event) {
	if (props.editing) {
		e.preventDefault();
	}
}
</script>

<template>
	<WorkspaceWidget :class="[editing ? 'editing' : undefined, props.size==='small'?'small':undefined]">
		<template #title>
			<a v-if="url" class="link" @click="click" :href="url"
				:title="text">
				<span>
					{{ text }}
				</span>
				<svg v-if="!editing" class="icon ml-2 icon-xs"
					style="stroke: currentColor">
					<use class="" href="#icon-arrow-up-right"></use>
				</svg>
			</a>
		</template>
	</WorkspaceWidget>
</template>
<style scoped lang="less">
.small{
	padding: 0!important;
	.link{
		font-size: var(--text-md);
		color:var(--text-color);
		font-weight: 400;
		line-height: 1.5;
	}
}
.link {
	display: flex;
	font-size: 14px;
	font-weight: 500;
	text-decoration: none !important;
	max-width: 100%;
	align-items: center;

	span {
		vertical-align: middle;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

	}

	&:hover {
		text-decoration: underline !important;
		color: var(--blue-500);
	}

	.editing & {
		font-size: 16px;
	}
}
</style>
