<template>
	<ElTree v-loading="loading"
		class="container"
		:data="users"
		nodeKey="value"
		:expandOnClickNode="false"
		@node-click="click"
		:props="{ class: customNodeClass }" />
</template>

<script setup lang='ts'>
import {computed, watch, shallowRef} from 'vue';
import {ElTree} from 'element-plus';
interface User{value: string; label: string}
const props = defineProps<{
	users: User[]
	modelValue?: string
}>();
const loadingCount = shallowRef(0);
const loading = computed(() => Boolean(loadingCount.value));
const emit = defineEmits<{
	'update:modelValue': [string];
}>();

const customNodeClass = (data: User) => {
	if (data.value === props.modelValue) {
		return 'guigu-is-selected-value';
	}
	return {};
};
function click({value}: User) {
	emit('update:modelValue', value);
}


</script>

<style scoped lang="less">
.container {
    background: none;

    :global(.guigu-is-selected-value) {

        >:global(.el-tree-node__content)>:global(.el-tree-node__label) {
            color: #626aef;
        }

    }
    height: 100%;
    overflow: auto;
}

</style>
