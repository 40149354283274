<template>
	<ElButtonGroup v-if="expanders.length">
		<ElButton @click="set()" round>{{ tt('Fold') }}</ElButton>
		<ElDropdown @command="set" :teleported="false">
			<ElButton round>{{ tt('Expand') }}</ElButton>
			<template #dropdown>
				<ElDropdownMenu>
					<ElDropdownItem
						v-for="item in expanders"
						:key="item.key"
						:command="item">
						{{ item.title }}
					</ElDropdownItem>
				</ElDropdownMenu>
			</template>
		</ElDropdown>
	</ElButtonGroup>
</template>
<script lang="ts" setup>
import {
	ElButton, ElButtonGroup, ElDropdownMenu,
	ElDropdownItem, ElDropdown,
} from 'element-plus';

import type {ExpanderItem} from '../type';
const tt = __;
defineProps<{
	expanders:any[]
}>();
interface Emit{
	(e: 'fold'): void,
	(e: 'expand', expander:ExpanderItem): void,
}
const emit = defineEmits<Emit>();

function set(ex?: ExpanderItem) {
	if (ex) {
		emit('expand', ex);
	} else {
		emit('fold');
	}
}

</script>
