import {createApp} from 'vue';

import DiaryReport from './index.vue';

import VueViewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn';

export default function loadConstructionDiaryReport(wrapper:HTMLElement) {
	const app = createApp(DiaryReport, {});
	app.use(VueViewer);
	app.use(ElementPlus, {size: 'small', locale: zhCn});
	app.mount(wrapper);
}

frappe.provide('frappe.guigu_pm');
frappe.guigu_pm.guigu_pm_construction = frappe.guigu_pm.guigu_pm_construction || {};
frappe.guigu_pm.guigu_pm_construction.pages = frappe.guigu_pm.guigu_pm_construction.page || {};
frappe.guigu_pm.guigu_pm_construction.pages.loadConstructionDiaryReport = loadConstructionDiaryReport;
